const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",

    FETCH_PRODUCT_ITEMS_REQUESTED: "FETCH_PRODUCT_ITEMS_REQUESTED",
    FETCH_PRODUCT_ITEMS_SUCCEEDED: "FETCH_PRODUCT_ITEMS_SUCCEEDED",
    FETCH_PRODUCT_ITEMS_FAILED: "FETCH_PRODUCT_ITEMS_FAILED",

    EDIT_NOTE_REQUESTED: "EDIT_NOTE_REQUESTED",
    EDIT_NOTE_SUCCEEDED: "EDIT_NOTE_SUCCEEDED",
    EDIT_NOTE_FAILED: "EDIT_NOTE_FAILED",
    DELETE_NOTE_REQUESTED: "DELETE_NOTE_REQUESTED",
    DELETE_NOTE_SUCCEEDED: "DELETE_NOTE_SUCCEEDED",
    DELETE_NOTE_FAILED: "DELETE_NOTE_FAILED",

    CHANGE_ACTIVE_SUBTAB: "CHANGE_ACTIVE_SUBTAB",
    FETCH_SUBTAB_ITEMS_REQUESTED: "FETCH_SUBTAB_ITEMS_REQUESTED",
    ASSIGN_TO_PRODUCT_SUCCESS: "ASSIGN_TO_PRODUCT_SUCCESS",
    ASSIGN_TO_PRODUCT_FAILED: "ASSIGN_TO_PRODUCT_FAILED",
    UPDATE_SUCCESS: "UPDATE_SUCCESS",
    UPDATE_FAILED: "UPDATE_FAILED",
    SAVE_COPY_PUBLICATION_SUCCESS: "SAVE_COPY_PUBLICATION_SUCCESS",
    SAVE_COPY_PUBLICATION_FAILED: "SAVE_COPY_PUBLICATION_FAILED",
    CLOSE_COPY_PUBLICATION_MODAL: "CLOSE_COPY_PUBLICATION_MODAL",
    OPEN_COPY_PUBLICATION_MODAL: "OPEN_COPY_PUBLICATION_MODAL",
    CREATE_FILES_REQUEST: "CREATE_FILES_REQUEST",
    CREATE_FILES_SUCCESS: "CREATE_FILES_SUCCESS",
    CREATE_FILES_FAILED: "CREATE_FILES_FAILED",
    CLOSE_MODAL: "CLOSE_MODAL",
    OPEN_MODAL: "OPEN_MODAL",
    CHANGE_SELECT: "CHANGE_SELECT",

    CLOSE_EDIT_MODAL: "CLOSE_EDIT_MODAL",
    OPEN_EDIT_MODAL: "OPEN_EDIT_MODAL",

    EDIT_PRODUCT_FAILED: "EDIT_PRODUCT_FAILED",
    EDIT_PRODUCT_SUCCESS: "EDIT_PRODUCT_SUCCESS",

    RESET_FORM: "RESET_FORM",
};
export default Constants;

export const PUBLICATIONS_TABS = {
    VIEW: 'View',
    SAP_PRODUCTS: 'SapProducts',
    HISTORY: 'History',
    NOTES: 'Notes',
}

export const TABS = [
    {
        id: PUBLICATIONS_TABS.VIEW,
        title: "View",
    },
    {
        id: PUBLICATIONS_TABS.SAP_PRODUCTS,
        title: "Products",
    },
    {
        id: PUBLICATIONS_TABS.HISTORY,
        title: "History",
    },
    {
        id: PUBLICATIONS_TABS.NOTES,
        title: "Updates",
    }
];
