import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "../../../Services/Context";
import { withTagDefaultProps } from "../../../Hoc/Template";
import Success from "./View/Success";
import Forgot from "./View/Forgot";

const Index = ({ status }) => <>{status === "success" ? <Success /> : <Forgot />}</>;

Index.propTypes = {
    status: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    status: getStoreItem(state, "status"),
});

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
