import _ from "lodash";

export const columns = {
    strategic_group: "Strategic Group",
    sales_group: "Sales Group",
    gvw_rating: "GVW Rating",
    car_truck: "Car/Truck",
    product_type: "Product Code",
    segment_regional_sales: "Regional Sales Segment",
    global_nameplate: "Global Nameplate",
    segment_global_production: "Global Production Segment",
    global_production_class_price: "Global Production Class Price",
    global_sales_segment: "Global Sales Segment",
    global_sales_sub_segment: "Global Sales Project Sub-Segment",
    global_sales_price_class: "Global Sales-Price Class",
    short_term_risk: "Short-Term Risk Rating",
    long_term_risk: "Long-Term Risk Rating",
};

export const getFormatValue = (item, key) => {
    const value = _.get(item, key, "");

    switch (key) {
        default:
            return value;
    }
};
