import React from "react";
import { compose } from "redux";
import { useSelector } from 'react-redux';

import PropTypes from "prop-types";
import { numWithSub } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import Column from "../Templates/Column";
import Row from "../Templates/Row";
import DashboardCard from "./Level_2/Card/Widget/DashboardCard";
import StatusDataDashboardCard from "./Level_2/Card/Status/DataDashboardCard";
import ChartHeadDashboardCard from "./Level_2/Card/Chart/HeadDashboardCard";
import ChartDataDashboardCard from "./Level_2/Card/Chart/DataDashboardCard";
import {Title, Total} from "Templates/Titles";
import dashboardTypeConstant from "../Constants/DashboardConstants";
import DataBusinessDashboardCard from "./Level_2/Card/Status/DataBusinessDashboardCard";
import {Tooltip} from "Templates/Form";
import { weightUnitName, weightUnitConvertLbs } from 'Services/weightUnitName';
import Img from "Templates/Img";

const propTypes = {
    revenue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    volume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    grossMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    financialYear: PropTypes.string.isRequired,
    help: PropTypes.string,
    companyName: PropTypes.string.isRequired,
    dashboardType: PropTypes.number.isRequired,
    position: PropTypes.string.isRequired,
    expandRow: PropTypes.func.isRequired,
};

const InfoDashboardCard = DashboardCard({});
const ChartDashboardCard = DashboardCard({
    HeadDashboardCard: ChartHeadDashboardCard,
    DataDashboardCard: ChartDataDashboardCard,
});

const DashboardTypeFour = ({ revenue, companyName, volume, dashboardType, position, financialYear, help, expandRow, t }) => {
    const unit = useSelector(state => state.weightUnitState);
    const StatusDashboardCard = DashboardCard({
        DataDashboardCard: dashboardTypeConstant['BUSINESS'] === dashboardType ? DataBusinessDashboardCard : StatusDataDashboardCard
    });

    return (<>
        <div className="forecast-explorer__expand-title">
            <Title
                title={t("Forecast Roll-up Level 2")}
                help={help}
                total={() => (
                    <Total>
                        <div className="gray-text">
                            <Tooltip>
                                {t(`This is a roll-up for all your forecasts for the current fiscal year, consolidated into one view`)}
                            </Tooltip>
                        </div>
                    </Total>
                )}
            />
            <button type="button" className="expand-button" onClick={expandRow}>
                <Img img="expand-icon" width="24" height="24"/>
            </button>
        </div>
        <div className="dashboard__wrap-main main-content__wrap">
            <div className="dashboard__list">
                <Row>
                    <Column>
                        <StatusDashboardCard
                            headProps={{
                                title: t(`My Pipeline Forecast Revenue`),
                                subtitle: t(`FY ${financialYear}`),
                            }}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column columnSize={1} fromColumns={4}>
                        <InfoDashboardCard
                            headProps={{ title: t(`My ${dashboardTypeConstant['BUSINESS'] === dashboardType ? "Level 4" : "Current"} Forecast Revenue`), subtitle: t(`FY ${financialYear}`) }}
                            dataProsp={{ value: `$${numWithSub(Number(revenue))}`, className: "content_center" }}
                        />
                        <InfoDashboardCard
                            headProps={{ title: t(`My ${dashboardTypeConstant['BUSINESS'] === dashboardType ? "Level 4" : "Current"} Forecast Volume`), subtitle: t(`FY ${financialYear}`) }}
                            dataProsp={{ value: `${numWithSub(Number(volume))}`, unit: `${weightUnitConvertLbs(weightUnitName(unit.weightUnit))}`, className: "content_center" }}
                        />
                    </Column>
                    <Column columnSize={3} fromColumns={4}>
                        <ChartDashboardCard className="main-data" />
                    </Column>
                </Row>
            </div>
        </div>
    </>
    );
};

DashboardTypeFour.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(DashboardTypeFour);
