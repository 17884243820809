import React from "react";
import TableHeadColumnTemplate from "Templates/Table/TableHeadColumn";
import PropTypes from "prop-types";
import _ from "lodash";
import {compose} from "redux";
import TableHeadFilter from "Templates/Table/TableHeadFilter";
import TableHeadTitle from "Templates/Table/TableHeadTitle";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";

const propTypes = {
    totalAnalytics: PropTypes.arrayOf(PropTypes.any).isRequired,
    column: PropTypes.string.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getBudgetFilter: PropTypes.func.isRequired,
        getBudgetTableLabel: PropTypes.func.isRequired,
    }).isRequired,
    onSortClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const BudgetTableHeadColumnWrap = ({t, column, filter, sort, service, onSortClick, onChange, currentFY, totalAnalytics}) => {
    const {getBudgetFilter, getBudgetTableLabel} = service;

    const datesLabels = {
        slug: "Identification number of unique pair Customer Name and Product Name.",
        company_short_name: "Customer Name is field 1 in SAP.",
        company: "Lists SAP company name fields 1 - 4 concatenated.",
        customer_duns_number: "D-U-N-S is a registered trademark D&B",
        grade_sub_status: `
            <div class="long-tooltip"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</div>
            <div class="long-tooltip"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</div>
            <div class="long-tooltip"><span class="font-medium">Production Intent</span> - Product in process for being approved.</div>
            <div class="long-tooltip"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</div>
            <div class="long-tooltip"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</div>
            <div class="long-tooltip"><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</div>
        `,
        material_type: `
            <span class="white-space-nowrap">FERT = Finished Goods</span>
            <span class="white-space-nowrap">HALB = Semifinished Goods</span>
            <span class="white-space-nowrap">HAWA = Trade Goods</span>
        `,
        product_code: "The product code is automatically assigned to an SAP Product.",
        sap_application_code: "The SAP application code is automatically assigned to an SAP Product.",
        move_to_current: "This is the start date of a sale for a running change OR the start date the sale transferred to a new customer in the same Fiscal Year."
    }

    return (
        <TableHeadColumnTemplate>
            <div className={`head__with-tooltip flex ${column === 'move_to_current' ? 'move-to-current connections-move-to-current' : ''}`}>
                <TableHeadTitle column={column} sort={sort} onSortClick={() => onSortClick(column)}>
                    {t(getBudgetTableLabel(column, {currentFY, totalAnalytics}))}
                </TableHeadTitle>
                {
                    datesLabels[column] &&
                    <Tooltip>
                        <span dangerouslySetInnerHTML={{__html: datesLabels[column]}}></span>
                    </Tooltip>
                }
            </div>
            <TableHeadFilter>
                {getBudgetFilter(column, _.get(filter, column, "") || _.get(filter, `${column}s`, ""), onChange, {
                    filter,
                    sort
                })}
            </TableHeadFilter>
        </TableHeadColumnTemplate>
    );
};

BudgetTableHeadColumnWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        totalAnalytics: getStoreItem(state, "total_analytics"),
        filter: getStoreItem(state, "filter"),
        sort: getStoreItem(state, "sort"),
        currentFY: getStoreItem(state, "financialYear"),
        help: getStoreItem(state, "help"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(BudgetTableHeadColumnWrap);