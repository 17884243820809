import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Block, BlockWrap, Input, WrapInput } from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
};

const AddressBlock = ({ data, name, onChange, t }) => {
    const _onChange = (value, key) => {
        onChange(value.substring(0, 255), key);
    };

    return (
        <>
            <Block>
                <BlockWrap>
                    <Block>
                        <BlockWrap>
                            <WrapInput label={t("Address")} name={`${name}Address1`}>
                                <Input
                                    placeholder={t("Address")}
                                    value={data.address1}
                                    onChange={value => _onChange(value, `${name}Address1`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap />
                        <BlockWrap />
                    </Block>
                </BlockWrap>
            </Block>

            <Block>
                <BlockWrap>
                    <Block>
                        <BlockWrap>
                            <WrapInput label={t("City")} name={`${name}City`}>
                                <Input
                                    placeholder={t("City")}
                                    value={data.city}
                                    onChange={value => _onChange(value, `${name}City`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput label={t("Region/State")} name={`${name}State`}>
                                <Input
                                    placeholder={t("Region/State")}
                                    value={data.state}
                                    onChange={value => onChange(value, `${name}State`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput label={t("District/County")} name={`${name}District`}>
                                <Input
                                    placeholder={t("District/County")}
                                    value={data.district}
                                    onChange={value => _onChange(value, `${name}District`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>
                </BlockWrap>
            </Block>

            <Block>
                <BlockWrap>
                    <Block>
                        <BlockWrap>
                            <WrapInput label={t("Country")} name={`${name}Country`}>
                                <Input
                                    placeholder={t("Country")}
                                    value={data.country}
                                    onChange={value => _onChange(value, `${name}Country`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput label={t("Zip/Postal")} name={`${name}Zip`}>
                                <Input
                                    placeholder={t("Zip/Postal")}
                                    value={data.zip}
                                    onChange={value => _onChange(value, `${name}Zip`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput label={t("Time Zone")} name={`${name}TimeZone`}>
                                <Input
                                    placeholder={t("Time Zone")}
                                    value={data.timeZone}
                                    onChange={value => _onChange(value, `${name}TimeZone`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>
                </BlockWrap>
            </Block>
        </>
    );
};

AddressBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { name, service } = ownProps;
    const { getStoreItem } = service;

    return {
        data: {
            country: getStoreItem(state, `${name}Country`),
            city: getStoreItem(state, `${name}City`),
            address1: getStoreItem(state, `${name}Address1`),
            zip: getStoreItem(state, `${name}Zip`),
            state: getStoreItem(state, `${name}State`),
            district: getStoreItem(state, `${name}District`),
            timeZone: getStoreItem(state, `${name}TimeZone`),
        },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(AddressBlock);
