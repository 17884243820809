import React, {Fragment} from "react";
import {compose} from "redux";
import {withTagDefaultProps, CheckPermission} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import TableBody from "Templates/Table/TableBody";
import Img from "Templates/Img";

const ProductFinderContainerTable = (TableHeadColumn, TableRowColumn, {showButtons = true}) => ({
                                                                                       items,
                                                                                       loading,
                                                                                       request,
                                                                                       columns,
                                                                                       customIcon,
                                                                                       fetchItems,
                                                                                       onClickEdit,
                                                                                       onClickItem,
                                                                                       onClickDelete,
                                                                                       onClickCustom,
                                                                                       service: {tableButtonsPermission},
                                                                                   }) => {
    const showColumns = showButtons ? [...columns, "-1"] : [...columns];

    const onSubmit = e => {
        e.preventDefault();

        fetchItems(request);
    };

    const onSortClick = column => {
        const asc = "asc";
        const desc = "desc";
        const newRequest = {
            ...request,
            sort: {
                ...request.sort,
                column,
            },
        };
        if (request.sort.column === column) {
            newRequest.sort.direction = request.sort.direction === asc ? desc : asc;
        } else {
            newRequest.sort.direction = asc;
        }

        fetchItems(newRequest);
    };

    return loading ? null : <>
        {
            items && items.length && Object.keys(request.filter).length ? (
                <form onSubmit={onSubmit}>
                    <table className="main-table">
                        <tbody>
                        <tr>
                            {showColumns.map((column, index) => (
                                <Fragment key={`TableRowColumn-${index}`}>
                                    {column === "-1" ? (
                                        <CheckPermission permission={tableButtonsPermission}>
                                            <th key={`TableHeadColumn-${index}`} className="main-table__head"/>
                                        </CheckPermission>
                                    ) : (
                                        <th key={`TableHeadColumn-${index}`} className="main-table__head">
                                            <TableHeadColumn onSortClick={onSortClick} column={column} index={index}/>
                                        </th>
                                    )}
                                </Fragment>
                            ))}
                        </tr>
                        <TableBody
                            columns={showColumns}
                            TableRowColumn={TableRowColumn}
                            customIcon={customIcon}
                            onClickItem={onClickItem}
                            onClickEdit={onClickEdit}
                            onClickDelete={onClickDelete}
                            onClickCustom={onClickCustom}
                        />
                        </tbody>
                    </table>
                    <button type="submit" className="visually-hidden"/>
                </form>
            ) : (
                <div className="empty-product-finder-plug">
                    <Img img="filters_icon"/>
                    <p>
                        {!items.length && Object.keys(request.filter).length ? 'No products found. \n' : ''}
                        Please use the filters in the sidebar to find the products you need.
                    </p>
                </div>
            )
        }
    </>
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;
    return {
        items: getStoreItem(state, 'items'),
        columns: getStoreItem(state, "columns"),
        loading: getStoreItem(state, "loading"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default (TableHeadColumn, TableRowColumn, {showButtons = true} = {}) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(ProductFinderContainerTable(TableHeadColumn, TableRowColumn, {showButtons}));
