import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowItemCustom from "./TableRowItemCustom";

const propTypes = {
    onClickCustom: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

const TableRowColumnButton = ({ index, onClickCustom }) => {
    const _onClickCustom = () => {
        onClickCustom(index);
    };

    return <TableRowItemCustom onClickCustom={_onClickCustom} />;
};

TableRowColumnButton.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowColumnButton);
