import React from "react";
import {compose} from "redux";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {numberWithCommas} from "Services";
import {weightUnitName} from "Services/weightUnitName";
import {BlockTitle, Col} from "Templates/Form";

const TableBodyColumn = ({items, weightUnit}) => {
    return items && items.yearCYDetail && items.yearCYDetail.length > 0 ? (
        <tr>
            <td className="project-expanded-block sub">
                <Col>
                    <div className="project-expanded-block__title-wrap">
                        <BlockTitle>CY</BlockTitle>
                    </div>

                    <div className="project-expanded-block__toggle">
                        <div className="project-expanded-block__table">
                            <div className="td project-expanded-block__table-row">
                                <div className="vertical-row">
                                    <div className="project-expanded-block__table-col labels">
                                        <p className="project-expanded-block__table-title project-expanded-block__table-material-item plug"/>
                                        <p className="project-expanded-block__table-value project-expanded-block__table-material-item">
                                            CY
                                            Volume
                                        </p>
                                        <p className="project-expanded-block__table-value project-expanded-block__table-material-item">
                                            CY
                                            Revenue
                                        </p>
                                        <p className="project-expanded-block__table-value project-expanded-block__table-material-item">
                                            CY
                                            Builds
                                        </p>
                                    </div>
                                    {items.yearCYDetail.map((item, i) => {
                                        return (
                                            <div
                                                className="project-expanded-block__table-col-horizontal"
                                                key={`project-forecast-fy-${i}`}
                                            >
                                                <p className="project-expanded-block__table-title"><span
                                                    className="project-expanded-block__table-value-text" title={item.date_value}>{item.date_value}</span>
                                                </p>
                                                <p className="project-expanded-block__table-value">
                                                <span className="project-expanded-block__table-value-text" title={`
                                                    ${numberWithCommas(item.volume, 0)} 
                                                    ${weightUnitName(weightUnit && weightUnit.weightUnit)}
                                                `}>
                                                    {numberWithCommas(item.volume, 0)}
                                                    {' '}
                                                    {weightUnitName(weightUnit && weightUnit.weightUnit)}
                                                </span>
                                                </p>
                                                <p className="project-expanded-block__table-value">
                                                    <span className="project-expanded-block__table-value-text" title={`
                                                        $${numberWithCommas(item.revenue, 0)}
                                                    `}>
                                                        $
                                                        {numberWithCommas(item.revenue, 0)}
                                                    </span>
                                                </p>
                                                <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={numberWithCommas(item.real_builds, 0)}>{numberWithCommas(item.real_builds, 0)}</span></p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </td>
        </tr>
    ) : null
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        items: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
