import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {Tooltip} from "Templates/Form";
import {onChange} from "../../Services/EventsService";

function MaterialProperty({callback, filter}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>Material Property</BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    <p className="main-table__head-link filter-with-tooltip">
                        Property
                        <Tooltip>
                            {`Physical, chemical, or mechanical components of a specific product that would determine its functionality and manufacturability`}
                        </Tooltip>
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/product/product-finder/material-property-list/1"
                        name="property"
                        value={filter.property}
                        onChange={e => {
                            onChange(e).then(_value => callback('property', _value));
                        }}
                    />
                    <p className="main-table__head-link filter-with-tooltip">
                        Test Method
                        <Tooltip>
                            {`Specification of the test used to determine the physical property of the product`}
                        </Tooltip>
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/product/product-finder/material-property-list/2"
                        name="method"
                        value={filter.method}
                        onChange={e => {
                            onChange(e).then(_value => callback('method', _value));
                        }}
                    />
                    <p className="main-table__head-link filter-with-tooltip">
                        Specimen
                        <Tooltip>
                            {`Describes the type of specimen used in the test method`}
                        </Tooltip>
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        name="specimen"
                        url="/product/product-finder/material-property-list/3"
                        value={filter.specimen}
                        onChange={e => {
                            onChange(e).then(_value => callback('specimen', _value));
                        }}
                    />
                    <p className="main-table__head-link filter-with-tooltip">
                        Test Condition
                        <Tooltip>
                            {`The atmosphere to which a sample or test piece is exposed during the test.`}
                        </Tooltip>
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        name="condition"
                        url="/product/product-finder/material-property-list/4"
                        value={filter.condition}
                        onChange={e => {
                            onChange(e).then(_value => callback('condition', _value));
                        }}
                    />
                </div>
            )}
        </>
    );
}

MaterialProperty.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default MaterialProperty;
