import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

// const defaultProps = {
//     percent: "",
// };

const propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // percent: PropTypes.string,
};

const DataDashboardCard = ({ pref, value }) => {
    return (
        <div className={`${pref}__card-data`}>
            <div className={`${pref}__card-total`}>
                <span>{value}</span>
            </div>
            {/* {percent && (
                <div className={`${pref}__card-diff`}>
                    <span className={`${percent < 0 ? "clr-red" : "clr-green"}`}>{`${percent}%`}</span>
                </div>
            )} */}
        </div>
    );
};

// DataDashboardCard.defaultProps = defaultProps;
DataDashboardCard.propTypes = propTypes;

export default compose(withTagDefaultProps())(DataDashboardCard);
