import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const ColumnFlex = ({ pref, className, children }) => (
    <div className={`${pref}__form-column-flex ${className}`}>{children}</div>
);

ColumnFlex.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

ColumnFlex.defaultProps = {
    className: "",
    children: "",
};

export default compose(withTagDefaultProps())(ColumnFlex);
