import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import TabView from "./Views/TabView";
import { numberWithCommas } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { Title, WrapButtons, Total } from "Templates/Titles";
import { MainButtonLink } from "Templates/Content";
import ShowPermission from "Hoc/Template/ShowPermission";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import TabCheck from "Templates/Tabs/TabСheck";
import allUnitsService from "./Services/Tabs/AllUnits";
import myUnitsService from "./Services/Tabs/MyUnits";

const propTypes = {
    activeTab: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    onChangeTab: PropTypes.func.isRequired
};

const MyForecast = ({t, activeTab, title, help, onChangeTab }) => {
    const tabs = () => {
        return (
            <Tabs
                activeTab={activeTab}
                onClickTabItem={index => {
                    if (activeTab !== index) {
                        onChangeTab(index);
                    }
                }}
            >
                <Tab label={t("My Unit Forecasts")}>
                    <ServiceProvider value={myUnitsService}>
                        <TabView/>
                    </ServiceProvider>
                </Tab>
                <TabCheck label={t("All Unit Forecasts")} permission='forecast_all_units_view'>
                    <ServiceProvider value={allUnitsService}>
                        <TabView/>
                    </ServiceProvider>
                </TabCheck>
            </Tabs>
        );
    };

    return (
        <ShowPermission checkPermission={["forecast_all_units_access"]}>
            <Title
                title={t('Unit Forecasts')}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                <WrapButtons>
                    <MainButtonLink href="/project/forecasts/create" className="main-btn_primary">
                        {t("Add New Forecast")}
                    </MainButtonLink>
                </WrapButtons>
            </Title>

            {tabs()}
        </ShowPermission>
    );
};

MyForecast.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        title: getStoreItem(state, "pagination.total"),
        help: getStoreItem(state, "help"),
        activeTab: getStoreItem(state, "activeTab"),
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MyForecast);
