import React from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {BlockShadow, Form} from "Templates/Form";
import GeneralInfoBlock from "./Block/GeneralInfoBlock";
import ProjectTeamAndContact from "./Block/ProjectTeamAndContact";
import SupplyChainBlock from "./Block/SupplyChainBlock";
import Specification from "./Block/Specification";
import BlockMainCheck from "./BlockMainCheck";
import PropTypes from "prop-types";

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const FormBlock = ({permissions}) => {
    return (
        <Form>
            <BlockMainCheck title="Add to ComOpps Forecast">
                <BlockShadow blockName="sales_info_model">
                    <GeneralInfoBlock permissions={permissions}/>
                    <SupplyChainBlock />
                    <ProjectTeamAndContact />
                    <Specification />
                </BlockShadow>
            </BlockMainCheck>
        </Form>
    );
};

FormBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(),
)(FormBlock);
