import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ModalDefault } from "Templates/Modals";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import { Input, WrapInput } from "Templates/Form";
import { withServiceConsumer } from "Services/Context";

const { Button, Form, Title } = ModalDefault;

const propTypes = {
    onAppend: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

class UploadFileForm extends Component {
    constructor(props) {
        super(props);
        this.defaultFileData = ({ type, label }) => ({ attachment: "", type, label });
        this.types = [{ type: 1, label: "IHS LVP Sales Forecast" }, { type: 2, label: "IHS Delta" }];
        this.state = {
            files: this.types.reduce((acc, elem) => [...acc, this.defaultFileData(elem)], []),
        };
    }

    setFile = index => file => {
        this.setState(prevState => {
            prevState.files[index].attachment = file;
            return {
                files: [...prevState.files],
            };
        });
    };

    handleClickOnSaveTable = () => {
        const { onAppend, onClose } = this.props;
        const { files } = this.state;
        onAppend(files.filter(elem => elem.attachment)).then(onClose);
    };

    handleClickOnDeleteButton = el => () => {
        this.setState(prevState => ({
            files: prevState.files.reduce((acc, elem) => {
                if (elem.type === el.type) {
                    return [...acc, this.defaultFileData(el)];
                }
                return [...acc, elem];
            }, []),
        }));
    };

    render() {
        const { t, onClose } = this.props;
        const { files } = this.state;
        const disabled = !files.some(elem => elem.attachment);

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose(files)} />
                <Title>{t("Add File")}</Title>
                {files.map((elem, index) => (
                    <WrapInput key={`WrapInput-${index}`} label={t(elem.label)} name="type">
                        <Input
                            type="file"
                            name={`file-${elem.type}`}
                            value={elem.attachment.name || elem.attachment}
                            onChange={this.setFile(index)}
                            handleClickOnDeleteButton={this.handleClickOnDeleteButton(elem)}
                        />
                    </WrapInput>
                ))}
                <Button onClick={this.handleClickOnSaveTable} disabled={disabled}>
                    {t("Save File(s)")}
                </Button>
            </Form>
        );
    }
}

UploadFileForm.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(UploadFileForm);
