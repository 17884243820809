import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import TabView from "./Views/TabView";

const propTypes = {
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Grade = ({ service, fetchItems, request, resetFilter, t, permissions }) => {
    const { deleteItem } = service;

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        url.redirect(`/product/product-finder/${item.id}/edit`);
    };

    const onClickShow = item => {
        const win = window.open(`/product/product-finder/${item.id}`, "_blank");
        win.focus();
    };

    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the Product?"))) {
            deleteItem(`/product/product-finder/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const handleReset = () => {
      url.clearHistory();
      return resetFilter({});
    }

    return (
      <TabView
        onClickCustom={onClickShow}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        handleReset={handleReset}
        permissions={permissions}
      />
    );
};

Grade.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        resetFilter: getActionStore("resetFilter", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Grade);
