import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import CheckboxBlock from "Templates/Form/Checkbox/CheckboxBlock";
import CheckboxButton from "Templates/Form/Checkbox/CheckboxButton";
import CheckboxTitle from "Templates/Form/Checkbox/CheckboxTitle";
import {ButtonRight} from "Templates/Form";
import BlockSubmit from "Templates/Form/BlockSubmit/BlockSubmit";
import ButtonLeft from "Templates/Form/BlockSubmit/ButtonLeft";

const {Button, Form, Title, Col, Row, ColText, ColInput, Textarea} = ModalDefault;

const propTypes = {
    guestForm: PropTypes.object.isRequired,
    closeInviteModal: PropTypes.func.isRequired,
    updateGuestForm: PropTypes.func.isRequired,
    saveInviteGuestsItems: PropTypes.func.isRequired,
};

const InviteGuestsModalForm = (
    {
        guestForm,
        closeInviteModal,
        updateGuestForm,
        saveInviteGuestsItems,
        t,
    }
) => {
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const formValidate = () => {
        const {email} = guestForm;

        return Boolean((email && email.trim() !== '' && validateEmail(email)));
    };

    const handleSave = () => {
        const formData = {...guestForm}
        saveInviteGuestsItems(formData)
    };

    const onChangeInput = (value, key) => {
        updateGuestForm({value, key});
    };

    return (
        <Form className="invite-users-guest-form">
            <ButtonClose className="main-modal__form-close" onClick={closeInviteModal}/>
            <Title>{t('Invite Guest User')}</Title>
            <Row>
                <Col>
                    <ColText className="bold-text" isRequired>
                        {t("Email")}
                    </ColText>
                    <ColInput
                        placeholder={t("Enter Email")}
                        name="email"
                        value={guestForm.email || ''}
                        onChange={value => onChangeInput(value, `email`)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ColText className="bold-text">
                        {t("Email Message")}
                    </ColText>
                    <Textarea
                        placeholder={t("Enter Text")}
                        onChange={value => onChangeInput(value, `invite_text`)}
                    >
                        {guestForm.invite_text || ''}
                    </Textarea>
                </Col>
            </Row>

            <Row>
                <Col>
                    <CheckboxBlock>
                        <CheckboxButton
                            htmlFor="checkbox_notifyCreator"
                            value={!!guestForm.notify_creator}
                            onChange={value => onChangeInput(value, `notify_creator`)}
                        >
                            <CheckboxTitle title="Notify me when the Guest User signs into ComOpps"/>
                        </CheckboxButton>
                    </CheckboxBlock>
                </Col>
            </Row>

            <BlockSubmit>
                <ButtonRight/>
                <ButtonLeft>
                    <Button onClick={handleSave} disabled={!formValidate() || (guestForm.lists_invite)}>
                        {t("Send Invitation")}
                    </Button>
                </ButtonLeft>
            </BlockSubmit>
        </Form>
    );
}

InviteGuestsModalForm.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        guestForm: getStoreItem(state, "guestForm"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        closeInviteModal: getActionStore("closeInviteModal", service, dispatch),
        updateGuestForm: getActionStore("updateGuestForm", service, dispatch),
        saveInviteGuestsItems: getActionStore("saveInviteGuestsItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(InviteGuestsModalForm);
