import {deleteItem, requestTable, requestOnSaveFilter, requestSavedFilterData} from "../../RequestService";
import { getStoreItem, getActionStore } from "../../StoreService";
import { handleScroll } from "../../EventsService";
import { getTableLabel, getFilter, isSortEnabled } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default ({
    requestTable: requestTable(`solution/search/list`),
    deleteItem,
    getFilter,
    requestOnSaveFilter,
    requestSavedFilterData,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
    isSortEnabled
});
