const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    CHANGE_TAB: "CHANGE_TAB",

    OPEN_MODAL: "OPEN_MODAL",
    CLOSE_MODAL: "CLOSE_MODAL",

    UPDATE_VENDOR_FORM: "UPDATE_VENDOR_FORM",

    SAVE_VENDOR_REQUESTED: "SAVE_VENDOR_REQUESTED",
    SAVE_VENDOR_SUCCEEDED: "SAVE_VENDOR_SUCCEEDED",
    SAVE_VENDOR_REJECTED: "SAVE_VENDOR_REJECTED",

    OPEN_EDIT_MODAL: "OPEN_EDIT_MODAL",
    CLOSE_EDIT_MODAL: "CLOSE_EDIT_MODAL"
};

export default Constants;
