import { reducer as defaultReducer, initialState as defaultState } from "./DefaultReducer";
import { reducer as ForecastReducer, initialState as ForecastState } from "./ForecastReducer";
import { reducer as GeneralReducer, initialState as GeneralState } from "./GeneralReducer";
import { reducer as ProductReducer, initialState as ProductState } from "./ProductReducer";
import { reducer as ApplicationReducer, initialState as ApplicationState } from "./ApplicationReducer";
import { initialState as ProjectTeamAndContactsState } from "./ProjectTeamAndContactsReducer";
import { initialState as ReplaceBusiness } from "./ReplaceBusiness";
import { reducer as ProjectVolumeReducer, initialState as ProjectVolumeState } from "./ProjectVolumeReducer";
import { initialState as SupplyChainState } from "./SupplyChainReducer";
import { reducer as ModalReducer, initialState as ModalState } from "./Modal";

const reducers = [defaultReducer, ApplicationReducer, ForecastReducer, ProductReducer, ModalReducer, ProjectVolumeReducer, GeneralReducer];

const mainState = {
    ...defaultState,
    forecastBlock: ForecastState,
    productBlock: ProductState,
    applicationBlock: ApplicationState,
    generalBlock: GeneralState,
    projectTeamAndContactsBlock: ProjectTeamAndContactsState,
    replacementBusinessProjectsBlock: ReplaceBusiness,
    projectVolumeBlock: ProjectVolumeState,
    supplyChainBlock: SupplyChainState,
    modal: ModalState,
};

export const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }

    return state;
};

export const initialState = mainState;
