import React from "react";
import {useSelector} from 'react-redux';
import {formatDate, getStoreItem as _getStoreItem, getString, numberWithCommas} from "Services";
import {gradeStatus, projectConsistent, projectStatus} from "Services/enum";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {weightUnitConvertLbs, weightUnitName} from 'Services/weightUnitName';
import _ from "lodash";

const getStoreItem = _getStoreItem("listState.projects");

const title = "Projects";

const getTableLabel = key => {
    const unit = useSelector(state => state.weightUnitState);

    const tableLabel = {
        slug: "Project ID",
        name: "Project Name",
        owner: "Supplier",
        sub_status: "Status",
        industry: "Project Market Segment",
        application_code: "Project Application Code",
        customer_duns_number: "D-U-N-S® Number",
        note: "Note",
        customer_owner: "Customer Owner",
        sub_segment: "Project Segment",
        application: "Project Sub-Segment",
        product: "Product Source",
        grade: "Product Name",
        material_number: "Material Number",
        product_code: "Product Code",
        product_application_code: "SAP Application Code",
        replacement_business_project_id: "Replacement Project ID",
        replacement_business_project_name: "Replacement Project Name",
        status: "Added to ComOpps Forecast",
        budget: "Added to Budget & Sales Forecast",
        plant: "Selling Plant",
        line: "Production Line",
        customer: "Customer",
        customer_code: "Customer Code",
        manufacturer: "Manufacturer",
        tier1: "OEM Tier 1",
        tier2: "OEM Tier 2",
        molder: "OEM Tier 3",
        customer_nickname: "Customer Nickname",
        tier1_nickname: "OEM Tier 1 Nickname",
        tier2_nickname: "OEM Tier 2 Nickname",
        molder_nickname: "OEM Tier 3 Nickname",
        unit: "Unit Forecast Name",
        sop: "SOP",
        company_is_sap: "SAP Companies",
        grade_is_sap: "SAP Grades",
        los_date: "Loss of Sales Date",
        created_at: "Date Submitted",
        updated_at: "Date Last Changed",
        sub_status_changed_at: "Change Status Date",
        stage_gate: "Salesforce Stage Gates",
        move_to_current: "Start Date",
        end_date: "End Date",
        eop: "EOP",
        manager: "Project Manager",
        internal_team: "Internal Team",
        platform: "Platform",
        program: "Program",
        brand: "Production Brand",
        nameplate: "Production Nameplate",
        country: "Country",
        region: "Region",
        production_plant: "Production Plant",
        city: "City",
        description: "Project Description",
        volume: "Volume",
        revenue: "Revenue",
        gross_margin: "Gross Margin",
        grossMargin: "Gross Margin",
        actual_sale_price: `Price ($/${weightUnitName(unit.weightUnit)})`,
        weight_value: `Part Weight (${weightUnitConvertLbs(weightUnitName(unit.weightUnit))})`,
        unit_value: "No. of Parts/Unit",
        grade_sub_status: "Product Status",
    };


    switch (key) {
        case "volume":
        case "revenue":
        case "gross_margin":
            return `FY ${currentFY}  ${_.get(tableLabel, key, key)}`;

        case String(key.match(/^volume_.*/)):
        case String(key.match(/^revenue_.*/)):
        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.split("_");
            return `${keys[1]} ${keys[2]} <br/> Forecast ${_.get(tableLabel, keys[0], key)}`;
        }

        default:
            return _.get(tableLabel, key, key);
    }
};

const getViewItem = (key, item) => {
    const unit = useSelector(state => state.weightUnitState);
    const tableRowItemLink = (labelKey, href) => (
        <TableRowItemLink blank title={getString(item, labelKey)} href={href}>
            {getString(item, labelKey)}
        </TableRowItemLink>
    );

    switch (key) {
        case "slug":
            return tableRowItemLink("slug", `/project/projects/${getString(item, "id")}`);

        case "name":
            return tableRowItemLink("name", `/project/projects/${getString(item, "id")}`);

        case "sub_status":
            return <TableRowItemStatus status={getString(item, key)} list={[...projectStatus]} />;

        case "status":
            return <TableRowItemStatus status={getString(item, key)} list={projectConsistent} />;

        case "replacement_business_project_id": {
            const replacementBusinessItems = item.replacement_business_projects
            return (
                <>
                    {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                        <TableRowItemLink blank
                                          title={getString(item, key)}
                                          href={`/project/projects/${elem.id}`}
                        >
                            {elem.slug}
                        </TableRowItemLink>
                    )) : '-'}
                </>
            );
        }

        case "replacement_business_project_name": {
            const replacementBusinessItems = item.replacement_business_projects
            return (
                <>
                    {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                        <TableRowItemLink blank
                                          title={getString(item, key)}
                                          href={`/project/projects/${elem.id}`}
                        >
                            {elem.name}
                        </TableRowItemLink>
                    )) : '-'}
                </>
            );
        }

        case "actual_sale_price":
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => {
                        if (elem && !isNaN(+elem)) {
                            elem = +elem >= 10 ? `$${(+elem).toFixed(2)}` : (+elem).toFixed(2)
                        } else {
                            elem = "0.00"
                        }

                        return (
                            <TableRowItemSpan className="text-right" key={`TableRowItemSpan-${index}`} title={elem}>
                                {elem}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );

        case String(key.match(/^volume_.*/)): {
            const keys = key.toLowerCase().split("_");
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, `analytics.${keys[2]}.${keys[1]}.${keys[0]}`), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^revenue_.*/)): {
            const keys = key.toLowerCase().split("_");
            const value = getString(item, `analytics.${keys[2]}.${keys[1]}.${keys[0]}`)

            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(value) < 10 ? Number(value).toFixed(2) : numberWithCommas(value, 0)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.toLowerCase().split("_");
            return (
                <TableRowItemSpan
                    title={getString(item, `analytics.${keys[2]}.${keys[1]}.gross_margin`)}
                    className={`${
                        getString(item, `analytics.${keys[2]}.${keys[1]}.gross_margin`) < 0 ? "clr-red" : ""
                    } text-right`}
                >
                    {`${getString(item, `analytics.${keys[2]}.${keys[1]}.gross_margin`)}%`}
                </TableRowItemSpan>
            );
        }

        case "unit_value": {
            return <TableRowItemSpan className='text-right' title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
        }

        case "grade_is_sap":
        case "company_is_sap":
        case "product":
        case "weight_value":
        case "manufacturer":
        case "grade":
        case "material_number":
        case "product_code":
        case "product_application_code":
        case "plant":
        case "line":
        case "unit":
        case "internal_team":
        case "platform":
        case "program":
        case "brand":
        case "nameplate":
        case "country":
        case "region":
        case "production_plant":
        case "city": {
            return (
                <>
                    {getString(item, key).split("\n").map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={elem}>
                            {elem}
                        </TableRowItemSpan>
                    ))}
                </>
            );
        }

        case "grade_sub_status":
            return (
                <>
                    {getString(item, key).split("\n").map((elem, index) => {
                        return (
                            <div key={`div-${index}`}>
                                <TableRowItemStatus
                                    key={`TableRowItemGradeSubStatus-${index}`}
                                    status={elem}
                                    list={gradeStatus}
                                />
                            </div>
                        )
                    })}
                </>
            );

        case "move_to_current":
        case "end_date":
        case "sop":
        case "eop": {
            return (
                <>
                    {getString(item, key).split("\n").map((elem, index) => {
                        const formatValue = elem.toString() === '-' ? elem : formatDate(elem);
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                {formatValue}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case "los_date":
        case "created_at":
        case "updated_at":
        case "sub_status_changed_at": {
            const elem = getString(item, key);
            const formatValue = elem.toString() === '-' ? elem : formatDate(elem);

            return (
                <TableRowItemSpan title={formatValue}>
                    {formatValue}
                </TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};

export default searchValue => ({
    link: `/search/projects?value=${searchValue}`,
    getStoreItem,
    title,
    getTableLabel,
    getViewItem,
});
