import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const dataReq = {
        title: _.get(data, "title"),
        project: _.get(data, "project"),
        assignedTo: _.get(data, "assignedTo"),
        description: _.get(data, "description"),
        start: _.get(data, "start"),
        due: _.get(data, "due"),
    };

    return formDataNormalizer(dataReq);
};
