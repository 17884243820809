import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import reducers, {initialStates} from "../Reducers";

export default function configureStore(props) {
    const {items, view} = props;
    const {mainState} = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({...props, pref: "invoice-view"}),
        listState: {
            ...mainState,
            items,
            view,
        },
    };

    return _configureStore(initialState, reducers);
}
