import React, {useEffect, useRef} from "react";

const ShowMoreSwitch = ({ isHidden }) => {
    const showMoreTitle = 'Show Less';
    const moreBtn = useRef(null);

    useEffect(() => {
        if (isHidden) {
            hideContent();
        }
    }, []);

    const isActive = () => moreBtn.current.classList.contains('active');

    const toggleShowMore = () => isActive() ? showContent() : hideContent();

    const showContent = () => updateContent('Show Less', 'show-switch show-less');

    const hideContent = () => updateContent('Show More', 'show-switch show-more');

    const updateContent = (title, classList) => {
        const showMoreBlock = document.querySelector('.show-switch');
        moreBtn.current.classList.toggle('active');
        moreBtn.current.innerHTML = title;
        showMoreBlock.className = classList;
    }

    return (
        <div className="show-more-switch">
            <button type="button" onClick={() => toggleShowMore()} ref={moreBtn}>
                {showMoreTitle}
            </button>
        </div>
    );
};

export default ShowMoreSwitch;
