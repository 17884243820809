import _ from "lodash";
import {formDataNormalizer} from "Services";

export const formData = data => {
    const dataReq = {
        name: _.get(data, "generalBlock.project"),
        logo: _.get(data, "generalBlock.logo"),
        customer: _.get(data, "generalBlock.customer"),
        description: _.get(data, "generalBlock.description"),
        customerNickname: _.get(data, "generalBlock.customerNickname"),
        sub_status: _.get(data, "generalBlock.status"),
        stage_gate: _.get(data, "generalBlock.stageGate"),
        business_status: _.get(data, "generalBlock.status"),
        los_date: _.get(data, "generalBlock.losDate"),
        material_specification: _.get(data, "generalBlock.materialSpecification"),
        performance_specification: _.get(data, "generalBlock.performanceSpecification"),
        show_full: _.get(data, "show_full"),
        application: _.get(data, "applicationBlock.application"),
        manager: _.get(data, "projectTeamAndContactsBlock.projectManager"),
        forecasts: _.get(data, "forecastBlock.forecast").map(elem => ({
            forecast: _.get(elem, "id"),
            percent_of_units: _.get(elem, "units"),
            date_moved_to_current: _.get(elem, "dateMovedToCurrent"),
            end_date: _.get(elem, "endDate"),
        })),

        // files: _.get(data, "files"),

        products: _.get(data, "productBlock.products", []).map(elem => ({
            grade: elem.grade,
            plant: elem.plant,
            actual_sale_price: elem.actualSale,
            rebate: elem.rebate,
            percent_of_product: elem.percentOfProduct,
        })),

        supply_chain: {
            tier_1: _.get(data, "supplyChainBlock.tier1"),
            tier_1_nickname: _.get(data, "supplyChainBlock.tier1Nickname"),
            tier_2: _.get(data, "supplyChainBlock.tier2"),
            tier_2_nickname: _.get(data, "supplyChainBlock.tier2Nickname"),
            molder: _.get(data, "supplyChainBlock.molder"),
            molder_nickname: _.get(data, "supplyChainBlock.molderNickname"),
        },

        volume: {
            part_weight: _.get(data, "projectVolumeBlock.partWeight"),
            parts_per_unit: _.get(data, "projectVolumeBlock.partsperUnit"),
        },
        internal_teams: _.get(data, "projectTeamAndContactsBlock.internalTeam")
            .filter(elem => elem.value !== "")
            .reduce((acc, elem) => [...acc, elem.value], []),
        contacts: _.get(data, "projectTeamAndContactsBlock.contacts")
            .filter(elem => elem.value !== "")
            .reduce((acc, elem) => [...acc, elem.value], []),
        replacementBusinessProjects: _.get(data, "replacementBusinessProjectsBlock.replacementBusinessProjectsBlock.projects") ? _.get(data, "replacementBusinessProjectsBlock.replacementBusinessProjectsBlock.projects")
            .filter(elem => elem.value !== "")
            .reduce((acc, elem) => [...acc, elem.value], []) : _.get(data, "replacementBusinessProjectsBlock.projects")
            .filter(elem => elem.value !== "")
            .reduce((acc, elem) => [...acc, elem.value], []),
    };

    return formDataNormalizer(dataReq);
};
