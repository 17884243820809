import Constants from "../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const addYearAction = (service, dispatch) => () => {
    dispatch({ type: Constants.ADD_YEAR });
};

export const removeYearAction = (service, dispatch) => year => {
    dispatch({ type: Constants.REMOVE_YEAR, data: { year } });
};

export const changeYearAction = (service, dispatch) => (year, oldYear) => {
    dispatch({ type: Constants.CHANGE_YEAR, data: { year, oldYear } });
};

export const changeDataAction = (service, dispatch) => (year, key, value) => {
    dispatch({ type: Constants.CHANGE_DATA, data: { year, key, value } });
};

export const submitFormAction = (service, dispatch) => data => {
    dispatch({ type: Constants.FORM_REQUESTED });

    return new Promise((resolve, reject) => {
        const req = service.save;
        req(service.formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const submitBudgetFormAction = (service, dispatch) => data => {
    dispatch({ type: Constants.FORM_REQUESTED });

    return new Promise((resolve, reject) => {
        const req = service.save;
        req(service.budgetFormData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};