import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from "Hoc/Template";
import {numberWithCommas, url} from "Services";
import {withServiceConsumer} from "Services/Context";
import {Modal} from "Templates/Modals";
import {MainButton, TabWrap} from "Templates/Content";
import {Title, WrapButtons, Total} from "Templates/Titles";
import {customTable} from "./Table/TabViewDetail";
import TableHeadColumnWrap from "./TableHeadColumnWrap";
import ContainerTable from "./Table/ContainerTable";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import FolderForm from "./FolderForm";
import UploadFileForm from "./UploadFileForm";
import TabCheck from "Templates/Tabs/TabСheck";
import Tabs from "Templates/Tabs/Tabs";
import Shared from "./Shared/Shared";
import {resetFilterWithoutFetch} from "../Actions/TableActions";
import {SharedFiles} from "./SharedFiles";
import {requestShareFiles} from "../Services/RequestService";

const defaultProps = {
    help: '',
    defaultFolder: false,
    items: [],
};

const propTypes = {
    defaultFolder: PropTypes.bool,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    onClickDelete: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    service: PropTypes.shape({
        customIcon: PropTypes.func.isRequired,
        customSharedIcon: PropTypes.func.isRequired,
        listTypes: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
    activeTab: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    fetchSharedItems: PropTypes.func.isRequired,
};

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const TabView = (
    {
        request, defaultFolder, fetchItems, fetchSharedItems, activeTab, onChangeTab, items, onClickDelete,
        t, title, help, service
    }) => {
    useEffect(() => {
        activeTab === 1 ? fetchSharedItems(request) : fetchItems(request)
    }, [activeTab]);

    const {customIcon, customSharedIcon, showSharedEditButton, showEditButton} = service;
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [folderForEdit, setFolderForEdit] = useState({});
    const [sharedModalData, setSharedModalData] = useState({
        isOpen: false,
        file: {}
    })

    const closeModal = () => {
        setFolderForEdit({});
        setShowCreateFolderModal(false);
        setShowUploadFileModal(false);
        setSharedModalData({
            isOpen: false,
            file: {}
        })
    };

    const callbackAfterRequestInModalWindow = res => {
        if (res !== undefined) {
            closeModal();
            fetchItems({});
        }
    };

    const openCreateFolderModal = () => {
        setShowCreateFolderModal(true);
    };

    const openUploadFileModal = () => {
        setShowUploadFileModal(true);
    };

    const _onClickShare = (item) => {
        setSharedModalData({
            isOpen: true,
            file: item
        })
    }
    const handleClickOnDownloadFile = (item) => {
        if (!item.can_edit) {
            alert('Contact owner to download file.');
            return;
        }

        const link = document.createElement("a");
        link.setAttribute('download', item.name);
        link.href = `/files/${item.id}/download`;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const handleClickEdit = ({id, name}) => {
        setFolderForEdit({
            id,
            folderName: name,
            oldFolderName: name,
        });
        openCreateFolderModal();
    };

    const handleReset = () => {
        url.clearHistory();
        return resetFilterWithoutFetch();
    }

    const handleShareFile = async (id, type, selectedUsers, sharedWithUsersList) => {
        await requestShareFiles({id, selectedUsers, sharedWithUsersList}).then(() => {
            alert(`${type} successfully shared!`)
        }).catch(() => {
            alert(`Error! Cannot shared ${type}.`)
        })
    }

    return (
        <>
            {showCreateFolderModal ? (
                <Modal overlayClassName="main-modal list-files-modal" onRequestClose={closeModal}>
                    <FolderForm
                        data={folderForEdit}
                        cbAfterRequest={callbackAfterRequestInModalWindow}
                        onClose={closeModal}
                    />
                </Modal>
            ) : null}
            {showUploadFileModal ? (
                <Modal overlayClassName="main-modal list-files-modal" onRequestClose={closeModal}>
                    <UploadFileForm
                        onClose={closeModal}
                        cbAfterRequest={callbackAfterRequestInModalWindow}
                    />
                </Modal>
            ) : null}
            {sharedModalData.isOpen ? <Modal className="main-modal shared-files__modal" onRequestClose={closeModal}>
                <SharedFiles t={t} data={sharedModalData.file} onClose={closeModal} shareFile={handleShareFile}/>
            </Modal> : null}
            <Title
                title={t("Files")}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                <WrapButtons>
                    {!defaultFolder && activeTab !== 1 && (
                        <>
                            <MainButton className="main-btn_white" onClick={openCreateFolderModal}>
                                {t("Create Folder")}
                            </MainButton>
                            <MainButton className="main-btn_primary" onClick={openUploadFileModal}>
                                {t("Upload File")}
                            </MainButton>
                        </>
                    )}
                </WrapButtons>
            </Title>
            <Tabs
                activeTab={activeTab}
                onClickTabItem={(index) => {
                    if (activeTab !== index) {
                        handleReset();
                        onChangeTab({ activeTab: index, pref: activeTab === 1 ? 'files' : 'shared_files' });
                    }
                }}
            >
                <TabCheck
                    label={t('My Files')}
                    permission='file_general'
                >
                    <TabWrap>
                        <TabViewDetail
                            showEditButton={showEditButton}
                            customIcon={customIcon(items)}
                            onClickShare={_onClickShare}
                            onClickCustom={handleClickOnDownloadFile}
                            onClickEdit={handleClickEdit}
                            onClickDelete={onClickDelete}
                        />
                    </TabWrap>
                </TabCheck>
                <TabCheck
                    label={t('Shared with Me')}
                    permission='file_general'
                >
                    <Shared
                        customIcon={customSharedIcon(items)}
                        onClickCustom={handleClickOnDownloadFile}
                    />
                </TabCheck>
            </Tabs>
        </>
    );
};

TabView.defaultProps = defaultProps;
TabView.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => ({
    items: getStoreItem(state, "items"),
    defaultFolder: getStoreItem(state, "default_folder"),
    title: getStoreItem(state, "pagination.total"),
    help: getStoreItem(state, "help"),
    activeTab: state.listState.activeTab,
    request: {
        filter: state.listState.filter,
        sort: state.listState.sort,
    },
});

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        fetchSharedItems: getActionStore("fetchSharedItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
