import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import BarChart from "./Type/Bar/BarChart";
import LineChart from "./Type/Line/LineChart";

const propTypes = {
    chartType: PropTypes.oneOf(["line", "bar"]).isRequired,
    service: PropTypes.shape({ getChartData: PropTypes.func.isRequired, getStoreItem: PropTypes.func.isRequired })
        .isRequired,
};

const DataDashboardCard = ({ pref, chartType, service: { getChartData, getStoreItem } }) => {
    return (
        <div className={`${pref}__card-data`}>
            {chartType === "line" && (
                <ServiceProvider value={{ getChartData, getStoreItem }}>
                    <LineChart />
                </ServiceProvider>
            )}
            {chartType === "bar" && (
                <ServiceProvider value={{ getChartData, getStoreItem }}>
                    <BarChart />
                </ServiceProvider>
            )}
        </div>
    );
};

DataDashboardCard.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        chartType: getStoreItem(state, "chartType"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DataDashboardCard);
