import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {withTagDefaultProps} from 'Hoc/Template';
import {url} from 'Services';

const defaultProps = {
    isActiveTab: true,
    titleMessage: '',
    counter: 0,
};

const propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    counter: PropTypes.any.isRequired,
    activeTab: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    isActiveTab: PropTypes.bool,
    titleMessage: PropTypes.string,
};

const Tab = ({ label, counter, onClick, pref, activeTab, index, isActiveTab, titleMessage }) => {
    const defClick = e => {
        e.preventDefault();

        if (isActiveTab) {
            url.addToggle('activeTab', index);
            window.history.replaceState(null, null, index ? (`?${url.getParams()}`) : url.hrefWithoutParams());
        }

        onClick(index, e);
    };

    let className = `${pref}__tabs-item main-content__tabs-item`;

    if (index === activeTab) {
        className += ' current-item';
    }

    return (
        <li className={className}>
            <a
                href="#"
                title={titleMessage}
                className={`${pref}__tabs-link main-content__tabs-link`}
                onClick={defClick}
            >
                {label}
                {counter ? counter !== 0 && <span className="tab-counter">{counter}</span> : null}
            </a>
        </li>
    );
};

Tab.defaultProps = defaultProps;
Tab.propTypes = propTypes;

export default compose(withTagDefaultProps())(Tab);
