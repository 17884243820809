import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import ReactResizeDetector from "react-resize-detector";
import {BlockWrap} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {maxSizeCanvas, numberWithCommas} from "Services";
import {weightUnitName} from 'Services/weightUnitName';

import Chart from "chart.js";
import {generateDatasets, getLabels} from "./utils";

const propTypes = {
    weightUnit: PropTypes.objectOf(PropTypes.number).isRequired,
    graphV2: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedValue: PropTypes.string.isRequired,
};

class Graph extends Component {
    // TODO: this is custom tooltip drawing. May be will be apply after (mvp) client approve
    alwaysShowTooltip = {
        id: 'alwaysShowTooltip',
        afterDraw(chart, args, options) {
            const { ctx } = chart;
            ctx.save()

            chart.data.datasets.forEach((dataset, i) => {
                chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                    const { x, y } = datapoint.tooltipPosition()

                    const text = `${numberWithCommas(dataset.data[index], 0)}`
                    const textWidth = ctx.measureText(text).width

                    ctx.fillStyle = 'transparent'

                    ctx.fillRect(x - ((textWidth + 10) / 2), y - 25, textWidth + 10, 20)

                    ctx.beginPath()
                    ctx.moveTo(x, y)
                    ctx.lineTo(x - 15, y - 15)
                    ctx.lineTo(x + 15, y + 15)
                    ctx.fill()

                    ctx.font = '12px Arial'
                    ctx.fillStyle = 'black'

                    switch (dataset.order) {
                        case 4:
                            ctx.fillStyle = 'rgba(100, 120, 142, 1)'
                            if (dataset.data[index] === dataset.data[0]) {
                                ctx.fillText(text, x, y - 80)
                                return
                            }
                            ctx.fillText(text, x - (textWidth / 2), y - 80)
                            break
                        case 3:
                            ctx.fillStyle = 'rgba(204, 121, 0, 1)'
                            if (dataset.data[index] === dataset.data[0]) {
                                ctx.fillText(text, x, y - 80)
                                return
                            }
                            ctx.fillText(text, x - (textWidth / 2), y - 60)
                            break
                        case 2:
                            ctx.fillStyle = 'rgba(25, 96, 169, 1)'
                            if (dataset.data[index] === dataset.data[0]) {
                                ctx.fillText(text, x, y - 80)
                                return
                            }
                            ctx.fillText(text, x - (textWidth / 2), y - 40)
                            break
                        case 1:
                            ctx.fillStyle = 'rgba(19, 87, 68, 1)'
                            if (dataset.data[index] === dataset.data[0]) {
                                ctx.fillText(text, x, y - 80)
                                return
                            }
                            ctx.fillText(text, x - (textWidth / 2), y - 20)
                            break
                        default:
                            ctx.fillStyle = 'black'
                            if (dataset.data[index] === dataset.data[0]) {
                                ctx.fillText(text, x, y - 80)
                                return
                            }
                            ctx.fillText(text, x - (textWidth / 2), y - 10)
                    }

                    ctx.restore()
                })
            })
        },
    }
    constructor(props) {
        super(props);
        this.ChartV2 = {};
        this.resizeTimerId = 0;
        this.state = {
            width: 0,
            height: 0,
        };
    }

    componentDidMount = () => {
        const chartBlock = document.getElementsByClassName("chart")[0];
        this.setState(
            {
                width: chartBlock.clientWidth,
                height: chartBlock.clientHeight,
            },
            this.setChartConfig,
        );
    };

    componentDidUpdate(prevProps) {
        const { graphV2, selectedValue, weightUnit } = this.props;

        if (JSON.stringify(graphV2) !== JSON.stringify(prevProps.graphV2) || selectedValue !== prevProps.selectedValue){
            const width = this.getChartWidth();
            const height = this.getChartHeight();

            this.setState(
                {
                    width,
                    height
                },
                () => {
                    this.ChartV2.width = width;
                    this.ChartV2.height = height;
                    this.ChartV2.options.scales.xAxes[0].labels = getLabels(graphV2);
                    this.ChartV2.options.scales.yAxes[0].ticks.callback = value => `${numberWithCommas(value, 0)} ${weightUnitName(weightUnit.weightUnit)}`;
                    this.ChartV2.options.tooltips.callbacks.label = (tooltipItem, data) => {
                        const name = data.datasets[tooltipItem.datasetIndex].label || "";
                        return `${name}: ${numberWithCommas(tooltipItem.value, 0)} ${weightUnitName(weightUnit.weightUnit)}`;
                    };
                    this.ChartV2.data.datasets = generateDatasets(graphV2, selectedValue);
                    this.ChartV2.update();
                },
            );
        }
    }
    setChartConfig = () => {
        const { graphV2, selectedValue, weightUnit } = this.props;

        const canvas = document.getElementById("ChartV2");
        const ctx = canvas.getContext("2d");
        let initialDatasets = [];
        this.ChartV2 = new Chart(ctx, {
            type: "line",
            data: {
                datasets: generateDatasets(graphV2, selectedValue),
            },
            plugins: [
                // this.alwaysShowTooltip,
                {
                    beforeInit: function(chart, options) {
                        chart.legend.afterFit = function() {
                            this.height = this.height + 20;
                        };
                        if (chart && chart.data.datasets.length) {
                            const datasets = chart.data.datasets.map((dataset) => {
                                return dataset.data
                            })

                            initialDatasets = datasets
                        }
                    }
                }
            ],
            options: {
                devicePixelRatio: 1,
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 20,
                        right: 44,
                        bottom: 20,
                        left: 24,
                    },
                },
                legend: {
                    display: true,
                    labels: {
                        filter: (legendItem) => {
                            return legendItem.text !== null && legendItem.text !== undefined;
                        },
                        generateLabels: (chart) => {
                            const {data} = chart;
                            if (data.datasets.length) {
                                return data.datasets.map((dataset, i) => {
                                    return {
                                        text: ['Lead', 'Opportunity', 'Target', 'Current'][i],
                                        fillStyle: dataset.backgroundColor || data.options.elements.line.backgroundColor,
                                        hidden: !chart.isDatasetVisible(i),
                                        lineCap: dataset.borderCapStyle,
                                        lineDash: dataset.borderDash,
                                        lineDashOffset: dataset.borderDashOffset,
                                        lineJoin: dataset.borderJoinStyle,
                                        lineWidth: dataset.borderWidth,
                                        strokeStyle: dataset.borderColor,
                                        pointStyle: dataset.pointStyle,
                                        datasetIndex: i,
                                    };
                                });
                            }

                            return [];
                        },
                    },
                    onClick: (e, legendItem) => {
                        const index = legendItem.datasetIndex;
                        const ci = this.ChartV2;
                        const meta = ci.getDatasetMeta(index);

                        if (meta.hidden === null) {
                            meta.hidden = !ci.data.datasets[index].hidden
                            ci.data.datasets[index].data = []
                        } else {
                            meta.hidden = null
                            ci.data.datasets[index].data = initialDatasets[index]
                        }

                        ci.update();
                    }
                },
                tooltips: {
                    enabled: true,
                    callbacks: { label: (tooltipItem, data) => {
                            const name = data.datasets[tooltipItem.datasetIndex].label || "";
                            return `${name}: ${numberWithCommas(tooltipItem.value, 0)} ${weightUnitName(weightUnit.weightUnit)}`;
                        } },
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                drawTicks: false,
                            },
                            ticks: {
                                padding: 20,
                                callback: value => `${numberWithCommas(value, 0)} ${weightUnitName(weightUnit.weightUnit)}`,
                                min: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                drawTicks: false,
                                drawOnChartArea: true,
                            },
                            lineHeight: 0,
                            labels: getLabels(graphV2),
                            ticks: {
                                padding: 20,
                            },
                        },
                    ],
                },
            },
        });
    };

    // getChartWidth = () => {
    //     const { graphV2 } = this.props;
    //     const data = graphV2
    //     const years = Object.keys(data);
    //     const items = _.get(data, `[${_.get(years, "[0]", "")}]`, []);
    //     let width = document.getElementsByClassName("chart")[0].clientWidth;
    //     if (years.length * items.length >= 25) {
    //         const maxItems = 5;
    //         width += 16 * Math.abs(maxItems - items.length) * years.length * items.length;
    //     }
    //
    //     return maxSizeCanvas(width);
    // };

    getChartWidth = () => {
        if (typeof document === 'undefined') {
            return 0;
        }
        const width = document.querySelector('.chart').clientWidth || 0

        return maxSizeCanvas(width);
    };

    getChartHeight = () => {
        if (typeof document === 'undefined') {
            return 0;
        }
        const height = document.querySelector('.chart').clientHeight || 0

        return height;
    };

    onResize = () => {
        new Promise(resolve => {
            window.requestAnimationFrame(() => {
                if (this.resizeTimerId) {
                    clearTimeout(this.resizeTimerId);
                }
                this.resizeTimerId = setTimeout(() => resolve(), 200);
            });
        }).then(() => {
            const width = this.getChartWidth();
            const height = this.getChartHeight();

            this.setState(
                {
                    width,
                    height,
                },
                () => {
                    this.ChartV2.width = width;
                    this.ChartV2.height = height;
                    this.ChartV2.update();
                },
            );
        });
    };

    render() {
        const { width, height } = this.state;
        return (
            <BlockWrap className="chart">
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
                    <div className="chartAreaWrapper typeThree" style={{ width, height }}>
                        <canvas id="ChartV2" width={width} height={height} />
                    </div>
                </ReactResizeDetector>
            </BlockWrap>
        );
    }
}

Graph.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        weightUnit: state.weightUnitState,
        selectedValue: getStoreItem(state, "selectedValue"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Graph);
