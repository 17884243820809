import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockTitle, Block, BlockShadow } from "Templates/Form";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { CustomSimpleTable } from "Hoc/Widgets/Table/SimpleTableContainer";
import ApplicationTableBodyColumn from "./Table/Application/TableBodyColumn";
import ApplicationTableHeadColumn from "./Table/Application/TableHeadColumn";
import ApplicationTableFooterColumn from "./Table/Application/TableFooterColumn";
import * as _applicationService from "../../Services/Table/ApplicationService";

const ApplicationTable = CustomSimpleTable()(ApplicationTableHeadColumn)()(ApplicationTableBodyColumn)()(ApplicationTableFooterColumn);

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

const ApplicationBlock = (
    {
        service: { getStoreItem, getActionStore },
        t
    }) => {

    const applicationService = {
        ..._applicationService,
        getStoreItem,
        getActionStore
    };

    return (
        <>
            <BlockTitle className="market--segment__tooltip">
                {t("Legacy Market Segment")}
            </BlockTitle>
            <Block>
                <BlockShadow>
                    <ServiceProvider value={applicationService}>
                        <ApplicationTable tableClassName="layout-fixed"/>
                    </ServiceProvider>
                </BlockShadow>
            </Block>
        </>
    );
};

ApplicationBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(ApplicationBlock);
