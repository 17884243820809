import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withServiceConsumer } from 'Services/Context';
import { withTagDefaultProps } from 'Hoc/Template';
import { Block, BlockWrap, Input, WrapInput } from 'Templates/Form';

const propTypes = {
    name: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    service: PropTypes.shape({
        industryOptions: PropTypes.arrayOf(
            PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
        ).isRequired,
    }).isRequired,
};

const GeneralBlock = ({
                          name,
                          industry,
                          segment,
                          onChange,
                          t,
                          service: { industryOptions },
                      }) => {
    return (
        <>
            <Block>
                <BlockWrap>
                    <WrapInput isRequired label={ t('Code Name') } name="name">
                        <Input
                            placeholder="Code Name"
                            value={ name }
                            onChange={ value => onChange('name', value) }
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired label={ t('Project Market Segment') } name="industry">
                        <Input type="select"
                               options={ industryOptions }
                               placeholder="Select"
                               value={ industry }
                               onChange={ value => onChange('industry', value) }
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput label={ t('Project Segment') } name="segment">
                        <Input
                            placeholder="Segment"
                            value={ segment }
                            onChange={ value => onChange('segment', value) }
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap/>
            </Block>
        </>
    );
};

GeneralBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, 'name'),
        industry: getStoreItem(state, 'industry'),
        segment: getStoreItem(state, 'segment'),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore('onChange', service, dispatch)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(GeneralBlock);
