import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { Modal } from "Templates/Modals";
import { MainButton, ContentWrap, ContentWrapCol, TabWrap } from "Templates/Content";
import { Title, WrapButtons, Total } from "Templates/Titles";
import { ContainerTable } from "Hoc/Widgets";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import InfoWrap from "./InfoWrap";
import FolderForm from "./FolderForm";
import UploadFileForm from "./UploadFileForm";
import Breadcrumbs from "./Breadcrumbs";

const defaultProps = {
    defaultFolder: 0,
    items: [],
};

const propTypes = {
    defaultFolder: PropTypes.number,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.number.isRequired,
    canEdit: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
    onClickDelete: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    service: PropTypes.shape({
        customIcon: PropTypes.func.isRequired,
        listTypes: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
};

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TabTable);

class TabView extends React.Component {
    constructor(props) {
        super(props);
        this.folderForEdit = {};
        this.defaultState = {
            showCreateFolderModal: false,
            showUploadFileModal: false,
        };
        this.state = {
            ...this.defaultState,
        };
    }

    openCreateFolderModal = () => {
        this.setState({
            showCreateFolderModal: true,
        });
    };

    openUploadFileModal = () => {
        this.setState({
            showUploadFileModal: true,
        });
    };

    closeModal = () => {
        this.folderForEdit = {};
        this.setState({
            ...this.defaultState,
        });
    };

    callbackAfterRequestInModalWindow = res => {
        const { fetchItems } = this.props;

        if (res !== undefined) {
            this.closeModal();
            fetchItems({});
        }
    };

    handleClickOnDownloadFile = (item) => {
        if (!item.can_edit) {
            alert('Contact owner to download file.');
            return;
        }

        const link = document.createElement("a");
        link.setAttribute('download', item.name);
        link.href = `/files/${item.id}/download`;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    handleClickEdit = ({ id, name }) => {
        this.folderForEdit = {
            id,
            folderName: name,
            oldFolderName: name,
        };
        this.openCreateFolderModal();
    };

    handleClickEditCurrentFolder = () => {
        const { id, name } = this.props;
        this.handleClickEdit({ id, name });
    };

    render() {
        const {
            defaultFolder,
            items,
            onClickDelete,
            t,
            title,
            canEdit,
            service: { customIcon },
        } = this.props;
        const { showCreateFolderModal, showUploadFileModal } = this.state;

        return (
            <ContentWrap>
                <InfoWrap onClickEdit={canEdit ? this.handleClickEditCurrentFolder : null} canEdit={canEdit}/>
                <ContentWrapCol>
                    {showCreateFolderModal ? (
                        <Modal onRequestClose={this.closeModal}>
                            <FolderForm
                                data={this.folderForEdit}
                                cbAfterRequest={this.callbackAfterRequestInModalWindow}
                                onClose={this.closeModal}
                            />
                        </Modal>
                    ) : <div/>}
                    {showUploadFileModal ? (
                        <Modal onRequestClose={this.closeModal}>
                            <UploadFileForm
                                onClose={this.closeModal}
                                cbAfterRequest={this.callbackAfterRequestInModalWindow}
                            />
                        </Modal>
                    ) : <div/>}
                    <Title
                        title={t("Files")}
                        total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
                    >
                        <WrapButtons>
                            {!defaultFolder && canEdit === 10 && (
                                <MainButton className="main-btn_white" onClick={this.openCreateFolderModal}>
                                    {t("Create Folder")}
                                </MainButton>
                            )}
                            {!defaultFolder && canEdit === 10 && (
                                <MainButton className="main-btn_primary" onClick={this.openUploadFileModal}>
                                    {t("Upload File")}
                                </MainButton>
                            )}
                        </WrapButtons>
                    </Title>
                    <Breadcrumbs/>
                    <TabWrap>
                        <TabViewDetail
                            customIcon={customIcon(items)}
                            showEditButton={this.showEditButton}
                            onClickCustom={this.handleClickOnDownloadFile}
                            onClickEdit={this.handleClickEdit}
                            onClickDelete={onClickDelete}
                        />
                    </TabWrap>
                </ContentWrapCol>
            </ContentWrap>
        );
    }
}

TabView.defaultProps = defaultProps;
TabView.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    id: getStoreItem(state, "view.id"),
    name: getStoreItem(state, "view.name"),
    defaultFolder: getStoreItem(state, "view.default_folder"),
    canEdit: getStoreItem(state, "view.can_edit"),
    items: getStoreItem(state, "items"),
    title: getStoreItem(state, "pagination.total"),
});

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
