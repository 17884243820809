import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {BlockWrap, Input, WrapInput} from "Templates/Form";
import {ComoppsEditor} from "Templates/Input";

const propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const MainBlock = ({name, text, onChange, t}) => {
    return (
        <BlockWrap>
            <WrapInput label={t("Name")} name="name" isRequired>
                <Input placeholder={t("Name")} value={name} onChange={value => onChange("name", value)} />
            </WrapInput>
            <WrapInput label={t("Copy")} name="description" isRequired>
                <ComoppsEditor text={text} onChange={value => onChange("text", value)}/>
            </WrapInput>
        </BlockWrap>
    );
};

MainBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        text: getStoreItem(state, "text"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MainBlock);
