import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import {numberWithCommas, formatDate, showMessage, url} from "Services";
import { withTagDefaultProps, CheckPermission } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import {MainButton, TabWrap} from "Templates/Content";
import { Title, WrapButtons, Total } from "Templates/Titles";
import TabViewIndex from "Templates/Tabs/DefaultWrap/TabViewIndex";
import ResetTableFilters from "Services/ResetTableFilters/ResetTableFilters";

const propTypes = {
    title: PropTypes.number.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TabView = ({ onClickEdit, onClickCustom, onClickDelete, handleReset, t, title, updated_date, help, permissions }) => {
    const lastUpdate = !updated_date ? null :
        (
            <>
                <div className="break" />
                <div className="last-update">{`${t("Last SAP Update")} - ${formatDate(updated_date)}`}</div>
            </>
        );

    const onAddNewGrade = () => {
        if (!permissions.includes('admin')) {
            showMessage('To add a new product email support@comopps.com and include the name of the product in the email. Please use the subject heading: "Add New Product"');
            return;
        }
        url.redirect('/product/product-finder/create');
    }

    return (
        <>
            <Title
                title={t("Grades")}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                <CheckPermission>
                    <WrapButtons>
                      <ResetTableFilters handleReset={handleReset} />

                        <MainButton onClick={onAddNewGrade} className="main-btn_primary">
                            {t("Add New Product")}
                        </MainButton>
                    </WrapButtons>
                </CheckPermission>
                {lastUpdate}
            </Title>

            <TabWrap>
                <TabViewIndex customIcon={'icon_external_link'} onClickCustom={onClickCustom} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        title: state.listState.pagination.total,
        help: state.listState.help,
        updated_date: state.listState.updated_date,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TabView);
