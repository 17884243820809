import React from "react";

const Preloader = () => (
    <div className="preloader">
        <svg className="preloader__icon" viewBox="0 0 56 56">
            <path d="M33.12 51.874c0 2.277-1.886 4.126-4.216 4.126-2.329 0-4.215-1.849-4.215-4.126 0-2.278 1.886-4.127 4.215-4.127 2.33 0 4.216 1.849 4.216 4.127zM28.904 0c-2.659 0-4.817 2.113-4.817 4.716s2.158 4.716 4.817 4.716c2.66 0 4.818-2.113 4.818-4.716S31.564 0 28.904 0zm24.088 25.347c1.66 0 3.008 1.323 3.008 2.948 0 1.627-1.349 2.947-3.008 2.947-1.662 0-3.009-1.318-3.009-2.947 0-1.625 1.347-2.948 3.009-2.948zM0 28.295C0 30.9 2.158 33.01 4.817 33.01c2.66 0 4.818-2.11 4.818-4.716 0-2.603-2.158-4.716-4.818-4.716C2.158 23.579 0 25.692 0 28.295zM45.765 9.432c1.328 0 2.41 1.058 2.41 2.357 0 1.304-1.082 2.363-2.41 2.358-1.327 0-2.408-1.054-2.408-2.353 0-1.304 1.081-2.362 2.408-2.362zm0 31.831c1.995 0 3.613 1.585 3.613 3.537s-1.618 3.54-3.617 3.537c-1.99 0-3.609-1.582-3.609-3.535 0-1.954 1.619-3.539 3.613-3.539zM12.044 7.073c2.659 0 4.817 2.113 4.817 4.716 0 2.604-2.158 4.716-4.82 4.716-2.657 0-4.815-2.11-4.815-4.716 0-2.605 2.158-4.715 4.818-4.715zm0 33.011c2.659 0 4.817 2.113 4.817 4.716s-2.158 4.716-4.82 4.716c-2.657 0-4.815-2.11-4.815-4.716 0-2.605 2.158-4.716 4.818-4.716z" />
        </svg>
    </div>
);

export default Preloader;
