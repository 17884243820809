export const productTypeProps = {
    "1": "Assembly",
    "2": "Material",
    "3": "Part",
};

export const claassificationProps = {
    "1": "Ceramic",
    "2": "Composite",
    "3": "Metal",
    "4": "Other",
    "5": "Polymer",
};

export const groupOptions = {
    "1": "APO",
    "2": "BIO",
    "3": "OTH",
    "4": "PVC",
    "5": "RTP",
    "6": "SLU",
    "7": "TPC",
    "8": "TPO",
    "9": "TPS",
    "10": "TPV",
};
