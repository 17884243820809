import Constants from "../Constants";

export const initState = {
    activeSubTab: {},
};

export const reducer = (state, action) => {
    const { data } = action;
    const items = data && data[0] ? data[0].items : [];

    switch (action.type) {
        case Constants.CHANGE_ACTIVE_SUBTAB: {
            return {
                ...state,
                activeSubTab: items,
            };
        }

        case Constants.FETCH_TECHNICAL_ITEMS_REQUESTED: {
            if (items.length > 0) {
                const datasheetData = items[0].datasheet_data || items[0].process_data || items[0].design_data;

                return {
                    ...state,
                    items,
                    activeSubTab: items[0],
                    view: {
                        ...state.view,
                        datasheet: {
                            ...state.view.datasheet,
                            contacts: datasheetData.contacts,
                            colors: datasheetData.colors,
                            countries: datasheetData.countries,
                            paintable: datasheetData.paintable,
                            molded: datasheetData.molded,
                            is_boundable: datasheetData.is_boundable,
                            locations: datasheetData.locations,
                            application_codes: datasheetData.application_codes,
                            market_segments: datasheetData.market_segments,
                            segments: datasheetData.segments,
                            sub_segments: datasheetData.sub_segments
                        }
                    }
                };
            }

            return {
                ...state,
                items: [],
                activeSubTab: {},
                view: {
                    ...state.view,
                    datasheet: {
                        contacts: [],
                        colors: [],
                        countries: [],
                        paintable: null,
                        molded: null,
                        is_boundable: null,
                        locations: [],
                        application_codes: [],
                        market_segments: [],
                        segments: [],
                        sub_segments: []
                    }
                }
            };
        }

        default:
            return state;
    }
};
