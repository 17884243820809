import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Div, Ul } from "Templates/Default";
import InfoBlock from "./Items/InfoBlock";
import { weightUnitName } from 'Services/weightUnitName';

const defaultProps = {
    showRevenue: true,
    showVolume: true,
    showGrossMargin: true,
    revenueText: "Revenue",
    volumeText: "Volume",
    grossMarginText: "Gross Margin",
};

const propTypes = {
    showRevenue: PropTypes.bool,
    revenueText: PropTypes.string,
    revenue: PropTypes.string.isRequired,
    showVolume: PropTypes.bool,
    volumeText: PropTypes.string,
    volume: PropTypes.string.isRequired,
    showGrossMargin: PropTypes.bool,
    grossMarginText: PropTypes.string,
    grossMargin: PropTypes.string.isRequired,
};

const InfoPanelBlock = ({
    showRevenue,
    revenue,
    revenueText,
    showVolume,
    volume,
    volumeText,
    showGrossMargin,
    grossMargin,
    grossMarginText,
    weightUnit,
    t,
}) => {
    return (
        <Div className="data">
            <Ul className="data__list">
                {showRevenue ? (
                    <InfoBlock
                        icon="icon_revenue"
                        caption={t(revenueText)}
                        title={`$${numberWithCommas(revenue, 0)}`}
                    />
                ) : null}
                {showVolume ? (
                    <InfoBlock icon="icon_volume" caption={t(volumeText)} title={`${numberWithCommas(volume, 0)} ${weightUnitName(weightUnit && weightUnit.weightUnit)}`} />
                ) : null}
            </Ul>
        </Div>
    );
};

InfoPanelBlock.defaultProps = defaultProps;
InfoPanelBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(InfoPanelBlock);
