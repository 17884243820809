import React, {useEffect, useState} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {getSharedPublications, setSharedPublications} from "../../../Services/RequestService";
import {MainButtonLink} from "Templates/Content";
import {Modal} from "Templates/Modals";
import {SharingPublicationsModal} from "./SharingPublicationsModal";

const SharingPublications = ({ id, company, t }) => {
    const [sharingPublicationsData, setSharingPublicationsData] = useState({
        receive_from: [],
        shared_with: []
    })
    const [showModal, setShowModal] =  useState(false)

    useEffect(() => {
        getItems();
    }, [])

    const getItems = async () => {
        const data = await getSharedPublications(`/admin/company-sharing-data/${id}`);
        if (data && data.length) {
            setSharingPublicationsData(data[0].items)
        }
    }

    const handleShare = (sharedItems) => {
        setSharedPublications(`/admin/company-sharing-data/${id}/edit`, { type: 1, shared_with: sharedItems }).then(() => {
            getItems();
            setShowModal(false);
        })
    }

    return (
        <div className="sharing-publications">
            <div className="sharing-publications__item">
                <div className="sharing-publications__item-title">
                    <h3>{t(`${company} is sharing Publications with`)}</h3>
                    <MainButtonLink
                        className="main-btn_primary"
                        onClick={() => setShowModal(true)}
                    >
                        {t("Edit Sharing")}
                    </MainButtonLink>
                </div>
                <div className="sharing-publications__item-table">
                    <div className="sharing-publications__item-table-head">
                        <p>{t("Organization Name")}</p>
                        <p></p>
                    </div>
                    <div className={`sharing-publications__item-table-body ${sharingPublicationsData.shared_with.length > 0 ? '' : 'empty'}`}>
                        {sharingPublicationsData.shared_with.length > 0 ? sharingPublicationsData.shared_with.map((item) => {
                            return <div key={item.id} className="sharing-publications__item-table-body">
                                <a href={`/admin/companies/${item.id}`} target="_blank">{item.name}</a>
                            </div>
                        }) : <p className="sharing-publications-empty">No shared Publications yet</p>}
                    </div>
                </div>
            </div>
            <div className="sharing-publications__item">
                <div className="sharing-publications__item-title">
                    <h3>{t(`${company} is receiving Publications from`)}</h3>
                </div>
                <div className="sharing-publications__item-table-head">
                    <p>{t("Organization Name")}</p>
                    <p></p>
                </div>
                <div className={`sharing-publications__item-table-body ${sharingPublicationsData.receive_from.length > 0 ? '' : 'empty'}`}>
                    {sharingPublicationsData.receive_from.length > 0 ? sharingPublicationsData.receive_from.map((item) => {
                        return <div key={item.id} className="sharing-publications__item-table-body">
                            <a href={`/admin/companies/${item.id}`} target="_blank">{item.name}</a>
                        </div>
                    }) : <p className="sharing-publications-empty">No receiving Publications yet</p>}
                </div>
            </div>
            {showModal ? <Modal onRequestClose={() => setShowModal(false)}>
                <SharingPublicationsModal t={t} id={id} sharedWithList={sharingPublicationsData.shared_with} onShare={handleShare} onCloseModal={() => setShowModal(false)}/>
            </Modal> : null}
        </div>
    );
};

SharingPublications.propTypes = {
    id: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        company: getStoreItem(state, "view.name")
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(SharingPublications);
