import React from 'react';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import PropTypes from "prop-types";
import _ from "lodash";
import {Modal} from "Templates/Modals";
import {formatDate} from "Services";
import {InputFilter} from "Templates/Table/Filters";
import {Label} from "Templates/Form";
import HeaderDatasheet from "../Technical/HeaderDatasheet";
import Content from "../Technical/Content";
import NotFoundDatasheet from "./NotFoundDatasheet";
import CopyForm from "../Technical/CopyForm";
import DownloadReport from "../../Modals/DownloadReport";

const Design = (
    {
        id, items, activeSubTab, fetchSubTabAction,
        fetchItems, showModal, handleCloseModal, form, callback
    }
) => {
    const designData = _.get(activeSubTab, 'design_data');

    React.useEffect(() => {
        fetchItems();
    }, [])

    const onChangeForm = (key, value) => {
        form = {
            ...form,
            [key]: value || []
        };

        callback(key, value, form);
    }

    return (
        <>
            {showModal && (
                <Modal onRequestClose={handleCloseModal}>
                    <CopyForm
                        callback={onChangeForm}
                        datasheetId={activeSubTab.id}
                        gradeId={id}
                        form={form}
                        onClose={handleCloseModal}
                    />
                </Modal>
            )}

            {items && items.length > 0 && (
                <div className={'flex'}>
                    <Label label="Select"/>
                    <span className={'publications-counter flex'}>({items.length})</span>
                </div>
            )}
            <div className="tds-list-wrap">
                <div className="shared-publications-sub-tabs-wrap">
                    {
                        (items && items.length) ?
                            (
                                <InputFilter
                                    type="select"
                                    value={activeSubTab.id}
                                    options={[...items.map((val) => {
                                        return { value: val.id, label: val.custom_name }
                                    })]}
                                    onChange={e => {
                                        fetchSubTabAction(items.find(x => x.id === e));
                                    }}
                                />
                            ) : <div/>
                    }
                    <DownloadReport label={'Guide'}/>
                </div>
            </div>

            <div className={`grade-view__wrap main-wrap-block technical-wrap ${designData ? '' : 'not-available'}`}>
                {!designData || (Array.isArray(designData) && !designData.length) ? (
                    <NotFoundDatasheet title="Guide"/>
                ) : (
                    <>
                        <HeaderDatasheet datasheetData={designData}/>
                        {designData.contents[0] && designData.contents[0].description && (
                            <Content data={designData.contents[0].description}/>
                        )}
                        {designData.copy && <Content data={designData.copy}/>}
                        <Content data={`Revised ${formatDate(designData.date_updated)}`}/>
                    </>
                )}
            </div>
        </>
    );
};

Design.propTypes = {
    id: PropTypes.string.isRequired,
    fetchSubTabAction: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
    activeSubTab: PropTypes.objectOf(PropTypes.any),
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired
    }).isRequired,
    showModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
};

Design.defaultProps = {
    items: [],
    activeSubTab: {}
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchSubTabAction: getActionStore("addActiveSubTabAction", service, dispatch),
        fetchItems: getActionStore("getTechnicalItems", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        callback: getActionStore("onChangeForm", service, dispatch),
    };
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeSubTab: getStoreItem(state, "activeSubTab"),
        items: getStoreItem(state, "items"),
        showModal: getStoreItem(state, "showModal"),
        form: { ...getStoreItem(state, "form", true) },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Design);