import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const CheckboxLabel = ({ pref, label, t }) => (
    <span className={`${pref}__form-checkbox-label-text main-content__checkbox-label-text`}>{t(label)}</span>
);

CheckboxLabel.propTypes = {
    label: PropTypes.string.isRequired,
};
export default compose(withTagDefaultProps())(CheckboxLabel);
