import * as main from "../../../Services";

export default {
    ...main,
    save: solutionId => main.requestCreate(`/admin/solutions/${solutionId}/datasheets/add`),
    saveColor: main.requestCreate(`/product/product-finder/color/add`),
    saveContentTemplate: main.requestCreate(`/product/product-finder/content-template/add`),
    editContentTemplate: templateId => main.requestCreate(`/product/product-finder/content-template/${templateId}/edit`),
    requestMaterialProperty: main.requestData(),
    requestLegal: main.requestData(),
    requestContentTemplate: main.requestData(),
    getStoreItem: main.getStoreItem("createState"),
};
