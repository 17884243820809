import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "./EventsService";
import {isSapImported} from "Services/enum";

export const getTableLabel = key => {
    const getDunsNumberData = () => {
        return 'D-U-N-S&#174; Number'
    }

    const tableLabel = {
        name: "Company Full Name",
        short_name: "Company Name",
        customer_code: "Customer Code",
        legacy_customer_code: "Customer Legacy No",
        duns_number: getDunsNumberData(),
        nicknames: "Nickname",
        customer_owner: "Customer Owner",
        phone: "Phone Number",
        project_count: "Project(s)",
        invoice_count: "Invoice(s)",
        notes: "Notes",
        email: "Email",
        nda: "NDA",
        slug: "Customer ID",
        supply_chain: "Supply Chain",
        address1: "Address",
        city: "City",
        region_code: "Region/State ABR",
        state: "Region/State",
        district: "District/County",
        zip: "Zip Code",
        country: "Country",
        time_zone: "Time Zone",
        corporate_group: "Corporate Group",
        changed_date: "Date Changed",
        updated_date: "Date Updated in ComOpps",
        created_code_date: "Date Created/Extended",
        created_system_date: "Date Created",
        owner: "Owner",
        marked_deletion: "Deletion Marker",
        sap_value: "SAP Import",
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        short_name: "Search",
        customer_code: "Search",
        legacy_customer_code: "Search",
        duns_number: "Search",
        slug: "Search",
        nicknames: "Search",
        customer_owner: "Search",
        phone: "Search",
        project_count: "Search",
        invoice_count: "Search",
        notes: "Search",
        email: "Search",
        nda: "NDA",
        supply_chain: "Supply Chain",
        address1: "Search",
        city: "Search",
        region_code: "Search",
        state: "Search",
        district: "Search",
        zip: "Search",
        country: "Search",
        time_zone: "Search",
        corporate_group: "Search",
        changed_date: "Date Changed",
        updated_date: "Date Updated in ComOpps",
        created_code_date: "Date Created/Extended",
        created_system_date: "Date Created",
        owner: "Search",
        marked_deletion: "Select",
        sap_value: "Select",
    };

    const supplyChainOptions = [
        {
            value: "",
            label: "All",
        },
        {
            value: "1",
            label: "OEM Tier 1",
        },
        {
            value: "2",
            label: "OEM Tier 2",
        },
        {
            value: "3",
            label: "OEM Tier 3",
        },
        {
            value: "4",
            label: "Manufacturer",
        },
    ];

    switch (key) {
        case "marked_deletion":
        case "sap_value":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "supply_chain":
            return (
                <InputFilter
                    type="select"
                    options={supplyChainOptions}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "nda":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "nicknames":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/companies/nickname-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "updated_date":
        case "changed_date":
        case "created_code_date":
        case "created_system_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
