import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { getString, formatDate } from "Services";
import TableRowItemLinkImg from "../../../../../../Templates/Table/TableRowItemLinkImg";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "user_name":
            return (
                <TableRowItemLinkImg
                    blank
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "user_id")}`}
                    img={getString(item, "user_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "name":
        case "slug":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/legal/copy/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        default:
            const modified = formatDate(getString(item, key));

            return <TableRowItemSpan title={modified}>{modified}</TableRowItemSpan>;
    }
};
