import {deleteItem, requestTable} from "../RequestService";
import { getStoreItem, getActionStore } from "../StoreService";
import { handleScroll } from "../EventsService";
import { getTableLabel, getFilter, isSortEnabled } from "../TableHeaderService";
import { getViewItem, modifierValues } from "../TableBodyService";

export default ({
    requestTable,
    deleteItem,
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
    isSortEnabled
});
