import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { gradeFileTypes, isSapImported, gradeStatus } from "Services/enum";
import { onChange } from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Grades",
        sub_status: "Product Status",
        product_name: "Brands",
        material_number: "Material Numbers",
        legacy_product_number: "Legacy Product Number",
        count_projects: "Project(s)",
        count_invoices: "Invoice(s)",
        plant_name: "Plants",
        line_name: "Production Lines",
        owner: "Suppliers",
        product_code: "Product Codes",
        files_count: "Count Files",
        submitted_by: "Submitted by",
        submitted_date: "Date Submitted",
        classification: "Material Types",
        volume: "Volume",
        revenue: "Revenue",
        gross_margin: "Gross Margin",
        publications: "File Type",
        sap_plant: "SAP Plants",
        sap_line: "SAP Production Lines",
        date_update: "Date Updated",
        sap_value: "SAP Import",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        product_name: "Search",
        sub_status: "Select",
        material_number: "Search",
        legacy_product_number: "Search",
        count_projects: "Search",
        count_invoices: "Search",
        plant_name: "Search",
        line_name: "Search",
        owner: "Search",
        files_count: "Search",
        product_code: "Search",
        submitted_by: "Search",
        submitted_date: "Date Submitted",
        classification: "Search",
        revenue: "Search",
        volume: "Search",
        gross_margin: "Search",
        publications: "Search",
        date_update: "Date Updated",
        sap_value: "Select",
        sap_plant: "Search",
        sap_line: "Search",
    };

    switch (key) {
        case "sap_value":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{ value: '', label: "-" }], ...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...gradeStatus]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/plant-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_line":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/sap-line-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "product_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/product-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "publications":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={[...gradeFileTypes]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "classification":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/classification"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "date_update":
        case "submitted_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        case "revenue":
        case "volume":
        case "gross_margin": {
            return (
                <InputFilter disabled type="text" placeholder="" value="" onChange={() => {}} />
            );
        }

        case "owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/products/owner-list"
                    name="owner"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request))
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
