import * as main from "../../index";
import {getActionStore} from './StoreService';

const modifierValues = item => item;

export default id => ({
    ...main,
    modifierValues,
    getActionStore,
    requestTable: main.requestTable(`/connection/connections/${id}/notes`),
    requestAttach: main.requestAttach(`/connection/connections/${id}/notes/add`),
    editNotesRequest: itemId => main.requestAttach(`/connection/connections/${id}/notes/${itemId}/edit`),
    deleteNotesRequest: itemId => main.deleteItem(`/connection/connections/${id}/notes/${itemId}/delete`)
});
