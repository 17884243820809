import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Column, Row} from "Templates/Table/NewVersion";
import {Span} from "Templates/Default";
import Img from "Templates/Img";
import {weightUnitName} from "Services/weightUnitName";
import {useSelector} from "react-redux";
import {getString, numberWithCommas} from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";

const propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    marginListSelect: PropTypes.string.isRequired,
    isConnectionProjectType:PropTypes.bool.isRequired,
};

const ConnectionsTableBodyColumn = ({ marginListSelect, data, isConnectionProjectType }) => {
    const [expandedColumns, setExpandedColumns] = useState([])
    const unit = useSelector(state => state.weightUnitState);
    const [link, setLink] = useState('');

    const getQueryParams = (href) => {
        const queryString = href.split('?')[1];

        if (queryString !== undefined) {
            const queryParams = queryString.split('&');

            const paramsObj = {};

            queryParams.forEach((_, i) => {
                const pair = queryParams[i].split('=');
                const key = decodeURIComponent(pair[0]);
                if (key === 'activeTab') return
                paramsObj[key] = decodeURIComponent(pair[1]);
            })

            return paramsObj.items ? {
                ...paramsObj,
                statusProject: paramsObj.statusProject ? paramsObj.statusProject.split(',').map((item) => item) : [],
                items: paramsObj.items ? paramsObj.items.split(',').map((item) => item) : []
            } : paramsObj;
        } else {
            return {};
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const paramsObject = getQueryParams(window.location.href);
            paramsObject && paramsObject.type === 'TYPE_12' ? setLink('/connection/connections/') : setLink('');
        }
    }, [])

    const getValue = (item, key) => `${marginListSelect === '1' ? '$' : ''}${numberWithCommas(getString(item, key), 0)} ${marginListSelect === '1' ? '' : weightUnitName(unit.weightUnit)}`
    return data && data.length ? data.map((item) => {
        return (
                <Fragment key={item.id}>
                    <Row>
                        <Column className={`graphv2__expand-row first`}>
                            {item.internal_items.length > 0 && <button onClick={() => setExpandedColumns((prev) => {
                                if (prev.includes(item.id)) {
                                    const list = prev.filter((expandedItem) => expandedItem !== item.id);
                                    return [...list]
                                }
                                prev.push(item.id)
                                return [...prev]
                            })} type="button" className={`${expandedColumns.includes(item.id) ? 'has-expanded' : ''} expand-row-button`}>
                                <Img img={'icon-select-arrow'}/>
                            </button>}
                            <Span>{item.name}</Span>
                        </Column>
                        {isConnectionProjectType ? null : <Column className={`graphs-v2__budget graphv2__expand-row`}>
                            <Span className="text-right d-block">{getValue(item, 'budget_sum')}</Span>
                        </Column>}
                        <Column className={`graphs-v2__actual graphv2__expand-row`}>
                            <Span className="text-right d-block">{getValue(item, 'actual_sum')}</Span>
                        </Column>
                        <Column className={`graphs-v2__annualized graphv2__expand-row`}>
                            <Span className="text-right d-block">{getValue(item, 'annualized_sum')}</Span>
                        </Column>
                        <Column className={`graphs-v2__current graphv2__expand-row`}>
                            <Span className="text-right d-block">{getValue(item, 'current_sum')}</Span>
                        </Column>
                        <Column className={`graphs-v2__next graphv2__expand-row`}>
                            <Span className="text-right d-block">{getValue(item, 'next_sum')}</Span>
                        </Column>
                    </Row>
                    {item.internal_items.length
                        ? <>
                            {item.internal_items.map((internalItem, index) => {
                                return <Row key={`Row-internal-${internalItem.id}-${index}`} className={`${expandedColumns.includes(item.id) ? 'show' : ''} expanded-row`}>
                                    <Column colSpan={1} className="first">
                                        {link ? (
                                            <Span>
                                                <TableRowItemLink
                                                    title={`${link}${internalItem.id}`}
                                                    href={`${link}${internalItem.id}`}
                                                >
                                                    {internalItem.name}
                                                </TableRowItemLink>
                                            </Span>
                                        ) : <Span>{internalItem.name}</Span>}
                                    </Column>
                                    {isConnectionProjectType ? null :<Column className="graphs-v2__budget">
                                        <Span className="text-right d-block"></Span>
                                    </Column>}
                                    <Column className="graphs-v2__actual">
                                        <Span className="text-right d-block">{getValue(internalItem, 'actual_sum')}</Span>
                                    </Column>
                                    <Column className="graphs-v2__annualized">
                                        <Span className="text-right d-block">{getValue(internalItem, 'annualized_sum')}</Span>
                                    </Column>
                                    <Column className="graphs-v2__current">
                                        <Span className="text-right d-block">{getValue(internalItem, 'current_sum')}</Span>
                                    </Column>
                                    <Column className="graphs-v2__next">
                                        <Span className="text-right d-block">{getValue(internalItem, 'next_sum')}</Span>
                                    </Column>
                                </Row>
                            })}
                        </>
                        : null}
                </Fragment>
            )
    }) : null
};

ConnectionsTableBodyColumn.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        data: getStoreItem(state, "data"),
        marginListSelect: getStoreItem(state, "old.marginListSelect"),
        isConnectionProjectType: getStoreItem(state, "isConnectionProjectType"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ConnectionsTableBodyColumn);
