import {combineReducers} from "redux";
import {getMainInitialStates, getMainReducers} from "App/Services";
import {initState as userState, reducer as userReducer} from "./UsersReducer";
import {initState as contactsState, reducer as contactsReducer} from "./ContactsReducer";
import {initState as publicationsState, reducer as publicationsReducer} from "./PublicationsReducer";

const reducers = [userReducer, contactsReducer, publicationsReducer];

const mainState = {
    id: '',
    name: '',
    owner: {},
    users: userState,
    contacts: contactsState,
    publications: publicationsState,
    // ...userState,
    // ...contactsState,
    // ...publicationsState,
};

const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }
    return state;
};

// Combine all reducers you may have here
export default combineReducers({
    ...getMainReducers,
    listState: reducer,
});

export const initialStates = {
    ...getMainInitialStates,
    mainState,
};