import React from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";

import PartWeightUnitTableBodyColumn from "./Table/TableBodyColumn";
import * as MarketSegmentsService from "../../../../../Services/Tabs/General/Table/PartWeightUnitService";
import ExpandedBlock from "../../../../Templates/ExpandedBlock";

const PartWeightUnitTable = CustomSimpleTable()(() => null)()(PartWeightUnitTableBodyColumn)()(() => null);

const PartWeightUnitBlock = ({ t, block, expanded, setExpandedAction }) => {
    return (
        <div className="project-expanded-block">
            <ExpandedBlock title={t("Part Weight & No. of Parts/Unit")} block={block} service={MarketSegmentsService} isExpanded={expanded} setExpandedAction={setExpandedAction}>
                <PartWeightUnitTable />
            </ExpandedBlock>
        </div>
    );
};

PartWeightUnitBlock.propTypes = {};

export default compose(withTagDefaultProps())(PartWeightUnitBlock);
