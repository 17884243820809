import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { createState } = initialStates;
    const { plant } = props;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "plants-create" }),
        createState: { ...createState, plant },
    };

    return _configureStore(initialState, reducers);
}
