const Constants = {
    CHANGE_FORM: "CHANGE_FORM",
    CHANGE_SECOND_SELECT: "CHANGE_SECOND_SELECT",
    CHANGE_TAB: "CHANGE_TAB",
    RESET_FORM: "RESET_FORM",
    REMOVE_ITEM: "REMOVE_ITEM",

    FETCH_ITEM_REQUESTED: "FETCH_ITEM_REQUESTED",
    FETCH_ITEM_SUCCEEDED: "FETCH_ITEM_SUCCEEDED",
    FETCH_ITEM_FAILED: "FETCH_ITEM_FAILED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",
};
export default Constants;
