import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas, request} from "Services";
import {withServiceConsumer} from "Services/Context";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {
    MainButton,
    TabBlock, TabBlockBtn,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import {Modal} from "Templates/Modals";
import {ContainerTable} from "Hoc/Widgets";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";

import {WrapButtons} from "Templates/Titles";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import UploadFileForm from "./UploadFileForm";
import {customTable} from "../../../Services/Tabs/Files/Table/TabViewDetailWrap.js";
import Breadcrumbs from "./Breadcrumbs";

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TabTable);

class Files extends Component {
    constructor(props) {
        super(props);

        if (window) {
            const wrapper = document.querySelector('.main-wrap')

            if (wrapper && !wrapper.classList.contains('full-height')) {
                wrapper.classList.add('full-height')
            }
        }

        this.defaultState = {
            showUploadFileModal: false,
            uploadFileForm: {
                files: [],
                type: '',
                note: '',
                visibility: 0,
            },
        };

        this.state = {
            showUploadFileModal: false,
            tableType: '',
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        };
    }

    componentWillUnmount() {
        if (window) {
            const wrapper = document.querySelector('.main-wrap')

            if (wrapper && wrapper.classList.contains('full-height')) {
                wrapper.classList.remove('full-height')
            }
        }
    }

    onClickDelete = item => {
        const {t} = this.props;

        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the File?"))) {
            const {
                fetchItems,
                request,
                service: {onClickDeleteItem},
            } = this.props;
            onClickDeleteItem(item).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    handleClickOnDownloadFile = (item) => {
        const {t} = this.props;

        if (+item.status === 5) {
            if (window.confirm(t("Your are going to download an outdated file. Ok to proceed?"))) {
                const link = document.createElement("a");
                link.setAttribute('download', item.name);
                link.href = `/product/product-finder/files/${item.id}/download`;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } else {
            const link = document.createElement("a");
            link.setAttribute('download', item.name);
            link.href = `/product/product-finder/files/${item.id}/download`;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    };

    onClickItem = (path) => {
        const {
            fetchItems,
            request
        } = this.props;

        request.path = path;
        fetchItems(request)

        this.setState({tableType: path});
    };

    handleClickOnUploadFile = () => {
        const {addFiles} = this.props;
        const {
            uploadFileForm: {files, type, note, visibility},
        } = this.state;

        addFiles({files, type, note, visibility}).then(this.callbackAfterRequestInModalWindow);
    };

    setFile = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                files: value,
            },
        }));
    };

    setType = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                type: value,
            },
        }));
    };

    setNote = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                note: value,
            },
        }));
    };

    setVisibility = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                visibility: value,
            },
        }));
    };

    openUploadFileModal = () => {
        this.setState({
            showUploadFileModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            ...this.defaultState,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        });
    };

    handleCloseUploadModalWithCheck = event => {
        event.preventDefault();
        const {
            uploadFileForm: {files},
        } = this.state;
        const {t} = this.props;
        if (files.length >= 1) {
            if (!confirm(t("Are you sure you want to undo the action?"))) {
                return;
            }
        }
        this.closeModal();
    };

    callbackAfterRequestInModalWindow = res => {
        const {fetchItems} = this.props;
        if (res !== undefined) {
            this.closeModal();
            fetchItems({});
        }
    };

    resetFilters = () => {
        this.props.fetchItems(
            {
                filter: {},
                sort: {column: undefined, direction: undefined}
            }
        )
    }

    render() {
        const {pagination, t, request} = this.props;
        const {showUploadFileModal, uploadFileForm, tableId} = this.state;
        const showReset = Boolean(Object.keys(request.filter).length > 0);

        return (
            <>
                {showUploadFileModal ? (
                    <Modal onRequestClose={this.handleCloseUploadModalWithCheck}>
                        <UploadFileForm
                            form={uploadFileForm}
                            onAppend={this.handleClickOnUploadFile}
                            onClose={this.handleCloseUploadModalWithCheck}
                            onChange={this.setFile}
                            onChangeType={this.setType}
                            onChangeNote={this.setNote}
                            onChangeVisibility={this.setVisibility}
                        />
                    </Modal>
                ) : null}
                <TabWrapTop className="files-top__container">
                    <div className="files-top__row">
                        <TabBlock>
                            <TabBlockCol>
                                <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                                <TabBlockColTitleH4>
                                    {`${numberWithCommas(pagination.total, 0)} ${t(
                                        "Files",
                                    )}`}
                                </TabBlockColTitleH4>
                            </TabBlockCol>
                        </TabBlock>
                        <CheckPermission permission="grade_product_files">
                            <WrapButtons>
                                {showReset ?
                                    <TabBlockBtn onClick={this.resetFilters} className="main-btn_white">
                                        {t("Reset Filters")}
                                    </TabBlockBtn> : null}
                                <MainButton className="main-btn_primary" onClick={this.openUploadFileModal}>
                                    {t("Add New File")}
                                </MainButton>
                            </WrapButtons>
                        </CheckPermission>
                    </div>
                    <Breadcrumbs onClickItem={this.onClickItem} path={tableId}/>
                </TabWrapTop>
                <div className="grade-table-wrap">
                    <TabViewDetail
                        customIcon="icon_download"
                        onClickCustom={this.handleClickOnDownloadFile}
                        onClickDelete={this.onClickDelete}
                        onClickEdit={this.handleClickEdit}
                        onClickItem={this.onClickItem}
                    />
                </div>
            </>
        );
    }
}

Files.propTypes = {
    pagination: PropTypes.shape({total: PropTypes.number}).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func,
        getStoreItem: PropTypes.func,
        getActionStore: PropTypes.func,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
    addFiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        addFiles: getActionStore("createFilesAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Files);
