const Constants = {
    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    CREATE_FOLDER_REQUEST: "CREATE_FOLDER_REQUEST",
    CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
    CREATE_FOLDER_FAILED: "CREATE_FOLDER_FAILED",

    EDIT_FOLDER_REQUEST: "EDIT_FOLDER_REQUEST",
    EDIT_FOLDER_SUCCESS: "EDIT_FOLDER_SUCCESS",
    EDIT_FOLDER_FAILED: "EDIT_FOLDER_FAILED",

    EDIT_VIDEO_REQUEST: "EDIT_VIDEO_REQUEST",
    EDIT_VIDEO_SUCCESS: "EDIT_VIDEO_SUCCESS",
    EDIT_VIDEO_FAILED: "EDIT_VIDEO_FAILED",

    SET_FOLDER_REQUEST: "SET_FOLDER_REQUEST",
    SET_FOLDER_SUCCESS: "SET_FOLDER_SUCCESS",
    SET_FOLDER_FAILED: "SET_FOLDER_REQUEST",

    OPEN_VIDEO_UPLOAD_MODAL: "OPEN_VIDEO_UPLOAD_MODAL",
    CLOSE_VIDEO_UPLOAD_MODAL: "CLOSE_VIDEO_UPLOAD_MODAL",

    OPEN_EDIT_VIDEO_UPLOAD_MODAL: "OPEN_EDIT_VIDEO_UPLOAD_MODAL",
    CLOSE_EDIT_VIDEO_UPLOAD_MODAL: "CLOSE_EDIT_VIDEO_UPLOAD_MODAL",

    UPLOAD_VIDEO_REQUEST: "UPLOAD_VIDEO_REQUEST",
    UPLOAD_VIDEO_SUCCESS: "UPLOAD_VIDEO_SUCCESS",
    UPLOAD_VIDEO_FAILED: "UPLOAD_VIDEO_FAILED",

    NEXT_PAGE: "NEXT_PAGE",
    NEXT_FOLDERS_PAGE: "NEXT_FOLDERS_PAGE",
};

export default Constants;
