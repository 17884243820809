import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Contact Name",
        phone: "Phone",
        company: "Company",
        email: "Email",
        title: "Title",
        type: "Int/Ext",
        owner: "Owner",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        phone: "Search",
        company: "Search",
        email: "Search",
        title: "Search",
        owner: "Search",
    };
    switch (key) {
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
