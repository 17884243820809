import _ from "lodash";
import {url} from "Services";
import Constants from "../Constants";

export const initState = {
    activeTab: +url.get('activeTab'),
    showModal: false,
    showEditModal: false,
    form: { vendorName: '', vendorCode: ''}
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data, filter: {}, sort: {} };

        case Constants.OPEN_MODAL:
            return {...state, showModal: true}

        case Constants.CLOSE_MODAL:
            return {...state, showModal: false}

        case Constants.UPDATE_VENDOR_FORM: {
            return {
                ...state,
                form: {
                    vendorName: data.key === 'vendorName' ? data.value : state.form.vendorName,
                    vendorCode: data.key === 'vendorCode' ? data.value : state.form.vendorCode
                }
            };
        }

        case Constants.SAVE_VENDOR_SUCCEEDED: {
            return {
                ...state,
                showModal: false,
                showEditModal: false,
                form: { vendorName: '', vendorCode: ''}
            };
        }

        case Constants.OPEN_EDIT_MODAL:
            return {...state, showEditModal: true}

        case Constants.CLOSE_EDIT_MODAL:
            return {...state, showEditModal: false}

        default:
            return state;
    }
};
