import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {Tooltip} from "Templates/Form";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Legal = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };
    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_legal_agreements_view-${index}`}
                    value={getPermission("legal_agreements_view", index)}
                    onChange={value => onChangePermission("legal_agreements_view", value, index)}
                >
                    <CheckboxTitle title="View/Edit/Delete - Agreements">
{/*                        <Tooltip>
                            {t(
                                "Access user to View/Edit/Delete Agreements.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_legal_copy_view-${index}`}
                    value={getPermission("legal_copy_view", index)}
                    onChange={value => onChangePermission("legal_copy_view", value, index)}
                >
                    <CheckboxTitle title="View/Edit/Delete - Copy">
{/*                        <Tooltip>
                            {t(
                                "Access user to View/Edit/Delete Copy.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_legal_iso_view-${index}`}
                    value={getPermission("legal_iso_view", index)}
                    onChange={value => onChangePermission("legal_iso_view", value, index)}
                >
                    <CheckboxTitle title="View/Edit/Delete - ISO">
{/*                        <Tooltip>
                            {t(
                                "Access user to View/Edit/Delete Files.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
        </>
    )
}

Legal.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Legal);