import React from "react";
import _ from "lodash";
import {FormBlockLink} from "Templates/Form";
import {Span} from "Templates/Default";
import {getStoreItem as _getStoreItem, numberWithCommas} from "Services";
import {gradeStatus} from "Services/enum";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {weightUnitName} from 'Services/weightUnitName';

export const getStoreItem = _getStoreItem("listState");
export const itemsName = "items.product";
export const columnsName = {
    product: {label: "Source"},
    grade: {label: "Product"},
    sub_status: {
        label: "Product Status", tooltip: `
            <div class="long-tooltip"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</div>
            <div class="long-tooltip"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</div>
            <div class="long-tooltip"><span class="font-medium">Production Intent</span> - Product in process for being approved.</div>
            <div class="long-tooltip"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</div>
            <div class="long-tooltip"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</div>
            <div class="long-tooltip"><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</div>
        `
    },
    sale_price: {label: "Price", permission: "revenue_gross_operating_view"},
    revenue: {label: "FY Revenue", permission: "revenue_gross_operating_view"},
    volume: {label: "FY Volume", permission: "volume_view"},
    percent_of_product: {label: "% of Project Volume"},
};

export const getBodyView = (key, item, weightUnit) => {
    const link = href => {
        return _.get(item, 'shared_company') ?
            (<Span>{`${_.get(item, key, "")} (${_.get(item, 'name_company')})`}</Span>) :
            (<FormBlockLink href={href}>{_.get(item, key, "")}</FormBlockLink>)
    };

    switch (key) {

        case "product": {
            return link(`/product/products/${_.get(item, "product_id")}`);
        }

        case "grade": {
            return link(`/product/product-finder/${_.get(item, "grade_id")}`);
        }

        case "sale_price":
            return <Span>{`$${_.get(item, key, "")}/${weightUnitName(weightUnit.weightUnit)}`}</Span>;

        case "revenue":
            return <Span>{`$${numberWithCommas(_.get(item, key), 0)}`}</Span>;

        case "volume":
            return <Span>{`${numberWithCommas(_.get(item, key), 0)} ${weightUnitName(weightUnit.weightUnit)}`}</Span>;

        case "percent_of_product":
            return <Span>{`${_.get(item, key)}%`}</Span>;

        case "sub_status":
            const subStatusItem = gradeStatus.find(el => el.value.toString() === _.get(item, key).toString());
            const subStatusLabel = subStatusItem && subStatusItem.label;

            return subStatusLabel && <TableRowItemStatus status={subStatusLabel} list={gradeStatus}/>
                || <Span>-</Span>;

        default:
            return <Span>{_.get(item, key, "")}</Span>;
    }
};
