import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "../../../Services/EventsService";

const {Button, Form, Title} = ModalDefault;

const propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    id: PropTypes.string.isRequired,
    datasheetId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    getSubTabs: PropTypes.func.isRequired,
    savePublicationCopy: PropTypes.func.isRequired,
};

const CopyPublicationForm = (
    {
        form,
        id,
        datasheetId,
        getSubTabs,
        savePublicationCopy,
        onClose,
        callback,
        t,
    }
) => {
    const formValidate = () => {
        return form.publication && form.publication.length > 0;
    };

    const handleSave = () => {
        return savePublicationCopy(datasheetId)(form).then(() => {
            getSubTabs()
        });
    };

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose}/>
            <Title>{t('Assign to Publication')}</Title>
            <InputFilter
                type="asyncMultiSelect"
                placeholder="Search"
                url={`/product/product-finder/publication-list`}
                value={form.publication}
                onChange={e => {
                    onChange(e).then(_value => callback('publication', _value));
                }}
            />
            <Button onClick={handleSave} disabled={!formValidate()}>
                {t("Apply")}
            </Button>
        </Form>
    );
}

CopyPublicationForm.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
    }
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        getSubTabs: getActionStore("getSubTabs", service, dispatch),
        savePublicationCopy: getActionStore("saveCopyPublicationAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(CopyPublicationForm);
