import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    loading: false,
    items: [],
    pagination: {},
    sort: {},
    columns: [],
    errors: {},
    tab: 0,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.TABLE_SORT:
            return { ...state, items: data.items, sort: data.sort, pagination: data.pagination };

        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.CREATE_FOLDER_REQUEST:
        case Constants.EDIT_FOLDER_REQUEST:
        case Constants.DELETE_ITEM_REQUESTED:
            return { ...state, loading: true };

        case Constants.CREATE_FOLDER_SUCCESS:
        case Constants.EDIT_FOLDER_SUCCESS:
        case Constants.DELETE_ITEM_SUCCEEDED:
            return { ...state, loading: false };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return { ...state, ...data, loading: false };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.NEXT_PAGE:
            return { ...state, items: _.concat(state.items, data.items), pagination: data.pagination, loading: false };

        default: {
            return state;
        }
    }
};
