import {request, url} from "Services";

export const getItems = listUrl => data => {
    return request.sendRequest({
        url: url.getUrl(listUrl, []),
        data,
        type: "GET",
    });
};

export const getVideoPreview = async (videoId) => {
    const sendObj = {
        url: url.getUrl(`/video-guides/files/${videoId}/presigned-url`),
        type: "GET",
    };

    return await request.sendRequestWithNoCache(sendObj)
};

export const setFolder = async (data) => {
    const sendObj = {
        url: url.getUrl(`/video-guides/${data.folderId}?page=${data.page}`),
        type: "GET",
    };

    return await request.sendRequestWithNoCache(sendObj)
};

export const getFolderList = async (page) => {
    const sendObj = {
        url: url.getUrl(`/video-guides?page=${page}`),
        type: "GET",
    };

    return await request.sendRequestWithNoCache(sendObj)
};

export const requestItems = listUrl => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination
            });
        })
    });
};

export const requestOnCreateFolder = data =>
    request.sendRequest({
        url: url.getUrl(`video-guides/folders/create`),
        data,
        type: "POST",
    });

export const requestOnEditFolder = (id, data) =>
    request.sendRequest({
        url: url.getUrl(`video-guides/folders/${id}/edit`),
        data,
        type: "PATCH",
    });

export const getPreSignedUrl = async (fileName) => {
    const sendObj = {
        url: url.getUrl(`/video-guides/files/presigned-url`),
        data: { fileName },
        type: "POST",
    };

    return await request.sendRequestWithNoCacheAndNoLoading(sendObj)
}

export const requestOnUploadVideo = async (data) => {
    const payload = {
        thumbnail_id: data.thumbnail_id,
        attachmentPath: data.path,
        attachmentName: data.title,
        attachmentDescription: data.description,
        position: data.position
    }
    await request.sendRequestWithNoCacheAndNoLoading({
        url: url.getUrl(`/video-guides/folders/${data.folderId}/files/add`),
        data: payload,
        type: "POST",
    });
}

export const changeVideoPositions = async (positions) => {
    const sendObj = {
        url: url.getUrl(`/video-guides/change-position`),
        data: { positions },
        type: "POST",
    };

    return await request.sendRequestWithNoCacheAndNoLoading(sendObj)
}

export const deleteVideo = async (videoId) => {
    const sendObj = {
        url: url.getUrl(`/video-guides/files/${videoId}/delete`),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export const deleteVideoFolder = async (folderId) => {
    const sendObj = {
        url: url.getUrl(`/video-guides/folders/${folderId}/delete`),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export const requestOnEditVideo = (id, data) =>
    request.sendRequest({
        url: url.getUrl(`video-guides/files/${id}/update`),
        data,
        type: "PATCH",
    });