import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";
import Img from "Templates/Img";

const propTypes = {
    customIcon: PropTypes.string,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const defaultProps = {
    customIcon: "",
    onClickCustom: () => {},
};

const TableRowItemEdit = ({ customIcon, onClickCustom, onClickEdit, onClickDelete }) => (
    <TableBodyButtonsBlock>
        {!!customIcon && (
            <TableBodyButton onClick={onClickCustom}>
                <Img img={customIcon} />
            </TableBodyButton>
        )}
        <TableBodyButton onClick={onClickEdit}>
            <Img img="icon_edit" />
        </TableBodyButton>
        <TableBodyButton onClick={onClickDelete}>
            <Img img="icon_delete" />
        </TableBodyButton>
    </TableBodyButtonsBlock>
);

TableRowItemEdit.propTypes = propTypes;
TableRowItemEdit.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowItemEdit);
