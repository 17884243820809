import React, {Fragment, useEffect, useState} from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { LinkIcon } from "Templates/Link";
import { Button } from "Templates/Default";
import { BlockWrap, Input, WrapInput } from "Templates/Form";

const propTypes = {
    id: PropTypes.string.isRequired,
    replacementBusinessProjectsBlock: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
};

const ReplaceBusiness = ({ id, replacementBusinessProjectsBlock, t, onChange }) => {
    const [excludedIds, setExcludedIds] = useState({})

    useEffect(() => {
        if (id && replacementBusinessProjectsBlock && replacementBusinessProjectsBlock.projects && replacementBusinessProjectsBlock.projects.length) {
            const filteredExcludedIds = replacementBusinessProjectsBlock.projects.filter((item) => item.value)
            if (!filteredExcludedIds.length) {
                setExcludedIds({['filter[exclude_id][]']: [id]})
                return
            }
            const excludeIdsList = {['filter[exclude_id][]']: [id, ...filteredExcludedIds.map((item) => item.value)]}
            if (!Object.keys(excludeIdsList).length) {
                setExcludedIds({['filter[exclude_id][]']: [id]})
                return
            }
            setExcludedIds(excludeIdsList)
        }
    }, [replacementBusinessProjectsBlock])
    const _onAddArray = (lastFrontId, key, array) => {
        onChange(key, [...array, { frontId: lastFrontId + 1, value: "" }]);
    };

    const _onChangeArray = (index, value, key, array) => {
        _.set(array, index, value);
        onChange(key, _.uniqBy(_.concat(array), 'value'));
    };

    const _onDeleteArray = (frontId, key, array) => {
        onChange(key, array.filter(elem => elem.frontId !== frontId));
    };

    return (
        <>
            <BlockWrap className="width_1-3">
                <WrapInput className="wrap-input--delete" label="Projects" name="projects">
                    {replacementBusinessProjectsBlock.projects.map((contact, index) => (
                        Object.keys(excludedIds).length > 0 ? <Fragment key={`Input-${contact.frontId}`}>
                            <Input
                                type="asyncSelect"
                                name={`Projects-${index}`}
                                url={`/project/projects/project-list-slug-name`}
                                params={excludedIds}
                                value={contact.value}
                                placeholder={t("Search")}
                                onChange={value =>
                                    _onChangeArray(
                                        index,
                                        { frontId: contact.frontId, value },
                                        "replacementBusinessProjectsBlock.projects",
                                        replacementBusinessProjectsBlock.projects
                                    )
                                }
                            />
                            {replacementBusinessProjectsBlock.projects.length > 1 && (
                                <LinkIcon
                                    className="icon-delete"
                                    icon="la-icon-close"
                                    onClick={() =>
                                        _onDeleteArray(
                                            contact.frontId,
                                            "replacementBusinessProjectsBlock.projects",
                                            replacementBusinessProjectsBlock.projects,
                                        )
                                    }
                                />
                            )}
                        </Fragment> : null
                    ))}
                    <Button
                        onClick={() =>
                            _onAddArray(
                                replacementBusinessProjectsBlock.projects[replacementBusinessProjectsBlock.projects.length - 1].frontId,
                                "replacementBusinessProjectsBlock.projects",
                                replacementBusinessProjectsBlock.projects,
                            )
                        }
                        className="size_normal main-btn main-btn_white"
                    >
                        Add Projects
                    </Button>
                </WrapInput>
            </BlockWrap>
        </>
    );
};

ReplaceBusiness.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        id: state.editState.id,
        replacementBusinessProjectsBlock: getStoreItem(state, "replacementBusinessProjectsBlock"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ReplaceBusiness);
