import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import CheckboxButton from "Templates/Form/Checkbox/CheckboxButton";
import CheckboxBlock from "Templates/Form/Checkbox/CheckboxBlock";
import {sendNotifications} from "../../../Services/RequestService";

export const ADDED_TO_PROJECT_AS_MANAGER = 53;
export const ADDED_TO_PROJECT_AS_INTERNAL_TEAM_MEMBER = 54;
export const SHARING_FILE_FOLDER = 67;
export const SHARED_AGREEMENT = 68;

export const NOTIFICATION_ADDED_TO_PROJECT_AS_MANAGER = "P_XXXXX has been added and you are assigned as the Sales Manager"
export const NOTIFICATION_ADDED_TO_PROJECT_AS_INTERNAL_TEAM_MEMBER = "P_XXXXX has been added and you are assigned as an internal team member"
export const NOTIFICATION_SHARING_FILE_FOLDER = "[User Name] shared [File Name] with you"
export const NOTIFICATION_SHARED_AGREEMENT = "[User Name] shared [Agreement] with you"

export const DEFAULT_NOTIFICATIONS_STORAGE = "DEFAULT_NOTIFICATIONS"

export const DEFAULT_NOTIFICATIONS = [
    {
        type: ADDED_TO_PROJECT_AS_MANAGER,
        value: NOTIFICATION_ADDED_TO_PROJECT_AS_MANAGER,
        email: 0,
        comopps: 0
    },
    {
        type: ADDED_TO_PROJECT_AS_INTERNAL_TEAM_MEMBER,
        value: NOTIFICATION_ADDED_TO_PROJECT_AS_INTERNAL_TEAM_MEMBER,
        email: 0,
        comopps: 0
    },
    {
        type: SHARING_FILE_FOLDER,
        value: NOTIFICATION_SHARING_FILE_FOLDER,
        email: 0,
        comopps: 0
    },
    {
        type: SHARED_AGREEMENT,
        value: NOTIFICATION_SHARED_AGREEMENT,
        email: 0,
        comopps: 0
    }
]

const Notifications = ({items, fetchItems, request}) => {
    const [notifications, setNotifications] = useState([])
    const [disabled, setDisabled] = useState(false)
    const generateNotificastionsText = (type) => {
        switch (type) {
            case ADDED_TO_PROJECT_AS_MANAGER:
                return NOTIFICATION_ADDED_TO_PROJECT_AS_MANAGER
            case ADDED_TO_PROJECT_AS_INTERNAL_TEAM_MEMBER:
                return NOTIFICATION_ADDED_TO_PROJECT_AS_INTERNAL_TEAM_MEMBER
            case SHARING_FILE_FOLDER:
                return NOTIFICATION_SHARING_FILE_FOLDER
            case SHARED_AGREEMENT:
                return NOTIFICATION_SHARED_AGREEMENT
            default:
                return ""
        }
    }

    useEffect(() => {
        if (!items || !items.length) {
            localStorage.setItem(DEFAULT_NOTIFICATIONS_STORAGE, JSON.stringify(DEFAULT_NOTIFICATIONS))
        }
    }, [items])

    useEffect(() => {
        fetchItems({...request})
    }, []);

    useEffect(() => {
        if (items && items.length) {
            const newNotifications = items.map((n) => {
                return {
                    type: n.type_value,
                    value: generateNotificastionsText(n.type_value),
                    email: n.email_value,
                    comopps: n.comopps_value
                }
            })
            localStorage.setItem(DEFAULT_NOTIFICATIONS_STORAGE, JSON.stringify(newNotifications))
            setNotifications(newNotifications)
        } else {
            setNotifications(DEFAULT_NOTIFICATIONS)
        }
    }, [items])

    useEffect(() => {
        const defaultNotifications = localStorage.getItem(DEFAULT_NOTIFICATIONS_STORAGE)
        setDisabled(defaultNotifications === JSON.stringify(notifications))
    }, [notifications])

    const changeNotify = (type, field, value) => {
        setNotifications((prev) => {
            const index = prev.findIndex((item) => item.type === type)
            if (index === -1) return prev
            prev[index][field] = value ? 10 : 0

            return [...prev]
        })
    }

    const sendData = (notificationsList) => {
        sendNotifications({
            notificationSettings: [...notificationsList.map((n) => {
                return {type: +n.type, email: +n.email, comopps: +n.comopps}
            })]
        }).then(() => {
            alert('Notifications updated!')
            localStorage.setItem(DEFAULT_NOTIFICATIONS_STORAGE, JSON.stringify(notificationsList))
            setDisabled(true)
        })
    }

    return (
        <>
            <div className="profile-notifications-actions">
                <button
                    type="button"
                    disabled={disabled}
                    onClick={() => {
                        sendData(notifications)
                    }}
                >
                    Save
                </button>
            </div>
            <div className="profile-notifications">
                <div className="profile-notifications__head">
                    <p>Notification Message</p>
                    <p>Notification Center</p>
                    <p>Email</p>
                </div>
                {notifications && notifications.length > 0 ? notifications.map((notify) => {
                    return (
                        <div key={notify.type} className="profile-notifications__body">
                            <p>{notify.value}</p>
                            <CheckboxBlock>
                                <CheckboxButton
                                    htmlFor={`checkbox_comopps-${notify.type}`}
                                    value={!!notify.comopps}
                                    onChange={value => changeNotify(notify.type, 'comopps', value)}
                                />
                            </CheckboxBlock>
                            <CheckboxBlock>
                                <CheckboxButton
                                    htmlFor={`checkbox_email-${notify.type}`}
                                    value={!!notify.email}
                                    onChange={value => changeNotify(notify.type, 'email', value)}
                                />
                            </CheckboxBlock>
                        </div>
                    )
                }) : null}
            </div>
        </>
    )
}

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        items: getStoreItem(state, 'items'),
        request: {
            filter: {
                ...getStoreItem(state, 'filter'),
            },
            sort: {
                ...getStoreItem(state, 'sort'),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchPage: getActionStore('fetchPage', service, dispatch),
        fetchItems: getActionStore('fetchItems', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Notifications);