import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { datasheetLocation, datasheetPaintable, isMolded, isBoundable } from "Services/enum";
import { countryList } from "Services";
import { onChange } from "./EventsService";

export const getTableLabel = (key) => {
    const tableLabel = {
        name: "Technology Name",
        slug: "Technology ID",

        datasheets: "Name",
        datasheets_approved_for_solution: "Approved for <br> SAP Products",
        datasheets_approved_by_solution: "SAP Products <br> Approved by",
        datasheets_approved_for_sharing: "Approved for <br> Sharing",
        datasheets_approved_by_sharing: "Sharing <br> Approved by",
        datasheets_products_list: "Assigned To",
        datasheets_material_numbers: "Material Number",
        datasheets_contacts: "Product Contact",
        datasheets_contact_roles: "Additional Information",
        datasheets_contact_relation_roles: "Contact Role",
        datasheets_molded: "Mold-In-Color",
        datasheets_boundable: "Bondable",
        datasheets_locations: "Vehicle Location",
        datasheets_paintable: "Paintable",
        datasheets_manufactures: "Part Approvals",
        datasheets_colors: "Color",
        datasheets_approvals: "OEM Approvals",
        datasheets_color_codes: "Color Code",
        datasheets_sources: "Source",
        datasheets_countries: "Country",
        datasheets_contents: "Description",
        datasheets_property: "Property",
        datasheets_test_method: "Test Method",
        datasheets_specimen: "Specimen",
        datasheets_test_condition: "Test Condition",
        datasheets_si_value_min: "Value Min (SI)",
        datasheets_si_value: "Value Max (SI)",
        datasheets_si_unit: "SI Unit",
        datasheets_english_value_min: "Value Min (English)",
        datasheets_english_value: "Value Max (English)",
        datasheets_english_unit: "English Unit",
        datasheets_legal: 'Legal Template Name',

        processes: "Name",
        processes_approved_for_solution: "Approved for <br> SAP Products",
        processes_approved_by_solution: "SAP Products <br> Approved by",
        processes_approved_for_sharing: "Approved for <br> Sharing",
        processes_approved_by_sharing: "Sharing <br> Approved by",
        processes_products_list: "Assigned To",
        processes_material_numbers: "Material Number",
        processes_contacts: "Product Contact",
        processes_contact_roles: "Additional Information",
        processes_contact_relation_roles: "Contact Role",
        processes_molded: "Mold-In-Color",
        processes_boundable: "Bondable",
        processes_locations: "Vehicle Location",
        processes_paintable: "Paintable",
        processes_manufactures: "Part Approvals",
        processes_colors: "Color",
        processes_approvals: "OEM Approvals",
        processes_color_codes: "Color Code",
        processes_sources: "Source",
        processes_countries: "Country",
        processes_contents: "Description",
        processes_manufacturing: "Manufacturing Process",
        processes_parameter: "Process Parameter",
        processes_description: "Process Description",
        processes_si_value_min: "Value Min (SI)",
        processes_si_value: "Value Max (SI)",
        processes_si_unit: "SI Unit",
        processes_english_value_min: "Value Min (English)",
        processes_english_value: "Value Max (English)",
        processes_english_unit: "English Unit",
        processes_legal: 'Legal Template Name',

        designs: "Name",
        designs_approved_for_solution: "Approved for <br> SAP Products",
        designs_approved_by_solution: "SAP Products <br> Approved by",
        designs_approved_for_sharing: "Approved for <br> Sharing",
        designs_approved_by_sharing: "Sharing <br> Approved by",
        designs_products_list: "Assigned To",
        designs_material_numbers: "Material Number",
        designs_contacts: "Product Contact",
        designs_contact_roles: "Additional Information",
        designs_contact_relation_roles: "Contact Role",
        designs_molded: "Mold-In-Color",
        designs_boundable: "Bondable",
        designs_locations: "Vehicle Location",
        designs_paintable: "Paintable",
        designs_manufactures: "Part Approvals",
        designs_colors: "Color",
        designs_approvals: "OEM Approvals",
        designs_color_codes: "Color Code",
        designs_sources: "Source",
        designs_countries: "Country",
        designs_contents: "Description",
        designs_legal: 'Legal Template Name',

        safeties: "Name",
        safeties_approved_for_solution: "Approved for <br> SAP Products",
        safeties_approved_by_solution: "SAP Products <br> Approved by",
        safeties_approved_for_sharing: "Approved for <br> Sharing",
        safeties_approved_by_sharing: "Sharing <br> Approved by",
        safeties_products_list: "Assigned To",
        safeties_material_numbers: "Material Number",
        safeties_contacts: "Product Contact",
        safeties_contact_roles: "Additional Information",
        safeties_contact_relation_roles: "Contact Role",
        safeties_molded: "Mold-In-Color",
        safeties_boundable: "Bondable",
        safeties_paintable: "Paintable",
        safeties_manufactures: "Part Approvals",
        safeties_colors: "Color",
        safeties_approvals: "OEM Approvals",
        safeties_color_codes: "Color Code",
        safeties_sources: "Source",

        brochures: "Name",
        brochures_approved_for_solution: "Approved for <br> SAP Products",
        brochures_approved_by_solution: "SAP Products <br> Approved by",
        brochures_approved_for_sharing: "Approved for <br> Sharing",
        brochures_approved_by_sharing: "Sharing <br> Approved by",
        brochures_products_list: "Assigned To",
        brochures_material_numbers: "Material Number",
        brochures_contacts: "Product Contact",
        brochures_contact_roles: "Additional Information",
        brochures_contact_relation_roles: "Contact Role",
        brochures_molded: "Mold-In-Color",
        brochures_boundable: "Bondable",
        brochures_paintable: "Paintable",
        brochures_manufactures: "Part Approvals",
        brochures_colors: "Color",
        brochures_approvals: "OEM Approvals",
        brochures_color_codes: "Color Code",
        brochures_sources: "Source",
    };

    return _.get(tableLabel, key, key);
};

export const getTooltip = key => {
    const tableTooltip = {
        datasheets_material_numbers: 'SAP Material Number',
        processes_material_numbers: 'SAP Material Number',
        designs_material_numbers: 'SAP Material Number',
        safeties_material_numbers: 'SAP Material Number',
        brochures_material_numbers: 'SAP Material Number',
    };

    return _.get(tableTooltip, key, null);
}

export const getFilter = (key, value, callback, request) => {
    switch (key) {
        case 'slug':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Search"
                    url="/admin/solutions/slugs"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'name':
        case 'datasheets':
        case 'processes':
        case 'designs':
        case 'safeties':
        case 'brochures':
        case 'datasheets_products_list':
        case 'processes_products_list':
        case 'designs_products_list':
        case 'safeties_products_list':
        case 'brochures_products_list':
        case 'datasheets_material_numbers':
        case 'processes_material_numbers':
        case 'designs_material_numbers':
        case 'safeties_material_numbers':
        case 'brochures_material_numbers':
        case 'datasheets_contacts':
        case 'datasheets_contact_roles':
        case 'datasheets_contact_relation_roles':
        case 'processes_contacts':
        case 'processes_contact_roles':
        case 'processes_contact_relation_roles':
        case 'designs_contacts':
        case 'designs_contact_roles':
        case 'designs_contact_relation_roles':
        case 'safeties_contacts':
        case 'safeties_contact_roles':
        case 'safeties_contact_relation_roles':
        case 'brochures_contacts':
        case 'brochures_contact_roles':
        case 'brochures_contact_relation_roles':
        case 'datasheets_properties':
        case 'datasheets_legal':
        case 'processes_legal':
        case 'designs_legal':
        case 'datasheets_si_value_min':
        case 'datasheets_si_value':
        case 'datasheets_english_value_min':
        case 'datasheets_english_value':
        case 'processes_si_value_min':
        case 'processes_si_value':
        case 'processes_english_value_min':
        case 'processes_english_value':
        case 'datasheets_approved_by_solution':
        case 'datasheets_approved_by_sharing':
        case 'processes_approved_by_solution':
        case 'processes_approved_by_sharing':
        case 'designs_approved_by_solution':
        case 'designs_approved_by_sharing':
        case 'safeties_approved_by_solution':
        case 'safeties_approved_by_sharing':
        case 'brochures_approved_by_solution':
        case 'brochures_approved_by_sharing':
        case 'datasheets_contents':
        case 'processes_contents':
        case 'designs_contents':
            return (
                <InputFilter
                    type="text"
                    placeholder="Search"
                    value={value}
                    name={key}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_property':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/material-property-list/1"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'datasheets_test_method':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/material-property-list/2"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'datasheets_specimen':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/material-property-list/3"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'datasheets_test_condition':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/material-property-list/4"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'datasheets_si_unit':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/material-property-list/5"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'datasheets_english_unit':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/material-property-list/6"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'processes_manufacturing':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/process-property-list/1"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'processes_parameter':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/process-property-list/2"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'processes_description':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/process-property-list/3"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'processes_si_unit':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/process-property-list/4"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'processes_english_unit':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/product/product-finder/process-property-list/5"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'datasheets_paintable':
        case 'processes_paintable':
        case 'designs_paintable':
        case 'safeties_paintable':
        case 'brochures_paintable':
            return (
                <InputFilter
                    type="select"
                    options={[...[{ value: "", label: "-" }], ...datasheetPaintable]}
                    name={key}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_molded':
        case 'processes_molded':
        case 'designs_molded':
        case 'safeties_molded':
        case 'brochures_molded':
            return (
                <InputFilter
                    type="select"
                    options={[...[{ value: "", label: "-" }], ...isMolded]}
                    name={key}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_boundable':
        case 'processes_boundable':
        case 'designs_boundable':
        case 'safeties_boundable':
        case 'brochures_boundable':
            return (
                <InputFilter
                    type="select"
                    options={[...[{ value: "", label: "-" }], ...isBoundable]}
                    name={key}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_locations':
        case 'processes_locations':
        case 'designs_locations':
            return (
                <InputFilter
                    type="select"
                    options={[...[{ value: "", label: "-" }], ...datasheetLocation]}
                    name={key}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_countries':
        case 'processes_countries':
        case 'designs_countries':
            return (
                <InputFilter
                    type="select"
                    options={[...[{ value: "", label: "-" }], ...countryList]}
                    name={key}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_approved_for_solution':
        case 'datasheets_approved_for_sharing':
        case 'processes_approved_for_solution':
        case 'processes_approved_for_sharing':
        case 'designs_approved_for_solution':
        case 'designs_approved_for_sharing':
        case 'safeties_approved_for_solution':
        case 'safeties_approved_for_sharing':
        case 'brochures_approved_for_solution':
        case 'brochures_approved_for_sharing':
            return (
                <InputFilter
                    type="select"
                    options={[
                        {
                            value: "",
                            label: "All",
                        },
                        {
                            value: '10',
                            label: "Yes",
                        },
                        {
                            value: "0",
                            label: "No",
                        },
                    ]}
                    name={key}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_manufactures':
        case 'processes_manufactures':
        case 'designs_manufactures':
        case 'safeties_manufactures':
        case 'brochures_manufactures':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/admin/solutions/manufacturer-forecast-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'datasheets_colors':
        case 'processes_colors':
        case 'designs_colors':
        case 'safeties_colors':
        case 'brochures_colors':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/colors/color-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            )

        case 'datasheets_approvals':
        case 'processes_approvals':
        case 'designs_approvals':
        case 'safeties_approvals':
        case 'brochures_approvals':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/colors/color-approvals-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_color_codes':
        case 'processes_color_codes':
        case 'designs_color_codes':
        case 'safeties_color_codes':
        case 'brochures_color_codes':
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder="Select"
                    url="/colors/color-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case 'datasheets_sources':
        case 'processes_sources':
        case 'designs_sources':
        case 'safeties_sources':
        case 'brochures_sources':
            return (
                <InputFilter
                    type="select"
                    options={[
                        {
                            value: "",
                            label: "-",
                        },
                        {
                            value: '1',
                            label: "Specification",
                        },
                        {
                            value: "2",
                            label: "Project",
                        },
                        {
                            value: "3",
                            label: "Legacy",
                        },
                        {
                            value: "4",
                            label: "Other",
                        },
                    ]}
                    name={key}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (<></>);
    }
};

export const isSortEnabled = key => ["slug", "name"].indexOf(key) > -1;
