export const validate = ({applicationBlock: {application}}) => {
    const cbForCheckString = el => typeof el === "string" && el.trim();
    const checkApplication = application.every(
        ({code, sub_segment}) =>
            [code, sub_segment].every(cbForCheckString) || (application.length === 1 && !code && !sub_segment)
    );

    return (
        checkApplication
    );
};
