import React, {useEffect, useState} from "react";

import {ButtonClose} from "Templates/Button";
import {ModalDefault} from "Templates/Modals";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {formatDate, numberWithCommas, request, url} from "Services";
import {Input} from "Templates/Form";
import {weightUnitName} from "Services/weightUnitName";

const {Form, Title} = ModalDefault;

const MonthlyModal = ({id, weightUnit, forecast, onClose}) => {
    const [data, setData] = useState(null)
    const [selectedYear, setSelectedYear] = useState('')
    const getMonthlyForecast = async (params) => {
        try {
            const sendObj = {
                url: url.getUrl(`/project/projects/${id}/forecasts/statistic`),
                data: params || null,
                type: "GET",
            };

            return await request.sendRequest(sendObj)
        } catch (e) {
            alert(e)
        }
    }

    useEffect(() => {
        getMonthlyForecast().then((r) => {
            if (r.length) {
                setData({...r[0].monthDetail, rangeYears: ['Reset', ...r[0].monthDetail.rangeYears]})
            }
        })
    }, [])

    const handleChangeYear = (value) => {
        const params = {
            forecast_id: forecast.id,
            year: value
        }
        getMonthlyForecast(value === 'Reset' ? null : params).then((r) => {
            if (r.length) {
                setData({...r[0].monthDetail, rangeYears: ['Reset', ...r[0].monthDetail.rangeYears]})
                setSelectedYear(value === 'Reset' ? '' : value)
            }
        })
    }

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose}/>
            <Title>
                {forecast.manufacturer.value}
                {' '}
                - Monthly
            </Title>
            {data && data.items && data.items.length ? (
                <div className="project-view__forecast-modal">
                    <div className="project-view__forecast-modal-item">
                        <p className="head">
                            {data.rangeYears && data.rangeYears.length ? (
                                <Input
                                    type="select"
                                    options={data.rangeYears.map(elem => ({value: elem.toString(), label: elem}))}
                                    placeholder="Select"
                                    name="year"
                                    value={selectedYear}
                                    onChange={value => handleChangeYear(value)}
                                />
                            ) : null}
                        </p>
                        <p className="head">Monthly Volume</p>
                        <p className="head">Monthly Revenue</p>
                        <p className="head">Monthly Builds</p>
                    </div>
                    <div className="project-view__forecast-modal-item">
                        {data.items.map((forecast, i) => {
                            return <div className="body-wrap" key={`forecast-monthly-modal-${i}`}>
                                <p className="body">{formatDate(forecast.date_value, "MMMM")}</p>
                                <p className="body">
                                    {numberWithCommas(forecast.volume, 0)}
                                    {' '}
                                    {weightUnitName(weightUnit && weightUnit.weightUnit)}
                                </p>
                                <p className="body">
                                    $
                                    {numberWithCommas(forecast.revenue, 0)}
                                </p>
                                <p className="body">
                                    {numberWithCommas(forecast.real_builds, 0)}
                                </p>
                            </div>
                        })}
                    </div>
                </div>
            ) : null}
        </Form>
    )
}

export default compose(
    withServiceConsumer,
)(MonthlyModal);