import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url, numberWithCommas } from "Services";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { Title, Total } from "Templates/Titles";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";
import freeAccounts from "../Services/Tabs/FreeAccounts";
import paidAccounts from "../Services/Tabs/PaidAccounts";

const propTypes = {
    title: PropTypes.number.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    activeTab: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TabView = ({ onClickEdit, onClickCustom, onClickDelete, t, title, activeTab, onChangeTab, permissions }) => {
    useEffect(() => {
        if(!permissions.includes('admin_help-pages_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    return (
        <CheckPermission permission="admin_help-pages_crud">
            <Title
                title={t("Help Pages")}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            />

            <Tabs
                activeTab={activeTab}
                onClickTabItem={index => {
                    if (activeTab !== index) {
                        onChangeTab(index);
                    }
                }}
            >
                <Tab label={t("For Internal Users")}>
                    <ServiceProvider value={paidAccounts}>
                        <TabViewDetail
                            customIcon="icon_external_link"
                            onClickCustom={onClickCustom}
                            onClickDelete={onClickDelete}
                            onClickEdit={onClickEdit}
                        />
                    </ServiceProvider>
                </Tab>
                <Tab label={t("For External Users")}>
                    <ServiceProvider value={freeAccounts}>
                        <TabViewDetail
                            customIcon="icon_external_link"
                            onClickCustom={onClickCustom}
                            onClickDelete={onClickDelete}
                            onClickEdit={onClickEdit}
                        />
                    </ServiceProvider>
                </Tab>
            </Tabs>
        </CheckPermission>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        title: state.listState.pagination.total,
        activeTab: getStoreItem(state, "activeTab")
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
