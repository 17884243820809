import { requestData } from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { colorClass, getTableView } from "./TableBodyService";

export default id => ({
    requestData: requestData(`/product/product-finder/${id}/costs`),
    getTableView,
    getStoreItem,
    getActionStore,
    colorClass,
});
