import {get} from "lodash";

export const getTableLabel = (key) => {
    const tableLabel = {
        name: "Publication Name",
        slug: "Publication ID",
    };
    return get(tableLabel, key, key);
};

export const getFilter = () => "";

export const isSortEnabled = key => (["name", "slug"].includes(key));
