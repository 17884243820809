export const technicalTableColumns = {
    property_name: "Property",
    parameter1_name: "Parameter 1",
    parameter2_name: "Parameter2",
    parameter3_name: "Parameter 3"
};

export const processTableColumns = {
    manufacturing_name: "Manufacturing Process",
    parameter_name: "Process Parameter",
    description_name: "Description",
};
