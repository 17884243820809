import React, {Component} from "react";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";
import _ from "lodash";
import {compose} from "redux";
import PropTypes from "prop-types";
import {Div} from "Templates/Default";
import {Tooltip} from "Templates/Form";
import Img, {ImgStorage} from "Templates/Img";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import attachFile from "Services/FileService";
import {withTagDefaultProps} from "Hoc/Template";
import {getCroppedImg} from "./Service/CropService";

const defaultProps = {
    defaultImg: "form-logo-square-min",
    textButton: "Upload Image",
    width: 320,
    height: 320,
    isZoomRange: false
};

const propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    isZoomRange: PropTypes.bool,
    defaultImg: PropTypes.string,
    textButton: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

const { Button, Form } = ModalDefault;
class BlockImgWithCrop extends Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            croppedAreaPixels: {},
            image: "",
            crop: { x: 0, y: 0 },
            zoom: 1,
            rotation: 0,
            aspect: 1,
        };
    }

    fileChange = async e => {
        function readFile(file) {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.addEventListener("load", () => resolve(reader.result), false);
                reader.readAsDataURL(file);
            });
        }
        if (e.target.files && e.target.files.length > 0) {
            const imageDataUrl = await readFile(e.target.files[0]);
            this.setState({
                cropperOpen: true,
                image: imageDataUrl,
                crop: { x: 0, y: 0 },
                zoom: 1,
            });
        }
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({
            croppedAreaPixels,
        });
    };

    onZoomChange = zoom => {
        this.setState({ zoom });
    };

    onClose = () => {
        this.fileRef.current.value = "";
        this.setState({
            cropperOpen: false,
        });
    };

    handleClickOnUploadPhoto = () => {
        this.fileRef.current.click();
    };

    showCroppedImage = async () => {
        try {
            const { image, croppedAreaPixels, rotation } = this.state;
            const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
            const { onChange, name } = this.props;
            attachFile(croppedImage).then(
                src => {
                    this.src = src;
                    onChange(croppedImage, name);
                    this.fileRef.current.value = "";
                    this.onClose();
                },
                () => {
                    this.src = croppedImage;
                    onChange(croppedImage, name);
                },
            );
        } catch (e) {
            console.error(e);
        }
    };

    deleteCroppedImage = async event => {
        event.preventDefault();
        const { onChange, name } = this.props;
        onChange("", name);
        this.fileRef.current.value = "";
    };

    renderPreview = value => {
        if (!value) {
            const { defaultImg } = this.props;
            return <Img img={defaultImg} />;
        }

        if (_.isString(value)) {
            return <ImgStorage url={value} />;
        }

        return <img src={this.src} alt="logo" />;
    };

    render = () => {
        const { cropperOpen, image, crop, zoom, aspect } = this.state;
        const { pref, textButton, title, width, height, isZoomRange, value, t } = this.props;

        return (
            <>
                <ReactModal
                    isOpen={cropperOpen}
                    shouldCloseOnOverlayClick={false}
                    ariaHideApp={false}
                    overlayClassName="main-modal"
                    className="main-modal-content main-modal__form-crop-wrap"
                    onRequestClose={this.onClose}
                >
                    <Form>
                        <ButtonClose className="main-modal__form-close" onClick={this.onClose} />
                        <Div className="main-modal__form-crop">
                            <Cropper
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={aspect}
                                restrictPosition={false}
                                cropSize={{ width, height }}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                            />
                        </Div>
                        {isZoomRange ? <div className="controls">
                            <input
                                type="range"
                                value={zoom}
                                min={0.1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e) => {
                                    this.setState({
                                        zoom: e.target.value
                                    })
                                }}
                                className="zoom-range"
                            />
                        </div> : null}
                        <Div className="gray-text">
                            {t("For assistance contact: ")}
                            <a href="mailto:support@comopps.com">support@comopps.com</a>
                        </Div>
                        <Button onClick={this.showCroppedImage}>{t("Save")}</Button>
                    </Form>
                </ReactModal>
                <div className={`${pref}__form-block-logo main-content__form-block-logo`}>

                    { title
                        ? (
                            <>
                                <span className={`${pref}__form-logo-title main-content__form-logo-title`}>{title}</span>
                                <Tooltip>{t("Recommended min image size 160 x 160px.")}</Tooltip>
                            </>
                        )
                        : null
                    }

                    <div className={`${pref}__form-logo-wrap main-content__form-logo-wrap`}>
                        <span className={`${pref}__form-logo-square main-content__form-logo-square main-square-logo`}>
                            {this.renderPreview(value)}
                        </span>
                        {value ? (
                            <button
                                type="button"
                                className="main-content__form-logo-delete"
                                onClick={this.deleteCroppedImage}
                            >
                                <i className="la la-plus" />
                            </button>
                        ) : null}
                    </div>

                    <input
                        type="file"
                        accept="image/*"
                        ref={this.fileRef}
                        onChange={this.fileChange}
                        className="visually-hidden"
                    />

                    <button
                        type="button"
                        onClick={this.handleClickOnUploadPhoto}
                        className={`${pref}__form-logo-btn main-content__form-logo-btn main-btn`}
                    >
                        {t(textButton)}
                    </button>
                </div>
            </>
        );
    };
}

BlockImgWithCrop.defaultProps = defaultProps;
BlockImgWithCrop.propTypes = propTypes;

export default compose(withTagDefaultProps())(BlockImgWithCrop);
