import React from "react";
import {countryListObj, formatDate, getString} from "Services";
import {
    datasheetLocation,
    datasheetPaintable,
    gradeFileTypes,
    gradeStatus,
    industryOptions,
    isMolded,
    isBoundable
} from "Services/enum";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import _ from "lodash";

const propertyName = [
    { value: 'material_number', label: "Material Number" },
    { value: 'sub_status', label: "Product Status" },
    { value: 'product_code', label: "Product Code" },
    { value: 'color', label: "Color" },
    { value: 'approval', label: "OEM Approval" },
    { value: 'approval_name', label: "OEM Approval" },
    { value: 'color_code', label: "Color Code" },
    { value: 'application_code', label: "Application Code" },
    { value: 'project_market_segment', label: "Project Market Segment" },
    { value: 'project_segment', label: "Project Segment" },
    { value: 'project_sub_segment', label: "Project Sub-Segment" },
    { value: 'market_segment', label: "Market Segment" },
    { value: 'segment', label: "Segment" },
    { value: 'sub_segment', label: "Sub-Segment" },
    { value: 'location', label: "Vehicle Location" },
    { value: 'molded', label: "Mold-in-Color" },
    { value: 'boundable', label: "Bondable" },
    { value: 'customer_code', label: "Customer Code" },
    { value: 'line', label: "Production Line" },
    { value: 'sap_plant', label: "Plant" },
    { value: 'sap_line', label: "Production Line" },
    { value: 'process_property', label: "Manufacturing Process" },
    { value: 'process_parameter', label: "Process Parameter" },
    { value: 'user', label: "Owner" },
    { value: 'project_slug', label: "Project ID" },
    { value: 'project_name', label: "Project Name" },
    { value: 'material_type', label: "Material Type" },
    { value: 'updated_at', label: "Date Updated" },
    { value: 'part_approval', label: "Part Approval" },
    { value: 'part_approval_legacy', label: "Part Approval - Legacy" },
    { value: 'part_approval_custom', label: "Part Approval - Custom" },
    { value: 'publication_id', label: "Publication ID" },
];

export const modifierValues = items => items;

export const tableButtonsPermission = "grade_product_finder_edit";

const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
};

const getPropertyName = name => {
    const property = propertyName.find(el => el.value === name);
    const needle = property !== undefined ? property.label : undefined;

    return needle || capitalizeFirstLetter(name);
};

const showData = (elem) => {
    let { values } = elem;
    const { property } = elem;

    if (property === 'country') {
        values = values.map(item => {
            return _.get(countryListObj, item);
        });
    }

    if (property === 'material_type') {
        values = values.map(item => {
            return item.replace(/[\[\]"]/g, '')
        })
    }

    if (property === 'paintable') {
        values = values.map(item => {
            return datasheetPaintable.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'project_market_segment' || property === 'market_segment') {
        values = values.map(item => {
            return industryOptions.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'location') {
        values = values.map(item => {
            return datasheetLocation.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'molded') {
        values = values.map(item => {
            return isMolded.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'boundable') {
        values = values.map(item => {
            return isBoundable.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'publication') {
        values = values.map(item => {
            return gradeFileTypes.find(el => el.value.toString() === item.toString()).label;
        });
    }

    return `${getPropertyName(property)}: ${values.join(', ')}`;
};

export const getViewItem = (key, item) => {

    switch (key) {
        case "grade":
            const foundBy = _.get(item, 'found_by', []);

            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key)}
                        href={`/product/product-finder/${getString(item, "grade_id")}`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>

                    {foundBy.length !== 0 && <TableRowItemSpan className="main-text-bold">Found By:</TableRowItemSpan>}

                    {foundBy.map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={showData(elem)}>
                            {showData(elem)}
                        </TableRowItemSpan>
                    ))}
                </>
            );

        case "count_projects":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "grade_id")}?activeTab=5`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "count_invoices":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "grade_id")}?activeTab=10`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "updated_at":
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );

        case "sub_status":
            const subItem = gradeStatus.find(el => el.value.toString() === getString(item, key).toString());
            const subItemLabel = subItem && subItem.label;

            return subItemLabel && <TableRowItemStatus status={subItemLabel} list={gradeStatus}/>
                || <TableRowItemSpan>-</TableRowItemSpan>;

        case "sap_plant":
        case "sap_line": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={index} title={elem.name}>{elem.name}</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        case "material_type": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={index} title={elem}>{elem}</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
