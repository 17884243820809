import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import TableRowColumnDefault from "./../../../../../Services/Tabs/Files/Table/DefaultWrap.js";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import { withServiceConsumer } from "Services/Context";
import TableRowItem from "./TableRowItem";

const propTypes = {
    column: PropTypes.string.isRequired,
    customIcon: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const TableRowColumn = React.memo(({ index, customIcon, service: { showEditButton }, onClickItem, secondaryIndex, column, item, onClickDelete, onClickEdit, onClickCustom }) => {
    return (
        <TableRowColumnTemplate key={`TableRowColumn-${index}-${secondaryIndex}`}>
            {column === "-1" && !item.default_folder ? (
                <TableRowItem
                    customIcon={customIcon}
                    showEditButton={showEditButton(item)}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    onClickCustom={onClickCustom}
                    index={secondaryIndex}
                />
            ) : (
                <TableRowColumnDefault column={column} item={item} onClickItem={onClickItem} />
            )}
        </TableRowColumnTemplate>
    );
});

TableRowColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableRowColumn);
