import Constants from "../Constants";

export const initState = {
    errors: {},
    loading: false,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.STATUS_REQUESTED: {
            return { ...state, loading: true };
        }

        case Constants.STATUS_SUCCEEDED: {
            return { ...state, errors: {}, view: { ...state.view, sub_status: data }, loading: false };
        }

        case Constants.STATUS_FAILED: {
            return { ...state, errors: { ...data }, loading: false };
        }

        default:
            return state;
    }
};
