import _ from "lodash";

export const formData = data => {
    const dataReq = {
        filter: {
            value: _.get(data, "marginListSelect"),
            per: _.get(data, "dynamicSelect"),
            time_scale: _.get(data, "timeScale"),
            period_from: _.get(data, "periodFrom"),
            period_to: _.get(data, "periodTo"),
            items: _.get(data, "items").map(elem => elem.value),
        },
    };

    if (_.get(data, "statusProject", "")) {
        dataReq.filter.status = _.get(data, "statusProject");
    }

    return dataReq;
};
