import React from "react";
import { projectStatusList } from "Services/enum";
import { Span } from "Templates/Default";
import { getStoreItem, getActionStore } from "./StoreService";
import { requestData, requestPostData } from "../../RequestService";

const getViewBelowStatus = item => <Span className="status__value">{`${item.value}%`}</Span>;

export default id => ({
    requestData: requestData(`/project/projects/${id}/general`),
    requestOnChangeStatus: requestPostData(`/project/projects/${id}/change-sub-status`),
    addContact: requestPostData(`/project/projects/${id}/change-contacts`),
    addInternalTeam: requestPostData(`/project/projects/${id}/change-internal-team`),
    getStoreItem,
    getActionStore,
    projectStatusList,
    getViewBelowStatus
});
