import {formDataNormalizer} from "Services";

export const formData = ({ manufacturer, unitName, owner, years }) => {
    const yearsArr = Object.keys(years);

    const monthEnum = {
        January: "jan_value",
        February: "feb_value",
        March: "mar_value",
        April: "apr_value",
        May: "may_value",
        June: "jun_value",
        July: "jul_value",
        August: "aug_value",
        September: "sep_value",
        October: "oct_value",
        November: "nov_value",
        December: "dec_value",
    };

    const dataReq = {
        manufacturer_value: manufacturer,
        unit_name_value: unitName,
        user_id: owner,
        months_quantity: {},
    };

    yearsArr.forEach(year => {
        dataReq.months_quantity[year] = Object.keys(years[year]).reduce(
            (acc, elem) => ({ ...acc, [monthEnum[elem]]: years[year][elem] }),
            {},
        );
    });

    return formDataNormalizer(dataReq);
};
