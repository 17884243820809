import _ from "lodash";
import Constants from "../Constants";
import {url} from "Services";

export const initState = {
    items: [],
    total_analytics: [],
    pagination: {},
    sort: {},
    columns: [],
    financialYear: +url.get('financial_year'),
    pinned_columns: [],
    errors: {},
    tab: 0,
    sandboxData: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.TABLE_SORT:
            return { ...state, items: data.items, pinned_columns: data.pinned_columns, sort: data.sort, pagination: data.pagination, total_analytics: data.total_analytics };

        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return { ...state, ...data, loading: false };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.NEXT_PAGE:
            return { ...state, items: _.concat(state.items, data.items), pagination: data.pagination, loading: false };

        default:
            return state;
    }
};
