import { request, url } from "Services";

const listUrl = "/notification/notifications";

const getItems = data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestNotifications = data => {
    return new Promise(resolve => {
        getItems(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
            });
        });
    });
};

const postItem = data => {
    const sendObj = {
        url: url.getUrl(`/notification/notifications/viewed/${data}`),
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const requestOnReadNotifications = data => {
    return new Promise(resolve => {
        postItem(data).then(res => resolve(res[0].has_notifications));
    });
};
