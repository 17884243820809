import ForecastConstants from "./ForecastConstants";
import GeneralConstants from "./GeneralConstants";
import FilesConstants from "./FilesConstants";
import CostConstants from "./CostConstants";
import NotesConstants from "./NotesConstants";
import ChangesHistoryConstants from "./ChangesHistoryConstants";

const Constants = {
    forecast: { ...ForecastConstants },
    general: { ...GeneralConstants },
    files: { ...FilesConstants },
    cost: { ...CostConstants },
    notes: { ...NotesConstants },
    changesHistory: { ...ChangesHistoryConstants },
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    CHANGE_STATUS_REQUESTED: "CHANGE_STATUS_REQUESTED",
    CHANGE_STATUS_SUCCEEDED: "CHANGE_STATUS_SUCCEEDED",
    CHANGE_STATUS_FAILED: "CHANGE_STATUS_FAILED",

    FETCH_ITEM_REQUESTED: "FETCH_ITEM_REQUESTED",
    FETCH_ITEM_SUCCEEDED: "FETCH_ITEM_SUCCEEDED",
    FETCH_ITEM_FAILED: "FETCH_ITEM_FAILED",

    RESET_FORM: "RESET_FORM",

    SET_EXPANDED: 'SET_EXPANDED',
    SET_COLLAPSED: 'SET_COLLAPSED',
    EXPANDED_BLOCKS: {
        general: true,
        product: true,
        marketSegment: true,
        forecast: true,
        part: true,
        supplyChain: true,
        project: true,
        specifications: true,
        description: true,
    }
};
export default Constants;
