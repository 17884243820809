import { request, url } from "Services";

const getItems = (data, requestUrl) => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestGet = requestUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, requestUrl).then(
            res => {
                resolve({ ...res[0] });
            },
            error => reject(error),
        );
    });
};
