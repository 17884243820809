import React, {Component} from "react";
import PropTypes from "prop-types";
import {ServiceProvider} from "Services/Context";
import {Sidebar} from "App/View/Sidebar";

const propTypes = {
    mainContentHeight: PropTypes.string,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
};

class Content extends Component {
    state = {
        sidebarIsOpen: localStorage.getItem("ComOppsSidebarIsOpen") || true,
    };

    handleClickOnSome = () =>
        // TODO: the function name
        {
            this.setState(prevState => {
                localStorage.setItem("ComOppsSidebarIsOpen", !prevState.sidebarIsOpen);
                return {
                    sidebarIsOpen: !prevState.sidebarIsOpen,
                };
            });
        };

    render() {
        const { sidebarIsOpen } = this.state;
        const { mainContentHeight, service, children } = this.props;

        return (
            <div
                className={
                `main-wrap__content ${sidebarIsOpen ? "active-sidebar" : ""}
                 ${window.location.pathname.includes("/project/projects/") ? 'project-main-wrap__content' : ''
                }`}
                style={{ height: mainContentHeight }}
            >
                <Sidebar onClickOnSome={this.handleClickOnSome} />
                <div className={`${window.location.pathname.includes("/admin/publications") || window.location.pathname.includes("/admin/solutions")
                    ? 'publications-content' : ''} ${window.location.pathname.includes("/project/projects/") && !window.location.pathname.includes("create") && !window.location.pathname.includes("edit") ? 'project-view-content' : ''} main-content`}>
                    <ServiceProvider value={service}>{children}</ServiceProvider>
                </div>
            </div>
        );
    }
}

Content.propTypes = propTypes;

export default Content;
