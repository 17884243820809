import { projectStatusList } from "Services/enum";
import { getTableView, colorClass } from "./ViewService";
import { getStoreItem, getActionStore } from "./StoreService";
import { requestGetGrade, requestPostData } from "../../RequestService";

export default id => ({
    requestGetGrade: requestGetGrade(`/project/projects/${id}/cost/grades`),
    requestOnChangeStatus: requestPostData(`/project/projects/${id}/change-sub-status`),
    getTableView,
    getStoreItem,
    getActionStore,
    colorClass,
    projectStatusList,
});
