import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const { productCode, applicationCode, plant, materialType, id, years } = props;
    const initData = { productCode, applicationCode, plant, materialType, id, years: _.isArray(years) && !years.length ? {} : years }

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "connection-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: { ..._.cloneDeep(initData) } },
    };

    return _configureStore(initialState, reducers);
}
