import React from "react";
import { useSelector } from "react-redux";
import {
    formatDate,
    getStoreItem as _getStoreItem,
    getString,
    numberCurrenciesWithCommas,
    numberWithCommas
} from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { weightUnitName } from 'Services/weightUnitName';
import { getJson } from "Services/StringService";
import { isYesNo } from "Services/enum";
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";

const getStoreItem = _getStoreItem("listState.raws");

const title = "Raw Materials";

const getTableLabel = key => {
    const unit = useSelector(state => state.weightUnitState);

    const tableLabel = {
        name: "SAP Material Code",
        legacy_material_code: "Legacy SAP Material Code",
        description: "SAP Material Name",
        selling_plant: "Selling Plant",
        map_cost: `MAP ($/${weightUnitName(unit.weightUnit)})`,
        iso_name: 'ISO Certificate Name',
        iso_expiration_date: 'ISO Certificate Expiration Date',
        manufacturer: 'Manufacturer',
        vendor_code: 'Vendor Code',
        distributor: 'Distributor',
        distributor_vendor_code: 'Distributor Vendor Code',
        material_type: 'Material Type',
        lead_time: 'Lead Time',
        standard_packaging: 'Standard Packaging',
        delivery_terms: 'Delivery Terms',
        freight_class: 'Freight Class',
        effective_from_date: 'Effective From',
        quantity_per_unit: 'Per (unit)',
        unit: 'Unit',
        scale_quantity: 'Scale Quantity (from)',
        purchase_price: 'Price',
        note: 'Notes',
        date_update: 'Change Date',
        obsolete_marker: "Obsolete Marker",
        deletion_marker: "Deletion Marker",
        sap_value: "SAP Import",
    };

    return getString(tableLabel, key, key);
};

export const getViewItem = (key, item) => {
    const mapRowItem = (elem) => {
        let currCost = getString(elem, 'cost_per_lbs_curr', 0);
        let prevCost = getString(elem, 'cost_per_lbs_prev', 0);
        const index = getString(elem, 'id');

        if (!currCost && prevCost >= 0) {
            currCost = prevCost;
        }

        if (!prevCost && currCost >= 0) {
            prevCost = currCost;
        }

        if (prevCost === currCost) {
            return (
                <TableRowItemSpan
                    key={index}
                    className="text-right"
                    title={`$${numberWithCommas(currCost, 3)}`}
                >
                    {`$${numberWithCommas(currCost, 3)}`}
                    <span className="statistic-icon">&nbsp;</span>
                </TableRowItemSpan>
            );
        }

        const className = currCost > prevCost ? 'clr-red' : 'clr-green';
        const iconClass = currCost > prevCost ? '' : 'rotated';

        return (
            <TableRowItemSpan key={index} title={`$${numberWithCommas(currCost, 3)}`} className="text-right">
                {`$${numberWithCommas(currCost, 3)}`}
                <span className={`statistic-icon ${iconClass}`}>
                    <i className={`fas fa-sort-up ${className}`}/>
                </span>
            </TableRowItemSpan>
        );
    };

    switch (key) {
        case "name":
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, key)}
                    href={`/product/raw/${getString(item, "id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case 'selling_plant': {
            const items = getJson(item, 'selling_plants');

            return (
                items.length
                    ? (
                        <>
                            {items.map((elem) => (
                                <TableRowItemLink
                                    blank
                                    key={getString(elem, 'id')}
                                    title={getString(elem, 'plant')}
                                    href={`/product/plants/${getString(elem, 'id')}`}
                                >
                                    {getString(elem, 'plant')}
                                </TableRowItemLink>
                            ))}
                        </>
                    )
                    : '-'
            );
        }

        case 'map_cost': {
            const items = getJson(item, 'selling_plants');

            return (
                items.length
                    ? (
                        <>
                            {items.map((elem) => mapRowItem(elem))}
                        </>
                    )
                    : <TableRowItemSpan className="text-right">-</TableRowItemSpan>
            );
        }

        case"sap_value":
            return <TableRowItemSpan>{getString(item, key) ? 'Yes' : 'No'}</TableRowItemSpan>;

        case"obsolete_marker":
        case"deletion_marker": {
            const items = getJson(item, 'selling_plants');

            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => {
                                const yesNoData = isYesNo.find(k => k.value.toString() === getString(elem, key).toString())

                                return (
                                    <TableRowItemSpan key={index}>
                                        {getString(yesNoData, 'label', '-')}
                                    </TableRowItemSpan>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case 'note':
            return (
                item.note
                    ? <div className="publication-row"><TableRowItemTooltip>{item.note}</TableRowItemTooltip></div>
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );

        case "created_date":
        case "date_update":
        case "iso_expiration_date": {
            const date = getString(item, key, '') ? formatDate(getString(item, key)) : '-';

            return (
                <TableRowItemSpan title={date}>{date}</TableRowItemSpan>
            );
        }

        case "effective_from_date": {
            const items = getJson(item, 'periods');

            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => {
                                const len = (getString(elem, 'scale_quantities') || []).length;
                                const value = getString(elem, 'effective_from_date', '')
                                const values = Array(len).fill('');
                                values[0] = value ? formatDate(value) : '-';

                                return (
                                    <div key={index} className="period-block">
                                        {values.map((date, subIndex) => {
                                            return (
                                                <TableRowItemSpan
                                                    key={`${index}_${subIndex}`}
                                                    className="period-item"
                                                    title={date}
                                                >
                                                    {date}
                                                </TableRowItemSpan>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case "quantity_per_unit": {
            const items = getJson(item, 'periods');

            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => {
                                const len = (getString(elem, 'scale_quantities') || []).length;
                                const value = getString(elem, 'quantity_per_unit', '')
                                const values = Array(len).fill('');
                                values[0] = value || '-';

                                return (
                                    <div key={index} className="period-block">
                                        {values.map((date, subIndex) => {
                                            return (
                                                <TableRowItemSpan
                                                    key={`${index}_${subIndex}`}
                                                    className="period-item"
                                                    title={date}
                                                >
                                                    {date}
                                                </TableRowItemSpan>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case "unit": {
            const items = getJson(item, 'periods');

            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => {
                                const len = (getString(elem, 'scale_quantities') || []).length;
                                const value = getString(elem, 'unit', '')
                                const values = Array(len).fill('');
                                values[0] = value || '-';

                                return (
                                    <div key={index} className="period-block">
                                        {values.map((date, subIndex) => {
                                            return (
                                                <TableRowItemSpan
                                                    key={`${index}_${subIndex}`}
                                                    className="period-item"
                                                    title={date}
                                                >
                                                    {date}
                                                </TableRowItemSpan>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case "scale_quantity": {
            const items = getJson(item, 'periods');

            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => {
                                const scaleQuantities = getString(elem, 'scale_quantities') || [];

                                return (
                                    <div key={index} className="period-block">
                                        {scaleQuantities.map((scaleQuantity, subIndex) => {
                                            const value = getString(scaleQuantity, 'scale_quantity');

                                            return (
                                                <TableRowItemSpan
                                                    key={`${index}_${subIndex}`}
                                                    className="period-item"
                                                    title={value}
                                                >
                                                    {value}
                                                </TableRowItemSpan>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case "purchase_price": {
            const items = getJson(item, 'periods');

            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => {
                                const scaleQuantities = getString(elem, 'scale_quantities') || [];

                                return (
                                    <div key={index} className="period-block">
                                        {scaleQuantities.map((scaleQuantity, subIndex) => {
                                            const value = getString(scaleQuantity, 'period_price');
                                            const price = `$ ${numberCurrenciesWithCommas(value)}`

                                            return (
                                                <TableRowItemSpan
                                                    key={`${index}_${subIndex}`}
                                                    className="period-item"
                                                    title={price}
                                                >
                                                    {price}
                                                </TableRowItemSpan>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};

export default searchValue => ({
    link: `/search/raws?value=${searchValue}`,
    getStoreItem,
    title,
    getTableLabel,
    getViewItem,
});
