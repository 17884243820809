/*
    *
    * This method cleans the URL from search params.
    *
 */
export const ClearFilterParams = (key) => {
    if (typeof window !== 'undefined' && key) {
        const urlObject = new URL(window.location.href);
        const {searchParams} = urlObject;
        searchParams.delete(`filter[${key}]`)
        const newUrl = `${urlObject.origin}${urlObject.pathname}`
        history.pushState({}, '', newUrl)
    }
}

export const resetFilters = () => {
    if (typeof window !== 'undefined') {
        const urlObject = new URL(window.location.href);
        const {search} = urlObject;
        search.replace(/\?/g, '')
        const newUrl = `${urlObject.origin}${urlObject.pathname}`
        history.pushState({}, '', newUrl)
    }
}