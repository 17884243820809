import React, {Component} from "react";
import Img from "Templates/Img";

class DragAndDrop extends Component {
    dragCounter = 0;

    state = {
        drag: false,
    };

    dropRef = React.createRef();

    componentDidMount() {
        if (!this.dropRef.current) return
        const div = this.dropRef.current;
        div.addEventListener("dragenter", this.handleDragIn);
        div.addEventListener("dragleave", this.handleDragOut);
        div.addEventListener("dragover", this.handleDrag);
        div.addEventListener("drop", this.handleDrop);
    }

    componentWillUnmount() {
        if (!this.dropRef.current) return
        const div = this.dropRef.current;
        div.removeEventListener("dragenter", this.handleDragIn);
        div.removeEventListener("dragleave", this.handleDragOut);
        div.removeEventListener("dragover", this.handleDrag);
        div.removeEventListener("drop", this.handleDrop);
    }

    formatData = files => {
        const maxSize = 500;
        for (let i = 0; i < files.length; i += 1) {
            if (files[i].size >= maxSize * 1024 * 1024) {
                alert("Maximum file size 500Mb");
                return;
            }
        }

        const regex = /\/(mp4|mov|avi|mkv|flv|wmv|mpg|mpeg|webm|quicktime|3gpp)$/i
        for (let i = 0; i < files.length; i += 1) {
            if (!files[i].type.match(regex)) {
                alert("Wrong video format! Video must be one of 'mp4 | mov | avi | mkv | flv | wmv | mpg | mpeg |webm | quicktime | 3gpp' formats.");
                return;
            }
        }

        const { handleDrop } = this.props;
        handleDrop(files);
    };

    handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragIn = e => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true });
        }
    };

    handleDragOut = e => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({ drag: false });
        }
    };

    handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ drag: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.formatData(e.dataTransfer.files);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    };

    onChange = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ drag: false });
        this.formatData(e.target.files);
    };

    render() {
        const { children, files } = this.props;
        const list = React.Children.count(children);
        const file = "";
        return (
            <div className={`main-modal__form-wrap ${list && "main-modal__form-wrap_flex"}`}>
                {!files.length ? <div className="main-modal__form-file" ref={this.dropRef}>
                    <Img img="input_file_img" />
                    <div className="main-modal__form-file-wrap-text">
                        <h4 className="main-modal__form-file-h4">Drag and Drop to Upload</h4>
                        <div className="main-modal__form-file-text">
                            or{" "}
                            <a href="#" className="main-modal__form-file-link">
                                Browse
                            </a>{" "}
                            to choose a file
                        </div>
                        <div className="main-modal__form-file-text">Maximum file size 500Mb</div>
                    </div>
                    <input
                        className="main-modal__form-file-input"
                        type="file"
                        value={file}
                        accept="video/mp4,video/quicktime,.mov,video/x-m4v,video/*"
                        multiple=""
                        onChange={this.onChange}
                    />
                </div> : null}
                {children}
            </div>
        );
    }
}

export default DragAndDrop;