import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import PermissionsProps from "Hoc/Template/PermissionsProps";

const defaultProps = {
    permissions: [],
    permissionList: [],
    permission: "",
};

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string),
    permission: PropTypes.string,
    permissionList: PropTypes.array,
};

const CheckPermission = ({ permissions, permission, children, permissionList }) => {
    if (!permission && !permissionList.length) return children;

    return <>{(permissions.includes(permission) || _.intersection(permissions, permissionList).length) ? children : null}</>;
};

CheckPermission.defaultProps = defaultProps;
CheckPermission.propTypes = propTypes;

export default compose(
    PermissionsProps,
)(CheckPermission);
