import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Contacts = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };

    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxHierarchy activeHierarchy={getPermission("contacts_view", index)} inputClass='first-hierarchy'>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_contacts_view-${index}`}
                        value={getPermission("contacts_view", index)}
                        onChange={value => onChangePermission("contacts_view", value, index)}
                    >
                        <CheckboxTitle title="View/Edit/Delete - Contacts">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Contacts Module.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_contacts_project_view-${index}`}
                        value={getPermission("contacts_project_view", index)}
                        onChange={
                            value => onChangePermission("contacts_project_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Access -  Projects Tab">
{/*                            <Tooltip>
                                {t(
                                    "Access user to Projects tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_contacts_list_hide-${index}`}
                    value={getPermission("contacts_list_hide", index)}
                    onChange={
                        value => onChangePermission("contacts_list_hide", value, index)
                    }
                >
                    <CheckboxTitle title="Hide user - Contacts list">
{/*                        <Tooltip>
                            {t(
                                "Hide user from Contacts lists dropdown.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
        </>
    )
}

Contacts.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Contacts);