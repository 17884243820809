import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { formatDate, getString } from "Services";
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const tableRowItemLink = (labelKey, href) => (
        <TableRowItemLink title={getString(item, labelKey)} href={href}>
            {getString(item, labelKey)}
        </TableRowItemLink>
    );

    switch (key) {
        case "name":
            return (
                <TableRowItemSpan title={getString(item, key)}>
                    {getString(item, key, '')}
                </TableRowItemSpan>
            );

        case "date":
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );

        case "submitted_by":
            return tableRowItemLink("submitted_by", `/admin/users/${getString(item, "submitted_id")}`);

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
