import Constants from "../../../Constants";
import {debounce} from "Services/Debounce";

export const createFilesAction = (service, dispatch) => data => {
    dispatch({ type: Constants.CREATE_FILES_REQUEST });

    const { requestOnUploadFiles } = service;

    return requestOnUploadFiles(data).then(
        res => dispatch({ type: Constants.CREATE_FILES_SUCCESS, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.CREATE_FILES_FAILED, data: res });
        },
    );
};

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort, filter } = data;
    const { requestTable, requestTableProductFiles } = service;

    if(filter && data.hasOwnProperty('path') && data.path !== filter.path){
        filter.path = data.path;
    }

    delete data.path;
    const request = (filter && filter.path == 'product') ? requestTableProductFiles(data) : requestTable(data);

    request.then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: sort || {}, filter: filter || {}}));
        },
        () => {
            dispatch(requestFailed({ items: [] }));
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if ([
        "file_type",
        "modified",
        "visibility"
    ].includes(key)) {
        fetchItems(service, dispatch)(request);
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        () => {
            dispatch(requestFailed({ items: [] }));
        },
    );
};