import React, { useEffect } from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {ServiceProvider, withServiceConsumer} from 'Services/Context';
import {ContentWrap, ContentWrapCol} from 'Templates/Content';
import {CheckPermission, PermissionsProps, withTagDefaultProps} from 'Hoc/Template';
import InfoWrap from './Views/InfoWrap';
import MainBlock from './Views/Tabs/General';
import Tabs from 'Templates/Tabs/Tabs';
import referrals from './Services/Tabs/Referrals';
import general from './Services/Tabs/General';
import notifications from './Services/Tabs/Notifications';
import Referrals from './Views/Tabs/Referrals';
import TabCheck from "Templates/Tabs/TabСheck";
import PersonalDetails from "./Views/Tabs/PersonalDetails";
import Tab from "Templates/Tabs/Tab";
import Notifications from "./Views/Tabs/Notifications";
import {url} from "Services";

const Index = ({activeTab, onChangeTab, t}) => {
    const onTabClick = (index) => (activeTab !== index) && onChangeTab(index);

    return (
        <>
            <ContentWrap>
            <InfoWrap/>
            <ContentWrapCol>
                <Tabs activeTab={ activeTab }
                      onClickTabItem={ onTabClick }>
                    <Tab label={t('Profile')}>
                        <PersonalDetails />
                    </Tab>
                    <TabCheck
                        label={t('General')}
                        permission="profile_general">
                        <ServiceProvider value={ general }>
                            <MainBlock/>
                        </ServiceProvider>
                    </TabCheck>


                    <TabCheck label={t("My Guest Users")} permission="profile_guests">
                        <ServiceProvider value={ referrals }>
                            <Referrals/>
                        </ServiceProvider>
                    </TabCheck>
                    <Tab label={t('Notifications')}>
                        <ServiceProvider value={ notifications }>
                            <Notifications />
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
        </>
    );
};

Index.propTypes = {
    id: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, 'view.id'),
        activeTab: getStoreItem(state, 'activeTab')
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore('onChangeTab', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
