import Constants from "../Constants";

export const createFolderAction = (service, dispatch) => data => {
    dispatch({ type: Constants.CREATE_FOLDER_REQUEST });

    const { requestOnCreateFolder } = service;

    return requestOnCreateFolder(data).then(
        res => dispatch({ type: Constants.CREATE_FOLDER_SUCCESS, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.CREATE_FOLDER_FAILED, data: res });
        },
    );
};

export const editFolderAction = (service, dispatch) => ({ id, name, currentFolder }) => {
    dispatch({ type: Constants.EDIT_FOLDER_REQUEST });

    const { requestOnEditFolder } = service;

    return requestOnEditFolder({ id, name }).then(
        () => {
            if (id === currentFolder) {
                return dispatch({ type: Constants.EDIT_CURRENT_FOLDER_SUCCESS, data: name });
            }
            return dispatch({ type: Constants.EDIT_FOLDER_SUCCESS, data: name });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.EDIT_FOLDER_FAILED, data: res });
        },
    );
};
