import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    let clone = _.pick(data, ['paintable', 'molded', 'is_boundable', 'legal', 'keywords']);

    clone.name = _.trim(data.name);
    clone.file = data.file || '';
    clone.attachment_name = data.attachment_name || '';
    clone.attachment_path = data.attachment_path || '';
    clone.locations = _.without(_.map(data.locations, 'value'), '');
    clone.countries = _.without(_.map(data.countries, 'value'), '');
    clone.suppliers = _.without(_.map(data.suppliers, 'value'), '');

    clone.colors = _.map(data.colors, item => ({
        'color': item.color,
        'items': _.map(item.items, i => ({
            'source': i.source,
            'approval': i.approval,
            'code': i.code
        }))
    }));

    clone.contacts = _.map(data.contacts, function square(v) {
        return {
            'contact': v.contact,
            'role': v.role,
            'role_id': v.role_id
        };
    });

    clone.agreements = _.map(data.agreements, function square(v) {
        return {
            'type': v.type,
            'status': v.status
        };
    });

    clone = _.pickBy(clone, value => !(value === undefined || value === ''));
    clone.note = _.trim(data.note);

    return formDataNormalizer(clone);
};
