import { request, url } from "Services";

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: Array.isArray(res[0].items)
                    ? res[0].items.map((elem, index) => ({ ...elem, frontId: index }))
                    : res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
            });
        });
    });
};
