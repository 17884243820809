import React from "react";
import PropTypes from "prop-types";
import {ButtonClose} from "Templates/Button";
import {ModalDefault} from "Templates/Modals";
import _ from "lodash";

const {Form, ColText} = ModalDefault;

const propTypes = {
    onClose: PropTypes.func.isRequired
};

function MaterialPropertyPreviewModal({tdsData, visible, onClose}) {
    const splitCamelCaseStr = (str) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase())
    };

    const replacements = {'unit2': 'Value (2)', 'unit1': 'Value (1)'};
    const replacedItems = (obj) => {
        return Object.entries(obj).reduce((acc, [key,value]) => {
            const newKey = replacements[key]
            acc[newKey || splitCamelCaseStr(key) ] = value;

            delete acc['Front Id']
            delete acc['Id']

            delete acc['Property'];
            if(!visible.property) {
                delete acc['Property View'];
            }

            delete acc['Parameter 1'];
            if(!visible.parameter1) {
                delete acc['Parameter 1 View'];
            }

            delete acc['Parameter 3'];
            if(!visible.parameter3) {
                delete acc['Parameter 3 View'];
            }

            delete acc['parameter 2'];
            if(!visible.parameter2) {
                delete acc['Parameter 2 View'];
            }

            if(['Unit 1'].includes(newKey || splitCamelCaseStr(key))){
                const valMin = acc['Value Min 1'];
                const valMax= acc['Value Max 1'];
                let valueRes = valMin || valMax;

                if(valMin && valMax) {
                    valueRes = valMin === valMax ? valMin : (valMin + ' - ' + valMax);
                }

                if(visible.unit1) {
                    acc['Value (1)']  = valueRes ? valueRes + ' ' + (obj.unit1View || '') : '';
                }
                delete acc['Unit 1']
                delete acc['Value Min 1']
                delete acc['Value Max 1']
            }

            if(['Unit 2'].includes(newKey || splitCamelCaseStr(key))){
                const valMin = acc['Value Min 2'];
                const valMax = acc['Value Max 2'];
                let valueRes = valMin || valMax;

                if(valMin && valMax) {
                    valueRes = valMin === valMax ? valMin : (valMin + ' - ' + valMax);
                }

                if(visible.unit2) {
                    acc['Value (2)']  = valueRes ? valueRes + ' ' + (obj.unit2View || ''): '';
                }
                delete acc['Unit 2']
                delete acc['Value Min 2']
                delete acc['Value Max 2']
            }

            if(!obj.parameter3) {
                delete acc['Value Min 1']
                delete acc['Value Max 1']
            }

            if(!obj.unit1) {
                delete acc['Value Min 2']
                delete acc['Value Max 2']
            }

            delete acc['Unit 1 View']
            delete acc['Unit 1 View']

            return acc
        }, {})
    }

    const replacedTDSData = tdsData.map(obj => replacedItems(obj))

    const getTableHeaderItems = () => {
        if(replacedTDSData[0]){

            return _.filter(Object.keys(replacedTDSData[0]), (val) => {
                return (visible.property && val === 'Property View') ||
                    (visible.parameter2 && val === 'Parameter 2 View') ||
                    (visible.parameter3 && val === 'Parameter 3 View') ||
                    (visible.parameter1 && val === 'Parameter 1 View') ||
                    (visible.unit1 && (val === 'Value (1)')) ||
                    (visible.unit2 && (val === 'Value (2)'));
            })
        }
        return []
    }

    const headerNames = {
        'Property View' : 'Property',
        'Specimen View' : 'Specimen',
        'Test Condition View' : 'Test Condition',
        'Test Method View' : 'Test Method',
    };

    return (
        <Form className="preview_properties_modal">
            <div>
                <ButtonClose className="main-modal__form-close" onClick={onClose}/>
            </div>
            {
                getTableHeaderItems().length ?
                    (<div className="table-responsive_preview">
                        <table className="technical-table">
                            <thead>
                            <tr>
                                {getTableHeaderItems().map((title) => (
                                    <th key={title}>{headerNames[title] || title}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {replacedTDSData.map((prop, idx) => (
                                <tr key={`${idx}-prop`}>
                                    {Object.values(prop).map((value, key) => (
                                        <td key={`${idx}-${key}-item`}>{value}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>) :
                    (<ColText>Not found active columns</ColText>)
            }

        </Form>
    )
}

MaterialPropertyPreviewModal.propTypes = propTypes;
export default MaterialPropertyPreviewModal;

