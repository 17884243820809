import React from "react";
import _ from "lodash";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import { formatDate } from "Services";
import { taskStatus } from "Services/enum";
import { handleScroll } from "./CommonService";
import { TableTasksActions } from "../Actions";
import { requestGet } from "./RequestService";

const tableLabel = {
    title: "Title",
    due: "Due",
    sub_status: "Status",
};

const getViewItem = (key, item) => {
    switch (key) {
        case "title":
            return (
                <TableRowItemLink title={_.get(item, key)} href={`/tasks/${_.get(item, "id")}`}>
                    {_.get(item, key)}
                </TableRowItemLink>
            );

        case "due":
            const value = _.get(item, key);
            return <TableRowItemSpan title={formatDate(value)}>{formatDate(value)}</TableRowItemSpan>;

        case "sub_status":
            const status = taskStatus.find(elem => elem.value === _.get(item, key)) || {};
            return <TableRowItemStatus status={status.label} list={taskStatus} />;

        default:
            return <TableRowItemSpan title={_.get(item, key)}>{_.get(item, key)}</TableRowItemSpan>;
    }
};

const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableTasksActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export default () => ({
    requestTable: requestGet("/dashboard/table/tasks"),
    getActionStore,
    tableLabel,
    getViewItem,
    handleScroll,
});
