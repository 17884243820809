import * as main from "../../../Services";

export default {
    ...main,
    save: publicationId => main.requestCreate(`/admin/publications/${publicationId}/brochures/add`),
    saveColor: main.requestCreate(`/product/product-finder/color/add`),
    requestLegal: main.requestData(),
    requestContentTemplate: main.requestData(),
    getStoreItem: main.getStoreItem("createState"),
};
