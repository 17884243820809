import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const email = _.get(data, "email", {}).trim();

    const { code, number } = _.get(data, "phone", {});

    const dataReq = {
        firstName: _.get(data, "firstName"),
        lastName: _.get(data, "lastName"),
        title: _.get(data, "title"),
        employer: _.get(data, "employer"),
        photo: _.get(data, "photo"),
        twoFactor: _.get(data, "twoFactor"),
        paid: _.get(data, "paid"),

        code: number ? code : "",
        number,
        email,
        companyList: _.get(data, "team").map(elem => ({
            company: elem.company,
            defaultPage: elem.defaultPage,
            defaultCompany: elem.defaultCompany,
            permissions: elem.permission,
        })),
    };

    return formDataNormalizer(dataReq);
};
