import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    TabBlock,
    TabBlockBtn,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import _ from "lodash";
import { NoteBlock, NotesWrap } from "Templates/Notes";
import NoteTopWrapMod from "./TemplateMod/NoteTopWrapMod";
import NotesWallMod from "./TemplateMod/NotesWallMod";
import NoteMessageWrap from "Templates/Notes/NoteMessageWrap/NoteMessageWrap";
import NoteModal from "./NoteModal";

const NotesWall = NotesWallMod(NotesWrap, NoteBlock, NoteTopWrapMod, NoteMessageWrap);

const defaultProps = {
    total: "0",
};

const type = {
    "ADMIN": 10,
    "PINNED": 5,
    "DEFAULT": 0
};

const propTypes = {
    total: PropTypes.number,
    fetchItems: PropTypes.func.isRequired,
    deleteNote: PropTypes.func.isRequired,
};

class Notes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.item = {};
    }

    componentDidMount() {
        const { fetchItems } = this.props;
        fetchItems({});
    }

    handleOpenModal = (item = {}) => {
        this.item = {
            id: item.id,
            old: item.comment || "",
            type: item.type || type.DEFAULT,
            append: item.comment || ""
        };
        this.setState({ showModal: true });
    };

    handleCloseModal = append => () => {
        const { t } = this.props;
        if (append.length === 0) {
            this.setState({ showModal: false });
            this.item = {};
            return;
        }

        if (window.confirm(t("Are you sure you want to close the modal window?"))) {
            this.setState({ showModal: false });
            this.item = {};
        }
    };

    handleDeleteModal = (item) => {
        const { deleteNote, fetchItems, t } = this.props;

        if (window.confirm(t("Do you want to delete the Note?"))) {
            deleteNote(item).then(() => {
                    if(item.type) {
                        window.location.reload();
                    } else {
                        this.item = {};
                        fetchItems({});
                    }
                }
            );
        }
    };

    cbAfterRequest = (item) => () => {
        const { fetchItems } = this.props;
        if(item.type) {
            window.location.reload();
        } else {
            this.item = {};
            this.setState({ showModal: false });
            fetchItems({});
        }
    };

    render = () => {
        let isLoaded = false;
        const { total, isAdmin, isProductAdminNoteCrud, isProductPinnedNoteCrud, items, t } = this.props;
        const { showModal } = this.state;
        const firstItemType = _.get(items, '0.type');
        const secondItemType = _.get(items, '1.type');

        if (typeof items !== 'undefined') {
            isLoaded = true
        }

        return (
            <>
                <TabWrapTop>
                    <TabBlock>
                        <TabBlockCol>
                            <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                            <TabBlockColTitleH4>{`${numberWithCommas(total, 0)} ${t("Notes")}`}</TabBlockColTitleH4>
                        </TabBlockCol>
                    </TabBlock>
                    <div className="sap-notes-wrap">
                        {isLoaded ? <>
                            {firstItemType !== type.ADMIN && isProductAdminNoteCrud ? (<TabBlockBtn onClick={() => this.handleOpenModal({type: type.ADMIN})}>{t("Add Admin Note")}</TabBlockBtn>) : null}
                            {firstItemType !== type.PINNED && secondItemType !== type.PINNED && isProductPinnedNoteCrud ? (<TabBlockBtn onClick={() => this.handleOpenModal({type: type.PINNED})}>{t("Add Pinned Note")}</TabBlockBtn>) : null}
                        </> : null}
                        <TabBlockBtn onClick={() => this.handleOpenModal({type: type.DEFAULT})}>{t("Add Notes")}</TabBlockBtn>
                    </div>
                </TabWrapTop>
                <NotesWall handleClickOnEditButton={this.handleOpenModal} handleClickOnDeleteButton={this.handleDeleteModal} />
                {showModal && (
                    <NoteModal onClose={this.handleCloseModal} cbAfterRequest={this.cbAfterRequest} item={this.item} />
                )}
            </>
        );
    };
}

Notes.defaultProps = defaultProps;
Notes.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {

    return {
        isAdmin: (state.userState.permissions || []).includes('admin'),
        isProductAdminNoteCrud: (state.userState.permissions || []).includes('product_admin_note_crud'),
        isProductPinnedNoteCrud: (state.userState.permissions || []).includes('product_pinned_note_crud'),
        total: getStoreItem(state, "pagination.total"),
        items: getStoreItem(state, "items"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        deleteNote: getActionStore("deleteNoteAction", service, dispatch)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Notes);
