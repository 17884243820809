import { request, url } from "Services";

const save = (data, requestUrl) => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };
    return request.sendRequest(sendObj);
};

const get = requestUrl => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        type: "GET",
    };

    return request.sendRequest(sendObj, false);
};

export const requestCreate = requestUrl => data => {
    return new Promise((resolve, reject) => {
        save(data, requestUrl).then(res => resolve({ ...res[0] }), error => reject(error));
    });
};

export const requestData = () => listUrl => {
    return new Promise((resolve, reject) => {
        get(listUrl).then(res => {
            resolve(res[0]);
        }, reject);
    });
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};
