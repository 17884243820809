import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { DivTableCard } from "Templates/Table/NewVersion";

import ColumnTableBodyColumnDefault from "Templates/Table/ColumnTableBodyColumnDefault";

const SimpleColumnTableContainer = (TableBodyColumn = ColumnTableBodyColumnDefault) => ({ tableClassName }) => {
    return (
        <DivTableCard className={tableClassName}>
            <TableBodyColumn />
        </DivTableCard>
    );
};

const DefaultSimpleTable = SimpleColumnTableContainer();

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(DefaultSimpleTable);

export const CustomSimpleColumnTable = TableBodyColumn =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(),
    )(SimpleColumnTableContainer(TableBodyColumn));
