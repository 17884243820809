import React from "react";
import _ from "lodash";
import {compose} from "redux";
import PropTypes from "prop-types";
import {formatDateToLocal, storageUrl} from "Services";
import {withTagDefaultProps} from "Hoc/Template";
import {NoteTop, NoteTopDate, NoteTopUser} from "Templates/Notes/index";
import {Div} from "Templates/Default";
import Img from "Templates/Img/Img";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    storage_url: PropTypes.string.isRequired,
};

const TypeNote = {
    "ADMIN":10,
    "PINNED":5,
    "DEFAULT":0,
};

const NoteTopWrapMod = ({ item, storage_url }) => (
    <NoteTop>
        {item.type ?
            (<Div className="note-label">
                {TypeNote.ADMIN === item.type ? (
                    <h3 className="note-label__admin">
                        <Img img="admin-note-icon" className="main-header__add-svg" alt=""/>
                        Admin Note
                    </h3>
                ) : null}
                {TypeNote.PINNED === item.type ? (
                    <h3 className="note-label__pinned">
                        <Img img="pinned-note-icon" className="main-header__add-svg" alt=""/>
                        Pinned Note
                    </h3>
                ) : null}
            </Div>) : null
        }
        {item.company ?
            (<NoteTopUser
                img={storageUrl(_.get(item, "avatar"), storage_url)}
                label={item.name}
                company={item.company}
                role={item.role}
                url={`/admin/users/${_.get(item, "contact_id")}`}
            />) : null}
        {item.date ? (<NoteTopDate>{formatDateToLocal(item.date, "MMM DD, YYYY h:mm A")}</NoteTopDate>) : null}
    </NoteTop>
);

NoteTopWrapMod.propTypes = propTypes;

export default compose(withTagDefaultProps())(NoteTopWrapMod);
