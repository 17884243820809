import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {ServiceProvider, withServiceConsumer} from 'Services/Context';
import {CheckPermission, withTagDefaultProps} from 'Hoc/Template';
import {showMessage, url} from 'Services';
import {Modal} from 'Templates/Modals';
import {ContentWrap, ContentWrapCol, MainButton, TabWrap, TabBlockBtn} from 'Templates/Content';
import selfService from './Services/Tabs/Self';
import InfoWrap from './Views/InfoWrap';
import Products from './Views';
import WelcomeGuestModal from './Views/WelcomeGuestModal';
import ShareFiltersModal from './Views/ShareFiltersModal';
const Grade = (
    {
        activeTab,
        t,
        permissions,
        isFirstLogin,
        welcomeGuestShowModal,
        shareFiltersShowModal,
        handleOpenShareFiltersModal,
        handleCloseShareFiltersModal,
        handleOpenWelcomeGuestModal,
        handleCloseWelcomeGuestModal,
        filterForm,
        onChangeFilterForm,
        filterType,
        request
    }) => {
    const { filter } = request;

    if (
        isFirstLogin
        && permissions.length < 3
        && (permissions.includes('product_publications_guest') || permissions.includes('product_publication_groups_view'))
        && !localStorage.getItem('WelcomeGuestModalWatched')
        && !welcomeGuestShowModal
    ) {
        handleOpenWelcomeGuestModal();
    }

    const closeWelcomeGuestModal = () => {
        localStorage.setItem('WelcomeGuestModalWatched', !welcomeGuestShowModal);
        handleCloseWelcomeGuestModal();
    }

    const onAddNewProduct = () => {
        if (!permissions.includes('admin')) {
            showMessage('To add a new product email support@comopps.com and include the name of the product in the email. Please use the subject heading: "Add New Product"');
            return;
        }
        url.redirect('/product/product-finder/create');
    }

    return (
        <>
            {shareFiltersShowModal && (
                <Modal onRequestClose={handleCloseShareFiltersModal}>
                    <ShareFiltersModal
                        onChange={onChangeFilterForm}
                        filterForm={filterForm}
                        filter={request.filter}
                        sort={request.sort}
                        type={filterType}
                        onClose={handleCloseShareFiltersModal}
                    />
                </Modal>
            )}
            {welcomeGuestShowModal && (
                <Modal onRequestClose={closeWelcomeGuestModal}>
                    <WelcomeGuestModal
                        onClose={closeWelcomeGuestModal}
                    />
                </Modal>
            )}
            <ContentWrap>
                <ServiceProvider value={selfService}>
                    <InfoWrap activeTab={activeTab}/>
                </ServiceProvider>
                <ContentWrapCol>
                    <div className="d-flex flex-col h-100">
                        <div className="product-finder-actions">
                                <div
                                    className={`d-flex ${Object.keys(filter).length !== 0 ? 'shared-filters-btns' : ''}`}
                                >
                                    {Object.keys(filter).length !== 0 && (
                                        <TabBlockBtn
                                            title="Invite users to view filtered products and approved publications in ComOpps."
                                            onClick={handleOpenShareFiltersModal}
                                            className="main-btn_primary main-btn_primary-share-filters ml-auto"
                                        >
                                            {t('Share Filter')}
                                        </TabBlockBtn>
                                    )}
                                    <CheckPermission>
                                        <MainButton onClick={onAddNewProduct} className="main-btn_primary">
                                            {t("Add New Product")}
                                        </MainButton>
                                    </CheckPermission>
                                </div>
                        </div>
                        <TabWrap className={'product_finder_table'}>
                            <ServiceProvider value={selfService}>
                                <Products />
                            </ServiceProvider>
                        </TabWrap>
                    </div>
                </ContentWrapCol>
            </ContentWrap>
        </>
    );
};

Grade.defaultProps = {
    items: []
}

Grade.propTypes = {
    activeTab: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.any),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    showModal: PropTypes.bool.isRequired,
    isFirstLogin: PropTypes.bool.isRequired,
    welcomeGuestShowModal: PropTypes.bool.isRequired,
    shareFiltersShowModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    handleOpenShareFiltersModal: PropTypes.func.isRequired,
    handleCloseShareFiltersModal: PropTypes.func.isRequired,
    handleOpenWelcomeGuestModal: PropTypes.func.isRequired,
    handleCloseWelcomeGuestModal: PropTypes.func.isRequired,
    resetFilterWithoutFetch: PropTypes.func.isRequired,
    getUrlExport: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeFilterForm: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filterForm: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    filterType: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    addSharedProductsPublicationListShowModal: PropTypes.bool.isRequired,
    handleCloseAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    handleAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    addPublicationListShowModal: PropTypes.bool.isRequired,
    handleCloseAddPublicationListModal: PropTypes.func.isRequired,
    handleOpenAddPublicationListModal: PropTypes.func.isRequired,
    addPublicationListForm: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeAddPublicationListForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service }) => {
    const { getStoreItem } = service;
    const activeTab = getStoreItem(state, 'activeTab');
    let filterType = 9;

    return {
        activeTab,
        items: getStoreItem(state, 'items'),
        permissions: getStoreItem(state, 'permissions'),
        showModal: getStoreItem(state, 'showModal'),
        welcomeGuestShowModal: getStoreItem(state, 'welcomeGuestShowModal'),
        shareFiltersShowModal: getStoreItem(state, 'shareFiltersShowModal'),
        addPublicationListShowModal: getStoreItem(state, 'addPublicationListShowModal'),
        addSharedProductsPublicationListShowModal: getStoreItem(state, 'addSharedProductsPublicationListShowModal'),
        isFirstLogin: getStoreItem(state, 'isFirstLogin'),
        addPublicationListForm: { ...getStoreItem(state, 'addPublicationListForm', true) },
        filterType,
        form: { ...getStoreItem(state, 'form', true) },
        filterForm: { ...getStoreItem(state, 'filterForm', true) },
        request: {
            filter: {
                ...getStoreItem(state, 'filter', true),
            },
            sort: {
                ...getStoreItem(state, 'sort'),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore('onChangeTab', service, dispatch),
        handleCloseModal: getActionStore('handleCloseModal', service, dispatch),
        handleOpenModal: getActionStore('handleOpenModal', service, dispatch),
        resetFilterWithoutFetch: getActionStore('resetFilterWithoutFetch', service, dispatch),
        handleOpenShareFiltersModal: getActionStore('handleOpenShareFiltersModal', service, dispatch),
        handleCloseShareFiltersModal: getActionStore('handleCloseShareFiltersModal', service, dispatch),
        handleCloseWelcomeGuestModal: getActionStore('handleCloseWelcomeGuestModal', service, dispatch),
        handleOpenWelcomeGuestModal: getActionStore('handleOpenWelcomeGuestModal', service, dispatch),
        handleCloseAddSharedProductsPublicationsToList: getActionStore('handleCloseAddSharedProductsPublicationsToList', service, dispatch),
        handleAddSharedProductsPublicationsToList: getActionStore('handleAddSharedProductsPublicationsToList', service, dispatch),
        handleCloseAddPublicationListModal: getActionStore('handleCloseAddPublicationListModal', service, dispatch),
        handleOpenAddPublicationListModal: getActionStore('handleOpenAddPublicationListModal', service, dispatch),
        onChangeAddPublicationListForm: getActionStore('onChangeAddPublicationListForm', service, dispatch),
        updateForm: getActionStore('updateForm', service, dispatch),
        onChange: getActionStore('onChangeForm', service, dispatch),
        onChangeFilterForm: getActionStore('onChangeFilterForm', service, dispatch),
        getUrlExport: getActionStore('getUrlExport', service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Grade);
