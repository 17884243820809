import { handleScroll } from "../../EventsService";
import { requestTable } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreSerivce";
import { getFilter, getTableLabel, isSortEnabled } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/admin/ihs/${id}/projects`),
    getViewItem,
    getFilter: getFilter(id),
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
    isSortEnabled,
});
