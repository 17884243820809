import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import {Title} from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";
import _ from "lodash";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    employer: PropTypes.string.isRequired,
    photo: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
    email: PropTypes.string.isRequired,
    twoFactor: PropTypes.bool.isRequired,
    paid: PropTypes.bool.isRequired,
    phone: PropTypes.shape({
        code: PropTypes.string,
        number: PropTypes.string,
    }).isRequired,
    submitForm: PropTypes.func.isRequired,
}

const User = ({ team, firstName, lastName, title, photo, email, twoFactor, paid, employer, phone, validate, pref, t, submitForm, permissions }) => {
    useEffect(() => {
        if(!permissions.includes('admin_users_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    const handleClickOnSave = () => {
        team = _.filter(team, {haveAccess: true});
        submitForm({ team, firstName, lastName, title, employer, photo, email, twoFactor, paid, phone }).then(
            data => {
                url.redirect(`/admin/users/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (window.confirm(t("Cancel?"))) {
            url.redirect("/admin/users");
        }
    };

    return (
        <CheckPermission permission="admin_users_crud">
            <Title
                title={t("Add New User")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validate} onClick={handleClickOnSave}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </CheckPermission>
    );
};

User.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    team: getStoreItem(state, `team`),
    title: getStoreItem(state, "title"),
    employer: getStoreItem(state, "employer"),
    firstName: getStoreItem(state, "firstName"),
    lastName: getStoreItem(state, "lastName"),
    photo: getStoreItem(state, "photo"),
    validate: getStoreItem(state, `form_validate`),
    email: getStoreItem(state, `email`),
    twoFactor: getStoreItem(state, `twoFactor`),
    paid: getStoreItem(state, `paid`),
    phone: {
        code: getStoreItem(state, `phone.code`),
        number: getStoreItem(state, `phone.number`),
    },
});

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(User);
