const Constants = {
    /* table */
    CHANGE_FILTER: "CHANGE_FILTER",
    CHANGE_ITEM: "CHANGE_ITEM",
    RESET_SELECTED: "RESET_SELECTED",
    CHANGE_ITEM_MY: "CHANGE_ITEM_MY",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",
    /* table */

    CLOSE_MODAL: "CLOSE_MODAL",

    DELETE_PRODUCT: "DELETE_PRODUCT",
    DELETE_FORECAST: "DELETE_FORECAST",

    CHANGE_FORM: "CHANGE_FORM",
    CHANGE_PRODUCT: "CHANGE_PRODUCT",
    CHANGE_GRADE: "CHANGE_GRADE",
    ADD_NEW_PRODUCT: "ADD_NEW_PRODUCT",
    CHANGE_FORECAST: "CHANGE_FORECAST",
    CHANGE_VOLUME: "CHANGE_VOLUME",

    CHANGE_FORECAST_DATE_REQUESTED: "CHANGE_FORECAST_DATE_REQUESTED",
    CHANGE_FORECAST_DATE_SUCCEEDED: "CHANGE_FORECAST_DATE_SUCCEEDED",
    CHANGE_FORECAST_DATE_FAILED: "CHANGE_FORECAST_DATE_FAILED",

    // CHANGE_GRADE_REQUESTED: "CHANGE_GRADE_REQUESTED",
    // CHANGE_GRADE_SUCCEEDED: "CHANGE_GRADE_SUCCEEDED",
    // CHANGE_GRADE_FAILED: "CHANGE_GRADE_FAILED",

    CHANGE_PLANT_REQUESTED: "CHANGE_PLANT_REQUESTED",
    CHANGE_PLANT_SUCCEEDED: "CHANGE_PLANT_SUCCEEDED",
    CHANGE_PLANT_FAILED: "CHANGE_PLANT_FAILED",

    SUBMIT_PERFOMACE_REQUESTED: "SUBMIT_PERFOMACE_REQUESTED",
    SUBMIT_PERFOMACE_SUCCEEDED: "SUBMIT_PERFOMACE_SUCCEEDED",
    SUBMIT_PERFOMACE_FAILED: "SUBMIT_PERFOMACE_FAILED",

    SUBMIT_MATERIAL_REQUESTED: "SUBMIT_MATERIAL_REQUESTED",
    SUBMIT_MATERIAL_SUCCEEDED: "SUBMIT_MATERIAL_SUCCEEDED",
    SUBMIT_MATERIAL_FAILED: "SUBMIT_MATERIAL_FAILED",

    SUBMIT_APPLICATION_REQUESTED: "SUBMIT_APPLICATION_REQUESTED",
    SUBMIT_APPLICATION_SUCCEEDED: "SUBMIT_APPLICATION_SUCCEEDED",
    SUBMIT_APPLICATION_FAILED: "SUBMIT_APPLICATION_FAILED",

    CHANGE_SUB_SEGMENT: "CHANGE_SUB_SEGMENT",
    CHANGE_APPLICATION_CODE_REQUESTED: "CHANGE_APPLICATION_CODE_REQUESTED",
    CHANGE_APPLICATION_CODE_SUCCEEDED: "CHANGE_APPLICATION_CODE_SUCCEEDED",
    CHANGE_APPLICATION_CODE_FAILED: "CHANGE_APPLICATION_CODE_FAILED",

    CHANGE_CUSTOMER_REQUESTED: "CHANGE_CUSTOMER_REQUESTED",
    CHANGE_CUSTOMER_SUCCEEDED: "CHANGE_CUSTOMER_SUCCEEDED",
    CHANGE_CUSTOMER_FAILED: "CHANGE_CUSTOMER_FAILED",

    FORM_REQUESTED: "FORM_REQUESTED",
    FORM_SUCCEEDED: "FORM_SUCCEEDED",
    FORM_FAILED: "FORM_FAILED",
    FORM_RESET: "FORM_RESET",
};

export default Constants;
