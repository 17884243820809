import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const {
        id,
        product_id,
        isSap,
        generalForm,
        colors,
        colorsPartApproval,
        sapForm,
        paintable,
        molded,
        is_boundable,
        locations,
        applicationBlock
    } = props;

    const initialState = {
        ...editState,
        id,
        product_id,
        applicationBlock,
        paintable,
        molded,
        is_boundable,
        locations: (locations.length ? locations : [{ frontId: 0, value: "" }]).map(color => ({
            frontId: color.frontId || 0,
            value: color.value || ''
        })),
        isSap,
        colors: (colors || [{frontId: 0, color: '', items: []}]).map(color => ({
            frontId: color.frontId || 0,
            color: color.color || '',
            items: color.items.length ? color.items : [{frontId: 0, approval: '', code: '', source: ''}]
        })),
        colorsPartApproval: (colorsPartApproval || [{frontId: 0, color: '', items: []}]).map(color => ({
            frontId: color.frontId || 0,
            color: color.color || '',
            items: color.items.length ? color.items : [{frontId: 0, approval: '', code: '', note: ''}]
        })),
        generalForm,
        sapForm
    };

    return _configureStore(
        {
            ...getMainModifiedStoreItems({ ...props, pref: "grade-edit" }),
            editState: { ...initialState, old: _.cloneDeep(initialState), form_validate: true },
        },
        reducers
    );
}
