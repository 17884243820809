import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Finance = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };
    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxHierarchy activeHierarchy={getPermission("finance_view", index)} inputClass='first-hierarchy'>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_finance_view-${index}`}
                        value={getPermission("finance_view", index)}
                        onChange={value => onChangePermission("finance_view", value, index)}
                    >
                        <CheckboxTitle title="Access - Budget & Sales" />
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_finance_view_export-${index}`}
                        value={getPermission("finance_view_export", index)}
                        onChange={value => onChangePermission("finance_view_export", value, index)}
                    >
                        <CheckboxTitle title="Export - Budget & Sales button" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
            <CheckboxHierarchy activeHierarchy={getPermission("forecast_invoices_view", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_invoices_view-${index}`}
                        value={getPermission("forecast_invoices_view", index)}
                        onChange={
                            value => onChangePermission("forecast_invoices_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Access - Invoices" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Invoices Module.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_invoices_export-${index}`}
                        value={getPermission("forecast_invoices_export", index)}
                        onChange={
                            value => onChangePermission("forecast_invoices_export", value, index)
                        }
                    >
                        <CheckboxTitle title="Export - Invoices" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_invoices_export_ZComOpps-${index}`}
                        value={getPermission("forecast_invoices_export_ZComOpps", index)}
                        onChange={
                            value => onChangePermission("forecast_invoices_export_ZComOpps", value, index)
                        }
                    >
                        <CheckboxTitle title="ZComOpps Export - Invoices" >
                            {/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
            <CheckboxHierarchy activeHierarchy={getPermission("connection_view", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_connection_view-${index}`}
                        value={getPermission("connection_view", index)}
                        onChange={
                            value => onChangePermission("connection_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Access - Connections" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Connections Module.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_connection_export-${index}`}
                        value={getPermission("connection_export", index)}
                        onChange={
                            value => onChangePermission("connection_export", value, index)
                        }
                    >
                        <CheckboxTitle title="Export - Connections" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`connection_new_button-${index}`}
                        value={getPermission("connection_new_button", index)}
                        onChange={
                            value => onChangePermission("connection_new_button", value, index)
                        }
                    >
                        <CheckboxTitle title="Add New Connection button" >
                            {/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_connection_budget_view-${index}`}
                        value={getPermission("connection_budget_view", index)}
                        onChange={
                            value => onChangePermission("connection_budget_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Access - Budget" >
                            {/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_connection_budget_export-${index}`}
                        value={getPermission("connection_budget_export", index)}
                        onChange={
                            value => onChangePermission("connection_budget_export", value, index)
                        }
                    >
                        <CheckboxTitle title="Export - Budget" >
                            {/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`connection_budget_new_button-${index}`}
                        value={getPermission("connection_budget_new_button", index)}
                        onChange={
                            value => onChangePermission("connection_budget_new_button", value, index)
                        }
                    >
                        <CheckboxTitle title="Add New Budget button" >
                            {/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
        </>
    )
}

Finance.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Finance);