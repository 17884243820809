import {request, url} from "Services";

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const getSelectedFiltersItems = (listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        type: "GET",
    };

    return request.sendRequestWithNoCacheAndNoLoading(sendObj);
};

export const requestTable = (listUrl) => data => {
    const urlParams = new URLSearchParams(document.location.search);
    const financialYear = +urlParams.get('financial_year');

    if(financialYear && !data.financial_year){
        data.financial_year = financialYear;
    }

    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                total_analytics: res[0].total_analytics,
                columns: res[0].columns,
                pinned_columns: res[0].pinned_columns,
                activeColumns: res[0].activeColumns,
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
            url.addHistory(data);
        });
    });
};

export const requestChangeSettings = data => {
    const sendObj = {
        url: url.getUrl('/connection/table-settings/edit'),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const requestBudgetsChangeSettings = data => {
    const sendObj = {
        url: url.getUrl('/connection/table-settings/budgets/edit'),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const createFilter = data => {
    const sendObj = {
        url: url.getUrl('/connection/connections/filters/create'),
        data: { name: data.name, data: { filter: data.filters.list.map((item) => {
            return { [item.column]: item.value }
        }) || [], sort: { ...data.filters.sort || [] } } },
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const getSavedFiltersList = (page) => {
    const sendObj = {
        url: url.getUrl(`/connection/connections/filters?page=${page}`),
        type: "GET",
    };

    return request.sendRequest(sendObj);
}

export const editTemplateRequest = (templateId, data) => {
    const sendObj = {
        url: url.getUrl(`/connection/table-settings/template/${templateId}/edit`),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
}

export const getSavedFilter = (data) => {
    const sendObj = {
        url: url.getUrl(`/connection/connections/filters/data/${data}`),
        type: "GET",
    };

    return request.sendRequest(sendObj);
}

export const requestCreateOrder = data => {
    const sendObj = {
        url: url.getUrl('/connection/table-settings/template/create'),
        data,
        type: "POST",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const requestGetSettings = () => {
    const sendObj = {
        url: url.getUrl('/connection/table-settings'),
        type: "GET"
    };

    return request.sendRequest(sendObj);
};

export const editBudgetTemplateRequest = (templateId, data) => {
    const sendObj = {
        url: url.getUrl(`/connection/table-settings/budgets/template/${templateId}/edit`),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
}

export const requestBudgetCreateOrder = data => {
    const sendObj = {
        url: url.getUrl('/connection/table-settings/budgets/template/create'),
        data,
        type: "POST",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const requestBudgetsGetSettings = () => {
    const sendObj = {
        url: url.getUrl('/connection/table-settings/budgets'),
        type: "GET"
    };

    return request.sendRequest(sendObj);
};

export const requestExportConnection = data => {
    const sendObj = {
        url: data,
        type: "POST"
    };

    return request.sendRequest(sendObj);
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};