import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { formatDate, getString } from "Services";
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "date":
            return (
                <TableRowItemLink
                    blank
                    title={formatDate(_.get(item, key))}
                    href={`/admin/import-templates/${getString(item, "id")}`}
                >
                    {formatDate(_.get(item, key))}
                </TableRowItemLink>
            );

        case "owner":
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, key)}
                    href={`/admin/companies/${getString(item, "owner_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "user":
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "user_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "status":
            return (
                <TableRowItemSpan title={getString(item, key)}>
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "imported_count":
        case "updated_count":
            return (
                <TableRowItemSpan
                    className="text-right"
                    title={getString(item, key)}
                >
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
