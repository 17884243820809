import React from "react";
import parse from 'html-react-parser';

const TooltipHtml = ({ children, classBlock = '' }) => {
    return (
        <span className={`tooltip tooltip__table`} onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
        }}>
            <i className="la la-info-circle" />
            <span className={`tooltip__text tooltip__text__table ${classBlock}`}>{parse(children)}</span>
        </span>
    );
};

TooltipHtml.propTypes = {};

export default TooltipHtml;
