import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Row} from "Templates/Form";
import Img from "Templates/Img/Img";
import {isMobile, url} from "Services";

const propTypes = {
    csrf_token: PropTypes.string.isRequired,
    error: PropTypes.string,
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    redirect_path: PropTypes.string.isRequired,
    login_path: PropTypes.string.isRequired,
    submitForm: PropTypes.func.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

class RegistrationGuest extends Component {
    state = {
        firstName: "",
        lastName: "",
        employer: "",
        title: "",
        password: "",
        confirmPassword: "",
    };

    handleChangeString = ({ target }) => {
        this.setState({
            [target.name]: target.value,
        });
    };

    checkForm = () => {
        const { firstName, lastName, employer, title, password, confirmPassword } = this.state;
        const minPasswordLength = 6;
        return !(firstName.trim() && lastName.trim() && employer.trim() && title.trim() && (
            password.length >= minPasswordLength &&
            confirmPassword.length >= minPasswordLength &&
            password === confirmPassword
        ));
    };

    isPasswordMatch = () => {
        const { password, confirmPassword } = this.state;
        if (!password || !confirmPassword) {
            return true;
        }
        return password === confirmPassword;
    };

    onSubmit = e => {
        e.preventDefault();
        const { csrf_token, submitForm, redirect_path, token } = this.props;
        const { firstName, lastName, employer, title, password, confirmPassword } = this.state;
        submitForm(
            {
                firstName, lastName, employer, title, password, confirmPassword,
                _csrf_token: csrf_token, redirect_path, token
            });
    };

    renderMobileAppPage = () => {
        const { t, redirect_url, appStoreUrl, playMarketUrl } = this.props;
        return <>
            <div className="main-wrap mobile-login">
                <div className="mobile-login__wrapper">
                    <h1 className="mobile-login__title">{t("For comfortable work with ComOpps")} <br/> {t("Download our mobile app!")}</h1>
                    <div className="mobile-login__apps">
                        <a href={appStoreUrl} className="mobile-login__apps--link">
                            <Img img="appstore" alt="appStore" className="mobile-login__apps--img"/>
                        </a>
                        <a href={playMarketUrl} className="mobile-login__apps--link">
                            <Img img="googleplay" alt="playMarket" className="mobile-login__apps--img"/>
                        </a>
                    </div>
                    <a href={redirect_url} className="mobile-login__link">
                        {t("Continue in Browser")}
                    </a>
                </div>
            </div>
        </>
    };

    renderError = () => {
        const {pref, error, login_path} = this.props;

        return (
            <>
                <div className={`${pref}__body`}>
                    <div className={`${pref}-logo__wrap`}>
                        <div className={`${pref}__logo`}>
                            <a href="/" className={`${pref}__logo-link`}>
                                <Img img="new_logo" alt="logo"/>
                                <span className={`${pref}__logo-link-text`}>Data You Can Trust </span>
                            </a>
                        </div>
                    </div>
                    <div className={`${pref}__wrap`}>
                        <h3 className={`${pref}__title error`}>
                            {error}
                        </h3>

                        <div className={`${pref}__title error`}>
                            <p>
                                <a href={login_path}>Click here</a> to log in.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    renderForm = () => {
        const { firstName, lastName, employer, title, password, confirmPassword } = this.state;
        const { pref, error, t } = this.props;

        return (
            <>
                <div className={`${pref}__body`}>
                    <div className={`${pref}-logo__wrap`}>
                        <div className={`${pref}__logo`}>
                            <a href="/" className={`${pref}__logo-link`}>
                                <Img img="new_logo" alt="logo" />
                                <span className={`${pref}__logo-link-text`}>Data You Can Trust </span>
                            </a>
                        </div>
                    </div>
                    <div className={`${pref}__wrap`}>
                        <form onSubmit={this.onSubmit} className={`${pref}__form`} autoComplete="off">
                            <div className="text-wrap">
                                <p>
                                    Create a free account to access Mitsubishi Chemical Group (MCG) Products via ComOpps
                                    Product Finder.
                                </p>
                                <h3>
                                    All fields are required.
                                </h3>
                            </div>
                            <Row>
                                <label htmlFor="firstName" className={`${pref}__form-label`}>
                                    <input
                                        required
                                        autoComplete="off"
                                        id="firstName"
                                        type="text"
                                        name="firstName"
                                        value={firstName}
                                        className={`${pref}__form-input ${error ? 'error': ''}`}
                                        placeholder="First Name"
                                        onChange={this.handleChangeString}
                                    />
                                </label>
                            </Row>
                            <Row>
                                <label htmlFor="lastName" className={`${pref}__form-label`}>
                                    <input
                                        required
                                        autoComplete="off"
                                        id="lastName"
                                        type="text"
                                        name="lastName"
                                        value={lastName}
                                        className={`${pref}__form-input ${error ? 'error': ''}`}
                                        placeholder="Last Name"
                                        onChange={this.handleChangeString}
                                    />
                                </label>
                            </Row>
                            <Row>
                                <label htmlFor="employer" className={`${pref}__form-label`}>
                                    <input
                                        required
                                        autoComplete="off"
                                        id="employer"
                                        type="text"
                                        name="employer"
                                        value={employer}
                                        className={`${pref}__form-input ${error ? 'error': ''}`}
                                        placeholder="Employer"
                                        onChange={this.handleChangeString}
                                    />
                                </label>
                            </Row>
                            <Row>
                                <label htmlFor="title" className={`${pref}__form-label`}>
                                    <input
                                        required
                                        autoComplete="off"
                                        id="title"
                                        type="text"
                                        name="title"
                                        value={title}
                                        className={`${pref}__form-input ${error ? 'error': ''}`}
                                        placeholder="Title"
                                        onChange={this.handleChangeString}
                                    />
                                </label>
                            </Row>
                            <Row>
                                <label htmlFor="password" className={`${pref}__form-label`}>
                                    <input
                                        required
                                        autoComplete="new-password"
                                        id="password"
                                        type="password"
                                        name="password"
                                        value={password}
                                        className={`${pref}__form-input`}
                                        placeholder="Password"
                                        onChange={this.handleChangeString}
                                    />
                                </label>
                                <div className="text-wrap">
                                    <p>Minimum 6 characters</p>
                                </div>
                            </Row>
                            <Row>
                                <label htmlFor="confirmPassword" className={`${pref}__form-label`}>
                                    <input
                                        required
                                        autoComplete="off"
                                        id="confirmPassword"
                                        type="password"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        className={`${pref}__form-input`}
                                        placeholder="Confirm Password"
                                        onChange={this.handleChangeString}
                                    />
                                </label>
                                <div className="text-wrap" style={{visibility: this.isPasswordMatch() ? 'hidden' : 'visible'}}>
                                    <p>Passwords mismatch</p>
                                </div>
                            </Row>
                            <Row>
                                <button
                                    type="submit"
                                    disabled={this.checkForm()}
                                    className={`${pref}__btn login__form-submit`}
                                >
                                    {t('Create Free Account')}
                                </button>
                            </Row>
                            {error && <div className={`${pref}__error`}>{error}</div>}
                        </form>
                    </div>
                </div>
            </>
        );
    };

    componentDidUpdate(prevProps) {
        const { error, redirect_url } = this.props;
        if (!error && !isMobile() && redirect_url) {
            url.redirect(redirect_url);
        }
    }

    render() {
        const { error, redirect_url } = this.props;
        if (redirect_url && isMobile()) {
            return this.renderMobileAppPage();
        }
        if (error) {
            return this.renderError();
        }
        return this.renderForm();
    }
}

RegistrationGuest.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        csrf_token: getStoreItem(state, "csrf_token"),
        redirect_path: getStoreItem(state, "redirect_path"),
        login_path: getStoreItem(state, "login_path"),
        redirect_url: getStoreItem(state, "redirect_url"),
        error: getStoreItem(state, "error"),
        email: getStoreItem(state, "email"),
        token: getStoreItem(state, "token"),
        appStoreUrl: getStoreItem(state, "appStoreUrl"),
        playMarketUrl: getStoreItem(state, "playMarketUrl"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(RegistrationGuest);
