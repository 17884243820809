import { handleScroll } from "../../EventsService";
import {getItem, getUploadUrl, requestOnSaveCopy} from "./RequestService";
import { deleteItem, requestTable } from "../../RequestService";
import { getActionStore, getStoreItem } from "./StoreService";
import { getFilter, getTableLabel } from "../../TableHeaderService";
import { getViewItem, modifierValues } from "../../TableBodyService";

export default (id, datasheetId) => ({
    requestTable: requestTable(`/product/publications/${id}/technical/${datasheetId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    modifierValues,
    getTableLabel,
    requestSubTab: () => getItem(`/product/product-finder/publications/${id}/technical`),
    requestTypeItem: (item) => getItem(`/product/product-finder/publications/${id}/technical/${item.id}`),
    requestOnSaveCopy: (datasheetId) => requestOnSaveCopy(`/product/datasheets/${datasheetId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/datasheets/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/datasheets/${datasheetId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (type) => getUploadUrl(datasheetId, type),
});
