import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {};

const propTypes = {
    revenueByStatus: PropTypes.arrayOf(
        PropTypes.shape({ status: PropTypes.string.isRequired, revenue: PropTypes.string.isRequired }),
    ).isRequired,
};

const DataDashboardCard = ({ pref, revenueByStatus, t }) => {
    let lead = {};
    let target = {};
    let current = {};
    let current_target = {};
    let opportunity = {};

    revenueByStatus.forEach(elem => {
        switch (elem.status) {
            case "Lead":
                lead = elem;
                break;
            case "Target":
                target = elem;
                break;
            case "Current":
                current = elem;
                break;
            case "Current + Target":
                current_target = elem;
                break;
            case "Opportunity":
                opportunity = elem;
                break;

            default:
                break;
        }
    });
    return (
        <div className={`${pref}__card-data`}>
            <ul className="status status--dashboard">
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern gray-bg">
                        <span>{t("Lead")}</span>
                        <svg
                            className="status-icon status-icon--right"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 40V0H0.503944L20.6187 17.4096C22.4907 19.0298 22.4546 21.9281 20.5429 23.5021L0.503944 40H0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value gray-text">
                        {`$${numberWithCommas(Number(lead.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern yellow-bg">
                        <span>{t("Opportunity")}</span>
                        <svg
                            className="status-icon status-icon--left"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H22V0Z"
                            />
                        </svg>
                        <svg
                            className="status-icon status-icon--right"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 40V0H0.503944L20.6187 17.4096C22.4907 19.0298 22.4546 21.9281 20.5429 23.5021L0.503944 40H0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value clr-yellow">
                        {`$${numberWithCommas(Number(opportunity.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern selected">
                        <span>{t("Target")}</span>
                        <svg
                            className="status-icon status-icon--left"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H22V0Z"
                            />
                        </svg>
                        <svg
                            className="status-icon status-icon--right"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 40V0H0.503944L20.6187 17.4096C22.4907 19.0298 22.4546 21.9281 20.5429 23.5021L0.503944 40H0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value clr-bright-blue">
                        {`$${numberWithCommas(Number(target.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern filled">
                        <span>{t("Current")}</span>
                        <svg
                            className="status-icon status-icon--left"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H22V0Z"
                            />
                        </svg>
                        <svg
                            className="status-icon status-icon--right"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 40V0H0.503944L20.6187 17.4096C22.4907 19.0298 22.4546 21.9281 20.5429 23.5021L0.503944 40H0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value clr-green">
                        {`$${numberWithCommas(Number(current.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern red-bg">
                        <span>{t("Current + Target")}</span>
                        <svg
                            className="status-icon status-icon--left"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H22V0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value clr-red">
                        {`$${numberWithCommas(Number(current_target.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>

            </ul>
        </div>
    );
};

DataDashboardCard.defaultProps = defaultProps;
DataDashboardCard.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        revenueByStatus: getStoreItem(state, "level_1.dashboard.revenueByStatus"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DataDashboardCard);
