import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "./EventsService";
import {historyInteractions, historyInteractionTypes} from "Services/enum";

export const getTableLabel = key => {
    const tableLabel = {
        created_at: "Interaction Date",
        interaction: "Interaction Type",
        type: "Type",
        entity_name: "Product Name",
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholder = {
        created_at: "Interaction Date",
        interaction: "Select",
        type: "Select",
        entity_name: "Search",
    };

    switch (key) {
        case "interaction":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={historyInteractions}
                    placeholder={_.get(placeholder, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "type":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={historyInteractionTypes}
                    placeholder={_.get(placeholder, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "created_at":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "-1":
            return null;

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
