import Constants from "../Constants";
import {url} from "Services";

export const initState = {
    activeTab: +url.get('activeTab'),
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            return { ...state, pref: data.pref, filter: {}, activeTab: data.activeTab };

        default:
            return state;
    }
};