import React, {Fragment} from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import ChangesHistoryTableBody from "./TableBody";

const ContainerTable = (TableHeadColumn, ChangesHistoryTableRowColumn, {showButtons = false}) => ({
    // eslint-disable-next-line react/prop-types
    request,
    // eslint-disable-next-line react/prop-types
    columns,
    // eslint-disable-next-line react/prop-types
    fetchItems
}) => {
    const showColumns = showButtons ? [...columns, "-1"] : [...columns];

    const onSortClick = column => {
        const asc = "asc";
        const desc = "desc";
        const newRequest = {
            ...request,
            sort: {
                // eslint-disable-next-line react/prop-types
                ...request.sort,
                column,
            },
        };
        // eslint-disable-next-line react/prop-types
        if (request.sort.column === column) {
            // eslint-disable-next-line react/prop-types
            newRequest.sort.direction = request.sort.direction === asc ? desc : asc;
        } else {
            newRequest.sort.direction = asc;
        }

        fetchItems(newRequest);
    };

    return (
        <table className="main-table changes-history__table">
            <tbody>
            <tr>
                {showColumns.map((column, index) => (
                    <Fragment key={`TableRowColumn-${index}`}>
                        <th key={`TableHeadColumn-${index}`} className="main-table__head">
                            <TableHeadColumn onSortClick={onSortClick} column={column} index={index} />
                        </th>
                    </Fragment>
                ))}
            </tr>
            <ChangesHistoryTableBody
                columns={showColumns}
                ChangesHistoryTableRowColumn={ChangesHistoryTableRowColumn}
            />
            </tbody>
        </table>
    );
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        columns: getStoreItem(state, "columns"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            }
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default (TableHeadColumn, ChangesHistoryTableRowColumn, {showButtons = false} = {}) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(ContainerTable(TableHeadColumn, ChangesHistoryTableRowColumn, {showButtons}));
