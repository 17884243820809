import ReactOnRails from "react-on-rails";
import App from "./App";
import Store from "./Store";
import BudgetStore from "./Store/budgetStore";

ReactOnRails.registerStore({
    connectionStore: Store,
});

ReactOnRails.register({ ConnectionIndex: App("connectionStore") });

ReactOnRails.registerStore({
    budgetStore: BudgetStore,
});

ReactOnRails.register({ BudgetIndex: App("budgetStore") });