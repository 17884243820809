import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = (data) => {
    const dataReq = {
        name: _.get(data, "generalForm.grade"),
        sub_status: _.get(data, "generalForm.status"),
        sub_segments: _.get(data, "applicationBlock.application"),
        colors: _.get(data, "colors"),
        colorsPartApproval: _.get(data, "colorsPartApproval"),
        molded: _.get(data, "molded"),
        locations: _.without(_.map(data.locations, 'value'), ''),

    };

    if (_.get(data, "generalForm.materialNumber")) {
        _.set(dataReq, "material_number", _.get(data, "generalForm.materialNumber"));
    }

    if (_.get(data, "generalForm.productCode")) {
        _.set(dataReq, "product_code", _.get(data, "generalForm.productCode"));
    }

    if(data.paintable != '') {
        _.set(dataReq, "paintable", data.paintable);
    }

    if(data.is_boundable != '') {
        _.set(dataReq, "is_boundable", data.is_boundable);
    }

    return formDataNormalizer(dataReq);
};