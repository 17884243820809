import {handleScroll} from "../../EventsService";
import {getItem, getItemData, getUploadUrl, requestOnSaveCopy} from "./RequestService";
import {getActionStore, getStoreItem} from "./StoreService";
import {deleteItem, requestTable} from "../../RequestService";
import {getFilter, getTableLabel} from "../../TableHeaderService";
import {getViewItem, modifierValues} from "../../TableBodyService";

export default (id, processId) => ({
    requestTable: requestTable(`/product/publications/${id}/processing/${processId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    modifierValues,
    getTableLabel,
    requestSubTab: () => getItemData(`/product/product-finder/publications/${id}/processing`),
    requestTypeItem: (item) => getItem(`/product/product-finder/publications/${id}/processing/${item.id}`),
    requestOnSaveCopy: (processId) => requestOnSaveCopy(`/product/processes/${processId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/processes/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/processes/${processId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (type) => getUploadUrl(processId, type),
});
