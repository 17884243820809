const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    CHANGE_ACTIVE_SUBTAB: "CHANGE_ACTIVE_SUBTAB",
    CHANGE_ACTIVE_SAFETY_SUBTAB: "CHANGE_ACTIVE_SAFETY_SUBTAB",
    CHANGE_ACTIVE_BROCHURE_SUBTAB: "CHANGE_ACTIVE_BROCHURE_SUBTAB",
    FETCH_TECHNICAL_ITEMS_REQUESTED: "FETCH_TECHNICAL_ITEMS_REQUESTED",
    FETCH_SAFETY_ITEMS_REQUESTED: "FETCH_SAFETY_ITEMS_REQUESTED",
    FETCH_BROCHURE_ITEMS_REQUESTED: "FETCH_BROCHURE_ITEMS_REQUESTED",
    SAVE_COPY_SUCCESS: "SAVE_COPY_SUCCESS",
    SAVE_COPY_FAILED: "SAVE_COPY_FAILED",
    CREATE_FILES_REQUEST: "CREATE_FILES_REQUEST",
    CREATE_FILES_SUCCESS: "CREATE_FILES_SUCCESS",
    CREATE_FILES_FAILED: "CREATE_FILES_FAILED",
    CLOSE_MODAL: "CLOSE_MODAL",
    OPEN_MODAL: "OPEN_MODAL",
    CHANGE_SELECT: "CHANGE_SELECT",

    RESET_FORM: "RESET_FORM",

    OPEN_ADD_TO_PUBLICATIONS_LIST_MODAL: "OPEN_ADD_TO_PUBLICATIONS_LIST_MODAL",
    CLOSE_ADD_TO_PUBLICATIONS_LIST_MODAL: "CLOSE_ADD_TO_PUBLICATIONS_LIST_MODAL",
    UPDATE_ADD_TO_PUBLICATION_LIST_FORM: "UPDATE_ADD_TO_PUBLICATION_LIST_FORM",
    ADD_TO_PUBLICATION_LIST_REQUESTED: "ADD_TO_PUBLICATION_LIST_REQUESTED",
    ADD_TO_PUBLICATION_LIST_SUCCEEDED: "ADD_TO_PUBLICATION_LIST_SUCCEEDED",
    ADD_TO_PUBLICATION_LIST_REJECTED: "ADD_TO_PUBLICATION_LIST_REJECTED",
};
export default Constants;
