import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import SharedTableRowColumnTemplate from "../SharedTableRowColumn";
import SharedTableRowColumnButton from "./SharedButton";
import SharedTableRowColumnDefault from "./SharedDefault";

const propTypes = {
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickCustom: PropTypes.func,
    secondaryIndex: PropTypes.number.isRequired,
};

const defaultProps = {
    customIcon: "",
    onClickCustom: () => {},
};

const SharedTableRowColumn = React.memo(
    ({ customIcon, secondaryIndex, column, item, onClickCustom}) => (
        <SharedTableRowColumnTemplate>
            {column === "-1" ? (
                <SharedTableRowColumnButton
                    customIcon={customIcon}
                    onClickCustom={onClickCustom}
                    index={secondaryIndex}
                />
            ) : (
                <SharedTableRowColumnDefault column={column} item={item} />
            )}
        </SharedTableRowColumnTemplate>
    ),
);

SharedTableRowColumn.propTypes = propTypes;
SharedTableRowColumn.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(SharedTableRowColumn);
