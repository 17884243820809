import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoLogo,
    ContentInfoTitle,
    ContentInfoWrap,
    InfoTitleSmall,
} from "Templates/Content";

const propTypes = {
    photoView: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.shape({ code: PropTypes.string, number: PropTypes.string }).isRequired,
};

const InfoWrap = ({
                      photoView,
                      title,
                      id,
                      firstName,
                      isAdmin,
                      idAdmin,
                      isInvited,
                      lastName,
                      email,
                      phone,
                      t
                  }) => (
    <ContentInfoWrap>
        <ContentInfoLogo className="main-circle-logo" url={photoView} />
        <ContentInfoTitle>{`${firstName} ${lastName}`}</ContentInfoTitle>
        <InfoTitleSmall>{title}</InfoTitleSmall>

        <ContentInfoBlock />

        <ContentInfoBlock show={!!email}>
            <ContentInfoBlockTitle>{t("Email")}</ContentInfoBlockTitle>
            <ContentInfoBlockText type="email" value={email} />
        </ContentInfoBlock>

        <ContentInfoBlock show={!!phone.code}>
            <ContentInfoBlockTitle>{t("Phone")}</ContentInfoBlockTitle>
            <ContentInfoBlockText type="phone" value={`${phone.code} ${phone.number}`} />
        </ContentInfoBlock>

        <ContentInfoBlock show={isAdmin && !isInvited && idAdmin !== id}>
            <ContentInfoBlockText type="link" title={t('Login this user')} value={`/admin-login/${id}`} />
        </ContentInfoBlock>
    </ContentInfoWrap>
);

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        photoView: getStoreItem(state, "view.photoView"),
        firstName: getStoreItem(state, "view.firstName"),
        lastName: getStoreItem(state, "view.lastName"),
        title: getStoreItem(state, "view.title"),
        email: getStoreItem(state, "view.email"),
        isAdmin: getStoreItem(state, "view.isAdmin"),
        isInvited: getStoreItem(state, "view.isInvited"),
        idAdmin: state.userState.id,
        id: getStoreItem(state, "view.id"),
        phone: getStoreItem(state, "view.phone"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
