import React from "react";
import { compose } from "redux";
// import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import { withTagDefaultProps } from "Hoc/Template";
import InfoWrap from "./Views/InfoWrap";

import MainBlock from "./Views/MainBlock";

const Index = () => {
    return (
        <ContentWrap>
            <InfoWrap />
            <ContentWrapCol>
                <MainBlock />
            </ContentWrapCol>
        </ContentWrap>
    );
};

Index.propTypes = {};

const mapStateToProps = state => {
    return {
        id: state.listState.view.id,
        activeTab: state.listState.activeTab,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
