import React from "react";
import { useSelector } from 'react-redux';

import { getString, numberWithCommas } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { weightUnitName } from 'Services/weightUnitName';

export const tableButtonsPermission = "plant_raw_crud";
export const modifierValues = items => items;

export const getViewItem = (key, item) => {
  const unit = useSelector(state => state.weightUnitState);

    switch (key) {
        case "name":
            return (
                <TableRowItemLink title={getString(item, key)} href={`/product/plants/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "address":
            return (
                <TableRowItemSpan title={getString(item, key)} className="max-width-60">
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "revenue":
            return (
                <TableRowItemSpan className="text-center">
                    {`$${numberWithCommas(getString(item, key), 0)}`}
                </TableRowItemSpan>
            );

        case "volume":
            return (
                <TableRowItemSpan className="text-center">
                    {`${numberWithCommas(getString(item, key), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );

        case "gross_margin":
            return (
                <TableRowItemSpan
                    title={getString(item, key)}
                    className={`${getString(item, key) < 0 ? "clr-red" : ""} text-right`}
                >
                    {`${getString(item, key)}%`}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
