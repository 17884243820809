import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

import { BlockMain, BlockWrap, Input, WrapInput } from "Templates/Form";

function GeneralBlock({ name, sapPlantId, onChange, t }) {
    const checkCompanyName = (value, key) => {
        onChange(key, value.slice(0, 100));
    };

    return (
        <BlockMain title="General Info">
            <BlockWrap>
                <WrapInput isRequired label={t("Plant name")} name="name">
                    <Input placeholder="Name" value={name} onChange={value => checkCompanyName(value, "name")} />
                </WrapInput>
            </BlockWrap>
            <BlockWrap>
                <WrapInput label={t("SAP Plant ID")} name="sap_plant_id">
                    <Input placeholder="SAP Plant ID" value={sapPlantId} onChange={value => onChange("sap_plant_id", value)} />
                </WrapInput>
            </BlockWrap>
            <BlockWrap />
        </BlockMain>
    );
}

GeneralBlock.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        sapPlantId: getStoreItem(state, "sap_plant_id")
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(GeneralBlock);
