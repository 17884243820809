import React from "react";
import { compose } from "redux";
import { useSelector } from 'react-redux';

import PropTypes from "prop-types";
import { numWithSub } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import Column from "../Templates/Column";
import Row from "../Templates/Row";
import DashboardCard from "./Card/Widget/DashboardCard";
import StatusDataDashboardCard from "./Card/Status/DataDashboardCard";
import TaskHeadDashboardCard from "./Card/Task/HeadDashboardCard";
import ChartHeadDashboardCard from "./Card/Chart/HeadDashboardCard";
import ChartDataDashboardCard from "./Card/Chart/DataDashboardCard";
import {Title, Total} from "Templates/Titles";
import dashboardTypeConstant from "../Constants/DashboardConstants";
import DataBusinessDashboardCard from "../Views/Card/Status/DataBusinessDashboardCard";
import {Tooltip} from "Templates/Form";
import { weightUnitName, weightUnitConvertLbs } from 'Services/weightUnitName';

const propTypes = {
    revenue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    volume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tasks: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    grossMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    role: PropTypes.string.isRequired,
    financialYear: PropTypes.string.isRequired,
    help: PropTypes.string,
};

const InfoDashboardCard = DashboardCard({});
const ChartDashboardCard = DashboardCard({
    HeadDashboardCard: ChartHeadDashboardCard,
    DataDashboardCard: ChartDataDashboardCard,
});

const DashboardTypeFour = ({ revenue, companyName, volume, tasks, dashboardType, position, financialYear, help, t }) => {
    const unit = useSelector(state => state.weightUnitState);
    const StatusDashboardCard = DashboardCard({
        DataDashboardCard: dashboardTypeConstant['BUSINESS'] === dashboardType ? DataBusinessDashboardCard : StatusDataDashboardCard
    });

    return (<>
        <Title
            title={t("Dashboard")}
            help={help}
            total={() =>
                   <Total>
                       <div className={'gray-text'}>
                           { dashboardTypeConstant['BUSINESS'] === dashboardType ? "Business Development" : "Sales" }
                       </div>
                       <div className={'gray-text'}>
                           { ` - ${position}` }
                           <Tooltip>{t(`Displays information from my projects in "${companyName}"`)}</Tooltip>
                       </div>
                   </Total>
               }>
        </Title>
        <div className="dashboard__wrap-main main-content__wrap">
            <div className="dashboard__list">
                <Row>
                    <Column>
                        <StatusDashboardCard
                            headProps={{
                                title: t(`My Pipeline Forecast Revenue`),
                                subtitle: t(`FY ${financialYear}`),
                            }}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column columnSize={1} fromColumns={4}>
                        <InfoDashboardCard
                            headProps={{ title: t(`My ${dashboardTypeConstant['BUSINESS'] === dashboardType ? "Level 4" : "Current"} Forecast Revenue`), subtitle: t(`FY ${financialYear}`) }}
                            dataProsp={{ value: `$${numWithSub(Number(revenue))}`, className: "content_center" }}
                        />
                        <InfoDashboardCard
                            headProps={{ title: t(`My ${dashboardTypeConstant['BUSINESS'] === dashboardType ? "Level 4" : "Current"} Forecast Volume`), subtitle: t(`FY ${financialYear}`) }}
                            dataProsp={{ value: `${numWithSub(Number(volume))}`, unit: `${weightUnitConvertLbs(weightUnitName(unit.weightUnit))}`, className: "content_center" }}
                        />
                    </Column>
                    <Column columnSize={3} fromColumns={4}>
                        <ChartDashboardCard className="main-data" />
                    </Column>
                </Row>
            </div>
        </div>
    </>
    );
};

DashboardTypeFour.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        companyName: getStoreItem(state, "companyName"),
        revenue: getStoreItem(state, "dashboard.analytic.revenue"),
        volume: getStoreItem(state, "dashboard.analytic.volume"),
        tasks: getStoreItem(state, "dashboard.analytic.tasks"),
        grossMargin: getStoreItem(state, "dashboard.analytic.gross_margin"),
        financialYear: getStoreItem(state, "financialYear"),
        role: getStoreItem(state, "role"),
        dashboardType: getStoreItem(state, "dashboardType"),
        position: getStoreItem(state, "position"),
        help: getStoreItem(state, "help"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DashboardTypeFour);
