import React from "react";
// import PropTypes from 'prop-types'
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {};

const CardHeader = ({ pref, children }) => {
    return <div className={`${pref}__card-heading`}>{children}</div>;
};

CardHeader.propTypes = propTypes;

export default compose(withTagDefaultProps())(CardHeader);
