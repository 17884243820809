import { getStoreItem } from "Services";
import { industryOptions } from "Services/enum";
import { requestCreate, requestGet } from "./RequestService";
import * as FormService from "./FormService";
import { getActionStore } from "./StoreService";

export default () => ({
    saveMaterial: requestCreate("/project/material-specification/create"),
    savePerformance: requestCreate("/project/performance-specification/create"),
    saveApplication: requestCreate("/project/application/create"),
    FormService,
    requestCreate,
    requestGet,
    getActionStore,
    getStoreItem,
    industryOptions,
});
