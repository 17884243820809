import React from "react";
// import PropTypes from 'prop-types'
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {};

const TableBodyButtonsBlock = ({ pref, children }) => {
    return <div className={`${pref}-table__body-btns main-table__body-btns`}>{children}</div>;
};

TableBodyButtonsBlock.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableBodyButtonsBlock);
