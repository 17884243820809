import Constants from "../Constants";
import {requestGroupList} from "../Services/Tabs/SharedList/RequestService";

export const onChangeTab = (service, dispatch) => (data) => {
    dispatch({type: Constants.CHANGE_TAB, data});
};

export const fetchGroupList = (service, dispatch) => () => {
    dispatch({type: Constants.FETCH_GROUP_LIST_REQUESTED});

    return requestGroupList().then(
        res => {
            dispatch({type: Constants.FETCH_GROUP_LIST_SUCCEEDED, data: res});
        },
        res => {
            alert(res.detail);
            dispatch({type: Constants.FETCH_GROUP_LIST_FAILED, data: res});
        },
    );
};

export const handleCloseModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.CLOSE_MODAL, data});
};

export const handleOpenModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.OPEN_MODAL, data});
};

export const handleFilterCloseModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.FILTER_CLOSE_MODAL, data});
};

export const handleFilterOpenModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.FILTER_OPEN_MODAL, data});
};

export const handleCloseShareFiltersModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.SHARE_FILTERS_CLOSE_MODAL, data});
};

export const handleOpenShareFiltersModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.SHARE_FILTERS_OPEN_MODAL, data});
};

export const handleCloseWelcomeGuestModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.WELCOME_GUEST_CLOSE_MODAL, data});
};

export const handleOpenWelcomeGuestModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.WELCOME_GUEST_OPEN_MODAL, data});
};

export const onChangeForm = (service, dispatch) => (key, value) => {
    dispatch({type: Constants.CHANGE_INVITE_FORM, data: {key, value}});
};

export const onChangeFilterForm = (service, dispatch) => (key, value) => {
    dispatch({type: Constants.CHANGE_FILTER_FORM, data: {key, value}});
};

export const onChangeAddPublicationListForm = (service, dispatch) => (key, value) => {
    dispatch({type: Constants.CHANGE_ADD_PUBLICATION_LIST_FORM, data: {key, value}});
};

export const updateGroups = (service, dispatch) => data => {
    dispatch({type: Constants.UPDATE_GROUPS_FORM, data});
};

export const saveInviteAction = (service, dispatch) => data => {
    const {requestOnSaveInvite} = service;

    return requestOnSaveInvite(data).then(
        res => {
            if (res && res[0] && res[0] === "Success") {
                alert('Congratulations! Your Guest User invitation was sent successfully.');
            }
            dispatch({type: Constants.SAVE_INVITE_SUCCESS, data: res});
        },
        res => {
            alert(res.detail);
            dispatch({type: Constants.SAVE_INVITE_FAILED, data: res});
        },
    );
};

export const saveFilterAction = (service, dispatch) => data => {
    const {requestOnSaveFilter} = service;

    return requestOnSaveFilter(data).then(
        res => {
            if (res && res[0] && res[0].id) {
                alert('Your filter was successfully saved.');
            }
            dispatch({type: Constants.SAVE_FILTER_SUCCESS, data: res[0]});
        },
        res => {
            alert(res.detail);
            dispatch({type: Constants.SAVE_FILTER_FAILED, data: res});
        },
    );
};

export const saveShareFiltersAction = (service, dispatch) => data => {
    const {requestOnSaveShareFilters} = service;

    return requestOnSaveShareFilters(data).then(
        res => {
            if (res && res[0] && res[0] === "Success") {
                alert('Congratulations! Your Share Filters link was sent successfully.');
            }
            dispatch({type: Constants.SAVE_SHARE_FILTERS_SUCCESS, data: res});
        },
        res => {
            alert(res.detail);
            dispatch({type: Constants.SAVE_SHARE_FILTERS_FAILED, data: res});
        },
    );
};

export const handleAddSharedProductsPublicationsToList = (service, dispatch) => (data) => {
    dispatch({type: Constants.ADD_SHARED_PRODUCTS_PUBLICATION_LIST_OPEN_MODAL, data});
};

export const handleCloseAddSharedProductsPublicationsToList = (service, dispatch) => () => {
    dispatch({type: Constants.ADD_SHARED_PRODUCTS_PUBLICATION_LIST_CLOSE_MODAL});
};

export const handleOpenAddPublicationListModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.ADD_PUBLICATION_LIST_OPEN_MODAL, data});
};

export const handleCloseAddPublicationListModal = (service, dispatch) => () => {
    dispatch({type: Constants.ADD_PUBLICATION_LIST_CLOSE_MODAL});
};

export const savePublicationList = (service, dispatch) => data => {
    const {requestAddPublicationList, requestUpdatePublicationList} = service;

    dispatch({type: Constants.SAVE_PUBLICATION_LIST, data})

    const requestFn = data.id ? requestUpdatePublicationList : requestAddPublicationList;
    return requestFn(data).then(
        res => {
            if (res && res[0] && res[0] === "Success") {
                alert('Congratulations! Your Publication list saved successfully.');
            }
            dispatch({type: Constants.ADD_PUBLICATION_LIST_CLOSE_MODAL});
        },
        res => {
            alert(res.detail);
        },
    );
};

export const saveNewPublicationsList = (service, dispatch) => data => {
    const {requestNewAddPublicationList} = service;

    dispatch({type: Constants.SAVE_PUBLICATION_LIST, data})

    return requestNewAddPublicationList(data).then(
        res => {
            if (res && res[0] && res[0] === "Success") {
                alert('Congratulations! Your Publication list saved successfully.');
            }
            dispatch({type: Constants.ADD_SHARED_PRODUCTS_PUBLICATION_LIST_CLOSE_MODAL});
        },
        res => {
            alert(res.detail);
        },
    );
};
