import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas, formatDate } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Title, Total } from "Templates/Titles";
import { TabWrap } from "Templates/Content";
import QuickEditTabViewIndex from "../../../../../Templates/Table/QuickEditDefaultWrap/QuickEditTabViewIndex";

const defaultProps = {
    help: '',
    updated_date: '',
    rowId: null,
    invalidQuickEdit: false
}

const propTypes = {
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    updated_date: PropTypes.string,
    rowId: PropTypes.string,
    invalidQuickEdit: PropTypes.bool,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickUnlock: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    applyChange: PropTypes.func.isRequired,
    setInvalid: PropTypes.func.isRequired,
};

const TabView = ({ onClickEdit, rowId, invalidQuickEdit, onClickCustom, onClickDelete, onClickUnlock, t, title, help, updated_date, onClickSave, applyChange, setInvalid }) => {
    const lastUpdate = !updated_date ? null :
        (
            <>
                <div className="break"/>
                <div className="last-update">{`${t("Last SAP Update")} - ${formatDate(updated_date)}`}</div>
            </>
        );

    return (
        <>
            <Title
                title={t("Raw Materials")}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
                shouldWrap={() => true}
            >
                {lastUpdate}
            </Title>
            <TabWrap>
                <QuickEditTabViewIndex
                    customIcon="icon_external_link"
                    rowId={rowId}
                    invalidQuickEdit={invalidQuickEdit}
                    onClickCustom={onClickCustom}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    onClickUnlock={onClickUnlock}
                    onClickSave={onClickSave}
                    applyChange={applyChange}
                    setInvalid={setInvalid}
                />
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes;
TabView.defaultProps = defaultProps;

const mapStateToProps = state => {
    return {
        title: state.listState.pagination.total,
        help: state.listState.help,
        updated_date: state.listState.updated_date,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TabView);
