const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    RESET_FILTER: "RESET_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    EXPORT_PUBLICATIONS: "EXPORT_PUBLICATIONS",
    EXPORT_PUBLICATIONS_SUCCESS: "EXPORT_PUBLICATIONS_SUCCESS",
    EXPORT_PUBLICATIONS_FAILED: "EXPORT_PUBLICATIONS_FAILED",
};

export default Constants;
