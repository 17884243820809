import React from "react";
import { getString, diffDate, formatDateToLocal } from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "expiration_date":
            const value = getString(item, key);

            if (!value || value === '-') {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            let dateColor = "";
            const date = formatDateToLocal(value, "MMM DD, YYYY H:mm A");

            const dateDiff = diffDate(value, false, "minutes");

            switch (true) {
                case dateDiff <= 0:
                    dateColor = "clr-red";
                    break;
            }

            return (
                <TableRowItemSpan title={date} className={dateColor}>
                    {date}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
