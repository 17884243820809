import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ContainerTable} from "Hoc/Widgets";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TabTable);

const propTypes = {
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        onClickRestoreItem: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

class Raw extends Component {
    render = () => {
        const {
            t,
            fetchItems,
            request,
            service: {onClickDeleteItem, onClickRestoreItem},
        } = this.props;

        const onClickDelete = item => {
            if (confirm(t("Do you want to delete this Raw Material?"))) {
                onClickDeleteItem(item).then(
                    () => {
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        };

        const onClickRestore = item => {
            if (confirm(t("Do you want to restore this Raw Material?"))) {
                onClickRestoreItem(item).then(
                    () => {
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        };

        return (
            <>
                <TabViewDetail
                    onClickDelete={onClickDelete}
                    onClickCustom={onClickRestore}
                />
            </>
        );
    };
}

Raw.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Raw);
