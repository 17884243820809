import main from "../../../Services";

const storeName = "editState";

const services = main();

export default {
    ...services,
    save: id => services.requestCreate(`/project/projects/${id}/edit`),
    storeName,
    getStoreItem: services.getStoreItem(storeName),
};
