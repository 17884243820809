import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const {
        id,
        publicationId,
        publicationName,
        company,
        customName,
        paintable,
        publicationLogo,
        molded,
        is_boundable,
        keywords,
        visible,
        contacts,
        locations,
        countries,
        colors,
        suppliers,
        content,
        materialProperties,
        legal,
        legalHTML
    } = props;

    const initData = {
        id,
        publicationId,
        publicationName,
        publicationLogo,
        company,
        customName,
        paintable,
        molded,
        is_boundable,
        keywords: keywords || [],
        visible: !_.isEmpty(visible)
            ? Object.keys(visible)
                .map(key => [key, visible[key].toLowerCase() === '10'])
                .reduce((obj, [key, value]) => (obj[key] = value, obj), {})
            : {
                property: true,
                testMethod: true,
                specimen: true,
                testCondition: true,
                unitSi: true,
                unitEnglish: true
            },
        contacts: contacts.length ? contacts : [{ frontId: 0, contact: "", role: "", role_id: "" }],
        locations: locations.length ? locations : [{ frontId: 0, value: "" }],
        countries: countries.length ? countries : [{ frontId: 0, value: "" }],
        colors: (colors || [{ frontId: 0, color: '', items: [] }]).map(color => ({
            frontId: color.frontId || 0,
            color: color.color || '',
            items: color.items.length ? color.items : [{ frontId: 0, approval: '', code: '', source: '' }]
        })),
        suppliers: suppliers.length ? suppliers : [{ frontId: 0, value: "" }],
        content: content.length
            ? content.map(item => ({
                ...item,
                value: item.value
            }))
            : [
                { frontId: 0, value: '', template: '' },
                { frontId: 1, value: '', template: '' }
            ],
        materialProperties: materialProperties.length
            ? materialProperties
            : [{
                id: '',
                frontId: 0,
                property: '',
                testMethod: '',
                specimen: '',
                testCondition: '',
                valueMinSi: '',
                valueMaxSi: '',
                unitSi: '',
                valueMinEnglish: '',
                valueMaxEnglish: '',
                unitEnglish: ''
            }],
        legal,
        legalHTML
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "publication-datasheets-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
