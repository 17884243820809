import _ from "lodash";
import { TableActions, TabActions } from "../../../Actions";
import * as notesAction from "../../../Actions/Tabs/NotesAction";

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableActions,
        ...TabActions,
        ...notesAction,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
