import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabView from "./Views/TabView";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired
};

const HelpPage = () => {
    const onClickEdit = item => {
        url.redirect(`/admin/help-pages/${item.id}/edit`);
    };

    const onClickDelete = () => {

    }

    const onClickShow = item => {
        const win = window.open(`/admin/help-pages/${item.id}`, "_blank");
        win.focus();
    };

    return (
        <TabView
            onClickCustom={onClickShow}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
        />
    );
};

HelpPage.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(HelpPage);
