import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const properties = _.get(data, "properties", []).filter(property => _.trim(property.property));

    const dataReq = {
        name: _.get(data, "name"),
        applicationCode: _.get(data, "applicationCode"),
        properties
    };

    return formDataNormalizer(dataReq);
};
