import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { Li, Span } from "Templates/Default";
import { renderStatusIcons } from "Templates/Content/ProjectStatusBlock/StatusIcons/StatusIcons";

const propTypes = {
    index: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    item: PropTypes.shape({
        sub_status: PropTypes.string.isRequired,
        revenue_sum: PropTypes.number.isRequired,
        count_project: PropTypes.number.isRequired,
    }).isRequired,
};

const getStatusColor = status => {
    switch (true) {
        case status === "Lead":
            return "gray-bg";

        case status === "Opportunity":
            return "yellow-bg";

        case status === "Target":
            return "selected";

        case status === "Current":
            return "filled";

        default:
            return "";
    }
};

const ProjectStatusBlockListElement = ({ item, t, index, length }) => {
    return (
        <Li className="status__item status__item--modern">
            <div className={`status__button status__button--modern ${getStatusColor(item.sub_status)}`}>
                <Span>
                    {t(item.sub_status)}
                    {` (${numberWithCommas(item.count_project, 0)})`}
                </Span>
                {renderStatusIcons(index, length)}
            </div>
            <Span className="status__value clr-green">{`$${numberWithCommas(item.revenue_sum)}`}</Span>
        </Li>
    );
};

ProjectStatusBlockListElement.propTypes = propTypes;

export default compose(withTagDefaultProps())(ProjectStatusBlockListElement);
