import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { ChartActions } from "../Actions";

const getStoreItem = _getStoreItem("listState");
const getChartData = store => getStoreItem(store, "dashboard.graph");

const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...ChartActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export default () => ({
    getStoreItem,
    getChartData,
    getActionStore,
});
