import React from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";

import SpecificationsTableBodyColumn from "./Table/TableBodyColumn";
import * as SpecificationsService from "../../../../../Services/Tabs/General/Table/PartWeightUnitService";
import ExpandedBlock from "../../../../Templates/ExpandedBlock";

const SpecificationsTable = CustomSimpleTable()(() => null)()(SpecificationsTableBodyColumn)()(() => null);

const SpecificationsBlock = ({ t, block, expanded, setExpandedAction }) => {
    return (
        <div className="project-expanded-block">
            <ExpandedBlock title={t("Specifications")} service={SpecificationsService} block={block} isExpanded={expanded} setExpandedAction={setExpandedAction}>
                <SpecificationsTable />
            </ExpandedBlock>
        </div>
    );
};

SpecificationsBlock.propTypes = {};

export default compose(withTagDefaultProps())(SpecificationsBlock);
