import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import Img from "Templates/Img";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    index: PropTypes.number.isRequired,
    customIcon: PropTypes.string.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const TableRowItem = ({ index, customIcon, onClickCustom, onClickDelete }) => {
    const _onClickCustom = () => {
        onClickCustom(index);
    };

    const _onClickDelete = () => {
        onClickDelete(index);
    };
    return (
        <TableBodyButtonsBlock>
            <TableBodyButton onClick={_onClickCustom}>
                <Img img={customIcon} />
            </TableBodyButton>
            <CheckPermission permission="grade_product_files">
                <TableBodyButton onClick={_onClickDelete}>
                    <Img img="icon_delete" />
                </TableBodyButton>
            </CheckPermission>
        </TableBodyButtonsBlock>
    );
};

TableRowItem.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowItem);
