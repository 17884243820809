import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import Img, {ImgStorage} from "Templates/Img";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    item: {},
};

const TableBodyColumn = ({item}) => {
    const {manager, internalTeam, contacts} = item
    return (
        <tr>
            <td className="project-expanded-block__table">
                <div className="project-expanded-block__table-team">
                    <div className="project-expanded-block__table-team_item">
                        <p>Project Manager</p>
                        {manager ? <div className="project-expanded-block__table-team_item-wrap">
                                <span
                                    className="projects-view__overview-user-title main-circle">
                                        {manager.photo ? <ImgStorage url={manager.photo}/> :
                                            <Img img="icon_contact_small"/>}
                                </span>
                                <div>
                                    <a
                                        href={`/admin/users/${manager.id}`}
                                        className="projects-view__overview-user-link main-content__note-user-link">
                                        {manager.name}
                                    </a>
                                    <a
                                        href={`/admin/companies/${manager.company_id}`}
                                        className="projects-view__overview-user-link main-content__note-user-link block">
                                        {manager.company}
                                    </a>
                                </div>
                        </div> : '-'}
                    </div>
                    <div className="project-expanded-block__table-team_item">
                        <p>Internal Team</p>
                        {internalTeam && internalTeam.length ? internalTeam.map((internalItem) => {
                            return <div key={`internal-${internalItem.id}`} className="project-expanded-block__table-team_item-wrap">
                                <span
                                    className="projects-view__overview-user-title main-circle">
                                        {internalItem.photo ? <ImgStorage url={internalItem.photo}/> :
                                            <Img img="icon_contact_small"/>}
                                </span>
                                <div>
                                    <a
                                        href={`/admin/users/${internalItem.id}`}
                                        className="projects-view__overview-user-link main-content__note-user-link block">
                                        {internalItem.name}{" "}
                                    </a>
                                    <a
                                        href={`/admin/companies/${internalItem.company_id}`}
                                        className="projects-view__overview-user-link main-content__note-user-link block">
                                        {internalItem.company}
                                    </a>
                                </div>
                            </div>
                        }) : '-'}
                    </div>
                    <div className="project-expanded-block__table-team_item">
                        <p>Contacts</p>
                        {contacts && contacts.length ? contacts.map((contactItem) => {
                            return <div key={`contact-${contactItem.id}`} className="project-expanded-block__table-team_item-wrap">
                            <span
                                className="projects-view__overview-user-title main-circle">
                                    {contactItem.photo ? <ImgStorage url={contactItem.photo}/> : <Img img="icon_contact_small"/>}
                            </span>
                                <div>
                                    <a
                                        href={`/contacts/${contactItem.id}`}
                                        className="projects-view__overview-user-link main-content__note-user-link block"
                                    >
                                        {contactItem.name}
                                    </a>
                                    <a
                                        href={`/companies/${contactItem.customer_id}`}
                                        className="projects-view__overview-user-link main-content__note-user-link block"
                                    >
                                        {contactItem.customer}
                                    </a>
                                </div>
                            </div>
                        }) : '-'}
                    </div>
                </div>
            </td>
        </tr>
    );
};

TableBodyColumn.propTypes = propTypes;

TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
