import React from "react";
import _ from "lodash";
import { industryOptions } from "Services/enum";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

const placeholders = {
    name: "Search",
    industry: "Select",
};

export const getFilter = (key, value, callback, request) => {
    switch (key) {
        case "industry":
            return (
                <InputFilter
                    type="select"
                    options={[
                        {
                            value: "",
                            label: "All",
                        },
                        ...industryOptions,
                    ]}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
