import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";
import _ from "lodash";
import {filter} from "Services";

export default function configureStore(props) {
    const { items, pagination, columns, can_export, help } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({...props, pref: "vehicle"}),
        listState: {
            ...mainState,
            items,
            pagination,
            can_export,
            help,
            columns,
            filter: filter.getParsedFilters(),
            sort: filter.getSort(),
        },
    };

    if (initialState.listState.activeTab) {
        _.unset(initialState.listState, "items");
    }

    return _configureStore(initialState, reducers);
}
