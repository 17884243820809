import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";
import Img from "Templates/Img";

const propTypes = {
    customIcon: PropTypes.string,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    rowId: PropTypes.string,
    invalidQuickEdit: PropTypes.bool,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickUnlock: PropTypes.func,
    onClickSave: PropTypes.func,
};

const defaultProps = {
    customIcon: "",
    rowId: null,
    invalidQuickEdit: false,
    onClickCustom: () => {},
    onClickUnlock: () => {},
    onClickSave: () => {},
};

const QuickEditTableRowItemEdit = ({ customIcon, item, rowId, invalidQuickEdit, onClickCustom, onClickEdit, onClickDelete, onClickUnlock, onClickSave }) => {
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        setIsEditing(item.id === rowId)
    }, [rowId])

    return (
        <TableBodyButtonsBlock>
            {!!customIcon && (
                <TableBodyButton onClick={onClickCustom}>
                    <Img img={customIcon}/>
                </TableBodyButton>
            )}
            {!isEditing ? <TableBodyButton onClick={onClickUnlock}>
                    <Img img="edit_lock_icon" width="13" height="16"/>
                </TableBodyButton> :
                <TableBodyButton disabled={invalidQuickEdit} onClick={onClickSave}>
                    <Img img="edit_save_icon" width="20" height="20"/>
                </TableBodyButton>
            }
            <TableBodyButton onClick={onClickEdit}>
                <Img img="icon_edit"/>
            </TableBodyButton>
            <TableBodyButton onClick={onClickDelete}>
                <Img img="icon_delete"/>
            </TableBodyButton>
        </TableBodyButtonsBlock>
    )
};

QuickEditTableRowItemEdit.propTypes = propTypes;
QuickEditTableRowItemEdit.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(QuickEditTableRowItemEdit);
