const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    DELETE_ITEM_REQUESTED: "DELETE_ITEM_REQUESTED",
    DELETE_ITEM_SUCCEEDED: "DELETE_ITEM_SUCCEEDED",
    DELETE_ITEM_FAILED: "DELETE_ITEM_FAILED",

    CREATE_FOLDER_REQUEST: "CREATE_FOLDER_REQUEST",
    CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
    CREATE_FOLDER_FAILED: "CREATE_FOLDER_FAILED",

    EDIT_FOLDER_REQUEST: "EDIT_FOLDER_REQUEST",
    EDIT_FOLDER_SUCCESS: "EDIT_FOLDER_SUCCESS",
    EDIT_FOLDER_FAILED: "EDIT_FOLDER_FAILED",

    EDIT_CURRENT_FOLDER_REQUEST: "EDIT_CURRENT_FOLDER_REQUEST",
    EDIT_CURRENT_FOLDER_SUCCESS: "EDIT_CURRENT_FOLDER_SUCCESS",
    EDIT_CURRENT_FOLDER_FAILED: "EDIT_CURRENT_FOLDER_FAILED",

    CREATE_FILES_REQUEST: "CREATE_FILES_REQUEST",
    CREATE_FILES_SUCCESS: "CREATE_FILES_SUCCESS",
    CREATE_FILES_FAILED: "CREATE_FILES_FAILED",

    DOWNLOAD_FILE_REQUEST: "DOWNLOAD_FILE_REQUEST",
    DOWNLOAD_FILE_SUCCESS: "DOWNLOAD_FILE_SUCCESS",
    DOWNLOAD_FILE_FAILED: "DOWNLOAD_FILE_FAILED",
};

export default Constants;