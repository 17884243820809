import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    addShared: PropTypes.func,
    column: PropTypes.string.isRequired,
};

const SharedTableRowColumnDefault = ({ column, addShared, item, service, weightUnit }) => {
    const { getSharedViewItem } = service;
    return getSharedViewItem(column, item, addShared, weightUnit);
};

SharedTableRowColumnDefault.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    weightUnit: state.weightUnitState,
  };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(SharedTableRowColumnDefault);
