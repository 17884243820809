import React, { Fragment } from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { LinkIcon } from "Templates/Link";
import { Button } from "Templates/Default";
import {BlockTitle, Block, BlockWrap, Input, WrapInput, BlockShadow} from "Templates/Form";

const propTypes = {
    projectManager: PropTypes.string.isRequired,
    internalTeam: PropTypes.arrayOf(
        PropTypes.shape({ frontId: PropTypes.number.isRequired, value: PropTypes.string.isRequired }),
    ).isRequired,
    contacts: PropTypes.arrayOf(
        PropTypes.shape({ frontId: PropTypes.number.isRequired, value: PropTypes.string.isRequired }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
};

const ProjectTeamAndContact = ({ projectManager, internalTeam, contacts, t, onChange }) => {
    const _onAddArray = (lastFrontId, key, array) => {
        onChange(key, [...array, { frontId: lastFrontId + 1, value: "" }]);
    };

    const _onChangeArray = (index, value, key, array) => {
        _.set(array, index, value);
        onChange(key, _.uniqBy(_.concat(array), 'value'));

        if(value.value === projectManager){
            onChange("projectTeamAndContactsBlock.projectManager", '');
        }
    };

    const _onDeleteArray = (frontId, key, array) => {
        onChange(key, array.filter(elem => elem.frontId !== frontId));
    };

    const _onChangePM = (key, value) => {
        onChange(key, value);

        const teamFilter = internalTeam.filter(elem => elem.value !== value);
        onChange("projectTeamAndContactsBlock.internalTeam", teamFilter.length ? teamFilter : [{ frontId: 0, value: "" }]);
    };

    return (
        <>
            <BlockTitle>{t("Project Team & Contacts")}</BlockTitle>
            <Block>
                <BlockShadow>
                    <BlockWrap className="width_1-3">
                        <WrapInput label="Project Manager" name="projectManager">
                            <Input
                                type="asyncSelect"
                                url="/project/projects/user-list"
                                name="Project Manager"
                                value={projectManager}
                                placeholder={t("Search")}
                                onChange={value => _onChangePM("projectTeamAndContactsBlock.projectManager", value)}
                            />
                        </WrapInput>
                    </BlockWrap>
                    <BlockWrap className="width_1-3">
                        <WrapInput className="wrap-input--delete" label="Internal Team" name="internalTeam">
                            {internalTeam.map((team, index) => (
                                <Fragment key={`Input-${team.frontId}`}>
                                    <Input
                                        type="asyncSelect"
                                        name={`Internal-Team-${index}`}
                                        url="/project/projects/user-list"
                                        value={team.value}
                                        placeholder={t("Search")}
                                        onChange={value =>
                                            _onChangeArray(
                                                index,
                                                { frontId: team.frontId, value },
                                                "projectTeamAndContactsBlock.internalTeam",
                                                internalTeam,
                                            )
                                        }
                                    />
                                    {internalTeam.length > 1 && (
                                        <LinkIcon
                                            className="icon-delete"
                                            icon="la-icon-close"
                                            onClick={() =>
                                                _onDeleteArray(
                                                    team.frontId,
                                                    "projectTeamAndContactsBlock.internalTeam",
                                                    internalTeam,
                                                )
                                            }
                                        />
                                    )}
                                </Fragment>
                            ))}
                            <Button
                                onClick={() =>
                                    _onAddArray(
                                        internalTeam[internalTeam.length - 1].frontId,
                                        "projectTeamAndContactsBlock.internalTeam",
                                        internalTeam,
                                    )
                                }
                                className="size_normal main-btn main-btn_white"
                            >
                                Add Team Member
                            </Button>
                        </WrapInput>
                    </BlockWrap>
                    <BlockWrap className="width_1-3">
                        <WrapInput className="wrap-input--delete" label="Contacts" name="contacts">
                            {contacts.map((contact, index) => (
                                <Fragment key={`Input-${contact.frontId}`}>
                                    <Input
                                        type="asyncSelect"
                                        name={`Contacts-${index}`}
                                        url="project/projects/contact-list"
                                        value={contact.value}
                                        placeholder={t("Search")}
                                        onChange={value =>
                                            _onChangeArray(
                                                index,
                                                { frontId: contact.frontId, value },
                                                "projectTeamAndContactsBlock.contacts",
                                                contacts,
                                            )
                                        }
                                    />
                                    {contacts.length > 1 && (
                                        <LinkIcon
                                            className="icon-delete"
                                            icon="la-icon-close"
                                            onClick={() =>
                                                _onDeleteArray(
                                                    contact.frontId,
                                                    "projectTeamAndContactsBlock.contacts",
                                                    contacts,
                                                )
                                            }
                                        />
                                    )}
                                </Fragment>
                            ))}
                            <Button
                                onClick={() =>
                                    _onAddArray(
                                        contacts[contacts.length - 1].frontId,
                                        "projectTeamAndContactsBlock.contacts",
                                        contacts,
                                    )
                                }
                                className="size_normal main-btn main-btn_white"
                            >
                                Add Contact
                            </Button>
                            <LinkIcon blank url="/contacts/create">
                                {t("Add New Contact")}
                            </LinkIcon>
                        </WrapInput>
                    </BlockWrap>
                </BlockShadow>
            </Block>
        </>
    );
};

ProjectTeamAndContact.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        projectManager: getStoreItem(state, "projectTeamAndContactsBlock.projectManager"),
        internalTeam: getStoreItem(state, "projectTeamAndContactsBlock.internalTeam"),
        contacts: getStoreItem(state, "projectTeamAndContactsBlock.contacts"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ProjectTeamAndContact);
