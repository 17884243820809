import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ModalDefault } from "Templates/Modals";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import { withServiceConsumer } from "Services/Context";

const { Button, Form, Title, Col, Row, ColText, ColInput } = ModalDefault;

const propTypes = {
    titleText: PropTypes.string.isRequired,
    applicationCode: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onAppend: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

class CreateApplicationModal extends Component {
    state = {
        name: ""
    };

    handleChange = label => value => {
        this.setState({ [label]: value });
    };

    handleClickOnSaveButton = () => {
        const { name } = this.state;
        const { onAppend, onClose, applicationCode } = this.props;
        onAppend({ name, applicationCode }).then(onClose);
    };

    render() {
        const { name } = this.state;
        const {
            titleText,
            applicationCode,
            buttonText,
            t,
            onClose,
        } = this.props;

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose} />
                <Title>{t(titleText)}</Title>
                <Row>
                    <Col>
                        <ColText className="bold-text" isRequired>
                            {t("Name")}
                        </ColText>
                        <ColInput
                            placeholder={t("Enter Name")}
                            name="name"
                            value={name}
                            onChange={this.handleChange("name")}
                        />
                    </Col>
                </Row>
                <Button onClick={this.handleClickOnSaveButton}
                        disabled={name.trim().length === 0 || applicationCode === undefined || applicationCode === ''}>
                    {t(buttonText)}
                </Button>
            </Form>
        );
    }
}

CreateApplicationModal.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(CreateApplicationModal);
