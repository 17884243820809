import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Div } from "Templates/Default";
import { Block, BlockWrap, Input, WrapInput, BlockTitle, BlockShadow } from "Templates/Form";
import parse from "html-react-parser";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeLegal: PropTypes.func.isRequired,
    legal: PropTypes.string.isRequired,
    legalHTML: PropTypes.string.isRequired
};

class Legal extends Component {
    state = {
        showContent: true,
    };

    toggleContent = () => {
        this.setState(({ showContent }) => ({
            showContent: !showContent,
        }));
    };

    render() {
        const { showContent } = this.state;
        const {
            legal,
            legalHTML,
            onChange,
            onChangeLegal,
            t
        } = this.props;

        const onChangeInput = (value, key) => {
            onChangeLegal(`legalHTML`, value);
            onChange(key, value);
        };

        return (
            <>
                <Div className="toggle__show-block">
                    <BlockTitle>{t("Legal Copy")}</BlockTitle>
                    <button
                        type="button"
                        className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                        onClick={() => this.toggleContent()}
                    >
                        Show
                        {' '}
                        {showContent ? 'Less' : 'More'}
                    </button>
                </Div>
                <Block>
                    {showContent ?
                        (
                            <BlockShadow>
                                <WrapInput label={t("Legal")} className=" width_1-5" name="legal">
                                    <Input
                                        type="asyncSelect"
                                        placeholder={t("Select")}
                                        url="/product/designs/legal-list"
                                        value={legal}
                                        onChange={value => onChangeInput(value, `legal`)}
                                    />
                                </WrapInput>
                                {legalHTML && (
                                    <Block>
                                        <BlockWrap>
                                            <div id="css-render-form">{parse(legalHTML)}</div>
                                        </BlockWrap>
                                    </Block>
                                )}
                            </BlockShadow>
                        ) : ''}
                </Block>
            </>
        );
    }
}

Legal.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        legal: getStoreItem(state, "legal"),
        legalHTML: getStoreItem(state, "legalHTML"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        onChangeLegal: getActionStore("onChangeLegal", service, dispatch)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Legal);
