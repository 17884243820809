import React from "react";
import { compose } from "redux";
import { Form, BlockMain } from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import MainBlock from "./MainBlock";

const FormBlock = () => {
    return (
        <Form>
            <BlockMain title="">
                <MainBlock />
            </BlockMain>
        </Form>
    );
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(FormBlock);
