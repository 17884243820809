import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowItemEdit from "./TableRowItemEdit";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    completed: PropTypes.bool,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

const TableRowColumnButton = ({ item, index, onClickDelete, onClickEdit }) => {
    const _onClickDelete = () => {
        onClickDelete(index);
    };
    const _onClickEdit = () => {
        onClickEdit(index);
    };

    return (
        <TableRowItemEdit
            item={item}
            onClickDelete={_onClickDelete}
            onClickEdit={_onClickEdit}
        />
    );
};

TableRowColumnButton.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowColumnButton);
