import React from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import { getString, formatDate } from "Services";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const tableRowItemLink = (labelKey, href) => (
        <TableRowItemLink title={getString(item, labelKey)} href={href}>
            {getString(item, labelKey)}
        </TableRowItemLink>
    );

    switch (key) {
        case "name":
            return (
                <TableRowItemSpan title={getString(item, key)}>
                    {getString(item, key, '')}
                </TableRowItemSpan>
            );
        case "show_filter":
            return (
                <TableRowItemSpan title={getString(item, key)}>
                    {getString(item, key, '') ? 'Yes' : 'No'}
                </TableRowItemSpan>
            );

        case "submitted_by":
            return tableRowItemLink("submitted_by", `/admin/users/${getString(item, "submitted_id")}`);

        default:
            const date = formatDate(getString(item, key));
            return <TableRowItemSpan title={date}>{date}</TableRowItemSpan>;
    }
};
