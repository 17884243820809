import React from "react";
import _ from "lodash";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { ContainerTable } from "Hoc/Widgets";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import { TableRowColumnWrap } from "Templates/Table/TableRowColumnWrap";
import { TabWrap } from "Templates/Content";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);

const propTypes = {
    customIcon: PropTypes.string,
    onClickItem: PropTypes.func,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const TabViewDetailWrap = TabTable => ({
    onClickCustom,
    onClickEdit,
    onClickDelete,
    onClickItem,
    pref,
    customIcon,
    items,
    pagination,
    loading,
    fetchItems,
    fetchPage,
    request,
    service,
}) => {
    const { page, pages } = pagination;

    const { handleScroll } = service;

    if (items === undefined) {
        if (!loading) {
            fetchItems({});
        }
        return "";
    }

    const onScroll = e => {
        if (page >= pages) {
            return;
        }

        handleScroll(e).then(() => {
            request.page = page + 1;

            fetchPage(request);
        });
    };

    const _onClickEdit = e => {
        const item = _.get(items, e);
        if (item) {
            onClickEdit(item);
        }
    };

    const _onClickDelete = e => {
        const item = _.get(items, e);
        if (item) {
            onClickDelete(item);
        }
    };

    const _onClickCustom = e => {
        const item = _.get(items, e);
        if (item) {
            onClickCustom(item);
        }
    };

    return (
        <TabWrap>
            <div
                className={`${pref}__wrap-body main-wrap-block__body main-wrap-block__body_height`}
                onScroll={onScroll}
            >
                <TabTable
                    onClickCustom={_onClickCustom}
                    customIcon={customIcon}
                    onClickItem={onClickItem}
                    onClickEdit={_onClickEdit}
                    onClickDelete={_onClickDelete}
                />
            </div>
        </TabWrap>
    );
};

TabViewDetailWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        items: getStoreItem(state, "items"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        loading: getStoreItem(state, "loading"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    TabViewDetailWrap,
)(DefaultTabTable);

export const customTable = CustomTable =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
        TabViewDetailWrap,
    )(CustomTable);
