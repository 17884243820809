import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const hierarchyProperties = _.get(data, "hierarchyProperties", [])
        .filter(manufacturing => _.trim(manufacturing.manufacturing) && manufacturing.parameters.filter(parameter => _.trim(parameter.parameter)));

    const dataReq = {
        hierarchyProperties
    };

    return formDataNormalizer(dataReq);
};
