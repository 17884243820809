import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Block, BlockWrap, Input, WrapInput } from "Templates/Form";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    publicationLogo: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const PublicationLogo = (props) => {
    const {
        t,
        publicationLogo,
        onChange
    } = props;

    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
                <Block>
                    <BlockWrap>
                        <WrapInput isRequired label={t("Publication Logo")} name="publicationLogo">
                            <Input
                                type="asyncSelect"
                                url="/admin/list/publication-logos/list"
                                placeholder={t("Select")}
                                value={publicationLogo}
                                onChange={value => onChangeInput(value, `publicationLogo`)}
                            />
                        </WrapInput>
                    </BlockWrap>
                </Block>
    );
}

PublicationLogo.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        publicationLogo: getStoreItem(state, "publicationLogo"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(PublicationLogo);
