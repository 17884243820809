import React, {Component} from "react";
import { withTagDefaultProps } from "Hoc/Template";
import {compose} from "redux";
import Img from "Templates/Img/Img";
import {withServiceConsumer} from "Services/Context";

const approvedData = [
    {
        type: 1,
        title: 'Approved for SAP',
    },
    {
        type: 2,
        title: 'Approved for Shared Products',
    },
];

class Approve extends Component {
    constructor(props) {
        super(props);

        this.approver = props.approver;
        this.email = props.approverEmail;
        this.date = props.approveDate;
        this.type = props.approveType;
        this.approvedData = this.type && approvedData.filter(item => item.type === this.type);
    }

    render() {
        if (this.approvedData && this.approvedData.length) {
            return this.approvedData.map((item, i) => {
                return (
                    <div key={i} className="is-approved-block">
                        <div className="is-approved-block__wrap">
                            <div className="is-approved-block__labels-wrap">
                                <div className="is-approved-block__label">
                            <span>
                                <Img width="18" height="18" img="icon_checked" alt="Approved" />
                            </span>
                                    <p>{item.title}</p>
                                </div>
                            </div>
                            {item.type === 'Sharing' &&
                                <button type="button">
                                    <Img width="16" height="18" img="icon_share" alt="Share" />
                                </button>
                            }
                        </div>
                        <div className="is-approved-block__info">
                            <p className="title">Approved by</p>
                            <p className="approver">{this.approver}</p>
                            <a href={`mailto: ${this.email}`} className="email" title={this.email}>{this.email}</a>
                            <p>{this.date}</p>
                        </div>
                    </div>
                );
            });
        } 
            return null;
        
    }
}

Approve.propTypes = {};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Approve);