import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { url as urlService, request } from "Services";
import AsyncMultiSelectReact from "./AsyncMultiSelectReact";

const propTypes = {
    disabled: PropTypes.bool,
    url: PropTypes.string,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    ]).isRequired,
    inputProps: PropTypes.shape({ isOptionDisabled: PropTypes.func }),
};

const defaultProps = {
    disabled: false,
    url: "",
    placeholder: "Search",
    inputProps: { isOptionDisabled: option => option.disable },
    handleChange: () => {},
};

class AsyncMultiSelectNoCache extends Component {
    state = {
        value: false,
    };

    componentDidMount = () => {
        const { value } = this.props;
        this.setValue(value);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { value } = this.state;
        if (value && nextProps.value !== value.value && nextProps.value === "") {
            this.setState({
                value: false,
            });
        }

        // TODO, bad code
        if (!value || nextProps.value !== value.value) {
            this.setValue(nextProps.value);
        }
    }

    handleChange = selectedOption => {
        this.setState(
            {
                value: selectedOption,
            },
            () => {
                const { handleChange } = this.props;
                handleChange(selectedOption ? selectedOption.map(v => v.value) : "");
            },
        );
    };

    getOptions = url => data => {
        if (url.includes("//")) {
            return new Promise(resolve => resolve([]));
        }

        return new Promise((resolve, reject) => {
            request
                .sendRequestWithNoCache(
                    {
                        url: urlService.getUrl(url),
                        data,
                        type: "GET",
                    },
                    false,
                )
                .then(res => {
                    const options = _.get(res, "0.items", []);
                    resolve(options);
                }, reject);
        });
    };

    setValue = value => {
        const { url } = this.props;

        if (value && !url.includes("//")) {
            this.getOptions(`${url}/${value}`)({}).then(options => {
                this.setState({
                    value: options || {},
                });
            });
        }
    };

    render = () => {
        const { value } = this.state;
        const { url, disabled, inputProps, placeholder } = this.props;

        const customStyles = {
            control: base => ({
                ...base,
            }),
        };

        const loadOptions = this.getOptions(url);

        return (
            <AsyncMultiSelectReact
                isMulti
                isLoading
                menuPosition="fixed"
                // closeMenuOnScroll={e => !e.target.parentNode.className.includes("menu")}
                closeMenuOnScroll={e => !(e.target.parentNode || {className: ''}).className.includes("menu")}
                placeholder={placeholder}
                isDisabled={disabled}
                inputProps={inputProps}
                styles={customStyles}
                loadArguments={{ url }}
                value={value}
                className="select-custom-wrap basic-multi-select"
                cacheOptions
                defaultOptions
                isClearable={false}
                onChange={this.handleChange}
                onResetOptions={this.handleChange}
                loadOptions={label => loadOptions({ label })}
            />
        );
    };
}

AsyncMultiSelectNoCache.propTypes = propTypes;
AsyncMultiSelectNoCache.defaultProps = defaultProps;

export default AsyncMultiSelectNoCache;
