import Constants from "../Constants";

export const initState = {
    chartType_2: "line",
    selectedValue_2: "all",
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TYPE_CHART_2:
            return { ...state, chartType_2: data };

        case Constants.GET_CHART_DATA_REQUESTED_2:
            return { ...state, loading: true };

        case Constants.GET_CHART_DATA_SUCCEEDED_2:
            return {
                ...state,
                selectedValue_2: data.selectedValue_2,
                dashboard: { ...state.dashboard, graph: data.items },
                loading: false,
            };

        case Constants.GET_CHART_DATA_FAILED_2:
            return { ...state, errors: { ...data }, loading: false };

        default:
            return state;
    }
};
