import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {Tooltip} from "Templates/Form";
import {onChange} from "../../Services/EventsService";

function MaterialProperty({callback, filter}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>Technology Property</BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    <p className="main-table__head-link filter-with-tooltip">
                        Property
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/product/product-finder/solution-material-property-list/1"
                        name="property"
                        value={filter.property}
                        onChange={e => {
                            onChange(e).then(_value => callback('property', _value));
                        }}
                    />
                    <p className="main-table__head-link filter-with-tooltip">
                        Parameter 1
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/product/product-finder/solution-material-property-list/2"
                        name="parameter1"
                        value={filter.parameter1}
                        onChange={e => {
                            onChange(e).then(_value => callback('parameter1', _value));
                        }}
                    />
                    <p className="main-table__head-link filter-with-tooltip">
                        Parameter 2
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        name="parameter2"
                        url="/product/product-finder/solution-material-property-list/3"
                        value={filter.parameter2}
                        onChange={e => {
                            onChange(e).then(_value => callback('parameter2', _value));
                        }}
                    />
                    <p className="main-table__head-link filter-with-tooltip">
                        Parameter 3
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        name="parameter3"
                        url="/product/product-finder/solution-material-property-list/4"
                        value={filter.parameter3}
                        onChange={e => {
                            onChange(e).then(_value => callback('parameter3', _value));
                        }}
                    />
                </div>
            )}
        </>
    );
}

MaterialProperty.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default MaterialProperty;
