import Constants from "../Constants";
import {requestGet} from "../Services/RequestService";

export const onChangeChartTypeAction_2 = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_TYPE_CHART_2, data });
};

export const getChartDataAction_2 = (service, dispatch) => (data, level) => {
    dispatch({ type: Constants.GET_CHART_DATA_REQUESTED_2 });

    const getChartDataRequest = requestGet(data === "business-all" ? `/dashboard/graph/company/${level}/business` : `/dashboard/graph/company/${level}`);

    const form = {};
    if (data !== "all") {
        const list = {
            lead: 10,
            opportunity: 9,
            target: 8,
            current: 7,
            current_target: 6,
            level4: 4,
            level3: 3,
            level2: 2,
            level1: 1
        };
        form.filter = { status: list[data] };
    }

    getChartDataRequest(form).then(
        res => {
            dispatch({ type: Constants.GET_CHART_DATA_SUCCEEDED_2, data: { items: res.items, selectedValue_2: data } });
        },
        res => {
            dispatch({ type: Constants.GET_CHART_DATA_FAILED_2, data: res });
        },
    );
};