import { request } from "Services";

const listUrl = "/login";

const getItems = data =>
    request.sendRequest({
        url: listUrl,
        data,
        type: "POST",
    });

export const requestOnSubmitForm = data => {
    return new Promise((resolve, reject) => {
        getItems(data).then(
            res => {
                resolve({ ...res[0] });
            },
            error => reject(error),
        );
    });
};
