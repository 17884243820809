import React, {Component, Fragment} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {BlockWrap, Input, WrapInput, BlockMain, BlockShadow, Block, BlockTitle, Tooltip, Col} from "Templates/Form";
import {datasheetLocation, datasheetPaintable, gradeStatusList, isBoundable} from "Services/enum";
import { Div, Ul, Li, Button, Span } from "Templates/Default";
import ApplicationBlock from "./ApplicationBlock";
import Colors from "./Colors";
import {CheckboxBlock, CheckboxButton, CheckboxLabel} from "Templates/Form/Checkbox";
import {RadioBlock, RadioButton, RadioLabel} from "Templates/Form/Radio";
import {LinkIcon} from "Templates/Link";
import _ from "lodash";
import ColorsPartApproval from "./ColorsPartApproval";

const propTypes = {
    productName: PropTypes.string,
    grade: PropTypes.string.isRequired,
    productCode: PropTypes.any,
    productCodeName: PropTypes.string,
    materialNumber: PropTypes.string,
    molded:PropTypes.objectOf(PropTypes.any).isRequired,
    locations: PropTypes.arrayOf(PropTypes.any).isRequired,
    paintable: PropTypes.string.isRequired,
    is_boundable: PropTypes.string.isRequired,
    status: PropTypes.any,
    isSap: PropTypes.bool.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

class GeneralForm extends Component {
    render() {
        const {
            productName,
            grade,
            materialNumber,
            productCode,
            productCodeName,
            status,
            paintable,
            molded,
            is_boundable,
            locations,
            isSap,
            onChange,
            t
        } = this.props;

        const onChangeInput = (value, key) => {
            onChange(key, value);
        };

        const onChangeMolded = (value, key) => {
            const _molded = _.cloneDeep(molded);

            _molded[key] = value;
            onChange(`molded`, _molded);
        };

        const _onAddArray = (lastFrontId, key, array) => {
            onChange(key, [...array, {frontId: lastFrontId + 1, value: ""}]);
        };

        const _onChangeArray = (index, value, key, array) => {
            _.set(array, index, value);
            onChange(key, _.uniqBy(_.concat(array), 'value'));
        };

        const _onDeleteArray = (frontId, key, array) => {
            onChange(key, array.filter(elem => elem.frontId !== frontId));
        };

        return isSap ? (
            <>
                <>
                    <BlockTitle>
                        <Span>{t("Status")}</Span>
                    </BlockTitle>
                    <BlockShadow blockName="project_status">
                        <div className={'grade-statuses-wrap'}>
                            <Ul className="status">
                                {gradeStatusList.map((elem, index) => (
                                    <Li key={`status-${index}`} className="status__item">
                                        <Button
                                            className={`status__button ${
                                                elem.order === status ? elem.statusColor : ""
                                            }`}
                                            onClick={() => {
                                                onChange("generalForm.status", elem.order);
                                            }}
                                        >
                                            {t(elem.label)}
                                            {elem.tooltip ? <Tooltip>{t(elem.tooltip)}</Tooltip> : null}
                                        </Button>
                                    </Li>
                                ))}
                            </Ul>
                            {status &&
                                <button type={'button'}
                                        className="clear-all-btn"
                                        onClick={() => onChange("generalForm.status", '')}>
                                    Clear All
                                </button>
                            }
                        </div>
                    </BlockShadow>
                </>
                <BlockMain title="General Info">
                    <BlockShadow>
                        <BlockWrap>
                            <Block className="molded-wrap">
                                <BlockWrap>
                                    <WrapInput label={t("Product Source")} name="product">
                                        <Div>{productName}</Div>
                                    </WrapInput>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput label={t("Material Number")} name="materialNumber">
                                        <Div>{materialNumber}</Div>
                                    </WrapInput>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput label={t("Product Name")} name="grade">
                                        <Div>{grade}</Div>
                                    </WrapInput>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput label={t("Product Code")} name="productCode">
                                        <Div>{productCodeName || '-'}</Div>
                                    </WrapInput>
                                </BlockWrap>
                            </Block>
                            <Block className="molded-wrap">
                                <BlockWrap>
                                    <div className={'is-molder publications-is-molder'}>
                                        <WrapInput label={t("Mold-in-Color")} name="is_molded">
                                            <Tooltip>
                                                {`Mold-in-Color is a pre-colored plastic resin. You must have a color code for 80% or 100% color match. Please contact your MCGC representative for more information.`}
                                            </Tooltip>
                                            <div className='molder-description'>
                                                <CheckboxBlock>
                                                    <CheckboxButton
                                                        htmlFor="molded_yes"
                                                        value={!!molded.yes}
                                                        onChange={value => onChangeMolded(value, 'yes')}
                                                    >
                                                        <CheckboxLabel label="Yes"/>
                                                    </CheckboxButton>
                                                </CheckboxBlock>
                                                <Input
                                                    name="yes_desc"
                                                    value={molded.yes_desc}
                                                    onChange={value => {
                                                        onChangeMolded(value, 'yes_desc');
                                                    }}
                                                />
                                            </div>
                                            <div className='molder-description'>
                                                <CheckboxBlock>
                                                    <CheckboxButton
                                                        htmlFor="molded_no"
                                                        value={!!molded.no}
                                                        onChange={value => onChangeMolded(value, 'no')}
                                                    >
                                                        <CheckboxLabel label="No"/>
                                                    </CheckboxButton>
                                                </CheckboxBlock>
                                                <Input
                                                    name="no_desc"
                                                    value={molded.no_desc}
                                                    onChange={value => {
                                                        onChangeMolded(value, 'no_desc');
                                                    }}
                                                />
                                            </div>
                                            <div className='molder-description'>
                                                <CheckboxBlock>
                                                    <CheckboxButton
                                                        htmlFor="checkbox_molded_can_be_matched"
                                                        value={!!molded.can_be_matched}
                                                        onChange={value => onChangeMolded(value, 'can_be_matched')}
                                                    >
                                                        <CheckboxLabel label="No, can be color matched"/>
                                                    </CheckboxButton>
                                                </CheckboxBlock>
                                                <Input
                                                    name="can_be_matched_desc"
                                                    value={molded.can_be_matched_desc}
                                                    onChange={value => {
                                                        onChangeMolded(value, 'can_be_matched_desc');
                                                    }}
                                                />
                                            </div>
                                        </WrapInput>
                                    </div>
                                </BlockWrap>
                                <BlockWrap>
                                    <div className={'is-boundable'}>
                                        <WrapInput label={t("Bondable")} name="is_boundable">
                                            {[...isBoundable].map(elem => (
                                                <Col key={`Col-${elem.value}`}>
                                                    <RadioBlock>
                                                        <RadioButton
                                                            htmlFor={`radio_2-${elem.value}`}
                                                            name={`radio_3-${elem.value}`}
                                                            value={elem.value === is_boundable}
                                                            onChange={() => onChangeInput(elem.value, `is_boundable`)}
                                                        >
                                                            <RadioLabel label={t(elem.label)}/>
                                                        </RadioButton>
                                                    </RadioBlock>
                                                </Col>
                                            ))}
                                        </WrapInput>
                                    </div>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput
                                        className={locations.length > 1 ? "wrap-input--delete" : ""}
                                        label="Vehicle Location"
                                        name="location"
                                    >
                                        {locations.map((location, index) => (
                                            <Fragment key={`Input-${location.frontId}`}>
                                                <Input
                                                    type="select"
                                                    name={`location-${index}`}
                                                    options={[{value: '', label: "-"}, ...datasheetLocation]}
                                                    value={location.value}
                                                    placeholder={t("Select")}
                                                    onChange={
                                                        value => _onChangeArray(
                                                            index,
                                                            {frontId: location.frontId, value},
                                                            "locations",
                                                            locations,
                                                        )
                                                    }
                                                />
                                                {locations.length > 1 && (
                                                    <LinkIcon
                                                        className="icon-delete"
                                                        icon="la-icon-close"
                                                        onClick={
                                                            () => _onDeleteArray(location.frontId, "locations", locations)
                                                        }
                                                    />
                                                )}
                                            </Fragment>
                                        ))}
                                        <Button
                                            onClick={() =>
                                                _onAddArray(
                                                    locations[locations.length - 1].frontId,
                                                    "locations",
                                                    locations,
                                                )}
                                            className="size_normal main-btn main-btn_white"
                                        >
                                            Add Vehicle Location
                                        </Button>
                                    </WrapInput>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput label={t("Paintable")} name="paintable">
                                        <Input
                                            name="paintable"
                                            type="select"
                                            options={[{value: '', label: "-"}, ...datasheetPaintable]}
                                            placeholder={t("Select")}
                                            value={paintable}
                                            onChange={value => onChangeInput(value, `paintable`)}
                                        />
                                    </WrapInput>
                                </BlockWrap>
                            </Block>
                        </BlockWrap>
                    </BlockShadow>
                </BlockMain>
                <Colors/>
                <ColorsPartApproval />
                <ApplicationBlock/>
            </>
        ) : (
            <>
                <>
                    <BlockTitle>
                        <Span>{t("Status")}</Span>
                    </BlockTitle>
                    <BlockShadow blockName="project_status">
                        <div className={'grade-statuses-wrap'}>
                            <Ul className="status">
                                {gradeStatusList.map((elem, index) => (
                                    <Li key={`status-${index}`} className="status__item">
                                        <Button
                                            className={`status__button ${
                                                elem.order === status ? elem.statusColor : ""
                                            }`}
                                            onClick={() => {
                                                onChange("generalForm.status", elem.order);
                                            }}
                                        >
                                            {t(elem.label)}
                                            {elem.tooltip ? <Tooltip>{t(elem.tooltip)}</Tooltip> : null}
                                        </Button>
                                    </Li>
                                ))}
                            </Ul>
                            {status &&
                                <button type={'button'}
                                        className="clear-all-btn"
                                        onClick={() => onChange("generalForm.status", '')}>
                                    Clear All
                                </button>
                            }
                        </div>
                    </BlockShadow>
                </>
                <BlockMain title="General Info">
                    <BlockShadow>
                        <BlockWrap>
                            <Block className="molded-wrap">
                                <WrapInput label={t("Product Source")} name="product">
                                    <Div>My Products (NonSap)</Div>
                                </WrapInput>
                                <BlockWrap>
                                    <WrapInput label={t("Material Number")} name="materialNumber">
                                        <Input
                                            name="materialNumber"
                                            value={materialNumber}
                                            onChange={value => onChangeInput(value, `generalForm.materialNumber`)}
                                            placeholder="Material Number"
                                        />
                                    </WrapInput>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput label={t("Product Name")} name="grade">
                                        <Input
                                            name="grade"
                                            value={grade}
                                            onChange={value => onChangeInput(value, `generalForm.grade`)}
                                            placeholder="Product Name"
                                        />
                                    </WrapInput>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput label={t("Product code")} name="productCodeName">
                                        <Input
                                            name="productCodeName"
                                            value={productCode}
                                            type="asyncSelect"
                                            url="/product/product-finder/product-code-list"
                                            onChange={value => onChangeInput(value, "generalForm.productCode")}
                                            placeholder="Product Code"
                                        />
                                    </WrapInput>
                                </BlockWrap>
                            </Block>
                            <Block className="molded-wrap">
                                <BlockWrap>
                                    <div className={'is-molder publications-is-molder'}>
                                        <WrapInput label={t("Mold-in-Color")} name="is_molded">
                                            <Tooltip>
                                                {`Mold-in-Color is a pre-colored plastic resin. You must have a color code for 80% or 100% color match. Please contact your MCGC representative for more information.`}
                                            </Tooltip>
                                            <div className='molder-description'>
                                                <CheckboxBlock>
                                                    <CheckboxButton
                                                        htmlFor="molded_yes"
                                                        value={!!molded.yes}
                                                        onChange={value => onChangeMolded(value, 'yes')}
                                                    >
                                                        <CheckboxLabel label="Yes"/>
                                                    </CheckboxButton>
                                                </CheckboxBlock>
                                                <Input
                                                    name="yes_desc"
                                                    value={molded.yes_desc}
                                                    onChange={value => {
                                                        onChangeMolded(value, 'yes_desc');
                                                    }}
                                                />
                                            </div>
                                            <div className='molder-description'>
                                                <CheckboxBlock>
                                                    <CheckboxButton
                                                        htmlFor="molded_no"
                                                        value={!!molded.no}
                                                        onChange={value => onChangeMolded(value, 'no')}
                                                    >
                                                        <CheckboxLabel label="No"/>
                                                    </CheckboxButton>
                                                </CheckboxBlock>
                                                <Input
                                                    name="no_desc"
                                                    value={molded.no_desc}
                                                    onChange={value => {
                                                        onChangeMolded(value, 'no_desc');
                                                    }}
                                                />
                                            </div>
                                            <div className='molder-description'>
                                                <CheckboxBlock>
                                                    <CheckboxButton
                                                        htmlFor="checkbox_molded_can_be_matched"
                                                        value={!!molded.can_be_matched}
                                                        onChange={value => onChangeMolded(value, 'can_be_matched')}
                                                    >
                                                        <CheckboxLabel label="No, can be color matched"/>
                                                    </CheckboxButton>
                                                </CheckboxBlock>
                                                <Input
                                                    name="can_be_matched_desc"
                                                    value={molded.can_be_matched_desc}
                                                    onChange={value => {
                                                        onChangeMolded(value, 'can_be_matched_desc');
                                                    }}
                                                />
                                            </div>
                                        </WrapInput>
                                    </div>
                                </BlockWrap>
                                <BlockWrap>
                                    <div className={'is-boundable'}>
                                        <WrapInput label={t("Bondable")} name="is_boundable">
                                            {[...isBoundable].map(elem => (
                                                <Col key={`Col-${elem.value}`}>
                                                    <RadioBlock>
                                                        <RadioButton
                                                            htmlFor={`radio_2-${elem.value}`}
                                                            name={`radio_3-${elem.value}`}
                                                            value={elem.value === is_boundable}
                                                            onChange={() => onChangeInput(elem.value, `is_boundable`)}
                                                        >
                                                            <RadioLabel label={t(elem.label)}/>
                                                        </RadioButton>
                                                    </RadioBlock>
                                                </Col>
                                            ))}
                                        </WrapInput>
                                    </div>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput
                                        className={locations.length > 1 ? "wrap-input--delete" : ""}
                                        label="Vehicle Location"
                                        name="location"
                                    >
                                        {locations.map((location, index) => (
                                            <Fragment key={`Input-${location.frontId}`}>
                                                <Input
                                                    type="select"
                                                    name={`location-${index}`}
                                                    options={[{value: '', label: "-"}, ...datasheetLocation]}
                                                    value={location.value}
                                                    placeholder={t("Select")}
                                                    onChange={
                                                        value => _onChangeArray(
                                                            index,
                                                            {frontId: location.frontId, value},
                                                            "locations",
                                                            locations,
                                                        )
                                                    }
                                                />
                                                {locations.length > 1 && (
                                                    <LinkIcon
                                                        className="icon-delete"
                                                        icon="la-icon-close"
                                                        onClick={
                                                            () => _onDeleteArray(location.frontId, "locations", locations)
                                                        }
                                                    />
                                                )}
                                            </Fragment>
                                        ))}
                                        <Button
                                            onClick={() =>
                                                _onAddArray(
                                                    locations[locations.length - 1].frontId,
                                                    "locations",
                                                    locations,
                                                )}
                                            className="size_normal main-btn main-btn_white"
                                        >
                                            Add Vehicle Location
                                        </Button>
                                    </WrapInput>
                                </BlockWrap>
                                <BlockWrap>
                                    <WrapInput label={t("Paintable")} name="paintable">
                                        <Input
                                            name="paintable"
                                            type="select"
                                            options={[{value: '', label: "-"}, ...datasheetPaintable]}
                                            placeholder={t("Select")}
                                            value={paintable}
                                            onChange={value => onChangeInput(value, `paintable`)}
                                        />
                                    </WrapInput>
                                </BlockWrap>
                            </Block>
                        </BlockWrap>
                    </BlockShadow>
                </BlockMain>
                <Colors/>
                <ColorsPartApproval />
                <ApplicationBlock/>
            </>
        )
    }
}

GeneralForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    const productCode = state.editState
        ? state.editState.generalForm.productCode.toString()
        : state.createState.generalForm.productCode.toString();

    return {
        product: getStoreItem(state, "generalForm.product"),
        productName: getStoreItem(state, "generalForm.productName"),
        isSap: getStoreItem(state, "isSap"),
        paintable: getStoreItem(state, 'paintable'),
        molded: getStoreItem(state, 'molded'),
        is_boundable: getStoreItem(state, 'is_boundable'),
        locations: getStoreItem(state, 'locations'),
        grade: getStoreItem(state, "generalForm.grade"),
        productCode,
        productCodeName: getStoreItem(state, "generalForm.productCodeName"),
        materialNumber: getStoreItem(state, "generalForm.materialNumber"),
        status: getStoreItem(state, "generalForm.status"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(GeneralForm);
