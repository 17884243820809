import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import TableHeadTitle from "./TableHeadTitle";
import TableHeadFilter from "Templates/Table/TableHeadFilter";
import parse from "html-react-parser";

const defaultProps = {
    currentFY: "",
    totalAnalytics: undefined,
};

const propTypes = {
    currentFY: PropTypes.string,
    onSortClick: PropTypes.func.isRequired,
    column: PropTypes.string.isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getFilter: PropTypes.func.isRequired,
        getTableLabel: PropTypes.func.isRequired,
    }).isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    totalAnalytics: PropTypes.any,
};

const TableHeadColumnDefault = ({
    t,
    currentFY,
    column,
    filter,
    sort,
    service,
    onSortClick,
    onChange,
    totalAnalytics,
}) => {
    const { getFilter, getTableLabel} = service;
    let getTooltip = service.getTooltip;
    return (
        <>
            <TableHeadTitle toolTip={getTooltip ? getTooltip(column) : null} column={column} sort={sort} onSortClick={() => onSortClick(column)}>
                {t(getTableLabel(column, { currentFY }))}
            </TableHeadTitle>
            <TableHeadFilter>
                {getFilter(column, _.get(filter, column, "") || _.get(filter, column + 's', ""), onChange, { filter, sort }, { totalAnalytics })}
            </TableHeadFilter>
        </>
    );
};

TableHeadColumnDefault.defaultProps = defaultProps;
TableHeadColumnDefault.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        filter: getStoreItem(state, "filter"),
        sort: getStoreItem(state, "sort"),
        currentFY: getStoreItem(state, "financialYear"),
        totalAnalytics: getStoreItem(state, "total_analytics"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TableHeadColumnDefault);
