import Constants from "../Constants";
import { url } from "../../../../Services";

export const submitFormAction = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_FROM_REQUEST });

    const { requestOnSubmitForm } = service;

    return requestOnSubmitForm(data).then(
        res => {
            dispatch({ type: Constants.SUBMIT_FROM_SUCCEEDED, data: res });
            url.redirect("/login");
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
        },
    );
};
