import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {countryListObj, getString} from "Services";
import {datasheetLocation, datasheetPaintable, gradeFileTypes, industryOptions, isMolded, isBoundable} from "Services/enum";
import _ from "lodash";

const propertyName = [
    { value: 'color', label: "Color" },
    { value: 'approval', label: "OEM Approval" },
    { value: 'approval_name', label: "OEM Approval" },
    { value: 'color_code', label: "Color Code" },
    { value: 'location', label: "Vehicle Location" },
    { value: 'process_property', label: "Manufacturing Process" },
    { value: 'process_parameter', label: "Process Parameter" },
    { value: 'market_segment', label: "Market Segment" },
    { value: 'segment', label: "Segment" },
    { value: 'sub_segment', label: "Sub-Segment" },
    { value: 'user', label: "Owner" },
    { value: 'name', label: "Product Name" },
    { value: 'molded', label: "Mold-in-Color" },
    { value: 'boundable', label: "Bondable" },
];

export const modifierValues = items => items;

const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
};

const getPropertyName = name => {
    const property = propertyName.find(el => el.value === name);
    const needle = property !== undefined ? property.label : undefined;

    return needle || capitalizeFirstLetter(name);
};

const showData = (elem) => {
    let { values } = elem;
    const { property } = elem;

    if (property === 'country') {
        values = values.map(item => {
            return _.get(countryListObj, item);
        });
    }

    if (property === 'paintable') {
        values = values.map(item => {
            return datasheetPaintable.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'market_segment') {
        values = values.map(item => {
            return industryOptions.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'location') {
        values = values.map(item => {
            return datasheetLocation.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'publication') {
        values = values.map(item => {
            return gradeFileTypes.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'molded') {
        values = values.map(item => {
            return isMolded.find(el => el.value.toString() === item.toString()).label;
        });
    }

    if (property === 'boundable') {
        values = values.map(item => {
            return isBoundable.find(el => el.value.toString() === item.toString()).label;
        });
    }

    return `${getPropertyName(property)}: ${values.join(', ')}`;
};

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            const foundBy = _.get(item, 'found_by', []);

            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key)}
                        href={`/product/product-finder/publications/${getString(item, "id")}/shared-products`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>

                    {foundBy.length !== 0 && <TableRowItemSpan className="main-text-bold">Found By:</TableRowItemSpan>}

                    {foundBy.map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={showData(elem)}>
                            {showData(elem)}
                        </TableRowItemSpan>
                    ))}
                </>
            );

        case "description":
            return (
                <TableRowItemSpan
                    title={getString(item, key)}
                    isShortText={false}
                >
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
