import React from "react";
import _ from "lodash";
import { taskStatus } from "Services/enum";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../EventsService";

const placeholder = {
    title: "Title",
    project: "Project Name",
    start: "Start Date",
    due: "Due Date",
    assigned_to: "Assigned To",
    assigned_by: "Assigned By",
    status: "Status",
};

const getFilter = (key, value, callback, request) => {
    switch (key) {
        case "sub_status":
            return (
                <InputFilter
                    type="select"
                    options={[
                        {
                            value: "",
                            label: "All",
                        },
                        ...taskStatus,
                    ]}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "start":
        case "due":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export { getFilter };
