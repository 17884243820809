import React from 'react';
import PropTypes from "prop-types";

import { TabBlockBtn } from "Templates/Content";

const propTypes = {
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
};

export const ButtonsGroup = ({ values, handleClick, active }) => {

  return (
    values.map(({ name, value }) => (
      <TabBlockBtn
        key={name}
        onClick={(e) => handleClick(e, value)}
        className={`${active === value ? `toggle-btn__active` : 'toggle-btn__primary-bg'} toggle-btn__primary`}
      >
        {name}
      </TabBlockBtn>
    ))
  );
}

ButtonsGroup.propTypes = propTypes;
