import React, {Fragment} from "react";
import {useSelector} from 'react-redux';
import {formatDate, getString, numberWithCommas} from "Services";
import {gradeStatus, isSapImported, projectConsistent, projectStatus} from "Services/enum";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {weightUnitName} from 'Services/weightUnitName';
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";

export const modifierValues = items => items;
export const tableButtonsPermission = "project_crud";

export const getViewItem = (key, item) => {
    const unit = useSelector(state => state.weightUnitState);

    const tableRowItemLink = (labelKey, href) => (
        <TableRowItemLink title={getString(item, labelKey)} href={href}>
            {getString(item, labelKey)}
        </TableRowItemLink>
    );

    const renderTableBody = () => {
        switch (key) {
            case "slug":
                return <TableRowItemLink blank
                                         title={getString(item, key)}
                                         href={`/project/projects/${getString(item, "id")}`}
                >
                    {getString(item, "slug")}
                </TableRowItemLink>

            case "name":
                return <TableRowItemLink blank
                                         title={getString(item, key)}
                                         href={`/project/projects/${getString(item, "id")}`}
                >
                    {getString(item, "name")}
                </TableRowItemLink>

            case "weight_value":
            case "unit_value": {
                return <TableRowItemSpan className='text-right' title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
            }

            case "product":
            case "manufacturer":
            case "grades":
            case "material_numbers":
            case "product_codes":
            case "product_application_codes":
            case "plants":
            case "lines":
            case "unit":
            case "internal_team":
            case "platform":
            case "program":
            case "brand":
            case "nameplate":
            case "country":
            case "region":
            case "production_plant":
            case "city": {
                return (
                    <>
                        {getString(item, key).split("\n").map((elem, index) => (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={elem}>
                                {elem}
                            </TableRowItemSpan>
                        ))}
                    </>
                );
            }

            case "replacement_business_project_id": {
                const replacementBusinessItems = item.replacement_business_projects
                return (
                    <>
                        {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                            <TableRowItemLink blank
                                              title={getString(item, key)}
                                              href={`/project/projects/${elem.id}`}
                            >
                                {elem.slug}
                            </TableRowItemLink>
                        )) : '-'}
                    </>
                );
            }

            case "replacement_business_project_name": {
                const replacementBusinessItems = item.replacement_business_projects
                return (
                    <>
                        {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                            <TableRowItemLink blank
                                              title={getString(item, key)}
                                              href={`/project/projects/${elem.id}`}
                            >
                                {elem.name}
                            </TableRowItemLink>
                        )) : '-'}
                    </>
                );
            }

            case "actual_sale_price":
                const arrDate = getString(item, key).split("\n");
                return (
                    <>
                        {arrDate.map((elem, index) => {
                            if (elem && !isNaN(+elem)) {
                                elem = +elem > 0 ? `$${(+elem).toFixed(2)}` : (+elem).toFixed(2)
                            } else {
                                elem = "0.00"
                            }

                            return (
                                <TableRowItemSpan className="text-right" key={`TableRowItemSpan-${index}`} title={elem}>
                                    {elem}
                                </TableRowItemSpan>
                            )
                        })}
                    </>
                );

            case "sub_status":
                return <TableRowItemStatus status={getString(item, key)} list={[...projectStatus]} />;

            case "status":
            case "budget":
                return <TableRowItemStatus status={getString(item, key)} list={projectConsistent} />;

            case "company_is_sap":
                return <TableRowItemStatus status={getString(item, key)} list={isSapImported} />;

            case "grade_is_sap":
                return (
                    <>
                        {getString(item, key).split("\n").map((elem, index) => {
                            return (
                                <div key={`div-${index}`}>
                                    <TableRowItemStatus
                                        key={`TableRowItemGradeIsSap-${index}`}
                                        status={elem}
                                        list={isSapImported}
                                    />
                                </div>
                            )
                        })}
                    </>
                );

            case "grade_sub_status":
                return (
                    <>
                        {getString(item, key).split("\n").map((elem, index) => {
                            return (
                                <div key={`div-${index}`}>
                                    <TableRowItemStatus
                                        key={`TableRowItemGradeSubStatus-${index}`}
                                        status={elem}
                                        list={gradeStatus}
                                    />
                                </div>
                            )
                        })}
                    </>
                );

            case String(key.match(/^volume_.*/)): {
                const keys = key.toLowerCase().split("_");
                return (
                    <TableRowItemSpan className="text-right">
                        {`${numberWithCommas(getString(item, `analytics.${keys[2]}.${keys[1]}.${keys[0]}`), 0)} ${weightUnitName(unit.weightUnit)}`}
                    </TableRowItemSpan>
                );
            }

            case String(key.match(/^revenue_.*/)): {
                const keys = key.toLowerCase().split("_");
                const value = getString(item, `analytics.${keys[2]}.${keys[1]}.${keys[0]}`)

                return (
                    <TableRowItemSpan className="text-right">
                        {`$${Number(value) < 10 ? Number(value).toFixed(2) : numberWithCommas(value, 0)}`}
                    </TableRowItemSpan>
                );
            }

            case String(key.match(/^grossMargin_.*/)): {
                const keys = key.toLowerCase().split("_");
                return (
                    <TableRowItemSpan
                        title={getString(item, `analytics.${keys[2]}.${keys[1]}.gross_margin`)}
                        className={`${
                            getString(item, `analytics.${keys[2]}.${keys[1]}.gross_margin`) < 0 ? "clr-red" : ""
                        } text-right`}
                    >
                        {`${getString(item, `analytics.${keys[2]}.${keys[1]}.gross_margin`)}%`}
                    </TableRowItemSpan>
                );
            }

            case "eop": {
                const arrDate = getString(item, key).split("\n");

                return (
                    <>
                        {arrDate.map((elem, index) => {
                            let dateColor;

                            const today = new Date();

                            const threeMonthsFromToday = new Date(today);
                            threeMonthsFromToday.setMonth(threeMonthsFromToday.getMonth() + 3);

                            if (today >= new Date(elem)) {
                                dateColor = "clr-red";
                            } else if (new Date(elem) <= threeMonthsFromToday) {
                                dateColor = "clr-yellow";
                            } else {
                                dateColor = ""
                            }

                            const formatValue = elem === '-' ? elem : formatDate(elem);
                            return (
                                <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                    <i className={dateColor}>{formatValue}</i>
                                </TableRowItemSpan>
                            )
                        })}
                    </>
                );
            }

            case "move_to_current":
            case "end_date":
            case "sop": {
                const arrDate = getString(item, key).split("\n");
                return (
                    <>
                        {arrDate.map((elem, index) => {
                            const formatValue = elem === '-' ? elem : formatDate(elem);
                            return (
                                <>
                                    <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                        {formatValue}
                                    </TableRowItemSpan>
                                </>
                            )
                        })}
                    </>
                );
            }

            case "los_date":
            case "created_at":
            case "updated_at":
            case "sub_status_changed_at": {
                const elem = getString(item, key);
                const formatValue = elem.toString() === '-' ? elem : formatDate(elem);

                return (
                    <TableRowItemSpan title={formatValue}>
                        {formatValue}
                    </TableRowItemSpan>
                );
            }

            case 'description':
            case 'note':
                return (
                    item[key]
                        ? <div className="publication-row"><TableRowItemTooltip>{item[key]}</TableRowItemTooltip></div>
                        : <TableRowItemSpan title="-">-</TableRowItemSpan>
                );

            default:
                return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
        }
    }

    return <Fragment key={`${key}`}>
            {renderTableBody()}
    </Fragment>
};
