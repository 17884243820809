import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { handleScroll } from "./EventsService";
import { requestNotifications, requestOnReadNotifications } from "./RequestService";
import { getMessage, getLink } from "./ViewService";
import * as notificationActions from "../Actions/NotificationActions";

export const getStoreItem = _getStoreItem("userState");

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...notificationActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export { getMessage, getLink, requestNotifications, requestOnReadNotifications, handleScroll };
