import React, { Component } from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {ContainerTable} from "Hoc/Widgets";
import { TabWrap } from "Templates/Content";
import TableHeadColumnWrap from "../../TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {};

class Shared extends Component {
    render = () => {
        return (
            <TabWrap className="myProduct__wrap">
                <TabViewDetail />
            </TabWrap>
        );
    };
}

Shared.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {};
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Shared);
