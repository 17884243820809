import _ from "lodash";
import { TableActions } from "../../Actions";
import { closeModal } from "../../Actions/Modal/ModalAction";

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableActions,
        closeModal,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export const getTableLabel = tableLabel => key => _.get(tableLabel, key, key);
