import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from "Hoc/Template";
import {CheckboxBlock, CheckboxButton} from "Templates/Form/Checkbox";
import {Block, BlockTitle} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import Status from "../Constants/status";

const propTypes = {
    title: PropTypes.string.isRequired,
};

const BlockMainCheck = ({title, t, addedToSalesForecast, children, onChange, showFull, statusProject}) => {
    const onChangeInput = (value, key) => {
        onChange(key, value)
    };

    return (
<>
        <div className="projects-form-wrap">
            {statusProject !== Status.ACTIVE ?
                (
                    <CheckboxBlock>
                        <CheckboxButton
                            htmlFor="checkbox_is_full"
                            value={showFull}
                            onChange={value => onChangeInput(value, "show_full")}
                        >
                            <BlockTitle>{t(title)}</BlockTitle>
                        </CheckboxButton>
                    </CheckboxBlock>
                ) : null}
            {/*<div className="projects-form-wrap__custom-checkbox">*/}
            {/*    <WrapInput label={t("Add to Budget & Sales Forecast")} name="addedToSalesForecast">*/}
            {/*        <CheckboxBlock>*/}
            {/*            <CheckboxButton*/}
            {/*                htmlFor="checkbox_addedToSalesForecast"*/}
            {/*                value={!!addedToSalesForecast}*/}
            {/*                onChange={value => onChange("generalBlock.addedToSalesForecast", value)}*/}
            {/*            />*/}
            {/*        </CheckboxBlock>*/}

            {/*        <Tooltip>*/}
            {/*            {t('Check the "Add to Budget & Sales Forecast" to include this project in the Budget & Sales Forecast.')}*/}
            {/*        </Tooltip>*/}
            {/*    </WrapInput>*/}
            {/*</div>*/}
        </div>
        <Block>{children}</Block>
</>
);
}

BlockMainCheck.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        addedToSalesForecast: getStoreItem(state, "generalBlock.addedToSalesForecast"),
        showFull: getStoreItem(state, "show_full"),
        statusProject: getStoreItem(state, "generalBlock.statusProject"),
    }
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(BlockMainCheck);


