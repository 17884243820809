import ReactOnRails from "react-on-rails";
import React from "react";
import Root from "App/View/Root";
import Pipeline from "./Index";
import * as service from "./Services";
import ShowPermission from "Hoc/Template/ShowPermission";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);

    return (
        <Root store={store} service={service}>
            <ShowPermission checkPermission={['pipeline_view']}>
                <Pipeline />
            </ShowPermission>
        </Root>
    );
};

export default App;
