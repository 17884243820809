import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "./EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        manufacturer: "Manufacturer",
        unit: "Unit Forecast Name",
        sop: "SOP",
        eop: "EOP",
        submitted_by: "Owner",
        submitted_date: "Date Submitted",
        total: "Total",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        manufacturer: "Search",
        unit: "Search",
        sop: "Select",
        eop: "Select",
        submitted_by: "Search",
        submitted_date: "Date Submitted",
        total: "Total",
    };
    switch (key) {
        case "submitted_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        case "sop":
        case "eop":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
