import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";

const propTypes = {
    slug: PropTypes.string.isRequired,
    productCode: PropTypes.string.isRequired,
    applicationCode: PropTypes.string.isRequired,
    plant: PropTypes.string.isRequired,
    materialType: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired
};

const InfoWrap = ({ slug, productCode, applicationCode, plant, materialType, t, onClickEdit }) => (
    <ContentInfoWrap>
        <ContentInfoTop onClickEdit={onClickEdit} urlBack="/connection/connections?activeTab=1" />
        <ContentInfoTitle>{slug}</ContentInfoTitle>

        <ContentInfoBlock />

        <ContentInfoBlock show={!!productCode}>
            <ContentInfoBlockTitle>{t("Product Code")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={productCode} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!applicationCode}>
            <ContentInfoBlockTitle>{t("SAP Application Code")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={applicationCode} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!plant}>
            <ContentInfoBlockTitle>{t("Selling Plant")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={plant} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!materialType}>
            <ContentInfoBlockTitle>{t("Material Type")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={materialType} />
        </ContentInfoBlock>
    </ContentInfoWrap>
);

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        slug: getStoreItem(state, "view.slug"),
        productCode: getStoreItem(state, "view.productCode"),
        applicationCode: getStoreItem(state, "view.applicationCode"),
        plant: getStoreItem(state, "view.plant"),
        materialType: getStoreItem(state, "view.materialType"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
