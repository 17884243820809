import React from "react";
import {useSelector} from 'react-redux';
import _ from "lodash";
import {numberWithCommas} from "Services";
import {gradeStatus, industryOptions, isSapImported, projectConsistent, projectStatusBudgetForecast} from "Services/enum";
import {InputFilter} from "Templates/Table/Filters";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {weightUnitName} from 'Services/weightUnitName';
import {onChange} from "./EventsService";

export const getTableLabel = (key, { currentFY }) => {
    const unit = useSelector(state => state.weightUnitState);

    const getDunsNumberData = () => {
        return 'D-U-N-S&#174; Number'
    }

    const tableLabel = {
        slug: "Project ID",
        name: "Project Name",
        product_code: "Product Code",
        sub_status: "Project Status",
        product_application_code: "SAP Application Code",
        manager: "Sales Manager (Project)",
        connection_manager: "Sales Manager (ZSales)",
        customer: "Customer Full Name",
        customer_short_name: "Customer Name",
        los_date: "Loss of Sales Date",
        sap_customer: "SAP Customer",
        customer_code: "Customer Code",
        customer_duns_number: getDunsNumberData(),
        grade: "Product Name",
        grade_sub_status: "Product Status",
        sap_grade: "SAP Product",
        material_number: "SAP Material Number",
        plant: "Selling Plant",
        move_to_current: "Month Volume Starts",
        volume: "Volume",
        revenue: "Revenue",
        actual_sale_price: `Price <br/> Forecast <br/> ($/${weightUnitName(unit.weightUnit)})`,
        actual_sale_average_price: `Average Sale Price <br/> Forecast <br/> ($/${weightUnitName(unit.weightUnit)})`,
        fixed_cost: `Fixed Cost <br/> Forecast <br/> ($/${weightUnitName(unit.weightUnit)})`,
        variable_cost: `Variable Cost <br/> Forecast <br/> ($/${weightUnitName(unit.weightUnit)})`,
    };

    switch (key) {
        case "volume":
        case "revenue":
            return `FY ${currentFY}  ${_.get(tableLabel, key, key)}`;

        case String(key.match(/^volume_.*/)): {
            const keys = key.split("_");
            return `${keys[1]} ${keys[2]} ${keys[3] || ''} <br/> ${_.get(tableLabel, keys[0], key)} Forecast`;
        }

        case String(key.match(/^actual_volume_.*/)): {
            const keys = key.split("_");
            return `${keys[2]} ${keys[3]} ${keys[4] || ''} <br/> Actual ${_.get(tableLabel, keys[1], key)}`;
        }

        case String(key.match(/^actual_sale_price_.*/)): {
            const keys = key.split("_");
            return `${keys[3]} ${keys[4]} ${keys[5] ? keys[5] : ''} <br> ${keys[2][0].toUpperCase() + keys[2].slice(1)} <br> Forecast ($/${weightUnitName(unit.weightUnit)})`;
        }

        case String(key.match(/^actual_sale_average_price_.*/)): {
            const keys = key.split("_");
            return `${keys[3]} ${keys[4]} ${keys[5] ? keys[5] : ''} <br> Average Sale Price <br> Forecast ($/${weightUnitName(unit.weightUnit)})`;
        }

        case String(key.match(/^fixed_cost_.*/)): {
            const keys = key.split("_");
            return `${keys[2]} ${keys[3]} ${keys[4] ? keys[4] : ''} <br> Fixed Cost <br> Forecast ($/${weightUnitName(unit.weightUnit)})`;
        }

        case String(key.match(/^variable_cost_.*/)): {
            const keys = key.split("_");
            return `${keys[2]} ${keys[3]} ${keys[4] ? keys[4] : ''} <br> Variable Cost <br> Forecast ($/${weightUnitName(unit.weightUnit)})`;
        }

        case String(key.match(/^revenue_.*/)): {
            const keys = key.split("_");
            return `${keys[1]} ${keys[2]} ${keys[3] || ''} <br/> ${_.get(tableLabel, keys[0], key)} Forecast`;
        }

        case String(key.match(/^actual_revenue_.*/)): {
            const keys = key.split("_");
            return `${keys[2]} ${keys[3]} ${keys[4] || ''} <br/> Actual ${_.get(tableLabel, keys[1], key)}`;
        }

        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = (key, value, callback, request, { totalAnalytics }) => {
    const unit = useSelector(state => state.weightUnitState);

    const placeholders = {
        slug: "Search",
        name: "Search",
        owner: "Search",
        sub_status: "Select",
        status: "Select",
        grade: "Search",
        sap_grade: "Select",
        material_number: "Search",
        product_code: "Search",
        product_application_code: "Search",
        plant: "Search",
        customer: "Search",
        customer_short_name: "Search",
        sap_customer: "Select",
        customer_code: "Search",
        customer_duns_number: "Search",
        customer_nickname: "Search",
        move_to_current: "Move to Current",
        manager: "Search",
        connection_manager: "Search",
        volume: "Search",
        revenue: "Search",
        los_date: "Search",
        grade_sub_status: "Select",
    };

    switch (key) {
        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-slug"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_duns_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/companies/duns-number-list"
                    name="customer_duns_number"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-list"
                    name="name"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/owner-list"
                    name="owner"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "grade":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/grade-list"
                    name="grade"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("grades", _value, request));
                    }}
                />
            );

        case "grade_sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={gradeStatus}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "material_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/material-number-list"
                    name="material_number"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("material_numbers", _value, request));
                    }}
                />
            );

        case "product_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="project/projects/product-code-list"
                    name="product_code"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("product_codes", _value, request));
                    }}
                />
            );

        case "product_application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-code-list"
                    name="product_application_code"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("product_application_codes", _value, request));
                    }}
                />
            );

        case "plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/plant-list"
                    name="plant"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("plants", _value, request));
                    }}
                />
            );

        case "line":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/line-list"
                    name="line"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("lines", _value, request));
                    }}
                />
            );

        case "internal_team":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name="internal_team"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key , _value, request));
                    }}
                />
            );

        case "brand":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/brand-list"
                    name="brand"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("brands", _value, request));
                    }}
                />
            );

        case "nameplate":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/nameplate-list"
                    name="nameplate"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("nameplates", _value, request));
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={
                        [...projectStatusBudgetForecast]
                    }
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "status":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...projectConsistent]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-code-list"
                    name="application_code"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sub_segment":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/sub-segment-list"
                    name="sub_segment"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-list"
                    name="application"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "manufacturer":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/manufacturer-forecast-list"
                    name="manufacturer"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "platform":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/platform-list"
                    name="platform"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "program":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/program-list"
                    name="program"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "city":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/city-list"
                    name="city"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "country":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/country-list"
                    name="country"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "region":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/region-list"
                    name="region"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "production_plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/production-plant-list"
                    name="production_plant"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "unit":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/unit-list"
                    name="unit"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "industry":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={industryOptions}
                    placeholder={_.get(placeholders, key)}
                    name="industry"
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "move_to_current":
        case "sop":
        case "eop":
        case "los_date":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer":
        case "tier1":
        case "tier2":
        case "molder":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_short_name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-short-list"
                    name="short_name"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_nickname":
        case "tier1_nickname":
        case "tier2_nickname":
        case "molder_nickname":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-nickname-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "manager":
        case "connection_manager":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_customer":
        case "sap_grade":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "volume":
        case "revenue":
        case "gross_margin": {
            return <InputFilter disabled type="text" placeholder="" value="" onChange={() => {}} />;
        }

        case String(key.match(/^volume_.*/)): {
            const keys = key.split("_");
            const data = totalAnalytics && keys[3] ? totalAnalytics[keys[2]][keys[3]].volume : totalAnalytics[keys[1]][keys[2]].volume || "0";

            return (
                <TableRowItemSpan className="main-text-bold main-text-right" title={`${numberWithCommas(data, 0)} ${weightUnitName(unit.weightUnit)}`}>
                    {`${numberWithCommas(data, 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^actual_volume_.*/)): {
            const keys = key.split("_");
            const data = totalAnalytics && keys[4] ? totalAnalytics[keys[3]][keys[4]].actual_volume : totalAnalytics[keys[2]][keys[3]].actual_volume || "0";

            return (
                <TableRowItemSpan
                    className="main-text-bold main-text-right"
                    title={`${numberWithCommas(data, 0)} ${weightUnitName(unit.weightUnit)}`}>
                    {`${numberWithCommas(data, 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^revenue_.*/)): {
            const keys = key.split("_");
            const data = totalAnalytics && keys[3] ? totalAnalytics[keys[2]][keys[3]].revenue : totalAnalytics[keys[1]][keys[2]].revenue || "0";

            return (
                <TableRowItemSpan className="main-text-bold main-text-right" title={`$${numberWithCommas(data, 0)}`}>
                    {`$${numberWithCommas(data, 0)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^actual_revenue_.*/)): {
            const keys = key.split("_");
            const data = totalAnalytics && keys[4] ? totalAnalytics[keys[3]][keys[4]].actual_revenue : totalAnalytics[keys[2]][keys[3]].actual_revenue || "0";

            return (
                <TableRowItemSpan className="main-text-bold main-text-right" title={`$${numberWithCommas(data, 0)}`}>
                    {`$${numberWithCommas(data, 0)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^actual_sale_price_.*/)):
        case String(key.match(/^actual_sale_average_price_.*/)):
        case String(key.match(/^fixed_cost_.*/)):
        case String(key.match(/^variable_cost_.*/)):
            return '';

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => !(key === 'budget_price' || key === 'budget_volume' || key === 'budget_revenue'
    || /^actual_sale_price*/.test(key)
    || /^actual_sale_average_price*/.test(key) || /^fixed_cost*/.test(key)
    || /^variable_cost*/.test(key) || /^volume_.*/.test(key) || /^actual_volume_.*/.test(key)
    || /^revenue_.*/.test(key) || /^actual_revenue_.*/.test(key) || /^grossMargin_.*/.test(key));
