import React, {Component} from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {ContainerTable} from "Hoc/Widgets";
import {TabWrap} from "Templates/Content";
import {Block, Tooltip} from "Templates/Form";
import TableHeadColumnWrap from "../../TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";
import ShowMoreSwitch from "./ShowMoreSwitch";
import Contact from "./Contact";
import Owner from "./Owner";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {};

class SharedPublication extends Component {
    render = () => {
        const { contacts, owner } = this.props;

        return (
            <>
                {owner && Object.keys(owner).length !== 0 && <div className={"contacts-toggle"}>
                    <ShowMoreSwitch label={'Contacts'} isHidden={false}/>
                </div>}

                <div className={'show-switch show-more'}>
                    <Block>
                        {owner && Object.keys(owner).length !== 0 && <Owner
                            key={`owner_${owner.id}`}
                            name={owner.name}
                            email={owner.email}
                            photo={owner.photo}
                            title={owner.title}
                        />}
                        <div className="shared-list-contacts">
                            <p className="title">
                                Contacts
                                <Tooltip>
                                    A list of additional contacts shared with you.
                                </Tooltip>
                            </p>
                            {contacts && contacts.length > 0 && contacts.map((contact) => (
                                <Contact
                                    key={`contact_${contact.id}`}
                                    name={contact.name}
                                    photo={contact.photo}
                                    email={contact.email}
                                    role={contact.role.name}
                                />
                            ))}
                        </div>
                    </Block>
                    <br></br>
                </div>
                <TabWrap className="myProduct__wrap shared-list-table">
                    <TabViewDetail />
                </TabWrap>
            </>
        );
    };
}

SharedPublication.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        contacts: getStoreItem(state, "contacts"),
        owner: getStoreItem(state, "owner"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SharedPublication);
