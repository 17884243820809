import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: "",
};

const Ul = ({ children, className }) => <ul className={className}>{children}</ul>;

Ul.propTypes = propTypes;
Ul.defaultProps = defaultProps;

export default Ul;
