export const fileDownloader = (fileToLoad, filename) => {
  if(!filename) {
    filename = 'unknown format';
  }

  const getUrl = window.URL.createObjectURL(
    new Blob([fileToLoad]),
  );

  const link = document.createElement('a');
  link.href = getUrl;
  link.setAttribute(
    'download',
    filename,
  );
  
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}