import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const phones = _.get(data, "phones", []).filter(item => _.trim(item.type) && _.trim(item.code) && _.trim(item.number));
    const emails = _.get(data, "emails", []).filter(item => _.trim(item.type) && _.trim(item.value));
    const socials = _.get(data, "socials", []).filter(item => _.trim(item.type) && _.trim(item.value));

    const dataReq = {
        firstName: _.get(data, "firstName"),
        lastName: _.get(data, "lastName"),
        title: _.get(data, "title"),
        company: _.get(data, "company"),
        photo: _.get(data, "photo"),
        phones,
        emails,
        socials,
        country: _.get(data, "country"),
        city: _.get(data, "city"),
        address1: _.get(data, "address1"),
        zip: _.get(data, "zip"),
        state: _.get(data, "state"),
        district: _.get(data, "district"),
        timeZone: _.get(data, "timeZone"),
    };

    return formDataNormalizer(dataReq);
};
