import React, {useState} from "react";
import {compose} from "redux";

import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import PropTypes from "prop-types";
import DashboardTypeThree from "./DashboardTypeThree";
import DashboardTypeFour from "./DashboardTypeFour";
import MyForecastView from "./Forecasts";

const propTypes = {
    level_1: PropTypes.shape({
        companyName: PropTypes.string.isRequired,
        help: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
        // role: PropTypes.string.isRequired,
        dashboardType: PropTypes.number.isRequired,
        type: PropTypes.number.isRequired,
        dashboard: PropTypes.shape({
            analytic: PropTypes.shape({
                gross_margin: PropTypes.string.isRequired,
                revenue: PropTypes.string.isRequired,
                volume: PropTypes.string.isRequired,
            })
        }),
    }).isRequired,
    level_2: PropTypes.shape({
        companyName: PropTypes.string.isRequired,
        help: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
        // role: PropTypes.string.isRequired,
        dashboardType: PropTypes.number.isRequired,
        type: PropTypes.number.isRequired,
        dashboard: PropTypes.shape({
            analytic: PropTypes.shape({
                gross_margin: PropTypes.string.isRequired,
                revenue: PropTypes.string.isRequired,
                volume: PropTypes.string.isRequired,
            })
        }),
    }).isRequired,
    interactive: PropTypes.shape({
        FY: PropTypes.string.isRequired,
        help: PropTypes.string.isRequired,
    }).isRequired,
    customize_report: PropTypes.shape({
        FY: PropTypes.string.isRequired,
        analytics: PropTypes.any,
        help: PropTypes.string.isRequired,
    }).isRequired,
    financialYear: PropTypes.string.isRequired,
};

const DashboardForecastExplorer = ({
                                       level_1,
                                       level_2,
                                       interactive,
                                       financialYear,
                                       t
                                   }) => {

    const [levelOneExpanded, setLevelOneExpanded] = useState(false)
    const [levelTwoExpanded, setLevelTwoExpanded] = useState(false)
    const [forecastExpanded, setForecastExpanded] = useState(false)

    return (
        <div className="forecast-explorer">
            <div className={`forecast-explorer__expand ${levelOneExpanded ? 'expanded' : ''}`}>
                <DashboardTypeThree
                    companyName={level_1.companyName}
                    position={level_1.position}
                    financialYear={financialYear}
                    revenue={level_1.dashboard.analytic.revenue}
                    volume={level_1.dashboard.analytic.volume}
                    grossMargin={level_1.dashboard.analytic.gross_margin}
                    dashboardType={level_1.dashboardType}
                    help={level_1.help}
                    expandRow={() => {
                        setLevelOneExpanded(!levelOneExpanded)
                    }}
                    t={t}
                />
            </div>
            <div className={`forecast-explorer__expand ${levelTwoExpanded ? 'expanded' : ''}`}>
                <DashboardTypeFour
                    companyName={level_2.companyName}
                    position={level_2.position}
                    financialYear={financialYear}
                    revenue={level_2.dashboard.analytic.revenue}
                    volume={level_2.dashboard.analytic.volume}
                    grossMargin={level_2.dashboard.analytic.gross_margin}
                    dashboardType={level_2.dashboardType}
                    help={level_2.help}
                    expandRow={() => {
                        setLevelTwoExpanded(!levelTwoExpanded)
                    }}
                    t={t}
                />
            </div>
            <div className={`forecast-explorer__expand ${forecastExpanded ? 'expanded' : ''}`}>
                <MyForecastView
                    help={interactive.help}
                    expandRow={() => {
                        setForecastExpanded(!forecastExpanded)
                    }}
                    t={t}
                />
            </div>
        </div>
    );
};

DashboardForecastExplorer.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        level_1: getStoreItem(state, "level_1"),
        level_2: getStoreItem(state, "level_2"),
        interactive: getStoreItem(state, "interactive"),
        customize_report: getStoreItem(state, "customize_report"),
        financialYear: getStoreItem(state, "financialYear"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DashboardForecastExplorer);