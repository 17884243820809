import React, {Component} from "react";
import {compose} from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import ReactResizeDetector from "react-resize-detector";
import {BlockWrap} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {maxSizeCanvas, numberWithCommas} from "Services";
import {weightUnitName} from 'Services/weightUnitName';

import Chart from "chart.js";
import {generateDatasets, getLabels} from "./utils";

const propTypes = {
    weightUnit: PropTypes.objectOf(PropTypes.number).isRequired,
    data: PropTypes.any,
    selectedValue: PropTypes.string.isRequired,
};

class LineChart extends Component {
    constructor(props) {
        super(props);
        this.myChartLevel_1 = {};
        this.resizeTimerId = 0;
        this.state = {
            width: 0,
            height: 0,
        };
    }

    componentDidMount = () => {
        const chartBlock = document.querySelector('.chart.chart-line-1');
        this.setState(
            {
                width: chartBlock.clientWidth,
                height: chartBlock.clientHeight,
            },
            this.setChartConfig,
        );
    };

    componentDidUpdate(prevProps) {
        const { data, selectedValue, weightUnit } = this.props;

        if (JSON.stringify(data) !== JSON.stringify(prevProps.data) || selectedValue !== prevProps.selectedValue){
            const width = this.getChartWidth();

            this.setState(
                {
                    width,
                },
                () => {
                    this.myChartLevel_1.width = width;
                    this.myChartLevel_1.options.scales.xAxes[0].labels = getLabels(data);
                    this.myChartLevel_1.options.scales.yAxes[0].ticks.callback = value => `${numberWithCommas(value, 0)} ${weightUnitName(weightUnit.weightUnit)}`;
                    this.myChartLevel_1.options.tooltips.callbacks.label = (tooltipItem, data) => {
                        const name = data.datasets[tooltipItem.datasetIndex].label || "";
                        return `${name}: ${numberWithCommas(tooltipItem.value, 0)} ${weightUnitName(weightUnit.weightUnit)}`;
                    };
                    this.myChartLevel_1.data.datasets = generateDatasets(data, selectedValue);
                    this.myChartLevel_1.update();
                },
            );
        }
    }

    setChartConfig = () => {
        const { data, selectedValue, weightUnit } = this.props;

        const canvas = document.getElementById("myChartLevel_1");
        const ctx = canvas.getContext("2d");
        this.myChartLevel_1 = new Chart(ctx, {
            type: "line",
            data: {
                datasets: generateDatasets(data, selectedValue),
            },
            options: {
                devicePixelRatio: 1,
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 54,
                        right: 24,
                        bottom: 20,
                        left: 24,
                    },
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    callbacks: { label: (tooltipItem, data) => {
                            const name = data.datasets[tooltipItem.datasetIndex].label || "";
                            return `${name}: ${numberWithCommas(tooltipItem.value, 0)} ${weightUnitName(weightUnit.weightUnit)}`;
                        } },
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                drawTicks: false,
                            },
                            ticks: {
                                padding: 20,
                                callback: value => `${numberWithCommas(value, 0)} ${weightUnitName(weightUnit.weightUnit)}`,
                                // stepSize: 16.3,
                                min: 0,
                                // max: 100,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                drawTicks: false,
                                drawOnChartArea: false,
                            },
                            lineHeight: 0,
                            labels: getLabels(data),
                            ticks: {
                                padding: 20,
                            },
                        },
                    ],
                },
            },
        });
    };

    getChartWidth = () => {
        const { data } = this.props;
        const years = Object.keys(data);
        const items = _.get(data, `[${_.get(years, "[0]", "")}]`, []);
        let width = document.querySelector('.chart.chart-line-1').clientWidth;
        if (years.length * items.length >= 25) {
            const maxItems = 5;
            width += 16 * Math.abs(maxItems - items.length) * years.length * items.length;
        }

        return maxSizeCanvas(width);
    };

    onResize = () => {
        new Promise(resolve => {
            window.requestAnimationFrame(() => {
                if (this.resizeTimerId) {
                    clearTimeout(this.resizeTimerId);
                }
                this.resizeTimerId = setTimeout(() => resolve(), 200);
            });
        }).then(() => {
            const width = this.getChartWidth();

            this.setState(
                {
                    width,
                },
                () => {
                    this.myChartLevel_1.width = width;
                    this.myChartLevel_1.update();
                },
            );
        });
    };

    render() {
        const { width, height } = this.state;
        return (
            <BlockWrap className="chart chart-line-1">
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
                    <div className="chartAreaWrapper typeThree" style={{ width, height }}>
                        <canvas id="myChartLevel_1" width={width} height={height} />
                    </div>
                </ReactResizeDetector>
            </BlockWrap>
        );
    }
}

LineChart.propTypes = propTypes;

const mapStateToProps = (state, { service: { getChartDataLevel_1, getStoreItem } }) => {
    return {
        weightUnit: state.weightUnitState,
        data: getChartDataLevel_1(state),
        selectedValue: getStoreItem(state, "selectedValue"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(LineChart);
