import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    name: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    segment: PropTypes.string.isRequired,
    validate: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
};

const ApplicationCode = ({ name, industry, segment, pref, validate, t, submitForm }) => {
    const handleClickOnSave = () => {
        submitForm({
            name,
            industry,
            segment,
        }).then(
            data => {
                url.redirect(`/admin/list/application-codes/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (window.confirm(t("Cancel?"))) {
            url.redirect("/admin/list?activeTab=2");
        }
    };

    return (
        <>
            <Title
                title={t("Add New Application Code")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validate} onClick={handleClickOnSave}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

ApplicationCode.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        name: getStoreItem(state, "name"),
        industry: getStoreItem(state, "industry"),
        segment: getStoreItem(state, "segment"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ApplicationCode);
