import React from "react";
import { industryOptions, marginEnum } from "Services/enum";
import { Span } from "Templates/Default";
import main from "../index";
import { formData } from "./FormService";
import { getStoreItem, getChartData, getActionStore } from "./StoreService";
import { getData, getItemOptions } from "../RequestService";

const optionsForFirstSelect = marginEnum;

const optionsForSecondSelect = industryOptions.map(elem => ({ ...elem, urlToOptions: `industry/${elem.value}` }));

const getFirstColumnInTheTable = item => {
    return <Span>{item.label}</Span>;
};

export default () => ({
    ...main,
    getData: getData("/forecasts/applications"),
    getItems: getItemOptions("/forecasts/applications/application-list"),
    optionsForFirstSelect,
    optionsForSecondSelect,
    getFirstColumnInTheTable,
    getStoreItem,
    getChartData,
    getActionStore,
    formData,
});
