import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { LinkIcon } from "Templates/Link";

const propTypes = {
    title: PropTypes.string.isRequired,
};

const HeadDashboardCard = ({ pref, title }) => {
    return (
        <div className={`${pref}__card-head`}>
            <span className="bold-text">{title}</span>
            <LinkIcon url="tasks/create" />
        </div>
    );
};

HeadDashboardCard.propTypes = propTypes;

export default compose(withTagDefaultProps())(HeadDashboardCard);
