import React from 'react';
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {onChange} from "../../Services/EventsService";

function Approval({callback, filter}) {
    return (
        <>
            <div className="product__filters">
                <>
                    <p className="main-table__head-link long">Color</p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/colors/color-list"
                        value={filter.color}
                        onChange={e => {
                            onChange(e).then(_value => callback('color', _value));
                        }}
                    />

                    <p className="main-table__head-link long filter-with-tooltip">
                        Color Code
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/colors/color-code-list"
                        value={filter.color_code}
                        onChange={e => {
                            onChange(e).then(_value => callback('color_code', _value));
                        }}
                    />
                    <p className="main-table__head-link filter-with-tooltip">
                        OEM Approval
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/colors/color-approvals-list"
                        value={filter.approval_id}
                        onChange={e => {
                            onChange(e).then(_value => callback('approval_id', _value));
                        }}
                    />
                </>
            </div>
        </>
    );
}

Approval.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired
}

export default Approval;
