import * as main from "../../index";
import {getFilter, getTableLabel} from "./TableHeaderService";
import {getViewItem, modifierValues} from "./TableBodyService";

export default {
    ...main,
    getFilter, getTableLabel,
    getViewItem, modifierValues,
    requestTable: main.requestTable("/finance/finance-customers/yearly"),
};
