import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import Img from "Templates/Img";
import { taskStatus } from "Services/enum";
import { Span } from "Templates/Default";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const defaultProps = {
    onClickCustom: () => {},
};

const complete = taskStatus.find(elem => elem.value === 4);

const TableRowItemEdit = ({ item, onClickCustom, onClickEdit, onClickDelete, t }) => (
    <TableBodyButtonsBlock>
        <TableBodyButton className="oval" onClick={onClickCustom}>
            <Span className={item.sub_status === complete.value ? "clr-red" : "clr-green"}>
                {item.sub_status === complete.value ? t("Undo") : t("Done")}
            </Span>
        </TableBodyButton>
        <TableBodyButton onClick={onClickEdit}>
            <Img img="icon_edit" />
        </TableBodyButton>
        <TableBodyButton onClick={onClickDelete}>
            <Img img="icon_delete" />
        </TableBodyButton>
    </TableBodyButtonsBlock>
);

TableRowItemEdit.propTypes = propTypes;
TableRowItemEdit.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowItemEdit);
