import React, {Component} from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ServiceProvider} from "Services/Context";
import Img from "Templates/Img/Img";
import * as notificationService from "./Items/Notification/Services";
import * as unitsService from "./Items/Units/Services";
import Notification from "./Items/Notification/View/Notification";
import QuickAccessToForms from "./Items/QuickAccessToForms";
import UserProfile from "./Items/UserProfile";
import Search from "./Items/Search";
import ContactForm from "./Items/ContactForm";
import ConverterUnits from '../../../Pages/Admin/User/Change/Views/ConverterUnits';
import ReactGA from 'react-ga';
import {Button} from "Templates/Default";
import PropTypes from "prop-types";
import McgInfo from "App/View/Header/Items/McgInfo";
import parse from 'html-react-parser';

const propTypes = {
    viewedAlerts: PropTypes.array,
    permissions: PropTypes.array,
    unit: PropTypes.object,
};
const defaultProps = {
    viewedAlerts: [],
    permissions: [],
    unit: {},
};

ReactGA.initialize('G-F179VZEFN6');

class Header extends Component {
    state = {
        permissions: this.props.permissions,
        alerts: this.props.alerts,
        viewedAlerts: JSON.parse(localStorage.getItem('viewedAlerts') || '[]'),
    };

    isOnlyProductPublicationsGuest = this.state.permissions.length === 1 && this.state.permissions.includes("product_publications_guest");

    isVisibleQuickAccessToForms = this.state.permissions.includes("grade_product_crud")
        || this.state.permissions.includes("project_crud")
        || this.state.permissions.includes("project_companies_team_view")
        || this.state.permissions.includes("companies_view")
        || this.state.permissions.includes("contacts_view")
        || this.state.permissions.includes("plant_raw_crud");

    isVisibleNotification = this.isVisibleQuickAccessToForms
        || this.state.permissions.includes("product_share_button")
        || this.state.permissions.includes("file_general")
        || this.state.permissions.includes("grade_product_finder_view");

    handleClose = (id) => {
        const { viewedAlerts } = this.state;
        viewedAlerts.push(id);

        localStorage.setItem('viewedAlerts', JSON.stringify(viewedAlerts));

        this.setState({
            viewedAlerts,
        });

        this.props.handleCloseAlerts()
    };

    render() {
        const { innerRef, unit, isInternalUser } = this.props;
        const { alerts, viewedAlerts } = this.state;
        const displayAlerts = alerts.filter(({ id }) => !viewedAlerts.includes(id));
        const visibleConverterUnits = [
            '/product/plants',
            '/connection/connections',
            '/product/products',
            '/product/raw',
            '/dashboard/business',
            '/analytic/forecast-explorer',
            '/dashboard/business/level-1',
            '/dashboard/business/level-2',
            '/forecasts/graphs-v2',
            '/search',
            '/',
        ].includes(window.location.pathname) || unit.isActive;

        return (
            <header ref={innerRef} className="main-header">
                {isInternalUser && displayAlerts && displayAlerts.length ?
                    <div className="main-header__alerts">
                        {displayAlerts.map(item => (
                            <div key={item.id} className="main-header__alerts-item">
                                <div className={"alert-image"}>
                                    <Img img="alert_bell" width="12" height="16" alt="" className="main-header__alerts-item-icon" />
                                </div>
                                <p className="main-header__alerts-item-text">{parse(item.message)}</p>
                                <Button className="main-modal__form-close alert-close-btn" onClick={() => this.handleClose(item.id)}/>
                            </div>
                        ))}
                    </div>:
                    null}
                <div className="main-header__wrap-top">
                    <div className="main-header__container">
                        <div className="main-header__top">
                            <div className={'main-header__logo-wrap'}>
                                <a href="/" className="main-header__logo">
                                    <Img
                                        img="Logo-Horizontal"
                                        width="150"
                                        height="23"
                                        alt="logo"
                                        className="main-header__logo-img"
                                    />
                                </a>
                                <McgInfo />
                            </div>
                            <div className="main-header__other">
                                {!this.isOnlyProductPublicationsGuest &&
                                    <>
                                        {this.isVisibleQuickAccessToForms && <Search/>}
                                        {this.isVisibleNotification &&
                                            <ServiceProvider value={notificationService}>
                                                <Notification/>
                                            </ServiceProvider>}

                                        {
                                            visibleConverterUnits && (
                                                <ServiceProvider value={unitsService}>
                                                    <ConverterUnits/>
                                                </ServiceProvider>
                                            )
                                        }
                                        {this.isVisibleQuickAccessToForms &&
                                            <QuickAccessToForms permissions={this.state.permissions}/>}
                                    </>
                                }
                                <ContactForm/>
                                <UserProfile/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

const mapStateToProps = (state) => {
    return {
        permissions: state.userState.permissions || [],
        alerts: state.appState.alerts || [],
        unit: state.weightUnitState || {},
        isInternalUser: state.userState && state.userState.isInternalUser ? state.userState.isInternalUser : false
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

// export default compose(withTagDefaultProps(mapStateToProps, mapDispatchToProps))(Header);
const ConnectedHeaderComponent = compose(withTagDefaultProps(mapStateToProps, mapDispatchToProps))(Header);
export default React.forwardRef((props, ref) => (
    <ConnectedHeaderComponent {...props} innerRef={ref} />
))
