import React, {Fragment} from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps, CheckPermission } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const QuickEditTableBody = ({
    items,
    columns,
    customIcon,
    rowId,
    invalidQuickEdit,
    onClickEdit,
    onClickDelete,
    onClickCustom,
    onClickUnlock,
    onClickSave,
    applyChange,
    setInvalid,
    editTemplate,
    onClickItem,
    TableRowColumn,
    service: { tableButtonsPermission },
}) => {
    const QuickEditTableRow = (column, item, index, k) => {
        return (
            <td className={`main-table__body ${column === "-1" ? "main-table__body--sticky" : ""}`}>
                <TableRowColumn
                    item={item}
                    index={index}
                    secondaryIndex={k}
                    column={column}
                    customIcon={customIcon}
                    rowId={rowId}
                    invalidQuickEdit={invalidQuickEdit}
                    onClickCustom={onClickCustom}
                    onClickItem={onClickItem}
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                    onClickUnlock={onClickUnlock}
                    onClickSave={onClickSave}
                    applyChange={applyChange}
                    setInvalid={setInvalid}
                    editTemplate={editTemplate}
                />
            </td>
        );
    };

    return (
        <>
            {Array.isArray(items) &&
                items.map((item, k) => {
                    return (
                        <tr key={`TableRowColumn-${k}`}>
                            {columns.map((column, index) => (
                                <Fragment key={`TableRowColumn-${k}-${index}`}>
                                    {column === "-1" ? (
                                        <CheckPermission permission={tableButtonsPermission}>
                                            {QuickEditTableRow(column, item, index, k)}
                                        </CheckPermission>
                                    ) : (
                                        <>
                                            {QuickEditTableRow(column, item, index, k)}
                                        </>
                                    )}
                                </Fragment>
                            ))}
                        </tr>
                    )
                })}
        </>
    );
};

QuickEditTableBody.defaultProps = {
    customIcon: "",
    rowId: null,
    invalidQuickEdit: false,
    onClickCustom: () => {},
    onClickEdit: () => {},
    onClickDelete: () => {},
    onClickItem: () => {},
    onClickUnlock: () => {},
    onClickSave: () => {},
    applyChange: () => {},
    setInvalid: () => {},
    editTemplate: () => {},
    // TableRowColumn: () => null,
};

QuickEditTableBody.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowId: PropTypes.string,
    invalidQuickEdit: PropTypes.bool,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickItem: PropTypes.func,
    onClickUnlock: PropTypes.func,
    onClickSave: PropTypes.func,
    editTemplate: PropTypes.func,
    applyChange: PropTypes.func,
    setInvalid: PropTypes.func,
    service: PropTypes.shape({
        tableButtonsPermission: PropTypes.string,
    }).isRequired,
    // TableRowColumn: PropTypes.element,
};
const mapStateToProps = (state, { service: { getStoreItem, modifierValues } }) => {
    return {
        items: modifierValues(getStoreItem(state, "items")),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(QuickEditTableBody);
