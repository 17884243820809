import React from "react";
import _ from "lodash";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { TabWrap } from "Templates/Content";
import HierarchyContainerTable from "./HierarchyContainerTable";
import HierarchyTableHeadColumnWrap from "./HierarchyTableHeadColumnWrap";
import {HierarchyTableRowColumnWrap} from "./HierarchyTableRowColumnWrap";

const DefaultTabTable = HierarchyContainerTable(HierarchyTableHeadColumnWrap, HierarchyTableRowColumnWrap);

const propTypes = {
    customIcon: PropTypes.string,
    addHierarchy: PropTypes.func,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

/*
* This is recursive function get existing items and after backend getChildren request add response children to items
*/
function recursiveAddChildren(arr, newItems, level = 0) {
    if (!newItems || !newItems.length) {
        return arr;
    }
    const {parent_company_id} = newItems[0];
    const lastIndex = newItems.length - 1;
    return arr.map((item, i) => {
        const _lastParentItem = i === arr.length - 1;
        if (item.id === parent_company_id) {
            return {
                ...item,
                _lastParentItem,
                _parents: {
                    ...item._parents,
                    [level]: item,
                },
                children: newItems.map((ch, index) => ({
                    ...ch,
                    _lastParent: level === 0 && index === lastIndex ? true : (item._lastParent || false),
                    _last: index === lastIndex,
                    _level: level + 1,
                    _lastParentItem,
                    _parentIsLastParentItem: item._lastParentItem || false,
                    _parent: {
                        ...item,
                        _lastParentItem,
                    },
                    _parents: {
                        ...item._parents,
                        [level]: item,
                    },
                }) ),_active: true};
        }
        if (item.children && item.children.length) {
            return {
                ...item,
                _lastParentItem,
                _parents: {
                    ...item._parents,
                    [level]: item,
                },
                _parentIsLastParentItem: item._lastParentItem || false,
                children: recursiveAddChildren(item.children, newItems, level + 1)
            };
        }
        return item;
    });
};

const HierarchyTabViewDetail = TabTable => ({
    onClickCustom,
    onClickEdit,
    onClickDelete,
    addHierarchy,
    pref,
    customIcon,
    items,
    pagination,
    loading,
    fetchItems,
    fetchPage,
    request,
    service,
}) => {
    const { page, pages } = pagination;

    const { handleScroll } = service;

    if (items === undefined) {
        if (!loading) {
            fetchItems(request);
        }
        return "";
    }

    const onScroll = e => {
        if (page >= pages) {
            return;
        }

        handleScroll(e).then(() => {
            request.page = page + 1;

            fetchPage(request);
        });
    };

    const _onClickEdit = e => {
        const item = _.get(items, e);
        if (item) {
            onClickEdit(item);
        }
    };

    const _onClickDelete = e => {
        const item = _.get(items, e);
        if (item) {
            onClickDelete(item);
        }
    };

    const _onClickCustom = e => {
        const item = _.get(items, e);
        if (item) {
            onClickCustom(item);
        }
    };

    const _addHierarchy = data => {
        const list = recursiveAddChildren(items, data.children);
        addHierarchy(list);
    };

    return (
        <TabWrap>
            <div
                className={`${pref}__wrap-body main-wrap-block__body main-wrap-block__body_height`}
                onScroll={onScroll}
            >
                <TabTable
                    onClickCustom={_onClickCustom}
                    addHierarchy={_addHierarchy}
                    customIcon={customIcon}
                    onClickEdit={_onClickEdit}
                    onClickDelete={_onClickDelete}
                />
            </div>
        </TabWrap>
    );
};

HierarchyTabViewDetail.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        items: getStoreItem(state, "items"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        loading: getStoreItem(state, "loading"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    HierarchyTabViewDetail,
)(DefaultTabTable);

export const hierarchyCustomTable = CustomTable =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
        HierarchyTabViewDetail,
    )(CustomTable);
