import React from "react";
import _ from "lodash";
import { taskStatus } from "Services/enum";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        title: "Title",
        project: "Project Name",
        start: "Start Date",
        due: "Due Date",
        assigned_to: "Assigned To",
        assigned_by: "Assigned By",
        sub_status: "Status",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholder = {
        title: "Title",
        project: "Project Name",
        start: "Start Date",
        due: "Due Date",
        assigned_to: "Assigned To",
        assigned_by: "Assigned By",
        status: "Status",
    };
    switch (key) {
        case "sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    options={taskStatus}
                    placeholder="Select"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "due":
        case "start":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
