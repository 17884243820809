import _ from "lodash";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Name",
        owner: "Owner",
        created_at: "Date Submitted",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = () => "";

export const isSortEnabled = key => (["name", "owner", "created_at"].includes(key));
