import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Form, BlockWrap, Block, Input, BlockMain, WrapInput } from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import HierarchyBlock from "./HierarchyBlock";

const propTypes = {
    name: PropTypes.string.isRequired,
    applicationCode: PropTypes.string.isRequired,
    isCreate: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

const FormBlock = ({ name, applicationCode, isCreate, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <Form>
            <BlockMain title="General Info">
                <BlockWrap className="width_2-3">
                    <Block>
                        <BlockWrap>
                            <WrapInput isRequired label="Name" name="name">
                                <Input
                                    placeholder="Name"
                                    value={name}
                                    onChange={value => onChangeInput(value, "name")}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput isRequired label="Application Code" name="applicationCode">
                                <Input
                                    disabled={isCreate}
                                    placeholder={t("Search")}
                                    url="project/projects/application-code-list"
                                    type="asyncSelect"
                                    value={applicationCode}
                                    onChange={value => onChangeInput(value, "applicationCode")}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>
                </BlockWrap>
            </BlockMain>
            <HierarchyBlock />
        </Form>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        applicationCode: getStoreItem(state, "applicationCode"),
        isCreate: getStoreItem(state, "isCreate"),
        validate: getStoreItem(state, "form_validate"),
        formData: state.createState,
    };
};

FormBlock.propTypes = propTypes;

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
