import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from "Hoc/Template";
import {Input as InputTemplate} from "../Input";

const propTypes = {
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    onMenuAction: PropTypes.func,
    inputProps: PropTypes.objectOf(PropTypes.any),
    url: PropTypes.string,
    params: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.object),
    isTextArea: PropTypes.bool,
    viewFormat: PropTypes.string,
    handleClickOnDeleteButton: PropTypes.func,
    showEmptyOption: PropTypes.bool,
    valuesList: PropTypes.array,
    handleSelectedOptionsLength: PropTypes.func
};

const defaultProps = {
    multiple: false,
    disabled: false,
    showEmptyOption: true,
    placeholder: "",
    className: "",
    type: "text",
    url: "text",
    params: null,
    inputProps: {},
    options: [],
    viewFormat: "MMM DD, YYYY",
    isTextArea: false,
    valuesList: [],
    handleClickOnDeleteButton: () => {},
    handleSelectedOptionsLength: () => {},
    onMenuAction: () => {},
};

const Input = ({
    pref,
    type,
    multiple,
    disabled,
    name,
    isTextArea,
    placeholder,
    className,
    value,
    onChange,
    onMenuAction,
    options,
    inputProps,
    url,
    params,
    showEmptyOption,
    t,
    viewFormat,
    valuesList,
    handleClickOnDeleteButton,
    handleSelectedOptionsLength
}) => (
    <InputTemplate
        multiple={multiple}
        disabled={disabled}
        className={`${pref}__input main-content__input ${isTextArea ? `${pref}__textarea` : ""} ${className}`}
        type={type}
        url={url}
        params={params}
        showEmptyOption={showEmptyOption}
        placeholder={t(placeholder)}
        value={value}
        valuesList={valuesList}
        options={options}
        inputProps={{ name, id: name, ...inputProps }}
        onChange={onChange}
        onMenuAction={onMenuAction}
        viewFormat={viewFormat}
        handleClickOnDeleteButton={handleClickOnDeleteButton}
        handleSelectedOptionsLength={handleSelectedOptionsLength}
    />
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(Input);
