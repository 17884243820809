import {formDataNormalizer, request, url} from "Services";

const save = (data, requestUrl) => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const saveProfile = data => {
    return new Promise((resolve, reject) => {
        save(formDataNormalizer({...data}), '/profile/edit').then(res => {
            alert('Profile successfully updated!')
            return resolve(res)
        }, error => {
            alert(`${error.title}\n${error.detail}`)
            return reject(error)
        });
    });
}