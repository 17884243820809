import { combineReducers } from "redux";
import { getMainInitialStates, getMainReducers } from "App/Services";
import { reducer as formReducer, initialState as editState } from "../../../Reducers/BudgetFormReducer";

// Combine all reducers you may have here
export default combineReducers({
    ...getMainReducers,
    editState: formReducer,
});

export const initialStates = {
    ...getMainInitialStates,
    editState,
};
