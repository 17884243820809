import _ from "lodash";
import {getNumber} from "Services";
import {
    getProductGrossMargin,
    getFinalPrice,
    getProductOperatingMargin,
    getTotalForecasts,
    getTotalPercentProducts,
    unitsAmount,
} from "./MathService";

export const validate = ({
    generalBlock: { project, customer, status },
    projectVolumeBlock: { partWeight, partsperUnit },
    forecastBlock: { forecast },
    productBlock: { products, totalPercent },
    applicationBlock: { application },
    show_full
}) => {
    const cbForCheckString = el => typeof el === "string" && el.trim();
    const cbForCheckNumber = el => el !== "" && Number.isFinite(Number(el));

    if (!show_full) {
        return [project].every(cbForCheckString);
    }

    const checkString = [project, customer].every(cbForCheckString);
    const checkNumber = [status, partWeight, partsperUnit].every(cbForCheckNumber) && Boolean(status);

    const checkProduct = products.every(
        ({ name, grade, actualSale, percentOfProduct }) =>
            [name, grade].every(cbForCheckString) && [actualSale, percentOfProduct].every(cbForCheckNumber),
    );

    const checkActualSale = products.every(({actualSale}) => Number(actualSale) > 0)
    const checkPlant = products.every((item) => {
        return item.isSap ? (item.plant && item.plant !== '-') : true
    })

    const checkApplication = application.every(
        ({ code, sub_segment }) =>
            [code, sub_segment].every(cbForCheckString)
    );

    const checkForecast =
        forecast.length > 0 &&
        forecast.every(({ isValid, units }) => {
            // if (typeof isValid === "boolean" && !isValid) {
            //     return false;
            // }
            return [units].every(cbForCheckString);
        });

    return (
        checkActualSale &&
        checkPlant &&
        checkString &&
        checkNumber &&
        checkProduct &&
        checkApplication &&
        products.every(elem => _.get(elem, "unique", true)) &&
        checkForecast &&
        totalPercent === 100
    );
};

export const setTotalVolumeInProjectVolume = state => {
    const formula = (a, b, c) => getNumber(a * b * c);
    const result = formula(
        _.get(state, "projectVolumeBlock.totalUnits"),
        _.get(state, "projectVolumeBlock.partWeight"),
        _.get(state, "projectVolumeBlock.partsperUnit"),
    );
    _.set(state, `projectVolumeBlock.totalVolume`, result);
};

export const setForecastBlock = (state, data) => {
    const {
        forecastBlock: { forecast },
    } = state;
    _.set(forecast, `[${data.index}].${data.key}`, data.value);

    if (data.key === "units" || data.key === "dateMovedToCurrent" || data.key === "endDate") {
        const totalUnits = _.get(forecast, `[${data.index}].total`) || 0;
        const units = _.get(forecast, `[${data.index}].units`) || 0;
        _.set(forecast, `[${data.index}].unitsAmount`, unitsAmount(units, totalUnits));

        const total = getTotalForecasts(forecast);
        _.set(state, "forecastBlock.total", total);
        _.set(state, "projectVolumeBlock.totalUnits", total);
        setTotalVolumeInProjectVolume(state);
    }

    return {
        ...state,
        form_validate: validate(state),
        forecastBlock: _.cloneDeep(state.forecastBlock),
        projectVolumeBlock: _.cloneDeep(state.projectVolumeBlock),
    };
};

export const changeProduct = (state, data) => {
    const {
        productBlock: { products },
    } = state;
    _.set(products, `[${data.index}].${data.key}`, data.value);

    if (data.key.endsWith("name")) {
        _.set(products, `[${data.index}].grade`, "");
        _.set(products, `[${data.index}].sale`, 0);
        _.set(products, `[${data.index}].operatingMargin`, 0);
        _.set(products, `[${data.index}].goodsSold`, 0);
        _.set(products, `[${data.index}].grossMargin`, 0);
    }

    if (data.key.endsWith("percentOfProduct")) {
        const totalPercent = getTotalPercentProducts(products);
        _.set(state, "productBlock.totalPercent", totalPercent);
    }

    if (data.key.endsWith("rebate")) {
        const finalPrice = getFinalPrice(_.get(products, `[${data.index}]`));
        _.set(products, `[${data.index}].finalPrice`, finalPrice);
    }

    if (data.key.endsWith("actualSale")) {
        const grossMargin = getProductGrossMargin(_.get(products, `[${data.index}]`));
        _.set(products, `[${data.index}].grossMargin`, grossMargin);
        const operatingMargin = getProductOperatingMargin(_.get(products, `[${data.index}]`));
        _.set(products, `[${data.index}].operatingMargin`, operatingMargin);
        const finalPrice = getFinalPrice(_.get(products, `[${data.index}]`));
        _.set(products, `[${data.index}].finalPrice`, finalPrice);
    }

    return {
        ...state,
        form_validate: validate(state),
        productBlock: _.cloneDeep(state.productBlock),
    };
};
