import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { requestUnit } from "./RequestService";
import * as ConverterUnitsAction from "../Actions/ConverterUnitsAction";

export const getStoreItem = _getStoreItem("userState");

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...ConverterUnitsAction,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export { requestUnit };
