import {request, url} from "Services";

const listUrl = "files";

export const getUsersSharedWith = async (id) => {
    const sendObj = {
        url: url.getUrl(`files/sharing/${id}/already-shared-with-users`),
        type: "GET",
    };

    return request.sendRequestWithNoCache(sendObj, false);
}

export const requestShareFiles = (data) => {
    const { id, selectedUsers, sharedWithUsersList } = data;
    return request.sendRequest({
        url: url.getUrl(`files/sharing/${id}/edit`),
        data: { new: selectedUsers.length ? selectedUsers.map((item) => item.value) : [], old: sharedWithUsersList.length ? sharedWithUsersList.map((item) => item.value) : []},
        type: "POST",
    });
}

export const requestTable = data =>
    new Promise(resolve => {
        request
            .sendRequest({
                url: url.getUrl(listUrl),
                data,
                type: "GET",
            })
            .then(res =>
                resolve({
                    items: res[0].items,
                    pagination: res[0].pagination,
                    columns: res[0].columns,
                }),
            ).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
            url.addHistory(data);
        });
    });

export const requestOnCreateFolder = data =>
    request.sendRequest({
        url: url.getUrl(`${listUrl}/create-folder`),
        data,
        type: "POST",
    });

export const requestOnEditFolder = (id, data) =>
    request.sendRequest({
        url: url.getUrl(`${listUrl}/${id}/edit-folder`),
        data,
        type: "POST",
    });

export const requestOnDeleteItem = data => {
    const sendObj = {
        url: url.getUrl(`${listUrl}/${data}/delete`),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export const requestOnUploadFiles = data => {
    const sendObj = {
        url: url.getUrl(`${listUrl}/add`),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

const getSharedItems = (data) => {
    const sendObj = {
        url: url.getUrl(`files/shared-with-me`),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestSharedTable = data => {
    return new Promise(resolve => {
        getSharedItems(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
            url.addHistory(data);
        });
    });
};
