import React from "react";
import {compose} from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import {BlockMain, BlockWrap, Block, BlockWrapFlex, Input, WrapInput} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Button} from "Templates/Default";
import LinkIcon from "Templates/Link/LinkIcon";
import BlockTitle from "Templates/Form/BlockTitle";
import { url, isEqual } from "Services";
import Img from "Templates/Img/Img";
import {deleteProperty} from "../Actions/FormActions";

const attachProperty = () => {
    return {
        property: "",
        methods: []
    };
};

const attachMethod = () => {
    return {
        method: "",
        specimens: []
    };
};

const attachSpecimen = () => {
    return {
        specimen: "",
        conditions: []
    };
};

const attachCondition = () => {
    return {
        condition: "",
        si_units: []
    };
};

const attachSiUnit = () => {
    return {
        si_unit: "",
        english_units: []
    };
};

const attachEnglishUnit = () => {
    return {
        english_unit: ""
    };
};

const addItemProperty = (appendFunc, serviceFunc) => () => {
    serviceFunc(_.concat([], appendFunc()));
};

const addItemMethod = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    items[key].methods = _.concat(items[key].methods, appendFunc());

    serviceFunc(_.concat(items));
};

const addItemSpecimen = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    const data = _.concat(_.get(items, key).specimens, appendFunc());
    _.set(items, `${key}.specimens`, data);

    serviceFunc(_.concat(items));
};

const addItemCondition = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    const data = _.concat(_.get(items, key).conditions, appendFunc());
    _.set(items, `${key}.conditions`, data);

    serviceFunc(_.concat(items));
};

const addItemSiUnit = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    const data = _.concat(_.get(items, key).si_units, appendFunc());
    _.set(items, `${key}.si_units`, data);

    serviceFunc(_.concat(items));
};

const addItemEnglishUnit = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    const data = _.concat(_.get(items, key).english_units, appendFunc());
    _.set(items, `${key}.english_units`, data);

    serviceFunc(_.concat(items));
};

const removeItem = serviceFunc => (items, index, key) => () => {
    if (key === undefined) {
        serviceFunc(items.filter((i, k) => k !== index));
        return;
    }

    const filtered = _.get(items, key).filter((i, k) => k !== index);

    _.set(items, key, filtered);

    serviceFunc(_.concat(items));
};

const onChangeItem = serviceFunc => (itemsMap, key) => (value, index, funcNext, keyNext) => {
    _.set(itemsMap, index, value);

    const nextData = _.get(itemsMap, keyNext);
    const skipAddNext = Array.isArray(nextData) && nextData.length > 0;

    funcNext(skipAddNext);
    serviceFunc(key, _.concat(itemsMap));
};

const onChangeShowBtnItem = (serviceFunc, onChangeFunc, resetForm) => (itemsMap, key) => (value, index, rootProperties, formData, old) => {
    const canSwitch = isEqual(formData, old, _.keys(old));

    if (canSwitch) {
        itemsMap = [];
        _.set(itemsMap, index, value);

        value && serviceFunc(rootProperties[index].id, 'hierarchyProperties');
        onChangeFunc(key, _.concat(itemsMap));
    } else {
        if (window.confirm("You will lose unsaved changes. Continue?")) {
            itemsMap = [];
            _.set(itemsMap, index, value);

            value ? serviceFunc(rootProperties[index].id, 'hierarchyProperties') : resetForm();
            onChangeFunc(key, _.concat(itemsMap));
        }
    }
};

const deleteRootProperty = (serviceFunc, onChangeFunc, resetForm) => (id, rootProperties, showProperties, index) => () => {
    if (window.confirm("Do you want to remove the Property?")) {
        if (!id) {
            _.pullAt(rootProperties, index);
            _.pullAt(showProperties, index);
            onChangeFunc('rootProperties', rootProperties);
            onChangeFunc('showProperties', showProperties);
            resetForm();
        } else {
            serviceFunc(id).then(
                data => {
                    url.redirect("/admin/property-hierarchies");
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    }
};

const propTypes = {
    hierarchyProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    showProperties: PropTypes.arrayOf(PropTypes.bool).isRequired,
    rootProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
    addProperty: PropTypes.func.isRequired,
    addMethod: PropTypes.func.isRequired,
    addSpecimen: PropTypes.func.isRequired,
    addCondition: PropTypes.func.isRequired,
    addSiUnit: PropTypes.func.isRequired,
    addEnglishUnit: PropTypes.func.isRequired,
    removeProperty: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    deleteProperty: PropTypes.func.isRequired,
};

const HierarchyBlock = (
    {
        hierarchyProperties,
        showProperties,
        rootProperties,
        onChange,
        onChangeShowBtn,
        addProperty,
        addMethod,
        addSpecimen,
        addCondition,
        addSiUnit,
        addEnglishUnit,
        removeProperty,
        deleteProperty,
        formData,
        old,
        t
    }
) => {
    const onChangeHierarchyProperty = onChange(hierarchyProperties, "hierarchyProperties");
    const onChangeShowBtnLess = onChangeShowBtn(showProperties, "showProperties");

    const showAddNewBtn = () => {
        return _.every(showProperties, item => !item) && _.every(rootProperties, item => item.id !== '');
    };

    return (
        <BlockMain title="">
            <BlockWrap className="width_100">
                {rootProperties.map((rootProperty, keyRootP) => (
                    <div key={`Root-property-${keyRootP}`}>
                        <div className={'toggle__show-block-edit-property-hierarchies'} key={`Title-${keyRootP}`}>
                            <BlockTitle>{rootProperty.name}</BlockTitle>
                            <button
                                className={["cost-modal__calculator_btn", showProperties[keyRootP] ? 'open' : ''].join(' ')}
                                onClick={
                                    value => onChangeShowBtnLess(!showProperties[keyRootP], keyRootP, rootProperties, formData, old)
                                }>
                                Show {showProperties[keyRootP] ? 'Less' : 'More'}
                            </button>
                            <button
                                type="button"
                                className={'edit-property-hierarchies__form-block-link'}
                                onClick={deleteProperty(rootProperty.id, rootProperties, showProperties, keyRootP)}
                            >
                                <Img img="icon_delete" />
                            </button>
                        </div>
                        {showProperties[keyRootP] && hierarchyProperties.map((property, keyP) => (
                            <div className="property-hierarchy_content" key={`Hierarchy-${keyP}`}>
                                <Block key={`Block-hierarchy-${keyP}`} className="line_hierarchy">
                                    <BlockWrap key={`BlockWrap-property-${keyP}`} className="width_1-6">
                                        <BlockWrapFlex key={`BlockWrapFlex-property-${keyP}`}>
                                            <WrapInput key={`WrapInput-property-${keyP}`} label="Property"
                                                       name={`${keyP}.property`}>
                                                <Input
                                                    placeholder={t("Search")}
                                                    disabled={!!rootProperties[keyRootP].id}
                                                    url="admin/material-properties/unique/properties"
                                                    type="asyncSelect"
                                                    value={hierarchyProperties[keyP].property}
                                                    onChange={
                                                        value => onChangeHierarchyProperty(value, `${keyP}.property`,
                                                            addMethod(hierarchyProperties, keyP), `${keyP}.methods`)
                                                    }
                                                />
                                            </WrapInput>
                                            {hierarchyProperties.length > 1 &&
                                            <LinkIcon onClick={removeProperty(hierarchyProperties, keyP)}/>}
                                        </BlockWrapFlex>
                                    </BlockWrap>
                                    <BlockWrap key={`BlockWrap-method-${keyP}`}>
                                        {property.methods.map((method, keyM) => (
                                            <Block key={`Block-method-${keyM}`} className="line_hierarchy">
                                                <BlockWrap key={`BlockWrap-method-${keyM}`} className="width_1-5">
                                                    <BlockWrapFlex key={`BlockWrapFlex-method-${keyM}`}>
                                                        <WrapInput key={`WrapInput-method-${keyM}`} label="Test Method"
                                                                   name={`${keyM}.method`}>
                                                            <Input
                                                                placeholder={t("Search")}
                                                                url="admin/material-properties/list/2"
                                                                type="asyncSelect"
                                                                value={method.method}
                                                                onChange={
                                                                    value => onChangeHierarchyProperty(value, `${keyP}.methods.${keyM}.method`,
                                                                        addSpecimen(hierarchyProperties, `${keyP}.methods.${keyM}`),
                                                                        `${keyP}.methods.${keyM}.specimens`)
                                                                }
                                                            />
                                                        </WrapInput>
                                                        {property.methods.length > 1 &&
                                                        <LinkIcon
                                                            onClick={removeProperty(hierarchyProperties, keyM, `${keyP}.methods`)}/>}
                                                    </BlockWrapFlex>
                                                </BlockWrap>
                                                <BlockWrap key={`BlockWrap-specimen-${keyM}`}>
                                                    {method.specimens.map((specimen, keyS) => (
                                                        <Block key={`Block-specimen-${keyS}`}
                                                               className="line_hierarchy">
                                                            <BlockWrap key={`BlockWrap-specimen-${keyS}`}
                                                                       className="width_1-4">
                                                                <BlockWrapFlex key={`BlockWrapFlex-specimen-${keyS}`}>
                                                                    <WrapInput key={`WrapInput-specimen-${keyS}`}
                                                                               label="Specimen"
                                                                               name={`${keyS}.specimen`}>
                                                                        <Input
                                                                            placeholder={t("Search")}
                                                                            url="admin/material-properties/list/3"
                                                                            type="asyncSelect"
                                                                            value={specimen.specimen}
                                                                            onChange={
                                                                                value => onChangeHierarchyProperty(value, `${keyP}.methods.${keyM}.specimens.${keyS}.specimen`,
                                                                                    addCondition(hierarchyProperties, `${keyP}.methods.${keyM}.specimens.${keyS}`),
                                                                                    `${keyP}.methods.${keyM}.specimens.${keyS}.conditions`)
                                                                            }
                                                                        />
                                                                    </WrapInput>
                                                                    {method.specimens.length > 1 &&
                                                                    <LinkIcon
                                                                        onClick={removeProperty(hierarchyProperties, keyS, `${keyP}.methods.${keyM}.specimens`)}/>}
                                                                </BlockWrapFlex>
                                                            </BlockWrap>
                                                            <BlockWrap key={`BlockWrap-condition-${keyS}`}>
                                                                {specimen.conditions.map((condition, keyC) => (
                                                                    <Block key={`Block-condition-${keyC}`}
                                                                           className="line_hierarchy">
                                                                        <BlockWrap key={`BlockWrap-condition-${keyC}`}
                                                                                   className="width_1-3">
                                                                            <BlockWrapFlex
                                                                                key={`BlockWrapFlex-condition-${keyC}`}>
                                                                                <WrapInput
                                                                                    key={`WrapInput-condition-${keyC}`}
                                                                                    label="Test Condition"
                                                                                    name={`${keyC}.condition`}>
                                                                                    <Input
                                                                                        placeholder={t("Search")}
                                                                                        url="admin/material-properties/list/4"
                                                                                        type="asyncSelect"
                                                                                        value={condition.condition}
                                                                                        onChange={
                                                                                            value => onChangeHierarchyProperty(value, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.condition`,
                                                                                                addSiUnit(hierarchyProperties, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}`),
                                                                                                `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units`)
                                                                                        }
                                                                                    />
                                                                                </WrapInput>
                                                                                {specimen.conditions.length > 1 &&
                                                                                <LinkIcon
                                                                                    onClick={removeProperty(hierarchyProperties, keyC, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions`)}/>}
                                                                            </BlockWrapFlex>
                                                                        </BlockWrap>
                                                                        <BlockWrap key={`BlockWrap-si_unit-${keyC}`}>
                                                                            {condition.si_units.map((si_unit, keySU) => (
                                                                                <Block key={`Block-si_unit-${keySU}`}
                                                                                       className="line_hierarchy">
                                                                                    <BlockWrap
                                                                                        key={`BlockWrap-si_unit-${keySU}`}
                                                                                        className="width_1-2">
                                                                                        <BlockWrapFlex
                                                                                            key={`BlockWrapFlex-si_unit-${keySU}`}>
                                                                                            <WrapInput
                                                                                                key={`WrapInput-si_unit-${keySU}`}
                                                                                                label="SI Unit"
                                                                                                name={`${keySU}.si_unit`}>
                                                                                                <Input
                                                                                                    placeholder={t("Search")}
                                                                                                    url="admin/material-properties/list/5"
                                                                                                    type="asyncSelect"
                                                                                                    value={si_unit.si_unit}
                                                                                                    onChange={
                                                                                                        value => onChangeHierarchyProperty(value, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units.${keySU}.si_unit`,
                                                                                                            addEnglishUnit(hierarchyProperties, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units.${keySU}`),
                                                                                                            `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units.${keySU}.english_units`)
                                                                                                    }
                                                                                                />
                                                                                            </WrapInput>
                                                                                            {condition.si_units.length > 1 &&
                                                                                            <LinkIcon
                                                                                                onClick={removeProperty(hierarchyProperties, keySU, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units`)}/>}
                                                                                        </BlockWrapFlex>
                                                                                    </BlockWrap>
                                                                                    <BlockWrap
                                                                                        key={`BlockWrap-english_unit-${keySU}`}>
                                                                                        {si_unit.english_units.map((english_unit, keyEU) => (
                                                                                            <Block
                                                                                                key={`Block-english_unit-${keyEU}`}>
                                                                                                <BlockWrap
                                                                                                    key={`BlockWrap-english_unit-${keyEU}`}
                                                                                                    className="width_1-2">
                                                                                                    <BlockWrapFlex
                                                                                                        key={`BlockWrapFlex-english_unit-${keyEU}`}>
                                                                                                        <WrapInput
                                                                                                            key={`WrapInput-english_unit-${keyEU}`}
                                                                                                            label="English Unit"
                                                                                                            name={`${keyEU}.english_unit`}>
                                                                                                            <Input
                                                                                                                placeholder={t("Search")}
                                                                                                                url="admin/material-properties/list/6"
                                                                                                                type="asyncSelect"
                                                                                                                value={english_unit.english_unit}
                                                                                                                onChange={
                                                                                                                    value => onChangeHierarchyProperty(value, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units.${keySU}.english_units.${keyEU}.english_unit`,
                                                                                                                        addEnglishUnit(hierarchyProperties, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units.${keySU}`),
                                                                                                                        `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units.${keySU}.english_units`)
                                                                                                                }
                                                                                                            />
                                                                                                        </WrapInput>
                                                                                                        {si_unit.english_units.length > 1 &&
                                                                                                        <LinkIcon
                                                                                                            onClick={removeProperty(hierarchyProperties, keyEU, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}.si_units.${keySU}.english_units`)}/>}
                                                                                                    </BlockWrapFlex>
                                                                                                </BlockWrap>
                                                                                            </Block>
                                                                                        ))}
                                                                                    </BlockWrap>
                                                                                </Block>
                                                                            ))}
                                                                            {condition.si_units.length >= 1 && condition.si_units[0].si_unit !== '' &&
                                                                            <Button
                                                                                className="size_normal main-btn main-btn_white"
                                                                                onClick={value => addSiUnit(hierarchyProperties, `${keyP}.methods.${keyM}.specimens.${keyS}.conditions.${keyC}`)(false)}>
                                                                                {t("Add Another SI Unit")}
                                                                            </Button>
                                                                            }
                                                                        </BlockWrap>
                                                                    </Block>
                                                                ))}
                                                                {specimen.conditions.length >= 1 && specimen.conditions[0].condition !== '' &&
                                                                <Button className="size_normal main-btn main-btn_white"
                                                                        onClick={value => addCondition(hierarchyProperties, `${keyP}.methods.${keyM}.specimens.${keyS}`)(false)}>
                                                                    {t("Add Another Condition")}
                                                                </Button>
                                                                }
                                                            </BlockWrap>
                                                        </Block>
                                                    ))}
                                                    {method.specimens.length >= 1 && method.specimens[0].specimen !== '' &&
                                                    <Button className="size_normal main-btn main-btn_white"
                                                            onClick={value => addSpecimen(hierarchyProperties, `${keyP}.methods.${keyM}`)(false)}>
                                                        {t("Add Another Specimen")}
                                                    </Button>
                                                    }
                                                </BlockWrap>
                                            </Block>
                                        ))}
                                        {property.methods.length >= 1 && property.methods[0].method !== '' &&
                                        <Button className="size_normal main-btn main-btn_white"
                                                onClick={value => addMethod(hierarchyProperties, keyP)(false)}>
                                            {t("Add Another Method")}
                                        </Button>
                                        }
                                    </BlockWrap>
                                </Block>
                            </div>
                        ))}
                    </div>
                ))}
                {showAddNewBtn() &&
                <Button className="size_normal main-btn main-btn_white" onClick={addProperty}>
                    {t("Add Another Property")}
                </Button>
                }
            </BlockWrap>
        </BlockMain>
    );
};

HierarchyBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {service} = ownProps;
    const {getStoreItem} = service;

    return {
        hierarchyProperties: getStoreItem(state, `hierarchyProperties`),
        showProperties: getStoreItem(state, `showProperties`),
        rootProperties: getStoreItem(state, `rootProperties`),
        old: getStoreItem(state, "old"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    const onChange = getActionStore("onChange", service, dispatch);
    const resetForm = getActionStore("resetForm", service, dispatch);
    const refreshProperty = getActionStore("refreshProperty", service, dispatch);
    const addProperty = getActionStore("addProperty", service, dispatch);
    const requestHierarchyData = getActionStore("onChangeShowBtn", service, dispatch);
    const deleteProperty = getActionStore("deleteProperty", service, dispatch);

    return {
        addProperty: addItemProperty(attachProperty, addProperty),
        addMethod: addItemMethod(attachMethod, refreshProperty),
        addSpecimen: addItemSpecimen(attachSpecimen, refreshProperty),
        addCondition: addItemCondition(attachCondition, refreshProperty),
        addSiUnit: addItemSiUnit(attachSiUnit, refreshProperty),
        addEnglishUnit: addItemEnglishUnit(attachEnglishUnit, refreshProperty),
        removeProperty: removeItem(refreshProperty),
        onChange: onChangeItem(onChange),
        onChangeShowBtn: onChangeShowBtnItem(requestHierarchyData, onChange, resetForm),
        deleteProperty: deleteRootProperty(deleteProperty, onChange, resetForm)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(HierarchyBlock);
