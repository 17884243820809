import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";

const {Button, Form, Title, ColInput} = ModalDefault;

const defaultProps = {
    data: null
};

const propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    onClose: PropTypes.func.isRequired,
    cbAfterRequest: PropTypes.func.isRequired,
    createFolder: PropTypes.func.isRequired,
    editFolder: PropTypes.func.isRequired,
};

class ForlderForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.data && this.props.data.id || "",
            folderName: this.props.data && this.props.data.name || "",
        };
    }

    handleClickOnSubmit = () => {
        const {id, folderName} = this.state;
        if (id) {
            const {editFolder} = this.props;
            editFolder(id, {name: folderName}).then(res => {
                const {cbAfterRequest} = this.props;
                cbAfterRequest(res);
            });
        } else {
            const {createFolder} = this.props;
            createFolder({name: folderName}).then(res => {
                const {cbAfterRequest} = this.props;
                cbAfterRequest(res);
            });
        }
    };

    setFolderName = value => {
        this.setState({
            folderName: value.substr(0, 255),
        });
    };

    handleCloseModal = event => {
        event.preventDefault();
        const {onClose} = this.props;
        onClose();
    };

    render() {
        const {t} = this.props;
        const {id, folderName} = this.state;
        const disabled = Boolean(!folderName);
        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={this.handleCloseModal}/>
                <Title>{t(id ? "Edit Folder" : "Create Folder")}</Title>
                <ColInput
                    placeholder={t("Enter Name")}
                    name="folderName"
                    value={folderName}
                    onChange={value => this.setFolderName(value)}
                />
                <Button onClick={this.handleClickOnSubmit} disabled={disabled}>
                    {t(id ? "Save Folder" : "Create Folder")}
                </Button>
            </Form>
        );
    }
}

ForlderForm.defaultProps = defaultProps;
ForlderForm.propTypes = propTypes;

const mapStateToProps = () => {
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        createFolder: getActionStore("createFolderAction", service, dispatch),
        editFolder: getActionStore("editFolderAction", service, dispatch),
    };
};
export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ForlderForm);
