import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { CheckPermission, withTagDefaultProps } from "Hoc/Template";
import {
    TabBlock,
    TabBlockBtn,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";

const propTypes = {
    id: PropTypes.string.isRequired,
    pagination: PropTypes.shape({
        total: PropTypes.number.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        onClickEditItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const Contacts = ({ pagination, t, service, request, id, fetchItems }) => {
    const { onClickDeleteItem, onClickEditItem } = service;

    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the Contact?"))) {
            onClickDeleteItem(item).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        onClickEditItem(item);
    };

    const redirectOnAddContact = () => {
        window.open(`/contacts/create/${id}`, "_blank");
    };

    return (
        <>
            <TabWrapTop>
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                        <TabBlockColTitleH4>
                            {`${numberWithCommas(pagination.total, 0)} ${t("Contacts")}`}
                        </TabBlockColTitleH4>
                    </TabBlockCol>
                </TabBlock>
                <CheckPermission permission="contacts_view">
                    <TabBlockBtn onClick={redirectOnAddContact}>{t("Add Contact")}</TabBlockBtn>
                </CheckPermission>
            </TabWrapTop>
            <TabViewDetail onClickDelete={onClickDelete} onClickEdit={onClickEdit} />
        </>
    );
};

Contacts.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        id: getStoreItem(state, "view.id"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Contacts);
