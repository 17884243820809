import Constants from "../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const onChangeSecondSelectAction = (service, dispatch) => value => {
    dispatch({ type: Constants.CHANGE_SECOND_SELECT, data: { value } });
};

export const removeItemAction = (service, dispatch) => frontId => {
    dispatch({ type: Constants.REMOVE_ITEM, data: { frontId } });
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.RESET_FORM });
};

export const getDataAction = (service, dispatch) => data => {
    dispatch({ type: Constants.FETCH_ITEMS_REQUESTED });

    const { getData } = service;
    const { filter: { time_scale }} = data;
    const timeScale = {
        yearly: 1,
        quarterly: 2,
        monthly: 3,
    };

    return getData(data).then(
        res => {
            const data = {};

            switch (+time_scale) {
                case timeScale.quarterly:
                    for (const key in res.data) {
                        const t = (key).split("/")[0];
                        const y = (key).split("/")[1];
                        data[`Q${t} (FY ${y})`] = res.data[key];
                    }
                    res.data = data;
                    break;

                case timeScale.yearly:
                    for (const key in res.data) {
                        data[`FY ${key}`] = res.data[key];
                    }
                    res.data = data;
                    break;

                case timeScale.monthly:
                    res.data = keySort(res.data);
                    break;
            }

            dispatch({ type: Constants.FETCH_ITEMS_SUCCEEDED, data: res })
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.FETCH_ITEMS_FAILED, data: res });
        },
    );
};



const keySort = (unordered) => {
    const ordered = {};

    Object.keys(unordered).sort((a, b) => {
        const c = a.split('/');
        const d = b.split('/');

        if((c[1] - d[1]) < 0)
            return -1;

        if((c[1] - d[1]) > 0)
            return 1;

        return (c[0] - d[0]);
    }).forEach((key) => {
        ordered[key] = unordered[key];
    });

    return ordered;
};
