import _ from "lodash";
import {InputFilter} from "../../../../../../../Templates/Table/Filters";
import {onChange} from "./EventsService";
import React from "react";

export const getTableLabel = (key) => {
    const tableLabel = {
        name: "Shared Lists with",
        email: "Email",
        first_shared_date: "Date First Shared",
        last_login_date: "Last Date Accessed",
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        email: "Search",
        first_shared_date: "Search",
        last_login_date: "Search",
        History: "Search",
    };

    switch (key) {
        case "first_shared_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "last_login_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => (["name", "email", "first_shared_date", "last_login_date"].includes(key));