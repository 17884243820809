import React from "react";
import {compose} from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import {BlockMain, BlockWrap, Block, BlockWrapFlex, Input, WrapInput} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Button} from "Templates/Default";
import LinkIcon from "Templates/Link/LinkIcon";

const attachProperty = () => {
    return {
        property: ""
    };
};

const addItemProperty = (appendFunc, serviceFunc) => items => () => {
    serviceFunc(_.concat(items, appendFunc()));
};

const removeItem = serviceFunc => (items, index, key) => () => {
    if (key === undefined) {
        serviceFunc(items.filter((i, k) => k !== index));
        return;
    }

    const filtered = _.get(items, key).filter((i, k) => k !== index);

    _.set(items, key, filtered);

    serviceFunc(_.concat(items));
};

const onChangeItem = serviceFunc => (itemsMap, key) => (value, index) => {
    _.set(itemsMap, index, value);

    serviceFunc(key, _.concat(itemsMap));
};

const propTypes = {
    properties: PropTypes.arrayOf(PropTypes.object).isRequired,
    addProperty: PropTypes.func.isRequired,
    removeProperty: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const HierarchyBlock = (
    {
        properties,
        onChange,
        addProperty,
        removeProperty,
        t
    }
) => {
    const onChangeProperty = onChange(properties, "properties");

    return (
        <BlockMain title="Properties">
            <BlockWrap className="width_100">
                {properties.map((property, keyP) => (
                    <Block key={`Block-hierarchy-${keyP}`}>
                        <BlockWrap key={`BlockWrap-property-${keyP}`} className="width_1-6">
                            <BlockWrapFlex key={`BlockWrapFlex-property-${keyP}`}>
                                <WrapInput key={`WrapInput-property-${keyP}`} label="Property"
                                           name={`${keyP}.property`}>
                                    <Input
                                        placeholder={t("Search")}
                                        url="admin/material-properties/properties-with-hierarchy"
                                        type="asyncSelect"
                                        value={property.property}
                                        onChange={
                                            value => onChangeProperty(value, `${keyP}.property`)
                                        }
                                    />
                                </WrapInput>
                                {properties.length > 1 &&
                                <LinkIcon onClick={removeProperty(properties, keyP)}/>}
                            </BlockWrapFlex>
                        </BlockWrap>
                    </Block>
                ))}
                {properties.length >= 1 && properties[0].property !== '' &&
                <Button className="size_normal main-btn main-btn_white" onClick={addProperty(properties)}>
                    {t("Add Another Property")}
                </Button>
                }
            </BlockWrap>
        </BlockMain>
    );
};

HierarchyBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {service} = ownProps;
    const {getStoreItem} = service;

    return {
        properties: getStoreItem(state, `properties`),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    const onChange = getActionStore("onChange", service, dispatch);
    const refreshProperty = getActionStore("refreshProperty", service, dispatch);

    return {
        addProperty: addItemProperty(attachProperty, refreshProperty),
        removeProperty: removeItem(refreshProperty),
        onChange: onChangeItem(onChange),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(HierarchyBlock);
