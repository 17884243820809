import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import {numberWithCommas, url} from "Services";
import TabCheck from "Templates/Tabs/TabСheck";
import ProductSource from "./Views/Tabs/ProductSource";
import ProductCode from "./Views/Tabs/ProductCode";
import Color from "./Views/Tabs/Color";
import ColorCode from "./Views/Tabs/ColorCode";
import PublicationLogo from "./Views/Tabs/PublicationLogo";
import SolutionLogo from "./Views/Tabs/SolutionLogo";
import Safety from "./Views/Tabs/Safety";
import Brochure from "./Views/Tabs/Brochure";
import Keyword from "./Views/Tabs/Keyword";
import Approval from "./Views/Tabs/Approval";
import ApplicationCode from "./Views/Tabs/ApplicationCode";
import ContactRole from "./Views/Tabs/ContactRole";
import productSourceService from "./Services/Tabs/ProductSource";
import productCodeService from "./Services/Tabs/ProductCode";
import colorService from "./Services/Tabs/Color";
import colorCodeService from "./Services/Tabs/ColorCode";
import keywordService from "./Services/Tabs/Keyword";
import approvalService from "./Services/Tabs/Approval";
import manufacturerService from "./Services/Tabs/Manufacturer";
import applicationCodeService from "./Services/Tabs/ApplicationCode";
import materialTypeService from "./Services/Tabs/MaterialType";
import publicationLogoService from "./Services/Tabs/PublicationLogo";
import solutionLogoService from "./Services/Tabs/SolutionLogo";
import safetyService from "./Services/Tabs/Safety";
import brochureService from "./Services/Tabs/Brochure";
import roleRoleService from "./Services/Tabs/ContactRole";
import Title from "Templates/Titles/Title";
import Total from "Templates/Titles/Total";
import WrapButtons from "Templates/Titles/WrapButtons";
import TabBlockBtn from "Templates/Content/TabBlockBtn";
import {Modal} from "Templates/Modals";
import AddForm from "./AddForm";
import MaterialType from "./Views/Tabs/MaterialType";
import CustomManufacturer from "./Views/Tabs/CustomManufacturer";

const propTypes = {
    id: PropTypes.string,
    activeTab: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired
};

const labels = [
    {
        label: "Product Source",
        button: "Product Source",
        service: productSourceService,
    },
    {
        label: "Product Code",
        button: "Product Code",
        service: productCodeService,
    },
    {
        label: "Application Code",
        button: "Application Code",
        service: applicationCodeService,
    },
    {
        label: "Material Type",
        button: "Material Type",
        service: materialTypeService,
    },
    {
        label: "OEM Approval",
        button: "OEM Approval",
        service: approvalService,
    },
    {
        label: "Custom Manufacturer",
        button: "Custom Manufacturer",
        service: manufacturerService,
    },
    {
        label: "Contact Role",
        button: "Contact Role",
        service: roleRoleService,
    },
    {
        label: "Color",
        button: "Color",
        service: colorService,
    },
    {
        label: "Color Code",
        button: "Color Code",
        service: colorCodeService,
    },
    {
        label: "Publication Logo",
        button: "Publication Logo",
        service: publicationLogoService,
    },
    {
        label: "Safety",
        button: "Safety",
        service: safetyService,
    },
    {
        label: "Brochure",
        button: "Brochure",
        service: brochureService,
    },
    {
        label: "Technology Logo",
        button: "Technology Logo",
        service: solutionLogoService,
    },
    // {
    //     label: "Keyword",
    //     button: "Keyword",
    //     service: keywordService,
    // },
];

class Lists extends Component {
    render() {
        const {id, title, onChangeTab, t, handleOpenModal, handleCloseModal, showModal, permissions} = this.props;
        let {activeTab} = this.props;

        if (!permissions.includes('admin_lists_crud_product_source') && activeTab === 0) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_product_code') && activeTab === 1) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_application_code') && activeTab === 2) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_material_type') && activeTab === 3) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_oem_approval') && activeTab === 4) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_custom_manufacturer') && activeTab === 5) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_contact_role') && activeTab === 6) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_color') && activeTab === 7) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_color_code') && activeTab === 8) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_publication_logo') && activeTab === 9) {
            activeTab++;
            onChangeTab(activeTab);
        } else if (!permissions.includes('admin_lists_crud_safety') && activeTab === 10) {
            activeTab++;
            onChangeTab(activeTab);
        }else if (!permissions.includes('admin_lists_crud_brochure') && activeTab === 11) {
            activeTab++;
            onChangeTab(activeTab);
        }else if (!permissions.includes('admin_lists_crud_publication_logo') && activeTab === 12) {
            activeTab++;
            onChangeTab(activeTab);
        }

        const onClickAdd = activeTab => {
            if (labels[activeTab].label === 'Product Source') {
                return () => url.redirect(`/admin/list/products/create`);
            } else if (labels[activeTab].label === 'Application Code') {
                return () => url.redirect(`/admin/list/application-codes/create`);
            }

            return handleOpenModal;
        };

        const tab = () => {
            return (
                <>
                    {showModal && (
                        <Modal overlayClassName="main-modal list-files-modal" onRequestClose={handleCloseModal}>
                            <ServiceProvider value={labels[activeTab].service}>
                                <AddForm button={labels[activeTab].button} type={activeTab}
                                         onClose={handleCloseModal}/>
                            </ServiceProvider>
                        </Modal>
                    )}
                    <Tabs
                        isDropDownView={true}
                        dropDownTitle={'Select List View'}
                        activeTab={activeTab}
                        onClickTabItem={index => {
                            if (activeTab !== index) {
                                onChangeTab(index);
                            }
                        }}
                    >
                        <TabCheck label={t("Product Source")} permission="admin_lists_crud_product_source">
                            <ServiceProvider value={productSourceService(id)}>
                                <ProductSource/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Product Code")} permission="admin_lists_crud_product_code">
                            <ServiceProvider value={productCodeService}>
                                <ProductCode/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Application Code")} permission="admin_lists_crud_application_code">
                            <ServiceProvider value={applicationCodeService}>
                                <ApplicationCode/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Material Type")} permission="admin_lists_crud_material_type">
                            <ServiceProvider value={materialTypeService}>
                                <MaterialType/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("OEM Approval")} permission="admin_lists_crud_oem_approval">
                            <ServiceProvider value={approvalService}>
                                <Approval/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Custom Manufacturer")} permission="admin_lists_crud_custom_manufacturer">
                            <ServiceProvider value={manufacturerService}>
                                <CustomManufacturer/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Contact Role")} permission="admin_lists_crud_contact_role">
                            <ServiceProvider value={roleRoleService}>
                                <ContactRole/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Color")} permission="admin_lists_crud_color">
                            <ServiceProvider value={colorService}>
                                <Color/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Color Code")} permission="admin_lists_crud_color_code">
                            <ServiceProvider value={colorCodeService}>
                                <ColorCode/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Publication Logo")} permission="admin_lists_crud_publication_logo">
                            <ServiceProvider value={publicationLogoService}>
                                <PublicationLogo/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Safety")} permission="admin_lists_crud_safety">
                            <ServiceProvider value={safetyService}>
                                <Safety/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Brochure")} permission="admin_lists_crud_brochure">
                            <ServiceProvider value={brochureService}>
                                <Brochure/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Technology Logo")} permission="admin_lists_crud_publication_logo">
                            <ServiceProvider value={solutionLogoService}>
                                <SolutionLogo/>
                            </ServiceProvider>
                        </TabCheck>
                        {/*<Tab label={t("Keyword")}>*/}
                        {/*    <ServiceProvider value={keywordService}>*/}
                        {/*        <Keyword/>*/}
                        {/*    </ServiceProvider>*/}
                        {/*</Tab>*/}
                    </Tabs>
                </>
            );
        };

        return (
            <>
                <Title title={t(labels[activeTab].label)}
                       total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}>
                    <WrapButtons>
                        <TabBlockBtn
                            onClick={onClickAdd(activeTab)}>{t(`Add New ${labels[activeTab].button}`)}</TabBlockBtn>
                    </WrapButtons>
                </Title>
                {tab()}
            </>
        );
    }
}

Lists.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        title: getStoreItem(state, "pagination.total"),
        activeTab: getStoreItem(state, "activeTab"),
        showModal: getStoreItem(state, "showModal"),
        permissions: state.userState.permissions,
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Lists);
