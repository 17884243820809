import React from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabView from "./Views/TabView";
import { listTypes } from "./Services";
import ShowPermission from "Hoc/Template/ShowPermission";

const File = ({ fetchItems, request, t, deleteItem }) => {
    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (
            confirm(t(`Do you want to delete the ${(_.get(listTypes, `[${item.type}].label`) || "").toLowerCase().charAt(0).toUpperCase()}${(_.get(listTypes, `[${item.type}].label`) || "").toLowerCase().slice(1)}?`))
        ) {
            deleteItem(item.id).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    return (<ShowPermission checkPermission={['file_general']}>
                <TabView onClickDelete={onClickDelete} />
            </ShowPermission>);
};

File.propTypes = {
    fetchItems: PropTypes.func.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    deleteItem: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        deleteItem: getActionStore("deleteTableItemAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(File);
