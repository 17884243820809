import ReactOnRails from "react-on-rails";
import React from "react";
import Root from "App/View/Root";
import Index from "./Index";
import * as service from "./Services";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);

    const id = service.getStoreItem(store.getState(), "view.id");

    const _service = {
        ...service,
        requestTable: service.requestTable(id),
    };

    return (
        <Root store={store} service={_service}>
            <Index />
        </Root>
    );
};

export default App;
