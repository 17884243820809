import React, {useState} from 'react';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import PropTypes from "prop-types";
import Img from "Templates/Img/Img";
import _ from "lodash";
import {Modal} from "Templates/Modals";
import {InputFilter} from "Templates/Table/Filters";
import {MainButtonLink} from "Templates/Content";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {formatDate, url} from "Services";
import {Block, Label} from "Templates/Form";
import HeaderDatasheet from "../Technical/HeaderDatasheet";
import Content from "../Technical/Content";
import NotFoundDatasheet from "../Technical/NotFoundDatasheet";
import CopyPublicationForm from "../Technical/CopyPublicationForm";
import Approve from "../Approve";
import ProductTable from "../ProductTable";
import ShowMoreSwitch from "../ShowMoreSwitch";
import {datasheetLocation} from "Services/enum";
import AssignToProductForm from "../../Shared/SapProductsColorsModals/AssignToProductForm";
import EditProductForm from "../../Shared/SapProductsColorsModals/EditProductForm";
import {PUBLICATIONS_TABS, TABS} from "../../../Constants";
import {ViewHistoryTable} from "../../Modals/ViewHistoryTable";
import {ViewNotesTable} from "../../Modals/ViewNotesTable";

const defaultProps = {
    total: 0,
    subTabs: [],
    activeSubTab: {}
};

const propTypes = {
    id: PropTypes.string.isRequired,
    fetchSubTabAction: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        onClickDeleteFromProductItem: PropTypes.func.isRequired
    }).isRequired,
    showModal: PropTypes.bool.isRequired,
    showEditModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    saveAgreements: PropTypes.func.isRequired,
    showModalCopyPublication: PropTypes.bool.isRequired,
    handleCloseEditModal: PropTypes.func.isRequired,
    handleOpenEditModal: PropTypes.func.isRequired,
    handleCloseCopyPublicationModal: PropTypes.func.isRequired,
    handleOpenCopyPublicationModal: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    editProductForm: PropTypes.objectOf(PropTypes.any).isRequired,
    total: PropTypes.number,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchSubTabs: PropTypes.func.isRequired,
    subTabs: PropTypes.arrayOf(PropTypes.object),
    activeSubTab: PropTypes.objectOf(PropTypes.any),
    activeTab: PropTypes.number.isRequired,
    getHistoryItems: PropTypes.func.isRequired,
    getNotesItems: PropTypes.func.isRequired,
    rollbackPublicationHistory: PropTypes.func.isRequired,
};

const Design = (
    {
        id,
        t,
        subTabs,
        activeSubTab,
        activeTab,
        fetchSubTabAction,
        service: {onClickDeleteItem, onClickDeleteFromProductItem},
        fetchSubTabs,
        showModalCopyPublication,
        handleOpenCopyPublicationModal,
        handleCloseCopyPublicationModal,
        showModal,
        showEditModal,
        handleCloseModal,
        handleOpenModal,
        handleOpenEditModal,
        handleCloseEditModal,
        form,
        editProductForm,
        callback,
        total,
        permissions,
        saveAgreements,
        getHistoryItems,
        getNotesItems,
        rollbackPublicationHistory,
    }
) => {
    const designData = _.get(activeSubTab, 'items_data');
    const agreements = _.get(designData, 'agreements', []);
    const isApproveForPublication = !!_.find(agreements, {type: 1});
    const isApproveForSharing = !!_.find(agreements, {type: 2});
    const isApproveForLists = !!_.find(agreements, {type: 3});
    const [urlSubTab, setUrlSubTab] = useState('')

    const [publicationTabActive, setPublicationTabActive] = useState(PUBLICATIONS_TABS.VIEW);

    const showMore = agreements.length !== 0 && permissions.includes("product_publications_view");
    const hasPermissionToForChange = permissions && permissions.includes("product_publications_edit");

    const onClickDeleteDesignFromProduct = item => {
        if (!item.can_edit || !hasPermissionToForChange) {
            alert('Contact an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to remove the Guide from product?"))) {
            onClickDeleteFromProductItem(item.grade_id).then(
                () => {
                    fetchSubTabs();
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickEdit = item => {
        if (!hasPermissionToForChange) {
            alert('Contact an Admin to edit.');
            return;
        }

        handleOpenEditModal(item)
    }

    React.useEffect(() => {
        fetchSubTabs().then(() => {
            const subTabId = url.getQueryParam('subTab');

            if (subTabId) {
                setUrlSubTab(subTabId)
                fetchSubTabAction(subTabId);
            }
        });
    }, [])

    const onChangeForm = (key, value) => {
        form = {
            ...form,
            [key]: value || []
        };

        callback(key, value, form);
    }

    const onClickDelete = (item) => () => {
        if (window.confirm(t("Do you want to remove the Guide?"))) {
            onClickDeleteItem(item).then(() => {
                fetchSubTabs({});
            });
        }
    };

    const locationView = (elem) => {
        return elem ? datasheetLocation.find(el => el.value === elem).label : '-';
    };

    const showContent = (array, elemView) => {
        return array.map(elem => elemView(elem)).join(', ');
    };

    const onClickApprove = (item, type, status) => () => {
        const question = `${status ? 'Approve' : 'Disapprove'}`
            + ` selected Guide for ${type === 1 ? 'Publication' : 'Sharing'}?`;

        if (window.confirm(t(question))) {
            const agreements = {
                1: {type: 1, status: 0},
                2: {type: 2, status: 0},
            };

            for (const a of Object.values(item.agreements)) {
                agreements[a.type] = {type: a.type, status: 10};
            }

            agreements[type] = {type, status: status ? 10 : 0};

            saveAgreements(item.id)({agreements: Object.values(agreements)}).then(
                () => fetchSubTabs({})
            );
        }
    };

    return (
        <>
            {showModal && (
                <Modal onRequestClose={handleCloseModal}>
                    <AssignToProductForm
                        tabName={'designs'}
                        callback={onChangeForm}
                        datasheetId={activeSubTab.id}
                        form={form}
                        onClose={handleCloseModal}
                    />
                </Modal>
            )}
            {showEditModal && (
                <Modal onRequestClose={handleCloseEditModal}>
                    <EditProductForm
                        datasheetId={activeSubTab.id}
                        editProductForm={editProductForm}
                        onClose={handleCloseEditModal}
                    />
                </Modal>
            )}
            {showModalCopyPublication && (
                <Modal onRequestClose={handleCloseCopyPublicationModal}>
                    <CopyPublicationForm
                        callback={onChangeForm}
                        datasheetId={activeSubTab.id}
                        form={form}
                        onClose={handleCloseCopyPublicationModal}
                    />
                </Modal>
            )}

            <div className={'publications-head-wrap'}>
                {subTabs && subTabs.length > 0 && (
                    <div className={'flex'}>
                        <Label label="Select"/>
                        <span className={'publications-counter flex'}>({subTabs.length})</span>
                    </div>
                )}
                <div className="tds-list-wrap tds-list-wrap__publications">
                    {(subTabs && subTabs.length) ?
                        (
                            <InputFilter
                                type="select"
                                value={activeSubTab.id}
                                options={[...subTabs.map((val) => {
                                    return {value: val.id, label: val.custom_name}
                                })]}
                                onChange={subTabId => {
                                    fetchSubTabAction(subTabId);
                                    setUrlSubTab(subTabId);
                                    url.addHistory({'subTab': subTabId, 'activeTab': activeTab});
                                }}
                            />
                        ) : <div/>}
                    <div className="d-flex align-center">
                        {designData ?
                            <CheckPermission permission="product_publications_approve">
                                {!isApproveForPublication && (
                                    <MainButtonLink className="main-btn_white"
                                                    onClick={onClickApprove(designData, 1, true)}>
                                        {t("Approve for SAP")}
                                    </MainButtonLink>
                                )}
                                {isApproveForPublication && (
                                    <MainButtonLink className="main-btn_primary"
                                                    onClick={onClickApprove(designData, 1, false)}>
                                        {t("Disapprove for SAP")}
                                    </MainButtonLink>
                                )}
                                {!isApproveForSharing && (
                                    <MainButtonLink className="main-btn_white"
                                                    onClick={onClickApprove(designData, 2, true)}>
                                        {t("Approve for Shared Products")}
                                    </MainButtonLink>
                                )}
                                {isApproveForSharing && (
                                    <MainButtonLink className="main-btn_primary"
                                                    onClick={onClickApprove(designData, 2, false)}>
                                        {t("Disapprove for Shared Products")}
                                    </MainButtonLink>
                                )}
                                {!isApproveForLists && (
                                    <MainButtonLink className="main-btn_white"
                                                    onClick={onClickApprove(designData, 3, true)}>
                                        {t("Approve for Lists")}
                                    </MainButtonLink>
                                )}
                                {isApproveForLists && (
                                    <MainButtonLink className="main-btn_primary"
                                                    onClick={onClickApprove(designData, 3, false)}>
                                        {t("Disapprove for Lists")}
                                    </MainButtonLink>
                                )}
                            </CheckPermission> :
                            null}
                        <CheckPermission permission="product_publications_edit">
                            <MainButtonLink
                                href={`/admin/publications/${id}/designs/create`}
                                className="main-btn_primary"
                            >
                                {t("Add New Guide")}
                            </MainButtonLink>
                        </CheckPermission>
                    </div>
                </div>

                {designData && <div className={`publications-actions-wrap`}>
                    <div>
                        {showMore && (
                            <ShowMoreSwitch isHidden/>
                        )}
                    </div>
                    <div className={'publications-actions'}>
                        <CheckPermission permission="product_publications_edit">
                            {(activeSubTab && Object.keys(activeSubTab).length !== 0) && (
                                <>
                                    <button
                                        type="button"
                                        className="main-circle"
                                        onClick={handleOpenCopyPublicationModal}
                                    >
                                        <Img img="icon_copy" className="copy-icon"/>
                                    </button>
                                    {(!isApproveForPublication || permissions.includes("product_publications_approve")) && (
                                        <a
                                            type="button"
                                            className="main-circle"
                                            href={`/admin/publications/${id}/designs/${activeSubTab.id}/edit`}
                                        >
                                            <Img img="icon_edit"/>
                                        </a>
                                    )}
                                    <button
                                        type="button"
                                        className="main-circle"
                                        onClick={onClickDelete(activeSubTab)}
                                    >
                                        <Img img="icon_delete"/>
                                    </button>
                                </>
                            )}
                        </CheckPermission>
                    </div>
                </div>}

                {designData ? (
                    <div className={`${showMore ? 'show-switch' : ''}`}>
                        <Block>
                            {showMore && (
                                <div className="approved-items-wrap">
                                    {agreements.map((agreement) => (
                                        <Approve
                                            key={`approved_${agreement.type}`}
                                            approver={agreement.user_name}
                                            approverEmail={agreement.user_email}
                                            approveDate={formatDate(agreement.date)}
                                            approveType={agreement.type}
                                        />
                                    ))}
                                </div>
                            )}
                        </Block>
                    </div>
                ) : null}
            </div>

            <div className="publications-sub-tabs">
                <div className="publications-sub-tabs__list-wrap">
                    <ul className={'publications-sub-tabs__list'}>
                        {TABS.map((tab) => {
                            return (
                                <li
                                    key={tab.id}
                                    className={`${publicationTabActive === tab.id ? 'active' : ''} ${!designData ? 'disabled' : ''} tab-switcher`}
                                    onClick={() => {
                                        setPublicationTabActive(tab.id)
                                    }}
                                >
                                    {t(tab.title)}
                                </li>
                            )
                        })}
                    </ul>
                </div>

                {publicationTabActive === PUBLICATIONS_TABS.VIEW && <div className={'tab-view'}>
                    <div
                        className={`grade-view__wrap main-wrap-block technical-wrap ${designData ? '' : 'not-available'}`}>
                        {!designData || (Array.isArray(designData) && !designData.length) ? (
                            <NotFoundDatasheet title="Guide"/>
                        ) : (
                            <>
                                <HeaderDatasheet datasheetData={designData}/>
                                {designData.contents[0] && designData.contents[0].description &&
                                    <Content data={designData.contents[0].description}/>
                                }
                                {designData.copy && <Content data={designData.copy}/>}
                                <Content data={`Revised ${formatDate(designData.date_updated)}`}/>
                            </>
                        )}
                    </div>
                </div>}

                {publicationTabActive === PUBLICATIONS_TABS.SAP_PRODUCTS && <div className={'tab-view'}>
                    {isApproveForPublication ? (
                        <ProductTable
                            onClickEdit={onClickEdit}
                            onClickDelete={onClickDeleteDesignFromProduct}
                            total={total}
                            handleOpenModal={handleOpenModal}
                        />
                    ) : <div className={`grade-view__wrap main-wrap-block technical-wrap not-available`}>
                        <div className="sheet-notInfo text-center">
                            <Img
                                img="icon_files"
                                width="100"
                                height="100"
                                alt="file"
                                className=""
                            />
                            <p className="text-center">
                                To display table please Approve for SAP Products.
                            </p>
                        </div>
                    </div>}
                </div>}

                {publicationTabActive === PUBLICATIONS_TABS.HISTORY && <div className={'tab-view'}>
                    {designData ? <>
                        <ViewHistoryTable urlSubTab={urlSubTab} emptyText={'There is no history for current Guide.'}
                                          onLoadHistoryData={getHistoryItems}
                                          rollbackHistory={rollbackPublicationHistory} fetchSubTabs={fetchSubTabs}/>
                    </> : null}
                </div>}

                {publicationTabActive === PUBLICATIONS_TABS.NOTES && <div className={'tab-view'}>
                    {designData ? <>
                        <ViewNotesTable urlSubTab={urlSubTab} emptyText={'There are no notes for current Guide.'}
                                        onLoadNotesData={getNotesItems}/>
                    </> : null}
                </div>}
            </div>
        </>
    );
};

Design.defaultProps = defaultProps;
Design.propTypes = propTypes;

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        saveAgreements: getActionStore("saveAgreementsAction", service, dispatch),
        fetchSubTabAction: getActionStore("setActiveSubTabAction", service, dispatch),
        fetchSubTabs: getActionStore("getSubTabs", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
        handleOpenEditModal: getActionStore("handleOpenEditModal", service, dispatch),
        handleCloseEditModal: getActionStore("handleCloseEditModal", service, dispatch),
        handleCloseCopyPublicationModal: getActionStore("handleCloseCopyPublicationModal", service, dispatch),
        handleOpenCopyPublicationModal: getActionStore("handleOpenCopyPublicationModal", service, dispatch),
        callback: getActionStore("onChangeForm", service, dispatch),
    };
};

const mapStateToProps = (state, {service: {getStoreItem, getHistoryData, getNotesData, rollbackHistory}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeSubTab: getStoreItem(state, "activeSubTab"),
        subTabs: getStoreItem(state, "subTabs"),
        activeTab: getStoreItem(state, "activeTab"),
        showModal: getStoreItem(state, "showModal"),
        showEditModal: getStoreItem(state, "showEditModal"),
        showModalCopyPublication: getStoreItem(state, "showModalCopyPublication"),
        form: {...getStoreItem(state, "form", true)},
        editProductForm: getStoreItem(state, "editProductForm"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        getHistoryItems: getHistoryData,
        getNotesItems: getNotesData,
        rollbackPublicationHistory: rollbackHistory,
        total: getStoreItem(state, "pagination.total"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Design);