import React, { useEffect } from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import TabView from "./Views/TabView";
import { url } from "Services";

const IHS = ({permissions}) => {
    useEffect(() => {
        if(!permissions.includes('admin_ihs_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    return <TabView />;
};

IHS.propTypes = {};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = (dispatch, { service }) => {
    // const { getActionStore } = service;

    return {};
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(IHS);
