import { handleScroll } from "../../EventsService";
import { getStoreItem } from "../../StoreService";
import { requestTable } from "../../RequestService";
import { getActionStore } from "./StoreService";

export default () => ({
    requestTable: requestTable(`/pipeline/my-pipeline`),
    handleScroll,
    getStoreItem,
    getActionStore,
});
