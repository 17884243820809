import React, {Component} from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import {regExpForFloatFive} from "Services";

class Range extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showContent: false,
            filter: {
                range_type: props.filter.range_type || '',
                range_property_manufacture: props.filter.range_property_manufacture || '',
                range_property: props.filter.range_property || '',
                range_unit: props.filter.range_unit || '',
                unit_value_min: props.filter.unit_value_min || '',
                unit_value_max: props.filter.unit_value_max || '',
            },
        };
    }

    handleChangeFilter = (label, value) => {
        this.setState(prevState => ({
            filter: {
                ...prevState.filter, [label]: value
            },
        }));
    };

    resetFilter = () => {
        this.setState({
            filter: {
                range_type: '',
                range_property_manufacture: '',
                range_property: '',
                range_unit: '',
                unit_value_min: '',
                unit_value_max: '',
            }
        });
    };

    handleToggleContent = () => {
        this.setState(prevState => ({showContent: !prevState.showContent}));
    };

    applyFilters = () => {
        const {callback} = this.props;
        const filters = this.state.filter;

        callback('range_type', filters.range_type);
        callback('range_property', filters.range_property);
        callback('range_unit', filters.range_unit);
        callback('range_property_manufacture', filters.range_property_manufacture);
        callback('unit_value_min', filters.unit_value_min !== '' ? filters.unit_value_min : '');
        callback('unit_value_max', filters.unit_value_max !== '' ? filters.unit_value_max : '');
    }

    render() {
        const {filter, showContent} = this.state;
        const showSearchButton = (filter.unit_value_min !== '' &&
                                           filter.unit_value_max !== '' &&
                                           (parseInt(filter.unit_value_min, 10) <= parseInt(filter.unit_value_max, 10)));

        return (<>
                <Div className="toggle__show-block">
                    <BlockTitle>Range Search</BlockTitle>
                    <button
                        type="button"
                        className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                        onClick={this.handleToggleContent}
                    >
                        Show
                        {' '}
                        {showContent ? 'Less' : 'More'}
                    </button>
                </Div>

                {showContent && (<div className="product__filters">
                        <p className="main-table__head-link filter-with-tooltip">
                            Type
                        </p>
                        <InputFilter
                            type="select"
                            name="range_type"
                            options={[{
                                value: "datasheet", label: "Manufacturing Process",
                            }, {
                                value: "property", label: "Material Properties",
                            }]}
                            placeholder="Select"
                            value={filter.range_type}
                            onChange={e => {
                                this.handleChangeFilter("range_type", e);
                                this.handleChangeFilter("range_property_manufacture", '');
                                this.handleChangeFilter("range_property", '');
                                this.handleChangeFilter("range_unit", '');

                                this.handleChangeFilter("unit_value_min", '');
                                this.handleChangeFilter("unit_value_max", '');
                            }}
                        />
                        {(filter.range_type === 'datasheet') &&
                            <p className="main-table__head-link filter-with-tooltip">
                                Select Mfg Parameter
                            </p>
                        }
                        {(filter.range_type === 'datasheet') &&
                            <InputFilter
                                type="asyncSelect"
                                placeholder="Select"
                                url="/product/product-finder/process-property-list/1/product-finder"
                                name="range_property_manufacture"
                                disabled={!filter.range_type}
                                value={filter.range_property_manufacture}
                                onChange={e => {
                                    this.handleChangeFilter("range_property_manufacture", e);
                                    this.handleChangeFilter("range_property", '');
                                    this.handleChangeFilter("range_unit", '');

                                    this.handleChangeFilter("unit_value_min", '');
                                    this.handleChangeFilter("unit_value_max", '');
                                }}
                            />
                        }
                        <p className="main-table__head-link filter-with-tooltip">
                            Select
                                {filter.range_type === 'datasheet' ? " Process Parameter" : ""}
                                {filter.range_type === 'property' ? " Material Property" : ""}
                        </p>
                        <InputFilter
                            type="asyncSelect"
                            placeholder="Select"
                            url={filter.range_type === 'datasheet' ?
                                `/product/product-finder/process-property-list/2/product-finder/parent${
                                filter.range_property_manufacture.length ? ('/'+filter.range_property_manufacture) : ''}` :
                                "/product/product-finder/material-property-list/1/product-finder"
                            }
                            name="range_property"
                            disabled={filter.range_type === 'datasheet' ? !filter.range_property_manufacture : !filter.range_type}
                            value={filter.range_property}
                            onChange={e => {
                                this.handleChangeFilter("range_property", e);
                                this.handleChangeFilter("range_unit", '');

                                this.handleChangeFilter("unit_value_min", '');
                                this.handleChangeFilter("unit_value_max", '');
                            }}
                        />
                        <p className="main-table__head-link filter-with-tooltip">
                            Units
                        </p>
                        <InputFilter
                            type="select"
                            name="range_unit"
                            disabled={!filter.range_property}
                            options={[{
                                value: "", label: "",
                            }, {
                                value: "english", label: "English",
                            }, {
                                value: "si", label: "SI",
                            }]}
                            placeholder="Select"
                            value={filter.range_unit}
                            onChange={e => {
                                this.handleChangeFilter("range_unit", e);
                                this.handleChangeFilter("unit_value_min", '');
                                this.handleChangeFilter("unit_value_max", '');
                            }}
                        />
                        <p className="main-table__head-link filter-with-tooltip">
                            Value
                        </p>
                        <div className="filter-number-range">
                            <InputFilter
                                placeholder='Min'
                                name="unit_value_min"
                                disabled={!filter.range_unit}
                                value={filter.unit_value_min}
                                onChange={val => {
                                    regExpForFloatFive(val, data => this.handleChangeFilter("unit_value_min", data), true)
                                }}
                            />
                            <div>-</div>
                            <InputFilter
                                placeholder='Max'
                                name="unit_value_max"
                                disabled={!filter.range_unit}
                                value={filter.unit_value_max}
                                onChange={val => {
                                    regExpForFloatFive(val, data => this.handleChangeFilter("unit_value_max", data), true)
                                }}
                            />
                        </div>
                        {showSearchButton &&
                        (<div>
                            <span className="search-btn" onClick={this.applyFilters}>{"Search"}</span>
                        </div>)}

                    </div>)}
            </>);
    }
}

export default Range;