import _ from "lodash";

export const columns = {
    plant_mnemonic: "Plant Mnemonic",
    production_plant: "Product Plant",
    city: "City",
    plant_state: "Plant State/Province",
    source_plant: "Source Plant",
    source_plant_county: "Source Plant Country",
    source_plant_region: "Source Plant Region",
};

export const getFormatValue = (item, key) => {
    const value = _.get(item, key, "");

    switch (key) {
        default:
            return value;
    }
};
