import React from 'react';
import { compose } from 'redux';
import { withTagDefaultProps } from 'Hoc/Template';
import { withServiceConsumer } from 'Services/Context';
import { TabWrap } from 'Templates/Content';

const TabViewDetailWrap = TabTable => ({
    pref,
    items,
    pagination,
    loading,
    fetchItems,
    fetchPage,
    request,
    service,
    activeTab,
    activeCompareTab,
}) => {
    const { handleScroll } = service;

    if (items === undefined) {
        if (!loading) {
            fetchItems({
                activeTab,
                activeCompareTab
            });
        }
        return "";
    }

    const onScroll = e => {

    };

    return (
        <TabWrap>
            <div
                className={`${pref}__wrap-body main-wrap-block__body main-wrap-block__body_height`}
                onScroll={onScroll}
            >
                <TabTable />
            </div>
        </TabWrap>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        activeTab: getStoreItem(state, "activeTab"),
        activeCompareTab: getStoreItem(state, "activeCompareTab"),
        items: getStoreItem(state, "items"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        loading: getStoreItem(state, "loading"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export const customTable = CustomTable =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
        TabViewDetailWrap,
    )(CustomTable);
