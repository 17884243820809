import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row, Column } from "Templates/Table/NewVersion";

const TableHeadColumnDefault = ({ service: { columns, getHeadView } }) => {
    return (
        <Row>
            {columns.map((column, index) => (
                <Column key={`Head-${index}`}>{getHeadView(column)}</Column>
            ))}
        </Row>
    );
};

TableHeadColumnDefault.propTypes = {
    service: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
        getHeadView: PropTypes.func.isRequired,
    }).isRequired,
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableHeadColumnDefault);
