import React, { Fragment } from "react";
import { formatDate, getString, numberWithCommas} from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import {getJson} from "Services/StringService";
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const items = getJson(item, 'lines');

    switch (key) {
        case "product_code":
        case "application_code":
        case "material_number":
        case "order_country":
        case "order_region":
        case "line_number":
        case "sales_unit":
        case "business_area":
        case "gross_weight_unit":
        case "normalized_weight_unit":
        case "order_number":
        case "order_line_number":
        case "reference_invoice_number":
        case "reference_invoice_line_number":
        case "cancelled":
        case "free_of_charge":
            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => (
                                <Fragment key={`Fragment_${index}`}>
                                    <TableRowItemSpan
                                        key={`${index}.${getString(elem, key)}`}
                                        title={getString(elem, key)}
                                    >
                                        {getString(elem, key)}
                                    </TableRowItemSpan>
                                </Fragment>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "freight_amount":
        case "net_amount":
        case "cost":
        case "fixed_cost":
        case "variable_cost":
        case "gross_margin":
        case "price_kg":
        case "cost_kg":
        case "surcharge_zb00":
        case "surcharge_zb04":
        case "surcharge_zb05":
        case "surcharge_zd00":
            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => (
                                <Fragment key={`Fragment_${index}`}>
                                    <TableRowItemSpan
                                        className="text-right"
                                        key={`${index}.${getString(elem, key)}`}
                                        title={getString(elem, key)}
                                    >
                                        {`$${numberWithCommas(getString(elem, key), 2)}`}
                                    </TableRowItemSpan>
                                </Fragment>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "quantity":
        case "gross_weight":
        case "normalized_weight":
            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => (
                                <Fragment key={`Fragment_${index}`}>
                                    <TableRowItemSpan
                                        className="text-right"
                                        key={`${index}.${getString(elem, key)}`}
                                        title={getString(elem, key)}
                                    >
                                        {`${numberWithCommas(getString(elem, key), 3)}`}
                                    </TableRowItemSpan>
                                </Fragment>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "gross_margin_percent":
            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => (
                                <Fragment key={`Fragment_${index}`}>
                                    <TableRowItemSpan
                                        className="text-right"
                                        key={`${index}.${getString(elem, key)}`}
                                        title={getString(elem, key)}
                                    >
                                        {`${numberWithCommas(getString(elem, key), 2)} %`}
                                    </TableRowItemSpan>
                                </Fragment>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "grade":
            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => (
                                _.get(elem, key)
                                    ?
                                    <Fragment key={`Fragment_${index}`}>
                                        <TableRowItemLink
                                            blank
                                            key={`${index}.${getString(elem, 'grade_id')}`}
                                            title={getString(elem, key)}
                                            href={getString(elem, 'is_grade') ? `/product/product-finder/${getString(elem, 'grade_id')}` : `/product/raw/${getString(elem, 'grade_id')}`}
                                        >
                                            {getString(elem, key)}
                                        </TableRowItemLink>
                                    </Fragment>
                                    : <TableRowItemSpan key={`${index}`} title={''}>
                                        {'-'}
                                    </TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "plant":
            return (
                items.length
                    ? (
                        <>
                            {items.map((elem, index) => (
                                _.get(elem, key)
                                    ?
                                    <Fragment key={`Fragment_${index}`}>
                                        <TableRowItemLink
                                            blank
                                            key={`${index}.${getString(elem, 'plant_id')}`}
                                            title={getString(elem, key)}
                                            href={`/product/plants/${getString(elem, 'plant_id')}`}
                                        >
                                            {getString(elem, key)}
                                        </TableRowItemLink>
                                    </Fragment>
                                    : <TableRowItemSpan key={`${index}`} title={''}>
                                    {'-'}
                                </TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "invoice_number":
            return <TableRowItemLink
                key={`${getString(item, key)}`}
                title={getString(item, key)}
                href={`/invoice/invoices/${getString(item, key)}`}
            >
                {getString(item, key)}
            </TableRowItemLink>;

        case "customer":
        case "customer_full":
            return _.get(item, key)
                ? <TableRowItemLink
                    blank
                    key={`${getString(item, 'customer_id')}`}
                    title={getString(item, key)}
                    href={`/companies/${getString(item, 'customer_id')}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
                : <TableRowItemSpan key={`${key}`} title={''}>
                    {'-'}
                </TableRowItemSpan>;

        case "billing_date": {
            const elem = getString(item, key);
            const formatValue = elem === '-' ? elem : formatDate(elem);

            return (
                <TableRowItemSpan title={formatValue}>
                    {formatValue}
                </TableRowItemSpan>
            );
        }

        case "created_on": {
            const elem = getString(item, key);
            const formatValue = elem === '-' ? elem : formatDate(elem, "MMM DD, YYYY h:mm A");

            return (
                <TableRowItemSpan title={formatValue}>
                    {formatValue}
                </TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
