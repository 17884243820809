import * as main from "../../../Services";

export default {
    ...main,
    save: publicationId => main.requestCreate(`/admin/publications/${publicationId}/designs/add`),
    saveColor: main.requestCreate(`/product/product-finder/color/add`),
    saveContentTemplate: main.requestCreate(`/product/product-finder/content-template/add`),
    editContentTemplate: templateId => main.requestCreate(`/product/product-finder/content-template/${templateId}/edit`),
    requestLegal: main.requestData(),
    requestContentTemplate: main.requestData(),
    getStoreItem: main.getStoreItem("createState"),
};
