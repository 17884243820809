import React, {Component, Fragment} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {LinkIcon} from "Templates/Link";
import {Block, BlockWrap, Input, WrapInput} from "Templates/Form";
import {Button} from "Templates/Default";
import _ from "lodash";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import arrayMove from "array-move";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    suppliers: PropTypes.arrayOf(PropTypes.any).isRequired
};

const DragHandle = SortableHandle(() => <span className="table-settings__style__handle"> </span>);

const SortableItem = SortableElement(({key, val, ind, values, onChangeArray, handleMenuAction, onDeleteArray}) => {

    return (
        <li className="table-settings-li marketing-table-settings-li__wrap">
            <div className="table-settings-li__wrap">
                <DragHandle/>
                <Fragment key={`Input-${key}`}>
                    <div
                        className="other-input_suppliers"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            type="asyncSelect"
                            name={`supplier-${ind}`}
                            url="/admin/list/publication-logos/list"
                            value={val.value}
                            placeholder="Select"
                            onChange={(value) => {
                                onChangeArray(ind, {frontId: val.frontId, value}, "suppliers", values)
                            }}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    {values.length > 1 && (
                        <LinkIcon
                            className="icon-delete"
                            icon="la-icon-close"
                            onClick={() => {
                                onDeleteArray(val.frontId, "suppliers", values)
                            }}
                        />
                    )}
                </Fragment>
            </div>
        </li>
    );
});


const SortableList = SortableContainer(({
                                            isDisabled,
                                            values,
                                            onChange,
                                            onChangeArray,
                                            onDeleteArray,
                                            onHandleMenuAction
                                        }) => {
    return (
        <ul>
            {values.map((value, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    ind={index}
                    val={value}
                    values={values}
                    disabled={isDisabled}
                    handleMenuAction={onHandleMenuAction}
                    onChange={onChange}
                    onChangeArray={onChangeArray}
                    onDeleteArray={onDeleteArray}
                />
            ))}
        </ul>
    );
});


class TdsHeader extends Component {
    state = {
        isDisabled: false
    }

    render() {
        const {
            suppliers,
            onChange
        } = this.props;

        const {isDisabled} = this.state

        const onSortEnd = ({oldIndex, newIndex}) => {
            onChange("suppliers", arrayMove(suppliers, oldIndex, newIndex));
        };

        const onDeleteArray = (frontId, key, array) => {
            onChange(key, array.filter(elem => elem.frontId !== frontId));
        };

        const _onAddArray = (lastFrontId, key, array) => {
            onChange(key, [...array, {frontId: lastFrontId + 1, value: ""}]);
        };

        const onChangeArray = (index, value, key, array) => {
            _.set(array, index, value);

            onChange(key, _.uniqBy(_.concat(array), v => v.value));
        };

        const onHandleMenuAction = (e) => {
            this.setState({
                isDisabled: e
            })
        }

        return (
            <Block>
                <BlockWrap>
                    <WrapInput className="wrap-input--delete" label="Secondary" name="suppliers">
                        <SortableList
                            isDisabled={isDisabled}
                            values={suppliers}
                            distance={1}
                            axis="y"
                            lockAxis="y"
                            onChange={onChange}
                            onChangeArray={onChangeArray}
                            onDeleteArray={onDeleteArray}
                            onSortEnd={onSortEnd}
                            onHandleMenuAction={onHandleMenuAction}
                        />
                        <Button
                            onClick={
                                () => _onAddArray(_.maxBy(suppliers, s => s.frontId).frontId, "suppliers", suppliers)
                            }
                            className="size_normal main-btn tds_header_suppliers_btn main-btn_white"
                        >
                            Add Secondary Logo
                        </Button>
                    </WrapInput>
                </BlockWrap>
            </Block>
        );
    }
}

TdsHeader.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        suppliers: getStoreItem(state, "suppliers")
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TdsHeader);
