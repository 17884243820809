import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";

import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {MainButton} from "Templates/Content";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    handleSave: PropTypes.func.isRequired,
};

const SaveTableFilters = ({request, handleSave, t}) => {
    return (
        !_.isEmpty(request.filter) || !_.isEmpty(request.sort) ? (
            <MainButton
                className="main-btn__general main-btn_white save-filters-btn"
                onClick={handleSave}
            >
                {t("Save Filter")}
            </MainButton>
        ) : ''
    );
};

SaveTableFilters.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(SaveTableFilters);