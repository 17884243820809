import { request, url } from "Services";
import _ from "lodash";

export const requestOnUploadFiles = link => data => {
    const formData = new FormData();
    for (let i = 0; i < data.files.length; i += 1) {
        formData.append("files[]", data.files[i]);
    }
    formData.append("name", _.trim(data.name));
    const sendObj = {
        url: url.getUrl(link),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const getItem = async ( listUrl ) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        type: "GET",
    };

    return await request.sendRequest(sendObj)
};

export const requestOnSaveCopy = link => data => {
    const formData = new FormData();

    for (let i = 0; i < data.product.length; i += 1) {
        formData.append("products[]", data.product[i]);
    }

    const sendObj = {
        url: url.getUrl(link),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

/**
 * Get download url for PDF report
 * @param {string} brochureId - selected datasheet ID
 * @param {'normal' | 'extended'} type - type of report. Could be "normal" or "extended"
 * @returns {string | null} - Url or "null" if datasheetId weren't provided
 */
export const getUploadUrl = (brochureId, type = 'normal') => {
    if (!brochureId) {
        return null;
    }
    return type === 'extended' ?
        `/api/product/publications/brochure/${brochureId}/upload-extended` :
        `/api/product/publications/brochure/${brochureId}/upload`;
};