import _ from "lodash";
import Constants from "../../../Constants";

export const initState = {
    filter: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FILTER:
            const {
                modal: { filter },
            } = state;
            if (_.trim(data.value)) {
                filter[data.key] = data.value;
            } else {
                _.unset(filter, data.key);
            }
            return { ...state, modal: { ...state.modal, filter } };

        case Constants.TABLE_UPDATE:
            return {
                ...state,
                modal: { ...state.modal, items: data.items, pagination: data.pagination },
            };

        default:
            return state;
    }
};
