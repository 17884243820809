import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";

const propTypes = {
    name: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    segment: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired,
};

const InfoWrap = ({
    name,
    industry,
    segment,
    t,
    onClickEdit,
}) => {
    return (
        <ContentInfoWrap>
            <ContentInfoTop onClickEdit={onClickEdit} urlBack="/admin/list/application-codes" />
            <ContentInfoBlock>
                <ContentInfoTitle>{name}</ContentInfoTitle>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!industry}>
                <ContentInfoBlockTitle>{t("Market Segment")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={industry} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!segment}>
                <ContentInfoBlockTitle>{t("Segment")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={segment} />
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "view.name"),
        industry: getStoreItem(state, "view.industry"),
        segment: getStoreItem(state, "view.segment"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
