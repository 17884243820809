import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {weightUnitName} from "Services/weightUnitName";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    item: {},
};

const TableBodyColumn = ({item, weightUnit}) => {
    return (
        <tr>
            <td className="project-expanded-block__table">
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Part Weight</p>
                    <p className="project-expanded-block__table-value" title={`
                            ${item.part_weight} 
                            ${(weightUnitName(weightUnit.weightUnit))}
                    `}>
                        {item.part_weight ? <>
                            {item.part_weight}
                            {' '}
                            {(weightUnitName(weightUnit.weightUnit))}
                        </> : '0.00'}
                    </p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">No. of Parts/Unit</p>
                    <p className="project-expanded-block__table-value" title={item.parts_per_unit ? item.parts_per_unit.toFixed(2) : '0.00'}>{item.parts_per_unit ? item.parts_per_unit.toFixed(2) : '0.00'}</p>
                </div>
            </td>
        </tr>
    );
};

TableBodyColumn.propTypes = propTypes;

TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
