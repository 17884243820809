import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { formatDate } from "Services";
import {
    SingleContent,
    SingleColFirst,
    SinglePreviewRow,
    SinglePreviewCol,
    SinglePreview
} from "Templates/SinglePreview";
import TableRowItemLink from "Templates/Table/TableRowItemLink";

const propTypes = {
    date: PropTypes.string.isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    owner: PropTypes.objectOf(PropTypes.any).isRequired,
    status: PropTypes.string.isRequired,
    imported_count: PropTypes.number.isRequired,
    updated_count: PropTypes.number.isRequired,
    error_message: PropTypes.string.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

const InfoWrap = ({ date, user, owner, status, imported_count, updated_count, error_message, t }) => {
    return (
        <SingleContent>
            <SingleColFirst>
                <SinglePreview>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Last Modified")}</SinglePreviewCol>
                        <SinglePreviewCol>{formatDate(date)}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Modified By")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <TableRowItemLink blank title={user.name} href={`/admin/users/${user.id}`}>
                                {user.name}
                            </TableRowItemLink>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Supplier")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <TableRowItemLink blank title={owner.name} href={`/admin/companies/${owner.id}`}>
                                {owner.name}
                            </TableRowItemLink>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Status")}</SinglePreviewCol>
                        <SinglePreviewCol>{status}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Number of Imported Projects")}</SinglePreviewCol>
                        <SinglePreviewCol>{imported_count}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Number of Updated Projects")}</SinglePreviewCol>
                        <SinglePreviewCol>{updated_count}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Error Message")}</SinglePreviewCol>
                        <SinglePreviewCol>{error_message || '-'}</SinglePreviewCol>
                    </SinglePreviewRow>
                </SinglePreview>
            </SingleColFirst>
        </SingleContent>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        date: getStoreItem(state, "view.date"),
        owner: getStoreItem(state, "view.owner"),
        user: getStoreItem(state, "view.user"),
        status: getStoreItem(state, "view.status"),
        imported_count: getStoreItem(state, "view.imported_count"),
        updated_count: getStoreItem(state, "view.updated_count"),
        error_message: getStoreItem(state, "view.error_message"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(InfoWrap);
