import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas, url} from "Services";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Title, Total, WrapButtons} from "Templates/Titles";
import {MainButtonLink, TabBlockBtn} from "Templates/Content";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {Modal} from "Templates/Modals";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import TabCheck from "Templates/Tabs/TabСheck";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";
import TableHeadColumnWrap from "./TableHeadColumnWrap";
import ConnectionContainerTable from "./ConnectionContainerTable";
import TableSettingsView from "./TableSettings/TableSettingsView";
import ConnectionsTab from "./ConnectionsTab";
import BudgetsTab from "./BudgetsTab";
import BudgetTableSettingsView from "./TableSettings/BudgetTableSettingsView";
import ConnectionBudgetContainerTable from "./ConnectionBudgetContainerTable";
import BudgetTableHeadColumnWrap from "./BudgetTableHeadColumnWrap";
import BudgetTableRowColumn from "./TableRowColumn/BudgetTableRowColumn/TableRowColumnWrap";
import {InputFilter} from "Templates/Table/Filters";
import {onChangeFinancialYear} from "../Actions/TabActions";
import connectionService from "../Services/Tabs/Connections";
import budgetService from "../Services/Tabs/Budgets";

const defaultProps = {
    help: '',
    sandboxData: {}
}

const propTypes = {
    onChangeTab: PropTypes.func.isRequired,
    onChangeFinancialYear: PropTypes.func.isRequired,
    activeTab: PropTypes.number.isRequired,
    financialYear: PropTypes.any.isRequired,
    realFinancialYear: PropTypes.any.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    getUrlExport: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickBudgetCustom: PropTypes.func.isRequired,
    onClickBudgetEdit: PropTypes.func.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    sandboxData: PropTypes.any,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
};

const DefaultTabTable = ConnectionContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const DefaultBudgetTabTable = ConnectionBudgetContainerTable(BudgetTableHeadColumnWrap, BudgetTableRowColumn);
const BudgetTabViewDetail = customTable(DefaultBudgetTabTable);

const TabView = (
    {
        onChangeTab,
        onChangeFinancialYear,
        activeTab,
        financialYear,
        fetchItems,
        fetchBudgetItems,
        realFinancialYear,
        columns,
        activeColumns,
        onClickEdit,
        onClickCustom,
        onClickBudgetCustom,
        onClickBudgetEdit,
        t,
        title,
        help,
        sandboxData,
        request,
        requestChangeSettings,
        requestBudgetsChangeSettings,
        getUrlExport,
        currentFY,
    }) => {
    const [list, setList] = useState([])
    const [showEditColumnsModal, setShowEditColumnsModal] = useState(false)

    const handleExportConnectionsClick = (e) => {
        e.preventDefault();
        getUrlExport(url.getUrl('/connection/connections/export', request));
    }

    const handleExportConnectionsBudgetClick = (e) => {
        e.preventDefault();
        getUrlExport(url.getUrl('/connection/budgets/export', request));
    }

    const handleCloseEditColumnsModalWithCheck = e => {
        e.preventDefault();
        setShowEditColumnsModal(false);
    };

    const handleSaveModalWithCheck = (columnList, defaultColumns, pinnedColumns, idTemplate, noReload) => {
        requestChangeSettings({
            'templateId': idTemplate,
            'columns': columnList,
            'defaultColumns': defaultColumns,
            'pinnedColumns': pinnedColumns
        }).then(
            () => {
                if (noReload) return
                fetchItems(request);
            },
            errors => {
                alert(errors.detail);
            },
        );
        if (!noReload) {
            setShowEditColumnsModal(false);
        }
    };

    const handleSaveBudgetModalWithCheck = (columnList, defaultColumns, pinnedColumns, idTemplate, noReload) => {
        requestBudgetsChangeSettings({
            'templateId': idTemplate,
            'columns': columnList,
            'defaultColumns': defaultColumns,
            'pinnedColumns': pinnedColumns
        }).then(
            () => {
                if (noReload) return
                fetchBudgetItems(request);
            },
            errors => {
                alert(errors.detail);
            },
        );
        if (!noReload) {
            setShowEditColumnsModal(false);
        }
    };

    const openEditColumnsModal = () => {
        setShowEditColumnsModal(true);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (activeTab === 1) {
                const urlObject = new URL(window.location.href);
                const {searchParams} = urlObject;
                if (searchParams.toString().includes('financial_year')) {
                    searchParams.delete(`financial_year`)
                    window.location.href = `${urlObject.origin}${urlObject.pathname}?activeTab=1`;
                }
            }
        }

        if (!sandboxData.connection_id) return
        const index = list.findIndex((item) => item.connection_id === sandboxData.connection_id)
        if (index !== -1) {
            const updatedArray = [...list]
            updatedArray[index] = sandboxData

            setList(updatedArray);
        } else {
            setList([...list, sandboxData]);
        }
    }, [sandboxData])

    financialYear = parseInt(financialYear);
    realFinancialYear = parseInt(realFinancialYear);

    return (
        <>
            {activeTab === 0 ?  <Title
                title="Connections"
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                {showEditColumnsModal ? (
                    <Modal onRequestClose={handleCloseEditColumnsModalWithCheck}>
                        <TableSettingsView
                            columns={columns}
                            currentFY={currentFY}
                            activeColumns={activeColumns}
                            onAppend={handleSaveModalWithCheck}
                            onClose={handleCloseEditColumnsModalWithCheck}
                        />
                    </Modal>
                ) : <div/>}
                <WrapButtons>
                    <div className="connection-view-change-real_year">
                        <div className="label-name">FY:</div>
                        <InputFilter
                            type="select"
                            options={[
                                {
                                    value: realFinancialYear-1,
                                    label: realFinancialYear-1,
                                },
                                {
                                    value: realFinancialYear,
                                    label: realFinancialYear,
                                },
                                {
                                    value: realFinancialYear+1,
                                    label: realFinancialYear+1,
                                },
                            ]}
                            value={financialYear}
                            onChange={e => {
                                onChangeFinancialYear(e);
                                request.financial_year = e;
                                fetchItems(request);
                            }}
                        />
                    </div>
                    <TabBlockBtn onClick={openEditColumnsModal} className="main-btn_white">
                        {t("Edit Column")}
                    </TabBlockBtn>
                    <CheckPermission permission="connection_export">
                        <MainButtonLink
                            disabled
                            className="main-btn_white"
                            onClick={(e) => handleExportConnectionsClick(e)}
                        >
                            {t("Export Connections")}
                        </MainButtonLink>
                    </CheckPermission>
                    <CheckPermission permission="connection_new_button">
                        <MainButtonLink href="/connection/connections/create" className="main-btn_primary">
                            {t("Add New Connection")}
                        </MainButtonLink>
                    </CheckPermission>
                </WrapButtons>
            </Title> : null}
            {activeTab === 1 ?  <Title
                title="Budget"
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                {showEditColumnsModal ? (
                    <Modal onRequestClose={handleCloseEditColumnsModalWithCheck}>
                        <BudgetTableSettingsView
                            columns={columns}
                            currentFY={currentFY}
                            activeColumns={activeColumns}
                            onAppend={handleSaveBudgetModalWithCheck}
                            onClose={handleCloseEditColumnsModalWithCheck}
                        />
                    </Modal>
                ) : <div/>}
                <WrapButtons>
                    <TabBlockBtn onClick={openEditColumnsModal} className="main-btn_white">
                        {t("Edit Column")}
                    </TabBlockBtn>
                    <CheckPermission permission="connection_budget_export">
                        <MainButtonLink
                            disabled
                            className="main-btn_white"
                            onClick={(e) => handleExportConnectionsBudgetClick(e)}
                        >
                            {t("Export Budgets")}
                        </MainButtonLink>
                    </CheckPermission>
                    <CheckPermission permission="connection_budget_new_button">
                        <MainButtonLink href="/connection/budgets/create" className="main-btn_primary">
                            {t("Add New Budget")}
                        </MainButtonLink>
                    </CheckPermission>
                </WrapButtons>
            </Title> : null}
            <Tabs
                activeTab={activeTab}
                onClickTabItem={index => {
                    if (activeTab !== index) {
                        onChangeTab(index);
                    }
                }}
            >
                <Tab label={t("Connections")}>
                    <ConnectionsTab>
                        <TabViewDetail
                            customIcon="icon_external_link"
                            onClickCustom={onClickCustom}
                            onClickEdit={onClickEdit}
                        />

                    </ConnectionsTab>
                </Tab>
                <TabCheck
                    label={t("Budget")}
                    permission="connection_budget_view"
                >
                    <BudgetsTab>
                        <BudgetTabViewDetail
                            customIcon="icon_external_link"
                            onClickCustom={onClickBudgetCustom}
                            onClickEdit={onClickBudgetEdit}
                        />
                    </BudgetsTab>
                </TabCheck>
                <Tab label=''/>
            </Tabs>
        </>
    );
};

TabView.propTypes = propTypes;
TabView.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        activeTab: getStoreItem(state, "activeTab"),
        financialYear: getStoreItem(state, "financialYear"),
        realFinancialYear: getStoreItem(state, "realFinancialYear"),
        columns: getStoreItem(state, "columns"),
        activeColumns: getStoreItem(state, "activeColumns"),
        title: getStoreItem(state, "pagination.total"),
        help: getStoreItem(state, "help"),
        sandboxData: getStoreItem(state, "sandboxData"),
        currentFY: getStoreItem(state, "financialYear"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeFilter: getActionStore("onChange", service, dispatch),
        fetchItems: getActionStore("fetchItems", connectionService, dispatch),
        fetchBudgetItems: getActionStore("fetchItems", budgetService, dispatch),
        getUrlExport: getActionStore("getUrlExport", service, dispatch),
        requestChangeSettings: service.requestChangeSettings,
        requestBudgetsChangeSettings: service.requestBudgetsChangeSettings,
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        onChangeFinancialYear: getActionStore("onChangeFinancialYear", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
