import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {formatDateToLocal, numberWithCommas} from "Services";
import {withServiceConsumer} from "Services/Context";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {TabBlock, TabBlockCol, TabBlockColTitle, TabBlockColTitleH4, TabWrap, TabWrapTop} from "Templates/Content";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import ContainerTable from "../../Table/ContainerTable";
import TableHeadTitle from "../../Table/TableHeadTitle";
import TableRowColumn from "../../Table/TableRowColumn";

const TableStructure = ContainerTable(TableHeadTitle, TableRowColumn);

const TabViewDetail = customTable(TableStructure);

const propTypes = {
    loginsMo: PropTypes.number.isRequired,
    projects: PropTypes.number.isRequired,
    sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
    revenue: PropTypes.string.isRequired,
    volume: PropTypes.string.isRequired,
    grossMargin: PropTypes.string.isRequired,
    currentFY: PropTypes.string.isRequired,
};

const Index = ({loginsMo, projects, sessions, revenue, volume, grossMargin, currentFY, t}) => {
    return (
        <>
            <TabWrapTop>
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Logins/Mo")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{numberWithCommas(loginsMo, 0)}</TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{t("Projects")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{numberWithCommas(projects, 0)}</TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{`FY ${currentFY} ${t("Current Revenue")}`}</TabBlockColTitle>
                        <TabBlockColTitleH4 className="clr-green">
                            {`$${numberWithCommas(revenue, 0)}`}
                        </TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{`FY ${currentFY} ${t("Current Volume")}`}</TabBlockColTitle>
                        <TabBlockColTitleH4 className="clr-red">
                            {`${numberWithCommas(volume, 0)} lbs`}
                        </TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{`FY ${currentFY} ${t("Current Gross Margin")}`}</TabBlockColTitle>
                        <TabBlockColTitleH4 className="clr-primary">{`${grossMargin}%`}</TabBlockColTitleH4>
                    </TabBlockCol>
                </TabBlock>
            </TabWrapTop>

            {/*<TabViewDetail/>*/}

            <CheckPermission permission='admin'>
                <TabWrap>
                    <div className="main-wrap-block__body main-wrap-block__body_height">
                        <table className="main-table">
                            <tbody>
                            <tr>
                                {['IP', 'Time', 'Location'].map((column, index) => (
                                    <th key={`SessionTableHeadColumn-${index}`} className="main-table__head">
                                        <span className='main-table__head-link'>{column}</span>
                                    </th>
                                ))}
                            </tr>
                            {sessions.map((session, index) => (
                                <tr key={`SessionTableRow-${index}`}>
                                    <td className="main-table__body">
                                        <div className="main-table__body-row">{session.ip_address}</div>
                                    </td>
                                    <td className="main-table__body">
                                        <div className="main-table__body-row">
                                            {formatDateToLocal(session.date, "MMM DD, YYYY h:mm A")}
                                        </div>
                                    </td>
                                    <td className="main-table__body">
                                        <div className="main-table__body-row">
                                            {session.country ? `${session.country}, ` : ""}
                                            {session.city ? `${session.city}` : ""}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </TabWrap>
            </CheckPermission>
        </>
    );
};

Index.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;
    return {
        loginsMo: getStoreItem(state, "view.additionalData.login_count") || 0,
        projects: getStoreItem(state, "view.additionalData.projects") || 0,
        sessions: getStoreItem(state, "view.sessions") || [],
        revenue: getStoreItem(state, "view.additionalData.revenue") || "0",
        volume: getStoreItem(state, "view.additionalData.volume") || "0",
        grossMargin: getStoreItem(state, "view.additionalData.gross_margin") || "0",
        currentFY: getStoreItem(state, "financialYear"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Index);
