import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable } from "../../RequestService";
import { requestOnUploadFiles } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/product/product-finder/${id}/files`),
    requestTableProductFiles: requestTable(`/product/product-finder/${id}/files/products`),
    requestOnUploadFiles: requestOnUploadFiles(`/product/product-finder/${id}/files/add`),
    onClickDeleteItem: item => deleteItem(`/product/product-finder/${id}/files/${item.id}/delete`),
    getViewItem,
    getFilter,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
