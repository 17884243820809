import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {BlockWrap, WrapInput} from "Templates/Form";
import CheckboxBlock from "Templates/Form/Checkbox/CheckboxBlock";
import CheckboxButton from "Templates/Form/Checkbox/CheckboxButton";
import CheckboxTitle from "Templates/Form/Checkbox/CheckboxTitle";
import ComoppsHelpPagesEditor from "Templates/Input/HelpPagesEditor";

const propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    publish: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

const MainBlock = ({name, description, publish, onChange, t}) => {
    return (
        <BlockWrap>
            <h2 className="help-form__title main-content__form-title">{t("Name")}</h2>
            <span className="help-form__label main-content__label gray-text">{name}</span>
            <WrapInput label={t("Copy")} name="description" isRequired>
                <ComoppsHelpPagesEditor text={description} onChange={value => onChange("description", value)}/>
            </WrapInput>
            <WrapInput name="publish">
                <CheckboxBlock>
                    <CheckboxButton
                        htmlFor="checkbox_publish"
                        value={!!publish}
                        onChange={value => onChange("publish", value)}
                    >
                        <CheckboxTitle title={t("Publish")} />
                    </CheckboxButton>
                </CheckboxBlock>
            </WrapInput>
        </BlockWrap>
    );
};

MainBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        description: getStoreItem(state, "description"),
        publish: getStoreItem(state, "publish"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MainBlock);
