import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import Img from "Templates/Img/Img";

const propTypes = {
    user: PropTypes.shape({
        companyName: PropTypes.string.isRequired,
    }).isRequired,
};

const McgInfo = ({user}) => {

    return <div className="mcg">
        <Img img="mcg_icon"/>
        <p title={user.companyName}>{user.companyName}</p>
    </div>
};

McgInfo.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        user: state.userState,
    };
};

export default compose(withTagDefaultProps(mapStateToProps))(McgInfo);
