import React from "react";
import Img from "Templates/Img/Img";
import {ButtonClose} from "Templates/Button";
import PropTypes from "prop-types";

const stepList = [
   'Property',
   'Test Method',
   'Specimen',
   'Test Condition',
   'SI Unit',
   'English Unit',
]

function HelpPage({ onClose }) {
    return (
        <div className="help-page-modal">
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <div className="help-page main-modal__form ">
            <h1>TDS Hierarchy</h1>

            <p>
                The TDS Hierarchy module is used to build hierarchical search categories.
                NOTE: Only data entered in the TDS Data module will appear here is the TDS Hierarchy module.
            </p>
            <h2>Add New TDS Hierarchy</h2>

            <div className="help-step">
                <span>Step 1</span>
                <p>
                    To begin a hierarchy, click
                    <button type="button" className="size_normal main-btn main-btn_white">
                        Add Another Property
                    </button>
                    <br />
                    Next, click
                    <button type="button" className="cost-modal__calculator_btn ">Show More</button>
                </p>
                <Img img="heir_help_step1" alt="tdsData" />
            </div>

            {stepList.map((name, index) => (
                <div key={name} className="help-step">
                    <span>Step {index + 2}</span>
                    <p>
                        Choose the desired {name} from the drop-down menu. Alternatively, you may
                        search for the {name} by typing the name of the {name} in the search field.
                    </p>
                    <Img img={`heir_help_step${index + 2}`} alt={`step${index + 2}`} />
                </div>
            ))}

            <div className="help-step">
                <span>Tips</span>
                <p>
                    To add more than one item to the drop-down menu, click on the Add Another button
                    directly underneath the desired TDS data category.
                    <br />
                    To delete an item, click
                </p>
            </div>

        </div>
        </div>
    );
}

HelpPage.defaultProps = {
    onClose: () => {},
};
HelpPage.propTypes = {
    onClose: PropTypes.func
};

export default HelpPage;