import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const defaultProps = {
    onClickItem: () => { },
};

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    column: PropTypes.string.isRequired,
    onClickItem: PropTypes.func
};

const TableRowColumnDefaultEvent = ({ column, item, service, onClickItem }) => {
    const { getViewItem } = service;
    return getViewItem(column, item, onClickItem);
};

TableRowColumnDefaultEvent.defaultProps = defaultProps;
TableRowColumnDefaultEvent.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableRowColumnDefaultEvent);
