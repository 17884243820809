import _configureStore from "App/Store";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { token, error } = props;
    const { mainState, appState } = initialStates;

    const initialState = {
        listState: { ...mainState, token, error },
        appState: { ...appState, pref: "forgot" },
    };

    return _configureStore(initialState, reducers);
}
