import React from "react";
import {compose} from "redux";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import InfoWrap from "./Views/InfoWrap";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import Users from "./Views/Users/Users";
import Contacts from "./Views/Contacts/Contacts";
import Publications from "./Views/Publications/Publications";
import {Col, Row} from "Templates/Form";

import UsersService from "./Services/Users";
import PublicationsService from "./Services/Publications";
import ContactsService from "./Services/Contacts";

const PublicationGroups = ({groupId, pref}) => {
    const usersService = UsersService(groupId);
    const publicationsService = PublicationsService(groupId);
    const contactsService = ContactsService(groupId);

    return <ContentWrap>
        <InfoWrap/>
        <ContentWrapCol>
            <Row>
                <Col className={`${pref}__users-col`}>
                    <ServiceProvider value={usersService}>
                        <Users/>
                    </ServiceProvider>

                </Col>
                <Col>
                    <ServiceProvider value={contactsService}>
                        <Contacts/>
                    </ServiceProvider>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ServiceProvider value={publicationsService}>
                        <Publications/>
                    </ServiceProvider>
                </Col>
            </Row>
        </ContentWrapCol>
    </ContentWrap>;
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        groupId: getStoreItem(state, "id"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};


const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(PublicationGroups);
