import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {TabWrap} from "Templates/Content";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {formatDate} from "Services/DateService";

import {Input, WrapInput} from "Templates/Form";
import {getCostItems} from "../../../Services/RequestService";
import {regExpForFloat, numberWithCommas} from "Services";
import Img from "Templates/Img/Img";

const propTypes = {
    service: PropTypes.shape({
        colorClass: PropTypes.func.isRequired,
    }).isRequired,
};

const CostModel = ({t, id, pref}) => {
    const [selectedPlant, setSelectedPlant] = useState('');
    const [costs, setCosts] = useState([])
    const [rawMaterials, setRawMaterials] = useState({
        rawMaterialCostsSap: 0,
        rawMaterialCostsMap: 0,
        lineCost: 0,
        cogsSap: 0,
        cogsMap: 0,
    })
    const [salePrice, setSalePrice] = useState(0)
    const [sgA, setSgA] = useState(0)

    useEffect(() => {
        if (selectedPlant) {
            getCostItems(`/product/product-finder/${id}/costs?plant=${selectedPlant}`).then((res) => {
                if (res[0] && res[0].cost && res[0].cost.items && res[0].cost.items.length > 0) {
                    setCosts(res[0].cost.items)
                    setRawMaterials({
                        rawMaterialCostsSap: res[0].cost.rawMaterialCostsSap || 0,
                        rawMaterialCostsMap: res[0].cost.rawMaterialCostsMap || 0,
                        lineCost: res[0].cost.lineCost || 0,
                        cogsSap: res[0].cost.cogsSap || 0,
                        cogsMap: res[0].cost.cogsMap || 0,
                    })
                }
            });
        }
    }, [selectedPlant])

    return (
        <div className="grade-table-wrap cost-model-table-wrap">
                <TabWrap>
            <div className={`${pref}__wrap-body main-wrap-block__body main-wrap-block__padding`}>
                <form className={`costs-form ${costs.length ? 'fill' : ''}`} action="/" onSubmit={e => e.preventDefault()}>
                    <div className="costs-wrap">
                        <div className="cost-modal__plant-select">
                            <WrapInput label={t("Plant")} name="plant">
                                <Input
                                    type="asyncSelect"
                                    placeholder={t("Search")}
                                    url={`/product/product-finder/${id}/costs/plants`}
                                    value={selectedPlant}
                                    onChange={value => {
                                        setSelectedPlant(value)
                                    }}
                                />
                            </WrapInput>
                        </div>
                        {costs.length > 0 ? <>
                            <div className="costs-head">
                                <p className="material">Material Code</p>
                                <p className="description">Description</p>
                                <p className="sap_rm_cost">SAP RM Cost</p>
                                <p className="rm_cost_update">RM Cost Update</p>
                                <p className="cost_by_map">Cost (by MAP)</p>
                                <p className="map_update">MAP Update</p>
                            </div>
                            <div className="costs-body">
                                {costs.map((cost, i) => {
                                    return <div key={`${cost.name}-${cost.id}-${i}`} className="costs-body__item">
                                        {cost.id ?
                                            <a href={cost.is_grade? `/product/product-finder/${cost.id}` : `/product/raw/${cost.id}`} className="material">{cost.name}</a>
                                            : <p className="material">{cost.name}</p>}
                                        <p className="description">{cost.description || '-'}</p>
                                        <p className="sap_rm_cost">{cost.sap_rm_cost ? `$${cost.sap_rm_cost}` : '-'}</p>
                                        <p className="rm_cost_update">{cost.rm_cost_update ? formatDate(cost.rm_cost_update) : '-'}</p>
                                        <p className="cost_by_map">{cost.cost_by_map ? `$${cost.cost_by_map}` : '-'}</p>
                                        <p className="map_update">{cost.map_update ? formatDate(cost.map_update) : '-'}</p>
                                    </div>
                                })}
                            </div>
                            <div className="costs-sum">
                                <div className="costs-sum__item">
                                    <p>Raw Material Costs</p>
                                    <p>${rawMaterials.rawMaterialCostsSap}</p>
                                    <p></p>
                                    <p>${rawMaterials.rawMaterialCostsMap}</p>
                                    <p></p>
                                </div>
                                <div className="costs-sum__item">
                                    <p>Line Cost</p>
                                    <p>${rawMaterials.lineCost}</p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>
                                <div className="costs-sum__item">
                                    <p>Cost of Goods Sold (COGS)</p>
                                    <p>${rawMaterials.cogsSap}</p>
                                    <p></p>
                                    <p>${rawMaterials.cogsMap}</p>
                                    <p></p>
                                </div>
                            </div>
                            <div className="costs-sum calculated">
                                <div className="costs-sum__item">
                                    <p>Sale Price</p>
                                    <p>
                                        <Input
                                            name="salePrice"
                                            value={salePrice}
                                            onChange={value => {
                                                regExpForFloat(value, data => setSalePrice(data))
                                            }}
                                        />
                                    </p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>
                                <div className="costs-sum__item">
                                    <p>Gross Margin</p>
                                    <p>${numberWithCommas(+salePrice - +rawMaterials.cogsSap, 2)}</p>
                                    <p className="green">{numberWithCommas((1 - (+rawMaterials.cogsSap / +salePrice)) * 100, 2)}%</p>
                                    <p>${numberWithCommas(+salePrice - +rawMaterials.cogsMap, 2)}</p>
                                    <p className="green">{numberWithCommas((1 - (+rawMaterials.cogsMap / +salePrice)) * 100, 2)}%</p>
                                </div>
                                <div className="costs-sum__item">
                                    <p>SG&A</p>
                                    <p>
                                        <Input
                                            name="salePrice"
                                            value={sgA}
                                            onChange={value => {
                                                regExpForFloat(value, data => setSgA(data))
                                            }}
                                        />
                                    </p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>
                                <div className="costs-sum__item">
                                    <p>Operating Margin</p>
                                    <p>${numberWithCommas(+salePrice - +rawMaterials.cogsSap - +sgA, 2)}</p>
                                    <p className="green">{numberWithCommas((1 - ((+rawMaterials.cogsSap + +sgA) / +salePrice)) * 100, 2)}%</p>
                                    <p>${numberWithCommas(+salePrice - +rawMaterials.cogsMap - +sgA, 2)}</p>
                                    <p className="green">{numberWithCommas((1 - ((+rawMaterials.cogsMap + +sgA) / +salePrice)) * 100, 2)}%</p>
                                </div>
                            </div>
                        </> : <div className="no-plant">
                            <div className="sheet-notInfo text-center">
                                <Img
                                    img="icon_files"
                                    width="100"
                                    height="100"
                                    alt="file"
                                />
                                <p className="text-center">To display table please select Plant.</p>
                            </div>
                        </div>}
                    </div>
                </form>
            </div>
        </TabWrap></div>
    );
}

CostModel.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        id: state.listState.view.id,
    };
};

const mapDispatchToProps = () => {
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(CostModel);
