import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        team: "Team Name",
        name: "User Name",
        title: "Title",
        company: "Organization",
        email: "Email",
        phone: "Phone number",
        logins: "Logins/Mo (30 days)",
        projects_added: "Projects Added",
        total_projects: "Total Projects",
    };
    return _.get(tableLabel, key, key);
};

export const typeList = [
    {
        value: "10",
        label: "NDA",
    },
    {
        value: "7",
        label: "Intellectual Property",
    },
    {
        value: "5",
        label: "Other",
    },
];

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        team: "Search",
        name: "Search",
        title: "Search",
        company: "Search",
        email: "Search",
        phone: "Search",
        logins: "Logins/Mo (30 days)",
        projects_added: "Projects Added",
        total_projects: "Total Projects",
    };
    switch (key) {
        default:
            return (
                <InputFilter
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
