import React, {Component} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {LinkIcon} from "Templates/Link";
import {Button, Li, Span, Ul} from "Templates/Default";
import {
    BlockShadow,
    BlockTitle,
    BlockWrap,
    Input,
    InputCount,
    BlockImgWithCrop,
    Label,
    Tooltip,
    TooltipHtml,
    WrapInput
} from "Templates/Form";
import CheckboxBlock from "Templates/Form/Checkbox/CheckboxBlock";
import CheckboxButton from "Templates/Form/Checkbox/CheckboxButton";
import CheckboxTitle from "Templates/Form/Checkbox/CheckboxTitle";
import _ from "lodash";
import {projectStatus} from "Services/enum";
import {showMessage} from "Services";
import ProjectVolumeBlock from "./ProjectVolumeBlock";
import DescriptionBlock from "./DescriptionBlock";
import ProductBlock from "./ProductBlock";
import ApplicationBlock from "./ApplicationBlock";
import ForecastBlock from "./ForecastBlock";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import ReplaceBusiness from "./ReplaceBusiness";

const defaultProps = {
    isEdit: false,
    losDate: ''
};

const propTypes = {
    isEdit: PropTypes.bool,
    project: PropTypes.string.isRequired,
    replacementBusinessProjectsBlock: PropTypes.objectOf(PropTypes.any).isRequired,
    // logo: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]).isRequired,
    logoView: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]).isRequired,
    customer: PropTypes.string.isRequired,
    dunsNumber: PropTypes.string.isRequired,
    customerOwner: PropTypes.string.isRequired,
    invoiceCount: PropTypes.number.isRequired,
    customerNickname: PropTypes.string.isRequired,
    // addedToSalesForecast: PropTypes.bool.isRequired,
    status: PropTypes.number.isRequired,
    stageGate: PropTypes.number.isRequired,
    // businessStatus: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    submitApplication: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    losDate: PropTypes.string,
    showFull: PropTypes.bool.isRequired,
    changeCustomer: PropTypes.func.isRequired,
};

const salesValue = [
    {
        order: 10,
        value: 20,
        label: "Lead",
        statusColor: "gray-bg",
        tooltip: `Very early stages of project assessment. Project has been outlined and the approach defined. 
            Key decision makers have been identified. Initial meetings with the client(s) have been held 
            and necessary information that is required to scope and qualify the project as a probable 'Target' has been completed. 
            Action Plans have been agreed upon by the project team and responsibilities/due dates have been assigned.`,
    },
    {
        order: 9,
        value: 50,
        label: "Opportunity",
        statusColor: "yellow-bg",
        tooltip: `Design/manufacture/cost/use issues addressed clearly showing the product is an excellent fit for the application. 
            Trials scheduled or underway. Data being collected to support our positions/hypotheses. 
            Strong support from the client to proceed and indications given that they will support the solution if it can be validated.`,
    },
    {
        order: 8,
        value: 80,
        label: "Target",
        statusColor: "selected",
        tooltip: `All major issues addressed. Key decision makers on board and fully supportive of the solution. 
            Formal proposals are being shaped into a final draft form. All hurdles have been identified and addressed.`,
    },
    { order: 7, value: 100, label: "Current", statusColor: "filled" },
    // { order: 11, value: 0, label: "On Hold", statusColor: "initial-bg" },
    { order: 6, value: 0, label: "Loss of Sale", statusColor: "initial-bg" },
    { order: 5, value: 0, label: "Out of Production", statusColor: "initial-bg" },
];

const stageGatesValue = [
    {
        order: 1,
        label: "Business Case",
        statusColor: "selected"
    },
    {
        order: 2,
        label: "Evaluation",
        statusColor: "selected"
    },
    {
        order: 3,
        label: "Design Validation",
        statusColor: "selected"
    },
    { order: 4, label: "Technical Validation", statusColor: "selected" },
    { order: 5, label: "Prepare Launch", statusColor: "selected" },
    { order: 6, label: "Commercialized", statusColor: "selected" },
];

class GeneralInfoBlock extends Component {
    state = {
        showApplicationModal: false,
        isReplaceBusiness: this.props.replacementBusinessProjectsBlock && this.props.replacementBusinessProjectsBlock.projects && this.props.replacementBusinessProjectsBlock.projects.length ? !!this.props.replacementBusinessProjectsBlock.projects.filter((item) => item.value !== '').length : false,
    };

    handleClickOnShowModal = name => () => {
        this.setState({
            [name]: true,
        });
    };

    handleCloseModal = () => {
        this.setState({
            showApplicationModal: false,
        });
    };

    submitApplication = data => {
        const { submitApplication } = this.props;
        return submitApplication(data);
    };

    onChangeLogo = (value, key) => {
        const { onChange } = this.props;

        onChange(key, value);
        onChange('generalBlock.logoView', value);
    };

    onChangeCustomer = (value, key) => {
        const { onChange, changeCustomer } = this.props;

        onChange(key, value);
        changeCustomer(value);
    };

    onAddCompany = (e) => {
        const { permissions } = this.props;
        if (!permissions.includes('admin')) {
            e.preventDefault();
            showMessage('Email support@comopps.com to add a new customer');
        }
    }

    render() {
        const { showApplicationModal } = this.state;
        const {
            isEdit,
            replacementBusinessProjectsBlock,
            project,
            logoView,
            customer,
            dunsNumber,
            customerOwner,
            invoiceCount,
            customerNickname,
            losDate,
            status,
            stageGate,
            showFull,
            onChange,
            t,
        } = this.props;
        const currentStatus = _.find(projectStatus, x => x.label === 'Current');
        const { isReplaceBusiness } = this.state;

        return (
            <>
                {isEdit || (replacementBusinessProjectsBlock && replacementBusinessProjectsBlock.projects && replacementBusinessProjectsBlock.projects.length > 0 && replacementBusinessProjectsBlock.projects.filter((item) => item.value !== '').length) ? <>
                    <BlockWrap className="replace-business-wrap">
                        <WrapInput label={t("")} name="replacementBusiness">
                            <CheckboxBlock>
                                <CheckboxButton
                                    htmlFor="checkbox_addedToSalesForecast"
                                    value={isReplaceBusiness}
                                    onChange={value => {
                                        this.setState({isReplaceBusiness: value})
                                        onChange("replacementBusinessProjectsBlock.projects", [{
                                            frontId: 0,
                                            value: ''
                                        }]);
                                    }}
                                >
                                    <CheckboxTitle title="Replacement Business - Select Project(s)"/>
                                </CheckboxButton>
                            </CheckboxBlock>
                        </WrapInput>
                    </BlockWrap>
                    {isReplaceBusiness ? <ReplaceBusiness/> : null}
                </> : null}
                <div className="project_wrap_general_image">
                    <div className="general-info-block">
                        <BlockTitle>{t("General Info ")}</BlockTitle>
                        <BlockShadow className="block-shadow-wrap">
                            <BlockWrap className="width_1-3">
                                <WrapInput isRequired isWidth label="Project Name" name="project">
                                    <Input
                                        value={project}
                                        placeholder={t("Project Name")}
                                        onChange={value => onChange("generalBlock.project", value)}
                                        inputProps={{maxLength: 90}}
                                    />
                                    <InputCount>{`${project.length}/90`}</InputCount>
                                </WrapInput>
                            </BlockWrap>
                            <BlockWrap className="width_1-3">
                                <WrapInput isRequired={showFull} label="Customer" name="customer">
                                    <Input
                                        type="asyncSelect"
                                        url="project/projects/customer-list-extra"
                                        value={customer}
                                        placeholder={t("Search")}
                                        onChange={value => {
                                            this.onChangeCustomer(value, "generalBlock.customer");
                                        }}
                                    />
                                    <LinkIcon blank url="/companies/create" onHandleClick={this.onAddCompany}>
                                        {t("Add New")}
                                    </LinkIcon>
                                </WrapInput>
                            </BlockWrap>
                            <BlockWrap className="width_1-3">
                                <div className={"projects-edit__wrap-input main-content__wrap-input  "}>
                                    <div className="title">
                                        <Label label='D-U-N-S&#174; Number'
                                               className={"projects-edit__label main-content__label"}/>
                                        <TooltipHtml>
                                            {t(
                                                `<div>D-U-N-S is a registered trademark D&B.</div>`
                                            )}
                                        </TooltipHtml>
                                    </div>
                                    <Span className="items-item project-duns-number">{dunsNumber || '-'}</Span>
                                </div>
                            </BlockWrap>
                            <BlockWrap className="width_1-3">
                                <div className={"projects-edit__wrap-input main-content__wrap-input  "}>
                                    <div className="title">
                                        <Label label='Invoices' className={"projects-edit__label main-content__label"}/>
                                    </div>
                                    <Span className="items-item project-duns-number">
                                        {customer &&
                                            <TableRowItemLink
                                                blank
                                                title={invoiceCount.toString()}
                                                href={`/companies/${customer}?activeTab=4`}
                                            >
                                                {invoiceCount}
                                            </TableRowItemLink>
                                        }
                                        {!customer && '-'}
                                    </Span>
                                </div>
                            </BlockWrap>
                            <BlockWrap className="width_1-3">
                                <div className={"projects-edit__wrap-input main-content__wrap-input  "}>
                                    <div className="title">
                                        <Label label='Customer Owner'
                                               className={"projects-edit__label main-content__label"}/>
                                    </div>
                                    <Span className="items-item project-duns-number">{customerOwner || '-'}</Span>
                                </div>
                            </BlockWrap>
                        </BlockShadow>
                    </div>
                    <div className="image-block">
                        <BlockTitle>
                            {t("Image")}
                            {' '}
                            <Tooltip>{t(`Recommended min image size 160 x 160px.`)}</Tooltip>
                        </BlockTitle>
                        <BlockShadow className="content_center">
                            <BlockImgWithCrop
                                name="logo"
                                value={logoView}
                                title=""
                                onChange={value => this.onChangeLogo(value, "generalBlock.logo")}
                            />
                        </BlockShadow>
                    </div>
                </div>

                <DescriptionBlock/>
                <ProductBlock/>
                <ApplicationBlock
                    handleClickOnShowModal={this.handleClickOnShowModal}
                    submitApplication={this.submitApplication}
                    showApplicationModal={showApplicationModal}
                    handleCloseModal={this.handleCloseModal}
                />
                <ForecastBlock/>
                <ProjectVolumeBlock/>
                <>
                    <BlockTitle>
                        <Span isRequired={showFull}>{t("Status")}</Span>
                    </BlockTitle>
                    <BlockShadow blockName="project_status">
                        <WrapInput label="Sales" name="status">
                        <Ul className="status">
                                {salesValue.map((elem, index) => (
                                    <Li key={`status-${index}`} className="status__item">
                                        <Button
                                            className={`status__button ${
                                                elem.order === status ? elem.statusColor : ""
                                            }`}
                                            onClick={() => {
                                                onChange("generalBlock.status", elem.order);
                                                // Set/unset “Add to Sales Forecast” checkbox IF Project status = “Current”
                                                onChange("generalBlock.addedToSalesForecast", elem.order === parseInt(currentStatus.value, 10));
                                            }}
                                        >
                                            {t(elem.label)}
                                            {elem.tooltip ? <Tooltip>{t(elem.tooltip)}</Tooltip> : null}
                                        </Button>
                                        <Span className="status__value">{`${elem.value}%`}</Span>
                                    </Li>
                                ))}
                            </Ul>
                        </WrapInput>
                        {status === 6 && (
                            <BlockWrap className="width_1-4">
                                <WrapInput label="Loss of Sales Date" name="losDate">
                                    <Input
                                        type="date-select"
                                        name="losDate"
                                        value={losDate || ''}
                                        placeholder="MM/DD/YYYY"
                                        onChange={value => {
                                            onChange("generalBlock.losDate", value);
                                        }}
                                    />
                                </WrapInput>
                            </BlockWrap>
                        )}
                    </BlockShadow>
                </>
                <>
                    <BlockTitle>
                        <Span>{t("Salesforce Stage Gates")}</Span>
                    </BlockTitle>
                    <BlockShadow blockName="project_status">
                        <div className={'grade-statuses-wrap'}>
                            <Ul className="status">
                                {stageGatesValue.map((elem, index) => (
                                    <Li key={`stageGate-${index}`} className="status__item">
                                        <Button
                                            className={`status__button ${
                                                elem.order === stageGate ? elem.statusColor : ""
                                            }`}
                                            onClick={() => {
                                                onChange("generalBlock.stageGate", elem.order);
                                            }}
                                        >
                                            {t(elem.label)}
                                            {elem.tooltip ? <Tooltip>{t(elem.tooltip)}</Tooltip> : null}
                                        </Button>
                                    </Li>
                                ))}
                            </Ul>
                            {stageGate !== 0 &&
                                <button type={'button'}
                                        className="clear-all-btn"
                                        onClick={() => onChange("generalBlock.stageGate", 0)}>
                                    Clear All
                                </button>
                            }
                        </div>
                    </BlockShadow>
                </>
            </>
        );
    }
}

GeneralInfoBlock.propTypes = propTypes;
GeneralInfoBlock.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        isEdit: !!state.editState,
        replacementBusinessProjectsBlock: getStoreItem(state, "replacementBusinessProjectsBlock"),
        project: getStoreItem(state, "generalBlock.project"),
        logo: getStoreItem(state, "generalBlock.logo"),
        logoView: getStoreItem(state, "generalBlock.logoView"),
        customer: getStoreItem(state, "generalBlock.customer"),
        dunsNumber: getStoreItem(state, "generalBlock.dunsNumber"),
        customerOwner: getStoreItem(state, "generalBlock.customerOwner"),
        invoiceCount: getStoreItem(state, "generalBlock.invoiceCount"),
        customerNickname: getStoreItem(state, "generalBlock.customerNickname"),
        addedToSalesForecast: getStoreItem(state, "generalBlock.addedToSalesForecast"),
        status: getStoreItem(state, "generalBlock.status"),
        stageGate: getStoreItem(state, "generalBlock.stageGate"),
        businessStatus: getStoreItem(state, "generalBlock.businessStatus"),
        losDate: getStoreItem(state, "generalBlock.losDate"),
        showFull: getStoreItem(state, "show_full"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        submitApplication: getActionStore("submitApplicationAction", service, dispatch),
        changeCustomer: getActionStore("changeCustomerAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(GeneralInfoBlock);
