import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas} from "Services";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {TabBlock, TabBlockCol, TabBlockColTitle, TabBlockColTitleH4, TabWrap, TabWrapTop,} from "Templates/Content";

import {ContainerTable} from "Hoc/Widgets";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {
    pagination: PropTypes.shape({ total: PropTypes.number }).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

class Invoices extends Component {
    constructor(props) {
        super(props);

        if (window) {
            const wrapper = document.querySelector('.main-wrap')

            if (wrapper && !wrapper.classList.contains('full-height')) {
                wrapper.classList.add('full-height')
            }
        }
    }

    onClickShow = item => {
        const win = window.open(`/invoice/invoices/${item.invoice_number}`, "_blank");
        win.focus();
    };

    render() {
        const { pagination, t } = this.props;
        return (
            <>
                <TabWrapTop className="grade-invoices-wrap">
                    <TabBlock>
                        <TabBlockCol>
                            <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                            <TabBlockColTitleH4>
                                {`${numberWithCommas(pagination.total, 0)} ${t("Invoices")}`}
                            </TabBlockColTitleH4>
                        </TabBlockCol>
                        <div className={"invoices-total-analytics"}>
                            {this.props.totalAnalytics && this.props.totalAnalytics[0] ? <div className="invoices-total-analytics__item">
                                <p>Revenue:</p>
                                <span>${numberWithCommas(this.props.totalAnalytics[0].net_amount, 0)}</span>
                            </div> : null}
                            {this.props.totalAnalytics && this.props.totalAnalytics[0] ? <div className="invoices-total-analytics__item">
                                <p>Volume:</p>
                                <span>{numberWithCommas(this.props.totalAnalytics[0].normalized_weight, 0)} (kg)</span>
                            </div> : null}
                        </div>
                    </TabBlock>
                </TabWrapTop>
                <div className="grade-table-wrap">
                    <TabWrap className="invoices-content">
                        <TabViewDetail
                            customIcon={() => 'icon_external_link'}
                            onClickCustom={this.onClickShow}
                        />
                    </TabWrap>
                </div>
            </>
        );
    }
}

Invoices.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        totalAnalytics: getStoreItem(state, "total_analytics"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Invoices);
