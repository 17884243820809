import React from "react";
import _ from "lodash";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {useSelector} from "react-redux";

const propTypes = {
    path: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    selected: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
};

const defaultProps = {
    selected: [],
};

const Checkbox = ({ path, item, selected, onChange }) => {
    const data = useSelector(state => state);
    const selectedState = data.createState ? data.createState.forecastBlock.forecast : data.editState.forecastBlock.forecast

    const id = _.get(item, "id");
    return (
        <div className={`main-table__body-row with-checkbox ${selectedState.length && selectedState.find((sel) => sel.id === id) ? 'disabled' : ''}`}>
            <input
                type="checkbox"
                id={id}
                className="main-content__checkbox-input"
                checked={selectedState.length && selectedState.find((sel) => sel.id === id) ? true : selected.some(elem => elem.id === id)}
                onChange={({ target }) => {
                    onChange(item, target.checked)
                }}
            />
            <label htmlFor={id} className="main-content__checkbox-label">
                <span>{_.get(item, path)}</span>
            </label>
        </div>
    );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
    const { getStoreItem } = props.service;

    return {
        selected: getStoreItem(state, "selected"),
        forecastBlock: getStoreItem(state, "forecastBlock"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChangeItem", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Checkbox);
