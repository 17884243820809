import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {Label} from "Templates/Form";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Row, Column} from "Templates/Table/NewVersion";

const propTypes = {
    applications: PropTypes.arrayOf(PropTypes.object),
    service: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
}

const TableHeadColumn = (
    {
        service: {columns}
    }) => {

    const getHeadView = key => {
        const tableLabel = {
            code: "Application Code",
            market_segment: "Market Segment",
            segment: "Segment",
            sub_segment: "Sub-Segment",
        };

        switch (key) {
            case "code":
                return (
                    <div className="text-left">
                        <Label label={tableLabel[key]}/>
                    </div>
                );

            case "sub_segment":
                return (
                    <div className="text-left w-100 d-flex justify-between">
                        <Label className="text-nowrap" label={tableLabel[key]}/>
                    </div>
                );

            default:
                return (
                    <div className="text-left">
                        <Label label={tableLabel[key]}/>
                    </div>
                );
        }
    };

    const getColSpan = key => {
        return ["code", "market_segment", "segment", "sub_segment"].includes(key) ? 2 : 1;
    };

    return (
        <Row>
            {columns.map((column, index) => (
                <Column colSpan={getColSpan(column)} key={`Head-${index}`}>{getHeadView(column)}</Column>
            ))}
        </Row>
    );
};

TableHeadColumn.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {
        getStoreItem
    } = ownProps.service;

    return {
        applications: getStoreItem(state, "applicationBlock.application")
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableHeadColumn);
