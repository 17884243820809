import React from 'react';
import PropTypes from "prop-types";
import {ContainerTable} from "Hoc/Widgets";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import {numberWithCommas} from "Services";
import {Title, WrapButtons, Total} from "Templates/Titles";
import {TabBlockBtn} from "Templates/Content";
import {CheckPermission} from "Hoc/Template";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const ProductTable = ({onClickEdit, onClickDelete, total, handleOpenModal}) => {
    return (
        <div className="solution-products-table-wrap">
            <Title title="Products" total={() => <Total>{`${numberWithCommas(total, 0)} Total`}</Total>}>
                <CheckPermission permission="product_solutions_edit">
                    <WrapButtons>
                        <TabBlockBtn onClick={handleOpenModal}>
                            {"Assign to Product"}
                        </TabBlockBtn>
                    </WrapButtons>
                </CheckPermission>
            </Title>
            <TabViewDetail onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
        </div>
    );
}

ProductTable.defaultProps = {
    total: 0,
};

ProductTable.propTypes = {
    total: PropTypes.number,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
};

export default ProductTable;