import React, {useEffect, useRef, useState} from "react";
import {unionBy} from "lodash";
import {handleScroll} from "Services/Scroll";
import {ViewHistory} from "./ViewHistory";

export const ViewHistoryTable = ({urlSubTab, emptyText, onLoadHistoryData, rollbackHistory, fetchSubTabs}) => {
    const [sort, setSort] = useState({
        column: 'created_at',
        direction: 'desc'
    });

    const total = useRef({ page: 1, pages: 0 })

    const [listItems, setListItems] = useState([]);

    const loadData = (sortData, paginationData) => {
        onLoadHistoryData(sortData, paginationData.page).then(res => {
            const [data] = res;

            if (!data) {
                return;
            }
            setListItems((prev) => {
                return unionBy([...prev, ...data.items], 'id')
            });
            total.current = data.pagination
        });
    };

    const onSortClick = () => {
        setSort((prev) => {
            if (prev.direction === 'asc') {
                loadData({
                    column: 'created_at',
                    direction: 'desc'
                }, total.current)
                return {
                        column: 'created_at',
                        direction: 'desc'
                }
            }

            loadData({
                column: 'created_at',
                direction: 'asc'
            }, total.current)
            return {
                column: 'created_at',
                direction: 'asc'
            }
        })
    }

    useEffect(() => {
        setSort({
            column: 'created_at',
            direction: 'desc'
        })
        setListItems([])
        total.current = {page: 1, pages: 0}
        loadData(sort, total.current)
    }, [urlSubTab])

    const onScroll = (e) => {
        if (!listItems.length) return;

        if ((total.current.page > 0 && total.current.pages > 0) && (total.current.page >= total.current.pages)) {
            return
        }

        handleScroll(e).then(() => {
            onLoadHistoryData(sort, total.current.page + 1).then(res => {
                const [data] = res;

                if (!data) {
                    return;
                }
                setListItems((prev) => {
                    return unionBy([...prev, ...data.items], 'id')
                });

                total.current = data.pagination
            });
        });
    }

    useEffect(() => {
        loadData(sort, total.current)
    }, [onLoadHistoryData]);

    useEffect(() => {
        if (typeof window === 'undefined') return

        const datasheetWrap = document.querySelector('.publications-content.main-content')

        if (!datasheetWrap) return

        datasheetWrap.addEventListener('scroll', (e) => onScroll(e))

        return () => {
            datasheetWrap.removeEventListener('scroll', (e) => onScroll(e))
        }
    }, [listItems.length])

    const handleRollbackHistory = async (historyId) => {
        await rollbackHistory(historyId).then(() => {
            setSort({
                column: 'created_at',
                direction: 'desc'
            })

            onLoadHistoryData({
                column: 'created_at',
                direction: 'desc'
            }, 1).then(res => {
                const [data] = res;

                if (!data) {
                    return;
                }
                setListItems((prev) => {
                    return unionBy([...prev, ...data.items], 'id')
                });
                total.current = data.pagination

                fetchSubTabs({});
            });
        })
    }

    return <ViewHistory listItems={listItems} sort={sort} emptyText={emptyText} onSortClick={onSortClick} handleRollbackHistory={handleRollbackHistory}/>
};