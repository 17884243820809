/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// window._mfq = window._mfq || [];
    (function() {
        const { hostname } = new URL(window.location.href);

        if (hostname === 'mcg.comopps.com' || hostname === 'mcpp.comopps.com') {
            // var mf = document.createElement("script");
            // mf.type = "text/javascript"; mf.defer = true;
            // mf.src = "//cdn.mouseflow.com/projects/1d535986-f171-4e98-84cd-1923a5d43cdb.js";
            // document.getElementsByTagName("head")[0].appendChild(mf);

            var an = document.createElement("script");
            an.type = "text/javascript"; an.defer = true;
            an.src = "//www.googletagmanager.com/gtag/js?id=G-TCV20DKKC7";
            document.getElementsByTagName("head")[0].appendChild(an);

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-TCV20DKKC7');
        }
    })();



import "./core";

import "./assets";

import "./react/Pages/registration";

