import {compose} from "redux";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import React, {useEffect, useRef, useState} from "react";
import {handleScroll} from "Services/Scroll";
import {Preloader} from "Templates/Preloader";
import Img from "Templates/Img/Img";
import {Modal} from "Templates/Modals";
import PropTypes from "prop-types";
import CreateFiltersModal from "./Modals/CreateFiltersModal";
import {onChange} from "../Services/EventsService";
import {FiltersColumnsUrls} from "../Constants/filtersColumnsUrls";
import {
    createFilter,
    deleteItem,
    getSavedFilter,
    getSavedFiltersList,
    getSelectedFiltersItems
} from "../Services/RequestService";
import {AllTableColumns} from "../Constants/allTableColumns";
import connectionService from "../Services/Tabs/Connections";

const STORAGE_SELECTED_FILTER = 'SELECTED_CONNECTION_FILTER'
const STORAGE_SELECTED_FILTERS = 'SELECTED_CONNECTION_FILTERS'

const propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchFiltersItems: PropTypes.func.isRequired,
};
const ConnectionsTab = ({
                            children,
                            currentFY,
                            weightUnitState,
                            onChangeFilter,
                            request,
                            fetchItems,
                            fetchFiltersItems,
                            t
                        }) => {
    const unit = weightUnitState;
    const filtersModalRef = useRef(null)
    const buttonRef = useRef(null)

    const [filtersActive, setFiltersActive] = useState(false)
    const [savedFilters, setSavedFilters] = useState([])
    const [filters, setFilters] = useState(null)
    const [filtersItems, setFiltersItems] = useState(null)
    const [selectedSavedFilter, setSelectedSavedFilter] = useState('')

    const [showLoader, setShowLoader] = useState(true)

    const [openCreateFilterModal, setOpenCreateFilterModal] = useState(false)

    const [paginationData, setPaginationData] = useState({current_page_number: 1});

    const cleanSelectedFilters = () => {
        if (localStorage.getItem(STORAGE_SELECTED_FILTER) && localStorage.getItem(STORAGE_SELECTED_FILTERS)) {
            localStorage.removeItem(STORAGE_SELECTED_FILTER)
            localStorage.removeItem(STORAGE_SELECTED_FILTERS)
            setSelectedSavedFilter('')
        }
    }

    useEffect(() => {
        if (typeof window === 'undefined') return
        if (localStorage.getItem(STORAGE_SELECTED_FILTER)) {
            const savedFilterFromStorage = JSON.parse(localStorage.getItem(STORAGE_SELECTED_FILTER) || '')
            setSelectedSavedFilter(savedFilterFromStorage)
        }
        const handleDocumentClick = (event) => {
            if (filtersModalRef.current && !filtersModalRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
                setFiltersActive(false);
            }
        }

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [])

    const getNames = (object, type) => {
        if (!object) {
            return null;
        }

        if (type === 'filter') {
            const array = [];

            Object.keys(object).forEach((key) => {
                const columnName = AllTableColumns(unit, currentFY)[key];
                if (columnName) {
                    const name = columnName;
                    const value = object[key];
                    array.push({column: key, name, value});
                }
            });

            return array;
        }

        if (type === 'sort') {
            const array = [];

            const columnName = AllTableColumns(unit, currentFY)[object.column];
            if (columnName) {
                const name = columnName;
                const value = object.direction;
                array.push({sortColumn: object.column, name, value});
            }

            return array;
        }
    };

    const createFilters = () => {
        const filterNames = getNames(Object.keys(request.filter).length ? request.filter : null, 'filter');
        const sortNames = getNames(Object.keys(request.sort) ? request.sort : null, 'sort');

        if (!filterNames && !sortNames) {
            setFilters(null)

            return
        }

        setFilters({
            list: filterNames || null,
            sort: sortNames || null,
        })
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (localStorage.getItem(STORAGE_SELECTED_FILTERS)) {
                const oldFilters = localStorage.getItem(STORAGE_SELECTED_FILTERS)
                const oldFiltersRequest = JSON.parse(oldFilters || {})
                const newRequest = {...request}

                if (oldFiltersRequest.filter && oldFiltersRequest.filter.sap_customer) {
                    oldFiltersRequest.filter.sap_customer = oldFiltersRequest.filter.sap_customer.toString()
                }

                if (oldFiltersRequest.filter && oldFiltersRequest.filter.sap_grade) {
                    oldFiltersRequest.filter.sap_grade = oldFiltersRequest.filter.sap_grade.toString()
                }

                if (request.filter && request.filter.sap_customer) {
                    newRequest.filter.sap_customer = request.filter.sap_customer.toString()
                }


                if (request.filter && request.filter.sap_grade) {
                    newRequest.filter.sap_grade = request.filter.sap_grade.toString()
                }

                const requestFilters = JSON.stringify(newRequest)

                if (JSON.stringify(oldFiltersRequest) !== requestFilters) {
                    localStorage.removeItem(STORAGE_SELECTED_FILTER)
                    localStorage.removeItem(STORAGE_SELECTED_FILTERS)
                    setSelectedSavedFilter('')
                }
            }
        }
        createFilters()
    }, [request])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const urlObject = new URL(window.location.href);
            const urlParams = new URLSearchParams(document.location.search);

            let queryString = ''
            if (filters) {
                if (filters.list && filters.list.length) {
                    queryString = filters.list.map((item) => {
                        if (Array.isArray(item.value)) {
                            return item.value.map((val) => {
                                return `filter%5B${item.column}%5D%5B%5D=${val}`
                            })
                        }
                        if (item.value.from && item.value.to) {
                            return `filter%5B${item.column}%5D%5Bfrom%5D=${item.value.from}&filter%5B${item.column}%5D%5Bto%5D=${item.value.to}`
                        }
                        return `filter%5B${item.column}%5D=${item.value}`

                    }).join(',')
                }

                if (filters.sort && filters.sort.length) {
                    queryString += queryString
                        ? `&sort%5Bcolumn%5D=${filters.sort[0].sortColumn}&sort%5Bdirection%5D=${filters.sort[0].value}`
                        : `sort%5Bcolumn%5D=${filters.sort[0].sortColumn}&sort%5Bdirection%5D=${filters.sort[0].value}`
                }
            } else {
                queryString = ''
            }

            const newUrl = `${urlObject.origin}${urlObject.pathname}${queryString.length ? `?${queryString.replace(/,/g, "&")}` : ''}`;

            let financialYear = '';
            if(urlParams.get('financial_year')) {
                financialYear =  `${queryString.length ? '&' : '?'}financial_year=${urlParams.get('financial_year')}`;
            }

            window.history.replaceState({}, '', newUrl + financialYear);
        }
    }, [filters])

    const loadFiltersAsync = (items) => items.reduce(async (previousPromise, currentItem) => {
        await previousPromise;
        const result = await getSelectedFiltersItems(`${FiltersColumnsUrls[currentItem.column]}/${String(currentItem.value)}`);
        return [...(await previousPromise), {
            column: currentItem.column,
            name: currentItem.name,
            value: result[0].items
        }];
    }, Promise.resolve([]));

    useEffect(() => {
        setShowLoader(true)
        if (filters && filters.list && filters.list.length) {
            const list = filters.list.filter((item) => Array.isArray(item.value) && Object.keys(FiltersColumnsUrls).includes(item.column))
            const simpleList = filters.list.filter((item) => Array.isArray(item.value) && !Object.keys(FiltersColumnsUrls).includes(item.column))

            if (list && list.length) {
                loadFiltersAsync(list).then((res) => {
                    setFiltersItems([...res, ...simpleList])
                    setShowLoader(false)
                }).catch(() => {
                    setShowLoader(false)
                })
            } else {
                setFiltersItems(simpleList && simpleList.length ? simpleList : [])
                setShowLoader(false)
            }
        } else {
            setFiltersItems(null)
            setShowLoader(false)
        }
    }, [filters])

    const removeFilter = (filterItem, filterValue) => {
        cleanSelectedFilters()

        setFilters(prev => {
            const newRequest = {
                ...request,
                filter: {
                    ...request.filter,
                }
            };

            const updatedList = [...prev.list];

            const currentFilterIndex = updatedList.findIndex((filterListItem) => filterListItem.column === filterItem.column)

            if (currentFilterIndex < 0) return

            updatedList[currentFilterIndex] = {
                ...updatedList[currentFilterIndex],
                value: Array.isArray(updatedList[currentFilterIndex].value) ? updatedList[currentFilterIndex].value.filter(item => item !== filterValue) : ''
            };

            const filteredList = updatedList.filter(item => item.value.length);

            if (!filteredList.length) {
                delete newRequest.filter[filterItem.column];

                onChange([], filterItem.column).then(_value => onChangeFilter(filterItem.column, _value, newRequest));
                return {...prev, list: null};
            }
            delete newRequest.filter[filterItem.column];

            onChange(updatedList[currentFilterIndex].value, filterItem.column).then(_value => onChangeFilter(filterItem.column, _value, newRequest));
            return {...prev, list: filteredList};
        });
    }

    const removeSort = () => {
        cleanSelectedFilters()

        if (typeof window !== 'undefined') {
            const urlObject = new URL(window.location.href);

            const newUrl = `${urlObject.origin}${urlObject.pathname}`;

            window.history.replaceState({}, '', newUrl);
        }

        fetchItems({...request, sort: {}});

        setFilters((prev) => {
            return {...prev, sort: null}
        })
    }

    const onResetFilters = () => {
        if (typeof window !== 'undefined') {
            const urlObject = new URL(window.location.href);
            const newUrl = `${urlObject.origin}${urlObject.pathname}`;

            window.history.replaceState({}, '', newUrl);
        }
        fetchItems({filter: {}, sort: {}});

        onChangeFilter('all', undefined, {filter: {}, sort: {}});

        cleanSelectedFilters()
        setFilters(null)
        setFiltersItems(null)
    }

    const onSaveFilters = (name) => {
        if (!name.length || !filters || (!filters.list && !filters.sort)) {
            alert('Error! Fill name and chose filters')
        }

        createFilter({name, filters}).then((res) => {
            setOpenCreateFilterModal(false)

            localStorage.setItem(STORAGE_SELECTED_FILTER, JSON.stringify({label: name, value: res[0].id.value}))
            localStorage.setItem(STORAGE_SELECTED_FILTERS, JSON.stringify(request))
            setSelectedSavedFilter({label: name, value: res[0].id.value})
        }).catch((error) => {
            alert(error.detail)
        })
    }

    const getSavedFilters = (page = 1, deleting = false) => {
        getSavedFiltersList(page).then((res) => {
            const {
                items,
                pagination
            } = res[0]
            if (deleting) {
                setSavedFilters(items)
                return
            }
            if (page > 1) {
                setSavedFilters((prev) => {
                    return [...prev, ...items]
                })
            } else {
                setSavedFilters(items)
            }
            setPaginationData(pagination)
            setFiltersActive(true)
        }).catch((error) => {
            alert(error.detail)
        })
    }

    const setSavedFilter = (selectedFilter) => {
        getSavedFilter(selectedFilter.value).then((res) => {
            const transformedJSON = {
                filter: {},
                sort: {}
            };

            const list = JSON.parse(res[0].items)

            if (list && list.filter) {
                list.filter.forEach(item => {
                    const key = Object.keys(item)[0];
                    transformedJSON.filter[key] = item[key];
                });
            }

            if (list && list.sort) {
                list.sort.forEach(item => {
                    transformedJSON.sort = {
                        column: item.sortColumn,
                        direction: item.value
                    };
                });
            }


            const newRequest = {
                filter: {
                    ...transformedJSON.filter
                },
                sort: {
                    ...transformedJSON.sort
                },
            };

            fetchFiltersItems(newRequest);

            localStorage.setItem(STORAGE_SELECTED_FILTER, JSON.stringify(selectedFilter))
            localStorage.setItem(STORAGE_SELECTED_FILTERS, JSON.stringify(newRequest))
            setSelectedSavedFilter(selectedFilter)
        }).catch((error) => {
            alert(error.detail)
        })
        setFiltersActive(false);
    }

    const deleteFilter = (filterLabel, filterId) => {
        if (window.confirm(`Do you really want to delete ${filterLabel} filter?`)) {
            deleteItem(`/connection/connections/filters/${filterId}/delete`).then(() => {
                getSavedFilters(1, true)
            })

            if (typeof window !== 'undefined') {
                if (filterId === selectedSavedFilter.value) {
                    onResetFilters()
                }
            }
        }
    }

    const getProjectSubStatus = (status) => {
        switch (status) {
            case '10':
                return 'Lead'
            case '9':
                return 'Opportunity'
            case '8':
                return 'Target'
            case '7':
                return 'Current'
            case '6':
                return 'Loss of Sale'
            case '5':
                return 'Out of Production'
            default:
                return ''
        }
    }

    const getGradeSubStatus = (status) => {
        switch (status) {
            case '6':
                return 'WIP'
            case '2':
                return 'Trial'
            case '5':
                return 'Production Intent'
            case '4':
                return 'Production Viable'
            case '1':
                return 'Production'
            case '3':
                return 'Obsolete'
            default:
                return ''
        }
    }

    const getYesNo = (value) => {
        switch (value) {
            case '10':
                return 'Yes'
            case '0':
                return 'No'
            default:
                return ''
        }
    }

    const onScroll = (e) => {
        const {page, pages} = paginationData;
        if (page >= pages) {
            return;
        }
        handleScroll(e).then(() => {
            getSavedFilters(paginationData.page + 1);
        });
    };

    return (
        <ServiceProvider value={connectionService}>
            {showLoader && <div className="connections-table-loader"><Preloader/></div>}
            <div className={`connections-filters ${filtersActive ? 'filters-open' : ''} ${filters ? '' : 'empty'}`}>
                <button
                    ref={buttonRef}
                    type="button"
                    className="connections-filters__button"
                    onClick={() => getSavedFilters(1)}
                >
                    <Img img="filter_icon_blue"/>
                    {selectedSavedFilter ? selectedSavedFilter.label : 'Saved Views'}
                </button>
                <div
                    ref={filtersModalRef}
                    className={`connections-filters__list ${filtersActive ? 'active' : ''}`}
                    onScroll={onScroll}
                >
                    {(savedFilters && savedFilters.length)
                        ? savedFilters.map((saveFilterItem, i) => {
                            return (
                                <div
                                    key={`filter-${saveFilterItem.value}-${i}`}
                                    className="connections-filters__filter-item"
                                >
                                        <span
                                            onClick={() => setSavedFilter(saveFilterItem)}
                                        >
                                            {saveFilterItem.label}
                                        </span>
                                    <button
                                        type="button"
                                        onClick={() => deleteFilter(saveFilterItem.label, saveFilterItem.value)}
                                    >
                                        <Img img="icon_delete"/>
                                    </button>
                                </div>
                            )
                        })
                        : <p>You do not have any saved filters yet</p>}
                </div>

                <div className="connections-filters__chips-wrap">
                    {filtersItems ? (
                        <div className="connections-filters__chips">
                            {filtersItems.length > 0 ? filtersItems.map((filterItem) => {
                                return (
                                    <div key={filterItem.column} className="connections-filters__chips-item">
                                        <p>
                                            {filterItem.name}
                                            :
                                        </p>
                                        {filterItem.value.map((filterItemValue, i) => (
                                            <div
                                                key={`filter-value-${filterItem.column}-${i}`}
                                                className="connections-filters__chips-item__chip"
                                            >
                                                {filterItem.column === 'project_sub_status' ?
                                                    <span>{getProjectSubStatus(filterItemValue)}</span> : null}
                                                {filterItem.column === 'grade_sub_status' ?
                                                    <span>{getGradeSubStatus(filterItemValue)}</span> : null}
                                                {filterItemValue.label && <span>{filterItemValue.label}</span>}
                                                <button
                                                    type="button"
                                                    onClick={() => removeFilter(filterItem, filterItemValue.value ? filterItemValue.value : filterItemValue)}
                                                >
                                                    <Img img="icon_remove_filter"/>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )
                            }) : null}
                        </div>
                    ) : null}

                    {filters ? (
                        <>
                            <div className="connections-filters__chips">
                                {filters.list && filters.list.length > 0 ? filters.list.map((filterItem, index) => {
                                    if (Array.isArray(filterItem.value)) return null
                                    return filterItem.value ? (
                                        <div
                                            key={`simple-${filterItem.column}-${index}`}
                                            className="connections-filters__chips-item"
                                        >
                                            <p>
                                                {filterItem.name}
                                                :
                                            </p>
                                            <div
                                                className="connections-filters__chips-item__chip"
                                            >
                                                {
                                                    filterItem.column === 'sap_customer'
                                                        ? <span>{getYesNo(filterItem.value.toString())}</span>
                                                        : filterItem.column === 'sap_grade'
                                                            ? <span>{getYesNo(filterItem.value.toString())}</span>
                                                            : filterItem.value.from && filterItem.value.to
                                                                ? (
                                                                    <span>
                                                                        From:
                                                                        {filterItem.value.from}
                                                                        {' '}
                                                                        - To:
                                                                        {filterItem.value.to}
                                                                    </span>
                                                                ) : filterItem.value === 'non-zero'
                                                                    ? <span>Greater than 0</span>
                                                                    : <span>{filterItem.value}</span>
                                                }

                                                <button
                                                    type="button"
                                                    onClick={() => removeFilter(filterItem, filterItem.value)}
                                                >
                                                    <Img img="icon_remove_filter"/>
                                                </button>
                                            </div>
                                        </div>
                                    ) : null
                                }) : null}
                            </div>
                            <div className="connections-filters__chips">
                                {filters.sort && filters.sort.length > 0 ? filters.sort.map((filterItem) => {
                                    return (
                                        <div
                                            key={filterItem.sortColumn}
                                            className="connections-filters__chips-item"
                                        >
                                            <p>
                                                {filterItem.name}
                                                :
                                            </p>
                                            <div
                                                className="connections-filters__chips-item__chip"
                                            >
                                        <span>
                                            Sort by:
                                            {filterItem.value}
                                        </span>
                                                <button
                                                    type="button"
                                                    onClick={removeSort}
                                                >
                                                    <Img img="icon_remove_filter"/>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }) : null}
                            </div>
                        </>
                    ) : null}
                </div>
                {(filters && (filters.list && filters.sort)) && (
                    <div className="connections-filters__actions">
                        <button
                            className="connections__btn main-btn main-btn__general main-btn_white"
                            onClick={onResetFilters}
                        >
                            Reset Filters
                        </button>
                        <button
                            className="connections__btn main-btn main-btn__general main-btn_white"
                            onClick={() => setOpenCreateFilterModal(true)}
                        >
                            Save Filter
                        </button>
                        {openCreateFilterModal ? (
                            <Modal onRequestClose={() => setOpenCreateFilterModal(false)}>
                                <CreateFiltersModal
                                    onSaveFilters={onSaveFilters}
                                    onClose={() => setOpenCreateFilterModal(false)}
                                />
                            </Modal>
                        ) : null}
                    </div>
                )}
            </div>
            {children}
        </ServiceProvider>
    );
}

ConnectionsTab.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        currentFY: state.listState.financialYear,
        weightUnitState: state.weightUnitState,
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", connectionService, dispatch),
        fetchFiltersItems: getActionStore("fetchFiltersItems", connectionService, dispatch),
        onChangeFilter: getActionStore("onChange", connectionService, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ConnectionsTab);