import {formDataNormalizer} from "Services";

export const usersFormData = (data) => {
    return formDataNormalizer({contacts: data.map(({value}) => value)});
};

export const inviteGuestFormData = (data) => {
    return formDataNormalizer({...data});
};


export const contactsFormData = (data) => {
    return formDataNormalizer({managers: data.map(({user, role}) => ({user, role}))});
};

export const publicationsFormData = (data) => {
    return formDataNormalizer({publications: data.map(({value}) => value)});
};
