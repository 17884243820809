import React, {Fragment, useState} from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {formatDateToLocal, getString} from "Services";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import {TooltipHtml} from "Templates/Form";
import {Modal, ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";

const {Form, Title, Button} = ModalDefault;

const HistoryChangesModal = ({ title, oldContent, newContent, onClose }) => {
    return <Form className="changes-history__modal">
        <ButtonClose className="main-modal__form-close" onClick={onClose} />
        <Title>{title}</Title>
        <div className="changes-history__modal-wrap">
            <div className="changes-history__modal-content left" dangerouslySetInnerHTML={ { __html: oldContent } }></div>
            <div className="changes-history__modal-content right" dangerouslySetInnerHTML={ { __html: newContent } }></div>
        </div>
        <Button onClick={onClose}>
            Close
        </Button>
    </Form>
}
export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const oldValue = JSON.parse(item.before_value);
    const newValue = JSON.parse(item.after_value);

    const [modal, setModal] = useState({
        open: false,
        title: '',
        oldContent: null,
        newContent: null,
    })

    const closeModal = () => {
        setModal({
            open: false,
            oldContent: null,
            newContent: null,
        })
    }

    const generateStatus = (status) => {
        switch (status) {
            case 10:
                return 'Active'
            case 5:
                return 'Partially'
            case 0:
                return 'Archived'
            default:
                return '-'
        }
    }

    const generateSubStatus = (subStatus) => {
        switch (subStatus) {
            case 10:
                return 'Lead'
            case 9:
                return 'Opportunity'
            case 8:
                return 'Target'
            case 7:
                return 'Current'
            case 11:
                return 'On Hold'
            case 6:
                return 'Loss of Sale'
            case 5:
                return 'Out of Production'
            case 1:
                return 'Level 1'
            case 2:
                return 'Level 2'
            case 3:
                return 'Level 3'
            case 4:
                return 'Level 4'
            default:
                return '-'
        }
    }

    const generateStageGate = (stageGate) => {
        switch (stageGate) {
            case 1:
                return 'Business Case'
            case 2:
                return 'Evaluation'
            case 3:
                return 'Design Validation'
            case 4:
                return 'Technical Validation'
            case 5:
                return 'Prepare Launch'
            case 6:
                return 'Commercialized'
            default:
                return '-'
        }
    }

    const generateBusinessStatus = (businessStatus) => {
        switch (businessStatus) {
            case 1:
                return 'Level 1'
            case 2:
                return 'Level 2'
            case 3:
                return 'Level 3'
            case 4:
                return 'Level 4'
            default:
                return '-'
        }
    }


    const values = Object.keys(oldValue).map((col, i) => {
        switch (col) {
                case 'name':
                    if (oldValue.name === newValue.name) return null

                    return (
                        <div className="changes-history-grid-item" key={`name-${i}`}>
                            <TableRowItemSpan title="Name" className="history-col-title">Name</TableRowItemSpan>
                            <TableRowItemSpan title={oldValue.name || '-'}>{oldValue.name || '-'}</TableRowItemSpan>
                            <TableRowItemSpan title={newValue.name || '-'}>{newValue.name || '-'}</TableRowItemSpan>
                        </div>
                    );
                case 'manager':
                    const oldManager = oldValue.manager.user_name
                    const newManager = newValue.manager.user_name

                    if (oldManager === newManager) return null

                    return (
                        <div className="changes-history-grid-item" key={`manager-${i}`}>
                            <TableRowItemSpan title="Manager" className="history-col-title">Project Manager</TableRowItemSpan>
                            <TableRowItemSpan title={oldManager || '-'}>{oldManager || '-'}</TableRowItemSpan>
                            <TableRowItemSpan title={newManager || '-'}>{newManager || '-'}</TableRowItemSpan>
                        </div>
                    );
                case 'customer_name':
                const oldCustomer = oldValue.customer_name
                const newCustomer = newValue.customer_name

                if (oldCustomer === newCustomer) return null

                return (
                    <div className="changes-history-grid-item" key={`customer_name-${i}`}>
                        <TableRowItemSpan title="Customer" className="history-col-title">Customer</TableRowItemSpan>
                        <TableRowItemSpan title={oldCustomer || '-'}>{oldCustomer || '-'}</TableRowItemSpan>
                        <TableRowItemSpan title={newCustomer || '-'}>{newCustomer || '-'}</TableRowItemSpan>
                    </div>
                );
                case 'customerNickname_name':
                    const oldCustomerNickname = oldValue.customerNickname_name
                    const newCustomerNickname = newValue.customerNickname_name

                    if (oldCustomerNickname === newCustomerNickname) return null

                    return (
                        <div className="changes-history-grid-item" key={`customerNickname_name-${i}`}>
                            <TableRowItemSpan title="Customer Nickname" className="history-col-title">Customer Nickname</TableRowItemSpan>
                            <TableRowItemSpan title={oldCustomerNickname || '-'}>{oldCustomerNickname || '-'}</TableRowItemSpan>
                            <TableRowItemSpan title={newCustomerNickname || '-'}>{newCustomerNickname || '-'}</TableRowItemSpan>
                        </div>
                    );
                case 'status':
                const oldStatus = oldValue.status
                const newStatus = newValue.status

                if (oldStatus === newStatus) return null

                return (
                    <div className="changes-history-grid-item" key={`status-${i}`}>
                        <TableRowItemSpan title="Sub Status" className="history-col-title">Sub Status</TableRowItemSpan>
                        <TableRowItemSpan
                            title={generateStatus(oldStatus)}
                        >
                            {generateStatus(oldStatus)}
                        </TableRowItemSpan>
                        <TableRowItemSpan
                            title={generateStatus(newStatus)}
                        >
                            {generateStatus(newStatus)}
                        </TableRowItemSpan>
                    </div>
                );
                case 'subStatus':
                    const oldSubStatus = oldValue.subStatus
                    const newSubStatus = newValue.subStatus

                    if (oldSubStatus === newSubStatus) return null

                    return (
                        <div className="changes-history-grid-item" key={`subStatus-${i}`}>
                            <TableRowItemSpan title="Sub Status" className="history-col-title">Sub Status</TableRowItemSpan>
                            <TableRowItemSpan
                                title={generateSubStatus(oldSubStatus)}
                            >
                                {generateSubStatus(oldSubStatus)}
                            </TableRowItemSpan>
                            <TableRowItemSpan
                                title={generateSubStatus(newSubStatus)}
                            >
                                {generateSubStatus(newSubStatus)}
                            </TableRowItemSpan>
                        </div>
                    );
            case 'stageGate':
                const oldStageGate = oldValue.stageGate
                const newStageGate = newValue.stageGate

                if (oldStageGate === newStageGate) return null

                return (
                    <div className="changes-history-grid-item" key={`stageGate-${i}`}>
                        <TableRowItemSpan title="Sub Status" className="history-col-title">Salesforce Stage Gates</TableRowItemSpan>
                        <TableRowItemSpan
                            title={generateStageGate(oldStageGate)}
                        >
                            {generateStageGate(oldStageGate)}
                        </TableRowItemSpan>
                        <TableRowItemSpan
                            title={generateStageGate(newStageGate)}
                        >
                            {generateStageGate(newStageGate)}
                        </TableRowItemSpan>
                    </div>
                );
                case 'businessStatus':
                    const oldBusinessStatus = oldValue.businessStatus
                    const newBusinessStatus = newValue.businessStatus

                    if (oldBusinessStatus === newBusinessStatus) return null

                    return (
                        <div className="changes-history-grid-item" key={`businessStatus-${i}`}>
                            <TableRowItemSpan title="Sub Status" className="history-col-title">Sub Status</TableRowItemSpan>
                            <TableRowItemSpan
                                title={generateBusinessStatus(oldBusinessStatus)}
                            >
                                {generateBusinessStatus(oldBusinessStatus)}
                            </TableRowItemSpan>
                            <TableRowItemSpan
                                title={generateBusinessStatus(newBusinessStatus)}
                            >
                                {generateBusinessStatus(newBusinessStatus)}
                            </TableRowItemSpan>
                        </div>
                    );
                case 'materialSpecification_name':
                    const oldMaterialSpecification = oldValue.materialSpecification_name
                    const newMaterialSpecification = newValue.materialSpecification_name

                    if (oldMaterialSpecification === newMaterialSpecification) return null

                    return (
                        <div className="changes-history-grid-item" key={`materialSpecification_name-${i}`}>
                            <TableRowItemSpan title="Material Specification" className="history-col-title">Material Specification</TableRowItemSpan>
                            <TableRowItemSpan title={oldMaterialSpecification || '-'}>{oldMaterialSpecification || '-'}</TableRowItemSpan>
                            <TableRowItemSpan title={newMaterialSpecification || '-'}>{newMaterialSpecification || '-'}</TableRowItemSpan>
                        </div>
                    );
                case 'performanceSpecification_name':
                    const oldPerformanceSpecification = oldValue.performanceSpecification_name
                    const newPerformanceSpecification = newValue.performanceSpecification_name

                    if (oldPerformanceSpecification === newPerformanceSpecification) return null

                    return (
                        <div className="changes-history-grid-item" key={`performanceSpecification_name-${i}`}>
                            <TableRowItemSpan title="Performance Specification" className="history-col-title">Performance Specification</TableRowItemSpan>
                            <TableRowItemSpan title={oldPerformanceSpecification || '-'}>{oldPerformanceSpecification || '-'}</TableRowItemSpan>
                            <TableRowItemSpan title={newPerformanceSpecification || '-'}>{newPerformanceSpecification || '-'}</TableRowItemSpan>
                        </div>
                    );
                case 'addedToSalesForecast':
                    const oldAddedToSalesForecast = oldValue.addedToSalesForecast
                    const newAddedToSalesForecast = newValue.addedToSalesForecast

                    if (oldAddedToSalesForecast === newAddedToSalesForecast) return null

                    return (
                        <div className="changes-history-grid-item" key={`addedToSalesForecast-${i}`}>
                            <TableRowItemSpan title="Add to Budget & Sales Forecast" className="history-col-title">Add to Budget & Sales Forecast</TableRowItemSpan>
                            <TableRowItemSpan title={oldAddedToSalesForecast === 10 ? 'Yes' : 'No' || '-'}>{oldAddedToSalesForecast === 10 ? 'Yes' : 'No' || '-'}</TableRowItemSpan>
                            <TableRowItemSpan title={newAddedToSalesForecast === 10 ? 'Yes' : 'No' || '-'}>{newAddedToSalesForecast === 10 ? 'Yes' : 'No' || '-'}</TableRowItemSpan>
                        </div>
                    );

            case 'replacementBusinessProjects':
                const oldReplacementBusinessProjects = oldValue.replacementBusinessProjects
                const newReplacementBusinessProjects = newValue.replacementBusinessProjects

                while (oldReplacementBusinessProjects.length < newReplacementBusinessProjects.length) {
                    oldReplacementBusinessProjects.push('-');
                }

                while (newReplacementBusinessProjects.length < oldReplacementBusinessProjects.length) {
                    newReplacementBusinessProjects.push('-');
                }

                if (JSON.stringify(oldReplacementBusinessProjects) === JSON.stringify(newReplacementBusinessProjects)) return null

                return (
                    <div className="changes-history-grid-item" key={`projects-${i}`}>
                        <TableRowItemSpan title="ReplacementBusinessProjects" className="history-col-title">Replacement Business Projects</TableRowItemSpan>
                        <p>
                            {(oldReplacementBusinessProjects && oldReplacementBusinessProjects.length) ? oldReplacementBusinessProjects.map((projects, index) => {
                                if (JSON.stringify(oldReplacementBusinessProjects[index]) === JSON.stringify(newReplacementBusinessProjects[index])) return null
                                if (projects === '-') return <TableRowItemSpan
                                    key={`projects-${index + 1}-before-empty`}
                                >-</TableRowItemSpan>

                                return (
                                    <TableRowItemSpan
                                        key={`projects-${index}-before`}
                                        title={projects.name}
                                    >
                                        {projects.name}
                                    </TableRowItemSpan>
                                );
                            }) : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                        </p>
                        <p>
                            {(newReplacementBusinessProjects && newReplacementBusinessProjects.length) ? newReplacementBusinessProjects.map((projects, index) => {
                                if (JSON.stringify(newReplacementBusinessProjects[index]) === JSON.stringify(oldReplacementBusinessProjects[index])) return null

                                if (projects === '-') return <TableRowItemSpan
                                    key={`projects-${index + 1}-after-empty`}
                                >-</TableRowItemSpan>

                                return (
                                    <TableRowItemSpan
                                        key={`projects-${index}-after`}
                                        title={projects.name}
                                    >
                                        {projects.name}
                                    </TableRowItemSpan>
                                );
                            }) : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                        </p>
                    </div>
                );
                case 'contacts':
                    const oldContacts = oldValue.contacts
                    const newContacts = newValue.contacts

                    while (oldContacts.length < newContacts.length) {
                        oldContacts.push('-');
                    }

                    while (newContacts.length < oldContacts.length) {
                        newContacts.push('-');
                    }

                    if (JSON.stringify(oldContacts) === JSON.stringify(newContacts)) return null

                    return (
                        <div className="changes-history-grid-item" key={`contact-${i}`}>
                            <TableRowItemSpan title="Contacts" className="history-col-title">Contacts</TableRowItemSpan>
                            <p>
                                {(oldContacts && oldContacts.length) ? oldContacts.map((contact, index) => {
                                    if (JSON.stringify(oldContacts[index]) === JSON.stringify(newContacts[index])) return null
                                    if (contact === '-') return <TableRowItemSpan
                                        key={`contact-${index + 1}-before-empty`}
                                    >-</TableRowItemSpan>

                                    return (
                                        <TableRowItemSpan
                                            key={`contact-${index}-before`}
                                            title={contact.contact_name}
                                        >
                                            {contact.contact_name}
                                        </TableRowItemSpan>
                                    );
                                }) : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                            </p>
                            <p>
                                {(newContacts && newContacts.length) ? newContacts.map((contact, index) => {
                                    if (JSON.stringify(newContacts[index]) === JSON.stringify(oldContacts[index])) return null

                                    if (contact === '-') return <TableRowItemSpan
                                        key={`contact-${index + 1}-after-empty`}
                                    >-</TableRowItemSpan>

                                    return (
                                        <TableRowItemSpan
                                            key={`contact-${index}-after`}
                                            title={contact.contact_name}
                                        >
                                            {contact.contact_name}
                                        </TableRowItemSpan>
                                    );
                                }) : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                            </p>
                        </div>
                    );
                case 'internalTeams':
                    const oldInternalTeams = oldValue.internalTeams
                    const newInternalTeams = newValue.internalTeams

                    while (oldInternalTeams.length < newInternalTeams.length) {
                        oldInternalTeams.push('-');
                    }

                    while (newInternalTeams.length < oldInternalTeams.length) {
                        newInternalTeams.push('-');
                    }

                    if (JSON.stringify(oldInternalTeams) === JSON.stringify(newInternalTeams)) return null

                    return (
                        <div className="changes-history-grid-item" key={`internalTeam-${i}`}>
                            <TableRowItemSpan title="Internal Teams" className="history-col-title">Internal Teams</TableRowItemSpan>
                            <p>
                                {(oldInternalTeams && oldInternalTeams.length) ? oldInternalTeams.map((internalTeam, index) => {
                                    if (JSON.stringify(oldInternalTeams[index]) === JSON.stringify(newInternalTeams[index])) return null

                                    if (internalTeam === '-') return <TableRowItemSpan
                                        key={`internalTeam-${index + 1}-before-empty`}
                                    >-</TableRowItemSpan>

                                    return (
                                        <TableRowItemSpan
                                            key={`internalTeam-${index}-before`}
                                            title={internalTeam.user_name}
                                        >
                                            {internalTeam.user_name}
                                        </TableRowItemSpan>
                                    );
                                }) : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                            </p>
                            <p>
                                {(newInternalTeams && newInternalTeams.length) ? newInternalTeams.map((internalTeam, index) => {
                                    if (JSON.stringify(newInternalTeams[index]) === JSON.stringify(oldInternalTeams[index])) return null

                                    if (internalTeam === '-') return <TableRowItemSpan
                                        key={`internalTeam-${index + 1}-after-empty`}
                                    >-</TableRowItemSpan>

                                    return (
                                        <TableRowItemSpan
                                            key={`internalTeam-${index}-after`}
                                            title={internalTeam.user_name}
                                        >
                                            {internalTeam.user_name}
                                        </TableRowItemSpan>
                                    );
                                }) : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                            </p>
                        </div>
                    );
                case 'files':
                    const oldFiles = oldValue.files
                    const newFiles = newValue.files

                    while (oldFiles.length < newFiles.length) {
                        oldFiles.push('-');
                    }

                    while (newFiles.length < oldFiles.length) {
                        newFiles.push('-');
                    }

                    if (JSON.stringify(oldFiles) === JSON.stringify(newFiles)) return null

                    return (
                        <div className="changes-history-grid-item" key={`files-${i}`}>
                            <TableRowItemSpan title="Files" className="history-col-title">Files</TableRowItemSpan>
                            <p>
                                {
                                    (oldFiles && oldFiles.length) ? oldFiles.map((file, index) => {
                                        if (JSON.stringify(oldFiles[index]) === JSON.stringify(newFiles[index])) return null

                                        if (file === '-') return <TableRowItemSpan
                                            key={`file-${index + 1}-before-empty`}
                                        >-</TableRowItemSpan>

                                        return (
                                            <TableRowItemSpan
                                                key={`file-${index}-before`}
                                            >
                                                {file ? <TooltipHtml>
                                                    {`<span class="contact-info">
                                                            <span class="color-wrap">
                                                                <p class="color-title">Attachment:</p>
                                                                <p>${file.attachment
                                                        ? <TableRowItemLinkImg
                                                            title={getString(item, key)}
                                                            href={`/${file.attachment}`}
                                                            img={`/${file.attachment}`}
                                                        >
                                                            {file.attachment}
                                                        </TableRowItemLinkImg> : <span className="projects-view__table-body-text main-table__body-text">-</span>}</p>
                                                            </span>
                                                            <span class="color-wrap">
                                                                <p class="color-title">Data:</p>
                                                                <p>${file.date ? formatDateToLocal(file.date, "MMM DD, YYYY h:mm A") : <span className="projects-view__table-body-text main-table__body-text">-</span>}</p>
                                                            </span>
                                                            <span class="color-wrap">
                                                                <p class="color-title">Status:</p>
                                                                <p>${file.status ? generateStatus(file.status) : <span className="projects-view__table-body-text main-table__body-text">-</span>}</p>
                                                            </span>
                                                        </span>`}
                                                </TooltipHtml> : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                                            </TableRowItemSpan>
                                        );
                                    }) : <span className="projects-view__table-body-text main-table__body-text">-</span>
                                }
                            </p>
                            <p>
                                {
                                    (newFiles && newFiles.length) ? newFiles.map((file, index) => {
                                        if (JSON.stringify(newFiles[index]) === JSON.stringify(oldFiles[index])) return null

                                        if (file === '-') return <TableRowItemSpan
                                            key={`file-${index + 1}-after-empty`}
                                        >-</TableRowItemSpan>

                                        return (
                                            <TableRowItemSpan
                                                key={`file-${index}-after`}
                                            >
                                                {file ? <TooltipHtml>
                                                    {`<span class="contact-info">
                                                            <span class="color-wrap">
                                                                <p class="color-title">Attachment:</p>
                                                                <p>${file.attachment
                                                        ? <TableRowItemLinkImg
                                                            title={getString(item, key)}
                                                            href={`/${file.attachment}`}
                                                            img={`/${file.attachment}`}
                                                        >
                                                            {file.attachment}
                                                        </TableRowItemLinkImg> : '-'}</p>
                                                            </span>
                                                            <span class="color-wrap">
                                                                <p class="color-title">Date:</p>
                                                                <p>${file.date ? formatDateToLocal(file.date, "MMM DD, YYYY h:mm A") : '-'}</p>
                                                            </span>
                                                            <span class="color-wrap">
                                                                <p class="color-title">Status:</p>
                                                                <p>${file.status ? generateStatus(file.status) : '-'}</p>
                                                            </span>
                                                        </span>`}
                                                </TooltipHtml> : '-'}
                                            </TableRowItemSpan>
                                        );
                                    }) : <span className="projects-view__table-body-text main-table__body-text">-</span>
                                }
                            </p>
                        </div>
                    );
                case 'grades':
                    const oldGrades = oldValue.grades
                    const newGrades = newValue.grades

                    while (oldGrades.length < newGrades.length) {
                        oldGrades.push('-');
                    }

                    while (newGrades.length < oldGrades.length) {
                        newGrades.push('-');
                    }

                    if (JSON.stringify(oldGrades) === JSON.stringify(newGrades)) return null

                    return (
                        <div className="changes-history-grid-item" key={`grade-${i}`}>
                            <TableRowItemSpan title="Grades" className="history-col-title">Grades</TableRowItemSpan>
                            <p>
                                {
                                    (oldGrades && oldGrades.length) ? oldGrades.map((grade, index) => {
                                        if (JSON.stringify(oldGrades[index]) === JSON.stringify(newGrades[index])) return null

                                        if (grade === '-') return <TableRowItemSpan
                                            key={`grade-${index + 1}-before-empty`}
                                        >-</TableRowItemSpan>

                                        return (
                                            <TableRowItemSpan
                                                key={`grade-${index}-before`}
                                            >
                                                {grade ? <TooltipHtml>
                                                    {`<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Product Name:</p>
                                                            <p>${grade.grade_name ? grade.grade_name : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Actual Sale Price:</p>
                                                            <p>${grade.actualSalePrice ? grade.actualSalePrice : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Final Price:</p>
                                                            <p>${grade.finalPrice ? grade.finalPrice : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Percent of Product:</p>
                                                            <p>${grade.percentOfProduct ? grade.percentOfProduct : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Rebate:</p>
                                                            <p>${grade.rebate ? grade.rebate : '-'}</p>
                                                        </span>
                                                    </span>`}
                                                </TooltipHtml> : '-'}
                                            </TableRowItemSpan>
                                        );
                                    }) : <span className="projects-view__table-body-text main-table__body-text">-</span>
                                }
                            </p>
                            <p>
                                {
                                    (newGrades && newGrades.length) ? newGrades.map((grade, index) => {
                                        if (JSON.stringify(newGrades[index]) === JSON.stringify(oldGrades[index])) return null

                                        if (grade === '-') return <TableRowItemSpan
                                            key={`grade-${index + 1}-after-empty`}
                                        >-</TableRowItemSpan>

                                        const oldGradeName  = oldGrades[index].grade_name ? oldGrades[index].grade_name : '-'
                                        const oldActualSalePrice  = oldGrades[index].actualSalePrice ? oldGrades[index].actualSalePrice : '-'
                                        const oldFinalPrice  = oldGrades[index].finalPrice ? oldGrades[index].finalPrice : '-'
                                        const oldPercentOfProduct  = oldGrades[index].percentOfProduct ? oldGrades[index].percentOfProduct : '-'
                                        const oldRebate  = oldGrades[index].rebate ? oldGrades[index].rebate : '-'

                                        const newGradeName  = grade.grade_name ? grade.grade_name : '-'
                                        const newActualSalePrice  = grade.actualSalePrice ? grade.actualSalePrice : '-'
                                        const newFinalPrice  = grade.finalPrice ? grade.finalPrice : '-'
                                        const newPercentOfProduct  = grade.percentOfProduct ? grade.percentOfProduct : '-'
                                        const newRebate  = grade.rebate ? grade.rebate : '-'

                                        return (
                                            <TableRowItemSpan
                                                key={`grade-${index}-after`}
                                            >
                                                {grade ? <TooltipHtml>
                                                    {`<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Product Name:</p>
                                                            <p>${grade.grade_name ? grade.grade_name : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Actual Sale Price:</p>
                                                            <p>${grade.actualSalePrice ? grade.actualSalePrice : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Final Price:</p>
                                                            <p>${grade.finalPrice ? grade.finalPrice : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Percent of Product:</p>
                                                            <p>${grade.percentOfProduct ? grade.percentOfProduct : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Rebate:</p>
                                                            <p>${grade.rebate ? grade.rebate : '-'}</p>
                                                        </span>
                                                    </span>`}
                                                </TooltipHtml> : <span className="projects-view__table-body-text main-table__body-text">-</span>}

                                                <button className="changes-modal-btn cost-modal__calculator_btn" onClick={() => {
                                                    setModal({
                                                        open: true,
                                                        title: 'Grades History Changes',
                                                        oldContent: oldGrades[index] ? `<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Product Name:</p>
                                                            <p>${oldGrades[index].grade_name ? oldGrades[index].grade_name : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Actual Sale Price:</p>
                                                            <p>${oldGrades[index].actualSalePrice ? oldGrades[index].actualSalePrice : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Final Price:</p>
                                                            <p>${oldGrades[index].finalPrice ? oldGrades[index].finalPrice : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Percent of Product:</p>
                                                            <p>${oldGrades[index].percentOfProduct ? oldGrades[index].percentOfProduct : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Rebate:</p>
                                                            <p>${oldGrades[index].rebate ? oldGrades[index].rebate : '-'}</p>
                                                        </span>
                                                    </span>` : '-',
                                                        newContent: grade ? `<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Product Name:</p>
                                                            <p class="${oldGradeName !== newGradeName ? 'highlight' : ''}">${grade.grade_name ? grade.grade_name : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Actual Sale Price:</p>
                                                            <p class="${oldActualSalePrice !== newActualSalePrice ? 'highlight' : ''}">${grade.actualSalePrice ? grade.actualSalePrice : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Final Price:</p>
                                                            <p class="${oldFinalPrice !== newFinalPrice ? 'highlight' : ''}">${grade.finalPrice ? grade.finalPrice : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Percent of Product:</p>
                                                            <p class="${oldPercentOfProduct !== newPercentOfProduct ? 'highlight' : ''}">${grade.percentOfProduct ? grade.percentOfProduct : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Rebate:</p>
                                                            <p class="${oldRebate !== newRebate ? 'highlight' : ''}">${grade.rebate ? grade.rebate : '-'}</p>
                                                        </span>
                                                    </span>` : '-'
                                                    })
                                                }}
                                                >
                                                    Details
                                                </button>
                                            </TableRowItemSpan>
                                        );
                                    }) : <span className="projects-view__table-body-text main-table__body-text">-</span>
                                }
                            </p>
                        </div>
                    );
                case 'forecasts':
                    const oldForecasts = oldValue.forecasts
                    const newForecasts = newValue.forecasts

                    while (oldForecasts.length < newForecasts.length) {
                        oldForecasts.push('-');
                    }

                    while (newForecasts.length < oldForecasts.length) {
                        newForecasts.push('-');
                    }

                    if (JSON.stringify(oldForecasts) === JSON.stringify(newForecasts)) return null

                    return (
                        <div className="changes-history-grid-item" key={`forecast-${i}`}>
                            <TableRowItemSpan title="Forecasts" className="history-col-title">Forecasts</TableRowItemSpan>
                            <p>
                                {
                                    oldForecasts.length ? oldForecasts.map((forecast, index) => {
                                        if (JSON.stringify(oldForecasts[index]) === JSON.stringify(newForecasts[index])) return null

                                        if (forecast === '-') return <TableRowItemSpan
                                            key={`forecast-${index + 1}-before-empty`}
                                        >-</TableRowItemSpan>

                                        return (
                                            <TableRowItemSpan
                                                key={`forecast-${index}-before`}
                                            >
                                                {forecast ? <TooltipHtml>
                                                    {`<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Forecast Manufacturer Name:</p>
                                                            <p>${forecast.forecastManufacturerName ? forecast.forecastManufacturerName : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Date Moved to Current:</p>
                                                            <p>${forecast.dateMovedToCurrent ? formatDateToLocal(forecast.dateMovedToCurrent.date, "MMM DD, YYYY h:mm A") : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Percent of Units:</p>
                                                            <p>${forecast.percentOfUnits ? forecast.percentOfUnits : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Units Amount:</p>
                                                            <p>${forecast.unitsAmount ? forecast.unitsAmount : '-'}</p>
                                                        </span>
                                                    </span>`}
                                                </TooltipHtml> : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                                            </TableRowItemSpan>
                                        );
                                    }) : <span className="projects-view__table-body-text main-table__body-text">-</span>
                                }
                            </p>
                            <p>
                                {
                                    newForecasts.length ? newForecasts.map((forecast, index) => {
                                        if (JSON.stringify(newForecasts[index]) === JSON.stringify(oldForecasts[index])) return null

                                        if (forecast === '-') return <TableRowItemSpan
                                            key={`forecast-${index + 1}-after-empty`}
                                        >-</TableRowItemSpan>

                                        const oldForecastManufacturerName = oldForecasts[index].forecastManufacturerName ? oldForecasts[index].forecastManufacturerName : '-'
                                        const oldDateMovedToCurrent = oldForecasts[index].dateMovedToCurrent ? formatDateToLocal(oldForecasts[index].dateMovedToCurrent.date, "MMM DD, YYYY h:mm A") : '-'
                                        const oldPercentOfUnits = oldForecasts[index].percentOfUnits ? oldForecasts[index].percentOfUnits : '-'
                                        const oldUnitsAmount = oldForecasts[index].unitsAmount ? oldForecasts[index].unitsAmount : '-'

                                        const newForecastManufacturerName = forecast.forecastManufacturerName ? forecast.forecastManufacturerName : '-'
                                        const newDateMovedToCurrent = forecast.dateMovedToCurrent ? formatDateToLocal(forecast.dateMovedToCurrent.date, "MMM DD, YYYY h:mm A") : '-'
                                        const newPercentOfUnits = forecast.percentOfUnits ? forecast.percentOfUnits : '-'
                                        const newUnitsAmount = forecast.unitsAmount ? forecast.unitsAmount : '-'

                                        return (
                                            <TableRowItemSpan
                                                key={`forecast-${index}-after`}
                                            >
                                                {forecast ? <TooltipHtml>
                                                    {`<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Forecast Manufacturer Name:</p>
                                                            <p>${forecast.forecastManufacturerName ? forecast.forecastManufacturerName : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Date Moved to Current:</p>
                                                            <p>${forecast.dateMovedToCurrent ? formatDateToLocal(forecast.dateMovedToCurrent.date, "MMM DD, YYYY h:mm A") : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Percent of Units:</p>
                                                            <p>${forecast.percentOfUnits ? forecast.percentOfUnits : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Units Amount:</p>
                                                            <p>${forecast.unitsAmount ? forecast.unitsAmount : '-'}</p>
                                                        </span>
                                                    </span>`}
                                                </TooltipHtml> : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                                                <button className="changes-modal-btn cost-modal__calculator_btn" onClick={() => {
                                                    setModal({
                                                        open: true,
                                                        title: 'Forecasts History Changes',
                                                        oldContent: oldForecasts[index] ? `<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Forecast Manufacturer Name:</p>
                                                            <p>${oldForecasts[index].forecastManufacturerName ? oldForecasts[index].forecastManufacturerName : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Date Moved to Current:</p>
                                                            <p>${oldForecasts[index].dateMovedToCurrent ? formatDateToLocal(oldForecasts[index].dateMovedToCurrent.date, "MMM DD, YYYY h:mm A") : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Percent of Units:</p>
                                                            <p>${oldForecasts[index].percentOfUnits ? oldForecasts[index].percentOfUnits : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Units Amount:</p>
                                                            <p>${oldForecasts[index].unitsAmount ? oldForecasts[index].unitsAmount : '-'}</p>
                                                        </span>
                                                    </span>` : '-',
                                                        newContent: forecast ? `<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Forecast Manufacturer Name:</p>
                                                            <p class="${oldForecastManufacturerName !== newForecastManufacturerName ? 'highlight' : ''}">${forecast.forecastManufacturerName ? forecast.forecastManufacturerName : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Date Moved to Current:</p>
                                                            <p class="${oldDateMovedToCurrent !== newDateMovedToCurrent ? 'highlight' : ''}">${forecast.dateMovedToCurrent ? formatDateToLocal(forecast.dateMovedToCurrent.date, "MMM DD, YYYY h:mm A") : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Percent of Units:</p>
                                                            <p class="${oldPercentOfUnits !== newPercentOfUnits ? 'highlight' : ''}">${forecast.percentOfUnits ? forecast.percentOfUnits : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Units Amount:</p>
                                                            <p class="${oldUnitsAmount !== newUnitsAmount ? 'highlight' : ''}">${forecast.unitsAmount ? forecast.unitsAmount : '-'}</p>
                                                        </span>
                                                    </span>` : '-'
                                                    })
                                                }}
                                                >
                                                    Details
                                                </button>
                                            </TableRowItemSpan>
                                        );
                                    }) : <span className="projects-view__table-body-text main-table__body-text">-</span>
                                }
                            </p>
                        </div>
                    );
                case 'volume':
                    const oldVolume = oldValue.volume
                    const newVolume = newValue.volume

                    if (JSON.stringify(oldVolume) === JSON.stringify(newVolume)) return null

                    return (
                        <Fragment key={`volume-${i}`}>
                        {oldVolume.partWeight === newVolume.partWeight ? null : <div className="changes-history-grid-item" key={`partWeight-${i}`}>
                                <TableRowItemSpan title="Part Weight" className="history-col-title">Part Weight</TableRowItemSpan>
                                <TableRowItemSpan title={oldVolume.partWeight || '-'}>{oldVolume.partWeight || '-'}</TableRowItemSpan>
                                <TableRowItemSpan title={newVolume.partWeight || '-'}>{newVolume.partWeight || '-'}</TableRowItemSpan>
                            </div>}
                        {oldVolume.partsPerUnit === newVolume.partsPerUnit ? null : <div className="changes-history-grid-item" key={`partsPerUnit-${i}`}>
                                <TableRowItemSpan title="No. of Parts/Unit" className="history-col-title">No. of Parts/Unit</TableRowItemSpan>
                                <TableRowItemSpan title={oldVolume.partsPerUnit || '-'}>{oldVolume.partsPerUnit || '-'}</TableRowItemSpan>
                                <TableRowItemSpan title={newVolume.partsPerUnit || '-'}>{newVolume.partsPerUnit || '-'}</TableRowItemSpan>
                            </div>}
                        </Fragment>
                    );
                case 'supplyChain':
                    const oldSupplyChain = oldValue.supplyChain
                    const newSupplyChain = newValue.supplyChain

                    if (JSON.stringify(oldSupplyChain) === JSON.stringify(newSupplyChain)) return null

                    return (
                        <Fragment key={`supplyChain-${i}`}>
                            {oldSupplyChain.tier1_name === newSupplyChain.tier1_name ? null : <div className="changes-history-grid-item" key={`tier1_name-${i}`}>
                                <TableRowItemSpan title="OEM Tier 1 Nickname" className="history-col-title">OEM Tier 1 Nickname</TableRowItemSpan>
                                <TableRowItemSpan title={oldSupplyChain.tier1_name || '-'}>{oldSupplyChain.tier1_name || '-'}</TableRowItemSpan>
                                <TableRowItemSpan title={newSupplyChain.tier1_name || '-'}>{newSupplyChain.tier1_name || '-'}</TableRowItemSpan>
                            </div>}
                            {oldSupplyChain.tier2_name === newSupplyChain.tier2_name ? null : <div className="changes-history-grid-item" key={`tier2_name-${i}`}>
                                <TableRowItemSpan title="OEM Tier 2 Nickname" className="history-col-title">OEM Tier 2 Nickname</TableRowItemSpan>
                                <TableRowItemSpan title={oldSupplyChain.tier2_name || '-'}>{oldSupplyChain.tier2_name || '-'}</TableRowItemSpan>
                                <TableRowItemSpan title={newSupplyChain.tier2_name || '-'}>{newSupplyChain.tier2_name || '-'}</TableRowItemSpan>
                            </div>}
                            {oldSupplyChain.molder_name === newSupplyChain.molder_name ? null : <div className="changes-history-grid-item" key={`molderNickname-${i}`}>
                                <TableRowItemSpan title="OEM Tier 3 Nickname" className="history-col-title">OEM Tier 3 Nickname</TableRowItemSpan>
                                <TableRowItemSpan title={oldSupplyChain.molder_name || '-'}>{oldSupplyChain.molder_name || '-'}</TableRowItemSpan>
                                <TableRowItemSpan title={newSupplyChain.molder_name || '-'}>{newSupplyChain.molder_name || '-'}</TableRowItemSpan>
                            </div> }
                        </Fragment>
                    );
                case 'user':
                const oldUser = oldValue.user
                const newUser = newValue.user

                    while (oldUser.length < newUser.length) {
                        oldUser.push('-');
                    }

                    while (newUser.length < oldUser.length) {
                        newUser.push('-');
                    }

                if (JSON.stringify(oldUser) === JSON.stringify(newUser)) return null

                return (
                    <div className="changes-history-grid-item" key={`user-${i}`}>
                        <TableRowItemSpan title="Files" className="history-col-title">Files</TableRowItemSpan>
                        <p>
                            {
                                (oldUser && oldUser.length) ? oldUser.map((user, index) => {
                                    if (user === '-') return <TableRowItemSpan
                                        key={`user-${index + 1}-before-empty`}
                                    >-</TableRowItemSpan>

                                    return (
                                        <TableRowItemSpan
                                            key={`user-${index}-before`}
                                        >
                                            {user ? <TooltipHtml>
                                                {`<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Title:</p>
                                                            <p>${user.title ? user.title : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Photo:</p>
                                                            <p>${user.photo
                                                                ? <TableRowItemLinkImg
                                                                    title={getString(item, key)}
                                                                    href={`/${user.photo}`}
                                                                    img={`/${user.photo}`}
                                                                >
                                                                    {user.name}
                                                                </TableRowItemLinkImg> : '-'}
                                                            </p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Status:</p>
                                                            <p>${user.status ? generateStatus(user.status) : '-'}</p>
                                                        </span>
                                                    </span>`}
                                            </TooltipHtml> : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                                        </TableRowItemSpan>
                                    );
                                }) : <span className="projects-view__table-body-text main-table__body-text">-</span>
                            }
                        </p>
                        <p>
                            {
                                (newUser && newUser.length) ? newUser.map((user, index) => {
                                    if (user === '-') return <TableRowItemSpan
                                        key={`user-${index + 1}-after-empty`}
                                    >-</TableRowItemSpan>

                                    return (
                                        <TableRowItemSpan
                                            key={`user-${index}-after`}
                                        >
                                            {user ? <TooltipHtml>
                                                {`<span class="contact-info">
                                                        <span class="color-wrap">
                                                            <p class="color-title">Title:</p>
                                                            <p>${user.title ? user.title : '-'}</p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Photo:</p>
                                                            <p>${user.photo
                                                    ? <TableRowItemLinkImg
                                                        title={getString(item, key)}
                                                        href={`/${user.photo}`}
                                                        img={`/${user.photo}`}
                                                    >
                                                        {user.name}
                                                    </TableRowItemLinkImg> : '-'}
                                                            </p>
                                                        </span>
                                                        <span class="color-wrap">
                                                            <p class="color-title">Status:</p>
                                                            <p>${user.status ? generateStatus(user.status) : '-'}</p>
                                                        </span>
                                                    </span>`}
                                            </TooltipHtml> : <span className="projects-view__table-body-text main-table__body-text">-</span>}
                                        </TableRowItemSpan>
                                    );
                                }) : <span className="projects-view__table-body-text main-table__body-text">-</span>
                            }
                        </p>
                    </div>
                );
                case 'application_name':
                    const oldApplicationCode = oldValue.application_name
                    const newApplicationCode = newValue.application_name

                    if (oldApplicationCode === newApplicationCode) return null

                    return (
                        <div className="changes-history-grid-item" key={`application_name-${i}`}>
                            <TableRowItemSpan title="Project Application Code" className="history-col-title">Project Application Code</TableRowItemSpan>
                            <TableRowItemSpan title={oldApplicationCode || '-'}>{oldApplicationCode || '-'}</TableRowItemSpan>
                            <TableRowItemSpan title={newApplicationCode || '-'}>{newApplicationCode || '-'}</TableRowItemSpan>
                        </div>
                    );
                case 'sub_segment_name':
                const oldSubSegment = oldValue.sub_segment_name
                const newSubSegment = newValue.sub_segment_name

                if (oldSubSegment === newSubSegment) return null

                return (
                    <div className="changes-history-grid-item" key={`sub_segment_name-${i}`}>
                        <TableRowItemSpan title="Sub Segment" className="history-col-title">Sub Segment</TableRowItemSpan>
                        <TableRowItemSpan title={oldSubSegment || '-'}>{oldSubSegment || '-'}</TableRowItemSpan>
                        <TableRowItemSpan title={newSubSegment || '-'}>{newSubSegment || '-'}</TableRowItemSpan>
                    </div>
                );
                default:
                    return <Fragment key={i} />;
        }
    });

    switch (key) {
        case "created_at":
            const date = item.created_at ? formatDateToLocal(item.created_at, "MMM DD, YYYY h:mm A") : '-'
            return (
                <td className="main-table__body">
                    <div className="changes-history__table-body-row main-table__body-row">
                        <TableRowItemSpan title={date}>{date}</TableRowItemSpan>
                    </div>
                </td>
            );
        case "editor":
            return (
                <td className="main-table__body">
                    <div className="changes-history__table-body-row main-table__body-row">
                        <TableRowItemLinkImg
                            title={getString(item, key)}
                            href={`/admin/users/${getString(item, "user_id")}`}
                            img={getString(item, "user_photo", "")}
                        >
                            {getString(item, key)}
                        </TableRowItemLinkImg>
                    </div>
                </td>
            );
        case "source":
            let row;
            if (item.source_value === 10) {
                row = <TableRowItemSpan title="Internal">Internal</TableRowItemSpan>;
            } else if (item.source_value === 5) {
                row = <TableRowItemSpan title="External">External</TableRowItemSpan>;
            } else {
                row = <TableRowItemSpan title="Import">Import</TableRowItemSpan>;
            }
            return (
                <td className="main-table__body">
                    <div className="changes-history__table-body-row main-table__body-row">{row}</div>
                </td>
            );
        case "field":
            let field;
            if (item.action_value === 15) {
                field = (
                    <div className="changes-history__table-body-row main-table__body-row">
                        <TableRowItemSpan title="Project Restored">Project Restored</TableRowItemSpan>
                    </div>
                );
            } else if (item.action_value === 10) {
                field = (
                    <div className="changes-history__table-body-row main-table__body-row">
                        <TableRowItemSpan title="Project Created">Project Created</TableRowItemSpan>
                    </div>
                );
            } else if (item.action_value === 5) {
                field = (
                    <div className="changes-history__table-body-row main-table__body-row">
                        {values}
                        {modal.open ? (
                            <Modal onRequestClose={closeModal}>
                                <HistoryChangesModal
                                    title={modal.title}
                                    oldContent={modal.oldContent}
                                    newContent={modal.newContent}
                                    onClose={closeModal}
                                />
                            </Modal>
                        ) : null}
                    </div>
                );
            } else {
                field = (
                    <div className="changes-history__table-body-row main-table__body-row">
                        <TableRowItemSpan title="Project Deleted">Project Deleted</TableRowItemSpan>
                    </div>
                );
            }
            return <td className="main-table__body">{field}</td>;
        default:
            return (
                <td className="main-table__body">
                    <div className="changes-history__table-body-row main-table__body-row">
                        <TableRowItemSpan
                            title={getString(item[key], key)}
                        >
                            {getString(item[key], key)}
                        </TableRowItemSpan>
                    </div>
                </td>
            );
    }
};
