import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockWrap, BlockWrapFlex, Input, WrapInput } from "Templates/Form";

const propTypes = {
    start: PropTypes.string.isRequired,
    due: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const SecondaryBlock = ({ start, due, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <BlockWrap>
            <BlockWrapFlex>
                <WrapInput label={t("Start Date")} name="start" isRequired>
                    <Input
                        placeholder={t("Start Date")}
                        type="date-select"
                        value={start}
                        onChange={value => onChangeInput(value, "start")}
                    />
                </WrapInput>
                <WrapInput label={t("Due Date")} name="due" isRequired>
                    <Input
                        placeholder={t("Due Date")}
                        type="date-select"
                        value={due}
                        onChange={value => onChangeInput(value, "due")}
                    />
                </WrapInput>
            </BlockWrapFlex>
        </BlockWrap>
    );
};

SecondaryBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        start: getStoreItem(state, "start"),
        due: getStoreItem(state, "due"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SecondaryBlock);
