import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { pagination, columns, current_total_analytics } = props;
    let { items } = props;
    const { mainState } = initialStates;

    items = items.map(item => ({
        ...item,
        current_total_analytics,
    }));

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "admin-ihs" }),
        listState: {
            ...mainState,
            items,
            pagination,
            columns,
            sort: {column: columns[2], direction: "desc"}
        },
    };

    return _configureStore(initialState, reducers);
}
