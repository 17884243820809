import Constants from "../../Constants";

export const getData = (service, dispatch) => data => {
    dispatch({ type: Constants.changesHistory.FETCH_ITEM_REQUESTED });

    const { requestData } = service;

    return requestData(data).then(
        res => dispatch({ type: Constants.changesHistory.GET_FORECAST_DATA_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.changesHistory.GET_FORECAST_DATA_FAILED, data: res });
        },
    );
};
