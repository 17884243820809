import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ModalDefault } from "Templates/Modals";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import { withServiceConsumer } from "Services/Context";
import FileList from "Services/FileList/FileList";

const { Button, Form, Title } = ModalDefault;

const propTypes = {
    onAppend: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
};

const UploadFileForm = ({ t, form, onAppend, onClose, onChange }) => {
    const disabled = form.files.length <= 0;

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <Title>{t("Add File")}</Title>
            <FileList
                name="files"
                fileLength={1}
                files={form.files}
                dropFile={e => onChange(e)}
                handleDrop={e => onChange(e)}
            />
            <Button onClick={onAppend} disabled={disabled}>
                {t("Import")}
            </Button>
        </Form>
    );
};

UploadFileForm.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(UploadFileForm);
