import { requestTable, requestAttach, deleteItem } from "../../RequestService";
import { handleScroll } from "../../EventsService";
import { getStoreItem } from "../../StoreService";
import { getActionStore } from "./StoreService";

const modifierValues = items => items;

export default id => ({
    modifierValues,
    handleScroll,
    getStoreItem,
    getActionStore,
    requestTable: requestTable(`/project/forecasts/${id}/notes`),
    requestAttach: requestAttach(`/project/forecasts/${id}/notes/add`),
    editNotesRequest: itemId => requestAttach(`/project/forecasts/${id}/notes/${itemId}/edit`),
    deleteNotesRequest: itemId => deleteItem(`/project/forecasts/${id}/notes/${itemId}/delete`)
});
