import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {getVideoPreview} from "../Services/RequestService";

const propTypes = {
    videoId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

const VideoPreview = ({ videoId, onClose }) => {
    const [videoUrl, setVideoUrl] = useState('')

    useEffect(() => {
        getVideoPreview(videoId).then((res) => {
            if (res && res[0] && res[0].presignedUrl) {
                setVideoUrl(res[0].presignedUrl)
            }
        })
    }, [videoId])

    return <div className="video-preview-modal__wrap">
        <ButtonClose className="main-modal__form-close" onClick={onClose}/>
        {videoUrl ? <video controls autoPlay controlsList="nodownload">
            <source
                src={videoUrl}
                type="video/webm" />
        </video> : null}
    </div>
}

VideoPreview.propTypes = propTypes;

export default compose(
    withServiceConsumer,
)(VideoPreview);
