import * as main from "../../index";
import { getViewItem, modifierValues } from "./TableBodyService";
import { getTableLabel, getFilter, isSortEnabled } from "./TableHeaderService";

export default {
    ...main,
    requestTable: main.requestTable("product/product-finder/my-grades"),
    getViewItem,
    modifierValues,
    getTableLabel,
    getFilter,
    isSortEnabled
};
