import {requestDelete, requestList, requestSave} from "../RequestService";
import {getStoreItem, getActionStore} from "../StoreService";
import * as FormService from "../FormService";

import {getTableLabel, getFilter, isSortEnabled} from "./TableHeaderService";
import {getViewItem, modifierValues } from "./TableBodyService";
import {handleScroll} from "Services/Scroll";

const onChange = () => {};

export default (groupId) => ({
    getActionStore: getActionStore('publications'),
    getStoreItem: getStoreItem('publications'),
    saveItems: requestSave(`product/product-finder/publication-groups/${groupId}/publications`),
    getItems: (data) => requestList(`product/product-finder/publication-groups/${groupId}/publications`, data),
    deleteItem: publicationsId => requestDelete(`product/product-finder/publication-groups/${groupId}/publications/${publicationsId}`),
    FormService,
    requestTable: (data) => requestList(`product/product-finder/publication-groups/${groupId}/publications`, data),
    getTableLabel,
    getViewItem,
    modifierValues,
    getFilter,
    isSortEnabled,
    handleScroll,
    onChange,
});