import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    bar: [],
    items: [],
    loading: false,
    errors: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.TABLE_SORT:
            return { ...state, items: data.items, sort: data.sort, pagination: data.pagination };

        case Constants.FETCH_ITEMS_REQUESTED:
        case Constants.EDIT_COST_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return { ...state, ...data, loading: false };

        case Constants.FETCH_ITEMS_FAILED:
        case Constants.EDIT_COST_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.NEXT_PAGE:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.status === data.items.status) {
                        return {
                            ...item,
                            items: _.concat(item.items, data.items.items),
                            pagination: { ...data.items.pagination },
                        };
                    }
                    return item;
                }),
                pagination: data.pagination,
                loading: false,
            };

        default:
            return state;
    }
};
