import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const CheckboxButton = ({ isDisabled, pref, htmlFor, children, value, onChange, inputClass }) => {
    const _onChange = ({ target }) => onChange(target.checked);
    return (
        <>
            <input
                type="checkbox"
                id={htmlFor}
                disabled={isDisabled}
                className={`${pref}__form-checkbox-input main-content__checkbox-input ${inputClass}`}
                checked={value}
                onChange={_onChange}
            />
            <label htmlFor={htmlFor} className={`${pref}__form-checkbox-label main-content__checkbox-label`}>
                {children}
            </label>
        </>
    );
};

CheckboxButton.defaultProps = {
    isDisabled: false,
    htmlFor: "",
    inputClass: "",
};

CheckboxButton.propTypes = {
    isDisabled: PropTypes.bool,
    htmlFor: PropTypes.string,
    inputClass: PropTypes.string,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};
export default compose(withTagDefaultProps())(CheckboxButton);
