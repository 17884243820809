import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Button } from "Templates/Default";
import Notifications from "./Notifications";
import Img from "Templates/Img/Img";

const propTypes = {
    hasNotifications: PropTypes.number.isRequired,
};

class Notification extends Component {
    state = {
        openNotifications: false,
    };

    hangleClickOnBell = () => {
        const { openNotifications } = this.state;
        if (!openNotifications) {
            document.addEventListener("click", this.hangleClickOnBell);
        } else {
            document.removeEventListener("click", this.hangleClickOnBell);
        }

        this.setState(prevState => ({ openNotifications: !prevState.openNotifications }));
    };

    render() {
        const { openNotifications } = this.state;
        const { hasNotifications } = this.props;
        return (
            <div className="main-header__wrap-bell">
                <Button
                    onClick={this.hangleClickOnBell}
                    className={`main-header__bell ${openNotifications ? "active" : ""}`}
                >
                    {hasNotifications ? (
                        <>
                            <span className="main-header__bell-circle main-circle" />
                            <i className="main-header__bell-line-1" />
                            <i className="main-header__bell-line-2" />
                            <i className="main-header__bell-line-3" />
                        </>
                    ) : null}
                    <Img img="bell" width="13" height="16" alt="" className="main-header__bell-img" />
                </Button>
                {openNotifications && <Notifications />}
            </div>
        );
    }
}

Notification.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        hasNotifications: getStoreItem(state, "hasNotifications"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Notification);
