import {deleteItem, requestAttach, requestData, requestTable} from "./RequestService";
import {getActionStore, getStoreItem} from "./StoreService";

export {
    requestTable,
    requestData,
    requestAttach,
    deleteItem,
    getStoreItem,
    getActionStore,
};
