import React, {useEffect} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas, showMessage, url, formatDate} from "Services";
import {PermissionsProps, withTagDefaultProps, CheckPermission} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {MainButton, TabWrap, MainButtonLink} from "Templates/Content";
import {Title, WrapButtons, Total} from "Templates/Titles";

import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {ContainerTable} from "Hoc/Widgets";
import {TableRowColumnWrap} from "Templates/Table/TableRowColumnWrap";
import TabCheck from "Templates/Tabs/TabСheck";
import Tabs from "Templates/Tabs/Tabs";
import TableHeadColumnWrap from "./TableHeadColumnWrap";
import Hierarchy from "./Hierarchy/Hierarchy";
import {resetFilterWithoutFetch} from "../Actions/TableActions";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const defaultProps = {
    help: '',
}

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchHierarchyItems: PropTypes.func.isRequired,
    activeTab: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    addHierarchy: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    getUrlExport: PropTypes.func.isRequired,
};

const TabView = ({request, fetchItems, fetchHierarchyItems, activeTab, onChangeTab, onClickEdit, onClickDelete, onClickCustom, t, title, help, updated_date, permissions, addHierarchy, getUrlExport}) => {
    useEffect(() => {
        activeTab === 1 ? fetchHierarchyItems(request) : fetchItems(request)
    }, [activeTab]);

    const lastUpdate = !updated_date ? null :
        (
            <>
                <div className="break"/>
                <div className="last-update">{`${t("Last SAP Update")} - ${formatDate(updated_date)}`}</div>
            </>
        );

    const onAddCompanies = () => {
        if (!permissions.includes('admin')) {
            showMessage('Email support@comopps.com to add a new customer');
            return;
        }
        url.redirect('/companies/create');
    };

    const handleReset = () => {
        url.clearHistory();
        return resetFilterWithoutFetch();
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        getUrlExport(url.getUrl('/api/companies/export', request, ''));
    }

    return (
        <>
            <Title
                title={t("Companies")}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                <WrapButtons>
                    <CheckPermission permission="companies_export">
                        {activeTab === 0 && (
                            <MainButtonLink
                                className="main-btn_primary"
                                onClick={(e) => handleButtonClick(e)}
                            >
                                {t('Export Companies')}
                            </MainButtonLink>
                        )}
                    </CheckPermission>
                    <MainButton onClick={onAddCompanies} className="main-btn_primary">
                        {t("Add New Company")}
                    </MainButton>
                </WrapButtons>
                {lastUpdate}
            </Title>
            <Tabs
                activeTab={activeTab}
                onClickTabItem={(index) => {
                    if (activeTab !== index) {
                        handleReset();
                        onChangeTab({ activeTab: index, pref: activeTab === 1 ? 'hierarchy' : 'companies' });
                    }
                }}
            >
                <TabCheck
                    label={t('Companies')}
                    titleMessage="A list of Companies. (Internal access only)"
                    permission='companies_view'
                >
                    <TabWrap>
                        <TabViewDetail
                            customIcon="icon_external_link"
                            onClickCustom={onClickCustom}
                            onClickEdit={onClickEdit}
                            onClickDelete={onClickDelete}
                        />
                    </TabWrap>
                </TabCheck>
                <TabCheck
                    label={t('Hierarchy')}
                    titleMessage="A Hierarchy list of Companies. (Internal access only)"
                    permission='companies_view'
                >
                    <Hierarchy
                        addHierarchy={addHierarchy}
                        onClickCustom={onClickCustom}
                        onClickEdit={onClickEdit}
                        onClickDelete={onClickDelete}
                    />
                </TabCheck>
            </Tabs>
        </>
    );
};

TabView.propTypes = propTypes;
TabView.defaultProps = defaultProps;

const mapStateToProps = state => {
    return {
        request: {
            filter: state.listState.filter,
            sort: state.listState.sort,
        },
        activeTab: state.listState.activeTab,
        title: state.listState.pagination.total,
        help: state.listState.help,
        updated_date: state.listState.updated_date,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        fetchHierarchyItems: getActionStore("fetchHierarchyItems", service, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        getUrlExport: getActionStore('getUrlExport', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
