import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    items: [],
    pagination: {},
    sort: {},
    columns: [],
    errors: {},
    tab: 0,
    isCompareProducts: false,
    reset: false,
    tableFilters: null,
    productsControls: null,
    propertyName: [],
    testMethod: [],
    specimenName: [],
    testCondition: [],
    manufacturing: [],
    parameter: [],
    description: [],
    showCreateFiltersModal: false,
    activeFilters: {}
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.TABLE_SORT:
            return { ...state, items: data.items, sort: data.sort, pagination: data.pagination };

        case Constants.CHANGE_COMPARE_PRODUCTS:
            return { ...state, isCompareProducts: data.some((product) => product.value) };

        case Constants.RESET_COMPARE_PRODUCTS:
            return { ...state, reset: data, tableFilters: null};

        case Constants.SET_TABLE_FILTERS:
            return { ...state, tableFilters: data};

        case Constants.SET_PRODUCTS_CONTROLS:
            return {...state, productsControls: data};

        case Constants.SET_PROPERTY_NAME_VALUE:
            return {...state, propertyName: data};

        case Constants.SET_TEST_METHOD_VALUE:
            return {...state, testMethod: data};

        case Constants.SET_SPECIMEN_NAME_VALUE:
            return {...state, specimenName: data};

        case Constants.SET_TEST_CONDITION_VALUE:
            return {...state, testCondition: data};

        case Constants.SET_MANUFACTURING_VALUE:
            return {...state, manufacturing: data};

        case Constants.SET_PARAMETER_VALUE:
            return {...state, parameter: data};

        case Constants.SET_DESCRIPTION_VALUE:
            return {...state, description: data};

        case Constants.SET_ACTIVE_FILTERS:
            return { ...state, activeFilters: data};

        case Constants.SHOW_CREATE_FILTERS_MODAL:
            return { ...state, showCreateFiltersModal: data};

        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return { ...state, ...data, loading: false };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.NEXT_PAGE:
            return { ...state, items: _.concat(state.items, data.items), pagination: data.pagination, loading: false };

        default:
            return state;
    }
};
