import { url } from "Services";
import Constants from "../Constants";

export const closeModalAction = (service, dispatch) => () => {
    dispatch({ type: Constants.CLOSE_SESSION_MODAL });
    dispatch({ type: Constants.CLOSE_TWO_FACTOR_MODAL });
};

export const submitFormAction = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_FROM_REQUEST });

    const { requestOnSubmitForm } = service;

    return requestOnSubmitForm(data).then(
        res => {
            if (res.redirect) {
                url.redirect(res.redirect);
                return;
            }

            if (res.data.twoFactor) {
                dispatch({ type: Constants.SHOW_TWO_FACTOR_MODAL, data: { res, data } });
                return;
            }

            if (res.data.length) {
                dispatch({ type: Constants.SHOW_SESSION_MODAL, data: { res, data } });
                return;
            }

            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
        },
    );
};

export const deleteSessionAction = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_FROM_REQUEST });

    const { requestOnSubmitForm } = service;

    return requestOnSubmitForm(data).then(
        res => {
            dispatch(closeModalAction(service, dispatch));
            if (res.redirect) {
                url.redirect(res.redirect);
                return;
            }

            if (res.data.length) {
                dispatch({ type: Constants.SHOW_SESSION_MODAL, data: { res, data } });
                return;
            }
            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
        },
        res => {
            dispatch(closeModalAction(service, dispatch));
            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
            alert(res.detail);
        },
    );
};

export const twoFactorAction = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_FROM_REQUEST });

    const { requestOnSubmitForm } = service;

    return requestOnSubmitForm(data).then(
        res => {
            dispatch(closeModalAction(service, dispatch));

            if (res.redirect) {
                url.redirect(res.redirect);
                return;
            }

            if (res.data.twoFactor) {
                dispatch({ type: Constants.SHOW_TWO_FACTOR_MODAL, data: { res, data } });
                return;
            }

            if (res.data.length) {
                dispatch({ type: Constants.SHOW_SESSION_MODAL, data: { res, data } });
                return;
            }

            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
        },
        res => {
            dispatch(closeModalAction(service, dispatch));
            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
            alert(res.detail);
        },
    );
};
