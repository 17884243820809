import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { TabActions } from "../Actions";
import { resetForm } from "../Actions/FormActions";

export const getStoreItem = _getStoreItem("listState");

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TabActions,
        resetForm,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
