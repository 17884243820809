import Constants from "../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.FORM_RESET });
};

export const submitForm = (service, dispatch) => data => {
    dispatch({ type: Constants.FORM_REQUESTED });
    const { formData } = service.FormService;

    return new Promise((resolve, reject) => {
        service.save(formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const openModal = (service, dispatch) => () => {
    dispatch({type: Constants.OPEN_VALIDITY_PERIOD_MODAL});
};

export const closeModal = (service, dispatch) => () => {
    dispatch({type: Constants.CLOSE_VALIDITY_PERIOD_MODAL});
};

export const updateValidityPeriodForm = (service, dispatch) => data => {
    dispatch({type: Constants.UPDATE_VALIDITY_PERIOD_FORM, data});
};

export const changeValidityPeriodForm = (service, dispatch) => (key, value) => {
    dispatch({type: Constants.CHANGE_VALIDITY_PERIOD_FORM, data: {key, value}});
};

export const editValidityPeriodForm = (service, dispatch) => data => {
    dispatch({type: Constants.EDIT_VALIDITY_PERIOD_FORM, data});
};

export const changePeriods = (service, dispatch) => data => {
    dispatch({type: Constants.CHANGE_PERIODS, data});
};