import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import Img from "Templates/Img";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    defaultFolder: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    customIcon: PropTypes.func.isRequired,
    showEditButton: PropTypes.bool.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const TableRowItem = ({
    defaultFolder,
    index,
    customIcon,
    showEditButton,
    onClickEdit,
    onClickCustom,
    onClickDelete,
    item
}) => {
    const _onClickCustom = () => {
        onClickCustom(index);
    };

    const _onClickEdit = () => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        onClickEdit(index);
    };

    const _onClickDelete = () => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        onClickDelete(index);
    };
    return (
        <TableBodyButtonsBlock>
            {customIcon(index) && (
                <TableBodyButton onClick={_onClickCustom}>
                    <Img img={customIcon(index)} />
                </TableBodyButton>
            )}
            {showEditButton && (
                <TableBodyButton onClick={_onClickEdit}>
                    <Img img="icon_edit" />
                </TableBodyButton>
            )}

            {!defaultFolder && (
                <TableBodyButton onClick={_onClickDelete}>
                    <Img img="icon_delete" />
                </TableBodyButton>
            )}
        </TableBodyButtonsBlock>
    );
};

TableRowItem.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        defaultFolder: getStoreItem(state, "view.default_folder"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableRowItem);
