import React, {Component} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps, CheckPermission} from "Hoc/Template";
import {Label} from "Templates/Form";
import {ProjectStatusBlock as StatusBlock} from "Templates/Content";
import {Ul, Li, Button, Span} from "Templates/Default";
import Img from "Templates/Img/Img";
import {Modal} from "Templates/Modals";
import StatusDateModalForm from "./Modal/StatusDateModalForm";

const propTypes = {
    projectStatus: PropTypes.string,
    changeStatus: PropTypes.func.isRequired,
    canEdit: PropTypes.bool.isRequired,
    service: PropTypes.shape({
        projectStatusList: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
                order: PropTypes.number.isRequired,
            }),
        ).isRequired
    }).isRequired,
};

const defaultProps = {
    projectStatus: "",
};

class ProjectStatusBlock extends Component {
    state = {
        showStatus: false,
        showDateStatusModal: false,
        subStatus: null,
        losDate: null
    };

    handleClickOnShowButton = () => {
        this.setState(prevState => ({showStatus: !prevState.showStatus}));
    };

    handleClose = () => {
        this.setState({showStatus: false});
    };

    handleClickOnChangeStatus = elem => {
        if (elem.order.toString() === '6') {
            this.setState({subStatus: elem.order, showDateStatusModal: true});
            return;
        }

        const {changeStatus} = this.props;
        changeStatus({sub_status: elem.order}).then(this.handleClose);
    };

    handleCloseStatusEmptyDateModal = () => {
        const {subStatus} = this.state;

        this.setState({showDateStatusModal: false});

        const {changeStatus} = this.props;
        changeStatus({sub_status: subStatus}).then(this.handleClose);
    }

    handleCloseStatusDateModal = () => {
        const {subStatus, losDate} = this.state;

        this.setState({showDateStatusModal: false});

        const {changeStatus} = this.props;
        changeStatus({sub_status: subStatus, los_date: losDate}).then(this.handleClose);
    }

    onChangeStatusDate = (value) => {
        this.setState({losDate: value});
    }

    render() {
        const {
            pref,
            projectStatus,
            service: {projectStatusList},
            t,
            canEdit
        } = this.props;
        const {showStatus, showDateStatusModal} = this.state;

        const subStatus = [...projectStatusList].find(elem => elem.label.toLowerCase() === projectStatus.toLowerCase()) || {};

        return (
            <div className={`${pref}__wrap-status`}>
                {showDateStatusModal ? (
                    <Modal onRequestClose={this.handleCloseStatusEmptyDateModal}>
                        <StatusDateModalForm
                            onClose={this.handleCloseStatusEmptyDateModal}
                            onSave={this.handleCloseStatusDateModal}
                            onChange={this.onChangeStatusDate}
                        />
                    </Modal>
                ) : null}

                <Label>{t("Project Status")}</Label>
                <div className={`${pref}__wrap-status-row`}>
                    {canEdit && (
                        <CheckPermission permission="project_crud">
                            <div className={`${pref}__wrap-status-col`}>
                                <Button
                                    className={`${pref}__wrap-status-btn ${showStatus ? "active" : ""}`}
                                    onClick={this.handleClickOnShowButton}
                                />
                                <Ul className="dropdown">
                                    {[...projectStatusList].map((elem, index) => (
                                        <Li key={`projectStatus-${index}`} className="dropdown__item">
                                            <Button
                                                className={`dropdown__link ${
                                                    subStatus.label === elem.label ? "selected" : ""
                                                }`}
                                                onClick={() => this.handleClickOnChangeStatus(elem)}
                                            >
                                                <Img img="icon_check_blue" alt="check"/>
                                                <Span>{elem.label}</Span>
                                            </Button>
                                        </Li>
                                    ))}
                                </Ul>
                            </div>
                        </CheckPermission>
                    )}
                    <StatusBlock/>
                </div>
            </div>
        );
    }
}

ProjectStatusBlock.propTypes = propTypes;
ProjectStatusBlock.defaultProps = defaultProps;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        projectStatus: getStoreItem(state, "view.subStatus"),
        canEdit: getStoreItem(state, "view.canEdit"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        changeStatus: getActionStore("changeStatusAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ProjectStatusBlock);
