import React from "react";
import PropTypes from "prop-types";
import { LinkImg } from "../Link";

const defaultProps = {
    img: "",
    title: "",
    blank: true,
};

const propTypes = {
    img: PropTypes.string,
    href: PropTypes.string.isRequired,
    title: PropTypes.string,
    blank: PropTypes.bool,
};

const TableRowItemLinkImg = ({ children, img, blank, href, title }) => {
    return (
        <LinkImg
            target={blank ? "_blank" : "_self"}
            title={title}
            href={href}
            img={img}
            additionalClassname="main-circle"
        >
            {children}
        </LinkImg>
    );
};

TableRowItemLinkImg.defaultProps = defaultProps;
TableRowItemLinkImg.propTypes = propTypes;

export default TableRowItemLinkImg;
