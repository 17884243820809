const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",

    FETCH_ITEM_REQUESTED: "FETCH_ITEM_REQUESTED",
    FETCH_ITEM_SUCCEEDED: "FETCH_ITEM_SUCCEEDED",
    FETCH_ITEM_FAILED: "FETCH_ITEM_FAILED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    /* notes tab */
    EDIT_NOTE_REQUESTED: "EDIT_NOTE_REQUESTED",
    EDIT_NOTE_SUCCEEDED: "EDIT_NOTE_SUCCEEDED",
    EDIT_NOTE_FAILED: "EDIT_NOTE_FAILED",
    DELETE_NOTE_REQUESTED: "DELETE_NOTE_REQUESTED",
    DELETE_NOTE_SUCCEEDED: "DELETE_NOTE_SUCCEEDED",
    DELETE_NOTE_FAILED: "DELETE_NOTE_FAILED"
    /* notes tab */
};
export default Constants;
