import React from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import {Title} from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
};

const Company = ({ validate, formData, old, pref, t, submitForm, resetForm }) => {
    const saveAndAddCompany = () => {
        submitForm(formData).then(
            () => {
                resetForm();
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnExitFormButton = () => {
        const form = _.omit(formData, ["old"]);
        if (JSON.stringify(old) === JSON.stringify(form)) {
            window.location.href = document.referrer;
            return;
        }

        if (window.confirm(t("Cancel?"))) {
            url.redirect("/companies");
        }
    };

    const saveCompany = () => {
        submitForm(formData).then(
            data => {
                url.redirect(`/companies/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    return (
        <>
            <Title
                title={t("Add New Company")}
                before={() => (
                    <BackLink
                        className={`${pref}__info-top-btn main-content__info-top-btn`}
                        onClick={handleClickOnExitFormButton}
                    >
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnExitFormButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <button
                        type="button"
                        className={`${pref}__left-btn main-btn main-btn_white`}
                        onClick={saveAndAddCompany}
                        disabled={!validate}
                    >
                        {t("Save & Add Another Company")}
                    </button>
                    <SubmitBlockButton disabled={!validate} onClick={saveCompany}>
                        {t("Add Company")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Company.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        old: getStoreItem(state, "old"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
        resetForm: getActionStore("resetForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Company);
