import {url} from "Services";
import Constants from "../Constants";

export const initState = {
    view: {},
    activeTab: +url.get('activeTab'),
    errors: {},
};

export const reducer = (state, action) => {
    const {data} = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            return {...state, activeTab: data, filter: {}, sort: {}};

        default:
            return state;
    }
};
