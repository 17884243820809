import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Form, BlockTitle } from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import AddressBlock from "./AddressBlock";
import GeneralBlock from "./GeneralBlock";

const defaultProps = {
    edit: false,
};

const propTypes = {
    edit: PropTypes.bool,
};

const FormBlock = ({ edit, t }) => {
    return (
        <Form>
            <GeneralBlock edit={edit} />
            <BlockTitle>{t("Physical Address")}</BlockTitle>
            <AddressBlock name="physical" />
        </Form>
    );
};

FormBlock.defaultProps = defaultProps;
FormBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(FormBlock);
