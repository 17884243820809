import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    item: {},
};

const TableBodyColumn = ({ item }) => {
    return (
        <tr>
            <td className="project-expanded-block__table">
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Project Name</p>
                    <p className="project-expanded-block__table-value" title={item.name}>{item.name}</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Customer</p>
                    <a href={`/companies/${item.customer_id}`} target="_blank" rel="noopener noreferrer"
                       className="project-expanded-block__table-value" title={item.customer}>{item.customer}</a>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Customer Owner</p>
                    <p className="project-expanded-block__table-value"
                       title={item.customer_owner}>{item.customer_owner || '-'}</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">SAP Customer Code</p>
                    <p className="project-expanded-block__table-value"
                       title={item.customer_sap_code_value}>{item.customer_sap_code_value}</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">D-U-N-S® Number</p>
                    <p className="project-expanded-block__table-value"
                       title={item.customer_duns_number_value}>{item.customer_duns_number_value}</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Invoices</p>
                    <a href={`/companies/${item.customer_id}?activeTab=4`} target="_blank" rel="noopener noreferrer"
                       className="project-expanded-block__table-value"
                       title={item.customer_invoices_count}>{item.customer_invoices_count}</a>
                </div>
            </td>
        </tr>
    );
};

TableBodyColumn.propTypes = propTypes;

TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
