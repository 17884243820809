import React from "react";
import {useSelector} from 'react-redux';
import {weightUnitName} from 'Services/weightUnitName';
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "./EventsService";
import {numberWithCommas} from "Services";

export const getBudgetTableLabel = (key, {currentFY, totalAnalytics}) => {
    const unit = useSelector(state => state.weightUnitState);
    const nextFY = +currentFY + 1;

    const tableLabel = {
        slug: "Connection Budget ID",
        product_code: "Product Code",
        application_code: "SAP Application Code",
        plant: "Selling Plant",
        material_type: "Material Type",
        detail: "Filtered Details",
        budget: "Budget",
        stretch: "Stretch",
        variance: "Budget Variances",
        actual: "Actuals",
        current: "ComOpps",
    };

    switch (key) {
        case "budget_price":
            return `<span class="font-size">FY ${currentFY} Price ($/${weightUnitName(unit.weightUnit)})</span> <br> <span class="connections-total-analitic"></span>`;
        case "budget_volume":
            return `<span class="font-size">FY ${currentFY} Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].budget_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].budget_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "budget_revenue":
            return `<span class="font-size">FY ${currentFY} Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].budget_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].budget_revenue, 0)}</span>`;

        case "stretch_volume":
            return `<span class="font-size">FY ${currentFY} Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].stretch_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].stretch_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "stretch_revenue":
            return `<span class="font-size">FY ${currentFY} Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].stretch_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].stretch_revenue, 0)}</span>`;

        case "variance_volume":
            return `<span class="font-size">FY ${currentFY} Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].variance_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].variance_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "variance_revenue":
            return `<span class="font-size">FY ${currentFY} Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].variance_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].variance_revenue, 0)}</span>`;

        case "actual_calculated_price":
            return `<span class="connection-fy-with-tooltip actuals budget-actual-row">
                        <span class="font-size whitespace-nowrap">Calculated Price <br> ($/${weightUnitName(unit.weightUnit)})</span> <br> <span class="connections-total-analitic"></span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 Price = FYTD Net Amount / FYTD Volume
                            </span>
                        </span>
                    </span>`;
        case "actual_normalized_weight":
            return `<span class="font-size">FYTD Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].actual_normalized_weight, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].actual_normalized_weight, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "actual_net_amount":
            return `<span class="font-size">FYTD Net Amount ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].actual_net_amount, 0)}">$${numberWithCommas(totalAnalytics[0].actual_net_amount, 0)}</span>`;

        case "annualized_volume":
            return `<span class="connection-fy-with-tooltip actuals">
                        <span class="font-size whitespace-nowrap">FY ${currentFY} Volume (${weightUnitName(unit.weightUnit)})</span> <br> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].annualized_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].annualized_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 Annualized volume is an estimate based on the FYTD volume actuals. Year end actual volume could vary from annualized volume.
                            </span>
                        </span>
                    </span>`;
        case "annualized_revenue":
            return `<span class="connection-fy-with-tooltip actuals">
                        <span class="font-size whitespace-nowrap">FY ${currentFY} Revenue ($)</span> <br> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].annualized_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].annualized_revenue, 0)}</span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 Annualized revenue is an estimate based on the FYTD revenue actuals. Year end actual revenue could vary from annualized revenue.
                            </span>
                        </span>
                    </span>`;

        case "current_average_sale_price":
            return `<span class="connection-fy-with-tooltip">
                        <span class="font-size">FYTD ${currentFY} <br> <span class="whitespace-nowrap">Calculated Price ($/${weightUnitName(unit.weightUnit)})</span></span> <br> <span class="connections-total-analitic"></span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 FY 2023 Price = FY 2023 Revenue / FY 2023 Volume
                            </span>
                        </span>
                    </span>`;
        case "current_volume":
            return `<span class="font-size whitespace-nowrap">FY ${currentFY} Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].current_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].current_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "current_variance_percent":
            return `<span class="connection-fy-with-tooltip variance">
                        <span class="font-size">FY ${currentFY} <span class="whitespace-nowrap">Variance (%)</span></span> <br> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].current_variance_percent, 0)} %">${numberWithCommas(totalAnalytics[0].current_variance_percent, 0)} %</span>                     
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 FY 2023 Forecast Variance (%) = (FY XXXX Forecast Volume - FY XXXX Annualized Volume) / FY XXXX Forecast Volume
                            </span>
                        </span>
                    </span>`;
        case "current_variance":
            return `<span class="connection-fy-with-tooltip variance">
                        <span class="font-size whitespace-nowrap">FY ${currentFY} Variance (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].current_variance, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].current_variance, 0)} ${weightUnitName(unit.weightUnit)}</span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                FY XXXX Forecast Variance = FY XXXX Forecast Volume - FY XXXX Annualized Volume
                            </span>
                        </span>
                    </span>`;
        case "current_revenue":
            return `<span class="font-size whitespace-nowrap">FY ${currentFY} Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].current_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].current_revenue, 0)}</span>`;
        case "current_total_rebate":
            return `<span class="font-size whitespace-nowrap">FY ${currentFY} Total Rebate ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].current_total_rebate, 0)}">$${numberWithCommas(totalAnalytics[0].current_total_rebate, 0)}</span>`;
        case "current_total_revenue":
            return `<span class="font-size whitespace-nowrap">FY ${currentFY} Total Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].current_total_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].current_total_revenue, 0)}</span>`;

        case "next_average_sale_price":
            return `<span class="connection-fy-with-tooltip">
                        <span class="font-size">FYTD ${nextFY} <br> <span class="whitespace-nowrap">Calculated Price ($/${weightUnitName(unit.weightUnit)})</span></span> <br> <span class="connections-total-analitic"></span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 FY 2024 Price = FY 2024 Revenue / FY 2024 Volume
                            </span>
                        </span>
                    </span>`;
        case "next_volume":
            return `<span class="font-size whitespace-nowrap">FY ${nextFY} Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].next_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].next_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "next_revenue":
            return `<span class="font-size whitespace-nowrap">FY ${nextFY} Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].next_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].next_revenue, 0)}</span>`;
        case "next_total_rebate":
            return `<span class="font-size whitespace-nowrap">FY ${nextFY} Total Rebate ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].next_total_rebate, 0)}">$${numberWithCommas(totalAnalytics[0].next_total_rebate, 0)}</span>`;
        case "next_total_revenue":
            return `<span class="font-size whitespace-nowrap">FY ${nextFY} Total Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].next_total_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].next_total_revenue, 0)}</span>`;

        default:
            return _.get(tableLabel, key, key);
    }
};

export const getBudgetFilter = (key, value, callback, request) => {
    const placeholders = {
        slug: "Search",
        product_code: "Search",
        application_code: "Search",
        plant: "Search",
        material_type: "Select",
        budget_price: "Select",
        budget_volume: "Select",
        budget_revenue: "Select",
        actual_calculated_price: "Select",
        actual_normalized_weight: "Select",
        actual_net_amount: "Select",
        annualized_volume: "Select",
        annualized_revenue: "Select",
        current_average_sale_price: "Select",
        current_volume: "Select",
        current_variance_percent: "Select",
        current_variance: "Select",
        current_revenue: "Select",
        current_total_rebate: "Select",
        current_total_revenue: "Select",
        next_average_sale_price: "Select",
        next_volume: "Select",
        next_revenue: "Select",
        next_total_rebate: "Select",
        next_total_revenue: "Select",
        stretch_volume: "Select",
        stretch_revenue: "Select",
        variance_volume: "Select",
        variance_revenue: "Select",
    };

    /*
        *
        * Here, onChange(e) was modified to onChange(e, key) -> the "key" is needed to remove filter from the URL after clearing the filter in InputFilter Component
        *
    */

    switch (key) {
        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/connection/budgets/slug-list"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/plant-list"
                    name="plant"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "material_type":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/material-type-list"
                    name="material_type"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback('material_type', _value, request));
                    }}
                />
            );

        case "product_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/product-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-code-list"
                    name="application_code"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "detail":
            return <div className="filters-plug"></div>;

        case "current_average_sale_price":
        case "current_volume":
        case "current_variance_percent":
        case "current_variance":
        case "current_revenue":
        case "current_total_rebate":
        case "current_total_revenue":
        case "next_average_sale_price":
        case "next_volume":
        case "next_revenue":
        case "next_total_rebate":
        case "next_total_revenue":
        case "actual_calculated_price":
        case "actual_normalized_weight":
        case "actual_net_amount":
        case "annualized_volume":
        case "annualized_revenue":
        case "budget_price":
        case "budget_volume":
        case "stretch_volume":
        case "stretch_revenue":
        case "variance_volume":
        case "variance_revenue":
        case "budget_revenue": {
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[
                        {value: '', label: "All"},
                        {value: 'zero', label: "0"},
                        {value: 'non-zero', label: "Greater than 0"}]
                    ]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );
        }

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
