import { combineReducers } from "redux";
import { getMainInitialStates, getMainReducers } from "App/Services";
import { reducer as chartReducer, initState as chartState } from "./ChartReducer";
import { reducer as chartReducer_2, initState as chartState_2 } from "./ChartReducer_2";
import { reducer as viewReducer, initState as viewState } from "./ViewReducer";
import { reducer as formReducer, initState as formState } from "./FormReducer";

const reducers = [
    chartReducer,
    chartReducer_2,
    viewReducer,
    formReducer
];

const mainState = {
    ...chartState,
    ...chartState_2,
    ...viewState,
    ...formState
};

const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }

    return state;
};

// Combine all reducers you may have here
export default combineReducers({
    ...getMainReducers,
    listState: reducer,
});

export const initialStates = {
    ...getMainInitialStates,
    mainState,
};
