import * as main from "../../index";
import {getFilter, getTableLabel, isSortEnabled} from "./TableHeaderService";
import {getViewItem, modifierValues} from "./TableBodyService";

export default {
    ...main,
    requestTable: main.requestTable("/invoice/invoices/line-by-line"),
    getFilter,
    getTableLabel,
    isSortEnabled,
    getViewItem,
    modifierValues,
};
