import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabView from "./Views/TabView";

const propTypes = {
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const Products = ({ service, fetchItems, request, t }) => {
    const { deleteItem } = service;

    // const onClickEdit = item => {
    //     url.redirect(`/product/products/${item.id}/edit`);
    // };

    const onClickShow = item => {
        const win = window.open(`/product/products/${item.id}`, "_blank");
        win.focus();
    };

    // const onClickDelete = item => {
    //     if (confirm(t("Do you want to delete the product?"))) {
    //         deleteItem(`/product/products/${item.id}/delete`).then(
    //             () => {
    //                 fetchItems(request);
    //             },
    //             errors => {
    //                 alert(errors.detail);
    //             },
    //         );
    //     }
    // };

    return <TabView onClickCustom={onClickShow} />;
};

Products.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Products);
