import React from "react";
import { getString, numberWithCommas } from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

export const modifierValues = items => items;
export const tableButtonsPermission = "plant_line_crud";

export const getViewItem = (key, item) => {

    switch (key) {
        case "scrap":
            return <TableRowItemSpan title={`${getString(item, key)}%`}>{`${getString(item, key)}%`}</TableRowItemSpan>;

        case "hours_thousand":
            return (
                <TableRowItemSpan title={`${numberWithCommas(getString(item, key))}`}>
                    {`${numberWithCommas(getString(item, key))}`}
                </TableRowItemSpan>
            );

        case "hours_required":
            return (
                <TableRowItemSpan title={`${getString(item, key)} h`}>{`${getString(item, key)} h`}</TableRowItemSpan>
            );

        case "cost_hour":
        case "cost_thousand":
        case "cost_per_lbs":
            return (
                <TableRowItemSpan title={`$${numberWithCommas(getString(item, key), 0)}`}>
                    {`$${numberWithCommas(getString(item, key), 0)}`}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
