import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import { Modal } from "Templates/Modals";
import InfoWrap from "./Views/InfoWrap";
import EditVendorForm from "../Index/Views/editVendorForm";

const propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
    editData: PropTypes.objectOf(PropTypes.any).isRequired,
    activeTab: PropTypes.number.isRequired,
    onDeleteVendor: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    openEditModal: PropTypes.func.isRequired,
    closeEditModal: PropTypes.func.isRequired
};

const Index = (
    {
        t,
        id,
        activeTab,
        onDeleteVendor,
        name,
        code,
        showModal,
        openEditModal,
        closeEditModal,
        editData,
        canEdit
    }) => {
    const onClickEdit = () => {
        if (!canEdit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        openEditModal()
    };

    const onClickDelete = () => {
        if (!canEdit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to remove the Vendor?"))) {
            onDeleteVendor(`/vendors/${id}/delete`);
        }
    }

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit} onClickDelete={onClickDelete}/>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={() => {
                    }}
                >
                    <Tab label={t('General')}/>
                    <Tab label=""/>
                </Tabs>
            </ContentWrapCol>
            {showModal ? (
                <Modal onRequestClose={closeEditModal}>
                    <EditVendorForm
                        data={{
                            name: editData.name ? editData.name : name,
                            code: editData.code ? editData.code : code
                        }}
                        vendorId={id}
                    />
                </Modal>
            ) : <div/>}
        </ContentWrap>
    );
};

Index.propTypes = propTypes;
const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        name: getStoreItem(state, "view.name"),
        code: getStoreItem(state, "view.code"),
        showModal: getStoreItem(state, "showModal"),
        activeTab: getStoreItem(state, 'activeTab'),
        editData: getStoreItem(state, "editData"),
        canEdit: getStoreItem(state, "view.can_edit")
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onDeleteVendor: getActionStore('onDeleteVendor', service, dispatch),
        openEditModal: getActionStore("openEditModal", service, dispatch),
        closeEditModal: getActionStore("closeEditModal", service, dispatch)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
