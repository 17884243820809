import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { storageUrl } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import ContainerTable from "./Table/ContainerTable";
import TableHeadColumnWrap from "./Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import {customTable} from "./Table/TabViewDetail";
import {SharedAgreements} from "./SharedAgreements";
import {Modal} from "Templates/Modals";
import {requestShareAgreements} from "../../../Services/RequestService";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {
    storage_url: PropTypes.string.isRequired,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
    editTeam: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
};

class Agreements extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sharedModalData: {
                isOpen: false,
                agreement: {}
            }
        }
    }
    render = () => {
        const {
            t,
            fetchItems,
            request,
            editTeam,
            handleOpenModal,
            service: { onClickDeleteItem },
        } = this.props;

        const onClickDelete = item => {
            if (!item.can_edit) {
                alert('Contact the owner or an Admin to delete.');
                return;
            }

            if (window.confirm(t("Do you want to delete the Agreement?"))) {
                onClickDeleteItem(item).then(
                    () => {
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        };

        const onClickEdit = item => {
            if (!item.can_edit) {
                alert('Contact the owner or an Admin to edit.');
                return;
            }

            editTeam(item);
            handleOpenModal();
        };

        const onClickCustom = item => {
            if (!item.can_edit) {
                alert('Contact the owner or an Admin to download.');
                return;
            }

            const { storage_url } = this.props;
            window.open(storageUrl(_.get(item, "path"), storage_url), "_blank");
        };

        const _onClickShare = (item) => {
            this.setState({
                sharedModalData: {
                    isOpen: true,
                    agreement: item
                }
            })
        }

        const closeModal = () => {
            this.setState({
                sharedModalData: {
                    isOpen: false,
                    agreement: {}
                }
            })
        }

        const handleShareFile = async (id, type, selectedUsers, sharedWithUsersList) => {
            await requestShareAgreements({id, selectedUsers, sharedWithUsersList}).then(() => {
                alert(`Agreement successfully shared!`)
            }).catch(() => {
                alert(`Error! Cannot shared ${type}.`)
            })
        }

        const { sharedModalData } = this.state;

        return (
            <>
                <TabViewDetail
                    onClickCustom={onClickCustom}
                    customIcon="icon_download"
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    onClickShare={_onClickShare}
                />
                {sharedModalData.isOpen ? <Modal className="main-modal shared-files__modal" onRequestClose={closeModal}>
                    <SharedAgreements t={t} data={sharedModalData.agreement} onClose={closeModal} shareFile={handleShareFile}/>
                </Modal> : null}
            </>
        );
    };
}

Agreements.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        editTeam: getActionStore("editTeamAction", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Agreements);
