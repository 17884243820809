import {weightUnitConvertLbs, weightUnitName} from "Services/weightUnitName";

export const AllTableColumns = (unit, currentFY) => {
    const nextFY = +currentFY + 1;

    return {
        // Dropdowns filters fields
        slug: "Connection ID",
        company_short_name: "Customer Name",
        company: "Customer Full Name",
        customer_code: "Customer Code",
        customer_owner: "Customer Owner",
        customer_duns_number: "D-U-N-S® Number",
        sap_customer: "SAP Customer",
        sap_grade: "SAP Product",
        grade: "Product Name",
        grade_sub_status: "Product Status",
        material_type: "Material Type",
        sap_application_code: "SAP Application Code",
        material_number: "SAP Material Number",
        product_code: "Product Code",
        project_slug: "Project ID",
        project_name: "Project Name",
        part_weight: `Part Weight (${weightUnitConvertLbs(weightUnitName(unit.weightUnit))})`,
        sales_manager: "Sales Manager (ZSales)",
        note: "Notes",
        note_finance: "B&S Notes",
        project_manager: "Sales Manager (Project)",
        project_internal_team: "Internal Team (Project)",
        project_sub_status: "Project Status",
        los_date: "Loss of Sales Date",
        move_to_current: "Start Date",
        end_date: "End Date",
        sop: "SOP",
        eop: "EOP",
        plant: "Selling Plant",
        budget: "Budget",
        stretch: "Stretch",
        variance: "Budget Variances",
        actual: "Actuals",
        current: "ComOpps",

        // text filters fields
        price: "Price",
        rebate: "Rebate",
        final_price: "Final Price",
        budget_price: `Budget FY ${+currentFY} Price ($/${weightUnitName(unit.weightUnit)})`,
        budget_volume: `Budget FY ${+currentFY} Volume (${weightUnitName(unit.weightUnit)})`,
        budget_revenue: `Budget FY ${+currentFY} Revenue (${weightUnitName(unit.weightUnit)})`,
        actual_calculated_price: `Actuals Calculated Price ($/${weightUnitName(unit.weightUnit)})`,
        actual_normalized_weight: `Actuals FYTD Volume (${weightUnitConvertLbs(weightUnitName(unit.weightUnit))})`,
        actual_net_amount: "Actuals FYTD Net Amount ($)",
        annualized_volume: `Annualized FY ${+currentFY} Volume (${weightUnitName(unit.weightUnit)})`,
        annualized_revenue: `Annualized FY ${+currentFY} Revenue (${weightUnitName(unit.weightUnit)})`,
        current_average_sale_price: `Current FYTD ${+currentFY} Calculated Price ($/${weightUnitName(unit.weightUnit)})`,
        current_volume: `Current FY ${+currentFY} Volume (${weightUnitName(unit.weightUnit)})`,
        current_variance_percent: `Current FY ${+currentFY} Variance (%)`,
        current_variance: `Current FY ${+currentFY} Variance (${weightUnitName(unit.weightUnit)})`,
        current_revenue: `Current FY ${+currentFY} Revenue ($)`,
        current_total_rebate: `Current FY ${+currentFY} Total Rebate ($)`,
        current_total_revenue: `Current FY ${+currentFY} Total Revenue ($)`,
        next_average_sale_price: `Next FYTD ${+nextFY} Calculated Price ($/${weightUnitName(unit.weightUnit)})`,
        next_volume: "Next Volume",
        next_revenue: "Next Revenue",
        next_total_rebate: "Next Total Rebate",
        next_total_revenue: "Next Total Revenue",
    }
}