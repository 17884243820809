import React, {useEffect} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {ServiceProvider, withServiceConsumer} from 'Services/Context';
import {CheckPermission, withTagDefaultProps} from 'Hoc/Template';
import {url} from 'Services';
import {Modal} from 'Templates/Modals';
import Tabs from 'Templates/Tabs/Tabs';
import TabCheck from 'Templates/Tabs/TabСheck';
import {ContentWrap, ContentWrapCol, TabBlockBtn,} from 'Templates/Content';
import myListService from './Services/Tabs/MyList';
import usersHistoryService from './Services/Tabs/UsersHistory';
import sharedProductService from './Services/Tabs/SharedProduct';
import sharedListService from './Services/Tabs/SharedList';
import InfoWrap from './Views/InfoWrap';
import SharedProduct from './Views/Tabs/SharedProduct';
import SharedList from './Views/Tabs/SharedList';
import MyListView from './Views/Tabs/MyList';
import InviteModalForm from './Views/InviteModalForm';
import ShareFiltersModal from './Views/ShareFiltersModal';
import AddPublicationListModalForm from "./Views/AddPublicationListModalForm";
import Img from "Templates/Img";
import AddSharedProductsPublicationListModal from "../Index/Views/AddSharedProductsPublicationListModal";
import {Div} from "Templates/Default";
import UsersHistory from "./Views/Tabs/UsersHistory";
import WelcomeGuestModal from "./Views/WelcomeGuestModal";
import WelcomeSharedListGuestModal from "./Views/WelcomeSharedListGuestModal";

const Grade = (
    {
        activeTab,
        onChangeTab,
        t,
        items,
        permissions,
        showModal,
        shareFiltersShowModal,
        handleCloseModal,
        handleOpenModal,
        handleOpenShareFiltersModal,
        handleCloseShareFiltersModal,
        handleOpenWelcomeGuestModal,
        handleCloseWelcomeGuestModal,
        welcomeGuestShowModal,
        temporaryCloseGuestModal,
        form,
        filterForm,
        onChange,
        onChangeFilterForm,
        filterType,
        resetFilterWithoutFetch,
        request,
        addPublicationListShowModal,
        handleCloseAddPublicationListModal,
        handleOpenAddPublicationListModal,
        addSharedProductsPublicationListShowModal,
        handleCloseAddSharedProductsPublicationsToList,
        handleAddSharedProductsPublicationsToList,
        addPublicationListForm,
        onChangeAddPublicationListForm,
        showSharedModalChecked
    }) => {
    const { filter } = request;

    const services = {
        0: sharedProductService,
        1: myListService,
        2: sharedListService,
        3: usersHistoryService
    };

    if (permissions.includes('product_publication_groups_view') && !permissions.includes('product_publications_guest') && activeTab < 1) {
        activeTab = 2;
        onChangeTab(activeTab);
    } else if (!permissions.includes('product_publication_groups_view') && !permissions.includes('product_publications_guest') && activeTab < 2) {
        activeTab = 1;
        onChangeTab(activeTab);
    }

    const handleReset = () => {
        url.clearHistory();
        return resetFilterWithoutFetch();
    }

    if (permissions.length < 3
        && (permissions.includes('product_publications_guest') || permissions.includes('product_publication_groups_view'))
        && !localStorage.getItem('WelcomeGuestModalWatched')
        && !welcomeGuestShowModal
        && !temporaryCloseGuestModal
    ) {
        handleOpenWelcomeGuestModal();
    }

    const closeWelcomeGuestModal = () => {
        if(showSharedModalChecked) {
            localStorage.setItem('WelcomeGuestModalWatched', !welcomeGuestShowModal);
        }
        handleCloseWelcomeGuestModal();
    }

    return (
        <>
            {showModal ? (
                <Modal onRequestClose={handleCloseModal}>
                    <InviteModalForm
                        onChange={onChange}
                        form={form}
                        onClose={handleCloseModal}
                    />
                </Modal>
            ) : null}
            {shareFiltersShowModal && (
                <Modal onRequestClose={handleCloseShareFiltersModal}>
                    <ShareFiltersModal
                        onChange={onChangeFilterForm}
                        filterForm={filterForm}
                        filter={request.filter}
                        sort={request.sort}
                        type={filterType}
                        onClose={handleCloseShareFiltersModal}
                    />
                </Modal>
            )}

            {welcomeGuestShowModal && activeTab !== 2 && (
                <Modal onRequestClose={closeWelcomeGuestModal}>
                    <WelcomeGuestModal
                        onClose={closeWelcomeGuestModal}
                    />
                </Modal>
            )}
            {welcomeGuestShowModal && activeTab === 2 && (
                <Modal onRequestClose={closeWelcomeGuestModal}>
                    <WelcomeSharedListGuestModal
                        onClose={closeWelcomeGuestModal}
                    />
                </Modal>
            )}


            <ContentWrap>
                <ServiceProvider value={services[activeTab]}>
                    <InfoWrap activeTab={activeTab}/>
                </ServiceProvider>
                <ContentWrapCol>
                    <div className="d-flex flex-col h-100">
                        <div className="product-finder-actions">
                            <div
                                className={`d-flex ${Object.keys(filter).length !== 0 ? 'shared-filters-btns' : ''}`}
                            >
                                {activeTab !== 2 && Object.keys(filter).length !== 0 && (
                                    <TabBlockBtn
                                        title="Invite users to view filtered products and approved publications in ComOpps."
                                        onClick={handleOpenShareFiltersModal}
                                        className="main-btn_primary main-btn_primary-share-filters ml-auto"
                                    >
                                        {t('Share Filter')}
                                    </TabBlockBtn>
                                )}
                            </div>
                            {activeTab === 0 && !_.isEmpty(request.filter) && items.length > 0 && (
                                <>
                                    <TabBlockBtn
                                        title="Add Publications to List."
                                        onClick={handleAddSharedProductsPublicationsToList}
                                        className="main-btn_primary main-btn_primary-share-filters add-publication-btn"
                                    >
                                        {t('Add Publications to List')}
                                        <Img img={'icon-list'}></Img>
                                    </TabBlockBtn>
                                    {addSharedProductsPublicationListShowModal ?
                                        <ServiceProvider value={myListService}><Modal
                                            onRequestClose={handleCloseAddSharedProductsPublicationsToList}>
                                            <AddSharedProductsPublicationListModal filters={request.filter}
                                                                                   onClose={handleCloseAddSharedProductsPublicationsToList}/>
                                        </Modal></ServiceProvider>
                                        : <Div/>
                                    }
                                </>
                            )}


                            {activeTab === 0 && permissions.includes("product_share_button") && (
                                <TabBlockBtn
                                    title="Invite guest users to view filtered products and approved publications in ComOpps."
                                    onClick={handleOpenModal}
                                    className="main-btn_primary"
                                >
                                    {t('Share Products')}
                                </TabBlockBtn>
                            )}
                            <CheckPermission permission="product_publication_groups_edit">
                                {activeTab === 1 && (
                                    <TabBlockBtn
                                        title="Add List."
                                        onClick={handleOpenAddPublicationListModal}
                                        className="main-btn_primary"
                                    >
                                        {t('Add List')}
                                    </TabBlockBtn>
                                )}
                            </CheckPermission>
                        </div>
                        <Tabs
                            activeTab={activeTab}
                            onClickTabItem={(index) => {
                                if (activeTab !== index) {
                                    handleReset();
                                    onChangeTab(index);
                                }
                            }}
                        >
                            <TabCheck
                                label={t('Shared Products')}
                                titleMessage="All products with approved publications for sharing."
                                permission="product_publications_guest"
                            >
                                <ServiceProvider value={sharedProductService}>
                                    <SharedProduct/>
                                </ServiceProvider>
                            </TabCheck>

                            <TabCheck
                                label={t('My Lists')}
                                titleMessage="User generated list for sharing."
                                permission="product_publication_groups_edit">
                                <ServiceProvider value={myListService}>
                                    <MyListView/>
                                    {addPublicationListShowModal && (
                                        <Modal onRequestClose={handleCloseModal}>
                                            <AddPublicationListModalForm onChange={onChangeAddPublicationListForm}
                                                                         form={addPublicationListForm}
                                                                         onClose={handleCloseAddPublicationListModal}/>
                                        </Modal>
                                    )}
                                </ServiceProvider>
                            </TabCheck>

                            <TabCheck
                                label={t('Shared With Me')}
                                titleMessage="Lists shared with me."
                                permission="product_publication_groups_view"
                            >
                                <ServiceProvider value={sharedListService}>
                                    <SharedList/>
                                </ServiceProvider>
                            </TabCheck>

                            <TabCheck
                                label={t('User Activity')}
                                titleMessage="User Activity."
                                permission="product_publication_groups_edit"
                            >
                                <ServiceProvider value={usersHistoryService}>
                                    <UsersHistory />
                                </ServiceProvider>
                            </TabCheck>
                        </Tabs>
                    </div>
                </ContentWrapCol>
            </ContentWrap>
        </>
    );
};

Grade.defaultProps = {
    items: []
}

Grade.propTypes = {
    activeTab: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.any),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    showModal: PropTypes.bool.isRequired,
    shareFiltersShowModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    welcomeGuestShowModal: PropTypes.bool.isRequired,
    temporaryCloseGuestModal: PropTypes.bool.isRequired,
    handleOpenShareFiltersModal: PropTypes.func.isRequired,
    handleCloseShareFiltersModal: PropTypes.func.isRequired,
    handleOpenWelcomeGuestModal: PropTypes.func.isRequired,
    handleCloseWelcomeGuestModal: PropTypes.func.isRequired,
    resetFilterWithoutFetch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeFilterForm: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filterForm: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    filterType: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    addSharedProductsPublicationListShowModal: PropTypes.bool.isRequired,
    handleCloseAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    handleAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    addPublicationListShowModal: PropTypes.bool.isRequired,
    handleCloseAddPublicationListModal: PropTypes.func.isRequired,
    handleOpenAddPublicationListModal: PropTypes.func.isRequired,
    addPublicationListForm: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeAddPublicationListForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service }) => {
    const { getStoreItem } = service;
    const activeTab = getStoreItem(state, 'activeTab');
    let filterType;
    if (activeTab === 0) {
        filterType = 5;
    } else {
        filterType = 10
    }

    return {
        activeTab,
        items: getStoreItem(state, 'items'),
        showSharedModalChecked: getStoreItem(state, 'showSharedModalChecked'),
        permissions: getStoreItem(state, 'permissions'),
        showModal: getStoreItem(state, 'showModal'),
        welcomeGuestShowModal: getStoreItem(state, 'welcomeGuestShowModal'),
        temporaryCloseGuestModal: getStoreItem(state, 'temporaryCloseGuestModal'),
        shareFiltersShowModal: getStoreItem(state, 'shareFiltersShowModal'),
        addPublicationListShowModal: getStoreItem(state, 'addPublicationListShowModal'),
        addSharedProductsPublicationListShowModal: getStoreItem(state, 'addSharedProductsPublicationListShowModal'),
        isFirstLogin: getStoreItem(state, 'isFirstLogin'),
        addPublicationListForm: { ...getStoreItem(state, 'addPublicationListForm', true) },
        filterType,
        form: { ...getStoreItem(state, 'form', true) },
        filterForm: { ...getStoreItem(state, 'filterForm', true) },
        request: {
            filter: {
                ...getStoreItem(state, 'filter', true),
            },
            sort: {
                ...getStoreItem(state, 'sort'),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore('onChangeTab', service, dispatch),
        handleCloseModal: getActionStore('handleCloseModal', service, dispatch),
        handleOpenModal: getActionStore('handleOpenModal', service, dispatch),
        resetFilterWithoutFetch: getActionStore('resetFilterWithoutFetch', service, dispatch),
        handleOpenShareFiltersModal: getActionStore('handleOpenShareFiltersModal', service, dispatch),
        handleCloseShareFiltersModal: getActionStore('handleCloseShareFiltersModal', service, dispatch),
        handleCloseWelcomeGuestModal: getActionStore('handleCloseWelcomeGuestModal', service, dispatch),
        handleOpenWelcomeGuestModal: getActionStore('handleOpenWelcomeGuestModal', service, dispatch),
        handleCloseAddSharedProductsPublicationsToList: getActionStore('handleCloseAddSharedProductsPublicationsToList', service, dispatch),
        handleAddSharedProductsPublicationsToList: getActionStore('handleAddSharedProductsPublicationsToList', service, dispatch),
        handleCloseAddPublicationListModal: getActionStore('handleCloseAddPublicationListModal', service, dispatch),
        handleOpenAddPublicationListModal: getActionStore('handleOpenAddPublicationListModal', service, dispatch),
        onChangeAddPublicationListForm: getActionStore('onChangeAddPublicationListForm', service, dispatch),
        updateForm: getActionStore('updateForm', service, dispatch),
        onChange: getActionStore('onChangeForm', service, dispatch),
        onChangeFilterForm: getActionStore('onChangeFilterForm', service, dispatch),
        getUrlExport: getActionStore('getUrlExport', service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Grade);
