import _ from "lodash";
import Constants from "../../Constants";
import {uniqueGenerator} from "Services";

const validate = ({generalForm: {grade}}) => {
    const checkString = el => typeof el === "string" && el.trim();
    return [grade].every(checkString);
};

export const initialState = {
    generalForm: {
        grade: "",
        materialNumber: "",
        productCode: ""
    },
    molded: {
        yes: false,
        yes_desc: '',
        no: false,
        no_desc: '',
        can_be_matched: false,
        can_be_matched_desc: ''
    },
    is_boundable: '',
    paintable: '',
    locations: [{ frontId: 0, value: "" }],
    colors: [{frontId: 0, color: '', items: [{frontId: 0, approval: '', code: '', source: ''}]}],
    colorsPartApproval: [{frontId: 0, color: '', items: [{frontId: 0, approval: '', code: '', note: ''}]}],
    sapForm: [],
    isSap: false,
    form_validate: false,
    form_loading: false,
    form_errors: {},
};


export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = { ...state };

            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState)};
        }

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };
        default:
            return state;
    }
};
