import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../EventsService";

const placeholders = {
    name: "Product Name",
    product_name: "Brands",
    product_type: "Product Code",
    owner: "Company",
    revenue: "Revenue",
    volume: "Volume",
    gross_margin: "Gross Margin",
    margin: "Operating Margin",
    tensile_strength: "Tensile Strength",
    tensile_elasticity: "Tensile Modulus of Elasticity",
    tensile_elongation: "Tensile Elongation",
    flexural_strength: "Flexural Strengh",
    flexural_elasticity: "Flexural Modulus of Elasticity",
    compressive_strength: "Compressive Strength",
    izod_impact: "IZOD Impact (ff-lb/in)",
    hardness: "Hardness",
    specific_gravity: "Specific Gravity",
    water_absorption: "Water Absorption",
    coefficient_friction: "Coefficient of Friction",
    clte: "CLTE",
    heat_temperature: "Heat Deflection Temperature",
    shrinkage: "Shrinkage",
    light_transmittance: "Light Transmittance",
    haze: "Haze",
    dielectric_strength: "Dielectric Streght",
};

export const getFilter = () => {
    return null;
};
