import * as main from "../../../Services";

export default {
    ...main,
    save: (publicationId, brochureId) => main.requestCreate(`/admin/publications/${publicationId}/safeties/${brochureId}/edit`),
    saveColor: main.requestCreate(`/product/product-finder/color/add`),
    requestLegal: main.requestData(),
    requestContentTemplate: main.requestData(),
    getStoreItem: main.getStoreItem("editState"),
};
