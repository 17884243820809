import React from "react";
import { useDispatch } from 'react-redux';

import { compose } from "redux";
import PropTypes from "prop-types";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import { withTagDefaultProps } from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import { url } from "Services";
import InfoWrap from "./Views/InfoWrap";

import _productGradesService from "./Services/Tabs/ProductGrades";
import _productionLinesService from "./Services/Tabs/ProductionLines";
import _analyticsGradesService from "./Services/Tabs/Analytics";

import ProductGrades from "./Views/Tabs/ProductGrades";
import ProductionLines from "./Views/Tabs/ProductionLines";
import Analytics from "./Views/Tabs/Analytics";
import { activeWeightUnit } from "../../../../App/View/Header/Items/Units/Actions/ConverterUnitsAction";

const Plants = ({ id, activeTab, t, onChangeTab }) => {
    const dispatch = useDispatch();
    const onClickEdit = () => {
        url.redirect(`/product/plants/${id}/edit`);
    };

    const productGradesService = _productGradesService(id);
    const productionLinesService = _productionLinesService(id);
    const analyticsGradesService = _analyticsGradesService(id);

    dispatch(activeWeightUnit([2].includes(activeTab)));

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit} />
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index) => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Production Lines")}>
                        <ServiceProvider value={productionLinesService}>
                            <ProductionLines />
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Grades")}>
                        <ServiceProvider value={productGradesService}>
                            <ProductGrades />
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Analytics")}>
                        <ServiceProvider value={analyticsGradesService}>
                            <Analytics />
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Plants.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Plants);
