import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Img from "Templates/Img";
import {formatDateToLocal, request, url} from "Services";
import AnalitycsBlock from "./Blocks/AnalitycsBlock";
import ProjectStatusBlock from "../../Templates/ProjectStatusBlock";
import GeneralBlock from "./Blocks/GeneralBlock";
import ProductBlock from "./Blocks/ProductBlock";
import MarketSegmentsBlock from "./Blocks/MarketSegment";
import ForecastBlock from "./Blocks/ForecastBlock";
import PartWeightUnitBlock from "./Blocks/PartWeightUnitBlock";
import SupplyChainBlock from "./Blocks/SupplyChainBlock";
import ProjectTeamContactsBlock from "./Blocks/ProjectTeamContactsBlock";
import SpecificationsBlock from "./Blocks/SpecificationsBlock";
import DescriptionBlock from "./Blocks/DescriptionBlock";
import Constants from "../../../Constants";

const propTypes = {
    id: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
    showFull: PropTypes.bool.isRequired,
    getData: PropTypes.func.isRequired,
    service: PropTypes.shape({
        requestData: PropTypes.func,
    }).isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const defaultProps = {};

class General extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    componentDidMount() {
        const {getData} = this.props;
        getData();
    }

    onClickEdit(id) {
        url.redirect(`/project/projects/${id}/edit`);
    };

    async setApproval(id) {
        const sendObj = {
            url: url.getUrl(`/project/projects/${id}/approve-budget-and-sales`),
            type: "POST",
        };

        return request.sendRequest(sendObj);
    }

    render() {
        const {
            id,
            general,
            pref,
            canEdit,
            showFull,
            permissions,
            getData,
            isExpandedBlocks,
            expandedBlocks,
            setExpanded
        } = this.props;
        const {expanded} = this.state;
        const expandedList = expandedBlocks

        const setExpandedAction = ({ block, expand }) => {
            expandedList[block] = expand

            if (Object.values(expandedList).every((v) => v === true)) {
                setExpanded(Constants.SET_EXPANDED)
            } else if (Object.values(expandedList).every((v) => v === false)) {
                setExpanded(Constants.SET_COLLAPSED)
            }
        }

        return expandedBlocks ? (
            <div className={`${pref}__wrap-body main-wrap-block__body`}>
                <div className="projects-view__new-top">
                    <AnalitycsBlock/>
                    <div className="projects-view__new-top-buttons">
                        {isExpandedBlocks ? (
                            <button
                                type="button"
                                className="button-collapse"
                                onClick={() => {
                                    Object.values(expandedList).forEach((val) => val = false)

                                    setExpanded(Constants.SET_COLLAPSED)
                                }}
                            >
                                Collapse All
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="button-collapse"
                                onClick={() => {
                                    Object.values(expandedList).forEach((val) => val = true)

                                    setExpanded(Constants.SET_EXPANDED)
                                }}
                            >
                                Expand All
                            </button>
                        )}
                        {permissions.includes("project_crud") && canEdit ? (
                            <button
                                type="button"
                                className="button-edit"
                                onClick={() => this.onClickEdit(id)}
                            >
                                <Img img="icon_edit"/>
                                Edit
                            </button>
                        ) : null}
                    </div>
                </div>
                {general ? (
                    <div className="project-view__approvals-block">
                        <div className="project-view__approvals-block_actions">
                            {general.added_to_sales_forecast ? (
                                <button
                                    type="button"
                                    className={`toggle-btn ${expanded ? 'expanded' : ''}`}
                                    onClick={() => this.setState({expanded: !expanded})}
                                >
                                    Approval Details
                                    <Img img="expand-icon" width="24" height="24"/>
                                </button>
                            ) : null}
                            {canEdit && showFull ?
                                (<button
                                    type="button"
                                    className="approval-btn"
                                    onClick={() => this.setApproval(id).then(() => {
                                        getData()
                                        this.setState({expanded: false})
                                    })}
                                >
                                    {`${general.added_to_sales_forecast ? 'Disapprove' : 'Approve'}`} for Budget & Sales Forecast
                                </button>) : null
                            }
                        </div>
                        <div className={`project-view__approvals-block_expanded ${expanded ? 'show' : ''}`}>
                            <div className="approved-checkbox-indicator">
                                <Img img="disabled-checkbox-icon" width="20" height="20"/>
                                <p>Approved for Budget & Sales Forecast</p>
                            </div>
                            <p className="approved-title">Approved by</p>
                            <a
                                className="approved-name"
                                href={`/admin/users/${general.added_by_sales_forecast}`}
                            >
                                {general.added_by_sales_forecast_name}
                            </a>
                            <p className="approved-date">{formatDateToLocal(general.added_date_to_sales_forecast, "MMM DD, YYYY h:mm A")}</p>
                        </div>
                    </div>
                ) : null}
                <div className="projects-view__new-status">
                    <ProjectStatusBlock/>
                </div>
                <GeneralBlock
                    block="general"
                    expanded={expandedBlocks.general}
                    setExpandedAction={setExpandedAction}
                />
                <ProductBlock
                    block="product"
                    expanded={expandedBlocks.product}
                    setExpandedAction={setExpandedAction}
                />
                <MarketSegmentsBlock
                    block="marketSegment"
                    expanded={expandedBlocks.marketSegment}
                    setExpandedAction={setExpandedAction}
                />
                <ForecastBlock
                    block="forecast"
                    isExpanded={expandedBlocks.forecast}
                    setExpandedAction={setExpandedAction}
                />
                <PartWeightUnitBlock
                    block="part"
                    expanded={expandedBlocks.part}
                    setExpandedAction={setExpandedAction}
                />
                <SupplyChainBlock
                    block="supplyChain"
                    expanded={expandedBlocks.supplyChain}
                    setExpandedAction={setExpandedAction}
                />
                <ProjectTeamContactsBlock
                    block="project"
                    expanded={expandedBlocks.project}
                    setExpandedAction={setExpandedAction}
                />
                <SpecificationsBlock
                    block="specifications"
                    expanded={expandedBlocks.specifications}
                    setExpandedAction={setExpandedAction}
                />
                <DescriptionBlock
                    block="description"
                    expanded={expandedBlocks.description}
                    setExpandedAction={setExpandedAction}
                />
            </div>
        ) : null;
    }
}

General.defaultProps = defaultProps;
General.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    if (state && state.listState && state.listState.items && state.listState.items.general) {
        return {
            id: state.listState.view.id,
            general: state.listState.items.general,
            isExpandedBlocks: state.listState.isExpandedBlocks,
            expandedBlocks: state.listState.expandedBlocks,
            canEdit: getStoreItem(state, "view.canEdit"),
            showFull: getStoreItem(state, "view.showFull"),
        }
    }

    return {
        id: state.listState.view.id,
        canEdit: getStoreItem(state, "view.canEdit"),
        showFull: getStoreItem(state, "view.showFull"),
    }
}

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        getData: getActionStore("getData", service, dispatch),
        setExpanded: getActionStore("setExpanded", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(General);
