import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";
import {initState} from "../Reducers/ViewReducer";
import _ from "lodash";

export default function configureStore(props) {
    const {
        subTabs,
        view,
        totalTechnicals,
        totalProcesses,
        totalDesigns,
        totalSafeties,
        totalBrochures
    } = props;
    const { mainState } = initialStates;
    const productItems = subTabs[0] ? subTabs[0].product_items : {};

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "publication-view" }),
        listState: {
            ...mainState,
            subTabs,
            items: productItems.items || [],
            pagination: productItems.pagination || {},
            columns: productItems.columns || [],
            view,
            sort: { column: productItems.columns ? productItems.columns[0] : '', direction: "asc" },
            activeSubTab: subTabs[0],
            totalTechnicals,
            totalProcesses,
            totalDesigns,
            totalSafeties,
            totalBrochures,
        },
    };

    if (initState.activeTab) {
        _.unset(initialState.listState, "subTabs");
    }

    return _configureStore(initialState, reducers);
}
