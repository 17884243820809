import _ from "lodash";
import { formDataNormalizer } from "Services";
import { checkProtocol } from "Services/Url";

export const formData = (data) => {
    const socials = _.get(data, "socials", []);
    const phones = _.get(data, "phones", []).filter(item => _.trim(item.type) && _.trim(item.number));

    const dataReq = {
        customerCode: _.trim(_.get(data, "customerCode")),
        customerOwner: _.trim(_.get(data, "customerOwner")),
        name: _.get(data, "name"),
        dunsNumber: _.get(data, "dunsNumber"),
        logo: _.get(data, "logo"),
        email: _.get(data, "email"),
        url: checkProtocol(_.get(data, "url")),
        phones,
        parentCompanyId: _.get(data, "parentCompanyId"),
        nicknames: _.get(data, "nicknames", []).filter(item => _.trim(item.value)),

        physicalCountry: _.get(data, "physicalCountry"),
        physicalCity: _.get(data, "physicalCity"),
        physicalAddress1: _.get(data, "physicalAddress1"),
        physicalZip: _.get(data, "physicalZip"),
        physicalState: _.get(data, "physicalState"),
        physicalDistrict: _.get(data, "physicalDistrict"),
        physicalTimeZone: _.get(data, "physicalTimeZone"),
        socials: socials.filter(item => item && _.trim(item.value)),
    };

    return formDataNormalizer(dataReq);
};
