import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {formatDate} from "Services";
import {
    SingleContent,
    SingleColFirst,
    SinglePreviewRow,
    SinglePreviewCol,
    SinglePreview
} from "Templates/SinglePreview";
import {LinkImg} from "Templates/Link";
import parse from 'html-react-parser';

const propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    publish: PropTypes.string.isRequired,
    account_type: PropTypes.string.isRequired,
    owner: PropTypes.objectOf(PropTypes.any).isRequired,
    date: PropTypes.string.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

const InfoWrap = ({description, owner, publish, account_type, date, t}) => {
    return (
        <SingleContent>
            <SingleColFirst>
                <SinglePreview>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Last Modified")}</SinglePreviewCol>
                        <SinglePreviewCol>{formatDate(date)}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Owner")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <LinkImg img={owner.img} href={`/admin/users/${owner.id}`}>
                                {owner.name}
                            </LinkImg>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow className="help-page__single-preview__description">
                        <SinglePreviewCol>{t("Description")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <div id="css-render-form">{parse(description)}</div>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow className="help-page__single-preview__description">
                        <SinglePreviewCol>{t("Published")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <div id="css-render-form">{parse(publish)}</div>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow className="help-page__single-preview__description">
                        <SinglePreviewCol>{t("Account Type")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <div id="css-render-form">{parse(account_type)}</div>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                </SinglePreview>
            </SingleColFirst>
        </SingleContent>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        name: getStoreItem(state, "view.name"),
        description: getStoreItem(state, "view.description"),
        publish: getStoreItem(state, "view.publish"),
        owner: getStoreItem(state, "view.owner"),
        account_type: getStoreItem(state, "view.account_type"),
        date: getStoreItem(state, "view.date"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
