import React, {useEffect} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    getChartData: PropTypes.func.isRequired,
};

const HeadDashboardCard = ({ pref, getChartData }) => {
    useEffect(() => {
        getChartData('all')
    }, [])

    return (
        <div className={`${pref}__card-head`}>
        </div>
    );
};

HeadDashboardCard.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {

    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        getChartData: getActionStore("getChartDataAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(HeadDashboardCard);
