import React from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { marginList } from "Services/enum";
import { withServiceConsumer, ServiceProvider } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {Title, Total} from "Templates/Titles";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import {Block, Tooltip} from "Templates/Form";
import { Div } from "Templates/Default";
import BarChart from "./Forecasts/BarChart";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import ShowPermission from "Hoc/Template/ShowPermission";
import TabCheck from "Templates/Tabs/TabСheck";
import Table from "./Forecasts/Tabs/Templates/Table/Table";

import _productsService from "../Services/Tabs/ProductsService";
import ProductsFormBlock from "./Forecasts/Tabs/Products/FormBlock";

import _customersService from "../Services/Tabs/CustomersService";
import CustomersFormBlock from "./Forecasts/Tabs/Customers/FormBlock";

import _personnelService from "../Services/Tabs/PeopleService";
import PeopleFormBlock from "./Forecasts/Tabs/People/FormBlock";

import _marketTrendsService from "../Services/Tabs/MarketTrendsService";
import MarketTrendsFormBlock from "./Forecasts/Tabs/MarketTrends/FormBlock";

import _projectsService from "../Services/Tabs/ProjectsService";
import ProjectsFormBlock from "./Forecasts/Tabs/Projects/FormBlock";

import _applicationService from "../Services/Tabs/ApplicationService";
import ApplicationFormBlock from "./Forecasts/Tabs/Application/FormBlock";
import Img from "Templates/Img";

const defaultProps = {
    selectedValue: "",
    help: "",
};

const propTypes = {
    selectedValue: PropTypes.string,
    help: PropTypes.string,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    expandRow: PropTypes.func.isRequired,
    onChangeConnectionType: PropTypes.func.isRequired,
    onChangeActualsType: PropTypes.func.isRequired,
};

const MyForecastView = ({ activeTab, selectedValue, onChangeTab, resetForm, onChangeConnectionType, onChangeActualsType, t, weightUnit, help, expandRow }) => {
    const productsService = _productsService();
    const customersService = _customersService();
    const peopleService = _personnelService();
    const marketTrendsService = _marketTrendsService();
    const projectsService = _projectsService();
    const applicationService = _applicationService();

    const handleChangeTab = index => {
        resetForm();
        onChangeTab(index);
    };

    const viewYAsix = value => {
        const selected = marginList.find(elem => elem.value === selectedValue);
        if (typeof _.get(selected, "viewFormat") === "function") {
            return `${selected.viewFormat(value, weightUnit.weightUnit)}`;
        }
        return numberWithCommas(value);
    };

    const tooltipLabel = (tooltipItem, data) => {
        const selected = marginList.find(elem => elem.value === selectedValue);
        const name = data.datasets[tooltipItem.datasetIndex].label || "";
        return `${name}: ${selected.viewFormat(tooltipItem.value, weightUnit.weightUnit)}`;
    };

    return (
    <ShowPermission checkPermission={['forecast_graph_view']}>
        <div className={`forecast-view__wrap-main main-content__wrap`}>
            <div className={`forecast-view__wrap-col main-content__wrap-col`}>
                <div className="forecast-explorer__expand-title">
                    <Title
                        title={t("Interactive Analytics")}
                        help={help}
                        total={() => (
                            <Total>
                                <div className="gray-text">
                                    <Tooltip>
                                        {t(`Use this interactive tool to explore and run forecast scenarios based on multiple parameters for deeper insights.`)}
                                    </Tooltip>
                                </div>
                            </Total>
                        )}
                    />
                    <button type="button" className="expand-button" onClick={() => {
                        expandRow()
                        resetForm()
                        onChangeConnectionType(false)
                        onChangeActualsType(false)
                    }}>
                        <Img img="expand-icon" width="24" height="24"/>
                    </button>
                </div>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            handleChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Products")}>
                        <ServiceProvider value={productsService}>
                            <Div className="main-wrap-block__body">
                                <div className="forecast-view__form-block main-content__form-block">
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <ProductsFormBlock />
                                </div>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Customers")}>
                        <ServiceProvider value={customersService}>
                            <Div className="main-wrap-block__body">
                                <div className="forecast-view__form-block main-content__form-block">
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <CustomersFormBlock />
                                </div>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <TabCheck label={t("People")} permission={'forecast_people_analytics_view'} >
                        <ServiceProvider value={peopleService}>
                            <Div className="main-wrap-block__body">
                                <div className="forecast-view__form-block main-content__form-block">
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <PeopleFormBlock />
                                </div>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </TabCheck>
                    <Tab label={t("Market Trends")}>
                        <ServiceProvider value={marketTrendsService}>
                            <Div className="main-wrap-block__body">
                                <div className="forecast-view__form-block main-content__form-block">
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <MarketTrendsFormBlock />
                                </div>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Projects")} permission={'forecast_project_analytics_view'}>
                        <ServiceProvider value={projectsService}>
                            <Div className="main-wrap-block__body">
                                <div className="forecast-view__form-block main-content__form-block">
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <ProjectsFormBlock />
                                </div>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Applications")}>
                        <ServiceProvider value={applicationService}>
                            <Div className="main-wrap-block__body">
                                <div className="forecast-view__form-block main-content__form-block">
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <ApplicationFormBlock />
                                </div>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </div>
        </div>
    </ShowPermission>
    );
};

MyForecastView.defaultProps = defaultProps;
MyForecastView.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        activeTab: getStoreItem(state, "activeTab"),
        selectedValue: getStoreItem(state, "old.marginListSelect"),
        weightUnit: state.weightUnitState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        resetForm: getActionStore("resetForm", service, dispatch),
        onChangeConnectionType: getActionStore("onChangeConnectionType", service, dispatch),
        onChangeActualsType: getActionStore("onChangeActualsType", service, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MyForecastView);
