import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import { countryListObj, stateListObj } from "Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const {
        id,
        name,
        logo,
        logoView,
        email,
        code,
        number,
        start_fy,
        end_fy,
        physicalCity,
        physicalAddress1,
        physicalAddress2,
        physicalZip,
    } = props;
    
    const invertCountryListObj = _.invert(countryListObj);
    const invertStateListObj = _.invert(stateListObj);

    const initData = {
        id,
        name,
        logo,
        logoView,
        email,
        code: code || '+1',
        number,
        start_fy,
        end_fy,
        physicalCountry: _.get(invertCountryListObj, _.get(props, "physicalCountry")),
        physicalCity,
        physicalAddress1,
        physicalAddress2,
        physicalState: _.get(invertStateListObj, _.get(props, "physicalState"), ""),
        physicalZip,
    };

    const { editState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "edit-company" }),
        editState: {
            ...editState,
            form_validate: true,
            ...initData,
            old: { ..._.cloneDeep(initData) },
        },
    };

    return _configureStore(initialState, reducers);
}
