import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const MainButtonLinkComponent = ({ pref, children, href, className, onClick, target }) => {
    return (
        <a
          href={href}
          className={`${pref}__btn main-btn main-btn__general ${className ? `${className}` : ""}`}
          onClick={onClick}
          target={target}
          rel="noopener noreferrer"
        >
            {children}
        </a>
    );
};

MainButtonLinkComponent.defaultProps = {
    href: "#",
    className: "",
    onClick: undefined,
    target: "_self"
};

MainButtonLinkComponent.propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
};

export const MainButtonLink = compose(withTagDefaultProps())(MainButtonLinkComponent);

const MainButtonComponent = ({ pref, children, disabled, className, onClick }) => {

    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onClick}
            className={`${pref}__btn main-btn main-btn__general ${className ? `${className}` : ""}`}
        >
            {children}
        </button>
    );
};

MainButtonComponent.defaultProps = {
    className: "",
    disabled: false,
    onClick: () => {},
};

MainButtonComponent.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export const MainButton = compose(withTagDefaultProps())(MainButtonComponent);
