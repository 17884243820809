import React from "react";

const startStatusIcon = (
    <svg
        className="status-icon status-icon--right"
        width="24"
        height="40"
        viewBox="0 0 24 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 40V0H2.50388L22.6186 17.4096C24.4906 19.0298 24.4546 21.9281 22.5428 23.5021L2.50388 40H0Z"
        />
    </svg>
);

const middleStatusIcons = (
    <>
        <svg
            className="status-icon status-icon--left"
            width="24"
            height="40"
            viewBox="0 0 24 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H24V0Z"
            />
        </svg>
        <svg
            className="status-icon status-icon--right"
            width="24"
            height="40"
            viewBox="0 0 24 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 40V0H2.50388L22.6186 17.4096C24.4906 19.0298 24.4546 21.9281 22.5428 23.5021L2.50388 40H0Z"
            />
        </svg>
    </>
);

const endStatusIcon = (
    <svg
        className="status-icon status-icon--left"
        width="24"
        height="40"
        viewBox="0 0 24 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H24V0Z"
        />
    </svg>
);

export const renderStatusIcons = (currentIndex, lastIndexElement) => {
    switch (true) {
        case currentIndex === 0:
            return startStatusIcon;

        case currentIndex + 1 === lastIndexElement:
            return endStatusIcon;

        default:
            return middleStatusIcons;
    }
};
