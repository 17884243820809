import { request, url } from "Services";

const listUrl = "product/plants";

const getItems = data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = data => {
    return new Promise(resolve => {
        getItems(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
            });
        });
    });
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};
