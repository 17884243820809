import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer, ServiceProvider } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { numWithSub } from "Services";
import Column from "../Templates/Column";
import Row from "../Templates/Row";
import DashboardCard from "./Card/Widget/DashboardCard";
import ProjectsDataDashboardCard from "./Card/Table/Projects/DataDashboardCard";
import TasksHeadDashboardCard from "./Card/Table/Tasks/HeadDashboardCard";
import TasksDataDashboardCard from "./Card/Table/Tasks/DataDashboardCard";
import TableTasksService from "../Services/TableTasksService";
import TableProjectsService from "../Services/TableProjectsService";

const propTypes = {
    tasks: PropTypes.number.isRequired,
    financialYear: PropTypes.string.isRequired,
    volume: PropTypes.string.isRequired,
    projects: PropTypes.number.isRequired,
    service: PropTypes.shape({ getStoreItem: PropTypes.func.isRequired }).isRequired,
};

const InfoDashboardCard = DashboardCard({});
const TasksDashboardCard = DashboardCard({
    HeadDashboardCard: TasksHeadDashboardCard,
    DataDashboardCard: TasksDataDashboardCard,
});
const ProjectsDashboardCard = DashboardCard({ DataDashboardCard: ProjectsDataDashboardCard });

const DashboardTypeTwo = ({ tasks, financialYear, volume, projects, service: { getStoreItem }, t }) => {
    return (
        <div className="dashboard__wrap-main main-content__wrap">
            <div className="dashboard__list">
                <Row>
                    <Column columnSize={1} fromColumns={3}>
                        <InfoDashboardCard headProps={{ title: t("Tasks (Overdue)") }} dataProsp={{ value: tasks, className: "content_center" }} />
                    </Column>
                    <Column columnSize={1} fromColumns={3}>
                        <InfoDashboardCard
                            headProps={{ title: t("My Forecast Volume (Current)"), subtitle: t(`FY ${financialYear}`) }}
                            dataProsp={{ value: `${numWithSub(Number(volume))}`, className: "content_center" }}
                        />
                    </Column>
                    <Column columnSize={1} fromColumns={3}>
                        <InfoDashboardCard headProps={{ title: t("My Projects (Total Number)") }} dataProsp={{ value: projects, className: "content_center" }} />
                    </Column>
                </Row>
                <Row>
                    <Column columnSize={1} fromColumns={2}>
                        <ServiceProvider value={{ ...TableTasksService(), getStoreItem }}>
                            <TasksDashboardCard className="main-data" headProps={{ title: t("My Tasks") }} />
                        </ServiceProvider>
                    </Column>
                    <Column columnSize={1} fromColumns={2}>
                        <ServiceProvider value={{ ...TableProjectsService(), getStoreItem }}>
                            <ProjectsDashboardCard
                                className="main-data"
                                headProps={{ title: t("My Projects (Current)"), subtitle: t(`FY ${financialYear}`) }}
                            />
                        </ServiceProvider>
                    </Column>
                </Row>
            </div>
        </div>
    );
};

DashboardTypeTwo.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        tasks: getStoreItem(state, "dashboard.analytic.tasks"),
        financialYear: getStoreItem(state, "financialYear"),
        projects: getStoreItem(state, "dashboard.analytic.projects"),
        volume: getStoreItem(state, "dashboard.analytic.volume"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DashboardTypeTwo);
