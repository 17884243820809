import React from "react";
import { getString, numberWithCommas, getStoreItem as _getStoreItem } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { weightUnitName } from 'Services/weightUnitName';

const getStoreItem = _getStoreItem("listState.plants");

const tableLabel = {
    name: "Plant Name",
    address: "Address",
    revenue: "Revenue",
    volume: "Volume",
    gross_margin: "Gross Margin",
};

const title = "Plants";

const getTableLabel = key => getString(tableLabel, key, key);

const getViewItem = (key, item, weightUnit) => {

    switch (key) {
        case "name":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/product/plants/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "address":
            return (
                <TableRowItemSpan title={getString(item, key)} className="max-width-60">
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "revenue":
            return <TableRowItemSpan>{`$${numberWithCommas(getString(item, key))}`}</TableRowItemSpan>;

        case "volume":
            return <TableRowItemSpan>{`${numberWithCommas(getString(item, key))} ${weightUnitName(weightUnit.weightUnit)}`}</TableRowItemSpan>;

        case "gross_margin":
            return (
                <TableRowItemSpan
                    title={getString(item, key)}
                    className={`${getString(item, key) < 0 ? "clr-red" : ""}`}
                >
                    {`${getString(item, key)}%`}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};

export default searchValue => ({
    link: `/search/plants?value=${searchValue}`,
    getStoreItem,
    title,
    getTableLabel,
    getViewItem,
});
