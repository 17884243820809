import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    inputProps: PropTypes.shape({ isOptionDisabled: PropTypes.func }),
};

const defaultProps = {
    disabled: false,
    placeholder: "",
    value: "",
    inputProps: { isOptionDisabled: option => option.disable },
};

const renderTitle = (options, value) => {
    let title = null;

    options.reduce((acc, option) => {
        if (option.value) {
            if (option.value == value) {
                title = option.label;
            }
        }
        if (option.options && Array.isArray(option.options)) {
            const result = option.options.find(item => item.value == value);

            if (typeof result !== "undefined") {
                title = result.label;
            }
        }
    }, []);

    return title;
};

const SearchSelect = ({ disabled, value, showEmptyOption, placeholder, options, onChange, inputProps }) => {

    if(showEmptyOption && !options.filter(obj => !obj.value).length){
        options.unshift({value: '', label: '-'});
    }

    return (
        <div title={value ? renderTitle(options, value) : null} className={'select-title'}>
        <Select
        className="select-custom-wrap"
        isDisabled={disabled}
        menuPosition="fixed"
        // closeMenuOnScroll={e => !e.target.parentNode.className.includes("menu")}
        closeMenuOnScroll={e => !(e.target.parentNode || {className: ''}).className.includes("menu")}
        value={options.reduce((acc, option) => {
            if (option.value) {
                if (option.value === value) {
                    return [...acc, option];
                }
                return acc;
            }
            if (option.options && Array.isArray(option.options)) {
                const result = option.options.find(item => item.value === value);
                if (typeof result !== "undefined") {
                    return [...acc, result];
                }
            }
            return acc;
        }, [])}
        onChange={value => onChange(value.value)}
        options={options}
        placeholder={placeholder}
        {...inputProps}
    />
        </div>
)};

SearchSelect.propTypes = propTypes;
SearchSelect.defaultProps = defaultProps;

export default SearchSelect;
