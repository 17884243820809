import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import {withServiceConsumer} from "Services/Context";
import TableRowColumnDefault from "Templates/Table/TableRowColumnWrap/Default";
import TableRowItem from "./TableRowItem";

const propTypes = {
    column: PropTypes.string.isRequired,
    pinned_columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    customIcon: PropTypes.func.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
    onClickCustom: PropTypes.func.isRequired,
};

const TableRowColumn = ({
                            index,
                            customIcon,
                            column,
                            pinned_columns,
                            secondaryIndex,
                            item,
                            onClickCustom,
                        }) => {
    return (
        <Fragment key={`TableRowColumn-${index}-${secondaryIndex}`}>
            <td className={`
                ${column === "-1" ? "main-table__body--sticky" : ""}
                ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `td pinned-column ${column}` : ''}
                main-table__body
            `}>
                <TableRowColumnTemplate>
                    {column === "-1" ? (
                        <TableRowItem
                            item={item}
                            customIcon={customIcon}
                            onClickCustom={onClickCustom}
                            index={secondaryIndex}
                        />
                    ) : (
                        <TableRowColumnDefault column={column} item={item}/>
                    )}
                </TableRowColumnTemplate>
            </td>
        </Fragment>
    );
};

TableRowColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableRowColumn);
