import { reducer as tableReducer, initState as tableState } from "./TableReducer";
import { reducer as filterReducer, initState as filterState } from "./FilterReducer";

const reducers = [tableReducer, filterReducer];

const mainState = {
    ...tableState,
    ...filterState,
};

export const reducerKey = "modal";

export const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }

    return state;
};

export const initialState = mainState;
