import { request, url } from "Services";

const postItem = listUrl => {
    const sendObj = {
        url: url.getUrl(listUrl),
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const postRequest = listUrl => {
    return new Promise(resolve => {
        postItem(listUrl).then(resolve);
    });
};
