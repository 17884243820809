import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";

const propTypes = {
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
    editTeam: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
};

class Color extends Component {
    render = () => {
        const {
            t,
            fetchItems,
            request,
            editTeam,
            handleOpenModal,
            service: {onClickDeleteItem},
        } = this.props;

        const onClickDelete = item => {
            if (window.confirm(t("Do you want to delete the Color Code?"))) {
                onClickDeleteItem(item).then(
                    () => {
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        };

        const onClickEdit = item => {
            editTeam(item);
            handleOpenModal();
        };

        return (
            <>
                <TabViewDetail
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                />
            </>
        );
    };
}

Color.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        editTeam: getActionStore("editTeamAction", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Color);
