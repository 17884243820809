import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import { numberWithCommas, url } from "Services";
import Agreements from "./Views/Tabs/Agreements";
import SharedAgreements from "./Views/Tabs/SharedAgreements";
import Copy from "./Views/Tabs/Copy";
import Iso from "./Views/Tabs/Iso";
import myAgreementsService from "./Services/Tabs/Agreements";
import sharedAgreementsService from "./Services/Tabs/SharedAgreements";
import copyService from "./Services/Tabs/Copy";
import isoService from "./Services/Tabs/Iso";
import ShowPermission from "../../../Hoc/Template/ShowPermission";
import Title from "../../../Templates/Titles/Title";
import Total from "../../../Templates/Titles/Total";
import WrapButtons from "../../../Templates/Titles/WrapButtons";
import TabBlockBtn from "../../../Templates/Content/TabBlockBtn";
import AddAgreement from "./AddAgreement";
import AddIso from "./AddIso";
import {Modal} from "../../../Templates/Modals";
import TabCheck from "Templates/Tabs/TabСheck";

const defaultProps = {
    help: ''
}
const propTypes = {
    activeTab: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    showModal: PropTypes.bool.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
};

const labels = [
    {
        label: "My Agreements",
        button: "Agreement",
        service: myAgreementsService,
    },
    {
        label: "Shared with me Agreements",
        service: sharedAgreementsService,
    },
    {
        label: "Copy",
        button: "Copy",
        service: copyService,
    },
    {
        label: "ISO",
        button: "ISO",
        service: isoService,
    }
];

class Legal extends Component {
    render() {
        const {title, help, activeTab, onChangeTab, t, handleCloseModal, handleOpenModal, showModal} = this.props;

        const onClickAdd = activeTab => {
            if (labels[activeTab].label === 'Copy') {
                return () => url.redirect(`/legal/copy/create`);
            }

            return handleOpenModal;
        };

        const tab = () => {
            return (
                <>
                    {showModal && (
                        <Modal onRequestClose={handleCloseModal}>
                            <ServiceProvider value={labels[activeTab].service}>
                                { activeTab === 0 && (
                                    <AddAgreement button={labels[activeTab].button} onClose={handleCloseModal}/>
                                )}
                                { activeTab === 2 && (
                                    <AddIso button={labels[activeTab].button} onClose={handleCloseModal}/>
                                )}
                            </ServiceProvider>
                        </Modal>
                    )}
                    <Tabs
                        activeTab={activeTab}
                        onClickTabItem={index => {
                            if (activeTab !== index) {
                                onChangeTab(index);
                            }
                        }}
                    >
                        <TabCheck label={t("My Agreements")} permission={'legal_agreements_view'} >
                            <ServiceProvider value={myAgreementsService}>
                                <Agreements/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Shared with me Agreements")} permission={'legal_agreements_view'} >
                            <ServiceProvider value={sharedAgreementsService}>
                                <SharedAgreements/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("Copy")} permission={'legal_copy_view'} >
                            <ServiceProvider value={copyService}>
                                <Copy/>
                            </ServiceProvider>
                        </TabCheck>
                        <TabCheck label={t("ISO")} permission={'legal_iso_view'} >
                            <ServiceProvider value={isoService}>
                                <Iso/>
                            </ServiceProvider>
                        </TabCheck>
                    </Tabs>
                </>
            );
        };

        return (
            <ShowPermission checkPermission={["legal_agreements_view", "legal_copy_view", "legal_iso_view"]}>
                <Title
                    title={t(labels[activeTab].label)}
                    help={help}
                    total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
                >
                    <WrapButtons>
                        { activeTab !== 1 && <TabBlockBtn onClick={onClickAdd(activeTab)}>{t(`Add New ${labels[activeTab].button}`)}</TabBlockBtn> }
                    </WrapButtons>
                </Title>
                {tab()}
            </ShowPermission>
        );
    }
}

Legal.propTypes = propTypes;
Legal.defaultProps = defaultProps;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        title: getStoreItem(state, "pagination.total"),
        help: getStoreItem(state, "help"),
        activeTab: getStoreItem(state, "activeTab"),
        showModal: getStoreItem(state, "showModal"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Legal);
