import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {formatDate, getString} from "Services";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";

const getLabel = type => {
    switch (+type) {
        case 10:
            return "NDA";
        case 7:
            return "Intellectual Property";
        default:
            return "Other";
    }
};

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "type":
            return (
                <TableRowItemSpan title={getLabel(getString(item, key))}>
                    {getLabel(getString(item, key))}
                </TableRowItemSpan>
            );

        case "user_name":
            return item.can_edit === 10 ? (
                <TableRowItemLinkImg
                    blank
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "user_id")}`}
                    img={getString(item, "user_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            ) : (<TableRowItemSpan>{getString(item, key)}</TableRowItemSpan>);

        case "company":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/companies/${getString(item, "company_id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "name":
        case "slug":
            if (item.can_edit) {
                return (
                    <TableRowItemLink title={getString(item, key)} file href={getString(item, "path")}>
                        {getString(item, key)}
                    </TableRowItemLink>
                );
            }

            return (
                <TableRowItemSpan title={getString(item, key)}>
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "effective_date": {
            const date = formatDate(getString(item, key));

            let dateColor = "";

            const today = new Date();

            const threeMonthsFromToday = new Date(today);
            threeMonthsFromToday.setMonth(threeMonthsFromToday.getMonth() + 3);

            const effectiveDate = new Date(item.effective_date);

            if (today >= effectiveDate) {
                dateColor = "clr-red";
            } else if (effectiveDate <= threeMonthsFromToday) {
                dateColor = "clr-yellow";
            } else {
                dateColor = ""
            }

            return (
                <TableRowItemSpan title={formatDate(date)} className={dateColor}>
                    {formatDate(date)}
                </TableRowItemSpan>
            );
        }

        case "expiration_date": {
            const date = formatDate(getString(item, key));

            let dateColor = "";

            const today = new Date();

            const threeMonthsFromToday = new Date(today);
            threeMonthsFromToday.setMonth(threeMonthsFromToday.getMonth() + 3);

            if (today >= new Date(item.expiration_date)) {
                dateColor = "clr-red";
            } else if (new Date(item.expiration_date) <= threeMonthsFromToday) {
                dateColor = "clr-yellow";
            } else {
                dateColor = ""
            }

            return (
                <TableRowItemSpan title={formatDate(date)} className={dateColor}>
                    {formatDate(date)}
                </TableRowItemSpan>
            );
        }

        case "date":
            const modified = formatDate(getString(item, key));

            return <TableRowItemSpan title={modified}>{modified}</TableRowItemSpan>;

        default:
            const date = formatDate(getString(item, key));

            if (getLabel(getString(item, "type")) !== "NDA") {
                return (
                    <TableRowItemSpan title={formatDate(getString(item, key))}>
                        {formatDate(getString(item, key))}
                    </TableRowItemSpan>
                );
            }

            return (
                <TableRowItemSpan title={formatDate(date)}>
                    {formatDate(date)}
                </TableRowItemSpan>
            );
    }
};
