import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Ul } from "Templates/Default";
import ProjectStatusBlockListElement from "./ProjectStatusBlockListElement";

const propTypes = {
    status: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const ProjectStatusBlockList = ({ status, pref }) => (
    <Ul className={`status status--${pref}`}>
        {status.map((item, index) => (
            <ProjectStatusBlockListElement
                key={`projectStatus-${index}`}
                item={item}
                index={index}
                length={status.length}
            />
        ))}
    </Ul>
);

ProjectStatusBlockList.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        status: getStoreItem(state, "bar"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ProjectStatusBlockList);
