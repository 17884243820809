import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import ShowPermission from "Hoc/Template/ShowPermission";
import TabView from "./Views/TabView";

const propTypes = {
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
    activeTab: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchHierarchyItems: PropTypes.func.isRequired,
    addChildren: PropTypes.func.isRequired,
};

const Company = ({ activeTab, service, fetchItems, fetchHierarchyItems, request, t, addChildren }) => {
    const { deleteItem } = service;

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        url.redirect(`/companies/${item.id}/edit`);
    };

    const onClickShow = item => {
        const win = window.open(`/companies/${item.id}`, "_blank");
        win.focus();
    };

    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the Company?"))) {
            deleteItem(`/companies/${item.id}/delete`).then(
                () => {
                    activeTab === 1 ? fetchHierarchyItems(request) : fetchItems(request)
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const addHierarchy = (data) => {
        addChildren(data)
    }

    return (<ShowPermission checkPermission={['companies_view']}>
                <TabView onClickCustom={onClickShow} onClickEdit={onClickEdit} onClickDelete={onClickDelete} addHierarchy={addHierarchy} key="1" />
            </ShowPermission>);
};

Company.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        activeTab: state.listState.activeTab,
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        fetchHierarchyItems: getActionStore("fetchHierarchyItems", service, dispatch),
        addChildren: getActionStore("addChildren", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Company);
