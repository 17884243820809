import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: "",
};

const SinglePreviewRow = ({ className, pref, children }) => <div className={`${pref}__single-preview__row-first ${className ? `${className}` : ""}`}>{children}</div>;

SinglePreviewRow.propTypes = propTypes;
SinglePreviewRow.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(SinglePreviewRow);
