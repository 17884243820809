import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { url } from "Services";

const propTypes = {
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

class ProductSource extends Component {
    render = () => {
        const {
            t,
            fetchItems,
            request,
            service: { onClickDeleteItem },
        } = this.props;

        const onClickDelete = item => {
            if (window.confirm(t("Do you want to delete the Product Source?"))) {
                onClickDeleteItem(item).then(
                    () => {
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        };

        const onClickEdit = item => {
            url.redirect(`/admin/list/products/${item.id}/edit`);
        };

        const onClickShow = item => {
            const win = window.open(`/admin/list/products/${item.id}`);
            win.focus();
        };

        return (
            <>
                <TabViewDetail
                    onClickCustom={onClickShow}
                    customIcon={'icon_external_link'}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                />
            </>
        );
    };
}

ProductSource.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ProductSource);
