import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { BlockTitle, Block, BlockShadow, BlockWrap, Input, WrapInput } from "Templates/Form";

const propTypes = {
    tier1: PropTypes.string.isRequired,
    tier2: PropTypes.string.isRequired,
    molder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const SupplyChainBlock = ({ tier1, tier2, molder, onChange, t }) => {
    return (
        <>
            <BlockTitle>{t("Supply Chain")}</BlockTitle>
            <Block>
                <BlockShadow>
                    <BlockWrap className="width_1-3">
                        <WrapInput label="OEM Tier 1" name="tier1">
                            <Input
                                type="asyncSelect"
                                url="project/projects/customer-list/tier/1"
                                value={tier1}
                                placeholder={t("Search")}
                                onChange={value => onChange("supplyChainBlock.tier1", value)}
                            />
                        </WrapInput>
                    </BlockWrap>
                    <BlockWrap className="width_1-3">
                        <WrapInput label="OEM Tier 2" name="tier2">
                            <Input
                                type="asyncSelect"
                                url="project/projects/customer-list/tier/2"
                                value={tier2}
                                placeholder={t("Search")}
                                onChange={value => onChange("supplyChainBlock.tier2", value)}
                            />
                        </WrapInput>
                    </BlockWrap>
                    <BlockWrap className="width_1-3">
                        <WrapInput label="OEM Tier 3" name="molder">
                            <Input
                                type="asyncSelect"
                                url="project/projects/customer-list/tier/3"
                                value={molder}
                                placeholder={t("Search")}
                                onChange={value => onChange("supplyChainBlock.molder", value)}
                            />
                        </WrapInput>

                    </BlockWrap>
                </BlockShadow>
            </Block>
        </>
    );
};

SupplyChainBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        tier1: getStoreItem(state, "supplyChainBlock.tier1"),
        tier2: getStoreItem(state, "supplyChainBlock.tier2"),
        molder: getStoreItem(state, "supplyChainBlock.molder"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SupplyChainBlock);
