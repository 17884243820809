import {handleScroll} from "../../EventsService";
import {getActionStore, getStoreItem} from "../../StoreService";
import {deleteItem} from "../../RequestService";
import {
    requestAddPublicationList,
    requestNewAddPublicationList,
    requestStore,
    requestTable,
    requestUpdatePublicationList
} from "./RequestService";
import {getFilter, getTableLabel, isSortEnabled} from "./TableHeaderService";
import {getViewItem, modifierValues} from "./TableBodyService";

export default ({
    requestTable: requestTable(`/product/product-finder/publication-groups`),
    requestAttach: requestStore(`/product/product-finder/publication-groups`),
    onClickDeleteItem: item => deleteItem(`/product/product-finder/publication-groups/${item.id}`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    isSortEnabled,
    getStoreItem,
    getActionStore,
    requestAddPublicationList,
    requestUpdatePublicationList,
    requestNewAddPublicationList,
});

