import React from "react";
// import PropTypes from "propTypes";
import ProjectStatusBlockList from "./ProjectStatusBlockList";

const propTypes = {};

const ProjectStatusBlock = () => <ProjectStatusBlockList />;

ProjectStatusBlock.propTypes = propTypes;

export default ProjectStatusBlock;
