import { combineReducers } from "redux";
import { getMainInitialStates, getMainReducers } from "App/Services";
import { reducer as formReducer, initialState as createState } from "../../../Reducers/Form";

// Combine all reducers you may have here
export default combineReducers({
    ...getMainReducers,
    createState: formReducer,
});

export const initialStates = {
    ...getMainInitialStates,
    createState,
};
