import Constants from "../Constants";
import {uniqueGenerator} from "Services";
import {concat, uniqBy} from "lodash";

export const initState = {
    showModal: false,
    showInviteModal: false,
    list: {items: [], pagination: {}},
    form: [{id: uniqueGenerator(), value: ''}],
    guestForm: { email: '', invite_text: '', notify_creator: true },
    groupId: ''
};

export const reducer = (state, action) => {
    const {data} = action;
    const {users} = state;
    switch (action.type) {
        case Constants.OPEN_USERS_MODAL:
            return {
                ...state, users: {
                    ...users,
                    showModal: true,
                }
            };

        case Constants.CLOSE_USERS_MODAL:
            return {
                ...state, users: {
                    ...users,
                    form: [{id: uniqueGenerator(), value: ''}],
                    showModal: false,
                }
            };

        case Constants.UPDATE_USER_FORM: {
            return {
                ...state, users: {
                    ...users,
                    form: data,
                }
            };
        }

        case Constants.UPDATE_GUEST_FORM: {
            return {
                ...state, users: {
                    ...users,
                    guestForm: {
                        email: data.key === 'email' ? data.value : users.guestForm.email,
                        invite_text: data.key === 'invite_text' ? data.value : users.guestForm.invite_text,
                        notify_creator: data.key === 'notify_creator' ? data.value :  users.guestForm.notify_creator
                    },
                }
            };
        }

        case Constants.OPEN_INVITE_GUESTS_MODAL:
            return {
                ...state, users: {
                    ...users,
                    showInviteModal: true,
                }
            };

        case Constants.CLOSE_INVITE_GUESTS_MODAL:
            return {
                ...state, users: {
                    ...users,
                    guestForm: { email: '', invite_text: '', notify_creator: false },
                    showInviteModal: false,
                }
            };

        case Constants.GET_USERS_SUCCEEDED: {
            return {
                ...state, users: {
                    ...users,
                    list: data,
                }
            };
        }

        case Constants.GET_NEXT_PAGE_USERS_SUCCEEDED: {
            const {pagination, items} = data;
            return {
                ...state, users: {
                    ...users,
                    list: {
                        items: uniqBy(concat(users.list.items, items), 'id'),
                        pagination,
                    }
                }
            }
        }

        default:
            return state;
    }
};