import React from "react";
import { ButtonClose } from "Templates/Button";
import PropTypes from "prop-types";
import parse from "html-react-parser";

const propTypes = {
    help: PropTypes.string,
    onClose: PropTypes.func
};

const Help = ({ help, onClose }) => {
    return (
        <div className="help-page-modal">
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <div className="help-page main-modal__form">
                { parse(help) }
            </div>
        </div>
    );
}

Help.defaultProps = {
    help: '',
    onClose: () => {},
};
Help.propTypes = propTypes;

export default Help;
