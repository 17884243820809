import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import TableRowColumnDefault from "Templates/Table/TableRowColumnWrap/Default";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";

const propTypes = {
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
};

const TableRowColumn = React.memo(({ column, item }) => (
    <TableRowColumnTemplate>
        <TableRowColumnDefault column={column} item={item} />
    </TableRowColumnTemplate>
));

TableRowColumn.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowColumn);
