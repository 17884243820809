import { handleScroll } from "../../EventsService";
import {requestTable} from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { typeList, getTableLabel, getFilter } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default ({
    requestTable: requestTable(`/legal/shared-agreements`),
    typeList,
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
