import _configureStore from "App/Store";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { items, pagination, columns, view } = props;
    const { mainState, appState } = initialStates;

    const initialState = {
        listState: { ...mainState, items, pagination, columns, view },
        appState: { ...appState, pref: "forgot" },
    };

    return _configureStore(initialState, reducers);
}
