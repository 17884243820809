import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {ServiceProvider, withServiceConsumer} from 'Services/Context';
import {withTagDefaultProps, CheckPermission} from 'Hoc/Template';
import {url} from 'Services';
import {Modal} from 'Templates/Modals';
import Tabs from 'Templates/Tabs/Tabs';
import Tab from "Templates/Tabs/Tab";
import TabCheck from 'Templates/Tabs/TabСheck';
import {ContentWrap, ContentWrapCol, TabBlockBtn, MainButtonLink} from 'Templates/Content';
import { Title, WrapButtons } from "Templates/Titles";
import technicalService from './Services/Tabs/Technical';
import processingService from './Services/Tabs/Processing';
import CompareProductsView from './Views';
import {
    createFilter, deleteItem,
    getFilter, getFilters,
    requestProcessChangeSettings,
    requestTechnicalChangeSettings
} from './Services/RequestService';

import TechnicalTableSettingsView from "./Views/TableSettings/TechnicalTableSettingsView";
import ProcessTableSettingsView from "./Views/TableSettings/ProcessTableSettingsView";
import {setOpenCreateFilterModal} from "./Actions/TableActions";
import {onChange} from "./Services/EventsService";
import {InputFilter} from "Templates/Table/Filters";
import Img from "Templates/Img/Img";
import CreateFiltersModal from "./Views/Modals/CreateFiltersModal";

const Compare = (
    {
        columns,
        activeColumns,
        activeTab,
        executeExport,
        activeCompareTab,
        isCompareProducts,
        resetCompareProductsSelect,
        onChangeTab,
        onChangeCompareTab,
        t,
        request,
        fetchTechnicalItems,
        fetchProcessingItems,
        permissions,
        help,
        tableFilters,
        setOpenCreateFilterModal,
        showCreateFiltersModal,
        setActiveFilter,
        productsControls,
        propertyNameValue,
        parameter1Value,
        parameter2NameValue,
        parameter3Value,
        manufacturingValue,
        parameterValue,
        descriptionValue
    }) => {
    const [showEditColumnsModal, setShowEditColumnsModal] = useState(false)
    const [optionsUrl, setOptionsUrl] = React.useState(null);
    const [exportUrl, setExportUrl] = React.useState(null);
    const [filter, setFilter] = useState({label: '', value: ''})
    const [appliedFilter, setAppliedFilter] = useState({label: '', value: ''})

    useEffect(() => {
        if (!activeTab && !activeCompareTab) {
            setOptionsUrl(`/solution/compare-publish-solution-technical-filter-list`)
            setExportUrl(`/api/solution/compare/export/category`);
        }

        if (!activeTab && activeCompareTab) {
            setOptionsUrl(`/product/datasheets/compare-onboarding-product-technical-filter-list`)
            setExportUrl(`/api/product/product-finder/compare/export/onboarding-technical`);
        }

        if (activeTab && !activeCompareTab) {
            setOptionsUrl(`/product/processes/compare-shared-product-processing-filter-list`)
            setExportUrl(`/api/product/product-finder/compare/export/shared-processing`);
        }

        if (activeTab && activeCompareTab) {
            setOptionsUrl(`/product/processes/compare-onboarding-product-processing-filter-list`)
            setExportUrl(`/api/product/product-finder/compare/export/onboarding-processing`);
        }
    }, [activeTab, activeCompareTab]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.delete('activeCompareTab');
            window.history.replaceState({}, '', `${window.location.href}?activeCompareTab=${activeCompareTab}`);
        }
    }, [])

    useEffect(() => {
        if (!filter.value) {
            if (sessionStorage.getItem('selectedFilter')) {
                const selected = JSON.parse(sessionStorage.getItem('selectedFilter') || null)
                if (selected) {
                    getFilters(`/solution/filters/data/${selected.value}`).then((res) => {
                        if (typeof window !== 'undefined') {
                            sessionStorage.setItem('selectedFilter', JSON.stringify(selected))
                        }
                        const items = JSON.parse(res[0].items || '{}')
                        delete items.type
                        setActiveFilter(items)
                    })
                    setAppliedFilter(selected)
                }
            }
            return
        }
        if (filter.value) {
            getFilters(`/solution/filters/data/${filter.value}`).then((res) => {
                if (typeof window !== 'undefined') {
                    sessionStorage.removeItem('selectedFilter')
                    setAppliedFilter({label: '', value: ''})
                    resetCompareProductsSelect(true)
                    sessionStorage.setItem('selectedFilter', JSON.stringify(filter))
                }
                const items = JSON.parse(res[0].items || '{}')
                delete items.type
                setActiveFilter(items)
            })
        }
    }, [filter])

    const handleSaveTechnicalModalWithCheck = (columnList, defaultColumns, pinnedColumns, idTemplate, noReload) => {
        requestTechnicalChangeSettings({
            'templateId': idTemplate,
            'columns': columnList,
            'defaultColumns': defaultColumns,
            'pinnedColumns': pinnedColumns
        }).then(
            () => {
                if (noReload) return
                fetchTechnicalItems({...request, activeTab, activeCompareTab});
            },
            errors => {
                alert(errors.detail);
            },
        );
        if (!noReload) {
            setShowEditColumnsModal(false);
        }
    };

    const handleSaveProcessModalWithCheck = (columnList, defaultColumns, pinnedColumns, idTemplate, noReload) => {
        requestProcessChangeSettings({
            'templateId': idTemplate,
            'columns': columnList,
            'defaultColumns': defaultColumns,
            'pinnedColumns': pinnedColumns
        }).then(
            () => {
                if (noReload) return
                fetchProcessingItems({...request, activeTab, activeCompareTab});
            },
            errors => {
                alert(errors.detail);
            },
        );
        if (!noReload) {
            setShowEditColumnsModal(false);
        }
    };

    const handleCloseEditColumnsModalWithCheck = e => {
        e.preventDefault();
        setShowEditColumnsModal(false);
    };

    const openEditColumnsModal = () => {
        setShowEditColumnsModal(true);
    };

    const onSaveFilters = (name) => {
        if (!name.length || !tableFilters) {
            alert('Error! Fill name and chose filters')
        }

        getFilter(`${optionsUrl}`).then((filterItem) => {
            createFilter({ name, tableFilters }).then((data) => {
                setOpenCreateFilterModal(false)
                resetCompareProductsSelect(true)
                getFilter(`${optionsUrl}/${data[0].id}`).then((res) => {
                    setFilter(res[0].items.find(() => data[0].id))
                })

            }).catch((error) => {
                alert(error.detail)
            })
        }).catch((error) => {
            alert(error.detail)
        })
    }

    const onClickDelete = item => {
        if (typeof window !== 'undefined') {
            if (window.confirm(t("Do you want to delete the selected saved filter?"))) {
                setOptionsUrl('//');

                deleteItem(`/solution/filters/${item}/delete`).then(
                    async () => {
                        alert('Your saved filter was successfully deleted.');
                        resetCompareProductsSelect(true)                    },
                    errors => {
                        alert(errors.detail);
                    },
                )
            }
        }
    };

    const resetAppliedFilters = () => {
        sessionStorage.removeItem('selectedFilter')
        setFilter({label: '', value: ''})
        setAppliedFilter({label: '', value: ''})
        resetCompareProductsSelect(true)
    }

    const getExportParams = () => {
        if (!activeTab && !activeCompareTab) {
            if (productsControls) {
                return {
                    publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                    datasheet_ids: productsControls.map(item => JSON.parse(item.value).datasheet_id),
                    property_ids: propertyNameValue && propertyNameValue.every((prop) => prop) ? propertyNameValue.map((item) => item) : null,
                    parameter1_ids: parameter1Value && parameter1Value.every((prop) => prop) ? parameter1Value.map((item) => item) : null,
                    parameter2_ids: parameter2NameValue && parameter2NameValue.every((prop) => prop) ? parameter2NameValue.map((item) => item) : null,
                    parameter3_ids: parameter3Value && parameter3Value.every((prop) => prop) ? parameter3Value.map((item) => item) : null
                };
            }
        }

        if (!activeTab && activeCompareTab) {
            if (productsControls) {
                return {
                    publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                    datasheet_ids: productsControls.map(item => JSON.parse(item.value).datasheet_id),
                    property_ids: propertyNameValue && propertyNameValue.every((prop) => prop) ? propertyNameValue.map((item) => item) : null,
                    parameter1_ids: parameter1Value && parameter1Value.every((prop) => prop) ? parameter1Value.map((item) => item) : null,
                    parameter2_ids: parameter2NameValue && parameter2NameValue.every((prop) => prop) ? parameter2NameValue.map((item) => item) : null,
                    parameter3_ids: parameter3Value && parameter3Value.every((prop) => prop) ? parameter3Value.map((item) => item) : null
                };
            }
        }

        if (activeTab && !activeCompareTab) {
            if (productsControls) {
                return {
                    publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                    process_ids: productsControls.map(item => JSON.parse(item.value).process_id),
                    manufacturing_ids: manufacturingValue && manufacturingValue.every((prop) => prop) ? manufacturingValue.map((item) => item) : null,
                    parameter_ids: parameterValue && parameterValue.every((prop) => prop) ? parameterValue.map((item) => item) : null,
                    description_ids: descriptionValue && descriptionValue.every((prop) => prop) ? descriptionValue.map((item) => item) : null,
                };
            }
        }

        if (activeTab && activeCompareTab) {
            if (productsControls) {
                return {
                    publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                    process_ids: productsControls.map(item => JSON.parse(item.value).process_id),
                    manufacturing_ids: manufacturingValue && manufacturingValue.every((prop) => prop) ? manufacturingValue.map((item) => item) : null,
                    parameter_ids: parameterValue && parameterValue.every((prop) => prop) ? parameterValue.map((item) => item) : null,
                    description_ids: descriptionValue && descriptionValue.every((prop) => prop) ? descriptionValue.map((item) => item) : null,
                };
            }
        }
    }

    const handleExportClick = (e) => {
        e.preventDefault();
        executeExport(url.getUrl(exportUrl, getExportParams(), ""));
    }

    return (
        <>
            <ContentWrap>
                <ContentWrapCol>
                    <Title
                        title={t("Compare Technologies")}
                        help={help}
                    >
                        {!permissions.includes('grade_product_finder_view') && !permissions.includes('grade_product_finder_view') ? null : <WrapButtons>
                            {tableFilters ? <button type="button" className="reset-compare-products-btn" onClick={() => setOpenCreateFilterModal(true)}>Save Filters</button> : null}
                            {showCreateFiltersModal ? (
                                <Modal onRequestClose={() => setOpenCreateFilterModal(false)}>
                                    <CreateFiltersModal
                                        onSaveFilters={onSaveFilters}
                                        onClose={() => setOpenCreateFilterModal(false)}
                                    />
                                </Modal>
                            ) : null}
                            <TabBlockBtn onClick={openEditColumnsModal} className="main-btn_white">
                                {t("Edit Column")}
                            </TabBlockBtn>
                            <CheckPermission permission="grade_product_finder_view">
                                <MainButtonLink
                                    className="main-btn_white"
                                    onClick={(e) => handleExportClick(e)}
                                >
                                    {t("Export Compare Technologies")}
                                </MainButtonLink>
                            </CheckPermission>
                            {isCompareProducts ? <button type="button" onClick={() => {
                                resetAppliedFilters()
                            }} className="reset-compare-products-btn">
                                Reset Filters
                            </button> : null}
                            {optionsUrl ? <div className="compare-products-saved-filters product__filters saved-filters">
                                <p>Saved Filters:</p>
                                <InputFilter
                                    type="asyncSelect"
                                    placeholder="Select"
                                    url={optionsUrl}
                                    value={filter.value ? filter.value : appliedFilter.value}
                                    onChange={e => {
                                        if (!e) return
                                        onChange(e).then(_value => {
                                            getFilter(`${optionsUrl}/${_value}`).then((res) => {
                                                setFilter(res[0].items[0])
                                            })
                                        })
                                    }}
                                />
                                {filter.value || appliedFilter.value ? (
                                    <button
                                        type="button"
                                        className="remove-saved-filter"
                                        onClick={() => {
                                            onClickDelete(filter.value ? filter.value : appliedFilter.value)
                                        }}
                                    >
                                        <Img img="icon_delete" />
                                    </button>
                                ) : null}
                            </div> : null}
                        </WrapButtons>}
                    </Title>
                    {!permissions.includes('grade_product_finder_view') && !permissions.includes('grade_product_finder_view') ?
                        <div className="no-permissions-text">You don&apos;t have permissions to view this page</div> :
                        <div className="d-flex flex-col h-100">
                            <div className="compare-tabs">
                                <div className="compare-tabs__wrap">
                                    {permissions.includes('grade_product_finder_view') ? <button className={`${activeCompareTab === 0 ? 'active' : ''}`} onClick={() => {
                                        resetAppliedFilters()
                                        onChangeCompareTab(0)
                                    }}>Published Technologies</button> : null}
                                    {/*{permissions.includes('grade_product_finder_view') ? <button className={`${activeCompareTab === 1 ? 'active' : ''}`} onClick={() => {*/}
                                    {/*    resetAppliedFilters()*/}
                                    {/*    onChangeCompareTab(1)*/}
                                    {/*}}>Onboarding Products</button> : null}*/}
                                </div>
                            </div>
                            <Tabs
                                activeTab={activeTab}
                                onClickTabItem={(index) => {
                                    if (activeTab !== index) {
                                        resetAppliedFilters()
                                        onChangeTab(index);
                                    }
                                }}
                            >
                                <TabCheck
                                    label={t('Category')}
                                    titleMessage="All solutions with approved for publications."
                                    permission="grade_product_finder_view"
                                >
                                    <ServiceProvider value={technicalService}>
                                        {showEditColumnsModal ? (
                                            <Modal onRequestClose={handleCloseEditColumnsModalWithCheck}>
                                                <TechnicalTableSettingsView
                                                    columns={columns}
                                                    activeColumns={activeColumns}
                                                    onAppend={handleSaveTechnicalModalWithCheck}
                                                    onClose={handleCloseEditColumnsModalWithCheck}
                                                />
                                            </Modal>
                                        ) : <div/>}
                                        <CompareProductsView />
                                    </ServiceProvider>
                                </TabCheck>
                                <Tab label={ '' }/>
                                {/*<TabCheck*/}
                                {/*    label={t('Processing')}*/}
                                {/*    titleMessage="User generated list for sharing."*/}
                                {/*    permission="grade_product_finder_view">*/}
                                {/*    <ServiceProvider value={processingService}>*/}
                                {/*        {showEditColumnsModal ? (*/}
                                {/*            <Modal onRequestClose={handleCloseEditColumnsModalWithCheck}>*/}
                                {/*                <ProcessTableSettingsView*/}
                                {/*                    columns={columns}*/}
                                {/*                    activeColumns={activeColumns}*/}
                                {/*                    onAppend={handleSaveProcessModalWithCheck}*/}
                                {/*                    onClose={handleCloseEditColumnsModalWithCheck}*/}
                                {/*                />*/}
                                {/*            </Modal>*/}
                                {/*        ) : <div/>}*/}
                                {/*        <CompareProductsView />*/}
                                {/*    </ServiceProvider>*/}
                                {/*</TabCheck>*/}
                            </Tabs>
                        </div>
                    }
                </ContentWrapCol>
            </ContentWrap>
        </>
    );
};

Compare.defaultProps = {
    items: []
}

Compare.propTypes = {
    activeTab: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.any),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    showModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    setActiveFilter: PropTypes.func.isRequired,
    onChangeCompareTab: PropTypes.func.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state, { service }) => {
    const { getStoreItem } = service;
    const activeTab = getStoreItem(state, 'activeTab');

    return {
        activeTab,
        tableFilters: getStoreItem(state, "tableFilters"),
        isCompareProducts: getStoreItem(state, "isCompareProducts"),
        activeCompareTab: getStoreItem(state, "activeCompareTab", 0),
        columns: getStoreItem(state, "columns"),
        activeColumns: getStoreItem(state, "activeColumns"),
        items: getStoreItem(state, 'items'),
        permissions: getStoreItem(state, 'permissions'),
        showModal: getStoreItem(state, 'showModal'),
        help: getStoreItem(state, "help"),
        showCreateFiltersModal: getStoreItem(state, "showCreateFiltersModal"),
        productsControls: getStoreItem(state, "productsControls"),
        propertyNameValue: getStoreItem(state, "propertyName"),
        parameter1Value: getStoreItem(state, "parameter1"),
        parameter2NameValue: getStoreItem(state, "parameter2Name"),
        parameter3Value: getStoreItem(state, "parameter3"),
        manufacturingValue: getStoreItem(state, "manufacturing"),
        parameterValue: getStoreItem(state, "parameter"),
        descriptionValue: getStoreItem(state, "description"),
        request: {
            filter: {
                ...getStoreItem(state, 'filter', true),
            },
            sort: {
                ...getStoreItem(state, 'sort'),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchTechnicalItems: getActionStore("fetchItems", technicalService, dispatch),
        fetchProcessingItems: getActionStore("fetchItems", processingService, dispatch),
        onChangeTab: getActionStore('onChangeTab', service, dispatch),
        onChangeCompareTab: getActionStore('onChangeCompareTab', service, dispatch),
        handleCloseModal: getActionStore('handleCloseModal', service, dispatch),
        handleOpenModal: getActionStore('handleOpenModal', service, dispatch),
        setActiveFilter: getActionStore('setActiveFilter', service, dispatch),
        resetCompareProductsSelect: getActionStore("resetCompareProductsSelect", service, dispatch),
        setOpenCreateFilterModal: getActionStore("setOpenCreateFilterModal", service, dispatch),
        executeExport: getActionStore("executeExport", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Compare);
