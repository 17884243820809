import {compose} from "redux";
import replace from "./replace";

const imagesCtx = require.context('../images', false, /\.(png|jpg|jpeg|gif|svg|webp)$/);
const iconsCtx = require.context('../icons', true, /\.svg$/);

const files = {};
const icons = {};

const loadSvg = (context, value) => filename => {
    const key = filename.replace(/^.{2}/, '').replace(/.{4}$/, '');
    value[key] = context(filename);
};

imagesCtx.keys().forEach(loadSvg(imagesCtx, files));
iconsCtx.keys().forEach(loadSvg(iconsCtx, icons));


const replaceFile = (key) => replace[key] || key;

const getFile = (key) => files[key] ? files[key] : icons[key].default;

export default compose(
    getFile,
    replaceFile
)


