import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    inputProps: PropTypes.shape({ isOptionDisabled: PropTypes.func }),
};

const defaultProps = {
    disabled: false,
    placeholder: "",
    value: "",
    inputProps: { isOptionDisabled: option => option.disable },
};

const renderTitle = (options, value) => {
    let title = null;

    options.reduce((acc, option) => {
        if (option.value) {
            if (option.value == value) {
                title = option.label;
            }
        }
        if (option.options && Array.isArray(option.options)) {
            const result = option.options.find(item => item.value == value);

            if (typeof result !== "undefined") {
                title = result.label;
            }
        }
    }, []);

    return title;
};

const SearchSelect = ({ disabled, value, placeholder, options, onChange, inputProps }) => (
    <div title={value ? renderTitle(options, value) : null} className="select-title">
    <Select
        isSearchable={false}
        isDisabled={disabled}
        className="select-custom-wrap"
        menuPosition="fixed"
        closeMenuOnScroll={e => !(e.target.parentNode || {className: ''}).className.includes("menu")}
        value={options.reduce((acc, option) => {
            if (option.value) {
                if (option.value === value) {
                    return [...acc, option];
                }
                return acc;
            }
            if (option.options && Array.isArray(option.options)) {
                const result = option.options.find(item => item.value === value);
                if (typeof result !== "undefined") {
                    return [...acc, result];
                }
            }
            return acc;
        }, [])}
        onChange={val => onChange(val.value)}
        options={options}
        placeholder={placeholder}
        {...inputProps}
    />
    </div>
);

SearchSelect.propTypes = propTypes;
SearchSelect.defaultProps = defaultProps;

export default SearchSelect;
