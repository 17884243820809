import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Block, BlockShadow, BlockSubmit, BlockWrap, ButtonLeft, ButtonRight, Input, WrapInput} from "Templates/Form";
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {Modal} from "Templates/Modals";
import Img from "Templates/Img/Img";
import {ComoppsEditor} from "Templates/Input";
import CreateContentTemplateModal from "./Modal/CreateContentTemplateModal";
import ReplaceContentTemplateModal from "./Modal/ReplaceContentTemplateModal";

const propTypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeContentTemplate: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    submitContentTemplate: PropTypes.func.isRequired,
    editContentTemplate: PropTypes.func.isRequired,
};

class Content extends Component {
    timer;
    state = {
        isSaveSource: false,
        showContent: true,
        showCreateTemplateModal: false,
        showReplaceTemplateModal: false,
        templateName: '',
        url: `/product/designs/content-template-list`
    };

    toggleContent = () => {
        this.setState(({showContent}) => ({
            showContent: !showContent,
        }));
    };

    componentWillUnmount(){
        if(this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        const {showContent, showCreateTemplateModal, showReplaceTemplateModal, templateName, url, isSaveSource} = this.state;

        const {
            content,
            onChange,
            onChangeContentTemplate,
            submitContentTemplate,
            editContentTemplate,
            index,
            deleteItem,
            title,
            t
        } = this.props;

        const handleClickOnShowModal = () => {
            const modal = content.template ? `showReplaceTemplateModal` : `showCreateTemplateModal`;

            this.setState({
                [modal]: true,
            });
        };

        const handleCloseModal = name => () => {
            this.setState({
                [name]: false,
            });
        };

        const handleShowModal = name => () => {
            this.setState({
                [name]: true,
            });
        };

        const submitTemplate = data => {
            const requestData = {
                name: data.name,
                description: content.value,
                template: content.template,
            };

            this.setState({templateName: data.name});

            return submitContentTemplate(`content.${index}.template`, requestData);
        };

        const editTemplate = () => {
            const requestData = {
                description: content.value,
                template: content.template,
            };

            return editContentTemplate(content.template, `content.${index}.template`, requestData);
        };

        const onChangeContent = (value) => {
            onChange(`content.${index}`, value);
        };

        const onChangeTemplate = (templateId, contentData) => {
            onChangeContentTemplate(`content.${index}`, templateId, contentData).then((data) => {
                this.setState({templateName: data.name});
            });
        };

        const onClickDelete = (item) => {
            this.setState({url: '//'});
            if (window.confirm(t("Do you want to delete the current template?"))) {
                deleteItem(`/product/product-finder/content-template/${item}/delete`).then(
                    () => {
                        alert('Your template was successfully deleted');
                        onChangeTemplate('', {
                            frontId: content.frontId,
                            template: '',
                            oldTemplate: null,
                            name: '',
                            value: ''
                        });
                        this.setState({url: `/product/designs/content-template-list`});
                    },
                    errors => {
                        alert(errors.detail);
                    },
                )
            }
        };

        const validateSaveTemplate = () => {
            return content.value.trim() !== '';
        };

        const onSaveSource = () => {
            this.setState({isSaveSource: true})
        }

        return (
            <>
                <Div className="toggle__show-block">
                    <BlockTitle>{t(title)}</BlockTitle>
                    <button
                        type="button"
                        className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                        onClick={() => this.toggleContent()}
                    >
                        Show
                        {' '}
                        {showContent ? 'Less' : 'More'}
                    </button>
                </Div>
                <Block>
                    {showContent ?
                        (
                            <BlockWrap>
                                <BlockShadow>
                                    {showCreateTemplateModal ? (
                                        <Modal onRequestClose={handleCloseModal}>
                                            <CreateContentTemplateModal
                                                titleText="Create Template"
                                                buttonText="Create Template"
                                                onAppend={submitTemplate}
                                                onClose={handleCloseModal}
                                            />
                                        </Modal>
                                    ) : <div/>}
                                    {showReplaceTemplateModal ? (
                                        <Modal onRequestClose={handleCloseModal}>
                                            <ReplaceContentTemplateModal
                                                titleText="Edit Template"
                                                buttonEditText="Update Current"
                                                buttonCreateNewText="Save as New"
                                                onEdit={editTemplate}
                                                onCreateNew={handleShowModal}
                                                onClose={handleCloseModal}
                                            />
                                        </Modal>
                                    ) : <div/>}
                                    <div className="template-wrapper">
                                        <WrapInput
                                            className="wrap-input--delete width_1-5"
                                            label="Content Template"
                                            name="content_template"
                                        >
                                            <Input
                                                type="asyncSelect"
                                                placeholder={t("Select")}
                                                url={url}
                                                value={content.template || ''}
                                                onChange={value => onChangeTemplate(value, {
                                                    frontId: content.frontId,
                                                    template: value,
                                                    oldTemplate: null,
                                                    name: content.name,
                                                    value: content.value
                                                })}
                                            />
                                        </WrapInput>
                                        {content.template && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    onClickDelete(content.template)
                                                }}
                                            >
                                                <Img img="icon_delete"/>
                                            </button>
                                        )}
                                    </div>
                                    <p className={`template-name ${(templateName && templateName.length) || (content && content.name && content.name.length) ? 'show' : ''}`}>
                                        Based on
                                        {' '}
                                        <strong>{templateName || content.name}</strong>
                                        {' '}
                                        template
                                    </p>
                                    <ComoppsEditor
                                        text={content.value}
                                        onChange={value => onChangeContent({
                                            frontId: content.frontId,
                                            template: content.template,
                                            oldTemplate: content.oldTemplate,
                                            name: content.name,
                                            value
                                        })}
                                        onSaveSource={onSaveSource}
                                    />
                                </BlockShadow>
                                <BlockSubmit>
                                    <ButtonRight/>
                                    <ButtonLeft>
                                        <button
                                            type="button"
                                            className="projects-create__buttons-btn main-btn main-btn_primary"
                                            onClick={handleClickOnShowModal}
                                            disabled={!validateSaveTemplate()}
                                        >
                                            {t("Save as Template")}
                                        </button>
                                    </ButtonLeft>
                                </BlockSubmit>
                            </BlockWrap>
                        ) : ''}
                </Block>
            </>
        );
    }
}

Content.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        content: getStoreItem(state, "content")[ownProps.index],
        title: ownProps.title,
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore, deleteItem} = service;

    return {
        deleteItem,
        onChange: getActionStore("onChange", service, dispatch),
        onChangeContentTemplate: getActionStore("onChangeContentTemplate", service, dispatch),
        submitContentTemplate: getActionStore("submitContentTemplate", service, dispatch),
        editContentTemplate: getActionStore("editContentTemplate", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Content);
