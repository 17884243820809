import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {url} from "Services";
import {Title} from "Templates/Titles";
import {BlockSubmit, ButtonLeft, ButtonRight, SubmitBlockButton} from "Templates/Form";
import {withTagDefaultProps} from "Hoc/Template";
import {BackLink} from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    years: PropTypes.shape({
        [PropTypes.number]: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ),
    }).isRequired,
    manufacturer: PropTypes.string.isRequired,
    unitName: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
    validate: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
};

const MyForecast = ({ manufacturer, unitName, owner, years, pref, validate, t, submitForm }) => {
    const handleClickOnSave = () => {
        submitForm({
            manufacturer,
            unitName,
            owner,
            years,
        }).then(
            data => {
                url.redirect(`/project/forecasts/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (confirm(t("Cancel?"))) {
            url.redirect("/project/forecasts");
        }
    };

    return (
        <>
            <Title
                title={t("Add New Unit Forecast")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validate} onClick={handleClickOnSave}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

MyForecast.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        manufacturer: getStoreItem(state, "manufacturer"),
        unitName: getStoreItem(state, "unitName"),
        owner: getStoreItem(state, "owner"),
        years: getStoreItem(state, "years"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MyForecast);
