import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { TableActions } from "../Actions";

const getStoreItem = _getStoreItem("listState");

const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export default () => ({
    getStoreItem,
    getActionStore,
});
