import React, {useMemo} from "react";
import _ from "lodash";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ContainerTable} from "Hoc/Widgets";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import {TableRowColumnWrap} from "Templates/Table/TableRowColumnWrap";

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);

const propTypes = {
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
        handleScroll: PropTypes.func.isRequired,
    }).isRequired,
    fetchPage: PropTypes.func.isRequired,
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func,
};

const defaultProps = {
    customIcon: "",
    onClickCustom: () => {
    },
};

const PublicationsTabViewIndex = (
    {
        customIcon,
        onClickEdit,
        onClickDelete,
        onClickCustom,
        pref,
        items,
        columns,
        pagination,
        fetchPage,
        request,
        service,
        activeTab
    }) => {
    const { page, pages } = pagination;

    const { handleScroll } = service;

    const headersWidth = useMemo(() => {
        return {
            datasheet: 0,
            process: 0,
            design: 0,
            safety: 0,
            brochure: 0
        }
    }, [])

    const onScroll = e => {
        if (page >= pages) {
            return;
        }

        handleScroll(e).then(() => {
            request.page = page + 1;

            fetchPage(request);
        });
    };

    const _onClickEdit = e => {
        const item = _.get(items, e);
        if (item) {
            onClickEdit(item);
        }
    };

    const _onClickDelete = e => {
        const item = _.get(items, e);
        if (item) {
            onClickDelete(item);
        }
    };

    const _onClickCustom = e => {
        const item = _.get(items, e);
        if (item) {
            onClickCustom(item);
        }
    };

    const createTableHeading = () => {
        headersWidth.datasheet = columns.filter((item) => item.includes('datasheets')).length * 224 - 24
        headersWidth.process = columns.filter((item) => item.includes('processes')).length * 224 - 24
        headersWidth.design = columns.filter((item) => item.includes('designs')).length * 224 - 24
        headersWidth.safety = columns.filter((item) => item.includes('safeties')).length * 224 - 24
        headersWidth.brochure = columns.filter((item) => item.includes('brochures')).length * 224 - 24
    }

    createTableHeading()

    return (
        <div className={`${pref}__wrap-body main-wrap-block__body`} onScroll={onScroll}>
            <ul className="publications-table-headers">
                <li><span/></li>
                <li
                    style={{
                        minWidth: `${activeTab === 1 ? headersWidth.datasheet + 30 : headersWidth.datasheet}px`,
                        maxWidth: `${activeTab === 1 ? headersWidth.datasheet + 30 : headersWidth.datasheet}px`
                    }}
                >
                    <div className="sticky-title">
                        <span>Technical Data Sheet</span>
                    </div>
                </li>
                <li
                    style={{
                        minWidth: `${activeTab === 1 ? headersWidth.process + 30 : headersWidth.process}px`,
                        maxWidth: `${activeTab === 1 ? headersWidth.process + 30 : headersWidth.process}px`
                    }}
                >
                    <div className="sticky-title">
                        <span>Processing Data Sheet</span>
                    </div>
                </li>
                <li
                    style={{
                        minWidth: `${activeTab === 1 ? headersWidth.design + 15 : headersWidth.design}px`,
                        maxWidth: `${activeTab === 1 ? headersWidth.design + 15 : headersWidth.design}px`
                    }}
                >
                    <div className="sticky-title">
                        <span>Guides</span>
                    </div>
                </li>
                <li
                    style={{
                        minWidth: `${activeTab === 1 ? headersWidth.safety + 15 : headersWidth.safety}px`,
                        maxWidth: `${activeTab === 1 ? headersWidth.safety + 15 : headersWidth.safety}px`
                    }}
                >
                    <div className="sticky-title">
                        <span>Safety Data Sheet</span>
                    </div>
                </li>
                <li
                    style={{
                        minWidth: `${activeTab === 1 ? headersWidth.brochure + 15 : headersWidth.brochure}px`,
                        maxWidth: `${activeTab === 1 ? headersWidth.brochure + 15 : headersWidth.brochure}px`
                    }}
                >
                    <div className="sticky-title">
                        <span>Brochure</span>
                    </div>
                </li>
                <li><span/></li>
            </ul>
            <TabTable
                onClickCustom={_onClickCustom}
                customIcon={customIcon}
                onClickEdit={_onClickEdit}
                onClickDelete={_onClickDelete}
            />
        </div>
    );
};

PublicationsTabViewIndex.propTypes = propTypes;
PublicationsTabViewIndex.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        items: getStoreItem(state, "items"),
        activeTab: getStoreItem(state, "activeTab"),
        columns: getStoreItem(state, "columns"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(PublicationsTabViewIndex);
