import Constants from "../Constants";

export const fetchItems = (service, dispatch) => data => {
    dispatch({ type: Constants.FETCH_ITEMS_REQUESTED });

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            const { sort } = data;
            dispatch({ type: Constants.FETCH_ITEMS_SUCCEEDED, data: { table: res, sort: sort || {} } });
        },
        () => {
            dispatch({ type: Constants.FETCH_ITEMS_FAILED, data: { items: [] } });
        },
    );
};

export const fetchPage = (service, dispatch) => data => {
    dispatch({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch({ type: Constants.NEXT_PAGE, data: res });
        },
        () => {
            dispatch({ type: Constants.FETCH_ITEMS_FAILED, data: { items: [] } });
        },
    );
};
