import _ from "lodash";
import * as Actions from "../Actions";

export const getStoreItem = nameStore => (state, key) => _.get(state, `${nameStore}.${key}`);

export const getActionStore = (action, service, dispatch) => {
    const actions = { ...Actions };

    return _.get(actions, action, () => {})(service, dispatch);
};

export const onChange = value => {
    return new Promise(resolve => {
        resolve(value);
    });
};
