import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import ChangesHistoryTableRowColumnTemplate from "../../../../../Services/Tabs/ChangesHistory/Table/ChangesHistoryTableRowColumn";
import { withServiceConsumer } from "Services/Context";
import TableRowColumnDefault from "../../../../../Services/Tabs/ChangesHistory/Table/DefaultWrap"

const propTypes = {
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired
};

const ChangesHistoryTableRowColumn = React.memo(({ index, column, item }) => {
    return (
        <ChangesHistoryTableRowColumnTemplate key={`TableRowColumn-${index}`}>
            <TableRowColumnDefault column={column} item={item} />
        </ChangesHistoryTableRowColumnTemplate>
    );
});

ChangesHistoryTableRowColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(ChangesHistoryTableRowColumn);
