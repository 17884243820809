import React from "react";
import { getString, formatDate } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { contactPhoneTypes, contactEmailTypes } from "Services/enum";
import _ from "lodash";

const getDunsNumberData = () => {
    return 'D-U-N-S&#174; Number'
}

const tableLabel = {
    name: "Contact Name",
    title: "Title",
    company: "Customer",
    customer_code: "SAP Company Code",
    duns_number: getDunsNumberData(),
    phone: "Phone",
    email: "Email",
    type: "Int/Ext",
    owner: "Owner",
    created_at: "Date of Creation",
};

export const title = "Contacts";

export const getTableLabel = key => getString(tableLabel, key, key);

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/contacts/${getString(item, "id")}`}
                    img={getString(item, "photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "company":
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, "company_name")}
                    href={`/companies/${getString(item, "company_id")}`}
                >
                    {getString(item, "company_name")}
                </TableRowItemLink>
            );

        case "email":
            const emails = item.email ? JSON.parse(item.email) : [];
            return (
                <>
                    {emails.map((elem, index) => (
                        <TableRowItemSpan title={elem.value} key={`TableRowItemSpan-${elem.value}`}>
                            {`${_.find(contactEmailTypes, {value:`${elem.type}`}).label}: `}
                            <TableRowItemLink key={`TableRowItemLink-${elem.value}`} title={elem.value}
                                              href={`mailto:${elem.value}`}>
                                {elem.value}
                            </TableRowItemLink>
                        </TableRowItemSpan>
                    ))}
                </>
            );

        case "phone":
            const phones = item.phone ? JSON.parse(item.phone) : [];
            return (
                <>
                    {phones.map((elem, index) => (
                        <TableRowItemSpan title={elem.value} key={`TableRowItemSpan-${elem.value}`}>
                            {`${_.find(contactPhoneTypes, {value:`${elem.type}`}).label}: ${elem.value}`}
                        </TableRowItemSpan>
                    ))}
                </>
            );

        case "created_at": {
            const elem = getString(item, key);
            const formatValue = elem.toString() === '-' ? elem : formatDate(elem);

            return (
                <TableRowItemSpan title={formatValue}>
                    {formatValue}
                </TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
