import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    loading: false,
    items: [],
    pagination: {},
    sort: {},
    columns: [],
    errors: {},
    tab: 0,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.TABLE_SORT:
            data.items = data.items.map(item => ({
                ...item,
                'current_total_analytics': data.current_total_analytics,
            }));

            return { ...state, items: data.items, sort: data.sort, pagination: data.pagination };

        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            data.items = data.items.map(item => ({
                ...item,
                'current_total_analytics': data.current_total_analytics,
            }));

            return { ...state, ...data, loading: false };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.NEXT_PAGE:
            data.items = data.items.map(item => ({
                ...item,
                'current_total_analytics': data.current_total_analytics,
            }));

            return { ...state, items: _.concat(state.items, data.items), pagination: data.pagination, loading: false };
        default: {
            return state;
        }
    }
};
