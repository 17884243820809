import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import AsyncSelect from "Services/AsyncSelect/AsyncSelect";
import AsyncMultiSelect from "Services/AsyncMultiSelect/AsyncMultiSelect";
import AsyncMultiSelectNoCache from "Services/AsyncMultiSelect/AsyncMultiSelectNoCache";
import DateRange from "Services/DateRange/DateRange";
import UniqueListAsyncSelect from "Services/AsyncSelect/UniqueListAsyncSelect";
import AsyncSelectNoCache from "Services/AsyncSelect/AsyncSelectNoCache";
import DateInput from "./DateInput";
import DateInputSelect from "./DateSelect/DateInputSelect";
import TextArea from "./TextArea";
import SearchSelect from "./SearchSelect";
import Select from "./Select";
import MultiSelect from "./MultiSelect";
import File from "./File";
import AsyncSearchMultiSelect from "Services/AsyncMultiSelect/AsyncSearchMultiSelect";
import AsyncResponseSelect from "Services/AsyncSelect/AsyncResponseSelect";
import AsyncCompareProductSelect from "../../Services/AsyncSelect/AsyncCompareProductSelect";
import CompareProductsMultiSelect from "./CompareProductsMultiSelect";
import AsyncMultiSelectWithLabel from "Services/AsyncMultiSelect/AsyncMultiSelectWithLabel";

const propTypes = {
    /* for all types input */
    disabled: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.object),
    ]),
    onChange: PropTypes.func.isRequired,
    onMenuAction: PropTypes.func,
    placeholder: PropTypes.string,
    inputProps: PropTypes.objectOf(PropTypes.any),
    /* for all types input */

    url: PropTypes.string, // for asyncSelect
    params: PropTypes.any, // for asyncSelect
    options: PropTypes.arrayOf(PropTypes.object), // for searchSelect and select
    viewFormat: PropTypes.string, // For date
    multiple: PropTypes.bool, // For file
    handleClickOnDeleteButton: PropTypes.func, // For file
    handleSelectedOptionsLength: PropTypes.func,

    valuesList: PropTypes.arrayOf(PropTypes.any)
};

const defaultProps = {
    multiple: false,
    disabled: false,
    showEmptyOption: true,
    type: "text",
    placeholder: "",
    value: "",
    url: "",
    params: null,
    inputProps: {},
    options: [],
    viewFormat: "MMM DD, YYYY",
    valuesList: [],
    handleClickOnDeleteButton: () => {},
    handleSelectedOptionsLength: () => {},
    onMenuAction: () => {},
};

const Input = ({
    multiple,
    disabled,
    type,
    className,
    placeholder,
    value,
    url,
    params,
    onChange,
    onMenuAction,
    showEmptyOption,
    handleClickOnDeleteButton,
    options,
    inputProps,
    valuesList,
    viewFormat,
    handleSelectedOptionsLength
}) => {
    className = `${className} ${_.get(inputProps, "className", "")}`;
    _.unset(inputProps, "className");

    const _onChange = e => {
        const value = _.get(e, "target.value", _.get(e, "value", e));
        const label = _.get(e, "label");
        onChange(value, label);
    };

    switch (type) {
        case "asyncSelect":
            return (
                <AsyncSelect
                    placeholder={placeholder}
                    disabled={disabled}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    params={params}
                    handleChange={_onChange}
                    onMenuAction={onMenuAction}
                />
            );
        case "asyncCompareProductsSelect":
            return (
                <AsyncCompareProductSelect
                    placeholder={placeholder}
                    disabled={disabled}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    params={params}
                    handleChange={_onChange}
                    onMenuAction={onMenuAction}
                />
            );
        case "asyncResponseSelect":
            return (
                <AsyncResponseSelect
                    placeholder={placeholder}
                    disabled={disabled}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    handleChange={_onChange}
                />
            );
        case "asyncSelectNoCache":
            return (
                <AsyncSelectNoCache
                    placeholder={placeholder}
                    disabled={disabled}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    handleChange={_onChange}
                />
            );
        case "uniqueListAsyncSelect":
            return (
                <UniqueListAsyncSelect
                    valuesList={valuesList}
                    placeholder={placeholder}
                    disabled={disabled}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    handleChange={_onChange}
                    handleSelectedOptionsLength={(val) => handleSelectedOptionsLength(val)}
                />
            );

        case "searchSelect":
            return (
                <SearchSelect
                    disabled={disabled}
                    inputProps={inputProps}
                    className={className}
                    value={value}
                    showEmptyOption={showEmptyOption}
                    options={options}
                    placeholder={placeholder}
                    onChange={_onChange}
                />
            );

        case "select":
            return (
                <Select
                    disabled={disabled}
                    inputProps={inputProps}
                    value={value}
                    options={options}
                    placeholder={placeholder}
                    onChange={_onChange}
                />
            );

        case "multiSelect":
            return (
                <MultiSelect
                    disabled={disabled}
                    inputProps={inputProps}
                    value={value}
                    options={options}
                    placeholder={placeholder}
                    onChange={_onChange}
                />
            );
        case "compareProductsMultiSelect":
            return (
                <CompareProductsMultiSelect
                    disabled={disabled}
                    inputProps={inputProps}
                    value={value}
                    options={options}
                    placeholder={placeholder}
                    onChange={_onChange}
                />
            );
        case "asyncSearchMultiSelect":
            return (
                <AsyncSearchMultiSelect
                    placeholder={placeholder}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    handleChange={_onChange}
                />
            );
        case "asyncMultiSelect":
            return (
                <AsyncMultiSelect
                    placeholder={placeholder}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    handleChange={_onChange}
                />
            );
        case "asyncMultiSelectWithLabel":
            return (
                <AsyncMultiSelectWithLabel
                    placeholder={placeholder}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    handleChange={_onChange}
                />
            );
        case "asyncMultiSelectNoCache":
            return (
                <AsyncMultiSelectNoCache
                    placeholder={placeholder}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    handleChange={_onChange}
                />
            );
        case "textarea":
            return (
                <TextArea
                    className={className}
                    placeholder={placeholder}
                    value={value}
                    onChange={_onChange}
                    inputProps={inputProps}
                />
            );

        case "date":
            return (
                <DateInput
                    disabled={disabled}
                    className={className}
                    placeholder={placeholder}
                    value={value}
                    onChange={_onChange}
                    inputProps={inputProps}
                    viewFormat={viewFormat}
                />
            );

        case "date-range":
            return (
                <DateRange
                    value={value}
                    className={className}
                    onChange={_onChange}
                />
            );

        case "date-select":
            return (
                <DateInputSelect
                    className={className}
                    placeholder={placeholder}
                    value={value}
                    onChange={_onChange}
                    inputProps={inputProps}
                    viewFormat={viewFormat}
                />
            );

        case "file":
            return (
                <File
                    multiple={multiple}
                    className={className}
                    value={value}
                    inputProps={inputProps}
                    onChange={onChange}
                    handleClickOnDeleteButton={handleClickOnDeleteButton}
                />
            );

        case "magnifyGlass":
            return (
                <div>
                    <input
                        {...inputProps}
                        disabled={disabled}
                        className={className}
                        placeholder={placeholder}
                        value={value}
                        onChange={_onChange}
                    />
                </div>
            );

        default:
            return (
                <input
                    {...inputProps}
                    disabled={disabled}
                    className={className}
                    placeholder={placeholder}
                    value={value}
                    onChange={_onChange}
                />
            );
    }
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
