import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {DivColumn, DivRow} from "Templates/Table/NewVersion";
import {Span} from "Templates/Default";
import {getString, numberWithCommas} from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.any),
};

const defaultProps = {
    items: [],
};

const InvoiceLineColumn = ({items, t}) => {
    const InvoiceLineEnum = {
        line_number: "Invoice Line",
        order_country: "Order Country",
        order_region: "Order Region",
        grade: "Product Name",
        plant: "Plant Name",
        quantity: "Billed Quantity",
        sales_unit: "Sales Unit",
        net_amount: "Net Amount",
        cost: "Cost",
        fixed_cost: "Fixed Cost",
        variable_cost: "Variable Cost",
        business_area: "Business Area",
        gross_weight: "Gross Weight",
        gross_weight_unit: "Gross Weight Unit",
        normalized_weight: "Normalized Weight",
        normalized_weight_unit: "Normalized Weight Unit",
        order_number: "Sales Order",
        order_line_number: "Sales Order Line",
        reference_invoice_number: "Reference Invoice",
        reference_invoice_line_number: "Reference Invoice Line",
        freight_amount: "Freight Amount",
        header_long_text: "Header Long Text",
        line_long_text: "Line Long Text",
        gross_margin: "Gross Margin",
        gross_margin_percent: "Gross Margin %",
        price_kg: "Price per KG",
        cost_kg: "Cost per KG",
        cancelled: "Cancelled",
        surcharge_zb00: "Freight",
        surcharge_zb04: "Price Adj",
        surcharge_zb05: "Freight Surcharge",
        surcharge_zd00: "Misc Charges",
        free_of_charge: "Free Of Charge",
    };

    const getViewItem = (column, key) => {
        const elem = getString(items, column);
        const item = getString(elem, key, '');

        switch (key) {
            case "net_amount":
            case "cost":
            case "fixed_cost":
            case "variable_cost":
            case "gross_margin":
            case "price_kg":
            case "cost_kg":
            case "surcharge_zb00":
            case "surcharge_zb04":
            case "surcharge_zb05":
            case "surcharge_zd00":
            case "freight_amount": {
                const number = `$${numberWithCommas(item, 3)}`;
                return (
                    <TableRowItemSpan title={number}>{number}</TableRowItemSpan>
                );
            }

            case "normalized_weight":
            case "gross_weight": {
                const number = `${numberWithCommas(item, 3)}`;
                return (
                    <TableRowItemSpan title={number}>{number}</TableRowItemSpan>
                );
            }

            case "gross_margin_percent": {
                const number = `${numberWithCommas(item, 2)}`;
                return (
                    <TableRowItemSpan title={number}>{number} %</TableRowItemSpan>
                );
            }

            case 'grade': {
                if (!item) {
                    return (
                        <TableRowItemSpan title="-">-</TableRowItemSpan>
                    );
                }

                return (
                    <TableRowItemLink
                        blank
                        title={item}
                        href={getString(elem, 'is_grade') ? `/product/product-finder/${getString(elem, 'grade_id')}` : `/product/raw/${getString(elem, 'grade_id')}`}
                    >
                        {item}
                    </TableRowItemLink>
                );
            }

            case 'plant': {
                if (!item) {
                    return (
                        <TableRowItemSpan title="-">-</TableRowItemSpan>
                    );
                }

                return (
                    <TableRowItemLink
                        blank
                        title={item}
                        href={`/product/plants/${getString(elem, 'plant_id')}`}
                    >
                        {item}
                    </TableRowItemLink>
                );
            }

            default:
                return (
                    <TableRowItemSpan title={item || '-'}>{item || '-'}</TableRowItemSpan>
                );
        }
    }

    return (
        <>
            <DivColumn>
                {Object.values(InvoiceLineEnum).map(month => (
                    <DivRow key={`Row-${month}`}>
                        <Span className="gray-text">{t(month)}</Span>
                    </DivRow>
                ))}
            </DivColumn>

            {Object.keys(items).map(column => (
                <DivColumn key={`Column-${column}`}>
                    {Object.keys(InvoiceLineEnum).map(key => (
                        <DivRow key={`Row-${key}`}>
                            <Span>{getViewItem(column, key)}</Span>
                        </DivRow>
                    ))}
                </DivColumn>
            ))}
        </>
    );
};

InvoiceLineColumn.propTypes = propTypes;
InvoiceLineColumn.defaultProps = defaultProps;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        items: getStoreItem(state, "items"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InvoiceLineColumn);
