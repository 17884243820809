import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import Img from "Templates/Img";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    index: PropTypes.number.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
};

const TableRowItem = (
    {
        index,
        onClickDelete,
        onClickCustom
    }) => {

    const _onClickDelete = () => {
        onClickDelete(index);
    };

    const _onClickCustom = () => {
        onClickCustom(index);
    };

    return (
        <TableBodyButtonsBlock>
            <TableBodyButtonsBlock>
                <TableBodyButton onClick={_onClickCustom}>
                    <Img img="icon_check_green" />
                </TableBodyButton>

                {/*<TableBodyButton onClick={_onClickDelete}>*/}
                {/*    <Img img="icon_delete" />*/}
                {/*</TableBodyButton>*/}
            </TableBodyButtonsBlock>
        </TableBodyButtonsBlock>
    );
};

TableRowItem.propTypes = propTypes;

export default compose(withServiceConsumer, withTagDefaultProps(),)(TableRowItem);
