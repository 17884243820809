import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import { withTagDefaultProps } from "Hoc/Template";
import { url } from "Services";
import InfoWrap from "./Views/InfoWrap";

import MainBlock from "./Views/MainBlock";

const ConnectionView = ({ id }) => {
    const onClickEdit = () => {
        url.redirect(`/connection/connections/${id}/edit`);
    };

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit} />
            <ContentWrapCol>
                <MainBlock />
            </ContentWrapCol>
        </ContentWrap>
    );
};

ConnectionView.propTypes = {
    id: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        id: state.listState.view.id,
        activeTab: state.listState.activeTab,
        totalNotes: state.listState.totalNotes,
        totalFinanceNotes: state.listState.totalFinanceNotes,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ConnectionView);
