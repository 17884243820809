import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Row } from "Templates/Form";

const Success = ({ email, t }) => {
    return (
        <div className="main-wrap forgot">
            <div className="forgot__wrap forgot__wrap--success">
                <div className="forgot__logo">
                    <a href="/" className="forgot__logo-link">
                        {t("ComOpps")}
                    </a>
                </div>
                <h1 className="forgot__title">{t("Success!")}</h1>
                <div className="forgot__desc">{t(`We’ve sent an email to ${email} with password reset instructions. If the email doesn’t show up soon, check your spam folder`)}</div>
                <Row>
                    <a href="/login" className="forgot__form-return">
                        {t("Return to login")}
                    </a>
                </Row>
            </div>
        </div>
    );
};

Success.propTypes = {
    email: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    email: getStoreItem(state, "email"),
});

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Success);
