import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoLogo,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";

const defaultProps = {
    applications: [],
    company: "",
    companyId: "",
};

const propTypes = {
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    companyId: PropTypes.string,
    applications: PropTypes.arrayOf(PropTypes.string),
    onClickEdit: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const InfoWrap = ({ logo, name, company, companyId, applications, t, onClickEdit, permissions }) => {
    return (
        <ContentInfoWrap>
            <ContentInfoTop
                onClickEdit={onClickEdit}
                urlBack="/admin/list"
            />
            <ContentInfoLogo url={logo} />

            <ContentInfoBlock>
                <ContentInfoTitle>{name}</ContentInfoTitle>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!companyId}>
                <ContentInfoBlockTitle>{t("Supplier")}</ContentInfoBlockTitle>
                <ContentInfoBlockText type="link" value={`/admin/companies/${companyId}`} title={company}  permission={'companies_view'} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!applications.length}>
                <ContentInfoBlockTitle>{t("Applications")}</ContentInfoBlockTitle>
                {applications.map((elem, index) => (
                    <ContentInfoBlockText key={index} value={elem} />
                ))}
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.defaultProps = defaultProps;
InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        logo: getStoreItem(state, "view.logo"),
        name: getStoreItem(state, "view.name"),
        company: getStoreItem(state, "view.company"),
        companyId: getStoreItem(state, "view.companyId"),
        applications: getStoreItem(state, "view.applications"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
