import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {
    columnSize: 0,
    fromColumns: 0,
};

const propTypes = {
    columnSize: PropTypes.number,
    fromColumns: PropTypes.number,
};

const Column = ({ pref, children, columnSize, fromColumns }) => {
    return (
        <div className={`${pref}__column width_${columnSize ? `${columnSize}-${fromColumns}` : "100"}`}>{children}</div>
    );
};

Column.defaultProps = defaultProps;
Column.propTypes = propTypes;

export default compose(withTagDefaultProps())(Column);
