import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    column: PropTypes.string.isRequired,
};
const QuickEditTableRowColumnDefault = ({ column, item, service, weightUnit }) => {
    const { getViewItem } = service;
    return getViewItem(column, item, weightUnit);
};

QuickEditTableRowColumnDefault.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    weightUnit: state.weightUnitState,
  };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(QuickEditTableRowColumnDefault);
