import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Tooltip} from "Templates/Form";
import parse from "html-react-parser";

const propTypes = {
    column: PropTypes.string.isRequired,
    service: PropTypes.shape({getTableLabel: PropTypes.func.isRequired}).isRequired,
};

const TableHeadTitle = ({column, service, pref}) => {
    const {getTableLabel} = service;
    const columnsLabels = {
        'changed_date': 'The date data changed in the Customer SAP record.',
        'updated_date': 'Date record was changed in ComOpps',
        'created_code_date': 'Date Created = Date record was created in SAP. Date Extended = Date record was extended from original business creator to another legal entity (Company) in SAP.',
        'created_system_date': 'Date record was created in SAP',
        'duns_number': 'D-U-N-S is a registered trademark D&B'
    };

    return (
        <div className={`${pref}__table-head-row main-table__head-row`}>
            <div className={columnsLabels[column] ? `head__with-tooltip` : ``}>
                <span className={`${pref}__table-head-link main-table__head-link`}>
                    {parse(getTableLabel(column))}
                    {
                        columnsLabels[column] && (
                            <Tooltip>
                                {columnsLabels[column]}
                            </Tooltip>
                        )
                    }
                </span>
            </div>
        </div>
    )
};

TableHeadTitle.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableHeadTitle);
