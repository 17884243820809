import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import FileList from "Services/FileList/FileList";
import {Preloader} from "Templates/Preloader";

const { Button, Form, Title } = ModalDefault;

const propTypes = {
    onAppend: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    title: PropTypes.string.isRequired,
};

const UploadFileForm = ({ t, form, onAppend, onClose, onDrop, onChange, title }) => {
    const disabled = form.files.length <= 0;
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (form.files.length > 0 || !form.files.length) {
            setLoading(false)
        }
    }, [form.files])

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <Title>{t(`Add ${title}`)}</Title>
            <FileList
                name="files"
                fileLength={1}
                files={form.files}
                dropFile={e => onDrop(e)}
                handleDrop={e => {
                    setLoading(true)
                    onChange(e)
                }}
            />
            <Button onClick={onAppend} disabled={disabled}>
                {t("Save File")}
            </Button>
            {loading
                ? <div className="upload-file-loader">
                    <Preloader />
                  </div>
                : null
            }
        </Form>
    );
};

UploadFileForm.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(UploadFileForm);
