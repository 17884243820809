import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {
    href: "/admin/help-pages",
    icon: "la arrow-left",
    onClick: () => {},
};

const propTypes = {
    href: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
};

const LinkIcon = ({ href, icon, children, pref, onClick }) => {
    const _onClick = e => {
        if (href === "#") {
            e.preventDefault();
            onClick();
        }
    };
    return (
        <a href={href} onClick={_onClick} className={`${pref}__info-top-btn main-content__info-top-btn`}>
            <i className={`la ${icon}`} />
            {children}
        </a>
    );
};

LinkIcon.defaultProps = defaultProps;
LinkIcon.propTypes = propTypes;

export default compose(withTagDefaultProps())(LinkIcon);
