import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer, ServiceProvider } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import Column from "../Templates/Column";
import Row from "../Templates/Row";
import DashboardCard from "./Card/Widget/DashboardCard";
import TableDataDashboardCard from "./Card/Table/DataDashboardCard";
import TableService from "../Services/TableService";

const propTypes = {
    tasks: PropTypes.number.isRequired,
    service: PropTypes.shape({ getStoreItem: PropTypes.func.isRequired, getActionStore: PropTypes.func.isRequired })
        .isRequired,
};

const InfoDashboardCard = DashboardCard({});
const TableDashboardCard = DashboardCard({ DataDashboardCard: TableDataDashboardCard });

const DashboardTypeFive = ({ tasks, service: { getStoreItem, getActionStore }, t }) => {
    return (
        <div className="dashboard__wrap-main main-content__wrap">
            <div className="dashboard__list">
                <Row>
                    <Column columnSize={1} fromColumns={4}>
                        <Row>
                            <Column>
                                <InfoDashboardCard
                                    headProps={{ title: t("Current Tasks") }}
                                    dataProsp={{ value: tasks }}
                                />
                            </Column>
                        </Row>
                    </Column>
                    <Column columnSize={3} fromColumns={4}>
                        <ServiceProvider value={{ ...TableService(), getActionStore, getStoreItem }}>
                            <TableDashboardCard className="main-data" headProps={{ title: t("My Tasks") }} />
                        </ServiceProvider>
                    </Column>
                </Row>
            </div>
        </div>
    );
};

DashboardTypeFive.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        tasks: getStoreItem(state, "dashboard.analytic.tasks"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DashboardTypeFive);
