import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockWrap, BlockWrapFlex, Input, WrapInput } from "Templates/Form";

const propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    assignedTo: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const MainBlock = ({ title, project, assignedTo, description, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <BlockWrap>
            <WrapInput label={t("Task Title")} name="title" isRequired>
                <Input placeholder={t("Task Title")} value={title} onChange={value => onChangeInput(value, "title")} />
            </WrapInput>
            <BlockWrapFlex>
                <WrapInput label={t("Select Project")} name="project" isRequired>
                    <Input
                        placeholder={t("Search")}
                        type="asyncSelect"
                        url="tasks/project-list"
                        value={project}
                        onChange={value => onChangeInput(value, "project")}
                    />
                </WrapInput>
                <WrapInput label={t("Assign to")} name="assignedTo" isRequired>
                    <Input
                        placeholder={t("Search")}
                        type="asyncSelect"
                        url={`tasks/${project}/contact-list`}
                        value={assignedTo}
                        onChange={value => onChangeInput(value, "assignedTo")}
                    />
                </WrapInput>
            </BlockWrapFlex>
            <WrapInput label={t("Details")} name="description" isRequired>
                <Input
                    isTextArea
                    type="textarea"
                    placeholder={t("Details")}
                    value={description}
                    onChange={value => onChangeInput(value, "description")}
                />
            </WrapInput>
        </BlockWrap>
    );
};

MainBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        title: getStoreItem(state, "title"),
        project: getStoreItem(state, "project"),
        assignedTo: getStoreItem(state, "assignedTo"),
        description: getStoreItem(state, "description"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MainBlock);
