import React from "react";
import { compose } from "redux";
import { ServiceProvider } from "Services/Context";
import { CustomSimpleTable } from "Hoc/Widgets/Table/SimpleTableContainer";
import { withTagDefaultProps } from "Hoc/Template";
import UnitDetailTableBodyColumn from "./Table/TableBodyColumn";
import {SapTableService} from "../../Services";

const propTypes = {};

const SapTable = CustomSimpleTable()(() => null)()(UnitDetailTableBodyColumn)()(() => null);

const SapForm = () => {
    return (
        <div className="projects-view__wrap-holder scroll-card main-content__wrap-holder main-wrap-block cost_model__form-block_shadow">
            <ServiceProvider value={SapTableService}>
                <SapTable />
            </ServiceProvider>
        </div>
    );
};

SapForm.propTypes = propTypes;

export default compose(withTagDefaultProps())(SapForm);
