import React, {Component} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";

const defaultProps = {
    fullName: "",
    email: "",
};

const propTypes = {
    fullName: PropTypes.string,
    email: PropTypes.string,
};

class ContactForm extends Component {
    openWidget = () => {
        const {fullName, email} = this.props
        zE('webWidget', 'prefill', {
            name: {
                value: fullName,
            },
            email: {
                value: email,
                readOnly: true // optional
            },
        });
        zE("webWidget", "show")
        zE("webWidget", "open")

        document.querySelector("#zendeskLauncher").style.opacity = 0
    }

    render() {
        return <button
            type="button"
            id='zendeskLauncher'
            onClick={this.openWidget}>
            <i className="fa fa-question-circle-o fa-fw fa-lg"></i>
            Support
        </button>
    }
}

ContactForm.defaultProps = defaultProps;
ContactForm.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        fullName: state.userState.fullName,
        email: state.userState.email,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ContactForm);
