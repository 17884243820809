import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import CheckPermission from "Hoc/Template/CheckPermission";
import Img from "Templates/Img/Img";

const propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        photo: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
        companyName: PropTypes.string.isRequired,
        hasNotifications: PropTypes.number.isRequired,
        companyList: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                current_company_name: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

const UserProfile = ({user, t}) => {

    return (
        <div className="main-header__user">
            <span className="main-header__user-circle main-circle">
                {
                    user.photo ?
                        <img src={`${user.photo}`} alt=""/>
                        : <Img img="icon_contact_small" alt=""/>
                }
            </span>
            <div className="main-header__user-data">
                <span title={user.fullName} className="main-header__user-text">
                    {user.fullName}
                </span>
                <span title={user.companyName} className="main-header__user-caption">
                    {user.companyName}
                </span>
            </div>
            <ul className="main-header__dropdown">
                <CheckPermission permissionList={['profile_general', 'profile_guests']}>
                    <li className="main-header__dropdown-item">
                        <a href="/profile" className="main-header__dropdown-link">
                            {t("Profile")}
                        </a>
                    </li>
                </CheckPermission>
                <li className="main-header__dropdown-item">
                    <a href="/change-password" className="main-header__dropdown-link">
                        {t("Reset password")}
                    </a>
                </li>
                {user.companyList.sort((a, b) => {
                    if (a.current_company_name < b.current_company_name) return -1;
                    if (a.current_company_name > b.current_company_name) return 1;
                    return 0;
                }).map((company, index) => (
                    <li key={`Company-${index}`} className="main-header__dropdown-item">
                        <a href={`/admin/users/current-company/${company.id}`} className="main-header__dropdown-link">
                            {company.current_company_name}
                        </a>
                    </li>
                ))}
                <li className="main-header__dropdown-item">
                    <a href="/logout" className="main-header__dropdown-link">
                        {t("Log Out")}
                    </a>
                </li>
            </ul>
        </div>
    );
};

UserProfile.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        user: state.userState,
    };
};

export default compose(withTagDefaultProps(mapStateToProps))(UserProfile);
