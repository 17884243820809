import React from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import * as DescriptionService from "../../../../../Services/Tabs/General/Table/DescriptionService";
import ExpandedBlock from "../../../../Templates/ExpandedBlock";
import DescriptionTableBodyColumn from "./Table/TableBodyColumn";

const DescriptionTable = CustomSimpleTable()(() => null)()(DescriptionTableBodyColumn)()(() => null);

const DescriptionBlock = ({ t, block, expanded, setExpandedAction }) => {
    return (
        <div className="project-expanded-block">
            <ExpandedBlock title={t("Project Description")} service={DescriptionService} block={block} isExpanded={expanded} setExpandedAction={setExpandedAction}>
                <DescriptionTable />
            </ExpandedBlock>
        </div>
    );
};

DescriptionBlock.propTypes = {};

export default compose(withTagDefaultProps())(DescriptionBlock);
