import { request, url } from "Services";
import { deleteItem } from "../../RequestService";

const getItems = (data, listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, listUrl).then(res => {
            resolve({
                items: res[0].items,
                columns: res[0].columns,
                pagination: res[0].pagination,
                totalAnalytic: res[0].totalAnalytic,
                total_analytics: res[0].total_analytics,
                totalProjects: res[0].totalProjects || 0,
            });
        }, reject);
    });
};

export { deleteItem };
