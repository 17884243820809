import React from "react";
import { getString, formatDate, numberWithCommas } from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import _ from "lodash";

export const modifierValues = items => items;
export const getViewItem = (key, item) => {
    switch (key) {
        case "unit":
            return (
                <TableRowItemLink title={getString(item, key)} href={`/project/forecasts/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "sop":
        case "eop": {
            return (
                <TableRowItemSpan title={formatDate(getString(item, key))}>
                    {formatDate(getString(item, key))}
                </TableRowItemSpan>
            );
        }

        case "submitted_date":
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );

        case "total":
            return (
                <TableRowItemSpan title={numberWithCommas(getString(item, key))}>
                    {numberWithCommas(getString(item, key))}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
