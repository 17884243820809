import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { isYesNo } from "Services/enum";
import { useSelector } from "react-redux";
import { weightUnitName } from 'Services/weightUnitName';
import { onChange } from "./EventsService";

export const getTableLabel = key => {
    const weightUnit = useSelector(state => state.weightUnitState);

    const tableLabel = {
        name: "SAP Material Code",
        legacy_material_code: "Legacy SAP Material Code",
        description: "SAP Material Name",
        selling_plant: "Selling Plant",
        map_cost: `MAP ($/${weightUnitName(weightUnit.weightUnit)})`,
        iso_name: 'ISO Certificate Name',
        iso_expiration_date: 'ISO Certificate Expiration Date',
        manufacturer: 'Manufacturer',
        vendor_code: 'Vendor Code',
        distributor: 'Distributor',
        distributor_vendor_code: 'Distributor Vendor Code',
        material_type: 'Material Type',
        lead_time: 'Lead Time',
        standard_packaging: 'Standard Packaging',
        delivery_terms: 'Delivery Terms',
        freight_class: 'Freight Class',
        effective_from_date: 'Effective From',
        quantity_per_unit: 'Per (unit)',
        unit: 'Unit',
        scale_quantity: 'Scale Quantity (from)',
        purchase_price: 'Price',
        note: 'Notes',
        date_update: 'Change Date',
        obsolete_marker: "Obsolete Marker",
        deletion_marker: "Deletion Marker",
        sap_value: "SAP Import",
    };
    return _.get(tableLabel, key, key);
};

export const getTooltip = key => {
    const tableTooltip = {
        last_price: "<div>The price listed on the last PO</div> <div>created for each raw material</div> <div>record. It is assumed this</div> <div>price remains the same at the</div> time of delivery.",
        prior_price: "<div>The price listed on the prior PO</div> <div>for each raw material record.</div>",
        change_price: '<div>Price Change = "Last Price Paid" - "Prior Price Paid"</div>  <div class="padding">Values in Green are a decrease in price indicating a </div> <div>positive impact on margins. </div> <div class="padding">Values in Red are an increase in price indicating a </div> <div>negative impact on margins.</div>',
        // created_date: '<div>Date "Last Price Paid"</div> <div>PO was created.</div>',
        // date_update: '<div>Date the PO record was changed in ComOpps.<div> <div class="padding">Example:<div> <div>A PO is created in SAP on August 1.</div> <div>The new PO data is transferred from SAP to</div> <div>ComOpps on August 2.</div> <div>The "Change Date" is August 2.</div>'
    };
    return _.get(tableTooltip, key, null);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        legacy_material_code: "Search",
        description: "Search",
        last_price: "Search",
        prior_price: "Search",
        change_price: "Search",
        iso_name: "Search",
        manufacturer: 'Search',
        vendor_code: 'Search',
        distributor: 'Search',
        distributor_vendor_code: 'Search',
        material_type: 'Search',
        lead_time: 'Search',
        standard_packaging: 'Search',
        delivery_terms: 'Search',
        freight_class: 'Search',
        scale_quantity: 'Search',
        purchase_price: 'Search',
        quantity_per_unit: 'Search',
        unit: 'Search',
        note: 'Search',
        selling_plant: "Search",
        map_cost: "Search",
        deletion_marker: "Select",
        obsolete_marker: "Select",
        sap_value: "Select"
    };

    switch (key) {
        case "created_date":
        case "date_update":
        case "iso_expiration_date":
        case "effective_from_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "obsolete_marker":
        case "deletion_marker":
        case "sap_value":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{ value: '', label: "-" }], ...isYesNo]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );


        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => !(
    /effective_from_date/.test(key) ||
    /quantity_per_unit/.test(key) ||
    /unit/.test(key) ||
    /scale_quantity/.test(key) ||
    /purchase_price/.test(key) ||
    /selling_plant/.test(key) ||
    /map_cost/.test(key) ||
    /obsolete_marker/.test(key) ||
    /deletion_marker/.test(key)
);
