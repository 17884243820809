import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { TabWrap } from "Templates/Content";
import { withTagDefaultProps } from "Hoc/Template";
import { url } from "Services";
import PublicationsTabViewIndex from "./PublicationsTabViewIndex";

const defaultProps = {}

const propTypes = {
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const TabView = (
    {
        service,
        request,
        fetchItems,
        t
    }) => {

    const { deleteItem } = service;

    useEffect(() => {
        fetchItems(request)
    }, []);

    const onClickEdit = item => {
        if (!item.canEdit) {
            alert('Contact an Admin to edit.');
            return;
        }

        url.redirect(`/admin/publications/${item.id}/edit`);
    };

    const onClickDelete = item => {
        if (!item.canEdit) {
            alert('Contact an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the Publication?"))) {
            deleteItem(`/admin/publications/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickShow = item => {
        const win = window.open(`/admin/publications/${item.id}`, "_blank");
        win.focus();
    };

    return (
        <>
            <TabWrap>
                <PublicationsTabViewIndex
                    customIcon="icon_external_link"
                    onClickCustom={onClickShow}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                />
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes;
TabView.defaultProps = defaultProps;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
