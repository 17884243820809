import React from "react";
import Img from "Templates/Img/Img";
import {ButtonClose} from "Templates/Button";
import TabBlockBtn from "Templates/Content/TabBlockBtn";
import PropTypes from "prop-types";

function HelpPage({ onClose }) {
    return (
        <div className="help-page-modal">
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <div className="help-page main-modal__form">
            <h1>Technologies Data</h1>

            <p>
                The Technologies Data module is used to input and manage Technical Data Sheet (Technologies) Data.
                The data input into this module is used in the Technologies Hierarchy module to build material property hierarchies.
                Technologies data are listed in tables and are accessed by clicking on each Data Tab shown below:
            </p>

            <Img img="tds_help_data" alt="tdsData" />

            <h2>Add New Technologies Data</h2>
            <div className="help-step">
                <span>Step 1</span>
                <p>
                    Click on the Properties tab
                    <br />
                    Next, click
                    <TabBlockBtn onClick={() => {}}>Add New Property</TabBlockBtn>
                </p>
            </div>


            <Img img="tds_help_step1" alt="step1" />
            <div className="help-step">
                <span>Step 2</span>
                <p>
                    A pop-up window will appear. Enter the Property Name in the input field.
                    <br />
                    Next, click
                    <TabBlockBtn onClick={() => {}}>Save Property</TabBlockBtn>
                </p>
            </div>

            <Img img="tds_help_step2" alt="step2" />
            <p>
                Repeat the process (Step 1 and 2) for the remaining Data Tabs (i.e., Test Methods, Specimens, Test Conditions, SI Units, English Units).
                NOTE:Input all required data into the Technologies Data module prior to building the heirarchies in the Technologies Hierarchy module.
            </p>
        </div>
        </div>
    );
}

HelpPage.defaultProps = {
    onClose: () => {},
};
HelpPage.propTypes = {
    onClose: PropTypes.func
};

export default HelpPage;