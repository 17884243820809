import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Modal} from "Templates/Modals";
import {Title} from "Templates/Modals/ModalDefault";
import {ButtonClose} from "Templates/Button";
import {MainButtonLink} from "Templates/Content";
import PropTypes from "prop-types";

const downloadReportModalPropTypes = {
    normalUploadUrl: PropTypes.string.isRequired,
    extendedUploadUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const DownloadReportModal = ({onClose, normalUploadUrl, extendedUploadUrl, t, label}) => {
    const [sharedListId, setSharedListId] = useState('')

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const search = new URLSearchParams(window.location.search)
            const listId = search.get('listId')
            if (!listId) {
                window.location = '/product/product-finder/shared?activeTab=1'
                setSharedListId('')
                return
            }

            setSharedListId(listId)
        }
    }, [normalUploadUrl, extendedUploadUrl])

    return (
        <>
            <div className="main-content__form download-report">
                <ButtonClose className="main-modal__form-close" onClick={onClose}/>
                <Title>{t("Download PDF")}</Title>
                <div>
                    {normalUploadUrl ? (
                        <MainButtonLink className="main-btn_white" href={sharedListId ? `${normalUploadUrl}?listId=${sharedListId}` : normalUploadUrl} target="_blank"
                                        onClick={onClose}>
                            {t(`Download ${label} only`)}
                        </MainButtonLink>
                    ) : null}

                    {/*{extendedUploadUrl ? (*/}
                    {/*    <MainButtonLink className="main-btn_white" href={sharedListId ? `${extendedUploadUrl}?listId=${sharedListId}` : extendedUploadUrl} target="_blank"*/}
                    {/*                    onClick={onClose}>*/}
                    {/*        {t(`Download ${label} and Search Criteria`)}*/}
                    {/*    </MainButtonLink>*/}
                    {/*) : null}*/}
                </div>
            </div>
        </>
    )
};

DownloadReportModal.propTypes = downloadReportModalPropTypes;

const downloadReportPropTypes = {
    normalUploadUrl: PropTypes.string,
    extendedUploadUrl: PropTypes.string,
};

const DownloadReport = ({t, normalUploadUrl, extendedUploadUrl, label='Data Sheet'}) => {

    const [showModal, setShowModal] = useState(false);

    const onOpenModal = () => {
        setShowModal(true);
    };

    const onCloseModal = () => {
        setShowModal(false);
    };
    return (normalUploadUrl || extendedUploadUrl) ? (
        <>
            <button type="button" className="btn__download" onClick={onOpenModal}/>
            {showModal ? (
                <Modal onRequestClose={onCloseModal}>
                    <DownloadReportModal
                        t={t}
                        label={label}
                        onClose={onCloseModal}
                        normalUploadUrl={normalUploadUrl}
                        extendedUploadUrl={extendedUploadUrl}
                    />
                </Modal>
            ) : null}
        </>
    ) : null;
};

DownloadReport.propTypes = downloadReportPropTypes;

const mapStateToProps = (state, {service: {getUploadUrl}}) => {
    return {
        normalUploadUrl: getUploadUrl(),
        extendedUploadUrl: getUploadUrl('extended'),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DownloadReport);
