import React, {useEffect} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "./Tabs";
import {url} from "Services";
import Tab from "Templates/Tabs/Tab";
import InfoWrap from "./Views/InfoWrap";

import _technicalGradesService from "./Services/Tabs/Technical";
import _designGradesService from "./Services/Tabs/Design";
import _processingGradesService from "./Services/Tabs/Processing";
import _safetyService from "./Services/Tabs/Safety";
import _brochureService from "./Services/Tabs/Brochure";

import Technical from "./Views/Tabs/Technical";
import Processing from "./Views/Tabs/Processing";
import Design from "./Views/Tabs/Design";
import Safety from "./Views/Tabs/Safety";
import Brochure from "./Views/Tabs/Brochure";
import AddToPublicationListModal from "./Views/Modals/AddToPublicationList";
import {Modal} from "Templates/Modals";
import {closeAddPublicationListModal} from "./Actions/TabActions";
import {Button} from "Templates/Default";
import Img from "Templates/Img";

const Publication = ({
                         id,
                         activeTab,
                         t,
                         onChangeTab,
                         activeSubTab,
                         permissions,
                         showAddToPublicationsListModal,
                         openAddPublicationListModal
                     }) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const urlObject = new URL(window.location.href);
            const search = new URLSearchParams(window.location.search)
            const listId = search.get('listId')
            const activeTabQuery = search.get('activeTab')
             if (!listId) {
                window.location = '/product/product-finder/shared?activeTab=1'

                return
            }

            if (!activeTabQuery) {
                const newUrl = `${urlObject.origin}${urlObject.pathname}?listId=${listId}&activeTab=1`
                history.pushState({}, '', newUrl)
                onChangeTab(1)
            }
        }
    }, [id, activeTab])

    const onClickEdit = () => {
        url.redirect(`/product/publications/${id}/edit`);
    };

    const technicalService = _technicalGradesService(id, activeSubTab.id);
    const processingService = _processingGradesService(id, activeSubTab.id);
    const designService = _designGradesService(id, activeSubTab.id);
    const safetyService = _safetyService(id, activeSubTab.id);
    const brochureService = _brochureService(id, activeSubTab.id);

    return <>
        <ContentWrap>
            <InfoWrap activeTab={activeTab} onClickEdit={onClickEdit}/>
            <ContentWrapCol>
                <CheckPermission permission="product_publication_groups_edit">
                    <Button onClick={openAddPublicationListModal}
                             className="size_normal main-btn add-to-list-btn main-btn_white">
                        <span>{t('Add to List')}</span>
                        <Img img={'icon_add_to_list'} width="24" height="24"/>
                    </Button>
                </CheckPermission>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index) => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Technical")}>
                        <ServiceProvider value={technicalService}>
                            <Technical permissions={permissions}/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Processing")}>
                        <ServiceProvider value={processingService}>
                            <Processing permissions={permissions}/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Guides")}>
                        <ServiceProvider value={designService}>
                            <Design permissions={permissions}/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Safety")}>
                        <ServiceProvider value={safetyService}>
                            <Safety/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Brochure")}>
                        <ServiceProvider value={brochureService}>
                            <Brochure/>
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
        {showAddToPublicationsListModal ? <Modal onRequestClose={closeAddPublicationListModal}>
            <AddToPublicationListModal/>
        </Modal> : null}
    </>;
};

Publication.defaultProps = {
    activeSubTab: {id: null},
    showAddToPublicationsListModal: false,
}
Publication.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    activeSubTab: PropTypes.shape({id: null}),
    showAddToPublicationsListModal: PropTypes.bool.isRequired,
    openAddPublicationListModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
        activeSubTab: getStoreItem(state, "activeSubTab") || {id: null},
        showAddToPublicationsListModal: getStoreItem(state, "showAddToPublicationsListModal"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        openAddPublicationListModal: getActionStore("openAddPublicationListModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Publication);
