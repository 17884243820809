import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import { Title, WrapButtons, Total } from "Templates/Titles";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import { MainButtonLink } from "Templates/Content";
import selfService from "./Services/Tabs/Self";
import allService from "./Services/Tabs/All";
import TabView from "./Views/TabView";
import ShowPermission from "Hoc/Template/ShowPermission";

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeTab: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const Task = ({ t, title, permissions, activeTab, onChangeTab }) => {
    const tab = () => {
        const myTasks = (
            <ServiceProvider value={selfService}>
                <TabView />
            </ServiceProvider>
        );
        if (!permissions.includes("project_companies_team_view")) {
            return myTasks;
        }
        return (
            <Tabs
                activeTab={activeTab}
                onClickTabItem={index => {
                    if (activeTab !== index) {
                        onChangeTab(index);
                    }
                }}
            >
                <Tab label={t("My Tasks")}>{myTasks}</Tab>
                <Tab label={t("All Tasks")}>
                    <ServiceProvider value={allService}>
                        <TabView />
                    </ServiceProvider>
                </Tab>
            </Tabs>
        );
    };
    return (
        <ShowPermission>
            <Title title={t("Tasks")} total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}>
                <WrapButtons>
                    <MainButtonLink href="/tasks/create" className="main-btn_primary">
                        {t("Add New Task")}
                    </MainButtonLink>
                </WrapButtons>
            </Title>
            {tab()}
        </ShowPermission>
    );
};

Task.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        title: getStoreItem(state, "pagination.total"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Task);
