import {debounce} from "Services/Debounce";
import Constants from "../Constants";
import {requestExportConnection} from "../Services";
import {requestSandBoxTable} from "../Services/SandBoxRequestService";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const fetchFiltersItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { filter, sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, filter: { ...(filter || {}) }, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const fetchConnectionsItems = (dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    requestSandBoxTable('/connection/connections')(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (key === 'all') {
        fetchConnectionsItems(dispatch)(request);
        dispatch({ type: Constants.RESET_FILTER, data: {} });
        return;
    }

    if (
        [
            "slug",
            "company_short_name",
            "company",
            "customer_code",
            "customer_duns_number",
            "sap_customer",
            "sap_grade",
            "customer_owner",
            "grade",
            "grade_sub_status",
            "material_type",
            "application_code",
            "sap_application_code",
            "material_number",
            "product_code",
            "project_slug",
            "project_name",
            "sales_manager",
            "note",
            "note_finance",
            "project_manager",
            "project_internal_team",
            "project_sub_status",
            "los_date",
            "move_to_current",
            "end_date",
            "sop",
            "eop",
            "plant",
            "budget",
            "stretch",
            "variance",
            "actual",
            "current",
            "price",
            "rebate",
            "final_price",
            "part_weight",
            "budget_price",
            "budget_volume",
            "budget_revenue",
            "stretch_volume",
            "stretch_revenue",
            "variance_volume",
            "variance_revenue",
            "actual_calculated_price",
            "actual_normalized_weight",
            "actual_net_amount",
            "annualized_volume",
            "annualized_revenue",
            "current_average_sale_price",
            "current_volume",
            "current_variance_percent",
            "current_variance",
            "current_revenue",
            "current_total_rebate",
            "current_total_revenue",
            "next_average_sale_price",
            "next_volume",
            "next_revenue",
            "next_total_rebate",
            "next_total_revenue"
        ].includes(key)) {
        fetchItems(service, dispatch)(request);
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const getUrlExport = (service, dispatch) => (data) => {
    dispatch({type: Constants.EXPORT_CONNECTION, data});

    return requestExportConnection(data).then(
        res => {
            alert('Export Connections started. Check notifications to download.');
            dispatch({ type: Constants.EXPORT_CONNECTION_SUCCESS, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.EXPORT_CONNECTION_FAILED, data: res });
        },
    );
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};
