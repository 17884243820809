import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";

import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import ContainerTable from "./Table/ContainerTable";
import TableRowColumn from "./Table/TableRowColumn";

const ModalTable = ContainerTable(TableHeadColumnWrap, TableRowColumn);
const VehicleTable = customTable(ModalTable);

const { Title, Button } = ModalDefault;

const propTypes = {
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    onClose: PropTypes.func.isRequired,
    selected: PropTypes.arrayOf(PropTypes.object),
    fetchItems: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

const defaultProps = {
    selected: [],
};

class VehicleModal extends Component {
    componentDidMount() {
        const { sort, fetchItems } = this.props;
        fetchItems({ sort });
    }

    submitForm = () => {
        const { selected, onClose, closeModal } = this.props;
        closeModal(selected).then(onClose);
    };

    render() {
        const { selected, onClose, t, onResetSelected } = this.props;
        return (
            <div className="main-modal__form project-forecasts-vehicle-modal main-modal__form--table">
                <ButtonClose className="main-modal__form-close" onClick={() => {
                    onResetSelected()
                    onClose()
                }}/>
                <Title>{t("Select Vehicle(s)")}</Title>
                <div className="main-wrap-table">
                    <VehicleTable/>
                </div>
                <div className="main-modal__buttons with-deselect">
                    <Button disabled={selected.length === 0} onClick={this.submitForm}>
                        {t("Save")}
                    </Button>
                    <Button disabled={selected.length === 0} onClick={onResetSelected}>
                        {t("Deselect All")}
                    </Button>
                </div>
            </div>
        );
    }
}

VehicleModal.propTypes = propTypes;
VehicleModal.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
    const { getStoreItem } = props.service;

    return {
        sort: getStoreItem(state, "sort"),
        selected: getStoreItem(state, "selected"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        closeModal: getActionStore("closeModal", service, dispatch),
        onResetSelected: getActionStore("onResetSelected", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(VehicleModal);
