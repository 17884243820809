import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "performance-edit" }),
        editState: { ...editState, form_validate: true, ...props, old: _.cloneDeep(props) },
    };

    return _configureStore(initialState, reducers);
}
