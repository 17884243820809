import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { getNumber, numberWithCommas, getNumberRoundToUpper } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row, Column } from "Templates/Table/NewVersion";
import {FormBlockLink, Input, Label, Tooltip} from "Templates/Form";
import { Span } from "Templates/Default";
import Div from "Templates/Default/Div";
import UnitWeightWrapper from "App/View/UnitWeightWrapper";

const propTypes = {
    salePrice: PropTypes.string.isRequired,
    ifThen: PropTypes.shape({
        grossMargin: PropTypes.number.isRequired,
        grossMarginInPercent: PropTypes.number.isRequired,
        operatingMargin: PropTypes.number.isRequired,
        operatingMarginInPercent: PropTypes.number.isRequired,
    }).isRequired,
    actual: PropTypes.shape({
        sale_price: PropTypes.string,
        goods_sold: PropTypes.string,
        gross_margin_ye: PropTypes.string,
        gross_margin: PropTypes.string,
        sga: PropTypes.string,
        margin: PropTypes.string,
        margin_ye: PropTypes.string,
    }).isRequired,
    line: PropTypes.shape({
        id: PropTypes.string,
        plant_name: PropTypes.string,
        line_name: PropTypes.string,
        cost_per_lbs: PropTypes.string,
    }).isRequired,
    changeCost: PropTypes.func.isRequired,
    service: PropTypes.shape({
        colorClass: PropTypes.func.isRequired,
        getTableView: PropTypes.func.isRequired,
    }).isRequired,
};

class TableBodyColumn extends PureComponent {
    state = {
        isOpen: false,
    }

    handleShowContent = () => {
        this.setState(({ isOpen }) => ({ isOpen:!isOpen}));
    }

    handleChangeCost = value => {
        const maxLength = 11;
        if (value.length <= maxLength) {
            const { changeCost } = this.props;
            changeCost(value);
        }
    };

    render() {
        const {salePrice, isManualCost, rawsTotalCost, actual, line, ifThen, raws, service: {getTableView}, t} = this.props;
        const {isOpen} = this.state;

        return (
            <div className="cost-modal__content_box">
                <h2 className="cost-modal__calculator__title">Margin Calculator</h2>
                <div className="cost-modal__content">
                    <div className="cost-modal__content_row cost-modal__content_row-total">
                        <h3 className="cost-modal__content_title">
                            Price
                            <span className="label_param">
                              ($/
                              <UnitWeightWrapper />
                              )
                            </span>
                        </h3>
                        <div className="cost-modal__head_row-right">
                            <Input
                              className="text-right bold-texts cost-modal__content_price cost-modal_outlined"
                              name="sale"
                              placeholder="0"
                              value={salePrice}
                              onChange={this.handleChangeCost}
                            />
                            <Tooltip>{t(`Enter price to calculate`)}</Tooltip>
                        </div>
                    </div>
                    <div className="cost-modal__content_row">
                        <h3 className="cost-modal__content_title">
                            Cost of Goods Sold
                            <span className="label_param">
                              ($/
                              <UnitWeightWrapper />
                              )
                            </span>
                        </h3>
                        <div className="cost-modal__head_row-right">
                            <span className="text-right bold-texts cost-modal__content_price">{`$${numberWithCommas(getNumber(actual.goods_sold))}`}</span>
                        </div>
                    </div>
                    {!isManualCost ? (
                        <div className="cost-modal__calculator_box">
                            <button
                                className={["cost-modal__calculator_btn", isOpen ? 'open' : ''].join(' ')}
                                onClick={this.handleShowContent}
                            >
                                Show {isOpen ? 'Less' : 'More'}
                            </button>
                            <div className="cost-modal__calculator_content">
                                <div>
                                    <div className="cost-modal__content_row">
                                        <h3 className="cost-modal__content_title">
                                            Line Cost
                                            <span className="label_param">
                                              ($/
                                                <UnitWeightWrapper />
                                              )
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="cost-modal__table">
                                        <div className="cost-modal__table_row">
                                            <FormBlockLink
                                                href={`/product/plants/${line.id}`}
                                                className="cost-modal__table_name"
                                                blank
                                            >
                                                {line.plant_name ? `${line.plant_name}, ${line.line_name}` : '-'}
                                            </FormBlockLink>
                                            <span>{`$${numberWithCommas(getNumber(line.cost_per_lbs))}`}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="cost-modal__content_row cost-modal__content_row-small">
                                        <h3 className="cost-modal__content_title">
                                            Raw materials
                                            <span className="label_param">
                                              ($/
                                                <UnitWeightWrapper />
                                              )
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="cost-modal__table">
                                        <div className="cost-modal__table_row cost-modal__table_row-head">
                                            <div className="cost-modal__table_name">Name</div>
                                            <div>Description</div>
                                            <div className="cost-modal__table_quantity">Quantity</div>
                                            <div>
                                                Extended Cost
                                                <Tooltip>{t(`Includes scrap`)}</Tooltip>
                                            </div>
                                        </div>
                                        {raws.map(elem => (
                                            <div className="cost-modal__table_row">
                                                <div>
                                                    <a
                                                        href={`/product/raw/${elem.id}`}
                                                        className="cost-modal__table_name"
                                                        target="_blank"
                                                    >
                                                        {elem.name}
                                                    </a>
                                                </div>
                                                <div
                                                    className="cost-modal__table_text tooltip"
                                                    title={elem.description}
                                                >
                                                    {elem.description}
                                                </div>
                                                <div className="cost-modal__table_percent">
                                                    {`${getNumber(elem.qty_thousand / 10, 1)  }%`}
                                                </div>
                                                <div>{`$${numberWithCommas(getNumber(elem.cost))}`}</div>
                                            </div>
                                        ))}
                                        <div className="cost-modal__table_row cost-modal__table_row-total">
                                            <div className="cost-modal__table_total">
                                              Total Raw Material Costs
                                              ($/
                                                <UnitWeightWrapper />
                                              )
                                            </div>
                                            <div>{`$${numberWithCommas(getNumber(rawsTotalCost))}`}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''}
                    <table className="cost-modal__table_box">
                        <tr>
                            <td colSpan="8">
                                <div>
                                    <span className="cost-modal__content_title">Gross Margin</span>
                                    <span className="label_param">
                                      ($/
                                        <UnitWeightWrapper />
                                      )
                                    </span>
                                </div>
                            </td>
                            <td>
                                <span className="cost-modal__table_box-price">
                                    {`$${numberWithCommas(ifThen.grossMargin)}`}
                                </span>
                            </td>
                            <td>
                                <span className="green cost-modal__table_box-price">
                                    {`${ifThen.grossMarginInPercent}%`}
                                </span>
                            </td>
                        </tr>
                        {!isManualCost ? (
                            <tr>
                                <td colSpan="8">
                                    <div>
                                        <span className="cost-modal__content_title">Selling, General & Admin</span>
                                        <span className="label_param">
                                          ($/
                                            <UnitWeightWrapper />
                                          )
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <span className="cost-modal__table_box-price">
                                        {`$${numberWithCommas(getNumber(actual.sga))}`}
                                    </span>
                                </td>
                                <td>
                                    <span className="green cost-modal__table_box-price" />
                                </td>
                            </tr>
                        ) : ''}
                        {!isManualCost ? (
                            <tr>
                                <td colSpan="8">
                                    <div>
                                        <span className="cost-modal__content_title">Operating Margin</span>
                                        <span className="label_param">
                                          ($/
                                            <UnitWeightWrapper />
                                          )
                                        </span>
                                    </div>
                                </td>
                                <td>
                                <span className="cost-modal__table_box-price">
                                    {`$${numberWithCommas(ifThen.operatingMargin)}`}
                                </span>
                                </td>
                                <td className="cost-modal__head_row-right">
                                <span className="green cost-modal__table_box-price">
                                    {`${ifThen.operatingMarginInPercent}%`}
                                </span>
                                </td>
                            </tr>
                        ) : ''}
                    </table>
                </div>
            </div>
        );
    }
}

TableBodyColumn.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        salePrice: getStoreItem(state, "cost.salePrice"),
        rawsTotalCost: getStoreItem(state, "cost.rawsTotalCost"),
        actual: getStoreItem(state, "cost.salesPrice.actual"),
        ifThen: getStoreItem(state, "cost.ifThen"),
        line: getStoreItem(state, "cost.line"),
        isManualCost: getStoreItem(state, "cost.isManualCostModel"),
        raws: getStoreItem(state, "cost.raws"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        changeCost: getActionStore("changeCostAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TableBodyColumn);
