import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        company: "Company Name",
        team: "Team Name",
        admin: "Admin",
        stuff: "Stuff",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback) => {
    const placeholders = {
        company: "Search",
        team: "All",
        admin: "Search",
        stuff: "Search",
    };
    switch (key) {
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value));
                    }}
                />
            );
    }
};
