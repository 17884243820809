import _ from "lodash";

export const formData = data => {
    const dataReq = {
        sort: {
            column: _.get(data, "sortColumn"),
            direction: _.get(data, "sortDirection"),
        },
        filter: {
            type: _.get(data, "type"),
            value: _.get(data, "marginListSelect"),
            time_scale: _.get(data, "timeScale"),
            period_from: _.get(data, "periodFrom"),
            period_to: _.get(data, "periodTo"),
            items: _.get(data, "items").map(elem => elem),
        },
    };

    if (_.get(data, "statusProject", "")) {
        const statuses = _.get(data, "statusProject")
        if (!statuses || statuses === "0") {
            return dataReq;
        }

        if (!Array.isArray(statuses)) {
            dataReq.filter.status = statuses.split(',').filter(item => item).map(elem => elem)
        } else {
            dataReq.filter.status = statuses.map(elem => elem);
        }
    }

    return dataReq;
};
