import {formDataNormalizer, request, url} from "Services";

export const getItem = async (listUrl, data) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        type: "GET",
        data,
    };

    return await request.sendRequest(sendObj)
};

export const requestAssignToProduct = link => data => {
    const payload = {
        products: data.product,
        is_submitted: data.isSubbmited,
        colors: data.colors.map((color) => {
            return {
                color: color.id,
                items: color.items.map((item) => {
                    return {
                        approval: item.approval,
                        code: item.code
                    }
                })
            }
        })
    }

    const sendObj = {
        url: url.getUrl(link),
        data: formDataNormalizer(payload),
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestEditProduct = link => data => {
    const payload = {
        product: data.product,
        colors: data.colors.map((color) => {
            return {
                color: color.id,
                items: color.items.map((item) => {
                    return {
                        approval: item.approval,
                        code: item.code
                    }
                })
            }
        })
    }

    const sendObj = {
        url: url.getUrl(link),
        data: formDataNormalizer(payload),
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestOnSaveCopySolution = link => data => {
    const formData = new FormData();

    for (let i = 0; i < data.solution.length; i += 1) {
        formData.append("solutions[]", data.solution[i]);
    }

    const sendObj = {
        url: url.getUrl(link),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestOnSaveAgreements = link => data => {
    const sendObj = {
        url: url.getUrl(link),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};