import React, {useEffect, useState} from 'react';
import {TabWrap} from 'Templates/Content';
import {ReferralsTableColumn} from './constants';
import {compose} from 'redux';
import {withServiceConsumer} from 'Services/Context';
import {withTagDefaultProps} from 'Hoc/Template';
import {formatDate, url} from 'Services';
import {InputFilter} from "Templates/Table/Filters";
import {debounce} from "Services/Debounce";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {isYesNo} from "Services/enum";
import Img from "Templates/Img/Img";
import {resendProfileInvitation} from "../../../Services/RequestService";

const Referrals = ({items, fetchItems, request, t}) => {
    const [filter, setFilter] = useState({});

    const onSetFilter = (value, field) => {
        setFilter({...filter, [field]: value});
    };

    useEffect(() => {
        debounce(() => fetchItems({...request, filter}));
    }, [filter]);

    const onClickShow = item => {
        if (item && item.invite_status && item.invite_status === 'Expired') {
            if (window.confirm(t("Do you want to resend the invitation to this user?"))) {
                resendProfileInvitation(`/admin/users/${item.invited_user_id}/resend-invite`).then(
                    () => {
                        alert('Congratulations! Your Guest User invitation was sent successfully.');
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        } else {
            alert('Unable to send message.')
        }
    };

    const getHistoryLink = (referralId, referralHasAction) => (referralId && referralHasAction) ?
        <a href={`${url.origin()}/admin/users/${referralId}/history/product`}
           className="projects-view__overview-user-link main-content__note-user-link">
            {'View'}
        </a> : '-';

    return (
        <TabWrap>
            <div className="main-wrap-block__body main-wrap-block__body_height">
                <table className="main-table">
                    <tbody>
                    <tr>
                        {
                            ReferralsTableColumn.map((column, index) => (
                                <th key={`Referrals-${index}`}
                                    className="main-table__head">
                                        <span className="main-table__head-link">
                                            {t(column.name)}
                                        </span>
                                    {column.filter ? <InputFilter
                                        type="text"
                                        placeholder={t(column.placeholder)}
                                        value={filter.full_name}
                                        name={column.filter}
                                        onChange={e => onSetFilter(e, column.filter)}
                                    /> : null}
                                </th>
                            ))
                        }
                    </tr>
                    {
                    items && items.map((referral, index) => (
                            <tr key={`ReferralsTableRow-${index}`}>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">
                                        {referral.full_name}
                                    </div>
                                </td>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">
                                        {formatDate(referral.created_at)}
                                    </div>
                                </td>
                                <td className="main-table__body">
                                    <TableRowItemStatus status={referral.paid_value ? 'Yes' : 'No'} list={isYesNo}/>
                                </td>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">
                                        <TableRowItemStatus status={referral.invited_to_shared_products ? 'Yes' : 'No'} list={isYesNo}/>
                                    </div>
                                </td>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">
                                        <TableRowItemStatus status={referral.invited_to_shared_lists ? 'Yes' : 'No'} list={isYesNo}/>
                                    </div>
                                </td>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">
                                        {referral.login_last ? formatDate(referral.login_last) : referral.invite_status}
                                    </div>
                                </td>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">
                                        {
                                            referral.login_current_mpnth || '-'
                                        }
                                    </div>
                                </td>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">
                                        {
                                            referral.login_total || '-'
                                        }
                                    </div>
                                </td>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">
                                        {
                                            getHistoryLink(referral.invited_user_id, referral.has_actions)
                                        }
                                    </div>
                                </td>
                                {referral.invite_status && referral.invite_status === 'Expired' ? <td className="main-table__body main-table__body--sticky">
                                    <div className="users__table-body-row main-table__body-row">
                                        <div className="users-table__body-btns main-table__body-btns">
                                            <button type="button" className="users__table-body-circle main-circle" onClick={() => onClickShow(referral)}>
                                                <Img img="icon_email"/>
                                            </button>
                                        </div>
                                    </div>
                                </td> : null}
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </TabWrap>
    );
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        items: getStoreItem(state, 'items'),
        request: {
            filter: {
                ...getStoreItem(state, 'filter'),
            },
            sort: {
                ...getStoreItem(state, 'sort'),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchPage: getActionStore('fetchPage', service, dispatch),
        fetchItems: getActionStore('fetchItems', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Referrals);

