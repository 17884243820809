import React from "react";
import PropTypes from "prop-types";
import { ServiceProvider } from "Services/Context";
import { sidebarItems } from "./Config";
import SidebarContent from "./View/SidebarContent";
import sidebarService from "./Services";

const propTypes = {
    onClickOnSome: PropTypes.func.isRequired,
};

const Sidebar = ({ onClickOnSome }) => {
    return (
        <aside className="main-sidebar">
            <ul className="main-sidebar__list">
                <ServiceProvider value={sidebarService}>
                    <SidebarContent map={sidebarItems} />
                </ServiceProvider>
            </ul>
            <button type="button" onClick={onClickOnSome} className="main-sidebar__btn" />
        </aside>
    );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
