import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const ProductsFinder = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };
    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_grade_product_crud-${index}`}
                    value={getPermission("grade_product_crud", index)}
                    onChange={value => onChangePermission("grade_product_crud", value, index)}
                >
                    <CheckboxTitle title="View - Grades"/>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxHierarchy activeHierarchy={getPermission("grade_product_finder_view", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_grade_product_finder_view-${index}`}
                        value={getPermission("grade_product_finder_view", index)}
                        onChange={value => onChangePermission("grade_product_finder_view", value, index)}
                    >
                        <CheckboxTitle title="Access - SAP Products & My Products">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the SAP Products and My Products tabs.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_grade_product_finder_edit-${index}`}
                        value={getPermission("grade_product_finder_edit", index)}
                        onChange={value => onChangePermission("grade_product_finder_edit", value, index)}
                    >
                        <CheckboxTitle title="Edit - SAP Products" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to edit SAP Products.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_grade_product_finder_export-${index}`}
                        value={getPermission("grade_product_finder_export", index)}
                        onChange={value => onChangePermission("grade_product_finder_export", value, index)}
                    >
                        <CheckboxTitle title="Export - Product Finder">
{/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_grade_product_sap_view-${index}`}
                        value={getPermission("grade_product_sap_view", index)}
                        onChange={value => onChangePermission("grade_product_sap_view", value, index)}
                    >
                        <CheckboxTitle title="Access - Grade SAP Data">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the information which contain SAP Products.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_grade_product_crud_cost-${index}`}
                        value={getPermission("grade_product_crud_cost", index)}
                        onChange={value => onChangePermission("grade_product_crud_cost", value, index)}
                    >
                        <CheckboxTitle title="Access - Grades Cost Model">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the information which contain Grades Cost Model.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_product_project_view-${index}`}
                        value={getPermission("product_project_view", index)}
                        onChange={
                            value => onChangePermission("product_project_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Access - Projects Tab">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Projects tab for the SAP Products.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxHierarchy activeHierarchy={getPermission("product_note_view", index)}>
                    <CheckboxBlock>
                        <CheckboxButton
                            isDisabled={
                                hasAccess()
                            }
                            htmlFor={`checkbox_product_note_view-${index}`}
                            value={getPermission("product_note_view", index)}
                            onChange={
                                value => onChangePermission("product_note_view", value, index)
                            }
                        >
                            <CheckboxTitle title="Access - Notes Tab">
                            </CheckboxTitle>
                        </CheckboxButton>
                    </CheckboxBlock>
                    <CheckboxBlock>
                        <CheckboxButton
                            isDisabled={
                                hasAccess()
                            }
                            htmlFor={`checkbox_product_admin_note_crud-${index}`}
                            value={getPermission("product_admin_note_crud", index)}
                            onChange={
                                value => onChangePermission("product_admin_note_crud", value, index)
                            }
                        >
                            <CheckboxTitle title="View/Add/Edit/Delete - Admin notes">
                            </CheckboxTitle>
                        </CheckboxButton>
                    </CheckboxBlock>
                    <CheckboxBlock>
                        <CheckboxButton
                            isDisabled={
                                hasAccess()
                            }
                            htmlFor={`checkbox_product_pinned_note_crud-${index}`}
                            value={getPermission("product_pinned_note_crud", index)}
                            onChange={
                                value => onChangePermission("product_pinned_note_crud", value, index)
                            }
                        >
                            <CheckboxTitle title="Add/Edit/Delete - Pinned notes">
                            </CheckboxTitle>
                        </CheckboxButton>
                    </CheckboxBlock>
                </CheckboxHierarchy>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={hasAccess()}
                        htmlFor={`checkbox_grade_product_analytics_view-${index}`}
                        value={getPermission("grade_product_analytics", index)}
                        onChange={value => onChangePermission("grade_product_analytics", value, index)}
                    >
                        <CheckboxTitle title="Access - Analytics Tab">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Analytics tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={hasAccess()}
                        htmlFor={`checkbox_grade_product_invoices_view-${index}`}
                        value={getPermission("grade_product_invoices", index)}
                        onChange={value => onChangePermission("grade_product_invoices", value, index)}
                    >
                        <CheckboxTitle title="Access - Invoices Tab">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Invoices tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxHierarchy inputClass="no-margin" activeHierarchy={getPermission("grade_product_files", index)}>
                    <CheckboxBlock>
                        <CheckboxButton
                            isDisabled={
                                hasAccess()
                            }
                            htmlFor={`checkbox_grade_product_files-${index}`}
                            value={getPermission("grade_product_files", index)}
                            onChange={value => onChangePermission("grade_product_files", value, index)}
                        >
                            <CheckboxTitle title="Add/Delete - Files">
                            </CheckboxTitle>
                        </CheckboxButton>
                    </CheckboxBlock>
                    <CheckboxBlock>
                        <CheckboxButton
                            isDisabled={
                                hasAccess()
                            }
                            htmlFor={`checkbox_grade_private_product_files-${index}`}
                            value={getPermission("grade_private_product_files", index)}
                            onChange={value => onChangePermission("grade_private_product_files", value, index)}
                        >
                            <CheckboxTitle title="Add/Delete - Private Files">
                            </CheckboxTitle>
                        </CheckboxButton>
                    </CheckboxBlock>
                </CheckboxHierarchy>
            </CheckboxHierarchy>
            <CheckboxHierarchy activeHierarchy={getPermission("product_publications_guest", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_product_publications_guest-${index}`}
                        value={getPermission("product_publications_guest", index)}
                        onChange={value => onChangePermission("product_publications_guest", value, index)}
                    >
                        <CheckboxTitle title="Access - Shared Products">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Shared Products.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={hasAccess()}
                        htmlFor={`product_share_button_view-${index}`}
                        value={getPermission("product_share_button", index)}
                        onChange={value => onChangePermission("product_share_button", value, index)}
                    >
                        <CheckboxTitle title="Share Products button">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Shared Products button.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_product_publication_groups_edit-${index}`}
                    value={getPermission("product_publication_groups_edit", index)}
                    onChange={
                        value => onChangePermission("product_publication_groups_edit", value, index)
                    }
                >
                    <CheckboxTitle title="My Lists Tab">
                        {/*                      <Tooltip>
                            {t(
                                "Access user to My Lists tab.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_product_publication_groups_view-${index}`}
                    value={getPermission("product_publication_groups_view", index)}
                    onChange={
                        value => onChangePermission("product_publication_groups_view", value, index)
                    }
                >
                    <CheckboxTitle title="Shared With Me">
{/*                        <Tooltip>
                            {t(
                                "Access user to the Shared Lists tab.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_product_grade_approval_view-${index}`}
                    value={getPermission("product_grade_approval_view", index)}
                    onChange={value => onChangePermission("product_grade_approval_view", value, index)}
                >
                    <CheckboxTitle title="Access - Approvals"/>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxHierarchy activeHierarchy={getPermission("product_shared_compare_access", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_product_shared_compare_access-${index}`}
                        value={getPermission("product_shared_compare_access", index)}
                        onChange={value => onChangePermission("product_shared_compare_access", value, index)}
                    >
                        <CheckboxTitle title="Access - Compare Shared Products"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_product_onboarding_compare_access-${index}`}
                        value={getPermission("product_onboarding_compare_access", index)}
                        onChange={value => onChangePermission("product_onboarding_compare_access", value, index)}
                    >
                        <CheckboxTitle title="Access - Compare Onboarding Products"/>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
        </>
    )
}

ProductsFinder.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(ProductsFinder);
