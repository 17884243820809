import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import CheckboxBlock from "Templates/Form/Checkbox/CheckboxBlock";
import CheckboxButton from "Templates/Form/Checkbox/CheckboxButton";
import CheckboxTitle from "Templates/Form/Checkbox/CheckboxTitle";

const { Button, Form, Title, Col, Row, ColText } = ModalDefault;

const propTypes = {
    onClose: PropTypes.func.isRequired,
    userFullName: PropTypes.string.isRequired,
};

const WelcomeGuestModal = (
    {
        onClose,
        userFullName,
        t,
        showSharedModalChecked,
        showSharedModalCheck
    }
) => {
    const arrName = userFullName.split(' ');
    const userName = arrName !== [] ? arrName[0] : '';

    return (
        <div className="welcome-modal">
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose} />
                <Title>{t(`Welcome to ComOpps Product Finder ${userName}!`)}</Title>
                <div className="top">
                    <Row>
                        <Col>
                            <ColText>
                                1. Find the perfect product for your application.
                            </ColText>
                            <ColText>
                                2. Use the search filters to the left to filter through the portfolio of products.
                            </ColText>
                            <ColText>
                                3. Products matching your search criteria will display in the Shared Products panel.
                            </ColText>
                            <ColText>
                                4. Click on the product name to access the corresponding:
                            </ColText>
                        </Col>
                    </Row>
                </div>
                <div className="middle">
                    <Row>
                        <Col>
                            <ul>
                                <li>Technical Data Sheets</li>
                                <li>Processing Data Sheets</li>
                                <li>Product Guides</li>
                                <li>Safety Data Sheets</li>
                                <li>Brochures</li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div className="bottom">
                    <Row>
                        <Col>
                            <ColText>
                                Have questions about a product? Contact your sales representative
                                or the contact name listed for each product.
                            </ColText>
                        </Col>
                    </Row>
                </div>
                <div className="storage-checkbox">
                    <CheckboxBlock>
                        <CheckboxButton
                            htmlFor={`checkbox_productsInvite`}
                            value={showSharedModalChecked}
                            onChange={value => {
                                showSharedModalCheck(value)
                            }}
                        >
                            <CheckboxTitle title="Do not show me this message again"/>
                        </CheckboxButton>
                    </CheckboxBlock>
                </div>
                <Button onClick={onClose}>
                    {t("Continue")}
                </Button>
            </Form>
        </div>
    );
}

WelcomeGuestModal.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {

    return {
        showSharedModalChecked: getStoreItem(state, "showSharedModalChecked"),
        userFullName: getStoreItem(state, "userFullName"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const { getActionStore } = service;

    return {
        showSharedModalCheck: getActionStore("showSharedModalCheck", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(WelcomeGuestModal);
