import _ from "lodash";
import Constants from "../Constants";

export const initialState = {
    hierarchyProperties: [],
    rootProperties: [],
    form_validate: false,
    form_loading: false,
    form_errors: {},
    showProperties: [],
    old: {},
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM:
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };

        case Constants.SHOW_BTN_SUCCEEDED:
            const currentState = { ...state };
            _.set(currentState, data.key, data.value);

            const old = {
                hierarchyProperties: currentState[data.key]
            };

            return { ...currentState, form_validate: validate(currentState), old: _.cloneDeep(old) };

        case Constants.SET_PROPERTY:
            return { ...state, ...data };

        case Constants.ADD_PROPERTY:
            const curState = { ...state };

            _.set(curState, 'hierarchyProperties', data.hierarchyProperties);
            _.set(curState, 'rootProperties', _.concat(curState.rootProperties, [{id: '', name: 'New Property'}]));

            const oldData = {
                hierarchyProperties: curState.hierarchyProperties
            };

            return { ...curState, form_validate: validate(curState), old: _.cloneDeep(oldData) };

        case Constants.FORM_REQUESTED:
        case Constants.SHOW_BTN_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
        case Constants.SHOW_BTN_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return {
                ...state,
                hierarchyProperties: [],
                old: {},
            };

        default:
            return state;
    }
};

const validate = state => {
    return !!state.hierarchyProperties
        .filter(property => _.trim(property.property) && property.methods.filter(method => _.trim(method.method)).length > 0).length > 0;
};
