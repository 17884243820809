import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {
    className: "",
    disabled: false
};

const propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

const TableBodyButton = ({ pref, onClick, children, className, disabled }) => {
    return (
        <button
            type="button"
            className={`${pref}__table-body-circle main-circle ${className} ${disabled ? 'disabled-quick-edit-save' : ''}`}
            onClick={e => {
                e.preventDefault();
                onClick();
            }}
        >
            {children}
        </button>
    );
};

TableBodyButton.defaultProps = defaultProps;
TableBodyButton.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableBodyButton);
