import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable } from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getTableLabel, getFilter } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";
import { restoreItem } from "./RequestService";

export default () => ({
    requestTable: requestTable(`admin/deleted/projects`),
    onClickDeleteItem: item => deleteItem(`admin/deleted/projects/${item.id}/force`),
    onClickRestoreItem: item => restoreItem(`admin/deleted/projects/${item.id}/restore`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
