import _ from "lodash";
import Constants from "../Constants";
import {url} from "Services";

export const initState = {
    view: {},
    activeTab: +url.get('activeTab'),
    errors: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data, filter: {}, sort: {} };

        default:
            return state;
    }
};
