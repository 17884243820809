import React from "react";
import {useSelector} from 'react-redux';
import _ from "lodash";
import {numberWithCommas} from "Services";
import {
    gradeStatus,
    industryOptions,
    isSapImported,
    projectBudget,
    projectConsistent,
    projectStatus,
    stageGates
} from "Services/enum";
import {InputFilter} from "Templates/Table/Filters";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {weightUnitConvertLbs, weightUnitName} from 'Services/weightUnitName';
import {onChange} from "../../EventsService";

export const getTableLabel = (key, { currentFY }) => {
    const unit = useSelector(state => state.weightUnitState);

    const tableLabel = {
        slug: "Project ID",
        name: "Project Name",
        owner: "Supplier",
        sub_status: "Status",
        industry: "Project Market Segment",
        application_code: "Project Application Code",
        sub_segment: "Project Segment",
        application: "Project Sub-Segment",
        product: "Product Source",
        grade: "Product Name",
        material_number: "Material Number",
        customer_duns_number: "D-U-N-S® Number",
        note: "Note",
        customer_owner: "Customer Owner",
        product_code: "Product Code",
        product_application_code: "SAP Application Code",
        status: "Added to ComOpps Forecast",
        budget: "Added to Budget & Sales Forecast",
        replacement_business_project_id: "Replacement Project ID",
        replacement_business_project_name: "Replacement Project Name",
        plant: "Selling Plant",
        line: "Production Line",
        customer: "Customer",
        customer_code: "Customer Code",
        manufacturer: "Manufacturer",
        tier1: "OEM Tier 1",
        tier2: "OEM Tier 2",
        molder: "OEM Tier 3",
        customer_nickname: "Customer Nickname",
        tier1_nickname: "OEM Tier 1 Nickname",
        tier2_nickname: "OEM Tier 2 Nickname",
        molder_nickname: "OEM Tier 3 Nickname",
        unit: "Unit Forecast Name",
        sop: "SOP",
        company_is_sap: "SAP Companies",
        grade_is_sap: "SAP Grades",
        los_date: "Loss of Sales Date",
        created_at: "Date Submitted",
        updated_at: "Date Last Changed",
        sub_status_changed_at: "Change Status Date",
        stage_gate: "Salesforce Stage Gates",
        description: "Project Description",
        move_to_current: "Start Date",
        end_date: "End Date",
        eop: "EOP",
        manager: "Project Manager",
        internal_team: "Internal Team",
        platform: "Platform",
        program: "Program",
        brand: "Production Brand",
        nameplate: "Production Nameplate",
        country: "Country",
        region: "Region",
        production_plant: "Production Plant",
        city: "City",
        volume: "Volume",
        revenue: "Revenue",
        gross_margin: "Gross Margin",
        grossMargin: "Gross Margin",
        actual_sale_price: `Price ($/${weightUnitName(unit.weightUnit)})`,
        weight_value: `Part Weight (${weightUnitConvertLbs(weightUnitName(unit.weightUnit))})`,
        unit_value: "No. of Parts/Unit",
        grade_sub_status: "Product Status",
    };

    switch (key) {
        case "volume":
        case "revenue":
        case "gross_margin":
            return `FY ${currentFY}  ${_.get(tableLabel, key, key)}`;

        case String(key.match(/^volume_.*/)):
        case String(key.match(/^revenue_.*/)):
        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.split("_");
            return `${keys[1]} ${keys[2]} <br/> Forecast ${_.get(tableLabel, keys[0], key)}`;
        }

        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = id => (key, value, callback, request, { totalAnalytics }) => {
    const unit = useSelector(state => state.weightUnitState);

    const placeholders = {
        slug: "Search",
        name: "Search",
        owner: "Search",
        sub_status: "Select",
        status: "Search",
        budget: "Search",
        application_code: "Search",
        sub_segment: "Search",
        industry: "Search",
        application: "Search",
        product: "Search",
        grade: "Search",
        material_number: "Search",
        product_code: "Search",
        product_application_code: "Search",
        plant: "Search",
        replacement_business_project_id: "Search",
        replacement_business_project_name: "Search",
        line: "Search",
        customer: "Search",
        customer_code: "Search",
        manufacturer: "Search",
        customer_nickname: "Search",
        tier1_nickname: "Search",
        tier2_nickname: "Search",
        customer_duns_number: "Search",
        note: "Search",
        customer_owner: "Search",
        molder_nickname: "Search",
        tier1: "Search",
        tier2: "Search",
        molder: "Search",
        unit: "Search",
        sop: "Search",
        company_is_sap: "Search",
        grade_is_sap: "Search",
        los_date: "Search",
        created_at: "Search",
        updated_at: "Search",
        sub_status_changed_at: "Search",
        stage_gate: "Select",
        move_to_current: "Search",
        end_date: "Search",
        eop: "Search",
        manager: "Search",
        internal_team: "Search",
        platform: "Search",
        program: "Search",
        brand: "Search",
        nameplate: "Search",
        country: "Search",
        region: "Search",
        production_plant: "Search",
        city: "Search",
        volume: "Search",
        revenue: "Search",
        gross_margin: "Search",
        weight_value: "Search",
        unit_value: "Search",
        actual_sale_price: "Search",
        grade_sub_status: "Select",
    };

    switch (key) {
        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/project-slug`}
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/project-list`}
                    name="name"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/owner-list`}
                    name="owner"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "product":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/product-list`}
                    name="product"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("products", _value, request));
                    }}
                />
            );

        case "grade":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/grade-list`}
                    name="grade"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("grades", _value, request));
                    }}
                />
            );


        case "customer_duns_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-duns-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "material_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="project/projects/material-number-list"
                    name="material_number"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("material_numbers", _value, request));
                    }}
                />
            );

        case "product_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="project/projects/product-code-list"
                    name="product_code"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("product_codes", _value, request));
                    }}
                />
            );

        case "product_application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/application-code-list`}
                    name="product_application_code"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("product_application_codes", _value, request));
                    }}
                />
            );

        case "plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/plant-list`}
                    name="plant"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("plants", _value, request));
                    }}
                />
            );

        case "line":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/line-list`}
                    name="line"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("lines", _value, request));
                    }}
                />
            );

        case "internal_team":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/user-list`}
                    name="internal_team"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key , _value, request));
                    }}
                />
            );

        case "brand":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/brand-list`}
                    name="brand"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("brands", _value, request));
                    }}
                />
            );

        case "nameplate":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/nameplate-list`}
                    name="nameplate"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("nameplates", _value, request));
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={[...projectStatus]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "status":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...projectConsistent]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "budget":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...projectBudget]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/application-code-list`}
                    name="application_code"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sub_segment":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/sub-segment-list`}
                    name="sub_segment"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/application-list`}
                    name="application"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "manufacturer":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/manufacturer-forecast-list"
                    name="manufacturer"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "platform":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/platform-list"
                    name="platform"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "program":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/program-list"
                    name="program"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "city":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/city-list"
                    name="city"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "country":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/country-list"
                    name="country"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "region":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/region-list"
                    name="region"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "production_plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/production-plant-list"
                    name="production_plant"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "unit":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/unit-list"
                    name="unit"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "industry":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={industryOptions}
                    placeholder={_.get(placeholders, key)}
                    name="industry"
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "stage_gate":
            return (
                <InputFilter
                    type="multiSelect"
                    name={key}
                    value={value}
                    options={stageGates}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "los_date":
        case "created_at":
        case "updated_at":
        case "sub_status_changed_at":
        case "move_to_current":
        case "end_date":
        case "sop":
        case "eop":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "company_is_sap":
        case "grade_is_sap":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer":
        case "tier1":
        case "tier2":
        case "molder":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/customer-list`}
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/customer-code-list`}
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "manager":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`companies/${id}/projects/user-list`}
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "grade_sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={gradeStatus}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_nickname":
        case "tier1_nickname":
        case "tier2_nickname":
        case "molder_nickname":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-nickname-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "volume":
        case "revenue":
        case "gross_margin": {
            return <InputFilter disabled type="text" placeholder="" value="" onChange={() => {}} />;
        }

        case String(key.match(/^volume_.*/)): {
            const keys = key.toLowerCase().split("_");
            const data = (totalAnalytics && totalAnalytics[keys[2]][keys[1]].volume) || "0"; // TODO: ramda на другой ветке, нежен set
            return (
                <TableRowItemSpan className="main-text-bold main-text-right" title={`${numberWithCommas(data, 0)} ${weightUnitName(unit.weightUnit)}`}>
                    {`${numberWithCommas(data, 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^revenue_.*/)): {
            const keys = key.toLowerCase().split("_");
            const data = (totalAnalytics && totalAnalytics[keys[2]][keys[1]].revenue) || "0"; // TODO: ramda на другой ветке, нежен set
            return (
                <TableRowItemSpan className="main-text-bold main-text-right" title={`$${numberWithCommas(data, 0)}`}>
                    {`$${numberWithCommas(data, 0)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.toLowerCase().split("_");
            const data = (totalAnalytics && totalAnalytics[keys[2]][keys[1]].gross_margin) || "0"; // TODO: ramda на другой ветке, нежен set
            return (
                <TableRowItemSpan className={`main-text-bold main-text-right ${data < 0 ? "clr-red" : ""}`} title={`${data}%`}>
                    {`${data}%`}
                </TableRowItemSpan>
            );
        }

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => !(/^volume_.*/.test(key) || /^revenue_.*/.test(key) || /^grossMargin_.*/.test(key));
