import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps, PermissionsProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import FileList from "Services/FileList/FileList";
import {CheckboxButton, CheckboxLabel} from "Templates/Form/Checkbox";

const {Button, Form, Title, Row, Col, ColInput, ColText} = ModalDefault;

const typeList = [
    {
        value: "4",
        label: "Moldflow",
    },
    {
        value: "3",
        label: "Moldex3D",
    },
    {
        value: "2",
        label: "FEA",
    },
    {
        value: "1",
        label: "Other",
    },
];

const propTypes = {
    onAppend: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const UploadFileForm = ({t, form, onAppend, onClose, onChange, onChangeType, onChangeNote, onChangeVisibility, permissions}) => {
    const disabled = form.files.length <= 0 || !form.type;
    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <Title>{t("Add File")}</Title>
            <FileList
                name="files"
                fileLength={1}
                files={form.files}
                dropFile={e => onChange(e)}
                handleDrop={e => onChange(e)}
            />
            <Row>
                <Col>
                    <ColText isRequired>{t("Type")}</ColText>
                    <ColInput
                        name="type"
                        value={form.type}
                        onChange={value => onChangeType(value, "type")}
                        options={typeList}
                        type="select"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ColText>{t("Note")}</ColText>
                    <ColInput
                        name="note"
                        value={form.note}
                        onChange={value => onChangeNote(value, "note")}
                        type="textarea"
                    />
                </Col>
            </Row>
            {
                permissions.includes('grade_private_product_files')
                ? <Row>
                        <Col>
                            <CheckboxButton
                                inputClass="grade-private-file-checkbox"
                                htmlFor="visibility"
                                value={form.visibility}
                                onChange={value => onChangeVisibility(value  === true ? 10 : 0, "visibility")}
                            >
                                <CheckboxLabel label="Private Files"/>
                            </CheckboxButton>
                        </Col>
                    </Row>
                : ''
            }
            <Button onClick={onAppend} disabled={disabled}>
                {t("Add File")}
            </Button>
        </Form>
    );
};

UploadFileForm.propTypes = propTypes;

export default compose(
    PermissionsProps,
    withServiceConsumer,
    withTagDefaultProps(),
)(UploadFileForm);
