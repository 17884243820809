import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockWrap, Input, WrapInput, BlockMain } from "Templates/Form";

const propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const FormBlock = ({ pref, name, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };
    return (
        <form action="/" className={`${pref}__form`}>
            <BlockMain title="General Info">
                <BlockWrap>
                    <WrapInput isRequired label={t("Performance Name")} name="name">
                        <Input placeholder={t("Name")} value={name} onChange={value => onChangeInput(value, `name`)} />
                    </WrapInput>
                </BlockWrap>
            </BlockMain>
        </form>
    );
};

FormBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
