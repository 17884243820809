import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {gradeFileTypes, gradeStatus} from "Services/enum";
import {countryList} from "Services";
import {Tooltip, TooltipHtml} from "Templates/Form";
import {onChange} from "../../Services/EventsService";
import InfoTooltip from "Templates/InfoTooltip/InfoTooltip";

function Product({callback, filter}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>Product</BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                   <>
                        <p className="main-table__head-link">Product Name - Specific</p>
                        <InputFilter
                            type="asyncMultiSelect"
                            placeholder="Select"
                            url={`/product/product-finder/grade-list/my`}
                            value={filter.grade}
                            onChange={e => {
                                onChange(e).then(_value => callback('grade', _value));
                            }}
                        />
                   </>
                </div>
            )}
        </>
    );
}

Product.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Product;
