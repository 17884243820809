import React, {Fragment, useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {countryList} from "Services";
import {datasheetLocation, datasheetPaintable, isMolded} from "Services/enum";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoContactWrapper,
    ContentInfoHeader,
    ContentInfoHeaderWrap,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap
} from "Templates/Content";
import {Tooltip} from "Templates/Form";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import Img, {ImgStorage} from "Templates/Img";
import InfoPopup from "Templates/InfoPopup/InfoPopup";
import {isBoundable} from "../../../../../Services/enum";
import _ from "lodash";

const defaultProps = {
    datasheet: {}
}

const propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    datasheet: PropTypes.objectOf(PropTypes.any),
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
    canEdit: PropTypes.number.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    activeTab: PropTypes.number.isRequired,
};

const InfoWrap = (
    {
        name,
        description,
        slug,
        company,
        items,
        datasheet,
        t,
        onClickEdit,
        activeTab,
        canEdit,
    }) => {

    const [isShownApplicationCodesContent, setIsShownApplicationCodesContent] = useState(true);
    const [isShownMarketSegmentsContent, setIsShownMarketSegmentsContent] = useState(true);
    const [isShownSegmentsContent, setIsShownSegmentsContent] = useState(true);
    const [isShownLocationsContent, setIsShownLocationsContent] = useState(true);
    const [isShownCountryContent, setIsShowCountryContent] = useState(true);
    const [subSegments, setSubSegment] = useState([]);



    useEffect(() => {
        if (!datasheet || !datasheet.sub_segments || !datasheet.sub_segments.length) {
                setSubSegment([]);
        } else {
            setSubSegment(datasheet.sub_segments);
        }
    }, [datasheet.sub_segments]);

    const paintableView = () => {
        const groupedPaintables = _.uniqBy(_.chain(items)
            .map((value) => typeof value.paintable==='number' ? _.get(datasheetPaintable.find(el => el.value === value.paintable.toString()), 'label', '') : '')
            .value());

        return _.filter(groupedPaintables);
    };

    const boundableView = () => {
        const groupedBoundable = _.uniqBy(_.chain(items)
            .map((value) => typeof value.boundable==='number' ? _.get(isBoundable.find(el => el.value === value.boundable.toString()), 'label', '') : '')
            .value());

        return _.filter(groupedBoundable);
    };

    const locationView = (elem) => {
        return elem ? datasheetLocation.find(el => el.value === elem).label : '-';
    };

    const countryView = (elem) => {
        return elem ? countryList.find(el => el.value === elem).label : '-';
    };

    const approvalView = (elem) => {
        let response = '-';

        if (elem) {
            if (elem.approval_name && elem.code_name) {
                response = `${elem.approval_name} - ${elem.code_name}`;
            } else if (elem.approval_name) {
                response = elem.approval_name;
            }
        }

        return response;
    };

    const showContent = (array, toggle, elemView) => {
        if (!toggle) {
            return array.slice(0, 3).map((elem, index) => (
                <ContentInfoBlockText key={index} value={elemView ? elemView(elem) : elem}/>
            ));
        }

        return array.map((elem, index) => (
            <ContentInfoBlockText key={index} value={elemView ? elemView(elem) : elem}/>
        ));
    };

    const showCountries = (array, toggle, elemView) => {
        const items = array.map((elem) => (
            elemView ? elemView(elem) : elem
        )).sort((a, b) => {
            // Global option should be the first in order
            if (a === 'Global') {
                return -1;
            }

            if (b === 'Global') {
                return 1;
            }

            return a > b ? 1 : -1;
        });

        return showContent(items, toggle)
    };

    const tabCountryLabel = ['Technical Data Sheet', 'Processing Data Sheet', 'Guide', 'Safety Data Sheet', 'Brochure'];

    const getSlugInfoBlock = () => {
        return (
            <ContentInfoBlock show={!!slug}>
                <ContentInfoBlockTitle>{t("Publication ID")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={slug}/>
            </ContentInfoBlock>
        )
    }

    const showData = () => {
        if (!datasheet) {
            return null;
        }

        if (datasheet.locations
            || datasheet.paintable
            || datasheet.molded
            || datasheet.is_boundable
            || datasheet.product_colors
            || datasheet.countries
            || datasheet.contacts
            || datasheet.application_codes
            || datasheet.market_segments
            || datasheet.segments
            || datasheet.sub_segments
            || datasheet.manufacturers
        ) {

            const activeValueMolds = _.uniqBy(_.chain(items)
                .map((value) => value.molded ? JSON.parse(value.molded) : [])
                .value());

            const activeLocations = _.uniqBy(_.chain(items)
                .map((value) => value.locations ? JSON.parse(value.locations) : [])
                .value().flat());

            const yesMoldsList =  _.orderBy(_.get(_.groupBy(activeValueMolds, ({yes}) => yes ? "yes" : ''), 'yes', []), 'yes_desc', 'desc');
            const noMoldsList =  _.orderBy(_.get(_.groupBy(activeValueMolds, ({no}) => no ? "no" : ''), 'no', []), 'no_desc', 'desc');
            const canBeMatchedMoldsList = _.orderBy(_.get(_.groupBy(activeValueMolds, ({can_be_matched}) =>  can_be_matched ? "can_be_matched" : ''), 'can_be_matched', []), 'can_be_matched_desc', 'desc');

            return (
                <>
                    <ContentInfoBlock show={!!datasheet.application_codes.length}>
                        <ContentInfoBlockTitle>{t("Application Codes")}</ContentInfoBlockTitle>
                        {showContent(datasheet.application_codes, isShownApplicationCodesContent)}
                        {datasheet.application_codes.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownApplicationCodesContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShownApplicationCodesContent(!isShownApplicationCodesContent)}
                            >
                                Show
                                {' '}
                                {isShownApplicationCodesContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!datasheet.market_segments.length}>
                        <ContentInfoBlockTitle>{t("Market Segments")}</ContentInfoBlockTitle>
                        {showContent(datasheet.market_segments, isShownMarketSegmentsContent)}
                        {datasheet.market_segments.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownMarketSegmentsContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShownMarketSegmentsContent(!isShownMarketSegmentsContent)}
                            >
                                Show
                                {' '}
                                {isShownMarketSegmentsContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!datasheet.segments.length}>
                        <ContentInfoBlockTitle>{t("Segments")}</ContentInfoBlockTitle>
                        {showContent(datasheet.segments, isShownSegmentsContent)}
                        {datasheet.segments.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownSegmentsContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShownSegmentsContent(!isShownSegmentsContent)}
                            >
                                Show
                                {' '}
                                {isShownSegmentsContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!subSegments.length}>
                        <ContentInfoBlockTitle>{t("Sub-Segments")}</ContentInfoBlockTitle>
                        {subSegments.length > 3 ? (
                            subSegments.slice(0, 3).map((elem, index) => (
                                <ContentInfoBlockText key={index} value={elem}/>
                            ))
                        ) : subSegments.map((elem, index) => (
                            <ContentInfoBlockText key={index} value={elem}/>
                        ))}
                        {subSegments.length > 3 && (
                            <InfoPopup type={'segments'} left={false} content={[...subSegments].map((elem, index) => (
                                <ContentInfoBlockText key={index} value={elem}/>))}
                            />
                        )}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!paintableView().length}>
                        <ContentInfoBlockTitle>{t("Paintable")}</ContentInfoBlockTitle>
                        {paintableView().map((val, key) => (<ContentInfoBlockText key={key} value={val}/>))}
                    </ContentInfoBlock>
                    <ContentInfoBlock show={!!yesMoldsList.length || !!noMoldsList.length || !!canBeMatchedMoldsList.length}>
                        <ContentInfoBlockTitle>
                            {t("Mold-in-Сolor")}
                            <Tooltip>
                                {`Mold-in-Color is a pre-colored plastic resin. You must have a color code for 80% or 100% color match. Please contact your MCGC representative for more information.`}
                            </Tooltip>
                        </ContentInfoBlockTitle>
                        {yesMoldsList.map((elem, key) => (
                            <div>
                                <ContentInfoBlockText key={key} value={(!elem['yes_desc'] && key > 0 ? '' : 'Yes ') + (elem['yes_desc'] ? ('('+elem['yes_desc']+')') : '')}/>
                            </div>
                        ))}
                        {noMoldsList.map((elem, key) => (
                            <div>
                                <ContentInfoBlockText key={key} value={(!elem['no_desc'] && key > 0 ? '' : 'No ') + (elem['no_desc'] ? ('('+elem['no_desc']+')') : '')}/>
                            </div>
                        ))}
                        {canBeMatchedMoldsList.map((elem, key) => (
                            <div>
                                <ContentInfoBlockText key={key} value={(!elem['can_be_matched_desc'] && key > 0 ? '' : 'No, can be color matched ') + (elem['can_be_matched_desc'] ? ('('+elem['can_be_matched_desc']+')') : '')}/>
                            </div>
                        ))}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!boundableView().length}>
                        <ContentInfoBlockTitle>{t("Bondable")}</ContentInfoBlockTitle>
                        {boundableView().map((val, key) => (<ContentInfoBlockText key={key} value={val}/>))}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!datasheet.product_colors.length}>
                        <ContentInfoBlockTitle>{t("Colors and OEM Approvals")}</ContentInfoBlockTitle>
                        {datasheet.product_colors.length > 1 ? (
                            datasheet.product_colors.slice(0, 1).map((color, colorKey) => (
                                <Fragment key={colorKey}>
                                    <p className={'color-title'}>{color.color}:</p>
                                    {(
                                        color.items.map((item, itemKey) => (
                                            <ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={approvalView(item)}/>
                                        ))
                                    )}
                                </Fragment>
                            ))
                        ) : datasheet.product_colors.map((color, colorKey) => (
                            <Fragment key={colorKey}>
                                <p className={'color-title'}>{color.color}:</p>
                                {(
                                    color.items.map((item, itemKey) => (
                                        <ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={approvalView(item)}/>
                                    ))
                                )}
                            </Fragment>
                        ))}
                        {datasheet.product_colors.length > 1 && (
                            <InfoPopup type={'colors-type'} left={false} content={datasheet.product_colors.map((color, colorKey) => (
                                <div className="color-wrap" key={colorKey}>
                                    <p className="color-title">
                                        {color.color}:{' '}
                                    </p>
                                    {(color.items.map((item, itemKey) => (<ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={approvalView(item)}/>)))}
                                </div>
                            ))} />
                        )}
                    </ContentInfoBlock>
                    <ContentInfoBlock show={!!datasheet.manufacturers.length}>
                        <ContentInfoBlockTitle>
                            {t("Part Approvals")}
                            <Tooltip>
                                {`This is a list of manufactures using this material in a part without an approved OEM specification. Confirm part details in the projects tab.`}
                            </Tooltip>
                        </ContentInfoBlockTitle>
                        {datasheet.manufacturers.length > 1 ? (
                            datasheet.manufacturers.slice(0, 1).map((elem, index) => (
                                <ContentInfoBlockText key={index} value={elem}/>
                            ))
                        ) : datasheet.manufacturers.map((elem, index) => (
                            <ContentInfoBlockText key={index} value={elem}/>
                        ))}
                        {datasheet.manufacturers.length > 1 && (
                            <InfoPopup type={'manufacturers'} left={false} content={[...datasheet.manufacturers].map((elem, index) => (
                                <ContentInfoBlockText key={index} value={elem}/>))}
                            />
                        )}
                    </ContentInfoBlock>
                    <ContentInfoBlock show={!!activeLocations.length}>
                        <ContentInfoBlockTitle>{t("Vehicle Locations")}</ContentInfoBlockTitle>
                        {showContent(activeLocations, isShownLocationsContent, locationView)}
                        {activeLocations.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownLocationsContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShownLocationsContent(!isShownLocationsContent)}
                            >
                                Show
                                {' '}
                                {isShownLocationsContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!datasheet.countries.length}>
                        <ContentInfoBlockTitle>
                            {t("Countries")}
                            <Tooltip>
                                {`This ${tabCountryLabel[activeTab] || 'Technical Data Sheet'} is applicable to these countries. Verify Compliance.`}
                            </Tooltip>
                        </ContentInfoBlockTitle>
                        {showCountries(datasheet.countries, isShownCountryContent, countryView)}
                        {datasheet.countries.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownCountryContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShowCountryContent(!isShownCountryContent)}
                            >
                                Show
                                {' '}
                                {isShownCountryContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>

                    {!!datasheet.contacts.length && (
                        <ContentInfoBlockTitle>{t("Contacts")}</ContentInfoBlockTitle>
                    )}

                    {!!datasheet.contacts.length && datasheet.contacts.map((contact, index) => (
                        <Fragment key={`contact-${index}`}>
                            <ContentInfoBlock show={!!contact.id}>
                                <ContentInfoContactWrapper>
                                    <a
                                        href={`/admin/users/${contact.id}`}
                                        rel="nofollow noopener noreferrer"
                                        target="_blank"
                                    >
                                        {
                                            contact.photo ? (
                                                <ImgStorage
                                                    href={`/admin/users/${contact.id}`}
                                                    url={contact.photo}
                                                    className="grade-view__img"
                                                />
                                            ) : (
                                                <Img img="icon_contact_small" className="grade-view__img"/>
                                            )
                                        }
                                    </a>
                                    <ContentInfoBlock>
                                        <ContentInfoBlockText value={contact.name || ''}/>
                                        <ContentInfoBlockTitle>{contact.relation_role ? contact.relation_role : ''}</ContentInfoBlockTitle>
                                        <ContentInfoBlockTitle>{contact.role ? contact.role : ''}</ContentInfoBlockTitle>
                                        <TableRowItemLink
                                            title={contact.email}
                                            href={`mailto:${contact.email}`}
                                        >
                                            {contact.email}
                                        </TableRowItemLink>
                                        <ContentInfoBlockText value={contact.phone || ''}/>
                                    </ContentInfoBlock>
                                </ContentInfoContactWrapper>
                            </ContentInfoBlock>
                        </Fragment>
                    ))}
                </>
            )
        }

        return null;
    };

    const getDescription = () => {
        if (description.length <= 0) {
            return null;
        }
        const contentDescription = description.length > 100 ? `${description.slice(0, 100)}...` : description;
        return <ContentInfoBlockTitle title={description}>{`${contentDescription}`}</ContentInfoBlockTitle>;
    }

    return (
        <ContentInfoWrap>
            <ContentInfoHeaderWrap>
                <ContentInfoTop
                    onClickEdit={canEdit ? onClickEdit : null}
                    urlBack="/admin/publications"
                />
                <ContentInfoHeader>
                    <ContentInfoTitle>{`${name}`}</ContentInfoTitle>
                    <ContentInfoBlockTitle>{company}</ContentInfoBlockTitle>
                    {getDescription()}
                </ContentInfoHeader>
            </ContentInfoHeaderWrap>

            <div className="product__filters">
                {getSlugInfoBlock()}
                {showData()}
            </div>
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;
InfoWrap.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "view.name"),
        items: getStoreItem(state, "items"),
        description: getStoreItem(state, "view.description"),
        slug: getStoreItem(state, "view.slug"),
        company: getStoreItem(state, "view.company"),
        datasheet: getStoreItem(state, "view.datasheet"),
        canEdit: getStoreItem(state, "view.canEdit"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
