import React from 'react';
import PropTypes from "prop-types";

const GroupPublication = ({groupList, callback, activeGroupId}) => {
    const onChangeGroup = (groupId) => () => {
        if (activeGroupId === groupId) {
            return;
        }

        return callback('group_id', groupId)
    }

    return (
        <div className="group-list">
            {groupList && groupList.map((group) => (
                <button
                    key={group.value}
                    type="button"
                    className={`group-list-item__btn${activeGroupId === group.value ? ' group-list-item__btn--active' : ''}`}
                    onClick={onChangeGroup(group.value)}
                >
                    {group.label}
                </button>
            ))}
        </div>
    );
}

GroupPublication.propTypes = {
    callback: PropTypes.func.isRequired,
}

export default GroupPublication;