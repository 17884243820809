import { request, url } from "Services";

// const listUrl = "/notification/notifications";

// const getItems = data => {
//     const sendObj = {
//         url: url.getUrl(listUrl),
//         data,
//         type: "GET",
//     };
//
//     return request.sendRequest(sendObj);
// };

export const requestUnit = data => {
  const sendObj = {
    url: url.getUrl(`/admin/users/edit-weight`),
    data,
    type: "POST",
  };

  return request.sendRequest(sendObj);
};
