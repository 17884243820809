import React from "react";
import { marginEnum } from "Services/enum";
import { FormBlockLink } from "Templates/Form";
import main from "../index";
import { formData } from "./FormService";
import { getStoreItem, getChartData, getActionStore } from "./StoreService";
import { getData, getItemOptions } from "../RequestService";

const optionsForFirstSelect = marginEnum;

const getFirstColumnInTheTable = item => {
    return <FormBlockLink href={`/companies/${item.id}`}>{item.label}</FormBlockLink>;
};

export default () => ({
    ...main,
    getData: getData("/forecasts/customers"),
    getItems: getItemOptions("/forecasts/customers/customer-list"),
    optionsForFirstSelect,
    getFirstColumnInTheTable,
    getStoreItem,
    getChartData,
    getActionStore,
    formData,
});
