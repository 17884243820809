import React from "react";
import { compose } from "redux";
// import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const TableColumn = ({ pref, children }) => {
    return <div className={`${pref}__table-column`}>{children}</div>;
};

TableColumn.propTypes = {};

export default compose(withTagDefaultProps())(TableColumn);
