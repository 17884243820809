import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from "Hoc/Template";

const defaultProps = {
    className: "",
    onClick: e => {
        e.preventDefault();
        window.history.back();
    },
};

const propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};

const BackLink = ({ children, className, onClick }) => {
    return (
        <a href="#" onClick={onClick} className={className}>
            {children}
        </a>
    );
};

BackLink.defaultProps = defaultProps;
BackLink.propTypes = propTypes;

export default compose(withTagDefaultProps())(BackLink);
