import React, {Fragment, useEffect} from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import TableBody from "./TableBody";
import parse from "html-react-parser";

const ProjectsContainerTable = (TableHeadColumn, TableRowColumn, {showButtons = true}) => ({
    request,
    columns,
    service,
    pinned_columns,
    customIcon,
    fetchItems,
    onClickEdit,
    onClickItem,
    onClickDelete,
    onClickCustom
}) => {
    const showColumns = showButtons ? [...columns, "-1"] : [...columns];
    let getTooltip = service.getTooltip;

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.querySelectorAll(`.main-table__head`).forEach((el) => el.removeAttribute('style'));
            document.querySelectorAll(`.main-table__body`).forEach((el) => el.removeAttribute('style'));
            document.querySelectorAll('.last-pinned').forEach((el) => el.classList.remove('last-pinned'));

            if (showColumns.length && pinned_columns.length) {
                const cols = showColumns.reduce((acc, curr) => {
                    if (pinned_columns.includes(curr)) {
                        acc.push(curr)
                    }
                    return acc
                }, [])

                if (cols.length) {
                    let totalWidth = 0;
                    const tableBodyCols = document.querySelectorAll(`.td.pinned-column`)
                    const tableBodyLastCols = document.querySelectorAll(`.td.${cols[cols.length - 1]}`)

                    if (tableBodyCols.length) {
                        tableBodyCols.forEach((el) => {
                            const tooltip = el.querySelector(`.tooltip`)

                            if (tooltip) {
                                el.classList.add('with-tooltip')
                            }
                        })
                    }

                    if (tableBodyLastCols.length) {
                        tableBodyLastCols.forEach((el) => el.classList.add('last-pinned'))
                    }

                    cols.forEach((col, i) => {
                        const elem = document.querySelector(`.th.${col}`);
                        const width = document.querySelector(`.th.${col}`).clientWidth - 10;

                        if (i === (cols.length - 1)) elem.classList.add('last-pinned')

                        if (i !== cols.length) {
                            elem.style.left = `${totalWidth}px`;

                            if (tableBodyCols.length) {
                                tableBodyCols.forEach((bodyCol, index) => {
                                    if ((index % cols.length) === i) {
                                        bodyCol.style.left = `${totalWidth}px`
                                    }
                                })
                            }

                            totalWidth += width;
                        }
                    })
                }
            }
        }
    }, [pinned_columns, request])

    const onSubmit = e => {
        e.preventDefault();

        fetchItems(request);
    };

    const onSortClick = column => {
        const asc = "asc";
        const desc = "desc";
        const newRequest = {
            ...request,
            sort: {
                ...request.sort,
                column,
            },
        };
        if (request.sort.column === column) {
            newRequest.sort.direction = request.sort.direction === asc ? desc : asc;
        } else {
            newRequest.sort.direction = asc;
        }

        fetchItems(newRequest);
    };

    return (
        <form onSubmit={onSubmit}>
            <table className="main-table">
                <thead>
                    <tr className="projects-table-head">
                    {showColumns.map((column, index) => {
                        return (
                            <Fragment key={`TableRowColumn-proj-${index}-table-${column}`}>
                                {column === "-1" ? (
                                    <th key={`TableHeadColumn-${index}-revenue`} className={`main-table__head`}/>
                                ) : (
                                    <th
                                        key={`TableHeadColumn-${index}-current`}
                                        className={`
                                            ${index === 0 ? 'first-th' : ''}
                                            ${index === showColumns.length - 1 ? 'last-th' : ''}
                                            ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}
                                            main-table__head
                                            ${getTooltip && getTooltip(column) ? `table-${column}` : ''}
                                        `}>
                                        {getTooltip && getTooltip(column) ? <span
                                            className="projects-table-head__tooltip tooltip__text tooltip__text__table">{parse(`${getTooltip(column)}`)}</span> : null}
                                        <div className={`connections-head-wrapper
                                            ${column.includes('volume_') ? 'project-header__filter' : ''}
                                            ${column.includes('revenue_') ? 'project-header__filter' : ''}
                                            ${column.includes('grossMargin_') ? 'project-header__filter' : ''}
                                         `}>
                                            <TableHeadColumn onSortClick={onSortClick} column={column} index={index}/>
                                        </div>
                                    </th>
                                )}
                            </Fragment>
                        )
                            ;
                    })}
                </tr>
                </thead>
                <tbody>
                <TableBody
                    columns={showColumns}
                    TableRowColumn={TableRowColumn}
                    customIcon={customIcon}
                    onClickItem={onClickItem}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                    onClickCustom={onClickCustom}
                />
                </tbody>
            </table>
            <button type="submit" className="visually-hidden"/>
        </form>
    );
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        columns: getStoreItem(state, "columns"),
        pinned_columns: getStoreItem(state, "pinned_columns"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default (TableHeadColumn, TableRowColumn, {showButtons = true} = {}) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(ProjectsContainerTable(TableHeadColumn, TableRowColumn, {showButtons}));
