import _ from "lodash";
import {uniqueGenerator, url} from "Services";
import Constants from "../Constants";

export const initState = {
    activeTab: +url.get('activeTab'),
    showModal: false,
    welcomeGuestShowModal: false,
    addPublicationListShowModal: false,
    addSharedProductsPublicationListShowModal: false,
    groups: [{id: uniqueGenerator(), value: ''}],
    form: {
        notify_creator: true,
        products_invite: false,
        lists_invite: false,
        lists: []
    },
    addPublicationListForm: {
        name: '',
        oldName: '',
        id: null,
    }
};

export const reducer = (state, action) => {
    const {data} = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");

            return {...state, activeTab: data, filter: {}, sort: {}, groupList: null};

        case Constants.SAVE_INVITE_SUCCESS:
        case Constants.CLOSE_MODAL:
            return {
                ...state, showModal: false, groups: [{id: uniqueGenerator(), value: ''}], form: {
                    notify_creator: true,
                    products_invite: false,
                    lists_invite: false,
                    lists: []
                }
            };

        case Constants.WELCOME_GUEST_CLOSE_MODAL:
            return {
                ...state, welcomeGuestShowModal: false, form: {
                    notify_creator: true
                }
            };

        case Constants.OPEN_MODAL:
            return {...state, showModal: true};

        case Constants.WELCOME_GUEST_OPEN_MODAL:
            return {...state, welcomeGuestShowModal: true};

        case Constants.SAVE_INVITE_FAILED:
            return {...state, errors: {...data}, showModal: true};

        case Constants.CHANGE_INVITE_FORM: {
            const newState = {...state};
            _.set(newState, data.key, data.value);

            return {...newState};
        }

        case Constants.CHANGE_FILTER_FORM: {
            const newState = {...state};
            _.set(newState, data.key, data.value);

            return {...newState};
        }

        case Constants.UPDATE_GROUPS_FORM: {
            return {...state, groups: data};
        }

        case Constants.ADD_PUBLICATION_LIST_OPEN_MODAL: {
            const id = data && data.id || null;
            const name = data && data.name || '';
            const oldName = name;

            return {
                ...state,
                addPublicationListShowModal: true,
                addPublicationListForm: {
                    name,
                    oldName,
                    id,
                }
            };
        }

        case Constants.ADD_PUBLICATION_LIST_CLOSE_MODAL: {
            return {
                ...state,
                addPublicationListShowModal: false,
                addPublicationListForm: {
                    name: '',
                    id: null,
                },
            };
        }

        case Constants.CHANGE_ADD_PUBLICATION_LIST_FORM: {
            const newState = {...state};
            _.set(newState, data.key, data.value);
            return {...newState};
        }

        case Constants.ADD_SHARED_PRODUCTS_PUBLICATION_LIST_OPEN_MODAL: {
            return {
                ...state,
                addSharedProductsPublicationListShowModal: true
            };
        }

        case Constants.ADD_SHARED_PRODUCTS_PUBLICATION_LIST_CLOSE_MODAL: {
            return {
                ...state,
                addSharedProductsPublicationListShowModal: false
            };
        }

        default:
            return state;
    }
};
