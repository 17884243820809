const tableColumns = {
    name: "Project Name",
    slug: "Project ID",
    sub_status: "Status",
    application: "Project Sub-Segment",
    application_code: "Project Application Code",
    customer: "Customer",
    customer_code: "Customer Code",
    industry: "Project Market Segment",
    manager: "Project Manager",
    grade: "Material Name",
    material_number: "Material Number",
};

export default tableColumns;
