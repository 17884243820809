import NotificationConstants from "../View/Header/Items/Notification/Constants";

export const initialState = {
    id: "",
    fullName: "",
    photo: "",
    companyName: "",
    companyList: [],
    hasNotifications: 0,
    permissions: [],
    isInternalUser: false
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case NotificationConstants.CHANGE_NOTIFICATION_STATUS: {
            const { data } = action;

            return {
                ...state,
                hasNotifications: data,
            };
        }

        default:
            return state;
    }
}
