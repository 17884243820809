import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form, BlockWrap, Input, WrapInput, BlockMain } from "Templates/Form";

const propTypes = {
    name: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    service: PropTypes.shape({
        industryOptions: PropTypes.arrayOf(
            PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
        ).isRequired,
    }).isRequired,
};

const FormBlock = ({ name, industry, service: { industryOptions }, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };
    return (
        <Form>
            <BlockMain title="General Info">
                <BlockWrap>
                    <WrapInput isRequired label={t("Application Name")} name="name">
                        <Input placeholder={t("Name")} value={name} onChange={value => onChangeInput(value, `name`)} />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired label={t("Market Segment")} name="industry">
                        <Input
                            type="select"
                            options={industryOptions}
                            placeholder={t("Select")}
                            value={industry}
                            onChange={value => onChangeInput(value, `industry`)}
                        />
                    </WrapInput>
                </BlockWrap>
            </BlockMain>
        </Form>
    );
};

FormBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        industry: getStoreItem(state, "industry"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
