import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {
    BlockImgWithCrop,
    BlockWrap,
    Block,
    Input,
    BlockShadow,
    BlockMain,
    WrapInput,
    BlockWrapFlex
} from "Templates/Form";
import CheckboxBlock from "Templates/Form/Checkbox/CheckboxBlock";
import CheckboxButton from "Templates/Form/Checkbox/CheckboxButton";
import CheckboxTitle from "Templates/Form/Checkbox/CheckboxTitle";

const propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    employer: PropTypes.string.isRequired,
    twoFactor: PropTypes.bool.isRequired,
    paid: PropTypes.bool.isRequired,
    // photo: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
    photoView: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
    onChange: PropTypes.func.isRequired,
};

function GeneralBlock({firstName, lastName, title, twoFactor, employer, paid, onChange, t, photoView}) {
    const changePhoto = (value, key) => {
        onChange(key, value);
        onChange('photoView', value);
    };

    return (
        <BlockWrapFlex className="width_100">
            <BlockWrap className="width_4-5">
                <BlockMain title="General Info">
                    <BlockShadow>
                        <BlockWrapFlex>
                            <BlockWrap className="width_1-2">
                                <WrapInput label={t("First Name")} name="firstName" isRequired>
                                    <Input
                                        placeholder={t("First Name")}
                                        value={firstName}
                                        onChange={value => onChange("firstName", value)}
                                    />
                                </WrapInput>
                                <WrapInput isRequired label={t("Title")} name="title">
                                    <Input placeholder={t("Title")} value={title}
                                           onChange={value => onChange("title", value)}/>
                                </WrapInput>
                                <WrapInput label={t("")} name="twoFactor">
                                    <CheckboxBlock>
                                        <CheckboxButton
                                            htmlFor="checkbox_twoFactor"
                                            value={!!twoFactor}
                                            onChange={value => onChange("twoFactor", value)}
                                        >
                                            <CheckboxTitle title="Two Factor Authentication"/>
                                        </CheckboxButton>
                                    </CheckboxBlock>
                                </WrapInput>
                            </BlockWrap>
                            <BlockWrap className="width_1-2">
                                <WrapInput isRequired label={t("Last Name")} name="lastName">
                                    <Input
                                        placeholder={t("Last Name")}
                                        value={lastName}
                                        onChange={value => onChange("lastName", value)}
                                    />
                                </WrapInput>
                                <WrapInput isRequired label={t("Employer")} name="employer">
                                    <Input
                                        placeholder={t("Employer")}
                                        value={employer}
                                        onChange={value => onChange("employer", value)}
                                    />
                                </WrapInput>
                                <WrapInput label={t("")} name="paid">
                                    <CheckboxBlock>
                                        <CheckboxButton
                                            htmlFor="checkbox_paid"
                                            value={!!paid}
                                            onChange={value => onChange("paid", value)}
                                        >
                                            <CheckboxTitle title="Internal User"/>
                                        </CheckboxButton>
                                    </CheckboxBlock>
                                </WrapInput>
                            </BlockWrap>
                        </BlockWrapFlex>
                    </BlockShadow>
                </BlockMain>
            </BlockWrap>
            <BlockWrap className="width_1-5">
                <BlockMain title="Image">
                    <BlockShadow>
                        <BlockImgWithCrop
                            name="photo"
                            value={photoView}
                            title={t("")}
                            onChange={value => changePhoto(value, "photo")}
                            textButton={t("Upload Photo")}
                        />
                    </BlockShadow>
                </BlockMain>
            </BlockWrap>
        </BlockWrapFlex>
    );
}

GeneralBlock.propTypes = propTypes;

const mapStateToProps = (state, {service}) => {
    const {getStoreItem} = service;
    return {
        title: getStoreItem(state, "title"),
        employer: getStoreItem(state, "employer"),
        firstName: getStoreItem(state, "firstName"),
        lastName: getStoreItem(state, "lastName"),
        photo: getStoreItem(state, "photo"),
        photoView: getStoreItem(state, "photoView"),
        twoFactor: getStoreItem(state, "twoFactor"),
        paid: getStoreItem(state, "paid"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(GeneralBlock);
