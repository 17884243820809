import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const hierarchyProperties = _.get(data, "hierarchyProperties", [])
        .filter(property => _.trim(property.property) && property.parameters1.filter(parameter1 => _.trim(parameter1.parameter1)));

    const dataReq = {
        hierarchyProperties
    };

    return formDataNormalizer(dataReq);
};
