import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url, isEqual } from "Services";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import FormBlock from "../../Views/Form";
import _ from "lodash";
import {Modal} from "Templates/Modals";
import HelpPage from "./HelpPage";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
};

const PropertyHierarchy = ({ old, validate, formData, t, submitForm }) => {
    const [showHelpModal, setShowHelpModal] = React.useState(false);
    const handleOpenHelpModal = () => {
        setShowHelpModal(!showHelpModal);
    }
    const savePropertyHierarchy = () => {
        submitForm(formData).then(
            data => {
                url.redirect("/admin/process-hierarchies");
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const validateAll = validate ? !isEqual(formData, old, _.keys(old)) : validate;

    return (
        <>
            <Title
                title={t("PDS Hierarchy")}
                showHelpBtn
                handleHelpBtn={handleOpenHelpModal}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight />
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validateAll} onClick={savePropertyHierarchy}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>

            {showHelpModal && (
                <Modal onRequestClose={handleOpenHelpModal}>
                    <HelpPage onClose={handleOpenHelpModal} />
                </Modal>
            )}
        </>
    );
};

PropertyHierarchy.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        old: getStoreItem(state, "old"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(PropertyHierarchy);
