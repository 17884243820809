import React from "react";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {getString, formatDate} from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLink title={getString(item, key)}
                                  href={`/product/product-finder/publication-groups/${item.id}`}>
                    {getString(item, key, '')}
                </TableRowItemLink>
            );

        case "owner":
            return (
                <TableRowItemLinkImg
                    blank
                    title={item.owner.name.toString()}
                    href={`/admin/users/${item.owner.id.toString()}`}
                    img={item.owner.photo.toString()}
                >
                    {item.owner.name.toString()}
                </TableRowItemLinkImg>
            );

        case "created_at":
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
