import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import {Span} from "Templates/Default";
import {getNumber, numberWithCommas} from "Services/NumberService";
import PropTypes from "prop-types";
import CheckDefaultPermission from "Hoc/Template/CheckDefaultPermission";
import UnitWeightWrapper from "App/View/UnitWeightWrapper";

const propTypes = {
    suggested: PropTypes.shape({
        sale_price: PropTypes.string,
        goods_sold: PropTypes.string,
    }).isRequired,
};

const TableHeadColumn = ({ suggested, actual }) => {
    return (
            <div className="cost-modal__head">
                <div className="cost-modal__content_row">
                    <div className="cost-modal__head_row-left">
                        <h3 className="cost-modal__content_title">
                            Price
                            <span className="label_param">
                              ($/
                                <UnitWeightWrapper />
                              )
                            </span>
                        </h3>
                    </div>
                    <div className="cost-modal__head_row-right">
                        <span className="text-right title_price cost-modal__content_price">{'$' + numberWithCommas(getNumber(actual.sale_price))}</span>
                    </div>
                </div>
                <div className="cost-modal__content_row">
                    <div className="cost-modal__head_row-left">
                        <h3 className="cost-modal__content_title">
                            Suggested Price
                            <Span className="label_param">
                              ($/
                                <UnitWeightWrapper />
                              )
                            </Span>
                        </h3>
                    </div>
                    <div className="cost-modal__head_row-right">
                        <span className="text-right bold-texts cost-modal__content_price">
                            <CheckDefaultPermission permission={'project_suggested_price'} defaultValue={'-'}>
                                {'$' + numberWithCommas(getNumber(suggested.sale_price))}
                            </CheckDefaultPermission>
                        </span>
                    </div>
                </div>
            </div>
);
};

TableHeadColumn.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        suggested: getStoreItem(state, "cost.salesPrice.suggested"),
        actual: getStoreItem(state, "cost.salesPrice.actual"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableHeadColumn);
