import React from "react";
import { projectStatusList } from "Services/enum";
import { Span } from "Templates/Default";
import { requestData } from "../../RequestService";
import { getStoreItem, getForecastStore, getActionStore } from "./StoreService";

const getViewBelowStatus = item => <Span className="status__value">{`${item.value}%`}</Span>;

export default id => ({
    requestData: requestData(`/project/projects/${id}/forecasts`),
    requestVehicleBuilds: data => requestData(`/project/projects/${id}/forecasts/statistic`)(data),
    getStoreItem,
    getForecastStore,
    getActionStore,
    projectStatusList,
    getViewBelowStatus,
});
