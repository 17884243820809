import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import BlockTitle from "Templates/Form/BlockTitle";
import {CheckboxBlock, CheckboxButton, CheckboxTitle} from "Templates/Form/Checkbox";
import {Block, BlockWrap, Input, WrapInput, BlockMain, BlockShadow} from "Templates/Form";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

class CustomTds extends Component {
    render() {
        const {
            t,
            customName,
            onChange
        } = this.props;

        const onChangeInput = (value, key) => {
            onChange(key, value);
        };

        return (
            <BlockMain title="Guide" blockClassName="fh">
                <BlockShadow>
                    <Block>
                        <BlockWrap>
                            <WrapInput isRequired label={t("Name")} name="customName">
                                <Input
                                    placeholder={t("Name")}
                                    value={customName}
                                    onChange={value => {
                                        onChangeInput(value, 'customName');
                                    }}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>
                </BlockShadow>
            </BlockMain>
        );
    }
}

CustomTds.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        customName: getStoreItem(state, "customName"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(CustomTds);
