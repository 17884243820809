import {requestDelete, requestList, requestSave} from "../RequestService";
import {getStoreItem, getActionStore} from "../StoreService";
import * as FormService from "../FormService";
import {handleScroll} from "Services/Scroll";

export default (groupId) => ({
    getActionStore: getActionStore('contacts'),
    getStoreItem: getStoreItem('contacts'),
    saveItems: requestSave(`product/product-finder/publication-groups/${groupId}/managers`),
    getItems: (data) => requestList(`product/product-finder/publication-groups/${groupId}/managers`, data),
    deleteItem: userId => requestDelete(`product/product-finder/publication-groups/${groupId}/managers/${userId}`),
    FormService,
    handleScroll,
});