import React from "react";
import { compose } from "redux";
import { Form} from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import HierarchyBlock from "./HierarchyBlock";

const FormBlock = () => {
    return (
        <Form>
            <HierarchyBlock />
        </Form>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {};
};

FormBlock.propTypes = {};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
