import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas} from "Services";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {TabBlock, TabBlockBtn, TabBlockCol, TabBlockColTitle, TabBlockColTitleH4, TabWrapTop,} from "Templates/Content";
import {NoteBlock, NotesWrap} from "Templates/Notes";
import {NotesWall as NotesWallWidgets} from "Hoc/Widgets";
import NoteTopWrap from "Templates/Notes/NoteTopWrap/NoteTopWrap";
import NoteMessageWrap from "Templates/Notes/NoteMessageWrap/NoteMessageWrap";
import NoteModal from "./NoteModal";

const NotesWall = NotesWallWidgets(NotesWrap, NoteBlock, NoteTopWrap, NoteMessageWrap);

const defaultProps = {
    total: 0,
};

const propTypes = {
    total: PropTypes.number,
    fetchItems: PropTypes.func.isRequired,
    deleteNote: PropTypes.func.isRequired,
};

class Notes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.item = {};
    }

    componentDidMount() {
        const { fetchItems } = this.props;
        fetchItems({});
    }

    handleOpenModal = (item = {}) => {
        this.item = { id: item.id, old: item.comment || "", append: item.comment || "" };
        this.setState({ showModal: true });
    };

    handleCloseModal = append => () => {
        const { t } = this.props;
        if (append.length === 0) {
            this.setState({ showModal: false });
            this.item = {};
            return;
        }

        if (confirm(t("Are you sure you want to close the modal window?"))) {
            this.setState({ showModal: false });
            this.item = {};
        }
    };

    handleDeleteModal = (item) => {
        const { deleteNote, fetchItems, t } = this.props;

        if (confirm(t("Do you want to delete the Note?"))) {
            deleteNote(item).then(() => {
                    this.item = {};
                    fetchItems({});
                }
            );
        }
    };

    cbAfterRequest = () => {
        const { fetchItems } = this.props;
        this.item = {};
        this.setState({ showModal: false });
        fetchItems({});
    };

    render = () => {
        const { total, t } = this.props;
        const { showModal } = this.state;

        return (
            <>
                <TabWrapTop>
                    <TabBlock>
                        <TabBlockCol>
                            <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                            <TabBlockColTitleH4>{`${numberWithCommas(total, 0)} ${t("Notes")}`}</TabBlockColTitleH4>
                        </TabBlockCol>
                    </TabBlock>
                    {<TabBlockBtn onClick={this.handleOpenModal}>{t("Add Note")}</TabBlockBtn>}
                </TabWrapTop>
                <NotesWall handleClickOnEditButton={this.handleOpenModal} handleClickOnDeleteButton={this.handleDeleteModal} />
                {showModal && (
                    <NoteModal onClose={this.handleCloseModal} cbAfterRequest={this.cbAfterRequest} item={this.item} />
                )}
            </>
        );
    };
}

Notes.defaultProps = defaultProps;
Notes.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        total: getStoreItem(state, "pagination.total"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        deleteNote: getActionStore("deleteNoteAction", service, dispatch)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Notes);
