import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: "",
};

const Li = ({ children, className }) => <li className={className}>{children}</li>;

Li.propTypes = propTypes;
Li.defaultProps = defaultProps;

export default Li;
