import { combineReducers } from "redux";
import { getMainInitialStates, getMainReducers } from "App/Services";
import { reducer as projectsReducer, initState as projectsState } from "./TableProjectsReducer";
import { reducer as tasksReducer, initState as tasksState } from "./TableTasksReducer";

const reducers = [projectsReducer, tasksReducer];

const mainState = {
    projectsTable: projectsState,
    tasksTable: tasksState,
};

const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }

    return state;
};

// Combine all reducers you may have here
export default combineReducers({
    ...getMainReducers,
    listState: reducer,
});

export const initialStates = {
    ...getMainInitialStates,
    mainState,
};
