import React, {useEffect, useState} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {
    disabled: false,
};

const propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

const Button = ({ disabled, onClick, pref, children }) => {
    const [focus, setFocus] = useState(false)

    useEffect(() => {
        if (disabled) setFocus(false)
    }, [disabled])

    return (
        <button
            tabIndex={0}
            type="button"
            className={`${pref}__buttons-btn main-content__buttons-btn main-btn ${disabled ? 'disabled' : ''} ${focus ? 'focus' : ''}`}
            onClick={onClick}
            onFocus={() => {
                setFocus((prev) => !prev)
            }}
            onBlur={() => {
                setFocus((prev) => !prev)
            }}
        >
            {children}
        </button>
    );
}

Button.defaultProps = defaultProps;
Button.propTypes = propTypes;

export default compose(withTagDefaultProps())(Button);
