import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import Img, { ImgStorage } from "../Img";

const defaultProps = {
    img: "",
    ico: "",
    className: "",
    additionalClassname: "",
    title: "",
};

const propTypes = {
    img: PropTypes.string,
    ico: PropTypes.string,
    className: PropTypes.string,
    additionalClassname: PropTypes.string,
    title: PropTypes.string,
};

const NoLinkImg = ({ children, className, pref, img, ico, additionalClassname, title }) => {
    return (
        <span title={title} className={`${pref}__table-body-link main-table__body-link ${className}`}>
            <span className={`${pref}__table-body-link-user main-table__body-link-user ${additionalClassname}`}>
                {img ? <ImgStorage url={img}/> : <Img img={ico || "icon_contact_small"}/>}
            </span>
            <span className={`${pref}__table-body-link-text main-table__body-link-text`}>{children}</span>
        </span>
    );
};

NoLinkImg.propTypes = propTypes;
NoLinkImg.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(NoLinkImg);
