import {debounce} from "Services/Debounce";
import {requestExportFinance} from "../Services";
import Constants from "../Constants";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const getUrlExport = (service, dispatch) => (data) => {
  dispatch({type: Constants.EXPORT_FINANCE, data});

  return requestExportFinance(data).then(
      res => {
          alert('Export Forecast started. Check notifications to download.');
          dispatch({ type: Constants.EXPORT_FINANCE_SUCCESS, data: res });
      },
      res => {
          alert(res.detail);
          dispatch({ type: Constants.EXPORT_FINANCE_FAILED, data: res });
      },
  );
};

export const activeWeightUnit = (service, dispatch) => data => {
    dispatch({type: Constants.IS_ACTIVE_COVERTOR, data});
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (
        [
            "slug",
            "name",
            "customer_short_name",
            "customer_duns_number",
            "grades",
            "sap_grade",
            "material_types",
            "material_numbers",
            "product_codes",
            "product_application_codes",
            "plants",
            "sub_status",
            "move_to_current",
            "sub_status",
            "customer",
            "los_date",
            "sap_customer",
            "connection_slug",
            "connection_note_finance",
            "customer_code",
            "customer_nickname",
            "status",
            "manager",
            "connection_manager",
            "owner",
            "project_name",
            "project_slug",
            "grade_sub_status",
        ].includes(key)
    ) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const resetFilter = (service, dispatch) => (request) => {
    dispatch({ type: Constants.RESET_FILTER, data: {} });
    fetchItems(service, dispatch)(request);
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};
