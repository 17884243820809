import _ from "lodash";
import Constants from "../Constants";

export const initialState = {
    id: "",
    customerCode: "",
    customerOwner: "",
    name: "",
    dunsNumber: "",
    logo: "",
    logoView: "",
    email: "",
    url: "",
    nicknames: [{id: "", value: ""}],
    phones: [{ type: '1', number: "" }],
    canEditCustomerCode: false,
    physicalCountry: "",
    physicalCity: "",
    physicalAddress1: "",
    physicalZip: "",
    physicalState: "",
    physicalDistrict: "",
    physicalTimeZone: "",
    socials: [],
    parentCompanyId: "",

    form_validate: false,
    form_loading: false,
    form_errors: {},
    old: {},
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM:
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return {
                ...newState,
                form_validate: validate(newState),
            };

        case Constants.SET_PHONE:
        case Constants.SET_NICKNAME:
            return {...state, ...data};

        case Constants.FORM_REQUESTED:
            return {...state, form_loading: true};

        case Constants.FORM_SUCCEEDED:
            return {...state, form_loading: false};

        case Constants.FORM_FAILED:
            return {...state, form_errors: {...data}, form_loading: false};

        case Constants.FORM_RESET:
            return {...initialState, nicknames: [{id: "", value: ""}], phones: [{ type: '1', number: "" }]};
        default:
            return state;
    }
};

const validate = state => {
    return !!state.name.trim();
};
