import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {getString} from "Services";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            let listId = ''
            if (typeof window !== 'undefined') {
                const search = new URLSearchParams(window.location.search)
                listId = search.get('listId')
            }
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key)}
                        href={listId ? `/product/product-finder/publications/${getString(item, "id")}/shared-lists?listId=${listId}&activeTab=1` : '/product/product-finder/shared?activeTab=1'}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "description":
            return <TableRowItemSpan title={getString(item, key)} isShortText={false}>{getString(item, key)}</TableRowItemSpan>;

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
