import React, { Fragment } from "react";
import { compose } from "redux";
import { withTagDefaultProps, CheckPermission } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import HierarchyTableBody from "./HierarchyTableBody";
import {fetchHierarchyItems} from "../../Actions/TableActions";

const HierarchyContainerTable = (TableHeadColumn, TableRowColumn, { showButtons = true }) => ({
    children,
    request,
    columns,
    customIcon,
    fetchHierarchyItems,
    onClickEdit,
    onClickItem,
    onClickDelete,
    onClickCustom,
    addHierarchy,
    service: { tableButtonsPermission },
}) => {
    const showColumns = showButtons ? [...columns, "-1"] : [...columns];

    const onSubmit = e => {
        e.preventDefault();

        fetchHierarchyItems(request);
    };

    const onSortClick = column => {
        const asc = "asc";
        const desc = "desc";
        const newRequest = {
            ...request,
            sort: {
                ...request.sort,
                column,
            },
        };
        if (request.sort.column === column) {
            newRequest.sort.direction = request.sort.direction === asc ? desc : asc;
        } else {
            newRequest.sort.direction = asc;
        }

        fetchHierarchyItems(newRequest);
    };

    return (
        <form onSubmit={onSubmit}>
            <table className="main-table">
                <tbody>
                    <tr>
                        {showColumns.map((column, index) => (
                            <Fragment key={`TableRowColumn-${index}`}>
                                {column === "-1" ? (
                                    <CheckPermission permission={tableButtonsPermission}>
                                        <th key={`TableHeadColumn-${index}`} className="main-table__head" />
                                    </CheckPermission>
                                ) : (
                                    <th key={`TableHeadColumn-${index}`} className="main-table__head">
                                        <TableHeadColumn onSortClick={onSortClick} column={column} index={index} />
                                    </th>
                                )}
                            </Fragment>
                        ))}
                    </tr>
                    <HierarchyTableBody
                        children={children}
                        columns={showColumns}
                        TableRowColumn={TableRowColumn}
                        customIcon={customIcon}
                        onClickItem={onClickItem}
                        onClickEdit={onClickEdit}
                        onClickDelete={onClickDelete}
                        onClickCustom={onClickCustom}
                        addHierarchy={addHierarchy}
                    />
                </tbody>
            </table>
            <button type="submit" className="visually-hidden" />
        </form>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        columns: getStoreItem(state, "columns"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchHierarchyItems: getActionStore("fetchHierarchyItems", service, dispatch),
    };
};

export default (TableHeadColumn, TableRowColumn, { showButtons = true } = {}) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(HierarchyContainerTable(TableHeadColumn, TableRowColumn, { showButtons }));
