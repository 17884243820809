import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { ModalDefault } from "Templates/Modals";
import { ButtonClose } from "Templates/Button";
import { withServiceConsumer } from "Services/Context";

const { Button, Form, Title, ColInput } = ModalDefault;

const defaultProps = {
    oldValue: {},
    editValue: {},
};

const propTypes = {
    service: PropTypes.shape({
        requestAttach: PropTypes.func.isRequired,
        requestEdit: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    editValue: PropTypes.objectOf(PropTypes.any),
    onClose: PropTypes.func.isRequired,
    type: PropTypes.number.isRequired,
    showFilterColumn: PropTypes.bool.isRequired,
    button: PropTypes.string.isRequired,
    oldValue: PropTypes.objectOf(PropTypes.any),
    fetchItems: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
};

class AddForm extends Component {
    state = {
        form: {
            id: "",
            name: "",
            alternate_name: "",
            type: "",
            show_filter: "10",
        },
    };

    componentDidMount() {
        const { editValue, type } = this.props;

        if (editValue.id) {
            this.setState({
                form: { ...editValue, type },
                oldValue: { ...editValue, type },
            });
        } else {
            this.setState({
                form: {
                    id: "",
                    name: "",
                    alternate_name: "",
                    type: type,
                    show_filter: "10",
                },
            });
        }
    }

    componentWillUnmount() {
        const { resetForm } = this.props;
        resetForm();
    }

    handleChange = (value, fieldName) => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [fieldName]: value,
            },
        }));
    };

    onAppend = () => {
        const {
            fetchItems,
            service: { requestAttach, requestEdit },
            onClose,
        } = this.props;
        const { form } = this.state;

        const request = form.id ? requestEdit({ id: form.id }) : requestAttach;

        request({ ...form }).then(
            () => {
                onClose();
                fetchItems({});
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    render() {
        const { t, onClose, showFilterColumn, button, type } = this.props;
        const { form, oldValue } = this.state;

        const disabled = form.name === '' || !form.type || JSON.stringify(oldValue) === JSON.stringify(form);

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose} />
                <Title>{t(`${form.id ? "Edit" : "Add New"} ${button}`)}</Title>
                <p className="main-table__head-link">Name</p>
                <ColInput
                    value={form.name}
                    name="name"
                    onChange={value => this.handleChange(value, "name")}
                    placeholder={t(`${button} Name`)}
                />
                {/* hide for Si and English Units*/}
                {(+type !== 4 && +type !== 5) && <p className="main-table__head-link subcount">Alternate Name</p>}
                {(+type !== 4 && +type !== 5) && (
                    <ColInput
                        value={form.alternate_name}
                        name="alternate_name"
                        onChange={value => this.handleChange(value, "alternate_name")}
                        placeholder={t(`${button} Alternate Name`)}
                        type="textarea"
                    />
                )}
                {showFilterColumn && <p className="main-table__head-link subcount">Show in filter</p>}
                {showFilterColumn && (
                    <ColInput
                        value={form.show_filter.toString()}
                        name="show_filter"
                        type="select"
                        options={[{value: '0', label: "No"}, {value: '10', label: "Yes"}]}
                        onChange={value => this.handleChange(value, "show_filter")}
                        placeholder={t(`Show ${button} in filter`)}
                    />
                )}
                <Button onClick={this.onAppend} disabled={disabled}>
                    {t(`Save ${button}`)}
                </Button>
            </Form>
        );
    }
}

AddForm.defaultProps = defaultProps;
AddForm.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        oldValue: getStoreItem(state, "oldValue"),
        editValue: getStoreItem(state, "editValue"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        resetForm: getActionStore("resetFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddForm);
