import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Tooltip} from "Templates/Form";
import parse from "html-react-parser";
import {gradeStatus} from "Services/enum";
import {Span} from "Templates/Default";
import {weightUnitName} from "Services/weightUnitName";

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    service: PropTypes.shape({
        columnsName: PropTypes.shape({
            product: PropTypes.shape({label: PropTypes.string.isRequired}).isRequired,
            grade: PropTypes.shape({label: PropTypes.string.isRequired}).isRequired,
            sub_status: PropTypes.shape({label: PropTypes.string.isRequired}).isRequired,
            sale_price: PropTypes.shape({label: PropTypes.string.isRequired, permission: PropTypes.string})
                .isRequired,
            revenue: PropTypes.shape({label: PropTypes.string.isRequired, permission: PropTypes.string}).isRequired,
            volume: PropTypes.shape({label: PropTypes.string.isRequired, permission: PropTypes.string}).isRequired,
            percent_of_product: PropTypes.shape({label: PropTypes.string.isRequired})
                .isRequired,
        }).isRequired,
        getBodyView: PropTypes.func.isRequired,
    }).isRequired,
};

const defaultProps = {
    items: [],
};

const TableBodyColumn = ({items, financialYear, permissions, weightUnit, service: {columnsName, getBodyView}, t}) => {
    const title = label => label.indexOf('FY') > -1 ? label.replace(/FY/g, `FY ${financialYear}`) : label;
    const [data, setData] = useState([])

    useEffect(() => {
        const productItems = items && items.length > 0 ? items.map((item) => {
            return {
                grade: {
                    value: item.grade_name || '-',
                },
                materialNumber: {
                    value: item.material_number_name || '-',
                    link: item.grade ? `/product/product-finder/${item.grade}` : null
                },
                sellingPlant: {
                    value: item.plant_name || '-',
                },
                productStatus: {
                    value: getStatus(item.sub_status)
                },
                productCode: {
                    value: item.product_code || '-',
                },
                line: {
                    value: item.line || '-',
                },
                invoices: {
                    value: item.invoice_count || 0,
                    link: `/product/product-finder/${item.grade}?activeTab=10`
                },
                connection: {
                    value: item.connection_slug || '-',
                    link: `/connection/connections?filter%5Bslug%5D%5B%5D=${item.connection_slug}`
                },
                sapApplicationCode: {
                    value: item.code || '-',
                },
                actualSalePrice: {
                    value: item.actual_sale_price ? `$${item.actual_sale_price}/${weightUnitName(weightUnit.weightUnit)}` : '-'
                },
                rebate: {
                    value: item.rebate ? `$${item.rebate}/${weightUnitName(weightUnit.weightUnit)}` : null
                },
                finalPrice: {
                    value: item.final_price ? `$${item.final_price}/${weightUnitName(weightUnit.weightUnit)}` : '-'
                },
                productPercentage: {
                    value: item.percent_of_product ? `${item.percent_of_product} %` : null
                }
            }
        }) : null
        setData(productItems)
    }, [items])

    const getStatus = (itemStatus) => {
        if (!itemStatus) return '-'
        const subStatusItem = gradeStatus.find(el => el.value === itemStatus.toString());
        const subStatusLabel = subStatusItem && subStatusItem.label;

        const {color} = gradeStatus.find(elem => elem.label === subStatusLabel) || {};

        return subStatusLabel && (<span
            title={t(subStatusLabel)}
            className={`main-table__body-status status-${color}`}>
                {t(subStatusLabel)}
            </span>) || <Span>-</Span>
    }

    return data && data.length > 0 ? (
        <tr className="project-expanded-block__table project-product-expanded-block__table">
            <td className="project-expanded-block__table-row">
                <div className="project-expanded-block__table-col labels">
                <p className="project-expanded-block__table-material-item plug"></p>
                <p className="project-expanded-block__table-title">Material Number</p>
                <p className="project-expanded-block__table-title">Selling Plant</p>
                <p className="project-expanded-block__table-title">Product Status <Tooltip>
                    {parse(`
                        <span class="long-tooltip"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</span>
                        <span class="long-tooltip"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</span>
                        <span class="long-tooltip"><span class="font-medium">Production Intent</span> - Product in process for being approved.</span>
                        <span class="long-tooltip"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</span>
                        <span class="long-tooltip"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</span>
                        <span class="long-tooltip"><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</span>
                    `)}
                </Tooltip></p>
                <p className="project-expanded-block__table-title">Product Code</p>
                <p className="project-expanded-block__table-title">Line</p>
                <p className="project-expanded-block__table-title">Invoices</p>
                <p className="project-expanded-block__table-title">Connections</p>
                <p className="project-expanded-block__table-title">SAP Application Code</p>
                <p className="project-expanded-block__table-title">Price {(weightUnitName(weightUnit.weightUnit))}</p>
                <p className="project-expanded-block__table-title">Rebate ({weightUnitName(weightUnit.weightUnit)})</p>
                <p className="project-expanded-block__table-title">Final Price
                    ({weightUnitName(weightUnit.weightUnit)})</p>
                <p className="project-expanded-block__table-title">Product (%)</p>
            </div>
                {data.map((item, i) => (
                    <div className="project-expanded-block__table-col" key={`project-product-${i}`}>
                        <p className="project-expanded-block__table-material-item"><span
                            className="project-expanded-block__table-value-text"
                            title={item.grade.value}>{item.grade.value}</span></p>
                        {item.materialNumber.link ?
                            <a href={`${item.materialNumber.link}`} target="_blank" rel="noopener noreferrer"
                               className="project-expanded-block__table-value"
                               title={item.materialNumber.value}>{item.materialNumber.value}</a> :
                            <p className="project-expanded-block__table-value"><span
                                className="project-expanded-block__table-value-text"
                                title={item.materialNumber.value}>{item.materialNumber.value}</span></p>}
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.sellingPlant.value}>{item.sellingPlant.value}</span></p>
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.productStatus.value}>{item.productStatus.value}</span></p>
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.productCode.value}>{item.productCode.value}</span></p>
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.line.value}>{item.line.value}</span></p>
                        <a href={`${item.invoices.link}`} target="_blank" rel="noopener noreferrer"
                           className="project-expanded-block__table-value"
                           title={item.invoices.value}>{item.invoices.value}</a>
                        <a href={`${item.connection.link}`} target="_blank" rel="noopener noreferrer"
                           className="project-expanded-block__table-value"
                           title={item.connection.value}>{item.connection.value}</a>
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.sapApplicationCode.value}>{item.sapApplicationCode.value}</span></p>
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.actualSalePrice.value}>{item.actualSalePrice.value}</span></p>
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.rebate.value}>{item.rebate.value}</span></p>
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.finalPrice.value}>{item.finalPrice.value}</span></p>
                        <p className="project-expanded-block__table-value"><span
                            className="project-expanded-block__table-value-text"
                            title={item.productPercentage.value}>{item.productPercentage.value}</span></p>
                    </div>
                ))}
            </td>
        </tr>
    ) : null;
};

TableBodyColumn.propTypes = propTypes;
TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        items: getStoreItem(state, itemsName),
        financialYear: getStoreItem(state, "financialYear"),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
