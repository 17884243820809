import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Title, WrapButtons, Total } from "Templates/Titles";
import TabViewIndex from "Templates/Tabs/DefaultWrap/TabViewIndex";
import { MainButtonLink, TabWrap } from "Templates/Content";

const propTypes = {
    title: PropTypes.number.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const TabView = ({ onClickEdit, onClickDelete, t, title }) => {
    return (
        <>
            <Title
                title={t("Performance")}
                total={() => <Total>{`${title} ${t("Total")}`}</Total>}
            >
                <WrapButtons>
                    <MainButtonLink href="/project/performance/create" className="main-btn_primary">
                        {t("Add New Performance")}
                    </MainButtonLink>
                </WrapButtons>
            </Title>
            <TabWrap>
                <TabViewIndex onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        title: state.listState.pagination.total,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TabView);
