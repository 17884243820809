import React from "react";
import _ from "lodash";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import { formatDate } from "Services";
import { taskStatus } from "Services/enum";
import { requestGet } from "./RequestService";

const tableLabel = {
    title: "Title",
    due: "Due",
    sub_status: "Status",
};

const getViewItem = (key, item) => {
    switch (key) {
        case "title":
            return (
                <TableRowItemLink title={_.get(item, key)} href={`/tasks/${_.get(item, "id")}`}>
                    {_.get(item, key)}
                </TableRowItemLink>
            );

        case "due":
            const value = _.get(item, key);
            return <TableRowItemSpan title={formatDate(value)}>{formatDate(value)}</TableRowItemSpan>;

        case "sub_status":
            const status = taskStatus.find(elem => elem.value === _.get(item, key)) || {};
            return <TableRowItemStatus status={status.label} list={taskStatus} />;

        default:
            return <TableRowItemSpan title={_.get(item, key)}>{_.get(item, key)}</TableRowItemSpan>;
    }
};

const eventFrame = () => {
    let ticking = false;

    return new Promise(resolve => {
        if (!ticking) {
            window.requestAnimationFrame(() => {
                resolve();

                ticking = false;
            });

            ticking = true;
        }
    });
};

const handleScroll = e => {
    const element = e.currentTarget;
    return new Promise(resolve => {
        eventFrame().then(() => {
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                return;
            }

            if (element.scrollHeight - element.scrollTop <= element.clientHeight * 1.3) {
                resolve();
            }
        });
    });
};

export default () => ({
    requestTable: requestGet("/dashboard/table/tasks"),
    tableLabel,
    getViewItem,
    handleScroll,
});
