import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row, Column } from "Templates/Table/NewVersion";
import { Span } from "Templates/Default";

const propTypes = {
    item: PropTypes.shape({ [PropTypes.string]: PropTypes.string }),
    service: PropTypes.shape({
        getFormatValue: PropTypes.func.isRequired,
        columns: PropTypes.shape({ [PropTypes.string]: PropTypes.string }).isRequired,
    }).isRequired,
};

const defaultProps = {
    item: {},
};

const TableBodyColumn = ({ item, service: { columns, getFormatValue }, t }) => {
    return (
        <>
            {Object.keys(columns).map(column => (
                <Fragment key={`Fragment-${column}`}>
                    <Row>
                        <Column className="gray-text">{t(columns[column])}</Column>
                        <Column>
                            <Span>{getFormatValue(item, column)}</Span>
                        </Column>
                    </Row>
                </Fragment>
            ))}
        </>
    );
};

TableBodyColumn.propTypes = propTypes;
TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        item: getStoreItem(state, "items"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
