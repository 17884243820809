import React from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";
import UnitDetailTableBodyColumn from "./Table/TableBodyColumn";

import * as ProductService from "../../../../../Services/Tabs/General/Table/ProductTableService";
import ExpandedBlock from "../../../../Templates/ExpandedBlock";

const propTypes = {};

const ProductTable = CustomSimpleTable()(() => null)()(UnitDetailTableBodyColumn)()(() => null);

const ProductBlock = ({ t, block, expanded, setExpandedAction }) => {
    return (
        <div className="project-expanded-block">
            <ExpandedBlock title={t("Product")} block={block} service={ProductService} isExpanded={expanded} setExpandedAction={setExpandedAction}>
                <ProductTable />
            </ExpandedBlock>
        </div>
    );
};

ProductBlock.propTypes = propTypes;

export default compose(withTagDefaultProps())(ProductBlock);
