import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import {url} from "Services";
import Tab from "Templates/Tabs/Tab";
import InfoWrap from "./Views/InfoWrap";

import _technicalGradesService from "./Services/Tabs/Technical";

import Technical from "./Views/Tabs/Technical";
import Notes from "./Views/Tabs/Notes";
import _notesService from "./Services/Tabs/Notes";
import TabCheck from "Templates/Tabs/TabСheck";

const Solution = ({
                         id,
                         activeTab,
                         t,
                         onChangeTab,
                         activeSubTab,
                         permissions,
                         totalTechnicals,
                         totalNotes
                     }) => {
    const onClickEdit = () => {
        url.redirect(`/admin/solutions/${id}/edit`);
    };
    
    const technicalService = _technicalGradesService(id, activeSubTab.id);
    const notesService = _notesService(id);

    const onChangeSolutionTab = (index) => {
        url.remove('subTab');
        url.replaceHistory(index ? {'activeTab': index } : {});
        onChangeTab(index);
    }

    return (
        <ContentWrap>
            <InfoWrap activeTab={activeTab} onClickEdit={onClickEdit} />
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index)=> {
                        if (activeTab !== index) {
                            onChangeSolutionTab(index);
                        }
                    }}
                >
                    <Tab label={t("Category")} counter={totalTechnicals}>
                        <ServiceProvider value={technicalService}>
                            <Technical permissions={permissions} />
                        </ServiceProvider>
                    </Tab>

                    <TabCheck
                        label={t('Notes')}
                        permission="product_solutions_notes_view"
                        counter={totalNotes}
                    >
                        <ServiceProvider value={notesService}>
                            <Notes/>
                        </ServiceProvider>
                    </TabCheck>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Solution.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    activeSubTab: PropTypes.shape({id: null}),
    totalTechnicals: PropTypes.number.isRequired,
    totalNotes: PropTypes.number.isRequired,
};

Solution.defaultProps = {
    activeSubTab: {}
}

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
        activeSubTab: getStoreItem(state, "activeSubTab") || {},
        totalTechnicals: getStoreItem(state, "totalTechnicals"),
        totalNotes: getStoreItem(state, "totalNotes"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Solution);
