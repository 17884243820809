import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Block, BlockShadow, BlockWrap} from "Templates/Form";
import BlockTitle from "Templates/Form/BlockTitle";
import {ComoppsEditor} from "Templates/Input";

const propTypes = {
    description: PropTypes.string.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired
};

class DescriptionBlock extends Component {
    timer;
    state = {
        isSaveSource: false,
    };

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        const {
            description,
            onChange,
            t
        } = this.props;

        const onSaveSource = () => {
            this.setState({isSaveSource: true})
        }

        return (
            <>
                <BlockTitle>
                    {t("Project Description")}
                </BlockTitle>
                <Block>
                    <BlockWrap>
                        <BlockShadow>
                            <ComoppsEditor
                                text={description}
                                onChange={value => onChange("generalBlock.description", value)}
                                onSaveSource={onSaveSource}
                            />
                        </BlockShadow>
                    </BlockWrap>
                </Block>
            </>
        );
    }
}

DescriptionBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        description: getStoreItem(state, "generalBlock.description"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(DescriptionBlock);
