import { combineReducers } from "redux";
import { getMainInitialStates, getMainReducers } from "App/Services";
import { reducer as MainReducer, initState } from "./MainReducer";

const reducers = [MainReducer];

const mainState = {
    ...initState,
};

const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }

    return state;
};

// Combine all reducers you may have here
export default combineReducers({
    ...getMainReducers,
    listState: reducer,
});

export const initialStates = {
    ...getMainInitialStates,
    mainState,
};
