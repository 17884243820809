import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import _ from "lodash";
import reducers, { initialStates } from "../Reducers";
import { initState } from "../../../../Company/View/Reducers/ViewReducer";

export default function configureStore(props) {
    const {
        items,
        total_analytics,
        pagination,
        columns,
        view,
        financialYear,
        isProductFinderPermission,
        totalNotes,
        totalFiles,
        totalProjects,
        totalInvoices,
        totalTechnicals,
        totalProcesses,
        totalDesigns,
        totalSafeties,
        totalBrochures,
    } = props;

    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: isProductFinderPermission ? "grade-view" : "old-grade-view" }),
        listState: {
            ...mainState,
            items,
            total_analytics,
            pagination,
            columns,
            view,
            financialYear,
            sort: { column: columns[0], direction: "asc" },
            activeSubTab: items[0],
            totalNotes,
            totalFiles,
            totalProjects,
            totalInvoices,
            totalTechnicals,
            totalProcesses,
            totalDesigns,
            totalSafeties,
            totalBrochures,
        },
    };

    if (initState.activeTab) {
        _.unset(initialState.listState, "items");
    }

    return _configureStore(initialState, reducers);
}
