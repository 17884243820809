import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {
    subtitle: "",
};

const propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

const HeadDashboardCard = ({ pref, title, subtitle }) => {
    return (
        <div className={`${pref}__card-head-info`}>
            {subtitle && <div className="gray-text">{subtitle}</div>}
            <span className="bold-text">{title}</span>
        </div>
    );
};

HeadDashboardCard.defaultProps = defaultProps;
HeadDashboardCard.propTypes = propTypes;

export default compose(withTagDefaultProps())(HeadDashboardCard);
