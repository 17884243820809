import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import _ from "lodash";
import reducers, {initialStates} from "../Reducers";
import {filter} from "Services";

export default function configureStore(props) {
    const { items, pinned_columns, total_analytics, pagination, columns, help, financialYear } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "budgets" }),
        listState: {
            ...mainState,
            items,
            pinned_columns,
            total_analytics,
            pagination,
            columns,
            help,
            filter: filter.getParsedFilters(),
            sort: filter.getSort(),
            financialYear
        },
    };

    if (initialState.listState.activeTab) {
        _.unset(initialState.listState, "items");
    }

    return _configureStore(initialState, reducers);
}
