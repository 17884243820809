import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {formatDate} from "Services";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoLogo,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
    InfoSocialWrap,
    ContentInfoHeaderWrap
} from "Templates/Content";
import SocialLinkIcon from "Templates/Social/LinkIcon";
import {checkProtocol} from "Services/Url";
import _ from "lodash";
import {Tooltip} from "Templates/Form";
import { companyPhoneTypes } from "Services/enum";

const defaultProps = {
    physical: PropTypes.shape({
        address1: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        district: "",
        timeZone: "",
    }),
};

const propTypes = {
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    companyCode: PropTypes.string.isRequired,
    dunsNumber: PropTypes.string,
    customerCode: PropTypes.string.isRequired,
    corporateGroup: PropTypes.string.isRequired,
    changedDate: PropTypes.string.isRequired,
    createdCodeDate: PropTypes.string.isRequired,
    createdSystemDate: PropTypes.string.isRequired,
    canEdit: PropTypes.number.isRequired,
    markedDeletion: PropTypes.number.isRequired,
    physical: PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        country: PropTypes.string,
        district: PropTypes.string,
        timeZone: PropTypes.string,
    }),
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    link: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    instagram: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    twitter: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    facebook: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    nicknames: PropTypes.arrayOf(PropTypes.string).isRequired,
    phones: PropTypes.arrayOf(PropTypes.any).isRequired
};

const InfoWrap = ({
                      logo,
                      name,
                      email,
                      phones,
                      url,
                      userName,
                      canEdit,
                      markedDeletion,
                      companyCode,
                      customerCode,
                      corporateGroup,
                      changedDate,
                      createdCodeDate,
                      createdSystemDate,
                      physical,
                      dunsNumber,
                      t,
                      onClickEdit,
                      onClickDelete,
                      link,
                      instagram,
                      twitter,
                      facebook,
                      nicknames
                  }) => {
    const addressExists = (address) => {
        return !!(address.address1 + address.city + address.state + address.zip + address.country).trim();
    }

    const sapInfo = (
        <div className="companies-content__info-content-block">
            <ContentInfoBlock show={!!customerCode.trim()}>
                <ContentInfoBlockTitle>{t("SAP Customer Code")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={customerCode} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!corporateGroup.trim()}>
                <ContentInfoBlockTitle>{t("Corporate Group")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={corporateGroup} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!changedDate.trim()}>
                <ContentInfoBlockTitle>
                    {t("Date Changed")}
                    <Tooltip>
                        {t(
                            "The date data changed in the Customer SAP record.",
                        )}
                    </Tooltip>
                </ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(changedDate)} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!createdCodeDate.trim()}>
                <ContentInfoBlockTitle>
                    {t("Date Created/Extended")}
                    <Tooltip>
                        {t(
                            "Date Created = Date record was created in SAP. Date Extended = Date record was extended from original business creator to another legal entity (Company) in SAP.",
                        )}
                    </Tooltip>
                </ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(createdCodeDate)} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!createdSystemDate.trim()}>
                <ContentInfoBlockTitle>
                    {t("Date Created")}
                    <Tooltip>
                        {t(
                            "Date record was created in SAP.",
                        )}
                    </Tooltip>
                </ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(createdSystemDate)} />
            </ContentInfoBlock>

            <ContentInfoBlock>
                <ContentInfoBlockTitle>{t("Deletion Marker")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={markedDeletion ? 'YES' : 'NO'} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!physical.timeZone.trim()}>
                <ContentInfoBlockTitle>{t("Time Zone")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={physical.timeZone} />
            </ContentInfoBlock>
        </div>
    );

    return (
        <ContentInfoWrap>
            <ContentInfoHeaderWrap>
                <ContentInfoTop
                    onClickEdit={canEdit ? onClickEdit : null}
                    onClickDelete={canEdit ? onClickDelete : null}
                    urlBack="/companies"
                />
                <ContentInfoLogo url={logo} />
                <ContentInfoTitle>{name}</ContentInfoTitle>
                <InfoSocialWrap>
                    {instagram && <SocialLinkIcon type="instagram" url={`https://instagram.com/${instagram}`} />}
                    {facebook && <SocialLinkIcon type="facebook" url={`https://facebook.com/${facebook}`} />}
                    {link && <SocialLinkIcon type="linkedin" url={checkProtocol(link)} />}
                    {twitter && <SocialLinkIcon type="twitter" url={`https://twitter.com/${twitter}`} />}
                </InfoSocialWrap>
            </ContentInfoHeaderWrap>
            <div className="companies-content__info-content-block">
                <ContentInfoBlock show={!!nicknames.length}>
                    <ContentInfoBlockTitle>{t("Nicknames")}</ContentInfoBlockTitle>
                    {nicknames.map((elem, index) => (
                        <ContentInfoBlockText key={index} value={elem} />
                    ))}
                </ContentInfoBlock>

                <ContentInfoBlock show={addressExists(physical)}>
                    <ContentInfoBlockTitle>{t("Address")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={physical.address1} />
                    <ContentInfoBlockText
                        value={`${physical.city ? `${physical.city},` : ""} ${physical.state} ${physical.zip}`}
                    />
                    <ContentInfoBlockText value={physical.country} />
                </ContentInfoBlock>

                <ContentInfoBlock show={!!companyCode.trim() || !!userName.trim()}>
                    <ContentInfoBlockTitle>{t("Owner")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={companyCode.trim() ? `SAP - ${companyCode}` : userName} />
                </ContentInfoBlock>

                <ContentInfoBlock show={!!dunsNumber.trim()}>
                    <ContentInfoBlockTitle>
                        {t("D-U-N-S® Number")}
                        <Tooltip>
                            {t(
                                "D-U-N-S® is a registered trademark D&B",
                            )}
                        </Tooltip>
                    </ContentInfoBlockTitle>
                    <ContentInfoBlockText value={dunsNumber} />
                </ContentInfoBlock>

                <ContentInfoBlock show={!!phones.length}>
                    <ContentInfoBlockTitle>{t("Phone")}</ContentInfoBlockTitle>
                    {phones.map((phone, index) => (
                        <ContentInfoBlockText
                            type="phone"
                            value={`${_.find(companyPhoneTypes, {value:`${phone.type}`}).label}: ${phone.number}`}
                            key={`phone_${index}`}
                        />
                    ))}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!email.trim()}>
                    <ContentInfoBlockTitle>{t("Email")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText type="email" value={email} />
                </ContentInfoBlock>

                <ContentInfoBlock show={!!url.trim()}>
                    <ContentInfoBlockTitle>{t("Website")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText type="link" value={url} />
                </ContentInfoBlock>
            </div>
            {customerCode ? sapInfo : null}
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;
InfoWrap.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    const socials = getStoreItem(state, "view.socials") || [];
    return {
        logo: getStoreItem(state, "view.logo"),
        name: getStoreItem(state, "view.name"),
        email: getStoreItem(state, "view.email"),
        phones: getStoreItem(state, "view.phones"),
        url: getStoreItem(state, "view.url"),
        canEdit: getStoreItem(state, "view.canEdit"),
        physical: getStoreItem(state, "view.physical"),
        userName: getStoreItem(state, "view.userName"),
        companyCode: getStoreItem(state, "view.companyCode"),
        dunsNumber: getStoreItem(state, "view.dunsNumber"),
        corporateGroup: getStoreItem(state, "view.corporateGroup"),
        customerCode: getStoreItem(state, "view.customerCode"),
        changedDate: getStoreItem(state, "view.changedDate"),
        createdCodeDate: getStoreItem(state, "view.createdCodeDate"),
        createdSystemDate: getStoreItem(state, "view.createdSystemDate"),
        markedDeletion: getStoreItem(state, "view.markedDeletion"),
        link: _.get(_.head(socials.filter(item => item.type === 10)), "value", false),
        instagram: _.get(_.head(socials.filter(item => item.type === 9)), "value", false),
        twitter: _.get(_.head(socials.filter(item => item.type === 8)), "value", false),
        facebook: _.get(_.head(socials.filter(item => item.type === 7)), "value", false),
        nicknames: getStoreItem(state, "view.nicknames"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
