import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {formatDate} from "Services";
import {withServiceConsumer} from "Services/Context";
import {projectStatus} from "Services/enum";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoLogo,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";

const defaultProps = {
    application: "",
    applicationId: "",
};

const propTypes = {
    logo: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customer: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    subStatus: PropTypes.string.isRequired,
    stageGate: PropTypes.string.isRequired,
    application: PropTypes.string,
    applicationId: PropTypes.string,
    losDate: PropTypes.string,
};

const InfoWrap = ({
                      logo,
                      slug,
                      name,
                      customer,
                      customerId,
                      subStatus,
                      stageGate,
                      application,
                      applicationId,
                      losDate,
                      t,
                  }) => {
    return (
        <ContentInfoWrap>
            <ContentInfoTop
                urlBack="/project/projects"
            />

            <ContentInfoBlock>
                <ContentInfoLogo url={logo} />
                <ContentInfoTitle>{name}</ContentInfoTitle>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!slug}>
                <ContentInfoBlockTitle>{t("Project ID")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={slug} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!subStatus}>
                <ContentInfoBlockTitle>{t("Project Status")}</ContentInfoBlockTitle>
                <TableRowItemStatus status={t(subStatus)} list={[...projectStatus]} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!losDate}>
                <ContentInfoBlockTitle>{t("Loss of Sales Date")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(losDate)} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!stageGate}>
                <ContentInfoBlockTitle>{t("Salesforce Stage Gates")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={stageGate} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!customer}>
                <ContentInfoBlockTitle>{t("Customer")}</ContentInfoBlockTitle>
                <ContentInfoBlockText type="link" value={`/companies/${customerId}`} title={t(customer)} permission="companies_view" />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!applicationId}>
                <ContentInfoBlockTitle>{t("Application")}</ContentInfoBlockTitle>
                <ContentInfoBlockText title={t(application)} />
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.defaultProps = defaultProps;
InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        logo: getStoreItem(state, "view.logo"),
        slug: getStoreItem(state, "view.slug"),
        name: getStoreItem(state, "view.name"),
        canEdit: getStoreItem(state, "view.canEdit"),
        customer: getStoreItem(state, "view.customer"),
        customerId: getStoreItem(state, "view.customerId"),
        subStatus: getStoreItem(state, "view.subStatus"),
        stageGate: getStoreItem(state, "view.stageGate"),
        application: getStoreItem(state, "view.application"),
        applicationId: getStoreItem(state, "view.applicationId"),
        losDate: getStoreItem(state, "view.losDate"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
