import Constants from "../Constants";
import {debounce} from "Services/Debounce";
import {fetchItems} from "./TableActions";

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (["type", "effective_date", "expiration_date", "expiration_date", "status"].includes(key)) {
        fetchItems(service, dispatch)(request);
    } else if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};