import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {savePublicationList} from "../Actions/TabActions";

const {Button, Form, Title, Col, Row, ColText, ColInput} = ModalDefault;

const propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    savePublicationList: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const AddPublicationListModalForm = ({
                                         form,
                                         savePublicationList,
                                         onClose,
                                         onChange,
                                         t,
                                         fetchItems,
                                         request,
                                     }) => {

    const formValidate = () => {
        return form.name && form.name.trim() !== '' && form.name.trim() !== form.oldName.trim();
    };

    const handleSave = () => {
        savePublicationList(form).then(
            data => {
                fetchItems(request);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={() => onClose(true)}/>
            <Title>{t(`${form.id ? 'Edit' : 'New'} List`)}</Title>
            <Row>
                <Col>
                    <ColText className="bold-text" isRequired>{t("Name")}</ColText>
                    <ColInput
                        placeholder={t("Enter Name")}
                        name="name"
                        value={form.name || ''}
                        onChange={value => onChangeInput(value, `addPublicationListForm.name`)}
                    />
                </Col>
            </Row>
            <Button onClick={handleSave} disabled={!formValidate()}>
                {t(`${form.id ? 'Save' : ' Add'} List `)}
            </Button>
        </Form>
    );
}

AddPublicationListModalForm.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        savePublicationList: getActionStore("savePublicationList", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddPublicationListModalForm);
