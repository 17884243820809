import _ from "lodash";
import { formatDate } from "Services/index";
import Constants from "../Constants";

export const initialState = {
    id: "",

    title: "",
    project: "",
    assignedTo: "",
    description: "",
    start: formatDate(Date.now(), "YYYY-MM-DD"),
    due: formatDate(Date.now(), "YYYY-MM-DD"),

    form_validate: false,
    form_loading: false,
    form_errors: {},

    old: {},
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM:
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };

        default:
            return state;
    }
};

const validate = state => {
    const title = !!state.title.trim();
    const project = !!state.project.trim();
    const assignedTo = !!state.assignedTo.trim();
    const start = !!state.start.trim();
    const due = !!state.due.trim();
    const description = !!state.description.trim();

    return title && project && assignedTo && start && due && description;
};
