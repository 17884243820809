import React from "react";
import { getString } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { gradeStatus } from "Services/enum";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name": {
            return (
                <TableRowItemLink
                    title={getString(item, key)}
                    href={`/product/product-finder/${getString(item, "grade_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );
        }

        case "sub_status": {
            const subItem = gradeStatus.find(el => el.value.toString() === getString(item, key).toString());
            const subItemLabel = subItem && subItem.label;

            return subItemLabel && <TableRowItemStatus status={subItemLabel} list={gradeStatus}/>
                || <TableRowItemSpan>-</TableRowItemSpan>;
        }

        case "sap_plant":
        case "sap_line": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={index} title={elem.name}>{elem.name}</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        case "color": {
            const arrDate = getString(item, 'product_colors') !== '-' ? JSON.parse(getString(item, 'product_colors')) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={index} title={elem.color}>{elem.color}</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={'-'}>-</TableRowItemSpan>
            );
        }

        case "approval_name":
        case "source_name":
        case "code_name": {
            const arrDate = getString(item, 'product_colors') !== '-' ? JSON.parse(getString(item, 'product_colors')) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((data, indexParent) => (
                                data.items && data.items.length > 0
                                    ? data.items.map((elem, indexChild) => (
                                        <TableRowItemSpan key={`${indexParent}_${indexChild}`}
                                                          title={getString(elem, key)}>{getString(elem, key)}</TableRowItemSpan>
                                    ))
                                    : <TableRowItemSpan key={indexParent} title={'-'}>-</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={'-'}>-</TableRowItemSpan>
            );
        }

        case "count_projects": {
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "grade_id")}?activeTab=5`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );
        }

        case "count_invoices":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "grade_id")}?activeTab=10`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
