import "./Company/Index/registration";
import "./Company/Change/Pages/Create/registration";
import "./Company/Change/Pages/Edit/registration";
import "./Company/View/registration";

import "./Contact/Index/registration";
import "./Contact/Change/Pages/Create/registration";
import "./Contact/Change/Pages/Edit/registration";
import "./Contact/View/registration";

import "./Task/Index/registration";
import "./Task/Change/Pages/Create/registration";
import "./Task/Change/Pages/Edit/registration";
import "./Task/View/registration";

import "./File/Index/registration";
import "./File/View/registration";

import "./Admin/Company/Index/registration";
import "./Admin/Company/Change/Pages/Create/registration";
import "./Admin/Company/Change/Pages/Edit/registration";
import "./Admin/Company/View/registration";

import "./Admin/Lists/Index/registration";
import "./Admin/Archive/Index/registration";

import "./Admin/Lists/ApplicationCode/Change/Pages/Create/registration";
import "./Admin/Lists/ApplicationCode/Change/Pages/Edit/registration";
import "./Admin/Lists/ApplicationCode/View/registration";

import "./Admin/Lists/Safety/View/registration";
import "./Admin/Lists/Brochure/View/registration";

import "./Admin/Lists/ApplicationCode/SubSegment/Change/Pages/Create/registration";
import "./Admin/Lists/ApplicationCode/SubSegment/Change/Pages/Edit/registration";

import "./Admin/Lists/ProductSource/Change/Pages/Create/registration";
import "./Admin/Lists/ProductSource/Change/Pages/Edit/registration";
import "./Admin/Lists/ProductSource/View/registration";

import "./Admin/PropertyHierarchy/Index/Pages/Create/registration";

import "./Admin/SolutionPropertyHierarchy/Index/Pages/Create/registration";

import "./Admin/MaterialProperty/Index/registration";

import "./Admin/SolutionMaterialProperty/Index/registration";

import "./Admin/ProcessHierarchy/Index/Pages/Create/registration";

import "./Admin/ProcessProperty/Index/registration";

import "./Admin/IHS/Index/registration";
import "./Admin/IHS/View/registration";

import "./Admin/Alert/Index/registration";

import "./Admin/User/ProductHistory/registration";
import "./Admin/User/Index/registration";
import "./Admin/User/Change/Pages/Create/registration";
import "./Admin/User/Change/Pages/Edit/registration";
import "./Admin/User/Profile/registration";
import "./Admin/User/View/registration";

import "./Admin/Publication/Index/registration";
import "./Admin/Publication/Change/Pages/Create/registration";
import "./Admin/Publication/Change/Pages/Edit/registration";
import "./Admin/Publication/View/registration";
import "./Admin/Publication/Datasheet/Change/Pages/Create/registration";
import "./Admin/Publication/Datasheet/Change/Pages/Edit/registration";
import "./Admin/Publication/Process/Change/Pages/Create/registration";
import "./Admin/Publication/Process/Change/Pages/Edit/registration";
import "./Admin/Publication/Design/Change/Pages/Create/registration";
import "./Admin/Publication/Design/Change/Pages/Edit/registration";
import "./Admin/Publication/Brochure/Change/Pages/Create/registration";
import "./Admin/Publication/Brochure/Change/Pages/Edit/registration";
import "./Admin/Publication/Safety/Change/Pages/Create/registration";
import "./Admin/Publication/Safety/Change/Pages/Edit/registration";

import "./Admin/Solution/Index/registration";
import "./Admin/Solution/Change/Pages/Create/registration";
import "./Admin/Solution/Change/Pages/Edit/registration";
import "./Admin/Solution/View/registration";
import "./Admin/Solution/Datasheet/Change/Pages/Create/registration";
import "./Admin/Solution/Datasheet/Change/Pages/Edit/registration";

import "./Admin/HelpPage/Index/registration";
import "./Admin/HelpPage/View/registration";
import "./Admin/HelpPage/Change/Pages/Edit/registration";

import "./Admin/System/View/registration";

import "./Admin/System/Index/registration";

import "./Auth/Login/registration";
import "./Auth/RegistrationGuest/registration";
import "./Auth/Forgot/registration";
import "./Auth/Reset/registration";
import "./Auth/ChangePassword/registration";

import "./Product/Raw/Index/registration";
import "./Product/Raw/Change/Pages/Create/registration";
import "./Product/Raw/Change/Pages/Edit/registration";
import "./Product/Raw/View/registration";

import "./Product/Plants/Index/registration";
import "./Product/Plants/Change/Pages/Create/registration";
import "./Product/Plants/Change/Pages/Edit/registration";
import "./Product/Plants/View/registration";
import "./Product/Plants/Line/Change/Pages/Create/registration";
import "./Product/Plants/Line/Change/Pages/Edit/registration";

import "./Product/Products/Index/registration";
import "./Product/Products/View/registration";

import "./Project/Application/Index/registration";
import "./Project/Application/Change/Pages/Create/registration";
import "./Project/Application/Change/Pages/Edit/registration";

import "./Project/Material/Index/registration";
import "./Project/Material/Change/Pages/Create/registration";
import "./Project/Material/Change/Pages/Edit/registration";

import "./Project/Performance/Index/registration";
import "./Project/Performance/Change/Pages/Create/registration";
import "./Project/Performance/Change/Pages/Edit/registration";

import "./Invoice/Invoice/Index/registration";
import "./Invoice/Invoice/View/registration";

import "./Product/Grade/Index/registration";
import "./Product/Grade/Shared/registration";
import "./Product/Grade/Sap/registration";
import "./Product/Grade/CompareProducts/registration";
import "./Product/Grade/Approvals/registration";
import "./Product/Grade/My/registration";
import "./Product/Grade/OldIndex/registration";
import "./Product/Grade/Change/Pages/Create/registration";
import "./Product/Grade/Change/Pages/Edit/registration";
import "./Product/Grade/View/registration";

import "./Product/Grade/Publications/SharedProduct/View/registration";
import "./Product/Grade/Publications/SharedList/View/registration";

import "./Project/Projects/Index/registration";
import "./Project/Projects/Change/Pages/Create/registration";
import "./Project/Projects/Change/Pages/Edit/registration";
import "./Project/Projects/View/registration";

import "./Finance/Finance/Index/registration";

import "./Legal/Index/registration";
import "./Legal/Change/Pages/Create/registration";
import "./Legal/Change/Pages/Edit/registration";
import "./Legal/View/registration";

import "./Project/Vehicle/Index/registration";
import "./Project/Vehicle/View/registration";

import "./Project/MyForecast/Index/registration";
import "./Project/MyForecast/Change/Pages/Create/registration";
import "./Project/MyForecast/Change/Pages/Edit/registration";
import "./Project/MyForecast/View/registration";

import "./Forecast/View/registration";
import "./Pipelines/View/registration";

import "./Dashboard/View/registration";

import "./Search/Index/registration";
import "./Search/View/registration";

import "./Product/Publications/Groups/Change/registration";
import "./Product/Publications/UserHistory/Change/registration";

import "./Connection/Index/registration";
import "./Connection/View/registration";
import "./Connection/Change/Pages/Create/registration";
import "./Connection/Change/Pages/Edit/registration";

import "./Connection/BudgetView/registration";
import "./Connection/Change/Pages/BudgetCreate/registration";
import "./Connection/Change/Pages/BudgetEdit/registration";

import "./Vendor/Index/registration";
import "./Vendor/View/registration";

import "./Video/Index/registration";

import "./Solution/Search/registration";
import "./Solution/Compare/registration";
import "./Solution/View/registration";