import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { formatDate } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    ContentInfoBlockTextWithUserImg,
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoWrap,
    ContentInfoTop,
} from "Templates/Content";

const defaultProps = {
    previous_page: "",
};

const propTypes = {
    defaultForder: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
    modified_by: PropTypes.string.isRequired,
    modified_by_id: PropTypes.string.isRequired,
    modified_by_photo: PropTypes.string.isRequired,
    previous_page: PropTypes.string,
};

const InfoWrap = ({
    defaultForder,
    name,
    modified,
    previous_page,
    modified_by,
    modified_by_id,
    modified_by_photo,
    t,
    onClickEdit,
    canEdit,
}) => {
    return (
        <ContentInfoWrap>
            <ContentInfoTop
                onClickEdit={!defaultForder ? onClickEdit : null}
                urlBack={`/files${previous_page ? `/${previous_page}` : ""}`}
            />
            <ContentInfoTitle>{name}</ContentInfoTitle>

            <ContentInfoBlock show={!!modified}>
                <ContentInfoBlockTitle>{t("Modified")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(modified)} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!modified_by}>
                <ContentInfoBlockTitle>{t("Modified By")}</ContentInfoBlockTitle>
                {canEdit ? <ContentInfoBlockTextWithUserImg
                    href={`/admin/users/${modified_by_id}`}
                    url={modified_by_photo}
                    value={modified_by}
                /> : <ContentInfoBlockText value={modified_by}/>}

            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.defaultProps = defaultProps;
InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        defaultForder: getStoreItem(state, "view.default_folder"),
        name: getStoreItem(state, "view.name"),
        modified: getStoreItem(state, "view.modified"),
        modified_by: getStoreItem(state, "view.modified_by"),
        modified_by_id: getStoreItem(state, "view.modified_by_id"),
        modified_by_photo: getStoreItem(state, "view.modified_by_photo"),
        previous_page: getStoreItem(state, "view.previous_page"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
