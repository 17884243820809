import _ from "lodash";
import {url} from "Services";
import Constants from "../Constants";

export const initState = {
    view: {},
    activeTab: +url.get('activeTab'),
    oldValue: {},
    editValue: {},
    errors: {},
    showModal: false,
    showAddToPublicationsListModal: false,
    addToPublicationsListForm: {},
    form: {},
};

export const reducer = (state, action) => {
    const {data} = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "subTabs");
            return {
                ...state,
                activeTab: data,
                filter: {},
                sort: {},
                activeSubTab: {},
            };

        case Constants.RESET_FORM:
            return {...state, editValue: {}};

        case Constants.SAVE_COPY_SUCCESS:
        case Constants.CLOSE_MODAL:
            return {...state, showModal: false, form: {}};

        case Constants.OPEN_MODAL:
            return {...state, showModal: true};

        case Constants.CHANGE_SELECT: {
            const {form} = state;
            form[data.key] = data.value;

            return {...state, form};
        }

        case Constants.SAVE_COPY_FAILED:
            return {...state, errors: {...data}, showModal: true};

        case Constants.OPEN_ADD_TO_PUBLICATIONS_LIST_MODAL:
            return {...state, showAddToPublicationsListModal: true};

        case Constants.CLOSE_ADD_TO_PUBLICATIONS_LIST_MODAL:
            return {...state, showAddToPublicationsListModal: false, addToPublicationsListForm: {}};

        case Constants.UPDATE_ADD_TO_PUBLICATION_LIST_FORM: {
            const {key, value} = data;
            const {addToPublicationsListForm} = state;
            return {
                ...state, addToPublicationsListForm: {
                    ...addToPublicationsListForm,
                    [key]: value,
                }
            };
        }
        default:
            return state;
    }
};
