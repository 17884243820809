import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";
import {initState} from "../../../Company/View/Reducers/ViewReducer";
import _ from "lodash";

export default function configureStore(props) {
    const { view } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "tasks" }),
        listState: { ...mainState, view },
    };

    if(initState.activeTab){
        _.unset(initialState.listState, "items");
    }
    return _configureStore(initialState, reducers);
}
