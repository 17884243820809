import React from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import {getString} from "Services";
import {formatDate} from "react-day-picker/moment";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <>
                    <TableRowItemSpan key={`TableRowItemSpan-${key}`} title={getString(item, key)}>
                        {getString(item, key)}
                    </TableRowItemSpan>
                </>
            );

        case "email":
            return (
                <TableRowItemLink title={getString(item, key)} href={`mailto:${getString(item, key)}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "first_shared_date":
        case "last_login_date":
            const formatValue = getString(item, key) === '-' ? getString(item, key) : formatDate(getString(item, key), "MMM DD, YYYY");
            return (
                <TableRowItemSpan key={`TableRowItemSpan-${key}`} title={formatValue}>
                    {formatValue}
                </TableRowItemSpan>
            )

        default:
            return <TableRowItemSpan />;
    }
};
