import Constants from "../Constants";

export const sendUnit = (service) => data => {
  const { requestUnit } = service;

  return requestUnit(data);
};

export const getActiveUnit = (service, dispatch) => data => {
  dispatch({ type: Constants.ACTIVE_UNIT, data });
};

export const activeWeightUnit = data => ({
  type: Constants.IS_ACTIVE,
  data
});
