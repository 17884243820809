import React, {useState} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import ShowPermission from "Hoc/Template/ShowPermission";
import TabView from "./Views/TabView";

const propTypes = {
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
        quickEdit: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const Raw = ({ service, fetchItems, request, t }) => {
    const [rowId, setRowId] = useState(null)
    const [invalidQuickEdit, setInvalidQuickEdit] = useState(false)
    const [quickEditData, setQuickEditData] = useState(null)
    const { quickEdit, deleteItem } = service;

    const onClickEdit = item => {
        url.redirect(`/product/raw/${item.id}/edit`);
    };

    const onClickUnlock = item => {
        setRowId(item.id)
    };

    const onClickShow = item => {
        const win = window.open(`/product/raw/${item.id}`, "_blank");
        win.focus();
    };

    const onClickDelete = item => {
        if (window.confirm(t("Do you want to delete the Raw Material?"))) {
            deleteItem(`/product/raw/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickSave = () => {
        quickEdit(quickEditData, `/product/raw/${quickEditData.id}/quick-edit`).then(() => fetchItems(request));
        setRowId(null)
    }
    const applyChange = item => {
        setQuickEditData((prev) => {
            if (item.field === 'effective_from_date' || item.field === 'quantity_per_unit' || item.field === 'unit') {
                prev.periods[item.index][item.field] = item.value;
            }

            if (item.periodIndex > -1) {
                prev.periods[item.periodIndex].scale_quantities[item.index][item.field] = item.value;
            }

            const data = {...prev, [item.field]: item.value};
            delete data.effective_from_date
            delete data.quantity_per_unit
            delete data.unit
            delete data.scale_quantity
            delete data.period_price

            return data
        })
    }

    const setInvalid = item => {
        setInvalidQuickEdit(item)
    }

    return (
      <ShowPermission checkPermission={['plant_raw_view']}>
        <TabView rowId={rowId} invalidQuickEdit={invalidQuickEdit} onClickCustom={onClickShow} onClickUnlock={onClickUnlock} onClickEdit={onClickEdit} onClickDelete={onClickDelete} onClickSave={onClickSave} applyChange={applyChange} setInvalid={setInvalid}/>
      </ShowPermission>
    );
};

Raw.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Raw);
