import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Column, Row} from "Templates/Table/NewVersion";
import {Span} from "Templates/Default";

const propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    select: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    weightUnit: PropTypes.objectOf(PropTypes.number).isRequired,
    service: PropTypes.shape({
        optionsForFirstSelect: PropTypes.func.isRequired,
    }).isRequired,
};

const TableHeadColumn = ({ title, data, select, permissions, weightUnit, service: { optionsForFirstSelect } }) => {
    const selectedValue = optionsForFirstSelect(permissions).find(elem => elem.value === select);

    return (
        <>
            <Row>
                <Column colSpan={1} className="th first">
                    <Span className="bold-text">{title}</Span>
                </Column>
                {Object.keys(data).map(elem => (
                    <Column key={`Column-${elem}`} className="th">
                        <Span>{elem}</Span>
                    </Column>
                ))}
            </Row>
            {selectedValue.showTotalInTableHead && (
                <Row>
                    <Column colSpan={1} className="total-title"><Span className="bold-text">Total</Span></Column>
                    {Object.values(data).map((items, index) => (
                        <Column key={`Column-${index}`} className="total">
                            <Span className="bold-text">
                                {`${selectedValue.viewFormat(items.reduce((acc, elem) => acc + Number(elem.sum), 0), weightUnit.weightUnit)}`}
                            </Span>
                        </Column>
                    ))}
                </Row>
            )}
        </>
    );
};

TableHeadColumn.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        title: getStoreItem(state, "title"),
        data: getStoreItem(state, "data"),
        select: getStoreItem(state, "old.marginListSelect"),
        form: getStoreItem(state, "form"),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(TableHeadColumn);
