import React, {Component} from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import TableHeadColumnWrap from "../../TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import ContainerTable from "./ContainerTable";
import {TabWrap} from "Templates/Content";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

class UsersHistory extends Component {
    render = () => {
        return (
            <TabWrap className="user-history-table__wrap">
                <TabViewDetail />
            </TabWrap>
        )
    };
}

export default compose(
    withServiceConsumer,
)(UsersHistory);
