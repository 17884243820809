import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import PermissionsProps from "Hoc/Template/PermissionsProps";

const defaultProps = {
    permissions: [],
    permissionList: [],
    permission: "",
    defaultValue: "",
};

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string),
    permission: PropTypes.string,
    permissionList: PropTypes.array,
};

const CheckDefaultPermission = ({ permissions, permission, children, permissionList,  defaultValue = ''}) => {
    if (!permission && !permissionList.length) return children;

    return <>{permissions.includes(permission)  || _.intersection(permissions, permissionList).length  ? children : defaultValue}</>;
};

CheckDefaultPermission.defaultProps = defaultProps;
CheckDefaultPermission.propTypes = propTypes;

export default compose(
    PermissionsProps,
)(CheckDefaultPermission);
