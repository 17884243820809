import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";

const { Button, Form, Title, Col, Row, ColText, ColInput } = ModalDefault;

const propTypes = {
    filterForm: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    saveFilter: PropTypes.func.isRequired,
    type: PropTypes.number.isRequired,
};

const FilterModalForm = (
    {
        filterForm,
        filter,
        sort,
        type,
        saveFilter,
        onClose,
        onChange,
        t,
    }
) => {
    const formValidate = () => {
        return filterForm.name && filterForm.name.trim() !== '';
    };

    const handleSave = () => {
        filterForm.type = type;
        filterForm.data = {filter, sort};

        saveFilter(filterForm);
    };

    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <Title>{t('Save Filter')}</Title>
            <Row>
                <Col>
                    <ColText className="bold-text" isRequired>
                        {t("Filter Name")}
                    </ColText>
                    <ColInput
                        placeholder={t("Enter Filter Name")}
                        name="name"
                        value={filterForm.name || ''}
                        onChange={value => onChangeInput(value, `filterForm.name`)}
                    />
                </Col>
            </Row>
            <Button onClick={handleSave} disabled={!formValidate()}>
                {t("Save Filter")}
            </Button>
        </Form>
    );
}

FilterModalForm.propTypes = propTypes;

const mapStateToProps = () => {
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        saveFilter: getActionStore("saveFilterAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FilterModalForm);
