import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const DivFormRow = ({ pref, children, className }) => (
    <div className={`${pref}__form-row ${className}`}>{children}</div>
);

DivFormRow.propTypes = {
    className: PropTypes.string,
};

DivFormRow.defaultProps = {
    className: "",
};

const Row = ({ pref, children, className }) => <tr className={`${pref}__form-row ${className}`}>{children}</tr>;

Row.propTypes = {
    className: PropTypes.string,
};

Row.defaultProps = {
    className: "",
};

export const DivRow = compose(withTagDefaultProps())(DivFormRow);
export default compose(withTagDefaultProps())(Row);
