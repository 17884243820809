import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { formatDate } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";

import { BlockWrapFlex } from "Templates/Form";

const propTypes = {
    manufacturer: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    sop: PropTypes.string.isRequired,
    eop: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired,
};

const InfoWrap = ({ manufacturer, unit, sop, eop, t, onClickEdit }) => (
    <ContentInfoWrap>
        <ContentInfoTop onClickEdit={onClickEdit} urlBack="/project/forecasts" />
        <ContentInfoTitle>{unit}</ContentInfoTitle>
        {/* <InfoTitleSmall>{title}</InfoTitleSmall> */}

        <ContentInfoBlock />

        <ContentInfoBlock show={!!manufacturer}>
            <ContentInfoBlockTitle>{t("Manufacturer")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={manufacturer} />
        </ContentInfoBlock>
        <BlockWrapFlex>
            <ContentInfoBlock show={!!sop}>
                <ContentInfoBlockTitle>{t("SOP")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(sop)} />
            </ContentInfoBlock>
            <ContentInfoBlock show={!!eop}>
                <ContentInfoBlockTitle>{t("EOP")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(eop)} />
            </ContentInfoBlock>
        </BlockWrapFlex>
    </ContentInfoWrap>
);

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        manufacturer: getStoreItem(state, "view.manufacturer"),
        unit: getStoreItem(state, "view.unit"),
        sop: getStoreItem(state, "view.sop"),
        eop: getStoreItem(state, "view.eop"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
