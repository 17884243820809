import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Companies = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };

    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxHierarchy activeHierarchy={getPermission("companies_view", index)} inputClass='first-hierarchy'>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_companies_view-${index}`}
                        value={getPermission("companies_view", index)}
                        onChange={value => onChangePermission("companies_view", value, index)}
                    >
                        <CheckboxTitle title="View/Edit/Delete - Companies">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Companies Module.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_companies_project_view-${index}`}
                        value={getPermission("companies_project_view", index)}
                        onChange={
                            value => onChangePermission("companies_project_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Access -  Projects Tab">
{/*                            <Tooltip>
                                {t(
                                    "Access user to Projects tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_companies_export-${index}`}
                        value={getPermission("companies_export", index)}
                        onChange={
                            value => onChangePermission("companies_export", value, index)
                        }
                    >
                        <CheckboxTitle title="Export - Companies" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to export table in excel format.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
        </>
    )
}

Companies.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Companies);