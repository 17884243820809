import React from "react";
import _ from "lodash";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const className = {
    instagram: "clr-red",
    facebook: "clr-dark-blue",
    linkedin: "clr-bright-blue",
    twitter: "clr-bright-blue",
};

const propTypes = {
    url: PropTypes.string.isRequired,
    type: PropTypes.oneOf(_.keys(className)).isRequired,
};

const SocialLinkIcon = ({ pref, url, type }) => (
    <a
        href={_.startsWith(url, "http") ? url : `http://${url}`}
        target="_blank"
        rel="noopener noreferrer"
        className={`${pref}__info-social-link main-content__info-social-link ${_.get(
            className,
            type,
            "clr-bright-blue",
        )}`}
    >
        <i className={`la la-${type}`} />
    </a>
);

SocialLinkIcon.propTypes = propTypes;

export default compose(withTagDefaultProps())(SocialLinkIcon);
