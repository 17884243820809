import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Block, BlockMain, BlockShadow, BlockWrap, Input, WrapInput } from "Templates/Form";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    customName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const CustomTds = (props) => {
    const {
        t,
        customName,
        onChange
    } = props;

    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <BlockMain title="Technology Data Sheet" blockClassName="fh">
            <BlockShadow>
                <Block>
                    <BlockWrap>
                        <WrapInput isRequired label={t("Name")} name="customName">
                            <Input
                                placeholder={t("Name")}
                                value={customName}
                                onChange={value => {
                                    onChangeInput(value, 'customName');
                                }}
                            />
                        </WrapInput>
                    </BlockWrap>
                </Block>
            </BlockShadow>
        </BlockMain>
    );
}

CustomTds.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        customName: getStoreItem(state, "customName"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(CustomTds);
