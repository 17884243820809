import React, {Fragment, useEffect, useState} from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { ModalDefault } from "Templates/Modals";
import PropTypes from "prop-types";
import { ButtonClose } from "Templates/Button";
import { ButtonRight, Input, WrapInput } from "Templates/Form";
import { LinkIcon } from "Templates/Link";
import { Button as FormButton } from "Templates/Default";
import BlockSubmit from "Templates/Form/BlockSubmit/BlockSubmit";
import ButtonLeft from "Templates/Form/BlockSubmit/ButtonLeft";
import Block from "Templates/Form/Block";
import { uniqBy } from "lodash";

const { Button, Form, Title } = ModalDefault;

const propTypes = {
    form: PropTypes.arrayOf(PropTypes.any).isRequired,
    closeModal: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    saveItems: PropTypes.func.isRequired,
};

const ContactsModal = ({ t, form, closeModal, updateForm, saveItems, groupId }) => {
    const [selectedOptions, setSelectedOptions] = useState(0);
    const [hideAddMoreButton, setHideAddMoreButton] = useState(false);

    useEffect(() => {
        setHideAddMoreButton(selectedOptions === form.length)
    }, [selectedOptions, form.length])

    const isValidForm = () => {
        return form.every(({ user, role }) => !!user && !!role);
    };

    const onAddItem = () => {
        updateForm([...form, { user: '', role: '' }])
    };

    const onSave = () => {
        saveItems(form);
    };

    const onUpdateItem = (value, index, key) => {
        const array = form.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    [key]: value,
                };
            }
            return item;
        });
        updateForm(uniqBy(array, 'user'));
    };

    const isRoleDisabled = (index, key) => {
        return !form[index][key].length;
    };

    const onRemoveItem = (index) => {
        const array = form.filter((_, itemIndex) => itemIndex !== index);
        updateForm(array);
    };

    return (
        <>
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={closeModal}/>
                <Title>{t('List Contacts')}</Title>
                <Block>
                    <WrapInput label={t('Product Contact')} name="" className="wrap-input--delete">
                        {form.map(({ user }, index) => (
                            <Fragment key={`Product-contact-user-${index}`}>
                                <Input
                                    name={`user-${index}`}
                                    type="uniqueListAsyncSelect"
                                    placeholder={t("Select")}
                                    url={`product/product-finder/publication-groups/search/contacts/${groupId}`}
                                    value={user}
                                    valuesList={form}
                                    handleSelectedOptionsLength={(val) => {
                                        setSelectedOptions(val)
                                    }}
                                    onChange={value => onUpdateItem(value, index, 'user')}
                                />
                            </Fragment>
                        ))}
                        {!hideAddMoreButton ? <FormButton onClick={onAddItem} className="size_small main-btn main-btn_white">
                            {t('Add Contact')}
                        </FormButton> : <div />}
                    </WrapInput>

                    <WrapInput label={t('Contact Role')} name="" className="wrap-input--delete">
                        {form.map(({ role }, index) => (
                            <Fragment key={`Product-user-role-${index}`}>
                                <Input
                                    name={`role-${index}`}
                                    type="asyncSelect"
                                    placeholder={t("Select")}
                                    url="product/product-finder/publication-groups/search/roles"
                                    value={role || ''}
                                    onChange={value => onUpdateItem(value, index, 'role')}
                                    disabled={isRoleDisabled(index, 'user')}
                                />
                                {form.length > 1 ? (
                                    <LinkIcon
                                        className="icon-delete"
                                        icon="la-icon-close"
                                        onClick={() => onRemoveItem(index)}
                                    />
                                ) : null}
                            </Fragment>
                        ))}
                    </WrapInput>
                </Block>

                <BlockSubmit>
                    <ButtonRight/>
                    <ButtonLeft>
                        <Button onClick={onSave} disabled={!isValidForm()}>
                            {t('Save')}
                        </Button>
                    </ButtonLeft>
                </BlockSubmit>
            </Form>
        </>
    )
};

ContactsModal.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        form: getStoreItem(state, "form"),
        groupId: getStoreItem(state, "groupId"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        closeModal: getActionStore("closeModal", service, dispatch),
        updateForm: getActionStore("updateForm", service, dispatch),
        saveItems: getActionStore("saveItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ContactsModal);
