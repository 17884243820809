import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas} from "Services";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {TabBlock, TabBlockCol, TabBlockColTitle, TabBlockColTitleH4, TabWrap, TabWrapTop,} from "Templates/Content";
import {ContainerTable} from "Hoc/Widgets";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const propTypes = {
    pagination: PropTypes.shape({
        total: PropTypes.number.isRequired,
    }).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const Invoices = ({pagination, totalAnalytics, t}) => {
    const onClickShow = item => {
        const win = window.open(`/invoice/invoices/${item.invoice_number}`, "_blank");
        win.focus();
    };

    return (
        <>
            <TabWrapTop className="grade-invoices-wrap">
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                        <TabBlockColTitleH4>
                            {`${numberWithCommas(pagination.total, 0)} ${t("Invoices")}`}
                        </TabBlockColTitleH4>
                    </TabBlockCol>
                    <div className={"invoices-total-analytics"}>
                        {totalAnalytics && totalAnalytics[0] ? <div className="invoices-total-analytics__item">
                            <p>Revenue:</p>
                            <span>${numberWithCommas(totalAnalytics[0].net_amount, 0)}</span>
                        </div> : null}
                        {totalAnalytics && totalAnalytics[0] ? <div className="invoices-total-analytics__item">
                            <p>Volume:</p>
                            <span>{numberWithCommas(totalAnalytics[0].normalized_weight, 0)} (kg)</span>
                        </div> : null}
                    </div>
                </TabBlock>
            </TabWrapTop>
            <TabWrap className="invoices-content">
                <TabViewDetail
                    customIcon={() => 'icon_external_link'}
                    onClickCustom={onClickShow}
                />
            </TabWrap>
        </>
    );
};

Invoices.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        totalAnalytics: getStoreItem(state, "total_analytics"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Invoices);
