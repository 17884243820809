import React, {Fragment} from "react";
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import { getString, formatDate, storageUrl } from "Services";
import TableRowItemFolderLinkImgWrap from "./Table/TableRowItemFolderLinkImgWrap.js";

export const modifierValues = items => items;

const getLabel = type => {
    switch (+type) {
        case 4:
            return "Moldflow";
        case 3:
            return "Moldex3D";
        case 2:
            return "FEA";
        default:
            return "Other";
    }
};

const getVisibility = visibility => {
    switch (+visibility) {
        case 10:
            return "YES";
        case 0:
            return "NO";
    }
};

export const getViewItem = (key, item, onClickItem) => {

    switch (key) {
        case "file_type":
            return (
                <TableRowItemSpan title={getLabel(getString(item, key))}>
                    {getLabel(getString(item, key))}
                </TableRowItemSpan>
            );

        case "visibility":
            return (
                <TableRowItemSpan title={getVisibility(getString(item, key))}>
                    {getVisibility(getString(item, key))}
                </TableRowItemSpan>
            );

        case "note":
            const note = getString(item, key) !== '-' ? getString(item, key) : '';

            return (
                note ? (
                        <Fragment>
                            <TableRowItemTooltip>{note}</TableRowItemTooltip>
                        </Fragment>
                    )
                    : (
                        <TableRowItemSpan title={'-'}>
                            {'-'}
                        </TableRowItemSpan>
                    )
            );

        case "name":
            const propList = {
                Folder: {
                    ico: "icon-folder",
                    href: () => `${item.sufix}`,
                    label: getString(item, key),
                },
                File: {
                    ico: "icon-file",
                    target: "_blank",
                    href: storage_url => storageUrl(getString(item, "path"), storage_url),
                    label: getString(item, key),
                }
            };
            return getString(item, "status") == 10 ? (
                <TableRowItemFolderLinkImgWrap
                    title={getString(item, key)}
                    className="files-img"
                    type={getString(item, "type") == 10 ? 'File' : 'Folder'}
                    settings={propList}
                    onClickItem={onClickItem}
                >
                    {getString(item, key)}
                </TableRowItemFolderLinkImgWrap>) : (getString(item, key));

        case "modified":
            return getString(item, key, '') ? (
                <TableRowItemSpan title={formatDate(getString(item, key))}>
                    {formatDate(getString(item, key))}
                </TableRowItemSpan>)
                : (getString(item, key));

        case "modified_by":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "modified_by_id")}`}
                    img={getString(item, "modified_by_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key, '')}</TableRowItemSpan>;
    }
};
