import {projectStatusList} from "Services/enum";
import Constants from "../../Constants";

export const getData = (service, dispatch) => data => {
    dispatch({ type: Constants.FETCH_ITEM_REQUESTED });

    const { requestData } = service;

    return requestData(data).then(
        res => dispatch({ type: Constants.FETCH_ITEM_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.FETCH_ITEM_FAILED, data: res });
        },
    );
};

export const changeStatusAction = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_STATUS_REQUESTED });

    const { requestOnChangeStatus } = service;

    return requestOnChangeStatus(data).then(
        () =>
            dispatch({
                type: Constants.CHANGE_STATUS_SUCCEEDED,
                data: ([...projectStatusList].find(elem => elem.order === data.sub_status) || {}).label,
            }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.CHANGE_STATUS_FAILED, data: res });
        },
    );
};

export const setExpanded = (service, dispatch) => (value) => {
    if (value === Constants.SET_EXPANDED) {
        dispatch({ type: Constants.SET_EXPANDED });
    }

    if (value === Constants.SET_COLLAPSED) {
        dispatch({ type: Constants.SET_COLLAPSED });
    }
}