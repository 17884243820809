import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const BlockShadow = ({ pref, blockName, children, className }) => (
    <div className={`${pref}__form-block-wrap main-content__form-block-wrap ${blockName || pref}__form-block_shadow ${className}`}>
        {children}
    </div>
);

BlockShadow.propTypes = {
    blockName: PropTypes.string,
    className: PropTypes.string,
};

BlockShadow.defaultProps = {
    blockName: "",
    className: ""
};

export default compose(withTagDefaultProps())(BlockShadow);
