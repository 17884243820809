import Constants from "../Constants";
import {url} from "Services";
import _ from "lodash";

export const initState = {
    errors: {},
    loading: false,
    view: {},
    activeTab: +url.get('activeTab'),
    showModal: false,
    editData: { name: '', code: '' }
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.STATUS_REQUESTED: {
            return { ...state, loading: true };
        }

        case Constants.STATUS_SUCCEEDED: {
            return { ...state, errors: {}, view: { ...state.view, sub_status: data }, loading: false };
        }

        case Constants.STATUS_FAILED: {
            return { ...state, errors: { ...data }, loading: false };
        }

        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data, filter: {}, sort: {} };

        case Constants.SHOW_LOADER: {
            return { ...state, loading: true };
        }

        case Constants.HIDE_LOADER: {
            return { ...state, loading: false };
        }

        case Constants.OPEN_EDIT_MODAL:
            return {...state, showModal: true}

        case Constants.CLOSE_EDIT_MODAL:
            return {...state, showModal: false}

        case Constants.EDIT_VENDOR:
            return {...state, showModal: false, editData: data}

        default:
            return state;
    }
};
