import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import _ from "lodash";
import { numberWithCommas } from "Services";
import { Input } from "Templates/Form";
import { Div, Span } from "Templates/Default";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row, Column } from "Templates/Table/NewVersion";

const TableBodyColumn = ({ item, service: { itemsName, columns }, onChangeVolume }) => {
    const getBodyView = key => {
        switch (key) {
            case "*": {
                return <Span className="text-center">x</Span>;
            }

            case "=": {
                return <Span className="text-center">=</Span>;
            }

            case "partWeight": {
                return (
                    <>
                        <Input
                            className="text-right"
                            name="partWeight"
                            value={_.get(item, key)}
                            placeholder="0"
                            onChange={value => onChangeVolume(`${itemsName}.partWeight`, value)}
                        />
                        <Span className="gray-text">lbs</Span>
                    </>
                );
            }

            case "partsperUnit": {
                return (
                    <Input
                        className="text-right"
                        name="partsperUnit"
                        value={_.get(item, key)}
                        placeholder="0"
                        onChange={value => onChangeVolume(`${itemsName}.partsperUnit`, value)}
                    />
                );
            }

            case "totalVolume":
                return <Div className="text-right">{numberWithCommas(_.get(item, key, ""))}</Div>;

            case "totalUnits":
                return <Div className="text-right">{numberWithCommas(_.get(item, key, ""))}</Div>;

            default:
                return <Div className="text-right">{_.get(item, key, "")}</Div>;
        }
    };
    return (
        <Row>
            {columns.map((column, index) => (
                <Column key={`Column-${index}`}>{getBodyView(column, item, onChangeVolume)}</Column>
            ))}
        </Row>
    );
};

TableBodyColumn.propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        itemsName: PropTypes.string.isRequired,
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    onChangeVolume: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem, itemsName } = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeVolume: getActionStore("onChangeVolumeAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TableBodyColumn);
