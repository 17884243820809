import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: "",
};

const BlockWrap = ({ className, pref, children }) => (
    <div className={`${pref}__form-block-wrap main-content__form-block-wrap ${className ? `${className}` : ""}`}>{children}</div>
);

BlockWrap.propTypes = propTypes;
BlockWrap.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(BlockWrap);
