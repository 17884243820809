import {Tooltip} from "Templates/Form";
import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Sitewide = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };

    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_only_api-${index}`}
                    value={getPermission("only_api", index)}
                    onChange={value => onChangePermission("only_api", value, index)}
                >
                    <CheckboxTitle title="Only API">
{/*                        <Tooltip>
                            {t(
                                "Permission uses for the SAP connection.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={hasAccess()}
                    htmlFor={`checkbox_revenue_gross_operating_view-${index}`}
                    value={getPermission("revenue_gross_operating_view", index)}
                    onChange={
                        value => onChangePermission("revenue_gross_operating_view", value, index)
                    }
                >
                    <CheckboxTitle title="View Revenue, Gross Margin & Operating Margin">
{/*                        <Tooltip>
                            {t(
                                "View all information regarding Revenue, Gross Margin & Operating Margin.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={hasAccess()}
                    htmlFor={`checkbox_volume_view-${index}`}
                    value={getPermission("volume_view", index)}
                    onChange={value => onChangePermission("volume_view", value, index)}
                >
                    <CheckboxTitle title="View Volume">
{/*                        <Tooltip>
                            {t(
                                "View all information regarding Volume.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>
        </>
    )
}

Sitewide.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Sitewide);