import React, {Component} from "react";
import {withTagDefaultProps} from "Hoc/Template";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import Img, {ImgStorage} from "Templates/Img";

class Contact extends Component {
    constructor(props) {
        super(props);

        this.name = props.name;
        this.email = props.email;
        this.role = props.role;
        this.photo = props.photo;
    }

    render() {
        return (<>
                <div className="contact-wrap">
                   <div className="contact-img">
                       {
                           this.photo ?
                               <ImgStorage url={this.photo} className="publication-groups__user-img"/> :
                               <Img img="contact_with_circle" width="22.5" height="22.5"/>
                       }
                   </div>
                   <div className="content">
                       <div className="shared-list-contacts-item-info shared-list-contacts-item-info__name">{this.name}</div>
                       <div className="shared-list-contacts-item-info shared-list-contacts-item-info__role">{this.role}</div>


                       <div className="shared-list-contacts-item-info shared-list-contacts-item-info__email">
                           <a href={`mailto: ${this.email}`} className="email" title={this.email}>{this.email}</a>
                       </div>
                   </div>
               </div>
            </>
        );
    }
}

Contact.propTypes = {};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Contact);