import Constants from "../Constants";

export const initState = {
    activeSubTab: {},
    items: [],
    totalNotes: 0,
    totalFiles: 0,
    totalProjects: 0,
    totalInvoices: 0,
    totalTechnicals: 0,
    totalProcesses: 0,
    totalDesigns: 0,
    totalSafeties: 0,
    totalBrochures: 0,
};

export const reducer = (state, action) => {
    const { type, data } = action;
    const items = data && data[0] ? data[0].items : [];
    const totalNotes = data && data[0] ? data[0].totalNotes : 0;
    const totalFiles = data && data[0] ? data[0].totalFiles : 0;
    const totalProjects = data && data[0] ? data[0].totalProjects : 0;
    const totalInvoices = data && data[0] ? data[0].totalInvoices : 0;
    const totalTechnicals = data && data[0] ? data[0].totalTechnicals : 0;
    const totalProcesses = data && data[0] ? data[0].totalProcesses : 0;
    const totalDesigns = data && data[0] ? data[0].totalDesigns : 0;
    const totalSafeties = data && data[0] ? data[0].totalSafeties : 0;
    const totalBrochures = data && data[0] ? data[0].totalBrochures : 0;

    switch (type) {
        case Constants.CHANGE_ACTIVE_BROCHURE_SUBTAB: {
            return {
                ...state,
                activeSubTab: items,
                view: {
                    ...state.view,
                    datasheet: {
                        ...state.view.datasheet,
                        ...items.brochure_data
                    }
                }
            };
        }

        case Constants.FETCH_BROCHURE_ITEMS_REQUESTED: {
            if (items.length > 0) {
                return {
                    ...state,
                    items,
                    activeSubTab: items[0],
                    view: {
                        ...state.view,
                        datasheet: {
                            ...state.view.datasheet,
                            ...items[0].brochure_data
                        }
                    },
                    totalNotes,
                    totalFiles,
                    totalProjects,
                    totalInvoices,
                    totalTechnicals,
                    totalProcesses,
                    totalDesigns,
                    totalSafeties,
                    totalBrochures,
                };
            }

            return {
                ...state,
                items: [],
                activeSubTab: {},
                view: {
                    ...state.view,
                    datasheet: {
                        contacts: [],
                        colors: [],
                        countries: [],
                        paintable: null,
                        molded: null,
                        is_boundable: null,
                        locations: [],
                        application_codes: [],
                        market_segments: [],
                        segments: [],
                        sub_segments: []
                    }
                },
                totalNotes,
                totalFiles,
                totalProjects,
                totalInvoices,
                totalTechnicals,
                totalProcesses,
                totalDesigns,
                totalSafeties,
                totalBrochures,
            };
        }

        default:
            return state;
    }
};
