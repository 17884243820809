import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { DivRow, DivColumn } from "Templates/Table/NewVersion";

const ColumnTableBodyColumnDefault = ({ items, service: { rows, getBodyView } }) => {
    return (
        <>
            {items.map((item, key) => (
                <DivColumn key={`DivColumn-${key}`}>
                    {rows.map((row, index) => (
                        <DivRow key={`DivRow-${index}`}>{getBodyView(row, item)}</DivRow>
                    ))}
                </DivColumn>
            ))}
        </>
    );
};

ColumnTableBodyColumnDefault.propTypes = {
    items: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
    service: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.string).isRequired,
        getBodyView: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem, itemsName } = ownProps.service;
    return {
        items: getStoreItem(state, itemsName),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ColumnTableBodyColumnDefault);
