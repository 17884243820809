import React from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { getString, diffDate, formatDate } from "Services";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import _ from "lodash";
import parse from 'html-react-parser';

const getLabel = status => {
    switch (+status) {
        case 10:
            return "Active";
        case 5:
            return "Disabled";
    }
};

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "status":
            return (
                <TableRowItemSpan title={getLabel(getString(item, key))}>
                    {getLabel(getString(item, key))}
                </TableRowItemSpan>
            );

        case "user_name":
            return (
                <TableRowItemLinkImg
                    blank
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "user_id")}`}
                    img={getString(item, "user_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "message":
            return (
                <TableRowItemSpan title={parse(getString(item, key))} htmlTitle={true}>
                    {parse(getString(item, key))}
                </TableRowItemSpan>
            );

        case "date":
            const modified = formatDate(getString(item, key));

            return <TableRowItemSpan title={modified}>{modified}</TableRowItemSpan>;

        default:
            if (_.get(item, key) === null) {
                return (
                    <TableRowItemSpan title='-'>-</TableRowItemSpan>
                );
            }

            let dateColor = "";
            const date = formatDate(getString(item, key));

            const dateDiff = diffDate(getString(item, "end_date"), false);

            switch (true) {
                case dateDiff < 0:
                    dateColor = "clr-red";
                    break;
            }

            return (
                <TableRowItemSpan title={formatDate(date)} className={dateColor}>
                    {formatDate(date)}
                </TableRowItemSpan>
            );
    }
};
