const tableColumns = {
    name: "Customer Full Name",
    customer_short_name: "Customer Name",
    customer_code: "Customer Code",
    customer_duns_number: "D-U-N-S Number",
    sap_customer: "SAP Customer",
    project_slug: "Project ID",
    project_name: "Project Name",
    product_code: "Product Code",
    sub_status: "Project Status",
    product_application_code: "SAP Application Code",
    manager: "Sales Manager (Project)",
    connection_manager: "Sales Manager (ZSales)",
    grade: "Product Name",
    connection_slug: "Connection ID",
    connection_note_finance: "B&S Notes",
    grade_sub_status: "Product Status",
    los_date: "Loss of Sales Date",
    sap_grade: "SAP Product",
    material_type: "Material Type",
    material_number: "SAP Material Number",
    plant: "Selling Plant",
    move_to_current: "Month Volume Starts",
    budget_price: "FY 2023 Budget Price",
    budget_volume: "FY 2023 Budget Volume",
    budget_revenue: "FY 2023 Budget Revenue",
    volume: "Volume",
    revenue: "Revenue",
};

export default tableColumns;
