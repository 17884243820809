import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { getString, countryListObj } from "Services";
import {datasheetPaintable} from "Services/enum";
import _ from "lodash";

const propertyName = [
    {value: 'approval', label: "OEM Approval"},

];

export const modifierValues = items => items;

const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
};

const getPropertyName = name => {
    const property = propertyName.find(el => el.value === name);
    const needle = property !== undefined ? property.label : undefined;

    return needle || capitalizeFirstLetter(name);
};

const showData = (elem) => {
    let {values, property} = elem;

    if (property === 'country') {
        values = values.map(item => {
            return _.get(countryListObj, item);
        });
    }

    if (property === 'paintable') {
        values = values.map(item => {
            return datasheetPaintable.find(el => el.value.toString() === item.toString()).label;
        });
    }

    return `${getPropertyName(property)}: ${values.join(', ')}`;
};

export const getViewItem = (key, item) => {
    switch (key) {
        case "project_ids":
            const arrDate = JSON.parse(_.get(item, key, []));

            return (arrDate.map((elem, index) => {
               return elem.id !== '-' ?
                   (
                       <TableRowItemLink
                           key={`TableRowItemLink-${index}`}
                           blank={true}
                           href={`/project/projects/${elem.id}`}
                       >
                            {elem.slug}
                       </TableRowItemLink>
                   ) : '-'
            }));
        case "projects":
            const projects = JSON.parse(_.get(item, 'projects', []));

            return (
                <>
                    {projects.map((elem, index) => (
                        <div key={`div-${index}`}>
                            {
                                elem.project !== '-' ?
                                (<TableRowItemLink key={`TableRowItemLinkProject-${index}`} blank={true} title={elem.project} href={`/project/projects/${elem.project_id}`}>
                                    {elem.project}
                                </TableRowItemLink>) : '-'
                            }
                        </div>
                    ))}
                </>
            );
        case "name":
            const foundBy = _.get(item, 'found_by', []);

            return (
                <>
                    <TableRowItemLink
                        blank={true}
                        title={getString(item, key)}
                        href={`/product/product-finder/${getString(item, "grade_id")}`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>

                    {foundBy.length !== 0 && <TableRowItemSpan className="main-text-bold">Found By:</TableRowItemSpan>}

                    {foundBy.map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={getString(elem, 'property')}>
                            {showData(elem)}
                        </TableRowItemSpan>
                    ))}
                </>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
