import React from "react";
import { compose } from "redux";
import { useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import { withServiceConsumer, ServiceProvider } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

import { ContentWrap, ContentWrapCol } from "Templates/Content";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";

import { activeWeightUnit } from 'App/View/Header/Items/Units/Actions/ConverterUnitsAction';
import TabCheck from "Templates/Tabs/TabСheck";
import _generalService from "../Services/Tabs/General";
import General from "./Tabs/General";

import _projectsService from "../Services/Tabs/Projects";
import Projects from "./Tabs/Projects";

import _vehicleBuildsService from "../Services/Tabs/VehicleBuilds";
import VehicleBuilds from "./Tabs/VehicleBuilds";

const propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const MainBlock = ({ id, activeTab, onChangeTab, totalProjects, t }) => {
    const dispatch = useDispatch();
    const generalService = _generalService(id);
    const projectsService = _projectsService(id);
    const vehicleBuildsService = _vehicleBuildsService(id);

    dispatch(activeWeightUnit([1].includes(activeTab)));

    return (
        <ContentWrap>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("General")}>
                        <ServiceProvider value={generalService}>
                            <General />
                        </ServiceProvider>
                    </Tab>

                    <TabCheck label={t("Projects")} permission="forecast_project_view" counter={totalProjects}>
                        <ServiceProvider value={projectsService}>
                            <Projects />
                        </ServiceProvider>
                    </TabCheck>

                    <Tab label={t("Vehicle Builds")}>
                        <ServiceProvider value={vehicleBuildsService}>
                            <VehicleBuilds />
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

MainBlock.defaultProps = {
    totalProjects: 0
}

MainBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
        totalProjects: getStoreItem(state, "totalProjects"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MainBlock);
