import React from "react";
import _ from "lodash";
import {formatDate} from "Services";

export const columns = {
    delta_notes: "Notes",
};

export const getFormatValue = (item, key) => {
    const value = _.get(item, key, "");

    if(!value) {
        return "";
    }

    switch (key) {
        default:
            return <>
                {
                    value.split("\n").map(val => {
                        val = val.split("::: ");
                        return<>
                            {formatDate(val[0])}:
                            <pre>
                                {val[1]}
                            </pre>
                            <br />
                        </>
                    })
                }
            </>;
    }
};
