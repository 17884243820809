import React from "react";
import {formatDate, getString, numberWithCommas} from "Services";
import {gradeStatus} from "Services/enum";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";

export const title = "Products";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Product Name",
        product_name: "Brand",
        sub_status: "Status",
        material_number: "Material Number",
        legacy_product_number: "Legacy Product Number",
        count_projects: "Project(s)",
        count_invoices: "Invoice(s)",
        plant_name: "Plant",
        line_name: "Production Line",
        owner: "Supplier",
        product_code: "Product Code",
        files_count: "Count Files",
        submitted_by: "Submitted by",
        submitted_date: "Date Submitted",
        classification: "Material Type",
        volume: "Volume",
        revenue: "Revenue",
        gross_margin: "Gross Margin",
        publications: "File Type",
        sap_plant: "SAP Plants",
        sap_line: "SAP Production Lines",
        date_update: "Date Updated",
        sap_value: "SAP Import",
    };
    return getString(tableLabel, key, key);
};

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/product/product-finder/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "address":
            return (
                <TableRowItemSpan title={getString(item, key)} className="max-width-60">
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "revenue":
            return (
                <TableRowItemSpan className="text-right">
                    {`$${numberWithCommas(getString(item, key),)}`}
                </TableRowItemSpan>
            );

        case "volume":
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, key),)} lb`}
                </TableRowItemSpan>
            );

        case "gross_margin":
            return (
                <TableRowItemSpan
                    title={getString(item, key)}
                    className={`${getString(item, key) < 0 ? "clr-red" : ""} text-right`}
                >
                    {`${getString(item, key)}%`}
                </TableRowItemSpan>
            );

        case "date_update":
        case "submitted_date": {
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => {
                        const formatValue = elem === '-' ? elem : formatDate(elem);
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                {formatValue}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case "count_projects":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "id")}?activeTab=5`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "count_invoices":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "id")}?activeTab=10`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "sap_plant":
        case "sap_line": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={index} title={elem.name}>{elem.name}</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        case "sap_value":
            return <TableRowItemSpan>{getString(item, key) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case "sub_status":
            return <TableRowItemStatus status={getString(item, key)} list={gradeStatus} />;

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
