import _ from "lodash";
import { TableActions } from "../../../Actions";
import { getDataAction } from "../../../Actions/Tabs/GeneralActions";

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableActions,
        getDataAction,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
