import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const CheckboxTitle = ({ pref, title, t, children, className }) => (
    <span className={`${pref}__form-checkbox-label-title main-content__checkbox-label-title ${className}`}>
        {t(title)}
        {children}
    </span>
);

CheckboxTitle.propTypes = {
    title: PropTypes.string.isRequired,
};
export default compose(withTagDefaultProps())(CheckboxTitle);
