import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockTitle, Block, BlockShadow, Tooltip } from "Templates/Form";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { CustomSimpleTable } from "Hoc/Widgets/Table/SimpleTableContainer";

import ApplicationTableBodyColumn from "./Table/Application/TableBodyColumn";
import ApplicationTableHeadColumn from "./Table/Application/TableHeadColumn";
import ApplicationTableFooterColumn from "./Table/Application/TableFooterColumn";
import * as _applicationService from "../../Services/Table/ApplicationService";

const ApplicationTable = CustomSimpleTable()(ApplicationTableHeadColumn)()(ApplicationTableBodyColumn)()(ApplicationTableFooterColumn);

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    handleClickOnShowModal: PropTypes.func.isRequired,
    showApplicationModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    submitApplication: PropTypes.func.isRequired,
};

const ApplicationBlock = (
    {
        service: { getStoreItem, getActionStore },
        t,
        handleClickOnShowModal,
        showApplicationModal,
        handleCloseModal,
        submitApplication
    }) => {

    const applicationService = {
        ..._applicationService,
        getStoreItem,
        getActionStore,
        handleClickOnShowModal,
        showApplicationModal,
        handleCloseModal,
        submitApplication,
    };

    return (
        <>
            <BlockTitle className="market--segment__tooltip">
                {t("Market Segment")}
                <Tooltip>
                    {t(`By default: Project Application Code = SAP Application Code. Use this panel to change the default Project Application Code
                    and associated market segment information. The Project Application Code will not be included in the Budget/Sales Finance Forecast report.`)}
                </Tooltip>
            </BlockTitle>
            <Block>
                <BlockShadow>
                    <ServiceProvider value={applicationService}>
                        <ApplicationTable tableClassName="layout-fixed"/>
                    </ServiceProvider>
                </BlockShadow>
            </Block>
        </>
    );
};

ApplicationBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(ApplicationBlock);
