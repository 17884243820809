import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {marginList} from "Services/enum";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Column, Row} from "Templates/Table/NewVersion";
import {Span} from "Templates/Default";
import {generateDatasets} from "Templates/Chart/utils";
import Img from "Templates/Img";

const propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    marginListSelect: PropTypes.string.isRequired,
    weightUnit: PropTypes.objectOf(PropTypes.number).isRequired,
};

const TableBodyColumn = ({ marginListSelect, data, weightUnit }) => {
    const modifiedData = generateDatasets(data);
    const {viewFormat} = marginList.find(elem => elem.value === marginListSelect);
    const [expandedColumns, setExpandedColumns] = useState([])

    const result = modifiedData.map((item) => {
        return {... item, internalItems: item.internalItems.reduce((acc, innerArray) => {
                innerArray.forEach((obj) => {
                    const existingObj = acc.find((internalItem) => internalItem.id === obj.id);

                    if (existingObj) {
                        existingObj.sums.push(Number(obj.sum));
                    } else {
                        acc.push({ id: obj.id, name: obj.name, sums: [Number(obj.sum)] });
                    }
                });

                return acc;
            }, [])}
    })

    return (
        <>
            {result.map(item => (
                <Fragment key={`Row-${item.id}`}>
                    <Row>
                        <Column colSpan={1} className={`graphv2__expand-row first`}><Span>
                            {item.internalItems.length > 0 && <button onClick={() => setExpandedColumns((prev) => {
                                if (prev.includes(item.id)) {
                                    const list = prev.filter((expandedItem) => expandedItem !== item.id);
                                    return [...list]
                                }
                                prev.push(item.id)
                                return [...prev]
                            })} type="button" className={`${expandedColumns.includes(item.id) ? 'has-expanded' : ''} expand-row-button`}>
                                <Img img={'icon-select-arrow'}/>
                            </button>}
                            {item.label}
                        </Span></Column>
                        {item.data.map((elem, index) => (
                            <Column key={`Column-${index}`} className={'graphv2__expand-row'} colSpan={1}>
                                <Span>{`${viewFormat(elem, weightUnit.weightUnit)}`}</Span>
                            </Column>
                        ))}
                    </Row>
                    {item.internalItems.length
                        ? <>
                            {item.internalItems.map((internalItem, index) => {
                                return <Row key={`Row-internal-${internalItem.id}-${index}`} className={`${expandedColumns.includes(item.id) ? 'show' : ''} expanded-row`}>
                                    <Column colSpan={1} className="first"><Span>{internalItem.name}</Span></Column>
                                    {internalItem.sums.map((internalSum, internalSumIndex) => (
                                        <Column key={`Internal-Column-${internalSumIndex}`} colSpan={1}>
                                            <Span>{`${viewFormat(internalSum, weightUnit.weightUnit)}`}</Span>
                                        </Column>
                                    ))}
                                </Row>
                            })}
                        </>
                        : null}
                </Fragment>
                ))}
        </>
    );
};

TableBodyColumn.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        data: getStoreItem(state, "data"),
        marginListSelect: getStoreItem(state, "old.marginListSelect"),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
