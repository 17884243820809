import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disable: PropTypes.bool,
};

const defaultProps = {
    className: "",
    type: "button",
    disabled: false,
};

const Button = ({type, onClick, children, className, disabled}) => {
    const [focus, setFocus] = useState(false)

    useEffect(() => {
        if (disabled) setFocus(false)
    }, [disabled])

    return (
        <button
            tabIndex={0}
            type={type}
            className={`${className} ${disabled ? 'disabled' : ''} ${focus ? 'focus' : ''}`}
            onClick={onClick}
            onFocus={() => {
                setFocus(true)
            }}
            onBlur={() => {
                setFocus(false)
            }}
        >
            {children}
        </button>
    )
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
