import React from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {TabWrap} from "Templates/Content";
import {Title} from "Templates/Titles";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumnWrap/TableRowColumn";
import {ContainerTable} from "Hoc/Widgets";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import PropTypes from "prop-types";
import { BackLink } from "Templates/Link";

const TableStructure = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TableStructure);

const propTypes = {
    historyUserName: PropTypes.string.isRequired
}

const TabView = ({t, historyUserName, pref}) => {
    return (
        <>
            <Title
                title={t(`${historyUserName}'s View History`)}
            />
            <TabWrap>
                <TabViewDetail/>
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        title: getStoreItem(state, "pagination.total"),
        historyUserName: getStoreItem(state, "historyUserName"),

        request: {
            filter: state.listState.filter,
            sort: state.listState.sort,
        },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TabView);
