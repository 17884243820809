import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    filter: {},
    showFilterModal: false,
    shareFiltersShowModal: false,
    filterForm: {},
    groupList: null,
    savedFilter: ''
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FILTER:
            const { filter } = state;
            if (_.trim(data.value)) {
                filter[data.key] = data.value;
            } else {
                _.unset(filter, data.key);
            }
            return { ...state, filter };

        case Constants.RELOAD_FILTER:
            return { ...state, filter: data.filter, sort: data.sort };

        case Constants.FETCH_GROUP_LIST_SUCCEEDED:
            return { ...state, groupList: data.items, help: data.help };

        case Constants.FILTER_CLOSE_MODAL:
            return {
                ...state, showFilterModal: false, filterForm: {}
            };

        case Constants.SAVE_FILTER_SUCCESS:
            return {
                ...state, showFilterModal: false, filterForm: {}, savedFilter: data.id
            };

        case Constants.SHARE_FILTERS_CLOSE_MODAL:
            return {
                ...state, shareFiltersShowModal: false, filterForm: {}
            };

        case Constants.FILTER_OPEN_MODAL:
            return {...state, showFilterModal: true};

        case Constants.SHARE_FILTERS_OPEN_MODAL:
            return {...state, shareFiltersShowModal: true};

        case Constants.RESET_FILTER:
            state.filter = data;
            state.savedFilter = '';
            return state;

        case Constants.TABLE_UPDATE:
            return { ...state, items: data.items, pagination: data.pagination };

        case Constants.SAVED_FILTER_DATA_REQUESTED: {
            state.savedFilter = data.value;
            return state;
        }

        default:
            return state;
    }
};
