import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import TableRowColumnDefault from "./Default";
import {withTagDefaultProps} from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import {withServiceConsumer} from "Services/Context";
import TableRowItem from "./TableRowItem";

const propTypes = {
    column: PropTypes.string.isRequired,
    pinned_columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    customIcon: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
};

const BudgetTableRowColumn = React.memo(({index, secondaryIndex, column, pinned_columns, item, onClickCustom, customIcon, onClickEdit}) => {
    const budgetVolume = Number(item.budget_volume);
    const currentVolume = Number(item.current_volume);

    const percentageThreshold = 0.25;

    const difference = currentVolume - budgetVolume;
    const percentageDifference = difference / budgetVolume;

    const isCurrentBiggerThanBudget = currentVolume === 0 && budgetVolume === 0 ?
        false :
        percentageDifference > percentageThreshold &&
        column === 'current_volume';

    return (
        <Fragment key={`TableRowColumn-${index}-${secondaryIndex}-${column}`}>
            <td className={`
                ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `td pinned-column ${column}` : ''}
                ${
                    column.includes("budget_volume") ||
                    column.includes("budget_price") ||
                    column.includes("budget_revenue") ? 'connections-budgets-td' : ''
                } 
                ${
                    column.includes("actual_calculated_price") ||
                    column.includes("actual_normalized_weight") ||
                    column.includes("actual_net_amount") ? 'connections-actual-td' : ''
                } 
                ${
                    column.includes("stretch_volume") ||
                    column.includes("stretch_revenue") ? 'connections-stretch-td' : ''
                }  
                ${
                    column.includes("variance_volume") ||
                    column.includes("variance_revenue") ? 'connections-variance-td' : ''
                } 
                ${
                    column.includes("annualized_volume") ||
                    column.includes("annualized_revenue") ? 'connections-annualized-td' : ''
                }   
                ${
                    column.includes("current_volume") ||
                    column.includes("current_variance_percent") ||
                    column.includes("current_variance") ||
                    column.includes("current_average_sale_price") ||
                    column.includes("current_total_rebate") ||
                    column.includes("current_total_revenue") ||
                    column.includes("current_revenue") ? 'connections-currents-td' : ''
                } 
                ${
                column.includes("next_volume") ||
                column.includes("next_average_sale_price") ||
                column.includes("next_total_rebate") ||
                column.includes("next_total_revenue") ||
                column.includes("next_revenue") ? 'connections-next-td' : ''
                } 
                main-table__body
                ${column === "-1" ? "main-table__body--sticky" : ""}
                ${budgetVolume !== 0 && +item.actual_normalized_weight !== 0 && currentVolume === 0 && column === 'current_volume' ? 'color-red' : ''}
                ${column === 'actual_calculated_price' ? 'first' : ''} 
                ${column === 'annualized_volume' ? 'first' : ''} 
                ${column === 'stretch_volume' ? 'first' : ''} 
                ${column === 'variance_volume' ? 'first' : ''} 
                ${column === 'current_average_sale_price' ? 'first' : ''} 
                ${column === 'next_average_sale_price' ? 'first' : ''} 
                ${column === 'budget_revenue' ? 'last' : ''} 
                ${column === 'actual_net_amount' ? 'last' : ''} 
                ${column === 'stretch_revenue' ? 'last' : ''} 
                ${column === 'variance_revenue' ? 'last' : ''} 
                ${column === 'annualized_revenue' ? 'last' : ''} 
                ${column === 'current_total_revenue' ? 'last' : ''} 
                ${column === 'next_total_revenue' ? 'last' : ''} 
                `
            }>
                <div className={"connections-body-wrapper"}>
                    <TableRowColumnTemplate>
                        {column === "-1" ? (
                            <TableRowItem
                                onClickEdit={onClickEdit}
                                index={secondaryIndex}
                                customIcon={customIcon}
                                onClickCustom={onClickCustom}
                            />
                        ) : (
                            <TableRowColumnDefault column={column} item={item}/>
                        )}
                    </TableRowColumnTemplate>
                </div>
            </td>
        </Fragment>
    )
});

BudgetTableRowColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(BudgetTableRowColumn);
