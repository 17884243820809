import React from "react";
import {compose} from "redux";
import {useDispatch} from 'react-redux';

import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import {url} from "Services";
import TabCheck from "Templates/Tabs/TabСheck";
import {activeWeightUnit} from 'App/View/Header/Items/Units/Actions/ConverterUnitsAction';
import InfoWrap from "./Views/InfoWrap";

import _projectService from "./Services/Tabs/Projects";
import _notesService from "./Services/Tabs/Notes";
import _analyticsGradesService from "./Services/Tabs/Analytics";

import Projects from "./Views/Tabs/Projects";
import Notes from "./Views/Tabs/Notes";
import Analytics from "./Views/Tabs/Analytics";

const defaultProps = {
    totalNotes: 0,
    totalProjects: 0
};

const propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    totalNotes: PropTypes.number,
    totalProjects: PropTypes.number,
    onChangeTab: PropTypes.func.isRequired,
};

const Contact = ({ pref, id, activeTab, totalNotes, totalProjects, t, onChangeTab, service }) => {
    const dispatch = useDispatch();
    const { deleteItem } = service;

    const onClickEdit = () => {
        url.redirect(`/contacts/${id}/edit`);
    };

    const onClickDelete = () => {
        if (window.confirm(t("Do you want to delete the Contact?"))) {
            deleteItem(`/contacts/${id}/delete`).then(
                () => {
                    url.redirect(`/contacts`);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const projectService = _projectService(id);
    const notesService = _notesService(id);
    const analyticsGradesService = _analyticsGradesService(id);

    dispatch(activeWeightUnit([1, 2].includes(activeTab)));

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit} onClickDelete={onClickDelete}/>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index) => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t(`Notes`)} counter={totalNotes}>
                        <ServiceProvider value={notesService}>
                            <Notes/>
                        </ServiceProvider>
                    </Tab>

                    <TabCheck
                        label={t("Projects")}
                        permission="contacts_project_view"
                        className={`${pref}__wrap main-content__wrap-top main-wrap-block`}
                        counter={totalProjects}
                    >
                        <ServiceProvider value={projectService}>
                            <Projects/>
                        </ServiceProvider>
                    </TabCheck>

                    <Tab label={t("Analytics")}>
                        <ServiceProvider value={analyticsGradesService}>
                            <Analytics/>
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

const mapStateToProps = state => {
    return {
        id: state.listState.view.id,
        activeTab: state.listState.activeTab,
        totalNotes: state.listState.totalNotes,
        totalProjects: state.listState.totalProjects
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Contact);
