import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {numberWithCommas} from "Services";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {DivColumn, DivRow} from "Templates/Table/NewVersion";
import {Span} from "Templates/Default";

const propTypes = {
    yearsCalendarQuantity: PropTypes.objectOf(PropTypes.any),
    yearsFYQuantity: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    yearsCalendarQuantity: {},
    yearsFYQuantity: {},
};

const YearTableBodyColumn = ({ yearsCalendarQuantity, yearsFYQuantity, t }) => {
    const header = [
        { label: "Volume (lbs) or Builds - CY", values: yearsCalendarQuantity },
        { label: "Volume (lbs) or Builds - FY", values: yearsFYQuantity },
    ];
    return (
        <>
            <DivColumn>
                <DivRow />
                {Object.keys(yearsCalendarQuantity).map(column => (
                    <DivRow key={`Row-${column}`}>
                        <Span className="gray-text">{t(column)}</Span>
                    </DivRow>
                ))}
            </DivColumn>
            {header.map(column => (
                <DivColumn key={`Fragment-${column.label}`}>
                    <DivRow>
                        <Span className="bold-text">{column.label}</Span>
                    </DivRow>
                    {Object.keys(column.values).map(key => (
                        <DivRow key={`Fragment-${key}`}>
                            <Span>{numberWithCommas(column.values[key])}</Span>
                        </DivRow>
                    ))}
                </DivColumn>
            ))}
        </>
    );
};

YearTableBodyColumn.propTypes = propTypes;
YearTableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        yearsCalendarQuantity: getStoreItem(state, "items.yearsCalendarQuantity"),
        yearsFYQuantity: getStoreItem(state, "items.yearsFYQuantity"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(YearTableBodyColumn);
