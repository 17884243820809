import React from "react";
import PropTypes from "prop-types";
import { Input } from "../../Input";

const defaultProps = {
    placeholder: "",
};

const propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const Textarea = ({ children, placeholder, onChange }) => (
    <Input
        type="textarea"
        placeholder={placeholder}
        onChange={onChange}
        value={children}
        className="main-modal__form-textarea"
    />
);

Textarea.defaultProps = defaultProps;
Textarea.propTypes = propTypes;

export default Textarea;
