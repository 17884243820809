import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable } from "../../RequestService";
import { requestOnUploadFiles, requestOnCreateFolder, requestOnEditFolder,  } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues, customIcon, showEditButton } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/project/projects/${id}/files`),
    requestOnUploadFiles: requestOnUploadFiles(`/project/projects/${id}/files/add`),
    onClickDeleteItem: item => deleteItem(`/project/projects/${id}/files/${item.id}/delete`),
    requestOnCreateFolder: requestOnCreateFolder(`/project/projects/${id}/files/create-folder`),
    requestOnEditFolder: requestOnEditFolder(`/project/projects/${id}/files`),
    getViewItem,
    getFilter,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
    customIcon,
    showEditButton
});
