import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import Img, { ImgStorage } from "Templates/Img";

const propTypes = {
    id: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

const CardFooter = ({ id, photo, name, permissions, pref }) => {
    return (
        <a href={permissions.includes('admin') ? `admin/users/${id}` :  `#`} className={`${pref}__card-link main-table__body-link gray-text`}>
            <span className={`${pref}__card-link-user main-table__body-link-user main-circle`}>
                {photo ? <ImgStorage url={photo} /> : <Img img="icon_contact_small" />}
            </span>
            <span className={`${pref}__card-link-text main-table__body-link-text`}>{name}</span>
        </a>
    );
};

CardFooter.propTypes = propTypes;

export default compose(PermissionsProps, withTagDefaultProps())(CardFooter);
