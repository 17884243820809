import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {numberWithCommas, url} from "Services";
import Alerts from "./Views/Tabs/Alerts";
import alertsService from "./Services/Tabs/Alerts";
import ShowPermission from "Hoc/Template/ShowPermission";
import Title from "Templates/Titles/Title";
import Total from "Templates/Titles/Total";
import WrapButtons from "Templates/Titles/WrapButtons";
import TabBlockBtn from "Templates/Content/TabBlockBtn";
import AddAlert from "./AddAlert";
import {Modal} from "Templates/Modals";
import Tabs from 'Templates/Tabs/Tabs';
import Tab from 'Templates/Tabs/Tab';

const defaultProps = {
    help: ''
}
const propTypes = {
    activeTab: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    showModal: PropTypes.bool.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const labels = [
    {
        label: "Alerts",
        button: "Alert",
        service: alertsService,
    },
];

class Alert extends Component {
    componentDidMount() {
        if(!this.props.permissions.includes('admin_alerts_crud')) {
            url.redirect(`/`);
        }
    }

    render() {
        const {title, help, activeTab, onChangeTab, t, handleCloseModal, handleOpenModal, showModal} = this.props;

        const onClickAdd = () => {
            return handleOpenModal;
        };

        const tab = () => {
            return (
                <>
                    {showModal && (
                        <Modal onRequestClose={handleCloseModal}>
                            <ServiceProvider value={labels[activeTab].service}>
                                <AddAlert button={labels[activeTab].button} onClose={handleCloseModal}/>
                            </ServiceProvider>
                        </Modal>
                    )}
                    <Tabs
                        activeTab={activeTab}
                        onClickTabItem={index => {
                            if (activeTab !== index) {
                                onChangeTab(index);
                            }
                        }}
                    >
                        <Tab label={t("Alerts")} >
                            <ServiceProvider value={alertsService}>
                                <Alerts/>
                            </ServiceProvider>
                        </Tab>
                        <Tab label={ '' }/>
                    </Tabs>
                </>
            );
        };

        return (
            <CheckPermission permission="admin_alerts_crud">
                <Title
                    title={t(labels[activeTab].label)}
                    help={help}
                    total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
                >
                    <WrapButtons>
                        <TabBlockBtn onClick={onClickAdd(activeTab)}>{t(`Add New ${labels[activeTab].button}`)}</TabBlockBtn>
                    </WrapButtons>
                </Title>
                {tab()}
            </CheckPermission>
        );
    }
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        title: getStoreItem(state, "pagination.total"),
        help: getStoreItem(state, "help"),
        activeTab: getStoreItem(state, "activeTab"),
        showModal: getStoreItem(state, "showModal"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Alert);
