import React from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Div } from "Templates/Default";
import PropTypes from "prop-types";

const propTypes = {
    publicationName: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
};

const AdditionInfo = ({ publicationName, company }) => {
    return (
        <Div className="design_addition_info">
            <Div>{publicationName}</Div>
            <Div>{company}</Div>
        </Div>
    );
}

AdditionInfo.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        publicationName: getStoreItem(state, "publicationName"),
        company: getStoreItem(state, "company"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(AdditionInfo);
