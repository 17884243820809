import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    status: PropTypes.number.isRequired,
    pagination: PropTypes.shape({ page: PropTypes.number.isRequired, pages: PropTypes.number.isRequired }).isRequired,
    service: PropTypes.shape({ handleScroll: PropTypes.func.isRequired }).isRequired,
    fetchPage: PropTypes.func.isRequired,
};

const MasonryColumn = ({ status, pagination, service: { handleScroll }, pref, fetchPage, children }) => {
    const onScroll = e => {
        const { page, pages } = pagination;

        if (page >= pages) {
            return;
        }

        handleScroll(e).then(() => {
            fetchPage({ filter: { status }, page: page + 1 });
        });
    };
    return (
        <div className={`${pref}__masonry-column`} onScroll={onScroll}>
            {children}
        </div>
    );
};

MasonryColumn.propTypes = propTypes;

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MasonryColumn);
