import React from "react";
import _ from "lodash";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

const getViewItem = (key, item) => {
    switch (key) {
        default:
            return <TableRowItemSpan title={_.get(item, key)}>{_.get(item, key)}</TableRowItemSpan>;
    }
};

export { getViewItem };
