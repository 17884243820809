import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoHeader,
    ContentInfoHeaderWrap,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap
} from "Templates/Content";

const propTypes = {
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    editData: PropTypes.objectOf(PropTypes.any).isRequired,
    canEdit: PropTypes.bool.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

const InfoWrap = ({t, name, code, onClickEdit, onClickDelete, editData, canEdit}) => {
    return (
        <ContentInfoWrap>
            <ContentInfoHeaderWrap>
                <ContentInfoTop
                    onClickEdit={canEdit ? onClickEdit : null}
                    onClickDelete={canEdit ? onClickDelete : null}
                    urlBack="/vendors"
                />
                <ContentInfoHeader>
                    <ContentInfoTitle>{editData.name ? editData.name : name}</ContentInfoTitle>
                </ContentInfoHeader>
            </ContentInfoHeaderWrap>
            <ContentInfoBlock>
                <ContentInfoBlockTitle>{t("Vendor Code")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={editData.code ? editData.code : code} />
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        name: getStoreItem(state, "view.name"),
        code: getStoreItem(state, "view.code"),
        editData: getStoreItem(state, "editData"),
        canEdit: getStoreItem(state, "view.can_edit")
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
