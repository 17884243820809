import { request, url } from "Services";

const listUrl = "auth/request";

const getItems = data =>
    request.sendRequest({
        url: url.getUrl(`${listUrl}`),
        data,
        type: "POST",
    });

export const requestOnSubmitForm = data =>
    new Promise((resolve, reject) => {
        getItems(data).then(res => resolve(res[0]), res => reject(res));
    });
