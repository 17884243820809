import React from "react";
import { getString } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import { contactPhoneTypes, contactEmailTypes } from "Services/enum";
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/contacts/${getString(item, "id")}`}
                    img={getString(item, "contact_img", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "company":
            return (
                <TableRowItemLink
                    title={getString(item, "company_name")}
                    href={`/companies/${getString(item, "company_id")}`}
                >
                    {getString(item, "company_name")}
                </TableRowItemLink>
            );

        case "email":
            const emails = item.email ? JSON.parse(item.email) : [];
            return (
                <>
                    {emails.map((elem, index) => (
                        <TableRowItemSpan title={elem.value} key={`TableRowItemSpan-${elem.value}`}>
                            {`${_.find(contactEmailTypes, {value:`${elem.type}`}).label}: `}
                            <TableRowItemLink key={`TableRowItemLink-${elem.value}`} title={elem.value}
                                              href={`mailto:${elem.value}`}>
                                {elem.value}
                            </TableRowItemLink>
                        </TableRowItemSpan>
                    ))}
                </>
            );

        case "phone":
            const phones = item.phone ? JSON.parse(item.phone) : [];
            return (
                <>
                    {phones.map((elem, index) => (
                        <TableRowItemSpan title={elem.value} key={`TableRowItemSpan-${elem.value}`}>
                            {`${_.find(contactPhoneTypes, {value:`${elem.type}`}).label}: ${elem.value}`}
                        </TableRowItemSpan>
                    ))}
                </>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
