import React from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form } from "Templates/Form";
import CheckPermission from "Hoc/Template/CheckPermission";
import GeneralForm from "./Forms/GeneralForm";
import SapBlock from "./SapBlock";
import PropTypes from "prop-types";

const propTypes = {
    isSap: PropTypes.bool.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

const FormBlock = ({isSap}) => {
    return (
        <Form>
            <GeneralForm/>
            {isSap && <CheckPermission permission="grade_product_sap_view">
                <SapBlock/>
            </CheckPermission>}
        </Form>
    );
};

FormBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        isSap: getStoreItem(state, "isSap"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
