import React, {useEffect, useReducer, useState} from "react";
import {formatDate, getString, numberWithCommas, regExpForFloat, regExpForInteger} from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import {getJson} from "Services/StringService";
import {Input} from "Templates/Form";
import {Button} from "Templates/Default";
import Img from "Templates/Img";
import {isYesNo} from "Services/enum";
import {reducer} from "../Reducers/TableReducer";
import Constants from "../Constants";

export const getQuickEditViewItem = (column, item, applyChange, setInvalid) => {
    const [state, dispatch] = useReducer(reducer, {
        id: item.id,
        iso_id: item.iso_id,
        material_type: item.material_type,
        lead_time: item.lead_time,
        standard_packaging: item.standard_packaging,
        delivery_terms: item.delivery_terms,
        freight_class: item.freight_class,
        note: item.note,
        periods: JSON.parse(item.periods)
    });
    const [duplicatedPeriodId, setDuplicatedPeriodId] = useState(null)
    const [wrongPerLbsPeriodId, setWrongPerLbsPeriodId] = useState(null)
    const [wrongScaleQuantity, setWrongScaleQuantity] = useState(null)
    const [wrongScaleQuantityPrice, setWrongScaleQuantityPrice] = useState(null)


    const handleChange = (field, value) => {
        dispatch({ type: Constants.ON_QUICK_EDIT, field, value })
        applyChange({ field, value })
    }

    useEffect(() => {
        setInvalid(!!(duplicatedPeriodId || wrongPerLbsPeriodId))
    }, [duplicatedPeriodId, wrongPerLbsPeriodId])

    const handleChangePeriods = (value, field, periodId) => {
        if (field === 'quantity_per_unit' && value === '') {
            setWrongPerLbsPeriodId(periodId);
        } else {
            setWrongPerLbsPeriodId(null);
        }

        const dateDuplicate = state.periods.find((period) => period.effective_from_date === value);

        if (dateDuplicate) {
            alert('Error! You can\'t save Validity Period with same Effective From Date.');
            setDuplicatedPeriodId(periodId);
        } else {
            setDuplicatedPeriodId(null);
        }

        const currentPeriod = state.periods.find((period) => period.id === periodId);
        currentPeriod[field] = value;

        const currentPeriodIndex = state.periods.findIndex((period) => period.id === periodId)

        if (!wrongPerLbsPeriodId && currentPeriodIndex > -1) {
            state.periods[currentPeriodIndex] = currentPeriod;
            dispatch({ type: Constants.ON_QUICK_EDIT, field, value: state.periods[currentPeriodIndex][field] });
            applyChange({ index: currentPeriodIndex, field, value: state.periods[currentPeriodIndex][field] });
        }
    }

    const handleChangePeriodsQuantity = (value, index, periodId) => {
        const currentPeriod = state.periods.find((period) => period.id === periodId);
        currentPeriod.scale_quantities[index].scale_quantity = value;

        const invalidIndex = currentPeriod.scale_quantities.findIndex((val, i) => {
            if (i === 0 || !val.scale_quantity) return

            // eslint-disable-next-line consistent-return
            return +currentPeriod.scale_quantities[i - 1].scale_quantity >= +val.scale_quantity
        });

        if (invalidIndex > -1 || !value) {
            setWrongScaleQuantity(currentPeriod.scale_quantities[index].id)
        } else {
            setWrongScaleQuantity(null)
        }

        const currentPeriodIndex = state.periods.findIndex((period) => period.id === periodId)
        state.periods[currentPeriodIndex] = currentPeriod;
        dispatch({
            type: Constants.ON_QUICK_EDIT,
            field: 'scale_quantity',
            value: currentPeriod.scale_quantities[index].scale_quantity
        });

        applyChange({
            periodIndex: currentPeriodIndex,
            index,
            field: 'scale_quantity',
            value: currentPeriod.scale_quantities[index].scale_quantity
        });
    }

    const handleChangePeriodsPrice = (value, index, periodId) => {
        const currentPeriod = state.periods.find((period) => period.id === periodId);
        currentPeriod.scale_quantities[index].period_price = value;

        if (value <= 0) {
            setWrongScaleQuantityPrice(currentPeriod.scale_quantities[index].id)
        } else {
            setWrongScaleQuantityPrice(null)
        }

        const currentPeriodIndex = state.periods.findIndex((period) => period.id === periodId)
        state.periods[currentPeriodIndex] = currentPeriod;
        dispatch({
            type: Constants.ON_QUICK_EDIT,
            field: 'period_price',
            value: currentPeriod.scale_quantities[index].period_price
        });

        applyChange({
            periodIndex: currentPeriodIndex,
            index,
            field: 'period_price',
            value: currentPeriod.scale_quantities[index].period_price
        });
    }

    useEffect(() => {
        const list = [...Object.entries(state)];
        list.forEach((listItem) => {
            applyChange({ field: listItem[0], value: listItem[1] })
        })
    }, [])

    const mapRowItem = (elem) => {
        let currCost = getString(elem, 'cost_per_lbs_curr', 0);
        let prevCost = getString(elem, 'cost_per_lbs_prev', 0);
        const index = getString(elem, 'id');

        if (!currCost && prevCost >= 0) {
            currCost = prevCost;
        }

        if (!prevCost && currCost >= 0) {
            prevCost = currCost;
        }

        if (prevCost === currCost) {
            return (
                <TableRowItemSpan
                    key={index}
                    className="text-right"
                    title={`$${numberWithCommas(currCost, 3)}`}
                >
                    {`$${numberWithCommas(currCost, 3)}`}
                    <span className="statistic-icon">&nbsp;</span>
                </TableRowItemSpan>
            );
        }

        const className = currCost > prevCost ? 'clr-red' : 'clr-green';
        const iconClass = currCost > prevCost ? '' : 'rotated';

        return (
            <TableRowItemSpan key={index} title={`$${numberWithCommas(currCost, 3)}`} className="text-right">
                {`$${numberWithCommas(currCost, 3)}`}
                <span className={`statistic-icon ${iconClass}`}>
                    <i className={`fas fa-sort-up ${className}`}/>
                </span>
            </TableRowItemSpan>
        );
    };

    switch (column) {
        case "name": {
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, column)}
                    href={`/product/raw/${getString(item, "id")}`}
                >
                    {getString(item, column)}
                </TableRowItemLink>
            );
        }

        case "description":
        case "manufacturer":
        case "vendor_code":
        case "distributor":
        case "distributor_vendor_code": {
            return <TableRowItemSpan title={getString(item, column)}>{getString(item, column)}</TableRowItemSpan>;
        }

        case 'selling_plant': {
            const items = getJson(item, 'selling_plants');

            return (
                items && items.length
                    ? (
                        <>
                            {items.map((elem) => (
                                <TableRowItemLink
                                    blank
                                    key={getString(elem, 'id')}
                                    title={getString(elem, 'plant')}
                                    href={`/product/plants/${getString(elem, 'id')}`}
                                >
                                    {getString(elem, 'plant')}
                                </TableRowItemLink>
                            ))}
                        </>
                    )
                    : '-'
            );
        }

        case 'map_cost': {
            const items = getJson(item, 'selling_plants');

            return (
                items && items.length
                    ? (
                        <>
                            {items.map((elem) => mapRowItem(elem))}
                        </>
                    )
                    : <TableRowItemSpan className="text-right">-</TableRowItemSpan>
            );
        }

        case "iso_name": {
            return (
                <div className="quick-edit-field">
                    <Input
                        name="iso_id"
                        type="asyncSelect"
                        placeholder="Search"
                        url="/product/raw/iso-list"
                        value={state.iso_id}
                        onChange={(value) => {
                            handleChange('iso_id', value)
                        }}
                    />
                    {item.iso_id !== state.iso_id && (
                        <Button onClick={() => {
                            handleChange('iso_id', item.iso_id)
                        }}
                        >
                            <Img img="turn_back"/>
                        </Button>
                    )}
                </div>
            )
        }

        case "created_date":
        case "date_update":
        case "iso_expiration_date": {
            const date = getString(item, column, '') ? formatDate(getString(item, column)) : '-';

            return (
                <TableRowItemSpan title={date}>{date}</TableRowItemSpan>
            );
        }

        case "material_type":
        case "lead_time":
        case "standard_packaging":
        case "delivery_terms":
        case "freight_class": {
            return (
                <div className="raw-empty-input quick-edit-field">
                    <Input
                        name={column}
                        value={state[column]}
                        onChange={(value) => {
                            handleChange(column, value)
                        }}
                    />
                    {item[column] !== state[column] && (
                        <Button onClick={() => {
                            handleChange(column, item[column])
                        }}
                        >
                            <Img img="turn_back"/>
                        </Button>
                    )}
                </div>
            )
        }

        case "note": {
            return (
                <div className="raw-empty-input quick-edit-field">
                    <Input
                        type="textarea"
                        name={column}
                        value={state[column]}
                        onChange={(value) => {
                            handleChange(column, value)
                        }}
                    />
                    {item[column] !== state[column] && (
                        <Button onClick={() => {
                            handleChange(column, item[column])
                        }}
                        >
                            <Img img="turn_back"/>
                        </Button>
                    )}
                </div>
            )
        }

        case"obsolete_marker":
        case"deletion_marker": {
            const items = getJson(item, 'selling_plants');

            return (
                items && items.length
                    ? (
                        <>
                            {items.map((elem, index) => {
                                const yesNoData = isYesNo.find(k => k.value.toString() === getString(elem, column).toString())

                                return (
                                    <TableRowItemSpan key={index}>
                                        {getString(yesNoData, 'label', '-')}
                                    </TableRowItemSpan>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case"sap_value": {
            return <TableRowItemSpan>{getString(item, column) ? 'Yes' : 'No'}</TableRowItemSpan>;
        }

        case "effective_from_date": {
            return (
                state.periods && state.periods.length
                    ? (
                        <>
                            {state.periods.map((elem, index) => {
                                const len = (getString(elem, 'scale_quantities') || []).length;
                                const value = getString(elem, 'effective_from_date', '')
                                const values = Array(len).fill('');
                                values[0] = value ? formatDate(value) : '-';

                                return (
                                    <div key={index} className="period-block">
                                        {values.map((date, subIndex) => {
                                            return (
                                                <div key={`${index}_${subIndex}`} className="date-period-item">
                                                    {date ? (
                                                        <Input
                                                            name="effective_from_date"
                                                            className={`${duplicatedPeriodId === elem.id ? 'error' : ''}`}
                                                            type="date"
                                                            placeholder="MM/DD/YYYY"
                                                            value={date}
                                                            disabled={duplicatedPeriodId && duplicatedPeriodId !== elem.id}
                                                            onChange={dateVal => {
                                                                handleChangePeriods(dateVal, 'effective_from_date', elem.id)
                                                            }}
                                                        />
                                                    ) : ''}
                                                    {date && JSON.parse(item.periods)[index].effective_from_date !== state.periods[index].effective_from_date && (
                                                        <Button onClick={() => {
                                                            handleChangePeriods(JSON.parse(item.periods)[index].effective_from_date, 'effective_from_date', elem.id)
                                                        }}
                                                        >
                                                            <Img img="turn_back"/>
                                                        </Button>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case "quantity_per_unit": {
            return (
                state.periods && state.periods.length
                    ? (
                        <>
                            {state.periods.map((elem, index) => {
                                const len = (getString(elem, 'scale_quantities') || []).length;
                                const value = getString(elem, 'quantity_per_unit', '')
                                const values = Array(len).fill('');
                                values[0] = value || '-';

                                return (
                                    <div key={index} className="period-block">
                                        {values.map((per, subIndex) => {
                                            return (
                                                <div key={`${index}_${subIndex}`} className="date-period-item">
                                                    {per ? (
                                                        <Input
                                                            className={`${wrongPerLbsPeriodId === elem.id ? 'error' : ''}`}
                                                            name="quantity_per_unit"
                                                            placeholder="Input"
                                                            value={per === '-' ? '' : per.toString()}
                                                            disabled={wrongPerLbsPeriodId && wrongPerLbsPeriodId !== elem.id}
                                                            onChange={perVal => {
                                                                regExpForInteger(perVal, data => handleChangePeriods(data, 'quantity_per_unit', elem.id))
                                                            }}
                                                        />
                                                    ) : ''}
                                                    {per && JSON.parse(item.periods)[index].quantity_per_unit !== state.periods[index].quantity_per_unit && (
                                                        <Button onClick={() => {
                                                            handleChangePeriods(JSON.parse(item.periods)[index].quantity_per_unit, 'quantity_per_unit', elem.id)
                                                        }}
                                                        >
                                                            <Img img="turn_back"/>
                                                        </Button>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case "unit": {
            return (
                state.periods && state.periods.length
                    ? (
                        <>
                            {state.periods.map((elem, index) => {
                                const len = (getString(elem, 'scale_quantities') || []).length;
                                const value = getString(elem, 'unit', '')
                                const values = Array(len).fill('');
                                values[0] = value || '-';

                                return (
                                    <div key={index} className="period-block">
                                        {values.map((per, subIndex) => {
                                            return (
                                                <div key={`${index}_${subIndex}`} className="date-period-item">
                                                    {per ? (
                                                        <Input
                                                            className={`${duplicatedPeriodId === elem.id ? 'error' : ''}`}
                                                            name="unit"
                                                            placeholder="Input"
                                                            value={per === '-' ? '' : per.toString()}
                                                            onChange={unitVal => {
                                                                handleChangePeriods(unitVal, 'unit', elem.id)
                                                            }}
                                                        />
                                                    ) : ''}
                                                    {per && JSON.parse(item.periods)[index].unit !== state.periods[index].unit && (
                                                        <Button onClick={() => {
                                                            handleChangePeriods(JSON.parse(item.periods)[index].unit, 'unit', elem.id)
                                                        }}
                                                        >
                                                            <Img img="turn_back"/>
                                                        </Button>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case "scale_quantity": {
            return (
                state.periods && state.periods.length
                    ? (
                        <>
                            {state.periods.map((elem, index) => {
                                const scaleQuantities = getString(elem, 'scale_quantities') || [];

                                return (
                                    <div key={index} className="period-block">
                                        {scaleQuantities.map((scaleQuantity, subIndex) => {
                                            return (
                                                <div
                                                    key={`${index}_${subIndex}`}
                                                    className="date-period-item quick-edit-raw-quantity"
                                                >
                                                    <Input
                                                        className={`${wrongScaleQuantity === scaleQuantity.id ? 'error' : ''}`}
                                                        name="scale_quantity"
                                                        placeholder="Input"
                                                        value={subIndex === 0 && scaleQuantity.scale_quantity.toString() !== '0' ? '0' : scaleQuantity.scale_quantity.toString()}
                                                        disabled={(wrongScaleQuantity && wrongScaleQuantity !== scaleQuantity.id) || subIndex === 0}
                                                        onChange={quantity => {
                                                            regExpForInteger(quantity, data => handleChangePeriodsQuantity(data, subIndex, elem.id))
                                                        }}
                                                    />
                                                    {JSON.parse(item.periods)[index].scale_quantities[subIndex].scale_quantity !== state.periods[index].scale_quantities[subIndex].scale_quantity && (
                                                        <Button onClick={() => {
                                                            handleChangePeriodsQuantity(JSON.parse(item.periods)[index].scale_quantities[subIndex].scale_quantity, subIndex, elem.id)
                                                        }}
                                                        >
                                                            <Img img="turn_back"/>
                                                        </Button>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }

        case "purchase_price": {
            return (
                state.periods && state.periods.length
                    ? (
                        <>
                            {state.periods.map((elem, index) => {
                                const scaleQuantities = getString(elem, 'scale_quantities') || [];

                                return (
                                    <div key={index} className="period-block">
                                        {scaleQuantities.map((scaleQuantity, subIndex) => {
                                            return (
                                                <div
                                                    key={`${index}_${subIndex}`}
                                                    className="date-period-item quick-edit-raw-quantity"
                                                >
                                                    <Input
                                                        className={`${wrongScaleQuantityPrice === scaleQuantity.id ? 'error' : ''}`}
                                                        name="period_price"
                                                        placeholder="Input"
                                                        value={scaleQuantity.period_price.toString()}
                                                        disabled={wrongScaleQuantityPrice && wrongScaleQuantityPrice !== scaleQuantity.id}
                                                        onChange={quantity => {
                                                            regExpForFloat(quantity, data => handleChangePeriodsPrice(data, subIndex, elem.id))
                                                        }}
                                                    />
                                                    {JSON.parse(item.periods)[index].scale_quantities[subIndex].period_price !== state.periods[index].scale_quantities[subIndex].period_price && (
                                                        <Button onClick={() => {
                                                            handleChangePeriodsPrice(JSON.parse(item.periods)[index].scale_quantities[subIndex].period_price, subIndex, elem.id)
                                                        }}
                                                        >
                                                            <Img img="turn_back"/>
                                                        </Button>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                );
                            })}
                        </>
                    )
                    : <TableRowItemSpan>-</TableRowItemSpan>
            );
        }


        default:
            return (<TableRowItemSpan>-</TableRowItemSpan>)
    }
}

