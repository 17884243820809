import ReactOnRails from "react-on-rails";
import React from "react";
import Root from "App/View/Root";
import service from "./Services";
import DashboardTypeFive from "./Views/DashboardTypeFive";
import ShowPermission from "Hoc/Template/ShowPermission";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);
    
    return (
        <Root store={store} service={service()}>
            <DashboardTypeFive />
        </Root>
    );
};

export default App;
