import {debounce} from "Services/Debounce";
import Constants from "../Constants";

const requestFailed = data => ({type: Constants.FETCH_ITEMS_FAILED, data});

export const requestItem = () => ({type: Constants.FETCH_ITEMS_REQUESTED, data: {loading: true}});

const requestSucceeded = data => ({type: Constants.FETCH_ITEMS_SUCCEEDED, data});

const requestNextPageSucceeded = data => ({type: Constants.NEXT_PAGE, data});

export const setCompareProductsSelect = (service, dispatch) => data => dispatch({
    type: Constants.CHANGE_COMPARE_PRODUCTS,
    data
});

export const resetCompareProductsSelect = (service, dispatch) => data => dispatch({
    type: Constants.RESET_COMPARE_PRODUCTS,
    data
});

export const setActiveFilter = (service, dispatch) => data => dispatch({type: Constants.SET_ACTIVE_FILTERS, data});

export const setTableFilters = (service, dispatch) => data => dispatch({type: Constants.SET_TABLE_FILTERS, data});

export const setProductsControls = (service, dispatch) => data => dispatch({type: Constants.SET_PRODUCTS_CONTROLS, data});

export const setPropertyNameValue = (service, dispatch) => data => dispatch({type: Constants.SET_PROPERTY_NAME_VALUE, data});

export const setTestMethodValue = (service, dispatch) => data => dispatch({type: Constants.SET_TEST_METHOD_VALUE, data});

export const setSpecimenNameValue = (service, dispatch) => data => dispatch({type: Constants.SET_SPECIMEN_NAME_VALUE, data});

export const setTestConditionValue = (service, dispatch) => data => dispatch({type: Constants.SET_TEST_CONDITION_VALUE, data});

export const setManufacturingValue = (service, dispatch) => data => dispatch({type: Constants.SET_MANUFACTURING_VALUE, data});

export const setParameterValue = (service, dispatch) => data => dispatch({type: Constants.SET_PARAMETER_VALUE, data});

export const setDescriptionValue = (service, dispatch) => data => dispatch({type: Constants.SET_DESCRIPTION_VALUE, data});

export const setOpenCreateFilterModal = (service, dispatch) => data => dispatch({
    type: Constants.SHOW_CREATE_FILTERS_MODAL,
    data
});

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const {sort} = data;
    const {requestTable} = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({...res, sort: {...(sort || {})}}));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const {requestTable} = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

const filterList = [
    "supplier",
    "brand",
    "grade",
    "material_number",
    "material_type",
    "product_code",
    "publication_ids",
    "slug",
    "paintable",
    "sub_status",
    "keywords",
    "color",
    "approval",
    "color_code",
    "publication",
    "user",
    "country",
    "contact",
    "property",
    "method",
    "specimen",
    "condition",
    "process_property",
    "process_parameter",
    "project_market_segment",
    "project_segment",
    "project_sub_segment",
    "application_code",
    "market_segment",
    "segment",
    "sub_segment",
    "location",
    "plant",
    "sap_plant",
    "sap_line",
    "line",
    "datasheet_unit",
    "unit_value_max",
    "process_unit",
    "customer",
    "customer_code",
    "project_slug",
    "project_id",
    "molded",
    "boundable",
    "group_id",
    "first_shared_date",
    "last_login_date",
]

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({type: Constants.CHANGE_FILTER, data: {key, value}});
    if (['range_type', 'range_property', 'range_unit', 'unit_value_min'].includes(key)) {
        return;
    }

    if (filterList.includes(key)) {
        fetchItems(service, dispatch)(request);
        return;
    }

    if (key === 'approval_name' && value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }

    if ((key === 'name' || key === 'group_id' || key === 'content' || key === 'approval_name') && !value.length) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const executeExport = (service, dispatch) => (data) => {
    dispatch({type: Constants.EXPORT_COMPARE_PRODUCTS, data});

    const {requestExportProducts} = service;

    return requestExportProducts(data).then(
        res => {
            alert('Export Compare Products started. Check notifications to download.');
            dispatch({type: Constants.EXPORT_COMPARE_PRODUCTS_SUCCESS, data: res});
        },
        res => {
            alert(res.detail);
            dispatch({type: Constants.EXPORT_COMPARE_PRODUCTS_FAILED, data: res});
        },
    );
};
