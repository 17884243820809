import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {getSelectedFiltersItems} from "../Services/RequestService";
import {FiltersColumnsUrls} from "../Constants/tableFiltersList";

const FilterComponent = ({ filterName, filterItemValue }) => {
    const [label, setLabel] = useState(null);


    const getFilterValueRequest = async (name, value) => {
        return await getSelectedFiltersItems(`${FiltersColumnsUrls[name]}/${String(value)}`);
    };

    const getFilterValue = async (name, value) => {
        try {
            const data = await getFilterValueRequest(name, value);
            const searchValue = data[0].items.find((item) => item.value === value);
            if (searchValue) {
                return searchValue.label;
            }
            return value;
        } catch (error) {
            return value;
        }
    };

    useEffect(() => {
        const fetchFilterValue = async () => {
            const result = await getFilterValue(filterName, filterItemValue);
            setLabel(result);
        };

        fetchFilterValue();
    }, [filterName, filterItemValue]);

    const getFilterLabel = (label) => {
        if (filterName === 'sub_status') {
            switch (+label) {
                case 10:
                    return 'Lead'
                case 9:
                    return 'Opportunity'
                case 8:
                    return 'Target'
                case 7:
                    return 'Current'
                case 6:
                    return 'Current Target'
                case 4:
                    return 'Level 4'
                case 3:
                    return 'Level 3'
                case 2:
                    return 'Level 2'
                case 1:
                    return 'Level 1'
                default:
                   return label
            }
        }

        if (filterName === 'grade_sub_status') {
            switch (+label) {
                case 1:
                    return 'Production'
                case 2:
                    return 'Trial'
                case 3:
                    return 'Obsolete'
                case 4:
                    return 'Production Viable'
                case 5:
                    return 'Production Intent'
                case 6:
                    return 'WIP'
                default:
                    return label
            }
        }

        if (filterName === 'stage_gate') {
            switch (+label) {
                case 0:
                    return 'None'
                case 1:
                    return 'Business Case'
                case 2:
                    return 'Evaluation'
                case 3:
                    return 'Design Validation'
                case 4:
                    return 'Technical Validation'
                case 5:
                    return 'Prepare Launch'
                case 6:
                    return 'Commercialized'
                default:
                    return label
            }
        }

        if (filterName === 'industry') {
            switch (+label) {
                case 0:
                    return 'None'
                case 1:
                    return 'Aerospace'
                case 2:
                    return 'Automotive'
                case 3:
                    return 'Construction & Industry'
                case 4:
                    return 'Consumer & Electronics'
                case 5:
                    return 'Industrial Materials'
                case 6:
                    return 'Medical'
                case 7:
                    return 'Food Packaging'
                case 8:
                    return 'Electric Wire'
                case 9:
                    return 'Other'
                case 10:
                    return 'N/A'
                case 11:
                    return '3D Printing'
                case 12:
                    return 'BIC'
                case 13:
                    return 'CFRP'
                case 14:
                    return 'Electronics'
                case 15:
                    return 'Energy Storage'
                case 16:
                    return 'Healthcare'
                case 17:
                    return 'Packaging'
                case 18:
                    return 'Tolling'
                case 19:
                    return '3DP'
                case 20:
                    return 'Mobility'

                default:
                    return label
            }
        }

        return label
    }

    return (
        <span>{label ? getFilterLabel(label) : getFilterLabel(filterItemValue.label)}</span>
    );
};

const mapStateToProps = () => {
};

const mapDispatchToProps = () => {

};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FilterComponent);