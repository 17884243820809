import _ from "lodash";
import Constants from "../Constants";
import {url} from "Services";

const defaultCompanyItem = {
    frontId: 0,
    company: "",
    team: "",
    haveAccess: false,
    defaultCompany: false,
    activeTab: +url.get('activeTab'),
    defaultPage: "dashboard.index",
    permission: [],
};

export const initialState = {
    view: {},

    id: "",
    firstName: "",
    lastName: "",
    title: "",
    employer: "",
    photo: "",
    photoView: "",
    email: "",
    twoFactor: true,
    paid: false,
    phone: { code: "+1", number: "" },
    team: [{ ...defaultCompanyItem }],
    activeTab: +url.get('activeTab'),

    form_validate: false,
    form_loading: false,
    form_errors: {},

    old: {},
};

const validate = ({ firstName, lastName, title, email, team, employer }) => {
    const checkString = [firstName, lastName, title, email, employer].every(el => typeof el === "string" && el.trim());
    const checkTeam = team.every(el => el.company);
    const existCompany = _.findIndex(team, (o) => o.haveAccess) >= 0;

    return checkString && checkTeam && existCompany;
};
export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM:
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };

        case Constants.SET_EMAIL:
        case Constants.SET_PHONE:
            return { ...state, ...data };

        case Constants.DELETE_TEAM:
            return { ...state, team: state.team.filter(item => item.frontId !== data.key) };

        case Constants.ADD_TEAM:
            return {
                ...state,
                team: [
                    ...state.team,
                    { ...defaultCompanyItem, frontId: _.get(state, `team[${state.team.length - 1}].frontId`) + 1 || 0 },
                ],
            };

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };

        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data };
        default:
            return { ...state };
    }
};
