import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { listData, searchValue } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "search-view" }),
        listState: { ...mainState, ...listData, searchValue, sort: { column: listData.columns[0], direction: "asc" } },
    };

    return _configureStore(initialState, reducers);
}
