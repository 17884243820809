import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import _ from "lodash";

function Breadcrumbs({ path, onClickItem }) {
    const _onClickCustom = path => {
        onClickItem(path);
    };

    return path ? (
        <ul className="breadcrumbs">
            <li className="breadcrumbs__item">
                <a className="breadcrumbs__link" onClick={_onClickCustom.bind(this, '')}>Home</a>
            </li>
            {path.split('/').map((elem) => (
                <li className="breadcrumbs__item">
                    <span className="breadcrumbs__link">{_.upperFirst(elem)}</span>
                </li>
            ))}
        </ul>
    ) : null;
}

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Breadcrumbs);
