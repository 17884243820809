import React from "react";
import _ from "lodash";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { TabWrap } from "Templates/Content";
import SharedContainerTable from "./SharedContainerTable";
import SharedTableHeadColumnWrap from "./SharedTableHeadColumnWrap";
import {SharedTableRowColumnWrap} from "./SharedTableRowColumnWrap";

const DefaultTabTable = SharedContainerTable(SharedTableHeadColumnWrap, SharedTableRowColumnWrap);

const propTypes = {
    customIcon: PropTypes.string,
    onClickCustom: PropTypes.func,
};

const SharedTabViewDetail = TabTable => ({
    onClickCustom,
    pref,
    customIcon,
    items,
    pagination,
    loading,
    fetchSharedItems,
    fetchSharedPage,
    request,
    service,
}) => {
    const { page, pages } = pagination;

    const { handleScroll } = service;

    if (items === undefined) {
        if (!loading) {
            fetchSharedItems(request);
        }
        return "";
    }

    const onScroll = e => {
        if (page >= pages) {
            return;
        }

        handleScroll(e).then(() => {
            request.page = page + 1;

            fetchSharedPage(request);
        });
    };

    const _onClickCustom = e => {
        const item = _.get(items, e);
        if (item) {
            onClickCustom(item);
        }
    };

    return (
        <TabWrap>
            <div
                className={`${pref}__wrap-body main-wrap-block__body main-wrap-block__body_height`}
                onScroll={onScroll}
            >
                <TabTable
                    onClickCustom={_onClickCustom}
                    customIcon={customIcon}
                />
            </div>
        </TabWrap>
    );
};

SharedTabViewDetail.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        items: getStoreItem(state, "items"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        loading: getStoreItem(state, "loading"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchSharedPage: getActionStore("fetchSharedPage", service, dispatch),
        fetchSharedItems: getActionStore("fetchSharedItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    SharedTabViewDetail,
)(DefaultTabTable);

export const sharedCustomTable = CustomTable =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
        SharedTabViewDetail,
    )(CustomTable);
