import {handleScroll} from "../../EventsService";
import {deleteItem, requestAttach, requestTable} from "../../RequestService";
import {getActionStore, getStoreItem} from "./StoreService";
import {getFilter, getTableLabel} from "./TableHeaderService";
import {getViewItem, modifierValues} from "./TableBodyService";

export default ({
    requestTable: requestTable(`/admin/list/keywords`),
    requestAttach: requestAttach(`/admin/list/keywords/create`),
    requestEdit: item => requestAttach(`/admin/list/keywords/${item.id}/edit`),
    onClickDeleteItem: item => deleteItem(`/admin/list/keywords/${item.id}/delete`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
