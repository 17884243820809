import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";

import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { MainButton } from "Templates/Content";

const propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired,
  service: PropTypes.shape({
    getStoreItem: PropTypes.func.isRequired,
    getActionStore: PropTypes.func.isRequired,
  }).isRequired,
  handleReset: PropTypes.func.isRequired,
};

const ResetTableFilters = ({ request, handleReset, t }) => {
  return (
      !_.isEmpty(request.filter) || !_.isEmpty(request.sort) ? (
      <MainButton
        className="main-btn__general main-btn_white"
        onClick={handleReset}
      >
        {t("Reset")}
      </MainButton>
    ) : ''
  );
};

ResetTableFilters.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
  return {
    request: {
      filter: {
        ...getStoreItem(state, "filter"),
      },
      sort: {
        ...getStoreItem(state, "sort"),
      },
    },
  };
};

export default compose(
  withServiceConsumer,
  withTagDefaultProps(mapStateToProps),
)(ResetTableFilters);