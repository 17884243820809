import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const dataReq = {
        name: _.trim(_.get(data, "name")),
        description: _.trim(_.get(data, "description")),
    };

    return formDataNormalizer(dataReq);
};
