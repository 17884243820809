import {handleScroll} from "../../EventsService";
import {requestAttach} from "./RequestService";
import {deleteItem, requestTable} from "../../RequestService";
import {getActionStore, getStoreItem} from "./StoreService";
import {getFilter, getTableLabel} from "./TableHeaderService";
import {getViewItem, modifierValues} from "./TableBodyService";

export default ({
    requestTable: requestTable(`/legal/iso`),
    requestAttach: requestAttach(`/legal/iso`),
    requestEdit: item => requestAttach(`/legal/iso/${item.id}`),
    onClickDeleteItem: item => deleteItem(`/legal/iso/${item.id}`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
