import {combineReducers} from "redux";
import {getMainInitialStates, getMainReducers} from "App/Services";
import {initState as viewState, reducer as viewReducer} from "./ViewReducer";
import {initState as formState, reducer as formReducer} from "./FormReducer";
import {initState as graphState, reducer as graphReducer} from "./GraphReducer";

const reducers = [viewReducer, formReducer, graphReducer];

const mainState = {
    ...viewState,
    ...formState,
    ...graphState,
};

const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }

    return state;
};

// Combine all reducers you may have here
export default combineReducers({
    ...getMainReducers,
    listState: reducer,
});

export const initialStates = {
    ...getMainInitialStates,
    mainState,
};
