import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable } from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues, tableButtonsPermission } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/product/products/${id}/grades`),
    onClickDeleteItem: item => deleteItem(`/product/product-finder/${item.id}/delete`),
    getViewItem,
    getFilter: getFilter(id),
    modifierValues,
    tableButtonsPermission,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
