import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;

    props.phone.code = _.get(props, "phone.code") || "+1";

    const { id, title, employer, firstName, lastName, email, phone, photo, photoView, team, twoFactor, paid } = props;

    const initData = { id, title, employer, firstName, lastName, email, phone, photo, photoView, team, twoFactor, paid };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "edit-user" }),
        editState: {
            ...editState,
            form_validate: true,
            ...initData,
            old: { ..._.cloneDeep(initData) },
        },
    };

    return _configureStore(initialState, reducers);
}
