import _ from "lodash";

export const formData = data => {
    const dataReq = {
        name: _.get(data, "name"),
        hoursThousand: _.get(data, "hoursThousand") || 0,
        costHour: _.get(data, "costHour") || 0,
    };

    return dataReq;
};
