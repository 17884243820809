import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    TabBlock,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import TabViewDetail from "./Table/TabViewDetail";

class Index extends Component {
    onClickDelete = item => {
        const { t } = this.props;
        if (window.confirm(t("Do you want to delete the Brochure from Publication?"))) {
            const {
                fetchItems,
                request,
                service: { onClickDeleteItem },
            } = this.props;
            onClickDeleteItem(item).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    render() {
        const { pagination, t } = this.props;
        return (
            <>
                <TabWrapTop>
                    <TabBlock>
                        <TabBlockCol>
                            <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                            <TabBlockColTitleH4>
                                {`${numberWithCommas(pagination.total, 0)} ${t("Publications")}`}
                            </TabBlockColTitleH4>
                        </TabBlockCol>
                    </TabBlock>
                </TabWrapTop>
                <TabViewDetail onClickDelete={this.onClickDelete}/>
            </>
        );
    }
}

Index.propTypes = {
    pagination: PropTypes.shape({ total: PropTypes.number }).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
