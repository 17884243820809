import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../../EventsService";

export const typeList = [
    {
        value: "10",
        label: "NDA",
    },
    {
        value: "7",
        label: "Intellectual Property",
    },
    {
        value: "5",
        label: "Other",
    },
];

export const getTableLabel = key => {
    const tableLabel = {
        name: "Name",
        slug: "Agreement ID",
        company: "Company",
        type: "Agreement Type",
        effective_date: "Effective Date",
        expiration_date: "Expiration Date",
        date: "Last Modified",
        user_name: "Owner",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        slug: "Search",
        company: "Search",
        type: "Search",
        effective_date: "Effective Date",
        expiration_date: "Expiration Date",
        date: "Last Modified",
        user_name: "Search",
    };

    switch (key) {
        case "type":
            return (
                <InputFilter
                    type="multiSelect"
                    options={typeList}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/legal/agreement-slug"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "company":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    name="company"
                    url="/legal/company-list"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "name":
        case "user_name":
            return (
                <InputFilter
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
