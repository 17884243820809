import _ from "lodash";
import { url } from "Services";
import Constants from "../Constants";

export const initState = {
    activeTab: +url.get('activeTab'),
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data, filter: {}, sort: {} };
        default:
            return state;
    }
};
