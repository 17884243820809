import React from "react";
import {useSelector} from 'react-redux';
import {formatDate, getString, numberWithCommas} from "Services";
import {gradeStatus, projectConsistent, projectStatus} from "Services/enum";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {weightUnitName} from 'Services/weightUnitName';
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";
import _ from "lodash";

export const modifierValues = items => items;
export const tableButtonsPermission = "companies_view";

export const getViewItem = (key, item) => {
    const unit = useSelector(state => state.weightUnitState);

    const tableRowItemLink = (labelKey, href) => (
        <TableRowItemLink title={getString(item, labelKey)} href={href}>
            {getString(item, labelKey)}
        </TableRowItemLink>
    );

    switch (key) {
        case "name":
            return tableRowItemLink("name", `/companies/${getString(item, "id")}`);

        case "customer_short_name":
            const value = _.get(item, key);
            return (
                value &&
                tableRowItemLink(key, `/companies/${getString(item, "id")}`) ||
                <TableRowItemSpan title={getString(item, key)}>-</TableRowItemSpan>
            );

        case "budget_price": {
            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(getString(item, key)).toFixed(2)}`}
                </TableRowItemSpan>
            );
        }

        case "budget_volume": {
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, key), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case "budget_revenue": {
            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(getString(item, key)).toFixed(2)}`}
                </TableRowItemSpan>
            );
        }

        case "project_slug":
        case "project_name":
            const name = JSON.parse(_.get(item, key, []));

            return (name.map((elem, index) => {
                return (
                    <TableRowItemLink
                        key={`TableRowItemLink-${index}`}
                        blank={true}
                        href={`/project/projects/${elem.id}`}>
                        {elem.value}
                    </TableRowItemLink>
                )
            }));

        case "grade_sub_status":
            return (
                <TableRowItemStatus
                    status={getString(item, key)}
                    list={[...gradeStatus]}
                />
            );

        case "sub_status":
            const subStatus = getString(item, key).split("\n");

            return (
                <>
                    {subStatus.map((elem, index) => {
                        return (
                            <div key={`div-${index}`}>
                                <TableRowItemStatus key={`TableRowItemSubStatus-${index}`} status={elem}
                                                    list={[...projectStatus]}/>
                            </div>
                        )
                    })}
                </>
            );

        case "status":
            const status = getString(item, key).split("\n");

            return (
                <>
                    {status.map((elem, index) => {
                        return (
                            <>
                                <TableRowItemStatus key={`TableRowItemStatus-${index}`} status={elem}
                                                    list={projectConsistent}/>
                                <br key={`BlankStatusLine-${index}`}/>
                            </>
                        )
                    })}
                </>
            );

        case "manager":
        case "product_application_code":
        case "plant":
            const data = getString(item, key).split("\n");

            return (
                <>
                    {data.map((elem, index) => {
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={elem}>{elem}</TableRowItemSpan>
                        )
                    })}
                </>
            );

        case String(key.match(/^actual_sale_average_price_.*/)): {
            const keys = key.split("_");
            return (
                <TableRowItemSpan className="text-right">
                    {getString(item, `analytics.${keys[5]}.${keys[6] || keys[4]}.${keys[3]}`).toFixed(2)}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^volume_.*/)): {
            const keys = key.split("_");
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, `analytics.${keys[2]}.${keys[3] || keys[1]}.${keys[0]}`), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^actual_volume_.*/)): {
            const keys = key.split("_");
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, `analytics.${keys[3]}.${keys[4] || keys[2]}.actual_volume`), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^revenue_.*/)): {
            const keys = key.split("_");
            const value = getString(item, `analytics.${keys[2]}.${keys[3] || keys[1]}.${keys[0]}`)

            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(value) < 10 ? Number(value).toFixed(2) : numberWithCommas(value, 0)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^actual_revenue_.*/)): {
            const keys = key.split("_");
            const value = getString(item, `analytics.${keys[3]}.${keys[4] || keys[2]}.actual_revenue`)

            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(value) < 10 ? Number(value).toFixed(2) : numberWithCommas(value, 0)}`}
                </TableRowItemSpan>
            );
        }

        case "los_date":
        case "move_to_current": {
            const date = getString(item, key).split("\n");
            return (
                <>
                    {date.map((elem, index) => {
                        const formatValue = elem === '-' ? elem : formatDate(elem, "MMM, YYYY");
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                {formatValue}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case "sap_customer":
        case "sap_grade":
            return <TableRowItemSpan>{getString(item, key, null) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case String(key.match(/^actual_sale_price.*/)): {
            const elem = getString(item, 'actual_sale_price').split("\n");

            return (
                <>
                    {elem.map((item, index) => {
                        item = (+item).toFixed(2);
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} className="text-right" title={item}>
                                {item}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case String(key.match(/^variable_cost.*/)): {
            let elem = getString(item, 'variable_cost').split("\n");

            return (
                <>
                    {elem.map((item, index) => {
                        item = (+item).toFixed(2);
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} className="text-right" title={item}>{item}</TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case String(key.match(/^fixed_cost.*/)): {
            let elem = getString(item, 'fixed_cost').split("\n");

            return (
                <>
                    {elem.map((item, index) => {
                        item = (+item).toFixed(2);
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} className="text-right" title={item}>{item}</TableRowItemSpan>
                        )
                    })}
                </>
            );
        }
        case 'connection_note_finance':
            return (
                item.connection_note_finance
                    ? <div className="publication-row"><TableRowItemTooltip>{item.connection_note_finance}</TableRowItemTooltip></div>
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );

        case 'connection_slug':
            return (
                <TableRowItemLink blank title={`${item.connection_id}`}
                                  href={`/connection/connections/${getString(item, "connection_id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
