import Constants from "../Constants";

export const changeStatusAction = (service, dispatch) => (url, status) => {
    dispatch({ type: Constants.STATUS_REQUESTED, data: { loading: true } });

    const { postRequest } = service;

    postRequest(url).then(
        () => {
            dispatch({ type: Constants.STATUS_SUCCEEDED, data: status });
        },
        res => {
            dispatch({ type: Constants.STATUS_FAILED, data: res });
        },
    );
};

export {};
