import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { ContainerTable } from "Hoc/Widgets";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import { TableRowColumnWrap } from "Templates/Table/TableRowColumnWrap";
import { TabWrap } from "Templates/Content";

const TabViewDetailWrap = TabTable => ({
    pref,
    items,
    pagination,
    loading,
    fetchItems,
    fetchPage,
    request,
    service,
}) => {
    const { page, pages } = pagination;

    const { handleScroll } = service;

    if (items === undefined) {
        if (!loading) {
            fetchItems({});
        }
        return "";
    }

    const onScroll = e => {
        if (page >= pages) {
            return;
        }

        handleScroll(e).then(() => {
            request.page = page + 1;

            fetchPage(request);
        });
    };

    return (
        <TabWrap>
            <div
                className={`${pref}__wrap-body main-wrap-block__body main-wrap-block__body_height`}
                onScroll={onScroll}
            >
                <TabTable />
            </div>
        </TabWrap>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        items: getStoreItem(state, "items"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        loading: getStoreItem(state, "loading"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export const customTable = CustomTable =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
        TabViewDetailWrap,
    )(CustomTable);
