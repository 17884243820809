import _ from "lodash";

export const columns = {
    region_mnemonic: "Core Nameplate Region Mnemonic",
    engineering_group: "Engineering Group",
    manufacturer_group: "Manufacturer Group",
    manufacturer: "Manufacturer",
    production_brand: "Brand",
    platform_design_owner: "Platform Design Owner",
    assembly_type: "Assembly Type",
    lifecycle: "Lifecycle (Time)",
};

export const getFormatValue = (item, key) => {
    const value = _.get(item, key, "");

    switch (key) {
        default:
            return value;
    }
};
