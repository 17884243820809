import React from "react";
import _ from "lodash";
import TableRowItemFolderLinkImg from "Templates/Table/TableRowItemFolderLinkImg";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {getString, formatDate, storageUrl} from "Services";
import {listTypes} from "./TableHeaderService";

export const modifierValues = items =>
    items.map(item => ({
        ...item,
        type: listTypes[item.type].label || "",
    }));

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            const propList = {
                Folder: {
                    ico: "icon-folder",
                    href: () => `files/${getString(item, "id")}${item.sufix ? `${item.sufix}` : ""}`,
                    label: getString(item, key),
                },
                File: {
                    ico: "icon-file",
                    target: "_blank",
                    href: storage_url => storageUrl(getString(item, "path", ""), storage_url),
                    label: getString(item, key),
                    noLink: !item.can_edit
                },
            };
            return (
                <TableRowItemFolderLinkImg
                    title={getString(item, key)}
                    className="files-img"
                    type={getString(item, "type")}
                    settings={propList}
                >
                    {getString(item, key)}
                </TableRowItemFolderLinkImg>
            );

        case "modified":
            return (
                <TableRowItemSpan title={formatDate(getString(item, key))}>
                    {formatDate(getString(item, key))}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan>{getString(item, key, "")}</TableRowItemSpan>;
    }
};

export const customIcon = items => index => {
    const type = _.get(items, `[${index}].type`);
    const label = _.get(listTypes, `[${type}].label`);
    return label === "File" ? "icon_download" : "";
};

export const showEditButton = item => {
    const showButton = _.get(item, `defaultFolder`, true);
    if (showButton) {
        return item.type !== "File";
    }
    return showButton;
};
