import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Block, BlockWrap, Input, WrapInput } from "Templates/Form";
import { countryList, StateList } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const AddressBlock = ({ data, stateOff, isDisabled, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <>
            <Block>
                <BlockWrap>
                    <WrapInput isRequired label={t("Address 1")} name="address1">
                        <Input
                            placeholder={t("Address")}
                            value={data.address1}
                            onChange={value => onChangeInput(value, "address1")}
                        />
                    </WrapInput>
                    <WrapInput label={t("Address 2")} name="address2">
                        <Input
                            placeholder={t("Address")}
                            value={data.address2}
                            onChange={value => onChangeInput(value, "address2")}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap />
                <BlockWrap />
            </Block>

            <Block>
                <BlockWrap>
                    <WrapInput isRequired label={t("City")} name="city">
                        <Input
                            placeholder={t("City")}
                            value={data.city}
                            onChange={value => onChangeInput(value, "city")}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired={data.country === "US"} label={t("State (US) Only")} name="state">
                        <Input
                            type="searchSelect"
                            placeholder={t("Select")}
                            value={data.state}
                            inputProps={{ isDisabled: isDisabled || data.country !== stateOff }}
                            onChange={value => onChangeInput(value, "state")}
                            options={StateList}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired label={t("Zip")} name="zip">
                        <Input
                            placeholder={t("Zip")}
                            value={data.zip}
                            onChange={value => onChangeInput(value, "zip")}
                        />
                    </WrapInput>
                </BlockWrap>
            </Block>

            <Block>
                <BlockWrap>
                    <WrapInput isRequired label={t("Country")} name="country">
                        <Input
                            type="searchSelect"
                            placeholder="Select country"
                            value={data.country}
                            inputProps={{ isDisabled }}
                            onChange={value => {
                                onChangeInput(value, "country");
                                onChangeInput("", "state");
                            }}
                            options={countryList}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap />
                <BlockWrap />
            </Block>
        </>
    );
};

AddressBlock.defaultProps = {
    isDisabled: false,
};

AddressBlock.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    stateOff: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { service } = ownProps;
    const { getStoreItem } = service;

    return {
        data: {
            country: getStoreItem(state, "country"),
            city: getStoreItem(state, "city"),
            address1: getStoreItem(state, "address1"),
            address2: getStoreItem(state, "address2"),
            zip: getStoreItem(state, "zip"),
            state: getStoreItem(state, "state"),
        },
        stateOff: "US",
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddressBlock);
