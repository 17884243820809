import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import Img, { ImgStorage } from "../Img";

const defaultProps = {
    img: "",
    ico: "",
    href: "",
    target: "_self",
    className: "",
    additionalClassname: "",
    title: "",
};

const propTypes = {
    img: PropTypes.string,
    ico: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    target: PropTypes.string,
    additionalClassname: PropTypes.string,
    title: PropTypes.string,
};

const LinkImg = ({ children, className, pref, target, img, ico, href, storage_url, additionalClassname, title }) => {
    const _href = typeof href === "function" ? href(storage_url) : href;
    return (
        <a
            title={title}
            href={_href}
            target={target}
            rel={_href === '_blank' ? "noopener noreferrer" : ''}
            className={`${pref}__table-body-link main-table__body-link ${className}`}
        >
            <span className={`${pref}__table-body-link-user main-table__body-link-user ${additionalClassname}`}>
                {img ? <ImgStorage url={img}/> : <Img img={ico || "icon_contact_small"}/>}
            </span>
            <span className={`${pref}__table-body-link-text main-table__body-link-text`}>{children}</span>
        </a>
    );
};

LinkImg.propTypes = propTypes;
LinkImg.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(LinkImg);
