import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form } from "Templates/Form";
import GeneralBlock from "./Blocks/GeneralBlock";
import ContactBlock from "./Blocks/ContactBlock";
import TeamBlock from "./Blocks/Team";

const propTypes = {
    addTeam: PropTypes.func.isRequired,
};

const FormBlock = ({ addTeam, t }) => {
    const handleClickAddTeam = () => addTeam();

    return (
        <Form>
            <GeneralBlock />
            <ContactBlock />
            <TeamBlock />
{/*            <button type="button" className="add-user__form-btn main-btn_primary main-btn" onClick={handleClickAddTeam}>
                {t("Add Another Company")}
            </button>
            <a href="/admin/companies/create" target="_blank" rel="nofollow noopener" className="add-user__form-btn main-btn main-btn_white">
                {t("Add New Company")}
            </a>*/}
        </Form>
    );
};

FormBlock.propTypes = propTypes;

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        addTeam: getActionStore("addTeamAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
