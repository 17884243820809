import {request, url} from "Services";

const getItems = data => {
    const urlPath = url.path().replace('contact/', '')
    const sendObj = {
        url: url.getUrl(urlPath),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = data => {
    return new Promise(resolve => {
        getItems(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
            });
        });
    });
};
