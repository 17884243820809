import _ from "lodash";
import Constants from "../../Constants";
import { validate } from "../../Services/Reducers/LogicService";

export const initialState = {
    form_validate: false,
    form_loading: false,
    show_full: true,
    form_errors: {},
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = _.cloneDeep(state);
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };
        }

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        default: {
            return { ...state };
        }
    }
};
