import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {
    name: "",
    label: "",
    className: "",
    param: "",
    isRequired: false,
    children: null,
};

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    children: PropTypes.node,
};

const Label = ({ pref, name, param, className, label, t, isRequired, children }) => (
    <label htmlFor={name} className={`${className} ${pref}__label main-content__label ${isRequired ? "required" : ""}`}>
        {t(label)}
        {children}
        {param ? (<span className={`label_param`}>{param}</span>) : ''}
    </label>
);

Label.defaultProps = defaultProps;
Label.propTypes = propTypes;

export default compose(withTagDefaultProps())(Label);
