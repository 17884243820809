import React, {Fragment} from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {getString} from "Services";
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case 'name':
            return (
                <TableRowItemLink
                    title={getString(item, key)}
                    href={`/admin/solutions/${getString(item, 'id')}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case 'slug':
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;

        case 'datasheets':{
            const items = item[key] || [];

            return (
                items.length
                    ? (
                        <>
                            {items.map((elems, baseIndex) => (
                                <div key={baseIndex} className="solution-row">
                                    {elems.map((elem, index) => (
                                        elem ? (
                                                <Fragment key={`${baseIndex}_${index}`}>
                                                    <TableRowItemSpan title={elem}>{elem}</TableRowItemSpan>
                                                </Fragment>
                                            )
                                            : (
                                                <TableRowItemSpan key={`${baseIndex}_${index}`} title={index === 0 ? '-' : ''}>
                                                    {index === 0 ? '-' : ''}
                                                </TableRowItemSpan>
                                            )
                                    ))}
                                </div>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );
        }

        case 'datasheets_si_value_min':
        case 'datasheets_si_value':
        case 'processes_si_value_min':
        case 'processes_si_value':
        case 'datasheets_english_value_min':
        case 'datasheets_english_value':
        case 'processes_english_value_min':
        case 'processes_english_value':  {
            const items = item[key] || [];

            return (
                items.length
                    ? (
                        <>
                            {items.map((elems, baseIndex) => (
                                <div key={baseIndex} className="solution-row text-right">
                                    {elems.map((elem, index) => (
                                        elem ? (
                                                <Fragment key={`${baseIndex}_${index}`}>
                                                    <TableRowItemSpan title={elem}>{elem}</TableRowItemSpan>
                                                </Fragment>
                                            )
                                            : <TableRowItemSpan key={`${baseIndex}_${index}`} title="-">-</TableRowItemSpan>
                                    ))}
                                </div>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title="-" className="text-right">-</TableRowItemSpan>
            );
        }

        case 'datasheets_property':
        case 'datasheets_test_method':
        case 'datasheets_specimen':
        case 'datasheets_test_condition':
        case 'datasheets_si_unit':
        case 'datasheets_english_unit':
        case 'processes_manufacturing':
        case 'processes_parameter':
        case 'processes_description':
        case 'processes_si_unit':
        case 'processes_english_unit': {
            const items = item[key] || [];

            return (
                items.length
                    ? (
                        <>
                            {items.map((elems, baseIndex) => {
                                if (!Array.isArray(elems)) return '-'

                                return (
                                    <div key={baseIndex} className="solution-row">
                                        {elems.map((elem, index) => (
                                            elem ? (
                                                    <Fragment key={`${baseIndex}_${index}`}>
                                                        <TableRowItemSpan title={elem}>{elem}</TableRowItemSpan>
                                                    </Fragment>
                                                )
                                                : <TableRowItemSpan key={`${baseIndex}_${index}`}
                                                                    title="-">-</TableRowItemSpan>
                                        ))}
                                    </div>
                                )
                            })}
                        </>
                    )
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );
        }

        case 'datasheets_approved_by_solution':
        case 'datasheets_approved_by_sharing':
        case 'processes_approved_by_solution':
        case 'processes_approved_by_sharing':
        case 'designs_approved_by_solution':
        case 'designs_approved_by_sharing':
        case 'safeties_approved_by_solution':
        case 'safeties_approved_by_sharing':
        case 'brochures_approved_by_solution':
        case 'brochures_approved_by_sharing':
        case 'datasheets_contacts':
        case 'processes_contacts':
        case 'designs_contacts':
        case 'safeties_contacts':
        case 'brochures_contacts': {
            const items = item[key] || [];
            const links = item[`${key}_id`] || [];

            return (
                items.length
                    ? (
                        <>
                            {items.map((elems, baseIndex) => (
                                <div key={baseIndex} className="solution-row">
                                    {elems.map((elem, index) => (
                                        elem ? (
                                                <Fragment key={`${baseIndex}_${index}`}>
                                                    <TableRowItemLink
                                                        blank
                                                        title={elem}
                                                        href={`/admin/users/${((links[baseIndex] || [])[index]) || ''}`}
                                                    >
                                                        {elem}
                                                    </TableRowItemLink>
                                                </Fragment>
                                            )
                                            : (
                                                <TableRowItemSpan key={`${baseIndex}_${index}`} title={index === 0 ? '-' : ''}>
                                                    {index === 0 ? '-' : ''}
                                                </TableRowItemSpan>
                                            )
                                    ))}
                                </div>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );
        }

        case 'datasheets_legal':
        case 'processes_legal':
        case 'designs_legal': {
            const items = item[key] || [];
            const links = item[`${key}_id`] || [];

            return (
                items.length
                    ? (
                        <>
                            {items.map((elems, baseIndex) => (
                                <div key={baseIndex} className="solution-row">
                                    {elems.map((elem, index) => (
                                        elem ? (
                                                <Fragment key={`${baseIndex}_${index}`}>
                                                    <TableRowItemLink
                                                        blank
                                                        title={elem}
                                                        href={`/legal/copy/${((links[baseIndex] || [])[index]) || ''}`}
                                                    >
                                                        {elem}
                                                    </TableRowItemLink>
                                                </Fragment>
                                            )
                                            : (
                                                <TableRowItemSpan key={`${baseIndex}_${index}`} title={index === 0 ? '-' : ''}>
                                                    {index === 0 ? '-' : ''}
                                                </TableRowItemSpan>
                                            )
                                    ))}
                                </div>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );
        }

        case 'datasheets_contents':
        case 'processes_contents':
        case 'designs_contents':
            const items = item[key] || [];
            return (
                items.length
                    ? (
                        <>
                            {items.map((elems, baseIndex) => (
                                <div key={baseIndex} className="solution-row">
                                    {elems.map((elem, index) => (
                                        elem ? (
                                                <Fragment key={`${baseIndex}_${index}`}>
                                                    <TableRowItemTooltip>{elem}</TableRowItemTooltip>
                                                </Fragment>
                                            )
                                            : (
                                                <TableRowItemSpan key={`${baseIndex}_${index}`} title={index === 0 ? '-' : ''}>
                                                    {index === 0 ? '-' : ''}
                                                </TableRowItemSpan>
                                            )
                                    ))}
                                </div>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
