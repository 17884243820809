import React from "react";
import {compose} from "redux";
import {useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

import {ContentWrap, ContentWrapCol} from "Templates/Content";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";

import TabCheck from "Templates/Tabs/TabСheck";
import {activeWeightUnit} from 'App/View/Header/Items/Units/Actions/ConverterUnitsAction';
import _projectsService from "../Services/Tabs/Projects";
import Projects from "./Tabs/Projects";

import _forecastBuildsService from "../Services/Tabs/ForecastBuilds";
import ForecastBuilds from "./Tabs/ForecastBuilds";

import _notesService from "../Services/Tabs/Notes";
import Notes from "./Tabs/Notes";

const MainBlock = ({ id, activeTab, totalNotes, totalProjects, t, onChangeTab }) => {
    const dispatch = useDispatch();
    const projectsService = _projectsService(id);
    const forecastBuildsService = _forecastBuildsService(id);
    const notesService = _notesService(id);

    dispatch(activeWeightUnit([1].includes(activeTab)));

    return (
        <ContentWrap>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index)=> {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >

                    <Tab label={t("Forecast Units")}>
                        <ServiceProvider value={forecastBuildsService}>
                            <ForecastBuilds />
                        </ServiceProvider>
                    </Tab>

                    <TabCheck label={t("Projects")} permission={'forecast_all_units_project_tab'} counter={totalProjects}>
                        <ServiceProvider value={projectsService}>
                            <Projects />
                        </ServiceProvider>
                    </TabCheck>

                    <Tab label={t(`Notes`)} counter={totalNotes}>
                        <ServiceProvider value={notesService}>
                            <Notes />
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

MainBlock.defaultProps = {
    totalNotes: 0,
    totalProjects: 0
}

MainBlock.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    totalNotes: PropTypes.number,
    totalProjects: PropTypes.number,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
        totalNotes: getStoreItem(state, "totalNotes"),
        totalProjects: getStoreItem(state, "totalProjects"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MainBlock);
