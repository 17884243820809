import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { ModalDefault } from "Templates/Modals";
import { ButtonClose } from "Templates/Button";
import { withServiceConsumer } from "Services/Context";

const { Button, Form, Title, Col, Row, ColText, ColInput } = ModalDefault;

const propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const StatusDateModalForm = ({ onClose, onSave, onChange, t }) => {
    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <Title>{t('Loss of Sales Date')}</Title>
            <Row>
                <Col>
                    <ColText className="bold-text">
                        {t("Loss of Sales Date")}
                    </ColText>
                    <ColInput
                        type="date-select"
                        name="losDate"
                        value=""
                        placeholder={t("MM/DD/YYYY")}
                        onChange={value => {
                            onChange(value)
                        }}
                    />
                </Col>
            </Row>
            <Button onClick={onSave}>
                {t("Save")}
            </Button>
        </Form>
    );
}

StatusDateModalForm.propTypes = propTypes;

const mapStateToProps = () => {
};

const mapDispatchToProps = () => {
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(StatusDateModalForm);
