const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    RESET_FILTER: "RESET_FILTER",
    RELOAD_FILTER: "RELOAD_FILTER",

    FETCH_GROUP_LIST_REQUESTED: "FETCH_GROUP_LIST_REQUESTED",
    FETCH_GROUP_LIST_SUCCEEDED: "FETCH_GROUP_LIST_SUCCEEDED",
    FETCH_GROUP_LIST_FAILED: "FETCH_GROUP_LIST_FAILED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",
    SAVED_FILTER_DATA_REQUESTED: "SAVED_FILTER_DATA_REQUESTED",
    SAVED_FILTER_DATA_SUCCEEDED: "SAVED_FILTER_DATA_SUCCEEDED",
    SAVED_FILTER_DATA_FAILED: "SAVED_FILTER_DATA_FAILED",

    CHANGE_TAB: "CHANGE_TAB",

    SAVE_SHARE_FILTERS_SUCCESS: "SAVE_SHARE_FILTERS_SUCCESS",
    SAVE_SHARE_FILTERS_FAILED: "SAVE_SHARE_FILTERS_FAILED",

    UPDATE_GROUPS_FORM: "UPDATE_GROUPS_FORM",

    SAVE_INVITE_SUCCESS: "SAVE_INVITE_SUCCESS",
    SAVE_INVITE_FAILED: "SAVE_INVITE_FAILED",
    CHANGE_INVITE_FORM: "CHANGE_INVITE_FORM",
    SAVE_FILTER_SUCCESS: "SAVE_FILTER_SUCCESS",
    SAVE_FILTER_FAILED: "SAVE_FILTER_FAILED",
    CHANGE_FILTER_FORM: "CHANGE_FILTER_FORM",
    CLOSE_MODAL: "CLOSE_MODAL",
    OPEN_MODAL: "OPEN_MODAL",
    FILTER_CLOSE_MODAL: "FILTER_CLOSE_MODAL",
    FILTER_OPEN_MODAL: "FILTER_OPEN_MODAL",
    SHARE_FILTERS_CLOSE_MODAL: "SHARE_FILTERS_CLOSE_MODAL",
    SHARE_FILTERS_OPEN_MODAL: "SHARE_FILTERS_OPEN_MODAL",
    WELCOME_GUEST_CLOSE_MODAL: "WELCOME_GUEST_CLOSE_MODAL",
    WELCOME_GUEST_OPEN_MODAL: "WELCOME_GUEST_OPEN_MODAL",

    ADD_SHARED_PRODUCTS_PUBLICATION_LIST_OPEN_MODAL: "ADD_SHARED_PRODUCTS_PUBLICATION_LIST_OPEN_MODAL",
    ADD_SHARED_PRODUCTS_PUBLICATION_LIST_CLOSE_MODAL: "ADD_SHARED_PRODUCTS_PUBLICATION_LIST_CLOSE_MODAL",

    ADD_PUBLICATION_LIST_OPEN_MODAL: "ADD_PUBLICATION_LIST_OPEN_MODAL",
    ADD_PUBLICATION_LIST_CLOSE_MODAL: "ADD_PUBLICATION_LIST_CLOSE_MODAL",
    SAVE_PUBLICATION_LIST: "SAVE_PUBLICATION_LIST",
    CHANGE_ADD_PUBLICATION_LIST_FORM: "CHANGE_ADD_PUBLICATION_LIST_FORM",

    EXPORT_PRODUCTS: "EXPORT_PRODUCTS",
    EXPORT_PRODUCTS_SUCCESS: "EXPORT_PRODUCTS_SUCCESS",
    EXPORT_PRODUCTS_FAILED: "EXPORT_PRODUCTS_FAILED",
};

export default Constants;
