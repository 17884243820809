import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const InfoContactWrapper = ({ pref, children }) => (
    <div className={`${pref}__info-contact-wrapper`}>{children}</div>
);

InfoContactWrapper.defaultProps = {
    children: "",
};

export default compose(withTagDefaultProps())(InfoContactWrapper);
