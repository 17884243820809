import React from "react";
import _ from "lodash";
import {compose} from "redux";
import PropTypes from "prop-types";
import {formatDateToLocal, storageUrl} from "Services";
import {withTagDefaultProps} from "Hoc/Template";
import {NoteTop, NoteTopDate, NoteTopUser} from "../index";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    storage_url: PropTypes.string.isRequired,
};

const NoteTopWrap = ({ item, storage_url }) => (
    <NoteTop>
        <NoteTopUser
            img={storageUrl(_.get(item, "avatar"), storage_url)}
            label={item.name}
            company={item.company}
            role={item.role}
            url={`/admin/users/${_.get(item, "contact_id")}`}
        />
        <NoteTopDate>{formatDateToLocal(item.date, "MMM DD, YYYY h:mm A")}</NoteTopDate>
    </NoteTop>
);

NoteTopWrap.propTypes = propTypes;

export default compose(withTagDefaultProps())(NoteTopWrap);
