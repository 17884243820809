import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import Img from "Templates/Img";
import { withTagDefaultProps } from "Hoc/Template";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    index: PropTypes.number.isRequired,
    onClickEdit: PropTypes.func.isRequired,
};

const TableRowItem = ({ index, onClickEdit }) => {
    const _onClickEdit = () => {
        onClickEdit(index);
    };

    return (
        <TableBodyButtonsBlock>
            <TableBodyButton onClick={_onClickEdit}>
                <Img img="icon_edit" />
            </TableBodyButton>
        </TableBodyButtonsBlock>
    );
};

TableRowItem.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowItem);
