import React from "react";
import {compose} from "redux";
import {useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import TabCheck from "Templates/Tabs/TabСheck";
import Tab from "Templates/Tabs/Tab";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import {url} from "Services";
import InfoWrap from "./Views/InfoWrap";
import {activeWeightUnit} from 'App/View/Header/Items/Units/Actions/ConverterUnitsAction';

import General from "./Views/Tabs/General";
import _generalService from "./Services/Tabs/General";

import _forecastService from "./Services/Tabs/Forecast";

// import _tasksService from "./Services/Tabs/Tasks";
// import Tasks from "./Views/Tabs/Tasks";
import _notesService from "./Services/Tabs/Notes";
import Notes from "./Views/Tabs/Notes";

import _filesService from "./Services/Tabs/Files";
import _changesHistoryService from "./Services/Tabs/ChangesHistory";
import Files from "./Views/Tabs/Files";

import CostModel from "./Views/Tabs/CostModel";
import _costModelProjectService from "./Services/Tabs/CostModel";
import ChangesHistory from "./Views/Tabs/ChangesHistory";

const Projects = ({ id, activeTab, totalNotes, totalFiles, t, onChangeTab }) => {
    const dispatch = useDispatch();
    const onClickEdit = () => {
        url.redirect(`/project/projects/${id}/edit`);
    };

    const generalService = _generalService(id);
    const forecastService = _forecastService(id);
    // const tasksService = _tasksService(id);
    const notesService = _notesService(id);
    const filesService = _filesService(id);
    const changesHistoryService = _changesHistoryService(id);
    const costModelProjectService = _costModelProjectService(id);

    dispatch(activeWeightUnit([0,1,4].includes(activeTab)));

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit} />
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index, e) => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Details")}>
                        <ServiceProvider value={generalService}>
                            <General />
                        </ServiceProvider>
                    </Tab>

                    {/*<Tab label={t("Forecast")}>*/}
                    {/*    <ServiceProvider value={forecastService}>*/}
                    {/*        <Forecast />*/}
                    {/*    </ServiceProvider>*/}
                    {/*</Tab>*/}

                    {/*<Tab label={t("Tasks")}>*/}
                    {/*    <ServiceProvider value={tasksService}>*/}
                    {/*        <Tasks />*/}
                    {/*    </ServiceProvider>*/}
                    {/*</Tab>*/}

                    <Tab label={t(`Notes`)} counter={totalNotes}>
                        <ServiceProvider value={notesService}>
                            <Notes />
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Files")} counter={totalFiles}>
                        <ServiceProvider value={filesService}>
                            <Files />
                        </ServiceProvider>
                    </Tab>
                    <TabCheck label={t("Cost Model")} permission={'project_view_cost'}>
                        <ServiceProvider value={costModelProjectService}>
                            <CostModel />
                        </ServiceProvider>
                    </TabCheck>
                    <Tab label={t("History")}>
                        <ServiceProvider value={changesHistoryService}>
                            <ChangesHistory />
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Projects.defaultProps = {
    totalNotes: 0,
    totalFiles: 0
}

Projects.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    totalNotes: PropTypes.number,
    totalFiles: PropTypes.number,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        totalNotes: getStoreItem(state, "totalNotes"),
        totalFiles: getStoreItem(state, "totalFiles"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Projects);
