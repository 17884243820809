import {formDataNormalizer} from "../../../../Services";

export const budgetFormData = ({ productCode, applicationCode, plant, materialType, years }) => {
    const yearsArr = Object.keys(years);

    const dataReq = {
        product_code: productCode,
        application_code: applicationCode,
        plant: plant,
        materialType: materialType,
        dataYears: yearsArr[0] === '' ? {} : years,
    };

    return formDataNormalizer(dataReq);
};