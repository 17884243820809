import React from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Button} from "Templates/Default";
import PropTypes from "prop-types";
import {Row} from "Templates/Table/NewVersion";

const propTypes = {
    addApplication: PropTypes.func.isRequired,
};

const TableFooterColumn = ({ addApplication }) => {
    return (
        <Row>
            <td>
                <Button
                    onClick={() => {
                        addApplication()
                    }}
                    className="size_normal main-btn main-btn_white"
                >
                    Add Sub-Segment
                </Button>
            </td>
        </Row>
    );
};

TableFooterColumn.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    return ownProps.service;
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        addApplication: getActionStore("addApplication", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TableFooterColumn);
