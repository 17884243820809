import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const Row = ({ pref, children }) => {
    return <div className={`${pref}__row`}>{children}</div>;
};

Row.propTypes = {};

export default compose(withTagDefaultProps())(Row);
