import React from "react";
import {compose} from "redux";
import {useDispatch} from 'react-redux';
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import {url} from "Services";
import TabCheck from "Templates/Tabs/TabСheck";
import Tab from "Templates/Tabs/Tab";
import {activeWeightUnit} from '../../../../App/View/Header/Items/Units/Actions/ConverterUnitsAction';
import InfoWrap from "./Views/InfoWrap";
import OldInfoWrap from "./Views/OldInfoWrap";

import _projectsGradesService from "./Services/Tabs/Projects";
import _invoicesGradesService from "./Services/Tabs/Invoices";
import _filesLinesService from "./Services/Tabs/Files";
import _safetyService from "./Services/Tabs/Safety";
import _brochureService from "./Services/Tabs/Brochure";
import _costModelGradesService from "./Services/Tabs/CostModel";
import _analyticsGradesService from "./Services/Tabs/Analytics";
import _technicalGradesService from "./Services/Tabs/Technical";
import _designGradesService from "./Services/Tabs/Design";
import _processingGradesService from "./Services/Tabs/Processing";

import Technical from "./Views/Tabs/Technical";
import Processing from "./Views/Tabs/Processing";
import Files from "./Views/Tabs/Files";
import Projects from "./Views/Tabs/Projects";
import Invoices from "./Views/Tabs/Invoices";
import CostModel from "./Views/Tabs/CostModel";
import Analytics from "./Views/Tabs/Analytics";
import Notes from "./Views/Tabs/Notes";
import Safety from "./Views/Tabs/Safety";
import Brochure from "./Views/Tabs/Brochure";
import _notesService from "./Services/Tabs/Notes";
import Design from "./Views/Tabs/Design";

const Grade = ({id, activeTab, isSap, totalNotes, totalFiles, totalProjects, totalInvoices, totalTechnicals, totalProcesses, totalDesigns, totalSafeties, totalBrochures, t, onChangeTab, permissions}) => {
    const dispatch = useDispatch();
    const onClickEdit = () => {
        url.redirect(`/product/product-finder/${id}/edit`);
    };

    const projectsGradesService = _projectsGradesService(id);
    const analyticsGradesService = _analyticsGradesService(id);
    const invoicesGradesService = _invoicesGradesService(id);
    const notesService = _notesService(id);

    const infoWrapView = () => {
        if (permissions.includes("grade_product_finder_view")) {
            return <InfoWrap activeTab={activeTab} onClickEdit={onClickEdit} />
        }

        return <OldInfoWrap onClickEdit={onClickEdit} />
    }

    // if (!isSap) {
    //     if (activeTab === 0) {
    //         onChangeTab(activeTab);
    //     }
    //
    //     return (
    //         <ContentWrap>
    //             {infoWrapView()}
    //
    //             <ContentWrapCol>
    //                 <Tabs
    //                     activeTab={activeTab}
    //                     onClickTabItem={(index)=> {
    //                         if (activeTab !== index) {
    //                             onChangeTab(index);
    //                         }
    //                     }}
    //                 >
    //                     <TabCheck label={t("Projects")} counter={totalProjects} permission="product_project_view">
    //                         <ServiceProvider value={projectsGradesService}>
    //                             <Projects />
    //                         </ServiceProvider>
    //                     </TabCheck>
    //
    //                     <TabCheck label={t("Analytics")} permission="grade_product_analytics">
    //                         <ServiceProvider value={analyticsGradesService}>
    //                             <Analytics />
    //                         </ServiceProvider>
    //                     </TabCheck>
    //
    //                     <TabCheck label={t("Invoices")} counter={totalInvoices} permission="grade_product_invoices">
    //                         <ServiceProvider value={invoicesGradesService}>
    //                             <Invoices />
    //                         </ServiceProvider>
    //                     </TabCheck>
    //
    //                     <TabCheck label={t(`Notes`)} counter={totalNotes} permission="product_note_view" productOnly>
    //                         <ServiceProvider value={notesService}>
    //                             <Notes />
    //                         </ServiceProvider>
    //                     </TabCheck>
    //
    //                 </Tabs>
    //             </ContentWrapCol>
    //         </ContentWrap>
    //     );
    // }

    const costModelGradesService = _costModelGradesService(id);
    const filesLinesService = _filesLinesService(id);
    const safetyService = _safetyService(id);
    const brochureService = _brochureService(id);
    const technicalService = _technicalGradesService(id);
    const processingService = _processingGradesService(id);
    const designService = _designGradesService(id);
    dispatch(activeWeightUnit([6, 8, 9].includes(activeTab)));

    if (
        (!permissions.includes("grade_product_invoices") && activeTab === 10)
        || (!permissions.includes("grade_product_analytics") && activeTab === 9)
    ) {
        activeTab = 0;
    }
    if (!permissions.includes("grade_product_finder_view") && activeTab < 5) {
        activeTab = 5;
        onChangeTab(activeTab);
    }

    return (
        <ContentWrap>
            {infoWrapView()}

            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index)=> {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <TabCheck label={t("Technical")} counter={totalTechnicals} permission="grade_product_finder_view">
                        <ServiceProvider value={technicalService}>
                            <Technical />
                        </ServiceProvider>
                    </TabCheck>

                    <TabCheck label={t("Processing")} counter={totalProcesses} permission="grade_product_finder_view">
                        <ServiceProvider value={processingService}>
                            <Processing />
                        </ServiceProvider>
                    </TabCheck>

                    <TabCheck label={t("Guides")} counter={totalDesigns} permission="grade_product_finder_view">
                        <ServiceProvider value={designService}>
                            <Design />
                        </ServiceProvider>
                    </TabCheck>

                    <TabCheck label={t("Safety")} counter={totalSafeties} permission="grade_product_finder_view">
                        <ServiceProvider value={safetyService}>
                            <Safety />
                        </ServiceProvider>
                    </TabCheck>

                    <TabCheck label={t("Brochure")} counter={totalBrochures} permission="grade_product_finder_view">
                        <ServiceProvider value={brochureService}>
                            <Brochure />
                        </ServiceProvider>
                    </TabCheck>

                    <TabCheck label={t("Projects")} counter={totalProjects} permission="product_project_view">
                        <ServiceProvider value={projectsGradesService}>
                            <Projects />
                        </ServiceProvider>
                    </TabCheck>

                    <Tab label={t("Files")} counter={totalFiles}>
                        <ServiceProvider value={filesLinesService}>
                            <Files />
                        </ServiceProvider>
                    </Tab>

                    <TabCheck label={t(`Notes`)} counter={totalNotes} permission="product_note_view" productOnly>
                        <ServiceProvider value={notesService}>
                            <Notes />
                        </ServiceProvider>
                    </TabCheck>

                    <TabCheck label={t("Cost Model")} permission="grade_product_crud_cost" productOnly>
                        <ServiceProvider value={costModelGradesService}>
                            <CostModel />
                        </ServiceProvider>
                    </TabCheck>

                    <TabCheck label={t("Analytics")} permission="grade_product_analytics">
                        <ServiceProvider value={analyticsGradesService}>
                            <Analytics />
                        </ServiceProvider>
                    </TabCheck>

                    <TabCheck label={t("Invoices")} counter={totalInvoices} permission="grade_product_invoices">
                        <ServiceProvider value={invoicesGradesService}>
                            <Invoices />
                        </ServiceProvider>
                    </TabCheck>

                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Grade.propTypes = {
    id: PropTypes.string.isRequired,
    totalNotes: PropTypes.number.isRequired,
    totalFiles: PropTypes.number.isRequired,
    totalProjects: PropTypes.number.isRequired,
    totalInvoices: PropTypes.number.isRequired,
    totalTechnicals: PropTypes.number.isRequired,
    totalProcesses: PropTypes.number.isRequired,
    totalDesigns: PropTypes.number.isRequired,
    totalSafeties: PropTypes.number.isRequired,
    totalBrochures: PropTypes.number.isRequired,
    activeTab: PropTypes.number.isRequired,
    isSap: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeTab: PropTypes.func.isRequired
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        isSap: getStoreItem(state, "view.isSap"),
        activeTab: getStoreItem(state, "activeTab"),
        totalNotes: getStoreItem(state, "totalNotes"),
        totalFiles: getStoreItem(state, "totalFiles"),
        totalProjects: getStoreItem(state, "totalProjects"),
        totalInvoices: getStoreItem(state, "totalInvoices"),
        totalTechnicals: getStoreItem(state, "totalTechnicals"),
        totalProcesses: getStoreItem(state, "totalProcesses"),
        totalDesigns: getStoreItem(state, "totalDesigns"),
        totalSafeties: getStoreItem(state, "totalSafeties"),
        totalBrochures: getStoreItem(state, "totalBrochures"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Grade);
