import {marginEnum} from "Services/enum";
import main from "../index";
import {formData} from "./FormService";
import {getActionStore, getStoreItem} from "./StoreService";
import {getData} from "../RequestService";

const optionsForFirstSelect = marginEnum;
export default () => ({
    ...main,
    getData: getData("/forecasts/graphs-v2/product-codes"),
    optionsForFirstSelect,
    getStoreItem,
    getActionStore,
    formData,
});
