import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import QuickEditTableRowItemEdit from "./QuickEditTableRowItemEdit";

const defaultProps = {
    customIcon: "",
    rowId: null,
    invalidQuickEdit: false,
    onClickCustom: () => {},
    onClickUnlock: () => {},
    onClickSave: () => {},
};

const propTypes = {
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    rowId: PropTypes.string,
    invalidQuickEdit: PropTypes.bool,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickUnlock: PropTypes.func,
    onClickSave: PropTypes.func,
    index: PropTypes.number.isRequired,
};

const QuickEditTableRowColumnButton = ({ index, customIcon, item, rowId, invalidQuickEdit, onClickCustom, onClickDelete, onClickEdit, onClickUnlock, onClickSave }) => {
    const _onClickDelete = () => {
        onClickDelete(index);
    };
    const _onClickEdit = () => {
        onClickEdit(index);
    };

    const _onClickUnlock = () => {
        onClickUnlock(index);
    };

    const _onClickCustom = () => {
        onClickCustom(index);
    };

    const _onClickSave = () => {
        onClickSave(index);
    };

    return (
            <QuickEditTableRowItemEdit
                item={item}
                rowId={rowId}
                invalidQuickEdit={invalidQuickEdit}
                customIcon={typeof customIcon === "function" ? customIcon(index) : customIcon}
                onClickCustom={_onClickCustom}
                onClickUnlock={_onClickUnlock}
                onClickDelete={_onClickDelete}
                onClickEdit={_onClickEdit}
                onClickSave={_onClickSave}
            />
    );
};

QuickEditTableRowColumnButton.defaultProps = defaultProps;
QuickEditTableRowColumnButton.propTypes = propTypes;

export default compose(withTagDefaultProps())(QuickEditTableRowColumnButton);
