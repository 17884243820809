import React, {Fragment, useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Block, BlockMain, BlockShadow, BlockWrap, Input, WrapInput} from "Templates/Form";
import {uniqueGenerator} from "Services";
import {LinkIcon} from "Templates/Link";
import {Button} from "Templates/Default";
import Img from "Templates/Img";

const propTypes = {
    colorsPartApproval: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired
};

const ColorsPartApproval = (props) => {
    const {
        colorsPartApproval,
        t,
        onChange
    } = props;

    const [colorFields, setColorFields] = useState([
        {
            frontId: 1,
            color: '',
            items: [
                {
                    frontId: 1,
                    approval: '',
                    code: '',
                    note: '',
                }
            ]
        }
    ])

    useEffect(() => {
        if (colorsPartApproval.length) {
            setColorFields(colorsPartApproval)
        }
    }, [])

    useEffect(() => {
        if (!colorFields.length) return
        onChange('colorsPartApproval', colorFields)
    }, [colorFields])

    const onChangeColor = (colorItem, value) => {
        const filteredColors = colorFields.filter((item) => {
            return item.color !== colorItem.color
        })
        const existedColor = filteredColors.findIndex((item) => item.color === value)

        if (existedColor !== -1) {
            alert('This color has been chosen already! Please choose another color.')
            setColorFields((prev) => [...prev])

            return
        }

        colorItem.color = value
        setColorFields((prev) => [...prev])
    }

    const addApproval = (index, items) => {
        const lastElement = items.find((_, i) => i === items.length - 1)
        if (lastElement) {
            const newApproval = {
                frontId: `${lastElement.frontId}-${uniqueGenerator()}`,
                approval: '',
                code: '',
                note: '',
            }

            items.push(newApproval)

            setColorFields((prev) => {
                return [...prev]
            })
        }
    }

    const removeApproval = (index, subIndex) => {
        setColorFields((prev) => {
            prev[index].items.splice(subIndex, 1)
            return [...prev]
        })
    }

    const addColor = (frontId) => {
        const newColor = {
            frontId: uniqueGenerator(),
            color: '',
            items: [
                {
                    frontId: `${frontId}-${uniqueGenerator()}`,
                    approval: '',
                    code: '',
                    note: '',
                }
            ]
        }

        colorFields.push(newColor)

        setColorFields((prev) => {
            return [...prev]
        })
    }

    const removeColor = (index) => {
        setColorFields((prev) => {
            prev.splice(index, 1)
            return [...prev]
        })
    }

    return (
        <BlockMain title={t("Part Approval")}>
            <BlockShadow className="grade-edit-class_color_shadow">
                {colorFields.length && colorFields.map((colorItem, index) => {
                    return (
                        <Fragment key={`${colorItem.frontId}-color-${index}`}>
                            <div className="colors-block">
                                <div className="colors">
                                    <Block className="colors-wrap">
                                        <BlockWrap>
                                            <WrapInput
                                                name={`${colorItem.frontId}.color`}
                                                label={t("Color")}
                                            >
                                                <Input
                                                    name={`color-${index}`}
                                                    type="asyncSelect"
                                                    placeholder={t("Search")}
                                                    url="/colors/color-list"
                                                    value={colorItem.color}
                                                    onChange={value => {
                                                        onChangeColor(colorItem, value)
                                                    }}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                        <BlockWrap>
                                            {colorItem.items.map((item, subIndex) => {
                                                return (
                                                    <Fragment key={`${item.frontId}-approval-${subIndex}`}>
                                                        <WrapInput
                                                            name={`${item.frontId}.approval`}
                                                            label={subIndex === 0 ? t("Custom Manufacturer") : ""}
                                                        >
                                                            <Input
                                                                name={`approval-${subIndex}`}
                                                                type="asyncSelect"
                                                                placeholder={t("Search")}
                                                                url="product/product-finder/part-approval-custom-list"
                                                                value={item.approval}
                                                                onChange={value => {
                                                                    item.approval = value
                                                                    setColorFields((prev) => [...prev])
                                                                }}
                                                            />
                                                        </WrapInput>
                                                    </Fragment>
                                                )
                                            })}
                                        </BlockWrap>
                                        <BlockWrap>
                                            {colorItem.items.map((item, subIndex) => {
                                                return (
                                                    <Fragment key={`${item.frontId}-color-code-${subIndex}`}>
                                                        <WrapInput
                                                            name={`${item.frontId}.color_code`}
                                                            label={subIndex === 0 ? t("Color Code") : ""}
                                                        >
                                                            <div
                                                                className={colorItem.items.length > 1 ? 'colors-item-delete' : ''}
                                                            >
                                                                <Input
                                                                    name={`color_code-${subIndex}`}
                                                                    type="asyncSelect"
                                                                    placeholder={t("Search")}
                                                                    url="/colors/color-code-list"
                                                                    value={item.code}
                                                                    onChange={value => {
                                                                        item.code = value
                                                                        setColorFields((prev) => [...prev])
                                                                    }}
                                                                />
                                                            </div>
                                                        </WrapInput>
                                                    </Fragment>
                                                )
                                            })}
                                        </BlockWrap>
                                        <BlockWrap>
                                            {colorItem.items.map((item, subIndex) => {
                                                return (
                                                    <Fragment key={`${item.frontId}-color-note-${subIndex}`}>
                                                        <WrapInput
                                                            name={`${item.frontId}.note`}
                                                            label={subIndex === 0 ? t("Notes") : ""}
                                                        >
                                                            <div
                                                                className={colorItem.items.length > 1 ? 'colors-item-delete notes-field' : ''}
                                                            >
                                                                <Input
                                                                    name={`${item.frontId}.note`}
                                                                    value={item.note}
                                                                    placeholder="Input"
                                                                    onChange={value => {
                                                                        item.note = value
                                                                        setColorFields((prev) => [...prev])
                                                                    }}
                                                                />
                                                                {colorItem.items.length > 1 && (
                                                                    <LinkIcon
                                                                        className="icon-delete"
                                                                        icon="la-icon-close"
                                                                        onClick={
                                                                            () => {
                                                                                removeApproval(index, subIndex)
                                                                            }
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </WrapInput>
                                                    </Fragment>
                                                )
                                            })}
                                        </BlockWrap>
                                    </Block>
                                    <Block className="colors-wrap">
                                        <BlockWrap/>
                                        <BlockWrap>
                                            <Button
                                                onClick={() => {
                                                    addApproval(index, colorItem.items)
                                                }}
                                                className="size_normal main-btn main-btn_white"
                                            >
                                                Add Custom Manufacturer
                                            </Button>
                                        </BlockWrap>
                                        <BlockWrap/>
                                        <BlockWrap/>
                                    </Block>
                                </div>
                                {colorFields.length > 1 && (
                                    <Button
                                        className="remove-color"
                                        onClick={() => {
                                            removeColor(index)
                                        }}
                                    >
                                        <Img img="icon_delete"/>
                                    </Button>
                                )}
                            </div>
                            {index === colorFields.length - 1 && (
                                <Button
                                    className="size_normal main-btn main-btn_white"
                                    onClick={() => {
                                        addColor(colorItem.frontId)
                                    }}
                                >
                                    Add Part Approval
                                </Button>
                            )}
                        </Fragment>
                    )
                })}
            </BlockShadow>
        </BlockMain>
    );
}

ColorsPartApproval.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        colorsPartApproval: getStoreItem(state, 'colorsPartApproval'),
    };
};

const mapDispatchToProps = (dispatch, {
    service
}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore('onChange', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ColorsPartApproval);
