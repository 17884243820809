import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
};

const Close = ({ className, onClick }) => {
    const [focus, setFocus] = useState(false)

    return <button
        tabIndex={0}
        type="button"
        className={`${className} ${focus ? 'focus' : ''}`}
        onClick={onClick}
        onFocus={() => {
            setFocus(true)
        }}
        onBlur={() => {
            setFocus(false)
        }}
    />;
}

Close.propTypes = propTypes;

export default Close;
