import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TableBodyButton from "Templates/Table/TableBodyButton";
import Img from "Templates/Img";
import {
    SingleContent,
    SingleColFirst,
    SinglePreviewRow,
    SinglePreviewCol,
    SinglePreview,
    SingleColMeta,
    SingleMetaDate,
    SinglePreviewStatus,
} from "Templates/SinglePreview";
import { formatDate, formatDateAddUtcOffset } from "Services";
import { LinkImg } from "Templates/Link";

const propTypes = {
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    subStatus: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    due: PropTypes.string.isRequired,
    project: PropTypes.objectOf(PropTypes.any).isRequired,
    assignedTo: PropTypes.objectOf(PropTypes.any).isRequired,
    assignedBy: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        listTypes: PropTypes.shape({ [PropTypes.string]: PropTypes.number }).isRequired,
        statusColor: PropTypes.func.isRequired,
        statusLabel: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    changeStatus: PropTypes.func.isRequired,
};

const renderStatusButton = (statusList, status, onClickUnComplete, onClickComplete) => {
    if (status === statusList.COMPLETED) {
        return (
            <TableBodyButton onClick={onClickUnComplete}>
                <Img img="icon-close" />
            </TableBodyButton>
        );
    }

    return (
        <TableBodyButton onClick={onClickComplete}>
            <Img img="icon_check_green" />
        </TableBodyButton>
    );
};

const InfoWrap = ({
    id,
    date,
    subStatus,
    project,
    assignedTo,
    assignedBy,
    service,
    description,
    start,
    due,
    changeStatus,
    t,
}) => {
    const onClickUnComplete = () => {
        if (window.confirm(t("Do you want to cancel the completed task?"))) {
            changeStatus(`/tasks/${id}/un-complete`, 1);
        }
    };

    const onClickComplete = () => {
        if (window.confirm(t("Do you want to complete task?"))) {
            changeStatus(`/tasks/${id}/complete`, 4);
        }
    };

    return (
        <SingleContent>
            <SingleColFirst>
                <SinglePreview>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Start Date")}</SinglePreviewCol>
                        <SinglePreviewCol>{formatDate(start)}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Due Date")}</SinglePreviewCol>
                        <SinglePreviewCol>{formatDate(due)}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Status")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <SinglePreviewStatus status={service.statusColor(subStatus)}>
                                {t(service.statusLabel(subStatus))}
                            </SinglePreviewStatus>
                            {renderStatusButton(service.listTypes, subStatus, onClickUnComplete, onClickComplete)}
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Assigned to")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <LinkImg img={assignedTo.img} href={`/admin/users/${assignedTo.id}`}>
                                {assignedTo.name}
                            </LinkImg>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Assigned by")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <LinkImg img={assignedBy.img} href={`/admin/users/${assignedBy.id}`}>
                                {assignedBy.name}
                            </LinkImg>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Project")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <a href={`/project/projects/${project.id}`}>{project.name}</a>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Details")}</SinglePreviewCol>
                        <SinglePreviewCol>{description}</SinglePreviewCol>
                    </SinglePreviewRow>
                </SinglePreview>
            </SingleColFirst>
            <SingleColMeta>
                <SingleMetaDate>{formatDateAddUtcOffset(date)}</SingleMetaDate>
            </SingleColMeta>
        </SingleContent>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        date: getStoreItem(state, "view.date"),
        project: getStoreItem(state, "view.project"),
        assignedTo: getStoreItem(state, "view.assignedTo"),
        assignedBy: getStoreItem(state, "view.assignedBy"),
        description: getStoreItem(state, "view.description"),
        start: getStoreItem(state, "view.start"),
        due: getStoreItem(state, "view.due"),
        subStatus: getStoreItem(state, "view.sub_status"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        changeStatus: getActionStore("changeStatusAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(InfoWrap);
