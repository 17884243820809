import Constants from "../../Constants";

export const editNoteAction = (service, dispatch) => data => {
    dispatch({ type: Constants.notes.EDIT_NOTE_REQUESTED });

    const { editNotesRequest } = service;

    return editNotesRequest(data.id)(data).then(
        res => dispatch({ type: Constants.notes.EDIT_NOTE_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.notes.EDIT_NOTE_FAILED, data: res });
        },
    );
};

export const deleteNoteAction = (service, dispatch) => data => {
    dispatch({ type: Constants.notes.DELETE_NOTE_REQUESTED });

    const { deleteNotesRequest } = service;

    return deleteNotesRequest(data.id).then(
        res => dispatch({ type: Constants.notes.DELETE_NOTE_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.notes.DELETE_NOTE_FAILED, data: res });
        },
    );
};