const Constants = {
    CHANGE_FORM: "CHANGE_FORM",
    SET_NICKNAME: "SET_NICKNAME",
    SET_PHONE: "SET_PHONE",
    FORM_REQUESTED: "FORM_REQUESTED",
    FORM_SUCCEEDED: "FORM_SUCCEEDED",
    FORM_FAILED: "FORM_FAILED",
    FORM_RESET: "FORM_RESET"
};

export default Constants;
