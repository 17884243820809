import React from "react";
import {useSelector} from 'react-redux';
import {weightUnitName} from 'Services/weightUnitName';
import {formatDate, getString, numberWithCommas} from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {gradeStatus, projectStatus} from "Services/enum";
import _ from "lodash";
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";

export const getBudgetViewItem = (key, item) => {
    const unit = useSelector(state => state.weightUnitState);

    switch (key) {
        case "slug":
            return (
                <TableRowItemLink
                    title={getString(item, key)}
                    href={`/connection/budgets/${getString(item, "budget_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "detail":
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, key)}
                    href={`/connection/connections?filter%5Bproduct_code%5D%5B%5D=${item.product_code_id}&filter%5Bsap_application_code%5D%5B%5D=${item.application_code_id}&filter%5Bplant%5D%5B%5D=${item.plant_id}&filter%5Bmaterial_type%5D%5B%5D=${item.material_type}`}
                >
                    Show Details
                </TableRowItemLink>
            )

        case "next_average_sale_price":
        case "current_average_sale_price":
        case "actual_calculated_price":
        case "budget_price": {
            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(getString(item, key)).toFixed(2)}`}
                </TableRowItemSpan>
            );
        }

        case "next_revenue":
        case "next_total_revenue":
        case "next_total_rebate":
        case "current_revenue":
        case "current_total_revenue":
        case "current_total_rebate":
        case "budget_revenue":
        case "stretch_revenue":
        case "variance_revenue":
        case "annualized_revenue":
        case "actual_net_amount": {
            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(getString(item, key)) === 0 ? '0' : numberWithCommas(getString(item, key), 0)}`}
                </TableRowItemSpan>
            );
        }

        case "current_variance_percent": {
            return (
                <TableRowItemSpan className="text-right">
                    {`${Number(getString(item, key)) === 0 ? '0' : numberWithCommas(getString(item, key), 0)} %`}
                </TableRowItemSpan>
            );
        }

        case "budget_volume":
        case "stretch_volume":
        case "variance_volume":
        case "annualized_volume":
        case "actual_normalized_weight": {
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, key), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case "next_volume":
        case "current_variance":
        case "current_volume": {
            return (
                <TableRowItemSpan className="text-right">
                    <>
                        <span className="value">{numberWithCommas(getString(item, key), 0)}</span>
                        {' '}
                        {`${weightUnitName(unit.weightUnit)}`}
                    </>
                </TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
