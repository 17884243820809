import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import Img from "Templates/Img";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    index: PropTypes.number.isRequired,
    customIcon: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
};

const TableRowItem = ({
    index,
    customIcon,
    onClickCustom
}) => {
    const _onClickCustom = () => {
        onClickCustom(index);
    };

    return (
        <TableBodyButtonsBlock>
            {customIcon(index) && (
                <TableBodyButton onClick={_onClickCustom}>
                    <Img img={customIcon(index)} />
                </TableBodyButton>
            )}
        </TableBodyButtonsBlock>
    );
};

TableRowItem.propTypes = propTypes;

const mapStateToProps = () => {};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableRowItem);
