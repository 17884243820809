import React from "react";
import { compose } from "redux";
// import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import DefaultHeadDashboardCard from "../Default/HeadDashboardCard";
import DefaultDataDashboardCard from "../Default/DataDashboardCard";

// const propTypes = {
//     headProps: PropTypes.objectOf(PropTypes.any),
//     dataProsp: PropTypes.objectOf(PropTypes.any),
// };

const DashboardCard = (HeadDashboardCard, DataDashboardCard) => ({
    className = "",
    pref,
    headProps = {},
    dataProsp = {},
}) => {
    return (
        <div className={`${pref}__card ${className}`}>
            <HeadDashboardCard {...headProps} />
            <DataDashboardCard {...dataProsp} />
        </div>
    );
};

// DashboardCard.propTypes = propTypes;

export default ({ HeadDashboardCard = DefaultHeadDashboardCard, DataDashboardCard = DefaultDataDashboardCard }) =>
    compose(withTagDefaultProps())(DashboardCard(HeadDashboardCard, DataDashboardCard));
