import React from "react";
import _ from "lodash";
import {notificationTypes, projectStatusList} from "Services/enum";

export const getMessage = (type, data) => {
    switch (type) {
        case notificationTypes.IHS_UPDATED:
            return "IHS is Updated.";

        case notificationTypes.ASSIGNED_TASK:
            return "You have been assigned to a task.";

        case notificationTypes.ADDED_TO_PROJECT:
            return "You have been Added to a Project.";

        case notificationTypes.ADDED_TO_PROJECT_AS_INTERNAL_TEAM_MEMBER:
            return `${data.slug} has been added and you are assigned as internal team member.`;

        case notificationTypes.ADDED_TO_PROJECT_AS_MANAGER:
            return `${data.slug} has been added and you are assigned as the Sales Manager.`;

        case notificationTypes.CONTRACT_EXPIRE_MONTH:
            return "The contract is going to expire 1 month before.";

        case notificationTypes.CONTRACT_EXPIRE_TWO_WEEKS:
            return "The contract is going to expire 2 weeks before.";

        case notificationTypes.CONTRACT_EXPIRE_WEEK:
            return "The contract is going to expire 1 week before.";

        case notificationTypes.CONTRACT_EXPIRE_TODAY:
            return "The contract is going to expire today.";

        case notificationTypes.DELTA_UPDATED:
            return "Delta is Updated.";

        case notificationTypes.PROJECT_ARCHIVED:
            const status = _.find(projectStatusList, {order: data.status});
            return `Project ${data.name} changed status to ${status ? status.label : '-'}`;

        case notificationTypes.CONNECTION_EXPORT_EXPORTED:
            return "Export connections are ready to download.";

        case notificationTypes.CONNECTION_EXPORT_CANCELED:
            return `Error while exporting connections. ${data.error}`;

        case notificationTypes.CONNECTION_BUDGET_EXPORT_EXPORTED:
            return "Export connections budget are ready to download.";

        case notificationTypes.CONNECTION_BUDGET_EXPORT_CANCELED:
            return `Error while exporting connections budget. ${data.error}`;

        case notificationTypes.GUEST_USER_ACTIVATED:
            return `Your Guest User, ${data.name}, successfully logged into ComOpps.`;

        case notificationTypes.GRADE_SHARE_FILTER:
            return `The User ${data.creatorName} share filters with you.`;

        case notificationTypes.FINANCE_EXPORT_EXPORTED:
            return "Export Budget & Sales details are ready to download.";

        case notificationTypes.FINANCE_EXPORT_CANCELED:
            return `Error while exporting Budget & Sales details. ${data.error}`;

        case notificationTypes.FINANCE_CUSTOMER_EXPORT_EXPORTED:
            return "Export Budget & Sales forecasts (General) are ready to download.";

        case notificationTypes.FINANCE_CUSTOMER_EXPORT_CANCELED:
            return `Error while exporting Budget & Sales forecasts (General). ${data.error}`;

        case notificationTypes.PROJECT_TEMPLATE_EXPORT_EXPORTED:
            return "Export projects template is ready to download.";

        case notificationTypes.PROJECT_TEMPLATE_EXPORT_CANCELED:
            return `Error while exporting projects template. ${data.error}`;

        case notificationTypes.PROJECT_TEMPLATE_IMPORT_IMPORTED:
            return "Projects have been successfully imported";

        case notificationTypes.PROJECT_TEMPLATE_IMPORT_ERROR:
            return `Error while importing projects template. ${data.error}`;

        case notificationTypes.PROJECT_TEMPLATE_IMPORT_REVERTED:
            return "Projects have been successfully reverted";

        case notificationTypes.PROJECT_TEMPLATE_IMPORT_REVERT_FAILED:
            return `Error while reverted projects. ${data.error}`;

        case notificationTypes.PUBLICATION_EXPORT_EXPORTED:
            return "Export publications are ready to download.";

        case notificationTypes.PUBLICATION_EXPORT_CANCELED:
            return `Error while exporting publications. ${data.error}`;

        case notificationTypes.PRODUCT_EXPORT_EXPORTED:
            return "Export products details are ready to download.";

        case notificationTypes.PRODUCT_EXPORT_CANCELED:
            return `Error while exporting products. ${data.error}`;

        case notificationTypes.INVOICE_ZSALES_EXPORT_EXPORTED:
            return "Export invoices with ZSales format are ready to download.";

        case notificationTypes.INVOICE_ZSALES_EXPORT_CANCELED:
            return `Error while exporting invoices with ZSales format. ${data.error}`;

        case notificationTypes.INVOICE_ZCOMOPPS_EXPORT_EXPORTED:
            return "Export invoices with ZComOpps format are ready to download.";

        case notificationTypes.INVOICE_ZCOMOPPS_EXPORT_CANCELED:
            return `Error while exporting invoices with ZComOpps format. ${data.error}`;

        case notificationTypes.INVOICE_INLINE_EXPORT_EXPORTED:
            return "Export invoices are ready to download.";

        case notificationTypes.INVOICE_INLINE_EXPORT_CANCELED:
            return `Error while exporting invoices. ${data.error}`;

        case notificationTypes.FINANCE_CUSTOMER_EXPORT_QUARTERLY_EXPORTED:
            return "Export Budget & Sales forecasts (Quarterly) are ready to download.";

        case notificationTypes.FINANCE_CUSTOMER_EXPORT_QUARTERLY_CANCELED:
            return `Error while exporting Budget & Sales forecasts (Quarterly). ${data.error}`;

        case notificationTypes.FINANCE_CUSTOMER_EXPORT_MONTHLY_EXPORTED:
            return "Export Budget & Sales forecasts (Monthly) are ready to download.";

        case notificationTypes.FINANCE_CUSTOMER_EXPORT_MONTHLY_CANCELED:
            return `Error while exporting Budget & Sales forecasts (Monthly). ${data.error}`;

        case notificationTypes.FINANCE_CUSTOMER_EXPORT_YEARLY_EXPORTED:
            return "Export Budget & Sales forecasts (Yearly) are ready to download.";

        case notificationTypes.FINANCE_CUSTOMER_EXPORT_YEARLY_CANCELED:
            return `Error while exporting Budget & Sales forecasts (Yearly). ${data.error}`;

        case notificationTypes.PROJECT_EXPORT_EXPORTED:
            return "Export projects are ready to download.";

        case notificationTypes.PROJECT_EXPORT_CANCELED:
            return `Error while exporting projects. ${data.error}`;

        case notificationTypes.USER_EXPORT_EXPORTED:
            return "Export users are ready to download.";

        case notificationTypes.USER_EXPORT_CANCELED:
            return `Error while exporting users. ${data.error}`;

        case notificationTypes.COMPANY_EXPORT_EXPORTED:
            return "Export companies are ready to download.";

        case notificationTypes.COMPANY_EXPORT_CANCELED:
            return `Error while exporting companies. ${data.error}`;

        case notificationTypes.PROJECT_VEHICLE_EXPORT_EXPORTED:
            return "Export IHS forecast is ready to download.";

        case notificationTypes.PROJECT_VEHICLE_EXPORT_CANCELED:
            return `Error while exporting IHS forecast. ${data.error}`;

        case notificationTypes.COMPARE_PRODUCT_SHARED_TECHNICAL_EXPORT_EXPORTED:
            return "Export compare products shared technical are ready to download.";

        case notificationTypes.COMPARE_PRODUCT_SHARED_TECHNICAL_EXPORT_CANCELED:
            return `Error while exporting compare products shared technical. ${data.error}`;

        case notificationTypes.COMPARE_PRODUCT_SHARED_PROCESSING_EXPORT_EXPORTED:
            return "Export compare products shared processing are ready to download.";

        case notificationTypes.COMPARE_PRODUCT_SHARED_PROCESSING_EXPORT_CANCELED:
            return `Error while exporting compare products shared processing. ${data.error}`;

        case notificationTypes.COMPARE_PRODUCT_ONBOARDING_TECHNICAL_EXPORT_EXPORTED:
            return "Export compare products onboarding technical are ready to download.";

        case notificationTypes.COMPARE_PRODUCT_ONBOARDING_TECHNICAL_EXPORT_CANCELED:
            return `Error while exporting compare products onboarding technical. ${data.error}`;

        case notificationTypes.COMPARE_PRODUCT_ONBOARDING_PROCESSING_EXPORT_EXPORTED:
            return "Export compare products onboarding processing are ready to download.";

        case notificationTypes.COMPARE_PRODUCT_ONBOARDING_PROCESSING_EXPORT_CANCELED:
            return `Error while exporting compare products onboarding processing. ${data.error}`;

        case notificationTypes.COMPARE_SOLUTION_CATEGORY_EXPORT_EXPORTED:
            return "Export compare technologies category are ready to download.";

        case notificationTypes.COMPARE_SOLUTION_CATEGORY_EXPORT_CANCELED:
            return `Error while exporting compare technologies category. ${data.error}`;

        case notificationTypes.SHARING_FILE_FOLDER:
            return `${data.user_name} shared ${data.file_name} with you.`;

        case notificationTypes.SHARED_AGREEMENT:
            return `${data.user_name} shared ${data.agreement_name} with you.`;

        default:
            return "";
    }
};

export const getLink = (notification, onClick) => {
    switch (notification.type) {
        case notificationTypes.IHS_UPDATED:
        case notificationTypes.GUEST_USER_ACTIVATED:
            return null;

        case notificationTypes.ASSIGNED_TASK:
            return (
                <a
                    href={`/tasks/${notification.data.id}`}
                    className="notifications__link"
                    onClick={() => onClick(notification)}
                />
            );

        case notificationTypes.SHARING_FILE_FOLDER:
            return (
                <a
                    href={'/files?activeTab=1'}
                    className="notifications__link"
                    onClick={() => onClick(notification)}
                />
            );

        case notificationTypes.SHARED_AGREEMENT:
            return (
                <a
                    href={'/legal?activeTab=1'}
                    className="notifications__link"
                    onClick={() => onClick(notification)}
                />
            );

        case notificationTypes.ADDED_TO_PROJECT:
        case notificationTypes.PROJECT_ARCHIVED:
            return (
                <a
                    href={`/project/projects/${notification.data.id}`}
                    className="notifications__link"
                    onClick={() => onClick(notification)}
                />
            );

        case notificationTypes.PROJECT_EXPORT_EXPORTED:
        case notificationTypes.FINANCE_EXPORT_EXPORTED:
        case notificationTypes.FINANCE_CUSTOMER_EXPORT_EXPORTED:
        case notificationTypes.PROJECT_TEMPLATE_EXPORT_EXPORTED:
        case notificationTypes.PUBLICATION_EXPORT_EXPORTED:
        case notificationTypes.PRODUCT_EXPORT_EXPORTED:
        case notificationTypes.INVOICE_ZSALES_EXPORT_EXPORTED:
        case notificationTypes.INVOICE_ZCOMOPPS_EXPORT_EXPORTED:
        case notificationTypes.PROJECT_VEHICLE_EXPORT_EXPORTED:
        case notificationTypes.INVOICE_INLINE_EXPORT_EXPORTED:
        case notificationTypes.FINANCE_CUSTOMER_EXPORT_QUARTERLY_EXPORTED:
        case notificationTypes.FINANCE_CUSTOMER_EXPORT_MONTHLY_EXPORTED:
        case notificationTypes.FINANCE_CUSTOMER_EXPORT_YEARLY_EXPORTED:
        case notificationTypes.CONNECTION_EXPORT_EXPORTED:
        case notificationTypes.CONNECTION_BUDGET_EXPORT_EXPORTED:
        case notificationTypes.USER_EXPORT_EXPORTED:
        case notificationTypes.COMPANY_EXPORT_EXPORTED:
        case notificationTypes.COMPARE_PRODUCT_SHARED_TECHNICAL_EXPORT_EXPORTED:
        case notificationTypes.COMPARE_PRODUCT_SHARED_PROCESSING_EXPORT_EXPORTED:
        case notificationTypes.COMPARE_PRODUCT_ONBOARDING_TECHNICAL_EXPORT_EXPORTED:
        case notificationTypes.COMPARE_PRODUCT_ONBOARDING_PROCESSING_EXPORT_EXPORTED:
        case notificationTypes.COMPARE_SOLUTION_CATEGORY_EXPORT_EXPORTED:
            return (
                <a
                    href={`/export/operation/${notification.data.id}`}
                    className="notifications__link"
                    onClick={() => onClick(notification)}
                />
            );

        case notificationTypes.CONTRACT_EXPIRE_MONTH:
        case notificationTypes.CONTRACT_EXPIRE_TWO_WEEKS:
        case notificationTypes.CONTRACT_EXPIRE_WEEK:
        case notificationTypes.CONTRACT_EXPIRE_TODAY:
            return (
                <a
                    href={`/companies/${notification.data.id}`}
                    className="notifications__link"
                    onClick={() => onClick(notification)}
                />
            );

        case notificationTypes.GRADE_SHARE_FILTER:
            return (
                <a
                    href={`${notification.data.link}`}
                    className="notifications__link"
                    onClick={() => onClick(notification)}
                />
            );

        default:
            return null;
    }
};
