import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {onChange} from "../../Services/EventsService";

function Production({callback, filter}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>Production</BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    <p href="#" className="main-table__head-link">Plant</p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/product/product-finder/plant-list"
                        value={filter.sap_plant}
                        onChange={e => {
                            onChange(e).then(_value => callback('sap_plant', _value));
                        }}
                    />
                    <p href="#" className="main-table__head-link">Production Line</p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/product/product-finder/sap-line-list"
                        value={filter.sap_line}
                        onChange={e => {
                            onChange(e).then(_value => callback('sap_line', _value));
                        }}
                    />
                </div>
            )}
        </>
    );
}

Production.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Production;