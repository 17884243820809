import React, {useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {InputFilter} from "Templates/Table/Filters";

const { Button, Form, Title } = ModalDefault;

const defaultProps = {
    colors: []
};

const propTypes = {
    datasheetId: PropTypes.string.isRequired,
    tabName: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    assignProduct: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    getSubTabs: PropTypes.func.isRequired,
    colors: PropTypes.arrayOf(PropTypes.any),
};

const AssignToProductForm = (
    {
        tabName,
        datasheetId,
        assignProduct,
        getSubTabs,
        fetchItems,
        colors,
        onClose,
        t,
    }
) => {
    const [formState, setFormState] = useState({
        product: [],
        colors: []
    });

    const formValidate = () => {
        if (!formState) return false;
        return formState.product && formState.product.length > 0;
    };

    const isColorChecked = (color) => {
        if (!formState || !formState.colors.length) return false;

        const colorsIds = formState.colors.map((item) => item.id);

        return colorsIds.includes(color.id);
    };

    const generateUniqApproval = (colorId, item) => {
        return `${colorId}${item.approval ? item.approval : ''}${item.code ? item.code : ''}`
    }

    const generateUniqOam = (colorId, oam) => {
        return `${colorId}${oam.approval ? oam.approval : ''}${oam.code ? oam.code : ''}`
    }

    const isApprovalChecked = (color, oam) => {
        if (!formState || !formState.colors.length) return false;
        const approvals = formState.colors.find((item) => item.id === color.id);
        if (!approvals || !approvals.items.length) return false;
        const approvalsList = approvals.items.map((item) => {
            return generateUniqApproval(color.id, item)
        });

        const compareApproval = generateUniqOam(color.id, oam)

        return approvalsList.includes(compareApproval);
    }

    const onColorChange = (color, value) => {
        if (value) {
            setFormState((prev) => {
                prev.colors.push(color)
                return {...prev, colors: [...prev.colors]};
            })
        } else {
            setFormState((prev) => {
                const updatedColors = prev.colors.filter((item) => item.id !== color.id);
                return {...prev, colors: updatedColors};
            })
        }
    };

    const onApprovalChange = (color, value, oam) => {
        if (value) {
            setFormState((prev) => {
                const current = prev.colors.find(item => item.id === color.id);
                if (!current) return {...prev};
                current.items.push(oam);
                return {...prev, colors: [...prev.colors]};
            })
        } else {
            setFormState((prev) => {
                const updatedColors = prev.colors.map((colorItem) => {
                    return {
                        ...colorItem,
                        items: colorItem.items.filter((item) => {
                            return generateUniqApproval(color.id, item) !== generateUniqOam(color.id, oam)
                        })
                    }
                });

                return { ...prev, colors: updatedColors };
            })
        }
    }

    const handleSave = () => {
        assignProduct(datasheetId)(formState).then(() => {
            getSubTabs();
        });
    };

    const approvalView = (elem) => {
        let response = '-';

        if (elem.approval_name && elem.code_name) {
            response = `${elem.approval_name} - ${elem.code_name}`;
        } else if (elem.approval_name) {
            response = elem.approval_name;
        } else if (elem.code_name) {
            response = elem.code_name
        }

        return response;
    };

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose}/>
            <Title>{t('Assign to Product')}</Title>
            <InputFilter
                type="asyncMultiSelect"
                placeholder="Search Product Name"
                url={`/product/${tabName}/${datasheetId}/grade-material-number-list`}
                value={formState.product.length ? formState.product : ''}
                onChange={value => {
                    setFormState((prev) => {
                        return {...prev, product: value}
                    })
                }}
            />

            {/*<BlockWrap>*/}
            {/*    <div className="product-colors-wrap">*/}
            {/*        <ContentInfoBlock>*/}
            {/*            <ContentInfoBlockTitle>{t("Colors and OEM Approvals")}</ContentInfoBlockTitle>*/}
            {/*        </ContentInfoBlock>*/}

            {/*        <div className="colors--to-product-wrap">*/}
            {/*            {colors.map((color, colorIndex) => (*/}
            {/*                <div className={'colors--to-product-oam-wrap'} key={colorIndex}>*/}
            {/*                    <WrapInput key={colorIndex} name={`color-${colorIndex}`}>*/}
            {/*                        <CheckboxBlock>*/}
            {/*                            <CheckboxButton*/}
            {/*                                htmlFor={`checkbox-color-${colorIndex}`}*/}
            {/*                                value={isColorChecked(color)}*/}
            {/*                                onChange={value => {*/}
            {/*                                    onColorChange(color, value);*/}
            {/*                                }}*/}
            {/*                            >*/}
            {/*                                <CheckboxTitle title={t(color.color)}/>*/}
            {/*                            </CheckboxButton>*/}

            {/*                            {color.items.map((item, itemIndex) => {*/}
            {/*                                return (*/}
            {/*                                    <div*/}
            {/*                                        key={`${colorIndex}_${itemIndex}-color-param`}*/}
            {/*                                        className={`color-params ${itemIndex === color.items.length - 1 ? 'last' : ''}`}*/}
            {/*                                    >*/}
            {/*                                        <WrapInput name={`checkbox-color-param-${color.id}-${itemIndex}`}>*/}
            {/*                                            <CheckboxBlock>*/}
            {/*                                                <CheckboxButton*/}
            {/*                                                    isDisabled={!isColorChecked(color)}*/}
            {/*                                                    htmlFor={`checkbox-color-param-${color.id}-${itemIndex}`}*/}
            {/*                                                    value={isApprovalChecked(color, item)}*/}
            {/*                                                    onChange={value => {*/}
            {/*                                                        onApprovalChange(color, value, item);*/}
            {/*                                                    }}*/}
            {/*                                                >*/}
            {/*                                                    <CheckboxTitle title={approvalView(item)}/>*/}
            {/*                                                </CheckboxButton>*/}
            {/*                                            </CheckboxBlock>*/}
            {/*                                        </WrapInput>*/}
            {/*                                    </div>*/}
            {/*                                )*/}
            {/*                            })}*/}
            {/*                        </CheckboxBlock>*/}
            {/*                    </WrapInput>*/}
            {/*                </div>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</BlockWrap>*/}

            <Button onClick={handleSave} disabled={!formValidate()}>
                {t("Apply")}
            </Button>
        </Form>
    );
}

AssignToProductForm.defaultProps = defaultProps;
AssignToProductForm.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        colors: getStoreItem(state, "activeSubTab.items_data.colors"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        assignProduct: getActionStore("assignToProductAction", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
        getSubTabs: getActionStore("getSubTabs", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AssignToProductForm);
