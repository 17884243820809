import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {url} from "Services";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {TableHeadColumnWrap} from "./TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumnWrap";
import ProjectsContainerTable from "./ProjectsContainerTable";

const DefaultTabTable = ProjectsContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const TabView = ({ request, fetchItems, service, t }) => {
    const { deleteItem } = service;

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('You do not have the necessary privileges to perform this action');
            return;
        }

        url.redirect(`/project/projects/${item.id}/edit`);
    };

    const onClickShow = item => {
        const win = window.open(`/project/projects/${item.id}`, "_blank");
        win.focus();
    };

    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('You do not have the necessary privileges to perform this action');
            return;
        }

        if (confirm(t("Do you want to remove the Project?"))) {
            deleteItem(`/project/projects/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    return <TabViewDetail customIcon={'icon_external_link'} onClickCustom={onClickShow}  onClickEdit={onClickEdit} onClickDelete={onClickDelete} />;
};

TabView.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
