import React from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate, parseDate} from "react-day-picker/moment";
import {formatDate as formatDateService} from "Services";

const propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    inputProps: PropTypes.objectOf(PropTypes.any),
    dateFormat: PropTypes.string,
    viewFormat: PropTypes.string,
    placeholder: PropTypes.string,
};

const defaultProps = {
    disabled: false,
    value: "",
    dateFormat: "YYYY-MM-DD",
    viewFormat: "MMM DD, YYYY",
    placeholder: "MMM DD, YYYY",
    inputProps: {},
};

const CustomOverlay = onChange => ({classNames, selectedDay, children, onBlur ,...props}) => {

    return (
        <div className={classNames.overlayWrapper} style={{marginLeft: -100}}
             onBlur={ e => {
                 onBlur(e);
             //  props.input.focus();
                 props.input.blur();
             }}
             {...props}>
            <div className={classNames.overlay}>
                <p>
                    <button type="button" onClick={() => {
                        props.input.focus();
                        props.input.blur();
                        onChange("");
                    }}>
                        Reset
                    </button>
                </p>
                {children}
            </div>
        </div>
    );
};

const DateInput = ({disabled, value, className, onChange, inputProps, viewFormat, placeholder, dateFormat}) => {
    const _onChange = day => {
        onChange({target: {value: day ? formatDateService(day, dateFormat) : ""}});
    };

    return (
        <div className={disabled ? 'disabled-date-picker' : ''}>
        <DayPickerInput
            selectedDays={value}
            value={value ? formatDate(value, viewFormat) : value}
            inputProps={{
                className,
                readOnly: true,
                ...inputProps,
            }}
            formatDate={formatDate}
            keepFocus={false}
            format={viewFormat}
            parseDate={parseDate}
            placeholder={placeholder}
            overlayComponent={CustomOverlay(_onChange)}
            onDayChange={_onChange}
        />
        </div>
    );
};

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;
