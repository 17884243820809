import React from "react";
import {getString} from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

export const modifierValues = items => items;
export const getViewItem = (key, item) => {

    switch (key) {
        case "product":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key)}
                        href={`/product/product-finder/${getString(item, "product_id")}`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
