import {request, url} from "Services";

const getItems = (data, listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestAttach = attachUrl => data => {
    const sendObj = {
        url: url.getUrl(attachUrl),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, listUrl).then(res => {
            resolve({
                items: res[0].items,
                total_analytics: res[0].total_analytics,
                columns: res[0].columns,
                pagination: res[0].pagination,
                totalNotes: res[0].totalNotes || 0,
                totalProjects: res[0].totalProjects || 0,
                totalInvoices: res[0].totalInvoices || 0,
            });
        }, reject);
    });
};

export const requestData = listUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, listUrl).then(res => {
            resolve(res[0]);
        }, reject);
    });
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};
