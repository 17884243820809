import Constants from "../Constants";

export const initState = {
    chartType: "line",
    selectedValue: "all",
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TYPE_CHART:
            return { ...state, chartType: data };

        case Constants.GET_CHART_DATA_REQUESTED:
            return { ...state, loading: true };

        case Constants.GET_CHART_DATA_SUCCEEDED:
            return {
                ...state,
                selectedValue: data.selectedValue,
                dashboard: { ...state.dashboard, graph: data.items },
                loading: false,
            };

        case Constants.GET_CHART_DATA_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        default:
            return state;
    }
};
