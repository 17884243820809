const Constants = {
    CHANGE_FORM: "CHANGE_FORM",
    CHANGE_SHOW_BTN: "CHANGE_SHOW_BTN",

    SHOW_BTN_REQUESTED: "SHOW_BTN_REQUESTED",
    SHOW_BTN_SUCCEEDED: "SHOW_BTN_SUCCEEDED",
    SHOW_BTN_FAILED: "SHOW_BTN_FAILED",

    DELETE_PROPERTY_REQUESTED: "DELETE_PROPERTY_REQUESTED",
    DELETE_PROPERTY_SUCCEEDED: "DELETE_PROPERTY_SUCCEEDED",
    DELETE_PROPERTY_FAILED: "DELETE_PROPERTY_FAILED",

    SET_PROPERTY: "SET_PROPERTY",

    ADD_PROPERTY: "ADD_PROPERTY",

    FORM_REQUESTED: "FORM_REQUESTED",
    FORM_SUCCEEDED: "FORM_SUCCEEDED",
    FORM_FAILED: "FORM_FAILED",
    FORM_RESET: "FORM_RESET"
};

export default Constants;
