import Constants from "../Constants";

export const createFilesAction = (service, dispatch) => data => {
    dispatch({ type: Constants.CREATE_FILES_REQUEST });

    const { requestOnUploadFiles } = service;

    return requestOnUploadFiles(data).then(
        res => dispatch({ type: Constants.CREATE_FILES_SUCCESS, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.CREATE_FILES_FAILED, data: res });
        },
    );
};
