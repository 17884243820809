import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url, numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, CheckPermission } from "Hoc/Template";
import {
    MainButtonLink,
    TabBlock,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import { WrapButtons } from "Templates/Titles";

import { ContainerTable } from "Hoc/Widgets";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";
import ResetTableFilters from "Services/ResetTableFilters/ResetTableFilters";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {
    pagination: PropTypes.shape({ total: PropTypes.number }).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const ProductGrades = ({ pagination, fetchItems, resetFilter, request, service: { onClickDeleteItem }, t }) => {
    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the Product?"))) {
            onClickDeleteItem(item).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        return url.redirect(`/product/product-finder/${item.id}/edit`);
    };

    const handleReset = () => {

        return resetFilter({});
    }

    return (
        <>
            <TabWrapTop>
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{`${numberWithCommas(pagination.total, 0)} ${t(
                            " Grades",
                        )}`}</TabBlockColTitleH4>
                    </TabBlockCol>
                </TabBlock>
                <CheckPermission permission="grade_product_crud">
                    <WrapButtons>
                        <ResetTableFilters handleReset={handleReset} />

                        <MainButtonLink href="/product/product-finder/create" className="main-btn_primary">
                            {t("Add New Product")}
                        </MainButtonLink>
                    </WrapButtons>
                </CheckPermission>
            </TabWrapTop>
            <TabViewDetail onClickDelete={onClickDelete} onClickEdit={onClickEdit} />
        </>
    );
};

ProductGrades.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        resetFilter: getActionStore("resetFilter", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ProductGrades);
