import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import reducers, {initialStates} from "../Reducers";

export default function configureStore(props) {
    const { id, items, pagination, columns, historyUserName } = props;
    const { mainState } = initialStates;
    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "user-history" }),
        listState: { ...mainState, id, items, pagination, historyUserName, columns, sort: { column: columns[0], direction: "desc" } }
    };

    return _configureStore(initialState, reducers);
}
