import { handleScroll } from "./EventsService";
import { requestTable, requestAttach } from "./RequestService";
import { getActionStore, getStoreItem } from "./StoreService";
import { modifierValues, getViewItem } from "./TableBodyService";
import { getFilter, getTableLabel } from "./TableHeaderService";

export default id => ({
    requestTable: requestTable(`/admin/users/${id}/projects`),
    requestAttach,
    getViewItem,
    getFilter,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
