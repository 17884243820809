import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TableRow from "../Templates/TableRow";
import TableColumn from "../Templates/TableColumn";

const propTypes = {
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    tasks: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                due: PropTypes.string.isRequired,
                sub_status: PropTypes.number.isRequired,
            }),
        ).isRequired,
        pagination: PropTypes.shape({ page: PropTypes.number.isRequired, pages: PropTypes.number.isRequired })
            .isRequired,
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    service: PropTypes.shape({
        tableLabel: PropTypes.objectOf(PropTypes.any).isRequired,
        getViewItem: PropTypes.func.isRequired,
        handleScroll: PropTypes.func.isRequired,
    }).isRequired,
    fetchPage: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
};

class DataDashboardCard extends Component {
    onSortClick = column => {
        const { sort, fetchItems } = this.props;
        const newRequest = {
            sort: {
                column,
                direction: sort.direction === "asc" ? "desc" : "asc",
            },
        };

        fetchItems(newRequest);
    };

    onScroll = e => {
        const {
            tasks: { pagination },
            sort,
            service: { handleScroll },
            fetchPage,
        } = this.props;
        if (pagination.page >= pagination.pages) {
            return;
        }

        handleScroll(e).then(() => {
            fetchPage({ sort, page: pagination.page + 1 });
        });
    };

    render() {
        const {
            pref,
            sort,
            tasks: { columns, items },
            service: { tableLabel, getViewItem },
            t,
        } = this.props;
        return (
            <div className={`${pref}__card-data`} onScroll={this.onScroll}>
                <div className={`${pref}__table`}>
                    <TableRow>
                        {columns.map((elem, index) => (
                            <TableColumn key={`TableColumn-${index}`}>
                                <span className="bold-text">{t(tableLabel[elem])}</span>
                                <a
                                    href="#"
                                    className={`${pref}__table-head-link`}
                                    onClick={e => {
                                        e.preventDefault();
                                        this.onSortClick(elem);
                                    }}
                                >
                                    {sort.column === elem && sort.direction === "asc" && (
                                        <span className={`${pref}__table-head-link-icon up`} />
                                    )}
                                    {sort.column === elem && sort.direction === "desc" && (
                                        <span className={`${pref}__table-head-link-icon down`} />
                                    )}
                                </a>
                            </TableColumn>
                        ))}
                    </TableRow>
                    {items.map((item, index) => (
                        <TableRow key={`TableRow-${index}`}>
                            {columns.map((elem, index) => (
                                <TableColumn key={`TableColumn-${index}`}>{getViewItem(elem, item)}</TableColumn>
                            ))}
                        </TableRow>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        tasks: getStoreItem(state, "dashboard.tasks"),
        sort: getStoreItem(state, "tasksTable.sort"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

DataDashboardCard.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(DataDashboardCard);
