import { marginEnum } from "Services/enum";
import { requestData } from "../../RequestService";
import { getStoreItem, getChartData, getActionStore } from "./StoreService";
import { formData } from "./FormService";

const optionsForFirstSelect = marginEnum;

export default id => ({
    requestChartData: requestData(`/product/products/${id}/analytics`),
    requestData: requestData(`/product/products/${id}/analytics/data`),
    getStoreItem,
    getChartData,
    getActionStore,
    optionsForFirstSelect,
    formData,
});
