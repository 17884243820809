import {getStoreItem as _getStoreItem, getActionStore as _getActionStore} from "./StoreService";
import * as FormService from "./FormService";

const getStoreItem = _getStoreItem();
const getActionStore = _getActionStore();

export {
    getStoreItem,
    getActionStore,
    FormService,
};