import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { ModalDefault } from "Templates/Modals";
import { ButtonClose } from "Templates/Button";
import Row from "../../../../Templates/Form/Row";

const { Form } = ModalDefault;

const propTypes = {
    email: PropTypes.string.isRequired,
    csrf_token: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    submitCode: PropTypes.func.isRequired,
    closeModalAction: PropTypes.func.isRequired,
};

class TwoFactorModal extends Component {
    state = {
        code: "",
    };

    handleChangeString = ({ target }) => {
        this.setState({
            [target.name]: target.value,
        });
    };

    checkForm = () => {
        const { code } = this.state;
        const codeLength = 6;
        return !(code.trim() && code.trim().length === codeLength);
    };

    onClose = () => {
        const { closeModalAction } = this.props;

        closeModalAction();
    };

    handleClickOnButton = () => {
        const { code } = this.state;
        const { submitCode, csrf_token, password, email } = this.props;

        submitCode({ code, _csrf_token: csrf_token, password, email });
    };

    render() {
        const { code } = this.state;
        const { email, error, t } = this.props;

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={this.onClose} />
                <div className="session">
                    <div className="session__title">{t("2-Step Verification")}</div>
                    <div className="session__subtitle">{t("Enter a verification code")}</div>
                    <div className="session__subtitle">{t(`A verification code was emailed to ${email}. Please enter the code below and click the Submit button.`)}</div>
                    <Row>
                        <label htmlFor="code" className={`login__form-label`}>
                            <input
                                required
                                id="code"
                                type="text"
                                name="code"
                                value={code}
                                className={`login__form-input`}
                                placeholder="Code"
                                onChange={this.handleChangeString}
                                autoFocus
                            />
                        </label>
                    </Row>
                    <Row>
                        {error && <div style={{'textAlign': 'center'}}>{error}</div>}
                        <button
                            disabled={this.checkForm()}
                            onClick={this.handleClickOnButton}
                            className={`login__btn`}
                        >
                            {t('Submit')}
                        </button>
                    </Row>
                </div>
            </Form>
        );
    }
}

TwoFactorModal.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        password: getStoreItem(state, "password"),
        csrf_token: getStoreItem(state, "csrf_token"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitCode: getActionStore("twoFactorAction", service, dispatch),
        closeModalAction: getActionStore("closeModalAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TwoFactorModal);
