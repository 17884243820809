import {getStoreItem, getActionStore} from "./StoreService";
import {handleScroll} from "./EventsService";
import {requestTable, requestData, requestAttach, deleteItem, addToPublicationList as _addToPublicationList} from "./RequestService";

const addToPublicationList = data => _addToPublicationList(`product/product-finder/publication-groups/add-publication`)(data);

export {
    requestAttach,
    deleteItem,
    requestTable,
    requestData,
    handleScroll,
    getStoreItem,
    getActionStore,
    addToPublicationList,
};
