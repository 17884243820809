import React from "react";
import { connect } from "react-redux";
import {compose} from "redux";

const PermissionsProps = Wrapped => (props) => {
    return <Wrapped {...props} />;
};

const mapStateToProps = state => {
    return {
        permissions: state.userState.permissions,
    };
};

export default compose(connect(mapStateToProps), PermissionsProps);
