import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ContentInfoWrap} from "Templates/Content";
import {formatDate, numberWithCommas, url} from "Services";
import {Title, Total, WrapButtons} from "Templates/Titles";
import ResetTableFilters from "Services/ResetTableFilters/ResetTableFilters";
import {Modal} from "Templates/Modals";
import SaveTableFilters from "../Services/SaveTableFilters/SaveTableFilters";
import ProductFilters from "./Filters/Product";
import ApprovalFilters from "./Filters/Approval";
import ColorFilters from "./Filters/Color";
import KeyWordFilters from "./Filters/KeyWord";
import MaterialPropertyFilters from "./Filters/MaterialProperty";
import Range from "./Filters/Range";
import ProcessingPropertyFilters from "./Filters/Processing";
import ApplicationFilters from "./Filters/Application";
import ProductionFilters from "./Filters/Production";
import CustomerFilters from "./Filters/Customer";
import ProjectFilters from "./Filters/Project";
import GroupPublicationFilters from "./Filters/GroupPublication";
import SavedFilters from "./Filters/SavedFilter";
import FilterModalForm from "./FilterModalForm";

const defaultProps = {
    help: '',
    updated_date: ''
}

const propTypes = {
    resetFilter: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    callbackSavedFilter: PropTypes.func.isRequired,
    reloadFilter: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    fetchGroupList: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    savedFilter: PropTypes.string.isRequired,
    title: PropTypes.number.isRequired,
    filterType: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    filterForm: PropTypes.objectOf(PropTypes.any).isRequired,
    isSharedTab: PropTypes.bool.isRequired,
    isMyTab: PropTypes.bool.isRequired,
    activeTab: PropTypes.number.isRequired,
    help: PropTypes.string,
    updated_date: PropTypes.string,
};

const InfoWrap = (props) => {
    const {
        title,
        help,
        resetFilter,
        t,
        callback,
        callbackSavedFilter,
        reloadFilter,
        request,
        updated_date,
        savedFilter,
        isSharedTab,
        isSharedPublicationTab,
        isMyTab,
        filterType,
        showModal,
        handleCloseModal,
        handleOpenModal,
        filterForm,
        activeTab,
        onChange,
        groupList,
        fetchGroupList,
    } = props;

    let {filter} = request;

    const lastUpdate = !updated_date || isMyTab || isSharedTab ? null :
        (
            <div className="last-update">{`${t("Last SAP Update")} - ${formatDate(updated_date)}`}</div>
        );
    const myRef = React.useRef();

    const handleReset = () => {
        url.clearHistory();

        myRef.current.resetFilter();
        return resetFilter({});
    }

    const handleSave = () => {
        handleOpenModal();
    }

    const onChangeFilter = (key, value) => {
        filter = {
            ...filter,
            [key]: value || []
        };
        request.filter = filter;

        callback(key, value, request);
    }

    const onChangeSavedFilter = (value) => {
        if (!value) {
            handleReset();
            return;
        }

        callbackSavedFilter(value).then(
            data => {
                request.filter = JSON.parse(data.items).filter || {};
                request.sort = JSON.parse(data.items).sort || {};

                return reloadFilter(request);
            },
            errors => {
                alert(errors.detail);
            },
        );
    }

    if (isSharedPublicationTab) {
        if (groupList === null) {
            fetchGroupList();
        }

        if (!filter.group_id && groupList && groupList.length > 0) {
            onChangeFilter('group_id', groupList[0].value);
        }
    }

    const renderInfoWrap = (activeTab) => {
        if (activeTab === 4) {
            return (
                <ContentInfoWrap>
                    <Title
                        title={t('My Lists')}
                        help={help}
                        total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}/>
                </ContentInfoWrap>
            );
        }

        if (isSharedPublicationTab) {
            const length = groupList ? groupList.length : 0;

            return (
                <ContentInfoWrap>
                    <Title
                        title={t("Shared With Me")}
                        help={help}
                        total={() => <Total>{`${length} ${t("Total")}`}</Total>}
                    />

                    <GroupPublicationFilters groupList={groupList} callback={onChangeFilter} activeGroupId={filter.group_id}/>
                </ContentInfoWrap>
            );
        }

        return (
            <ContentInfoWrap>
                {showModal && (
                    <Modal onRequestClose={handleCloseModal}>
                        <FilterModalForm
                            onChange={onChange}
                            filterForm={filterForm}
                            onClose={handleCloseModal}
                            type={filterType}
                            filter={request.filter}
                            sort={request.sort}
                        />
                    </Modal>
                )}
                <Title
                    title={t("Products")}
                    help={help}
                    total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
                >
                    <WrapButtons>
                        <a title="Clear all selections.">
                            <ResetTableFilters handleReset={handleReset}/>
                        </a>
                        <a title="Assign a unique name to a saved search.">
                            <SaveTableFilters handleSave={handleSave}/>
                        </a>
                    </WrapButtons>
                    {lastUpdate}
                </Title>

                <SavedFilters activeTab={activeTab} callback={onChangeSavedFilter} handleReset={handleReset}
                              filter={savedFilter} isSharedTab={isSharedTab}/>
                {<ProductFilters callback={onChangeFilter} filter={filter} isMyTab={isMyTab} isSharedTab={isSharedTab}/>}
                {!isMyTab && <ApprovalFilters callback={onChangeFilter} filter={filter}/>}
                {!isMyTab && <ColorFilters callback={onChangeFilter} filter={filter}/>}
                <ApplicationFilters callback={onChangeFilter} filter={filter} isSharedTab={isSharedTab} isMyTab={isMyTab}/>
                {!isMyTab && <ProcessingPropertyFilters callback={onChangeFilter} filter={filter}/>}
                {!isMyTab && <MaterialPropertyFilters callback={onChangeFilter} filter={filter}/>}
                {!isMyTab && <Range callback={onChangeFilter} ref={myRef} filter={filter}/>}
                {!isSharedTab && !isMyTab && <ProductionFilters callback={onChangeFilter} filter={filter}/>}
                {!isSharedTab && <CustomerFilters callback={onChangeFilter} filter={filter}/>}
                {!isSharedTab && !isMyTab && <ProjectFilters callback={onChangeFilter} filter={filter}/>}
                {!isMyTab && <KeyWordFilters callback={onChangeFilter} filter={filter}/>}
            </ContentInfoWrap>
        );
    }

    return renderInfoWrap(activeTab);
};

InfoWrap.propTypes = propTypes;
InfoWrap.defaultProps = defaultProps;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    const pagination = getStoreItem(state, "pagination");
    const activeTab = getStoreItem(state, "activeTab");

    return {
        pagination,
        title: pagination.total || 0,
        help: getStoreItem(state, "help"),
        showModal: getStoreItem(state, "showFilterModal"),
        groupList: getStoreItem(state, "groupList"),
        updated_date: getStoreItem(state, "updated_date"),
        savedFilter: getStoreItem(state, "savedFilter"),
        isSharedTab: activeTab === 2,
        isSharedPublicationTab: activeTab === 3,
        isMyTab: activeTab === 1,
        filterType: activeTab === 2 ? 5 : 10,
        filterForm: {...getStoreItem(state, "filterForm", true)},
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        callback: getActionStore("onChange", service, dispatch),
        reloadFilter: getActionStore("reloadFilter", service, dispatch),
        callbackSavedFilter: getActionStore("onChangeSavedFilter", service, dispatch),
        resetFilter: getActionStore("resetFilter", service, dispatch),
        handleCloseModal: getActionStore("handleFilterCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleFilterOpenModal", service, dispatch),
        onChange: getActionStore("onChangeFilterForm", service, dispatch),
        fetchGroupList: getActionStore("fetchGroupList", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(InfoWrap);
