import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { storageUrl } from "Services";

const propTypes = {
    href: PropTypes.string.isRequired,
    storage_url: PropTypes.string.isRequired,
    title: PropTypes.string,
    file: PropTypes.bool,
    blank: PropTypes.bool,
};

const defaultProps = {
    file: false,
    blank: true,
    title: "",
};

const TableRowItemLink = ({ children, pref, href, storage_url, title, file, blank }) => {
    if (file) {
        href = storageUrl(href, storage_url);
        blank = true;
    }

    return (
        <a
            href={href}
            title={title}
            onClick={e => {
                if(blank){
                    e.preventDefault();
                    window.open(href);
                }
            }}
            className={`${pref}__table-body-link main-table__body-link`}
        >
            {children}
        </a>
    );
};

TableRowItemLink.propTypes = propTypes;
TableRowItemLink.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowItemLink);
