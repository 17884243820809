import React from "react";
import { marginEnum } from "Services/enum";
import { FormBlockLink } from "Templates/Form";
import main, {getSelectedOption} from "../index";
import { formData } from "./FormService";
import { getStoreItem, getChartData, getActionStore } from "./StoreService";
import { getData, getItemOptions } from "../RequestService";

const optionsForFirstSelect = marginEnum;

const getFirstColumnInTheTable = item => <FormBlockLink href={`/admin/users/${item.id}`}>{item.label}</FormBlockLink>;

export default () => ({
    ...main,
    getSelectedOption,
    getData: getData("/forecasts/personnels"),
    getItems: getItemOptions("/forecasts/personnels/user-list"),
    optionsForFirstSelect,
    getFirstColumnInTheTable,
    getStoreItem,
    getChartData,
    getActionStore,
    formData,
});
