import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { TabWrap } from "Templates/Content";
import TabViewIndex from "./Tabs/DefaultWrap/TabViewIndex";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const TabView = () => {
    return (
        <TabWrap>
            <TabViewIndex
                onClickEdit={() => {}}
                onClickDelete={() => {}}
            />
        </TabWrap>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
