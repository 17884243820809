import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import { BackLink } from "Templates/Link";
import BudgetFormBlock from "../../Views/BudgetForm";

const propTypes = {
    years: PropTypes.shape({
        [PropTypes.number]: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ),
    }).isRequired,
    productCode: PropTypes.string.isRequired,
    applicationCode: PropTypes.string.isRequired,
    plant: PropTypes.string.isRequired,
    materialType: PropTypes.string.isRequired,
    validate: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Connection = ({ productCode, applicationCode, plant, materialType, years, pref, validate, t, submitForm }) => {
    const handleClickOnSave = () => {
        submitForm({
            productCode,
            applicationCode,
            plant,
            materialType,
            years,
        }).then(
            data => {
                url.redirect(`/connection/budgets/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (confirm(t("Cancel?"))) {
            url.redirect("/connection/connections?activeTab=1");
        }
    };

    return (
        <>
            <Title
                title={t("Add New Budget")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <BudgetFormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validate} onClick={handleClickOnSave}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Connection.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        productCode: getStoreItem(state, "productCode"),
        applicationCode: getStoreItem(state, "applicationCode"),
        plant: getStoreItem(state, "plant"),
        materialType: getStoreItem(state, "materialType"),
        years: getStoreItem(state, "years"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitBudgetFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Connection);
