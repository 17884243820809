import React from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {Block, BlockWrap, Form} from "Templates/Form";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import GeneralForm from "./Forms/GeneralForm";
import CustomTds from "./Forms/CustomTds";
import NotesSection from "../../../View/Views/Forms/NotesSection";
import KeyWords from "../../../View/Views/Forms/KeyWords";

const FormBlock = () => {
    return (
        <Form>
            <Block>
                <BlockWrap>
                    <CustomTds/>
                </BlockWrap>
            </Block>
            <Block className="publication-designs-general">
                <BlockWrap>
                    <GeneralForm/>
                </BlockWrap>
            </Block>
            {/*<Colors/>*/}
            {/*<KeyWords url={'product/safeties/keywords'}/>*/}
            <NotesSection/>
            <CheckPermission permission="product_publications_approve"/>
        </Form>
    );
};

const mapStateToProps = () => {
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
