import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import { isEqual, url } from "Services";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Performance = ({ old, validate, formData, pref, t, submitForm }) => {
    const savePerformance = () => {
        submitForm(formData).then(
            () => {
                url.redirect(`/project/performance`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (confirm(t("Cancel?"))) {
            url.redirect("/project/performance");
        }
    };

    const validateAll = validate ? !isEqual({ ...formData }, old, _.keys(old)) : validate;

    return (
        <>
            <Title
                title={t("Edit Performance")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock edit />
            <BlockSubmit>
                <ButtonRight>
                    <button
                        type="button"
                        className={`${pref}__buttons-btn main-btn`}
                        onClick={handleClickOnCancelButton}
                    >
                        {t("Cancel")}
                    </button>
                </ButtonRight>
                <ButtonLeft>
                    <button
                        type="button"
                        className={`${pref}__buttons-btn main-btn`}
                        onClick={savePerformance}
                        disabled={!validateAll}
                    >
                        {t("Save")}
                    </button>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Performance.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        old: getStoreItem(state, "old"),
        // errors: getStoreItem(state, "errors"),
        formData: state.editState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Performance);
