import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    addHierarchy: PropTypes.func,
    column: PropTypes.string.isRequired,
};

const HierarchyTableRowColumnDefault = ({ column, addHierarchy, item, service, weightUnit }) => {
    const { getHierarchyViewItem } = service;
    return getHierarchyViewItem(column, item, addHierarchy, weightUnit);
};

HierarchyTableRowColumnDefault.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    weightUnit: state.weightUnitState,
  };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(HierarchyTableRowColumnDefault);
