import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import InfoWrap from "./Views/InfoWrap";
import _projectService from "./Services/Tabs/Projects";
import Projects from "./Views/Tabs/Projects";

const propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Ihs = ({ pref, id, activeTab, t, onChangeTab }) => {
    const projectService = _projectService(id);

    return (
        <CheckPermission permission="admin_ihs_crud">
            <ContentWrap>
            <InfoWrap />
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index) => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Projects")} className={`${pref}__wrap main-content__wrap-top main-wrap-block`}>
                        <ServiceProvider value={projectService}>
                            <Projects />
                        </ServiceProvider>
                    </Tab>
                    <Tab label='' />
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
        </CheckPermission>
    );
};

Ihs.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        id: state.listState.view.id,
        activeTab: state.listState.activeTab,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Ihs);
