import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import CheckPermission from "Hoc/Template/CheckPermission";
import Img from "Templates/Img/Img";
import {showMessage} from "Services";

const QuickAccessToForms = ({ src, t, permissions }) => {

    const onAddGrade = (e) => {
        if (!permissions.includes('admin')) {
            e.preventDefault();
            showMessage('To add a new product email support@comopps.com and include the name of the product in the email. Please use the subject heading: "Add New Product"');
        }
    }

    return (
        <div className="main-header__add-wrap">
            <button type="button" className="main-header__btn main-btn">
                {t("Add new")}
            </button>
            <ul className="main-header__add-list">
                <CheckPermission permission="grade_product_crud">
                    <li className="main-header__add-item">
                        <a href="/product/product-finder/create" className="main-header__add-link" onClick={onAddGrade}>
                            <span className="main-header__add-img">
                                <Img img="icon_product" className="main-header__add-svg" alt="" />
                            </span>
                            <span className="main-header__add-title">{t("Product")}</span>
                        </a>
                    </li>
                </CheckPermission>
                <CheckPermission permission="project_crud">
                    <li className="main-header__add-item">
                        <a href="/project/projects/create" className="main-header__add-link">
                            <span className="main-header__add-img">
                                <Img img="icon_project" className="main-header__add-svg" alt="" />
                            </span>
                            <span className="main-header__add-title">{t("Project")}</span>
                        </a>
                    </li>
                </CheckPermission>
                <CheckPermission permission="file_general">
                    <li className="main-header__add-item">
                        <a href="/files" className="main-header__add-link">
                        <span className="main-header__add-img">
                            <Img img="icon_files" className="main-header__add-svg" alt=""/>
                        </span>
                            <span className="main-header__add-title">{t("File")}</span>
                        </a>
                    </li>
                </CheckPermission>
                <CheckPermission permission="admin">
                    <li className="main-header__add-item">
                        <a href="/companies/create" className="main-header__add-link">
                            <span className="main-header__add-img">
                                <Img img="icon_companies" className="main-header__add-svg" alt="" />
                            </span>
                            <span className="main-header__add-title">{t("Company")}</span>
                        </a>
                    </li>
                </CheckPermission>
                <CheckPermission permission="contacts_view">
                    <li className="main-header__add-item">
                        <a href="/contacts/create" className="main-header__add-link">
                            <span className="main-header__add-img">
                                <Img img="icon_people" className="main-header__add-svg" alt="" />
                            </span>
                            <span className="main-header__add-title">{t("Contact")}</span>
                        </a>
                    </li>
                </CheckPermission>
                <CheckPermission permission="plant_raw_crud">
                    <li className="main-header__add-item">
                        <a href="/product/plants/create" className="main-header__add-link">
                            <span className="main-header__add-img">
                                <Img img="icon_plant" className="main-header__add-svg" alt="" />
                            </span>
                            <span className="main-header__add-title">{t("Plant")}</span>
                        </a>
                    </li>
                </CheckPermission>
            </ul>
        </div>
    );
};

export default compose(withTagDefaultProps())(QuickAccessToForms);
