import _ from "lodash";
import { formDataNormalizer, countryListObj, stateListObj } from "Services";

export const formData = data => {
    const number = _.trim(_.get(data, "number"));

    const physicalCountry = _.get(countryListObj, _.get(data, "physicalCountry"));
    const physicalState =
        _.get(data, "physicalCountry") === "US" ? _.get(stateListObj, _.get(data, "physicalState"), "") : "";

    const dataReq = {
        name: _.get(data, "name"),
        logo: _.get(data, "logo"),
        email: _.get(data, "email"),
        code: number ? _.get(data, "code") : "",
        number,
        startFy: _.get(data, "start_fy"),
        endFy: _.get(data, "end_fy"),

        physicalCountry,
        physicalCity: _.get(data, "physicalCity"),
        physicalAddress1: _.get(data, "physicalAddress1"),
        physicalAddress2: _.get(data, "physicalAddress2"),
        physicalZip: _.get(data, "physicalZip"),
        physicalState,
    };

    return formDataNormalizer(dataReq);
};
