import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { handleScroll } from "../EventsService";
import { requestTable } from "./RequestService";
import { getFilter } from "./FilterService";
import { getViewItem, modifierValues, getTableLabel, title } from "./ViewService";
import { CommonTableActions } from "../../Actions";
import { GradesTableActions } from "../../Actions/GradesActions";

const getStoreItem = _getStoreItem("listState");

const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...CommonTableActions,
        ...GradesTableActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export default searchValue => ({
    title,
    requestTable: requestTable(searchValue),
    getViewItem,
    getFilter,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
