import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import {
    TableCard,
    THead as THeadDefault,
    TBody as TBodyDefault,
    TFooter as TFooterDefault,
} from "Templates/Table/NewVersion";

import TableHeadColumnDefault from "Templates/Table/TableHeadColumnDefault";
import TableBodyColumnDefault from "Templates/Table/TableBodyColumnDefault";
import TableFooterColumnDefault from "Templates/Table/TableFooterColumnDefault";

const SimpleTableContainer = (
    THead = THeadDefault,
    TableHeadColumn = TableHeadColumnDefault,
    TBody = TBodyDefault,
    TableBodyColumn = TableBodyColumnDefault,
    TFooter = TFooterDefault,
    TableFooterColumn = TableFooterColumnDefault,
) => ({ tableClassName }) => {
    return (
        <TableCard className={tableClassName}>
            <THead>
                <TableHeadColumn />
            </THead>
            <TBody>
                <TableBodyColumn />
            </TBody>
            <TFooter>
                <TableFooterColumn />
            </TFooter>
        </TableCard>
    );
};

const DefaultSimpleTable = SimpleTableContainer();

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(DefaultSimpleTable);

export const CustomSimpleTable = THead => TableHeadColumn => TBody => TableBodyColumn => TFooter => TableFooterColumn =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(),
    )(SimpleTableContainer(THead, TableHeadColumn, TBody, TableBodyColumn, TFooter, TableFooterColumn));
