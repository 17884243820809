import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;

    const {
        id,
        applicationCode,
        name,
        properties
    } = props;

    const initData = {
        id,
        applicationCode,
        name,
        properties: properties.length ? properties : [{property: ''}],
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "edit-application" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
