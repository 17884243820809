import React from "react";
import { connect } from "react-redux";
import {weightUnitName, weightUnitConvertLbs} from "../../Services/weightUnitName";

const defaultProps = {
    isLbs: false
};

const UnitWeightWrapper = ({ weightUnit, isLbs}) => {
    return isLbs ? weightUnitConvertLbs(weightUnitName(weightUnit)) : weightUnitName(weightUnit);
};

UnitWeightWrapper.defaultProps = defaultProps;

const mapStateToProps = state => ({
    weightUnit: state.weightUnitState.weightUnit,
});

export default connect(mapStateToProps)(UnitWeightWrapper);
