import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {onChange} from "../../Services/EventsService";
import {isYesNo} from "Services/enum";

function Invoice({callback, filter}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>
                    <p className="filter-with-tooltip">
                        Invoices
                    </p>
                </BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    <>
                        <p className="main-table__head-link filter-with-tooltip">
                            Invoices
                        </p>
                        <InputFilter
                            type="select"
                            placeholder="Select"
                            options={isYesNo}
                            value={filter.has_invoice}
                            onChange={e => {
                                onChange(e).then(_value => callback('has_invoice', _value));
                            }}
                        />
                    </>
                </div>
            )}
        </>
    );
}

Invoice.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired
}

export default Invoice;
