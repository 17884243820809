import _ from "lodash";
import { formDataNormalizer } from "Services";
import Constants from "../Constants";
import { initialState } from "../Reducers/FormReducer";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });

    if (key !== 'agreements' && initialState.agreements) {
        dispatch({ type: Constants.CHANGE_FORM, data: { key: 'agreements', value: initialState.agreements } });
    }
};

export const onChangeLegal = (service, dispatch) => (key, legalId) => {
    dispatch({ type: Constants.SUB_LEGAL_REQUESTED });

    return new Promise((resolve, reject) => {
        service.requestLegal(`/product/datasheets/legal-data/${legalId}`).then(
            res => {
                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key, value: _.get(res, "items") }
                });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SUB_LEGAL_FAILED, data: errors });
                reject(errors);
            },
        );
    });
}

export const onChangeContentTemplate = (service, dispatch) => (key, templateId, contentData) => {
    dispatch({ type: Constants.CONTENT_TEMPLATE_REQUESTED });

    return new Promise((resolve, reject) => {
        service.requestContentTemplate(`/product/datasheets/content-template-data/${templateId}`).then(
            res => {
                contentData.value = _.get(res, "value", '');
                contentData.name = _.get(res, "name", '');

                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key, value: contentData }
                });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.CONTENT_TEMPLATE_FAILED, data: errors });
                reject(errors);
            },
        );
    });
}

export const submitContentTemplate = (service, dispatch) => (key, data) => {
    dispatch({ type: Constants.SUBMIT_CONTENT_TEMPLATE_REQUESTED });

    return new Promise((resolve, reject) => {
        service.saveContentTemplate(formDataNormalizer(data)).then(
            res => {
                dispatch({ type: Constants.SUBMIT_CONTENT_TEMPLATE_SUCCEEDED });
                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key, value: _.get(res, "id") },
                });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SUBMIT_CONTENT_TEMPLATE_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const editContentTemplate = (service, dispatch) => (id, key, data) => {
    dispatch({ type: Constants.SUBMIT_CONTENT_TEMPLATE_REQUESTED });

    return new Promise((resolve, reject) => {
        service.editContentTemplate(id)(formDataNormalizer(data)).then(
            res => {
                dispatch({ type: Constants.SUBMIT_CONTENT_TEMPLATE_SUCCEEDED });
                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key, value: _.get(res, "id") },
                });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SUBMIT_CONTENT_TEMPLATE_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const submitColor = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_COLOR_REQUESTED });

    return new Promise((resolve, reject) => {
        service.saveColor(formDataNormalizer(data)).then(
            res => {
                dispatch({ type: Constants.SUBMIT_COLOR_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SUBMIT_COLOR_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const changeMaterialProperty = (service, dispatch) => (segmentId) => {
    dispatch({ type: Constants.SUB_SEGMENT_REQUESTED });

    return service.requestMaterialProperty(`/product/datasheets/sub-segment/${segmentId || '-'}/property-hierarchy-list`).then(
        res => {
            dispatch({ type: Constants.SUB_SEGMENT_SUCCEEDED });

            const materialNum = res.items.map((val, index) => {
                return { frontId: index, property: val.value }
            });

            if (materialNum.length) {
                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key: "materialProperties", value: materialNum },
                });
            } else {
                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key: "materialProperties", value: [{id: '', frontId: 0, property: ''}] },
                });
            }
        },
        errors => {
            dispatch({ type: Constants.SUB_SEGMENT_FAILED, data: errors });
            // reject(errors);
        },
    );
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.FORM_RESET });
};

export const submitForm = (service, dispatch) => data => {
    dispatch({ type: Constants.FORM_REQUESTED });
    const { formData } = service.FormService;

    return new Promise((resolve, reject) => {
        service.save(formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};
