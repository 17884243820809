import _ from "lodash";
import Constants from "../Constants";

export const defaultMonthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const defaultYearData = defaultMonthLabels.reduce((acc, elem) => ({ ...acc, [elem]: "" }), {});

export const initialState = {
    years: { "": _.cloneDeep(defaultYearData) },
    yearlyVolume: { "": '' },
    manufacturer: "",
    unitName: "",
    owner: "",
    form_validate: false,
    form_loading: false,
    form_errors: {},

    old: {},
};

const validate = ({ manufacturer, unitName, owner, years }) => {
    const yearsArr = Object.keys(years);
    const checkString = [manufacturer, unitName, owner].every(el => typeof el === "string" && el.trim());
    const checkNumber = yearsArr.every(year => Object.values(years[year]).every(el => !Number.isNaN(Number(el))));
    const checkTotal = yearsArr.every(year => Object.values(years[year]).reduce((acc, elem) => acc + Number(elem), 0));
    const checkOneOfYear = yearsArr.every(year => year);

    return checkString && checkNumber && checkTotal && checkOneOfYear;
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };
        }

        case Constants.ADD_YEAR: {
            const newState = {
                ...state,
                years: { ...state.years, "": _.cloneDeep(defaultYearData) },
                yearlyVolume: {
                    ...state.yearlyVolume,
                    '': ''
                }
            };

            return {...newState, form_validate: validate(newState) };
        }

        case Constants.REMOVE_YEAR: {
            const { years } = state;
            delete years[data.year];

            return { ...state, form_validate: validate(state), years: { ...state.years } };
        }

        case Constants.CHANGE_YEAR: {
            const { years, yearlyVolume } = state;
            years[data.year] = _.cloneDeep(years[data.oldYear]);
            delete years[data.oldYear];

            yearlyVolume[data.year] = yearlyVolume[data.oldYear];
            delete yearlyVolume[data.oldYear];

            return { ...state, form_validate: validate(state), years: { ...state.years } };
        }

        case Constants.CHANGE_MONTH_DATA: {
            const { year, month, value } = data;

            const newState = {
                ...state,
                years: {
                    ...state.years,
                    [year]: {
                        ...state.years[year],
                        [month]: value,
                    },
                },
            };

            return { ...newState, form_validate: validate(newState) };
        }

        case Constants.CHANGE_YEARLY_VOLUME: {
            const { year, value } = data;
            const newState = {
                ...state,
                yearlyVolume: {
                    ...state.yearlyVolume,
                    [year]: value
                }
            };

            return { ...newState, form_validate: validate(newState), yearlyVolume: { ...newState.yearlyVolume } };
        }

        case Constants.APPLY_YEARLY_VOLUME: {
            const { year } = data;
            const newState = { ...state };

            _.map(newState.years[year], (item, i) => {
                const n = _.floor(_.divide(state.yearlyVolume[year], Object.keys(state.years[year]).length), 0);
                _.set(newState.years[year], i, n >= 1 ? n.toString() : '');
            })

            return { ...newState, form_validate: validate(newState), years: { ...newState.years } };
        }

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };

        default:
            return { ...state };
    }
};
