import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { isSapImported } from "Services/enum";
import { onChange } from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        name: "SAP Material Code",
        description: "SAP Material Name",
        date_update: "Change Date",
        sap_value: "SAP Import",
    };
    return _.get(tableLabel, key, key);
};

export const getTooltip = key => {
    const tableTooltip = {
        date_update: '<div>Date the PO record was changed in ComOpps.<div> <div class="padding">Example:<div> <div>A PO is created in SAP on August 1.</div> <div>The new PO data is transferred from SAP to</div> <div>ComOpps on August 2.</div> <div>The "Change Date" is August 2.</div>'
    };
    return _.get(tableTooltip, key, null);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        description: "Search",
        date_update: "Change Date",
        sap_value: "Select"
    };
    switch (key) {
        case "sap_value":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{ value: '', label: "-" }], ...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "date_update":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
