import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {onChange} from "../../Services/EventsService";

function Product({callback, filter, isSharedTab}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>Technology</BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    {isSharedTab && (
                        <>
                            <p className="main-table__head-link">Name Contains</p>
                            <InputFilter
                                type="text"
                                placeholder="Select"
                                value={filter.name}
                                onChange={e => {
                                    onChange(e).then(_value => callback('name', _value));
                                }}
                            />

                            <p className="main-table__head-link">Name Is</p>
                            <InputFilter
                                type="asyncMultiSelect"
                                placeholder="Select"
                                url="/product/product-finder/solution-list"
                                value={filter.solution_ids}
                                onChange={e => {
                                    onChange(e).then(_value => callback('solution_ids', _value));
                                }}
                            />

                            <p className="main-table__head-link">ID</p>
                            <InputFilter
                                type="asyncMultiSelect"
                                placeholder="Select"
                                url="/product/product-finder/solution-slug-list"
                                value={filter.slug}
                                onChange={e => {
                                    onChange(e).then(_value => callback('slug', _value));
                                }}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
}

Product.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
    isSharedTab: PropTypes.bool.isRequired,
}

export default Product;
