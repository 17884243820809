import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";

const {Button, Form, Title, Col, Row, ColText, ColInput} = ModalDefault;

const propTypes = {
    titleText: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onAppend: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

class CreateContentTemplateModal extends Component {
    state = {
        name: "",
    };

    handleChange = label => value => {
        this.setState({[label]: value});
    };

    handleClickOnSaveButton = () => {
        const {name} = this.state;
        const {onAppend, onClose} = this.props;
        onAppend({name}).then(
            data => {
                onClose('showCreateTemplateModal')();
            },
            errors => {
                alert(errors.detail);
            }
        );
    };

    render() {
        const {name} = this.state;
        const {
            titleText,
            buttonText,
            t,
            onClose,
        } = this.props;

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose('showCreateTemplateModal')}/>
                <Title>{t(titleText)}</Title>
                <Row>
                    <Col>
                        <ColText className="bold-text" isRequired>
                            {t("Name")}
                        </ColText>
                        <ColInput
                            placeholder={t("Enter Name")}
                            name="name"
                            value={name}
                            onChange={this.handleChange("name")}
                        />
                    </Col>
                </Row>
                <Button onClick={this.handleClickOnSaveButton} disabled={name.trim().length === 0}>
                    {t(buttonText)}
                </Button>
            </Form>
        );
    }
}

CreateContentTemplateModal.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(CreateContentTemplateModal);
