import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowColumnTemplate from "../TableRowColumn";
import TableRowColumnButton from "./Button";
import TableRowColumnDefault from "./Default";

const propTypes = {
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
};

const defaultProps = {
    customIcon: "",
    onClickCustom: () => {},
};

const TableRowColumn = React.memo(
    ({ customIcon, secondaryIndex, column, item, onClickCustom, onClickDelete, onClickEdit }) => (
        <TableRowColumnTemplate>
            {column === "-1" ? (
                <TableRowColumnButton
                    customIcon={customIcon}
                    onClickCustom={onClickCustom}
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                    index={secondaryIndex}
                />
            ) : (
                <TableRowColumnDefault column={column} item={item} />
            )}
        </TableRowColumnTemplate>
    ),
);

TableRowColumn.propTypes = propTypes;
TableRowColumn.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowColumn);
