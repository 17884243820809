import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { items, pagination, columns, view, FY, help } = props;
    const { mainState } = initialStates;

    mainState.form.periodFrom = FY;
    mainState.form.periodTo = FY;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "forecast-view" }),
        listState: { ...mainState, items, pagination, columns, view, help },
    };

    return _configureStore(initialState, reducers);
}
