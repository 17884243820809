import React, {Component} from 'react';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {Modal} from "Templates/Modals";
import {storageUrl} from "Services";
import {withTagDefaultProps} from "Hoc/Template";
import PropTypes from "prop-types";
import {InputFilter} from "Templates/Table/Filters";
import {Label} from "Templates/Form";
import UploadFileForm from "./UploadFileForm";
import PdfViewer from "./PdfViewer";
import NotFoundBrochure from "./NotFoundBrochure";
import CopyForm from "../Technical/CopyForm";
import DownloadReport from "../../Modals/DownloadReport";

class Brochure extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            showUploadFileModal: false,
            uploadFileForm: {
                files: [],
                name: '',
            },
        };

        this.state = {
            showUploadFileModal: false,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        };
    }

    componentDidMount() {
        const {fetchSubTabs} = this.props;
        fetchSubTabs({});
    }

    onClickDeleteBrochureFromProduct = item => {
        if (!item.can_edit) {
            alert('Contact an Admin to delete.');
            return;
        }

        const {service: {onClickDeleteFromProductItem}, t, fetchItems, request} = this.props;

        if (window.confirm(t("Do you want to remove the Brochure from Product?"))) {
            onClickDeleteFromProductItem(item.grade_id).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    handleCloseUploadModalWithCheck = event => {
        event.preventDefault();

        const {uploadFileForm: {files}} = this.state;
        const {t} = this.props;

        if (files.length >= 1) {
            if (!window.confirm(t("Are you sure you want to undo the action?"))) {
                return;
            }
        }

        this.closeModal();
    };

    onClickDelete = (item) => () => {
        const {t} = this.props;

        if (window.confirm(t("Do you want to remove the Brochure?"))) {
            const {
                fetchSubTabs,
                service: {onClickDeleteItem},
            } = this.props;

            onClickDeleteItem(item).then(() => {
                fetchSubTabs({});
            });
        }
    };

    openUploadFileModal = () => {
        this.setState({
            showUploadFileModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            ...this.defaultState,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        });
    };

    callbackAfterRequestInModalWindow = res => {
        const {fetchSubTabs} = this.props;

        if (res !== undefined) {
            this.closeModal();
            fetchSubTabs({});
        }
    };

    handleClickOnUploadFile = () => {
        const {addFile} = this.props;
        const {
            uploadFileForm: {files, name},
        } = this.state;

        addFile({files, name}).then(this.callbackAfterRequestInModalWindow);
    };

    setFile = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                files: value,
            },
        }));
    };

    setName = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                name: value,
            },
        }));
    };

    onChangeForm = (key, value) => {
        let {form, callback} = this.props;

        form = {
            ...form,
            [key]: value || []
        };

        callback(key, value, form);
    }

    render() {
        const {
            subTabs,
            fetchSubTabAction,
            activeSubTab,
            storage_url,
            showModal,
            handleCloseModal,
            form
        } = this.props;
        const {showUploadFileModal, uploadFileForm} = this.state;

        return (
            <>
                {showModal && (
                    <Modal onRequestClose={handleCloseModal}>
                        <CopyForm
                            callback={this.onChangeForm}
                            datasheetId={activeSubTab.id}
                            form={form}
                            onClose={handleCloseModal}
                        />
                    </Modal>
                )}
                {showUploadFileModal ? (
                    <Modal onRequestClose={this.handleCloseUploadModalWithCheck}>
                        <UploadFileForm
                            form={uploadFileForm}
                            onAppend={this.handleClickOnUploadFile}
                            onClose={this.handleCloseUploadModalWithCheck}
                            onChange={this.setFile}
                            onChangeName={this.setName}
                        />
                    </Modal>
                ) : null}

                {subTabs && subTabs.length > 0 && (
                    <div className={'flex'}>
                        <Label label="Select"/>
                        <span className={'publications-counter flex'}>({subTabs.length})</span>
                    </div>
                )}
                <div className="tds-list-wrap">
                    {(subTabs && subTabs.length) ?
                        (
                            <InputFilter
                                type="select"
                                value={activeSubTab.id}
                                options={[...subTabs.map((val) => {
                                    return {value: val.id, label: val.custom_name}
                                })]}
                                onChange={e => {
                                    fetchSubTabAction(subTabs.find(x => x.id === e));
                                }}
                            />
                        ) : <div/>}
                    <DownloadReport label={'Brochure'}/>
                </div>

                {subTabs && subTabs.length > 0 ? (
                        <PdfViewer
                            url={activeSubTab && activeSubTab.path ? storageUrl(activeSubTab.path, storage_url) : ''}
                        />
                    )
                    : (
                        <div className="grade-view__wrap main-wrap-block technical-wrap not-available">
                            <NotFoundBrochure/>
                        </div>
                    )}
            </>
        );
    }
}

Brochure.defaultProps = {
    showModal: false,
    activeSubTab: {id: null, path: null},
    subTabs: []
}
Brochure.propTypes = {
    fetchSubTabAction: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
    activeSubTab: PropTypes.shape({id: null, path: null}),
    handleCloseModal: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func,
        onClickDeleteFromProductItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func,
        getActionStore: PropTypes.func,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchSubTabs: PropTypes.func.isRequired,
    subTabs: PropTypes.arrayOf(PropTypes.object),
    showModal: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchSubTabAction: getActionStore("addActiveSubTabAction", service, dispatch),
        fetchSubTabs: getActionStore("getSubTabs", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
        addFile: getActionStore("createFilesAction", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        callback: getActionStore("onChangeForm", service, dispatch),
    };
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        activeSubTab: getStoreItem(state, "activeSubTab"),
        subTabs: getStoreItem(state, "subTabs"),
        showModal: getStoreItem(state, "showModal"),
        form: {...getStoreItem(state, "form", true)},
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        total: getStoreItem(state, "pagination.total"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Brochure);