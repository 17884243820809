import React from "react";
import _ from "lodash";

export const getTableLabel = (key) => {
    const tableLabel = {
        name: "Product Name",
        projects: "Project Name",
        project_ids: "Project ID"
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = () => "";

export const isSortEnabled = key => (["name"].includes(key));
