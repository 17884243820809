import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import Block from "./Block";
import BlockTitle from "./BlockTitle";

const propTypes = {
    title: PropTypes.string.isRequired,
    blockClassName: PropTypes.string,
};

const BlockMain = ({ title, blockClassName, t, children }) => (
    <>
        <BlockTitle>{t(title)}</BlockTitle>
        <Block className={blockClassName}>{children}</Block>
    </>
);

BlockMain.propTypes = propTypes;

export default compose(withTagDefaultProps())(BlockMain);
