import React, {useEffect} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {projectStatus} from "Services/enum";
import {BlockWrap, Col, Form, Input, Row, WrapInput} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {RadioBlock, RadioButton, RadioLabel} from "Templates/Form/Radio";
import {MainButton} from "Templates/Content";
import {InputFilter} from "Templates/Table/Filters";
import {GraphV2Types} from "../../../Constants/graph-v2-types";

const defaultProps = {
    productCodeParams: null
}

const propTypes = {
    title: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    productsCodes: PropTypes.arrayOf(PropTypes.any).isRequired,
    old: PropTypes.shape({
        type: PropTypes.objectOf(PropTypes.any),
        marginListSelect: PropTypes.string,
        statusProject: PropTypes.arrayOf(PropTypes.string),
        periodFrom: PropTypes.string,
        periodTo: PropTypes.string,
        timeScale: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
        sortColumn: PropTypes.string.isRequired,
        sortDirection: PropTypes.string.isRequired,
        type: PropTypes.objectOf(PropTypes.any).isRequired,
        marginListSelect: PropTypes.string.isRequired,
        statusProject: PropTypes.arrayOf(PropTypes.string),
        periodFrom: PropTypes.string.isRequired,
        periodTo: PropTypes.string.isRequired,
        timeScale: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.any).isRequired,
    }).isRequired,
    isConnectionType: PropTypes.bool.isRequired,
    isActualsType: PropTypes.bool.isRequired,
    productCodeParams: PropTypes.shape({
        sortColumn: PropTypes.string.isRequired,
        sortDirection: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        marginListSelect: PropTypes.string.isRequired,
        statusProject: PropTypes.arrayOf(PropTypes.string),
        periodFrom: PropTypes.string.isRequired,
        periodTo: PropTypes.string.isRequired,
        timeScale: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.any).isRequired,
    }),
    service: PropTypes.shape({
        optionsForFirstSelect: PropTypes.func.isRequired,
        formData: PropTypes.func.isRequired,
    }).isRequired,
    getData: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    setProductsCodes: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    productCodesList: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChangeSecondSelect: PropTypes.func.isRequired,
    setSortDirection: PropTypes.func.isRequired,
    isApply: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
};

const radioButtons = [
    { label: "Fiscal Year", value: "1" },
    { label: "Quarterly", value: "2" },
    { label: "Monthly", value: "3" },
];

const FormBlock = ({
    title,
    permissions,
    productsCodes,
    productCodeParams,
    old,
    form,
    service: { optionsForFirstSelect, formData },
    getData,
    onChange,
    setProductsCodes,
    t,
    data,
    isConnectionType,
    isActualsType,
    resetForm,
    activeTab,
    productCodesList,
    onChangeSecondSelect,
    setSortDirection,
    isApply,
    onApply,
    resetConnections,
    resetActuals,
}) => {
    const initialForm = {
            sortColumn: "name",
            sortDirection: "asc",
            marginListSelect: "1",
            statusProject: [],
            items: [],
            periodFrom: new Date().getFullYear().toString(),
            periodTo: new Date().getFullYear().toString(),
            timeScale: "1",
    }
    const optionsCount = 30;
    const currentYear = new Date().getFullYear() - 20;
    const periodToOptions = new Array(optionsCount)
        .fill(null)
        .reduce(
            (acc, elem, index) => [...acc, { label: `${currentYear + index}`, value: `${currentYear + index}` }],
            [],
        );

    useEffect(() => {
        if (productCodeParams) {
            onChange('form.sortColumn', productCodeParams.sortColumn)
            onChange('form.sortDirection', productCodeParams.sortDirection)
            onChange('form.type', GraphV2Types[productCodeParams.type])
            onChange('form.marginListSelect', productCodeParams.marginListSelect)
            onChange('form.statusProject', productCodeParams.statusProject)
            onChange('form.items', productCodeParams.items)
            onChange('form.periodFrom', productCodeParams.periodFrom)
            onChange('form.periodTo', productCodeParams.periodTo)
            onChange('form.timeScale', productCodeParams.timeScale)

            if (!isApply) {
                getData(formData(productCodeParams))
            }
        } else {
            resetForm()
        }
    }, [productCodeParams])

    useEffect(() => {
        setProductsCodes(productsCodes)
    }, [productsCodes])

    const formValidate = () => {
        const { marginListSelect, periodFrom, periodTo, timeScale } = form;
        return Boolean(marginListSelect && periodFrom && periodTo && timeScale);
    };

    const resetFilters = () => {
        if (typeof window !== 'undefined') {
            const urlObject = new URL(window.location.href);
            const newUrl = activeTab === 1 ? `${urlObject.origin}${urlObject.pathname}?activeTab=1` : `${urlObject.origin}${urlObject.pathname}`
            history.pushState({}, '', newUrl)
        }
        resetForm()
        resetConnections()
        resetActuals()
    };

    const onSortClick = () => {
        if (form.sortDirection === 'asc') {
            setSortDirection('desc');
            getData(formData({...form, sortDirection: 'desc'}));
        }

        if (form.sortDirection === 'desc') {
            setSortDirection('asc')
            getData(formData({...form, sortDirection: 'asc'}))
        }
    }

    const periodFromOptions = periodToOptions.map(elem => ({ ...elem, disable: elem.value < form.periodFrom }));

    const handleApply = () => {
        getData(formData({...form, items: []}))
        onApply()
    }

    return (
        <BlockWrap className="analytic-graph-v2-chart-form">
            <Form>
                <div className="analytic-graph-v2-chart-form__button-wrap">
                    <WrapInput name="type" label={t("Type")} className="graph-v2-type">
                        <Row>
                            <Col>
                                <Input
                                    type="select"
                                    options={Object.values(GraphV2Types)}
                                    name="type"
                                    placeholder={t("Select")}
                                    value={form.type.value}
                                    onChange={value => {
                                        const newType = Object.values(GraphV2Types).find(item => item.value === value) || GraphV2Types.TYPE_1;

                                        if (old.type && (old.type.value === newType.value)) return

                                        onChange("form.type", newType)
                                        getData(formData({...form, type: newType, items: []}))
                                        onApply()
                                    }}
                                />
                            </Col>
                        </Row>
                    </WrapInput>
                    <button
                        type="button"
                        className="main-btn__general main-btn__general main-btn_white"
                        onClick={resetFilters}
                    >
                        Reset
                    </button>
                </div>
                <div className={`analytics-graphs-v2-view__form-wrap ${isConnectionType ? 'analytics-graphs-v2-view__form-wrap-connections' : ''}`}>
                    {isConnectionType ? <>
                        <WrapInput name="Select 1" className="graph-v2-radio">
                            <Row>
                                {optionsForFirstSelect(permissions).map(elem => (
                                    <Col key={`Col-${elem.value}`}>
                                        <RadioBlock>
                                            <RadioButton
                                                htmlFor={`radio_margin_1-${elem.value}`}
                                                name={`radio-margin-${elem.value}`}
                                                value={form.marginListSelect === elem.value}
                                                onChange={() => onChange("form.marginListSelect", elem.value)}
                                            >
                                                <RadioLabel label={t(elem.label)} />
                                            </RadioButton>
                                        </RadioBlock>
                                    </Col>
                                ))}
                            </Row>
                        </WrapInput>
                        <WrapInput name="statusProject" label={t("Status")} className="graph-v2-status">
                            <Row>
                                <Col>
                                    <Input
                                        type="multiSelect"
                                        options={[
                                            ...projectStatus,
                                        ]}
                                        name="statusProject"
                                        placeholder={t("Select")}
                                        value={form.statusProject || []}
                                        onChange={value => onChange("form.statusProject", value)}
                                    />
                                </Col>
                            </Row>
                        </WrapInput>
                    </> : isActualsType ? <>
                        <WrapInput name="Select 1" className="graph-v2-radio">
                            <Row>
                                {optionsForFirstSelect(permissions).map(elem => (
                                    <Col key={`Col-${elem.value}`}>
                                        <RadioBlock>
                                            <RadioButton
                                                htmlFor={`radio_margin_1-${elem.value}`}
                                                name={`radio-margin-${elem.value}`}
                                                value={form.marginListSelect === elem.value}
                                                onChange={() => onChange("form.marginListSelect", elem.value)}
                                            >
                                                <RadioLabel label={t(elem.label)} />
                                            </RadioButton>
                                        </RadioBlock>
                                    </Col>
                                ))}
                            </Row>
                        </WrapInput>
                        <WrapInput name="Period" label={t("Period")} className="graph-v2-periods">
                            <Row>
                                <Col>
                                    <Input
                                        type="select"
                                        options={periodToOptions}
                                        name="Period1"
                                        placeholder={t("Select")}
                                        value={form.periodFrom}
                                        onChange={value => onChange("form.periodFrom", value)}
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        type="select"
                                        options={periodFromOptions}
                                        name="Period2"
                                        inputProps={{ isOptionDisabled: option => option.disable }}
                                        placeholder={t("Select")}
                                        value={form.periodTo}
                                        onChange={value => onChange("form.periodTo", value)}
                                    />
                                </Col>
                            </Row>
                        </WrapInput>
                    </> : <>
                        <WrapInput name="Select 1" className="graph-v2-radio">
                            <Row>
                                {optionsForFirstSelect(permissions).map(elem => (
                                    <Col key={`Col-${elem.value}`}>
                                        <RadioBlock>
                                            <RadioButton
                                                htmlFor={`radio_margin_1-${elem.value}`}
                                                name={`radio-margin-${elem.value}`}
                                                value={form.marginListSelect === elem.value}
                                                onChange={() => onChange("form.marginListSelect", elem.value)}
                                            >
                                                <RadioLabel label={t(elem.label)} />
                                            </RadioButton>
                                        </RadioBlock>
                                    </Col>
                                ))}
                            </Row>
                        </WrapInput>
                        <WrapInput name="Period" label={t("Period")} className="graph-v2-periods">
                            <Row>
                                <Col>
                                    <Input
                                        type="select"
                                        options={periodToOptions}
                                        name="Period1"
                                        placeholder={t("Select")}
                                        value={form.periodFrom}
                                        onChange={value => onChange("form.periodFrom", value)}
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        type="select"
                                        options={periodFromOptions}
                                        name="Period2"
                                        inputProps={{ isOptionDisabled: option => option.disable }}
                                        placeholder={t("Select")}
                                        value={form.periodTo}
                                        onChange={value => onChange("form.periodTo", value)}
                                    />
                                </Col>
                            </Row>
                        </WrapInput>
                        <WrapInput name="Timescale" className="graph-v2-radio">
                            <Row>
                                {radioButtons.map(elem => (
                                    <Col key={`Col-${elem.value}`}>
                                        <RadioBlock>
                                            <RadioButton
                                                htmlFor={`radio_1-${elem.value}`}
                                                name={`radio-${elem.value}`}
                                                value={form.timeScale === elem.value}
                                                onChange={() => onChange("form.timeScale", elem.value)}
                                            >
                                                <RadioLabel label={t(elem.label)} />
                                            </RadioButton>
                                        </RadioBlock>
                                    </Col>
                                ))}
                            </Row>
                        </WrapInput>
                        <WrapInput name="statusProject" label={t("Status")} className="graph-v2-status">
                            <Row>
                                <Col>
                                    <Input
                                        type="multiSelect"
                                        options={[
                                            ...projectStatus,
                                        ]}
                                        name="statusProject"
                                        placeholder={t("Select")}
                                        value={form.statusProject || []}
                                        onChange={value => onChange("form.statusProject", value)}
                                    />
                                </Col>
                            </Row>
                        </WrapInput>
                    </>}
                    <div className="form-block-button">
                        <MainButton
                            disabled={!formValidate() || JSON.stringify(form) === JSON.stringify(old)}
                            className="main-btn_primary"
                            onClick={() => handleApply()}
                        >
                            {t("Apply")}
                        </MainButton>
                    </div>
                </div>
            </Form>
            {(Object.keys(old).length > 0 && JSON.stringify(initialForm) !== JSON.stringify(form) || Object.keys(data).length > 0) && (
                <div className="product-codes__actions">
                    <div className="product-codes__select-wrap">
                        <a
                            href="#"
                            className="main-table__head-link"
                            onClick={e => {
                                e.preventDefault();
                                onSortClick();
                            }}
                        >
                            {title}
                            <span className="main-table__head-link-icons">
                                <i className={`fas fa-sort-up main-table__head-link-icon 
                                    ${form.sortDirection === "asc" ? "icon-active" : ""}`}
                                />
                                <i className={`fas fa-sort-down main-table__head-link-icon 
                                    ${form.sortDirection === "desc" ? "icon-active" : ""}`}
                                />
                            </span>
                        </a>

                        <InputFilter
                            type="multiSelect"
                            value={form.items}
                            options={[...productCodesList]}
                            placeholder="Select"
                            onChange={value => {
                                onChangeSecondSelect(value, formData({...form}))
                            }}
                        />
                    </div>
                </div>
            )}
        </BlockWrap>
    );
};

FormBlock.defaultProps = defaultProps;
FormBlock.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        title: getStoreItem(state, "title"),
        old: getStoreItem(state, "old"),
        form: getStoreItem(state, "form"),
        data: getStoreItem(state, "data"),
        isConnectionType: getStoreItem(state, "isConnectionType"),
        isActualsType: getStoreItem(state, "isActualsType"),
        productCodesList: getStoreItem(state, "productCodesList"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        resetForm: getActionStore("resetForm", service, dispatch),
        onChange: getActionStore("onChange", service, dispatch),
        getData: getActionStore("getDataAction", service, dispatch),
        setProductsCodes: getActionStore("onSetProductsCodes", service, dispatch),
        setSortDirection: getActionStore("setSortDirection", service, dispatch),
        onChangeSecondSelect: getActionStore("onChangeSecondSelectAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
