import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import {getFilter, getTableLabel, getTooltip, isSortEnabled} from "./TableHeaderService";
import { getViewItem, modifierValues, tableButtonsPermission } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/product/product-finder/${id}/projects`),
    onClickDeleteItem: item => deleteItem(`/product/projects/${item.id}/delete`),
    getViewItem,
    getFilter: getFilter(id),
    modifierValues,
    tableButtonsPermission,
    getTableLabel,
    getTooltip,
    handleScroll,
    getStoreItem,
    getActionStore,
    isSortEnabled,
});
