import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {storageUrl, numberWithCommas} from "Services";
import {withServiceConsumer} from "Services/Context";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {
    ContentWrapCol,
    MainButton,
    TabBlock,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import {Modal} from "Templates/Modals";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import {WrapButtons} from "Templates/Titles";
import {Div} from "Templates/Default";
import ContainerTable from "../../../Services/Tabs/Files/Table/ContainerTable";

import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import UploadFileForm from "./UploadFileForm";
import {customTable} from "../../../Services/Tabs/Files/Table/TabViewDetailWrap.js";
import FolderForm from "./FolderForm";
import Breadcrumbs from "./Breadcrumbs";

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TabTable);

class Files extends Component {
    constructor(props) {
        super(props);
        this.folderForEdit = {};
        this.defaultState = {
            showUploadFileModal: false,
            showCreateFolderModal: false,
            uploadFileForm: {
                files: [],
            },
        };

        this.state = {
            showUploadFileModal: false,
            showCreateFolderModal: false,
            folderId: '',
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        };
    }

    onClickDelete = item => {
        const {t, canEdit} = this.props;
        if (!canEdit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (confirm(t("Do you want to delete the File?"))) {
            const {
                fetchItems,
                request,
                service: {onClickDeleteItem},
            } = this.props;
            onClickDeleteItem(item).then(() => {
                request.path = this.state.folderId;
                fetchItems(request);
            });
        }
    };

    handleClickOnDownloadFile = ({id, name}) => {
        const link = document.createElement("a");
        link.setAttribute('download', name);
        link.href = `/project/projects/${id}/files/download`;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    onClickItem = (folderId) => {
        const {
            fetchItems,
            request
        } = this.props;

        request.path = folderId;
        fetchItems(request)

        this.setState({folderId});
    };

    handleClickOnUploadFile = () => {
        const {addFiles} = this.props;
        const {
            uploadFileForm: {files},
        } = this.state;

        addFiles({files, parentId: this.state.folderId}).then(this.callbackAfterRequestInModalWindow);
    };

    setFile = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                files: [...value],
            },
        }));
    };

    openUploadFileModal = () => {
        this.setState({
            showUploadFileModal: true,
        });
    };

    openCreateFolderModal = () => {
        this.setState({
            showCreateFolderModal: true,
        });
    };

    handleClickEdit = ({id, name}) => {
        this.folderForEdit = {
            id,
            folderName: name,
            oldFolderName: name,
        };
        this.openCreateFolderModal();
    };

    closeModal = () => {
        this.folderForEdit = {};
        this.setState({
            ...this.defaultState,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        });
    };

    handleCloseUploadModalWithCheck = event => {
        event.preventDefault();
        const {
            uploadFileForm: {files},
        } = this.state;
        const {t} = this.props;
        if (files.length >= 1) {
            if (!confirm(t("Are you sure you want to undo the action?"))) {
                return;
            }
        }
        this.closeModal();
    };

    callbackAfterRequestInModalWindow = res => {
        const {fetchItems, request} = this.props;
        if (res !== undefined) {
            this.closeModal();
            request.path = this.state.folderId;
            fetchItems(request);
        }
    };

    render() {
        const {pagination, t, service: {customIcon}, items, canEdit} = this.props;
        const {showCreateFolderModal, showUploadFileModal, uploadFileForm, tableType} = this.state;

        return (
            <>
                {showCreateFolderModal ? (
                    <Modal onRequestClose={this.closeModal}>
                        <FolderForm
                            data={this.folderForEdit}
                            parentId={this.state.folderId}
                            cbAfterRequest={this.callbackAfterRequestInModalWindow}
                            onClose={this.closeModal}
                        />
                    </Modal>
                ) : null}
                {showUploadFileModal ? (
                    <Modal onRequestClose={this.handleCloseUploadModalWithCheck}>
                        <UploadFileForm
                            form={uploadFileForm}
                            onAppend={this.handleClickOnUploadFile}
                            onClose={this.handleCloseUploadModalWithCheck}
                            onChange={this.setFile}
                        />
                    </Modal>
                ) : null}
                <TabWrapTop className="panel-file-list">
                    <Div className="statistic-bar">
                        <TabBlock>
                            <TabBlockCol>
                                <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                                <TabBlockColTitleH4>
                                    {`${numberWithCommas(pagination.total, 0)} ${t(
                                        "Files",
                                    )}`}
                                </TabBlockColTitleH4>
                            </TabBlockCol>
                        </TabBlock>
                        {canEdit && (
                            <WrapButtons>
                                <MainButton className="main-btn_white" onClick={this.openCreateFolderModal}>
                                    {t("Create Folder")}
                                </MainButton>
                                <MainButton className="main-btn_primary" onClick={this.openUploadFileModal}>
                                    {t("Add New File")}
                                </MainButton>
                            </WrapButtons>
                        )}
                    </Div>
                    <Breadcrumbs onClickItem={this.onClickItem}/>
                </TabWrapTop>
                <TabViewDetail
                    customIcon={customIcon(items)}
                    onClickCustom={this.handleClickOnDownloadFile}
                    onClickDelete={this.onClickDelete}
                    onClickEdit={this.handleClickEdit}
                    onClickItem={this.onClickItem}
                />
            </>
        );
    }
}

Files.propTypes = {
    pagination: PropTypes.shape({total: PropTypes.number}).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func,
        getStoreItem: PropTypes.func,
        getActionStore: PropTypes.func,
    }).isRequired,
    canEdit: PropTypes.bool.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
    addFiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        items: getStoreItem(state, "items"),
        canEdit: getStoreItem(state, "view.canEdit"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            }
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        addFiles: getActionStore("createFilesAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Files);
