import React, {Fragment, useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {countryList} from "Services";
import {datasheetLocation, datasheetPaintable, isMolded, isBoundable} from "Services/enum";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoContactWrapper,
    ContentInfoHeader,
    ContentInfoHeaderWrap,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap
} from "Templates/Content";
import {Tooltip} from "Templates/Form";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import Img, {ImgStorage} from "Templates/Img";
import InfoPopup from "Templates/InfoPopup/InfoPopup";

const propTypes = {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    datasheet: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
    activeTab: PropTypes.number.isRequired,
};

const InfoWrap = (
    {
        name,
        slug,
        company,
        datasheet,
        t,
        activeTab,
    }) => {

    const [isShownMarketSegmentsContent, setIsShownMarketSegmentsContent] = useState(true);
    const [isShownSegmentsContent, setIsShownSegmentsContent] = useState(true);
    const [isShownLocationsContent, setIsShownLocationsContent] = useState(true);
    const [isShownCountryContent, setIsShowCountryContent] = useState(true);
    const [subSegments, setSubSegment] = useState([])

    useEffect(() => {
        if (!datasheet || !datasheet.sub_segments || !datasheet.sub_segments.length) {
            setSubSegment([]);
        } else {
            setSubSegment(datasheet.sub_segments);
        }
    }, [datasheet.sub_segments]);

    const paintableView = () => {
        return datasheet.paintable ? datasheetPaintable.find(el => el.value.toString() === datasheet.paintable.toString()).label : '';
    };

    const boundableView = () => {
        return datasheet.is_boundable ? isBoundable.find(el => el.value === datasheet.is_boundable).label : '';
    };

    const locationView = (elem) => {
        return elem ? datasheetLocation.find(el => el.value.toString() === elem.toString()).label : '-';
    };

    const countryView = (elem) => {
        return elem ? countryList.find(el => el.value.toString() === elem.toString()).label : '';
    };

    const approvalView = (elem) => {
        let response = '-';

        if (elem) {
            if (elem.approval_name && elem.code_name) {
                response = `${elem.approval_name} - ${elem.code_name}`;
            } else if (elem.approval_name) {
                response = elem.approval_name;
            }
        }

        return response;
    };

    const showContent = (array, toggle, elemView) => {
        if (!toggle) {
            return array.slice(0, 3).map((elem, index) => (
                <ContentInfoBlockText key={index} value={elemView ? elemView(elem) : elem} />
            ));
        }

        return array.map((elem, index) => (
            <ContentInfoBlockText key={index} value={elemView ? elemView(elem) : elem} />
        ));
    };

    const showCountries = (array, toggle, elemView) => {
        const items = array.map((elem) => (
            elemView ? elemView(elem) : elem
        )).sort((a, b) => {
            // Global option should be the first in order
            if (a === 'Global') {
                return -1;
            }

            if (b === 'Global') {
                return 1;
            }

            return a > b ? 1 : -1;
        });

        return showContent(items, toggle)
    };

    const tabCountryLabel = ['Technical Data Sheet', 'Processing Data Sheet', 'Guide', 'Safety Data Sheet', 'Brochure'];

    const showData = () => {
        if (datasheet.paintable
            || datasheet.molded
            || datasheet.colors.length
            || datasheet.locations.length
            || datasheet.countries.length
            || datasheet.contacts.length
            || datasheet.market_segments.length
            || datasheet.segments.length
            || datasheet.sub_segments.length
            || datasheet.manufacturers.length
        ) {

            const activeMolds = isMolded.filter((value) => {
                return datasheet.molded && datasheet.molded[value.value];
            });

            return (
                <>
                    <ContentInfoBlock show={!!datasheet.market_segments.length}>
                        <ContentInfoBlockTitle>{t("Market Segments")}</ContentInfoBlockTitle>
                        {showContent(datasheet.market_segments, isShownMarketSegmentsContent)}
                        {datasheet.market_segments.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownMarketSegmentsContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShownMarketSegmentsContent(!isShownMarketSegmentsContent)}
                            >
                                Show
                                {' '}
                                {isShownMarketSegmentsContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!datasheet.segments.length}>
                        <ContentInfoBlockTitle>{t("Segments")}</ContentInfoBlockTitle>
                        {showContent(datasheet.segments, isShownSegmentsContent)}
                        {datasheet.segments.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownSegmentsContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShownSegmentsContent(!isShownSegmentsContent)}
                            >
                                Show
                                {' '}
                                {isShownSegmentsContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>

                    <ContentInfoBlock show={!!subSegments.length}>
                        <ContentInfoBlockTitle>{t("Sub-Segments")}</ContentInfoBlockTitle>
                        {subSegments.length > 3 ? (
                            subSegments.slice(0, 3).map((elem, index) => (
                                <ContentInfoBlockText key={index} value={elem}/>
                            ))
                        ) : subSegments.map((elem, index) => (
                            <ContentInfoBlockText key={index} value={elem}/>
                        ))}
                        {subSegments.length > 3 && (
                            <InfoPopup type={'segments'} left={false} content={[...subSegments].map((elem, index) => (
                                <ContentInfoBlockText key={index} value={elem}/>))}
                            />
                        )}
                    </ContentInfoBlock>

                    {/*<ContentInfoBlock show={!!datasheet.manufacturers.length}>*/}
                    {/*    <ContentInfoBlockTitle>*/}
                    {/*        {t("Part Approvals")}*/}
                    {/*        <Tooltip>*/}
                    {/*            {`This is a list of manufactures using this material in a part without an approved OEM specification. Confirm part details in the projects tab.`}*/}
                    {/*        </Tooltip>*/}
                    {/*    </ContentInfoBlockTitle>*/}
                    {/*    {datasheet.manufacturers.length > 3 ? (*/}
                    {/*        datasheet.manufacturers.slice(0, 3).map((elem, index) => (*/}
                    {/*            <ContentInfoBlockText key={index} value={elem}/>*/}
                    {/*        ))*/}
                    {/*    ) : datasheet.manufacturers.map((elem, index) => (*/}
                    {/*        <ContentInfoBlockText key={index} value={elem}/>*/}
                    {/*    ))}*/}
                    {/*    {datasheet.manufacturers.length > 3 && (*/}
                    {/*        <InfoPopup type={'manufacturers'} left={false} content={[...datasheet.manufacturers].map((elem, index) => (*/}
                    {/*            <ContentInfoBlockText key={index} value={elem}/>))}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*</ContentInfoBlock>*/}

                    <ContentInfoBlock show={!!datasheet.countries.length}>
                        <ContentInfoBlockTitle>
                            {t("Countries")}
                            <Tooltip>
                                {`This ${tabCountryLabel[activeTab] || 'Technical Data Sheet'} is applicable to these countries. Verify Compliance.`}
                            </Tooltip>
                        </ContentInfoBlockTitle>
                        {showCountries(datasheet.countries, isShownCountryContent, countryView)}
                        {datasheet.countries.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownCountryContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShowCountryContent(!isShownCountryContent)}
                            >
                                Show
                                {' '}
                                {isShownCountryContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>

                    {!!datasheet.contacts.length && (
                        <ContentInfoBlockTitle>{t("Contacts")}</ContentInfoBlockTitle>
                    )}

                    {!!datasheet.contacts.length && datasheet.contacts.map((contact, index) => (
                        <Fragment key={`contact-${index}`}>
                            <ContentInfoBlock show={!!contact.id}>
                                <ContentInfoContactWrapper>
                                    <a
                                        href={`/admin/users/${contact.id}`}
                                        rel="nofollow noopener noreferrer"
                                        target="_blank"
                                    >
                                        {
                                            contact.photo ? (
                                                <ImgStorage
                                                    href={`/admin/users/${contact.id}`}
                                                    url={contact.photo}
                                                    className="grade-view__img"
                                                />
                                            ) : (
                                                <Img img="icon_contact_small" className="grade-view__img"/>
                                            )
                                        }
                                    </a>
                                    <ContentInfoBlock>
                                        <ContentInfoBlockText value={contact.name || ''}/>
                                        <ContentInfoBlockTitle>{contact.relation_role ? contact.relation_role : ''}</ContentInfoBlockTitle>
                                        <ContentInfoBlockTitle>{contact.role ? contact.role : ''}</ContentInfoBlockTitle>
                                        <TableRowItemLink
                                            title={contact.email}
                                            href={`mailto:${contact.email}`}
                                        >
                                            {contact.email}
                                        </TableRowItemLink>
                                        <ContentInfoBlockText value={contact.phone || ''}/>
                                    </ContentInfoBlock>
                                </ContentInfoContactWrapper>
                            </ContentInfoBlock>
                        </Fragment>
                    ))}
                </>
            )
        }

        return null;
    };

    const getSlugInfoBlock = () => {
        return (
            <ContentInfoBlock show={!!slug}>
                <ContentInfoBlockTitle>{t("Publication ID")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={slug}/>
            </ContentInfoBlock>
        )
    }

    return (
        <ContentInfoWrap>
            <ContentInfoHeaderWrap>
                <ContentInfoTop
                    onClickEdit={null}
                    urlBack="/product/product-finder?activeTab=2"
                />
                <ContentInfoHeader>
                    <ContentInfoTitle>{`${name}`}</ContentInfoTitle>
                    <ContentInfoBlockTitle>{company}</ContentInfoBlockTitle>
                </ContentInfoHeader>
            </ContentInfoHeaderWrap>

            <div className="product__filters">
                {getSlugInfoBlock()}
                {showData()}
            </div>
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "view.name"),
        slug: getStoreItem(state, "view.slug"),
        company: getStoreItem(state, "view.company"),
        datasheet: getStoreItem(state, "view.datasheet"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
