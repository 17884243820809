import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import Img from "Templates/Img/Img";
import {onChange} from "../../Services/EventsService";
import {onChangeFilterForm} from "../../Actions/TabActions";

function SavedFilter(props) {
    const { t, service, callback, filter, handleReset } = props;
    const [showContent, setShowContent] = React.useState(false);
    const [optionsUrl, setOptionsUrl] = React.useState(`/product/product-finder/grade-filter-list`);
    const handleToggleContent = () => {
        setShowContent(!showContent)
    }


    const onClickDelete = item => {
        const { deleteItem } = service;

        if (window.confirm(t("Do you want to delete the selected saved filter?"))) {
            setOptionsUrl('//');

            deleteItem(`/product/product-finder/filters/${item}/delete`).then(
                async () => {
                    alert('Your saved filter was successfully deleted.');
                    handleReset();
                    setOptionsUrl(`/product/product-finder/grade-filter-list`);
                    onChangeFilterForm()
                },
                errors => {
                    alert(errors.detail);
                },
            )
        }
    };

    return (
        <>
            <Div className="toggle__show-block">
                <a title="A list of saved searches">
                    <BlockTitle>Saved Filters</BlockTitle>
                </a>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters saved-filters">
                    <InputFilter
                        type="asyncSelect"
                        placeholder="Select"
                        url={optionsUrl}
                        value={filter}
                        onChange={e => {
                            onChange(e).then(_value => callback(_value));
                        }}
                    />
                    {filter && (
                        <button
                            type="button"
                            className="remove-saved-filter"
                            onClick={() => {
                                onClickDelete(filter)
                            }}
                        >
                            <Img img="icon_delete" />
                        </button>
                    )}
                </div>
            )}
        </>
    );
}

SavedFilter.propTypes = {
    service: PropTypes.shape({ deleteItem: PropTypes.func.isRequired }).isRequired,
    callback: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    filter: PropTypes.string.isRequired,
}

const mapStateToProps = () => {
};

const mapDispatchToProps = () => {
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SavedFilter);
