import { request, url } from "Services";

const getItems = (data, listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, listUrl).then(res => {
            resolve(res[0]);
        }, reject);
    });
};
