import React, { Component } from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {formatDateToLocal} from "Services";
import {withServiceConsumer} from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    getNotifications: PropTypes.func.isRequired,
    readNotification: PropTypes.func.isRequired,
    service: PropTypes.shape({
        handleScroll: PropTypes.func.isRequired,
        getMessage: PropTypes.func.isRequired,
        getLink: PropTypes.func.isRequired,
    }).isRequired,
};

class Notifications extends Component {
    state = {
        items: [],
        pagination: {},
    };

    componentDidMount() {
        const { getNotifications } = this.props;
        getNotifications().then(res => this.setState({ ...res }));
    }

    hangleClickOnBell = () => {
        this.setState(prevState => ({ openNotifications: !prevState.openNotifications }));
    };

    readNotificationAndGetAll = notification => {
        if (!notification.status) {
            const { readNotification } = this.props;
            readNotification(notification.id).then(() => {
                const { getNotifications } = this.props;
                getNotifications().then(res => this.setState({ ...res }));
            });
        }
    };

    readNotification = notification => {
        if (!notification.status) {
            const { readNotification } = this.props;
            readNotification(notification.id);
        }
    };

    onScroll = e => {
        const { pagination } = this.state;
        if (pagination.page >= pagination.pages) {
            return;
        }

        const {
            service: { handleScroll },
        } = this.props;
        handleScroll(e).then(() => {
            const { getNotifications } = this.props;
            getNotifications({ page: pagination.page + 1 }).then(res =>
                this.setState(prevState => ({
                    items: [...prevState.items, ...res.items],
                    pagination: { ...res.pagination },
                })),
            );
        });
    };

    stopTheAscent = event => {
        if (typeof event.stopPropagation === "function") {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            return;
        }
        event.cancelBubble = true;
    };

    render() {
        const {
            service: { getMessage, getLink },
            t,
        } = this.props;
        const { items } = this.state;
        return (
            <div className="notifications" onClick={this.stopTheAscent}>
                <div className="notifications__title">{t("Notifications")}</div>
                {items.length !== 0 ? (
                    <ul className="notifications__list" onScroll={this.onScroll}>
                        {items.map((notification, index) => (
                            <li
                                key={`Notification-${index}`}
                                className={`notifications__item ${notification.status ? "" : "not-read"}`}
                            >
                                <div
                                    className="notifications__content"
                                    onClick={() => this.readNotificationAndGetAll(notification)}
                                >
                                    <div className="notifications__caption">
                                        {t(getMessage(notification.type, notification.data))}
                                    </div>
                                    <div className="notifications__date">
                                        {formatDateToLocal(notification.date, "MMM DD, YYYY h:mm A")}
                                    </div>
                                </div>
                                {getLink(notification, this.readNotification)}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="notifications__empty">Nothing to show</div>
                )}
            </div>
        );
    }
}

Notifications.propTypes = propTypes;

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        getNotifications: getActionStore("getNotificationsAction", service, dispatch),
        readNotification: getActionStore("readNotificationAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Notifications);
