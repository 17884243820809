import {
    ADMIN_LISTS_CRUD_PERMISSIONS, COMPANIES_VIEW_PERMISSIONS,
    CONNECTION_VIEW_PERMISSIONS, CONTACTS_VIEW_PERMISSIONS,
    FINANCE_VIEW_PERMISSIONS,
    FORECAST_ALL_UNITS_ACCESS_PERMISSIONS,
    FORECAST_GRAPH_VIEW_PERMISSIONS,
    FORECAST_INVOICES_VIEW_PERMISSIONS,
    GRADE_PRODUCT_FILES_PERMISSIONS,
    GRADE_PRODUCT_FINDER_VIEW_PERMISSIONS,
    IHV_LVP_ACCESS_PERMISSIONS, PLANT_RAW_VIEW_PERMISSIONS,
    PRODUCT_NOTE_VIEW_PERMISSIONS,
    PRODUCT_PUBLICATIONS_GUESTS_PERMISSIONS,
    PRODUCT_PUBLICATIONS_VIEW_PERMISSIONS,
    PRODUCT_SHARED_COMPARE_ACCESS_PERMISSIONS, PRODUCT_SOLUTIONS_VIEW_PERMISSIONS,
    PROJECT_CRUD_PERMISSIONS, VENDOR_VIEWS_PERMISSIONS
} from "./PartialsPermissions";
import ALL_PERMISSIONS from "./AllPermissions";

const filterHierarchiesPermissions = (keys, value, teamPermissionsList) => {
    if(value) {
        return [...teamPermissionsList, ...keys];
    }

    return teamPermissionsList.filter((item) => !keys.includes(item));
}

export const generatePermissions = (key, val, index, team) => {
    switch (key) {
        case 'admin':
            if(val) {
                team[index].permission = ALL_PERMISSIONS.filter((item) => item !== 'only_api');
            } else {
                team[index].permission = team[index].permission.includes('only_api') ? ['only_api'] : team[index].permission.filter((item) => item !== 'admin');
            }
            break;
        case 'only_api':
            if(val) {
                team[index].permission = ['only_api'];
            } else {
                team[index].permission = team[index].permission.filter((item) => item !== 'only_api');
            }
            break;
        case 'product_publications_view':
            team[index].permission = filterHierarchiesPermissions(
                PRODUCT_PUBLICATIONS_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'product_solutions_view':
            team[index].permission = filterHierarchiesPermissions(
                PRODUCT_SOLUTIONS_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'admin_lists_crud':
            team[index].permission = filterHierarchiesPermissions(
                ADMIN_LISTS_CRUD_PERMISSIONS,
                val,
                team[index].permission
            )
            break;
        case 'grade_product_finder_view':
            team[index].permission = filterHierarchiesPermissions(
                [
                    ...GRADE_PRODUCT_FINDER_VIEW_PERMISSIONS,
                    ...PRODUCT_NOTE_VIEW_PERMISSIONS,
                    ...GRADE_PRODUCT_FILES_PERMISSIONS
                ],
                val,
                team[index].permission
            );
            break;
        case 'product_note_view':
            team[index].permission = filterHierarchiesPermissions(
                PRODUCT_NOTE_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'grade_product_files':
            team[index].permission = filterHierarchiesPermissions(
                GRADE_PRODUCT_FILES_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'product_publications_guest':
            team[index].permission = filterHierarchiesPermissions(
                PRODUCT_PUBLICATIONS_GUESTS_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'product_shared_compare_access':
            team[index].permission = filterHierarchiesPermissions(
                PRODUCT_SHARED_COMPARE_ACCESS_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'forecast_graph_view':
            team[index].permission = filterHierarchiesPermissions(
                FORECAST_GRAPH_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'project_crud':
            team[index].permission = filterHierarchiesPermissions(
                PROJECT_CRUD_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'ihv_lvp_access':
            team[index].permission = filterHierarchiesPermissions(
                IHV_LVP_ACCESS_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'forecast_all_units_access':
            team[index].permission = filterHierarchiesPermissions(
                FORECAST_ALL_UNITS_ACCESS_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'finance_view':
            team[index].permission = filterHierarchiesPermissions(
                FINANCE_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'forecast_invoices_view':
            team[index].permission = filterHierarchiesPermissions(
                FORECAST_INVOICES_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'connection_view':
            team[index].permission = filterHierarchiesPermissions(
                CONNECTION_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'plant_raw_view':
            team[index].permission = filterHierarchiesPermissions(
                PLANT_RAW_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'companies_view':
            team[index].permission = filterHierarchiesPermissions(
                COMPANIES_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'vendors_view':
            team[index].permission = filterHierarchiesPermissions(
                VENDOR_VIEWS_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        case 'contacts_view':
            team[index].permission = filterHierarchiesPermissions(
                CONTACTS_VIEW_PERMISSIONS,
                val,
                team[index].permission
            );
            break;
        default:
            if(val) {
                team[index].permission.push(key);
            } else {
                team[index].permission = team[index].permission.filter((item) => item !== key);
            }
    }
}
