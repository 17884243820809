import AppReducer, { initialState as appState } from "../Reducers/AppReducer";
import UserReducer, { initialState as userState } from "../Reducers/UserReducer";
import WeightUnitReducer, { initialState as weightUnitState } from "../Reducers/WeightUnitReduser";

export const mapStateToProps = state => ({
    pref: state.appState.pref,
    src: state.appState.src,
    storage_url: state.appState.storage_url,
});

export const dispatchStateToProps = dispatch => ({});

export const getMainModifiedStoreItems = ({ user, pref, editorKey, alerts}) => {
  const { weightUnit } = user;

  return {
    appState: { ...appState, pref, editorKey, alerts },
    userState: { ...userState, ...user },
    weightUnitState: { ...weightUnitState, weightUnit },
  };
}

export const getMainInitialStates = {
    appState: { ...appState },
    userState: { ...userState },
    weightUnitState: { ...weightUnitState },
};

export const getMainReducers = {
    appState: AppReducer,
    userState: UserReducer,
    weightUnitState: WeightUnitReducer,
};
