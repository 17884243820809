import _ from "lodash";
import Constants from "../Constants";

export const budgetDefaultLabels = [
    "Price",
    "Volume",
    "Revenue",
    "stretch_volume",
    "stretch_revenue",
    "sales_manager",
];

const defaultYearData = budgetDefaultLabels.reduce((acc, elem) => {
    return { ...acc, [elem.toLowerCase()]: "" }
}, {});

export const initialState = {
    years: {},
    productCode: "",
    applicationCode: "",
    plant: "",
    materialType: "",
    form_validate: false,
    form_loading: false,
    form_errors: {},
    old: {},
};

const validate = ({ productCode, applicationCode, plant, materialType, years }) => {
    const yearsArr = Object.keys(years);
    const checkString = [productCode, applicationCode, plant, materialType].every(el => typeof el === "string" && el.trim());
    const checkOneOfYear =  yearsArr.every(year => year);

    return checkString && checkOneOfYear;
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };
        }

        case Constants.ADD_YEAR: {
            const newState = {
                ...state,
                years: { ...state.years, "": _.cloneDeep(defaultYearData) },
            };

            return {...newState, form_validate: validate(newState) };
        }

        case Constants.REMOVE_YEAR: {
            const { years } = state;
            delete years[data.year];

            return { ...state, form_validate: validate(state), years: { ...state.years } };
        }

        case Constants.CHANGE_YEAR: {
            const { years } = state;

            years[data.year] = _.cloneDeep(years[data.oldYear]);
            delete years[data.oldYear];

            return { ...state, form_validate: validate(state), years: { ...state.years } };
        }

        case Constants.CHANGE_DATA: {
            const { year, key, value } = data;

            const newState = {
                ...state,
                years: {
                    ...state.years,
                    [year]: {
                        ...state.years[year],
                        [key]: value,
                    },
                },
            };

            return { ...newState, form_validate: validate(newState) };
        }

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };

        default:
            return { ...state };
    }
};
