import React, {useEffect} from "react";
import {compose} from "redux";
import {Editor} from '@tinymce/tinymce-react';
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

/**
 * List of toolbar actions. Each element of array is string which contains @tinymce buttons
 * and in result it will be divided into groups by  "|" symbol
 * @type {string}
 */
const TOOLBAR_ITEMS = [
    'spellchecker',
    'undo redo',
    'code',
    'fontfamily',
    'fontsize',
    'bold italic underline strikethrough',
    'fontselect fontsizeselect formatselect',
    'alignleft aligncenter alignright alignjustify',
    'outdent indent',
    'numlist bullist',
    'forecolor backcolor removeformat pagebreak',
    'charmap emoticons insertfile image link codesample',
    'ltr rtl',
    'quickimage quicktable',
    'help'
].join(' | ');

/**
 * List of @tinymce active plugins
 * @type {string}
 */
const TINYMCE_PLUGINS = [
    // 'advcode', // Premium plugin
    'advlist',
    // 'advtable', // Premium plugin
    'anchor',
    'autolink',
    'autoresize',
    // 'casechange', // Premium plugin
    'charmap',
    'charmap',
    // 'checklist', // Premium plugin
    'code',
    'directionality',
    // 'editimage', // Premium plugin
    'emoticons',
    // 'export',
    // 'formatpainter', // Premium plugin
    'help',
    'image',
    'importcss',
    'insertdatetime',
    'link',
    // 'linkchecker', // Premium plugin
    'lists',
    'media',
    // 'mediaembed', // Premium plugin
    // 'mentions', // Premium plugin
    'nonbreaking',
    'pagebreak',
    // 'pageembed', // Premium plugin
    // 'permanentpen', // Premium plugin
    // 'powerpaste', // Premium plugin
    'preview',
    'quickbars',
    'save',
    'searchreplace',
    'table',
    // 'tinycomments', // Premium plugin
    // 'tinydrive',
    // 'tinymcespellchecker', // Premium plugin
    // 'tableofcontents', // Premium plugin
    'visualblocks',
    'visualchars',
    'wordcount'
].join(' ');

const TINYMCE_STYLES = `
body {
    line-height: 1.2;
}
p {
    margin: 0 0 15px 0;
}

img {
    max-width: 100%;
    display: inline-block;
    height: auto;
}`;

const TINYMCE_FONT_SIZES = [8, 10, 12, 14, 16, 18, 24, 36, 48].join('px ');

/**
 * List of @tinymce fonts
 * @type {string}
 */
const TINYMCE_FONT_FAMILIES = [
    'Arial=arial,helvetica,sans-serif',
].join('; ');

const propTypes = {
    text: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

const defaultProps = {
    text: "",
};

const ComoppsHelpPagesEditor = ({text, onChange, editorKey}) => {

    useEffect(() => {
        const form = document.querySelector(".main-content__form");

        const closeModal = () => {
            const modalButton = document.querySelector(".tox-tbtn--active");
            if (modalButton) {
                modalButton.click();
            }
        }

        form.addEventListener("scroll", closeModal)

        return () => {
            form.removeEventListener("scroll", closeModal)
        }
    }, [])

    return <Editor
        value={text}
        onEditorChange={value => onChange(value)}
        plugins={TINYMCE_PLUGINS}
        toolbar={TOOLBAR_ITEMS}
        apiKey={editorKey}
        init={{
            toolbar_mode: 'sliding',
            force_br_newlines : true,
            fontsize_formats: TINYMCE_FONT_SIZES,
            pagebreak_separator: '<!-- pagebreak --><div style="page-break-before:always">&nbsp;</div>',
            content_style: TINYMCE_STYLES,
            setup: (ed) => {
                ed.on('init',  () => {
                    ed.getDoc().body.style.fontFamily = 'Arial';
                    ed.getDoc().querySelector("p").style.fontFamily = 'Arial';
                    ed.getDoc().querySelector("p").style.fontSize = '10pt';
                });
            },
        }}
    />
};

ComoppsHelpPagesEditor.propTypes = propTypes;
ComoppsHelpPagesEditor.defaultProps = defaultProps;

const mapStateToProps = (state) => {
    const editorKey = state.appState.editorKey;
    return {
        editorKey,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ComoppsHelpPagesEditor);