import React, {useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Block, BlockMain, BlockShadow, BlockWrap, Input, WrapInput} from "Templates/Form";
import PublicationFileUploader from "../../../../View/Views/Tabs/Brochure/PublicationFileUploader";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export const UPLOAD_SAFETY_FILE = 1
export const SELECT_FROM_ALREADY_UPLOADED = 2

const CustomTds = ({t, name, onChange}) => {
    const [safetyFileAction, setSafetyFileAction] = useState(UPLOAD_SAFETY_FILE)
    const safetyFileActionsOptions = [
        {
            label: 'Upload Safety File',
            value: UPLOAD_SAFETY_FILE
        },
        {
            label: 'Select from already uploaded',
            value: SELECT_FROM_ALREADY_UPLOADED
        }
    ]
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <BlockMain title="Safety Data Sheet">
            <BlockShadow>
                <Block className="publication-top-row">
                    <BlockWrap className="width_360">
                        <WrapInput isRequired label={t("Name")} name="name">
                            <Input
                                placeholder={t("Name")}
                                value={name}
                                onChange={value => {
                                    onChangeInput(value, 'name');
                                }}
                            />
                        </WrapInput>
                    </BlockWrap>
                    <BlockWrap className="width_360">
                        <WrapInput name="choseOption" label={t("Select Safety File Action")}>
                            <Input
                                type="select"
                                options={safetyFileActionsOptions}
                                placeholder={t("Select")}
                                value={safetyFileAction}
                                onChange={value => setSafetyFileAction(value)}
                            />
                        </WrapInput>
                    </BlockWrap>
                    <BlockWrap className="width_360">
                        {safetyFileAction === UPLOAD_SAFETY_FILE ? (
                            <PublicationFileUploader title="Safety File"/>
                        ) : (
                            <WrapInput name="safetyFile" label={t("Select Safety File")}>
                                <Input
                                    name="safetyFile"
                                    type="asyncSelect"
                                    placeholder={t("Select")}
                                    url="/admin/list/safeties/path/list"
                                    value=""
                                    onChange={(value, label) => {
                                        onChange('attachment_name', label);
                                        onChange('attachment_path', value);
                                    }}
                                />
                            </WrapInput>
                        )}
                    </BlockWrap>
                </Block>
            </BlockShadow>
        </BlockMain>
    );
}

CustomTds.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(CustomTds);
