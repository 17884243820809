import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "../../Hoc/Template";
import Img, { ImgStorage } from "../Img";

const defaultProps = {
    img: "",
    ico: "",
    additionalClassname: "",
};

const propTypes = {
    img: PropTypes.string,
    ico: PropTypes.string,
    additionalClassname: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
};

const ButtonImg = ({ children, pref, img, ico, handleClick, additionalClassname }) => {
    return (
        <button type="button" className={`${pref}__table-body-link main-table__body-link`} onClick={handleClick}>
            <span className={`${pref}__table-body-link-user main-table__body-link-user ${additionalClassname}`}>
                {img ? <ImgStorage url={img} /> : <Img img={ico || "icon_contact_small"} />}
            </span>
            <span className={`${pref}__table-body-link-text main-table__body-link-text`}>{children}</span>
        </button>
    );
};

ButtonImg.propTypes = propTypes;
ButtonImg.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(ButtonImg);
