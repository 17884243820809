import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ModalDefault } from "Templates/Modals";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import { withServiceConsumer } from "Services/Context";
import FileList from "Services/FileList/FileList";

const { Button, Form, Title } = ModalDefault;

const propTypes = {
    id: PropTypes.string.isRequired,
    addFiles: PropTypes.func.isRequired,
    cbAfterRequest: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

class UploadFileForm extends Component {
    state = { files: [] };

    onChange = value => {
        this.setState(prevState => ({
            ...prevState,
            files: [...value],
        }));
    };

    handleCloseModal = event => {
        event.preventDefault();
        const { files } = this.state;
        const { t } = this.props;
        if (files.length >= 1) {
            if (window.confirm(t("Are you sure you want to undo the action?"))) {
                const { onClose } = this.props;
                onClose();
            }
        } else {
            const { onClose } = this.props;
            onClose();
        }
    };

    handleClickOnSubmit = () => {
        const { id, addFiles } = this.props;
        const { files } = this.state;

        addFiles({ id, files }).then(res => {
            const { cbAfterRequest } = this.props;
            cbAfterRequest(res);
        });
    };

    render() {
        const { files } = this.state;
        const { t } = this.props;
        const disabled = files.length <= 0;
        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={this.handleCloseModal} />
                <Title>{t("Add File")}</Title>
                <FileList
                    name="files"
                    files={files}
                    dropFile={e => this.onChange(e)}
                    handleDrop={e => this.onChange([...files, ...e])}
                />
                <Button onClick={this.handleClickOnSubmit} disabled={disabled}>
                    {t("Add File(s)")}
                </Button>
            </Form>
        );
    }
}

UploadFileForm.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    id: getStoreItem(state, "view.id"),
});

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        addFiles: getActionStore("createFilesAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(UploadFileForm);
