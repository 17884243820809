import {handleScroll} from "../../EventsService";
import {getItem, requestAssignToProduct, requestOnSaveAgreements, requestOnSaveCopyPublication} from "./RequestService";
import {getActionStore, getStoreItem} from "../../StoreService";
import {deleteItem, requestProductsTable} from "../../RequestService";
import {getFilter, getTableLabel, isSortEnabled} from "../../TableHeaderService";
import {getViewItem, modifierValues} from "../../TableBodyService";
import {requestEditProduct} from "../Technical/RequestService";

export default (id, subId) => ({
    requestProductsTable: requestProductsTable(`/admin/publications/${id}/brochures/${subId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    modifierValues,
    getTableLabel,
    isSortEnabled,
    requestSubTab: () => getItem(`/admin/publications/${id}/brochures`),
    requestTypeItem: item => getItem(`/admin/publications/${id}/brochures/${item.id}`),
    // requestOnUploadFiles: requestOnUploadFiles(`/admin/publications/${id}/brochures/add`),
    requestAssignToProduct: brochureId => requestAssignToProduct(`/product/brochures/${brochureId}/copy`),
    requestEditProduct: (datasheetId, productId) => requestEditProduct(`/product/brochures/${datasheetId}/edit-product-colors/${productId}`),
    requestOnSaveCopyPublication: brochureId => requestOnSaveCopyPublication(`/admin/publications/${id}/brochures/${brochureId}/copy`),
    requestOnSaveAgreements: brochureId => requestOnSaveAgreements(`/admin/publications/${id}/brochures/${brochureId}/agreements`),
    onClickDeleteItem: item => deleteItem(`/product/brochures/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/brochures/${subId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getNotesData: (page) => getItem(`/admin/publications/${id}/brochures/${subId}/notes`, { page }),
    getHistoryData: (sort, page) => getItem(`/admin/publications/${id}/brochures/${subId}/history`, { sort, page }),
    rollbackHistory: (historyId) => getItem(`/admin/publications/${id}/brochures/${subId}/history/${historyId}/rollback`),
});
