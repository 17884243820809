import { request, url } from "Services";

const getItems = (data, listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestAttach = attachUrl => data => {
    const sendObj = {
        url: url.getUrl(attachUrl),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const getSharedPublications = async (listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        type: "GET",
    };

    return request.sendRequestWithNoCache(sendObj);
}

export const setSharedPublications = async (listUrl, data) => {
    const formData = new FormData();

    data.shared_with.forEach((item, index) => {
        formData.append(`shared_with[${index}]`, item);
    });

    formData.append('type', data.type);
    const sendObj = {
        url: url.getUrl(listUrl),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequestWithNoCache(sendObj);
}

export const requestTable = listUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, listUrl).then(res => {
            resolve({
                items: res[0].items,
                columns: res[0].columns,
                pagination: res[0].pagination,
            });
        }, reject);
    });
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};
