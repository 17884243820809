import React from "react";
import _ from "lodash";
import { industryOptions } from "Services/enum";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        manufacturer: "Manufacturer",
        brand: "Brand",
        platform: "Platform",
        program: "Program",
        nameplate: "Nameplate",
        vehicle_id: "Vehicle Mnemonic",
        region_mnemonic: "Region Mnemonic",
        plant_mnemonic: "Plant Mnemonic",
        product_plant: "Selling Plant",
        move_to_current: "Start Date",
        end_date: "End Date",
        sop: "SOP",
        eop: "EOP",
        segment: "Segment",
        sub_segment: "Project Sub-Segment",
        region: "Region",
        market: "Market",
        location_country: "Country",
        production_city: "Production City",
        design_parent: "Design Parent",
        total: "Total",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        manufacturer: "Search",
        brand: "Search",
        platform: "Search",
        program: "Search",
        nameplate: "Search",
        vehicle_id: "Search",
        region_mnemonic: "Search",
        plant_mnemonic: "Search",
        product_plant: "Search",
        sop: "Select",
        eop: "Select",
        segment: "Search",
        sub_segment: "Search",
        region: "Search",
        market: "Search",
        location_country: "Search",
        production_city: "Search",
        design_parent: "Search",
        total: "Search",
    };
    switch (key) {
        case "industry":
            return (
                <InputFilter
                    type="select"
                    options={[
                        {
                            value: "",
                            label: "All",
                        },
                        ...industryOptions,
                    ]}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "move_to_current":
        case "end_date":
        case "sop":
        case "eop":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
