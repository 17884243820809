import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {BlockMain, BlockWrap, BlockShadow, BlockWrapFlex, Input, WrapInput} from "Templates/Form";
import {phoneList} from "Services";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    email: PropTypes.string.isRequired,
    phone: PropTypes.shape({
        code: PropTypes.string,
        number: PropTypes.string,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

const ContactBlock = ({email, phone, onChange, t}) => {
    return (
        <BlockMain title="Contact">
            <BlockShadow>
                <BlockWrapFlex>
                    <BlockWrap className="width_1-3">
                        <BlockWrapFlex>
                            <WrapInput label={t("Country Code")} name="code" isWidth>
                                <Input
                                    type="searchSelect"
                                    value={phone.code}
                                    onChange={value => onChange("phone.code", value)}
                                    options={phoneList}
                                />
                            </WrapInput>
                            <WrapInput label={t("Phone")} name="phone">
                                <Input
                                    placeholder="Phone Number"
                                    value={phone.number}
                                    onChange={value => onChange("phone.number", value)}
                                />
                            </WrapInput>
                        </BlockWrapFlex>
                    </BlockWrap>
                    <BlockWrap className="width_1-3">
                        <BlockWrapFlex>
                            <WrapInput isRequired label={t("Email")} name="email">
                                <Input placeholder={t("Email")} value={email}
                                       onChange={value => onChange("email", value)}/>
                            </WrapInput>
                        </BlockWrapFlex>
                    </BlockWrap>
                </BlockWrapFlex>
            </BlockShadow>
        </BlockMain>
    );
};

ContactBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {service} = ownProps;
    const {getStoreItem} = service;

    return {
        email: getStoreItem(state, `email`),
        phone: {
            code: getStoreItem(state, `phone.code`),
            number: getStoreItem(state, `phone.number`),
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ContactBlock);
