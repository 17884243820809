import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import {
    Block,
    BlockImgWithCrop,
    BlockMain,
    BlockShadow,
    BlockTitle,
    BlockWrap,
    BlockWrapFlex,
    Form,
    Input,
    Tooltip,
    WrapInput
} from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Button } from "Templates/Default";
import LinkIcon from "Templates/Link/LinkIcon";
import { companyPhoneTypes } from "Services/enum";
import _ from "lodash";
import AddressBlock from "./AddressBlock";
import SocialBlock from "./SocialBlock";

const defaultProps = {
    id: null
};

const propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    customerCode: PropTypes.string.isRequired,
    dunsNumber: PropTypes.string.isRequired,
    parentCompanyId: PropTypes.string.isRequired,
    nicknames: PropTypes.arrayOf(PropTypes.object).isRequired,
    email: PropTypes.string.isRequired,
    customerOwner: PropTypes.string,
    canEditCustomerCode: PropTypes.bool.isRequired,
    logoView: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
    url: PropTypes.string.isRequired,
    phones: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeNicknamePhone: PropTypes.func.isRequired,
    addNickname: PropTypes.func.isRequired,
    removeNickname: PropTypes.func.isRequired,
    addPhone: PropTypes.func.isRequired,
    removePhone: PropTypes.func.isRequired,
};

const attachNickname = () => {
    return {
        id: "",
        value: "",
    };
};

const attachPhone = () => {
    return {
        type: '1',
        number: "",
    };
};

const addItem = (appendFunc, serviceFunc) => items => () => {
    serviceFunc(_.concat(items, appendFunc()));
};

const removeItem = (deleteItem, serviceFunc) => (items, index, nicknameId) => () => {
    if (nicknameId !== '') {
        if (window.confirm("Do you want to delete the Nickname?")) {
            deleteItem(`/companies/${nicknameId}/delete-nickname`).then(
                () => {
                    serviceFunc(items.filter((i, k) => k !== index));
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    } else {
        serviceFunc(items.filter((i, k) => k !== index));
    }
};

const removePhoneItem = serviceFunc => (items, index) => () => {
    serviceFunc(items.filter((i, k) => k !== index));
};

const onChangeNicknamePhoneItem = serviceFunc => (itemsMap, key) => (value, index) => {
    _.set(itemsMap, index, value);
    serviceFunc(key, _.concat(itemsMap));
};

const FormBlock = (
    {
        id,
        name,
        dunsNumber,
        customerCode,
        parentCompanyId,
        nicknames,
        logoView,
        email,
        url,
        phones,
        customerOwner,
        canEditCustomerCode,
        onChange,
        onChangeNicknamePhone,
        addNickname,
        removeNickname,
        addPhone,
        removePhone,
        t,
    }) => {
    const onChangeLogo = (value, key) => {
        onChange(key, value);
        onChange('logoView', value);
    };

    const onChangeInput = (value, key) => {
        onChange(key, value.substring(0, 255));
    };

    const onChangeSocialsInputs = (value, key) => {
        onChange(key, value);
    };

    const onChangeNicknameData = onChangeNicknamePhone(nicknames, "nicknames");
    const onChangePhone = onChangeNicknamePhone(phones, "phones");

    return (
        <Form>
            <div className="wrapper_general_image">
                <div className="general-info-block">
                    <BlockMain title="Company Information">
                        <div className="wrapper__form-block-content">
                            <BlockShadow>
                                <BlockWrap>
                                    <Block>
                                        <BlockWrap>
                                            <WrapInput label="Customer Code" name="customerCode">
                                                <Input
                                                    disabled={!canEditCustomerCode}
                                                    placeholder="Customer Code"
                                                    value={customerCode}
                                                    onChange={value => onChangeInput(value, "customerCode")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                        <BlockWrap>
                                            <WrapInput label="D-U-N-S&#174; Number" name="dunsNumber">
                                                <Tooltip>
                                                    {t(`D-U-N-S is a registered trademark D&B`)}
                                                </Tooltip>
                                                <Input
                                                    placeholder="D-U-N-S&#174; Number"
                                                    value={dunsNumber}
                                                    onChange={value => onChangeInput(value, "dunsNumber")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                    </Block>
                                    <Block>
                                        <BlockWrap>
                                            <WrapInput isRequired label="Company Name" name="name">
                                                <Input
                                                    placeholder="Name"
                                                    value={name}
                                                    onChange={value => onChangeInput(value, "name")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                        <BlockWrap>
                                            <WrapInput label="Email" name="email">
                                                <Input
                                                    type="email"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={value => onChangeInput(value, "email")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                        <BlockWrap>
                                            <WrapInput label="Customer Owner" name="customerOwner">
                                                <Input
                                                    name="customerOwner"
                                                    type="asyncSelect"
                                                    placeholder={t("Search")}
                                                    value={customerOwner}
                                                    url={'/project/projects/user-list'}
                                                    onChange={value => onChange('customerOwner', value)}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                    </Block>
                                    <Block>
                                        <BlockWrap>
                                            {phones.map((item, key) => (
                                                <BlockWrapFlex key={`BlockWrapFlex-phones-${key}`}>
                                                    <WrapInput label={t("Label")} name={`${key}.type`} isWidth>
                                                        <Input
                                                            type="select"
                                                            value={item.type}
                                                            onChange={value => onChangePhone(value, `${key}.type`)}
                                                            options={companyPhoneTypes}
                                                        />
                                                    </WrapInput>
                                                    <WrapInput label={t("Phone")} name={`${key}.number`}>
                                                        <Input
                                                            placeholder={t("Phone")}
                                                            value={item.number}
                                                            onChange={value => onChangePhone(value, `${key}.number`)}
                                                        />
                                                    </WrapInput>
                                                    {phones.length > 1 &&
                                                        <LinkIcon onClick={removePhone(phones, key)}/>}
                                                </BlockWrapFlex>
                                            ))}
                                            <Button
                                                className="size_normal main-btn main-btn_white"
                                                onClick={addPhone(phones)}
                                            >
                                                {t("Add Phone")}
                                            </Button>
                                        </BlockWrap>
                                        <BlockWrap>
                                            <WrapInput label="Website" name="url">
                                                <Input
                                                    placeholder="Website"
                                                    value={url}
                                                    onChange={value => onChangeInput(value, "url")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                    </Block>
                                </BlockWrap>
                            </BlockShadow>
                        </div>
                    </BlockMain>
                </div>
                <div className="image-block">
                    <BlockTitle>
                        {t("Company Logo ")}
                        <Tooltip>{t(`Recommended min image size 160 x 160px.`)}</Tooltip>
                    </BlockTitle>
                    <div className="wrapper__form-block-content">
                        <BlockShadow>
                            <BlockWrap>
                                <BlockImgWithCrop
                                    name="logo"
                                    textButton="Upload Logo"
                                    value={logoView}
                                    title=""
                                    onChange={value => onChangeLogo(value, "logo")}
                                />
                            </BlockWrap>
                        </BlockShadow>
                    </div>
                </div>
            </div>

            <div className="wrapper__form-block-content">
                <BlockMain title="Hierarchy">
                    <BlockShadow>
                        <BlockWrap className="width_1-3">
                            <Block>
                                <BlockWrap>
                                    <BlockWrapFlex key={`BlockWrapFlex-hierarchy-${parentCompanyId}`}>
                                        <WrapInput label={t("Parent Company")} name={`${parentCompanyId}.hierarchy`}>
                                            <Input
                                                name="parentCompanyId"
                                                type="asyncSelect"
                                                placeholder={t("Search")}
                                                value={parentCompanyId}
                                                url={`/companies/company-list/${id || '-'}`}
                                                onChange={value => onChange('parentCompanyId', value)}
                                            />
                                        </WrapInput>
                                    </BlockWrapFlex>
                                </BlockWrap>
                            </Block>
                        </BlockWrap>
                        <BlockWrap className="width_1-3"/>
                    </BlockShadow>
                </BlockMain>
            </div>
            <div className="wrapper__form-block-content">
                <BlockMain title="Nicknames">
                    <BlockShadow>
                        <BlockWrap className="width_1-3">
                            <Block>
                                <BlockWrap>
                                    {nicknames.map((item, key) => (
                                        <BlockWrapFlex key={`BlockWrapFlex-nicknames-${key}`}>
                                            <WrapInput label={t("Nickname")} name={`${key}.nickname`}>
                                                <Input
                                                    placeholder={t("Nickname")}
                                                    value={item.value}
                                                    onChange={value => onChangeNicknameData(value, `${key}.value`)}
                                                />
                                            </WrapInput>
                                            {nicknames.length > 1 &&
                                                <LinkIcon onClick={removeNickname(nicknames, key, item.id)}/>}
                                        </BlockWrapFlex>
                                    ))}
                                    <Button
                                        className="size_normal main-btn main-btn_white"
                                        onClick={addNickname(nicknames)}
                                    >
                                        {t("Add Nickname")}
                                    </Button>
                                </BlockWrap>
                            </Block>
                        </BlockWrap>
                        <BlockWrap className="width_1-3"/>
                    </BlockShadow>
                </BlockMain>
            </div>

            <div className="wrapper__form-block-content">
                <BlockTitle>{t("Address")}</BlockTitle>
                <BlockShadow>
                    <AddressBlock isDisabled={false} name="physical" onChange={onChangeInput}/>
                </BlockShadow>
            </div>

            <div className="wrapper__form-block-content row">
                <SocialBlock onChange={onChangeSocialsInputs}/>
            </div>
        </Form>
    );
};

FormBlock.defaultProps = defaultProps;
FormBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        id: getStoreItem(state, "id"),
        customerCode: getStoreItem(state, "customerCode"),
        dunsNumber: getStoreItem(state, "dunsNumber"),
        name: getStoreItem(state, "name"),
        logo: getStoreItem(state, "logo"),
        logoView: getStoreItem(state, "logoView"),
        email: getStoreItem(state, "email"),
        url: getStoreItem(state, "url"),
        customerOwner: getStoreItem(state, "customerOwner"),
        phones: getStoreItem(state, `phones`),
        parentCompanyId: getStoreItem(state, "parentCompanyId"),
        nicknames: getStoreItem(state, "nicknames"),
        canEditCustomerCode: getStoreItem(state, "canEditCustomerCode"),
        validate: getStoreItem(state, "form_validate"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore, deleteItem } = service;

    const onChange = getActionStore("onChange", service, dispatch);
    const refreshNickname = getActionStore("refreshNickname", service, dispatch);
    const refreshPhone = getActionStore("refreshPhone", service, dispatch);

    return {
        onChange,
        addPhone: addItem(attachPhone, refreshPhone),
        removePhone: removePhoneItem(refreshPhone),
        addNickname: addItem(attachNickname, refreshNickname),
        removeNickname: removeItem(deleteItem, refreshNickname),
        onChangeNicknamePhone: onChangeNicknamePhoneItem(onChange),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
