import React from "react";
import _ from "lodash";
export const getTableLabel = key => {
    const tableLabel = {
        created_at: "Date",
        editor: "Editor",
        source: "Source",
        field: "Field",
        original_value: "Original Value",
        new_value: "New Value"
    };

    return _.get(tableLabel, key, key);
};

export const isSortEnabled = key => !(/^editor.*/.test(key) || /^field.*/.test(key) || /^original_value.*/.test(key) || /^new_value.*/.test(key));