import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {gradeFileTypes, gradeStatus} from "Services/enum";
import {countryList} from "Services";
import {Tooltip, TooltipHtml} from "Templates/Form";
import {onChange} from "../../Services/EventsService";
import InfoTooltip from "Templates/InfoTooltip/InfoTooltip";

function Product({callback, filter, isSharedTab}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>Product</BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    {!isSharedTab && (
                        <>
                            <p className="main-table__head-link">Product Name - General</p>
                            <InputFilter
                                type="text"
                                placeholder="Search"
                                value={filter.name}
                                onChange={e => {
                                    onChange(e).then(_value => callback('name', _value));
                                }}
                            />
                        </>
                    )}
                    {
                        !isSharedTab && (
                            <>
                                <p className="main-table__head-link">Product Name - Specific</p>
                                <InputFilter
                                    type="asyncMultiSelect"
                                    placeholder="Select"
                                    url={`/product/product-finder/grade-list/sap`}
                                    value={filter.grade}
                                    onChange={e => {
                                        onChange(e).then(_value => callback('grade', _value));
                                    }}
                                />
                            </>
                        )
                    }
                    {!isSharedTab && (
                        <>
                            <p className="main-table__head-link">Material Number </p>
                            <InputFilter
                                type="asyncMultiSelect"
                                placeholder="Select"
                                url="/product/product-finder/material-number-list"
                                value={filter.material_number}
                                onChange={e => {
                                    onChange(e).then(_value => callback('material_number', _value));
                                }}
                            />

                            <BlockTitle className="main-table__head-link">
                                Product Status
                                <InfoTooltip classBlock="gradeFilterBlock-status" content={
                                    `
                                            <div class="padding-bottom__small"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</div>
                                            <div class="padding-bottom__small"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</div>
                                            <div class="padding-bottom__small"><span class="font-medium">Production Intent</span> - Product in process for being approved.</div>
                                            <div class="padding-bottom__small"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</div>
                                            <div class="padding-bottom__small"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</div>
                                            <div><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</div>
                                        `
                                }/>
                            </BlockTitle>
                            <InputFilter
                                type="multiSelect"
                                placeholder="Select"
                                options={gradeStatus}
                                value={filter.sub_status}
                                onChange={e => {
                                    onChange(e).then(_value => callback('sub_status', _value));
                                }}
                            />

                            <BlockTitle className="main-table__head-link">
                                Material Type
                                <TooltipHtml classBlock="gradeFilterBlock">
                                    {
                                        `<div class="padding">FERT = Finished Goods</div>
                                        <div class="padding">HALB = Semifinished Goods</div>
                                        <div class="padding">HAWA = Trade Goods</div>`
                                    }
                                </TooltipHtml>
                            </BlockTitle>
                            <InputFilter
                                type="multiSelect"
                                options={[
                                    {value: "FERT", label: "Finished Goods"},
                                    {value: "HALB", label: "Semifinished Goods"},
                                    {value: "HAWA", label: "Trade Goods"}
                                ]}
                                placeholder="Select"
                                value={filter.material_type}
                                onChange={e => {
                                    onChange(e).then(_value => callback('material_type', _value));
                                }}
                            />
                            <p className="main-table__head-link">Product Code</p>
                            <InputFilter
                                type="asyncMultiSelect"
                                placeholder="Select"
                                url="/product/product-finder/product-code-list"
                                value={filter.product_code}
                                onChange={e => {
                                    onChange(e).then(_value => callback('product_code', _value));
                                }}
                            />
                        </>
                    )}
                    {isSharedTab && (
                        <>
                            <p className="main-table__head-link">Product Name - General</p>
                            <InputFilter
                                type="text"
                                placeholder="Select"
                                value={filter.name}
                                onChange={e => {
                                    onChange(e).then(_value => callback('name', _value));
                                }}
                            />

                            <p className="main-table__head-link">Product Name - Specific</p>
                            <InputFilter
                                type="asyncMultiSelect"
                                placeholder="Select"
                                url="/product/product-finder/publications/publications/list"
                                value={filter.publication_ids}
                                onChange={e => {
                                    onChange(e).then(_value => callback('publication_ids', _value));
                                }}
                            />

                            <p className="main-table__head-link">Product ID</p>
                            <InputFilter
                                type="asyncMultiSelect"
                                placeholder="Select"
                                url="/product/product-finder/publication-slug-list"
                                value={filter.slug}
                                onChange={e => {
                                    onChange(e).then(_value => callback('slug', _value));
                                }}
                            />
                        </>
                    )}

                    <>
                        <p className="main-table__head-link filter-with-tooltip">
                            Publication Type
                            <Tooltip>
                                {`A technical document with data that describes a material.`}
                            </Tooltip>
                        </p>
                        <InputFilter
                            type="multiSelect"
                            value={filter.publication}
                            options={
                                !isSharedTab
                                    ? [{value: '0', label: 'None'}, ...gradeFileTypes]
                                    : [...gradeFileTypes]
                            }
                            placeholder="Select"
                            onChange={e => {
                                onChange(e).then(_value => callback('publication', _value));
                            }}
                        />

                        <p className="main-table__head-link filter-with-tooltip">
                            Country
                            <Tooltip>
                                {`The country where the technical document can be used. Verify Compliance.`}
                            </Tooltip>
                        </p>
                        <InputFilter
                            type="multiSelect"
                            placeholder="Select"
                            options={countryList}
                            value={filter.country}
                            onChange={e => {
                                onChange(e).then(_value => callback('country', _value));
                            }}
                        />
                    </>

                    <p className="main-table__head-link">Keywords (Description)</p>
                    <InputFilter
                        type="text"
                        placeholder="Search"
                        value={filter.content}
                        onChange={e => {
                            onChange(e).then(_value => callback('content', _value));
                        }}
                    />
                </div>
            )}
        </>
    );
}

Product.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
    isSharedTab: PropTypes.bool.isRequired,
}

export default Product;
