import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "../EventsService";
import {isSapImported, isYesNo} from "Services/enum";

const placeholders = {
    name: "Search",
    legacy_material_code: "Search",
    description: "Search",
    last_price: "Search",
    prior_price: "Search",
    change_price: "Search",
    date_update: "Change Date",
    created_date: "Date",
    selling_plant: "Search",
    map_cost: "Search",
    deletion_marker: "Select",
    obsolete_marker: "Select",
    sap_value: "Select",

    iso_name: 'Search',
    iso_expiration_date: 'Change Date',
    manufacturer: 'Search',
    vendor_code: 'Search',
    distributor: 'Search',
    distributor_vendor_code: 'Search',
    material_type: 'Search',
    lead_time: 'Search',
    standard_packaging: 'Search',
    delivery_terms: 'Search',
    freight_class: 'Search',
    effective_from_date: 'Change Date',
    quantity_per_lbs: 'Search',
    scale_quantity: 'Search',
    quantity_per_unit: 'Search',
    unit: 'Search',
    purchase_price: 'Search',
    note: 'Search',
};

export const getFilter = (key, value, callback, request) => {
    switch (key) {
        case "created_date":
        case "iso_expiration_date":
        case "effective_from_date":
        case "date_update":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "obsolete_marker":
        case "deletion_marker":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...isYesNo]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_value":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );


        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
