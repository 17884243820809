import React, {useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {formatDate, getString, numberCurrenciesWithCommas, uniqueGenerator} from "Services";
import {withServiceConsumer} from "Services/Context";
import {Button} from "Templates/Default";
import {withTagDefaultProps} from "Hoc/Template";
import {Block, BlockMain, BlockShadow, BlockTitle, BlockWrap, Form, Input, WrapInput} from "Templates/Form";
import {Modal} from "Templates/Modals";
import Img from "Templates/Img";
import ValidityPeriodModalForm from "./ValidityPeriodModalForm";

const defaultProps = {};

const propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iso: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    vendor_code: PropTypes.string.isRequired,
    distributor: PropTypes.string.isRequired,
    distributor_vendor_code: PropTypes.string.isRequired,
    material_type: PropTypes.string.isRequired,
    lead_time: PropTypes.string.isRequired,
    standard_packaging: PropTypes.string.isRequired,
    delivery_terms: PropTypes.string.isRequired,
    freight_class: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
    plants: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            plant: PropTypes.string.isRequired,
            cost_per_lbs_curr: PropTypes.number.isRequired,
        }),
    ).isRequired,
    periods: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            effective_from_date: PropTypes.string.isRequired,
            quantity_per_unit: PropTypes.any.isRequired,
            unit: PropTypes.any.isRequired,
            scale_quantities: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    scale_quantity: PropTypes.any.isRequired,
                    period_price: PropTypes.any.isRequired,
                })
            )
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateValidityPeriodForm: PropTypes.func.isRequired,
    changeValidityPeriodForm: PropTypes.func.isRequired,
    editValidityPeriodForm: PropTypes.func.isRequired,
    changePeriods: PropTypes.func.isRequired,
};

const FormBlock = (
    {
        name,
        description,
        iso,
        manufacturer,
        vendor_code,
        distributor,
        distributor_vendor_code,
        material_type,
        lead_time,
        standard_packaging,
        delivery_terms,
        freight_class,
        note,
        plants,
        periods,
        pref,
        t,
        onChange,
        showModal,
        openModal,
        closeModal,
        updateValidityPeriodForm,
        changeValidityPeriodForm,
        editValidityPeriodForm,
        changePeriods
    }) => {
    const [validityPeriods, setValidityPeriods] = useState(periods)
    const [periodsModalType, setPeriodsModalType] = useState('')

    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    const checkCompanyName = (value, key) => {
        onChange(key, value.slice(0, 100));
    };

    const handleAddValidityPeriod = (data) => {
        setValidityPeriods(prev => {
            const index = prev.findIndex((el) => {
                if (el.id) {
                    return el.id === data.id
                }

                return el.front_id === data.front_id
            });

            if (index > -1) {
                prev[index] = data;
                changePeriods(prev)
                return prev.sort((a, b) => new Date(a.effective_from_date) - new Date(b.effective_from_date));
            }
            const new_data = {
                ...data,
                front_id: uniqueGenerator()
            }
            changePeriods([...prev, new_data])
            return [...prev, new_data].sort((a, b) => new Date(a.effective_from_date) - new Date(b.effective_from_date))
        })
    }

    const editPeriod = (period) => {
        const currPeriod = {
            ...period,
            quantity_per_unit: period.quantity_per_unit.toString(),
            unit: period.unit.toString(),
            scale_quantities: period.scale_quantities.map((item) => {
                return {
                    ...item,
                    scale_quantity: item.scale_quantity.toString(),
                    period_price: item.period_price.toString(),
                }
            })
        }
        editValidityPeriodForm(currPeriod);
    }

    const deletePeriod = (periodId, periodFrontId) => {
        if (periodId) {
            setValidityPeriods(prev => {
                const array = prev.filter((item) => item.id !== periodId);
                changePeriods(array);
                return array;
            })
        } else {
            setValidityPeriods(prev => {
                const array = prev.filter((item) => item.front_id !== periodFrontId);
                changePeriods(array);
                return array;
            })
        }
    }

    return (
        <Form>
            <BlockMain title="">
                <BlockWrap>
                    <Block>
                        <BlockWrap>
                            <WrapInput isRequired label={t("Material Number")} name="name">
                                <Input
                                    placeholder={t("Material Number")}
                                    value={name}
                                    onChange={value => checkCompanyName(value, "name")}
                                    disabled
                                />
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput isRequired label={t("SAP Material Name")} name="description">
                                <Input
                                    value={description}
                                    onChange={value => onChangeInput(value, "description")}
                                    disabled
                                />
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Select ISO Certificate")} name="iso">
                                <Input
                                    type="asyncSelect"
                                    placeholder={t("Search")}
                                    url="/product/raw/iso-list"
                                    value={iso}
                                    onChange={value => onChangeInput(value, "iso")}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>

                    <Block className="width_1-3 raw-plants">
                        <BlockWrap>
                            {plants.map((plant, index) => (
                                <Block key={index}>
                                    <BlockWrap>
                                        {index === 0 && (
                                            <span className={`${pref}__label main-content__label`}>
                                                {t("Plant")}
                                            </span>
                                        )}
                                        <div>
                                            {getString(plant, 'plant')}
                                        </div>
                                    </BlockWrap>

                                    <BlockWrap>
                                        {index === 0 && (
                                            <span className={`${pref}__label main-content__label`}>
                                                {t("MAP ($/lb)")}
                                            </span>
                                        )}
                                        <div>
                                            {numberCurrenciesWithCommas(plant.cost_per_lbs_curr, 3)}
                                        </div>
                                    </BlockWrap>
                                </Block>
                            ))}
                        </BlockWrap>
                    </Block>

                    <Block>
                        <BlockWrap>
                            <WrapInput label={t("Manufacturer")} name="manufacturer">
                                <Input
                                    value={manufacturer}
                                    onChange={value => onChangeInput(value, "manufacturer")}
                                    disabled
                                />
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Vendor Code")} name="vendor_code">
                                <Input
                                    value={vendor_code}
                                    onChange={value => onChangeInput(value, "vendor_code")}
                                    disabled
                                />
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Distributor")} name="distributor">
                                <Input
                                    value={distributor}
                                    onChange={value => onChangeInput(value, "distributor")}
                                    disabled
                                />
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Distributor Vendor Code")} name="distributor_vendor_code">
                                <Input
                                    value={distributor_vendor_code}
                                    onChange={value => onChangeInput(value, "distributor_vendor_code")}
                                    disabled
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>

                    <Block>
                        <BlockWrap>
                            <WrapInput label={t("Material Type")} name="material_type">
                                <Input
                                    value={material_type}
                                    onChange={value => onChangeInput(value, "material_type")}
                                />
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Lead Time")} name="lead_time">
                                <Input
                                    value={lead_time}
                                    onChange={value => onChangeInput(value, "lead_time")}
                                />
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Standard Packaging")} name="standard_packaging">
                                <Input
                                    value={standard_packaging}
                                    onChange={value => onChangeInput(value, "standard_packaging")}
                                />
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Delivery Terms")} name="delivery_terms">
                                <Input
                                    value={delivery_terms}
                                    onChange={value => onChangeInput(value, "delivery_terms")}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>

                    <Block className="width_1-5">
                        <BlockWrap>
                            <WrapInput label={t("Freight Class")} name="freight_class">
                                <Input
                                    value={freight_class}
                                    onChange={value => onChangeInput(value, "freight_class")}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>

                    <Block>
                        <BlockWrap>
                            <BlockTitle>{t("Purchase Prices")}</BlockTitle>

                            <BlockShadow>
                                <Button
                                    className={`${pref}__form-btn size_normal main-btn main-btn_white`}
                                    onClick={() => {
                                        setPeriodsModalType('create')
                                        openModal()
                                    }}
                                >
                                    {t("New Validity Period")}
                                </Button>
                                {showModal ? (
                                    <Modal onRequestClose={closeModal}>
                                        <ValidityPeriodModalForm
                                            periodsModalType={periodsModalType}
                                            validityPeriods={validityPeriods}
                                            changeValidityPeriodForm={changeValidityPeriodForm}
                                            updateValidityPeriodForm={updateValidityPeriodForm}
                                            addValidityPeriod={handleAddValidityPeriod}

                                        />
                                    </Modal>
                                ) : <div/>}
                                {validityPeriods.length > 0 && (
                                    <div className="periods">
                                        {validityPeriods.map((period, i) => {
                                            const to = validityPeriods[i + 1]
                                                ? new Date(validityPeriods[i + 1].effective_from_date).setDate(new Date(validityPeriods[i + 1].effective_from_date).getDate() - 1)
                                                : '9999-12-31';
                                            return (
                                                <div key={`${period.id}_${i}`} className="periods__wrap">
                                                    <div className="periods__head">
                                                        <p>
                                                            {t(`Effective From`)}
                                                            <span>{formatDate(period.effective_from_date)}</span>
                                                            {t(`to`)}
                                                            <span>
                                                                {formatDate(new Date(to).toUTCString())}
                                                            </span>
                                                        </p>
                                                        <div className="periods__actions">
                                                            <Button onClick={() => {
                                                                setPeriodsModalType('edit')
                                                                editPeriod(period)
                                                            }}
                                                            >
                                                                <Img img="icon_edit"/>
                                                            </Button>
                                                            <Button onClick={() => {
                                                                deletePeriod(period.id, period.front_id)
                                                            }}
                                                            >
                                                                <Img img="icon_delete"/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="periods__per">
                                                        <p>{t(`Per (${period.unit})`)}</p>
                                                        <p>{period.quantity_per_unit}</p>
                                                    </div>
                                                    <div className="periods__body">
                                                        <div className="periods__body-title">
                                                            <p>{t(`Scale Quantity (from)`)}</p>
                                                            <p>{t(`Price`)}</p>
                                                        </div>
                                                        {period.scale_quantities.map((quantity, index) => {
                                                            return (
                                                                <div
                                                                    key={`quantity-${index}`}
                                                                    className="periods__body-content"
                                                                >
                                                                    <p>
                                                                        {period.scale_quantities[index + 1]
                                                                            ? `${quantity.scale_quantity} - ${period.scale_quantities[index + 1].scale_quantity - 1}`
                                                                            : `${quantity.scale_quantity}+`}
                                                                    </p>
                                                                    <p>
                                                                        {`$ ${numberCurrenciesWithCommas(quantity.period_price)}`}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </BlockShadow>
                        </BlockWrap>
                    </Block>

                    <Block>
                        <BlockWrap>
                            <BlockTitle>{t("Notes")}</BlockTitle>

                            <BlockShadow>
                                <WrapInput name="note">
                                    <Input
                                        type="textarea"
                                        value={note}
                                        onChange={value => onChangeInput(value, "note")}
                                    />
                                </WrapInput>
                            </BlockShadow>
                        </BlockWrap>
                    </Block>
                </BlockWrap>
            </BlockMain>
        </Form>
    );
};

FormBlock.propTypes = propTypes;
FormBlock.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        name: getStoreItem(state, 'name'),
        description: getStoreItem(state, 'description'),
        iso: getStoreItem(state, 'iso'),
        manufacturer: getStoreItem(state, 'manufacturer'),
        vendor_code: getStoreItem(state, 'vendor_code'),
        distributor: getStoreItem(state, 'distributor'),
        distributor_vendor_code: getStoreItem(state, 'distributor_vendor_code'),
        material_type: getStoreItem(state, 'material_type'),
        lead_time: getStoreItem(state, 'lead_time'),
        standard_packaging: getStoreItem(state, 'standard_packaging'),
        delivery_terms: getStoreItem(state, 'delivery_terms'),
        freight_class: getStoreItem(state, 'freight_class'),
        note: getStoreItem(state, 'note'),
        plants: getStoreItem(state, 'plants'),
        periods: getStoreItem(state, 'periods'),
        periods_form: getStoreItem(state, 'periods_form'),
        validate: getStoreItem(state, 'form_validate'),
        showModal: getStoreItem(state, 'showModal'),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        openModal: getActionStore("openModal", service, dispatch),
        closeModal: getActionStore("closeModal", service, dispatch),
        updateValidityPeriodForm: getActionStore("updateValidityPeriodForm", service, dispatch),
        changeValidityPeriodForm: getActionStore("changeValidityPeriodForm", service, dispatch),
        editValidityPeriodForm: getActionStore("editValidityPeriodForm", service, dispatch),
        onChange: getActionStore('onChange', service, dispatch),
        changePeriods: getActionStore('changePeriods', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
