import moment from "moment";

export const formatDate = (date, format = "MMM DD, YYYY") => {
    // const stillUtc = moment.utc(date).toDate();
    // return moment(stillUtc)
    //     .local()
    //     .format(format);
    return moment.utc(date).format(format);
};

export const formatDateWithTime = (date, format = "MMM DD, YYYY LT") => {
    return moment.utc(date).format(format);
};

export const formatDateToLocal = (date, format = "MMM DD, YYYY") => {
    const stillUtc = moment.utc(date).toDate();
    return moment(stillUtc)
        .local()
        .format(format);
};

export const formatDateAddUtcOffset = (date, format = "ll") => {
    // const stillUtc = moment.utc(date).toDate();
    // return moment(stillUtc)
    //     .local()
    //     .format(format);
    return moment
        .utc(date)
        .add(moment().utcOffset(), "minutes")
        .format(format);
};

export const diffDate = (date, diffDate, unitOfTime = "days") => {
    date = date ? moment.utc(date) : moment().utc();
    diffDate = diffDate ? moment.utc(diffDate) : moment().utc();

    return date.diff(diffDate, unitOfTime);
};
