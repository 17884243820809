import React from "react";
import { getString, formatDate, numberWithCommas } from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "manufacturer":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/project/vehicles/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "move_to_current":
        case "end_date":
        case "sop":
        case "eop": {
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => {
                        const formatValue = elem == '-' ? elem : formatDate(elem);
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                {formatValue}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case "total":
            return (
                <TableRowItemSpan title={numberWithCommas(getString(item, key))}>
                    {numberWithCommas(getString(item, key))}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
