import React, {Component} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {BlockImgWithCropAlt} from "Templates/Form";
import FileList from "Services/FileList/FileList";
import {Preloader} from "Templates/Preloader";

const {Button, Form, Title, ColInput} = ModalDefault;

const defaultProps = {
    oldValue: {},
    editValue: {},
};

const propTypes = {
    service: PropTypes.shape({
        requestAttach: PropTypes.func.isRequired,
        requestEdit: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    editValue: PropTypes.objectOf(PropTypes.any),
    onClose: PropTypes.func.isRequired,
    button: PropTypes.string.isRequired,
    oldValue: PropTypes.objectOf(PropTypes.any),
    fetchItems: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
};

class AddForm extends Component {
    state = {
        form: {
            id: "",
            name: "",
            logo: "",
            file: "",
        },
        files: [],
        loading: false,
        updated: false
    };

    componentDidMount() {
        const {editValue} = this.props;

        if (editValue.id) {
            this.setState({
                form: {...editValue, logo: editValue.path},
                oldValue: {...editValue},
            });
        } else {
            this.setState({
                form: {
                    id: "",
                    name: ""
                },
            });
        }
    }

    componentWillUnmount() {
        const {resetForm} = this.props;
        resetForm();
    }

    handleChange = (value, fieldName) => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [fieldName]: value,
            },
        }));
    };

    onAppend = () => {
        const {
            fetchItems,
            service: {requestAttach, requestEdit},
            onClose,
        } = this.props;
        const {form} = this.state;

        const request = form.id ? requestEdit({id: form.id}) : requestAttach;

        request({...form}).then(
            () => {
                onClose();
                fetchItems({});
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    onAddFiles = ([file]) => {
        if (!file) {
            return;
        }
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                file
            },
            files: [file],
            loading: false
        }));
        this.handleChange(file, 'file');
    };

    onDrop = () => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                name: '',
                path: ''
            },
            files: [],
        }));
        this.handleChange(null, "file")
        this.handleChange('', "fileName")
    }

    fetchFile = async () => {
        this.setState({
            loading: true,
            updated: true,
        })

        const url = this.state.form.path;
        const response = await fetch(url);
        const blob = await response.blob();
        const fileName = this.state.form.name;
        const fetchedFile = new File([blob], fileName, { type: blob.type });

        this.setState({
            files: [fetchedFile],
            loading: false
        })
    }

    render() {
        const {t, type, onClose, button} = this.props;
        const {form, oldValue, loading, files} = this.state;

        const disabled = form.name === '' || JSON.stringify(oldValue) === JSON.stringify(form);
        const disabledFiles = (oldValue && oldValue.path && form && form.path) && oldValue.path === form.path;

        if ((+type === 10 || +type === 11) && !this.state.updated && this.state.form.path) {
            this.fetchFile();
        }

        return (
            <Form className="publication-logo-modal">
                <ButtonClose className="main-modal__form-close" onClick={onClose}/>
                <Title>{t(`${form.id ? "Edit" : "Add New"} ${button}`)}</Title>
                {+type !== 10 && +type !== 11 ? <ColInput
                    value={form.name}
                    name="name"
                    onChange={value => this.handleChange(value, "name")}
                    placeholder={t(`${button} Name`)}
                /> : null}
                {+type === 9 || +type === 12 ? (
                    <BlockImgWithCropAlt
                        name="logo"
                        value={form.logo}
                        title={t("")}
                        onChange={value => this.handleChange(value, "logo")}
                    />
                ) : null}
                {+type === 10 || +type === 11 ? (
                    <FileList
                        name="files"
                        fileLength={1}
                        multiple={false}
                        accept="application/pdf"
                        files={files}
                        dropFile={e => {
                            this.onDrop(e)
                        }}
                        handleDrop={e => {
                            this.setState({
                                loading: true
                            })
                            this.onDrop(e)
                            this.onAddFiles(e)
                        }}
                    />
                ) : null}
                {+type === 9 ? (
                    <Button onClick={this.onAppend} disabled={disabled || !form || !form.logo}>
                        {t(`Save ${button}`)}
                    </Button>
                ) : null}
                {+type === 10 || +type === 11 ? (
                    <Button onClick={this.onAppend} disabled={disabledFiles || !files || !files.length}>
                        {t(`Save ${button}`)}
                    </Button>
                ) : null}
                {+type !== 9 && +type !== 10 && +type !== 11 ? (
                    <Button onClick={this.onAppend} disabled={disabled}>
                        {t(`Save ${button}`)}
                    </Button>
                ) : null}
                {loading
                    ? (
                        <div className="upload-file-loader">
                            <Preloader/>
                        </div>
                    )
                    : null}
            </Form>
        );
    }
}

AddForm.defaultProps = defaultProps;
AddForm.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        oldValue: getStoreItem(state, "oldValue"),
        editValue: getStoreItem(state, "editValue"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        resetForm: getActionStore("resetFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddForm);
