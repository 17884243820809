import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const ChangesHistoryTableBody = ({
                                     items,
                                     columns,
                                     ChangesHistoryTableRowColumn,
                                     service: {tableButtonsPermission},
                                 }) => {
    const TableRow = (column, item, index, k) => {
        return (
            <ChangesHistoryTableRowColumn
                item={item}
                index={index}
                secondaryIndex={k}
                column={column}
            />
        );
    };

    return (
        <>
            {Array.isArray(items) &&
                items.map((item, k) => (
                    <tr key={`TableRowColumn-${k}`}>
                        {columns.map((column, index) => (
                            <Fragment key={`TableRowColumn-project-col-${k}-${index}`}>
                                {TableRow(column, item, index, k)}
                            </Fragment>
                        ))}
                    </tr>
                ))}
        </>
    );
}

ChangesHistoryTableBody.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    service: PropTypes.shape({
        tableButtonsPermission: PropTypes.string,
    }).isRequired,
};

const mapStateToProps = (state, {service: {getStoreItem, modifierValues}}) => {
    return {
        items: modifierValues(getStoreItem(state, "items")),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ChangesHistoryTableBody);