import React from "react";

import { getString } from "Services";
import { projectStatus } from "Services/enum";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";

export const modifierValues = items => items;
export const tableButtonsPermission = "project_crud";

export const getViewItem = (key, item) => {
    switch (key) {
        case "sub_status":
            return (
                <TableRowItemStatus
                    status={getString(item, key)}
                    list={[...projectStatus]}
                />
            );

        case "grade":
        case "material_number": {
            return (
                <>
                    {getString(item, key).split(",").map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={elem}>
                            {elem}
                        </TableRowItemSpan>
                    ))}
                </>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
