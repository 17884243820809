import React from "react";
// import PropTypes from 'prop-types';
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {};

const PerList = ({ pref, children }) => <ul className={`${pref}__per-list`}>{children}</ul>;

PerList.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(PerList);
