import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { formatDate } from "Services";
import {
    SingleContent,
    SingleColFirst,
    SinglePreviewRow,
    SinglePreviewCol,
    SinglePreview
} from "Templates/SinglePreview";
import {LinkImg} from "Templates/Link";
import parse from 'html-react-parser';

const propTypes = {
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    owner: PropTypes.objectOf(PropTypes.any).isRequired,
    date: PropTypes.string.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

const InfoWrap = ({text, slug, owner, date, t}) => {
    return (
        <SingleContent>
            <SingleColFirst>
                <SinglePreview>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Copy ID")}</SinglePreviewCol>
                        <SinglePreviewCol>{slug}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Last Modified")}</SinglePreviewCol>
                        <SinglePreviewCol>{formatDate(date)}</SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow>
                        <SinglePreviewCol>{t("Owner")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <LinkImg img={owner.img} href={`/admin/users/${owner.id}`}>
                                {owner.name}
                            </LinkImg>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                    <SinglePreviewRow className="copy__single-preview__description">
                        <SinglePreviewCol>{t("Description")}</SinglePreviewCol>
                        <SinglePreviewCol>
                            <div id={"css-render-form"}>{parse(text)}</div>
                        </SinglePreviewCol>
                    </SinglePreviewRow>
                </SinglePreview>
            </SingleColFirst>
        </SingleContent>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        slug: getStoreItem(state, "view.slug"),
        owner: getStoreItem(state, "view.owner"),
        date: getStoreItem(state, "view.date"),
        text: getStoreItem(state, "view.text"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(InfoWrap);
