import {request, showMessage, url} from "Services";

const save = (data, requestUrl) => {
    const config = {
        url: url.getUrl(requestUrl),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };
    return request.sendRequest(config);
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE"
    };

    return request.sendRequest(sendObj);
};

const getItems = listUrl => data => {
    return request.sendRequest({
        url: url.getUrl(listUrl, []),
        data,
        type: "GET",
    });
};

export const requestSaveVendor = data => {
    return new Promise((resolve, reject) => {
        save(data, '/vendors/create').then(
            response => resolve(response),
            error => {
                showMessage(`${error.title || ''} ${error.detail}`);
                reject(error);
            },
        );
    });
};

export const requestEditVendor = data => {
    return new Promise((resolve, reject) => {
        save(data.payload, `/vendors/${data.vendorId}/edit`).then(
            response => resolve(response),
            error => {
                showMessage(`${error.title || ''} ${error.detail}`);
                reject(error);
            },
        );
    });
};

export const requestOnDeleteVendorCode = data => {
    return new Promise((resolve, reject) => {
        deleteItem(`/vendors/${data.id}/delete`).then(
            response => resolve(response),
            error => {
                showMessage(`${error.title || ''} ${error.detail}`);
                reject(error);
            },
        );
    });
}

export const requestTable = listUrl => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination
            });
        })
    });
};
