const Constants = {
    SHOW_SESSION_MODAL: "SHOW_SESSION_MODAL",
    CLOSE_SESSION_MODAL: "CLOSE_SESSION_MODAL",

    SHOW_TWO_FACTOR_MODAL: "SHOW_TWO_FACTOR_MODAL",
    CLOSE_TWO_FACTOR_MODAL: "CLOSE_TWO_FACTOR_MODAL",

    SUBMIT_FROM_REQUEST: "SUBMIT_FORM_REQUEST",
    SUBMIT_FROM_SUCCEEDED: "SUBMIT_FORM_SUCCEEDED",
    SUBMIT_FROM_FAILURE: "SUBMIT_FORM_FAILURE",
};

export default Constants;
