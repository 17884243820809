const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",

    EXPORT_VEHICLES: "EXPORT_VEHICLES",
    EXPORT_VEHICLES_SUCCESS: "EXPORT_VEHICLES_SUCCESS",
    EXPORT_VEHICLES_FAILED: "EXPORT_VEHICLES_FAILED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    CHANGE_TAB: "CHANGE_TAB"
};

export default Constants;