import _ from "lodash";
import { validate, setTotalVolumeInProjectVolume } from '../../Services/Reducers/LogicService';
import Constants from "../../Constants";

export const initialState = {
    totalUnits: 0,
    partWeight: "",
    partsperUnit: "",
    totalVolume: 0,
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_VOLUME: {
            _.set(state, data.key, data.value);
            setTotalVolumeInProjectVolume(state);
            return {
                ...state,
                form_validate: validate(state),
                projectVolumeBlock: _.cloneDeep(state.projectVolumeBlock),
            };
        }

        default: {
            return { ...state };
        }
    }
};
