import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { TabWrap } from "Templates/Content";
import TabViewIndex from "Templates/Tabs/DefaultWrap/TabViewIndex";
import { withTagDefaultProps } from "Hoc/Template";
import { requestRevertItem } from "../../../Services/Tabs/ImportTemplates/RequestService";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const TabView = ({ t, fetchItems, request/* , service */ }) => {

    const onClickEdit = (/* item */) => {
        // url.redirect(`/admin/import-templates/${item.id}/edit`);
    };

    const onClickDelete = (/* item */) => {
        // const { deleteItem } = service;
        //
        // if (window.confirm(t("Do you want to delete the Import Template?"))) {
        //     deleteItem(`/admin/import-templates/${item.id}/delete`).then(
        //         () => {
        //             fetchItems(request);
        //         },
        //         errors => {
        //             alert(errors.detail);
        //         },
        //     );
        // }
    };

    const onClickRevert = item => {
        // TODO hide revert button if item.status !== 'Imported'
        if (window.confirm(t("Do you want to revert this Import?"))) {
            requestRevertItem({ 'importId': item.id }).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                }
            );
        }
    };

    return (
            <TabWrap>
                <TabViewIndex
                    customIcon="icon_undo"
                    onClickCustom={onClickRevert}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                />
            </TabWrap>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
