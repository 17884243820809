import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { withTagDefaultProps } from "Hoc/Template";
import Img, { ImgStorage } from "Templates/Img";

const defaultProps = {
    url: "",
};

const propTypes = {
    value: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    url: PropTypes.string,
};

const ContentInfoBlockTextWithUserImg = ({ pref, href, url, value }) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className={`${pref}-content__info-block-link main-content__info-block-link`}
        >
            <span className={`${pref}-content__info-block-link-user main-content__info-block-link-user main-circle`}>
                {_.isString(url) && _.trim(url) ? <ImgStorage url={url} /> : <Img img="icon_contact_small" />}
            </span>
            <span className={`${pref}-content__info-block-link-text main-content__info-block-link-text`}>{value}</span>
        </a>
    );
};

ContentInfoBlockTextWithUserImg.defaultProps = defaultProps;
ContentInfoBlockTextWithUserImg.propTypes = propTypes;

export default compose(withTagDefaultProps())(ContentInfoBlockTextWithUserImg);
