export const tableColumns = {
    slug: "Connection ID",
    company_short_name: "Customer Name",
    company: "Customer Full Name",
    customer_code: "Customer Code",
    customer_owner: "Customer Owner",
    customer_duns_number: "D-U-N-S® Number",
    sap_customer: "SAP Customer",
    sap_grade: "SAP Product",
    grade: "Product Name",
    grade_sub_status: "Product Status",
    material_type: "Material Type",
    sap_application_code: "SAP Application Code",
    material_number: "SAP Material Number",
    product_code: "Product Code",
    project_slug: "Project ID",
    project_name: "Project Name",
    sales_manager: "Sales Manager (ZSales)",
    note: "Notes",
    note_finance: "B&S Notes",
    project_manager: "Sales Manager (Project)",
    project_internal_team: "Internal Team (Project)",
    project_sub_status: "Project Status",
    los_date: "Loss of Sales Date",
    move_to_current: "Start Date",
    end_date: "End Date",
    sop: "SOP",
    eop: "EOP",
    plant: "Selling Plant",
    budget: "Budget",
    actual: "Actuals",
    annualized: "Annualized Actuals",
    part_weight: "Part Weight (lbs)",
};

export const budgetTableColumns = {
    slug: "Connection Budget ID",
    product_code: "Product Code",
    application_code: "SAP Application Code",
    plant: "Selling Plant",
    material_type: "Material Type",
    detail: "Filtered Details",
    budget: "Budget",
    stretch: "Stretch",
    variance: "Budget to Forecast Variance",
    annualized: "Annualized Actuals",
    actual: "Actuals",
};
