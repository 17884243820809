import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    sort: {
        column: "volume",
        direction: "desc",
    },
    loading: false,
    errors: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return {
                ...state,
                table: { ...state.table, sort: data.sort },
                dashboard: { ...state.dashboard, personnel: data.table },
                loading: false,
            };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.NEXT_PAGE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    personnel: {
                        ...state.dashboard.personnel,
                        items: [...state.dashboard.personnel.items, ...data.items],
                        pagination: data.pagination,
                    },
                },
                loading: false,
            };

        default:
            return state;
    }
};
