import React, { useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Input, WrapInput } from "Templates/Form";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};


const NotesSection = ({ onChange, t }) => {

    const [value, setValue] = useState('');

    const onChangeInput = (value, key) => {
        setValue(value);
        onChange(key, value);
    };

    return (
        <>
            <WrapInput label={t("Notes")} name="note">
                <Input
                    placeholder={t("Notes")}
                    value={value}
                    onChange={val => onChangeInput(val, 'note')}
                />
            </WrapInput>
        </>
    )
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

NotesSection.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(() => {
    }, mapDispatchToProps),
)(NotesSection);
