import React from "react";
import TableHeadColumnTemplate from "Templates/Table/TableHeadColumn";
import PropTypes from "prop-types";
import TableHeadColumnDefault from "Templates/Table/TableHeadColumnWrap/Default";

const propTypes = {
    column: PropTypes.string.isRequired,
    onSortClick: PropTypes.func.isRequired,
};

const TableHeadColumnWrap = ({column, onSortClick}) => {
    return (
        <TableHeadColumnTemplate>
            <TableHeadColumnDefault
                column={column}
                onSortClick={() => onSortClick(column)}
            />
        </TableHeadColumnTemplate>
    );
};

TableHeadColumnWrap.propTypes = propTypes;

export default TableHeadColumnWrap;