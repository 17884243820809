import {request, url} from "Services";

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequestWithNoCacheAndNoLoading(sendObj);
};

export const requestExportProducts = data => {
    const sendObj = {
        url: data,
        type: "POST"
    };

    return request.sendRequest(sendObj);
};

const get = requestUrl => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        type: "GET",
    };

    return request.sendRequest(sendObj, false);
};

export const requestTable = listUrl => data => {
    const {activeTab, activeCompareTab} = data;

    if (activeTab === 1) {
        return new Promise(resolve => {
            getItems(activeCompareTab === 1 ? '/product/product-finder/publication-process' : '/product/product-finder/shared-publication-process')(data).then(res => {
                resolve({
                    items: res[0].items,
                    pagination: res[0].pagination,
                    columns: res[0].columns,
                    help: res[0].help,
                    pinned_columns: res[0].pinned_columns,
                    activeColumns: res[0].activeColumns,
                });
            }).then(() => {
                if (url.getActiveTab()) {
                    data.activeTab = url.getActiveTab();
                }
                url.addHistory(data);
            });
        });
    }

    return new Promise(resolve => {
        getItems(activeCompareTab === 1 ? '/product/product-finder/publication-technical' : '/product/product-finder/shared-publication-technical')(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                help: res[0].help,
                pinned_columns: res[0].pinned_columns,
                activeColumns: res[0].activeColumns,
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
            url.addHistory(data);
        });
    });
};

export const materialProperties = async (listUrl, data) => {
    if (!data.property_ids) {
        delete data.property_ids
    }

    if (!data.test_method_ids) {
        delete data.test_method_ids
    }

    if (!data.specimen_ids) {
        delete data.specimen_ids
    }

    if (!data.test_condition_ids) {
        delete data.test_condition_ids
    }

    if (!data.manufacturing_ids) {
        delete data.manufacturing_ids
    }

    if (!data.parameter_ids) {
        delete data.parameter_ids
    }

    if (!data.description_ids) {
        delete data.description_ids
    }

    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                help: res[0].help,
            });
        });
    });
}

export const technicalRequestGetSettings = () => {
    const sendObj = {
        url: url.getUrl('/product/product-finder/compare/table-settings/technical'),
        type: "GET"
    };

    return request.sendRequest(sendObj);
};

export const processRequestGetSettings = async () => {
    const sendObj = {
        url: url.getUrl('/product/product-finder/compare/table-settings/processing'),
        type: "GET"
    };

    return request.sendRequest(sendObj);
};

export const requestTechnicalChangeSettings = data => {
    const sendObj = {
        url: url.getUrl('/product/product-finder/compare/table-settings/technical/edit'),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const requestProcessChangeSettings = data => {
    const sendObj = {
        url: url.getUrl('/product/product-finder/compare/table-settings/processing/edit'),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const technicalRequestCreateTemplate = data => {
    const sendObj = {
        url: url.getUrl('/product/product-finder/compare/template/technical/create'),
        data,
        type: "POST",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const technicalEditTemplateRequest = (templateId, data) => {
    const sendObj = {
        url: url.getUrl(`/product/product-finder/compare/template/${templateId}/edit`),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
}

export const processRequestCreateTemplate = data => {
    const sendObj = {
        url: url.getUrl('/product/product-finder/compare/template/processing/create'),
        data,
        type: "POST",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const processEditTemplateRequest = (templateId, data) => {
    const sendObj = {
        url: url.getUrl(`/product/product-finder/compare/template/${templateId}/edit`),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
}

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export const createFilter = data => {
    const formData = new FormData();

    formData.append("name", data.name.trim());
    formData.append("data", JSON.stringify(data.tableFilters));
    formData.append("type", data.tableFilters.type);

    const sendObj = {
        url: url.getUrl('/product/product-finder/filters/add'),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const getFilter = async (link) => {
    const sendObj = {
        url: url.getUrl(link),
        type: "GET"
    };

    return request.sendRequest(sendObj);
}

export const getFilters = async (link) => {
    const sendObj = {
        url: url.getUrl(link),
        type: "GET"
    };

    return request.sendRequest(sendObj);
}