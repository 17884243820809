import Constants from "../Constants";

export const createFilesAction = (service, dispatch) => data => {
    dispatch({ type: Constants.CREATE_FILES_REQUEST });

    const { requestOnUploadFiles } = service;
    const formData = new FormData();

    for (let i = 0; i < data.length; i += 1) {
        formData.append("files[]", data[i]);
    }

    return requestOnUploadFiles(formData).then(
        res => dispatch({ type: Constants.CREATE_FILES_SUCCESS, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.CREATE_FILES_FAILED, data: res });
        },
    );
};
