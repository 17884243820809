import { formDataNormalizer } from "Services";
import Constants from "../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const submitColor = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_COLOR_REQUESTED });

    return new Promise((resolve, reject) => {
        service.saveColor(formDataNormalizer(data)).then(
            res => {
                dispatch({ type: Constants.SUBMIT_COLOR_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SUBMIT_COLOR_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.FORM_RESET });
};

export const submitForm = (service, dispatch) => data => {
    dispatch({ type: Constants.FORM_REQUESTED });
    const { formData } = service.FormService;

    return new Promise((resolve, reject) => {
        service.save(formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};
