import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const NotesWrap = ({ pref, children, onScroll }) => (
    <div className={`${pref}__wrap-notes main-content__wrap-notes`} onScroll={onScroll}>
        {children}
    </div>
);

NotesWrap.propTypes = {
    onScroll: PropTypes.func.isRequired,
};

export default compose(withTagDefaultProps())(NotesWrap);
