import React, {Fragment, useEffect, useState} from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import PropTypes from "prop-types";
import {Block, ButtonRight, Input, WrapInput} from "Templates/Form";
import {Button as FormButton} from "Templates/Default";
import {LinkIcon} from "Templates/Link";
import {uniqBy} from "lodash";
import BlockSubmit from "Templates/Form/BlockSubmit/BlockSubmit";
import ButtonLeft from "Templates/Form/BlockSubmit/ButtonLeft";
import {uniqueGenerator} from "Services";

const {Button, Form, Title} = ModalDefault;

const propTypes = {
    closeModal: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
    form: PropTypes.array.isRequired,
    saveItems: PropTypes.func.isRequired,
};

const UsersModal = ({t, form, closeModal, updateForm, saveItems, groupId}) => {
    const [selectedOptions, setSelectedOptions] = useState(0);
    const [hideAddMoreButton, setHideAddMoreButton] = useState(false);
    const isValidForm = () => {
        return form.every(({value}) => !!value);
    };

    useEffect(() => {
        setHideAddMoreButton(selectedOptions === form.length)
    }, [selectedOptions, form.length])

    const onAddItem = () => {
        updateForm([...form, {id: uniqueGenerator(), value: ''}]);
    };

    const onUpdateItem = (value, index) => {
        const updatedUsers = form.map((item, itemIndex) => {
            return itemIndex === index ? {...item, value} : item;
        });
        updateForm(uniqBy(updatedUsers, 'value'));
    };

    const onRemoveItem = (index) => {
        const array = form.filter((_, itemIndex) => itemIndex !== index);
        updateForm(array);
    };

    const onSave = () => {
        saveItems(form);
    };

    return <>
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={closeModal}/>
            <Title>{t('Share List With ComOpps User')}</Title>
            <Block>
                <WrapInput label={''} name="user" className="wrap-input--delete">
                    {form.map(({id, value}, index) => (<Fragment key={`Product-user-${id}`}>
                        <Input name={`user-${value}`}
                               type="uniqueListAsyncSelect"
                               placeholder={t("Select")}
                               url={`product/product-finder/publication-groups/search/users/${groupId}`}
                               value={value}
                               valuesList={form}
                               handleSelectedOptionsLength={(val) => {
                                   setSelectedOptions(val)
                               }}
                               onChange={value => onUpdateItem(value, index)}/>
                        {form.length > 1 ? <LinkIcon
                            className="icon-delete"
                            icon="la-icon-close"
                            onClick={() => onRemoveItem(index)}
                        /> : null}
                    </Fragment>))}
                    {!hideAddMoreButton ? <FormButton disabled={!isValidForm()} onClick={onAddItem} className="size_small main-btn main-btn_white">
                        {t('Add user')}
                    </FormButton> : <div/>}
                </WrapInput>

            </Block>
            <BlockSubmit>
                <ButtonRight />
                <ButtonLeft>
                    <Button onClick={onSave} disabled={!isValidForm()}>
                        {t('Save')}
                    </Button>
                </ButtonLeft>
            </BlockSubmit>

        </Form>
    </>
};

UsersModal.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        form: getStoreItem(state, "form"),
        groupId: getStoreItem(state, "groupId"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;
    return {
        closeModal: getActionStore("closeModal", service, dispatch),
        updateForm: getActionStore("updateForm", service, dispatch),
        saveItems: getActionStore("saveItems", service, dispatch),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps),)(UsersModal);
