import React from 'react';
import Img from "Templates/Img/Img";
import PropTypes from "prop-types";

function NotFoundBrochure() {
    return (
        <div className="sheet-notInfo text-center">
            <Img
                img="icon_files"
                width="100"
                height="100"
                alt="file"
                className=""
            />
            <p className="text-center">
                A Brochure for this product will be available soon.
            </p>
        </div>
    );
}

export default NotFoundBrochure;