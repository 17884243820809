import {request, url} from "Services";

const inviteLink = '/admin/users/invite';
const filterLink = '/product/product-finder/filters/add';
const shareFiltersLink = '/product/product-finder/filters/share';

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestExportProducts = data => {
    const sendObj = {
        url: data,
        type: "POST"
    };

    return request.sendRequest(sendObj);
};

const get = requestUrl => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        type: "GET",
    };

    return request.sendRequest(sendObj, false);
};

export const requestSavedFilterData = listUrl => {
    return new Promise((resolve, reject) => {
        get(listUrl).then(res => {
            resolve(res[0]);
        }, reject);
    });
};

export const requestTable = listUrl => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                help: res[0].help,
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
            url.addHistory(data);
            });
    });
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export const requestOnSaveInvite = data => {
    const formData = new FormData();

    formData.append("email", data.email.trim());
    formData.append("inviteText", (data.invite_text && data.invite_text.trim()) || '');
    formData.append("notifyCreator", data.notify_creator ? 10 : 0);
    formData.append("productsInvite", data.products_invite ? 10 : 0);
    formData.append("listsInvite", data.lists_invite ? 10 : 0);
    for (let i = 0; i < data.lists.length; i += 1) {
        formData.append("lists[]", data.lists[i]);
    }
    const sendObj = {
        url: url.getUrl(inviteLink),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestOnSaveFilter = data => {
    const formData = new FormData();

    formData.append("name", data.name.trim());
    formData.append("data", JSON.stringify(data.data));
    formData.append("type", data.type);

    const sendObj = {
        url: url.getUrl(filterLink),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestOnSaveShareFilters = data => {
    const formData = new FormData();

    formData.append("user", data.user.trim());
    formData.append("message", (data.message && data.message.trim()) || '');
    formData.append("filters", JSON.stringify(data.data));
    formData.append("type", data.type);

    const sendObj = {
        url: url.getUrl(shareFiltersLink),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};
