import React, {Component, Fragment} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {LinkIcon} from "Templates/Link";
import {Block, BlockShadow, BlockTitle, ButtonLeft, Input, WrapInput} from "Templates/Form";
import {Button, Div} from "Templates/Default";
import _ from "lodash";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import arrayMove from "array-move";
import {CheckboxButton} from "Templates/Form/Checkbox";
import {Modal} from "Templates/Modals";
import MaterialPropertyPreviewModal from "./Modal/MaterialPropertyPreviewModal";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    subSegment: PropTypes.string.isRequired,
    visible: PropTypes.objectOf(PropTypes.any).isRequired,
    materialProperties: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    changeMaterialProperty: PropTypes.func.isRequired,
};

const DragHandle = SortableHandle(() => <span className="table-settings__style__handle"> </span>);

const SortableItem = SortableElement(({ val, indexVal, values, onChangeArray, handleMenuAction, onDeleteArray }) => {
    const highlightClass = val.highlight ? "highlight-row" : "";

    return (
        <li className={`table-settings-li ${highlightClass}`}>
            <DragHandle />

            <Fragment key={`Input-${val.frontId}`}>
                <Block className="property_input-list">
                    <div
                        className="property-input-wrap"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="property"
                            type="asyncSelect"
                            placeholder="Select"
                            url="/product/datasheets/material-hierarchy-list/root"
                            value={val.property || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { property: value, propertyView: label, testMethod: '', testMethodView: '' }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <div
                        className="property-input-wrap"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="test_method"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.property ? `/product/datasheets/material-hierarchy-list/${val.property}` : "//"}
                            disabled={!val.property}
                            value={val.testMethod || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { testMethod: value, testMethodView: label, specimen: '', specimenView: '' }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <div
                        className="property-input-wrap"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="specimen"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.testMethod ? `/product/datasheets/material-hierarchy-list/${val.testMethod}` : "//"}
                            disabled={!val.testMethod}
                            value={val.specimen || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { specimen: value, specimenView: label, testCondition: '', testConditionView: '' }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <div
                        className="property-input-wrap"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="test_condition"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.specimen ? `/product/datasheets/material-hierarchy-list/${val.specimen}` : "//"}
                            disabled={!val.specimen}
                            value={val.testCondition || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { testCondition: value, testConditionView: label, valueMinSi: '', valueMaxSi: '', valueMinEnglish: '', valueMaxEnglish: '' }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <Div>
                        <Input
                            name="value_si_min"
                            type="text"
                            className="text-right"
                            placeholder=""
                            disabled={!val.testCondition}
                            value={val.valueMinSi || ''}
                            onChange={value => {
                                onChangeArray(indexVal, { valueMinSi: value }, values)
                            }}
                        />
                    </Div>
                    <Div>
                        <Input
                            name="value_si_max"
                            type="text"
                            className="text-right"
                            placeholder=""
                            disabled={!val.testCondition}
                            value={val.valueMaxSi || ''}
                            onChange={value => {
                                onChangeArray(indexVal, { valueMaxSi: value }, values)
                            }}
                        />
                    </Div>
                    <div
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="unit_si"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.testCondition ? `/product/datasheets/material-hierarchy-list/${val.testCondition}` : "//"}
                            disabled={!val.testCondition}
                            value={val.unitSi || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { unitSi: value, unitSiView: label}, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <Div>
                        <Input
                            name="value_english_min"
                            type="text"
                            className="text-right"
                            placeholder=""
                            disabled={!val.unitSi}
                            value={val.valueMinEnglish || ''}
                            onChange={value => {
                                onChangeArray(indexVal, { valueMinEnglish: value }, values)
                            }}
                        />
                    </Div>
                    <Div>
                        <Input
                            name="value_english_max"
                            type="text"
                            className="text-right"
                            placeholder=""
                            disabled={!val.unitSi}
                            value={val.valueMaxEnglish || ''}
                            onChange={value => {
                                onChangeArray(indexVal, { valueMaxEnglish: value }, values)
                            }}
                        />
                    </Div>
                    <div
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="unit_english"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.unitSi ? `/product/datasheets/material-hierarchy-list/${val.unitSi}` : "//"}
                            disabled={!val.unitSi}
                            value={val.unitEnglish || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { unitEnglish: value, unitEnglishView: label }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                </Block>
                {values.length > 1 && (
                    <LinkIcon
                        className="icon-delete property_input-link"
                        icon="la-icon-close"
                        onClick={() => {
                            onDeleteArray(val.frontId, values)
                        }}
                    />
                )}
            </Fragment>
        </li>
    );
});

const SortableList = SortableContainer(({ isDisabled, values, onChangeArray, onDeleteArray, onHandleMenuAction }) => {
    return (
        <ul>
            {values.map((value, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    indexVal={index}
                    val={value}
                    values={values}
                    disabled={isDisabled}
                    handleMenuAction={onHandleMenuAction}
                    onChangeArray={onChangeArray}
                    onDeleteArray={onDeleteArray}
                />
            ))}
        </ul>
    );
});

class MaterialProperty extends Component {
    state = {
        showContent: true,
        showPreviewModal: false,
        isDisabled: false
    };

    compareProperties = () => {
        const { materialProperties } = this.props;

        const propertyCounts = {};

        materialProperties.forEach(propertyItem => {
            const { property, testMethod, specimen, testCondition } = propertyItem;

            if (property && testMethod && specimen && testCondition) {
                const key = `${property}-${testMethod}-${specimen}-${testCondition}`;
                propertyCounts[key] = (propertyCounts[key] || 0) + 1;
            }
        });

        materialProperties.forEach(propertyItem => {
            const { property, testMethod, specimen, testCondition } = propertyItem;

            if (property && testMethod && specimen && testCondition) {
                const key = `${property}-${testMethod}-${specimen}-${testCondition}`;
                propertyItem.highlight = propertyCounts[key] > 1;
            } else {
                propertyItem.highlight = false;
            }
        });
    };

    componentDidMount() {
        this.compareProperties();
    }

    toggleContent = () => {
        this.setState(({ showContent }) => ({
            showContent: !showContent,
        }));
    };

    render() {
        const { showContent, isDisabled, showPreviewModal } = this.state;
        const {
            subSegment,
            visible,
            materialProperties,
            onChange,
            changeMaterialProperty,
            t
        } = this.props;

        const onChangeSegment = (value, key) => {
            onChange(key, value);
            changeMaterialProperty(value);
        };

        const onChangeVisible = (value, key) => {
            const _visible = _.cloneDeep(visible);
            _visible[key] = value;

            onChange(`visible`, _visible);
        };

        const onSortEnd = ({ oldIndex, newIndex }) => {
            onChange('materialProperties', arrayMove(materialProperties, oldIndex, newIndex));
        };

        const onDeleteArray = (frontId, array) => {
            onChange('materialProperties', array.filter(elem => elem.frontId !== frontId));
        };

        const _onAddArray = (lastFrontId, array) => {
            onChange('materialProperties', [...array, {id: '', frontId: lastFrontId + 1, property: "" }]);
        };

        const onChangeArray = (index, value, array) => {
            array[index] = _.merge(array[index], value);
            onChange('materialProperties', _.concat(array));
            this.compareProperties();
        };

        const onHandleMenuAction = (e) => {
            this.setState({
                isDisabled: e
            })
        }

        const handleCloseModal = () => {
            this.setState({
                showPreviewModal: false,
            });
        };

        const handleShowModal = () => {
            this.setState({
                showPreviewModal: true,
            });
        };

        return (
            <>
                <Div className="toggle__show-block">
                    <BlockTitle>{t("Material Properties Table")}</BlockTitle>
                    <button
                        type="button"
                        className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                        onClick={() => this.toggleContent()}
                    >
                        Show
                        {' '}
                        {showContent ? 'Less' : 'More'}
                    </button>
                </Div>

                <Block>
                    {showContent ?
                        (
                            <BlockShadow>
                                <Block>
                                    <WrapInput
                                        className=" width_1-5"
                                        label="Sub-segment Material Property Template"
                                        name="sub-segment"
                                    >
                                        <Input
                                            name="sub_segment"
                                            type="asyncSelect"
                                            placeholder={t("Select")}
                                            url="/product/datasheets/sub-segment-list"
                                            value={subSegment}
                                            onChange={value => onChangeSegment(value, `subSegment`)}
                                        />

                                        {showPreviewModal ?
                                            (<Modal onRequestClose={handleCloseModal}>
                                                <MaterialPropertyPreviewModal
                                                    tdsData={materialProperties}
                                                    visible={visible}
                                                    onClose={handleCloseModal}
                                                />
                                            </Modal>)
                                            : ''}
                                    </WrapInput>
                                    <ButtonLeft>
                                        <button
                                            type="button"
                                            className="projects-create__buttons-btn main-btn main-btn_primary"
                                            onClick={handleShowModal}
                                        >
                                            {t("Preview")}
                                        </button>
                                    </ButtonLeft>
                                </Block>
                                <Block className="property_column_header customize-property-header">
                                    <div>
                                        <div>
                                            <span>Property</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_property"
                                                value={visible.property}
                                                onChange={value => {
                                                    onChangeVisible(value, 'property');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Test Method</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_testMethod"
                                                value={visible.testMethod}
                                                onChange={value => {
                                                    onChangeVisible(value, 'testMethod');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Specimen</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_specimen"
                                                value={visible.specimen}
                                                onChange={value => {
                                                    onChangeVisible(value, 'specimen');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Test Condition</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_testCondition"
                                                value={visible.testCondition}
                                                onChange={value => {
                                                    onChangeVisible(value, 'testCondition');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Value Min <br/> (SI)</span>
                                        </div>
                                        <div>
                                            <span>Value Max <br/> (SI)</span>
                                        </div>
                                        <div>
                                            <span>SI Unit</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_unitSi"
                                                value={visible.unitSi}
                                                onChange={value => {
                                                    onChangeVisible(value, 'unitSi');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Value Min <br/> (English)</span>
                                        </div>
                                        <div>
                                            <span>Value Max <br/> (English)</span>
                                        </div>
                                        <div>
                                            <span>English Unit</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_unitEnglish"
                                                value={visible.unitEnglish}
                                                onChange={value => {
                                                    onChangeVisible(value, 'unitEnglish');
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {materialProperties.length > 1 && (<span />)}
                                </Block>
                                <SortableList
                                    isDisabled={isDisabled}
                                    values={materialProperties}
                                    distance={1}
                                    axis="y"
                                    lockAxis="y"
                                    onChangeArray={onChangeArray}
                                    onDeleteArray={onDeleteArray}
                                    onSortEnd={onSortEnd}
                                    onHandleMenuAction={onHandleMenuAction}
                                />
                                <Button
                                    onClick={
                                        () => _onAddArray(
                                            _.maxBy(materialProperties, p => p.frontId).frontId,
                                            materialProperties
                                        )
                                    }
                                    className="size_normal main-btn main-btn_white"
                                >
                                    Add Material Property
                                </Button>
                            </BlockShadow>
                        ) : ''}
                </Block>
            </>
        );
    }
}

MaterialProperty.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        subSegment: getStoreItem(state, "subSegment"),
        visible: getStoreItem(state, "visible"),
        materialProperties: getStoreItem(state, "materialProperties"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        changeMaterialProperty: getActionStore("changeMaterialProperty", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MaterialProperty);
