export const technicalTableColumns = {
    property_name: "Property",
    test_method_name: "Test Method",
    specimen_name: "Specimen",
    test_condition_name: "Test Condition"
};

export const processTableColumns = {
    manufacturing_name: "Manufacturing Process",
    parameter_name: "Process Parameter",
    description_name: "Description",
};
