import Constants from "../Constants/TableProjectsContstants";

export const initState = {
    sort: {
        column: "volume",
        direction: "desc",
    },
    errors: {},
    loading: false,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.FETCH_PROJECTS_ITEMS_REQUESTED:
            return { ...state, loading: true };

        case Constants.FETCH_PROJECTS_ITEMS_SUCCEEDED:
            return {
                ...state,
                projectsTable: { ...state.projectsTable, sort: data.sort },
                dashboard: { ...state.dashboard, projects: data.table },
                loading: false,
            };

        case Constants.FETCH_PROJECTS_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.NEXT_PROJECTS_PAGE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    projects: {
                        ...state.dashboard.projects,
                        items: [...state.dashboard.projects.items, ...data.items],
                        pagination: data.pagination,
                    },
                },
                loading: false,
            };

        default:
            return state;
    }
};
