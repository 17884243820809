import Constants from "../Constants";

export const openModal = (service, dispatch) => () => {
    dispatch({type: Constants.OPEN_USERS_MODAL});
};

export const closeModal = (service, dispatch) => () => {
    dispatch({type: Constants.CLOSE_USERS_MODAL});
};

export const openInviteModal = (service, dispatch) => () => {
    dispatch({type: Constants.OPEN_INVITE_GUESTS_MODAL});
};

export const closeInviteModal = (service, dispatch) => () => {
    dispatch({type: Constants.CLOSE_INVITE_GUESTS_MODAL});
};

export const updateForm = (service, dispatch) => data => {
    dispatch({type: Constants.UPDATE_USER_FORM, data});
};

export const updateGuestForm = (service, dispatch) => data => {
    dispatch({type: Constants.UPDATE_GUEST_FORM, data});
};


export const getItems = (service, dispatch) => (payload) => {
    dispatch({type: Constants.GET_USERS_REQUESTED});
    service.getItems(payload).then(response => {
        const [data] = response;
        const type = payload && payload.page ?
            Constants.GET_NEXT_PAGE_USERS_SUCCEEDED :
            Constants.GET_USERS_SUCCEEDED;
        dispatch({type, data});
    }, errors => {
        dispatch({type: Constants.GET_USERS_REJECTED, data: errors});
    })
};

export const saveItems = (service, dispatch) => data => {
    dispatch({type: Constants.SAVE_USERS_REQUESTED});
    const {FormService} = service;
    const payload = FormService.usersFormData(data);
    service.saveItems(payload).then(response => {
        dispatch({type: Constants.SAVE_USERS_SUCCEEDED});
        closeModal(service, dispatch)();
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.SAVE_USERS_REJECTED, data: errors});
    });
};

export const saveInviteGuestsItems = (service, dispatch) => data => {
    dispatch({type: Constants.SAVE_USERS_REQUESTED});
    const {FormService} = service;
    const payload = FormService.inviteGuestFormData(data);
    service.saveInviteGuestItems(payload).then(response => {
        alert("Success: Guest has been invited!")
        dispatch({type: Constants.SAVE_USERS_SUCCEEDED});
        closeInviteModal(service, dispatch)();
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.SAVE_USERS_REJECTED, data: errors});
    });
};

export const deleteItem = (service, dispatch) => (data) => {
    dispatch({type: Constants.DELETE_USERS_REQUESTED});
    service.deleteItem(data).then(response => {
        dispatch({type: Constants.DELETE_USERS_SUCCEEDED});
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.DELETE_USERS_REJECTED, data: errors});
    });
};
