import React, {useState} from 'react';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import PropTypes from "prop-types";
import Img from "Templates/Img/Img";
import _ from "lodash";
import {Modal} from "Templates/Modals";
import {InputFilter} from "Templates/Table/Filters";
import {MainButtonLink} from "Templates/Content";
import {formatDate, url} from "Services";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {Block, Label} from "Templates/Form";
import PropertyTable from "./PropertyTable";
import HeaderDatasheet from "./HeaderDatasheet";
import Content from "./Content";
import NotFoundDatasheet from "./NotFoundDatasheet";
import CopySolutionForm from "./CopySolutionForm";
import Approve from "../Approve";
import ProductTable from "../ProductTable";
import ShowMoreSwitch from "../ShowMoreSwitch";
import AssignToProductForm from "../../Shared/SapProductsColorsModals/AssignToProductForm";
import EditProductForm from "../../Shared/SapProductsColorsModals/EditProductForm";
import {PUBLICATIONS_TABS, TABS} from "../../../Constants";
import {ViewHistoryTable} from "../../Modals/ViewHistoryTable";
import {ViewNotesTable} from "../../Modals/ViewNotesTable";

const defaultProps = {
    total: 0,
    subTabs: [],
    activeSubTab: {}
};

const propTypes = {
    id: PropTypes.string.isRequired,
    fetchSubTabAction: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        onClickDeleteFromProductItem: PropTypes.func.isRequired
    }).isRequired,
    showModal: PropTypes.bool.isRequired,
    showEditModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    handleCloseEditModal: PropTypes.func.isRequired,
    handleOpenEditModal: PropTypes.func.isRequired,
    showModalCopySolution: PropTypes.bool.isRequired,
    handleCloseCopySolutionModal: PropTypes.func.isRequired,
    handleOpenCopySolutionModal: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    editProductForm: PropTypes.objectOf(PropTypes.any).isRequired,
    total: PropTypes.number,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchSubTabs: PropTypes.func.isRequired,
    subTabs: PropTypes.arrayOf(PropTypes.object),
    activeSubTab: PropTypes.objectOf(PropTypes.any),
    saveAgreements: PropTypes.func.isRequired,
    getHistoryItems: PropTypes.func.isRequired,
    getNotesItems: PropTypes.func.isRequired,
    rollbackSolutionHistory: PropTypes.func.isRequired,
};

const Technical = (
    {
        id,
        t,
        subTabs,
        activeSubTab,
        fetchSubTabAction,
        service: {onClickDeleteItem, onClickDeleteFromProductItem},
        fetchSubTabs,
        showModalCopySolution,
        handleOpenCopySolutionModal,
        handleCloseCopySolutionModal,
        showModal,
        showEditModal,
        handleCloseModal,
        handleOpenModal,
        handleOpenEditModal,
        handleCloseEditModal,
        form,
        editProductForm,
        callback,
        total,
        permissions,
        saveAgreements,
        getHistoryItems,
        getNotesItems,
        rollbackSolutionHistory
    }
) => {
    const datasheetData = _.get(activeSubTab, 'items_data');
    const agreements = _.get(datasheetData, 'agreements', []);
    const isApproveForSharing = !!_.find(agreements, {type: 1});
    const [urlSubTab, setUrlSubTab] = useState('')

    const [solutionTabActive, setSolutionTabActive] = useState(PUBLICATIONS_TABS.VIEW);

    const showMore = agreements.length !== 0 && permissions.includes("product_solutions_view");
    const hasPermissionToForChange = permissions && permissions.includes("product_solutions_edit");
    // const showMore = false;
    const onClickDeleteDatasheetFromProduct = item => {
        if (!item.can_edit || !hasPermissionToForChange) {
            alert('Contact an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to remove the Technology Data Sheet from product?"))) {
            onClickDeleteFromProductItem(item.grade_id).then(
                () => {
                    fetchSubTabs();
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickEdit = item => {
        if (!hasPermissionToForChange) {
            alert('Contact an Admin to edit.');
            return;
        }

        handleOpenEditModal(item)
    }

    React.useEffect(() => {
        fetchSubTabs().then(() => {
            const subTabId = url.getQueryParam('subTab');

            if (subTabId) {
                setUrlSubTab(subTabId)
                fetchSubTabAction(subTabId);
            }
        });
    }, [])

    const getTableData = (datasheet) => {
        return datasheet.properties || []
    }

    const onChangeForm = (key, value) => {
        form = {
            ...form,
            [key]: value || []
        };

        callback(key, value, form);
    }

    const onClickDelete = (item) => () => {
        if (window.confirm(t("Do you want to remove the Technology Data Sheet?"))) {
            onClickDeleteItem(item).then(() => {
                fetchSubTabs({});
            });
        }
    };

    const onClickApprove = (item, type, status) => () => {
        const question = `${status ? 'Approve' : 'Disapprove'}`
            + ` selected Technology Data Sheet for ${type === 1 ? 'Publication' : (type === 2 ? 'Sharing' : 'Lists')}?`;

        if (window.confirm(t(question))) {
            const agreements = {
                1: {type: 1, status: 0},
            };

            for (const a of Object.values(item.agreements)) {
                agreements[a.type] = {type: a.type, status: 10};
            }

            agreements[type] = {type, status: status ? 10 : 0};

            saveAgreements(item.id)({agreements: Object.values(agreements)}).then(
                () => fetchSubTabs({})
            );
        }
    };

    return (
        <>
            {showEditModal && (
                <Modal onRequestClose={handleCloseEditModal}>
                    <EditProductForm
                        datasheetId={activeSubTab.id}
                        editProductForm={editProductForm}
                        onClose={handleCloseEditModal}
                    />
                </Modal>
            )}
            {showModalCopySolution && (
                <Modal onRequestClose={handleCloseCopySolutionModal}>
                    <CopySolutionForm
                        callback={onChangeForm}
                        datasheetId={activeSubTab.id}
                        form={form}
                        onClose={handleCloseCopySolutionModal}
                    />
                </Modal>
            )}

            <div className="publications-head-wrap">
                {subTabs && subTabs.length > 0 && (
                    <div className={'flex'}>
                        <Label label="Select"/>
                        <span className={'publications-counter flex'}>({subTabs.length})</span>
                    </div>
                )}
                <div className="tds-list-wrap tds-list-wrap__publications">
                    {
                        (subTabs && subTabs.length) ?
                            (
                                <InputFilter
                                    type="select"
                                    value={activeSubTab.id}
                                    options={[...subTabs.map((val) => {
                                        return {value: val.id, label: val.custom_name}
                                    })]}
                                    onChange={subTabId => {
                                        fetchSubTabAction(subTabId);
                                        setUrlSubTab(subTabId);
                                        url.addHistory({'subTab': subTabId});
                                    }}
                                />
                            )
                            : <div/>
                    }
                    <div className="d-flex align-center">
                        {datasheetData ? (
                            <CheckPermission permission="product_solutions_approve">
                                {!isApproveForSharing && (
                                    <MainButtonLink
                                        className="main-btn_white"
                                        onClick={onClickApprove(datasheetData, 1, true)}
                                    >
                                        {t("Approve Publication")}
                                    </MainButtonLink>
                                )}
                                {isApproveForSharing && (
                                    <MainButtonLink
                                        className="main-btn_primary"
                                        onClick={onClickApprove(datasheetData, 1, false)}
                                    >
                                        {t("Disapprove Publication")}
                                    </MainButtonLink>
                                )}
                            </CheckPermission>
                        ) : null}
                        <CheckPermission permission="product_solutions_edit">
                            <MainButtonLink
                                href={`/admin/solutions/${id}/datasheets/create`}
                                className="main-btn_primary"
                            >
                                {t("Add New Technology Data Sheet")}
                            </MainButtonLink>
                        </CheckPermission>
                    </div>
                </div>

                {datasheetData && (
                    <div className="publications-actions-wrap">
                        <div>
                            {showMore && (
                                <ShowMoreSwitch isHidden/>
                            )}
                        </div>
                        <div className="publications-actions">
                            <CheckPermission permission="product_solutions_edit">
                                {(activeSubTab && Object.keys(activeSubTab).length !== 0) && (
                                    <>
                                        <button
                                            type="button"
                                            className="main-circle"
                                            onClick={handleOpenCopySolutionModal}
                                        >
                                            <Img img="icon_copy" className="copy-icon"/>
                                        </button>
                                        <a
                                            type="button"
                                            className="main-circle"
                                            href={`/admin/solutions/${id}/datasheets/${activeSubTab.id}/edit`}
                                        >
                                            <Img img="icon_edit"/>
                                        </a>
                                        <button
                                            type="button"
                                            className="main-circle"
                                            onClick={onClickDelete(activeSubTab)}
                                        >
                                            <Img img="icon_delete"/>
                                        </button>
                                    </>
                                )}
                            </CheckPermission>
                        </div>
                    </div>
                )}

                {datasheetData ? (
                    <div className={`${showMore ? 'show-switch' : ''}`}>
                        <Block>
                            {showMore && (
                                <div className="approved-items-wrap">
                                {agreements.map((agreement) => (
                                        <Approve
                                            key={`approved_${agreement.type}`}
                                            approver={agreement.user_name}
                                            approverEmail={agreement.user_email}
                                            approveDate={formatDate(agreement.date)}
                                            approveType={agreement.type}
                                        />
                                    ))}
                                </div>
                            )}
                        </Block>
                    </div>
                ) : null}
            </div>

            <div className="publications-sub-tabs">
                <div className="publications-sub-tabs__list-wrap">
                    <ul className="publications-sub-tabs__list">
                        {[
                            {
                                id: PUBLICATIONS_TABS.VIEW,
                                title: "View",
                            },
                            {
                                id: PUBLICATIONS_TABS.HISTORY,
                                title: "History",
                            },
                            {
                                id: PUBLICATIONS_TABS.NOTES,
                                title: "Updates",
                            }
                        ].map((tab) => {
                            return (
                                <li
                                    key={tab.id}
                                    className={`${solutionTabActive === tab.id ? 'active' : ''} ${!datasheetData ? 'disabled' : ''} tab-switcher`}
                                    onClick={() => {
                                        setSolutionTabActive(tab.id)
                                    }}
                                >
                                    {t(tab.title)}
                                </li>
                            )
                        })}
                    </ul>
                </div>

                {solutionTabActive === PUBLICATIONS_TABS.VIEW && (
                    <div className="tab-view">
                        <div
                            className={`grade-view__wrap main-wrap-block technical-wrap ${datasheetData ? '' : 'not-available'}`}>
                            {!datasheetData || (Array.isArray(datasheetData) && !datasheetData.length) ? (
                                <NotFoundDatasheet title="Technology Data Sheet"/>
                            ) : (
                                <>
                                    <HeaderDatasheet datasheetData={datasheetData}/>
                                    {datasheetData.contents[0] && datasheetData.contents[0].description && (
                                        <Content data={datasheetData.contents[0].description}/>
                                    )}
                                    {(datasheetData.properties.length > 0) && (
                                        <PropertyTable tdsData={getTableData(datasheetData)}/>
                                    )}
                                    {datasheetData.contents[1] && datasheetData.contents[1].description && (
                                        <Content data={datasheetData.contents[1].description}/>
                                    )}
                                    {datasheetData.copy && <Content data={datasheetData.copy}/>}
                                    <Content data={`Revised ${formatDate(datasheetData.date_updated)}`}/>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {solutionTabActive === PUBLICATIONS_TABS.HISTORY && (
                    <div className="tab-view">
                        {datasheetData ? (
                            <>
                                <ViewHistoryTable urlSubTab={urlSubTab}
                                                  emptyText={'There is no history for current data sheet.'}
                                                  onLoadHistoryData={getHistoryItems}
                                                  rollbackHistory={rollbackSolutionHistory}
                                                  fetchSubTabs={fetchSubTabs}/>
                            </>
                        ) : null}
                    </div>
                )}

                {solutionTabActive === PUBLICATIONS_TABS.NOTES && (
                    <div className="tab-view">
                        {datasheetData ? (
                            <>
                                <ViewNotesTable urlSubTab={urlSubTab}
                                                emptyText={'There are no notes for current data sheet.'}
                                                onLoadNotesData={getNotesItems}/>
                            </>
                        ) : null}
                    </div>
                )}
            </div>
        </>
    );
};

Technical.defaultProps = defaultProps;
Technical.propTypes = propTypes;

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        saveAgreements: getActionStore("saveAgreementsAction", service, dispatch),
        fetchSubTabAction: getActionStore("setActiveSubTabAction", service, dispatch),
        fetchSubTabs: getActionStore("getSubTabs", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
        handleOpenEditModal: getActionStore("handleOpenEditModal", service, dispatch),
        handleCloseEditModal: getActionStore("handleCloseEditModal", service, dispatch),
        handleCloseCopySolutionModal: getActionStore("handleCloseCopySolutionModal", service, dispatch),
        handleOpenCopySolutionModal: getActionStore("handleOpenCopySolutionModal", service, dispatch),
        callback: getActionStore("onChangeForm", service, dispatch),
    };
};

const mapStateToProps = (state, {service: {getStoreItem, getHistoryData, getNotesData, rollbackHistory}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeSubTab: getStoreItem(state, "activeSubTab"),
        subTabs: getStoreItem(state, "subTabs"),
        showModal: getStoreItem(state, "showModal"),
        showEditModal: getStoreItem(state, "showEditModal"),
        showModalCopySolution: getStoreItem(state, "showModalCopySolution"),
        form: {...getStoreItem(state, "form", true)},
        editProductForm: getStoreItem(state, "editProductForm"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        getHistoryItems: getHistoryData,
        getNotesItems: getNotesData,
        rollbackSolutionHistory: rollbackHistory,
        total: getStoreItem(state, "pagination.total"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Technical);
