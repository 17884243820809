export const generateDatasets = (data, selectedValue) => {
    const defaultOptionInDatasets = {
        type: "line",
        pointRadius: 2,
        pointBackgroundColor: 'white',
        borderWidth: 1,
        fill: 'start',
    };
    const getColors = status => {
        switch (selectedValue) {
            case "lead":
            case "level1":
                return {
                    borderColor: "rgba(119, 140, 162, 1)",
                    backgroundColor: "rgba(119, 140, 162, 1)",
                    order: 4
                };

            case "opportunity":
            case "level2":
                return {
                    borderColor: "rgba(255, 171, 0, 1)",
                    backgroundColor: "rgba(255, 171, 0, 1)",
                    order: 3
                };

            case "target":
            case "level3":
                return {
                    borderColor: "rgba(45, 156, 219, 1)",
                    backgroundColor: "rgba(45, 156, 219, 1)",
                    order: 2
                };

            case "current":
            case "level4":
                return {
                    borderColor: "rgba(39, 174, 96, 1)",
                    backgroundColor: "rgba(39, 174, 96, 1)",
                    order: 1
                };

            case "all":
                const colorsForAll = {
                    10: {
                        borderColor: "rgba(119, 140, 162, 1)",
                        backgroundColor: "rgba(119, 140, 162, 1)",
                        order: 4
                    },
                    9: {
                        borderColor: "rgba(255, 171, 0, 1)",
                        backgroundColor: "rgba(255, 171, 0, 1)",
                        order: 3
                    },
                    8: {
                        borderColor: "rgba(45, 156, 219, 1)",
                        backgroundColor: "rgba(45, 156, 219, 1)",
                        order: 2
                    },
                    7: {
                        borderColor: "rgba(39, 174, 96, 1)",
                        backgroundColor: "rgba(39, 174, 96, 1)",
                        order: 1
                    },
                };
                return colorsForAll[status];

            default:
                return {
                    borderColor: "rgba(238, 4, 105, 1)",
                    backgroundColor: "rgba(238, 4, 105, 1)",
                };
        }
    };
    const dataItems = [];
    Object.values(data).forEach(items => {
        items.forEach((item, index) => {
            if (!dataItems[index]) {
                dataItems[index] = {
                    ...defaultOptionInDatasets,
                    ...getColors(item.status),
                    id: item.id,
                    label: item.name,
                    data: [Number(item.sum)],
                    tension: 0
                };
            } else {
                dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
            }
        });
    });
    return Object.values(dataItems);
};

export const getLabels = data => Object.keys(data);
