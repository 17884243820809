import Constants from "../Constants";

export const getNotificationsAction = service => data => {
    const { requestNotifications } = service;
    return requestNotifications({ size: 15, ...data });
};

export const readNotificationAction = (service, dispatch) => data => {
    const { requestOnReadNotifications } = service;
    return requestOnReadNotifications(data).then(res => {
        dispatch({ type: Constants.CHANGE_NOTIFICATION_STATUS, data: res });
        return res;
    });
};
