export const generateDatasets = (data, selectedValue) => {
    const defaultOptionInDatasets = {
        type: "bar",
        borderWidth: 0,
    };
    const getColors = status => {
        switch (selectedValue) {
            case "lead":
            case "level1":
                return { backgroundColor: "rgba(119, 140, 162, 1)" };

            case "opportunity":
            case "level2":
                return { backgroundColor: "rgba(255, 171, 0, 1)" };

            case "target":
            case "level3":
                return { backgroundColor: "rgba(45, 156, 219, 1)" };

            case "current":
            case "level4":
                return { backgroundColor: "rgba(39, 174, 96, 1)" };

            case "current_target":
                return { backgroundColor: "rgb(245,108,107)" };

            case "all":
            case "business-all":
                const colorsForAll = {
                    10: { backgroundColor: "rgba(119, 140, 162, 1)" },
                    9: { backgroundColor: "rgba(255, 171, 0, 1)" },
                    8: { backgroundColor: "rgba(45, 156, 219, 1)" },
                    7: { backgroundColor: "rgba(39, 174, 96, 1)" },
                    6: { backgroundColor: "rgb(245,108,107)" },

                    4: { backgroundColor: "rgba(39, 174, 96, 1)" },
                    3: { backgroundColor: "rgba(45, 156, 219, 1)" },
                    2: { backgroundColor: "rgba(255, 171, 0, 1)" },
                    1: { backgroundColor: "rgba(119, 140, 162, 1)" },
                };
                return colorsForAll[status];

            default:
                return { borderColor: "rgb(238,4,105)" };
        }
    };

    const getData = (type) => {
        const dataItems = [];
        Object.values(data).forEach(items => {
            switch (type) {
                case 'lead':
                case 'level1':
                    items.filter((item) => item.status === 10).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                case 'opportunity':
                case 'level2':
                    items.filter((item) => item.status === 9).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                case 'target':
                case 'level3':
                    items.filter((item) => item.status === 8).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                case 'current':
                case 'level4':
                    items.filter((item) => item.status === 7).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                case 'current_target':
                    items.filter((item) => item.status === 6).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                default:
                    items.forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
            }
        });

        return Object.values(dataItems)
    }

    const mapChartData = () => {
        return getData(selectedValue)
    }

    return mapChartData();
};

export const getLabels = data => Object.keys(data);
