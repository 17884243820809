import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { marginList } from "Services/enum";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { weightUnitName, weightUnitConvertLbs } from 'Services/weightUnitName';
import { Row, Column } from "Templates/Table/NewVersion";
import { Span } from "Templates/Default";
import { generateDatasets } from "Templates/Chart/utils";

const propTypes = {
    data: PropTypes.any,
    marginListSelect: PropTypes.string.isRequired,
    dynamicSelect: PropTypes.string.isRequired,
    service: PropTypes.shape({
        getFirstColumnInTheTable: PropTypes.func.isRequired,
    }).isRequired,
};

const TableBodyColumn = ({ marginListSelect, dynamicSelect, data, weightUnit, service: { getFirstColumnInTheTable } }) => {
    const modifiedData = generateDatasets(data);
    const viewFormat = marginList.find(elem => elem.value === marginListSelect).viewFormat;
    return (
        <>
            {modifiedData.map(item => (
                <Row key={`Row-${item.id}`}>
                    <Column colSpan={1}>{getFirstColumnInTheTable(item, dynamicSelect)}</Column>
                    {item.data.map((elem, index) => (
                        <Column key={`Column-${index}`} colSpan={1}>
                            <Span>{`${viewFormat(elem, weightUnit.weightUnit)}`}</Span>
                        </Column>
                    ))}
                </Row>
            ))}
        </>
    );
};

TableBodyColumn.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        data: getStoreItem(state, "data"),
        dynamicSelect: getStoreItem(state, "old.dynamicSelect"),
        marginListSelect: getStoreItem(state, "old.marginListSelect"),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
