import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const hierarchyProperties = _.get(data, "hierarchyProperties", [])
        .filter(property => _.trim(property.property) && property.methods.filter(method => _.trim(method.method)));

    const dataReq = {
        hierarchyProperties
    };

    return formDataNormalizer(dataReq);
};
