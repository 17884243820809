import $ from "jquery";
import _ from "lodash";

import {fileDownloader} from "Services/fileDownloader/fileDownloader";

const ContentDispositionAttachment = require('content-disposition-attachment');

export default function RequestComopps(cacheRequest) {
    this.delayClass = "ajax-delay";
    this.main = $("body");
    this.loaderUrls = [];

    const isLoading = () => {
        return this.main.hasClass(this.delayClass);
    }

    const setLoading = (url) => {
        if (!this.loaderUrls.includes(url)) {
            this.loaderUrls.push(url);
        }

        this.main.addClass(this.delayClass);
    }

    const removeLoading = (url) => {
        this.loaderUrls = this.loaderUrls.filter((val) => {
            return val !== url;
        })

        if (this.loaderUrls.length === 0) {
            this.main.removeClass(this.delayClass);
        }
    }

    this.sendRequestData = obj => {
        return new Promise((resolve, reject) => {
            this.sendRequest(obj, false).then(data => {
                resolve({ header: data[1], data: data[0] });
            }, reject);
        });
    };

    this.sendRequest = (obj, delay = true, isCache = true) => {
        return new Promise((resolve, reject) => {
            const getCacheKey = ({ url, data }) => `${url}?${$.param(data)}`;
            const { url } = obj;

            if (delay) {
                if (isLoading()) {
                    return;
                }
                setLoading(url);
            }
            if (!delay && obj.type.toLowerCase() === "get" && isCache) {
                const cacheKey = getCacheKey(obj);
                const dataFromTheCache = cacheRequest.get(cacheKey);
                if (dataFromTheCache) {
                    setTimeout(() => {
                        resolve(dataFromTheCache);
                        removeLoading(url);
                    }, 0);

                    return;
                }
            }

            $.ajax({
                url: obj.url,
                data: obj.data,
                type: obj.type,
                dataType: obj.dataType || "JSON",
                processData: obj.hasOwnProperty("processData") ? obj.processData : true,
                contentType: obj.hasOwnProperty("contentType")
                    ? obj.contentType
                    : "application/x-www-form-urlencoded; charset=UTF-8",
            })
                .done((data, textMessage, request) => {
                    const successRequestStatus = 200;
                    if (request.status === successRequestStatus) {
                        if (obj.type.toLowerCase() === "get") {
                            const cacheKey = getCacheKey(obj);
                            cacheRequest.set(cacheKey, [data.message, request]);
                        }
                    }

                    resolve([data.message, request]);
                })
                .fail(error => {
                    reject(_.get(error, "responseJSON.errors", "Error."));
                })
                .always(() => {
                    removeLoading(url);
                });
        });
    };

    this.sendRequestWithNoCache = (obj, delay = true) => {
        return new Promise((resolve, reject) => {
            const { url } = obj;

            if (delay) {
                if (isLoading()) {
                    return;
                }
                setLoading(url);
            }

            $.ajax({
                url: obj.url,
                data: obj.data,
                type: obj.type,
                dataType: obj.dataType || "JSON",
                processData: obj.hasOwnProperty("processData") ? obj.processData : true,
                contentType: obj.hasOwnProperty("contentType")
                    ? obj.contentType
                    : "application/x-www-form-urlencoded; charset=UTF-8",
            })
                .done((data, textMessage, request) => {
                    resolve([data.message, request]);
                })
                .fail(error => {
                    reject(_.get(error, "responseJSON.errors", "Error."));
                })
                .always(() => {
                    removeLoading(url);
                });
        });
    };

    this.sendRequestWithNoCacheAndNoLoading = (obj, delay = true) => {
        return new Promise((resolve, reject) => {
            const getCacheKey = ({ url, data }) => `${url}?${$.param(data)}`;

            $.ajax({
                url: obj.url,
                data: obj.data,
                type: obj.type,
                dataType: obj.dataType || "JSON",
                processData: obj.hasOwnProperty("processData") ? obj.processData : true,
                contentType: obj.hasOwnProperty("contentType")
                    ? obj.contentType
                    : "application/x-www-form-urlencoded; charset=UTF-8",
            })
                .done((data, textMessage, request) => {
                    const successRequestStatus = 200;
                    if (request.status === successRequestStatus) {
                        if (obj.type.toLowerCase() === "get") {
                            const cacheKey = getCacheKey(obj);
                            cacheRequest.set(cacheKey, [data.message, request]);
                        }
                    }

                    resolve([data.message, request]);
                })
                .fail(error => {
                    reject(_.get(error, "responseJSON.errors", "Error."));
                })
        });
    };

    this.sendRequestExportFile = (obj) => {
        const { url, type } = obj;
        setLoading(url);

        fetch(url, {
            method: type,
        }).then(async response => {
            if (!response.ok) {
                removeLoading(url);
                alert('Internal Server Error, please try again!');

                return;
            }

            const getFilename = ContentDispositionAttachment.parse(response.headers.get("content-disposition"));
            const fileToLoad = await response.blob();

            fileDownloader(fileToLoad, getFilename.filename);

            removeLoading(url);
        });
    }

    this.sendRequestWithoutLoading = (obj, delay = true, isCache = true) => {
        return new Promise((resolve, reject) => {
            const getCacheKey = ({ url, data }) => `${url}?${$.param(data)}`;

            if (!delay && obj.type.toLowerCase() === "get" && isCache) {
                const cacheKey = getCacheKey(obj);
                const dataFromTheCache = cacheRequest.get(cacheKey);
                if (dataFromTheCache) {
                    setTimeout(() => {
                        resolve(dataFromTheCache);
                    }, 0);

                    return;
                }
            }

            $.ajax({
                url: obj.url,
                data: obj.data,
                type: obj.type,
                dataType: obj.dataType || "JSON",
                processData: obj.hasOwnProperty("processData") ? obj.processData : true,
                contentType: obj.hasOwnProperty("contentType")
                    ? obj.contentType
                    : "application/x-www-form-urlencoded; charset=UTF-8",
            })
                .done((data, textMessage, request) => {
                    const successRequestStatus = 200;
                    if (request.status === successRequestStatus) {
                        if (obj.type.toLowerCase() === "get") {
                            const cacheKey = getCacheKey(obj);
                            cacheRequest.set(cacheKey, [data.message, request]);
                        }
                    }

                    resolve([data.message, request]);
                })
                .fail(error => {
                    reject(_.get(error, "responseJSON.errors", "Error."));
                })
        });
    };
}
