import React from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";

import MarketSegmentTableBodyColumn from "./Table/TableBodyColumn";
import * as MarketSegmentsService from "../../../../../Services/Tabs/General/Table/MarketSegmentsService";
import ExpandedBlock from "../../../../Templates/ExpandedBlock";

const MarketSegmentTable = CustomSimpleTable()(() => null)()(MarketSegmentTableBodyColumn)()(() => null);

const MarketSegment = ({ t, block, expanded, setExpandedAction }) => {
    return (
        <div className="project-expanded-block">
            <ExpandedBlock title={t("Market Segment")} block={block} service={MarketSegmentsService} isExpanded={expanded} setExpandedAction={setExpandedAction}>
                <MarketSegmentTable />
            </ExpandedBlock>
        </div>
    );
};

MarketSegment.propTypes = {};

export default compose(withTagDefaultProps())(MarketSegment);
