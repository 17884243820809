import React, { useEffect } from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import TabView from "./Views/TabView";
import {url} from "Services";

const Index = ({ permissions }) => {
    useEffect(() => {
        if(!permissions.includes('admin_users_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    return <CheckPermission permission="admin_users_crud">
        <TabView/>
    </CheckPermission>
};

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
