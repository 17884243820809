import React from "react";
import _ from "lodash";
import {formatDate} from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import Checkbox from "../../../Views/Block/Modal/Items/Checkbox";

export const listUrl = "/project/projects/forecast-list";

export const tableLabel = {
    manufacturer: "Manufacturer",
    nameplate: "Nameplate",
    program: "Program",
    unit: "Unit Forecast Name",
    industry: "Market Segment",
    platform: "Platform",
    brand: "Brand",
    vehicle_id: "Vehicle Mnemonic",
    product_plant: "Selling Plant",
    sop: "SOP",
    eop: "EOP",
    segment: "Segment",
    sub_segment: "Project Sub-Segment",
    region: "Region",
    market: "Market",
    country: "Country",
    production_city: "Production City",
    design_parent: "Design Parent",
    total: "Total",
};

export const placeholders = {
    manufacturer: "Search",
    nameplate: "Search",
    program: "Search",
    unit: "Search",
    industry: "Search",
    platform: "Search",
    brand: "Search",
    vehicle_id: "Search",
    product_plant: "Search",
    sop: "Select",
    eop: "Select",
    segment: "Search",
    sub_segment: "Search",
    region: "Search",
    market: "Search",
    country: "Search",
    production_city: "Search",
    design_parent: "Search",
    total: "Total",
};

export const getViewItem = (key, item) => {
    switch (key) {
        case "unit": {
            return <Checkbox item={item} path={key} />;
        }

        case "sop":
        case "eop": {
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={_.get(item, key)}>{_.get(item, key)}</TableRowItemSpan>;
    }
};
