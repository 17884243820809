import React, {Component} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import ImageDragAndDrop from "Services/VideoUpload/ImageDragAndDrop";

const defaultProps = {
    fileLength: 0,
};

const propTypes = {
    fileLength: PropTypes.number,
    handleDrop: PropTypes.func.isRequired,
    dropFile: PropTypes.func.isRequired,
    videoFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string.isRequired,
};

class VideoThumbNail extends Component {
    src = [];

    handleDrop = videoFiles => {
        const { handleDrop, fileLength } = this.props;
        handleDrop([...videoFiles].slice(-fileLength));
    };

    dropFile = key => {
        const { videoFiles, dropFile } = this.props;
        const value = videoFiles.filter((item, n) => +n !== +key);

        dropFile(value);
    };

    renderFiles = videoFiles => {
        if (!videoFiles || !_.head(videoFiles)) {
            return undefined;
        }

        return (
            <ul className="upload-video-modal">
                {videoFiles.map((item, key) => (
                    <li className="upload-video-modal__item" key={key}>
                        <i
                            className="la la-icon-close"
                            onClick={() => {
                                this.dropFile(key);
                            }}
                        />
                    </li>
                ))}
            </ul>
        );
    };

    render() {
        const { name, videoFiles } = this.props;

        return (
            <div className="file-list">
                <ImageDragAndDrop name={name} videoFiles={videoFiles} handleDrop={this.handleDrop}>
                    {this.renderFiles(videoFiles)}
                </ImageDragAndDrop>
            </div>
        );
    }
}

VideoThumbNail.defaultProps = defaultProps;
VideoThumbNail.propTypes = propTypes;

export default VideoThumbNail;
