import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import { Title } from "Templates/Titles";
import {BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton} from "Templates/Form";
import { BackLink } from "Templates/Link";
import { withTagDefaultProps } from "Hoc/Template";
import FormBlock from "../../Views/Form";
import AdditionInfo from "../../Views/Forms/AdditionInfo";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    publicationId: PropTypes.string.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Process = ({ validate, formData, publicationId, pref, t, submitForm }) => {
    const saveProcess = () => {
        if (formData.processProperties.some((property) => property.highlight)) return

        submitForm(formData).then(
            res => {
                url.redirect(`/admin/publications/${publicationId}?subTab=${res.id}&activeTab=1`);
            },
            errors => alert(errors.detail),
        );
    };

    const handleClickOnCancelButton = () => {
        if (window.confirm(t("Cancel?"))) {
            url.redirect(url.referrer('') === '' ? `/admin/publications/${publicationId}?activeTab=1` : url.referrer());
        }
    };

    return (
        <>
            <Title
                title={t("Add New Processing Data Sheet")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <AdditionInfo />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validate || formData.processProperties.some((property) => property.highlight)} onClick={saveProcess}>
                        {t("Publish")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Process.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        publicationId: getStoreItem(state, "publicationId"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
        resetForm: getActionStore("resetForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Process);
