import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

import {BlockImgWithCrop, BlockMain, BlockWrap, BlockWrapFlex, Input, WrapInput} from "Templates/Form";
import {phoneList} from "Services";

const GeneralBlock = ({ name, logoView, email, code, startFy, endFy, number, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    const onChangeLogo = (value, key) => {
        onChange(key, value);
        onChange('logoView', value);
    };

    const checkCompanyName = (value, key) => {
        onChange(key, value.slice(0, 100));
    };

    return (
        <BlockMain title="General Info">
            <BlockWrap>
                <WrapInput isRequired label={t("Organization Name")} name="name">
                    <Input placeholder="Name" value={name} onChange={value => checkCompanyName(value, "name")} />
                </WrapInput>

                <BlockWrapFlex>
                    <WrapInput label={t("Country Code")} name="code" isWidth>
                        <Input
                            type="searchSelect"
                            value={code}
                            onChange={value => onChangeInput(value, "code")}
                            options={phoneList}
                        />
                    </WrapInput>
                    <WrapInput label={t("Phone")} name="phone">
                        <Input
                            placeholder={t("Phone number")}
                            value={number}
                            onChange={value => onChangeInput(value, "number")}
                        />
                    </WrapInput>
                </BlockWrapFlex>
                <WrapInput isRequired label={t("Start of FY")} name="start_fy">
                    <Input
                        placeholder={t("MM/DD")}
                        type="date"
                        value={startFy}
                        onChange={value => onChangeInput(value, "start_fy")}
                        viewFormat="MM/DD"
                    />
                </WrapInput>
            </BlockWrap>
            <BlockWrap>
                <WrapInput label={t("Email")} name="email">
                    <Input
                        type="email"
                        placeholder={t("Email")}
                        value={email}
                        onChange={value => onChangeInput(value, "email")}
                    />
                </WrapInput>
                <WrapInput isRequired label={t("End of FY")} name="end_fy">
                    <Input
                        placeholder={t("MM/DD")}
                        type="date"
                        value={endFy}
                        onChange={value => onChangeInput(value, "end_fy")}
                        viewFormat="MM/DD"
                    />
                </WrapInput>
            </BlockWrap>
            <BlockImgWithCrop name="logo" value={logoView} title={t("Organization logo")} width={1136} height={320} isZoomRange={true} onChange={value => onChangeLogo(value, "logo")} />
        </BlockMain>
    );
};
GeneralBlock.defaultProps = {
    parentCompany: "",
};

GeneralBlock.propTypes = {
    edit: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
    logoView: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
    startFy: PropTypes.string.isRequired,
    endFy: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        logo: getStoreItem(state, "logo"),
        logoView: getStoreItem(state, "logoView"),
        email: getStoreItem(state, "email"),
        code: getStoreItem(state, "code"),
        number: getStoreItem(state, "number"),
        validate: getStoreItem(state, "form_validate"),
        startFy: getStoreItem(state, "start_fy"),
        endFy: getStoreItem(state, "end_fy"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(GeneralBlock);
