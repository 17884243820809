import { request, url } from "Services";

export const requestOnUploadFiles = link => data => {
    const formData = new FormData();
    for (let i = 0; i < data.length; i += 1) {
        formData.append("files[]", data[i]);
    }
    const sendObj = {
        url: url.getUrl(link),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};
