import {handleScroll} from "../../EventsService";
import {getItem, requestAssignToProduct, requestOnSaveAgreements, requestOnSaveCopyPublication} from "./RequestService";
import {getActionStore, getStoreItem} from "../../StoreService";
import {deleteItem, requestProductsTable} from "../../RequestService";
import {getFilter, getTableLabel, isSortEnabled} from "../../TableHeaderService";
import {getViewItem, modifierValues} from "../../TableBodyService";
import {requestEditProduct} from "../Technical/RequestService";

export default (id, subId) => ({
    requestProductsTable: requestProductsTable(`/admin/publications/${id}/safeties/${subId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    modifierValues,
    getTableLabel,
    isSortEnabled,
    requestSubTab: () => getItem(`/admin/publications/${id}/safeties`),
    requestTypeItem: item => getItem(`/admin/publications/${id}/safeties/${item.id}`),
    // requestOnUploadFiles: requestOnUploadFiles(`/admin/publications/${id}/safeties/add`),
    requestAssignToProduct: safetyId => requestAssignToProduct(`/product/safeties/${safetyId}/copy`),
    requestEditProduct: (datasheetId, productId) => requestEditProduct(`/product/safeties/${datasheetId}/edit-product-colors/${productId}`),
    requestOnSaveCopyPublication: safetyId => requestOnSaveCopyPublication(`/admin/publications/${id}/safeties/${safetyId}/copy`),
    requestOnSaveAgreements: safetyId => requestOnSaveAgreements(`/admin/publications/${id}/safeties/${safetyId}/agreements`),
    onClickDeleteItem: item => deleteItem(`/product/safeties/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/safeties/${subId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getNotesData: (page) => getItem(`/admin/publications/${id}/safeties/${subId}/notes`, {page}),
    getHistoryData: (sort, page) => getItem(`/admin/publications/${id}/safeties/${subId}/history`, {sort, page}),
    rollbackHistory: (historyId) => getItem(`/admin/publications/${id}/safeties/${subId}/history/${historyId}/rollback`),
});
