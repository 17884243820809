import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url, numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, CheckPermission } from "Hoc/Template";
import {
    TabBlock,
    TabBlockBtn,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";

class ProductionLines extends Component {
    redirectOnCreate = () => {
        const { id } = this.props;
        url.redirect(`${id}/lines/create`);
    };

    onClickDelete = item => {
        const { t } = this.props;
        if (confirm(t("Do you want to delete the Production Line?"))) {
            const {
                fetchItems,
                request,
                service: { onClickDeleteItem },
            } = this.props;
            onClickDeleteItem(item).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    onClickEdit = item => {
        const { id } = this.props;
        url.redirect(`/product/plants/${id}/lines/${item.id}/edit`);
    };

    render() {
        const { pagination, t } = this.props;
        return (
            <>
                <TabWrapTop>
                    <TabBlock>
                        <TabBlockCol>
                            <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                            <TabBlockColTitleH4>
                                {`${numberWithCommas(pagination.total, 0)} ${t("Product Line")}`}
                            </TabBlockColTitleH4>
                        </TabBlockCol>
                    </TabBlock>
                    <CheckPermission permission="plant_line_crud">
                        <TabBlockBtn onClick={this.redirectOnCreate}>{t("Add New Production Line")}</TabBlockBtn>
                    </CheckPermission>
                </TabWrapTop>
                <TabViewDetail onClickDelete={this.onClickDelete} onClickEdit={this.onClickEdit} />
            </>
        );
    }
}

ProductionLines.propTypes = {
    id: PropTypes.string.isRequired,
    pagination: PropTypes.shape({ total: PropTypes.number }).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        id: getStoreItem(state, "view.id"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ProductionLines);
