import React from "react";
import {compose} from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import {BlockMain, BlockWrap, Block, BlockWrapFlex, Input, WrapInput} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Button} from "Templates/Default";
import LinkIcon from "Templates/Link/LinkIcon";
import BlockTitle from "Templates/Form/BlockTitle";
import { url, isEqual } from "Services";
import Img from "Templates/Img/Img";

const attachManufacturing = () => {
    return {
        manufacturing: "",
        parameters: []
    };
};

const attachParameter = () => {
    return {
        parameter: "",
        descriptions: []
    };
};

const attachDescription = () => {
    return {
        description: "",
        si_units: []
    };
};

const attachSiUnit = () => {
    return {
        si_unit: "",
        english_units: []
    };
};

const attachEnglishUnit = () => {
    return {
        english_unit: ""
    };
};

const addItemManufacturing = (appendFunc, serviceFunc) => () => {
    serviceFunc(_.concat([], appendFunc()));
};

const addItemParameter = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    items[key].parameters = _.concat(items[key].parameters, appendFunc());

    serviceFunc(_.concat(items));
};

const addItemDescription = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    const data = _.concat(_.get(items, key).descriptions, appendFunc());
    _.set(items, `${key}.descriptions`, data);

    serviceFunc(_.concat(items));
};

const addItemSiUnit = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    const data = _.concat(_.get(items, key).si_units, appendFunc());
    _.set(items, `${key}.si_units`, data);

    serviceFunc(_.concat(items));
};

const addItemEnglishUnit = (appendFunc, serviceFunc) => (items, key) => (skipAdd) => {
    if (skipAdd) return;

    const data = _.concat(_.get(items, key).english_units, appendFunc());
    _.set(items, `${key}.english_units`, data);

    serviceFunc(_.concat(items));
};

const removeItem = serviceFunc => (items, index, key) => () => {
    if (key === undefined) {
        serviceFunc(items.filter((i, k) => k !== index));
        return;
    }

    const filtered = _.get(items, key).filter((i, k) => k !== index);

    _.set(items, key, filtered);

    serviceFunc(_.concat(items));
};

const onChangeItem = serviceFunc => (itemsMap, key) => (value, index, funcNext, keyNext) => {
    _.set(itemsMap, index, value);

    const nextData = _.get(itemsMap, keyNext);
    const skipAddNext = Array.isArray(nextData) && nextData.length > 0;

    funcNext(skipAddNext);
    serviceFunc(key, _.concat(itemsMap));
};

const onChangeShowBtnItem = (serviceFunc, onChangeFunc, resetForm) => (itemsMap, key) => (value, index, rootProperties, formData, old) => {
    const canSwitch = isEqual(formData, old, _.keys(old));

    if (canSwitch) {
        itemsMap = [];
        _.set(itemsMap, index, value);

        value && serviceFunc(rootProperties[index].id, 'hierarchyProperties');
        onChangeFunc(key, _.concat(itemsMap));
    } else {
        if (window.confirm("You will lose unsaved changes. Continue?")) {
            itemsMap = [];
            _.set(itemsMap, index, value);

            value ? serviceFunc(rootProperties[index].id, 'hierarchyProperties') : resetForm();
            onChangeFunc(key, _.concat(itemsMap));
        }
    }
};

const deleteRootProperty = (serviceFunc, onChangeFunc, resetForm) => (id, rootProperties, showProperties, index) => () => {
    if (window.confirm("Do you want to remove the Manufacturing Process?")) {
        if (!id) {
            _.pullAt(rootProperties, index);
            _.pullAt(showProperties, index);
            onChangeFunc('rootProperties', rootProperties);
            onChangeFunc('showProperties', showProperties);
            resetForm();
        } else {
            serviceFunc(id).then(
                data => {
                    url.redirect("/admin/process-hierarchies");
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    }
};

const propTypes = {
    hierarchyProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    showProperties: PropTypes.arrayOf(PropTypes.bool).isRequired,
    rootProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeShowBtn: PropTypes.func.isRequired,
    addManufacturing: PropTypes.func.isRequired,
    addParameter: PropTypes.func.isRequired,
    addDescription: PropTypes.func.isRequired,
    addSiUnit: PropTypes.func.isRequired,
    addEnglishUnit: PropTypes.func.isRequired,
    removeProperty: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    deleteProperty: PropTypes.func.isRequired,
};

const HierarchyBlock = (
    {
        hierarchyProperties,
        showProperties,
        rootProperties,
        onChange,
        onChangeShowBtn,
        addManufacturing,
        addParameter,
        addDescription,
        addSiUnit,
        addEnglishUnit,
        removeProperty,
        deleteProperty,
        formData,
        old,
        t
    }
) => {
    const onChangeHierarchyProperty = onChange(hierarchyProperties, "hierarchyProperties");
    const onChangeShowBtnLess = onChangeShowBtn(showProperties, "showProperties");

    const showAddNewBtn = () => {
        return _.every(showProperties, item => !item) && _.every(rootProperties, item => item.id !== '');
    };

    return (
        <BlockMain title="">
            <BlockWrap className="width_100">
                {rootProperties.map((rootProperty, keyRootP) => (
                    <div key={`Root-property-${keyRootP}`}>
                        <div className={'toggle__show-block-edit-property-hierarchies'} key={`Title-${keyRootP}`}>
                            <BlockTitle>{rootProperty.name}</BlockTitle>
                            <button
                                className={["cost-modal__calculator_btn", showProperties[keyRootP] ? 'open' : ''].join(' ')}
                                onClick={
                                    value => onChangeShowBtnLess(!showProperties[keyRootP], keyRootP, rootProperties, formData, old)
                                }
                            >
                                Show {showProperties[keyRootP] ? 'Less' : 'More'}
                            </button>
                            <button
                                type="button"
                                className={'edit-property-hierarchies__form-block-link'}
                                onClick={deleteProperty(rootProperty.id, rootProperties, showProperties, keyRootP)}
                            >
                                <Img img="icon_delete" />
                            </button>
                        </div>
                        {showProperties[keyRootP] && hierarchyProperties.map((manufacturing, keyM) => (
                            <div className="property-hierarchy_content" key={`Hierarchy-${keyM}`}>
                                <Block key={`Block-hierarchy-${keyM}`} className="line_hierarchy">
                                    <BlockWrap key={`BlockWrap-manufacturing-${keyM}`} className="width_1-6">
                                        <BlockWrapFlex key={`BlockWrapFlex-manufacturing-${keyM}`}>
                                            <WrapInput key={`WrapInput-manufacturing-${keyM}`} label="Manufacturing Process"
                                                       name={`${keyM}.manufacturing`}>
                                                <Input
                                                    placeholder={t("Search")}
                                                    disabled={!!rootProperties[keyRootP].id}
                                                    url="admin/process-properties/unique/properties"
                                                    type="asyncSelect"
                                                    value={hierarchyProperties[keyM].manufacturing}
                                                    onChange={
                                                        value => onChangeHierarchyProperty(value, `${keyM}.manufacturing`,
                                                            addParameter(hierarchyProperties, keyM), `${keyM}.parameters`)
                                                    }
                                                />
                                            </WrapInput>
                                            {hierarchyProperties.length > 1 &&
                                            <LinkIcon onClick={removeProperty(hierarchyProperties, keyM)}/>}
                                        </BlockWrapFlex>
                                    </BlockWrap>
                                    <BlockWrap key={`BlockWrap-parameter-${keyM}`}>
                                        {manufacturing.parameters.map((parameter, keyP) => (
                                            <Block key={`Block-parameter-${keyP}`} className="line_hierarchy">
                                                <BlockWrap key={`BlockWrap-parameter-${keyP}`} className="width_1-5">
                                                    <BlockWrapFlex key={`BlockWrapFlex-parameter-${keyP}`}>
                                                        <WrapInput key={`WrapInput-parameter-${keyP}`} label="Process Parameter"
                                                                   name={`${keyP}.parameter`}>
                                                            <Input
                                                                placeholder={t("Search")}
                                                                url="admin/process-properties/list/2"
                                                                type="asyncSelect"
                                                                value={parameter.parameter}
                                                                onChange={
                                                                    value => onChangeHierarchyProperty(value, `${keyM}.parameters.${keyP}.parameter`,
                                                                        addDescription(hierarchyProperties, `${keyM}.parameters.${keyP}`),
                                                                        `${keyM}.parameters.${keyP}.descriptions`)
                                                                }
                                                            />
                                                        </WrapInput>
                                                        {manufacturing.parameters.length > 1 &&
                                                        <LinkIcon
                                                            onClick={removeProperty(hierarchyProperties, keyM, `${keyP}.parameters`)}/>}
                                                    </BlockWrapFlex>
                                                </BlockWrap>
                                                <BlockWrap key={`BlockWrap-description-${keyP}`}>
                                                    {parameter.descriptions.map((description, keyD) => (
                                                        <Block key={`Block-description-${keyD}`}
                                                               className="line_hierarchy">
                                                            <BlockWrap key={`BlockWrap-description-${keyD}`}
                                                                       className="width_1-4">
                                                                <BlockWrapFlex key={`BlockWrapFlex-description-${keyD}`}>
                                                                    <WrapInput key={`WrapInput-description-${keyD}`}
                                                                               label="Description"
                                                                               name={`${keyD}.description`}>
                                                                        <Input
                                                                            placeholder={t("Search")}
                                                                            url="admin/process-properties/list/3"
                                                                            type="asyncSelect"
                                                                            value={description.description}
                                                                            onChange={
                                                                                value => onChangeHierarchyProperty(value, `${keyM}.parameters.${keyP}.descriptions.${keyD}.description`,
                                                                                    addSiUnit(hierarchyProperties, `${keyM}.parameters.${keyP}.descriptions.${keyD}`),
                                                                                    `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units`)
                                                                            }
                                                                        />
                                                                    </WrapInput>
                                                                    {parameter.descriptions.length > 1 &&
                                                                    <LinkIcon
                                                                        onClick={removeProperty(hierarchyProperties, keyD, `${keyM}.parameters.${keyP}.descriptions`)}/>}
                                                                </BlockWrapFlex>
                                                            </BlockWrap>
                                                            <BlockWrap key={`BlockWrap-si_unit-${keyD}`}>
                                                                {description.si_units.map((si_unit, keySU) => (
                                                                    <Block key={`Block-si_unit-${keySU}`}
                                                                           className="line_hierarchy">
                                                                        <BlockWrap
                                                                            key={`BlockWrap-si_unit-${keySU}`}
                                                                            className="width_1-2">
                                                                            <BlockWrapFlex
                                                                                key={`BlockWrapFlex-si_unit-${keySU}`}>
                                                                                <WrapInput
                                                                                    key={`WrapInput-si_unit-${keySU}`}
                                                                                    label="SI Unit"
                                                                                    name={`${keySU}.si_unit`}>
                                                                                    <Input
                                                                                        placeholder={t("Search")}
                                                                                        url="admin/process-properties/list/4"
                                                                                        type="asyncSelect"
                                                                                        value={si_unit.si_unit}
                                                                                        onChange={
                                                                                            value => onChangeHierarchyProperty(value, `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units.${keySU}.si_unit`,
                                                                                                addEnglishUnit(hierarchyProperties, `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units.${keySU}`),
                                                                                                `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units.${keySU}.english_units`)
                                                                                        }
                                                                                    />
                                                                                </WrapInput>
                                                                                {description.si_units.length > 1 &&
                                                                                    <LinkIcon
                                                                                        onClick={removeProperty(hierarchyProperties, keySU, `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units`)}/>}
                                                                            </BlockWrapFlex>
                                                                        </BlockWrap>
                                                                        <BlockWrap
                                                                            key={`BlockWrap-english_unit-${keySU}`}>
                                                                            {si_unit.english_units.map((english_unit, keyEU) => (
                                                                                <Block
                                                                                    key={`Block-english_unit-${keyEU}`}>
                                                                                    <BlockWrap
                                                                                        key={`BlockWrap-english_unit-${keyEU}`}
                                                                                        className="width_1-2">
                                                                                        <BlockWrapFlex
                                                                                            key={`BlockWrapFlex-english_unit-${keyEU}`}>
                                                                                            <WrapInput
                                                                                                key={`WrapInput-english_unit-${keyEU}`}
                                                                                                label="English Unit"
                                                                                                name={`${keyEU}.english_unit`}>
                                                                                                <Input
                                                                                                    placeholder={t("Search")}
                                                                                                    url="admin/process-properties/list/5"
                                                                                                    type="asyncSelect"
                                                                                                    value={english_unit.english_unit}
                                                                                                    onChange={
                                                                                                        value => onChangeHierarchyProperty(value, `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units.${keySU}.english_units.${keyEU}.english_unit`,
                                                                                                            addEnglishUnit(hierarchyProperties, `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units.${keySU}`),
                                                                                                            `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units.${keySU}.english_units`)
                                                                                                    }
                                                                                                />
                                                                                            </WrapInput>
                                                                                            {si_unit.english_units.length > 1 &&
                                                                                                <LinkIcon
                                                                                                    onClick={removeProperty(hierarchyProperties, keyEU, `${keyM}.parameters.${keyP}.descriptions.${keyD}.si_units.${keySU}.english_units`)}/>}
                                                                                        </BlockWrapFlex>
                                                                                    </BlockWrap>
                                                                                </Block>
                                                                            ))}
                                                                        </BlockWrap>
                                                                    </Block>
                                                                ))}
                                                                {description.si_units.length >= 1 && description.si_units[0].si_unit !== '' &&
                                                                    <Button
                                                                        className="size_normal main-btn main-btn_white"
                                                                        onClick={value => addSiUnit(hierarchyProperties, `${keyM}.parameters.${keyP}.descriptions.${keyD}`)(false)}>
                                                                        {t("Add Another SI Unit")}
                                                                    </Button>
                                                                }
                                                            </BlockWrap>
                                                        </Block>
                                                    ))}
                                                    {parameter.descriptions.length >= 1 && parameter.descriptions[0].description !== '' &&
                                                    <Button className="size_normal main-btn main-btn_white"
                                                            onClick={value => addDescription(hierarchyProperties, `${keyM}.parameters.${keyP}`)(false)}>
                                                        {t("Add Another Description")}
                                                    </Button>
                                                    }
                                                </BlockWrap>
                                            </Block>
                                        ))}
                                        {manufacturing.parameters.length >= 1 && manufacturing.parameters[0].parameter !== '' &&
                                        <Button className="size_normal main-btn main-btn_white"
                                                onClick={value => addParameter(hierarchyProperties, keyM)(false)}>
                                            {t("Add Another Process Parameter")}
                                        </Button>
                                        }
                                    </BlockWrap>
                                </Block>
                            </div>
                        ))}
                    </div>
                ))}
                {showAddNewBtn() &&
                <Button className="size_normal main-btn main-btn_white" onClick={addManufacturing}>
                    {t("Add Another Manufacturing Process")}
                </Button>
                }
            </BlockWrap>
        </BlockMain>
    );
};

HierarchyBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {service} = ownProps;
    const {getStoreItem} = service;

    return {
        hierarchyProperties: getStoreItem(state, `hierarchyProperties`),
        showProperties: getStoreItem(state, `showProperties`),
        rootProperties: getStoreItem(state, `rootProperties`),
        old: getStoreItem(state, "old"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    const onChange = getActionStore("onChange", service, dispatch);
    const resetForm = getActionStore("resetForm", service, dispatch);
    const refreshProperty = getActionStore("refreshProperty", service, dispatch);
    const addProperty = getActionStore("addProperty", service, dispatch);
    const requestHierarchyData = getActionStore("onChangeShowBtn", service, dispatch);
    const deleteProperty = getActionStore("deleteProperty", service, dispatch);

    return {
        addManufacturing: addItemManufacturing(attachManufacturing, addProperty),
        addParameter: addItemParameter(attachParameter, refreshProperty),
        addDescription: addItemDescription(attachDescription, refreshProperty),
        addSiUnit: addItemSiUnit(attachSiUnit, refreshProperty),
        addEnglishUnit: addItemEnglishUnit(attachEnglishUnit, refreshProperty),
        removeProperty: removeItem(refreshProperty),
        onChange: onChangeItem(onChange),
        onChangeShowBtn: onChangeShowBtnItem(requestHierarchyData, onChange, resetForm),
        deleteProperty: deleteRootProperty(deleteProperty, onChange, resetForm)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(HierarchyBlock);
