import React, { Component } from "react";
import moment from "moment";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import {formatDate as formatDateService} from "Services/index";

const defaultProps = {
    value: "",
    dateFormat: "YYYY-MM-DD",
    viewFormat: "MMM DD, YYYY",
    placeholder: "MMM DD, YYYY",
    inputProps: {},
};

const CustomOverlay = onChange => ({classNames, selectedDay, children, onBlur ,...props}) => {

    return (
        <div className={classNames.overlayWrapper}
             onBlur={ e => {
                 onBlur(e);
                 //  props.input.focus();
                 props.input.blur();
             }}
             {...props}>
            <div className={classNames.overlay}>
                <p>
                    <button type="button" onClick={() => {
                        props.input.focus();
                        props.input.blur();
                        onChange("");
                    }}>
                        Reset
                    </button>
                </p>
                {children}
            </div>
        </div>
    );
};

class DateRange extends Component {
    static defaultProps = {
        numberOfMonths: 2,
    };

    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.resetPicker = this.resetPicker.bind(this);

        this.state = props.value ? { from: new Date(props.value.from), to: new Date(props.value.to) } : this.getInitialState();
    }

    getInitialState() {
        return {
            from: undefined,
            to: undefined,
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        const { from, to} = this.state;

        if(!nextProps.value && from && to){
            this.setState(this.getInitialState());
        }
    };

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    handleFromChange(from) {
        this.setState({ from });
    }

    handleToChange(to, modifiers, dayPickerInput) {

        const { onChange, dateFormat } = this.props;

        if(to){
            this.to.getInput().blur();

            onChange({target: {value: {
                        from: this.state.from ? formatDateService(this.state.from, dateFormat) : "",
                        to: to ? formatDateService(to, dateFormat) : ""
                    }}});

            this.setState({ to }, this.showFromMonth);
        }

    }

    resetPicker = () => {
        this.setState(this.getInitialState());

        const { onChange } = this.props;
        onChange({target: {value: ''}});
    };

    render() {
        const { from, to} = this.state;
        const { className, viewFormat, value} = this.props;

        const modifiers = { start: from, end: to };

        return (
            <div className="InputFromTo">
                <DayPickerInput
                    value={from}
                    placeholder="From"
                    clickUnselectsDay={false}
                    format={viewFormat}
                    inputProps={{
                        className: className + ' inputDateRange'
                    }}
                    overlayComponent={CustomOverlay(this.resetPicker)}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                    }}
                    onDayChange={this.handleFromChange}
                />
                <div className={'Dash'}> - </div>
                <span className="InputFromTo-to">
                  <DayPickerInput
                      ref={el => (this.to = el)}
                      value={to}
                      placeholder="To"
                      format={viewFormat}
                      inputProps={{
                          className: className + ' inputDateRange'
                      }}
                      formatDate={formatDate}
                      parseDate={parseDate}
                      overlayComponent={CustomOverlay(this.resetPicker)}
                      dayPickerProps={{
                          selectedDays: [from, { from, to }],
                          disabledDays: { before: from },
                          modifiers,
                          month: from,
                          fromMonth: from,
                          numberOfMonths: 2,
                      }}
                      onDayChange={this.handleToChange}
                  />
                </span>
            </div>
        );
    }
}

DateRange.defaultProps = defaultProps;

export default DateRange;