import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { createState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "material-create" }),
        createState: { ...createState },
    };

    return _configureStore(initialState, reducers);
}
