import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import Img from "Templates/Img";

const defaultProps = {
    onClickEdit: null,
    onClickDelete: null,
};

const propTypes = {
    urlBack: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
};

const InfoTop = ({ pref, urlBack, onClickEdit, onClickDelete }) => (
    <div className={`${pref}__info-top main-content__info-top`}>
        <a href={document.referrer ? document.referrer : urlBack} className={`${pref}__info-top-btn main-content__info-top-btn`}>
            <i className="la arrow-left" />
        </a>
        {onClickEdit && (
            <button
                type="button"
                className={`${pref}__info-top-circle main-content__info-top-circle main-circle`}
                onClick={onClickEdit}
            >
                <Img img="icon_edit" />
            </button>
        )}
        {onClickDelete && (
            <button
                type="button"
                className={`${pref}__info-top-circle main-content__info-top-circle main-circle`}
                onClick={onClickDelete}
            >
                <Img img="icon_delete" />
            </button>
        )}
    </div>
);

InfoTop.defaultProps = defaultProps;
InfoTop.propTypes = propTypes;

export default compose(withTagDefaultProps())(InfoTop);
