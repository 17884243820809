import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";

const propTypes = {
    name: PropTypes.string.isRequired
};

const InfoWrap = ({
    name
}) => {
    return (
        <ContentInfoWrap>
            <ContentInfoTop urlBack="/admin/list?activeTab=10" />
            <ContentInfoBlock>
                <ContentInfoTitle>{name}</ContentInfoTitle>
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "view.name"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
