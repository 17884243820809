import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ContentInfoTitle, ContentInfoWrap, MainButton} from "Templates/Content";
import {WrapButtons} from "Templates/Titles";
import {Modal} from "Templates/Modals";
import Img from "Templates/Img/Img";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {showConfirm} from "Services";
import FolderForm from "./Views/FolderForm";
import UploadFileForm from "./Views/UploadVideoForm";
import VideoPreview from "./Views/VideoPreview";
import {changeVideoPositions, deleteVideo, deleteVideoFolder} from "./Services/RequestService";
import EditUploadVideoForm from "./Views/EditUploadVideoForm";

const DragHandle = SortableHandle(() => <span className="table-settings__style__handle"> </span>);

const SortableVideoItem = SortableElement(({canEdit, file, index, openVideoPreviewModal, handleMenuAction, deleteVideoFile, editFile}) => {

    return (
        <div className="video-item__wrapper">
            {canEdit ? <DragHandle/> : null}
            <div className="video-item">
                <div
                    className="video-item__preview"
                    onMouseOver={() => {
                        handleMenuAction(true)
                    }}
                    onMouseOut={() => {
                        if (!canEdit) {
                            handleMenuAction(true)

                            return
                        }

                        handleMenuAction(false)
                    }}
                    onClick={() => {
                        openVideoPreviewModal(file.id)
                    }}
                >
                    {canEdit ? <button
                        type="button"
                        className="video-item__preview-edit-btn main-circle"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            editFile(file)
                        }}
                    >
                        <Img img="icon_edit" />
                    </button> : null}
                    {canEdit ? <button
                        type="button"
                        className="video-item__preview-delete-btn main-circle"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            deleteVideoFile(file.id)
                        }}
                    >
                        <Img img="icon_delete"/>
                    </button> : null}
                    {file.thumbnail_path ? <img className="video-list-item__thumb-image" src={file.thumbnail_path} alt="Video Thumbnail"/> : <p className="video-item__preview-status">
                        Click to Play!
                    </p>}
                    <span className="video-item__preview-icon">
                                            <Img img="play-video-icon"/>
                </span>
                </div>
                <div className={`video-item__text ${canEdit ? 'allowed' : 'disabled'}`}>
                    <p className="video-item__text-title" title={file.name}>{file.name}</p>
                    <p className="video-item__text-description" title={file.description}>{file.description}</p>
                </div>
            </div>
        </div>
    )
});

const SortableVideoList = SortableContainer(({
                                                 canEdit,
                                                 isDisabled,
                                                 onHandleMenuAction,
                                                 files,
                                                 openVideoPreviewModal,
                                                 deleteVideoFile,
                                                 editFile
                                             }) => {
    return (
        <div className="videos">
            {files.map((file, index) => (
                <SortableVideoItem
                    canEdit={canEdit}
                    key={file.id}
                    index={index}
                    file={file}
                    openVideoPreviewModal={openVideoPreviewModal}
                    disabled={isDisabled}
                    handleMenuAction={onHandleMenuAction}
                    deleteVideoFile={deleteVideoFile}
                    editFile={editFile}
                />
            ))}
        </div>
    );
});

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    uploadVideoModal: PropTypes.bool.isRequired,
    editVideoModal: PropTypes.bool.isRequired,
    fetchItems: PropTypes.func.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    foldersRequest: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        handleScroll: PropTypes.func.isRequired,
    }).isRequired,
    selectedFolderFiles: PropTypes.objectOf(PropTypes.any).isRequired,
    openUploadVideoModal: PropTypes.func.isRequired,
    closeUploadVideoModal: PropTypes.func.isRequired,
    openEditUploadVideoModal: PropTypes.func.isRequired,
    closeEditUploadVideoModal: PropTypes.func.isRequired,
    setSelectedFolderRequest: PropTypes.func.isRequired,
    fetchPage: PropTypes.func.isRequired,
    fetchFoldersPage: PropTypes.func.isRequired,
};

export const VIDEO_PREVIEW_MODAL_DATA_DEFAULT = {
    isOpen: false,
    videoId: null
}

const VideoGuide = ({
                        permissions,
                        items,
                        uploadVideoModal,
                        editVideoModal,
                        service,
                        selectedFolderFiles,
                        request,
                        foldersRequest,
                        openUploadVideoModal,
                        closeUploadVideoModal,
                        openEditUploadVideoModal,
                        closeEditUploadVideoModal,
                        fetchItems,
                        setSelectedFolderRequest,
                        t,
                        fetchPage,
                        fetchFoldersPage
                    }) => {
    const [folderModalData, setFolderModalData] = useState({
        isOpen: false,
        folder: null
    })
    const [videoPreviewModalData, setVideoPreviewModalData] = useState(VIDEO_PREVIEW_MODAL_DATA_DEFAULT)
    const [selectedFolder, setSelectedFolder] = useState(null)
    const [isDisabled, setDisabled] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [editingVideo, setEditingVideo] = useState(null)

    const {handleScroll} = service;

    useEffect(() => {
        if (permissions.includes('video_guides_crud')) {
            setCanEdit(true)
        } else {
            setCanEdit(false)
        }
    }, [permissions])

    useEffect(() => {
        if (!items.length) {
            setSelectedFolder(null)

            return
        }

        if (!selectedFolder) {
            setSelectedFolder(items[0])
            setSelectedFolderRequest(items[0].id)
        } else {
            const updatedFolder = items.find((folder) => folder.id === selectedFolder.id)
            if (updatedFolder) {
                setSelectedFolder(updatedFolder)
            }
        }
    }, [items])

    const onScrollFoldersList = (e) => {
        const {page, pages} = foldersRequest;
        if (page >= pages) {
            return;
        }
        handleScroll(e).then(() => {
            fetchFoldersPage({page: page + 1});
        })
    };

    const onScroll = (e) => {
        const {page, pages} = request;
        if (page >= pages) {
            return;
        }
        handleScroll(e).then(() => {
            fetchPage({folderId: selectedFolder.id, page: page + 1});
        })
    };

    const openVideoPreviewModal = (videoId) => {
        setVideoPreviewModalData({
            isOpen: true,
            videoId
        })
    }

    const arrayMove = (array, from, to) => {
        const newArray = [...array];
        const [item] = newArray.splice(from, 1);
        newArray.splice(to, 0, item);
        return newArray;
    }

    const onHandleMenuAction = (e) => {
        if (!canEdit) {
            setDisabled(true);

            return
        }
        setDisabled(e)
    }

    const onDeleteVideo = (videoId) => {
        if (showConfirm(t('Delete video?'))) {
            deleteVideo(videoId).then(() => {
                setSelectedFolderRequest(selectedFolder.id)
                setSelectedFolder(selectedFolder)
            })
        }
    }

    const onEditFile = (video) => {
        setEditingVideo(video)
        openEditUploadVideoModal(true)
    }

    const onDeleteFolder = (folder) => {
        if (showConfirm(t(`Delete "${folder.name}" Folder?`))) {
            deleteVideoFolder(folder.id).then(() => {
                fetchItems()
            })
        }
    }

    return (
        <>
            <div className="video-guide">
                <ContentInfoWrap>
                    <ContentInfoTitle>Video Guides</ContentInfoTitle>
                    <div className="videos-infor-wrapper" onScroll={onScrollFoldersList}>
                        {items.length > 0 ? items.map((folder) => {
                            return folder ? <div
                                key={folder.id}
                                className={`video-guide__folder ${selectedFolder && selectedFolder.id === folder.id ? 'active' : ''}`}
                                onClick={() => {
                                    setSelectedFolderRequest(folder.id)
                                    setSelectedFolder(folder)
                                }}
                            >
                                <p>{folder.name}</p>
                            </div> : null
                        }) : null}
                    </div>
                </ContentInfoWrap>
                <div className="video-guide__wrap">
                    {canEdit && <WrapButtons>
                        <>
                            {selectedFolder
                                ? (
                                    <div>
                                        <MainButton
                                            className="main-btn_white delete-folder"
                                            onClick={() => onDeleteFolder(selectedFolder)}
                                        >
                                            {t("Delete Folder")}
                                        </MainButton>
                                        <MainButton
                                            className="main-btn_white"
                                            onClick={() => setFolderModalData({
                                                isOpen: true,
                                                folder: selectedFolder
                                            })}
                                        >
                                            {t("Edit Folder")}
                                        </MainButton>
                                    </div>
                                ) : null}
                            <MainButton
                                className="main-btn_white"
                                onClick={() => setFolderModalData({
                                    isOpen: true,
                                    folder: null
                                })}
                            >
                                {t("Create Folder")}
                            </MainButton>
                            {selectedFolder ? (
                                <MainButton className="main-btn_primary" onClick={() => openUploadVideoModal(true)}>
                                    {t("Add New Video")}
                                </MainButton>
                            ) : null}
                        </>
                    </WrapButtons>}
                    {selectedFolderFiles && selectedFolderFiles.items.length > 0 ? (
                        <div className="videos-container" onScroll={onScroll}>
                            <SortableVideoList
                                canEdit={canEdit}
                                isDisabled={isDisabled}
                                onHandleMenuAction={onHandleMenuAction}
                                files={selectedFolderFiles.items}
                                openVideoPreviewModal={openVideoPreviewModal}
                                onSortEnd={({oldIndex, newIndex}) => {
                                    if (oldIndex === newIndex) return

                                    const reorderedItems = arrayMove(selectedFolderFiles.items, oldIndex, newIndex);
                                    const newPositionList = reorderedItems.map((item) => item.id);
                                    changeVideoPositions(newPositionList).then(() => {
                                        setSelectedFolderRequest(selectedFolder.id)
                                        setSelectedFolder(selectedFolder)
                                    })
                                }}
                                deleteVideoFile={onDeleteVideo}
                                editFile={onEditFile}
                                axis="xy"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            {folderModalData && folderModalData.isOpen ? (
                <Modal onRequestClose={() => setFolderModalData({
                    isOpen: false,
                    folder: null
                })}
                >
                    <FolderForm
                        data={folderModalData.folder}
                        cbAfterRequest={(res) => {
                            if (!res) return
                            setFolderModalData({
                                isOpen: false,
                                folder: null
                            })
                            fetchItems();
                        }}
                        onClose={() => setFolderModalData({
                            isOpen: false,
                            folder: null
                        })}
                    />
                </Modal>
            ) : null}
            {videoPreviewModalData && videoPreviewModalData.isOpen ? (
                <Modal onRequestClose={() => setVideoPreviewModalData(VIDEO_PREVIEW_MODAL_DATA_DEFAULT)}>
                    <VideoPreview
                        videoId={videoPreviewModalData.videoId}
                        onClose={() => setVideoPreviewModalData(VIDEO_PREVIEW_MODAL_DATA_DEFAULT)}
                    />
                </Modal>
            ) : null}
            {uploadVideoModal ? (
                <Modal className="upload-video-modal__form" onRequestClose={() => closeUploadVideoModal(false)}>
                    <UploadFileForm
                        folderId={selectedFolder.id}
                        position={selectedFolderFiles.items.length || 0}
                        onClose={() => closeUploadVideoModal(false)}
                    />
                </Modal>
            ) : null}
            {editVideoModal ? (
                <Modal className="upload-video-modal__form" onRequestClose={() => closeEditUploadVideoModal(false)}>
                    <EditUploadVideoForm
                        folderId={selectedFolder.id}
                        editingVideo={editingVideo}
                        onClose={() => closeEditUploadVideoModal(false)}
                    />
                </Modal>
            ) : null}
        </>
    )
};

VideoGuide.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        permissions: state.userState.permissions,
        items: getStoreItem(state, "items"),
        uploadVideoModal: getStoreItem(state, "uploadVideoModal"),
        editVideoModal: getStoreItem(state, "editVideoModal"),
        selectedFolderFiles: getStoreItem(state, "selectedFolderFiles"),
        request: {
            ...getStoreItem(state, 'selectedFolderFiles.pagination'),
        },
        foldersRequest: {
            ...getStoreItem(state, 'foldersPagination'),
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        setSelectedFolderRequest: getActionStore("setSelectedFolder", service, dispatch),
        openUploadVideoModal: getActionStore("openUploadVideoModal", service, dispatch),
        closeUploadVideoModal: getActionStore("closeUploadVideoModal", service, dispatch),
        openEditUploadVideoModal: getActionStore("openEditUploadVideoModal", service, dispatch),
        closeEditUploadVideoModal: getActionStore("closeEditUploadVideoModal", service, dispatch),
        fetchPage: getActionStore("fetchPage", service, dispatch),
        fetchFoldersPage: getActionStore("fetchFoldersPage", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(VideoGuide);
