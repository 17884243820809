import _ from "lodash";

export const columns = {
    design_parent: "Design Parent",
    primary_design_center: "Primary Design Center",
    primary_design_country: "Primary Design Country",
    secondary_design_center: "Secondary Design Center",
    secondary_design_country: "Secondary Design Country",
    secondary_design_region: "Secondary Design Region",
    sales_parent: "Sales Parent",
};

export const getFormatValue = (item, key) => {
    const value = _.get(item, key, "");

    switch (key) {
        default:
            return value;
    }
};
