import { handleScroll } from "./EventsService";
import { requestTable, requestRevertItem } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default ({
    requestRevertItem,
    getViewItem,
    modifierValues,
    getFilter,
    getTableLabel,
    requestTable,
    handleScroll,
    getStoreItem,
    getActionStore,
});
