import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { Title } from "Templates/Titles";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    resetForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Task = ({ validate, formData, pref, t, submitForm, resetForm }) => {
    const saveAndAddTask = () => {
        submitForm(formData).then(
            () => {
                resetForm();
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const saveTask = () => {
        submitForm(formData).then(
            data => {
                url.redirect(`/tasks/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (window.confirm(t("Cancel?"))) {
            url.redirect("/tasks");
        }
    };

    return (
        <>
            <Title
                title={t("Add New Task")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>{t("Cancel")}</SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <button
                        type="button"
                        className={`${pref}__left-btn main-btn main-btn_white`}
                        onClick={saveAndAddTask}
                        disabled={!validate}
                    >
                        {t("Save & Add Another Task")}
                    </button>
                    <SubmitBlockButton disabled={!validate} onClick={saveTask}>
                        {t("Add Task")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Task.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        // errors: getStoreItem(state, "errors"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
        resetForm: getActionStore("resetForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Task);
