import Constants from "../Constants";

export const onChangeTab = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_TAB, data });
};

export const onChangeConnectionType = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_CONNECTION_TYPE, data });
};
export const onSetConnectionProjectType = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_CONNECTION_PROJECT_TYPE, data });
};
export const onChangeActualsType = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_ACTUALS_TYPE, data });
};

export const onGetProductCodesList = (service, dispatch) => (data) => {
    dispatch({ type: Constants.GET_PRODUCTS_CODES_LIST, data });
};

export const resetFormAction = (service, dispatch) => () => {
    dispatch({ type: Constants.RESET_FORM });
};

export const setTitle = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_TITLE, data });
};
