import React from "react";
// import PropTypes from 'prop-types'
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {};

const CardTitle = ({ pref, children }) => {
    return <div className={`${pref}__card-title`}>{children}</div>;
};

CardTitle.propTypes = propTypes;

export default compose(withTagDefaultProps())(CardTitle);
