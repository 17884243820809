import React from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    years: PropTypes.shape({
        [PropTypes.number]: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ),
    }).isRequired,
    old: PropTypes.shape({
        [PropTypes.number]: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired,
            }),
        ),
    }).isRequired,
    company: PropTypes.string.isRequired,
    grade: PropTypes.string.isRequired,
    validate: PropTypes.bool.isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Connection = ({ company, grade, old, validate, years, pref, t, submitForm }) => {
    const handleClickOnSave = () => {
        submitForm({
            company,
            grade,
            years,
        }).then(
            data => {
                url.redirect(`/connection/connections/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (confirm(t("Cancel?"))) {
            url.redirect("/connection/connections");
        }
    };

    const validateAll = validate ? !_.isEqual(years, old.years) : validate;

    return (
        <>
            <Title
                title={t("Edit Connection")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validateAll} onClick={handleClickOnSave}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Connection.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    validate: getStoreItem(state, "form_validate"),
    years: getStoreItem(state, "years"),
    old: getStoreItem(state, "old"),
    company: getStoreItem(state, "company"),
    grade: getStoreItem(state, "grade"),
});

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Connection);
