import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const {
        id,
        slug,
        generalBlock,
        forecastBlock,
        show_full,
        productBlock,
        applicationBlock,
        supplyChainBlock,
        projectVolumeBlock,
        projectTeamAndContactsBlock,
        replacementBusinessProjectsBlock,
    } = props;

    const initData = {
        id,
        slug,
        generalBlock,
        show_full,
        forecastBlock,
        productBlock,
        applicationBlock,
        supplyChainBlock,
        projectVolumeBlock,
        projectTeamAndContactsBlock,
        replacementBusinessProjectsBlock,
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "projects-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
