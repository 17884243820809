import React from "react";

const Tooltip = ({ children }) => {
    return (
        <span className="tooltip">
            <i className="la la-info-circle" />
            <span className="tooltip__text">{children}</span>
        </span>
    );
};

Tooltip.propTypes = {};

export default Tooltip;
