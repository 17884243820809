import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer, ServiceProvider } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { CustomSimpleTable } from "Hoc/Widgets/Table/SimpleTableContainer";
import { BlockTitle } from "Templates/Form";
import TableBodyColumn from "./Table/TableBodyColumn";
import DeltaTableBodyColumn from "./Table/DeltaTableBodyColumn";
import * as _designTableService from "../../../Services/Tabs/General/Tables/DesignTableService";
import * as _otherTableService from "../../../Services/Tabs/General/Tables/OtherTableService";
import * as _plantTableService from "../../../Services/Tabs/General/Tables/PlantTableService";
import * as _productionTableService from "../../../Services/Tabs/General/Tables/ProductionTableService";
import * as _unitDetailTableService from "../../../Services/Tabs/General/Tables/UnitDetailTableService";
import * as _deltaTableService from "../../../Services/Tabs/General/Tables/DetailTableService";

const Table = CustomSimpleTable()(() => null)()(TableBodyColumn)()(() => null);
const TableDelta = CustomSimpleTable()(() => null)()(DeltaTableBodyColumn)()(() => null);

const propTypes = {
    getData: PropTypes.func.isRequired,
    service: PropTypes.shape({ getStoreItem: PropTypes.func.isRequired }).isRequired,
};

class General extends Component {
    componentDidMount() {
        const { getData } = this.props;
        getData();
    }

    render() {
        const {
            t,
            service: { getStoreItem },
        } = this.props;
        const designTableService = { ..._designTableService, getStoreItem };
        const otherTableService = { ..._otherTableService, getStoreItem };
        const plantTableService = { ..._plantTableService, getStoreItem };
        const productionTableService = { ..._productionTableService, getStoreItem };
        const unitDetailTableService = { ..._unitDetailTableService, getStoreItem };
        const deltaTableService = { ..._deltaTableService, getStoreItem };
        return (
            <>
                <div className="vehicle-view__wrap-holders main-content__wrap-holders">
                    <div className="vehicle-view__wrap-holder main-content__wrap-holder main-wrap-block">
                        <BlockTitle>{t("Unit Details")}</BlockTitle>
                        <ServiceProvider value={unitDetailTableService}>
                            <Table />
                        </ServiceProvider>
                    </div>
                    <div className="vehicle-view__wrap-holder main-content__wrap-holder main-wrap-block">
                        <BlockTitle>{t("Plant")}</BlockTitle>
                        <ServiceProvider value={plantTableService}>
                            <Table />
                        </ServiceProvider>
                    </div>
                    <div className="vehicle-view__wrap-holder main-content__wrap-holder main-wrap-block">
                        <BlockTitle>{t("Production")}</BlockTitle>
                        <ServiceProvider value={productionTableService}>
                            <Table />
                        </ServiceProvider>
                    </div>
                    <div className="vehicle-view__wrap-holder main-content__wrap-holder main-wrap-block">
                        <BlockTitle>{t("Design")}</BlockTitle>
                        <ServiceProvider value={designTableService}>
                            <Table />
                        </ServiceProvider>
                    </div>
                    <div className="vehicle-view__wrap-holder main-content__wrap-holder main-wrap-block">
                        <BlockTitle>{t("Other")}</BlockTitle>
                        <ServiceProvider value={otherTableService}>
                            <Table />
                        </ServiceProvider>
                    </div>
                    <div className="vehicle-view__wrap-holder main-content__wrap-holder main-wrap-block">
                        <BlockTitle>{t("Monthly IHS Delta")}</BlockTitle>
                        <ServiceProvider value={deltaTableService}>
                            <TableDelta />
                        </ServiceProvider>
                    </div>
                </div>
            </>
        );
    }
}

General.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        getData: getActionStore("getDataAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(General);
