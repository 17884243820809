import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    TabBlock,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";

import { ContainerTable } from "Hoc/Widgets";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const propTypes = {
    pagination: PropTypes.shape({
        total: PropTypes.number.isRequired,
    }).isRequired,
};

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap, { showButtons: false });
const TabViewDetail = customTable(DefaultTabTable);

const Project = ({ pagination: { total }, t }) => {
    return (
        <>
            <TabWrapTop>
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{`${numberWithCommas(total, 0)} ${t("Projects")}`}</TabBlockColTitleH4>
                    </TabBlockCol>
                </TabBlock>
            </TabWrapTop>
            <TabViewDetail />
        </>
    );
};

Project.propTypes = propTypes;

const mapStateToProps = (state, props) => {
    const { getStoreItem } = props.service;

    return {
        pagination: getStoreItem(state, "pagination"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Project);
