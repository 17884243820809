// import _ from "lodash";
// import Constants from "../../Constants";

export const initialState = {
    projectManager: "",
    internalTeam: [{ frontId: 0, value: "" }],
    contacts: [{ frontId: 0, value: "" }],
};

// export const reducer = (state = initialState, action) => {
//     const { data } = action;

//     switch (action.type) {
//         default: {
//             return { ...state };
//         }
//     }
// };
