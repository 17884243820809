import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import _ from "lodash";
import {filter} from "Services";
import reducers, {initialStates} from "../Reducers";
import {initState} from "../../../../Company/View/Reducers/ViewReducer";

export default function configureStore(props) {
    const {
        items,
        pinned_columns,
        total_analytics,
        pagination,
        activeColumns,
        columns,
        financialYear,
        updated_date,
        totalNotes,
        help
    } = props;

    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "projects" }),
        listState: {
            ...mainState,
            items,
            pinned_columns,
            total_analytics,
            activeColumns,
            pagination,
            columns,
            financialYear,
            filter: filter.getParsedFilters(['status']),
            sort: filter.getSort(),
            updated_date,
            totalNotes,
            help
        },
    };

    if (initState.activeTab) {
        _.unset(initialState.listState, "items");
    }

    return _configureStore(initialState, reducers);
}
