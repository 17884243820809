import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, id: PropTypes.id })).isRequired,
};

function Breadcrumbs({ breadcrumbsItems, onClickItem }) {
    const getLabel = elem => elem.name;

    const _onClickItem = elem => () => {
        onClickItem(elem.id);
    };

    return breadcrumbsItems && breadcrumbsItems.length > 1 ?
        (<ul className="breadcrumbs">
            {breadcrumbsItems.map((elem, index) => (
                <li key={`breadcrumbs-li-${index}`} className="breadcrumbs__item">
                    {elem.id ? (
                        <a className="breadcrumbs__link" onClick={_onClickItem(elem)}>
                            {getLabel(elem)}
                        </a>
                    ) : (
                        <span className="breadcrumbs__link">{getLabel(elem)}</span>
                    )}
                </li>
            ))}
        </ul>) : null;
}
const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    breadcrumbsItems: getStoreItem(state, "breadcrumbs"),
});

Breadcrumbs.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Breadcrumbs);
