import React, {Component} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import {compose} from "redux";
import {ModalDefault} from "Templates/Modals";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {ComoppsEditor} from "Templates/Input";
import {Preloader} from "Templates/Preloader";
import {InputFilter} from "Templates/Table/Filters";

const { Button, Form, Title } = ModalDefault;

const propTypes = {
    item: PropTypes.shape({ id: PropTypes.string, append: PropTypes.string, old: PropTypes.string }).isRequired,
    onClose: PropTypes.func.isRequired,
    editNote: PropTypes.func.isRequired,
    cbAfterRequest: PropTypes.func.isRequired,
    service: PropTypes.shape({
        requestAttach: PropTypes.func.isRequired,
    }).isRequired,
};

class NoteModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id,
            old: this.props.item.old,
            type: this.props.item.type,
            append: this.props.item.append,
            loaded: false
        };
    }

    onAppend = () => {
        const {
            cbAfterRequest,
            editNote,
            service: { requestAttach },
        } = this.props;
        const {id, append, type} = this.state;

        let convertedLink = append.split('http').reduce((values, link) => {
            let value = "http" + link;
            if(!link.includes('://')){
                return values + link;
            } else if(link.includes('target=') || link.includes('</a>')){

                if(link.includes('target=') && !link.includes('class')){
                    value = value.replace('target=', 'class="clr-primary" target=');
                }
                return values + value;
            }
            return values + value.replace(/(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim, '<a class="clr-primary" target="_blank" href="$&">$&</a>');

        });

        const link = convertedLink.replace(/undefined\/\//g, '');

        if (id) {
            editNote({ id, comment: link, type}).then(cbAfterRequest({type}));
            return;
        }

        requestAttach({ comment: link, type}).then(cbAfterRequest({type}));
    };

    handleChangeNote = append => {
        this.setState({ append });
    };

    handleLoaded = () => {
        this.setState({ loaded: true });
    }

    render() {
        const { id, old, append, loaded } = this.state;
        const { t, onClose } = this.props;

        const disabled = !_.trim(append) || old === append;
        const mode = id ? "Edit" : "Add";

        return (
            <ReactModal
                isOpen
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                overlayClassName="main-modal"
                className={`main-modal-content notes-modal ${!loaded ? 'loading' : ''}`}
                onRequestClose={onClose(append)}
            >
                {!loaded && <Preloader />}
                <Form>
                    <ButtonClose className="main-modal__form-close" onClick={onClose(append)} />
                    <Title>{t(`${mode} Note`)}</Title>
                    <ComoppsEditor
                        text={append}
                        isModal={true}
                        onChange={this.handleChangeNote}
                        loaded={this.handleLoaded}
                    />
                    <Button onClick={this.onAppend} disabled={disabled}>
                        {t("Save Note")}
                    </Button>
                </Form>
            </ReactModal>
        );
    }
}

NoteModal.propTypes = propTypes;

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        editNote: getActionStore("editNoteAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(NoteModal);
