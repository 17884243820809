import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import { getString, formatDate } from "Services";
import { taskStatus } from "Services/enum";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "title":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/tasks/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "project":
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, key)}
                    href={`/project/projects/${getString(item, "project_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "assigned_by":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "assigned_by_id")}`}
                    img={getString(item, "assigned_by_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "assigned_to":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "assigned_to_id")}`}
                    img={getString(item, "assigned_to_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "sub_status":
            const status = taskStatus.find(elem => elem.value === getString(item, key)) || {};
            return <TableRowItemStatus status={status.label} list={taskStatus} />;

        default:
            const date = formatDate(getString(item, key));
            return <TableRowItemSpan title={date}>{date}</TableRowItemSpan>;
    }
};
