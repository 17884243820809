import React, { useEffect } from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import {numberWithCommas, url} from "Services";
import Tab from "Templates/Tabs/Tab";
import Title from "Templates/Titles/Title";
import Total from "Templates/Titles/Total";
import GradeView from "./Views/Tabs/Grade";
import CompanyView from "./Views/Tabs/Company";
import RawView from "./Views/Tabs/Raw";
import ProjectView from "./Views/Tabs/Project";
import PublicationView from "./Views/Tabs/Publication";
import UserView from "./Views/Tabs/User";
import gradeService from "./Services/Tabs/Grade";
import companyService from "./Services/Tabs/Company";
import rawService from "./Services/Tabs/Raw";
import projectService from "./Services/Tabs/Project";
import publicationService from "./Services/Tabs/Publication";
import userService from "./Services/Tabs/User";

const propTypes = {
    activeTab: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const labelGrade = "Grades";
const labelCompanies = "Companies";
const labelRaw = "Raw Materials";
const labelProjects = "Projects";
const labelPublications = "Publications";
const labelUsers = "Users";
const labelVendors = "Vendors";

const tabs = [
    {
        label: labelGrade,
        button: labelGrade,
    },
    {
        label: labelCompanies,
        button: labelCompanies,
    },
    {
        label: labelRaw,
        button: labelRaw,
    },
    {
        label: labelProjects,
        button: labelProjects,
    },
    {
        label: labelPublications,
        button: labelPublications,
    },
    {
        label: labelUsers,
        button: labelUsers,
    },
    {
        label: labelVendors,
        button: labelVendors,
    },
];

const Lists = (props) => {
    const { title, activeTab, onChangeTab, t, permissions } = props;
    useEffect(() => {
        if(!permissions.includes('admin_deleted-page_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    return (
        <CheckPermission permission="admin_deleted-page_crud">
            <Title
                title={t(tabs[activeTab].label)}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            />

            <Tabs
                activeTab={activeTab}
                onClickTabItem={index => {
                    if (activeTab !== index) {
                        onChangeTab(index);
                    }
                }}
            >
                <Tab label={t(labelGrade)}>
                    <ServiceProvider value={gradeService()}>
                        <GradeView />
                    </ServiceProvider>
                </Tab>
                <Tab label={t(labelCompanies)}>
                    <ServiceProvider value={companyService()}>
                        <CompanyView />
                    </ServiceProvider>
                </Tab>
                <Tab label={t(labelRaw)}>
                    <ServiceProvider value={rawService()}>
                        <RawView />
                    </ServiceProvider>
                </Tab>
                <Tab label={t(labelProjects)}>
                    <ServiceProvider value={projectService()}>
                        <ProjectView />
                    </ServiceProvider>
                </Tab>
                <Tab label={t(labelPublications)}>
                    <ServiceProvider value={publicationService()}>
                        <PublicationView />
                    </ServiceProvider>
                </Tab>
                <Tab label={t(labelUsers)}>
                    <ServiceProvider value={userService()}>
                        <UserView />
                    </ServiceProvider>
                </Tab>
            </Tabs>
        </CheckPermission>
    );
}

Lists.propTypes = propTypes;
Lists.defaultProps = {};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        title: getStoreItem(state, "pagination.total"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Lists);
