import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {projectStatus, stageGates} from "Services/enum";
import {onChange} from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        slug: "Project ID",
        name: "Project Name",
        manager: "Sales Manager (Project)",
        internal_team: "Internal Team Member",
        sub_status: "Status",
        stage_gate: "Salesforce Stage Gates",
        error_name: "Notifications",
        product_application_codes: "SAP Application Code",
        application_code: "Project Application Code",
        application: "Project Sub-Segment",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        slug: "Search",
        name: "Search",
        manager: "Search",
        internal_team: "Search",
        sub_status: "Select",
        stage_gate: "Select",
        error_name: "Search",
        product_application_codes: "Search",
        application_code: "Search",
        application: "Search",
    };

    const urls = {
        slug: "/project/projects/project-slug",
        name: "/project/projects/project-list",
        manager: "/project/projects/user-list",
        internal_team: "/project/projects/user-list",
        product_application_codes: "/project/projects/application-code-list",
        application_code: "/project/projects/application-code-list",
        application: "/project/projects/application-list"
    };

    switch (key) {
        case "slug":
        case "name":
        case "manager":
        case "internal_team":
        case "product_application_codes":
        case "application_code":
        case "application":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={_.get(urls, key)}
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    name={key}
                    value={value}
                    options={projectStatus}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "stage_gate":
            return (
                <InputFilter
                    type="multiSelect"
                    name={key}
                    value={value}
                    options={stageGates}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
