import { debounce } from "Services/Debounce";
import Constants from "../Constants";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: sort || {} }));
        },
        () => {
            dispatch(requestFailed({ items: [] }));
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if ([
        "sop",
        "eop",
        "los_date",
        'product_code',
        'application_code',
        "created_at",
        "updated_at",
        "sub_status_changed_at",
        "move_to_current",
        "end_date",
        'customer',
        'customer_code',
        'billing_date',
        'created_on',
        'stage_gate',
        'plant'
    ].includes(key)) {
        fetchItems(service, dispatch)(request);
        return;
    }

    if (
        [
            "slug",
            "name",
            "products",
            "grades",
            "material_numbers",
            "product_codes",
            "product_application_codes",
            "internal_team",
            "customer_duns_number",
            "customer_owner",
            "plants",
            "lines",
            "brands",
            "budget",
            "nameplates",
            "sub_status",
            "application_code",
            "sub_segment",
            "application",
            "industry",
            "customer",
            "customer_code",
            "tier1",
            "tier2",
            "molder",
            "status",
            "manager",
            "grade_is_sap",
            "company_is_sap",
            "customer_nickname",
            "tier1_nickname",
            "tier2_nickname",
            "molder_nickname",
            "owner",
            "unit_value",
            "weight_value",
            "manufacturer",
            "platform",
            "program",
            "city",
            "country",
            "region",
            "production_plant",
            "unit",
            "grade_sub_status",
        ].includes(key)
    ) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const onChangeForm = (service, dispatch) => (key, value, form) => {
    dispatch({ type: Constants.CHANGE_SELECT, data: { key, value } });
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        () => {
            dispatch(requestFailed({ items: [] }));
        },
    );
};
