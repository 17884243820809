import React, {useEffect, useState} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import Img from "Templates/Img";
import TableBodyButton from "Templates/Table/TableBodyButton";
import parse from 'html-react-parser';
import {NoteMessage} from "Templates/Notes";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    handleClickOnEditButton: PropTypes.func.isRequired,
    handleClickOnDeleteButton: PropTypes.func.isRequired,
};

const NoteMessageWrap = ({ permissions, item, handleClickOnEditButton, handleClickOnDeleteButton }) => {
    const [canEdit, setCanEdit] = useState(false)

    useEffect(() => {
        if (permissions.includes('product_solutions_notes_edit')) {
            setCanEdit(true)
        } else {
            setCanEdit(false)
        }
    }, [permissions])
    const _openModal = item => {
        handleClickOnEditButton(item);
    };

    const _deleteModal = item => {
        handleClickOnDeleteButton(item);
    };

    return (
        <NoteMessage>
            <p>{item.comment ? parse(item.comment) : item.comment}</p>
                {canEdit ? (
                    <div className="note-btn-list">
                        <TableBodyButton onClick={() => _openModal(item)}>
                            <Img img="icon_edit" />
                        </TableBodyButton>
                        <TableBodyButton onClick={() => _deleteModal(item)}>
                            <Img img="icon_delete" />
                        </TableBodyButton>
                    </div>
                ) : null}
        </NoteMessage>
    );
};

NoteMessageWrap.propTypes = propTypes;
const mapStateToProps = (state) => {
    return {
        permissions: state.userState.permissions,
    };
};
export default  compose(
    withTagDefaultProps(mapStateToProps),
)(NoteMessageWrap);
