import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {ServiceProvider, withServiceConsumer} from 'Services/Context';
import {CheckPermission, withTagDefaultProps} from 'Hoc/Template';
import {showMessage, url} from 'Services';
import {Modal} from 'Templates/Modals';
import Tabs from 'Templates/Tabs/Tabs';
import TabCheck from 'Templates/Tabs/TabСheck';
import {ContentWrap, ContentWrapCol, MainButton, MainButtonLink, TabBlockBtn} from 'Templates/Content';
import allService from './Services/Tabs/All';
import selfService from './Services/Tabs/Self';
import myListService from './Services/Tabs/MyList';
import sharedProductService from './Services/Tabs/SharedProduct';
import sharedListService from './Services/Tabs/SharedList';
import InfoWrap from './Views/InfoWrap';
import Products from './Views';
import SharedProduct from './Views/Tabs/SharedProduct';
import SharedList from './Views/Tabs/SharedList';
import MyListView from './Views/Tabs/MyList';
import WelcomeGuestModal from './Views/WelcomeGuestModal';
import WelcomeSharedListGuestModal from './Views/WelcomeSharedListGuestModal';
import ShareFiltersModal from './Views/ShareFiltersModal';
import AddPublicationListModalForm from "./Views/AddPublicationListModalForm";
import Img from "Templates/Img";
import AddSharedProductsPublicationListModal from "./Views/AddSharedProductsPublicationListModal";
import {Div} from "Templates/Default";

const Grade = (
    {
        activeTab,
        items,
        onChangeTab,
        t,
        permissions,
        isFirstLogin,
        welcomeGuestShowModal,
        shareFiltersShowModal,
        handleCloseModal,
        handleOpenModal,
        handleOpenShareFiltersModal,
        handleCloseShareFiltersModal,
        handleOpenWelcomeGuestModal,
        handleCloseWelcomeGuestModal,
        filterForm,
        onChangeFilterForm,
        filterType,
        resetFilterWithoutFetch,
        request,
        getUrlExport,
        addSharedProductsPublicationListShowModal,
        handleCloseAddSharedProductsPublicationsToList,
        handleAddSharedProductsPublicationsToList,
        addPublicationListShowModal,
        handleCloseAddPublicationListModal,
        handleOpenAddPublicationListModal,
        addPublicationListForm,
        onChangeAddPublicationListForm,
    }) => {
    const { filter } = request;

    const services = {
        0: allService,
        1: selfService,
        2: sharedProductService,
        3: sharedListService,
        4: myListService
    };

    if (
        !permissions.includes('grade_product_finder_view') && activeTab < 2
    ) {
        if (permissions.includes('product_publications_guest')){
            activeTab = 2;
        } else if (permissions.includes('product_publication_groups_view')){
            activeTab = 3;
        } else {
            activeTab = 4;
        }
        onChangeTab(activeTab);
    }

    if (
        isFirstLogin
        && permissions.length < 3
        && (permissions.includes('product_publications_guest') || permissions.includes('product_publication_groups_view'))
        && !localStorage.getItem('WelcomeGuestModalWatched')
        && !welcomeGuestShowModal
    ) {
        handleOpenWelcomeGuestModal();
    }

    const handleReset = () => {
        url.clearHistory();
        return resetFilterWithoutFetch();
    }

    const closeWelcomeGuestModal = () => {
        localStorage.setItem('WelcomeGuestModalWatched', !welcomeGuestShowModal);
        handleCloseWelcomeGuestModal();
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        getUrlExport(url.getUrl('/api/product/product-finder/export', request, ''));
    }

    const onAddNewProduct = () => {
        if (!permissions.includes('admin')) {
            showMessage('To add a new product email support@comopps.com and include the name of the product in the email. Please use the subject heading: "Add New Product"');
            return;
        }
        url.redirect('/product/product-finder/create');
    }

    return (
        <>
            {shareFiltersShowModal && (
                <Modal onRequestClose={handleCloseShareFiltersModal}>
                    <ShareFiltersModal
                        onChange={onChangeFilterForm}
                        filterForm={filterForm}
                        filter={request.filter}
                        sort={request.sort}
                        type={filterType}
                        onClose={handleCloseShareFiltersModal}
                    />
                </Modal>
            )}
            {welcomeGuestShowModal && activeTab !== 3 && (
                <Modal onRequestClose={closeWelcomeGuestModal}>
                    <WelcomeGuestModal
                        onClose={closeWelcomeGuestModal}
                    />
                </Modal>
            )}
            {welcomeGuestShowModal && activeTab === 3 && (
                <Modal onRequestClose={closeWelcomeGuestModal}>
                    <WelcomeSharedListGuestModal
                        onClose={closeWelcomeGuestModal}
                    />
                </Modal>
            )}
            <ContentWrap>
                <ServiceProvider value={services[activeTab]}>
                    <InfoWrap activeTab={activeTab}/>
                </ServiceProvider>
                <ContentWrapCol>
                    <div className="d-flex flex-col h-100">
                        <div className="product-finder-actions">
                                <div
                                    className={`d-flex ${Object.keys(filter).length !== 0 ? 'shared-filters-btns' : ''}`}
                                >
                                    {activeTab !== 3 && Object.keys(filter).length !== 0 && (
                                        <TabBlockBtn
                                            title="Invite users to view filtered products and approved publications in ComOpps."
                                            onClick={handleOpenShareFiltersModal}
                                            className="main-btn_primary main-btn_primary-share-filters ml-auto"
                                        >
                                            {t('Share Filter')}
                                        </TabBlockBtn>
                                    )}
                                    <CheckPermission>
                                        {activeTab === 1 && (
                                            <MainButton onClick={onAddNewProduct} className="main-btn_primary">
                                                {t("Add New Product")}
                                            </MainButton>
                                        )}
                                    </CheckPermission>
                                </div>
                            {activeTab === 2 && !_.isEmpty(request.filter) && items.length > 0 && (
                                <>
                                    <TabBlockBtn
                                        title="Add Publications to List."
                                        onClick={handleAddSharedProductsPublicationsToList}
                                        className="main-btn_primary main-btn_primary-share-filters add-publication-btn"
                                    >
                                        {t('Add Publications to List')}
                                        <Img img={'icon-list'}></Img>
                                    </TabBlockBtn>
                                    { addSharedProductsPublicationListShowModal ? <ServiceProvider value={myListService}><Modal onRequestClose={handleCloseAddSharedProductsPublicationsToList}>
                                            <AddSharedProductsPublicationListModal filters={request.filter} onClose={handleCloseAddSharedProductsPublicationsToList}/>
                                        </Modal></ServiceProvider>
                                        : <Div />
                                    }
                                </>
                            )}
                            {activeTab === 2 && permissions.includes("product_share_button") && (
                                <TabBlockBtn
                                    title="Invite guest users to view filtered products and approved publications in ComOpps."
                                    onClick={handleOpenModal}
                                    className="main-btn_primary"
                                >
                                    {t('Share Products')}
                                </TabBlockBtn>
                            )}
                            <CheckPermission permission="grade_product_finder_export">
                                {activeTab === 0 && (
                                    <MainButtonLink
                                        className="main-btn_primary"
                                        onClick={(e) => handleButtonClick(e)}
                                    >
                                        {t('Export Products')}
                                    </MainButtonLink>
                                )}
                            </CheckPermission>
                            <CheckPermission permission="product_publication_groups_edit">
                                {activeTab === 4 && (
                                    <TabBlockBtn
                                        title="Add List."
                                        onClick={handleOpenAddPublicationListModal}
                                        className="main-btn_primary"
                                    >
                                        {t('Add List')}
                                    </TabBlockBtn>
                                )}
                            </CheckPermission>
                        </div>
                        <Tabs
                            activeTab={activeTab}
                            onClickTabItem={(index) => {
                                if (activeTab !== index) {
                                    handleReset();
                                    onChangeTab(index);
                                }
                            }}
                        >
                            <TabCheck
                                label={t('SAP Products')}
                                titleMessage="A list of products in SAP with approved publications where available. (Internal access only)"
                                permission="grade_product_finder_view"
                            >
                                <ServiceProvider value={allService}>
                                    <Products />
                                </ServiceProvider>
                            </TabCheck>

                            <TabCheck
                                label={t('My Products')}
                                titleMessage="A user generated list of products (Product owner access only)."
                                permission="grade_product_finder_view"
                            >
                                <ServiceProvider value={selfService}>
                                    <Products />
                                </ServiceProvider>
                            </TabCheck>

                            <TabCheck
                                label={t('Shared Products')}
                                titleMessage="All products with approved publications for sharing."
                                permission="product_publications_guest"
                            >
                                <ServiceProvider value={sharedProductService}>
                                    <SharedProduct/>
                                </ServiceProvider>
                            </TabCheck>

                            <TabCheck
                                label={t('Shared Lists')}
                                titleMessage="Lists shared with me."
                                permission="product_publication_groups_view"
                            >
                                <ServiceProvider value={sharedListService}>
                                    <SharedList/>
                                </ServiceProvider>
                            </TabCheck>
                            <TabCheck
                                label={t('My Lists')}
                                titleMessage="User generated list for sharing."
                                permission="product_publication_groups_edit">
                                <ServiceProvider value={myListService}>
                                    <MyListView/>
                                    {addPublicationListShowModal && (
                                        <Modal onRequestClose={handleCloseModal}>
                                            <AddPublicationListModalForm onChange={onChangeAddPublicationListForm}
                                                                         form={addPublicationListForm}
                                                                         onClose={handleCloseAddPublicationListModal}/>
                                        </Modal>
                                    )}
                                </ServiceProvider>
                            </TabCheck>
                            <TabCheck
                                label={t('User Activity')}
                                titleMessage="User Activity."
                                permission="product_publication_groups_edit"
                            >
                                User Activity
                            </TabCheck>
                        </Tabs>
                    </div>
                </ContentWrapCol>
            </ContentWrap>
        </>
    );
};

Grade.defaultProps = {
    items: []
}

Grade.propTypes = {
    activeTab: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.any),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    showModal: PropTypes.bool.isRequired,
    isFirstLogin: PropTypes.bool.isRequired,
    welcomeGuestShowModal: PropTypes.bool.isRequired,
    shareFiltersShowModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    handleOpenShareFiltersModal: PropTypes.func.isRequired,
    handleCloseShareFiltersModal: PropTypes.func.isRequired,
    handleOpenWelcomeGuestModal: PropTypes.func.isRequired,
    handleCloseWelcomeGuestModal: PropTypes.func.isRequired,
    resetFilterWithoutFetch: PropTypes.func.isRequired,
    getUrlExport: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeFilterForm: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filterForm: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    filterType: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    addSharedProductsPublicationListShowModal: PropTypes.bool.isRequired,
    handleCloseAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    handleAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    addPublicationListShowModal: PropTypes.bool.isRequired,
    handleCloseAddPublicationListModal: PropTypes.func.isRequired,
    handleOpenAddPublicationListModal: PropTypes.func.isRequired,
    addPublicationListForm: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeAddPublicationListForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service }) => {
    const { getStoreItem } = service;
    const activeTab = getStoreItem(state, 'activeTab');
    let filterType;

    if (activeTab === 1) {
        filterType = 9;
    } else if (activeTab === 2) {
        filterType = 5;
    } else {
        filterType = 10
    }

    return {
        activeTab,
        items: getStoreItem(state, 'items'),
        permissions: getStoreItem(state, 'permissions'),
        showModal: getStoreItem(state, 'showModal'),
        welcomeGuestShowModal: getStoreItem(state, 'welcomeGuestShowModal'),
        shareFiltersShowModal: getStoreItem(state, 'shareFiltersShowModal'),
        addPublicationListShowModal: getStoreItem(state, 'addPublicationListShowModal'),
        addSharedProductsPublicationListShowModal: getStoreItem(state, 'addSharedProductsPublicationListShowModal'),
        isFirstLogin: getStoreItem(state, 'isFirstLogin'),
        addPublicationListForm: { ...getStoreItem(state, 'addPublicationListForm', true) },
        filterType,
        form: { ...getStoreItem(state, 'form', true) },
        filterForm: { ...getStoreItem(state, 'filterForm', true) },
        request: {
            filter: {
                ...getStoreItem(state, 'filter', true),
            },
            sort: {
                ...getStoreItem(state, 'sort'),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore('onChangeTab', service, dispatch),
        handleCloseModal: getActionStore('handleCloseModal', service, dispatch),
        handleOpenModal: getActionStore('handleOpenModal', service, dispatch),
        resetFilterWithoutFetch: getActionStore('resetFilterWithoutFetch', service, dispatch),
        handleOpenShareFiltersModal: getActionStore('handleOpenShareFiltersModal', service, dispatch),
        handleCloseShareFiltersModal: getActionStore('handleCloseShareFiltersModal', service, dispatch),
        handleCloseWelcomeGuestModal: getActionStore('handleCloseWelcomeGuestModal', service, dispatch),
        handleOpenWelcomeGuestModal: getActionStore('handleOpenWelcomeGuestModal', service, dispatch),
        handleCloseAddSharedProductsPublicationsToList: getActionStore('handleCloseAddSharedProductsPublicationsToList', service, dispatch),
        handleAddSharedProductsPublicationsToList: getActionStore('handleAddSharedProductsPublicationsToList', service, dispatch),
        handleCloseAddPublicationListModal: getActionStore('handleCloseAddPublicationListModal', service, dispatch),
        handleOpenAddPublicationListModal: getActionStore('handleOpenAddPublicationListModal', service, dispatch),
        onChangeAddPublicationListForm: getActionStore('onChangeAddPublicationListForm', service, dispatch),
        updateForm: getActionStore('updateForm', service, dispatch),
        onChange: getActionStore('onChangeForm', service, dispatch),
        onChangeFilterForm: getActionStore('onChangeFilterForm', service, dispatch),
        getUrlExport: getActionStore('getUrlExport', service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Grade);
