import { request, url } from "../../../../../Services";

const listUrl = "project/application";

const getItems = data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

const requestTable = data => {
    return new Promise(resolve => {
        getItems(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
            });
        });
    });
};

export { requestTable };
