import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import { url } from "Services";
import InfoWrap from "./Views/InfoWrap";
import MainBlock from "./Views/MainBlock";

const Index = ({ id, permissions }) => {
    useEffect(() => {
        if(!permissions.includes('admin_users_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    const onClickEdit = () => {
        url.redirect(`/admin/users/${id}/edit`);
    };

    return (
        <CheckPermission permission="admin_users_crud">
            <ContentWrap>
                <InfoWrap onClickEdit={onClickEdit} />
                <ContentWrapCol>
                    <MainBlock />
                </ContentWrapCol>
            </ContentWrap>
        </CheckPermission>
    );
};

Index.propTypes = {
    id: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        id: state.listState.view.id,
        activeTab: state.listState.activeTab,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
