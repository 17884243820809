import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import SapForm from "./Forms/SapForm";

const propTypes = {};

const SapBlock = () => {
    return (
        <>
            <div className="switcher__wrapper">
                <a
                    href="#"
                    className={`active switcher__item`}
                >
                    SAP
                </a>
            </div>
            <SapForm/>
      </>
    );
}

SapBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    return {}
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SapBlock);


