import React, {useEffect, useState} from 'react';

import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import PropTypes from "prop-types";
import {Button} from "Templates/Default";
import {BlockImgWithCrop, Input, WrapInput} from "Templates/Form";
import {phoneList} from "Services";
import {saveProfile} from "../../../Services/Tabs/PersonalDetails";
import {ComoppsEditor} from "Templates/Input";
import SocialBlock from "./SocialBlock";
import _ from "lodash";

const defaultProps = {
    employer: ''
}

const propTypes = {
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    employer: PropTypes.string,
    email: PropTypes.string.isRequired,
    photo: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
    photoView: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
    phone: PropTypes.shape({code: PropTypes.string, number: PropTypes.string}).isRequired,
    task: PropTypes.string,
}

const PersonalDetails = ({id, photo, photoView, title, firstName, lastName, email, employer, phone, task, socials}) => {
    const [editState, setEditState] = useState(false);
    const [userData, setUserData] = useState({
        id, firstName, lastName, title, employer, email, photo, photoView, phone, task, socials
    });

    useEffect(() => {
        setUserData({id, firstName, lastName, title, employer, email, photo, photoView, code: phone.code, number: phone.number, task, socials});
    }, [id, firstName, lastName, title, employer, email, photo, photoView, phone, task])

    const onChangeUserData = (field, value) => {
        setUserData((prev) => {
            return {...prev, [field]: value}
        })
    }

    const submit = () => {
        saveProfile(userData).then(() => {
            setEditState(false);
        })
    }

    const onChangeSocialsInputs = (value, key) => {
        if (key === 'socials') {
            const newVal = value.filter((item) => item.value)
            onChangeUserData(key, newVal);
            return
        }
        onChangeUserData(key, value);
    };

    const changePhoto = (field, value) => {
        setUserData((prev) => {
            return {...prev, [field]: value}
        })
        setUserData((prev) => {
            return {...prev, ['photoView']: value}
        })
    };

    return (
        <div className="personal_details">
            <div className="personal_details__action">
                {!editState
                    ? <Button onClick={() => setEditState(true)}>Edit</Button>
                    : <Button onClick={submit}>Save Changes</Button>}
            </div>
            <div className="personal_details__main">
                <div className="personal_details__main-wrap">
                    <div className="personal_details__main-info">
                        <h2>General info</h2>
                        <div className="personal_details__main-info-content">
                            <p>
                            <span className="title">
                                First Name
                            </span>
                                {!editState
                                    ? (
                                        <span className="value" title={userData.firstName || '-'}>
                                            {userData.firstName || '-'}
                                        </span>
                                    )
                                    : (
                                        <Input
                                            name="firstName"
                                            value={userData.firstName}
                                            onChange={value => onChangeUserData('firstName', value)}
                                        />
                                    )}
                            </p>
                            <p>
                            <span className="title">
                                Last Name
                            </span>
                                {!editState
                                    ? (
                                        <span className="value" title={userData.lastName || '-'}>
                                            {userData.lastName || '-'}
                                        </span>
                                    )
                                    : (
                                        <Input
                                            name="lastName"
                                            value={userData.lastName}
                                            onChange={value => onChangeUserData('lastName', value)}
                                        />
                                    )}
                            </p>
                            <p>
                            <span className="title">
                                Employer
                            </span>
                                {!editState
                                    ? (
                                        <span className="value" title={userData.employer || '-'}>
                                            {userData.employer || '-'}
                                        </span>
                                    )
                                    : (
                                        <Input
                                            name="employer"
                                            value={userData.employer}
                                            onChange={value => onChangeUserData('employer', value)}
                                        />
                                    )}
                            </p>
                            <p>
                            <span className="title">
                                Title
                            </span>
                                {!editState
                                    ? (
                                        <span className="value" title={userData.title || '-'}>
                                            {userData.title || '-'}
                                        </span>
                                    )
                                    : (
                                        <Input
                                            name="title"
                                            value={userData.title}
                                            onChange={value => onChangeUserData('title', value)}
                                        />
                                    )}
                            </p>
                        </div>
                    </div>
                    <div className={`personal_details__main-photo ${editState ? 'editable' : ''}`}>
                        <h2>Photo</h2>
                        <div className="personal_details__main-info-content">
                            <BlockImgWithCrop
                                name="photo"
                                value={userData.photoView}
                                title=""
                                defaultImg="icon_contact_small"
                                onChange={value => changePhoto("photo", value)}
                                textButton="Upload Photo"
                            />
                        </div>
                    </div>
                </div>
                <div className="personal_details__main-contact">
                    <h2>Contact</h2>
                    <div className="personal_details__main-info-content code">
                        <div className="code">
                        <span className="title">
                            Country Code
                        </span>
                            {!editState ? (
                                <span className="value" title={userData.code || '-'}>
                                    {userData.code || '-'}
                                </span>
                            ) : (
                                <Input
                                    name="phone.code"
                                    type="searchSelect"
                                    value={userData.code}
                                    onChange={value => onChangeUserData("code", value)}
                                    options={phoneList}
                                />
                            )}
                        </div>
                        <div className="number">
                        <span className="title">
                            Phone number
                        </span>
                            {!editState ? (
                                <span className="value" title={userData.number || '-'}>
                                    {userData.number || '-'}
                                </span>
                            ) : (
                                <Input
                                    name="phone.number"
                                    value={userData.number}
                                    onChange={value => onChangeUserData("number", value)}
                                />
                            )}
                        </div>
                        <div className="email">
                        <span className="title">
                            Email
                        </span>
                            {!editState ? (
                                <span className="value" title={userData.email || '-'}>
                                    {userData.email || '-'}
                                </span>
                            ) : (
                                <Input
                                    name=""
                                    type="email"
                                    placeholder="Email"
                                    value={userData.email}
                                    onChange={value => onChangeUserData("email", value)}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="wrapper__form-block-content row digital-wrap">
                    <SocialBlock editState={editState} socials={socials} onChange={onChangeSocialsInputs}/>
                </div>
                <div className="personal_details__main-task">
                    <h2>Task</h2>
                    <div className="personal_details__main-info-content code">
                        {!editState
                            ? (
                                <span className="value" title={userData.task || '-'}>
                                            {userData.task ? <span dangerouslySetInnerHTML={ { __html: userData.task } }></span> : '-'}
                                        </span>
                            )
                            : (
                                <WrapInput label={"Task"} name="task">
                                    <ComoppsEditor text={userData.task}
                                                   onChange={value => onChangeUserData("task", value)}/>
                                </WrapInput>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

PersonalDetails.defaultProps = defaultProps;
PersonalDetails.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;
    return {
        id: getStoreItem(state, "view.id"),
        firstName: getStoreItem(state, "view.firstName"),
        lastName: getStoreItem(state, "view.lastName"),
        title: getStoreItem(state, "view.title"),
        employer: getStoreItem(state, "view.employer"),
        email: getStoreItem(state, "view.email"),
        photo: getStoreItem(state, "view.photo"),
        photoView: getStoreItem(state, "view.photoView"),
        phone: getStoreItem(state, "view.phone"),
        task: getStoreItem(state, "view.task"),
        socials: _.concat(getStoreItem(state, `view.socials`)),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(PersonalDetails);
