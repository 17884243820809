import { combineReducers } from "redux";
import { getMainInitialStates, getMainReducers } from "App/Services";
import { reducer as tableReducer, initState as tableState } from "./TableReducer";
import { reducer as viewReducer, initState as viewState } from "./ViewReducer";

const reducers = [tableReducer, viewReducer];

const mainState = {
    ...tableState,
    ...viewState,
};

const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }

    return state;
};

// Combine all reducers you may have here
export default combineReducers({
    ...getMainReducers,
    listState: reducer,
});

export const initialStates = {
    ...getMainInitialStates,
    mainState,
};
