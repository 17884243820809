import Constants from "../Constants";
import { changeCostData } from "../Services/Tabs/CostModel/Reducer/MathService";

export const initState = {
    cost: {
        salePrice: "",
        rawsTotalCost: "",
        ifThen: {
            grossMargin: 0,
            grossMarginInPercent: 0,
            operatingMargin: 0,
            operatingMarginInPercent: 0,
        },
        salesPrice: {
            actual: {},
            suggested: {},
        },
        line: {},
        raws: [],
    },
};

export const reducer = (state, action) => {
    const { data } = action;
    switch (action.type) {
        case Constants.FETCH_COST_DATA_REQUESTED:
            return { ...state, loading: true };

        case Constants.CHANGE_COST_DATA: {
            return {
                ...state,
                cost: { ...state.cost, ...changeCostData(state.cost, data.value), salePrice: data.value },
            };
        }

        case Constants.FETCH_COST_DATA_SUCCEEDED:
            const newCost = { ...state.cost, ...data.cost };
            return {
                ...state,
                cost: { ...newCost, ...changeCostData(newCost, "0") },
                totalNotes: data.totalNotes || 0,
                totalFiles: data.totalFiles || 0,
                totalProjects: data.totalProjects || 0,
                totalInvoices: data.totalInvoices || 0,
                totalTechnicals: data.totalTechnicals || 0,
                totalProcesses: data.totalProcesses || 0,
                totalDesigns: data.totalDesigns || 0,
                totalSafeties: data.totalSafeties || 0,
                totalBrochures: data.totalBrochures || 0,
                loading: false,
            };

        case Constants.FETCH_COST_DATA_FAILED:
            return { ...state, errors: { ...data }, cost: { ...initState.cost }, loading: false };
        default:
            return state;
    }
};
