import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import CheckboxBlock from "Templates/Form/Checkbox/CheckboxBlock";
import CheckboxButton from "Templates/Form/Checkbox/CheckboxButton";
import CheckboxTitle from "Templates/Form/Checkbox/CheckboxTitle";
import {Block, ButtonRight, Input, WrapInput} from "Templates/Form";
import {LinkIcon} from "Templates/Link";
import BlockSubmit from "Templates/Form/BlockSubmit/BlockSubmit";
import ButtonLeft from "Templates/Form/BlockSubmit/ButtonLeft";
import {uniqueGenerator} from "Services";
import {uniqBy} from "lodash";

const {Button, Form, Title, Col, Row, ColText, ColInput, Textarea} = ModalDefault;

const propTypes = {
    updateGroups: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    saveInvite: PropTypes.func.isRequired,
};

const InviteModalForm = (
    {
        updateGroups,
        groups,
        form,
        saveInvite,
        onClose,
        onChange,
        t,
    }
) => {
    const formValidate = () => {
        const {email} = form;

        return Boolean((email && email.trim() !== '' && validateEmail(email)));
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleSave = () => {
        const array = groups.map((item) => {
            return item.value
        })
        const formData = {...form, lists: array}
        saveInvite(formData).then(
            data => {
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    const isValidForm = () => {
        return groups.every(({value}) => !!value);
    };

    const onAddItem = () => {
        updateGroups([...groups, {id: uniqueGenerator(), value: ''}]);
    };

    const onUpdateItem = (value, index) => {
        const updatedGroupList = groups.map((item, itemIndex) => {
            return itemIndex === index ? {...item, value} : item;
        });
        updateGroups(uniqBy(updatedGroupList, 'value'));
    };

    const onRemoveItem = (index) => {
        const array = groups.filter((_, itemIndex) => itemIndex !== index);
        updateGroups(array);
    };

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose}/>
            <Title>{t('Invite Guest User to Shared List(s)')}</Title>
            <Row>
                <Col>
                    <ColText className="bold-text" isRequired>
                        {t("Email")}
                    </ColText>
                    <ColInput
                        placeholder={t("Enter Email")}
                        name="email"
                        value={form.email || ''}
                        onChange={value => onChangeInput(value, `form.email`)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ColText className="bold-text">
                        {t("Email Message")}
                    </ColText>
                    <Textarea
                        placeholder={t("Enter Text")}
                        onChange={value => onChangeInput(value, `form.invite_text`)}
                    >
                        {form.invite_text || ''}
                    </Textarea>
                </Col>
            </Row>
            <Row>
                <Block>
                    <WrapInput label={t('Select List to share with your user')}
                               isRequired={true} name="list"
                               className={`wrap-input--delete ${groups.length > 1 ? 'with-delete' : ''}`}>
                        {groups.map(({id, value}, index) => (<Fragment key={`Lists-${id}`}>
                            <Input name={`list-${value}`}
                                   type="uniqueListAsyncSelect"
                                   placeholder={t("Select")}
                                   url="product/product-finder/publication-groups/search/groups"
                                   value={value}
                                   valuesList={groups}
                                   onChange={value => onUpdateItem(value, index)}/>
                            {groups.length > 1 ? <LinkIcon
                                className="icon-delete"
                                icon="la-icon-close"
                                onClick={() => onRemoveItem(index)}
                            /> : null}
                        </Fragment>))}
                        <Button disabled={!isValidForm()} onClick={onAddItem}
                                className="size_small main-btn main-btn_white">
                            {t('Add List')}
                        </Button>
                    </WrapInput>
                </Block>
            </Row>
            <Row>
                <Col>
                    <CheckboxBlock>
                        <CheckboxButton
                            htmlFor={`checkbox_notifyCreator`}
                            value={!!form.notify_creator}
                            onChange={value => onChangeInput(value, `form.notify_creator`)}
                        >
                            <CheckboxTitle title="Notify me when the Guest User signs into ComOpps"/>
                        </CheckboxButton>
                    </CheckboxBlock>
                </Col>
            </Row>

            <BlockSubmit>
                <ButtonRight/>
                <ButtonLeft>
                    <Button onClick={handleSave} disabled={!formValidate() || (form.lists_invite && !isValidForm())}>
                        {t("Send Invitation")}
                    </Button>
                </ButtonLeft>
            </BlockSubmit>
        </Form>
    );
}

InviteModalForm.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        groups: getStoreItem(state, "groups"),
        form: getStoreItem(state, "form"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        updateGroups: getActionStore("updateGroups", service, dispatch),
        saveInvite: getActionStore("saveInviteAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(InviteModalForm);
