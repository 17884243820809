import { debounce } from "Services/Debounce";
import Constants from "../Constants";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (
        [
            'owner',
            'slug',
            'datasheets_paintable',
            'processes_paintable',
            'designs_paintable',
            'datasheets_molded',
            'safeties_paintable',
            'processes_molded',
            'designs_molded',
            'safeties_molded',
            'brochures_molded',
            'datasheets_locations',
            'processes_locations',
            'designs_locations',
            'datasheets_manufactures',
            'processes_manufactures',
            'designs_manufactures',
            'safeties_manufactures',
            'brochures_manufactures',
            'datasheets_countries',
            'processes_countries',
            'designs_countries',
            'datasheets_property',
            'datasheets_test_method',
            'datasheets_specimen',
            'datasheets_test_condition',
            'datasheets_si_unit',
            'datasheets_english_unit',
            'processes_manufacturing',
            'processes_parameter',
            'processes_description',
            'processes_si_unit',
            'processes_english_unit',
            'datasheets_colors',
            'processes_colors',
            'designs_colors',
            'safeties_colors',
            'brochures_colors',
            'datasheets_color_codes',
            'processes_color_codes',
            'designs_color_codes',
            'safeties_color_codes',
            'brochures_color_codes',
            'datasheets_sources',
            'processes_sources',
            'designs_sources',
            'safeties_sources',
            'brochures_sources',
            'datasheets_approvals',
            'processes_approvals',
            'designs_approvals',
            'safeties_approvals',
            'brochures_approvals',
        ].includes(key) || /^.*_approved_for_.*/.test(key)
    ) {
        fetchItems(service, dispatch)(request);
        return;
    }

    if (value.length >= 2 || value.length === 0) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const executeExport = (service, dispatch) => (data) => {
    dispatch({ type: Constants.EXPORT_PUBLICATIONS, data });

    const { requestExportSolutions } = service;

    return requestExportSolutions(data).then(
        res => {
            alert('Export Technologies started. Check notifications to download.');
            dispatch({ type: Constants.EXPORT_PUBLICATIONS_SUCCESS, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.EXPORT_PUBLICATIONS_FAILED, data: res });
        },
    );
};

export const resetFilter = (service, dispatch) => (request) => {
    dispatch({ type: Constants.RESET_FILTER, data: {} });
    fetchItems(service, dispatch)(request);
};
