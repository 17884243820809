import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import {url} from "Services";
import Tab from "Templates/Tabs/Tab";
import InfoWrap from "./Views/InfoWrap";

import _technicalGradesService from "./Services/Tabs/Technical";
import _designGradesService from "./Services/Tabs/Design";
import _processingGradesService from "./Services/Tabs/Processing";
import _safetyService from "./Services/Tabs/Safety";
import _brochureService from "./Services/Tabs/Brochure";

import Technical from "./Views/Tabs/Technical";
import Processing from "./Views/Tabs/Processing";
import Design from "./Views/Tabs/Design";
import Safety from "./Views/Tabs/Safety";
import Brochure from "./Views/Tabs/Brochure";
import Notes from "./Views/Tabs/Notes";
import _notesService from "./Services/Tabs/Notes";
import TabCheck from "Templates/Tabs/TabСheck";

const Publication = ({
                         id,
                         activeTab,
                         t,
                         onChangeTab,
                         activeSubTab,
                         permissions,
                         totalTechnicals,
                         totalProcesses,
                         totalDesigns,
                         totalSafeties,
                         totalBrochures,
                         totalNotes
                     }) => {
    const onClickEdit = () => {
        url.redirect(`/admin/publications/${id}/edit`);
    };
    
    const technicalService = _technicalGradesService(id, activeSubTab.id);
    const processingService = _processingGradesService(id, activeSubTab.id);
    const designService = _designGradesService(id, activeSubTab.id);
    const safetyService = _safetyService(id, activeSubTab.id);
    const brochureService = _brochureService(id, activeSubTab.id);
    const notesService = _notesService(id);

    const onChangePublicationTab = (index) => {
        url.remove('subTab');
        url.replaceHistory(index ? {'activeTab': index } : {});
        onChangeTab(index);
    }

    return (
        <ContentWrap>
            <InfoWrap activeTab={activeTab} onClickEdit={onClickEdit} />
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index)=> {
                        if (activeTab !== index) {
                            onChangePublicationTab(index);
                        }
                    }}
                >
                    <Tab label={t("Technical")} counter={totalTechnicals}>
                        <ServiceProvider value={technicalService}>
                            <Technical permissions={permissions} />
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Processing")} counter={totalProcesses}>
                        <ServiceProvider value={processingService}>
                            <Processing permissions={permissions} />
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Guides")} counter={totalDesigns}>
                        <ServiceProvider value={designService}>
                            <Design permissions={permissions} />
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Safety")} counter={totalSafeties}>
                        <ServiceProvider value={safetyService}>
                            <Safety permissions={permissions} />
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Brochure")} counter={totalBrochures}>
                        <ServiceProvider value={brochureService}>
                            <Brochure permissions={permissions} />
                        </ServiceProvider>
                    </Tab>
                    <TabCheck
                        label={t('Notes')}
                        permission="product_publications_notes_view"
                        counter={totalNotes}
                    >
                        <ServiceProvider value={notesService}>
                            <Notes/>
                        </ServiceProvider>
                    </TabCheck>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Publication.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    activeSubTab: PropTypes.shape({id: null}),
    totalTechnicals: PropTypes.number.isRequired,
    totalProcesses: PropTypes.number.isRequired,
    totalDesigns: PropTypes.number.isRequired,
    totalSafeties: PropTypes.number.isRequired,
    totalBrochures: PropTypes.number.isRequired,
    totalNotes: PropTypes.number.isRequired,
};

Publication.defaultProps = {
    activeSubTab: {}
}

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
        activeSubTab: getStoreItem(state, "activeSubTab") || {},
        totalTechnicals: getStoreItem(state, "totalTechnicals"),
        totalProcesses: getStoreItem(state, "totalProcesses"),
        totalDesigns: getStoreItem(state, "totalDesigns"),
        totalSafeties: getStoreItem(state, "totalSafeties"),
        totalBrochures: getStoreItem(state, "totalBrochures"),
        totalNotes: getStoreItem(state, "totalNotes"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Publication);
