import { getStoreItem, getActionStore } from "./StoreService";
import { handleScroll } from "./EventsService";
import { productTypeProps, claassificationProps, groupOptions } from "./EnumService";
import { requestTable, requestData, requestAttach, deleteItem } from "./RequestService";

// export const modifierValues = item => item;

export {
    productTypeProps,
    claassificationProps,
    groupOptions,
    requestAttach,
    deleteItem,
    requestTable,
    requestData,
    handleScroll,
    getStoreItem,
    getActionStore,
};
