import React, { useEffect } from "react";
import { compose } from "redux";
import { useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import { withTagDefaultProps } from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import { url } from "Services";
import { activeWeightUnit } from "App/View/Header/Items/Units/Actions/ConverterUnitsAction";
import InfoWrap from "./Views/InfoWrap";

import _costService from "./Services/Tabs/Plants";
import Plants from "./Views/Tabs/Plants";

import _purchasePricesService from "./Services/Tabs/PurchasePrices";
import PurchasePrices from "./Views/Tabs/PurchasePrices";

// import _productGradesService from "./Services/Tabs/ProductGrades";
// import ProductGrades from "./Views/Tabs/ProductGrades";

const Raw = ({ id, activeTab, t, onChangeTab }) => {
    const dispatch = useDispatch();
    const onClickEdit = () => {
        url.redirect(`/product/raw/${id}/edit`);
    };

    useEffect(() => {
        const indexPlantsTab = 0;

        dispatch(activeWeightUnit(activeTab === indexPlantsTab));
    }, [activeTab]);

    const costService = _costService(id);
    const purchasePricesService = _purchasePricesService(id);
    // const productGradesService = _productGradesService(id);

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit}/>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Plants")}>
                        <ServiceProvider value={costService}>
                            <Plants/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label="Purchase Prices">
                        <ServiceProvider value={purchasePricesService}>
                            <PurchasePrices/>
                        </ServiceProvider>
                    </Tab>

                    {/*<Tab label={t("Grades")}>*/}
                    {/*    <ServiceProvider value={productGradesService}>*/}
                    {/*        <ProductGrades />*/}
                    {/*    </ServiceProvider>*/}
                    {/*</Tab>*/}
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Raw.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Raw);
