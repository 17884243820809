import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import TableBody from "Templates/Table/TableBody";

const ContainerTable = (TableHeadColumn, TableRowColumn) => ({ columns, request, fetchItems }) => {
    const onSubmit = e => {
        e.preventDefault();

        fetchItems(request);
    };

    const onSortClick = column => {
        const asc = "asc";
        const desc = "desc";
        const newRequest = {
            ...request,
            sort: {
                ...request.sort,
                column,
            },
        };
        if (request.sort.column === column) {
            newRequest.sort.direction = request.sort.direction === asc ? desc : asc;
        } else {
            newRequest.sort.direction = asc;
        }

        fetchItems(newRequest);
    };

    return (
        <form onSubmit={onSubmit}>
            <table className="main-table">
                <tbody>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={`TableHeadColumn-${index}`} className="main-table__head">
                                <TableHeadColumn column={column} onSortClick={onSortClick} index={index} />
                            </th>
                        ))}
                    </tr>
                    <TableBody columns={columns} TableRowColumn={TableRowColumn} />
                </tbody>
            </table>
            <button type="submit" className="visually-hidden" />
        </form>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        columns: getStoreItem(state, "columns"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default (TableHeadColumn, TableRowColumn) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(ContainerTable(TableHeadColumn, TableRowColumn));
