import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    status: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, color: PropTypes.string.isRequired }))
        .isRequired,
};

const TableRowItemStatus = ({ pref, status, list, t }) => {
    const { color } = list.find(elem => elem.label === status) || {};

    return (
        <span
            title={t(status)}
            className={`${pref}__table-body-status main-table__body-status status-${color}`}
        >
            {t(status)}
        </span>
    );
};

TableRowItemStatus.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowItemStatus);
