import React, {Fragment, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {countryList} from "Services";
import {datasheetLocation, datasheetPaintable, isMolded} from "Services/enum";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoHeader,
    ContentInfoHeaderWrap,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap
} from "Templates/Content";
import {Tooltip} from "Templates/Form";
import InfoPopup from "Templates/InfoPopup/InfoPopup";

const propTypes = {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    datasheet: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
    activeTab: PropTypes.number.isRequired,
};

const InfoWrap = (
    {
        name,
        slug,
        company,
        datasheet,
        t,
        activeTab,
    }) => {

    const [isShownLocationsContent, setIsShownLocationsContent] = useState(true);
    const [isShownCountryContent, setIsShowCountryContent] = useState(true);

    const paintableView = () => {
        return datasheet.paintable ? datasheetPaintable.find(el => el.value.toString() === datasheet.paintable.toString()).label : '';
    };

    const locationView = (elem) => {
        return elem ? datasheetLocation.find(el => el.value.toString() === elem.toString()).label : '-';
    };

    const countryView = (elem) => {
        return elem ? countryList.find(el => el.value.toString() === elem.toString()).label : '';
    };

    const showContent = (array, toggle, elemView) => {
        if (!toggle) {
            return array.slice(0, 3).map((elem, index) => (
                <ContentInfoBlockText key={index} value={elemView ? elemView(elem) : elem} />
            ));
        }

        return array.map((elem, index) => (
            <ContentInfoBlockText key={index} value={elemView ? elemView(elem) : elem} />
        ));
    };

    const showCountries = (array, toggle, elemView) => {
        const items = array.map((elem) => (
            elemView ? elemView(elem) : elem
        )).sort((a, b) => {
            // Global option should be the first in order
            if (a === 'Global') {
                return -1;
            }

            if (b === 'Global') {
                return 1;
            }

            return a > b ? 1 : -1;
        });

        return showContent(items, toggle)
    };

    const approvalView = (elem) => {
        let response = '-';

        if (elem) {
            if (elem.approval_name && elem.code_name) {
                response = `${elem.approval_name} - ${elem.code_name}`;
            } else if (elem.approval_name) {
                response = elem.approval_name;
            }
        }

        return response;
    };

    const tabCountryLabel = ['', 'Technical Data Sheet', 'Processing Data Sheet', 'Guide', 'Safety Data Sheet', 'Brochure'];

    const showData = () => {
        if (datasheet.paintable
            || datasheet.molded
            || datasheet.colors.length
            || datasheet.locations.length
            || datasheet.countries.length
        ) {
            return (
                <>
                    <ContentInfoBlock show={!!datasheet.countries.length}>
                        <ContentInfoBlockTitle>
                            {t("Countries")}
                            <Tooltip>
                                {`This ${tabCountryLabel[activeTab] || 'Technical Data Sheet'} is applicable to these countries. Verify Compliance.`}
                            </Tooltip>
                        </ContentInfoBlockTitle>
                        {showCountries(datasheet.countries, isShownCountryContent, countryView)}
                        {datasheet.countries.length > 3 && (
                            <button
                                type="button"
                                className={["cost-modal__calculator_btn", isShownCountryContent ? 'open' : ''].join(' ')}
                                onClick={() => setIsShowCountryContent(!isShownCountryContent)}
                            >
                                Show
                                {' '}
                                {isShownCountryContent ? 'Less' : 'More'}
                            </button>
                        )}
                    </ContentInfoBlock>
                </>
            )
        }

        return (
            <>
                <ContentInfoBlock show={!!slug}>
                    <ContentInfoBlockTitle>{t("Publication")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={slug} />
                </ContentInfoBlock>
            </>
        )
    };

    return (
        <ContentInfoWrap>
            <ContentInfoHeaderWrap>
                <ContentInfoTop
                    onClickEdit={null}
                    urlBack="/product/product-finder?activeTab=2"
                />
                <ContentInfoHeader>
                    <ContentInfoTitle>{`${name}`}</ContentInfoTitle>
                    <ContentInfoBlockTitle>{company}</ContentInfoBlockTitle>
                </ContentInfoHeader>
            </ContentInfoHeaderWrap>

            <div className="product__filters">
                {showData()}
            </div>
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "view.name"),
        description: getStoreItem(state, "view.description"),
        slug: getStoreItem(state, "view.slug"),
        company: getStoreItem(state, "view.company"),
        datasheet: getStoreItem(state, "view.datasheet"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
