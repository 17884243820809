import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import PropTypes from "prop-types";
import {Tooltip} from "Templates/Form";
import {onChange} from "../../Services/EventsService";

function Processing({callback, filter}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>Processing</BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    <p href="#" className="main-table__head-link filter-with-tooltip">
                        Manufacturing Process
                        <Tooltip>
                            {`General process type used to manufacture an application.`}
                        </Tooltip>
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/product/product-finder/process-property-list/1"
                        name="process_property"
                        value={filter.process_property}
                        onChange={e => {
                            onChange(e).then(_value => callback('process_property', _value));
                        }}
                    />
                    <p href="#" className="main-table__head-link filter-with-tooltip">
                        Process Parameter
                        <Tooltip>
                            {`Recommended machine settings/conditions utilized to manufacture an application.`}
                        </Tooltip>
                    </p>
                    <InputFilter
                        type="asyncMultiSelect"
                        placeholder="Select"
                        url="/product/product-finder/process-property-list/2"
                        name="process_parameter"
                        value={filter.process_parameter}
                        onChange={e => {
                            onChange(e).then(_value => callback('process_parameter', _value));
                        }}
                    />
                </div>
            )}
        </>
    );
}

Processing.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Processing;