export const listTypes = {
    OVERDUE: 1,
    IN_PROGRESS: 2,
    IN_QUE: 3,
    COMPLETED: 4,
};

export const statusColor = status => {
    switch (+status) {
        case listTypes.OVERDUE:
            return "loss";

        case listTypes.IN_PROGRESS:
            return "opportunity";

        case listTypes.IN_QUE:
            return "out";

        case listTypes.COMPLETED:
            return "current";
        default:
            return "";
    }
};

export const statusLabel = status => {
    switch (+status) {
        case listTypes.OVERDUE:
            return "Overdue";

        case listTypes.IN_PROGRESS:
            return "In progress";

        case listTypes.IN_QUE:
            return "In que";

        case listTypes.COMPLETED:
            return "Completed";

        default:
            return "";
    }
};
