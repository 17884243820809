import React, {Component} from 'react';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Modal} from "Templates/Modals";
import {storageUrl} from "Services";
import PropTypes from "prop-types";
import {InputFilter} from "Templates/Table/Filters";
import {Label} from "Templates/Form";
import UploadFileForm from "./UploadFileForm";
import PdfViewer from "./PdfViewer";
import NotFoundBrochure from "./NotFoundBrochure";
import CopyForm from "../Technical/CopyForm";
import DownloadReport from "../../Modals/DownloadReport";

class Brochure extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            showUploadFileModal: false,
            uploadFileForm: {
                files: [],
                name: '',
            },
        };

        this.state = {
            showUploadFileModal: false,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        };
    }

    componentDidMount() {
        const { fetchItems } = this.props;
        fetchItems({});
    }

    handleCloseUploadModalWithCheck = event => {
        event.preventDefault();

        const { uploadFileForm: { files } } = this.state;
        const { t } = this.props;

        if (files.length >= 1) {
            if (!confirm(t("Are you sure you want to undo the action?"))) {
                return;
            }
        }

        this.closeModal();
    };

    onClickDelete = (item) => () => {
        const { t } = this.props;

        if (confirm(t("Do you want to delete the file?"))) {
            const {
                fetchItems,
                service: { onClickDeleteItem },
            } = this.props;

            onClickDeleteItem(item).then(() => {
                fetchItems({});
            });
        }
    };

    openUploadFileModal = () => {
        this.setState({
            showUploadFileModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            ...this.defaultState,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        });
    };

    callbackAfterRequestInModalWindow = res => {
        const { fetchItems } = this.props;

        if (res !== undefined) {
            this.closeModal();
            fetchItems({});
        }
    };

    handleClickOnUploadFile = () => {
        const { addFile } = this.props;
        const {
            uploadFileForm: { files, name },
        } = this.state;

        addFile({ files, name }).then(this.callbackAfterRequestInModalWindow);
    };

    setFile = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                files: value,
            },
        }));
    };

    setName = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                name: value,
            },
        }));
    };

    onChangeForm = (key, value) => {
        let { form } = this.props;
        const { callback } = this.props;

        form = {
            ...form,
            [key]: value || []
        };

        callback(key, value, form);
    }

    render() {
        const {
            id,
            items,
            fetchSubTabAction,
            activeSubTab,
            storage_url,
            showModal,
            handleCloseModal,
            form
        } = this.props;

        const { showUploadFileModal, uploadFileForm } = this.state;

        return (
            <>
                {showModal && (
                    <Modal onRequestClose={handleCloseModal}>
                        <CopyForm
                            callback={this.onChangeForm}
                            datasheetId={activeSubTab.id}
                            gradeId={id}
                            form={form}
                            onClose={handleCloseModal}
                        />
                    </Modal>
                )}
                {showUploadFileModal ? (
                    <Modal onRequestClose={this.handleCloseUploadModalWithCheck}>
                        <UploadFileForm
                            form={uploadFileForm}
                            onAppend={this.handleClickOnUploadFile}
                            onClose={this.handleCloseUploadModalWithCheck}
                            onChange={this.setFile}
                            onChangeName={this.setName}
                        />
                    </Modal>
                ) : null}

                {items && items.length > 0 && (
                    <div className={'flex'}>
                        <Label label="Select"/>
                        <span className={'publications-counter flex'}>({items.length})</span>
                    </div>
                )}
                <div className="tds-list-wrap">
                    {(items && items.length) ?
                        (
                            <InputFilter
                                type="select"
                                value={activeSubTab.id}
                                options={[...items.map((val) => {
                                    return { value: val.id, label: val.custom_name }
                                })]}
                                onChange={e => {
                                    fetchSubTabAction(items.find(x => x.id === e));
                                }}
                            />
                        ) : <div/>}
                    <DownloadReport label={'Brochure'}/>
                </div>

                {items && items.length > 0
                    ? (
                        <PdfViewer
                            url={activeSubTab && activeSubTab.path ? storageUrl(activeSubTab.path, storage_url) : ''}
                        />
                    )
                    : (
                        <div className="grade-view__wrap main-wrap-block technical-wrap not-available">
                            <NotFoundBrochure/>
                        </div>
                    )}
            </>
        );
    }
}

Brochure.propTypes = {
    id: PropTypes.string.isRequired,
    fetchSubTabAction: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
    activeSubTab: PropTypes.objectOf(PropTypes.any),
    handleCloseModal: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func,
        getStoreItem: PropTypes.func,
        getActionStore: PropTypes.func,
    }).isRequired,
};

Brochure.defaultProps = {
    items: [],
    activeSubTab: {}
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchSubTabAction: getActionStore("addActiveSubTabAction", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
        addFile: getActionStore("createFilesAction", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        callback: getActionStore("onChangeForm", service, dispatch),
    };
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeSubTab: getStoreItem(state, "activeSubTab"),
        items: getStoreItem(state, "items"),
        showModal: getStoreItem(state, "showModal"),
        form: { ...getStoreItem(state, "form", true) },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Brochure);
