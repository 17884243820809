import ReactOnRails from "react-on-rails";
import App from "./App";
import Store from "./Store";

import contactService from "./Services/ContactService";
import companiesService from "./Services/CompaniesService";
import gradesService from "./Services/GradeService";
import plantService from "./Services/PlantService";
import productService from "./Services/ProductService";
import projectService from "./Services/ProjectService";
import rawService from "./Services/RawService";
import taskService from "./Services/TaskService";

ReactOnRails.registerStore({
    searchViewStore: Store,
});

ReactOnRails.register({ SearchContactsView: App("searchViewStore", contactService) });
ReactOnRails.register({ SearchCompaniesView: App("searchViewStore", companiesService) });
ReactOnRails.register({ SearchGradesView: App("searchViewStore", gradesService) });
ReactOnRails.register({ SearchPlantsView: App("searchViewStore", plantService) });
ReactOnRails.register({ SearchProductsView: App("searchViewStore", productService) });
ReactOnRails.register({ SearchProjectsView: App("searchViewStore", projectService) });
ReactOnRails.register({ SearchRawsView: App("searchViewStore", rawService) });
ReactOnRails.register({ SearchTasksView: App("searchViewStore", taskService) });
