import { handleScroll } from "../../EventsService";
import {deleteItem, requestAttach, requestTable} from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { typeList, getTableLabel, getFilter } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default ({
    requestTable: requestTable(`/legal/agreements`),
    requestAttach: requestAttach(`/legal/agreements/add`),
    requestEdit: item => requestAttach(`/legal/agreements/${item.id}/edit`),
    onClickDeleteItem: item => deleteItem(`/legal/agreements/${item.id}/delete`),
    typeList,
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
