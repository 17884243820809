import { getActionStore, getStoreItem } from "./StoreService";

export const getSelectedOption = (dynamicSelect, optionsForSecondSelect) => {
    return (optionsForSecondSelect || []).reduce((acc, elem) => {
        if (elem.value === dynamicSelect) {
            return elem;
        }
        if (elem.options) {
            const result = elem.options.find(option => option.value === dynamicSelect);
            if (typeof result !== "undefined") {
                return result;
            }
        }
        return acc;
    }, {});
};

export default {
    getStoreItem,
    getActionStore,
    getSelectedOption,
};
