import { url, request } from "Services";

export const requestOnUploadFiles = link => data => {
    const formData = new FormData();
    formData.append('parentId', data.parentId);
    for (let i = 0; i < data.files.length; i += 1) {
        formData.append("files[]", data.files[i]);
    }
    const sendObj = {
        url: url.getUrl(link),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};


export const requestOnCreateFolder = link => data =>
    request.sendRequest({
        url: url.getUrl(link),
        data,
        type: "POST",
    });

export const requestOnEditFolder = link  => (id, data) =>
    request.sendRequest({
        url: url.getUrl(`${link}/${id}/edit-folder`),
        data,
        type: "POST",
    });