import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { withTagDefaultProps } from "Hoc/Template";
import Img, { ImgStorage } from "Templates/Img";

const defaultProps = {
    url: "",
    className: "main-square-logo",
};

const propTypes = {
    url: PropTypes.string,
    className: PropTypes.string,
};

const InfoLogo = ({ pref, url, className }) => {
    return (
        <div className={`${pref}__info-logo main-content__info-logo ${className}`}>
            {_.isString(url) && _.trim(url) ? <ImgStorage url={url} /> : <Img img="form-logo-square" />}
        </div>
    );
};

InfoLogo.defaultProps = defaultProps;
InfoLogo.propTypes = propTypes;

export default compose(withTagDefaultProps())(InfoLogo);
