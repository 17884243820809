import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    item: {},
};

const TableBodyColumn = ({ item }) => {
    return (
        <tr>
            <td className="project-expanded-block__table">
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Project Application Code</p>
                    <p className="project-expanded-block__table-value" title={`
                        ${ item.label || '-' } (${ item.industry || '-' })
                    `}>{ item.label || '-' } ({ item.industry || '-' })</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Project Market Segment</p>
                    <p className="project-expanded-block__table-value" title={ item.industry || '-' }>{ item.industry || '-' }</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Project Segment</p>
                    <p className="project-expanded-block__table-value" title={ item.segment || '-' }>{ item.segment || '-' }</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Project Sub-Segment</p>
                    <p className="project-expanded-block__table-value" title={ item.sub_segment_name || '-' }>{ item.sub_segment_name || '-' }</p>
                </div>
            </td>
        </tr>
    );
};

TableBodyColumn.propTypes = propTypes;

TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem, itemsName } = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
