import React from "react";
import { compose } from "redux";
import { url } from "Services";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabView from "./Views/TabView";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
};

const Connection = () => {
    const onClickEdit = item => {
        url.redirect(`/connection/connections/${item.connection_id}/edit`);
    };

    const onClickShow = item => {
        const win = window.open(`/connection/connections/${item.connection_id}`, "_blank");
        win.focus();
    };

    const onClickBudgetEdit = item => {
        url.redirect(`/connection/budgets/${item.budget_id}/edit`);
    };

    const onClickBudgetCustom = item => {
        const win = window.open(`/connection/budgets/${item.budget_id}`, "_blank");
        win.focus();
    };

    return <TabView onClickCustom={onClickShow} onClickEdit={onClickEdit} onClickBudgetCustom={onClickBudgetCustom} onClickBudgetEdit={onClickBudgetEdit} />;
};

Connection.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Connection);
