import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps, LinkPermission} from "Hoc/Template";
import Img from "Templates/Img";

const defaultProps = {
    img: "",
    role: "",
    company: "",
};

const propTypes = {
    label: PropTypes.string.isRequired,
    company: PropTypes.string,
    url: PropTypes.string.isRequired,
    img: PropTypes.string,
    role: PropTypes.string,
};

const NoteTopUser = ({pref, label, company, url, role, img, t}) => (
    <div className={`${pref}__note-user main-content__note-user`}>
        <span className={`${pref}__note-user-title main-content__note-user-title main-circle`}>
            {img ? <img src={img} alt="text"/> : <Img img="contact_with_circle"/>}
        </span>
        <LinkPermission permission="admin" exclude={
            <span className={`${pref}__note-user-link main-content__note-user-link`}>
                {label}
            </span>
        }>
            <a href={url} className={`${pref}__note-user-link main-content__note-user-link`}>
                {label}
            </a>
        </LinkPermission>
        <span className={`${pref}__note-user-status`}>{company ? `| ${company} ` : ""}</span>
        <span className={`${pref}__note-user-status main-content__note-user-status`}>{t("left a note")}</span>
        <div className={`${pref}__note-user-role main-content__note-user-role`}>{role}</div>
    </div>
);

NoteTopUser.defaultProps = defaultProps;
NoteTopUser.propTypes = propTypes;

export default compose(withTagDefaultProps())(NoteTopUser);
