import { request, url, formDataNormalizer } from "Services";

const getItems = (data, listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const getUsersSharedWith = async (id) => {
    const sendObj = {
        url: url.getUrl(`legal/shared-agreements/${id}/already-shared-with-users`),
        type: "GET",
    };

    return request.sendRequestWithNoCache(sendObj, false);
}

export const requestShareAgreements = (data) => {
    const { id, selectedUsers, sharedWithUsersList } = data;
    return request.sendRequest({
        url: url.getUrl(`legal/shared-agreements/${id}/edit`),
        data: { new: selectedUsers.length ? selectedUsers.map((item) => item.value) : [], old: sharedWithUsersList.length ? sharedWithUsersList.map((item) => item.value) : []},
        type: "POST",
    });
}

export const requestAttach = attachUrl => data => {
    const sendObj = {
        url: url.getUrl(attachUrl),
        data: formDataNormalizer(data),
        type: "POST",
        contentType: false,
        processData: false,
    };

    return request.sendRequest(sendObj);
};

export const requestEdit = item => data => {
    const sendObj = {
        url: url.getUrl(`/legal/agreements/${item.id}/edit`),
        data: formDataNormalizer(data),
        type: "POST",
        contentType: false,
        processData: false,
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, listUrl).then(res => {
            resolve({
                items: res[0].items,
                columns: res[0].columns,
                pagination: res[0].pagination,
            });
        }, reject);
    });
};

export const requestData = listUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, listUrl).then(res => {
            resolve(res[0]);
        }, reject);
    });
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};
