import React from "react";
import { getString, numberWithCommas } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import { projectStatus} from "Services/enum";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const tableRowItemLink = (labelKey, href) => (
        <TableRowItemLink title={getString(item, labelKey)} href={href}>
            {getString(item, labelKey)}
        </TableRowItemLink>
    );

    const historyAnalytic = JSON.parse(item.history_analytic || '[]');
    const currentAnalytic = JSON.parse(item.current_analytic || '[]');

    switch (key) {
        case "slug":
            return tableRowItemLink("slug", `/project/projects/${getString(item, "id")}`);

        case "name":
            return tableRowItemLink("name", `/project/projects/${getString(item, "id")}`);

        case "replacement_business_project_id": {
            const replacementBusinessItems = item.replacement_business_projects
            return (
                <>
                    {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                        <TableRowItemLink blank
                                          title={getString(item, key)}
                                          href={`/project/projects/${elem.id}`}
                        >
                            {elem.slug}
                        </TableRowItemLink>
                    )) : '-'}
                </>
            );
        }

        case "replacement_business_project_name": {
            const replacementBusinessItems = item.replacement_business_projects
            return (
                <>
                    {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                        <TableRowItemLink blank
                                          title={getString(item, key)}
                                          href={`/project/projects/${elem.id}`}
                        >
                            {elem.name}
                        </TableRowItemLink>
                    )) : '-'}
                </>
            );
        }

        case "sub_status":
            return <TableRowItemStatus status={getString(item, key)} list={[...projectStatus]}/>;

        case String(key.match(/^volume_.*/)): {
            const keys = key.toLowerCase().split("_");

            return (
                <>
                    <TableRowItemSpan className="text-right">
                        {`${numberWithCommas(getString(currentAnalytic, `${keys[2]}.${keys[1]}.${keys[0]}`), 0)} lb`}
                    </TableRowItemSpan>
                    <TableRowItemSpan className="text-right clr-red">
                        {`${numberWithCommas(getString(historyAnalytic, `${keys[2]}.${keys[1]}.${keys[0]}`), 0)} lb`}
                    </TableRowItemSpan>
                </>
            );
        }

        case String(key.match(/^revenue_.*/)): {
            const keys = key.toLowerCase().split("_");

            return (
                <>
                    <TableRowItemSpan className="text-right">
                        {`$${numberWithCommas(getString(currentAnalytic, `${keys[2]}.${keys[1]}.${keys[0]}`), 0)}`}
                    </TableRowItemSpan>
                    <TableRowItemSpan className="text-right clr-red">
                        {`$${numberWithCommas(getString(historyAnalytic, `${keys[2]}.${keys[1]}.${keys[0]}`), 0)}`}
                    </TableRowItemSpan>
                </>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
