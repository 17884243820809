import _ from "lodash";

export const getTableLabel = (key) => {
    const tableLabel = {
        name: "Product Name",
        description: "Description",
        slug: "Product ID",
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = () => "";

export const isSortEnabled = key => (["name", "slug"].includes(key));
