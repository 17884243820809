import React, { Component } from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Div } from "Templates/Default";
import PropTypes from "prop-types";

const propTypes = {
    solutionName: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
};

class AdditionInfo extends Component {
    render() {
        const { solutionName, company } = this.props;

        return (
            <Div className="datasheet_addition_info">
                <Div>{solutionName}</Div>
                {/*<Div>{company}</Div>*/}
            </Div>
        );
    }
}

AdditionInfo.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        solutionName: getStoreItem(state, "solutionName"),
        company: getStoreItem(state, "company"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(AdditionInfo);
