import React, { Component } from "react";
import PropTypes from "prop-types";
import SidebarContent from "./SidebarContent";
import ItemMenuContent from "./ItemMenuContent";

const propTypes = {
    counter: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    map: PropTypes.shape({
        img: PropTypes.string.isRequired,
        childrens: PropTypes.shape({
            [PropTypes.string]: PropTypes.shape({
                link: PropTypes.shape.isRequired,
                sublabel: PropTypes.string,
            }),
        }),
        options: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    }).isRequired,
};

class DropdownMenu extends Component {
    constructor(props) {
        super(props);
        const { map } = this.props;
        this.state = {
            showItems: Object.values(map.childrens).some(elem => window.location.pathname.startsWith(elem.link)),
        };
    }

    handleClickOnMenuItem = e => {
        e.preventDefault();
        this.setState(prevState => ({
            showItems: !prevState.showItems,
        }));
    };

    render() {
        const { label, map, counter } = this.props;
        const { showItems } = this.state;
        return (
            <li className={`main-sidebar__list-item ${showItems ? map.options.dropdownActiveClass : ""}`}>
                <ItemMenuContent onClick={this.handleClickOnMenuItem} img={map.img} label={label} />
                <ul className={`main-sidebar__list menu_level_${counter}`}>
                    <SidebarContent counter={counter} map={map.childrens} />
                </ul>
            </li>
        );
    }
}

DropdownMenu.propTypes = propTypes;

export default DropdownMenu;
