import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, CheckPermission } from "Hoc/Template";
import {
    MainButtonLink,
    TabBlock,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import { ContainerTable } from "Hoc/Widgets";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const defaultProps = {
    pagination: {
        total: 0,
    },
    currentFY: "",
    revenue: "0",
    volume: "0",
    grossMargin: "0",
};

const propTypes = {
    service: PropTypes.shape({
        onClickEditItem: PropTypes.func.isRequired,
        onClickDeleteItem: PropTypes.func.isRequired,
    }).isRequired,
    currentFY: PropTypes.string,
    revenue: PropTypes.string,
    volume: PropTypes.string,
    grossMargin: PropTypes.string,
    pagination: PropTypes.shape({
        total: PropTypes.number,
    }),
};

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const Project = ({ currentFY, revenue, volume, grossMargin, pagination: { total }, t, service }) => {
    const { onClickEditItem, onClickDeleteItem } = service;

    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (confirm(t("Do you want to remove the Project?"))) {
            onClickDeleteItem(item).then(
                () => {},
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        onClickEditItem(item);
    };

    return (
        <>
            <TabWrapTop>
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{`${numberWithCommas(total, 0)} ${t("Projects")}`}</TabBlockColTitleH4>
                    </TabBlockCol>
                </TabBlock>

                <CheckPermission permission="project_crud">
                    <MainButtonLink href="/project/projects/create" className="main-btn_primary">
                        {t("Add New Project")}
                    </MainButtonLink>
                </CheckPermission>
            </TabWrapTop>
            <TabViewDetail onClickDelete={onClickDelete} onClickEdit={onClickEdit} />
        </>
    );
};

Project.propTypes = propTypes;
Project.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
    const { getStoreItem } = props.service;

    return {
        currentFY: getStoreItem(state, "financialYear"),
        pagination: getStoreItem(state, "pagination"),
        revenue: getStoreItem(state, "totalAnalytic.revenue"),
        volume: getStoreItem(state, "totalAnalytic.volume"),
        grossMargin: getStoreItem(state, "totalAnalytic.gross_margin"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Project);
