const Constants = {

    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",

    CREATE_FILES_REQUEST: "CREATE_FILES_REQUEST",
    CREATE_FILES_SUCCESS: "CREATE_FILES_SUCCESS",
    CREATE_FILES_FAILED: "CREATE_FILES_FAILED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    FETCH_COST_DATA_REQUESTED: "FETCH_COST_DATA_REQUESTED",
    FETCH_COST_DATA_SUCCEEDED: "FETCH_COST_DATA_SUCCEEDED",
    FETCH_COST_DATA_FAILED: "FETCH_COST_DATA_FAILED",

    CHANGE_COST_DATA: "CHANGE_COST_DATA",

    CLOSE_MODAL: "CLOSE_MODAL",
    OPEN_MODAL: "OPEN_MODAL",
    CHANGE_SELECT: "CHANGE_SELECT",
    SAVE_COPY_SUCCESS: "SAVE_COPY_SUCCESS",
    SAVE_COPY_FAILED: "SAVE_COPY_FAILED",

    EDIT_PRODUCT: "EDIT_PRODUCT",
    RESET_FORM: "RESET_FORM",

    /* analytics tab */
    CHANGE_FORM: "CHANGE_FORM",

    CHART_DATA_REQUESTED: "CHART_DATA_REQUESTED",
    CHART_DATA_SUCCEEDED: "CHART_DATA_SUCCEEDED",
    CHART_DATA_FAILED: "CHART_DATA_FAILED",

    HEADER_DATA_REQUESTED: "HEADER_DATA_REQUESTED",
    HEADER_DATA_SUCCEEDED: "HEADER_DATA_SUCCEEDED",
    HEADER_DATA_FAILED: "HEADER_DATA_FAILED",

    RESET_ANALYTICS: "RESET_ANALYTICS",
    /* analytics tab */

    /* notes tab */
    EDIT_NOTE_REQUESTED: "EDIT_NOTE_REQUESTED",
    EDIT_NOTE_SUCCEEDED: "EDIT_NOTE_SUCCEEDED",
    EDIT_NOTE_FAILED: "EDIT_NOTE_FAILED",
    DELETE_NOTE_REQUESTED: "DELETE_NOTE_REQUESTED",
    DELETE_NOTE_SUCCEEDED: "DELETE_NOTE_SUCCEEDED",
    DELETE_NOTE_FAILED: "DELETE_NOTE_FAILED",
    /* notes tab */

    /* technical tab */
    CHANGE_ACTIVE_SUBTAB: "CHANGE_ACTIVE_SUBTAB",
    CHANGE_ACTIVE_SAFETY_SUBTAB: "CHANGE_ACTIVE_SAFETY_SUBTAB",
    CHANGE_ACTIVE_BROCHURE_SUBTAB: "CHANGE_ACTIVE_BROCHURE_SUBTAB",
    FETCH_TECHNICAL_ITEMS_REQUESTED: "FETCH_TECHNICAL_ITEMS_REQUESTED",
    FETCH_SAFETY_ITEMS_REQUESTED: "FETCH_SAFETY_ITEMS_REQUESTED",
    FETCH_BROCHURE_ITEMS_REQUESTED: "FETCH_BROCHURE_ITEMS_REQUESTED",
};
export default Constants;
