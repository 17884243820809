import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";

import _myPypelineService from "./Services/Tabs/MyPypeline";
import Page from "./Views/Templates/Page";

import _сompanyPipelineService from "./Services/Tabs/CompanyPipeline";

const Pipeline = ({ currentFY, activeTab, companyName, permissions, t, onChangeTab }) => {
    const сompanyPipelineService = _сompanyPipelineService();
    const myPypelineService = _myPypelineService();

    const tab = () => {
        const myPipeline = (
            <ServiceProvider value={myPypelineService}>
                <Page />
            </ServiceProvider>
        );
        if (!permissions.includes("project_companies_team_view")) {
            return myPipeline;
        }
        return (<Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("My Pipeline")}>{myPipeline}</Tab>

                    <Tab label={companyName}>
                        <ServiceProvider value={сompanyPipelineService}>
                            <Page />
                        </ServiceProvider>
                    </Tab>
                </Tabs>);
    };
    return (
        <ContentWrap>
            <span className="pipeline__fy">{`FY ${currentFY}`}</span>
            <ContentWrapCol>{tab()}</ContentWrapCol>
        </ContentWrap>
    );
};

Pipeline.defaultProps = {
    currentFY: "",
};

Pipeline.propTypes = {
    currentFY: PropTypes.string,
    activeTab: PropTypes.number.isRequired,
    companyName: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        activeTab: getStoreItem(state, "activeTab"),
        companyName: getStoreItem(state, "companyName"),
        currentFY: getStoreItem(state, "financialYear"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Pipeline);

