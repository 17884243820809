import { getStoreItem as _getStoreItem } from "Services";
import { handleScroll } from "../EventsService";
import { requestTable } from "../RequestService";
import { getFilter } from "../TableHeaderService";
import { listUrl, tableLabel, placeholders, getViewItem } from "./TableConfig";
import { getActionStore, getTableLabel } from "../StoreService";
import { reducerKey as ModalReducerKey } from "../../../Reducers/Form/Modal";

const modifierValues = items => items;

export default storeName => ({
    getTableLabel: getTableLabel(tableLabel),
    getViewItem,
    getFilter: getFilter(placeholders),
    requestTable: requestTable(listUrl),
    handleScroll,
    modifierValues,
    getActionStore,
    getStoreItem: _getStoreItem(`${storeName}.${ModalReducerKey}`),
});
