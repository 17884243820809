import {request, url} from "Services";

const groupListLink = '/product/product-finder/publication-groups/shared/list';

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

const get = requestUrl => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        type: "GET",
    };

    return request.sendRequest(sendObj, false);
};

export const requestGroupList = () => {
    return new Promise((resolve, reject) => {
        get(groupListLink).then(res => {
            resolve(res[0]);
        }, reject);
    });
};

export const requestTable = listUrl => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                contacts: res[0].contacts || [],
                owner: res[0].owner || {},
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
        });
    });
};
