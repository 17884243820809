import { request, url } from "Services";

const listUrl = "files";

const getItems = (urlString, data) =>
    request.sendRequest({
        url: url.getUrl(`${urlString}`),
        data,
        type: "GET",
    });

export const requestTable = urlString => data =>
    new Promise(resolve => {
        getItems(urlString, { ...data }).then(res =>
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
            }),
        );
    });

export const requestOnCreateFolder = ({ id, name }) =>
    request.sendRequest({
        url: url.getUrl(`${listUrl}/${id}/create-folder`),
        data: { name },
        type: "POST",
    });

export const requestOnEditFolder = ({ id, name }) =>
    request.sendRequest({
        url: url.getUrl(`${listUrl}/${id}/edit-folder`),
        data: { name },
        type: "POST",
    });

export const requestOnDeleteItem = data => {
    const sendObj = {
        url: url.getUrl(`${listUrl}/${data}/delete`),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export const requestOnUploadFiles = ({ id, files }) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
        formData.append("files[]", files[i]);
    }
    const sendObj = {
        url: url.getUrl(`${listUrl}/${id}/add`),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};
