import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import reducers, {initialStates} from "../Reducers";

export default function configureStore(props) {
    const { items, pagination, columns, view, FY, help, financialYear } = props;
    const { mainState } = initialStates;

    mainState.form.periodFrom = FY;
    mainState.form.periodTo = FY;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "analytics-graphs-v2-view" }),
        listState: { ...mainState, items, pagination, columns, view, help, financialYear },
    };

    return _configureStore(initialState, reducers);
}
