import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowItemEdit from "./TableRowItemEdit";

const propTypes = {
    onClickEdit: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

const TableRowColumnButton = ({ index, onClickEdit }) => {
    const _onClickEdit = () => {
        onClickEdit(index);
    };

    return <TableRowItemEdit onClickEdit={_onClickEdit} />;
};

TableRowColumnButton.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowColumnButton);
