import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {Input, WrapInput} from "Templates/Form";
import {LinkIcon} from "Templates/Link";
import {uniqueGenerator} from "Services";
import {uniqBy} from "lodash";

const {Button, Form, Title} = ModalDefault;

const propTypes = {
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    onClose: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    updateGroups: PropTypes.func.isRequired,
    savePublications: PropTypes.func.isRequired,
};

const NEW_LIST = 'new_list';
const EXISTING_LIST = 'existing_list';

const ModalActions = ({modalType, t, newListName, groups, onSetModal, onSave}) => {
    return (
        <div className="add-shared-publications-modal__actions">
            {!modalType && (
                <>
                    <Button onClick={() => {
                        onSetModal(NEW_LIST)
                    }}
                    >
                        {t(`Add to New List`)}
                    </Button>
                    <Button onClick={() => {
                        onSetModal(EXISTING_LIST)
                    }}
                    >
                        {t(`Add to Existing List`)}
                    </Button>
                </>
            )}
            {modalType && modalType.type === NEW_LIST && (
                <>
                    <Button onClick={() => {
                        onSetModal(EXISTING_LIST)
                    }}
                    >
                        {t(`Add to Existing List`)}
                    </Button>
                    <Button disabled={newListName === ''} onClick={onSave}>
                        {t(`Save`)}
                    </Button>
                </>
            )}
            {modalType && modalType.type === EXISTING_LIST && (
                <>
                    <Button onClick={() => {
                        onSetModal(NEW_LIST)
                    }}
                    >
                        {t(`Add to New List`)}
                    </Button>
                    <Button disabled={!!groups.some((item) => item.value === '' || item.value === '-')} onClick={onSave}>
                        {t(`Save`)}
                    </Button>
                </>
            )}
        </div>
    )
}

const AddSharedProductsPublicationListModal = ({
                                                   filters,
                                                   onClose,
                                                   t,
                                                   groups,
                                                   updateGroups,
                                                   savePublications
                                               }) => {
    const [modalType, setModalType] = useState(null);
    const [newListName, setNewListName] = useState('')

    const [selectedOptions, setSelectedOptions] = useState(0);
    const [hideAddMoreButton, setHideAddMoreButton] = useState(false);

    useEffect(() => {
        setHideAddMoreButton(selectedOptions === groups.length)
    }, [selectedOptions, groups.length])

    const setModal = (type) => {
        switch (type) {
            case NEW_LIST:
                setModalType({
                    title: 'Add Filtered Publications to New List',
                    type
                });
                updateGroups([{id: uniqueGenerator(), value: ''}]);
                break
            case EXISTING_LIST:
                setModalType({
                    title: 'Add Filtered Publications to Existing List',
                    type
                });
                setNewListName('');
                break
            default:
                setModalType(null)
                updateGroups([{id: uniqueGenerator(), value: ''}]);
                setNewListName('');
        }
    }

    const isValidForm = () => {
        return groups.every(({value}) => !!value);
    };

    const onAddItem = () => {
        updateGroups([...groups, {id: uniqueGenerator(), value: ''}]);
    };

    const onUpdateItem = (value, index) => {
        const updatedGroupList = groups.map((item, itemIndex) => {
            return itemIndex === index ? {...item, value} : item;
        });
        updateGroups(uniqBy(updatedGroupList, 'value'));
    };

    const onRemoveItem = (index) => {
        const array = groups.filter((_, itemIndex) => itemIndex !== index);
        updateGroups(array);
    };

    const onSave = (payload) => {
        savePublications(payload).then(() => {
            alert('Congratulations! Your Publication list saved successfully.');
        }).catch(() => {
            alert('Error! Something went wrong!');
        });
    }

    const handleSave = () => {
        if (modalType.type === NEW_LIST) {
            const payload = { newListName, filters }
            onSave(payload)
        }

        if (modalType.type === EXISTING_LIST) {
            const payload = { groups, filters }
            onSave(payload)
        }
    }

    return (
        <Form className="add-shared-publications-modal">
            <ButtonClose
                className="main-modal__form-close"
                onClick={() => {
                    updateGroups([{id: uniqueGenerator(), value: ''}]);
                    setNewListName('');
                    onClose(true);
                }}
            />
            <Title>{modalType ? t(`${modalType.title}`) : t(`Add Filtered Publications to Existing or New List`)}</Title>
            {modalType && modalType.type === NEW_LIST && (
                <>
                    <WrapInput isRequired label={t("New List Name")} name={`newLists`}>
                        <Input
                            placeholder="Publication List Name"
                            value={newListName}
                            onChange={(e) => {
                                setNewListName(e);
                            }}
                        />
                    </WrapInput>
                </>
            )}
            {modalType && modalType.type === EXISTING_LIST && (
                <WrapInput
                    label={t('Lists')}
                    name="list"
                    isRequired
                    className={`wrap-input--delete ${groups.length > 1 ? 'with-delete' : ''}`}
                >
                    {groups.map(({id, value}, index) => (
                        <Fragment key={`Lists-${id}`}>
                            <Input
                                name={`list-${value}`}
                                type="uniqueListAsyncSelect"
                                placeholder={t("Select")}
                                url="product/product-finder/publication-groups/search/groups"
                                value={value}
                                valuesList={groups}
                                handleSelectedOptionsLength={(val) => {
                                    setSelectedOptions(val)
                                }}
                                onChange={e => onUpdateItem(e, index)}
                            />
                            {groups.length > 1 ? (
                                <LinkIcon
                                    className="icon-delete"
                                    icon="la-icon-close"
                                    onClick={() => onRemoveItem(index)}
                                />
                            ) : null}
                        </Fragment>
                    ))}
                    {!hideAddMoreButton ? (
                        <Button
                            disabled={!isValidForm()}
                            onClick={onAddItem}
                            className="size_small main-btn main-btn_white"
                        >
                            {t('Add List')}
                        </Button>
                    ) : <div/>}
                </WrapInput>
            )}
            <ModalActions
                modalType={modalType}
                t={t}
                newListName={newListName}
                groups={groups}
                onSetModal={setModal}
                onSave={handleSave}
            />
        </Form>
    );
}

AddSharedProductsPublicationListModal.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        groups: getStoreItem(state, "groups")
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        updateGroups: getActionStore("updateGroups", service, dispatch),
        savePublications: getActionStore("saveNewPublicationsList", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddSharedProductsPublicationListModal);
