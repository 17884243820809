import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import {ChartActions, ChartActions_2, TabActions} from "../Actions";
import {resetForm} from "../Actions/FormActions";

const getStoreItem = _getStoreItem("listState");
const getChartDataLevel_1 = store => getStoreItem(store, "level_1.dashboard.graph");
const getChartDataLevel_2 = store => getStoreItem(store, "level_2.dashboard.graph");

const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...ChartActions_2,
        ...ChartActions,
        ...TabActions,
        resetForm,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export const getSelectedOption = (dynamicSelect, optionsForSecondSelect) => {
    return (optionsForSecondSelect || []).reduce((acc, elem) => {
        if (elem.value === dynamicSelect) {
            return elem;
        }
        if (elem.options) {
            const result = elem.options.find(option => option.value === dynamicSelect);
            if (typeof result !== "undefined") {
                return result;
            }
        }
        return acc;
    }, {});
};

export default () => ({
    getStoreItem,
    getChartDataLevel_1,
    getChartDataLevel_2,
    getSelectedOption,
    getActionStore
});
