import React from "react";
import _ from "lodash";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabView from "./Views/TabView";
import { listTypes } from "./Services";

const File = ({ fetchItems, request, t, deleteItem }) => {
    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t(`Do you want to delete the ${_.get(listTypes, `[${item.type}].label`) || ""}?`))) {
            deleteItem(item.id).then(() => {
                fetchItems(request);
            });
        }
    };

    return <TabView onClickDelete={onClickDelete} />;
};

File.propTypes = {
    fetchItems: PropTypes.func.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    deleteItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service }) => {
    const { getStoreItem } = service;
    return {
        id: getStoreItem(state, "view.id"),
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        deleteItem: getActionStore("deleteTableItemAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(File);
