import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable, requestAttach } from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues, tableButtonsPermission } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/product/plants/${id}/lines`),
    requestEdit: item => requestAttach(`/product/plants/${id}/lines/${item.id}/edit`),
    onClickDeleteItem: item => deleteItem(`/product/plants/${id}/lines/${item.id}/delete`),
    getViewItem,
    getFilter,
    modifierValues,
    tableButtonsPermission,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
