import React, { useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { ServiceProvider, withServiceConsumer } from 'Services/Context';
import { ContentWrap, ContentWrapCol } from 'Templates/Content';
import {CheckPermission, PermissionsProps, withTagDefaultProps} from 'Hoc/Template';
import Tabs from 'Templates/Tabs/Tabs';
import Tab from 'Templates/Tabs/Tab';
import { url } from 'Services';
import InfoWrap from './Views/InfoWrap';
import _userService from './Services/Tabs/User';
import Users from './Views/Tabs/Users';
import SharingPublications from "./Views/Tabs/SharingPublications";

const Company = ({ id, activeTab, t, onChangeTab, permissions }) => {
    const onClickEdit = () => {
        url.redirect(`/admin/companies/${ id }/edit`);
    };

    useEffect(() => {
        if(!permissions.includes('admin_organizations_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    const userService = _userService(id);

    return (
        <CheckPermission permission="admin_organizations_crud">
            <ContentWrap>
            <InfoWrap onClickEdit={ onClickEdit }/>
            <ContentWrapCol>
                <Tabs
                    activeTab={ activeTab }
                    onClickTabItem={ index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    } }
                >
                    <Tab label={ t('Users') }>
                        <ServiceProvider value={ userService }>
                            <Users/>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={ t('Sharing Publications') }>
                        <SharingPublications />
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
        </CheckPermission>
    );
};

Company.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, 'view.id'),
        activeTab: getStoreItem(state, 'activeTab'),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore('onChangeTab', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Company);
