import {
    ADMIN_LISTS_CRUD_PERMISSIONS, COMPANIES_VIEW_PERMISSIONS,
    CONNECTION_VIEW_PERMISSIONS, CONTACTS_VIEW_PERMISSIONS,
    FINANCE_VIEW_PERMISSIONS,
    FORECAST_ALL_UNITS_ACCESS_PERMISSIONS,
    FORECAST_GRAPH_VIEW_PERMISSIONS,
    FORECAST_INVOICES_VIEW_PERMISSIONS,
    GRADE_PRODUCT_FILES_PERMISSIONS,
    GRADE_PRODUCT_FINDER_VIEW_PERMISSIONS,
    IHV_LVP_ACCESS_PERMISSIONS, PLANT_RAW_VIEW_PERMISSIONS,
    PRODUCT_NOTE_VIEW_PERMISSIONS,
    PRODUCT_PUBLICATIONS_GUESTS_PERMISSIONS,
    PRODUCT_PUBLICATIONS_VIEW_PERMISSIONS,
    PRODUCT_SHARED_COMPARE_ACCESS_PERMISSIONS, PRODUCT_SOLUTIONS_VIEW_PERMISSIONS,
    PROJECT_CRUD_PERMISSIONS, VENDOR_VIEWS_PERMISSIONS
} from "./PartialsPermissions";

const ALL_PERMISSIONS = [
    //Sitewide
    "revenue_gross_operating_view",
    "volume_view",
    //Admin
    "admin",
    "admin_users_crud",
    "admin_organizations_crud",
    "admin_ihs_crud",
    ...ADMIN_LISTS_CRUD_PERMISSIONS,
    "admin_tds_data",
    "admin_solutions_data",
    ...PRODUCT_PUBLICATIONS_VIEW_PERMISSIONS,
    "admin_help-pages_crud",
    "admin_deleted-page_crud",
    "admin_system-page_crud",
    "admin_templates-page_crud",
    "admin_alerts_crud",
    //Product Finder
    "grade_product_crud",
    ...GRADE_PRODUCT_FINDER_VIEW_PERMISSIONS,
    ...PRODUCT_NOTE_VIEW_PERMISSIONS,
    ...GRADE_PRODUCT_FILES_PERMISSIONS,
    'product_publication_groups_edit',
    'product_publication_groups_view',
    'product_grade_approval_view',
    ...PRODUCT_PUBLICATIONS_GUESTS_PERMISSIONS,
    ...PRODUCT_SHARED_COMPARE_ACCESS_PERMISSIONS,
    //Analytics
    "sale_level_1",
    "sale_level_2",
    ...FORECAST_GRAPH_VIEW_PERMISSIONS,
    "graphs_v2",
    "business_level_1",
    "business_level_2",
    //Forecast
    ...PROJECT_CRUD_PERMISSIONS,
    ...IHV_LVP_ACCESS_PERMISSIONS,
    ...FORECAST_ALL_UNITS_ACCESS_PERMISSIONS,
    //Finance
    ...FINANCE_VIEW_PERMISSIONS,
    ...FORECAST_INVOICES_VIEW_PERMISSIONS,
    ...CONNECTION_VIEW_PERMISSIONS,
    //Production
    ...PLANT_RAW_VIEW_PERMISSIONS,
    //Companies
    ...COMPANIES_VIEW_PERMISSIONS,
    //Vendors
    ...VENDOR_VIEWS_PERMISSIONS,
    //Contacts
    ...CONTACTS_VIEW_PERMISSIONS,
    "contacts_list_hide",
    //Files
    "file_general",
    //Legal
    "legal_agreements_view",
    "legal_copy_view",
    "legal_iso_view",
    //Profile
    "profile_general",
    "profile_guests",
    //Video
    "video_guides_crud",
    "video_guides_view",
    //Solutions (Technologies)
    'product_solutions',
    'product_solutions_compare_access',
    ...PRODUCT_SOLUTIONS_VIEW_PERMISSIONS,
];

export default ALL_PERMISSIONS;