import React from "react";
import { getString, formatDate } from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import _ from "lodash";
import {historyInteractions, historyInteractionTypes} from "Services/enum";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "created_at":
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );

        case "interaction":
            const interaction = historyInteractions.find(el => el.value === getString(item, key))

            return <TableRowItemSpan title={getString(interaction, 'label')}>{getString(interaction, 'label')}</TableRowItemSpan>;

        case "type":
            const type = historyInteractionTypes.find(el => el.value === getString(item, key))

            return <TableRowItemSpan title={getString(type, 'label')}>{getString(type, 'label')}</TableRowItemSpan>;

        case "-1":
            return null;

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
