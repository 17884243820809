import * as main from "../../index";
import { getActionStore } from './StoreService';

const modifierValues = item => item;

export default id => ({
    ...main,
    modifierValues,
    getActionStore,
    requestTable: main.requestTable(`/project/projects/${id}/notes`),
    requestAttach: main.requestAttach(`/project/projects/${id}/notes/add`),
    editNotesRequest: itemId => main.requestAttach(`/project/projects/${id}/notes/${itemId}/edit`),
    deleteNotesRequest: itemId => main.deleteItem(`/project/projects/${id}/notes/${itemId}/delete`)
});
