import React, {Component} from "react";
import {Modal} from "Templates/Modals";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas, url} from "Services";
import {Title, Total, WrapButtons} from "Templates/Titles";
import {MainButtonLink, TabBlockBtn} from "Templates/Content";
import Tabs from "Templates/Tabs/Tabs";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {PermissionsProps, withTagDefaultProps, CheckPermission} from "Hoc/Template";
import ShowPermission from "Hoc/Template/ShowPermission";
import TabCheck from "Templates/Tabs/TabСheck";
import allService from "./Services/Tabs/All";
import forecastService from "./Services/Tabs/Forecast";
import quarterlyService from "./Services/Tabs/Quarterly";
import yearlyService from "./Services/Tabs/Yearly";
import monthlyService from "./Services/Tabs/Monthly";
import TabView from "./Views/TabView";
import TableSettingsView from "./Views/TableSettings/TableSettingsView";
import CustomerTableSettingsView from "./Views/TableSettings/CustomerTableSettingsView";
import ResetFiltersView from "./Views/ResetFiltersView";

class Finance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSettingModal: false,
        };
    }

    openSettingModal = () => {
        this.setState({
            showSettingModal: true,
        });
    };

    handleSaveModalWithCheck = (columnList, defaultColumns, pinnedColumns, idTemplate, noReload) => {
        const {
            service: {requestChangeSettings},
            request,
            fetchAllItems,
        } = this.props;

        requestChangeSettings({'templateId': idTemplate, 'columns': columnList, 'defaultColumns': defaultColumns, 'pinnedColumns': pinnedColumns}).then(
            () => {
                if (noReload) return
                fetchAllItems(request)
            },
            errors => {
                alert(errors.detail);
            },
        );

        if (!noReload) {
            this.closeModal();
        }
    };

    handleSaveModalCustomerWithCheck = (columnList, defaultColumns, pinnedColumns, idTemplate, noReload) => {
        const {
            service: {requestChangeCustomerSettings},
            activeTab,
            request,
            fetchYearlyItems,
            fetchQuarterlyItems,
            fetchMonthlyItems,
            fetchForecastItems,
        } = this.props;

        requestChangeCustomerSettings({'templateId': idTemplate, 'columns': columnList, 'defaultColumns': defaultColumns, 'pinnedColumns': pinnedColumns}).then(
            () => {
                if (noReload) return
                switch (activeTab) {
                    case 1:
                        fetchQuarterlyItems(request)
                        return;
                    case 2:
                        fetchMonthlyItems(request)
                        return;
                    case 3:
                        fetchForecastItems(request)
                        return;
                    default:
                        fetchYearlyItems(request);
                }
            },
            errors => {
                alert(errors.detail);
            },
        );

        if (!noReload) {
            this.closeModal();
        }
    };

    handleCloseUploadModalWithCheck = event => {
        event.preventDefault();
        this.closeModal();
    };

    closeModal = () => {
        this.setState({
            showSettingModal: false
        });
    };

    getExportLink = (type) => {
        switch (type) {
            case 0:
                return "/api/finance/finance-customers/export/yearly";
            case 1:
                return "/api/finance/finance-customers/export/quarterly";
            case 2:
                return "/api/finance/finance-customers/export/monthly";
            case 3:
                return "/api/finance/finance-customers/export";
            default:
                return "/api/finance/finances/export";
        }
    };

    render() {
        const {
            request,
            title,
            help,
            columns,
            activeColumns,
            activeTab,
            onChangeTab,
            activeWeightUnit,
            getUrlExport,
            isActiveConvertor,
            t
        } = this.props;
        const {showSettingModal} = this.state;

        const handleButtonClick = (e) => {
            e.preventDefault();
            getUrlExport(url.getUrl(this.getExportLink(activeTab), request, ""));
        }

        if (typeof isActiveConvertor === "undefined") {
            activeWeightUnit([0, 1, 2, 3, 4].includes(activeTab));
        }

        const getService = (activeTab) => {
            switch (activeTab) {
                case 1:
                    return quarterlyService;
                case 2:
                    return monthlyService;
                case 3:
                    return forecastService;
                case 4:
                    return allService;
                default:
                    return yearlyService;
            }
        };

        return (
            <div className="budgets-wrap">
                <ShowPermission checkPermission={['finance_view']}>
                {showSettingModal ? (
                    <Modal onRequestClose={this.handleCloseUploadModalWithCheck}>
                        {activeTab === 4 ? (
                            <TableSettingsView
                                columns={columns}
                                activeColumns={activeColumns}
                                onAppend={this.handleSaveModalWithCheck}
                                onClose={this.closeModal}
                            />
                        ) : (
                            <CustomerTableSettingsView
                                columns={columns}
                                activeColumns={activeColumns}
                                onAppend={this.handleSaveModalCustomerWithCheck}
                                onClose={this.closeModal}
                            />
                        )}
                    </Modal>
                ) : <div/>}

                <Title
                    title={t("Budget & Sales")}
                    help={help}
                    total={() => <Total>{`${numberWithCommas(title)} ${t("Total")}`}</Total>}
                >
                    <WrapButtons>
                        {
                            [0, 1, 2, 3, 4].includes(activeTab) &&
                            (
                                <>
                                    <ServiceProvider value={getService(activeTab)}>
                                        <ResetFiltersView/>
                                    </ServiceProvider>

                                    <TabBlockBtn onClick={this.openSettingModal} className="main-btn_white">
                                        {t("Edit Column")}
                                    </TabBlockBtn>

                                    <CheckPermission permission="finance_view_export">
                                        <MainButtonLink
                                            className="main-btn_white"
                                            onClick={(e) => handleButtonClick(e)}
                                        >
                                            {t("Export Forecast")}
                                        </MainButtonLink>
                                    </CheckPermission>
                                </>
                            )
                        }
                    </WrapButtons>
                </Title>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                            activeWeightUnit([0, 1, 2, 3, 4].includes(index));
                        }
                    }}
                >
                    <TabCheck label={t("Yearly")} permission='finance_view'>
                        <ServiceProvider value={yearlyService}>
                            <TabView forecast/>
                        </ServiceProvider>
                    </TabCheck>
                    <TabCheck label={t("Quarterly")} permission='finance_view'>
                        <ServiceProvider value={quarterlyService}>
                            <TabView forecast/>
                        </ServiceProvider>
                    </TabCheck>
                    <TabCheck label={t("Monthly")} permission='finance_view'>
                        <ServiceProvider value={monthlyService}>
                            <TabView forecast/>
                        </ServiceProvider>
                    </TabCheck>
                    <TabCheck label={t("Forecast")} permission='finance_view'>
                        <ServiceProvider value={forecastService}>
                            <TabView forecast/>
                        </ServiceProvider>
                    </TabCheck>
                    <TabCheck label={t("Details")} permission='finance_view'>
                        <ServiceProvider value={allService}>
                            <TabView/>
                        </ServiceProvider>
                    </TabCheck>
                </Tabs>
            </ShowPermission>
            </div>
        );
    }
}

Finance.defaultProps = {
    help: ''
}
Finance.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeTab: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    onChangeTab: PropTypes.func.isRequired
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {

    return {
        columns: getStoreItem(state, "columns"),
        activeColumns: getStoreItem(state, "activeColumns"),
        isActiveConvertor: getStoreItem(state, "isActive"),
        title: getStoreItem(state, "pagination.total"),
        help: getStoreItem(state, "help"),
        activeTab: getStoreItem(state, "activeTab"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchYearlyItems: getActionStore("fetchItems", yearlyService, dispatch),
        fetchQuarterlyItems: getActionStore("fetchItems", quarterlyService, dispatch),
        fetchMonthlyItems: getActionStore("fetchItems", monthlyService, dispatch),
        fetchForecastItems: getActionStore("fetchItems", forecastService, dispatch),
        fetchAllItems: getActionStore("fetchItems", allService, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        getUrlExport: getActionStore("getUrlExport", service, dispatch),
        activeWeightUnit: getActionStore("activeWeightUnit", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Finance);
