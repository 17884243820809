import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ServiceProvider } from "Services/Context";
import AuthorizedUserWrapper from "App/View/Content/AuthorizedUserWrapper";

const propTypes = {
    userId: PropTypes.string.isRequired,
    service: PropTypes.objectOf(PropTypes.any).isRequired,
};

const AuthWrapper = ({ userId, service, children }) => {
    if (userId) {
        return <AuthorizedUserWrapper service={service}>{children}</AuthorizedUserWrapper>;
    }
    return <ServiceProvider value={service}>{children}</ServiceProvider>;
};

AuthWrapper.propTypes = propTypes;

const mapStateToProps = state => ({
    userId: state.userState.id,
});

export default connect(mapStateToProps)(AuthWrapper);
