import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {ButtonLeft, ButtonRight, BlockSubmit} from "Templates/Form";

const {Button, Form, Title} = ModalDefault;

const propTypes = {
    titleText: PropTypes.string.isRequired,
    buttonEditText: PropTypes.string.isRequired,
    buttonCreateNewText: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onCreateNew: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

class ReplaceContentTemplateModal extends Component {
    handleClickOnEditButton = () => {
        const {onEdit, onClose} = this.props;
        onEdit().then(
            data => {
                onClose('showReplaceTemplateModal')();
            },
            errors => {
                alert(errors.detail);
            }
        );
    };

    handleClickOnCreateNewButton = () => {
        const {onCreateNew, onClose} = this.props;

        onClose('showReplaceTemplateModal')();
        onCreateNew('showCreateTemplateModal')();
    };

    render() {
        const {
            titleText,
            buttonEditText,
            buttonCreateNewText,
            t,
            onClose,
        } = this.props;

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose('showReplaceTemplateModal')}/>
                <Title>{t(titleText)}</Title>
                <div>{t('Do you want replace current template or save as new?')}</div>
                <BlockSubmit>
                    <ButtonRight>
                        <Button onClick={this.handleClickOnEditButton}>
                            {t(buttonEditText)}
                        </Button>
                    </ButtonRight>
                    <ButtonLeft>
                        <Button onClick={this.handleClickOnCreateNewButton}>
                            {t(buttonCreateNewText)}
                        </Button>
                    </ButtonLeft>
                </BlockSubmit>
            </Form>
        );
    }
}

ReplaceContentTemplateModal.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(ReplaceContentTemplateModal);
