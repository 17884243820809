import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: "",
};

const Div = ({ children, className }) => <div className={className}>{children}</div>;

Div.propTypes = propTypes;
Div.defaultProps = defaultProps;

export default Div;
