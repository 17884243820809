import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { TableActions, TabActions } from "../../../Actions";
import * as General from "../../../Actions/Tabs/GeneralAction";

export const getStoreItem = _getStoreItem("listState");

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableActions,
        ...TabActions,
        ...General,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
