import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    width: PropTypes.number.isRequired,
};

const TablLine = ({ pref, width, children }) => {
    return (
        <div className={`${pref}__table-line`} style={{ width: `${width * 100}%` }}>
            {children}
        </div>
    );
};

TablLine.propTypes = propTypes;

export default compose(withTagDefaultProps())(TablLine);
