import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form, BlockTitle } from "Templates/Form";
import AddressBlock from "./AddressBlock";
import GeneralBlock from "./GeneralBlock";

const defaultProps = {
    edit: false,
};

const propTypes = {
    edit: PropTypes.bool,
};

const FormBlock = ({ edit, t }) => {
    return (
        <Form>
            <GeneralBlock edit={edit} />
            <BlockTitle>{t("Address")}</BlockTitle>
            <AddressBlock name="physical" />
        </Form>
    );
};

FormBlock.defaultProps = defaultProps;
FormBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        logo: getStoreItem(state, "logo"),
        email: getStoreItem(state, "email"),
        url: getStoreItem(state, "url"),
        code: getStoreItem(state, "code"),
        number: getStoreItem(state, "number"),
        validate: getStoreItem(state, "form_validate"),
        // errors: getStoreItem(state, "errors"),
        formData: state.createState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(FormBlock);
