import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import reducers, {initialStates} from "../Reducers";
import {initState} from "../../../../Company/View/Reducers/ViewReducer";
import _ from "lodash";
import {filter} from "Services";

export default function configureStore(props) {
    const { items, pinned_columns, total_analytics, pagination, columns, financialYear, help } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "projects" }),
        listState: {
            ...mainState,
            items,
            pinned_columns,
            total_analytics,
            pagination,
            columns,
            financialYear,
            help,
            filter: filter.getParsedFilters(['status']),
            sort: filter.getSort()
        },
    };

    if (initState.activeTab) {
        _.unset(initialState.listState, "items");
    }

    return _configureStore(initialState, reducers);
}
