import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const {
        id,
        name,
        description,
        iso,
        manufacturer,
        vendor_code,
        distributor,
        distributor_vendor_code,
        material_type,
        lead_time,
        standard_packaging,
        delivery_terms,
        freight_class,
        note,
        plants,
        periods
    } = props;

    const initData = {
        id,
        name,
        description,
        iso,
        manufacturer,
        vendor_code,
        distributor,
        distributor_vendor_code,
        material_type,
        lead_time,
        standard_packaging,
        delivery_terms,
        freight_class,
        note,
        plants,
        periods
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "raw-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
