import React from "react";
import _ from "lodash";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ContainerTable } from "Hoc/Widgets";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import { TableRowColumnWrap } from "./index";


const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
        handleScroll: PropTypes.func.isRequired,
    }).isRequired,
    fetchPage: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func,
};

const defaultProps = {
    onClickCustom: () => {},
};

const TabViewIndex = ({
    onClickEdit,
    onClickDelete,
    onClickCustom,
    pref,
    items,
    pagination,
    fetchPage,
    request,
    service,
}) => {
    const { page, pages } = pagination;

    const { handleScroll } = service;

    const onScroll = e => {
        if (page >= pages) {
            return;
        }

        handleScroll(e).then(() => {
            request.page = page + 1;

            fetchPage(request);
        });
    };

    const _onClickEdit = e => {
        const item = _.get(items, e);
        if (item) {
            onClickEdit(item);
        }
    };

    const _onClickDelete = e => {
        const item = _.get(items, e);
        if (item) {
            onClickDelete(item);
        }
    };

    const _onClickCustom = e => {
        const item = _.get(items, e);
        if (item) {
            onClickCustom(item);
        }
    };

    return (
        <div className={`${pref}__wrap-body main-wrap-block__body`} onScroll={onScroll}>
            <TabTable
                onClickCustom={_onClickCustom}
                onClickEdit={_onClickEdit}
                onClickDelete={_onClickDelete}
            />
        </div>
    );
};

TabViewIndex.propTypes = propTypes;
TabViewIndex.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        items: getStoreItem(state, "items"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabViewIndex);
