import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Row } from "Templates/Form";
import { Modal } from "Templates/Modals";
import SessionsModal from "./Views/SessionsModal";
import TwoFactorModal from "./Views/TwoFactorModal";
import Constants from "./Constants";
import Img from "Templates/Img/Img";

const propTypes = {
    lastAction: PropTypes.string.isRequired,
    csrf_token: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    twoFactor: PropTypes.bool.isRequired,
    sessions: PropTypes.arrayOf(
        PropTypes.shape({
            session_id: PropTypes.string.isRequired,
        }),
    ).isRequired,
    submitForm: PropTypes.func.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

class Login extends Component {
    state = {
        showSessionsModal: false,
        showTwoFactorModal: false,
        email: "",
        password: "",
        emailError: "",
        emailRegexError: "",
        passwordError: "",
        passwordLengthError: ""
    };

    componentDidUpdate(prevProps) {
        const { lastAction, sessions, twoFactor } = this.props;

        if (prevProps.twoFactor !== twoFactor && twoFactor) {
            this.setState({
                showTwoFactorModal: true,
            });
        }

        if (prevProps.sessions !== sessions && sessions.length && !twoFactor) {
            this.setState({
                showSessionsModal: true,
            });
        }

        if (lastAction !== prevProps.lastAction && lastAction === Constants.CLOSE_SESSION_MODAL) {
            this.setState({
                password: "",
                showSessionsModal: false,
                showTwoFactorModal: false,
            });
        }

        if (lastAction !== prevProps.lastAction && lastAction === Constants.CLOSE_TWO_FACTOR_MODAL) {
            this.setState({
                password: "",
                showTwoFactorModal: false,
                showSessionsModal: false,
            });
        }
    }

    handleChangeString = ({ target }) => {
        this.setState({
            [target.name]: target.value,
        });
    };

    // handleClickBool = ({ target }) => {
    //     this.setState({
    //         [target.name]: target.checked,
    //     });
    // };

    closeModal = () => {
        this.setState({
            showSessionsModal: false,
            showTwoFactorModal: false,
        });
    };

    checkForm = () => {
        const { email, password } = this.state;
        const minPasswordLength = 6;
        return !(email && password.length >= minPasswordLength);
    };

    onSubmit = e => {
        e.preventDefault();
        const { csrf_token, submitForm, redirect_path } = this.props;
        const {
            email,
            password,
            emailError,
            emailRegexError,
            passwordError,
            passwordLengthError
        } = this.state;

        if (emailError || emailRegexError || passwordError || passwordLengthError) return

        submitForm({ email, password, _csrf_token: csrf_token, redirect_path });
    };

    render() {
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

        const {
            showSessionsModal,
            showTwoFactorModal,
            email,
            password,
            emailError,
            emailRegexError,
            passwordError,
            passwordLengthError } = this.state;
        const { pref, error, t } = this.props;
        const getCurrentYear = new Date().getFullYear();

        const validate = () => {
            this.setState({
                emailError: !email ? 'Email can\'t be blank' : '',
                emailRegexError: !emailRegex.test(email) && email ? 'Invalid email format' : '',
                passwordError: !password ? 'Password can\'t be blank' : '',
                passwordLengthError: password.length < 6 && password ? 'Password should contain at least 6 characters' : ''
            })
        }

        const cleanErrors = (field) => {
            if (field === 'email') {
                this.setState({
                    emailError: '',
                    emailRegexError: ''
                })
            }
            if (field === 'password') {
                this.setState({
                    passwordError: '',
                    passwordLengthError: ''
                })
            }
        }

        return (
            <>
                {showSessionsModal && (
                    <Modal onRequestClose={this.closeModal}>
                        <SessionsModal email={email} />
                    </Modal>
                )}
                {showTwoFactorModal && (
                    <Modal onRequestClose={this.closeModal}>
                        <TwoFactorModal email={email} error={error} />
                    </Modal>
                )}
                <div className={`${pref}__body`}>
                    <div className={`${pref}-logo__wrap`}>
                        <div className={`${pref}__logo`}>
                            <a href="/" className={`${pref}__logo-link`}>
                                <Img img="new_logo" alt="logo" />
                                <span className={`${pref}__logo-link-text`}>Data You Can Trust </span>
                            </a>
                        </div>
                    </div>
                    <div className={`${pref}__wrap`}>
                        <form onSubmit={this.onSubmit} className={`${pref}__form`} noValidate>
                            <h2 className={`${pref}__title`}>
                                <span>
                                   <Img img="icon_lock"/>
                                </span>
                                {t('SECURE LOGIN')}
                            </h2>
                            <Row>
                                <label htmlFor="email" className={`${pref}__form-label`}>
                                    <input
                                        required
                                        id="email"
                                        type="email"
                                        name="email"
                                        value={email}
                                        className={`${pref}__form-input ${emailError || emailRegexError ? 'error': ''}`}
                                        placeholder="Email"
                                        onChange={(e) => {
                                            this.handleChangeString(e);
                                            cleanErrors('email')
                                        }}
                                    />
                                    {emailError ? <span className="error">{emailError}</span> : null}
                                    {emailRegexError ? <span className="error">{emailRegexError}</span> : null}
                                </label>
                            </Row>
                            <Row>
                                <label htmlFor="password" className={`${pref}__form-label`}>
                                    <input
                                        required
                                        id="password"
                                        type="password"
                                        name="password"
                                        value={password}
                                        className={`${pref}__form-input ${passwordError || passwordLengthError ? 'error': ''}`}
                                        placeholder="Password"
                                        onChange={(e) => {
                                            this.handleChangeString(e);
                                            cleanErrors('password')
                                        }}
                                    />
                                    {passwordError ? <span className="error">{passwordError}</span> : null}
                                    {passwordLengthError ? <span className="error">{passwordLengthError}</span> : null}
                                </label>
                            </Row>
                            <div className={`${pref}__footer`}>
                                <a href="/forgot" className={`${pref}__link`}>
                                    {t('Forgot Password?')}
                                </a>
                            </div>
                            <Row>
                                <button
                                    type="submit"
                                    onClick={validate}
                                    className={`${pref}__btn`}
                                >
                                    {t('LOGIN')}
                                </button>
                            </Row>
                            <div className={`${pref}__footer policy`}>
                                <a href="https://www.comopps.com/privacy-policy" className={`${pref}__link`} target="_blank" rel="noopener noreferrer">{t('Privacy Policy')}</a>
                            </div>
                            {error && <div className={`${pref}__error`}>{error}</div>}
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

Login.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        lastAction: getStoreItem(state, "lastAction"),
        csrf_token: getStoreItem(state, "csrf_token"),
        redirect_path: getStoreItem(state, "redirect_path"),
        sessions: getStoreItem(state, "sessions"),
        twoFactor: getStoreItem(state, "twoFactor"),
        error: getStoreItem(state, "error"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Login);
