import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    href: PropTypes.string.isRequired,
};

const CardBodyLink = ({ href, pref, children }) => {
    return (
        <a href={href} className={`${pref}__card-body-link`}>
            {children}
        </a>
    );
};

CardBodyLink.propTypes = propTypes;

export default compose(withTagDefaultProps())(CardBodyLink);
