import React from "react";
import { formatDate, getString, numberWithCommas } from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import _ from "lodash";

export const modifierValues = items => items;
export const tableButtonsPermission = "plant_raw_crud";

export const getViewItem = (key, item) => {

    switch (key) {
        case "created_date":
        case "date_update":
            const date = _.get(item, key) !== null ? formatDate(_.get(item, key)) : '-';

            return (
                <TableRowItemSpan title={date}>{date}</TableRowItemSpan>
            );

        case "last_price":
        case "prior_price":
            return (
                <TableRowItemSpan className="text-right" title={`${numberWithCommas(getString(item, key), 3)}`}>
                    {`${numberWithCommas(getString(item, key), 3)}`}
                </TableRowItemSpan>
            );

        case "change_price":
            const value = numberWithCommas(getString(item, key), 3);
            if (value === 0 || value === '0') {
                return (
                    <TableRowItemSpan className="text-right" title={`${value}`}>{`${value}`}</TableRowItemSpan>
                );
            }
            let className = '';
            let iconClass = '';
            if (value > 0) {
                className = 'clr-red text-right';
            } else {
                className = 'clr-green text-right';
                iconClass = 'rotated';
            }
            return (
                <TableRowItemSpan title={`${value}`} className={className}>
                    {value > 0 ? `+${value}` : value}
                    <span className={`statistic-icon ${iconClass}`}>
                        <i className={`fas fa-sort-up ${className}`} />
                    </span>
                </TableRowItemSpan>
            );

        case "marked_deletion":
        case "is_obsolete":
        case "sap_value":
            return <TableRowItemSpan>{getString(item, key) ? 'YES' : 'NO'}</TableRowItemSpan>;

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
