import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {
    className: "",
};

const TabWrapTop = ({ pref, children, className }) => (
    <div className={`${pref}__wrap main-wrap-block main-content__wrap-top ${className ? `${className}` : ""}`}>{children}</div>
);

TabWrapTop.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TabWrapTop);
