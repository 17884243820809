import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    icon: PropTypes.any,
    className: PropTypes.string,
    blank: PropTypes.bool,
    url: PropTypes.string,
    onClick: PropTypes.func,
    onHandleClick: PropTypes.func,
};

const defaultProps = {
    icon: "la-plus",
    className: "",
    blank: false,
    url: "#",
    onClick: () => {},
    onHandleClick: () => {},
};

const LinkIcon = ({ blank, className, icon, pref, url, children, onClick, onHandleClick }) => {
    const _onClick = e => {
        onHandleClick(e);
        if (url === "#") {
            e.preventDefault();
            onClick();
        }
    };

    return (
        <a className={`${pref}__form-block-link main-content__form-block-link ${className ? `${className}` : ''}`} href={url} onClick={_onClick} target={blank ? "_blank" : "_self"}>
            <i className={`la ${icon}`} />
            {children}
        </a>
    );
};

LinkIcon.propTypes = propTypes;
LinkIcon.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(LinkIcon);
