import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Analytics = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };

    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_sale_level_1-${index}`}
                    value={getPermission("sale_level_1", index)}
                    onChange={
                        value => onChangePermission("sale_level_1", value, index)
                    }
                >
                    <CheckboxTitle title="Dashboard - Sales - Level 1"/>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_sale_level_2-${index}`}
                    value={getPermission("sale_level_2", index)}
                    onChange={
                        value => onChangePermission("sale_level_2", value, index)
                    }
                >
                    <CheckboxTitle title="Dashboard - Sales - Level 2"/>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxHierarchy activeHierarchy={getPermission("forecast_graph_view", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_graph_view-${index}`}
                        value={getPermission("forecast_graph_view", index)}
                        onChange={
                            value => onChangePermission("forecast_graph_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Graphs" />
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_people_analytics_view-${index}`}
                        value={getPermission("forecast_people_analytics_view", index)}
                        onChange={
                            value => onChangePermission("forecast_people_analytics_view", value, index)
                        }
                    >
                        <CheckboxTitle title="View - People tab" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to the People tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_project_analytics_view-${index}`}
                        value={getPermission("forecast_project_analytics_view", index)}
                        onChange={
                            value => onChangePermission("forecast_project_analytics_view", value, index)
                        }
                    >
                        <CheckboxTitle title="View - Project tab" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Projects tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={hasAccess()}
                    htmlFor={`checkbox_graphs_v2-${index}`}
                    value={getPermission("graphs_v2", index)}
                    onChange={
                        value => onChangePermission("graphs_v2", value, index)
                    }
                >
                    <CheckboxTitle title="My Analytics" />
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_business_level_1-${index}`}
                    value={getPermission("business_level_1", index)}
                    onChange={
                        value => onChangePermission("business_level_1", value, index)
                    }
                >
                    <CheckboxTitle title="Dashboard - Biz Dev - Level 1"/>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_business_level_2-${index}`}
                    value={getPermission("business_level_2", index)}
                    onChange={
                        value => onChangePermission("business_level_2", value, index)
                    }
                >
                    <CheckboxTitle title="Dashboard - Biz Dev - Level 2"/>
                </CheckboxButton>
            </CheckboxBlock>
        </>
    )
}

Analytics.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Analytics);