import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {weightUnitName} from "Services/weightUnitName";
import {numberWithCommas} from "Services";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    item: {},
};

const TableBodyColumn = ({item, weightUnit}) => {
    return (
        <tr>
            <td className="projects-view__new-top__analitycs">
                <div className="projects-view__new-top__analitycs-block rev">
                    <p>Revenue</p>
                    <span title={`
                        $${numberWithCommas(item.revenue, 0)}
                    `}>
                        $
                        {numberWithCommas(item.revenue, 0)}
                    </span>
                </div>
                <div className="projects-view__new-top__analitycs-block vol">
                    <p>Volume</p>
                    <span title={`
                        ${numberWithCommas(item.volume, 0)} 
                        ${weightUnitName(weightUnit && weightUnit.weightUnit)}
                    `}>
                        {numberWithCommas(item.volume, 0)}
                        {' '}
                        {weightUnitName(weightUnit && weightUnit.weightUnit)}
                    </span>
                </div>
            </td>
        </tr>
    );
};

TableBodyColumn.propTypes = propTypes;

TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
