import React from "react";
import main from "../index";
import {getActionStore, getChartData, getStoreItem} from "./StoreService";

export default () => ({
    ...main,
    getStoreItem,
    getChartData,
    getActionStore,
});
