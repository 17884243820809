import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { TabBlock, TabBlockCol, TabBlockColTitle, TabBlockColTitleH4, TabWrapTop, TabWrap } from "Templates/Content";
import { CustomSimpleColumnTable } from "Hoc/Widgets/Table/SimpleColumnTableContainer";
import MonthTableBodyColumn from "./Table/MonthTableBodyColumn";
import YearTableBodyColumn from "./Table/YearTableBodyColumn";

const MonthTable = CustomSimpleColumnTable(MonthTableBodyColumn);
const YearTable = CustomSimpleColumnTable(YearTableBodyColumn);

const defaultProps = {
    total: 0,
};

const propTypes = {
    total: PropTypes.number,
    getData: PropTypes.func.isRequired,
};

class ForecastBuilds extends Component {
    componentDidMount() {
        const { getData } = this.props;
        getData();
    }

    render() {
        const { pref, total, t } = this.props;
        return (
            <>
                <TabWrapTop>
                    <TabBlock>
                        <TabBlockCol>
                            <TabBlockColTitle>{t("Total Forecast Units")}</TabBlockColTitle>
                            <TabBlockColTitleH4>{numberWithCommas(total, 0)}</TabBlockColTitleH4>
                        </TabBlockCol>
                    </TabBlock>
                </TabWrapTop>
                <TabWrap>
                    <div className={`${pref}__wrap-overflow`}>
                        <MonthTable />
                        <YearTable />
                    </div>
                </TabWrap>
            </>
        );
    }
}

ForecastBuilds.defaultProps = defaultProps;
ForecastBuilds.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        total: getStoreItem(state, "items.total"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        getData: getActionStore("getDataAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ForecastBuilds);
