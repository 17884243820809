import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

// import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
// import ContainerTable from "./Table/ContainerTable";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { ContainerTable } from "Hoc/Widgets";
import TableHeadColumnWrap from "./Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumn";

const TableStructure = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);

const TabViewDetail = customTable(TableStructure);

const propTypes = {
    service: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
};

// const TableStructure = ContainerTable();
// const Table = customTable(TableStructure);

const MainBlock = ({ pref, service: { title }, t }) => {
    return (
        <>
            <div className={`${pref}__title`}>{t(title)}</div>
            <TabViewDetail />
        </>
    );
};

MainBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(MainBlock);
