import React from "react";
import {TabWrap} from "Templates/Content";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import HierarchyTableHeadColumnWrap from "./HierarchyTableHeadColumnWrap";
import {HierarchyTableRowColumnWrap} from "./HierarchyTableRowColumnWrap";
import HierarchyContainerTable from "./HierarchyContainerTable";
import {hierarchyCustomTable} from "./HierarchyTabViewDetail";

const DefaultTabTable = HierarchyContainerTable(HierarchyTableHeadColumnWrap, HierarchyTableRowColumnWrap);
const TabViewHierarchy = hierarchyCustomTable(DefaultTabTable);

const propTypes = {
    addHierarchy: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};
const defaultProps = {
    addHierarchy: () => {}
}
const Hierarchy = ({ onClickEdit, onClickDelete, onClickCustom, addHierarchy }) => {
    const _addHierarchy = e => {
        addHierarchy(e);
    };
    return <TabWrap>
        <TabViewHierarchy
            customIcon="icon_external_link"
            addHierarchy={_addHierarchy}
            onClickCustom={onClickCustom}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
        />
    </TabWrap>
}

Hierarchy.propTypes = propTypes;
Hierarchy.defaultProps = defaultProps;

const mapStateToProps = state => {
    return {

    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Hierarchy);