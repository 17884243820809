import { request, url } from "Services";

const save = (data, requestUrl) => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

const requestCreate = requestUrl => data =>
    new Promise((resolve, reject) => {
        save(data, requestUrl).then(res => resolve({ ...res[0] }), error => reject(error));
    });

export { requestCreate };
