import Constants from "../Constants";

export const openModal = (service, dispatch) => () => {
    dispatch({type: Constants.OPEN_PUBLICATIONS_MODAL});
};

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });
const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: sort || {} }));
        },
        () => {
            dispatch(requestFailed({ items: [] }));
        },
    );
};

export const closeModal = (service, dispatch) => () => {
    dispatch({type: Constants.CLOSE_PUBLICATIONS_MODAL});
};

export const updateForm = (service, dispatch) => data => {
    dispatch({type: Constants.UPDATE_PUBLICATIONS_FORM, data});
};

export const saveItems = (service, dispatch) => data => {
    dispatch({type: Constants.SAVE_PUBLICATIONS_REQUESTED});
    const {FormService} = service;
    const payload = FormService.publicationsFormData(data);
    service.saveItems(payload).then(response => {
        dispatch({type: Constants.SAVE_PUBLICATIONS_SUCCEEDED});
        closeModal(service, dispatch)();
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.SAVE_PUBLICATIONS_REJECTED, data: errors});
    });
};

export const getItems = (service, dispatch) => (payload) => {
    dispatch({type: Constants.GET_PUBLICATIONS_REQUESTED});
    service.getItems(payload).then(response => {
        const [data] = response;
        const type = payload && payload.page ?
            Constants.GET_NEXT_PAGE_PUBLICATIONS_SUCCEEDED :
            Constants.GET_PUBLICATIONS_SUCCEEDED;
        dispatch({type, data});
    }, errors => {
        dispatch({type: Constants.GET_PUBLICATIONS_REJECTED, data: errors});
    })
};

export const deleteItem = (service, dispatch) => (data) => {
    dispatch({type: Constants.DELETE_PUBLICATIONS_REQUESTED});
    service.deleteItem(data).then(response => {
        dispatch({type: Constants.DELETE_PUBLICATIONS_SUCCEEDED});
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.DELETE_PUBLICATIONS_REJECTED, data: errors});
    });
};

export const onChange = (service, dispatch) => _ => {};