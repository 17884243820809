import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../EventsService";

const placeholders = {
    name: "Plant Name",
    address: "Address",
    revenue: "Revenue",
    volume: "Volume",
    gross_margin: "Gross Margin",
};

export const getFilter = (key, value, callback, request) => {
    switch (key) {
        case "revenue":
        case "volume":
        case "gross_margin": {
            return (
                <InputFilter
                    disabled
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value=""
                    onChange={() => {}}
                />
            );
        }
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
