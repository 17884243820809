import { debounce } from "Services/Debounce";
import Constants from "../Constants";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => (data, id) => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data, id).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: sort || {} }));
        },
        () => {
            dispatch(requestFailed({ items: [] }));
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (["sap_value", "date_update", "sop", "eop", "los_date", "created_at", "updated_at", "sub_status_changed_at", "move_to_current", "end_date", "sap_plant", "sap_line"].includes(key)) {
        fetchItems(service, dispatch)(request);
        return;
    }

    if (
        [
            "slug",
            "name",
            "publications",
            "classification",
            "submitted_date",
            "products",
            "grades",
            "material_numbers",
            "product_codes",
            "product_application_codes",
            "internal_team",
            "plants",
            "lines",
            "brands",
            "nameplates",
            "sub_status",
            "application_code",
            "sub_segment",
            "application",
            "industry",
            "customer",
            "customer_code",
            "tier1",
            "tier2",
            "molder",
            "status",
            "manager",
            "grade_is_sap",
            "company_is_sap",
            "customer_nickname",
            "tier1_nickname",
            "tier2_nickname",
            "molder_nickname",
            "product_type",
            "owner",
            "manufacturer",
            "platform",
            "program",
            "city",
            "country",
            "region",
            "files_count",
            "production_plant",
            "modified",
            "grade_sub_status",
            "customer_duns_number",
            "note",
            "customer_owner",
            "stage_gate",
            "unit"
        ].includes(key)
    ) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        () => {
            dispatch(requestFailed({ items: [] }));
        },
    );
};
