import { debounce } from "Services/Debounce";
import Constants from "../Constants";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const getOriginalUrlExport = (service, dispatch) => (data) => {
    const { requestOriginalExportVehicles } = service;

    return requestOriginalExportVehicles(data);
};

export const getUrlExport = (service, dispatch) => (data) => {
    dispatch({type: Constants.EXPORT_VEHICLES, data});

    const { requestExportVehicles } = service;

    return requestExportVehicles(data).then(
        res => {
            alert('Export IHS Forecast started. Check notifications to download.');
            dispatch({ type: Constants.EXPORT_VEHICLES_SUCCESS, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.EXPORT_VEHICLES_FAILED, data: res });
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (["sop", "eop", "move_to_current", "end_date"].includes(key)) {
        fetchItems(service, dispatch)(request);
        return;
    }

    if (["brand", "program"].includes(key) && value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (["platform", "nameplate", "segment"].includes(key) && value.length >= 1) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};
