import { request, url, formDataNormalizer } from "Services";

const listUrl = "admin/ihs";

const getItems = data =>
    request.sendRequest({
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    });

export const requestTable = data =>
    new Promise(resolve => {
        getItems(data).then(res =>
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                current_total_analytics: res[0].current_total_analytics,
            }),
        );
    });

export const requestOnUploadFiles = data => {
    const sendObj = {
        url: url.getUrl(`${listUrl}/add`),
        data: formDataNormalizer(data),
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};
