import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

const defaultProps = {
    content: ""
}

const propTypes = {
    classBlock: PropTypes.string.isRequired,
    content: PropTypes.node,
}

const InfoTooltip = ({classBlock, content}) => {
    const popupRef = useRef(null)
    const popupOverlayRef = useRef(null)
    const buttonRef = useRef(null)
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [popupDimensions, setPopupDimensions] = useState({
        width: '0px',
        height: '0px'
    })

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
                setPopupVisible(false);
            }
        }

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [])

    useEffect(() => {
        let timeout;

        if (isPopupVisible && typeof document !== 'undefined') {
            timeout = setTimeout(() => {
                if (popupRef.current && popupOverlayRef.current) {
                    setPopupDimensions({
                        width: popupRef.current.clientWidth,
                        height: popupRef.current.clientHeight + 10
                    })
                    const tooltipButton = document.querySelector(`.info-tooltip.${classBlock}`);
                    const tooltipButtonRect = tooltipButton.getBoundingClientRect();
                    popupRef.current.style.top = `${tooltipButtonRect.top + 20}px`;
                    popupOverlayRef.current.style.top = `${tooltipButtonRect.top + 10}px`;
                }
            }, 1)
        }

        return () => {
            clearTimeout(timeout);
        }
    }, [isPopupVisible])

    useEffect(() => {
        if (typeof document === 'undefined') {
            return undefined;
        }

        const page = document.querySelector('.publications-content.main-content');

        if (!page) {
            return undefined;
        }

        const tooltipButton = document.querySelector(`.info-tooltip.${classBlock}`);

        const scrolling = () => {
            if (popupRef.current && popupOverlayRef.current) {
                const tooltipButtonRect = tooltipButton.getBoundingClientRect();
                popupRef.current.style.top = `${tooltipButtonRect.top + 20}px`;
                popupOverlayRef.current.style.top = `${tooltipButtonRect.top + 10}px`;
            }
        };

        page.addEventListener('scroll', scrolling);

        return () => {
            page.removeEventListener('scroll', scrolling);
        };
    }, []);

    useEffect(() => {
        if (typeof document === 'undefined') {
            return undefined;
        }

        const sidebar = document.querySelector('.product__info.main-content__info') || document.querySelector('.publication-view__info.main-content__info');

        if (!sidebar) {
            return undefined;
        }

        const tooltipButton = document.querySelector(`.info-tooltip.${classBlock}`);

        const scrolling = () => {
            if (popupRef.current && popupOverlayRef.current) {
                const tooltipButtonRect = tooltipButton.getBoundingClientRect();
                popupRef.current.style.top = `${tooltipButtonRect.top + 20}px`;
                popupOverlayRef.current.style.top = `${tooltipButtonRect.top + 10}px`;
            }
        };

        sidebar.addEventListener('scroll', scrolling);

        return () => {
            sidebar.removeEventListener('scroll', scrolling);
        };
    }, []);

    return (
        <div
            className={`info-tooltip ${classBlock}`}
            onMouseOver={(e) => {
                e.persist();
                setPopupVisible(true);
            }}
            onMouseOut={(e) => {
                e.persist();
                setPopupVisible(false);
            }}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            <i className="la la-info-circle"/>
            {isPopupVisible && (
                <div
                    ref={popupRef}
                    className={`info-tooltip__content ${classBlock}`}
                >
                    {parse(content)}
                </div>
            )}
            <div ref={popupOverlayRef} className="info-tooltip__overlay" style={{...popupDimensions}}></div>
        </div>
    )
}

InfoTooltip.propTypes = propTypes;
InfoTooltip.defaultProps = defaultProps;

export default InfoTooltip