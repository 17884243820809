import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Button } from "Templates/Default";
import Img from "Templates/Img/Img";
import dashboardTypeConstant from '../../../Constants/DashboardConstants';

const propTypes = {
    companyName: PropTypes.string.isRequired,
    chartType: PropTypes.oneOf(["bar", "line"]).isRequired,
    selectedValue: PropTypes.oneOf(["lead",
        "opportunity",
        "target",
        "current",
        "current_target",
        "level1",
        "level2",
        "level3",
        "level4",
        "all"
    ]).isRequired,
    getChartData: PropTypes.func.isRequired,
    onChangeChartType: PropTypes.func.isRequired,
};

const typeButtons = [
    {
        value: "line",
        icon: "icon_chart_line",
    },
    {
        value: "bar",
        icon: "icon_chart_bar",
    },
];

const valueButtons = [
    {
        value: "lead",
        label: "Lead",
        color: "gray-text",
        hoverColor: "hover-gray",
    },
    {
        value: "opportunity",
        label: "Opportunity",
        color: "clr-yellow",
        hoverColor: "hover-yellow",
    },
    {
        value: "target",
        label: "Target",
        color: "clr-bright-blue",
        hoverColor: "hover-primary",
    },
    {
        value: "current",
        label: "Current",
        color: "clr-green",
        hoverColor: "hover-green",
    },
    {
        value: "current_target",
        label: "Current + Target",
        color: "clr-red",
        hoverColor: "hover-red",
    },
    {
        value: "all",
        label: "All",
        color: "gray-text",
        hoverColor: "hover-gray",
    },
];

const valueBusinessButtons = [
    {
        value: "level1",
        label: "Level 1",
        color: "gray-text",
        hoverColor: "hover-gray",
    },
    {
        value: "level2",
        label: "Level 2",
        color: "clr-yellow",
        hoverColor: "hover-yellow",
    },
    {
        value: "level3",
        label: "Level 3",
        color: "clr-bright-blue",
        hoverColor: "hover-primary",
    },
    {
        value: "level4",
        label: "Level 4",
        color: "clr-green",
        hoverColor: "hover-green",
    },
    {
        value: "business-all",
        label: "All",
        color: "gray-text",
        hoverColor: "hover-gray",
    },
];

const HeadDashboardCard = ({ pref, dashboardType, companyName, chartType, selectedValue, getChartData, onChangeChartType, t }) => {
    const isBusinessType = dashboardTypeConstant['BUSINESS'] === dashboardType;
    selectedValue = isBusinessType && selectedValue === 'all' ? 'business-all' : selectedValue;

    return (
        <div className={`${pref}__card-head`}>
            <span className="bold-text">{t(`${companyName} Forecast Volume`)}</span>
            <div className={`${pref}__card-buttons`}>
                {typeButtons.map((elem, index) => (
                    <Button
                        key={`Button-${index}`}
                        className={`${pref}__card-button ${chartType === elem.value ? "active" : ""}`}
                        onClick={() => onChangeChartType(elem.value)}
                    >
                        <Img img={elem.icon} alt="chart-icon" />
                    </Button>
                ))}
            </div>
            <ul className={`${pref}__tabs main-content__tabs`}>
                {(isBusinessType ? valueBusinessButtons : valueButtons )
                    .map((elem, index) => (
                    <li
                        key={`li-${index}`}
                        className={`${pref}__tabs-item main-content__tabs-item ${
                            selectedValue === elem.value ? "current-item" : ""
                        }`}
                    >
                        <Button
                            className={`${pref}__tabs-link main-content__tabs-link ${elem.hoverColor}
                            ${selectedValue === elem.value ? `active ${elem.color}` : ""} 
                            ${(selectedValue === "all" || selectedValue === "business-all") ? `${elem.color}` : ""}`}
                            onClick={() => getChartData(elem.value, 'level_1')} //sale
                        >
                            {t(elem.label)}
                        </Button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

HeadDashboardCard.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        companyName: getStoreItem(state, "companyName"),
        chartType: getStoreItem(state, "chartType"),
        selectedValue: getStoreItem(state, "selectedValue"),
        dashboardType: getStoreItem(state, "dashboardType")
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeChartType: getActionStore("onChangeChartTypeAction", service, dispatch),
        getChartData: getActionStore("getChartDataAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(HeadDashboardCard);
