import React from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { marginList } from "Services/enum";
import { withServiceConsumer, ServiceProvider } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Title } from "Templates/Titles";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import { Block } from "Templates/Form";
import { Div } from "Templates/Default";
import BarChart from "Templates/Chart/BarChart";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import ShowPermission from "Hoc/Template/ShowPermission";
import TabCheck from "Templates/Tabs/TabСheck";
import Table from "./Views/Tabs/Templates/Table/Table";

import _productsService from "./Services/Tabs/ProductsService";
import ProductsFormBlock from "./Views/Tabs/Products/FormBlock";

import _customersService from "./Services/Tabs/CustomersService";
import CustomersFormBlock from "./Views/Tabs/Customers/FormBlock";

import _personnelService from "./Services/Tabs/PeopleService";
import PeopleFormBlock from "./Views/Tabs/People/FormBlock";

import _marketTrendsService from "./Services/Tabs/MarketTrendsService";
import MarketTrendsFormBlock from "./Views/Tabs/MarketTrends/FormBlock";

import _projectsService from "./Services/Tabs/ProjectsService";
import ProjectsFormBlock from "./Views/Tabs/Projects/FormBlock";

import _applicationService from "./Services/Tabs/ApplicationService";
import ApplicationFormBlock from "./Views/Tabs/Application/FormBlock";

const defaultProps = {
    selectedValue: "",
    help: "",
};

const propTypes = {
    selectedValue: PropTypes.string,
    help: PropTypes.string,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
};

const MyForecastView = ({ activeTab, selectedValue, onChangeTab, resetForm, t, weightUnit, help }) => {
    const productsService = _productsService();
    const customersService = _customersService();
    const peopleService = _personnelService();
    const marketTrendsService = _marketTrendsService();
    const projectsService = _projectsService();
    const applicationService = _applicationService();

    const handleChangeTab = index => {
        resetForm();
        onChangeTab(index);
    };

    const viewYAsix = value => {
        const selected = marginList.find(elem => elem.value === selectedValue);
        if (typeof _.get(selected, "viewFormat") === "function") {
            return `${selected.viewFormat(value, weightUnit.weightUnit)}`;
        }
        return numberWithCommas(value);
    };

    const tooltipLabel = (tooltipItem, data) => {
        const selected = marginList.find(elem => elem.value === selectedValue);
        const name = data.datasets[tooltipItem.datasetIndex].label || "";
        return `${name}: ${selected.viewFormat(tooltipItem.value, weightUnit.weightUnit)}`;
    };

    return (
    <ShowPermission checkPermission={['forecast_graph_view']}>
        <ContentWrap>
            <ContentWrapCol>
                <Title
                    title={t("Forecast (FY)")}
                    help={help}
                />
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            handleChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Products")}>
                        <ServiceProvider value={productsService}>
                            <Div className="main-wrap-block__body">
                                <Block>
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <ProductsFormBlock />
                                </Block>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Customers")}>
                        <ServiceProvider value={customersService}>
                            <Div className="main-wrap-block__body">
                                <Block>
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <CustomersFormBlock />
                                </Block>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <TabCheck label={t("People")} permission={'forecast_people_analytics_view'} >
                        <ServiceProvider value={peopleService}>
                            <Div className="main-wrap-block__body">
                                <Block>
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <PeopleFormBlock />
                                </Block>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </TabCheck>
                    <Tab label={t("Market Trends")}>
                        <ServiceProvider value={marketTrendsService}>
                            <Div className="main-wrap-block__body">
                                <Block>
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <MarketTrendsFormBlock />
                                </Block>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Projects")} permission={'forecast_project_analytics_view'}>
                        <ServiceProvider value={projectsService}>
                            <Div className="main-wrap-block__body">
                                <Block>
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <ProjectsFormBlock />
                                </Block>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Applications")}>
                        <ServiceProvider value={applicationService}>
                            <Div className="main-wrap-block__body">
                                <Block>
                                    <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel} />
                                    <ApplicationFormBlock />
                                </Block>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    </ShowPermission>
    );
};

MyForecastView.defaultProps = defaultProps;
MyForecastView.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        activeTab: getStoreItem(state, "activeTab"),
        help: getStoreItem(state, "help"),
        selectedValue: getStoreItem(state, "old.marginListSelect"),
        weightUnit: state.weightUnitState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        resetForm: getActionStore("resetForm", service, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MyForecastView);
