import React, {useEffect} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import { url } from "Services";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {MainButton} from "Templates/Content";

const propTypes = {
    selectedFilters: PropTypes.any,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    resetFilter: PropTypes.func.isRequired,
};

const ResetFiltersView = ({ selectedFilters, request, resetFilter, resetProjectFilters, t }) => {
    return  !_.isEmpty(selectedFilters) || !_.isEmpty(request.sort) ?
           (<MainButton className="main-btn__general main-btn_white" onClick={() => {
               resetProjectFilters(true)
               resetFilter({ reset: true })
           }}>
                 {t("Reset")}
            </MainButton>) : '';
};

ResetFiltersView.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        resetProjectFilters: getActionStore("onResetProjectFilters", service, dispatch),
        resetFilter: getActionStore("resetFilter", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ResetFiltersView);
