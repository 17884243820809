import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { TabWrap } from "Templates/Content";
import { formatDate, numberCurrenciesWithCommas } from "Services";
import { getJson } from "Services/StringService";

const defaultProps = {
    periods: [],
};

const propTypes = {
    periods: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            effective_from_date: PropTypes.string.isRequired,
            quantity_per_unit: PropTypes.any.isRequired,
            unit: PropTypes.any.isRequired,
            scale_quantities: PropTypes.string.isRequired,
        }),
    ),
    getData: PropTypes.func.isRequired,
};

const PurchasePrices = (props) => {
    const { pref, periods, getData, t } = props;

    React.useEffect(() => {
        getData();
    }, [])

    return (
        <TabWrap>
            <div className={`${pref}__wrap-overflow`}>
                <div className="periods">
                    {periods.map((period, i) => {
                        const to = periods[i + 1]
                            ? new Date(periods[i + 1].effective_from_date).setDate(new Date(periods[i + 1].effective_from_date).getDate() - 1)
                            : '9999-12-31';
                        const scaleQuantities = getJson(period, 'scale_quantities');

                        return (
                            <div key={period.id} className="periods__wrap">
                                <div className="periods__head">
                                    <p>
                                        {t("Effective From")}
                                        <span>{formatDate(period.effective_from_date)}</span>
                                        {t("to")}
                                        <span>{formatDate(new Date(to).toUTCString())}</span>
                                    </p>
                                </div>
                                <div className="periods__per">
                                    <p>{t(`Per (${period.unit})`)}</p>
                                    <p>{period.quantity_per_unit}</p>
                                </div>
                                <div className="periods__body">
                                    <div className="periods__body-title">
                                        <p>{t("Scale Quantity (from)")}</p>
                                        <p>{t("Price")}</p>
                                    </div>
                                    {scaleQuantities.map((quantity, index) => {
                                        return (
                                            <div
                                                key={`quantity-${index}`}
                                                className="periods__body-content"
                                            >
                                                <p>
                                                    {scaleQuantities[index + 1]
                                                        ? `${quantity.scale_quantity} - ${scaleQuantities[index + 1].scale_quantity - 1}`
                                                        : `${quantity.scale_quantity}+`}
                                                </p>
                                                <p>
                                                    {`$ ${numberCurrenciesWithCommas(quantity.period_price)}`}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </TabWrap>
    );
}

PurchasePrices.defaultProps = defaultProps;
PurchasePrices.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        periods: getStoreItem(state, 'periods'),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        getData: getActionStore('getDataAction', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(PurchasePrices);
