import { ModalDefault } from "Templates/Modals";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import React, { useState } from "react";
import { Block, ButtonRight, Input, WrapInput } from "Templates/Form";
import ButtonLeft from "Templates/Form/BlockSubmit/ButtonLeft";
import BlockSubmit from "Templates/Form/BlockSubmit/BlockSubmit";

const { Button, Form, Title } = ModalDefault;

const propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any.isRequired,
    vendorId: PropTypes.string.isRequired,
    closeEditModal: PropTypes.func.isRequired,
    editVendorCode: PropTypes.func.isRequired,
}

const EditVendorForm = ({ t, data, vendorId, closeEditModal, editVendorCode }) => {
    const [editForm, setEditForm] = useState({
        vendorName: data.name,
        vendorCode: data.code
    })

    const isValidForm = () => {
        return editForm.vendorName && editForm.vendorCode;
    }

    const onChangeName = (value) => {
        setEditForm((prev) => {
            return { ...prev, vendorName: value }
        });
    };

    const onChangeCode = (value) => {
        setEditForm((prev) => {
            return { ...prev, vendorCode: value }
        });
    };

    const onSave = () => {
        const formData = {
            name: editForm.vendorName,
            code: editForm.vendorCode
        };
        editVendorCode({ vendorId, formData });
    }

    return (
        <>
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={closeEditModal}/>
                <Title>{t('Edit Vendor Code')}</Title>
                <Block>
                    <WrapInput isRequired label={t("Vendor Name")} name="vendorName">
                        <Input
                            placeholder={t("Vendor Name")}
                            value={editForm.vendorName}
                            onChange={value => onChangeName(value)}
                        />
                    </WrapInput>
                </Block>
                <Block>
                    <WrapInput isRequired label={t("Vendor Code")} name="vendorCode">
                        <Input
                            placeholder={t("Vendor Code")}
                            value={editForm.vendorCode}
                            onChange={value => onChangeCode(value)}
                        />
                    </WrapInput>
                </Block>
                <BlockSubmit>
                    <ButtonRight>
                        <Button onClick={onSave} disabled={!isValidForm()}>
                            {t('Save Vendor Code ')}
                        </Button>
                    </ButtonRight>
                    <ButtonLeft/>
                </BlockSubmit>
            </Form>
        </>
    )
}

EditVendorForm.propTypes = propTypes;

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        closeEditModal: getActionStore("closeEditModal", service, dispatch),
        editVendorCode: getActionStore("editVendorCode", service, dispatch),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps),)(EditVendorForm);
