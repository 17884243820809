import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {sapAnalyticTypes} from "Services/enum";
import {onChange} from "./EventsService";

export const getTableLabel = (key) => {
    const tableLabel = {
        date: "Date",
        type: "Endpoint",
        user: "User",
        imported_count: "Number of Imported Items",
        request: "Request JSON",
        response: "Response JSON",
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        date: "Date",
        type: "Select",
        user: "Search",
        imported_count: "Search",
    };

    switch (key) {
        case "date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "type":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...sapAnalyticTypes]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "user":
        case "imported_count":
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    name={key}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (<></>);
    }
};
export const isSortEnabled = key => !(/^request.*/.test(key) || /^response.*/.test(key));
