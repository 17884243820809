const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",
    CHANGE_FORM: "CHANGE_FORM",

    CREATE_FILES_REQUEST: "CREATE_FILES_REQUEST",
    CREATE_FILES_SUCCESS: "CREATE_FILES_SUCCESS",
    CREATE_FILES_FAILED: "CREATE_FILES_FAILED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    FETCH_ITEM_REQUESTED: "FETCH_ITEM_REQUESTED",
    FETCH_ITEM_SUCCEEDED: "FETCH_ITEM_SUCCEEDED",
    FETCH_ITEM_FAILED: "FETCH_ITEM_FAILED",

    /* analytics tab */
    CHART_DATA_REQUESTED: "CHART_DATA_REQUESTED",
    CHART_DATA_SUCCEEDED: "CHART_DATA_SUCCEEDED",
    CHART_DATA_FAILED: "CHART_DATA_FAILED",

    HEADER_DATA_REQUESTED: "HEADER_DATA_REQUESTED",
    HEADER_DATA_SUCCEEDED: "HEADER_DATA_SUCCEEDED",
    HEADER_DATA_FAILED: "HEADER_DATA_FAILED",

    RESET_ANALYTICS: "RESET_ANALYTICS",
    /* analytics tab */

    EDIT_PRODUCT: "EDIT_PRODUCT",
    RESET_FORM: "RESET_FORM",
};
export default Constants;
