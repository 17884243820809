import React, {Component} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import AsyncSelectReact from "./AsyncSelectReact";
import {request, url as urlService} from "../index";

const propTypes = {
    disabled: PropTypes.bool,
    url: PropTypes.string,
    params: PropTypes.any,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    onMenuAction: PropTypes.func,
    value: PropTypes.any.isRequired,
    inputProps: PropTypes.shape({ isOptionDisabled: PropTypes.func }),
};

const defaultProps = {
    disabled: false,
    url: "",
    placeholder: "Search",
    params: null,
    inputProps: { isOptionDisabled: option => option.disable },
    handleChange: () => {
    },
    onMenuAction: () => {
    },
};

class AsyncSelect extends Component {
    state = {
        value: false,
        isMenuOpen: false
    };

    componentDidMount = () => {
        const { value } = this.props;
        this.setValue(value);
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.value && !_.filter(this.refs.async.state.defaultOptions, this.state.value).length) {
            if (this.refs.async.state.defaultOptions) {
                this.refs.async.state.defaultOptions.splice(1, 0, this.state.value);
                this.refs.async.state.defaultOptions = _.sortBy(this.refs.async.state.defaultOptions, ['label']);
            }
        }
    }

    UNSAFE_componentWillReceiveProps = nextProps => {
        const { value } = this.state;
        if (value && nextProps.value !== value.value && nextProps.value === "") {
            this.setState({
                value: false,
            });
        }

        // TODO, bad code
        if (!value || nextProps.value !== value.value) {
            this.setValue(nextProps.value);
        }
    };

    handleChange = selectedOption => {
        this.setState(
            {
                value: selectedOption,
            },
            () => {
                const { handleChange } = this.props;
                handleChange(selectedOption || "");
            },
        );
    };

    getOptions = (url, params) => (data) => {
        if (url.includes("//")) {
            return new Promise(resolve => resolve([]));
        }

        return new Promise((resolve, reject) => {
            request.sendRequestWithNoCache(
                {
                    url: urlService.getUrl(url),
                    data: params && Object.keys(params).length ? params : data,
                    type: "GET",
                },
                false,
            )
            .then(res => {
                const options = _.get(res, "0.items", []);

                if (!options.filter(obj => !obj.value).length) {
                    options.unshift({ value: '', label: '-' });
                }

                resolve(options);
            }, reject);
        });
    };

    setValue = value => {
        const { url } = this.props;

        if (value && !url.includes("//")) {
            this.getOptions(`${url}/${value}`)({}).then(options => {
                this.setState({
                    value: _.last(options) || {},
                });
            });
        }
    };

    render = () => {
        const { value, isMenuOpen } = this.state;
        const { url, params, disabled, inputProps, placeholder, onMenuAction } = this.props;

        const customStyles = {
            control: base => ({
                ...base,
            }),
        };

        const loadOptions = params ? this.getOptions(url, params) : this.getOptions(url);

        return (
            <div title={value.label} className="select-title">
                <AsyncSelectReact
                    ref='async'
                    isLoading
                    menuPosition="fixed"
                    // closeMenuOnScroll={e => !e.target.parentNode.className.includes("menu")}
                    closeMenuOnScroll={e => {
                        if (isMenuOpen) {
                            return !(e.target.parentNode || { className: '' }).className.includes("menu")
                        }
                    }}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    inputProps={inputProps}
                    styles={customStyles}
                    loadArguments={{ url }}
                    value={value}
                    cacheOptions
                    defaultOptions
                    onChange={this.handleChange}
                    onResetOptions={this.handleChange}
                    onMenuOpen={() => {
                        this.setState({
                            isMenuOpen: true
                        })
                        onMenuAction(true)
                    }}
                    onMenuClose={() => {
                        this.setState({
                            isMenuOpen: false
                        })
                        onMenuAction(false)
                    }}
                    loadOptions={label => loadOptions({ label })}
                />
            </div>
        );
    };
}

AsyncSelect.propTypes = propTypes;
AsyncSelect.defaultProps = defaultProps;

export default AsyncSelect;
