import React, {Fragment} from "react";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {InputFilter} from "Templates/Table/Filters";
import {savePublicationsList, updatePublicationForm} from "../../Actions/TabActions";
import { onChange } from "../../Services/EventsService";
import {formDataNormalizer} from "Services";

const {Button, Form, Title} = ModalDefault;

const propTypes = {
    closeModal: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    updatePublicationForm: PropTypes.func.isRequired,
    savePublicationsList: PropTypes.func.isRequired,
};

const AddToPublicationListModal = ({t, closeModal, form, savePublicationsList, updatePublicationForm, publicationId}) => {

    const isValidForm = () => {
        return form.groups && form.groups.length > 0;
    };

    const onSave = () => {
        // savePublicationsList(formDataNormalizer({
        //     ids: form.groups,
        //     publication_id: publicationId,
        // }));
        savePublicationsList({
            ids: form.groups,
            publication_id: publicationId,
        });
    };

    return <Form>
        <ButtonClose className="main-modal__form-close" onClick={closeModal}/>
        <Title>{t('Add Publication to List')}</Title>

        <InputFilter
            type="asyncMultiSelect"
            placeholder="Search Publication List"
            url="/product/product-finder/publication-groups/search/groups"
            value={form.groups}
            onChange={e => {
                onChange(e).then(_value => updatePublicationForm('groups', _value));
            }}
        />

        <Button onClick={onSave} disabled={!isValidForm()}>
            {t("Save")}
        </Button>
    </Form>
};

AddToPublicationListModal.prototype = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {

    return {
        form: getStoreItem(state, "addToPublicationsListForm"),
        publicationId: getStoreItem(state, "view.id"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;
    return {
        closeModal: getActionStore("closeAddPublicationListModal", service, dispatch),
        savePublicationsList: getActionStore("savePublicationsList", service, dispatch),
        updatePublicationForm: getActionStore("updatePublicationForm", service, dispatch),
    }
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddToPublicationListModal);