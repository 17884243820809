import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { numberWithCommas } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { DivRow, DivColumn } from "Templates/Table/NewVersion";
import { Span } from "Templates/Default";
import TableRowItemLink from "Templates/Table/TableRowItemLink";

const propTypes = {
    items: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    items: {},
};

const YearTableBodyColumn = ({ items, t }) => {
    const dataEnum = {
        price: "Price",
        volume: "Volume",
        budget: "Budget",
        sales_manager_name: "Sales Manager",
    };

    return (
        <>
            <DivColumn>
                <DivRow />
                {Object.values(dataEnum).map(label => (
                    <DivRow key={`Row-${label}`}>
                        <Span className="gray-text">{t(label)}</Span>
                    </DivRow>
                ))}
            </DivColumn>
            {Object.keys(items).map(column => (
                <DivColumn key={`Column-${column}`}>
                    <DivRow>
                        <Span className="bold-text">{column}</Span>
                    </DivRow>
                    {Object.keys(dataEnum).map(key => (
                        <DivRow key={`Row-${key}`}>
                            {key === 'sales_manager_name' &&
                                <TableRowItemLink
                                    blank
                                    title={items[column][key]}
                                    href={`/admin/users/${items[column]['sales_manager']}`}
                                >
                                    {items[column][key]}
                                </TableRowItemLink>
                                ||
                                <Span>{key !== 'volume' ? '$' : ''}{numberWithCommas(items[column][key])}{key === 'volume' ? ' lb' : ''}</Span>
                            }
                        </DivRow>
                    ))}
                </DivColumn>
            ))}
        </>
    );
};

YearTableBodyColumn.propTypes = propTypes;
YearTableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        items: getStoreItem(state, "items"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(YearTableBodyColumn);
