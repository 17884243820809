const Constants = {
    CHANGE_FORM: "CHANGE_FORM",

    ADD_YEAR: "ADD_YEAR",
    REMOVE_YEAR: "REMOVE_YEAR",
    CHANGE_YEAR: "CHANGE_YEAR",
    CHANGE_MONTH_DATA: "CHANGE_MONTH_DATA",
    CHANGE_YEARLY_VOLUME: "CHANGE_YEARLY_VOLUME",
    APPLY_YEARLY_VOLUME: "APPLY_YEARLY_VOLUME",

    FORM_REQUESTED: "FORM_REQUESTED",
    FORM_SUCCEEDED: "FORM_SUCCEEDED",
    FORM_FAILED: "FORM_FAILED",
    FORM_RESET: "FORM_RESET",
};

export default Constants;
