import React from 'react';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import PropTypes from "prop-types";
import _ from "lodash";
import {Modal} from "Templates/Modals/index";
import HeaderDatasheet from "../Technical/HeaderDatasheet";
import Content from "../Technical/Content";
import NotFoundDatasheet from "../Technical/NotFoundDatasheet";
import CopyForm from "../Technical/CopyForm";
import {withTagDefaultProps} from "Hoc/Template";
import {InputFilter} from "Templates/Table/Filters";
import {datasheetLocation} from "Services/enum";
import {Label} from "Templates/Form";
import DownloadReport from "../../Modals/DownloadReport";

const Design = (
    {
        id, t, subTabs, activeSubTab, fetchSubTabAction, service: {onClickDeleteItem, onClickDeleteFromProductItem},
        fetchSubTabs, showModal, handleCloseModal, handleOpenModal, form, callback, request, fetchItems, total,
        permissions
    }
) => {
    const designData = _.get(activeSubTab, 'design_data');
    const agreements = _.get(designData, 'agreements', []);
    const isApproveForPublication = !!_.find(agreements, {type: 1});

    const [selectedTab, setSelectedTab] = React.useState();

    const showMore = agreements.length !== 0 && permissions.includes("product_solutions_view");

    const onClickDeleteDesignFromProduct = item => {
        if (!item.can_edit) {
            alert('Contact an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to remove the Guide from product?"))) {
            onClickDeleteFromProductItem(item.grade_id).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    React.useEffect(() => {
        fetchSubTabs();
    }, [])

    React.useEffect(() => {
        setSelectedTab(activeSubTab);
    }, [activeSubTab])

    const setSubTabType = (activeSubTab) => {
        return activeSubTab.custom_name;
    }

    const onChangeForm = (key, value) => {
        form = {
            ...form,
            [key]: value || []
        };

        callback(key, value, form);
    }

    const onClickDelete = (item) => () => {
        if (window.confirm(t("Do you want to remove the Guide?"))) {
            onClickDeleteItem(item).then(() => {
                fetchSubTabs({});
            });
        }
    };

    const locationView = (elem) => {
        return elem ? datasheetLocation.find(el => el.value === elem).label : '-';
    };

    const showContent = (array, elemView) => {
        return array.map(elem => elemView(elem)).join(', ');
    };

    return (
        <>
            {showModal && (
                <Modal onRequestClose={handleCloseModal}>
                    <CopyForm
                        callback={onChangeForm}
                        datasheetId={activeSubTab.id}
                        form={form}
                        onClose={handleCloseModal}
                    />
                </Modal>
            )}

            {subTabs && subTabs.length > 0 && (
                <div className={'flex'}>
                    <Label label="Select"/>
                    <span className={'publications-counter flex'}>({subTabs.length})</span>
                </div>
            )}
            <div className="tds-list-wrap">
                <div className="shared-publications-sub-tabs-wrap">
                    {(subTabs && subTabs.length) ?
                        (<InputFilter
                            type="select"
                            value={activeSubTab.id}
                            options={[...subTabs.map((val) => {
                                return {value: val.id, label: val.custom_name}
                            })]}
                            onChange={e => {
                                setSelectedTab(subTabs.find(x => x.id === e))
                                fetchSubTabAction(subTabs.find(x => x.id === e));
                            }}
                        />) : <div></div>
                    }
                    <DownloadReport label={'Guide'}/>
                </div>
                {/*<div className="d-flex align-center">*/}
                {/*    <CheckPermission permission="product_solutions_edit">*/}
                {/*        {(activeSubTab && Object.keys(activeSubTab).length !== 0) && (*/}
                {/*            <>*/}
                {/*                {(!isApproveForPublication || permissions.includes("product_solutions_approve")) && (*/}
                {/*                    <a*/}
                {/*                        type="button"*/}
                {/*                        className="main-circle"*/}
                {/*                        href={`/product/publications/${id}/designs/${activeSubTab.id}/edit`}*/}
                {/*                    >*/}
                {/*                        <Img img="icon_edit"/>*/}
                {/*                    </a>*/}
                {/*                )}*/}
                {/*                <button*/}
                {/*                    type="button"*/}
                {/*                    className={'main-circle'}*/}
                {/*                    onClick={onClickDelete(activeSubTab)}*/}
                {/*                >*/}
                {/*                    <Img img="icon_delete"/>*/}
                {/*                </button>*/}
                {/*            </>*/}
                {/*        )}*/}
                {/*    </CheckPermission>*/}
                {/*    <CheckPermission permission="product_solutions_edit">*/}
                {/*        <MainButtonLink href={`/product/publications/${id}/designs/create`}*/}
                {/*                        className="main-btn_primary">*/}
                {/*            {t("Add New Guides")}*/}
                {/*        </MainButtonLink>*/}
                {/*    </CheckPermission>*/}
                {/*</div>*/}
            </div>

            {/*{showMore && (*/}
            {/*    <ShowMoreSwitch />*/}
            {/*)}*/}

            {/*<div className={`${showMore ? 'show-switch' : ''}`}>*/}
            {/*    {showMore && (*/}
            {/*        <div className="approved-items-wrap">*/}
            {/*            {agreements.map((agreement) => (*/}
            {/*                <Approve*/}
            {/*                    key={`approved_${agreement.type}`}*/}
            {/*                    approver={agreement.user_name}*/}
            {/*                    approverEmail={agreement.user_email}*/}
            {/*                    approveDate={formatDate(agreement.date)}*/}
            {/*                    approveType={agreement.type}*/}
            {/*                />*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    )}*/}

            {/*    /!*{isApproveForPublication && showMore && (*!/*/}
            {/*    /!*    <ProductTable*!/*/}
            {/*    /!*        onClickDelete={onClickDeleteDesignFromProduct}*!/*/}
            {/*    /!*        total={total}*!/*/}
            {/*    /!*        handleOpenModal={handleOpenModal}*!/*/}
            {/*    /!*    />*!/*/}
            {/*    /!*)}*!/*/}
            {/*</div>*/}

            <div className={`grade-view__wrap main-wrap-block technical-wrap ${designData ? '' : 'not-available'}`}>
                {!designData || (Array.isArray(designData) && !designData.length) ? (
                    <NotFoundDatasheet title="Guide"/>
                ) : (
                    <>
                        <HeaderDatasheet datasheetData={designData}/>
                        {designData.contents[0] && designData.contents[0].description &&
                            <Content data={designData.contents[0].description}/>
                        }
                        {designData.copy && <Content data={designData.copy}/>}
                    </>
                )}
            </div>
        </>
    );
};

Design.propTypes = {
    id: PropTypes.string.isRequired,
    fetchSubTabAction: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    activeSubTab: PropTypes.shape({}),
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        onClickDeleteFromProductItem: PropTypes.func.isRequired
    }).isRequired,
    showModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    total: PropTypes.number,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchSubTabs: PropTypes.func.isRequired,
    subTabs: PropTypes.arrayOf(PropTypes.object),
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchSubTabAction: getActionStore("addActiveSubTabAction", service, dispatch),
        fetchSubTabs: getActionStore("getSubTabs", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
        callback: getActionStore("onChangeForm", service, dispatch),
    };
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeSubTab: getStoreItem(state, "activeSubTab"),
        subTabs: getStoreItem(state, "subTabs"),
        showModal: getStoreItem(state, "showModal"),
        form: {...getStoreItem(state, "form", true)},
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        total: getStoreItem(state, "pagination.total"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Design);