export const FiltersColumnsUrls = {
    slug: "/connection/connections/slug-list",
    company_short_name: "/project/projects/customer-short-list",
    company: "/project/projects/customer-list",
    customer_code: "/project/projects/customer-code-list",
    customer_owner: "/project/projects/user-list",
    customer_duns_number: "/project/projects/customer-duns-list",
    grade: "/project/projects/grade-list",
    material_type: "/project/projects/material-type-list",
    sap_application_code: "/project/projects/application-code-list",
    material_number: "/project/projects/material-number-list",
    product_code: "/product/product-finder/product-code-list",
    project_slug: "/project/projects/project-slug",
    project_name: "/project/projects/project-list",
    sales_manager: "/project/projects/user-list",
    project_manager: "/project/projects/user-list",
    project_internal_team: "/project/projects/user-list",
    plant: "/project/projects/plant-list"
}