import { debounce } from "Services/Debounce";
import Constants from "../Constants";

export const fetchItems = (service, dispatch) => data => {
    dispatch({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch({ type: Constants.FETCH_ITEMS_SUCCEEDED, data: { ...res, sort: { ...(data.sort || {}) } } });
        },
        () => {
            dispatch({ type: Constants.FETCH_ITEMS_FAILED, data: { items: [] } });
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if ([
        "sap_value",
        "date_update",
        "created_date",
        "deletion_marker",
        "obsolete_marker",
    ].includes(key)) {
        fetchItems(service, dispatch)(request);
    } else if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const fetchPage = (service, dispatch) => data => {
    dispatch({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch({ type: Constants.NEXT_PAGE, data: res });
        },
        () => {
            dispatch({ type: Constants.FETCH_ITEMS_FAILED, data: { items: [] } });
        },
    );
};
