import React, {Component, Fragment} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {LinkIcon} from "Templates/Link";
import {Block, BlockMain, BlockShadow, BlockWrap, Input, Tooltip, WrapInput} from "Templates/Form";
import {Button} from "Templates/Default";
import _ from "lodash";
import {countryList} from "Services";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    contacts: PropTypes.arrayOf(PropTypes.any).isRequired,
    locations: PropTypes.arrayOf(PropTypes.any).isRequired,
    countries: PropTypes.arrayOf(PropTypes.any).isRequired,
    paintable: PropTypes.string.isRequired,
    molded:PropTypes.objectOf(PropTypes.any).isRequired,
    is_boundable: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    submitColor: PropTypes.func.isRequired,
};

class GeneralForm extends Component {
    state = {
        showColorModal: false,
    };

    handleClickOnShowModal = (/*name*/) => () => {
        alert('Email support@comopps.com to add a New Color');
        // this.setState({
        //     [name]: true,
        // });
    };

    handleCloseModal = () => {
        this.setState({
            showColorModal: false,
        });
    };

    submitColor = data => {
        const { submitColor } = this.props;
        return submitColor(data);
    };

    render() {
        const {
            contacts,
            countries,
            onChange,
            t
        } = this.props;

        const _onAddContact = (lastFrontId) => {
            onChange('contacts', [...contacts, { frontId: lastFrontId + 1, contact: '', role: '', role_id: '' }]);
        };

        const _onChangeContact = (index, value, key, array) => {
            _.set(array, index, value);
            onChange(key, _.uniqBy(_.concat(array), 'contact'));
        };

        const _onChangeRole = (index, value, key, array) => {
            _.set(array, index, value);
            onChange(key, _.concat(array));
        };

        const _onAddArray = (lastFrontId, key, array) => {
            onChange(key, [...array, { frontId: lastFrontId + 1, value: "" }]);
        };

        const _onChangeArray = (index, value, key, array) => {
            _.set(array, index, value);
            onChange(key, _.uniqBy(_.concat(array), 'value'));
        };

        const _onDeleteArray = (frontId, key, array) => {
            onChange(key, array.filter(elem => elem.frontId !== frontId));
        };

        return (
            <BlockMain title="General Information">
                <BlockShadow>
                    <Block className="publication-top-row">
                        <BlockWrap>
                            <WrapInput
                                className={contacts.length > 1 ? "wrap-input--delete" : ""}
                                label={t("Product Contact")}
                                name="contact"
                            >
                                {contacts.map((contact, index) => (
                                    <Fragment key={`Input-contact-${contact.frontId}`}>
                                        <Input
                                            name={`contact-${index}`}
                                            type="asyncSelect"
                                            placeholder={t("Select")}
                                            url="/product/designs/product-contact-list"
                                            value={contact.contact}
                                            onChange={
                                                value => _onChangeContact(
                                                    index,
                                                    {
                                                        frontId: contact.frontId,
                                                        contact: value,
                                                        role: contact.role,
                                                        role_id: contact.role_id
                                                    },
                                                    "contacts",
                                                    contacts,
                                                )
                                            }
                                        />
                                        {contacts.length > 1 && (
                                            <LinkIcon
                                                className="icon-delete"
                                                icon="la-icon-close"
                                                onClick={
                                                    () => _onDeleteArray(contact.frontId, "contacts", contacts)
                                                }
                                            />
                                        )}
                                    </Fragment>
                                ))}
                                <Button
                                    onClick={() => _onAddContact(contacts[contacts.length - 1].frontId)}
                                    className="size_normal main-btn main-btn_white"
                                >
                                    Add Product Contact
                                </Button>
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Contact Role")} name="role_id">
                                {contacts.map((contact, index) => (
                                    <BlockWrap
                                        key={`Input-role_id-${contact.frontId}`}
                                        className="publication-form-block-wrap"
                                    >
                                        <Input
                                            name={`role_id-${index}`}
                                            type="asyncSelect"
                                            disabled={!contact.contact}
                                            placeholder={t("Select")}
                                            url="/product/safeties/roles"
                                            value={contact.role_id}
                                            onChange={
                                                value => _onChangeContact(
                                                    index,
                                                    {
                                                        frontId: contact.frontId,
                                                        contact: contact.contact,
                                                        role: contact.role,
                                                        role_id: value
                                                    },
                                                    "contacts",
                                                    contacts,
                                                )
                                            }
                                        />
                                    </BlockWrap>
                                ))}
                            </WrapInput>
                        </BlockWrap>

                        <BlockWrap>
                            <WrapInput label={t("Additional Information")} name="role" className="wrap-input--list">
                                {contacts.map((contact, index) => (
                                    <BlockWrap
                                        key={`Input-role-${contact.frontId}`}
                                        className="publication-form-block-wrap"
                                    >
                                        <Input
                                            name={`role-${index}`}
                                            disabled={!contact.contact}
                                            placeholder={t("Input")}
                                            value={contact.role || ''}
                                            onChange={
                                                value => _onChangeRole(
                                                    index,
                                                    {
                                                        frontId: contact.frontId,
                                                        contact: contact.contact,
                                                        role: value,
                                                        role_id: contact.role_id
                                                    },
                                                    "contacts",
                                                    contacts,
                                                )
                                            }
                                        />
                                    </BlockWrap>
                                ))}
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap className="design_country">
                            <WrapInput
                                className={countries.length > 1 ? "wrap-input--delete" : ""}
                                label="Country"
                                name="country"
                            >
                                <Tooltip>
                                    {t(
                                        "This Safety Data Sheet is applicable to these countries. Verify Compliance.",
                                    )}
                                </Tooltip>
                                {countries.map((country, index) => (
                                    <Fragment key={`Input-${country.frontId}`}>
                                        <Input
                                            type="searchSelect"
                                            name={`Country-${index}`}
                                            value={country.value}
                                            options={countryList}
                                            placeholder={t("Select")}
                                            onChange={
                                                value => _onChangeArray(
                                                    index,
                                                    { frontId: country.frontId, value },
                                                    "countries",
                                                    countries,
                                                )
                                            }
                                        />
                                        {countries.length > 1 && (
                                            <LinkIcon
                                                className="icon-delete"
                                                icon="la-icon-close"
                                                onClick={
                                                    () => _onDeleteArray(
                                                        country.frontId,
                                                        "countries",
                                                        countries,
                                                    )
                                                }
                                            />
                                        )}
                                    </Fragment>
                                ))}
                                <Button
                                    onClick={
                                        () => _onAddArray(
                                            countries[countries.length - 1].frontId,
                                            "countries",
                                            countries,
                                        )
                                    }
                                    className="size_normal main-btn main-btn_white"
                                >
                                    Add Country
                                </Button>
                            </WrapInput>
                        </BlockWrap>
                    </Block>
                </BlockShadow>
            </BlockMain>
        );
    }
}

GeneralForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        contacts: getStoreItem(state, 'contacts'),
        paintable: getStoreItem(state, 'paintable'),
        molded: getStoreItem(state, 'molded'),
        is_boundable: getStoreItem(state, 'is_boundable'),
        locations: getStoreItem(state, 'locations'),
        countries: getStoreItem(state, 'countries'),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore('onChange', service, dispatch),
        submitColor: getActionStore('submitColor', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(GeneralForm);
