import _ from "lodash";
import Constants from "../Constants";
import {url} from "Services";

export const initState = {
    view: {},
    productsCodes: [],
    title: 'Product Code',
    activeTab: +url.get('activeTab'),
    errors: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data, filter: {}, sort: {} };

        case Constants.GET_PRODUCTS_CODES_LIST: {
            return { ...state, productsCodes: data || [], loading: false };
        }

        case Constants.CHANGE_TITLE: {
            return {
                ...state,
                title: data
            };
        }

        default:
            return state;
    }
};
