const Constants = {
    STATUS_REQUESTED: "STATUS_REQUESTED",
    STATUS_SUCCEEDED: "STATUS_SUCCEEDED",
    STATUS_FAILED: "STATUS_FAILED",

    CHANGE_TAB: "CHANGE_TAB",

    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    EDIT_VENDOR: "EDIT_VENDOR",

    OPEN_EDIT_MODAL: "OPEN_EDIT_MODAL",
    CLOSE_EDIT_MODAL: "CLOSE_EDIT_MODAL"
};

export default Constants;
