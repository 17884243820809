import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Column, Row} from "Templates/Table/NewVersion";
import {Span} from "Templates/Default";
import {generateActualsDatasets} from "Templates/Chart/utils";
import Img from "Templates/Img";
import {weightUnitName} from "Services/weightUnitName";
import {useSelector} from "react-redux";
import {numberWithCommas} from "Services";

const propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    marginListSelect: PropTypes.string.isRequired,
};

const BudgetSalesTableBodyColumn = ({data, marginListSelect}) => {
    const modifiedData = generateActualsDatasets(data);

    const unit = useSelector(state => state.weightUnitState);
    const [expandedColumns, setExpandedColumns] = useState([])

    const result = modifiedData.map((item) => {
        // Step 1: Transform and aggregate the internal items
        const transformedInternalItems = item.internalItems.reduce((acc, innerArray) => {
            innerArray.forEach((obj) => {
                const existingObj = acc.find((internalItem) => internalItem.id === obj.id);

                if (existingObj) {
                    existingObj.sums.push({actual_sum: obj.actual_sum, forecast_sum: obj.forecast_sum});
                } else {
                    acc.push({
                        id: obj.id,
                        name: obj.name,
                        sums: [{actual_sum: obj.actual_sum, forecast_sum: obj.forecast_sum}]
                    });
                }
            });

            return acc;
        }, []);

        // Step 2: Find the maximum length of the `sums` arrays
        const maxLength = Math.max(...transformedInternalItems.map(obj => obj.sums.length));

        // Step 3: Pad the `sums` arrays to the maximum length with placeholder objects
        const paddedInternalItems = transformedInternalItems.map(obj => {
            while (obj.sums.length < maxLength) {
                obj.sums.push({actual_sum: '-', forecast_sum: '-'});
            }
            return obj;
        });

        return {...item, internalItems: paddedInternalItems};
    });

    return (
        <>
            {result.map((item) => {
                return (
                    <Fragment key={`Row-${item.id}`}>
                        <Row>
                            <Column colSpan={1}
                                    className="graphv2__expand-row first my-analytics-budget-body__title-wrap">
                                {item.internalItems.length > 0 && (
                                    <button
                                        onClick={() => setExpandedColumns((prev) => {
                                            if (prev.includes(item.id)) {
                                                const list = prev.filter((expandedItem) => expandedItem !== item.id);
                                                return [...list]
                                            }
                                            prev.push(item.id)
                                            return [...prev]
                                        })}
                                        type="button"
                                        className={`${expandedColumns.includes(item.id) ? 'has-expanded' : ''} expand-row-button`}
                                    >
                                        <Img img="icon-select-arrow"/>
                                    </button>
                                )}
                                {item.label}
                            </Column>
                            {item.data.map((itemData, i) => {
                                return (
                                    <Column colSpan={1} className="graphv2__expand-row" key={i}>
                                        <Span className="my-analytics-budget-body">
                                            <Span
                                                className="my-analytics-budget-body__actual"
                                            >
                                                {itemData.actuals}
                                                {' '}
                                                (
                                                {marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)}
                                                )
                                            </Span>
                                            <Span
                                                className="my-analytics-budget-body__forecast"
                                            >
                                                {itemData.forecasts}
                                                {' '}
                                                (
                                                {marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)}
                                                )
                                            </Span>
                                        </Span>
                                    </Column>
                                )
                            })}
                        </Row>
                        {item.internalItems.length
                            ? (
                                <>
                                    {item.internalItems.map((internalItem, index) => {
                                        return (
                                            <Row key={`Row-internal-${internalItem.id}-${index}`}
                                                 className={`${expandedColumns.includes(item.id) ? 'show' : ''} expanded-row`}>
                                                <Column colSpan={1}
                                                        className="first"><Span>{internalItem.name}</Span></Column>
                                                {internalItem.sums.map((internalSum, internalSumIndex) => (
                                                    <Column key={`Internal-Column-${internalSumIndex}`} colSpan={1}>
                                                        <Span className="my-analytics-budget-body">
                                                            <Span
                                                                className="my-analytics-budget-body__actual regular"
                                                            >
                                                                {!isNaN(internalSum.actual_sum) ? `${numberWithCommas(internalSum.actual_sum, 0)} (${marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)})` : '-'}
                                                            </Span>
                                                            <Span
                                                                className="my-analytics-budget-body__forecast regular"
                                                            >
                                                                {!isNaN(internalSum.forecast_sum) ? `${numberWithCommas(internalSum.forecast_sum, 0)} (${marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)})` : '-'}
                                                            </Span>
                                                        </Span>
                                                    </Column>
                                                ))}
                                            </Row>
                                        )
                                    })}
                                </>
                            )
                            : null}
                    </Fragment>
                )
            })}
        </>
    );
};

BudgetSalesTableBodyColumn.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        data: getStoreItem(state, "data"),
        marginListSelect: getStoreItem(state, "old.marginListSelect"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(BudgetSalesTableBodyColumn);
