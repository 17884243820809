import _ from "lodash";
import {url} from "Services";
import Constants from "../Constants";

export const initState = {
    view: {},
    activeTab: +url.get('activeTab'),
    oldValue: {},
    editValue: {},
    errors: {},
    isProductFinderPermission: false,
    showModal: false,
    form: {},
    totalNotes: 0,
    totalFiles: 0,
    totalProjects: 0,
    totalInvoices: 0,
    totalTechnicals: 0,
    totalProcesses: 0,
    totalDesigns: 0,
    totalSafeties: 0,
    totalBrochures: 0,
};

export const reducer = (state, action) => {
    const { type, data } = action;

    switch (type) {
        case Constants.FETCH_TECHNICAL_ITEMS_REQUESTED:
            return {
                ...state,
                totalNotes: data[0].totalNotes || 0,
                totalFiles: data[0].totalFiles || 0,
                totalProjects: data[0].totalProjects || 0,
                totalInvoices: data[0].totalInvoices || 0,
                totalTechnicals: data[0].totalTechnicals || 0,
                totalProcesses: data[0].totalProcesses || 0,
                totalDesigns: data[0].totalDesigns || 0,
                totalSafeties: data[0].totalSafeties || 0,
                totalBrochures: data[0].totalBrochures || 0,
            };

        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return {
                ...state,
                activeTab: data,
                filter: {},
                sort: {},
                activeSubTab: {},
                view: {
                    ...state.view,
                    datasheet: {
                        contacts: [],
                        colors: [],
                        countries: [],
                        paintable: null,
                        molded: null,
                        is_boundable: null,
                        locations: [],
                        application_codes: [],
                        market_segments: [],
                        segments: [],
                        sub_segments: []
                    }
                }
            };

        case Constants.RESET_FORM:
            return { ...state, editValue: {} };

        case Constants.SAVE_COPY_SUCCESS:
        case Constants.CLOSE_MODAL:
            return {...state, showModal: false, form: {}};

        case Constants.OPEN_MODAL:
            return {...state, showModal: true};

        case Constants.CHANGE_SELECT: {
            const { form } = state;
            form[data.key] = data.value;

            return { ...state, form };
        }

        case Constants.SAVE_COPY_FAILED:
            return { ...state, errors: { ...data }, showModal: true };

        case Constants.CHANGE_ACTIVE_SUBTAB:
            const datasheetData = data[0].items.datasheet_data || data[0].items.process_data || data[0].items.design_data
            return {
                ...state,
                view: {
                    ...state.view,
                    datasheet: {
                        ...state.view.datasheet,
                        contacts: datasheetData.contacts,
                        colors: datasheetData.colors,
                        countries: datasheetData.countries,
                        paintable: datasheetData.paintable,
                        molded: datasheetData.molded,
                        is_boundable: datasheetData.is_boundable,
                        locations: datasheetData.locations,
                        application_codes: datasheetData.application_codes,
                        market_segments: datasheetData.market_segments,
                        segments: datasheetData.segments,
                        sub_segments: datasheetData.sub_segments,
                    }
                }
            };
        default:
            return state;
    }
};
