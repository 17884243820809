import _ from "lodash";
import Constants from "../Constants";

const validate = ({
    name,
    start_fy,
    end_fy,
    physicalCountry,
    physicalCity,
    physicalAddress1,
    physicalZip,
    physicalState,
}) => {
    const checkState = !(physicalCountry === "US" && !physicalState);
    const checkString = [name, start_fy, end_fy, physicalCountry, physicalCity, physicalAddress1, physicalZip].every(
        el => typeof el === "string" && el.trim(),
    );
    return checkString && checkState;
};

export const initialState = {
    name: "",
    logo: "",
    logoView: "",
    email: "",
    code: "+1",
    number: "",
    start_fy: "",
    end_fy: "",
    stateOff: "US",
    physicalCountry: "US",
    physicalCity: "",
    physicalAddress1: "",
    physicalAddress2: "",
    physicalZip: "",
    physicalState: "",
    form_validate: false,
    form_loading: false,
    form_errors: {},
    old: {},
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM:
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };
        default:
            return state;
    }
};
