import React from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {withTagDefaultProps} from "Hoc/Template";

import MainBlock from "./Views/MainBlock";
import InfoWrap from "./Views/InfoWrap";

const InvoiceView = () => {
    return (
        <ContentWrap>
            <InfoWrap/>
            <ContentWrapCol>
                <MainBlock/>
            </ContentWrapCol>
        </ContentWrap>
    );
};

const mapStateToProps = state => {
    return {
        activeTab: state.listState.activeTab,
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(InvoiceView);
