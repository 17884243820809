import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockTitle, Block, BlockShadow, BlockWrap, Tooltip } from "Templates/Form";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { CustomSimpleTable } from "Hoc/Widgets/Table/SimpleTableContainer";

import ProjectVolumeTableBodyColumn from "./Table/ProjectVolume/TableBodyColumn";
import TableHeadColumn from "./Table/ProjectVolume/TableHeadColumn";
import * as _projectVolumeService from "../../Services/Table/ProjectVolumeService";

const ProjectVolumeTable = CustomSimpleTable()(TableHeadColumn)()(ProjectVolumeTableBodyColumn)()();

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
};

const ProjectVolumeBlock = ({ service: { getStoreItem }, t }) => {
    const projectVolumeService = {
        ..._projectVolumeService,
        getStoreItem,
    };

    return (
        <>
            <BlockTitle>
                {t("Part Weight & No. of Parts/Unit")}
                <Tooltip>
                    <span className="bold-text">{t("If a Part Weight is known: ")}</span>
                    {t("Then enter the Part Weight and the No. of Parts/Unit.")}
                    <br />
                    <br />
                    <span className="bold-text">
                        {t(
                            "If a Part Weight is unknown, and a sales volume is known, and the project is associated with a Unit Forecast: ",
                        )}
                    </span>
                    {t("Then enter “1” for the Part Weight and enter “1” for the No. of Parts/Unit")}
                </Tooltip>
            </BlockTitle>
            <Block>
                <BlockShadow>
                    <BlockWrap className="width_1-2">
                        <ServiceProvider value={projectVolumeService}>
                            <ProjectVolumeTable tableClassName="width-max-content" />
                        </ServiceProvider>
                    </BlockWrap>
                </BlockShadow>
            </Block>
        </>
    );
};

ProjectVolumeBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(ProjectVolumeBlock);
