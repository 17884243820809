import React from 'react';
import PropTypes from "prop-types";
import {ImgStorage} from "Templates/Img";

const HeaderDatasheet = ({ datasheetData }) => {
    const {
        publication_logo,
        suppliers
    } = datasheetData;

    return (
        <div className="header-datasheet">
            <div>
                <ImgStorage url={publication_logo} alt="publication logo" />
            </div>


            <div className="image-list">
                <div className="flex image-list">
                    {suppliers && suppliers.length ? suppliers.map((item) => {
                        return <ImgStorage url={item.logo} className="grade-view-logo" alt={item.name} />
                    }) : null}
                </div>
            </div>
        </div>
    );
}

HeaderDatasheet.defaultProps = {
    datasheetData: {},
};
HeaderDatasheet.propTypes = {
    datasheetData: PropTypes.shape({
        id: PropTypes.string,
        publication_logo: PropTypes.string,
    }),
};

export default HeaderDatasheet;