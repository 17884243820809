import Constants from "../Constants";

export const onChangeTab = (service, dispatch) => (data) => {
    dispatch({type: Constants.CHANGE_TAB, data});
};

export const handleCloseModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.CLOSE_MODAL, data});
};

export const handleOpenModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.OPEN_MODAL, data});
};

export const editTeamAction = (service, dispatch) => (data) => {
    dispatch({type: Constants.EDIT_TEAM, data})
};
