import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { FolderImg } from "Templates/Link";

const propTypes = {
    className: PropTypes.string,
    storage_url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
   // onClickItem: PropTypes.string.isRequired,
    settings: PropTypes.objectOf(
        PropTypes.shape({
            ico: PropTypes.string,
            target: PropTypes.string,
            img: PropTypes.string,
            href: PropTypes.func.isRequired,
        }),
    ).isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: "",
    title: "",
};

const TableRowItemFolderLinkImgWrap = ({ storage_url, className,  onClickItem, type, settings, children, title }) => {
    const settingsForCurrnetItem = settings[type];

    return (
        <>
            <FolderImg
                title={title}
                className={className}
                target={settingsForCurrnetItem.target}
                href={settingsForCurrnetItem.href(storage_url)}
                img={settingsForCurrnetItem.img}
                ico={settingsForCurrnetItem.ico}
                onClickItem={onClickItem}
            >
                {children}
            </FolderImg>
        </>
    );
};

TableRowItemFolderLinkImgWrap.propTypes = propTypes;
TableRowItemFolderLinkImgWrap.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowItemFolderLinkImgWrap);
