import Constants from "../Constants";

export const initState = {
    activeSubTab: {},
};

export const reducer = (state, action) => {
    const { data } = action;
    const subTabs = data && data[0] ? data[0].items : [];
    const productItems = subTabs && subTabs[0] ? subTabs[0].product_items : {};

    switch (action.type) {
        case Constants.CHANGE_ACTIVE_SUBTAB: {
            const datasheetData = subTabs[0].datasheet_data || subTabs[0].process_data || subTabs[0].design_data;

            return {
                ...state,
                items: productItems.items || [],
                pagination: productItems.pagination || {},
                columns: productItems.columns || [],
                sort: { column: productItems.columns[0], direction: "asc" },
                activeSubTab: subTabs[0],
                view: {
                    ...state.view,
                    datasheet: {
                        ...state.view.datasheet,
                        contacts: datasheetData.contacts || [],
                        colors: datasheetData.colors || [],
                        countries: datasheetData.countries || [],
                        locations: datasheetData.locations || [],
                        paintable: datasheetData.paintable,
                        molded: datasheetData.molded,
                        application_codes: datasheetData.application_codes || [],
                        manufacturers: datasheetData.manufacturers || [],
                    }
                }
            };
        }

        case Constants.FETCH_TECHNICAL_ITEMS_REQUESTED: {
            if (subTabs.length > 0) {
                const datasheetData = subTabs[0].datasheet_data || subTabs[0].process_data || subTabs[0].design_data;

                return {
                    ...state,
                    items: productItems.items || [],
                    pagination: productItems.pagination || {},
                    columns: productItems.columns || [],
                    sort: { column: productItems.columns[0], direction: "asc" },
                    subTabs,
                    activeSubTab: subTabs[0],
                    view: {
                        ...state.view,
                        datasheet: {
                            ...state.view.datasheet,
                            contacts: datasheetData.contacts || [],
                            colors: datasheetData.colors || [],
                            countries: datasheetData.countries || [],
                            locations: datasheetData.locations || [],
                            paintable: datasheetData.paintable,
                            molded: datasheetData.molded,
                            application_codes: datasheetData.application_codes || [],
                            manufacturers: datasheetData.manufacturers || [],
                        }
                    }
                };
            }

            return {
                ...state,
                items: [],
                pagination: {},
                columns: [],
                sort: { column: '', direction: '' },
                subTabs: [],
                activeSubTab: {},
                view: {
                    ...state.view,
                    datasheet: {
                        contacts: [],
                        colors: [],
                        countries: [],
                        paintable: null,
                        molded: null,
                        locations: [],
                        application_codes: [],
                        manufacturers: [],
                    }
                }
            };
        }

        default:
            return state;
    }
};
