import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import { countryListObj, stateListObj } from "Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const { id, name, sap_plant_id, city, address1, address2, zip } = props;

    const invertCountryListObj = _.invert(countryListObj);
    const invertStateListObj = _.invert(stateListObj);

    const initData = {
        id,
        name,
        sap_plant_id,
        country: _.get(invertCountryListObj, _.get(props, "country"), ""),
        city,
        address1,
        address2,
        zip,
        state: _.get(invertStateListObj, _.get(props, "state"), ""),
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "plants-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
