import Constants from "../Constants/TableTasksConstants";

export const initState = {
    sort: {
        column: "due",
        direction: "desc",
    },
    errors: {},
    loading: false,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.FETCH_TASKS_ITEMS_REQUESTED:
            return { ...state, loading: true };

        case Constants.FETCH_TASKS_ITEMS_SUCCEEDED:
            return {
                ...state,
                tasksTable: { ...state.tasksTable, sort: data.sort },
                dashboard: { ...state.dashboard, tasks: data.table },
                loading: false,
            };

        case Constants.FETCH_TASKS_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.NEXT_TASKS_PAGE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    tasks: {
                        ...state.dashboard.tasks,
                        items: [...state.dashboard.tasks.items, ...data.items],
                        pagination: data.pagination,
                    },
                },
                loading: false,
            };

        default:
            return state;
    }
};
