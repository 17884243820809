import React from "react";
import { compose } from "redux";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";

import { numWithSub} from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import Column from "../Templates/Column";
import Row from "../Templates/Row";
import dashboardTypeConstant  from "../Constants/DashboardConstants"
import DashboardCard from "./Card/Widget/DashboardCard";
import StatusDataDashboardCard from "./Card/Status/DataDashboardCard";
import DataBusinessDashboardCard from "./Card/Status/DataBusinessDashboardCard";
import ChartHeadDashboardCard from "./Card/Chart/HeadDashboardCard";
import ChartDataDashboardCard from "./Card/Chart/DataDashboardCard";
import {Tooltip} from "Templates/Form";
import {Title, Total} from "Templates/Titles";
import UnitWeightWrapper from "App/View/UnitWeightWrapper";

const propTypes = {
    companyName: PropTypes.string.isRequired,
    financialYear: PropTypes.string.isRequired,
    revenue: PropTypes.string.isRequired,
    volume: PropTypes.string.isRequired,
    // role: PropTypes.string.isRequired,
    dashboardType: PropTypes.number.isRequired,
    grossMargin: PropTypes.string.isRequired,
};

const InfoDashboardCard = DashboardCard({});
const ChartDashboardCard = DashboardCard({
    HeadDashboardCard: ChartHeadDashboardCard,
    DataDashboardCard: ChartDataDashboardCard,
});

const DashboardTypeThree = ({ companyName, position, financialYear, revenue, volume, dashboardType, help, t }) => {
    const StatusDashboardCard = DashboardCard({
        DataDashboardCard: dashboardTypeConstant['BUSINESS'] === dashboardType ? DataBusinessDashboardCard : StatusDataDashboardCard
    });

    return (<>
                <Title
                    title={t("Dashboard")}
                    help={help}
                    total={() =>
                       <Total>
                           <div className={'gray-text'}>
                               { dashboardTypeConstant['BUSINESS'] === dashboardType ? "Business Development" : "Sales" }
                           </div>
                           <div className={'gray-text'}>
                               { ` - ${position}` }
                               <Tooltip>{t(`Displays information from all projects in "${companyName}"`)}</Tooltip>
                           </div>
                       </Total>
                }>
                </Title>
                <div className="dashboard__wrap-main main-content__wrap">
                    <div className="dashboard__list">
                        <Row>
                            <Column>
                                <StatusDashboardCard
                                    headProps={{
                                        title: t(`${companyName} Pipeline Forecast Revenue`),
                                        subtitle: t(`FY ${financialYear}`),
                                    }}
                                />
                            </Column>
                        </Row>
                        <Row>
                            <Column columnSize={1} fromColumns={4}>
                                <InfoDashboardCard
                                    headProps={{ title: t(`${companyName} ${dashboardTypeConstant['BUSINESS'] === dashboardType ? "Level 4" : "Current"} Forecast Revenue `), subtitle: t(`FY ${financialYear}`) }}
                                    dataProsp={{ value: `$${numWithSub(Number(revenue))}`, className: "content_center" }}
                                />
                                <InfoDashboardCard
                                    headProps={{
                                        title: t(`${companyName} ${dashboardTypeConstant['BUSINESS'] === dashboardType ? "Level 4" : "Current"} Forecast Volume`),
                                        subtitle: t(`FY ${financialYear}`),
                                    }}
                                    dataProsp={{ value: `${numWithSub(Number(volume))}`, unit: <UnitWeightWrapper isLbs={true}/>, className: "content_center" }}
                                />
                            </Column>
                            <Column columnSize={3} fromColumns={4}>
                                <ChartDashboardCard className="main-data" />
                            </Column>
                        </Row>
                    </div>
                </div>
            </>);
};

DashboardTypeThree.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        companyName: getStoreItem(state, "companyName"),
        role: getStoreItem(state, "role"),
        financialYear: getStoreItem(state, "financialYear"),
        revenue: getStoreItem(state, "dashboard.analytic.revenue"),
        volume: getStoreItem(state, "dashboard.analytic.volume"),
        grossMargin: getStoreItem(state, "dashboard.analytic.gross_margin"),
        dashboardType: getStoreItem(state, "dashboardType"),
        position: getStoreItem(state, "position"),
        help: getStoreItem(state, "help"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DashboardTypeThree);
