import _ from "lodash";
import { url } from "Services";
import Constants from "../Constants";

export const initState = {
    view: {},
    activeTab: +url.get('activeTab'),
    totalNotes: 0,
    totalProjects: 0,
    errors: {},
};

export const reducer = (state, action) => {
    const { type, data } = action;

    switch (type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data, filter: {}, sort: {} };

        default:
            return state;
    }
};
