import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import { isEqual, url } from "Services";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";
import AdditionInfo from "../../Views/Forms/AdditionInfo";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    publicationId: PropTypes.string.isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Safety = ({ old, validate, publicationId, formData, pref, t, submitForm }) => {
    const saveSafety = () => {
        submitForm(formData).then(
            () => {
                url.redirect(`/admin/publications/${publicationId}?subTab=${formData.id}&activeTab=3`);
            },
            errors => {
                alert(errors.detail)
                if (typeof window !== 'undefined') {
                    const noteElement = document.getElementById("note");
                    if (errors && errors.detail && errors.detail === 'Note must be present' && noteElement) {
                        noteElement.focus();
                        noteElement.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }
        );
    };

    const handleClickOnCancelButton = () => {
        if (window.confirm(t("Cancel?"))) {
            url.redirect(`/admin/publications/${publicationId}?subTab=${formData.id}&activeTab=3`);
        }
    };

    validate = validate
        ? (!isEqual({ ...formData },
            old,
            [
                'name',
                'contacts',
                'agreements',
                'locations',
                'countries',
                'colors',
                'paintable',
                'molded',
                'is_boundable',
                'note',
                'keywords'
            ]
        ) || formData.file)
        : validate;

    validate = validate && formData.fileName || formData.attachment_path;

    return (
        <>
            <Title
                title={t("Edit Safety Data Sheet")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <AdditionInfo />
            <FormBlock edit />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validate} onClick={saveSafety}>
                        {t("Publish")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Safety.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        old: getStoreItem(state, "old"),
        publicationId: getStoreItem(state, "publicationId"),
        formData: state.editState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Safety);
