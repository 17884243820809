import Constants from "../../Constants";

export const getCostDataAction = (service, dispatch) => () => {
    dispatch({ type: Constants.cost.FETCH_COST_DATA_REQUESTED });

    const { requestGetGrade } = service;

    return requestGetGrade().then(
        res => dispatch({ type: Constants.cost.FETCH_COST_DATA_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.cost.FETCH_COST_DATA_FAILED, data: res });
        },
    );
};

export const changeGradeAction = (service, dispatch) => data => {
    dispatch({ type: Constants.cost.CHANGE_GRADE_REQUESTED });

    const { requestGetGrade } = service;

    return requestGetGrade(data).then(
        res => dispatch({ type: Constants.cost.CHANGE_GRADE_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.cost.CHANGE_GRADE_FAILED, data: res });
        },
    );
};

export const changeCostAction = (service, dispatch) => value => {
    dispatch({ type: Constants.cost.CHANGE_COST_DATA, data: { value } });
};
