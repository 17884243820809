import {formDataNormalizer, request, showMessage, url} from "Services";
import Constants from "../Constants";

export const openEditModal = (service, dispatch) => () => {
    dispatch({ type:Constants.OPEN_EDIT_MODAL })
}

export const closeEditModal = (service, dispatch) => () => {
    dispatch({ type:Constants.CLOSE_EDIT_MODAL })
}

export const onChangeTab = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_TAB, data });
};

export const onDeleteVendor = (service, dispatch) => data => {
    dispatch({ type: Constants.SHOW_LOADER });

    // TODO: Move this to service
    const sendObj = {
        url: url.getUrl(data),
        data: {},
        type: "DELETE"
    };

    request.sendRequest(sendObj).then(() => {
        dispatch({ type: Constants.HIDE_LOADER });
        url.redirect(`/vendors`);
    });
}

// TODO: Move this to service
const save = (data, requestUrl) => {
    const config = {
        url: url.getUrl(requestUrl),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };
    return request.sendRequest(config);
};

// TODO: Move this to service
const requestEditVendor = data => {
    return new Promise((resolve, reject) => {
        save(data.payload, `/vendors/${data.vendorId}/edit`).then(
            response => resolve(response),
            error => {
                showMessage(`${error.title || ''} ${error.detail}`);
                reject(error);
            },
        );
    });
};

export const editVendorCode = (service, dispatch) => data => {
    dispatch({ type: Constants.SHOW_LOADER });
    const { vendorId, formData } = data;
    const payload = formDataNormalizer({...formData});
    requestEditVendor({vendorId, payload}).then(() => {
        alert("Success: Vendor has been updated!")
        dispatch({type: Constants.HIDE_LOADER});
        dispatch({ type: Constants.EDIT_VENDOR, data: {...formData} })
    }, errors => {
        alert(errors)
        dispatch({type: Constants.HIDE_LOADER});
    });
};