import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Row } from "Templates/Form";
import Img from "Templates/Img/Img";

class Forgot extends Component {
    state = {
        email: "",
    };

    handleChangeString = ({ target }) => {
        this.setState({
            [target.name]: target.value,
        });
    };

    checkForm = () => {
        const { email } = this.state;
        return !email;
    };

    handleClickOnSubmitForm = e => {
        e.preventDefault();
        const { submitForm } = this.props;
        submitForm({ ...this.state });
    };

    render() {
        const { email } = this.state;
        const { t } = this.props;
        return (
            <div className="main-wrap forgot">
                <div className="forgot__wrap">
                    <div className="forgot__logo">
                        <a href="/" className="forgot__logo-link">
                            <Img img="reset_password_logo" className="forgot__logo-img" alt="logo" />
                        </a>
                    </div>
                    <h1 className="forgot__title">{t("Reset Your Password")}</h1>
                    <div className="forgot__desc">{t("We’ll email you instructions to reset your password.")}</div>
                    <form action="/" className="forgot__form" onSubmit={this.handleClickOnSubmitForm}>
                        <Row>
                            <label htmlFor="email" className="forgot__form-label">
                                {t("Your email")}
                                <input
                                    required
                                    id="email"
                                    type="email"
                                    name="email"
                                    className="forgot__form-input"
                                    placeholder="Enter your email here"
                                    value={email}
                                    onChange={this.handleChangeString}
                                />
                            </label>
                        </Row>
                        <Row>
                            <button disabled={this.checkForm()} type="submit" className="forgot__form-submit main-btn">
                                {t("Reset Password")}
                            </button>
                        </Row>
                        <Row>
                            <a href="/login" className="forgot__form-return">
                                {t("Return to login")}
                            </a>
                        </Row>
                    </form>
                </div>
            </div>
        );
    }
}

Forgot.propTypes = {
    submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Forgot);
