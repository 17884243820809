import { handleScroll } from "../../EventsService";
import { requestTable, requestAttach } from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/product/raw/${id}/cost`),
    requestEdit: item => requestAttach(`/product/raw/${id}/cost/${item.id}/edit`, item),
    getViewItem,
    getFilter,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
