import React from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

import MainBlock from "./Views/MainBlock";

const propTypes = {};

const Search = ({ pref, t }) => {
    return (
        <>
            <div className="main-content__wrap-title">
                <div className="main-content__wrap-block">
                    <h1 className="main-content__h1">{t("Search Results")}</h1>
                </div>
            </div>
            <div className={`${pref} main-wrap-block`}>
                <div className="main-content__wrap-change">
                    <MainBlock />
                </div>
            </div>
        </>
    );
};

Search.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Search);
