import Constants from "../../../Constants";

export const createFilesAction = (service, dispatch) => data => {
    dispatch({ type: Constants.CREATE_FILES_REQUEST });

    const { requestOnUploadFiles } = service;

    return requestOnUploadFiles(data).then(
        res => dispatch({ type: Constants.CREATE_FILES_SUCCESS, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.CREATE_FILES_FAILED, data: res });
        },
    );
};

export const addActiveSubTabAction = (service, dispatch) => item => {
    const { requestTypeItem } = service;

    return requestTypeItem(item).then(
        res => dispatch({ type: Constants.CHANGE_ACTIVE_SAFETY_SUBTAB, data: res })
    );
};

export const getSubTabs = (service, dispatch) => () => {
    const { requestSubTab } = service;

    return requestSubTab().then(
        res => dispatch({ type: Constants.FETCH_SAFETY_ITEMS_REQUESTED, data: res })
    );
};

export const saveCopyAction = (service, dispatch) => id => data => {
    const { requestOnSaveCopy } = service;

    return requestOnSaveCopy(id)(data).then(
        res => {
            dispatch({ type: Constants.SAVE_COPY_SUCCESS, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.SAVE_COPY_FAILED, data: res });
        },
    );
};