import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import ReactResizeDetector from "react-resize-detector";
import {BlockWrap} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {maxSizeCanvas, numberWithCommas} from "Services";

import Chart from "chart.js";
import {generateDatasets, getLabels} from "./utils";

const defaultProps = {
    viewYAsix: value => numberWithCommas(value),
    tooltipLabel: (tooltipItem, data) => {
        const name = data.datasets[tooltipItem.datasetIndex].label || "";
        return `${name}: ${numberWithCommas(tooltipItem.value)}`;
    },
};

const propTypes = {
    viewYAsix: PropTypes.func,
    tooltipLabel: PropTypes.func,
    data: PropTypes.any,
    isResponsive: PropTypes.bool,
};

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.myChart = {};
        this.resizeTimerId = 0;
        this.state = {
            width: 0,
            height: 0,
        };
    }

    componentDidMount = () => {
        const chartBlock = document.getElementsByClassName("chart")[0];
        this.setState(
            {
                width: chartBlock.clientWidth,
                height: chartBlock.clientHeight,
            },
            this.setChartConfig,
        );
    };

    componentDidUpdate(prevProps) {
        const { data, tooltipLabel, isResponsive } = this.props;
        if (JSON.stringify(data) !== JSON.stringify(prevProps.data) || tooltipLabel !== prevProps.tooltipLabel) {
            if (isResponsive) {
                this.updateChartData(data);
                return;
            }
            // two chart renders
            const width = this.getChartWidth();

            this.setState(
                {
                    width,
                },
                () => {
                    // const { viewYAsix } = this.props;

                    this.myChart.width = width;
                    this.updateChartData(data);
                    // this.myChart.options.scales.xAxes[0].labels = getLabels(data);
                    // this.myChart.options.scales.yAxes[0].ticks.callback = viewYAsix;
                    // this.myChart.options.tooltips.callbacks.label = tooltipLabel;
                    // this.myChart.data.datasets = generateDatasets(data, "bar");
                    this.myChart.update();
                },
            );
        }
    }

    updateChartData = (data) => {
        const { viewYAsix, tooltipLabel } = this.props;
        // this.myChart.width = width;
        this.myChart.options.scales.xAxes[0].labels = getLabels(data);
        this.myChart.options.scales.yAxes[0].ticks.callback = viewYAsix;
        this.myChart.options.tooltips.callbacks.label = tooltipLabel;
        this.myChart.data.datasets = generateDatasets(data, "bar");
        this.myChart.update();
    }

    setChartConfig = () => {
        const { data } = this.props;
        const canvas = document.getElementById("myChart");
        const ctx = canvas.getContext("2d");
        this.myChart = new Chart(ctx, {
            type: "bar",
            data: {
                datasets: generateDatasets(data, "bar"),
            },
            options: {
                devicePixelRatio: 1,
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 54,
                        right: 24,
                        bottom: 20,
                        left: 24,
                    },
                },
                legend: {
                    position: "bottom",
                    display: true,
                    labels: {
                        padding: 50,
                        usePointStyle: true,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                drawTicks: false,
                            },
                            ticks: {
                                padding: 20,
                                callback: value => numberWithCommas(value),
                                // stepSize: 16.3,
                                min: 0,
                                // max: 100,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                drawTicks: false,
                                drawOnChartArea: false,
                            },
                            lineHeight: 0,
                            labels: getLabels(data),
                            ticks: {
                                padding: 20,
                            },
                        },
                    ],
                },
            },
        });
    };

    getChartWidth = () => {
        if (typeof document === 'undefined') {
            return 0;
        }
        const width = document.querySelector('.chart').clientWidth || 0

        return maxSizeCanvas(width);
    };

    onResize = () => {
        new Promise(resolve => {
            window.requestAnimationFrame(() => {
                if (this.resizeTimerId) {
                    clearTimeout(this.resizeTimerId);
                }
                this.resizeTimerId = setTimeout(() => resolve(), 200);
            });
        }).then(() => {
            const width = this.getChartWidth();

            this.setState(
                {
                    width,
                },
                () => {
                    this.myChart.width = width;
                    this.myChart.update();
                },
            );
        });
    };

    render() {
        const { width, height } = this.state;
        return (
            <BlockWrap className="chart">
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
                    <div className="chartAreaWrapper inTemplates" style={{ width, height }}>
                        <canvas id="myChart" width={width} height={height} />
                    </div>
                </ReactResizeDetector>
            </BlockWrap>
        );
    }
}

BarChart.defaultProps = defaultProps;
BarChart.propTypes = propTypes;

const mapStateToProps = (state, { service: { getChartData } }) => {
    return {
        data: getChartData(state),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(BarChart);
