import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import TableHeadTitle from "Templates/Table/TableHeadTitle";

const propTypes = {
    column: PropTypes.string.isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    onSortClick: PropTypes.func.isRequired,
    service: PropTypes.shape({
        getTableLabel: PropTypes.func.isRequired
    }).isRequired
};

const TableHeadColumnWrap = ({ column, service, sort, onSortClick, t }) => {
    const { getTableLabel} = service;

    const Columns = () => {
        let col;
        switch (getTableLabel(column)) {
            case 'Date':
                col = <TableHeadTitle column={column} sort={sort} onSortClick={() => onSortClick(column)}>
                    {t('Date')}
                </TableHeadTitle>
                break;
            case 'Editor':
                col = <TableHeadTitle column={column} sort={sort} onSortClick={() => onSortClick(column)}>
                    {t('Editor')}
                </TableHeadTitle>
                break;
            case 'Source':
                col = <TableHeadTitle column={column} sort={sort} onSortClick={() => onSortClick(column)}>
                    {t('Source')}
                </TableHeadTitle>
                break;
            case 'Field':
                col = <div className="field-column-wrap">
                    <TableHeadTitle column={'Field'} sort={sort} onSortClick={() => false}>
                        {t('Field')}
                    </TableHeadTitle>
                    <TableHeadTitle column={'Original Value'} sort={sort} onSortClick={() => false}>
                        {t('Original Value')}
                    </TableHeadTitle>
                    <TableHeadTitle column={'New Value'} sort={sort} onSortClick={() => false}>
                        {t('New Value')}
                    </TableHeadTitle>
                </div>
                break;
            default: col = null
        }

        return col;
    }

    return (
        <Columns />
    );
}

TableHeadColumnWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        sort: getStoreItem(state, "sort")
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps))(TableHeadColumnWrap);
