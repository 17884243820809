import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas, url } from "Services";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { CheckPermission, withTagDefaultProps } from "Hoc/Template";
import { ContentWrap, ContentWrapCol, MainButtonLink } from "Templates/Content";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import { Title, Total, WrapButtons } from "Templates/Titles";
import TabView from "./Views/TabView";
import ResetFiltersButton from "./Views/ResetFiltersButton";

import reviewService from "./Services/Tabs/Review";
import generalService from "./Services/Tabs/General";


const defaultProps = {
    help: ''
};

const propTypes = {
    title: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    executeExport: PropTypes.func.isRequired,
    help: PropTypes.string,
};

const GENERAL_TAB = 1;

const labels = [
    {
        label: 'Review Table',
        service: reviewService,
    }
];

const Solution = ({ title, request, activeTab, onChangeTab, executeExport, help, t }) => {
    const handleExportSolutionsClick = e => {
        e.preventDefault();
        executeExport(url.getUrl('/admin/solutions/export', request));
    }

    const exportButton = () => {
        if (activeTab === GENERAL_TAB) {
            return (
                <MainButtonLink
                    disabled
                    className="main-btn_white"
                    onClick={(e) => handleExportSolutionsClick(e)}
                >
                    {t("Export Technologies")}
                </MainButtonLink>
            );
        }

        return <></>;
    };

    return (
        <>
            <Title
                title={t("Technologies")}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                <CheckPermission permission="product_solutions_edit">
                    <WrapButtons>
                        <ServiceProvider value={labels[activeTab].service}>
                            <ResetFiltersButton/>
                        </ServiceProvider>

                        {exportButton()}

                        <MainButtonLink href="/admin/solutions/create" className="main-btn_primary">
                            {t("Add New Technology")}
                        </MainButtonLink>
                    </WrapButtons>
                </CheckPermission>
            </Title>

            <ContentWrap>
                <ContentWrapCol>
                    <Tabs
                        activeTab={activeTab}
                        onClickTabItem={index => {
                            if (activeTab !== index) {
                                onChangeTab(index);
                            }
                        }}
                    >
                        {(
                            labels.map((item, index) => {
                                return (
                                    <Tab key={index} label={t(item.label)}>
                                        <ServiceProvider value={item.service}>
                                            <TabView/>
                                        </ServiceProvider>
                                    </Tab>
                                );
                            })
                        )}
                    </Tabs>
                </ContentWrapCol>
            </ContentWrap>
        </>
    );
};

Solution.defaultProps = defaultProps;
Solution.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        title: state.listState.pagination.total,
        help: getStoreItem(state, "help"),
        activeTab: getStoreItem(state, "activeTab"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        executeExport: getActionStore("executeExport", service, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Solution);
