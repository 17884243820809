import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form, BlockWrap, Input, WrapInput, BlockMain } from "Templates/Form";
import TextArea from "Templates/Input/TextArea";

const propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const FormBlock = ({ name, description, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <Form>
            <BlockMain title="General Info">
                <BlockWrap>
                    <WrapInput isRequired className=" width_1-4" label={t("Publication Name")} name="name">
                        <Input
                            placeholder={t("Publication Name")}
                            value={name}
                            onChange={value => onChangeInput(value, `name`)}
                        />
                    </WrapInput>
                    <WrapInput className=" width_1-4" label={t("Publication Description")} name="description">
                        <Input
                            isTextArea
                            type="textarea"
                            placeholder={t("Publication Description")}
                            value={description}
                            onChange={value => onChangeInput(value, `description`)}
                        />
                    </WrapInput>
                </BlockWrap>
            </BlockMain>
        </Form>
    );
};

FormBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        description: getStoreItem(state, "description"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
