import React, {useEffect, useRef, useState} from "react";
import Img from "Templates/Img";
import PropTypes from "prop-types";
import {ButtonClose} from "Templates/Button";

const defaultProps = {
    content: ""
}

const propTypes = {
    type: PropTypes.string.isRequired,
    left: PropTypes.bool.isRequired,
    content: PropTypes.node,
}
const InfoPopup = ({ type, left, content }) => {
    const popupRef = useRef(null)
    const buttonRef = useRef(null)
    const [isPopupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
                setPopupVisible(false);
            }
        }

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [])

    useEffect(() => {
        let timeout;

        if (isPopupVisible && typeof document !== 'undefined') {
            timeout = setTimeout(() => {
                if (popupRef.current) {
                    const tooltipButton = document.querySelector(`.tooltip-action.${type}`);
                    const tooltipButtonRect = tooltipButton.getBoundingClientRect();
                    popupRef.current.style.top = `${tooltipButtonRect.top}px`;

                    if (left && buttonRef.current) {
                        const popupAltArrowWidth = 20;
                        const tooltipButtonCurrentRect = buttonRef.current.getBoundingClientRect();
                        const tooltipButtonLeftPosition = tooltipButtonCurrentRect.left - popupRef.current.clientWidth - popupAltArrowWidth;
                        popupRef.current.style.left = `${tooltipButtonLeftPosition}px`;
                    }
                }
            }, 1)
        }

        return () => {
            clearTimeout(timeout);
        }
    }, [isPopupVisible])

    useEffect(() => {
        if (typeof document === 'undefined') {
            return undefined;
        }

        const page = document.querySelector('.publications-content.main-content');

        if (!page) {
            return undefined;
        }

        const tooltipButton = document.querySelector(`.tooltip-action.${type}`);

        const scrolling = () => {
            if (popupRef.current) {
                const tooltipButtonRect = tooltipButton.getBoundingClientRect();
                popupRef.current.style.top = `${tooltipButtonRect.top}px`;
            }
        };

        page.addEventListener('scroll', scrolling);

        return () => {
            page.removeEventListener('scroll', scrolling);
        };
    }, []);

    useEffect(() => {
        if (typeof document === 'undefined') {
            return undefined;
        }

        const sidebar = document.querySelector('.grade-view__info.main-content__info')
            || document.querySelector('.publication-view__info.main-content__info')
            || document.querySelector('.projects-view__wrap-body.main-wrap-block__body');

        if (!sidebar) {
            return undefined;
        }

        const tooltipButton = document.querySelector(`.tooltip-action.${type}`);

        const scrolling = () => {
            if (popupRef.current) {
                const tooltipButtonRect = tooltipButton.getBoundingClientRect();
                popupRef.current.style.top = `${tooltipButtonRect.top}px`;
            }
        };

        sidebar.addEventListener('scroll', scrolling);

        return () => {
            sidebar.removeEventListener('scroll', scrolling);
        };
    }, []);

    useEffect(() => {
        if (typeof document === 'undefined') {
            return undefined;
        }

        const table = document.querySelector('.project-expanded-block__toggle-forecast.expanded');

        if (!table) {
            return undefined;
        }

        const scrolling = () => {
            if (left && buttonRef.current && popupRef.current) {
                const popupAltArrowWidth = 20;
                const tooltipButtonCurrentRect = buttonRef.current.getBoundingClientRect();
                const tooltipButtonLeftPosition = tooltipButtonCurrentRect.left - popupRef.current.clientWidth - popupAltArrowWidth;
                popupRef.current.style.left = `${tooltipButtonLeftPosition}px`;
            }
        };

        table.addEventListener('scroll', scrolling);

        return () => {
            table.removeEventListener('scroll', scrolling);
        };
    }, []);

    const handleViewMoreClick = () => {
        setPopupVisible(!isPopupVisible);
    }

    return (
        <div className="expanded-tooltip-wrap">
            <div className={`tooltip expanded-tooltip ${isPopupVisible ? 'visible' : ''}`}>
                <button ref={buttonRef} type="button" className={`tooltip-action ${ type }`} onClick={handleViewMoreClick}>
                    View More
                    <Img img="icon_menu" className="main-header__add-svg" alt=""/>
                </button>
            </div>
            {isPopupVisible && (
                <div ref={popupRef} className="expanded-tooltip__tooltip__text">
                    <ButtonClose className="main-modal__form-close" onClick={() => setPopupVisible(false)}/>
                    <div className="expanded-tooltip__scroll-wrap">
                        {content}
                    </div>
                </div>
            )}
        </div>
    )
}

InfoPopup.propTypes = propTypes;
InfoPopup.defaultProps = defaultProps;

export default InfoPopup