import Constants from "../Constants";

export const initState = {
    email: "",
    error: "",
    status: "",
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.SUBMIT_FROM_REQUEST:
            return { status: "request" };

        case Constants.SUBMIT_FROM_SUCCEEDED:
            return { status: "success", email: data.email };

        case Constants.SUBMIT_FROM_FAILURE:
            return { status: "failure", error: data };

        default: {
            return state;
        }
    }
};
