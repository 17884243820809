import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {getString, formatDate, diffDate} from "Services";
import {companyPhoneTypes} from "Services/enum";
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "email":
            const email = getString(item, key);

            if (!email || email === '-') {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            return (
                <TableRowItemLink title={email} href={`mailto:${email}`}>
                    {email}
                </TableRowItemLink>
            );

        case "nicknames": {
            const value = getString(item, key);

            if (!value || value === '-') {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }
            const arrDate = JSON.parse(getString(item, key));

            return (
                <>
                    {arrDate.map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={elem}>
                            {elem}
                        </TableRowItemSpan>
                    ))}
                </>
            );
        }

        case "nda":
            const value = getString(item, key);
            if (!value) {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }
            const date = diffDate(value, false, "month");

            let dateColor = "";
            switch (true) {
                case date < 3:
                    dateColor = "clr-red";
                    break;

                case date < 6:
                    dateColor = "clr-yellow";
                    break;
            }

            return (
                <TableRowItemSpan title={formatDate(value)} className={dateColor}>
                    {formatDate(value)}
                </TableRowItemSpan>
            );

        case "marked_deletion":
        case "sap_value":
            return <TableRowItemSpan>{getString(item, key) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case "changed_date":
        case "created_code_date":
        case "created_system_date":
            const dateItem = _.get(item, key) !== null ? formatDate(_.get(item, key)) : '-';

            return (
                <TableRowItemSpan title={dateItem}>{dateItem}</TableRowItemSpan>
            );

        case "phone":
            const phones = item.phone ? JSON.parse(item.phone) : [];

            if (!phones.length) {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            return (
                <>
                    {phones.map((elem, index) => (
                        <TableRowItemSpan title={elem.number} key={`TableRowItemSpan-${elem.number}`}>
                            {`${_.find(companyPhoneTypes, {value: `${elem.type}`}).label}: ${elem.number}`}
                        </TableRowItemSpan>
                    ))}
                </>
            );

        default:
            const data = getString(item, key);

            if (!data || data === '-') {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            return <TableRowItemSpan title={data}>{data}</TableRowItemSpan>;
    }
};
