import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Block, BlockWrap, Input, WrapInput } from "Templates/Form";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    solutionLogo: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const SolutionLogo = (props) => {
    const {
        t,
        solutionLogo,
        onChange
    } = props;

    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <Block>
            <BlockWrap>
                <WrapInput isRequired label={t("Primary")} name="solutionLogo">
                    <Input
                        type="asyncSelect"
                        url="/admin/list/solution-logos/list"
                        placeholder={t("Select")}
                        value={solutionLogo}
                        onChange={value => onChangeInput(value, `solutionLogo`)}
                    />
                </WrapInput>
            </BlockWrap>
        </Block>
    );
}

SolutionLogo.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        solutionLogo: getStoreItem(state, "solutionLogo"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SolutionLogo);
