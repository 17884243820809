import { reducer as FormReducer, initialState as createState } from "./FormReducer";
import { reducer as ApplicationReducer, initialState as ApplicationState } from "./ApplicationReducer";

const reducers = [ApplicationReducer, FormReducer];

const mainState = {
    ...createState,
    applicationBlock: ApplicationState
};

export const reducer = (state = mainState, action) => {
    for (let i = 0; i < reducers.length; i += 1) {
        state = reducers[i](state, action);
    }

    return state;
};

export const initialState = mainState;
