import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ProjectStatusBlockRow } from "Templates/Content";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import MainBlock from "./MainBlock";
import ProjectStatusBlock from "../ProjectStatusBlock/ProjectStatusBlock";

const propTypes = {
    fetchItems: PropTypes.func.isRequired,
};

class MyPipeline extends Component {
    componentDidMount() {
        const { fetchItems } = this.props;
        fetchItems();
    }

    render() {
        const { pref } = this.props;
        return (
            <div className={`${pref}__wrap-content`}>
                <ProjectStatusBlockRow>
                    <ProjectStatusBlock />
                </ProjectStatusBlockRow>
                <MainBlock />
            </div>
        );
    }
}

MyPipeline.propTypes = propTypes;

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MyPipeline);
