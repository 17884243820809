import { handleScroll } from "../../EventsService";
import {getItem, requestOnSaveCopy, getUploadUrl} from "./RequestService";
import { getActionStore, getStoreItem } from "./StoreService";
import {deleteItem} from "../../RequestService";

export default id => ({
    requestItems: () => getItem(`/product/product-finder/${id}/technical`),
    requestTypeItem: (item) => getItem(`/product/product-finder/${id}/technical/${item.id}`),
    requestOnSaveCopy: (datasheetId) => requestOnSaveCopy(`/product/datasheets/${datasheetId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/datasheets/${item.id}/delete`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (datasheetId, type) => getUploadUrl(id, datasheetId, type),
});
