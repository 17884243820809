import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { onChange } from "./EventsService";

export const getTableLabel = (key, { currentFY }) => {
    const tableLabel = {
        modified: "Modified",
        modified_by: "Modified By",
        file_name: "File Name",
        status: "Status",
        error_message: "Error Message",
        volume: "Volume",
        revenue: "Revenue",
    };

    switch (key) {
        case "volume":
        case "revenue":
        case "gross_margin":
            return `FY ${currentFY}  ${_.get(tableLabel, key, key)}`;

        case String(key.match(/^volume_.*/)):
        case String(key.match(/^revenue_.*/)):
        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.split("_");
            return `${keys[1]} ${keys[2]} <br/> Forecast ${_.get(tableLabel, keys[0], key)}`;
        }

        default:
            return _.get(tableLabel, key, key);
    }
};

const getFilter = (key, value, callback, request) => {
    const placeholders = {
        modified: "Select Date",
        modified_by: "Search",
        file_name: "Search",
        status: "Select",
        error_message: "Search",
    };
    switch (key) {
        case "modified":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "status":
            return (
                <InputFilter
                    type="select"
                    options={[
                        {
                            value: "",
                            label: "All",
                        },
                        {
                            value: 10,
                            label: "Imported",
                        },
                        {
                            value: 5,
                            label: "Processing",
                        },
                        {
                            value: 3,
                            label: "Pending",
                        },
                        {
                            value: "0",
                            label: "Canceled",
                        },
                    ]}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case String(key.match(/^volume_.*/)):
        case String(key.match(/^revenue_.*/)):
        case String(key.match(/^grossMargin_.*/)): {
            return (
                <TableRowItemSpan/>
            );
        }

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export { getFilter };

export const isSortEnabled = key => !(/^volume_.*/.test(key) || /^revenue_.*/.test(key) || /^grossMargin_.*/.test(key));
