import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import HierarchyTableRowColumnTemplate from "../HierarchyTableRowColumn";
import HierarchyTableRowColumnButton from "./HierarchyButton";
import HierarchyTableRowColumnDefault from "./HierarchyDefault";

const propTypes = {
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    addHierarchy: PropTypes.func,
    secondaryIndex: PropTypes.number.isRequired,
};

const defaultProps = {
    customIcon: "",
    onClickCustom: () => {},
    addHierarchy: () => {},
};

const HierarchyTableRowColumn = React.memo(
    ({ customIcon, secondaryIndex, column, item, onClickCustom, onClickDelete, onClickEdit, addHierarchy }) => (
        <HierarchyTableRowColumnTemplate>
            {column === "-1" ? (
                <HierarchyTableRowColumnButton
                    customIcon={customIcon}
                    onClickCustom={onClickCustom}
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                    index={secondaryIndex}
                />
            ) : (
                <HierarchyTableRowColumnDefault column={column} item={item} addHierarchy={addHierarchy} />
            )}
        </HierarchyTableRowColumnTemplate>
    ),
);

HierarchyTableRowColumn.propTypes = propTypes;
HierarchyTableRowColumn.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(HierarchyTableRowColumn);
