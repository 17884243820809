import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import TableRowColumnButton from "Templates/Table/TableRowColumnWrap/Button";
import TableRowColumnDefault from "Templates/Table/TableRowColumnWrap/Default";

const propTypes = {
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
};

const defaultProps = {
    onClickCustom: () => {},
};

const TableRowColumn = React.memo(
    ({ secondaryIndex, column, item, onClickCustom, onClickDelete, onClickEdit }) => {
        return (
            <TableRowColumnTemplate>
                {column === "-1" ? (
                    <TableRowColumnButton
                        customIcon={!item.is_invited ? 'icon_external_link' : 'icon_email'}

                        onClickCustom={onClickCustom}
                        onClickDelete={onClickDelete}
                        onClickEdit={onClickEdit}
                        index={secondaryIndex}
                    />
                ) : (
                    <TableRowColumnDefault column={column} item={item}/>
                )}
            </TableRowColumnTemplate>
        )
    },
);

TableRowColumn.propTypes = propTypes;
TableRowColumn.defaultProps = defaultProps;

export default compose(
    withTagDefaultProps(),
)(TableRowColumn);
