import _ from "lodash";

export const getTableLabel = (key) => {
    const tableLabel = {
        color: "Color",
        color_code: "Color Code",
        approval: "OEM Approval",
        product: "Product Name",
        material_number: "Material Number",
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = () => "";

export const isSortEnabled = key => (["color", "color_code", "approval", "product", "material_number"].includes(key));