import _ from "lodash";
import {getStoreItem as _getStoreItem} from "Services";
import {ContactsActions, PublicationsActions, UsersActions} from "../Actions";

export const getStoreItem = key => key ?
    _getStoreItem(`listState.${key}`) :
    _getStoreItem("listState");

// export const getUsersStoreItem = _getStoreItem("listState.users");

export const getActionStore = key => (action, service, dispatch) => {
    const actions = {
        // ...UsersActions,
        // ...ContactsActions,
        // ...PublicationsActions,
        users: UsersActions,
        contacts: ContactsActions,
        publications: PublicationsActions,
    };
    const actionName = key ? `${key}.${action}` : action;
    return _.get(actions, actionName, () => {})(service, dispatch);
};
