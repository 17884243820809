import {request, url} from "Services";

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestSandBoxTable = (listUrl) => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                total_analytics: res[0].total_analytics,
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
            url.addHistory(data);
        });
    });
};
