import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { marginList } from "Services/enum";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";

import InfoPanelBlock from "Templates/InfoPanelBlock";
import { Block } from "Templates/Form";
import { Div } from "Templates/Default";
import BarChart from "Templates/Chart/BarChart";
import { weightUnitName, weightUnitConvertLbs } from 'Services/weightUnitName';
import FormBlock from "./FormBlock";

const defaultProps = {
    revenue: "0",
    volume: "0",
    grossMargin: "0",
    selectedValue: "",
    currentFY: "",
};

const propTypes = {
    revenue: PropTypes.string,
    currentFY: PropTypes.string,
    selectedValue: PropTypes.string,
    volume: PropTypes.string,
    grossMargin: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    getHeaderData: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    service: PropTypes.shape({ requestData: PropTypes.func.isRequired }).isRequired,
};

class Analytics extends Component {
    componentDidMount() {
        const { getHeaderData } = this.props;
        getHeaderData();
    }

    shouldComponentUpdate = nextProps => {
        const { permissions } = this.props;
        if (permissions !== nextProps.permissions && permissions.toString() === nextProps.permissions.toString()) {
            return false;
        }
        return true;
    };

    componentWillUnmount() {
        const { resetForm } = this.props;
        resetForm();
    }

    render() {
        const { permissions, currentFY, revenue, volume, grossMargin, selectedValue, t, weightUnit } = this.props;
        const viewYAsix = value => {
            const selected = marginList.find(elem => elem.value === selectedValue);
            if (typeof _.get(selected, "viewFormat") === "function") {
                return `${selected.viewFormat(value, weightUnit.weightUnit)}`;
            }
            return numberWithCommas(value);
        };

        const tooltipLabel = value => (tooltipItem, data) => {
            const selected = marginList.find(elem => elem.value === value);
            const name = data.datasets[tooltipItem.datasetIndex].label || "";
            return `${name}: ${selected.viewFormat(tooltipItem.value, weightUnit.weightUnit)}`;
        };

        return (
            <>
                <InfoPanelBlock
                    showRevenue={permissions.includes("revenue_gross_operating_view")}
                    revenue={revenue}
                    revenueText={`FY ${currentFY} ${t("Forecast Revenue")} (${t("Current")})`}
                    showVolume={permissions.includes("volume_view")}
                    volume={volume}
                    volumeText={`FY ${currentFY} ${t("Forecast Volume")} (${t("Current")})`}
                    showGrossMargin={permissions.includes("revenue_gross_operating_view")}
                    grossMargin={grossMargin}
                    weightUnit={weightUnit}
                    grossMarginText={`FY ${currentFY} ${t("Gross Margin")} (${t("Current")})`}
                />

                <Div className="main-wrap-block__body">
                    <Block>
                        <BarChart viewYAsix={viewYAsix} tooltipLabel={tooltipLabel(selectedValue)} />
                        <FormBlock />
                    </Block>
                </Div>
            </>
        );
    }
}

Analytics.defaultProps = defaultProps;
Analytics.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        currentFY: getStoreItem(state, "financialYear"),
        selectedValue: getStoreItem(state, "analytics.old.marginListSelect"),
        revenue: getStoreItem(state, "analytics.statistics.revenue"),
        volume: getStoreItem(state, "analytics.statistics.volume"),
        grossMargin: getStoreItem(state, "analytics.statistics.gross_margin"),
        weightUnit: state.weightUnitState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        getHeaderData: getActionStore("getHeaderDataAction", service, dispatch),
        resetForm: getActionStore("resetForm", service, dispatch),
    };
};
export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Analytics);
