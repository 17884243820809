import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form, BlockMain, Block, BlockWrap, Input, WrapInput } from "Templates/Form";

const propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    hoursThousand: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    costHour: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const FormBlock = ({ name, hoursThousand, costHour, t, onChange }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    const checkCompanyName = (value, key) => {
        onChange(key, value.slice(0, 100));
    };

    return (
        <Form>
            <BlockMain title="">
                <div className="width_1-3">
                    <BlockWrap>
                        <WrapInput isRequired label={t("Production Line Name")} name="name">
                            <Input
                                placeholder={t("Production Line Name")}
                                value={name}
                                onChange={value => checkCompanyName(value, "name")}
                            />
                        </WrapInput>
                        <Block>
                            <BlockWrap>
                                <WrapInput label={t("hrs/1000 lbs")} name="hours">
                                    <Input
                                        placeholder={t("hrs/1000 lbs")}
                                        value={hoursThousand}
                                        onChange={value => onChangeInput(value, "hoursThousand")}
                                    />
                                </WrapInput>
                            </BlockWrap>
                            <BlockWrap>
                                <WrapInput label={t("$/hr")} name="costHour">
                                    <Input
                                        placeholder={t("$/hr")}
                                        value={costHour}
                                        onChange={value => onChangeInput(value, "costHour")}
                                    />
                                </WrapInput>
                            </BlockWrap>
                        </Block>
                    </BlockWrap>
                </div>
            </BlockMain>
        </Form>
    );
};

FormBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        hoursThousand: getStoreItem(state, "hoursThousand"),
        costHour: getStoreItem(state, "costHour"),
        validate: getStoreItem(state, "form_validate"),
        // errors: getStoreItem(state, "errors"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
