import _configureStore from "App/Store";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { csrf_token, redirect_path } = props;
    const { mainState, appState } = initialStates;

    const initialState = {
        listState: { ...mainState, csrf_token, redirect_path },
        appState: { ...appState, pref: "login" },
    };

    return _configureStore(initialState, reducers);
}
