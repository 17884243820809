import {request, url} from 'Services';

const getItems = (data, listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: 'GET',
    };
    return request.sendRequest(sendObj);
};

export const requestAttach = attachUrl => data => {
    const sendObj = {
        url: url.getUrl(attachUrl),
        data,
        type: 'POST',
    };

    return request.sendRequest(sendObj);
};

export const sendNotifications = async (data) => {
    const sendObj = {
        url: url.getUrl(`/admin/users/notification-settings`),
        data,
        type: 'POST',
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const requestTable = listUrl => data => {
    return new Promise(resolve => {
        getItems(data, listUrl).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
            url.addHistory(data);
        });
    });
};

export const resendProfileInvitation = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "POST",
    };

    return request.sendRequest(sendObj);
};