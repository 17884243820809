import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const InfoHeader = ({ pref, children }) => (
    <div className={`${pref}__info-header`}>{children}</div>
);

InfoHeader.defaultProps = {
    children: "",
};

export default compose(withTagDefaultProps())(InfoHeader);
