import React, {Fragment, useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {Input, WrapInput} from "Templates/Form";
import AsyncMultiSelectWithLabel from "Services/AsyncMultiSelect/AsyncMultiSelectWithLabel";
import Img from "Templates/Img/Img";
import Loader from "../../../../../../../laradock/react/src/components/Loader/Loader";
import {getUsersSharedWith} from "../Services/RequestService";

const {Button, Form, Title} = ModalDefault;

const propTypes = {
    data: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    shareFile: PropTypes.func.isRequired,
};

export const SharedFiles = ({t, data, onClose, shareFile}) => {
    const {id, type} = data;

    const [loading, setLoading] = useState(true)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [sharedWithUsersList, setSharedWithUsersList] = useState([])

    const getSharedData = async () => {
        return getUsersSharedWith(id)
    }

    useEffect(() => {
        getSharedData().then((res) => {
            setSharedWithUsersList(res[0].items)
        }).catch((e) => {
            alert(e)
        }).finally(() => {
            setLoading(false)
        });
    }, [data])

    return loading ? <Loader/> : (
        <Form className="shared-files__modal-form">
            <ButtonClose className="main-modal__form-close" onClick={onClose}/>
            <Title>{t(`Share ${type}`)}</Title>

            <div className="shared-files__modal-form-users">
                <WrapInput name="choseOption" label={t("User Name")}>
                    <Input
                        type="asyncMultiSelectWithLabel"
                        placeholder={t("Search")}
                        url={`files/sharing/${id}/shared-with-users-list`}
                        value={selectedUsers.length ? selectedUsers : ""}
                        onChange={e => {
                            setSelectedUsers(e)
                        }}
                    />
                </WrapInput>
            </div>
            <WrapInput name="choseOption" label={t("Already shared with:")}>
                {sharedWithUsersList.length
                    ? <div className="shared-files__modal-form-users-shared-with">
                    {sharedWithUsersList.map((sharedUser) => {
                        return (
                            <div key={sharedUser.id} className="shared-files__modal-form-users-shared-with-item-wrap">
                                <div className="shared-files__modal-form-users-shared-with-item">
                                    <span className="icon">
                                        {sharedUser.user_photo ?
                                            <img src={`${sharedUser.user_photo}`} alt=""/>
                                            : <Img img="icon_contact_small" alt=""/>
                                        }
                                    </span>
                                    <p>{sharedUser.name}</p>
                                </div>
                                <button type="button" onClick={() => {
                                    setSharedWithUsersList((prev) => {
                                        const result = prev.filter((user) => user.id !== sharedUser.id)

                                        return [...result]
                                    })
                                }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="12" fill="#778CA2" fillOpacity="0.1"/>
                                        <path d="M8 8L16 16M16 8L8 16" stroke="#778CA2" strokeOpacity="0.8" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        )
                    })}
                </div>
                    : <p className="empty">No shared users yet</p>
                }
            </WrapInput>

            <div className="shared-files__modal-buttons-wrap">
                <button type="button" className="cancel-btn" onClick={onClose}>
                    {t("Cancel")}
                </button>
                <Button type="button" className="apply-btn" onClick={() => {
                    onClose()
                    shareFile(id, type, selectedUsers, sharedWithUsersList)
                }}>
                    {t("Save")}
                </Button>
            </div>
        </Form>
    )
}

SharedFiles.propTypes = propTypes;

const mapStateToProps = () => {
};

const mapDispatchToProps = () => {
};
export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SharedFiles);
