import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducer, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { companyName, role, dashboardType, position, financialYear, type, dashboard, help } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "dashboard" }),
        listState: { ...mainState, companyName, dashboardType, position, role, financialYear, type, dashboard, help },
    };

    return _configureStore(initialState, reducer);
}