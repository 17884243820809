import React from "react";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { getString, formatDate, storageUrl } from "Services";
import _ from "lodash";
import TableRowItemFolderLinkImg from "Templates/Table/TableRowItemFolderLinkImg";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            const propList = {
                File: {
                    ico: "icon-file",
                    target: "_blank",
                    href: storage_url => storageUrl(getString(item, "path", ""), storage_url),
                    label: getString(item, key),
                    noLink: false
                },
            };

            return (
                <TableRowItemFolderLinkImg
                    title={getString(item, key)}
                    className="files-img"
                    type={"File"}
                    settings={propList}
                >
                    {getString(item, key)}
                </TableRowItemFolderLinkImg>
            );

        case "modified_by":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "modified_by_id")}`}
                    img={getString(item, "modified_by_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "date":
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
