import { url } from "Services";
import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable } from "./RequestService";
import { getFilter, getTableLabel, isSortEnabled } from "./TableHeaderService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getViewItem, modifierValues, tableButtonsPermission } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/product/products/${id}/projects`),
    onClickEditItem: item => url.redirect(`/product/projects/${item.id}/edit`),
    onClickDeleteItem: item => deleteItem(`/product/projects/${item.id}/delete`),
    getViewItem,
    getFilter: getFilter(id),
    modifierValues,
    tableButtonsPermission,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
    isSortEnabled,
});
