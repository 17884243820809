import React from "react";
import {useSelector} from 'react-redux';
import _ from "lodash";
import {weightUnitConvertLbs, weightUnitName} from 'Services/weightUnitName';
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {url, numberWithCommas} from "Services";
import {InputFilter} from "Templates/Table/Filters";
import {
    gradeStatus,
    industryOptions, isSapImported,
    projectBudget,
    projectConsistent,
    projectStatusActive,
    projectStatusArchived, stageGates
} from "Services/enum";
import tableColumns from "../Constants/tableColumns";
import tabList from "../Constants/tabList";

export const getTableLabel = (key, {currentFY}) => {
    const unit = useSelector(state => state.weightUnitState);

    const tableLabel = {
        ...tableColumns,
        weight_value: `Part Weight (${weightUnitConvertLbs(weightUnitName(unit.weightUnit))})`,
        actual_sale_price: `Price ($/${weightUnitName(unit.weightUnit)})`,
    };

    switch (key) {
        case "volume":
        case "revenue":
        case "gross_margin":
            return `FY ${currentFY}  ${_.get(tableLabel, key, key)}`;

        case String(key.match(/^volume_.*/)):
        case String(key.match(/^revenue_.*/)):
        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.split("_");
            return `${keys[1]} ${keys[2]} <br/> Forecast ${_.get(tableLabel, keys[0], key)}`;
        }
        default:
            return _.get(tableLabel, key, key);
    }
};

export const getTooltip = key => {
    const tableTooltip = {
        customer_duns_number: "D-U-N-S is a registered trademark D&B",
        grade_sub_status: `
            <div class="long-tooltip"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</div>
            <div class="long-tooltip"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</div>
            <div class="long-tooltip"><span class="font-medium">Production Intent</span> - Product in process for being approved.</div>
            <div class="long-tooltip"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</div>
            <div class="long-tooltip"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</div>
            <div class="long-tooltip"><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</div>
        `,
        slug: `
            <div class="long-tooltip">If a project is repeated in a Connection, then the Project has at least more then one of the following:
                </br>
                <ol>
                    <li>- Team Member</li>
                    <li>- IHS LVP / Unit Forecast</li>
                </ol>
            </div>
            `,
    };

    return _.get(tableTooltip, key, null);
};

export const getFilter = (key, value, callback, onChange, request) => {
    const totalAnalytics = request && request.totalAnalytics && Object.keys(request.totalAnalytics).length ? request.totalAnalytics : null
    const unit = useSelector(state => state.weightUnitState);

    const placeholders = {
        slug: "Search",
        name: "Search",
        owner: "Search",
        sub_status: "Select",
        stage_gate: "Select",
        status: "Select",
        budget: "Select",
        industry: "Search",
        application_code: "Search",
        sub_segment: "Search",
        application: "Search",
        product: "Search",
        grades: "Search",
        grade_sub_status: "Select",
        material_numbers: "Search",
        product_codes: "Search",
        product_application_codes: "Search",
        plants: "Search",
        lines: "Search",
        customer: "Search",
        customer_code: "Search",
        customer_nickname: "Search",
        customer_duns_number: "Search",
        customer_owner: "Search",
        manufacturer: "Search",
        tier1: "Search",
        tier1_nickname: "Search",
        tier2: "Search",
        tier2_nickname: "Search",
        molder: "Search",
        molder_nickname: "Search",
        unit: "Search",
        sop: "Search",
        move_to_current: "Start Date",
        end_date: "End Date",
        eop: "Search",
        los_date: "Search",
        created_at: "Search",
        updated_at: "Search",
        sub_status_changed_at: "Search",
        manager: "Search",
        internal_team: "Search",
        platform: "Search",
        program: "Search",
        brand: "Search",
        nameplate: "Search",
        country: "Search",
        region: "Search",
        production_plant: "Search",
        city: "Search",
        volume: "Search",
        revenue: "Search",
        gross_margin: "Search",
        actual_sale_price: "Search",
        weight_value: "Search",
        unit_value: "Search",
        company_is_sap: "Search",
        grade_is_sap: "Search",
        replacement_business_project_id: "Search",
        replacement_business_project_name: "Search",
        note: "Search",
        description: "Search",
    };

    switch (key) {
        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-slug"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-list"
                    name="name"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/owner-list"
                    name="owner"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "product":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/product-list"
                    name="product"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("products", _value, request));
                    }}
                />
            );

        case "grades":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/grade-list"
                    name="grades"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("grades", _value, request));
                    }}
                />
            );

        case "material_numbers":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/material-number-list"
                    name="material_numbers"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("material_numbers", _value, request));
                    }}
                />
            );

        case "company_is_sap":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "grade_is_sap":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "grade_sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={gradeStatus}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "product_codes":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="project/projects/product-code-list"
                    name="product_codes"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("product_codes", _value, request));
                    }}
                />
            );

        case "product_application_codes":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-code-list"
                    name="product_application_codes"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("product_application_codes", _value, request));
                    }}
                />
            );

        case "plants":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/plant-list"
                    name="plants"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("plants", _value, request));
                    }}
                />
            );

        case "lines":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/line-list"
                    name="lines"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("lines", _value, request));
                    }}
                />
            );

        case "internal_team":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name="internal_team"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "brand":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/brand-list"
                    name="brand"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("brand", _value, request));
                    }}
                />
            );

        case "nameplate":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/nameplate-list"
                    name="nameplate"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("nameplates", _value, request));
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={
                        +url.getActiveTab() === tabList.archive ? projectStatusArchived : [...projectStatusActive]
                    }
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "stage_gate":
            return (
                <InputFilter
                    type="multiSelect"
                    name={key}
                    value={value}
                    options={stageGates}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "status":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...projectConsistent]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "budget":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...projectBudget]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-code-list"
                    name="application_code"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sub_segment":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/sub-segment-list"
                    name="sub_segment"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-list"
                    name="application"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "manufacturer":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/manufacturer-forecast-list"
                    name="manufacturer"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "platform":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/platform-list"
                    name="platform"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "program":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/program-list"
                    name="program"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "city":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/city-list"
                    name="city"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "country":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/country-list"
                    name="country"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "region":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/region-list"
                    name="region"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "production_plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/production-plant-list"
                    name="production_plant"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "unit":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/unit-list"
                    name="unit"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "industry":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={industryOptions}
                    placeholder={_.get(placeholders, key)}
                    name="industry"
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "move_to_current":
        case "end_date":
        case "sop":
        case "eop":
        case "los_date":
        case "created_at":
        case "updated_at":
        case "sub_status_changed_at":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer":
        case "tier1":
        case "tier2":
        case "molder":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_duns_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-duns-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_nickname":
        case "tier1_nickname":
        case "tier2_nickname":
        case "molder_nickname":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-nickname-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "manager":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "volume":
        case "revenue":
        case "gross_margin": {
            return (
                <InputFilter
                    disabled
                    type="text"
                    placeholder=""
                    value=""
                    onChange={() => {
                    }}
                />
            );
        }

        case String(key.match(/^volume_.*/)): {
            const keys = key.toLowerCase().split("_");
            const data = (totalAnalytics && totalAnalytics[keys[2]][keys[1]].volume) || "0"; // TODO: ramda на другой ветке, нежен set
            return (
                <TableRowItemSpan
                    className="main-text-bold main-text-right"
                    title={`${numberWithCommas(data, 0)} ${weightUnitName(unit.weightUnit)}`}
                >
                    {`${numberWithCommas(data, 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^revenue_.*/)): {
            const keys = key.toLowerCase().split("_");
            const data = (totalAnalytics && totalAnalytics[keys[2]][keys[1]].revenue) || "0"; // TODO: ramda на другой ветке, нежен set
            return (
                <TableRowItemSpan className="main-text-bold main-text-right" title={`$${numberWithCommas(data, 0)}`}>
                    {`$${numberWithCommas(data, 0)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.toLowerCase().split("_");
            const data = (totalAnalytics && totalAnalytics[keys[2]][keys[1]].gross_margin) || "0"; // TODO: ramda на другой ветке, нежен set
            return (
                <TableRowItemSpan
                    className={`main-text-bold main-text-right ${data < 0 ? "clr-red" : ""}`}
                    title={`${data}%`}
                >
                    {`${data}%`}
                </TableRowItemSpan>
            );
        }

        case "replacement_business_project_id":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-slug"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "replacement_business_project_name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-list"
                    name="name"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return null;
    }
};

export const isSortEnabled = key => !(/^volume_.*/.test(key) || /^revenue_.*/.test(key) || /^grossMargin_.*/.test(key));
