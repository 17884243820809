import React from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import PropTypes from "prop-types";
import {Modal} from "Templates/Modals";
import UsersModal from "./UsersModal";
import Img, {ImgStorage} from "Templates/Img";
import {showConfirm} from "Services";
import {Button} from "Templates/Default";
import {BlockWrapFlex} from "Templates/Form";
import BlockTitle from "Templates/Form/BlockTitle";
import InviteGuestsModalForm from "./InviteModalForm";

const propTypes = {
    showModal: PropTypes.bool.isRequired,
    showInviteModal: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    openInviteModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    closeInviteModal: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    deleteItem: PropTypes.func.isRequired,
    request: PropTypes.object.isRequired,
    getItems: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
};

const Users = ({
                   t,
                   showModal,
                   showInviteModal,
                   openModal,
                   openInviteModal,
                   closeModal,
                   closeInviteModal,
                   list,
                   deleteItem,
                   pref,
                   request,
                   getItems,
                   handleScroll,
               }) => {

    const onRemoveItem = (id) => {
        if (showConfirm(t('Delete user?'))) {
            deleteItem(id);
        }
    };

    const onScroll = (e) => {
        const {page, pages} = request;
        if (page >= pages) {
            return;
        }
        handleScroll(e).then(() => {
            getItems({page: page + 1});
        })
    };

    return <>
        <BlockWrapFlex>
            <BlockTitle>{t('Shared with')}</BlockTitle>
            {showModal ?
                <Modal onRequestClose={closeModal}>
                    <UsersModal/>
                </Modal> : <div/>}
            <div className={`${pref}__items-list`} onScroll={onScroll}>
                {list.items.length ? list.items.map(({id, name, photo}) => (
                    <div key={`User-${id}`} className="publication-groups__form-row-item">
                        <div className={`${pref}__form-block-data`}>
                            {
                                photo ?
                                    <ImgStorage url={photo} className="publication-groups__user-img"/> :
                                    <Img img="icon_contact_small" className="publication-groups__user-img" width="22.5"
                                         height="22.5"/>
                            }
                            <span>{name}</span>
                        </div>
                        <button type="button" className={`${pref}__btn-delete`} onClick={() => onRemoveItem(id)}>
                            <Img img={'icon_delete'} width="12" height="16"/>
                        </button>
                    </div>)) : null}
            </div>
            <div className="users-actions__wrap">
                <Button
                    onClick={openModal}
                    className="size_small main-btn tds_header_suppliers_btn main-btn_white">{t('Add ComOpps User')}</Button>
                <Button
                    onClick={openInviteModal}
                    className="size_small main-btn tds_header_suppliers_btn main-btn_primary">{t('Add Guest User')}</Button>
            </div>
        </BlockWrapFlex>
        {showInviteModal ? (
            <Modal onRequestClose={closeInviteModal}>
                <InviteGuestsModalForm />
            </Modal>
        ) : <div />}
    </>
};

Users.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        showModal: getStoreItem(state, 'showModal'),
        showInviteModal: getStoreItem(state, 'showInviteModal'),
        list: getStoreItem(state, "list"),
        request: {
            ...getStoreItem(state, 'list.pagination'),
        }
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore, handleScroll} = service;
    return {
        openModal: getActionStore("openModal", service, dispatch),
        openInviteModal: getActionStore("openInviteModal", service, dispatch),
        closeModal: getActionStore("closeModal", service, dispatch),
        closeInviteModal: getActionStore("closeInviteModal", service, dispatch),
        deleteItem: getActionStore("deleteItem", service, dispatch),
        getItems: getActionStore("getItems", service, dispatch),
        handleScroll,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Users);
