import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

export const Modal = ({
    shouldCloseOnOverlayClick,
    ariaHideApp,
    overlayClassName,
    className,
    isOpen,
    onRequestClose,
    children,
}) => (
    <ReactModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        ariaHideApp={ariaHideApp}
        overlayClassName={overlayClassName}
        className={className}
        onRequestClose={onRequestClose}
    >
        {children}
    </ReactModal>
);

Modal.defaultProps = {
    isOpen: true,
    shouldCloseOnOverlayClick: false,
    ariaHideApp: false,
    overlayClassName: "main-modal",
    className: "main-modal-content",
};

Modal.propTypes = {
    overlayClassName: PropTypes.string,
    className: PropTypes.string,
    shouldCloseOnOverlayClick: PropTypes.bool,
    ariaHideApp: PropTypes.bool,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
};
