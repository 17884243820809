import _ from "lodash";
import Constants from "../../Constants";
import {validate} from "../../Services/Reducers/LogicService";

const applicationModel = {
    code: '',
    market_segment: '-',
    segment: '-',
    sub_segment: '',
};

export const initialState = {
    application: [{...applicationModel}]
};

export const reducer = (state = initialState, action) => {
    const {data} = action;

    switch (action.type) {
        case Constants.CHANGE_APPLICATION_CODE_REQUESTED:
            return {...state, form_loading: true};

        case Constants.ADD_APPLICATION_ITEM:
            const prevApplication = state.applicationBlock.application[state.applicationBlock.application.length - 1];
            state.applicationBlock.application.push({
                ...applicationModel,
                frontId: prevApplication.frontId + 1,
                unique: true
            });

            return {
                ...state,
                form_validate: validate(state),
                applicationBlock: {
                    ...state.applicationBlock,
                    application: [...state.applicationBlock.application]
                }
            }

        case Constants.REMOVE_APPLICATION_ITEM:
            const removalItem = state.applicationBlock.application.findIndex((item) => item.frontId === data.frontId);
            state.applicationBlock.application.splice(removalItem, 1);

            if (!state.applicationBlock.application.length) {
                state.applicationBlock.application.push({
                    ...applicationModel,
                    frontId: 0,
                    unique: true
                })
            }

            return {
                ...state,
                applicationBlock: {
                    ...state.applicationBlock,
                    application: [...state.applicationBlock.application]
                }
            }

        case Constants.CHANGE_SUB_SEGMENT: {
            const {
                applicationBlock: {application},
            } = state;

            const applicationBlock = state.applicationBlock.application.find((elem, index) => index === data.index)
            _.set(applicationBlock, "sub_segment", data.value);

            return {
                ...state,
                form_validate: validate(state),
                applicationBlock: {
                    ...state.applicationBlock,
                    application: [...application]
                },
            };
        }

        case Constants.CHANGE_APPLICATION_CODE_SUCCEEDED: {
            const newData = data.index !== undefined ? data : {
                index: 0,
                value: '',
                industry: '-',
                segment: '-',
            };

            const applicationBlock = state.applicationBlock.application.find((elem, index) => index === newData.index);
            _.set(applicationBlock, "code", newData.value);
            _.set(applicationBlock, "market_segment", newData.industry ? newData.industry : '-');
            _.set(applicationBlock, "segment", newData.segment ? newData.segment : '-');
            _.set(applicationBlock, "sub_segment", '');

            const uniqueList = [];
            const application = state.applicationBlock.application.map(item => ({
                ...item,
                unique: (() => {
                    if (item.code && uniqueList.includes(item.code)) {
                        return false;
                    }
                    uniqueList.push(item.code);
                    return true;
                })(),
            }));

            return {
                ...state,
                form_loading: false,
                form_validate: validate(state),
                applicationBlock: {...state.applicationBlock, application},
            };
        }

        case Constants.CHANGE_APPLICATION_CODE_FAILED:
            return {...state, form_errors: {...data}, form_loading: false};

        default: {
            return {...state};
        }
    }
};
