import React, { Component } from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { customTable } from "./TabViewDetailWrap";
import ContainerTable from "./ContainerTable";
import { TabWrap } from "Templates/Content";
import TableHeadColumnWrap from "./TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {};

class Compare extends Component {
    render = () => {
        return (
            <TabWrap
            >
                <TabViewDetail />
            </TabWrap>
        );
    };
}

Compare.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {};
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Compare);
