import React from "react";
import _ from "lodash";
import TableRowItemFolderLinkImg from "Templates/Table/TableRowItemFolderLinkImg";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import {getString, formatDate, storageUrl} from "Services";
import {listTypes} from "./TableHeaderService";

export const modifierSharedValues = items =>
    items.map(item => ({
        ...item,
        type: listTypes[item.type].label || "",
    }));

export const getSharedViewItem = (key, item) => {
    switch (key) {
        case "name":
            const propList = {
                Folder: {
                    ico: "icon-folder",
                    href: () => `files/${getString(item, "id")}${item.sufix ? `${item.sufix}` : ""}`,
                    label: getString(item, key),
                },
                File: {
                    ico: "icon-file",
                    target: "_blank",
                    href: storage_url => storageUrl(getString(item, "path", ""), storage_url),
                    label: getString(item, key),
                    noLink: !item.can_edit
                },
            };
            return (
                <TableRowItemFolderLinkImg
                    title={getString(item, key)}
                    className="files-img"
                    type={getString(item, "type")}
                    settings={propList}
                >
                    {getString(item, key)}
                </TableRowItemFolderLinkImg>
            );

        case "shared_date":
            return (
                <TableRowItemSpan title={formatDate(getString(item, key))}>
                    {formatDate(getString(item, key))}
                </TableRowItemSpan>
            );

        case "shared_by":
            return item.can_edit === 10 ? (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "shared_by_id")}`}
                    img={getString(item, "shared_by_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            ) : (<TableRowItemSpan>{getString(item, key)}</TableRowItemSpan>);

        default:
            return <TableRowItemSpan>{getString(item, key, "")}</TableRowItemSpan>;
    }
};

export const customSharedIcon = items => index => {
    const type = _.get(items, `[${index}].type`);

    const label = _.get(listTypes, `[${type}].label`);
    return label === "File" ? "icon_download" : "";
};
