import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
};
const defaultProps = {
    className: '',
    title: ''
}

const TabBlockBtn = ({ pref, children, onClick, className, title }) => (
    <button
        title={title}
        type="button"
        className={`${pref}__btn main-btn__general main-btn ${className ? `${className}` : "main-btn_primary"}`}
        onClick={onClick}
    >
        {children}
    </button>
);

TabBlockBtn.propTypes = propTypes;
TabBlockBtn.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TabBlockBtn);
