import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form, BlockWrap, Input, WrapInput, BlockImgWithCrop, BlockMain } from "Templates/Form";

const propTypes = {
    name: PropTypes.string.isRequired,
    logo: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]).isRequired,
    logoView: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
};

const FormBlock = ({ name, logo, logoView, onChange, t }) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    const onChangeLogo = (value, key) => {
        onChange(key, value);
        onChange('logoView', value);
    };

    return (
        <Form>
            <BlockMain title="General Info">
                <BlockWrap>
                    <WrapInput isRequired label={t("Product Source Name")} name="name">
                        <Input
                            placeholder={t("Product Source")}
                            value={name}
                            onChange={value => onChangeInput(value, `name`)}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockImgWithCrop name="logo" value={logoView} title={t("Photo")} onChange={value => onChangeLogo(value, `logo`)} />
            </BlockMain>
        </Form>
    );
};

FormBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        logo: getStoreItem(state, "logo"),
        logoView: getStoreItem(state, "logoView"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
