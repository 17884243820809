import React from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {TabWrap} from "Templates/Content";
import {CustomSimpleColumnTable} from "Hoc/Widgets/Table/SimpleColumnTableContainer";
import InvoiceLineColumn from "./Table/InvoiceLineColumn";

const InvoiceLineTable = CustomSimpleColumnTable(InvoiceLineColumn);

const Invoice = (props) => {
    const {pref} = props;

    return (
        <>
            <TabWrap>
                <div className={`${pref}__wrap-overflow`}>
                    <InvoiceLineTable/>
                </div>
            </TabWrap>
        </>
    );
}

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Invoice);
