import { handleScroll } from "../../EventsService";
import { requestOnUploadFiles, getItem, requestOnSaveCopy, getUploadUrl } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { deleteItem, requestTable } from "../../RequestService";
import { getFilter, getTableLabel } from "../../TableHeaderService";
import { getViewItem, modifierValues } from "../../TableBodyService";

export default (id, safetyId) => ({
    requestTable: requestTable(`/product/publications/${id}/safeties/${safetyId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    modifierValues,
    getTableLabel,
    requestSubTab: () => getItem(`/product/product-finder/publications/${id}/safeties`),
    requestTypeItem: (item) => getItem(`/product/product-finder/publications/${id}/safeties/${item.id}`),
    requestOnUploadFiles: requestOnUploadFiles(`/product/publications/${id}/safeties/add`),
    requestOnSaveCopy: (safetyId) => requestOnSaveCopy(`/product/safeties/${safetyId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/safeties/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/safeties/${safetyId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (type) => getUploadUrl(safetyId, type),
});
