import React from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {ServiceProvider, withServiceConsumer} from 'Services/Context';
import {withTagDefaultProps} from 'Hoc/Template';
import {url} from 'Services';
import {ContentWrap, ContentWrapCol} from 'Templates/Content';
import InfoWrap from './Views/InfoWrap';
import ApprovalsTable from './Views';
import approvalsService from './Services/Approval';

const Approvals = (
    {
        t,
        items,
    }) => {

    const handleReset = () => {
        url.clearHistory();
    }

    return (
        <>
            <ContentWrap>
                <ServiceProvider value={approvalsService}>
                    <InfoWrap />
                </ServiceProvider>
                <ContentWrapCol>
                    <div className="d-flex flex-col h-100">
                        <ApprovalsTable/>
                    </div>
                </ContentWrapCol>
            </ContentWrap>
        </>
    );
};

Approvals.defaultProps = {
    items: []
}

Approvals.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = (state, { service }) => {
    const { getStoreItem } = service;

    return {
        items: getStoreItem(state, 'items'),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Approvals);
