import Constants from "../Constants";
import {EMPTY_CHART} from "../Constants/graph-v2-types";

export const initState = {
    graphV2: {},
    selectedValue: "all",
};

export const reducer = (state, action) => {
    const {data} = action;

    switch (action.type) {
        case Constants.GET_CHART_DATA_REQUESTED:
            return { ...state, loading: true };

        case Constants.GET_CHART_DATA_SUCCEEDED:
            return {
                ...state,
                selectedValue: data.selectedValue,
                graphV2: Object.keys(data.items).length > 0 ? data.items : EMPTY_CHART,
                loading: false,
            };

        case Constants.GET_CHART_DATA_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        default:
            return state;
    }
}