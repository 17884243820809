import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {uniqueGenerator} from "Services";
import Tab from "../../../../../../Templates/Tabs/Tab";


const propTypes = {
    activeTab: PropTypes.number.isRequired,
    onClickTabItem: PropTypes.func.isRequired,
};

const Tabs = ({ children, onClickTabItem, activeTab, pref }) => {
    return (
        <>
            <ul className={`${pref}__tabs main-content__tabs`}>
                {children.map((child, index) => {
                    if (!child) return undefined;

                    const adjustedIndex = index + 1;

                    if (child.type === 'div') {
                        const { label } = child.props;

                        return (
                            <Tab
                                index={adjustedIndex}
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                onClick={onClickTabItem}
                            />
                        );
                    }

                    return React.cloneElement(child, {
                        index: adjustedIndex,
                        pref: pref,
                        key: uniqueGenerator(),
                        activeTab: activeTab,
                        onClick: onClickTabItem
                    });
                })}
            </ul>
            <div className={`${pref}__wrap-change main-content__wrap-change ${pref}__tab-item-${activeTab}`}>
                {children.map((child, index) => {
                    const adjustedIndex = index + 1;

                    if (adjustedIndex !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </>
    );
};

Tabs.propTypes = propTypes;

export default compose(withTagDefaultProps())(Tabs);