import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {formatDate} from "Services";
import {ComoppsEditor} from "Templates/Input";
import {RadioBlock, RadioButton, RadioLabel} from "Templates/Form/Radio";

const { Button, Form, Title, Row, Col, ColInput, ColText } = ModalDefault;

const defaultProps = {
    oldValue: {},
    editValue: {},
};

const propTypes = {
    service: PropTypes.shape({
        requestAttach: PropTypes.func.isRequired,
        requestEdit: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    editValue: PropTypes.objectOf(PropTypes.any),
    onClose: PropTypes.func.isRequired,
    button: PropTypes.string.isRequired,
    oldValue: PropTypes.objectOf(PropTypes.any),
    fetchItems: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
};

const radioButtons = [
    {
        label: 'Enable',
        value: 10
    },
    {
        label: 'Disable',
        value: 5
    }
]

class AddAlert extends Component {
    state = {
        form: {
            id: "",
            message: "",
            status: 10,
            startDate: '',
            endDate: '',
        },
    };

    componentDidMount() {
        const { editValue } = this.props;

        if (editValue.id) {
            const form = {
                id: editValue.id,
                startDate: editValue.start_date ? formatDate(editValue.start_date, "YYYY-MM-DD") : '',
                endDate: editValue.end_date ? formatDate(editValue.end_date, "YYYY-MM-DD") : '',
                message: editValue.message,
                status: editValue.status,
            };
            this.setState({
                form: {...form},
                oldValue: {...form},
            });
        } else {
            this.setState({
                form: {
                    id: "",
                    message: "",
                    status: 10,
                    startDate: '',
                    endDate: '',
                },
            });
        }
    }

    componentWillUnmount() {
        const { resetForm } = this.props;
        resetForm();
    }

    handleChange = (value, name) => {
        this.setState(state => {
            const form = { ...state.form };
            form[name] = value;
            state.form = form;

            return state;
        });
    };

    onAppend = () => {
        const {
            fetchItems,
            service: { requestAttach, requestEdit },
            onClose
        } = this.props;
        const { form } = this.state;

        const request = form.id ? requestEdit({ id: form.id }) : requestAttach;

        request({
            startDate: form.startDate ? formatDate(form.startDate, "YYYY-MM-DD") : '',
            endDate: form.endDate ? formatDate(form.endDate, "YYYY-MM-DD") : '',
            status: form.status,
            message: form.message,
        }).then(() => {
            onClose();
            fetchItems({});
        });
    };

    render() {
        const { t, onClose } = this.props;
        const { form, oldValue } = this.state;

        const disabled = !form.message.trim() || JSON.stringify(oldValue) === JSON.stringify(form);

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose} />
                <Title>{t(form.id ? "Edit Alert" : "Add Alert")}</Title>
                <Row>
                    <Col>
                        <ColText isRequired>{t("Message")}</ColText>
                        <ComoppsEditor
                            text={form.message}
                            isModal={true}
                            onChange={value => this.handleChange(value, "message")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColText>{t("Start Date")}</ColText>
                        <ColInput
                            type="date-select"
                            name="startDate"
                            value={form.startDate}
                            onChange={value => this.handleChange(value, "startDate")}
                        />
                    </Col>
                    <Col>
                        <ColText>{t("End Date")}</ColText>
                        <ColInput
                            type="date-select"
                            name="endDate"
                            value={form.endDate}
                            onChange={value => this.handleChange(value, "endDate")}
                        />
                    </Col>
                    <div className="alert-radio-btns">
                        <Col>
                            {radioButtons.map(elem => (
                                <RadioBlock key={`Col-${elem.value}`}>
                                    <RadioButton
                                        htmlFor={`radio_1-${elem.value}`}
                                        name={`radio-${elem.value}`}
                                        value={form.status === elem.value}
                                        onChange={() => {
                                            this.handleChange(elem.value, 'status')
                                        }}
                                    >
                                        <RadioLabel label={t(elem.label)} />
                                    </RadioButton>
                                </RadioBlock>
                            ))}
                        </Col>
                    </div>
                </Row>
                <Button onClick={this.onAppend} disabled={disabled}>
                    {t("Save Alert")}
                </Button>
            </Form>
        );
    }
}

AddAlert.defaultProps = defaultProps;
AddAlert.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        oldValue: getStoreItem(state, "oldValue"),
        editValue: getStoreItem(state, "editValue"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        resetForm: getActionStore("resetFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddAlert);

