const Constants = {
    INIT: "@@INIT",
    IS_ACTIVE_COVERTOR: "IS_ACTIVE",

    CHANGE_FILTER: "CHANGE_FILTER",
    RESET_FILTER: "RESET_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",

    CHANGE_TAB: "CHANGE_TAB",
    EXPORT_PROJECTS: "EXPORT_PROJECTS",
    EXPORT_PROJECTS_SUCCESS: "EXPORT_PROJECTS_SUCCESS",
    EXPORT_PROJECTS_FAILED: "EXPORT_PROJECTS_FAILED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    CREATE_FILES_REQUEST: "CREATE_FILES_REQUEST",
    CREATE_FILES_SUCCESS: "CREATE_FILES_SUCCESS",
    CREATE_FILES_FAILED: "CREATE_FILES_FAILED",

    APPLY_FILTERS: "APPLY_FILTERS",

    RESET_PROJECT_FILTERS: "RESET_PROJECT_FILTERS",
};

export default Constants;
