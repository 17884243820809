import ReactOnRails from "react-on-rails";

import DashboardTypeOneApp from "./DashboardTypeOne/App";
import DashboardTypeOneStore from "./DashboardTypeOne/Store";

import DashboardTypeTwoApp from "./DashboardTypeTwo/App";
import DashboardTypeTwoStore from "./DashboardTypeTwo/Store";

import DashboardTypeThreeApp from "./DashboardTypeThree/App";
import DashboardTypeThreeStore from "./DashboardTypeThree/Store";

import DashboardTypeFourApp from "./DashboardTypeFour/App";
import DashboardTypeFourStore from "./DashboardTypeFour/Store";

import DashboardTypeFiveApp from "./DashboardTypeFive/App";
import DashboardTypeFiveStore from "./DashboardTypeFive/Store";

import AnalyticGraphsV2App from "./AnalyticGraphsV2/App";
import AnalyticGraphsV2Store from "./AnalyticGraphsV2/Store/index"

import DashboardForecastExplorerApp from "./ForecastExplorer/App";
import DashboardForecastExplorerStore from "./ForecastExplorer/Store";

/* analytic/forecast-explorer */
ReactOnRails.registerStore({
    forecastExplorerStore: DashboardForecastExplorerStore,
});

ReactOnRails.register({ ForecastExplorerView: DashboardForecastExplorerApp("forecastExplorerStore") });
/* analytic/forecast-explorer */

/* one */
ReactOnRails.registerStore({
    dashboardTypeOneStore: DashboardTypeOneStore,
});

ReactOnRails.register({ dashboardTypeOneView: DashboardTypeOneApp("dashboardTypeOneStore") });
/* one */

/* two */
ReactOnRails.registerStore({
    dashboardTypeTwoStore: DashboardTypeTwoStore,
});

ReactOnRails.register({ dashboardTypeTwoView: DashboardTypeTwoApp("dashboardTypeTwoStore") });
/* two */

/* three */
ReactOnRails.registerStore({
    dashboardTypeThreeStore: DashboardTypeThreeStore,
});

ReactOnRails.register({ dashboardTypeThreeView: DashboardTypeThreeApp("dashboardTypeThreeStore") });
/* three */

/* four */
ReactOnRails.registerStore({
    dashboardTypeFourStore: DashboardTypeFourStore,
});

ReactOnRails.register({ dashboardTypeFourView: DashboardTypeFourApp("dashboardTypeFourStore") });
/* four */

/* five */
ReactOnRails.registerStore({
    dashboardTypeFiveStore: DashboardTypeFiveStore,
});

ReactOnRails.register({ dashboardTypeFiveView: DashboardTypeFiveApp("dashboardTypeFiveStore") });
/* five */

/* GraphsV2 */
ReactOnRails.registerStore({
    forecastV2ViewStore: AnalyticGraphsV2Store,
});

ReactOnRails.register({ ForecastV2View: AnalyticGraphsV2App("forecastV2ViewStore") })
/* GraphsV2 */