import React from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { withServiceConsumer, ServiceProvider } from "Services/Context";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";

import SearchBlock from "./Views/SearchBlock";

import companiesService from "./Services/CompanieTableService";
import contactService from "./Services/ContactTableService";
import gradeService from "./Services/GradeTableService";
import plantService from "./Services/PlantTableService";
// import productService from "./Services/ProductTableService";
import projectService from "./Services/ProjectTableService";
import rawService from "./Services/RawTableService";
// import taskService from "./Services/TaskTableService";

const defaultProps = {
    data: {},
};

const propTypes = {
    data: PropTypes.shape({
        companies: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object).isRequired }).isRequired,
        contacts: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object).isRequired }).isRequired,
        grades: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object).isRequired }).isRequired,
        plants: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object).isRequired }).isRequired,
        products: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object).isRequired }).isRequired,
        raws: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object).isRequired }).isRequired,
        projects: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object).isRequired }).isRequired,
        tasks: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object).isRequired }).isRequired,
    }),
    searchValue: PropTypes.string.isRequired,
};

const Search = ({ data, searchValue, t }) => {
    const notEmpty = key => _.get(data, `[${key}].items`, []).length > 0;

    const allEmpty = () =>
        ["companies", "contacts", "grades", "plants", "raws", "projects"].every(
            key => !notEmpty(key),
        );

    return (
        <>
            <div className="main-content__wrap-title">
                <div className="main-content__wrap-block">
                    <h1 className="main-content__h1">{t("Search Results")}</h1>
                </div>
            </div>
            <div className="search main-wrap-block">
                <div className="main-wrap-block__body">
                    {allEmpty() && t("No search results")}

                    {notEmpty("companies") && (
                        <ServiceProvider value={companiesService(searchValue)}>
                            <SearchBlock />
                        </ServiceProvider>
                    )}

                    <CheckPermission permission="contacts_view">
                        {notEmpty("contacts") && (
                            <ServiceProvider value={contactService(searchValue)}>
                                <SearchBlock />
                            </ServiceProvider>
                        )}
                    </CheckPermission>

                    {notEmpty("plants") && (
                        <ServiceProvider value={plantService(searchValue)}>
                            <SearchBlock />
                        </ServiceProvider>
                    )}

                    {notEmpty("raws") && (
                        <ServiceProvider value={rawService(searchValue)}>
                            <SearchBlock />
                        </ServiceProvider>
                    )}

                    {notEmpty("projects") && (
                        <ServiceProvider value={projectService(searchValue)}>
                            <SearchBlock />
                        </ServiceProvider>
                    )}

                    {/* {notEmpty("tasks") && ( */}
                    {/*     <ServiceProvider value={taskService(searchValue)}> */}
                    {/*         <SearchBlock /> */}
                    {/*     </ServiceProvider> */}
                    {/* )} */}

                    {notEmpty("grades") && (
                        <ServiceProvider value={gradeService(searchValue)}>
                            <SearchBlock />
                        </ServiceProvider>
                    )}

                    {/* {notEmpty("products") && ( */}
                    {/*     <ServiceProvider value={productService(searchValue)}> */}
                    {/*         <SearchBlock /> */}
                    {/*     </ServiceProvider> */}
                    {/* )} */}
                </div>
            </div>
        </>
    );
};

Search.defaultProps = defaultProps;
Search.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        data: {
            companies: getStoreItem(state, "companies"),
            contacts: getStoreItem(state, "contacts"),
            grades: getStoreItem(state, "grades"),
            plants: getStoreItem(state, "plants"),
            products: getStoreItem(state, "products"),
            raws: getStoreItem(state, "raws"),
            projects: getStoreItem(state, "projects"),
            tasks: getStoreItem(state, "tasks"),
        },
        searchValue: getStoreItem(state, "searchValue"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Search);
