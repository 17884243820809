import React from "react";
import PropTypes from "prop-types";

const defaultProps = {
    isRequired: false,
    className: "",
};

const propTypes = {
    isRequired: PropTypes.bool,
    className: PropTypes.string,
};

const ColText = ({ className, children, isRequired }) => (
    <div className={`main-modal__form-col-text ${className} ${isRequired ? "required" : ""}`}>{children}</div>
);

ColText.defaultProps = defaultProps;
ColText.propTypes = propTypes;

export default ColText;
