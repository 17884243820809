import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const { plant, id, name, hoursThousand, costHour } = props;

    const initData = { plant, id, name, hoursThousand, costHour };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "plants-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
