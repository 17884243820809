import React, {Fragment, useEffect, useMemo} from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import TableBody from "./TableBody";

const ConnectionBudgetContainerTable = (TableHeadColumn, TableRowColumn, {showButtons = true}) => ({
    request,
    financialYear,
    nextFY,
    columns,
    pinned_columns,
    customIcon,
    fetchItems,
    onClickEdit,
    onClickItem,
    onClickDelete,
    onClickCustom
}) => {
    const showColumns = showButtons ? [...columns, "-1"] : [...columns];

    const headersWidth = useMemo(() => {
        return {
            budget: null,
            stretch: null,
            variance: null,
            actual: null,
            annualized: null,
            current: null,
            next: null,
        }
    }, [showColumns])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.querySelectorAll(`.main-table__head`).forEach((el) => el.removeAttribute('style'));
            document.querySelectorAll(`.main-table__body`).forEach((el) => el.removeAttribute('style'));
            document.querySelectorAll('.last-pinned').forEach((el) => el.classList.remove('last-pinned'));

            if (showColumns.length && pinned_columns.length) {
                const cols = showColumns.reduce((acc, curr) => {
                    if (pinned_columns.includes(curr)) {
                        acc.push(curr)
                    }
                    return acc
                }, [])

                if (cols.length) {
                    let totalWidth = 0;
                    const tableBodyCols = document.querySelectorAll(`.td.pinned-column`)
                    const tableBodyLastCols = document.querySelectorAll(`.td.${cols[cols.length - 1]}`)

                    if (tableBodyCols.length) {
                        tableBodyCols.forEach((el) => {
                            const tooltip = el.querySelector(`.tooltip`)

                            if (tooltip) {
                                el.classList.add('with-tooltip')
                            }
                        })
                    }

                    if (tableBodyLastCols.length) {
                        tableBodyLastCols.forEach((el) => el.classList.add('last-pinned'))
                    }

                    cols.forEach((col, i) => {
                        const elem = document.querySelector(`.th.${col}`);
                        const width = document.querySelector(`.th.${col}`).clientWidth - 10;

                        if (i === (cols.length - 1)) elem.classList.add('last-pinned')

                        if (i !== cols.length) {
                            elem.style.left = `${totalWidth}px`;

                            if (tableBodyCols.length) {
                                tableBodyCols.forEach((bodyCol, index) => {
                                    if ((index % cols.length) === i) {
                                        bodyCol.style.left = `${totalWidth}px`
                                    }
                                })
                            }

                            totalWidth += width;
                        }
                    })
                }
            }
        }
    }, [pinned_columns, request])

    const onSubmit = e => {
        e.preventDefault();

        fetchItems(request);
    };

    const onSortClick = column => {
        const asc = "asc";
        const desc = "desc";
        const newRequest = {
            ...request,
            sort: {
                ...request.sort,
                column,
            },
        };
        if (request.sort.column === column) {
            newRequest.sort.direction = request.sort.direction === asc ? desc : asc;
        } else {
            newRequest.sort.direction = asc;
        }

        fetchItems(newRequest);
    };

    return (
        <>
            <form onSubmit={onSubmit}>
                <table className="main-table">
                    <tbody>
                    <tr className="connections-titles-wrap">
                        {showColumns.map((column, index) => {
                            const col = column.includes('budget') || column.includes('stretch') || column.includes('variance')
                            || column.includes('actual') || column.includes('next')
                            || (column.includes('current') && !column.includes('move_to_current')) ? 'FY' : '';
                            return (
                                <Fragment key={`TableRowColumn-${index}-table-${column}`}>
                                    {column === "-1" ? (
                                        <th key={`TableHeadColumn-${index}-revenue`} className={`main-table__head`}/>
                                    ) : (
                                        <th
                                            key={`TableHeadColumn-${index}-current`}
                                            className={`
                                            connections-titles
                                            main-table__head ${col}
                                            
                                                ${column.includes("budget_price") ? 'connections-budget-title-head first' : ''}
                                                ${column.includes("budget_volume") ? 'connections-budget-title-head' : ''}
                                                ${column.includes("budget_revenue") ? 'connections-budget-title-head last' : ''} 
                                                
                                                ${column.includes("stretch_volume") ? 'connections-stretch-title-head first' : ''}
                                                ${column.includes("stretch_revenue") ? 'connections-stretch-title-head last' : ''}
                                                
                                                ${column.includes("variance_volume") ? 'connections-variance-title-head first' : ''}
                                                ${column.includes("variance_revenue") ? 'connections-variance-title-head last' : ''}
                                                
                                                ${column.includes("actual_calculated_price") ? 'connections-actual-title-head first' : ''}
                                                ${column.includes("actual_normalized_weight") ? 'connections-actual-title-head' : ''} 
                                                ${column.includes("actual_net_amount") ? 'connections-actual-title-head last' : ''} 
                                                
                                                ${column.includes("annualized_volume") ? 'connections-annualized-title-head first' : ''} 
                                                ${column.includes("annualized_revenue") ? 'connections-annualized-title-head last' : ''} 
                                                
                                                ${column.includes("current_average_sale_price") ? 'connections-currents-title-head first' : ''}
                                                ${column.includes("current_volume") ? 'connections-currents-title-head' : ''}
                                                ${column.includes("current_variance_percent") ? 'connections-currents-title-head' : ''}
                                                ${column.includes("current_variance") ? 'connections-currents-title-head' : ''}
                                                ${column.includes("current_total_rebate") ? 'connections-currents-title-head' : ''}
                                                ${column.includes("current_revenue") ? 'connections-currents-title-head' : ''} 
                                                ${column.includes("current_total_revenue") ? 'connections-currents-title-head last' : ''}
                                                
                                                ${column.includes("next_average_sale_price") ? 'connections-next-title-head first' : ''}
                                                ${column.includes("next_volume") ? 'connections-next-title-head' : ''} 
                                                ${column.includes("next_total_rebate") ? 'connections-next-title-head' : ''}
                                                ${column.includes("next_revenue") ? 'connections-next-title-head' : ''} 
                                                ${column.includes("next_total_revenue") ? 'connections-next-title-head last' : ''}
                                        `}>
                                            <div className={`
                                                connections-title-head-wrapper
                                            `}>
                                                {column === 'budget_volume' ? 'Budget' : ''}
                                                {column === 'actual_normalized_weight' ? 'Actuals' : ''}
                                                {column === 'current_variance' ? `Forecast FY ${financialYear}` : ''}
                                                {column === 'next_revenue' ? `Forecast FY ${nextFY}` : ''}
                                            </div>
                                        </th>
                                    )}
                                </Fragment>
                            );
                        })}
                    </tr>
                    <tr className="connections-head-wrap">
                        {showColumns.map((column, index) => {
                            const col = column.includes('budget') || column.includes('stretch') || column.includes('variance')
                            || column.includes('actual') || column.includes('next')
                            || (column.includes('current') && !column.includes('move_to_current')) ? 'FY' : '';
                            return (
                                <Fragment key={`TableRowColumn-${index}-table-${column}`}>
                                    {column === "-1" ? (
                                        <th key={`TableHeadColumn-${index}-revenue`} className={`main-table__head`}/>
                                    ) : (
                                        <th
                                            key={`TableHeadColumn-${index}-current`}
                                            className={`
                                            connections-table-head-column
                                            ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}
                                            
                                            ${column.includes("budget_volume") ||
                                            column.includes("budget_price") ||
                                            column.includes("budget_revenue") ? 'connections-budgets-th' : ''} 
                                            ${column.includes("stretch_volume") ? 'connections-stretch-th FY' : ''}
                                            ${column.includes("stretch_revenue") ? 'connections-stretch-th FY' : ''}
                                            ${column.includes("variance_volume") ? 'connections-variance-th FY' : ''}
                                            ${column.includes("variance_revenue") ? 'connections-variance-th FY' : ''}
                                            ${column.includes("actual_normalized_weight") ||
                                            column.includes("actual_calculated_price") ||
                                            column.includes("actual_net_amount") ? 'connections-actual-th' : ''} 
                                            ${column.includes("annualized_volume") ||
                                            column.includes("annualized_revenue") ? 'connections-annualized-th FY' : ''} 
                                            ${column.includes("current_volume") ||
                                            column.includes("current_average_sale_price") ||
                                            column.includes("current_variance_percent") ||
                                            column.includes("current_variance") ||
                                            column.includes("current_total_rebate") ||
                                            column.includes("current_total_revenue") ||
                                            column.includes("current_revenue") ? 'connections-currents-th' : ''} 
                                            ${column.includes("next_volume") ||
                                            column.includes("next_average_sale_price") ||
                                            column.includes("next_total_rebate") ||
                                            column.includes("next_total_revenue") ||
                                            column.includes("next_revenue") ? 'connections-next-th' : ''} 
                                            ${column === 'actual_calculated_price' ? 'first' : ''} 
                                            ${column === 'current_average_sale_price' ? 'first' : ''} 
                                            ${column === 'next_average_sale_price' ? 'first' : ''} 
                                            ${column === 'budget_revenue' ? 'last' : ''} 
                                            ${column === 'actual_net_amount' ? 'last' : ''} 
                                            ${column === 'annualized_volume' ? 'first' : ''} 
                                            ${column === 'annualized_revenue' ? 'last' : ''} 
                                            ${column === 'current_total_revenue' ? 'last' : ''} 
                                            ${column === 'next_total_revenue' ? 'last' : ''}
                                            ${column === 'stretch_volume' ? 'first' : ''}
                                            ${column === 'stretch_revenue' ? 'last' : ''}
                                            ${column === 'variance_volume' ? 'first' : ''}
                                            ${column === 'variance_revenue' ? 'last' : ''}
                                            
                                            main-table__head ${col}
                                        `}>
                                            <div className={"connections-head-wrapper"}>
                                                <TableHeadColumn onSortClick={onSortClick} column={column} index={index}/>
                                            </div>
                                        </th>
                                    )}
                                </Fragment>
                            );
                        })}
                    </tr>
                    <TableBody
                        columns={showColumns}
                        TableRowColumn={TableRowColumn}
                        customIcon={customIcon}
                        onClickItem={onClickItem}
                        onClickEdit={onClickEdit}
                        onClickDelete={onClickDelete}
                        onClickCustom={onClickCustom}
                    />
                    </tbody>
                </table>
                <button type="submit" className="visually-hidden"/>
            </form>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        columns: getStoreItem(state, "columns"),
        pinned_columns: getStoreItem(state, "pinned_columns"),
        financialYear: +getStoreItem(state, "financialYear"),
        nextFY: +getStoreItem(state, "financialYear") + 1,
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeFilter: getActionStore("onChange", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
        fetchFiltersItems: getActionStore("fetchFiltersItems", service, dispatch),
    };
};

export default (TableHeadColumn, TableRowColumn, {showButtons = true} = {}) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(ConnectionBudgetContainerTable(TableHeadColumn, TableRowColumn, {showButtons}));
