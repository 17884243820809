import React from "react";
import {marginEnum} from "Services/enum";
import {FormBlockLink} from "Templates/Form";
import {Span} from "Templates/Default";
import main, {getSelectedOption} from "../index";
import {formData} from "./FormService";
import {getActionStore, getChartData, getStoreItem} from "./StoreService";
import {getData, getItemOptions} from "../RequestService";

const optionsForFirstSelect = marginEnum;

const optionsForSecondSelect = [
    // { label: "Brand", value: "1", url: "/product/products", urlToOptions: "product-list" },
    { label: "Product", value: "2", url: "/product/product-finder", urlToOptions: "grade-list" },
    { label: "Product Code", value: "3", url: "/product/product-finder", urlToOptions: "code-list" }
];

const getFirstColumnInTheTable = (item, dynamicSelect) => {
    const selectedValue = getSelectedOption(dynamicSelect, optionsForSecondSelect);
    if (selectedValue.urlToOptions === "grade-list") {
        return <FormBlockLink href={`${selectedValue.url}/${item.id}`}>{item.label}</FormBlockLink>;
    }

    return <Span>{item.label}</Span>;
};

export default () => ({
    ...main,
    getData: getData("/forecasts/products"),
    getItems: getItemOptions("/forecasts/products"),
    optionsForFirstSelect,
    optionsForSecondSelect,
    getFirstColumnInTheTable,
    getStoreItem,
    getChartData,
    getActionStore,
    formData,
});
