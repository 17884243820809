import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {formatDate} from "Services";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap
} from "Templates/Content";

const propTypes = {
    invoiceNumber: PropTypes.string.isRequired,
    billingDate: PropTypes.string.isRequired,
    reversalOf: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    billingType: PropTypes.string.isRequired,
    billingCategory: PropTypes.string.isRequired,
    documentCategory: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
};

const InfoWrap = (
    {
        invoiceNumber,
        customer,
        customerId,
        billingDate,
        reversalOf,
        currency,
        billingType,
        billingCategory,
        documentCategory,
        createdBy,
        createdOn,
        t
    }) => (
    <ContentInfoWrap>
        <ContentInfoTop urlBack="/invoice/invoices"/>
        <ContentInfoTitle>{invoiceNumber}</ContentInfoTitle>

        <ContentInfoBlock/>

        <ContentInfoBlock show={!!customer}>
            <ContentInfoBlockTitle>{t("Customer")}</ContentInfoBlockTitle>
            <ContentInfoBlockText
                type="link"
                value={`/companies/${customerId}`}
                title={customer}
                permission="companies_view"
            />
        </ContentInfoBlock>

        <ContentInfoBlock show={!!billingDate}>
            <ContentInfoBlockTitle>{t("Billing Date")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={formatDate(billingDate)}/>
        </ContentInfoBlock>

        <ContentInfoBlock show={!!reversalOf}>
            <ContentInfoBlockTitle>{t("Reversal of")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={reversalOf}/>
        </ContentInfoBlock>

        <ContentInfoBlock show={!!currency}>
            <ContentInfoBlockTitle>{t("Currency")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={currency}/>
        </ContentInfoBlock>

        <ContentInfoBlock show={!!billingType}>
            <ContentInfoBlockTitle>{t("Billing Type")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={billingType}/>
        </ContentInfoBlock>

        <ContentInfoBlock show={!!billingCategory}>
            <ContentInfoBlockTitle>{t("Billing Category")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={billingCategory}/>
        </ContentInfoBlock>

        <ContentInfoBlock show={!!documentCategory}>
            <ContentInfoBlockTitle>{t("Document Category")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={documentCategory}/>
        </ContentInfoBlock>

        <ContentInfoBlock show={!!createdBy}>
            <ContentInfoBlockTitle>{t("Created By")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={createdBy}/>
        </ContentInfoBlock>

        <ContentInfoBlock show={!!createdOn}>
            <ContentInfoBlockTitle>{t("Created On")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={formatDate(createdOn, "MMM DD, YYYY h:mm A")}/>
        </ContentInfoBlock>
    </ContentInfoWrap>
);

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        invoiceNumber: getStoreItem(state, "view.invoiceNumber"),
        customer: getStoreItem(state, "view.customer"),
        customerId: getStoreItem(state, "view.customerId"),
        billingDate: getStoreItem(state, "view.billingDate"),
        reversalOf: getStoreItem(state, "view.reversalOf"),
        currency: getStoreItem(state, "view.currency"),
        billingType: getStoreItem(state, "view.billingType"),
        billingCategory: getStoreItem(state, "view.billingCategory"),
        documentCategory: getStoreItem(state, "view.documentCategory"),
        createdBy: getStoreItem(state, "view.createdBy"),
        createdOn: getStoreItem(state, "view.createdOn"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
