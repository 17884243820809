import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { items, total_analytics, pagination, columns, view, totalAnalytic, financialYear, totalNotes, totalProjects } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "my-forecast-view" }),
        listState: {
            ...mainState,
            items,
            total_analytics,
            pagination,
            columns,
            view,
            totalAnalytic,
            financialYear,
            totalNotes,
            totalProjects,
            sort: { column: columns[0], direction: "asc" },
        },
    };

    return _configureStore(initialState, reducers);
}
