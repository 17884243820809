import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const InputCount = ({ pref, className, children }) => (
    <span className={`${pref}__wrap-input-count ${className}`}>{children}</span>
);

InputCount.propTypes = {
    className: PropTypes.string,
};

InputCount.defaultProps = {
    className: "",
};

export default compose(withTagDefaultProps())(InputCount);
