import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import TableRowColumnButton from "Templates/Table/TableRowColumnWrap/Button";
import TableRowColumnDefault from "Templates/Table/TableRowColumnWrap/Default";

const propTypes = {
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    column: PropTypes.string.isRequired,
    pinned_columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
};

const defaultProps = {
    customIcon: "",
    onClickCustom: () => {
    },
};

const TableRowColumnWrap = React.memo(
    ({customIcon, secondaryIndex, pinned_columns, column, item, onClickCustom, onClickDelete, onClickEdit}) => (
        <td className={`
            main-table__body
            ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `td pinned-column ${column}` : ''}
            ${column === "-1" ? "main-table__body--sticky" : ""}
        `}>
            <TableRowColumnTemplate>
                {column === "-1" ? (
                    <TableRowColumnButton
                        customIcon={customIcon}
                        onClickCustom={onClickCustom}
                        onClickDelete={onClickDelete}
                        onClickEdit={onClickEdit}
                        index={secondaryIndex}
                    />
                ) : (
                    <TableRowColumnDefault column={column} item={item}/>
                )}
            </TableRowColumnTemplate>
        </td>
    ),
);

TableRowColumnWrap.propTypes = propTypes;
TableRowColumnWrap.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowColumnWrap);
