import ReactOnRails from "react-on-rails";
import React from "react";
import _ from "lodash";
import Root from "App/View/Root";
import Index from "./Index";
import service from "./Services";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);
    return (
        <Root store={store} service={service(_.get(store.getState(), "listState.view.id"))}>
            <Index />
        </Root>
    );
};

export default App;
