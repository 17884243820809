import React from 'react';
import PropTypes from "prop-types";
import parse from "html-react-parser";

function PropertyTable({tdsData}) {
    const splitCamelCaseStr = (str) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase())
    };

    const replacements = {'value2': 'Value (2)', 'value1': 'Value (1)'};
    const replacedItems = (obj) => {
        return Object.entries(obj).reduce((acc, [key,value]) => {
            const newKey = replacements[key]
            acc[newKey || splitCamelCaseStr(key) ] = value;

            if(['Value (2)'].includes(newKey || splitCamelCaseStr(key))){
                const valMin = acc['Value2 Min'];
                const valMax = acc['Value (2)'];
                let valueRes = valMin || valMax;

                if(valMin && valMax) {
                    valueRes = valMin === valMax ? valMin : (valMin + ' - ' + valMax);
                }

                acc['Value (2)']  = valueRes ? valueRes + ' ' + obj.unit2 : '';
                delete acc['Value2 Min']
                delete acc['Unit2']
            }
            if(['Value (1)'].includes(newKey || splitCamelCaseStr(key))){
                const valMin = acc['Value1 Min'];
                const valMax= acc['Value (1)'];
                let valueRes = valMin || valMax;

                if(valMin && valMax) {
                    valueRes = valMin === valMax ? valMin : (valMin + ' - ' + valMax);
                }

                acc['Value (1)']  = valueRes ? valueRes + ' ' + (obj.unit1) : '';
                delete acc['Value1 Min']
                delete acc['Unit1']
            }
            return acc
        }, {})
    }

    const replacedTDSData = tdsData.map(obj => replacedItems(obj))

    const getTableHeaderItems = () => {
        if(replacedTDSData[0]){
            return Object.keys(replacedTDSData[0])
        }
        return []
    }

    return (
        <div className="table-responsive">
            <table className="technical-table">
                <thead>
                <tr>
                    {getTableHeaderItems().map((title) => (
                        <th key={title}>{title}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {replacedTDSData.map((prop, idx) => (
                    <tr key={`${idx}-prop`}>
                        {Object.values(prop).map((value, key) => (
                            <td key={`${idx}-${key}-item`}>{parse(value)}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

PropertyTable.propTypes = {
    tdsData: PropTypes.arrayOf(
        PropTypes.shape({
            property: PropTypes.string,
            parameter1: PropTypes.string,
            unit1: PropTypes.string,
            unit2: PropTypes.string,
            value1Min: PropTypes.string,
            value1: PropTypes.string,
            value2Min: PropTypes.string,
            value2: PropTypes.string,
        }),
    ).isRequired,
};

export default PropertyTable;