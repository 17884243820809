import main from "../../../Services";

const storeName = "createState";

const services = main();

export default {
    ...services,
    save: services.requestCreate("/project/projects/create"),
    storeName,
    getStoreItem: services.getStoreItem(storeName),
};
