import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Organization Name",
        phone: "Phone",
        email: "Email",
        start_fy: "Start of FY",
        end_fy: "End of FY",
        teams: "Teams",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        phone: "Search",
        email: "Search",
        start_fy: "MM/DD",
        end_fy: "MM/DD",
        teams: "Search",
    };
    switch (key) {
        case "start_fy":
        case "end_fy":
            return (
                <InputFilter
                    type="date"
                    viewFormat="MM/DD"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
