const tableColumns = {
    slug: "Project ID",
    name: "Project Name",
    product_code: "Product Code",
    product_application_code: "SAP Application Code",
    manager: "Sales Manager (Project)",
    connection_manager: "Sales Manager (ZSales)",
    customer: "Customer Full Name",
    customer_short_name: "Customer Name",
    sap_customer: "SAP Customer",
    customer_code: "Customer Code",
    customer_duns_number: "D-U-N-S Number",
    grade: "Product Name",
    grade_sub_status: "Product Status",
    sap_grade: "SAP Product",
    sub_status: "Project Status",
    los_date: "Loss of Sales Date",
    material_type: "Material Type",
    material_number: "SAP Material Number",
    plant: "Selling Plant",
    move_to_current: "Month Volume Starts",
    volume: "Volume",
    revenue: "Revenue",
};

export default tableColumns;
