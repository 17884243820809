import _ from "lodash";
import Constants from "../Constants";

export const initialState = {
    id: "",
    firstName: "",
    lastName: "",
    title: "",
    company: "",
    photo: "",
    photoView: "",
    emails: [{type: '1', value: ""}],
    phones: [{ type: '1', code: "+1", number: "" }],
    socials: [],
    country: "",
    city: "",
    address1: "",
    zip: "",
    state: "",
    district: "",
    timeZone: "",

    form_validate: false,
    form_loading: false,
    form_errors: {},

    old: {},
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM:
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };

        case Constants.SET_EMAIL:
        case Constants.SET_PHONE:
            return { ...state, ...data };

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState, emails: [{type: '1', value: ""}], phones: [{type: '1', code: "+1", number: "" }] };

        default:
            return state;
    }
};

const validate = state => {
    return [state.firstName, state.lastName, state.company].every(
        el => typeof el === "string" && el.trim(),
    ) && state.emails.filter(item => _.trim(item.type) && _.trim(item.value)).length > 0;
};
