import React from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";

import SupplyChainTableBodyColumn from "./Table/TableBodyColumn";
import * as SupplyChainService from "../../../../../Services/Tabs/General/Table/SupplyChainTableService";
import ExpandedBlock from "../../../../Templates/ExpandedBlock";

const SupplyChainTable = CustomSimpleTable()(() => null)()(SupplyChainTableBodyColumn)()(() => null);

const SupplyChainBlock = ({ t, block, expanded, setExpandedAction }) => {
    return (
        <div className="project-expanded-block">
            <ExpandedBlock title={t("Supply Chain")} block={block} service={SupplyChainService} isExpanded={expanded} setExpandedAction={setExpandedAction}>
                <SupplyChainTable />
            </ExpandedBlock>
        </div>
    );
};

SupplyChainBlock.propTypes = {};

export default compose(withTagDefaultProps())(SupplyChainBlock);
