import Constants from "../Constants";

export const onChangeTab = (service, dispatch) => data => {
    dispatch({ type: Constants.CHANGE_TAB, data });
};

export const handleCloseModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.CLOSE_MODAL, data});
};

export const handleOpenModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.OPEN_MODAL, data});
};

export const handleOpenEditModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.OPEN_EDIT_MODAL, data});
};

export const handleCloseEditModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.CLOSE_EDIT_MODAL, data});
};

export const handleCloseCopySolutionModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.CLOSE_COPY_PUBLICATION_MODAL, data});
};

export const handleOpenCopySolutionModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.OPEN_COPY_PUBLICATION_MODAL, data});
};

export const resetFormAction = (service, dispatch) => () => {
    dispatch({ type: Constants.RESET_FORM });
};
