import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";
import Img from "Templates/Img";

const defaultProps = {
    customIcon: "",
    onClickCustom: () => {},
};

const propTypes = {
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClickCustom: PropTypes.func,
    index: PropTypes.number.isRequired,
};

const SharedTableRowColumnButton = ({ index, customIcon, onClickCustom }) => {
    const _onClickCustom = () => {
        onClickCustom(index);
    };

    const _customIcon = typeof customIcon === "function" ? customIcon(index) : customIcon;

    return (
        <TableBodyButtonsBlock>
            {!!_customIcon && (
                <TableBodyButton onClick={_onClickCustom}>
                    <Img img={_customIcon} />
                </TableBodyButton>
            )}
        </TableBodyButtonsBlock>
    );
};

SharedTableRowColumnButton.defaultProps = defaultProps;
SharedTableRowColumnButton.propTypes = propTypes;

export default compose(withTagDefaultProps())(SharedTableRowColumnButton);
