import _ from "lodash";
import { TableActions, FolderActions, FileActions } from "../Actions";

export const getStoreItem = (state, key) => _.get(state, `listState.${key}`);
export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableActions,
        ...FolderActions,
        ...FileActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
