import { requestTable } from "../../RequestService";
import {getStoreItem, getActionStore} from "./StoreService";
import {getViewItem, modifierValues} from "./TableBodyService";
import {getTableLabel, isSortEnabled} from "./TableHeaderService";

export default id => ({
    requestTable: requestTable(`/project/projects/${id}/history`),
    getTableLabel,
    getViewItem,
    modifierValues,
    getStoreItem,
    getActionStore,
    isSortEnabled
});
