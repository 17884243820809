import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const RadioLabel = ({ pref, label, t, children }) => (
    <span className={`${pref}__form-radio-label-title main-content__radio-label-title`}>
        {t(label)}
        {children}
    </span>
);

RadioLabel.propTypes = {
    label: PropTypes.string.isRequired,
};
export default compose(withTagDefaultProps())(RadioLabel);
