import React, {Component, Fragment} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {LinkIcon} from "Templates/Link";
import {Block, BlockShadow, BlockTitle, ButtonLeft, Input, WrapInput} from "Templates/Form";
import {Button, Div} from "Templates/Default";
import _ from "lodash";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import arrayMove from "array-move";
import {CheckboxButton} from "Templates/Form/Checkbox";
import {Modal} from "Templates/Modals";
import MaterialPropertyPreviewModal from "./Modal/MaterialPropertyPreviewModal";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    subSegment: PropTypes.string.isRequired,
    visible: PropTypes.objectOf(PropTypes.any).isRequired,
    materialProperties: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    changeMaterialProperty: PropTypes.func.isRequired,
};

const DragHandle = SortableHandle(() => <span className="table-settings__style__handle"> </span>);

const SortableItem = SortableElement(({ val, indexVal, values, onChangeArray, handleMenuAction, onDeleteArray }) => {
    const highlightClass = val.highlight ? "highlight-row" : "";

    return (
        <li className={`table-settings-li ${highlightClass}`}>
            <DragHandle />

            <Fragment key={`Input-${val.frontId}`}>
                <Block className="property_input-list">
                    <div
                        className="property-input-wrap"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="property"
                            type="asyncSelect"
                            placeholder="Select"
                            url="/admin/solution-property-hierarchies/material-hierarchy-list/root"
                            value={val.property || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { property: value, propertyView: label, parameter1: '', parameter1View: '' }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <div
                        className="property-input-wrap"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="parameter1"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.property ? `/admin/solution-property-hierarchies/material-hierarchy-list/${val.property}` : "//"}
                            disabled={!val.property}
                            value={val.parameter1 || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { parameter1: value, parameter1View: label, parameter2: '', parameter2View: '' }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <div
                        className="property-input-wrap"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="parameter2"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.parameter1 ? `/admin/solution-property-hierarchies/material-hierarchy-list/${val.parameter1}` : "//"}
                            disabled={!val.parameter1}
                            value={val.parameter2 || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { parameter2: value, parameter2View: label, parameter3: '', parameter3View: '' }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <div
                        className="property-input-wrap"
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="parameter3"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.parameter2 ? `/admin/solution-property-hierarchies/material-hierarchy-list/${val.parameter2}` : "//"}
                            disabled={!val.parameter2}
                            value={val.parameter3 || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { parameter3: value, parameter3View: label, valueMin1: '', valueMax1: '', valueMin2: '', valueMax2: '' }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <Div>
                        <Input
                            name="value_1_min"
                            type="text"
                            className="text-right"
                            placeholder=""
                            disabled={!val.parameter3}
                            value={val.valueMin1 || ''}
                            onChange={value => {
                                onChangeArray(indexVal, { valueMin1: value }, values)
                            }}
                        />
                    </Div>
                    <Div>
                        <Input
                            name="value_1_max"
                            type="text"
                            className="text-right"
                            placeholder=""
                            disabled={!val.parameter3}
                            value={val.valueMax1 || ''}
                            onChange={value => {
                                onChangeArray(indexVal, { valueMax1: value }, values)
                            }}
                        />
                    </Div>
                    <div
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="unit1"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.parameter3 ? `/admin/solution-property-hierarchies/material-hierarchy-list/${val.parameter3}` : "//"}
                            disabled={!val.parameter3}
                            value={val.unit1 || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { unit1: value, unit1View: label}, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                    <Div>
                        <Input
                            name="value_2_min"
                            type="text"
                            className="text-right"
                            placeholder=""
                            disabled={!val.unit1}
                            value={val.valueMin2 || ''}
                            onChange={value => {
                                onChangeArray(indexVal, { valueMin2: value }, values)
                            }}
                        />
                    </Div>
                    <Div>
                        <Input
                            name="value_2_max"
                            type="text"
                            className="text-right"
                            placeholder=""
                            disabled={!val.unit1}
                            value={val.valueMax2 || ''}
                            onChange={value => {
                                onChangeArray(indexVal, { valueMax2: value }, values)
                            }}
                        />
                    </Div>
                    <div
                        onMouseOver={() => {
                            handleMenuAction(true)
                        }}
                        onMouseOut={() => {
                            handleMenuAction(false)
                        }}
                    >
                        <Input
                            name="unit2"
                            type="asyncSelect"
                            placeholder="Select"
                            url={val.unit1 ? `/admin/solution-property-hierarchies/material-hierarchy-list/${val.unit1}` : "//"}
                            disabled={!val.unit1}
                            value={val.unit2 || ''}
                            onChange={(value, label) => onChangeArray(indexVal, { unit2: value, unit2View: label }, values)}
                            onMenuAction={(e) => {
                                handleMenuAction(e)
                            }}
                        />
                    </div>
                </Block>
                {values.length > 1 && (
                    <LinkIcon
                        className="icon-delete property_input-link"
                        icon="la-icon-close"
                        onClick={() => {
                            onDeleteArray(val.frontId, values)
                        }}
                    />
                )}
            </Fragment>
        </li>
    );
});

const SortableList = SortableContainer(({ isDisabled, values, onChangeArray, onDeleteArray, onHandleMenuAction }) => {
    return (
        <ul>
            {values.map((value, index) => (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    indexVal={index}
                    val={value}
                    values={values}
                    disabled={isDisabled}
                    handleMenuAction={onHandleMenuAction}
                    onChangeArray={onChangeArray}
                    onDeleteArray={onDeleteArray}
                />
            ))}
        </ul>
    );
});

class MaterialProperty extends Component {
    state = {
        showContent: true,
        showPreviewModal: false,
        isDisabled: false
    };

    compareProperties = () => {
        const { materialProperties } = this.props;

        const propertyCounts = {};

        materialProperties.forEach(propertyItem => {
            const { property, parameter1, parameter2, parameter3 } = propertyItem;

            if (property && parameter1 && parameter2 && parameter3) {
                const key = `${property}-${parameter1}-${parameter2}-${parameter3}`;
                propertyCounts[key] = (propertyCounts[key] || 0) + 1;
            }
        });

        materialProperties.forEach(propertyItem => {
            const { property, parameter1, parameter2, parameter3 } = propertyItem;

            if (property && parameter1 && parameter2 && parameter3) {
                const key = `${property}-${parameter1}-${parameter2}-${parameter3}`;
                propertyItem.highlight = propertyCounts[key] > 1;
            } else {
                propertyItem.highlight = false;
            }
        });
    };

    componentDidMount() {
        this.compareProperties();
    }

    toggleContent = () => {
        this.setState(({ showContent }) => ({
            showContent: !showContent,
        }));
    };

    render() {
        const { showContent, isDisabled, showPreviewModal } = this.state;
        const {
            subSegment,
            visible,
            materialProperties,
            onChange,
            changeMaterialProperty,
            t
        } = this.props;

        const onChangeSegment = (value, key) => {
            onChange(key, value);
            changeMaterialProperty(value);
        };

        const onChangeVisible = (value, key) => {
            const _visible = _.cloneDeep(visible);
            _visible[key] = value;

            onChange(`visible`, _visible);
        };

        const onSortEnd = ({ oldIndex, newIndex }) => {
            onChange('materialProperties', arrayMove(materialProperties, oldIndex, newIndex));
        };

        const onDeleteArray = (frontId, array) => {
            onChange('materialProperties', array.filter(elem => elem.frontId !== frontId));
        };

        const _onAddArray = (lastFrontId, array) => {
            onChange('materialProperties', [...array, {id: '', frontId: lastFrontId + 1, property: "" }]);
        };

        const onChangeArray = (index, value, array) => {
            array[index] = _.merge(array[index], value);
            onChange('materialProperties', _.concat(array));
            this.compareProperties();
        };

        const onHandleMenuAction = (e) => {
            this.setState({
                isDisabled: e
            })
        }

        const handleCloseModal = () => {
            this.setState({
                showPreviewModal: false,
            });
        };

        const handleShowModal = () => {
            this.setState({
                showPreviewModal: true,
            });
        };

        return (
            <>
                <Div className="toggle__show-block">
                    <BlockTitle>{t("Technology Properties Table")}</BlockTitle>
                    <button
                        type="button"
                        className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                        onClick={() => this.toggleContent()}
                    >
                        Show
                        {' '}
                        {showContent ? 'Less' : 'More'}
                    </button>
                </Div>

                <Block>
                    {showContent ?
                        (
                            <BlockShadow>
                                {/*<Block>*/}
                                {/*    <WrapInput*/}
                                {/*        className=" width_1-5"*/}
                                {/*        label="Sub-segment Material Property Template"*/}
                                {/*        name="sub-segment"*/}
                                {/*    >*/}
                                {/*        <Input*/}
                                {/*            name="sub_segment"*/}
                                {/*            type="asyncSelect"*/}
                                {/*            placeholder={t("Select")}*/}
                                {/*            url="/admin/solution-property-hierarchies/sub-segment-list"*/}
                                {/*            value={subSegment}*/}
                                {/*            onChange={value => onChangeSegment(value, `subSegment`)}*/}
                                {/*        />*/}

                                {/*        {showPreviewModal ?*/}
                                {/*            (<Modal onRequestClose={handleCloseModal}>*/}
                                {/*                <MaterialPropertyPreviewModal*/}
                                {/*                    tdsData={materialProperties}*/}
                                {/*                    visible={visible}*/}
                                {/*                    onClose={handleCloseModal}*/}
                                {/*                />*/}
                                {/*            </Modal>)*/}
                                {/*            : ''}*/}
                                {/*    </WrapInput>*/}
                                {/*    <ButtonLeft>*/}
                                {/*        <button*/}
                                {/*            type="button"*/}
                                {/*            className="projects-create__buttons-btn main-btn main-btn_primary"*/}
                                {/*            onClick={handleShowModal}*/}
                                {/*        >*/}
                                {/*            {t("Preview")}*/}
                                {/*        </button>*/}
                                {/*    </ButtonLeft>*/}
                                {/*</Block>*/}
                                <Block className="property_column_header customize-property-header">
                                    <div>
                                        <div>
                                            <span>Property</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_property"
                                                value={visible.property}
                                                onChange={value => {
                                                    onChangeVisible(value, 'property');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Parameter 1</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_parameter1"
                                                value={visible.parameter1}
                                                onChange={value => {
                                                    onChangeVisible(value, 'parameter1');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Parameter 2</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_parameter2"
                                                value={visible.parameter2}
                                                onChange={value => {
                                                    onChangeVisible(value, 'parameter2');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Parameter 3</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_parameter3"
                                                value={visible.parameter3}
                                                onChange={value => {
                                                    onChangeVisible(value, 'parameter3');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Value-1 Min </span>
                                        </div>
                                        <div>
                                            <span>Value-1 Max </span>
                                        </div>
                                        <div>
                                            <span>Value-1</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_unit1"
                                                value={visible.unit1}
                                                onChange={value => {
                                                    onChangeVisible(value, 'unit1');
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <span>Value-2 Min </span>
                                        </div>
                                        <div>
                                            <span>Value-2 Max </span>
                                        </div>
                                        <div>
                                            <span>Value-2</span>
                                            <CheckboxButton
                                                htmlFor="checkbox_unit2"
                                                value={visible.unit2}
                                                onChange={value => {
                                                    onChangeVisible(value, 'unit2');
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {materialProperties.length > 1 && (<span />)}
                                </Block>
                                <SortableList
                                    isDisabled={isDisabled}
                                    values={materialProperties}
                                    distance={1}
                                    axis="y"
                                    lockAxis="y"
                                    onChangeArray={onChangeArray}
                                    onDeleteArray={onDeleteArray}
                                    onSortEnd={onSortEnd}
                                    onHandleMenuAction={onHandleMenuAction}
                                />
                                <Button
                                    onClick={
                                        () => _onAddArray(
                                            _.maxBy(materialProperties, p => p.frontId).frontId,
                                            materialProperties
                                        )
                                    }
                                    className="size_normal main-btn main-btn_white"
                                >
                                    Add Technology Property
                                </Button>
                            </BlockShadow>
                        ) : ''}
                </Block>
            </>
        );
    }
}

MaterialProperty.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        subSegment: getStoreItem(state, "subSegment"),
        visible: getStoreItem(state, "visible"),
        materialProperties: getStoreItem(state, "materialProperties"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        changeMaterialProperty: getActionStore("changeMaterialProperty", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MaterialProperty);
