import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {getString, regExpForFloat} from "Services";
import {gradeStatus} from "Services/enum";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Button, Span} from "Templates/Default";
import {Input, Label, TooltipHtml} from "Templates/Form";
import Img from "Templates/Img/Img";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import TableRowItemLink from "Templates/Table/TableRowItemLink";

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
    service: PropTypes.shape({
        columnsTop: PropTypes.arrayOf(PropTypes.string).isRequired,
        columnsBottom: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    addNewProduct: PropTypes.func.isRequired,
    onChangeProduct: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func.isRequired,
    changeGrade: PropTypes.func.isRequired,
    changePlant: PropTypes.func.isRequired,
    totalPercent: PropTypes.number.isRequired,
    showFull: PropTypes.bool.isRequired,
};

const typeProduct = {
    'SAP': 'SAP Products',
    'NOSAP': 'My Products (NonSAP)'
}

const ProductsBlock = (
    {
        items,
        service: {columnsTop, columnsBottom},
        addNewProduct,
        onChangeProduct,
        deleteProduct,
        changeGrade,
        changePlant,
        t,
        showFull,
        totalPercent
    }) => {
    const handleClickDeleteProduct = index => () => {
        deleteProduct(index);
    };

    const tableLabel = {
        name: "Source",
        grade: "Material Number ** Product Name",
        plant: "Selling Plant",
        sub_status: "Product Status",
        productCode: "Product Code",
        line: "Line",
        invoiceCount: "Invoices",
        actualSale: "Price ($/lb)",
        rebate: "Rebate ($/lb)",
        finalPrice: "Final Price ($/lb)",
        percentOfProduct: "Product (%)",
        code: "SAP Application Code",
    };

    const getBodyView = (key, item, index) => {
        switch (key) {
            case 'name': {
                return (
                    <>
                        <div className="title">
                            <Label isRequired={showFull} label={getString(tableLabel, key, '')}/>
                        </div>
                        <Input
                            type="asyncSelect"
                            placeholder={t("Search")}
                            url="project/projects/product-list"
                            name="name"
                            value={getString(item, key, '')}
                            onChange={(value, label) => {
                                onChangeProduct('isSap', label === typeProduct.SAP, index);
                                onChangeProduct(key, value, index);
                            }}
                        />
                    </>
                );
            }

            case 'grade': {
                return (
                    <>
                        <div className="title">
                            <Label isRequired={showFull} label={getString(tableLabel, key)}/>
                            <TooltipHtml>
                                {t(
                                    `<div>Search for SAP Products with a Material Number or Product Name.</div>
                                    <div class="padding">Search for My Products with a Product Name only.</div>`,
                                )}
                            </TooltipHtml>
                        </div>
                        <Input
                            type="asyncSelect"
                            placeholder={t("Search")}
                            url={`project/projects/${getString(item, 'name', '')}/grade-list-extra`}
                            name="grade"
                            value={getString(item, key, '')}
                            onChange={value => {
                                changeGrade(key, value, index);
                            }}
                        />
                    </>
                );
            }

            case 'plant': {
                return (
                    <>
                        <div className={showFull && getString(item, 'isSap', '') && (!item.plant || item.plant === '-') ? 'error' : ''}>
                            <div className="title">

                                <Label isRequired={showFull && !!getString(item, 'isSap', '')} label={getString(tableLabel, key)}/>
                                <TooltipHtml>
                                    {t(
                                        `<div>If an SAP Product is selected, then select the Selling Plant.</div>`
                                    )}
                                </TooltipHtml>
                            </div>
                            <Input
                                disabled={!getString(item, 'isSap', '')}
                                type="asyncSelect"
                                placeholder={t("Search")}
                                url={`project/projects/grade/${getString(item, 'grade', '')}/sap-plant-list`}
                                name="plant"
                                value={getString(item, key, '')}
                                onChange={value => {
                                    changePlant(getString(item, 'grade'), value, index);
                                }}
                            />
                        </div>
                    </>
                );
            }

            case 'sub_status': {
                const subStatusItem = gradeStatus.find(el => el.value.toString() === getString(item, key).toString());
                const subStatusLabel = subStatusItem && subStatusItem.label;

                return (
                    <>
                        <div className="title">
                            <Label label={getString(tableLabel, key)}/>
                            <TooltipHtml classBlock={'status-tooltip'}>
                                {t(`
                                     <div class="long-tooltip"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</div>
                                     <div class="long-tooltip"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</div>
                                     <div class="long-tooltip"><span class="font-medium">Production Intent</span> - Product in process for being approved.</div>
                                     <div class="long-tooltip"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</div>
                                     <div class="long-tooltip"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</div>
                                     <div class="long-tooltip"><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</div>`,
                                )}
                            </TooltipHtml>
                        </div>
                        <Span className="items-item">
                            {(subStatusLabel &&
                                <TableRowItemStatus status={subStatusLabel} list={gradeStatus}/>) || '-'}
                        </Span>
                    </>
                );
            }

            case 'invoiceCount': {
                const invoiceCount = getString(item, key);
                const plant = getString(item, 'plant', '');
                const grade = getString(item, 'grade', '');

                return (
                    <>
                        <div className="title">
                            <Label label={getString(tableLabel, key)}/>
                        </div>
                        <Span className="items-item">
                            {plant && grade &&
                                <TableRowItemLink
                                    blank
                                    title={invoiceCount.toString()}
                                    href={`/product/product-finder/${grade}?activeTab=10`}
                                >
                                    {invoiceCount}
                                </TableRowItemLink>
                            }
                            {!plant && '0'}
                        </Span>
                    </>
                );
            }

            case 'actualSale': {
                return (
                    <div className={item.actualSale <= 0 ? 'error' : ''}>
                        <div className="title text-right">
                            <Label isRequired={showFull} label={getString(tableLabel, key)}/>
                        </div>
                        <Input
                            className="text-right"
                            name={`actualSale-${item.frontId}`}
                            value={getString(item, key, '')}
                            placeholder="0"
                            onChange={value => {
                                onChangeProduct(key, value, index);
                            }}
                        />
                    </div>
                );
            }

            case 'rebate': {
                return (
                    <div>
                        <div className="title text-right">
                            <Label label={getString(tableLabel, key)}/>
                        </div>
                        <Input
                            className="text-right"
                            name={`rebate-${item.frontId}`}
                            value={getString(item, key, '')}
                            placeholder="0"
                            onChange={value => {
                                onChangeProduct(key, value, index);
                            }}
                        />
                    </div>
                );
            }

            case 'percentOfProduct': {
                return (
                    <>
                        <div className="title text-right">
                            <Label label={getString(tableLabel, key)}/>
                            <TooltipHtml>
                                {t(
                                    `<div>Use this field to enter a percentage of product</div>
                                     <div>sold into an application.</div>
                                     <div class="padding">For Single Product applications, enter 100%.</div>
                                     <div class="padding">For multiple product applications, enter the</div>
                                     <div>percentage of each product in the application.</div>
                                     <div class="padding">The total percentage of products must equal </div>
                                     <div>100%.</div>`,
                                )}
                            </TooltipHtml>
                        </div>
                        <Input
                            className="text-right"
                            name={`percentOfProduct-${item.frontId}`}
                            value={getString(item, key, '')}
                            onChange={value => regExpForFloat(value, data => onChangeProduct(key, data, index))}
                        />
                    </>
                );
            }

            case 'productCode': {
                return (
                    <>
                        <div className="title">
                            <Label label={getString(tableLabel, key)}/>
                            <TooltipHtml>
                                {t(
                                    `<div>The product code is automatically assigned to an SAP Product.</div>`
                                )}
                            </TooltipHtml>
                        </div>
                        <Span className="items-item">{getString(item, key)}</Span>
                    </>
                );
            }

            case 'code': {
                return (
                    <>
                        <div className="title">
                            <Label label={getString(tableLabel, key)}/>
                            <TooltipHtml>
                                {t(
                                    `<div>SAP Products are automatically assigned an Application Code.</div>`,
                                )}
                            </TooltipHtml>
                        </div>
                        <Span className="items-item">{getString(item, key)}</Span>
                    </>
                );
            }

            default: {
                return (
                    <>
                        <div className="title">
                            <Label label={getString(tableLabel, key)}/>
                        </div>
                        <Span className="items-item">{getString(item, key)}</Span>
                    </>
                );
            }
        }
    };

    return (
        <>
            {items.map((item, itemIndex) => (
                <div className="items-block" key={`Item-top-${item.frontId}`}>
                    <div className="items-wrapper">
                        <div className="top-box">
                            {columnsTop.map((column, index) => (
                                <div
                                    key={index}
                                    className={`${!item.unique ? "border-bottom_red" : ""} text-left col-${index}`}
                                >
                                    {getBodyView(column, item, itemIndex)}
                                </div>
                            ))}
                        </div>

                        <div className="bottom-box">
                            {columnsBottom.map((column, index) => (
                                <div
                                    key={index}
                                    className={`${!item.unique ? "border-bottom_red" : ""} text-left col-${index}`}
                                >
                                    {getBodyView(column, item, itemIndex)}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="delete-box">
                        <button
                            type="button"
                            className={`delete-button__form-block-link ${items.length === 1 ? "hide" : ""}`}
                            onClick={handleClickDeleteProduct(item.frontId)}
                        >
                            <Img img="icon_delete"/>
                        </button>
                    </div>
                </div>
            ))}

            <div className="projects__product-add-btn">
                <Button className="size_normal main-btn main-btn_white" onClick={addNewProduct}>
                    {t("Add Product")}
                </Button>
            </div>
            <div className="projects__product-total-wrap">
                <div className="text-left">
                    <Span className="main-content__label">Total (% of Product)</Span>
                </div>
                <div className="projects__product-total">
                    <Span className={totalPercent === 100 ? "clr-green" : "clr-red"}>{`${totalPercent}%`}</Span>
                    <div className="gray-text">{t("* % of Product must equal 100%")}</div>
                </div>
            </div>
        </>
    );
};

ProductsBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        showFull: getStoreItem(state, "show_full"),
        items: getStoreItem(state, itemsName),
        totalPercent: getStoreItem(state, "productBlock.totalPercent"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        addNewProduct: getActionStore("addNewProductAction", service, dispatch),
        onChangeProduct: getActionStore("onChangeProductAction", service, dispatch),
        changeGrade: getActionStore("changeGradeAction", service, dispatch),
        changePlant: getActionStore("changePlantAction", service, dispatch),
        deleteProduct: getActionStore("deleteProductAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ProductsBlock);
