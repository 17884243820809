import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const listTypes = {
    "0": {
        value: 0,
        label: "Folder",
    },
    "10": {
        value: 10,
        label: "File",
    },
};

export const getProjectTableLabel = key => {
    const projectTableLabel = {
        project_id: "Project ID",
        name: "Project Name",
        modified: "Last modified",
        modified_by: "Modified By",
    };
    return _.get(projectTableLabel, key, key);
};

export const getTableLabel = key => {
    const tableLabel = {
        name: "Name",
        modified: "Last modified",
        modified_by: "Modified By",
    };
    return _.get(tableLabel, key, key);
};

const returnFilter = (placeholders, key, value, callback, request) => {
    switch (key) {
        case "modified":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        modified: "Select Date",
        modified_by: "Search",
    };

    return returnFilter(placeholders, key, value, callback, request);
};

export const getProjectsFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        project_id: "Search",
        modified: "Select Date",
        modified_by: "Search",
    };

    return returnFilter(placeholders, key, value, callback, request);
};
