import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import AsyncSelectReact from "../AsyncSelect/AsyncSelectReact";
import {url as urlService, request, uniqueGenerator} from "../index";

const propTypes = {
    disabled: PropTypes.bool,
    url: PropTypes.string,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    ]).isRequired,
    inputProps: PropTypes.shape({ isOptionDisabled: PropTypes.func }),
};

const defaultProps = {
    disabled: false,
    url: "",
    placeholder: "Search",
    inputProps: { isOptionDisabled: option => option.disable },
    handleChange: () => {
    },
};

class AsyncSearchMultiSelect extends Component {
    state = {
        value: false,
    };

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount = () => {
        const { value } = this.props;
        this.setValue(value);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { value } = this.state;
        if (value && nextProps.value !== value.value && nextProps.value === "") {
            this.setState({
                value: false,
            });
        }

        // TODO, bad code
        if (!value || nextProps.value !== value.value) {
            this.setValue(nextProps.value);
        }
    }

    handleChange = selectedOption => {
        this.setState(
            {
                value: selectedOption && selectedOption.length ? selectedOption.map((item) => {
                    if (item.label.includes(' (Add Keyword)')) {
                        return {...item, label: item.label.replace(' (Add Keyword)', '').trim()}
                    }
                    return item
                }) : selectedOption
            },
            () => {
                const { handleChange } = this.props;
                handleChange(selectedOption ? selectedOption.map(v => v) : "");
            },
        );
    };

    getOptions = url => data => {
        if (url.includes("//")) {
            return new Promise(resolve => resolve([]));
        }

        return new Promise((resolve, reject) => {
            request.sendRequestWithNoCache(
                {
                    url: urlService.getUrl(url),
                    data,
                    type: "GET",
                },
                false,
            )
                .then(res => {
                    const options = _.get(res, "0.items", []);
                    const inputVal = this.myRef.current.state.inputValue
                    if (inputVal && !options.find((item) => item.label === inputVal)) {
                        options.unshift({ value: inputVal, label: `${inputVal} (Add Keyword)` });
                    }

                    resolve(options);
                }, reject);
        });
    };

    setValue = value => {
        const { url } = this.props;

        if (value.length && !url.includes("//")) {
            this.setState({value});
        }
    };

    render = () => {
        const { value } = this.state;
        const { url, disabled, inputProps, placeholder } = this.props;

        const customStyles = {
            control: base => ({
                ...base,
            }),
        };

        const loadOptions = this.getOptions(url);

        return (
            <div title={value ? value.label : ""} className="select-title">
                <AsyncSelectReact
                    ref={this.myRef}
                    isMulti
                    isLoading
                    menuPosition="fixed"
                    // closeMenuOnScroll={e => !e.target.parentNode.className.includes("menu")}
                    closeMenuOnScroll={e => !(e.target.parentNode || { className: '' }).className.includes("menu")}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    inputProps={inputProps}
                    styles={customStyles}
                    loadArguments={{ url }}
                    value={value}
                    cacheOptions
                    defaultOptions
                    onChange={this.handleChange}
                    onResetOptions={this.handleChange}
                    loadOptions={label => loadOptions({ label })}
                />
            </div>
        );
    };
}

AsyncSearchMultiSelect.propTypes = propTypes;
AsyncSearchMultiSelect.defaultProps = defaultProps;

export default AsyncSearchMultiSelect;
