import React, {Fragment} from "react";
import {formatDate, getString, numberWithCommas} from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "product_code":
        case "application_code":
        case "material_number":
        case "order_country":
        case "order_region":
        case "line_number":
        case "sales_unit":
        case "business_area":
        case "gross_weight_unit":
        case "normalized_weight_unit":
        case "order_number":
        case "order_line_number":
        case "reference_invoice_number":
        case "reference_invoice_line_number":
        case "cancelled":
        case "free_of_charge":
            const stringData = _.get(item, key);

            return (
                stringData
                    ?
                    <Fragment key={`Fragment_${stringData}`}>
                        <TableRowItemSpan
                            key={stringData}
                            title={stringData}
                        >
                            {stringData}
                        </TableRowItemSpan>
                    </Fragment>
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "freight_amount":
        case "net_amount":
        case "cost":
        case "fixed_cost":
        case "variable_cost":
        case "gross_margin":
        case "price_kg":
        case "cost_kg":
        case "surcharge_zb00":
        case "surcharge_zb04":
        case "surcharge_zb05":
        case "surcharge_zd00":
            const cost = _.get(item, key);

            return (
                cost
                    ?
                    <Fragment key={`Fragment_${cost}`}>
                        <TableRowItemSpan
                            className="text-right"
                            key={`${cost}`}
                            title={cost}
                        >
                            {`$${numberWithCommas(cost, 2)}`}
                        </TableRowItemSpan>
                    </Fragment>
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "quantity":
        case "gross_weight":
        case "normalized_weight":
        case "gross_margin_percent":
            const weight = _.get(item, key);

            return (
                weight
                    ?
                    <Fragment key={`Fragment_${weight}`}>
                        <TableRowItemSpan
                            className="text-right"
                            key={`${weight}`}
                            title={weight}
                        >
                            {`${numberWithCommas(weight, 3)}`}
                        </TableRowItemSpan>
                    </Fragment>
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "grade":
            const grade = _.get(item, key);
            const is_grade = _.get(item, 'is_grade');

            return (
                grade
                    ?
                    <Fragment key={`Fragment_${grade}`}>
                        <TableRowItemLink
                            blank
                            key={`${grade}.${getString(item, 'grade_id')}`}
                            title={getString(item, key)}
                            href={is_grade ? `/product/product-finder/${getString(item, 'grade_id')}` : `/product/raw/${getString(item, 'grade_id')}`}
                        >
                            {grade}
                        </TableRowItemLink>
                    </Fragment>
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "plant":
            const plant = _.get(item, key);

            return (
                plant
                    ?
                    <Fragment key={`Fragment_${plant}`}>
                        <TableRowItemLink
                            blank
                            key={`${plant}.${getString(item, 'plant_id')}`}
                            title={getString(item, key)}
                            href={`/product/plants/${getString(item, 'plant_id')}`}
                        >
                            {plant}
                        </TableRowItemLink>
                    </Fragment>
                    : <TableRowItemSpan key={`${key}`} title={''}>
                        {'-'}
                    </TableRowItemSpan>
            );

        case "invoice_number":
            return <TableRowItemLink
                key={`${getString(item, key)}`}
                title={getString(item, key)}
                href={`/invoice/invoices/${getString(item, key)}`}
            >
                {getString(item, key)}
            </TableRowItemLink>;

        case "billing_date": {
            const elem = getString(item, key);
            const formatValue = elem === '-' ? elem : formatDate(elem);

            return (
                <TableRowItemSpan title={formatValue}>
                    {formatValue}
                </TableRowItemSpan>
            );
        }

        case "created_on": {
            const elem = getString(item, key);
            const formatValue = elem === '-' ? elem : formatDate(elem, "MMM DD, YYYY h:mm A");

            return (
                <TableRowItemSpan title={formatValue}>
                    {formatValue}
                </TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
