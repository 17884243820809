import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { compose } from "redux";
import { ButtonLeft } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import attachFile from "Services/FileService";

const propTypes = {
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.object)]),
    onChange: PropTypes.func.isRequired,
    handleClickOnDeleteButton: PropTypes.func.isRequired,
    inputProps: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    multiple: false,
    disabled: false,
    value: "",
    inputProps: {},
};

const File = ({ pref, multiple, disabled, value, onChange, handleClickOnDeleteButton, inputProps, t }) => {
    const fileRef = React.createRef();

    const handleClickOnUploadFile = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!fileRef || !fileRef.current) {
            alert('Error file uploading')

            return
        }
        fileRef.current.click();
    };

    const fileElement = (elem, onDelete) => (
        <div className={`${pref}__upload-item`}>
            {elem.name ? elem.name : elem}
            <i className="la la-icon-close" onClick={onDelete} />
        </div>
    );

    const _onChange = e => {
        e.persist();

        const file = _.head(e.target.files, false);
        attachFile(file).then(() => onChange(file));
    };

    const buttonElement = (
        <ButtonLeft>
            <input ref={fileRef} type="file" className="visually-hidden hidden-file-input" onChange={_onChange} {...inputProps} />
            <button
                disabled={disabled}
                type="button"
                className="main-btn main-btn__upload main-btn_white"
                onClick={handleClickOnUploadFile}
            >
                {t("Upload File")}
            </button>
        </ButtonLeft>
    );

    if (multiple) {
        return (
            <>
                {value.map((elem, index) => (
                    <React.Fragment key={`file_input-${index}`}>
                        {fileElement(elem, handleClickOnDeleteButton(elem))}
                    </React.Fragment>
                ))}
                {buttonElement}
            </>
        );
    }
    if (value) {
        return fileElement(value, handleClickOnDeleteButton);
    }
    return buttonElement;
};

File.propTypes = propTypes;
File.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(File);
