import Constants from "../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.FORM_RESET });
};

export const refreshPhone = (service, dispatch) => phones => {
    dispatch({ type: Constants.SET_PHONE, data: { phones } });
};

export const refreshEmail = (service, dispatch) => emails => {
    dispatch({ type: Constants.SET_EMAIL, data: { emails } });
};

export const submitForm = (service, dispatch) => (data) => {
    dispatch({ type: Constants.FORM_REQUESTED });
    const { formData } = service.FormService;
    return new Promise((resolve, reject) => {
        service.save(formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};
