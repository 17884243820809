import React, {useEffect} from 'react';
import {compose} from 'redux';
import PropTypes from 'prop-types';
import {ServiceProvider, withServiceConsumer} from 'Services/Context';
import {withTagDefaultProps} from 'Hoc/Template';
import {url} from 'Services';
import Tabs from 'Templates/Tabs/Tabs';
import Tab from "Templates/Tabs/Tab";
import TabCheck from 'Templates/Tabs/TabСheck';
import {ContentWrap, ContentWrapCol, TabBlockBtn,} from 'Templates/Content';
import sharedProductService from './Services/Tabs/SharedProduct';
import InfoWrap from './Views/InfoWrap';
import SharedProduct from './Views/Tabs/SharedProduct';
import Img from "Templates/Img";

const Grade = (
    {
        activeTab,
        onChangeTab,
        t,
        items,
        resetFilterWithoutFetch,
        request,
        handleAddSharedProductsPublicationsToList
    }) => {
    const { filter } = request;

    const services = {
        0: sharedProductService
    };

    const handleReset = () => {
        url.clearHistory();
        return resetFilterWithoutFetch();
    }

    return (
        <>
            <ContentWrap>
                <ServiceProvider value={services[activeTab]}>
                    <InfoWrap activeTab={activeTab}/>
                </ServiceProvider>
                <ContentWrapCol>
                    <div className="d-flex flex-col h-100">
                        {/*<div className="product-finder-actions">*/}
                        {/*    {activeTab === 0 && !_.isEmpty(request.filter) && items.length > 0 && (*/}
                        {/*        <>*/}
                        {/*            <TabBlockBtn*/}
                        {/*                title="Add Technologies to List."*/}
                        {/*                onClick={handleAddSharedProductsPublicationsToList}*/}
                        {/*                className="main-btn_primary main-btn_primary-share-filters add-publication-btn"*/}
                        {/*            >*/}
                        {/*                {t('Add Technologies to List')}*/}
                        {/*                <Img img={'icon-list'}></Img>*/}
                        {/*            </TabBlockBtn>*/}
                        {/*        </>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        <Tabs
                            activeTab={activeTab}
                            onClickTabItem={(index) => {
                                if (activeTab !== index) {
                                    handleReset();
                                    onChangeTab(index);
                                }
                            }}
                        >
                            <TabCheck
                                label={t('Technologies')}
                                titleMessage="All products with approved publications for sharing."
                                permission="product_solutions"
                            >
                                <ServiceProvider value={sharedProductService}>
                                    <SharedProduct/>
                                </ServiceProvider>
                            </TabCheck>
                            <Tab label={ '' }/>
                        </Tabs>
                    </div>
                </ContentWrapCol>
            </ContentWrap>
        </>
    );
};

Grade.defaultProps = {
    items: []
}

Grade.propTypes = {
    activeTab: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.any),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    showModal: PropTypes.bool.isRequired,
    shareFiltersShowModal: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    welcomeGuestShowModal: PropTypes.bool.isRequired,
    temporaryCloseGuestModal: PropTypes.bool.isRequired,
    handleOpenShareFiltersModal: PropTypes.func.isRequired,
    handleCloseShareFiltersModal: PropTypes.func.isRequired,
    handleOpenWelcomeGuestModal: PropTypes.func.isRequired,
    handleCloseWelcomeGuestModal: PropTypes.func.isRequired,
    resetFilterWithoutFetch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeFilterForm: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filterForm: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    filterType: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    addSharedProductsPublicationListShowModal: PropTypes.bool.isRequired,
    handleCloseAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    handleAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    addPublicationListShowModal: PropTypes.bool.isRequired,
    handleCloseAddPublicationListModal: PropTypes.func.isRequired,
    handleOpenAddPublicationListModal: PropTypes.func.isRequired,
    addPublicationListForm: PropTypes.objectOf(PropTypes.any).isRequired,
    onChangeAddPublicationListForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service }) => {
    const { getStoreItem } = service;
    const activeTab = getStoreItem(state, 'activeTab');
    let filterType;
    if (activeTab === 0) {
        filterType = 5;
    } else {
        filterType = 10
    }

    return {
        activeTab,
        items: getStoreItem(state, 'items'),
        showSharedModalChecked: getStoreItem(state, 'showSharedModalChecked'),
        permissions: getStoreItem(state, 'permissions'),
        showModal: getStoreItem(state, 'showModal'),
        welcomeGuestShowModal: getStoreItem(state, 'welcomeGuestShowModal'),
        temporaryCloseGuestModal: getStoreItem(state, 'temporaryCloseGuestModal'),
        shareFiltersShowModal: getStoreItem(state, 'shareFiltersShowModal'),
        addPublicationListShowModal: getStoreItem(state, 'addPublicationListShowModal'),
        addSharedProductsPublicationListShowModal: getStoreItem(state, 'addSharedProductsPublicationListShowModal'),
        isFirstLogin: getStoreItem(state, 'isFirstLogin'),
        addPublicationListForm: { ...getStoreItem(state, 'addPublicationListForm', true) },
        filterType,
        form: { ...getStoreItem(state, 'form', true) },
        filterForm: { ...getStoreItem(state, 'filterForm', true) },
        request: {
            filter: {
                ...getStoreItem(state, 'filter', true),
            },
            sort: {
                ...getStoreItem(state, 'sort'),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore('onChangeTab', service, dispatch),
        handleCloseModal: getActionStore('handleCloseModal', service, dispatch),
        handleOpenModal: getActionStore('handleOpenModal', service, dispatch),
        resetFilterWithoutFetch: getActionStore('resetFilterWithoutFetch', service, dispatch),
        handleOpenShareFiltersModal: getActionStore('handleOpenShareFiltersModal', service, dispatch),
        handleCloseShareFiltersModal: getActionStore('handleCloseShareFiltersModal', service, dispatch),
        handleCloseWelcomeGuestModal: getActionStore('handleCloseWelcomeGuestModal', service, dispatch),
        handleOpenWelcomeGuestModal: getActionStore('handleOpenWelcomeGuestModal', service, dispatch),
        handleCloseAddSharedProductsPublicationsToList: getActionStore('handleCloseAddSharedProductsPublicationsToList', service, dispatch),
        handleAddSharedProductsPublicationsToList: getActionStore('handleAddSharedProductsPublicationsToList', service, dispatch),
        handleCloseAddPublicationListModal: getActionStore('handleCloseAddPublicationListModal', service, dispatch),
        handleOpenAddPublicationListModal: getActionStore('handleOpenAddPublicationListModal', service, dispatch),
        onChangeAddPublicationListForm: getActionStore('onChangeAddPublicationListForm', service, dispatch),
        updateForm: getActionStore('updateForm', service, dispatch),
        onChange: getActionStore('onChangeForm', service, dispatch),
        onChangeFilterForm: getActionStore('onChangeFilterForm', service, dispatch),
        getUrlExport: getActionStore('getUrlExport', service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Grade);
