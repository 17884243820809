import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const {
        id,
        solutionId,
        solutionName,
        company,
        customName,
        solutionLogo,
        contacts,
        countries,
        suppliers,
        content,
        materialProperties,
        legal,
        visible,
        legalHTML
    } = props;

    const initData = {
        id,
        solutionId,
        solutionName,
        solutionLogo,
        company,
        customName,
        visible: !_.isEmpty(visible)
            ? Object.keys(visible)
                .map(key => [key, visible[key].toLowerCase() === '10'])
                .reduce((obj, [key, value]) => (obj[key] = value, obj), {})
            : {
                property: true,
                parameter1: true,
                parameter2: true,
                parameter3: true,
                unit1: true,
                unit2: true
            },
        contacts: contacts.length ? contacts : [{ frontId: 0, contact: "", role: "", role_id: "" }],
        countries: countries.length ? countries : [{ frontId: 0, value: "" }],
        suppliers: suppliers.length ? suppliers : [{ frontId: 0, value: "" }],
        content: content.length
            ? content.map(item => ({
                ...item,
                value: item.value
            }))
            : [
                { frontId: 0, value: '', template: '' },
                { frontId: 1, value: '', template: '' }
            ],
        materialProperties: materialProperties.length
            ? materialProperties
            : [{
                id: '',
                frontId: 0,
                property: '',
                parameter1: '',
                parameter2: '',
                parameter3: '',
                valueMin1: '',
                valueMax1: '',
                unit1: '',
                valueMin2: '',
                valueMax2: '',
                unit2: ''
            }],
        legal,
        legalHTML
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "publication-datasheets-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
