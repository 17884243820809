import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const DivFormColumn = ({ pref, className, children }) => (
    <div className={`${pref}__form-column ${className}`}>{children}</div>
);

DivFormColumn.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

DivFormColumn.defaultProps = {
    className: "",
    children: "",
};

const Column = ({ pref, colSpan, className, children }) => (
    <td colSpan={colSpan} className={`${pref}__form-column ${className}`}>
        {children}
    </td>
);

Column.propTypes = {
    colSpan: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.node,
};

Column.defaultProps = {
    colSpan: 1,
    className: "",
    children: "",
};

export const DivColumn = compose(withTagDefaultProps())(DivFormColumn);
export default compose(withTagDefaultProps())(Column);
