import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import PermissionsProps from "Hoc/Template/PermissionsProps";

const defaultProps = {
    permissions: [],
    permission: "",
};

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string),
    permission: PropTypes.string,
};

const UnCheckPermission = ({ permissions, permission, children }) => {
    if (!permission) return children;
    return <>{!permissions.includes(permission) ? children : null}</>;
};

UnCheckPermission.defaultProps = defaultProps;
UnCheckPermission.propTypes = propTypes;

export default compose(
    PermissionsProps,
)(UnCheckPermission);
