import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import TabView from "./Views/TabView";
import vendorsService from "./Services/Tabs/Vendors";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    deleteVendorCode: PropTypes.func.isRequired
};

const Vendor = () => {

    const onClickShow = item => {
        const win = window.open(`/vendors/${item.id}`, "_blank");
        win.focus();
    };

    return (
        <ServiceProvider value={vendorsService}>
            <TabView
                onClickCustom={onClickShow}
            />
        </ServiceProvider>
    );
};

Vendor.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        deleteVendorCode: getActionStore("deleteVendorCode", service, dispatch)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Vendor);
