import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { formatDate, getString, numberWithCommas } from "Services";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLink title={getString(item, key)} href={`/admin/companies/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "email":
            return (
                <TableRowItemLink title={getString(item, key)} href={`mailto:${getString(item, key)}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "start_fy":
        case "end_fy":
            return (
                <TableRowItemSpan title={formatDate(getString(item, key), "MM/DD")}>
                    {formatDate(getString(item, key), "MM/DD")}
                </TableRowItemSpan>
            );

        case "teams":
            return (
                <TableRowItemSpan title={numberWithCommas(getString(item, key), 0)}>
                    {numberWithCommas(getString(item, key), 0)}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
