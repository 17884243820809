import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { MainButtonLink, TabWrap } from "Templates/Content";
import { Title, WrapButtons, Total } from "Templates/Titles";
import TabViewIndex from "Templates/Tabs/DefaultWrap/TabViewIndex";
// import { url } from "Services";

const propTypes = {
    title: PropTypes.number.isRequired,
    // request: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const TabView = ({
    onClickEdit,
    onClickDelete,
    onClickCustom,
    // request,
    t,
    title,
}) => {
    return (
        <CheckPermission permission="admin_organizations_crud">
            <Title title={t("Organizations")} total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}>
                <WrapButtons>
                    {/* <MainButtonLink
                        href={url.getUrl("/admin/companies/export", request, "")}
                        className="main-btn_white"
                    >
                        {t("Export Companies")}
                    </MainButtonLink> */}
                    <MainButtonLink href="/admin/companies/create" className="main-btn_primary">
                        {t("Add New Organization")}
                    </MainButtonLink>
                </WrapButtons>
            </Title>
            <TabWrap>
                <TabViewIndex customIcon={'icon_external_link'} onClickCustom={onClickCustom} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
            </TabWrap>
        </CheckPermission>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        title: state.listState.pagination.total,
        // request: {
        //     filter: state.listState.filter,
        //     sort: state.listState.sort,
        // },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TabView);
