import { handleScroll } from "../../EventsService";
import { deleteItem } from "../../RequestService";
import { requestOnUploadFiles, getItem, requestOnSaveCopy, getUploadUrl } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";

export default id => ({
    requestItems: () => getItem(`/product/product-finder/${id}/brochures`),
    requestTypeItem: (item) => getItem(`/product/product-finder/${id}/brochures/${item.id}`),
    requestOnUploadFiles: requestOnUploadFiles(`/product/product-finder/${id}/brochures/add`),
    requestOnSaveCopy: (brochureId) => requestOnSaveCopy(`/product/brochures/${brochureId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/brochures/${item.id}/delete`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (brochureId, type) => getUploadUrl(id, brochureId, type),
});
