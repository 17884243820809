import {numberWithCommas} from "Services";

const getDefaultOption = type => {
    switch (type) {
        case "bar":
            return {
                type: "bar",
                borderWidth: 1,
            };

        case "line":
            return {
                type: "line",
                pointRadius: 0,
                fill: false,
            };

        default:
            return {};
    }
};

export const generateDatasets = (data, type) => {
    const defaultOptionInDatasets = getDefaultOption(type);
    const colors = [
        {
            backgroundColor: "rgb(161, 229, 205)",
            borderColor: "rgb(25, 94, 65)",
        },
        {
            backgroundColor: "rgb(158, 215, 254)",
            borderColor: "rgb(52, 135, 219)",
        },
        {
            backgroundColor: "rgba(56, 73, 227, 0.5)",
            borderColor: "rgb(52, 69, 219)",
        },
        {
            backgroundColor: "rgba(245, 154, 154, 0.5)",
            borderColor: "rgb(219, 52, 112)",
        },
        {
            backgroundColor: "rgba(245, 225, 154, 0.5)",
            borderColor: "rgb(219, 172, 52)",
        },
    ];
    const ListValuesByYear = Object.values(data);

    const dataItems = {};
    ListValuesByYear.forEach(items => {
        items.forEach((item) => {
            if (!dataItems[item.name]) {
                dataItems[item.name] = {
                    ...defaultOptionInDatasets,
                    ...colors[_.size(dataItems)],
                    id: item.id,
                    // internalItems: item.internal_items ? item.internal_items : [],
                    label: item.name,
                    data: _.fill(Array(ListValuesByYear.length), 0),
                    internalItems: _.fill(Array(ListValuesByYear.length), 0),
                };
            }
        });
    });

    ListValuesByYear.forEach((items, index) => {
        items.forEach(item => {
            if (dataItems[item.name]) {
                dataItems[item.name].data[index] = Number(item.sum);
                dataItems[item.name].internalItems[index] = item.internal_items
            }
        });
    });

    return Object.values(dataItems);
};
export const generateActualsDatasets = (data, type) => {
    const defaultOptionInDatasets = getDefaultOption(type);
    const ListValuesByYear = Object.values(data);

    const dataItems = {};
    ListValuesByYear.forEach(items => {
        items.forEach((item) => {
            if (!dataItems[item.name]) {
                dataItems[item.name] = {
                    ...defaultOptionInDatasets,
                    id: item.id,
                    // internalItems: item.internal_items ? item.internal_items : [],
                    label: item.name,
                    data: _.fill(Array(ListValuesByYear.length), []),
                    internalItems: _.fill(Array(ListValuesByYear.length), []),
                };
            }
        });
    });

    ListValuesByYear.forEach((items, index) => {
        items.forEach(item => {
            const newItem = {
                ...item,
                internal_items: item.internal_items.map((internalItem) => {
                    return {
                        ...internalItem,
                        actual_sum: +internalItem.actual_sum,
                        forecast_sum: +internalItem.forecast_sum,
                    }
                })
            }
            if (dataItems[newItem.name]) {
                dataItems[newItem.name].data[index] = {
                    actuals: numberWithCommas(newItem.internal_items.map((it) => +it.actual_sum).reduce((accumulator, currentValue) => accumulator + currentValue, 0), 0),
                    forecasts: numberWithCommas(newItem.internal_items.map((it) => +it.forecast_sum).reduce((accumulator, currentValue) => accumulator + currentValue, 0), 0)
                };
                dataItems[newItem.name].internalItems[index] = newItem.internal_items
            }
        });
    });

    return Object.values(dataItems);
};

export const getLabels = data => Object.keys(data);
