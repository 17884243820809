import _ from "lodash";

export const getString = (item, key, defaultValue = "-") => {
    const data = _.get(item, key);
    if (data === undefined || data === null || data === "") {
        return defaultValue;
    }
    return data;
};

export const getJson = (item, key, defaultValue = []) => {
    const str = getString(item, key, '');

    return str ? JSON.parse(str) : defaultValue;
};
