const Constants = {
    CHANGE_FORM: "CHANGE_FORM",

    FORM_REQUESTED: "FORM_REQUESTED",
    FORM_SUCCEEDED: "FORM_SUCCEEDED",
    FORM_FAILED: "FORM_FAILED",
    FORM_RESET: "FORM_RESET",

    OPEN_VALIDITY_PERIOD_MODAL: "OPEN_VALIDITY_PERIOD_MODAL",
    CLOSE_VALIDITY_PERIOD_MODAL: "CLOSE_VALIDITY_PERIOD_MODAL",

    UPDATE_VALIDITY_PERIOD_FORM: "UPDATE_VALIDITY_PERIOD_FORM",

    CHANGE_VALIDITY_PERIOD_FORM: "CHANGE_VALIDITY_PERIOD_FORM",
    EDIT_VALIDITY_PERIOD_FORM: "EDIT_VALIDITY_PERIOD_FORM",
    CHANGE_PERIODS: "CHANGE_PERIODS",
};

export default Constants;
