import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { canTranslate } from "Services";

const propTypes = {
    blank: PropTypes.bool,
    href: PropTypes.string.isRequired,
};

const defaultProps = {
    blank: true,
    className: "",
};

const FormBlockLink = ({ blank, href, className, pref, children, t }) => {
    return (
        <a target={blank ? "_blank" : "_self"} href={href} className={`${className} ${pref}__form-block-link`}>
            {React.Children.map(children, canTranslate(t))}
        </a>
    );
};

FormBlockLink.propTypes = propTypes;
FormBlockLink.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(FormBlockLink);
