import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { ContainerTable } from "Hoc/Widgets";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumnWrap/TableRowColumn";

const TableStructure = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);

const TabViewDetail = customTable(TableStructure);

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        taskStatus: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.number.isRequired,
                label: PropTypes.string.isRequired,
            }),
        ),
        requestItem: PropTypes.func.isRequired,
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const TabView = ({ request, fetchItems, service, t }) => {
    const { deleteItem, requestItem, taskStatus } = service;

    const onClickEdit = item => {
        url.redirect(`/tasks/${item.id}/edit`);
    };

    const onClickDelete = item => {
        if (window.confirm(t("Do you want to delete the Task?"))) {
            deleteItem(`/tasks/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickCustom = item => {
        const complete = taskStatus.find(elem => elem.label === "Completed");
        const text = item.sub_status === complete.value ? "cancel the completed" : "complete";
        const subUrl = item.sub_status === complete.value ? "un-complete" : "complete";
        if (window.confirm(t(`Do you want to ${text} task?`))) {
            requestItem(`/tasks/${item.id}/${subUrl}`).then(() => {
                fetchItems(request);
            });
        }
    };

    return <TabViewDetail onClickEdit={onClickEdit} onClickDelete={onClickDelete} onClickCustom={onClickCustom} />;
};

TabView.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
