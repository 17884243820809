import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { ContainerTable } from "Hoc/Widgets";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumnWrap/TableRowColumn";

const TableStructure = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);

const TabViewDetail = customTable(TableStructure);

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        requestItem: PropTypes.func.isRequired,
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const TabView = ({ request, fetchItems, editTeam, handleOpenModal, service, t }) => {
    const { deleteItem } = service;

    const onClickEdit = ({ id, name, show_filter, alternate_name }) => {
        editTeam({ id, name, show_filter, alternate_name });
        handleOpenModal();
    };

    const onClickDelete = item => {
        if (window.confirm(t("Do you want to delete the item?"))) {
            deleteItem(`/admin/process-properties/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    return <TabViewDetail onClickEdit={onClickEdit} onClickDelete={onClickDelete} />;
};

TabView.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        editTeam: getActionStore("editTeamAction", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
