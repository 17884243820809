import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import { filter } from "Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { items, pagination, columns } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "help-page" }),
        listState: {
            ...mainState,
            items,
            pagination,
            columns,
            filter: filter.getParsedFilters(),
            sort: filter.getSort(),
        },
    };

    return _configureStore(initialState, reducers);
}
