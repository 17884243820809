import React from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { contactPhoneTypes, contactEmailTypes } from "Services/enum";
import { BlockWrap, Block, BlockWrapFlex, Input, WrapInput } from "Templates/Form";
import { phoneList } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Button } from "Templates/Default";
import LinkIcon from "Templates/Link/LinkIcon";

const attachEmail = () => {
    return {
        type: '1',
        value: ""
    };
};

const attachPhone = () => {
    return {
        type: '1',
        code: "+1",
        number: "",
    };
};

const addItem = (appendFunc, serviceFunc) => items => () => {
    serviceFunc(_.concat(items, appendFunc()));
};

const removeItem = serviceFunc => (items, index) => () => {
    serviceFunc(items.filter((i, k) => k !== index));
};

const onChangeItem = serviceFunc => (itemsMap, key) => (value, index) => {
    _.set(itemsMap, index, value);
    serviceFunc(key, _.concat(itemsMap));
};

const propTypes = {
    emails: PropTypes.arrayOf(PropTypes.object).isRequired,
    phones: PropTypes.arrayOf(PropTypes.object).isRequired,
    addPhone: PropTypes.func.isRequired,
    addEmail: PropTypes.func.isRequired,
    removePhone: PropTypes.func.isRequired,
    removeEmail: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const ContactBlock = ({ emails, phones, onChange, addPhone, addEmail, removePhone, removeEmail, t }) => {
    const onChangePhone = onChange(phones, "phones");
    const onChangeEmail = onChange(emails, "emails");

    return (
            <BlockWrap>
                <Block>
                    <BlockWrap>
                        {phones.map((item, key) => (
                            <BlockWrapFlex key={`BlockWrapFlex-phones-${key}`}>
                                <WrapInput label={t("Label")} name={`${key}.type`} isWidth>
                                    <Input
                                        type="select"
                                        value={item.type}
                                        onChange={value => onChangePhone(value, `${key}.type`)}
                                        options={contactPhoneTypes}
                                    />
                                </WrapInput>
                                <WrapInput label={t("Country Code")} name={`${key}.code`} isWidth>
                                    <Input
                                        type="searchSelect"
                                        value={item.code}
                                        onChange={value => onChangePhone(value, `${key}.code`)}
                                        options={phoneList}
                                    />
                                </WrapInput>
                                <WrapInput label={t("Phone")} name={`${key}.number`}>
                                    <Input
                                        placeholder={t("Phone")}
                                        value={item.number}
                                        onChange={value => onChangePhone(value, `${key}.number`)}
                                    />
                                </WrapInput>
                                {phones.length > 1 && <LinkIcon onClick={removePhone(phones, key)} />}
                            </BlockWrapFlex>
                        ))}
                        <Button className="size_normal main-btn main-btn_white" onClick={addPhone(phones)}>
                            {t("Add Phone")}
                        </Button>
                    </BlockWrap>
                    <BlockWrap>
                        {emails.map((item, key) => {
                            return (
                                <BlockWrapFlex key={`BlockWrapFlex-emails-${key}`}>
                                    <WrapInput label={t("Label")} name={`${key}.type`} isWidth>
                                        <Input
                                            type="select"
                                            value={item.type}
                                            onChange={value => onChangeEmail(value, `${key}.type`)}
                                            options={contactEmailTypes}
                                        />
                                    </WrapInput>
                                    <WrapInput label={t("Email")} name={`${key}.value`} isRequired={!key}>
                                        <Input
                                            placeholder={t("Email")}
                                            value={item.value}
                                            onChange={value => onChangeEmail(value, `${key}.value`)}
                                        />
                                    </WrapInput>
                                    {emails.length > 1 && <LinkIcon onClick={removeEmail(emails, key)} />}
                                </BlockWrapFlex>
                            )
                        })}
                        <Button className="size_normal main-btn main-btn_white" onClick={addEmail(emails)}>
                            {t("Add Email")}
                        </Button>
                    </BlockWrap>
                </Block>
            </BlockWrap>
    );
};

ContactBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { service } = ownProps;
    const { getStoreItem } = service;

    return {
        emails: getStoreItem(state, `emails`),
        phones: getStoreItem(state, `phones`),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    const onChange = getActionStore("onChange", service, dispatch);
    const refreshEmail = getActionStore("refreshEmail", service, dispatch);
    const refreshPhone = getActionStore("refreshPhone", service, dispatch);

    return {
        addPhone: addItem(attachPhone, refreshPhone),
        removePhone: removeItem(refreshPhone),

        addEmail: addItem(attachEmail, refreshEmail),
        removeEmail: removeItem(refreshEmail),

        onChange: onChangeItem(onChange),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ContactBlock);
