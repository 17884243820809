import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import * as formActions from "../../Actions/FormActions";

export const getStoreItem = _getStoreItem("listState");
export const getChartData = store => getStoreItem(store, "data");

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...formActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
