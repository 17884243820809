import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import ItemMenuContent from "./ItemMenuContent";
import {CheckPermission} from "Hoc/Template";

const propTypes = {
    counter: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    map: PropTypes.shape({
        img: PropTypes.string.isRequired,
        childrens: PropTypes.shape({
            [PropTypes.string]: PropTypes.shape({
                link: PropTypes.shape.isRequired,
                sublabel: PropTypes.string,
            }),
            multilevelMenu: PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.arrayOf(PropTypes.object).isRequired,
            }),
            solutionMultilevelMenu: PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.arrayOf(PropTypes.object).isRequired,
            }),
        }),
        options: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    }).isRequired,
};

const multilevelMenuLinks = [
    "/admin/material-properties",
    "/admin/property-hierarchies",
    "/admin/process-properties",
    "/admin/process-hierarchies"
];

const solutionMultilevelMenuLinks = [
    "/admin/solution-material-properties",
    "/admin/solution-property-hierarchies",
    "/admin/solution-process-properties",
    "/admin/solution-process-hierarchies"
];

class MultilevelMenu extends Component {
    constructor(props) {
        super(props);
        const { map } = this.props;
        this.state = {
            showItems: multilevelMenuLinks.includes(window.location.pathname)
                ? multilevelMenuLinks.some(link => window.location.pathname.startsWith(link))
                : solutionMultilevelMenuLinks.includes(window.location.pathname)
                    ? solutionMultilevelMenuLinks.some(link => window.location.pathname.startsWith(link))
                    : Object.values(map.childrens).some(elem => window.location.pathname.startsWith(elem.link)),
        };
    }

    handleClickOnMenuItem = e => {
        e.preventDefault();
        this.setState(prevState => ({
            showItems: !prevState.showItems,
        }));
    };

    render() {
        const { label, map, counter } = this.props;
        const { showItems } = this.state;

        const handleMouseOver = (e) => {
            e.persist();
            if (typeof document !== 'undefined') {
                const element = document.querySelector('.main-sidebar__multilevel-item');
                const multilevelMenuElement = document.querySelector('.menu-items');
                multilevelMenuElement.style.top = `${element.getBoundingClientRect().top + 40}px`;
            }
        }

        return (
            <li className={`main-sidebar__list-item ${showItems ? map.options.dropdownActiveClass : ""}`}>
                <ItemMenuContent onClick={this.handleClickOnMenuItem} img={map.img} label={label} />
                <ul className={`main-sidebar__list menu_level_${counter} ${map.childrens.multilevelMenu || map.childrens.solutionMultilevelMenu ? 'with-multilevel-menu' : ''}`}>
                    {Object.keys(map.childrens).map((item, index) => {
                        let permissionList = map.childrens[item].permission || [];
                        if(map.childrens[item].permission && !_.isArray(map.childrens[item].permission)) {
                            permissionList = [map.childrens[item].permission];
                        }

                        return <Fragment key={`multilevel-menu-item-${index}`}>
                            {item === 'multilevelMenu' || item === 'solutionMultilevelMenu'
                                ? <CheckPermission permissionList={permissionList}>
                                    <li
                                        className={`
                                        main-sidebar__list-item 
                                        main-sidebar__multilevel-item 
                                        ${item === 'multilevelMenu' && multilevelMenuLinks.includes(window.location.pathname) 
                                            ? "hierarchies-active"
                                            : item === 'solutionMultilevelMenu' && solutionMultilevelMenuLinks.includes(window.location.pathname)
                                                ? "solutions-active"
                                                : ""}
                                        `}
                                        onMouseOver={(e) => handleMouseOver(e)}
                                    >
                                        <p className="main-sidebar__list-link"
                                           data-sublabel={item === 'multilevelMenu' ? map.childrens.multilevelMenu.sublabel : map.childrens.solutionMultilevelMenu.sublabel}>
                                            <span
                                                className="main-sidebar__list-text">{item === 'multilevelMenu' ? map.childrens.multilevelMenu.label : map.childrens.solutionMultilevelMenu.label}</span>
                                        </p>
                                        <ul className="menu-items">
                                            {item === 'multilevelMenu' ? map.childrens.multilevelMenu.value.map((elem, menuIndex) => {
                                                    const currentActive = elem.subValue.find((subVal) => subVal.link === window.location.pathname);
                                                    return (
                                                        <li className={`menu-items-item ${currentActive && currentActive.link === window.location.pathname ? 'menu-items-item__active' : ''}`} key={menuIndex}>
                                                            <p>{elem.subLabel}</p>
                                                            <ul className="menu-sub-items">
                                                                {elem.subValue.map((menuItem, i) => {
                                                                    return (
                                                                        <li key={i} className={menuItem.link === window.location.pathname ? 'menu-sub-item__active' : ''}>
                                                                            <a href={menuItem.link}
                                                                               data-sublabel={menuItem.sublabel}>{menuItem.name}</a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </li>
                                                    );
                                                }
                                            )
                                            :
                                                map.childrens.solutionMultilevelMenu.value.map((elem, menuIndex) => {
                                                        const currentActive = elem.subValue.find((subVal) => subVal.link === window.location.pathname);
                                                        return (
                                                            <li className={`menu-items-item ${currentActive && currentActive.link === window.location.pathname ? 'menu-items-item__active' : ''}`} key={menuIndex}>
                                                                <p>{elem.subLabel}</p>
                                                                <ul className="menu-sub-items">
                                                                    {elem.subValue.map((menuItem, i) => {
                                                                        return (
                                                                            <li key={i} className={menuItem.link === window.location.pathname ? 'menu-sub-item__active' : ''}>
                                                                                <a href={menuItem.link}
                                                                                   data-sublabel={menuItem.sublabel}>{menuItem.name}</a>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </li>
                                                        );
                                                    }
                                                )
                                            }
                                        </ul>
                                    </li>
                                </CheckPermission>
                                : <CheckPermission permissionList={permissionList}>
                                <li key={index} className={`main-sidebar__list-item ${map.childrens[item].link === window.location.pathname ? map.options.selectedItem : ""}`}>
                                    <a
                                        href={map.childrens[item].link}
                                        className="main-sidebar__list-link"
                                        data-sublabel={map.childrens[item].sublabel}
                                    >
                                        <span
                                            className="main-sidebar__list-text">{(item !== 'multilevelMenu' && item !== 'solutionMultilevelMenu') ? item : ''}</span>
                                    </a>
                                </li>
                            </CheckPermission>
                            }
                            </Fragment>
                    })}
                </ul>
            </li>
        );
    }
}

MultilevelMenu.propTypes = propTypes;

export default MultilevelMenu;
