import React, {useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {Input, WrapInput} from "Templates/Form";
import {uniqueGenerator} from "Services";
import {onChange} from "../../Services/EventsService";
import {InputFilter} from "Templates/Table/Filters";

const {Button, Form, Title} = ModalDefault;

const propTypes = {
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    onClose: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    updatePublicationForm: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    updateGroups: PropTypes.func.isRequired,
    savePublicationsList: PropTypes.func.isRequired,
    savePublicationsToExisting: PropTypes.func.isRequired,
};

const NEW_LIST = 'new_list';
const EXISTING_LIST = 'existing_list';

const ModalActions = ({modalType, t, newListName, isValidFormGroups, onSetModal, onSave}) => {
    return (
        <div className="add-shared-publications-modal__actions">
            {!modalType && (
                <>
                    <Button onClick={() => {
                        onSetModal(NEW_LIST)
                    }}
                    >
                        {t(`Add to New List`)}
                    </Button>
                    <Button onClick={() => {
                        onSetModal(EXISTING_LIST)
                    }}
                    >
                        {t(`Add to Existing List`)}
                    </Button>
                </>
            )}
            {modalType && modalType.type === NEW_LIST && (
                <>
                    <Button onClick={() => {
                        onSetModal(EXISTING_LIST)
                    }}
                    >
                        {t(`Add to Existing List`)}
                    </Button>
                    <Button disabled={newListName === ''} onClick={onSave}>
                        {t(`Save`)}
                    </Button>
                </>
            )}
            {modalType && modalType.type === EXISTING_LIST && (
                <>
                    <Button onClick={() => {
                        onSetModal(NEW_LIST)
                    }}
                    >
                        {t(`Add to New List`)}
                    </Button>
                    <Button
                        disabled={isValidFormGroups}
                        onClick={onSave}
                    >
                        {t(`Save`)}
                    </Button>
                </>
            )}
        </div>
    )
}

const AddSharedProductsPublicationListModal = ({
                                                   filters,
                                                   onClose,
                                                   t,
                                                   groups,
                                                   updateGroups,
                                                   form,
                                                   publicationId,
                                                   updatePublicationForm,
                                                   savePublicationsList,
                                                   savePublicationsToExisting
                                               }) => {
    const [modalType, setModalType] = useState(null);
    const [newListName, setNewListName] = useState('')

    const setModal = (type) => {
        switch (type) {
            case NEW_LIST:
                setModalType({
                    title: 'Add Filtered Publications to New List',
                    type
                });
                updateGroups([{id: uniqueGenerator(), value: ''}]);
                break
            case EXISTING_LIST:
                setModalType({
                    title: 'Add Filtered Publications to Existing List',
                    type
                });
                setNewListName('');
                break
            default:
                setModalType(null)
                updateGroups([{id: uniqueGenerator(), value: ''}]);
                setNewListName('');
        }
    }

    const isValidFormGroups = () => {
        return form.groups && form.groups.length > 0;
    };

    const handleSave = () => {
        if (modalType.type === NEW_LIST) {
            const payload = {newListName, filters}
            savePublicationsToExisting(payload).then(() => {
                alert('Congratulations! Your Publication list saved successfully.');
            }).catch(() => {
                alert('Error! Something went wrong!');
            });
        }

        if (modalType.type === EXISTING_LIST) {
            savePublicationsList({
                ids: form.groups,
                publication_id: publicationId,
            })
        }
    }

    const getUrl = () => {
        return `/product/product-finder/publications/${publicationId}/search/groups`
    }

    return (
        <Form className="add-shared-publications-modal">
            <ButtonClose
                className="main-modal__form-close"
                onClick={() => {
                    updateGroups([{id: uniqueGenerator(), value: ''}]);
                    setNewListName('');
                    onClose(true);
                }}
            />
            <Title>{modalType ? t(`${modalType.title}`) : t(`Add Publication to Existing or New List`)}</Title>
            {modalType && modalType.type === NEW_LIST && (
                <>
                    <WrapInput isRequired label={t("New List Name")} name="newLists">
                        <Input
                            placeholder="Publication List Name"
                            value={newListName}
                            onChange={(e) => {
                                setNewListName(e);
                            }}
                        />
                    </WrapInput>
                </>
            )}
            {modalType && modalType.type === EXISTING_LIST && (
                <WrapInput
                    label={t('Lists')}
                    name="list"
                    isRequired
                    className={`wrap-input--delete ${groups.length > 1 ? 'with-delete' : ''}`}
                >
                    <InputFilter
                        type="asyncMultiSelectNoCache"
                        placeholder="Search Publication List"
                        url={getUrl()}
                        value={form.groups}
                        onChange={e => {
                            onChange(e).then(_value => updatePublicationForm('groups', _value));
                        }}
                    />
                </WrapInput>
            )}
            <ModalActions
                modalType={modalType}
                t={t}
                newListName={newListName}
                isValidFormGroups={!isValidFormGroups()}
                onSetModal={setModal}
                onSave={handleSave}
            />
        </Form>
    );
}

AddSharedProductsPublicationListModal.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        groups: getStoreItem(state, "groups"),
        form: getStoreItem(state, "addToPublicationsListForm"),
        publicationId: getStoreItem(state, "view.id"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        updateGroups: getActionStore("updateGroups", service, dispatch),
        updatePublicationForm: getActionStore("updatePublicationForm", service, dispatch),
        savePublicationsList: getActionStore("savePublicationsList", service, dispatch),
        savePublicationsToExisting: getActionStore("saveNewPublicationsList", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddSharedProductsPublicationListModal);