import React from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form } from "Templates/Form";
import BudgetGeneralBlock from "./Blocks/BudgetGeneralBlock";

const BudgetFormBlock = () => {
    return (
        <Form>
            <BudgetGeneralBlock />
        </Form>
    );
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(BudgetFormBlock);
