import Constants from "../Constants";

export const initState = {
    email: "",
    error: "",
    status: "",
    password: "",
    twoFactor: false,
    lastAction: "",
    sessions: [],
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.SUBMIT_FROM_REQUEST:
            return { ...state };

        case Constants.SHOW_SESSION_MODAL:
            return {
                ...state,
                lastAction: Constants.SHOW_SESSION_MODAL,
                error: data.res.error,
                sessions: data.res.data,
                password: data.data.password,
            };

        case Constants.SHOW_TWO_FACTOR_MODAL:
            return {
                ...state,
                lastAction: Constants.SHOW_TWO_FACTOR_MODAL,
                error: data.res.error,
                password: data.data.password,
                twoFactor: data.res.data.twoFactor,
            };

        case Constants.CLOSE_TWO_FACTOR_MODAL: {
            return {
                ...state,
                lastAction: Constants.CLOSE_TWO_FACTOR_MODAL,
                error: initState.error,
                password: initState.password,
                twoFactor: false,
            };
        }

        case Constants.CLOSE_SESSION_MODAL: {
            return {
                ...state,
                lastAction: Constants.CLOSE_SESSION_MODAL,
                error: initState.error,
                sessions: [],
                password: initState.password,
            };
        }

        case Constants.SUBMIT_FROM_FAILURE:
            return { ...state, lastAction: Constants.SUBMIT_FROM_FAILURE, error: data.error };

        default: {
            return state;
        }
    }
};
