import _ from "lodash";
import Constants from "../Constants";

export const initialState = {
    name: "",
    industry: "",
    segment: "",

    form_validate: false,
    form_loading: false,
    form_errors: {},

    old: {},
};

const validate = ({ name, industry }) => {
    return [name, industry].every(el => typeof el === "string" && el.trim());
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ...newState, form_validate: validate(newState) };
        }

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };

        default:
            return { ...state };
    }
};
