import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    TabBlock,
    TabBlockBtn,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { ContainerTable } from "Hoc/Widgets";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumnWrap/TableRowColumn";

const TableStructure = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);

const TabViewDetail = customTable(TableStructure);

const Users = ({ id, pagination, t }) => {
    const onClickEdit = item => {
        window.open(`/admin/users/${item.id}/edit`, "_blank");
    };

    const onClickAddButton = () => {
        window.open(`/admin/users/create/${id}`, "_blank");
    };

    return (
        <>
            <TabWrapTop>
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                        <TabBlockColTitleH4>
                            {`${numberWithCommas(pagination.total, 0)} ${t("Users")}`}
                        </TabBlockColTitleH4>
                    </TabBlockCol>
                </TabBlock>
                <TabBlockBtn onClick={onClickAddButton}>{t("Add New User")}</TabBlockBtn>
            </TabWrapTop>
            <TabViewDetail onClickEdit={onClickEdit} />
        </>
    );
};

Users.propTypes = {
    id: PropTypes.string.isRequired,
    pagination: PropTypes.shape({ total: PropTypes.number }).isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Users);
