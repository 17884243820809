import React from "react";
import _ from "lodash";
import {FormBlockLink} from "Templates/Form";
import {Span} from "Templates/Default";
import {LinkPermission} from "Hoc/Template";
import {getStoreItem as _getStoreItem} from "Services";
import { weightUnitName, weightUnitConvertLbs } from 'Services/weightUnitName';

export const getStoreItem = _getStoreItem("listState");
export const itemsName = "items.general";
export const columnsName = {
    customer: "Customer",
    manager: "Project Manager",
    application: "Application",
    part_weight: "Part Weight",
    parts_per_unit: "# Parts per Unit",
    total_volume: "Total Volume"
};

export const getBodyView = (key, item, weightUnit) => {
    const link = href => <FormBlockLink href={href}>{_.get(item, key, "")}</FormBlockLink>;

    switch (key) {
        case "customer": {
            return link(`/companies/${_.get(item, "customer_id")}`);
        }

        case "manager": {
            return <LinkPermission permission="admin" exclude={<Span>{_.get(item, key, "")}</Span>}>
                {link(`/admin/users/${_.get(item, "manager_id")}`)}
            </LinkPermission>;
        }

        case "parts_per_unit":
            return <Span>{Math.floor(_.get(item, key, "0"))}</Span>;

        case "part_weight":
          return <Span>{`${_.get(item, key, "")} ${weightUnitConvertLbs(weightUnitName(weightUnit.weightUnit))}`}</Span>;

        default:
            return <Span>{_.get(item, key, "")}</Span>;
    }
};
