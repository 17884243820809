import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { storageUrl } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";

const propTypes = {
    storage_url: PropTypes.string.isRequired,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
    editTeam: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
};

class Iso extends Component {
    render = () => {
        const {
            t,
            fetchItems,
            request,
            editTeam,
            handleOpenModal,
            service: { onClickDeleteItem },
        } = this.props;

        const onClickDelete = item => {
            if (!item.can_edit) {
                alert('Contact the owner or an Admin to delete.');
                return;
            }

            if (window.confirm(t("Do you really want to delete selected ISO?"))) {
                onClickDeleteItem(item).then(
                    () => {
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        };

        const onClickEdit = item => {
            if (!item.can_edit) {
                alert('Contact the owner or an Admin to edit.');
                return;
            }

            editTeam(item);
            handleOpenModal();
        };

        const onClickCustom = item => {
            if (!item.can_edit) {
                alert('Contact the owner or an Admin to download.');
                return;
            }

            const { storage_url } = this.props;
            window.open(storageUrl(_.get(item, "path"), storage_url), "_blank");
        };

        return (
            <>
                <TabViewDetail
                    onClickCustom={onClickCustom}
                    customIcon="icon_download"
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                />
            </>
        );
    };
}

Iso.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        editTeam: getActionStore("editTeamAction", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Iso);
