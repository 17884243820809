import React from "react";
import { compose } from "redux";
// import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabView from "./Views/TabView";

const propTypes = {};

const Index = () => {
    return <TabView />;
};

Index.propTypes = propTypes;

// const mapStateToProps = state => {
//     return {
//         request: {
//             filter: {
//                 ...state.listState.filter,
//             },
//             sort: {
//                 ...state.listState.sort,
//             },
//         },
//     };
// };

// const mapDispatchToProps = (dispatch, { service }) => {
//     const { getActionStore } = service;

//     return {
//         fetchItems: getActionStore("fetchItems", service, dispatch),
//     };
// };

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Index);
