import React, {Component} from "react";
import {withTagDefaultProps} from "Hoc/Template";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import Img, {ImgStorage} from "Templates/Img";

class Owner extends Component {
    constructor(props) {
        super(props);

        this.name = props.name;
        this.email = props.email;
        this.photo = props.photo;
        this.title = props.title;
    }

    render() {
        return (
            <div className="is-shared-list-block__info">
                <p className="title">This list was shared with you by Mitsubishi Chemical Group (MCG)</p>
                <div className="owner-img">
                    {
                        this.photo ?
                            <ImgStorage url={this.photo} className="publication-groups__user-img"/> :
                            <Img img="contact_with_circle" width="22.5" height="22.5"/>
                    }
                </div>
                <p className="approver">{this.name}</p>
                <p className="owner-title">{this.title}</p>
                <a href={`mailto: ${this.email}`} className="email" title={this.email}>{this.email}</a>
            </div>
        );
    }
}

Owner.propTypes = {};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Owner);