import React from 'react';
import PropTypes from 'prop-types';
import {PermissionsProps} from 'Hoc/Template';

import Tab from './Tab';

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    permission: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    counter: PropTypes.any,
    activeTab: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    productOnly: PropTypes.bool,
    isActiveTab: PropTypes.bool,
};

const TabCheck = ({ label, counter, onClick, activeTab, index, permissions, permission, productOnly, isActiveTab, titleMessage }) => {

    if (permission && !permissions.includes(permission)) {
        return null;
    }

    return <Tab label={ label }
                counter={counter || ''}
                onClick={ onClick }
                activeTab={ activeTab }
                index={ index }
                titleMessage={ titleMessage }
                isActiveTab={ isActiveTab }/>;
};

TabCheck.propTypes = propTypes;

TabCheck.defaultProps = {
    productOnly: false,
    isActiveTab: true,
    counter: null,
};

export default PermissionsProps(TabCheck);
