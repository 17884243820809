import React from 'react';
import PropTypes from "prop-types";

function Content({ data, className }) {
    return (
        <div dangerouslySetInnerHTML={{ __html: data }} className={`technical-content ${className}`} id="css-render-form" />
    );
}

Content.defaultProps = {
    data: "",
    className: "",
};
Content.propTypes = {
    data: PropTypes.string,
    className: PropTypes.string,
};

export default Content;
