import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    className: PropTypes.string,
};

const TabBlockColTitleH4 = ({ pref, children, className }) => (
    <h4 className={`${pref}__top-h4 main-content__top-h4 ${className}`}>{children}</h4>
);

TabBlockColTitleH4.defaultProps = {
    className: "",
};

TabBlockColTitleH4.propTypes = propTypes;

export default compose(withTagDefaultProps())(TabBlockColTitleH4);
