const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",
    RESET_FILTER: "RESET_FILTER",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    EDIT_PRODUCT: "EDIT_PRODUCT",
    RESET_FORM: "RESET_FORM",

    /* analytics tab */
    CHANGE_FORM: "CHANGE_FORM",

    CHART_DATA_REQUESTED: "CHART_DATA_REQUESTED",
    CHART_DATA_SUCCEEDED: "CHART_DATA_SUCCEEDED",
    CHART_DATA_FAILED: "CHART_DATA_FAILED",

    HEADER_DATA_REQUESTED: "HEADER_DATA_REQUESTED",
    HEADER_DATA_SUCCEEDED: "HEADER_DATA_SUCCEEDED",
    HEADER_DATA_FAILED: "HEADER_DATA_FAILED",

    RESET_ANALYTICS: "RESET_ANALYTICS",
    /* analytics tab */
};
export default Constants;
