import React from 'react';
import {Div} from 'Templates/Default';
import BlockTitle from 'Templates/Form/BlockTitle';
import {InputFilter} from 'Templates/Table/Filters';
import {industryOptions, datasheetLocation, isBoundable} from 'Services/enum';
import PropTypes from 'prop-types';
import {Tooltip} from 'Templates/Form';
import {onChange} from '../../Services/EventsService';
import InfoTooltip from "Templates/InfoTooltip/InfoTooltip";

function Application({callback, filter}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    const sortFn = (a, b) => {
        if (a.label > b.label) {
            return 1;
        }
        if (a.label < b.label) {
            return -1
        }
        return 0;
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>
                    <p className="filter-with-tooltip">
                        Application
                    </p>
                </BlockTitle>
                <button
                    type="button"
                    className={['cost-modal__calculator_btn', showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    <>
                        <BlockTitle className="main-table__head-link">
                            SAP Application Code
                            <InfoTooltip classBlock="gradeFilterBlock-sapApplicationCode" content={
                                `<div class="padding-bottom__small">The SAP application code is automatically assigned to an SAP Product.</div>`
                            }/>
                        </BlockTitle>
                        <InputFilter
                            type="asyncMultiSelect"
                            placeholder="Select"
                            url="/product/product-finder/application-code-list"
                            value={filter.application_code}
                            onChange={e => {
                                onChange(e).then(_value => callback('application_code', _value));
                            }}
                        />
                    </>
                    <>
                        <p className="main-table__head-link">Market Segment</p>
                        <InputFilter
                            type="multiSelect"
                            options={industryOptions.sort(sortFn)}
                            placeholder="Select"
                            name="market_segment"
                            value={filter.market_segment}
                            onChange={e => {
                                onChange(e).then(_value => callback('market_segment', _value));
                            }}
                        />
                        <p className="main-table__head-link">Segment</p>
                        <InputFilter
                            type="asyncMultiSelect"
                            placeholder="Select"
                            url="/product/product-finder/segment-list"
                            value={filter.segment}
                            onChange={e => {
                                onChange(e).then(_value => callback('segment', _value));
                            }}
                        />
                        <p className="main-table__head-link">Sub-Segment (Application)</p>
                        <InputFilter
                            type="asyncMultiSelect"
                            placeholder="Select"
                            url="/product/product-finder/sub-segment-list"
                            value={filter.sub_segment}
                            onChange={e => {
                                onChange(e).then(_value => callback('sub_segment', _value));
                            }}
                        />
                    </>
                    <p className="main-table__head-link filter-with-tooltip">
                        Bondable
                    </p>
                    <InputFilter
                        type="multiSelect"
                        placeholder="Select"
                        options={isBoundable}
                        value={filter.boundable}
                        onChange={e => {
                            onChange(e).then(_value => callback('boundable', _value));
                        }}
                    />
                    <>
                        <p className="main-table__head-link">Vehicle Location</p>
                        <InputFilter
                            type="multiSelect"
                            value={filter.location}
                            options={[...datasheetLocation]}
                            placeholder="Select"
                            onChange={e => {
                                onChange(e).then(_value => callback('location', _value));
                            }}
                        />
                    </>
                </div>
            )}
        </>
    );
}

Application.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Application;
