import React, {Component, Fragment} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {BlockSubmit, ButtonLeft, ButtonRight, Checkbox, Col, Input, Row} from "Templates/Form";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {TabBlockBtn} from "Templates/Content";
import {budgetTableColumns} from "../../Constants/tableColumns";
import {requiredBudgetTableColumns} from "../../Constants/requiredTableColumns";
import hiddenBudgetTableColumns from "../../Constants/hiddenBudgetTableColumns";
import {ColInput, ColText} from "Templates/Modals/ModalDefault";
import {deleteItem} from "../../Services";
import {editBudgetTemplateRequest, requestBudgetCreateOrder} from "../../Services/RequestService";
import {request, url} from "Services";
import Img from "Templates/Img/Img";

const {CheckboxBlock, CheckboxButton, CheckboxTitle} = Checkbox;
const {Button, Form, Title} = ModalDefault;

const propTypes = {
    onAppend: PropTypes.func.isRequired,
};

const SEPARATOR_COLUMN = 'separator'

const CheckboxPinnedColumns = ({value, pinnedColumns, onChangePinnedColumns}) => {
    return (
        <CheckboxBlock>
            <CheckboxButton
                htmlFor={`${value}`}
                value={pinnedColumns.includes(value)}
                inputClass={`table-settings-checkbox-input`}
                onChange={(event) => {
                    onChangePinnedColumns(value)
                }}
            >
                <CheckboxTitle title={"Pin Column"} className="bold-text"> </CheckboxTitle>
            </CheckboxButton>
        </CheckboxBlock>
    );
}

const CheckboxBlockMod = ({value, activeColumns, onChange, currentFY}) => {
    const nextFY = +currentFY + 1;

    const tableLabel = {
        ...budgetTableColumns,
        current: `Forecast FY ${currentFY}`,
        next: `Forecast FY ${nextFY}`,
    };

    return (
        <CheckboxBlock>
            <CheckboxButton
                htmlFor={`${value}`}
                value={activeColumns.includes(value)}
                inputClass={`table-settings-checkbox-input ${requiredBudgetTableColumns.includes(value) ? 'disabled' : ''}`}
                onChange={(event) => {
                    onChange(value)
                }}
            >
                <CheckboxTitle title={_.get(tableLabel, value, value)} className="bold-text"> </CheckboxTitle>
            </CheckboxButton>
        </CheckboxBlock>
    );
}

const DragHandle = SortableHandle(() => <span className="table-settings__style__handle"> </span>);

const SortableItem = SortableElement(({value, activeColumns, pinnedColumns, onChange, onChangePinnedColumns, currentFY}) => {
    if (value === SEPARATOR_COLUMN) {
        if (pinnedColumns.length) return <li id="pinned_columns_separator" className={'edit-table-settings-li table-settings-li separator'}></li>

        return <li style={{ display: 'none' }}></li>
    }
    return (
        <li className={`edit-table-settings-li table-settings-li ${value}`}>
            <DragHandle/>
            <CheckboxBlockMod value={value} activeColumns={activeColumns} onChange={onChange} currentFY={currentFY}/>
            <span className="edit-table-settings-li__pin">
                <CheckboxPinnedColumns value={value} pinnedColumns={pinnedColumns} onChangePinnedColumns={onChangePinnedColumns}/>
            </span>
        </li>
    )
});

const SortableList = SortableContainer(({items, onChange, onChangePinnedColumns, activeColumns, pinnedColumns, currentFY}) => {
    return (
        <ul id="sortable_list">
            {items.map((value, index) => (
                !hiddenBudgetTableColumns.includes(value) ?
                    (<Fragment key={`item-${value}`}>
                        <SortableItem
                            index={index}
                            activeColumns={activeColumns}
                            pinnedColumns={pinnedColumns}
                            currentFY={currentFY}
                            value={value}
                            onChange={onChange}
                            onChangePinnedColumns={onChangePinnedColumns}
                        />
                    </Fragment>) : null
            ))}
            {hiddenBudgetTableColumns.map((value) => (
                <li key={`check-block-${value}`} className={`edit-table-settings-li table-settings-li ${value}`}>
                    <CheckboxBlockMod
                        value={value}
                        activeColumns={activeColumns}
                        currentFY={currentFY}
                        onChange={onChange}/>
                </li>
            ))}
        </ul>
    );
});

class BudgetTableSettingsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultColumns: [],
            selectedAll: false,
            activeColumns: [],
            pinnedColumns: [],
            showChangeOrderContent: false,
            orderName: '',
            idTemplate: '',
            showSelect: true,
            orderNameError: null,
            movedColumn: '',
            isRemovedTemplate: false,
            editTemplate: false,
        };

        this.constActiveColumns = [];
        this.constPinnedColumns = [];
        this.constDefaultColumns = [];
        this.constDefaultOrderColumns = [];
        this.constIdTemplate = '';

        const {requestBudgetsGetSettings} = props.service;
        requestBudgetsGetSettings().then(res => {
            const pinnedColumnsIndex = _.get(res, '0.pinnedColumns', []).length - 1

            const defaultColumns = _.get(res, '0.defaultColumns', []).slice();

            defaultColumns.splice(pinnedColumnsIndex + 1, 0, SEPARATOR_COLUMN);

            this.constActiveColumns = [...[SEPARATOR_COLUMN], ..._.cloneDeep(_.get(res, '0.activeColumns', []))];
            this.constDefaultColumns = defaultColumns;
            this.constDefaultOrderColumns = _.cloneDeep(_.get(res, '0.defaultOrderColumns', []));
            this.constPinnedColumns = _.cloneDeep(_.get(res, '0.pinnedColumns', []));
            this.constIdTemplate = _.cloneDeep(_.get(res, '0.idTemplate', ''));

            this.setState({
                defaultColumns,
                activeColumns: _.get(res, '0.activeColumns', []),
                pinnedColumns: _.get(res, '0.pinnedColumns', []),
                idTemplate: _.get(res, '0.idTemplate', ''),
                selectedAll: this.constActiveColumns.length === this.constDefaultColumns.length
            });
        });
    }

    componentDidUpdate() {
        const {pinnedColumns, movedColumn} = this.state;

        if (typeof document !== 'undefined' && movedColumn) {
            const separator = document.getElementById('pinned_columns_separator')
            const movedElement = document.querySelector(`.main-modal__form .${movedColumn}`)

            if (movedColumn === SEPARATOR_COLUMN && movedElement) {
                const separatorIndex = Array.from(movedElement.parentNode.children).indexOf(movedElement);

                const listItems = document.querySelectorAll('#sortable_list li');

                const itemsAboveSeparator = Array.from(listItems).slice(0, separatorIndex);

                const itemsBelowSeparator = Array.from(listItems).slice(separatorIndex + 1);

                if (itemsAboveSeparator && itemsAboveSeparator.length) {
                    const elements = itemsAboveSeparator.map((item) => {
                        const value = item.classList.toString().replace('edit-table-settings-li table-settings-li ', '');
                        if (pinnedColumns.includes(value)) return

                        return value
                    }).filter((item) => item);

                    if (elements.length) {
                        this.setState({
                            pinnedColumns: [...elements, ...pinnedColumns]
                        })
                    }
                }
                if (itemsBelowSeparator && itemsBelowSeparator.length) {
                    const classesToRemove = itemsBelowSeparator.map((item) => {
                        return item.classList.toString().replace('edit-table-settings-li table-settings-li ', '');
                    });

                    const updatedPinnedColumns = pinnedColumns.filter((column) => classesToRemove.includes(column));

                    if (updatedPinnedColumns.length) {
                        this.setState({
                            pinnedColumns: pinnedColumns.filter((column) => !classesToRemove.includes(column)) || []
                        });
                    }
                }
            } else if (separator && movedElement) {
                const separatorRect = separator.getBoundingClientRect();
                const movedElementRect = movedElement.getBoundingClientRect();

                if (movedElementRect.bottom < separatorRect.top) {
                    if (pinnedColumns.includes(movedColumn)) return
                    pinnedColumns.push(movedColumn)
                } else {
                    if (!pinnedColumns.includes(movedColumn)) return
                    _.remove(pinnedColumns, item => item === movedColumn)
                }

                this.setState({
                    pinnedColumns: pinnedColumns && pinnedColumns.length ? pinnedColumns : [],
                })
            }
        }
    }

    savePerformance = (noReload) => {
        const {onAppend} = this.props;
        const {defaultColumns, activeColumns, pinnedColumns, idTemplate} = this.state;

        const defColumns = defaultColumns.filter((col) => !col.includes(SEPARATOR_COLUMN))

        onAppend(_.filter(defColumns, (val) => {
            return activeColumns.includes(val)
        }), defColumns, pinnedColumns, idTemplate, noReload);
    };

    cancelChanges = () => {
        this.setState({
            idTemplate: _.cloneDeep(this.constIdTemplate),
            activeColumns: _.cloneDeep(this.constActiveColumns),
            defaultColumns: _.cloneDeep(this.constDefaultColumns),
            pinnedColumns: _.cloneDeep(this.constPinnedColumns),
            selectedAll: this.constActiveColumns.length === this.constDefaultColumns.length
        });
    };

    cancelSelectAll = () => {
        this.setState({
            idTemplate: '',
            defaultColumns: _.cloneDeep(this.constDefaultColumns),
            activeColumns: this.state.selectedAll ? _.cloneDeep(requiredBudgetTableColumns) : _.cloneDeep(this.constDefaultColumns),
            pinnedColumns: _.cloneDeep(this.constPinnedColumns),
            selectedAll: !this.state.selectedAll,
        });
    };

    resetAll = () => {
        this.setState({
            idTemplate: '',
            defaultColumns: [...[SEPARATOR_COLUMN], ...this.constDefaultOrderColumns],
            activeColumns: _.cloneDeep(this.constDefaultOrderColumns),
            pinnedColumns: [],
            selectedAll: true
        });
    };

    onChange = (val) => {
        if (this.state.idTemplate) {
            this.setState({
                idTemplate: '',
            })
        }

        const defaultColumns = this.state.defaultColumns;
        let activeColumns = this.state.activeColumns;

        if (!(requiredBudgetTableColumns.includes(val) && activeColumns.includes(val))) {

            activeColumns.includes(val) ? _.remove(activeColumns, item => item === val) : activeColumns.push(val)
            this.setState({
                activeColumns: _.uniq(activeColumns.concat(requiredBudgetTableColumns).sort((element1, element2) => {
                    const index1 = defaultColumns.indexOf(element1);
                    const index2 = defaultColumns.indexOf(element2);
                    return index1 - index2;
                }))
            });

        }
    };

    onChangePinnedColumns = (val) => {
        if (this.state.idTemplate) {
            this.setState({
                idTemplate: '',
            })
        }

        const pinnedColumns = this.state.pinnedColumns
        const defaultColumns = this.state.defaultColumns

        pinnedColumns.includes(val) ? _.remove(pinnedColumns, item => item === val) : pinnedColumns.push(val)

        if (pinnedColumns.includes(val)) {
            _.remove(defaultColumns, item => item === val);
            defaultColumns.splice(pinnedColumns.length - 1, 0, val);
        } else {
            const defaultIndex = defaultColumns.findIndex(item => item === SEPARATOR_COLUMN)
            _.remove(defaultColumns, item => item === val);
            defaultColumns.splice(defaultIndex !== -1 ? defaultIndex : 0, 0, val);
        }

        this.setState({
            pinnedColumns,
            defaultColumns,
        });

    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (this.state.idTemplate) {
            this.setState({
                idTemplate: '',
            })
        }

        this.setState(({defaultColumns}) => ({
            defaultColumns: arrayMove(defaultColumns, oldIndex, newIndex),
        }));

        this.setState({
            movedColumn: this.state.defaultColumns[newIndex]
        })
    };

    hasChanged = () => {
        const filteredDefaultColumns = this.state.defaultColumns.filter((item) => item !== SEPARATOR_COLUMN)
        const defaultColumns = JSON.stringify(filteredDefaultColumns);
        const activeColumns = JSON.stringify(this.state.activeColumns)
        const pinnedColumns = JSON.stringify(this.state.pinnedColumns)
        const pinnedColumnsDefault = JSON.stringify(this.constPinnedColumns)

        return pinnedColumns === pinnedColumnsDefault && defaultColumns === activeColumns
    }

    requestGetTemplates = () => {
        const sendObj = {
            url: url.getUrl('/connection/table-settings/budgets/template'),
            type: "GET",
        };

        return request.sendRequestWithNoCache(sendObj);
    };

    saveOrder = (orderName, defaultColumns, activeColumns, pinnedColumns) => {
        const columns = _.filter(defaultColumns, (val) => {return activeColumns.includes(val)})

        requestBudgetCreateOrder({'name': orderName, 'columns': columns,
            'sortingDefaultColumns': defaultColumns, 'pinned_columns': pinnedColumns}).then(
            (r) => {
                this.setState(() => ({
                    showSelect: false
                }));
                alert('Success! Order Template has been created.');
                this.setState(() => ({
                    idTemplate: r[0].id,
                    showSelect: true,
                    orderName: '',
                }))
            },
            () => {
                this.setState(() => ({
                    editTemplate: true
                }))
            },
        )
        this.setState(() => ({
            showChangeOrderContent: false
        }))
    }

    confirmSaveOrder = () => {
        const {orderName, defaultColumns, activeColumns, pinnedColumns} = this.state;
        if (!orderName) {
            this.setState(() => ({
                orderNameError: 'Template Name is required!'
            }));

            return
        }

        this.setState(() => ({
            orderNameError: null
        }));

        this.requestGetTemplates().then((r) => {
            if (!r.length) {
                this.saveOrder(orderName.trim(), defaultColumns, activeColumns, pinnedColumns);

                return
            }

            this.saveOrder(orderName.trim(), defaultColumns, activeColumns, pinnedColumns);
        })
    }

    deleteTemplate = () => {
        deleteItem(`/connection/table-settings/budgets/template/${this.state.idTemplate}/delete`).then(
            () => {
                this.setState(() => ({
                    showSelect: false
                }));
                alert('Success! Order has been deleted.')
                this.setState(() => ({
                    idTemplate: null,
                    showSelect: true,
                    isRemovedTemplate: true
                }));
                this.resetAll();
                this.savePerformance(true)
            },
            errors => {
                alert(errors.detail);
            },
        );
    }

    handleClickOnEditButton = () => {
        this.requestGetTemplates().then((r) => {
            const {defaultColumns, activeColumns, pinnedColumns} = this.state;
            const template = r[0].items.find(templateItem => templateItem.name.trim() === this.state.orderName.trim())
            if (template) {
                editBudgetTemplateRequest(template.id, {'columns': activeColumns,
                    'sortingDefaultColumns': defaultColumns, 'pinned_columns': pinnedColumns}).then(
                    (res) => {
                        this.setState(() => ({
                            showSelect: false
                        }));
                        alert('Success! Order Template has been changed.');
                        this.setState(() => ({
                            idTemplate: res[0].id,
                            showSelect: true,
                            orderName: '',
                            editTemplate: false
                        }))
                    },
                    errors => {
                        alert(errors.detail);
                    }
                )
            } else {
                alert('Template does not found')
            }
        })
    }

    render() {
        const { t, onClose, currentFY } = this.props;
        const { activeColumns, pinnedColumns, selectedAll, defaultColumns, showChangeOrderContent, orderName, idTemplate, showSelect, orderNameError, isRemovedTemplate, editTemplate } = this.state;

        return editTemplate ? <Form>
                <ButtonClose className="main-modal__form-close" onClick={() => this.setState({ orderName: '', editTemplate: false })}/>
                <Title>Edit Template</Title>
                <div>Do you want replace current template or save as new?</div>
                <BlockSubmit>
                    <ButtonRight>
                        <Button onClick={this.handleClickOnEditButton}>
                            Update Current
                        </Button>
                    </ButtonRight>
                    <ButtonLeft>
                        <Button onClick={() => this.setState({
                            editTemplate: false,
                            showChangeOrderContent: true,
                            orderName: ''
                        })}>
                            Save as New
                        </Button>
                    </ButtonLeft>
                </BlockSubmit>
            </Form> :
            <Form className="modal-edit__form">
                <ButtonClose className="main-modal__form-close" onClick={(e) => isRemovedTemplate ? this.savePerformance(false) : onClose(e)} />
                {showChangeOrderContent ? <>
                    <h3 className="main-modal__form-title-edit-column">{t("Create order")}</h3>
                    <Row>
                        <Col>
                            <ColText className="bold-text" isRequired>
                                {t("Template Name")}
                            </ColText>
                            <ColInput
                                placeholder={t("Enter Name")}
                                name="name"
                                value={orderName}
                                onChange={(value) => this.setState(() => ({orderName: value}))}
                            />
                            {orderNameError && <p className="error">{orderNameError}</p>}
                        </Col>
                    </Row>
                    <Button type="button" className="connections__btn main-btn main-btn__general main-btn_primary" disabled={!orderName} onClick={() => this.confirmSaveOrder()}>{t('Save order')}</Button>
                </> : <>
                    <div className="title-wrap">
                        <h3 className="main-modal__form-title-edit-column">{t("Edit Columns")}</h3>
                        <div className="columns-order">
                            <Button type="button" disabled={this.hasChanged()} className="connections__btn main-btn main-btn__general main-btn_primary" onClick={() => {
                                this.setState(() => ({
                                    showChangeOrderContent: true
                                }))
                            }}>{t('Save order')}</Button>
                            {showSelect && <Input
                                name="savedTemplates"
                                type="asyncResponseSelect"
                                url="/connection/table-settings/budgets/template"
                                placeholder={t("Search")}
                                value={idTemplate || ''}
                                onChange={value => {
                                    if (!value) {
                                        this.setState(() => (
                                            {
                                                idTemplate: ''
                                            }
                                        ));
                                        this.resetAll();

                                        return;
                                    }

                                    this.setState(() => (
                                        {
                                            idTemplate: value.id,
                                            defaultColumns: JSON.parse(value.sort_columns),
                                            activeColumns: JSON.parse(value.columns),
                                            pinnedColumns: JSON.parse(value.pinned_columns)
                                        }
                                    ))
                                }}
                            />}
                            {idTemplate ? <button type="button" className="remove-color" onClick={this.deleteTemplate}>
                                <Img img="icon_delete"/>
                            </button> : <div className="delete-plug"/>}
                        </div>
                    </div>

                    <div className="modal-edit__form_item_box">
                        <SortableList items={defaultColumns}
                                      pinnedColumns={pinnedColumns}
                                      activeColumns={activeColumns}
                                      currentFY={currentFY}
                                      onSortEnd={this.onSortEnd}
                                      onChange={this.onChange}
                                      onChangePinnedColumns={this.onChangePinnedColumns}
                        />
                    </div>

                    <div className="modal-edit__btns">
                        <div className="main-btn_toolbar">
                            <TabBlockBtn onClick={this.cancelChanges} className={`main-btn_white`}>
                                {t("Cancel")}
                            </TabBlockBtn>
                            <TabBlockBtn onClick={this.cancelSelectAll} className={`main-btn_white`}>
                                {t(selectedAll ? "Deselect All" : "Select All")}
                            </TabBlockBtn>
                            <TabBlockBtn onClick={this.resetAll} className={`main-btn_white`}>
                                {t("Reset")}
                            </TabBlockBtn>
                        </div>
                        <Button
                            onClick={() => this.savePerformance(false)}
                            disabled={(!activeColumns.length ||
                                ((activeColumns.length && _.isEqual(activeColumns, this.constActiveColumns)) &&
                                    (defaultColumns.length && _.isEqual(defaultColumns, this.constDefaultColumns)))
                            )}
                        >
                            {t("Apply")}
                        </Button>
                    </div>
                </>}
            </Form>
    }
}

BudgetTableSettingsView.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(BudgetTableSettingsView);
