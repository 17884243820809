import Constants from "../Constants";
import {findTypeKeyByValue} from "../Constants/graph-v2-types";

const createQueryParams = (data) => {
    if (typeof window === 'undefined') return

    const { sort, filter } = data

    const queryParams = {
        sortColumn: sort.column,
        sortDirection: sort.direction,
        type: filter.type.value ? findTypeKeyByValue(filter.type.value) : filter.type,
        periodFrom: filter.period_from,
        periodTo: filter.period_to,
        statusProject: filter.status || [],
        timeScale: filter.time_scale,
        marginListSelect: filter.value,
        items: filter.items || []
    }

    if (Object.values(queryParams).some((item) => item === undefined)) return

    const urlObject = new URL(window.location.href);

    Object.keys(queryParams).forEach((key) => {
        urlObject.searchParams.set(key, queryParams[key]);
    });

    history.pushState({}, '', urlObject)
}

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const onSetProductsCodes = (service, dispatch) => (data) => {
    dispatch({ type: Constants.SET_PRODUCTS_CODES_LIST, data });
};

export const onChangeSecondSelectAction = (service, dispatch) => (value, form) => {
    dispatch({ type: Constants.CHANGE_PRODUCTS_CODES, data: { value } });

    const data = { sort: {...form.sort}, filter: { ...form.filter, items: value } }

    createQueryParams(data)

    dispatch({ type: Constants.FETCH_ITEMS_REQUESTED });

    const { getData } = service;
    const { filter: { time_scale }} = data;
    const filterType = data.filter.type
    const timeScale = {
        yearly: 1,
        quarterly: 2,
        monthly: 3,
    };

    return getData(data).then(
        res => {
            if (filterType !== 'TYPE_10') {
                dispatch({ type: Constants.FETCH_ITEMS_SUCCEEDED, data: res })
                return
            }

            const data = {};

            switch (+time_scale) {
                    case timeScale.quarterly:
                        for (const key in res.data) {
                            const t = (key).split("/")[0];
                            const y = (key).split("/")[1];
                            data[`Q${t} (FY ${y})`] = res.data[key];
                        }
                        res.data = data;
                        break;

                    case timeScale.yearly:
                        for (const key in res.data) {
                            data[`FY ${key}`] = res.data[key];
                        }
                        res.data = data;
                        break;

                    case timeScale.monthly:
                        res.data = keySort(res.data);
                        break;
                }

            dispatch({ type: Constants.FETCH_ITEMS_SUCCEEDED, data: res })
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.FETCH_ITEMS_FAILED, data: res });
        },
    );
};

export const removeItemAction = (service, dispatch) => frontId => {
    dispatch({ type: Constants.REMOVE_ITEM, data: { frontId } });
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.RESET_FORM });
};

export const setSortDirection = (service, dispatch) => (data) => {
    dispatch({ type: Constants.SET_SORT_DIRECTION, data });
};

export const getDataAction = (service, dispatch) => data => {
    dispatch({ type: Constants.FETCH_ITEMS_REQUESTED });

    const { getData } = service;
    const { filter: { time_scale }} = data;
    const filterType = data.filter.type
    const timeScale = {
        yearly: 1,
        quarterly: 2,
        monthly: 3,
    };

    createQueryParams(data)

    return getData(data).then(
        res => {
            if (filterType !== 'TYPE_10') {
                dispatch({ type: Constants.FETCH_ITEMS_SUCCEEDED, data: res })
                return
            }

            const data = {};

            if (filterType !== 'TYPE_10') {
                switch (+time_scale) {
                    case timeScale.quarterly:
                        for (const key in res.data) {
                            const t = (key).split("/")[0];
                            const y = (key).split("/")[1];
                            data[`Q${t} (FY ${y})`] = res.data[key];
                        }
                        res.data = data;
                        break;

                    case timeScale.yearly:
                        for (const key in res.data) {
                            data[`FY ${key}`] = res.data[key];
                        }
                        res.data = data;
                        break;

                    case timeScale.monthly:
                        res.data = keySort(res.data);
                        break;
                }
            }

            dispatch({ type: Constants.FETCH_ITEMS_SUCCEEDED, data: res })
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.FETCH_ITEMS_FAILED, data: res });
        },
    );
};

const keySort = (unordered) => {
    const ordered = {};

    Object.keys(unordered).sort((a, b) => {
        const c = a.split('/');
        const d = b.split('/');

        if((c[1] - d[1]) < 0)
            return -1;

        if((c[1] - d[1]) > 0)
            return 1;

        return (c[0] - d[0]);
    }).forEach((key) => {
        ordered[key] = unordered[key];
    });

    return ordered;
};
