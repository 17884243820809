import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import { InputFilter } from "Templates/Table/Filters";
import { Button } from "Templates/Default";
import Img from "Templates/Img/Img";

const defaultProps = {
    searchValue: "",
};

const propTypes = {
    searchValue: PropTypes.string,
};

class Search extends Component {
    state = {
        searchValue: this.props.searchValue,
    };

    onSubmit = e => {
        e.preventDefault();
        this.redirect();
    };

    redirect = () => {
        const { searchValue } = this.state;
        const trimSearchValue = searchValue.trim();

        if (trimSearchValue) {
            url.redirect(`/search?value=${trimSearchValue}`);
        }
    };

    handleChangeSearchValue = searchValue => {
        this.setState({
            searchValue,
        });
    };

    render() {
        const { searchValue } = this.state;
        const { t, src } = this.props;
        return (
            <form onSubmit={this.onSubmit} className="main-header__search-wrap">
                {/*<InputFilter*/}
                {/*    type="text"*/}
                {/*    placeholder={t("Search")}*/}
                {/*    value={searchValue}*/}
                {/*    onChange={value => this.handleChangeSearchValue(value)}*/}
                {/*/>*/}
                {/*<Button className="main-header__search" onClick={this.redirect}>*/}
                {/*    <Img img="search" width="16" height="16" alt="" className="main-header__search-img" />*/}
                {/*</Button>*/}
            </form>
        );
    }
}

Search.defaultProps = defaultProps;
Search.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        searchValue: getStoreItem(state, "searchValue"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Search);
