import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { BlockWrap, Block, Input, WrapInput } from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
};

const AddressBlock = ({ data, onChange, t }) => {
    return (
        <>
            <Block>
                <BlockWrap>
                    <Block>
                        <BlockWrap>
                            <WrapInput label={t("Address")} name="address1">
                                <Input
                                    placeholder={t("Address")}
                                    value={data.address1}
                                    onChange={value => onChange(value, `address1`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap />
                        <BlockWrap />
                    </Block>
                </BlockWrap>
            </Block>

            <Block>
                <BlockWrap>
                    <Block>
                        <BlockWrap>
                            <WrapInput label={t("City")} name="city">
                                <Input
                                    placeholder={t("City")}
                                    value={data.city}
                                    onChange={value => onChange(value, `city`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput label={t("Region/State")} name="state">
                                <Input
                                    placeholder={t("Region/State")}
                                    value={data.state}
                                    onChange={value => onChange(value, `state`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput label={t("District/County")} name="district">
                                <Input
                                    placeholder={t("District/County")}
                                    value={data.district}
                                    onChange={value => onChange(value, `district`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>
                </BlockWrap>
            </Block>

            <Block>
                <BlockWrap>
                    <Block>
                        <BlockWrap>
                            <WrapInput label={t("Country")} name="country">
                                <Input
                                    placeholder={t("Country")}
                                    value={data.country}
                                    onChange={value => onChange(value, `country`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput label={t("Zip/Postal")} name="zip">
                                <Input
                                    placeholder={t("Zip/Postal")}
                                    value={data.zip}
                                    onChange={value => onChange(value, `zip`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap>
                            <WrapInput label={t("Time Zone")} name="timeZone">
                                <Input
                                    placeholder={t("Time Zone")}
                                    value={data.timeZone}
                                    onChange={value => onChange(value, `timeZone`)}
                                />
                            </WrapInput>
                        </BlockWrap>
                    </Block>
                </BlockWrap>
            </Block>
        </>
    );
};

AddressBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { service } = ownProps;
    const { getStoreItem } = service;

    return {
        data: {
            country: getStoreItem(state, `country`),
            city: getStoreItem(state, `city`),
            address1: getStoreItem(state, `address1`),
            zip: getStoreItem(state, `zip`),
            state: getStoreItem(state, `state`),
            district: getStoreItem(state, `district`),
            timeZone: getStoreItem(state, `timeZone`),
        },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(AddressBlock);
