import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {};

const propTypes = {
    revenueByStatus: PropTypes.arrayOf(
        PropTypes.shape({ status: PropTypes.string.isRequired, revenue: PropTypes.string.isRequired }),
    ).isRequired,
};

const DataBusinessDashboardCard = ({ pref, revenueByStatus, t }) => {
    let level1 = {};
    let level2 = {};
    let level3 = {};
    let level4 = {};

    revenueByStatus.forEach(elem => {
        switch (elem.status) {
            case "Level 1":
                level1 = elem;
                break;
            case "Level 2":
                level2 = elem;
                break;
            case "Level 3":
                level3 = elem;
                break;
            case "Level 4":
                level4 = elem;
                break;

            default:
                break;
        }
    });
    return (
        <div className={`${pref}__card-data`}>
            <ul className="status status--dashboard">
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern gray-bg">
                        <span>{t("Level 1")}</span>
                        <svg
                            className="status-icon status-icon--right"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 40V0H0.503944L20.6187 17.4096C22.4907 19.0298 22.4546 21.9281 20.5429 23.5021L0.503944 40H0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value gray-text">
                        {`$${numberWithCommas(Number(level1.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern yellow-bg">
                        <span>{t("Level 2")}</span>
                        <svg
                            className="status-icon status-icon--left"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H22V0Z"
                            />
                        </svg>
                        <svg
                            className="status-icon status-icon--right"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 40V0H0.503944L20.6187 17.4096C22.4907 19.0298 22.4546 21.9281 20.5429 23.5021L0.503944 40H0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value clr-yellow">
                        {`$${numberWithCommas(Number(level2.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern selected">
                        <span>{t("Level 3")}</span>
                        <svg
                            className="status-icon status-icon--left"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H22V0Z"
                            />
                        </svg>
                        <svg
                            className="status-icon status-icon--right"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 40V0H0.503944L20.6187 17.4096C22.4907 19.0298 22.4546 21.9281 20.5429 23.5021L0.503944 40H0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value clr-bright-blue">
                        {`$${numberWithCommas(Number(level3.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>
                <li className="status__item status__item--modern">
                    <div className="status__button status__button--modern filled">
                        <span>{t("Level 4")}</span>
                        <svg
                            className="status-icon status-icon--left"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22 0H0L20.3493 17.3817C22.249 19.0044 22.2127 21.9521 20.2737 23.5276L0 40H22V0Z"
                            />
                        </svg>
                        <svg
                            className="status-icon status-icon--right"
                            width="22"
                            height="40"
                            viewBox="0 0 22 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 40V0H0.503944L20.6187 17.4096C22.4907 19.0298 22.4546 21.9281 20.5429 23.5021L0.503944 40H0Z"
                            />
                        </svg>
                    </div>
                    <span className="status__value clr-green">
                        {`$${numberWithCommas(Number(level4.revenue).toFixed(0)) || "0"}`}
                    </span>
                </li>
            </ul>
        </div>
    );
};

DataBusinessDashboardCard.defaultProps = defaultProps;
DataBusinessDashboardCard.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        revenueByStatus: getStoreItem(state, "level_2.dashboard.revenueByStatus"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DataBusinessDashboardCard);
