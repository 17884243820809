import { handleScroll } from "../../EventsService";
import { deleteItem } from "../../RequestService";
import { requestOnUploadFiles, getItem, requestOnSaveCopy, getUploadUrl } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";

export default id => ({
    requestItems: () => getItem(`/product/product-finder/${id}/safeties`),
    requestTypeItem: (item) => getItem(`/product/product-finder/${id}/safeties/${item.id}`),
    requestOnUploadFiles: requestOnUploadFiles(`/product/product-finder/${id}/safeties/add`),
    requestOnSaveCopy: (safetyId) => requestOnSaveCopy(`/product/safeties/${safetyId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/safeties/${item.id}/delete`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (safetyId, type) => getUploadUrl(id, safetyId, type),
});
