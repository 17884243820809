import { debounce } from "Services/Debounce";
import Constants from "../Constants";
import {requestExportCompanies} from "../Services";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });
export const addChildren = (services, dispatch) => data => {
    dispatch({ type: Constants.ADD_CHILDREN, data });
};

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const fetchHierarchyItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestHierarchyTable } = service;

    requestHierarchyTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (["supply_chain", "nda", "nicknames", "sap_value", "changed_date", "updated_date", "created_code_date",
        "created_system_date", "marked_deletion", "project_count", "invoice_count", "customer_owner"].includes(key)) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const onChangeTab = (services, dispatch) => (value) => {
    dispatch({ type: Constants.CHANGE_TAB, data: value });
}

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const getUrlExport = (service, dispatch) => (data) => {
    dispatch({ type: Constants.EXPORT_COMPANIES, data });

    return requestExportCompanies(data).then(
        res => {
            alert('Export Companies has started. Check notifications to download.');
            dispatch({ type: Constants.EXPORT_COMPANIES_SUCCESS, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.EXPORT_COMPANIES_FAILED, data: res });
        },
    );
};

export const resetFilterWithoutFetch = (service, dispatch) => () => {
    dispatch({ type: Constants.RESET_FILTER, data: {} });
};