import Constants from "../Constants";
import {uniqueGenerator} from "Services";
import {concat, uniqBy} from "lodash";

export const initState = {
    showModal: false,
    columns: ['name', 'slug'],
    items: [],
    sort: {},
    filter: {},
    form: [{id: uniqueGenerator(), value: ''}],
    pagination: {},
    groupId: ''
};

export const reducer = (state, action) => {
    const {data} = action;
    const {publications} = state;
    switch (action.type) {
        case Constants.OPEN_PUBLICATIONS_MODAL:
            return {
                ...state, publications: {
                    ...publications,
                    showModal: true,
                }
            };

        case Constants.CLOSE_PUBLICATIONS_MODAL:
            return {
                ...state,
                publications: {
                    ...publications,
                    form: [{id: uniqueGenerator(), value: ''}],
                    showModal: false,
                },
            };

        case Constants.UPDATE_PUBLICATIONS_FORM: {
            return {
                ...state, publications: {
                    ...publications,
                    form: data,
                }
            };
        }

        case Constants.GET_PUBLICATIONS_SUCCEEDED: {
            const {pagination, items} = data;
            return {
                ...state, publications: {
                    ...publications,
                    items,
                    pagination,
                }
            };
        }

        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            const {sort} = data;
            return {
                ...state,
                publications: {
                    ...publications,
                    items: data[0].items,
                    sort: { column: sort.column, direction: sort.direction },
                    pagination: data[0].pagination,
                },
                loading: false,
            };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.GET_NEXT_PAGE_PUBLICATIONS_SUCCEEDED: {
            const {pagination, items} = data;
            return {
              ...state, publications: {
                  ...publications,
                    items: uniqBy(concat(publications.items, items), 'id'),
                    pagination,
                }
            };
        }

        default:
            return state;
    }
};