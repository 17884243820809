import React from "react";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoContactWrapper,
    ContentInfoHeader,
    ContentInfoHeaderWrap,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap
} from "Templates/Content";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import PropTypes from "prop-types";
import Img, { ImgStorage } from "Templates/Img";
import TableRowItemLink from "Templates/Table/TableRowItemLink";

const propTypes = {
    name: PropTypes.string.isRequired,
    owner: PropTypes.objectOf(PropTypes.any).isRequired,
};

const InfoWrap = ({ t, name, owner }) => {
    return (
        <>
            <ContentInfoWrap>
                <ContentInfoHeaderWrap>
                    <ContentInfoTop urlBack="/product/product-finder/publication-groups"/>
                    <ContentInfoHeader>
                        <ContentInfoTitle>{name}</ContentInfoTitle>
                    </ContentInfoHeader>
                </ContentInfoHeaderWrap>

                <div className="product__filters">
                    {owner ? (
                        <>
                            <ContentInfoBlockTitle>{t("Owner")}</ContentInfoBlockTitle>
                            <ContentInfoBlock>
                                <ContentInfoContactWrapper>
                                    <a
                                        href={`/admin/users/${owner.id}`}
                                        rel="nofollow noopener noreferrer"
                                        target="_blank"
                                    >
                                        {
                                            owner.photo ? (
                                                    <ImgStorage
                                                        href={`/admin/users/${owner.id}`}
                                                        url={owner.photo}
                                                        className="grade-view__img"
                                                    />
                                                )
                                                : <Img img="icon_contact_small" className="grade-view__img"/>
                                        }
                                    </a>
                                    <ContentInfoBlock>
                                        <ContentInfoBlockText value={owner.name || ''}/>
                                        <ContentInfoBlockTitle>{owner.title || ''}</ContentInfoBlockTitle>
                                        <TableRowItemLink
                                            title={owner.email}
                                            href={`mailto:${owner.email}`}
                                        >
                                            {owner.email}
                                        </TableRowItemLink>
                                        <ContentInfoBlockText value={owner.phone || ''}/>
                                    </ContentInfoBlock>
                                </ContentInfoContactWrapper>
                            </ContentInfoBlock>
                        </>
                    ) : null}
                </div>
            </ContentInfoWrap>
        </>
    )
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        name: getStoreItem(state, 'name'),
        owner: getStoreItem(state, 'owner'),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps)
)(InfoWrap);
