import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import ItemMenuContent from "./ItemMenuContent";

const propTypes = {
    label: PropTypes.string.isRequired,
    map: PropTypes.shape({
        link: PropTypes.string.isRequired,
        sublabel: PropTypes.string,
        img: PropTypes.string,
        options: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    }).isRequired,
    service: PropTypes.shape({
        getItemMenuClassName: PropTypes.func.isRequired,
    }).isRequired,
};

const ItemMenu = ({label, map, service: {getItemMenuClassName}}) => {
    return (
        <li
            className={`main-sidebar__list-item ${getItemMenuClassName(map)}`}
            onClick={() => {
                if (!map.link.includes('compare') && sessionStorage.getItem('selectedFilter')) {
                    sessionStorage.removeItem('selectedFilter')
                }
            }}
        >
            <ItemMenuContent label={label} link={map.link} img={map.img || ""} sublabel={map.sublabel || ""}/>
        </li>
    );
};

ItemMenu.propTypes = propTypes;

export default compose(withServiceConsumer)(ItemMenu);
