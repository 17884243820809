import React from "react";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import { getString } from "Services";
import {projectConsistent, projectStatus} from "Services/enum";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";

export const modifierValues = items => items;
export const tableButtonsPermission = "project_crud";

export const getViewItem = (key, item) => {
    switch (key) {
        case "slug":
            return (
                <TableRowItemLink blank
                    title={getString(item, key)}
                    href={`/project/projects/${getString(item, "id")}`}
                >
                    {getString(item, "slug")}
                </TableRowItemLink>
            );

        case "name":
            return (
                <TableRowItemLink blank
                    title={getString(item, key)}
                    href={`/project/projects/${getString(item, "id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "product_application_codes":
        case "internal_team": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={index} title={elem.name}>
                                    {elem.name}
                                </TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        case "sub_status":
            return <TableRowItemStatus status={getString(item, key)} list={[...projectStatus]}/>;

        case "status":
            return <TableRowItemStatus status={getString(item, key)} list={projectConsistent}/>;


        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
