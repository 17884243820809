import React from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Form } from "Templates/Form";
import GeneralBlock from "./Blocks/GeneralBlock";

const FormBlock = () => {
    return (
        <Form>
            <GeneralBlock />
        </Form>
    );
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(FormBlock);
