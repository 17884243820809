import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { projectStatusList } from "Services/enum";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Li, Span } from "Templates/Default";
import { Tooltip } from "Templates/Form";
import { renderStatusIcons } from "Templates/Content/ProjectStatusBlock/StatusIcons/StatusIcons";

const propTypes = {
    projectStatus: PropTypes.string,
    service: PropTypes.shape({
        getViewInProjectStatusBlockBelowStatus: PropTypes.func,
        getViewInProjectStatusBlockAfterText: PropTypes.func,
    }).isRequired,
    item: PropTypes.shape({
        order: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
    }).isRequired,
};

const defaultProps = {
    projectStatus: undefined,
};

const ProjectStatusBlockListElement = ({
    projectStatus,
    item,
    service: { getViewInProjectStatusBlockBelowStatus, getViewInProjectStatusBlockAfterText },
    t,
    index,
    length,
}) => {
    if (typeof getViewInProjectStatusBlockBelowStatus === "undefined") {
        getViewInProjectStatusBlockBelowStatus = () => null;
    }
    if (typeof getViewInProjectStatusBlockAfterText === "undefined") {
        getViewInProjectStatusBlockAfterText = () => null;
    }

    const subStatus = [...projectStatusList].find(elem => elem.label.toLowerCase() === projectStatus.toLowerCase());

    const getClassNameForProjectStatusBlock = (status, elem) => {
        if (typeof status === "undefined") {
            return "selected";
        }

        if (status.order > elem.order && status.value < 0) {
            return "filled";
        }

        if (status.order === elem.order) {
            return "selected";
        }

        if (status.order < elem.order && elem.order !== 11 && status.value > 0) {
            return "filled";
        }
        return "";
    };

    return (
        <Li className="status__item status__item--modern">
            <div
                className={`status__button status__button--modern ${getClassNameForProjectStatusBlock(
                    subStatus,
                    item,
                )}`}
            >
                <Span>{item.label}</Span>
                {item.tooltip ? <Tooltip>{t(item.tooltip)}</Tooltip> : null}
                {renderStatusIcons(index, length)}
                {getViewInProjectStatusBlockAfterText(item)}
            </div>
            {getViewInProjectStatusBlockBelowStatus(item)}
        </Li>
    );
};

ProjectStatusBlockListElement.propTypes = propTypes;
ProjectStatusBlockListElement.defaultProps = defaultProps;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        projectStatus: getStoreItem(state, "view.subStatus"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ProjectStatusBlockListElement);
