import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Title } from "Templates/Titles";
import LinkIcon from "./Views/Templates/LinkIcon";
import InfoWrap from "./Views/InfoWrap";

const propTypes = {
    name: PropTypes.string.isRequired,
};

const Index = ({ name, t }) => {
    return (
        <>
            <Title title={t(name)} before={() => <LinkIcon href="/legal?activeTab=1" />} />
            <InfoWrap />
        </>
    );
};

Index.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        name: state.listState.view.name,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Index);
