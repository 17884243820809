import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {BlockTitle, Col} from "Templates/Form";
import {ServiceProvider} from "Services/Context";
import Img from "Templates/Img";

const ExpandedBlock = ({title, block, service, isExpanded, setExpandedAction, children}) => {
    const [expanded, setExpanded] = useState(isExpanded)

    useEffect(() => setExpanded(isExpanded), [isExpanded])

    return (
        <Col>
            <div className={`project-expanded-block__title-wrap ${expanded ? 'expanded' : ''}`} onClick={() => {
                setExpanded(!expanded)
                setExpandedAction({
                    block,
                    expand: !expanded
                })
            }}>
                <BlockTitle>{title}</BlockTitle>
                <Img img="expand-icon" width="24" height="24"/>
            </div>

            <div className={`project-expanded-block__toggle ${expanded ? 'expanded' : ''}`}>
                <ServiceProvider value={service}>
                    {children}
                </ServiceProvider>
            </div>
        </Col>
    );
};

ExpandedBlock.propTypes = {};

export default compose(withTagDefaultProps())(ExpandedBlock);