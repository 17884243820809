import {request, url} from "Services";

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {

    return new Promise(resolve => {

        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                pinned_columns: res[0].pinned_columns,
                total_analytics: res[0].total_analytics,
            });
        }).then(() => {
            if(url.getActiveTab()){
                data.activeTab = url.getActiveTab();
            }
            url.addHistory(data);
        });
    });
};

export const requestChangeSettings = data => {
    const sendObj = {
        url: url.getUrl('/finance/table-settings/edit'),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const requestGetSettings = () => {
    const sendObj = {
        url: url.getUrl('/finance/table-settings'),
        type: "GET"
    };

    return request.sendRequest(sendObj);
};

export const requestGetCustomerSettings = () => {
    const sendObj = {
        url: url.getUrl('/finance/table-settings/customers'),
        type: "GET"
    };

    return request.sendRequest(sendObj);
};

export const requestChangeCustomerSettings = data => {
    const sendObj = {
        url: url.getUrl('/finance/table-settings/customers/edit'),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const editCustomerTemplateRequest = (templateId, data) => {
    const sendObj = {
        url: url.getUrl(`/finance/table-settings/customers/template/${templateId}/edit`),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
}

export const requestCreateOrder = data => {
    const sendObj = {
        url: url.getUrl('/finance/table-settings/template/create'),
        data,
        type: "POST",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const editCustomerFinanceRequest = (templateId, data) => {
    const sendObj = {
        url: url.getUrl(`/finance/table-settings/template/${templateId}/edit`),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
}

export const requestCustomerCreateOrder = data => {
    const sendObj = {
        url: url.getUrl('/finance/table-settings/customers/template/create'),
        data,
        type: "POST",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const requestExportFinance = data => {
    const sendObj = {
        url: data,
        type: "POST"
    };

    return request.sendRequest(sendObj);
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export const requestItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "POST",
    };

    return request.sendRequest(sendObj);
};
