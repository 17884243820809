import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    onClick: PropTypes.func.isRequired,
};

const PerItem = ({ pref, children, onClick }) => {
    return (
        <li className={`${pref}__per-item`}>
            <div className={`${pref}__upload-item`}>
                {children}
                <i className="la la-icon-close" onClick={onClick} />
            </div>
        </li>
    );
};

PerItem.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(PerItem);
