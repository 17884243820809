import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    inputProps: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    placeholder: "",
    value: "",
    inputProps: {},
};

const TextArea = ({ className, placeholder, value, onChange, inputProps }) => (
    <textarea placeholder={placeholder} onChange={onChange} value={value} className={className} {...inputProps} />
);

TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;

export default TextArea;
