import _ from "lodash";

export const formData = data => {
    const dataReq = {
        filter: {
            value: _.get(data, "marginListSelect"),
            time_scale: _.get(data, "timeScale"),
            period_from: _.get(data, "periodFrom"),
            period_to: _.get(data, "periodTo"),
        },
    };

    if (_.get(data, "statusProject", "")) {
        dataReq.filter.status = _.get(data, "statusProject");
    }

    return dataReq;
};
