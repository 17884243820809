import React, {Component} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {BlockTitle, Block, BlockShadow, BlockWrap, Input, WrapInput} from "Templates/Form";
import {Modal} from "Templates/Modals";
import {LinkIcon} from "Templates/Link";
import CreateModal from "./Modal/CreateModal";

const propTypes = {
    materialSpecification: PropTypes.string.isRequired,
    performanceSpecification: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    submitMaterial: PropTypes.func.isRequired,
    submitPerfomace: PropTypes.func.isRequired,
};

class Specification extends Component {
    state = {
        showMaterialModal: false,
        showPermanceModal: false,
    };

    handleClickOnShowModal = name => () => {
        this.setState({
            [name]: true,
        });
    };

    handleCloseModal = () => {
        this.setState({
            showMaterialModal: false,
            showPermanceModal: false,
        });
    };

    submitMaterial = data => {
        const {submitMaterial} = this.props;
        return submitMaterial(data);
    };

    submitPermance = data => {
        const {submitPerfomace} = this.props;
        return submitPerfomace(data);
    };

    render() {
        const {showMaterialModal, showPermanceModal} = this.state;
        const {
            materialSpecification,
            performanceSpecification,
            onChange,
            t,
        } = this.props;


        return (
            <>
                <BlockTitle>{t("Specifications")}</BlockTitle>
                <Block>
                    <BlockShadow>
                        <BlockWrap className="width_1-3">
                            <WrapInput label="Performance Specification" name="performanceSpecification">
                                {showPermanceModal ? (
                                    <Modal onRequestClose={this.handleCloseModal}>
                                        <CreateModal
                                            titleText="Create Performance Specification"
                                            buttonText="Create Performance Specification"
                                            onAppend={this.submitPermance}
                                            onClose={this.handleCloseModal}
                                        />
                                    </Modal>
                                ) : <div />}
                                <Input
                                    type="asyncSelect"
                                    url="project/projects/performance-specification-list"
                                    value={performanceSpecification}
                                    placeholder={t("Search")}
                                    onChange={value => onChange("generalBlock.performanceSpecification", value)}
                                />
                                <LinkIcon onClick={this.handleClickOnShowModal("showPermanceModal")}>
                                    {t("Add New")}
                                </LinkIcon>
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap className="width_1-3">
                            <WrapInput label="Material Specification" name="materialSpecification">
                                {showMaterialModal ? (
                                    <Modal onRequestClose={this.handleCloseModal}>
                                        <CreateModal
                                            titleText="Create Material Specification"
                                            buttonText="Create Material Specification"
                                            onAppend={this.submitMaterial}
                                            onClose={this.handleCloseModal}
                                        />
                                    </Modal>
                                ) : <div />}
                                <Input
                                    type="asyncSelect"
                                    url="project/projects/material-specification-list"
                                    value={materialSpecification}
                                    placeholder={t("Search")}
                                    onChange={value => onChange("generalBlock.materialSpecification", value)}
                                />
                                <LinkIcon onClick={this.handleClickOnShowModal("showMaterialModal")}>
                                    {t("Add New")}
                                </LinkIcon>
                            </WrapInput>
                        </BlockWrap>
                        <BlockWrap className="width_1-3"/>
                    </BlockShadow>
                </Block>
            </>
        );
    }
};

Specification.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        materialSpecification: getStoreItem(state, "generalBlock.materialSpecification"),
        performanceSpecification: getStoreItem(state, "generalBlock.performanceSpecification"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        submitPerfomace: getActionStore("submitPerfomaceAction", service, dispatch),
        submitMaterial: getActionStore("submitMaterialAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Specification);
