import React from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {Block, BlockMain, BlockShadow, BlockWrap, Form} from "Templates/Form";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import GeneralForm from "./Forms/GeneralForm";
import TdsHeader from "./Forms/TdsHeader";
import Content from "./Forms/Content";
import CustomTds from "./Forms/CustomTds";
import PublicationLogo from "./Forms/PublicationLogo";
import MaterialProperty from "./Forms/MaterialProperty";
import Legal from "./Forms/Legal";
import NotesSection from "../../../View/Views/Forms/NotesSection";
import KeyWords from "../../../View/Views/Forms/KeyWords";

const FormBlock = () => {
    return (
        <Form>
            <Block className="publication-top-wrap">
                <BlockWrap>
                    <CustomTds/>
                </BlockWrap>
                <BlockWrap>
                    <BlockMain title="Logo" blockClassName="fh">
                        <BlockShadow className="publication-logos-wrap">
                            <PublicationLogo/>
                            <div className="marketing-wrap">
                                <TdsHeader/>
                            </div>
                        </BlockShadow>
                    </BlockMain>
                </BlockWrap>
            </Block>
            <Block className="publication-datasheets-general">
                <BlockWrap>
                    <GeneralForm/>
                </BlockWrap>
            </Block>
            {/*<Colors/>*/}
            <Content index={0} title="Content"/>
            {/*<KeyWords url={'product/datasheets/keywords'}/>*/}
            <MaterialProperty/>
            <Content index={1} title="Additional Content"/>
            <Legal/>
            <NotesSection/>
            <CheckPermission permission="product_publications_approve"/>
        </Form>
    );
};

const mapStateToProps = () => {
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
