import React from 'react';
import {Div} from "Templates/Default";
import BlockTitle from "Templates/Form/BlockTitle";
import {InputFilter} from "Templates/Table/Filters";
import {datasheetPaintable, isMolded} from "Services/enum";
import PropTypes from "prop-types";
import {onChange} from "../../Services/EventsService";
import {Tooltip} from "Templates/Form";

function Color({callback, filter}) {
    const [showContent, setShowContent] = React.useState(false);

    const handleToggleContent = () => {
        setShowContent(!showContent)
    }

    return (
        <>
            <Div className="toggle__show-block">
                <BlockTitle>Color</BlockTitle>
                <button
                    type="button"
                    className={["cost-modal__calculator_btn", showContent ? 'open' : ''].join(' ')}
                    onClick={handleToggleContent}
                >
                    Show
                    {' '}
                    {showContent ? 'Less' : 'More'}
                </button>
            </Div>

            {showContent && (
                <div className="product__filters">
                    <>
                        <p className="main-table__head-link filter-with-tooltip">
                            Paintable
                            <Tooltip>
                                {`Delineates if a material can be painted.`}
                            </Tooltip>
                        </p>
                        <InputFilter
                            type="multiSelect"
                            placeholder="Select"
                            options={datasheetPaintable}
                            value={filter.paintable}
                            onChange={e => {
                                onChange(e).then(_value => callback('paintable', _value));
                            }}
                        />
                        <p className="main-table__head-link filter-with-tooltip">
                            Mold-in-Color
                            <Tooltip>
                                {`Mold-in-Color is a pre-colored plastic resin. You must have a color code for 80% or 100% color match. Please contact your MCGC representative for more information.`}
                            </Tooltip>
                        </p>
                        <InputFilter
                            type="multiSelect"
                            placeholder="Select"
                            options={isMolded}
                            value={filter.molded}
                            onChange={e => {
                                onChange(e).then(_value => callback('molded', _value));
                            }}
                        />
                    </>
                </div>
            )}
        </>
    );
}

Color.propTypes = {
    callback: PropTypes.func.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired
}

export default Color;
