export const ReferralsTableColumn = [
    {
        name: 'Name',
        placeholder: 'Search',
        filter: 'full_name',
    },
    {
        name: 'Date Invited',
    },
    {
        name: 'Internal User',
    },
    {
        name: 'Invited to Shared Products',
    },
    {
        name: 'Invited to Shared Lists',
    },
    {
        name: 'Date Last Accessed',
    },
    {
        name: 'Current Month Logins',
    },
    {
        name: 'Total Logins',
    },
    {
        name: 'History',
    },
];
