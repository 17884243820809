import { request, url } from "Services";
import _ from "lodash";

const save = (data, requestUrl) => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestCreate = requestUrl => data => {
    return new Promise((resolve, reject) => {
        save(data, requestUrl).then(res => resolve({ ...res[0] }), error => reject(error));
    });
};

const get = (requestUrl, data = null) => {
    const sendObj = {
        url: url.getUrl(requestUrl, data),
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestGet = (requestUrl, data) => {
    return new Promise((resolve, reject) => {
        get(requestUrl, data).then(
            res => {
                resolve({ ..._.get(res, `[0].items[0]`) });
            },
            error => reject(error),
        );
    });
};
