import React from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { checkProtocol } from "Services/Url";
import { withTagDefaultProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoLogo,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
    InfoSocialWrap,
    InfoTitleSmall,
    ContentInfoHeaderWrap
} from "Templates/Content";
import SocialLinkIcon from "Templates/Social/LinkIcon";
import { contactPhoneTypes, contactEmailTypes } from "Services/enum";

const defaultProps = {
    physical: PropTypes.shape({
        address1: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        district: "",
        timeZone: "",
    }),
};

const propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    link: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    instagram: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    twitter: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    facebook: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    photo: PropTypes.string.isRequired,
    physical: PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        country: PropTypes.string,
        district: PropTypes.string,
        timeZone: PropTypes.string,
    }),
    emails: PropTypes.arrayOf(PropTypes.any).isRequired,
    phones: PropTypes.arrayOf(PropTypes.any).isRequired,
    company: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    canEdit: PropTypes.number.isRequired,
};

const InfoWrap = (
    {
        photo,
        title,
        company,
        name,
        emails,
        phones,
        physical,
        canEdit,
        t,
        link,
        instagram,
        twitter,
        facebook,
        onClickEdit,
        onClickDelete,
    }) => {
    const addressExists = (address) => {
        return !!(address.address1 + address.city + address.state + address.zip + address.country);
    }
    return (
        <ContentInfoWrap>
            <ContentInfoHeaderWrap>
                <ContentInfoTop
                    onClickEdit={canEdit ? onClickEdit : null}
                    onClickDelete={canEdit ? onClickDelete : null}
                    urlBack="/contacts"
                />
                <ContentInfoLogo className="main-circle-logo" url={photo}/>
                <ContentInfoTitle>{name}</ContentInfoTitle>
                <InfoTitleSmall>{title}</InfoTitleSmall>

                <InfoSocialWrap>
                    {instagram && <SocialLinkIcon type="instagram" url={`https://instagram.com/${instagram}`}/>}
                    {facebook && <SocialLinkIcon type="facebook" url={`https://facebook.com/${facebook}`}/>}
                    {link && <SocialLinkIcon type="linkedin" url={checkProtocol(link)}/>}
                    {twitter && <SocialLinkIcon type="twitter" url={`https://twitter.com/${twitter}`}/>}
                </InfoSocialWrap>
            </ContentInfoHeaderWrap>

            <div className="contacts-content__info-content-block">
                <ContentInfoBlock show={addressExists(physical)}>
                    <ContentInfoBlockTitle>{t("Address")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={physical.address1}/>
                    <ContentInfoBlockText
                        value={`${physical.city ? `${physical.city},` : ""} ${physical.state} ${physical.zip}`}
                    />
                    <ContentInfoBlockText value={physical.country}/>
                </ContentInfoBlock>

                <ContentInfoBlock show={!!physical.timeZone}>
                    <ContentInfoBlockTitle>{t("Time Zone")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={physical.timeZone}/>
                </ContentInfoBlock>

                <ContentInfoBlock show={!!company.name}>
                    <ContentInfoBlockTitle>{t("Company")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText type="link" value={`/companies/${company.id}`} title={company.name}/>
                </ContentInfoBlock>

                <ContentInfoBlock show={!!phones.length}>
                    <ContentInfoBlockTitle>{t("Phone")}</ContentInfoBlockTitle>
                    {phones.map((phone, index) => (
                        <ContentInfoBlockText
                            type="phone"
                            value={`${_.find(contactPhoneTypes, { value: `${phone.type}` }).label}: ${phone.code} ${phone.number}`}
                            key={`phone_${index}`}
                        />
                    ))}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!emails.length}>
                    <ContentInfoBlockTitle>{t("Email")}</ContentInfoBlockTitle>
                    {emails.map((email, index) => [
                        <div className="email-wrap" key={index}>
                            <span>{`${_.find(contactEmailTypes, { value: `${email.type}` }).label}: `}</span>
                            {' '}
                            <ContentInfoBlockText
                                type="email"
                                value={email.value}
                                key={`email_${index}`}
                            />
                        </div>
                    ])}
                </ContentInfoBlock>
            </div>
        </ContentInfoWrap>
    );
}

InfoWrap.propTypes = propTypes;
InfoWrap.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    const socials = getStoreItem(state, "view.socials") || [];
    return {
        photo: getStoreItem(state, "view.photo"),
        name: getStoreItem(state, "view.name"),
        title: getStoreItem(state, "view.title"),
        emails: getStoreItem(state, "view.emails"),
        company: getStoreItem(state, "view.company"),
        canEdit: getStoreItem(state, "view.canEdit"),

        link: _.get(_.head(socials.filter(item => item.type === 10)), "value", false),
        instagram: _.get(_.head(socials.filter(item => item.type === 9)), "value", false),
        twitter: _.get(_.head(socials.filter(item => item.type === 8)), "value", false),
        facebook: _.get(_.head(socials.filter(item => item.type === 7)), "value", false),

        phones: getStoreItem(state, "view.phones"),
        physical: getStoreItem(state, "view.physical"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
