import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../../EventsService";

const typeList = [
    {
        value: "4",
        label: "Moldflow",
    },
    {
        value: "3",
        label: "Moldex3D",
    },
    {
        value: "2",
        label: "FEA",
    },
    {
        value: "1",
        label: "Other",
    },
];

const visibilityList = [
    {
        value: "10",
        label: "YES",
    },
    {
        value: "0",
        label: "NO",
    }
];

export const getTableLabel = key => {
    const tableLabel = {
        name: "Name",
        file_type: "Type",
        modified: "Date Submitted",
        modified_by: "Owner",
        note: "Note",
        visibility: "Private",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        file_type: "Search",
        modified: "Select Date",
        modified_by: "Search",
        note: "Search",
        visibility: "Search",
    };

    switch (key) {
        case "visibility":
            return (
                <InputFilter
                    type="select"
                    options={visibilityList}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        case "file_type":
            return (
                <InputFilter
                    type="multiSelect"
                    options={typeList}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "modified":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
