import React from "react";
import _ from "lodash";
import { numWithSub } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { handleScroll } from "./CommonService";
import { TableProjectsActions } from "../Actions";
import TableLine from "../Views/Card/Table/Templates/TableLine";
import { requestGet } from "./RequestService";

const tableLabel = {
    name: "Project Name",
    volume: "Forecast Volume (Current)",
};

const getViewItem = (key, item, lineWidth) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLink title={_.get(item, key)} href={`/project/projects/${_.get(item, "id")}`}>
                    {_.get(item, key)}
                </TableRowItemLink>
            );

        case "volume":
            return (
                <div className="line-bg">
                    <TableLine width={lineWidth}>
                        <span>{`${numWithSub(_.get(item, key))} lb`}</span>
                    </TableLine>
                </div>
            );

        default:
            return <TableRowItemSpan title={_.get(item, key)}>{_.get(item, key)}</TableRowItemSpan>;
    }
};

const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableProjectsActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export default () => ({
    requestTable: requestGet("/dashboard/table/projects"),
    getActionStore,
    tableLabel,
    getViewItem,
    handleScroll,
});
