import React from "react";
import _ from "lodash";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoLogo,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";
import { formatDate } from "Services";

const defaultProps = {};

const propTypes = {
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    physical: PropTypes.string.isRequired,
    startFy: PropTypes.string.isRequired,
    endFy: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired,
};

const InfoWrap = ({
    logo,
    name,
    email,
    phone,
    physical,
    startFy,
    endFy,
    t,
    onClickEdit,
}) => {
    return (
        <ContentInfoWrap>
            <ContentInfoTop onClickEdit={onClickEdit} urlBack="/admin/companies" />
            <ContentInfoLogo url={logo} />

            <ContentInfoBlock>
                <ContentInfoTitle>{name}</ContentInfoTitle>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!email}>
                <ContentInfoBlockTitle>{t("Email")}</ContentInfoBlockTitle>
                <ContentInfoBlockText type="email" value={email} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!phone.trim()}>
                <ContentInfoBlockTitle>{t("Phone")}</ContentInfoBlockTitle>
                <ContentInfoBlockText type="phone" value={phone} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!physical}>
                <ContentInfoBlockTitle>{t("Physical Address")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={physical} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!startFy}>
                <ContentInfoBlockTitle>{t("Start of FY")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(startFy, "MM/DD")} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!endFy}>
                <ContentInfoBlockTitle>{t("End of FY")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(endFy, "MM/DD")} />
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.defaultProps = defaultProps;
InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    const physical = getStoreItem(state, "view.physical");

    const formatAddress = obj =>
        _.replace([obj.address1, obj.address2, obj.zip, obj.city, obj.state, obj.country].join(" "), "  ", " ");

    return {
        logo: getStoreItem(state, "view.logo"),
        name: getStoreItem(state, "view.name"),
        email: getStoreItem(state, "view.email"),
        phone: `${getStoreItem(state, "view.code")} ${getStoreItem(state, "view.number")}`,
        physical: formatAddress(physical),
        startFy: getStoreItem(state, "view.start_fy"),
        endFy: getStoreItem(state, "view.end_fy"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
