import React from "react";
import { getString } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import Img from "Templates/Img";

export const modifierValues = companies => companies;

export const getViewItem = (key, item) => {
    switch (key) {
        case "company":
            return (
                <TableRowItemLink
                    title={getString(item, `${key}Name`)}
                    href={`/admin/companies/${getString(item, `${key}Id`)}`}
                >
                    {getString(item, `${key}Name`)}
                </TableRowItemLink>
            );

        case "admin":
            return (
                <TableRowItemSpan>
                    <Img img={getString(item, key, "") ? "icon_check_green_simple" : "icon_mdash"} />
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key) || ""}>{getString(item, key) || ""}</TableRowItemSpan>;
    }
};
