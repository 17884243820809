import React from "react";
import { getString } from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case 'name':
        case 'slug':
        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
