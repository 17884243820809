import { request, url } from "Services";

const save = (data, requestUrl) => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

const requestDelete = requestUrl => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        type: "DELETE",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

const getData = (requestUrl) => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        type: "GET",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestHierarchyData = requestUrl => {
    return new Promise((resolve, reject) => {
        getData(requestUrl).then(
            res => {
                resolve({ ...res[0] });
            },
            error => reject(error),
        );
    });
};

export const requestDeleteProperty = requestUrl => {
    return new Promise((resolve, reject) => {
        requestDelete(requestUrl).then(
            res => {
                resolve({ ...res[0] });
            },
            error => reject(error),
        );
    });
};

export const requestCreate = requestUrl => data => {
    return new Promise((resolve, reject) => {
        save(data, requestUrl).then(
            res => {
                resolve({ ...res[0] });
            },
            error => reject(error),
        );
    });
};
