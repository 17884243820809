import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row, Column } from "Templates/Table/NewVersion";
import { Span } from "Templates/Default";

const propTypes = {
    select: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    service: PropTypes.shape({
        optionsForFirstSelect: PropTypes.func.isRequired,
    }).isRequired,
};

const TableHeadColumn = ({ data, select, permissions, weightUnit, service: { optionsForFirstSelect }, t }) => {
    const selectedValue = optionsForFirstSelect(permissions).find(elem => elem.value === select);
    return (
        <>
            <Row>
                <Column colSpan={1} />
                {Object.keys(data).map(elem => (
                    <Column key={`Column-${elem}`} colSpan={1}>
                        <Span className="gray-text">{elem}</Span>
                    </Column>
                ))}
            </Row>
            {selectedValue.showTotalInTableHead && (
                <Row>
                    <Column colSpan={1}>
                        <Span className="bold-text">{t("Total")}</Span>
                    </Column>
                    {Object.values(data).map((items, index) => (
                        <Column key={`Column-${index}`} colSpan={1}>
                            <Span className="bold-text">
                                {`${selectedValue.viewFormat(items.reduce((acc, elem) => acc + Number(elem.sum), 0), weightUnit.weightUnit)}`}
                            </Span>
                        </Column>
                    ))}
                </Row>
            )}
        </>
    );
};

TableHeadColumn.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        data: getStoreItem(state, "data"),
        select: getStoreItem(state, "old.marginListSelect"),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(TableHeadColumn);
