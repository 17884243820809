import React from "react";
import {useSelector} from 'react-redux';
import {weightUnitName} from 'Services/weightUnitName';
import {formatDate, getString, numberWithCommas} from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {gradeStatus, projectStatus} from "Services/enum";
import _ from "lodash";
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const unit = useSelector(state => state.weightUnitState);
    const projects = getString(item, 'projects') !== '-' ? JSON.parse(_.get(item, 'projects', [])) : [];

    switch (key) {
        case "slug":
            return (
                <TableRowItemLink
                    title={getString(item, key)}
                    href={`/connection/connections/${getString(item, "connection_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "company_short_name":
        case "company":
            return _.get(item, key).trim()
                ? (
                <TableRowItemLink
                    blank
                    title={getString(item, key)}
                    href={`/companies/${getString(item, "company_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
                )
                : (
                    <TableRowItemSpan key={`${key}`} title="">
                        {'-'}
                    </TableRowItemSpan>
                );

        case "sap_grade":
        case "sap_customer":
            return <TableRowItemSpan>{getString(item, key, null) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case "sales_manager":
            return _.get(item, key).trim()
                ? (
                    <TableRowItemLink
                        blank
                        key={`${getString(item, 'sales_manager_id')}`}
                        title={getString(item, key)}
                        href={`/admin/users/${getString(item, 'sales_manager_id')}`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                )
                : (
                    <TableRowItemSpan key={`${key}`} title="">
                        {'-'}
                    </TableRowItemSpan>
                );

        case "grade":
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, key)}
                    href={`/product/product-finder/${getString(item, "grade_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "grade_sub_status":
            return (
                <TableRowItemStatus
                    status={getString(item, key)}
                    list={[...gradeStatus]}
                />
            );

        case "sap_application_code":
        case "material_type": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={`${key}-${index}`} title={elem}>{elem}</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title='-'>{'-'}</TableRowItemSpan>
            );
        }

        case "project_manager":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                elem.manager.id ?
                                    <TableRowItemLink
                                        blank
                                        key={`${key}-${index}`}
                                        href={`/admin/users/${elem.manager.id}`}
                                    >
                                        {elem.manager.name}
                                    </TableRowItemLink>
                                    : <TableRowItemSpan key={`${key}-${index}`} title='-'>{'-'}</TableRowItemSpan>
                            )
                        })
                    )
                    : <TableRowItemSpan title='-'>{'-'}</TableRowItemSpan>
            );

        case "project_internal_team":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                elem.internal_team.id ?
                                    <TableRowItemLink
                                        blank
                                        key={`${key}-${index}`}
                                        href={`/admin/users/${elem.internal_team.id}`}
                                    >
                                        {elem.internal_team.name}
                                    </TableRowItemLink>
                                    : <TableRowItemSpan key={`${key}-${index}`} title='-'>{'-'}</TableRowItemSpan>
                            )
                        })
                    )
                    : <TableRowItemSpan title='-'>{'-'}</TableRowItemSpan>
            );

        case "project_sub_status":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                <div key={`div-${index}`}>
                                    <TableRowItemStatus
                                        key={`${key}-${index}`}
                                        status={elem.sub_status.toString()}
                                        list={[...projectStatus]}
                                    />
                                </div>
                            )
                        })
                    )
                    : <TableRowItemSpan title='-'>{'-'}</TableRowItemSpan>
            );

        case "los_date":
        case "end_date":
        case "sop":
        case "eop":
        case "move_to_current": {
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            const formatValue = elem[key] ? formatDate(elem[key], "MMM, YYYY") : '-';

                            return (
                                <TableRowItemSpan key={`${key}-${index}`} title={formatValue}>
                                    {formatValue}
                                </TableRowItemSpan>
                            )
                        })
                    )
                    : <TableRowItemSpan title='-'>{'-'}</TableRowItemSpan>
            );
        }

        case "plant":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                elem.plant ?
                                    <TableRowItemSpan
                                        key={`${key}-${index}`}
                                        title={elem.plant}>
                                        {elem.plant}
                                    </TableRowItemSpan>
                                    : <TableRowItemSpan key={`${key}-${index}`} title='-'>{'-'}</TableRowItemSpan>
                            )
                        })
                    )
                    : <TableRowItemSpan title='-'>{'-'}</TableRowItemSpan>
            );

        case "project_slug":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                <TableRowItemLink
                                    key={`${key}-${index}`}
                                    blank={true}
                                    href={`/project/projects/${elem.id}`}>
                                    {elem.slug}
                                </TableRowItemLink>
                            )
                        })
                    )
                    : <TableRowItemSpan title='-'>{'-'}</TableRowItemSpan>
            );

        case "project_name":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                <TableRowItemLink
                                    key={`${key}-${index}`}
                                    blank={true}
                                    href={`/project/projects/${elem.id}`}>
                                    {elem.name}
                                </TableRowItemLink>
                            )
                        })
                    )
                    : <TableRowItemSpan title='-'>{'-'}</TableRowItemSpan>
            );

        case "price":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                <TableRowItemSpan
                                    className="text-right"
                                    key={`${key}-${index}`}
                                    title={elem.price}>
                                    {`$${Number(elem.price) < 10 ? Number(elem.price).toFixed(2) : numberWithCommas(elem.price, 2)}`}
                                </TableRowItemSpan>
                            )
                        })
                    )
                    : <TableRowItemSpan className="text-right" title='0.00'>{'$0.00'}</TableRowItemSpan>
            );

        case "rebate":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                <TableRowItemSpan
                                    className="text-right"
                                    key={`${key}-${index}`}
                                    title={elem.rebate}>
                                    {`$${Number(elem.rebate) < 10 ? Number(elem.rebate).toFixed(2) : numberWithCommas(elem.rebate, 2)}`}
                                </TableRowItemSpan>
                            )
                        })
                    )
                    : <TableRowItemSpan className="text-right" title='0.00'>{'$0.00'}</TableRowItemSpan>
            );

        case "final_price":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                <TableRowItemSpan
                                    className="text-right"
                                    key={`${key}-${index}`}
                                    title={elem.final_price}>
                                    {`$${Number(elem.final_price) < 10 ? Number(elem.final_price).toFixed(2) : numberWithCommas(elem.final_price, 2)}`}

                                </TableRowItemSpan>
                            )
                        })
                    )
                    : <TableRowItemSpan className="text-right" title='0.00'>{'$0.00'}</TableRowItemSpan>
            );
        case "part_weight":
            return (
                projects.length > 0
                    ? (
                        projects.map((elem, index) => {
                            return (
                                <TableRowItemSpan
                                    className="text-right"
                                    key={`${key}-${index}`}
                                    title={elem.part_weight}>
                                    {`${Number(elem.part_weight).toFixed(2)}`}
                                </TableRowItemSpan>
                            )
                        })
                    )
                    : <TableRowItemSpan className="text-right" title='0.00'>{'0.00'}</TableRowItemSpan>
            );
        case "next_average_sale_price":
        case "current_average_sale_price":
        case "actual_calculated_price":
        case "budget_price": {
            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(getString(item, key)).toFixed(2)}`}
                </TableRowItemSpan>
            );
        }

        case "next_revenue":
        case "next_total_revenue":
        case "next_total_rebate":
        case "current_revenue":
        case "current_total_revenue":
        case "current_total_rebate":
        case "budget_revenue":
        case "annualized_revenue":
        case "actual_net_amount": {
            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(getString(item, key)) === 0 ? '0' : numberWithCommas(getString(item, key), 0)}`}
                </TableRowItemSpan>
            );
        }

        case "current_variance_percent": {
            return (
                <TableRowItemSpan className="text-right">
                    {`${Number(getString(item, key)) === 0 ? '0' : numberWithCommas(getString(item, key), 0)} %`}
                </TableRowItemSpan>
            );
        }

        case "budget_volume":
        case "annualized_volume":
        case "actual_normalized_weight": {
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, key), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case "next_volume":
        case "current_volume": {
            return (
                <TableRowItemSpan className="text-right">
                    <>
                        <span className="value">{numberWithCommas(getString(item, key), 0)}</span>
                        {' '}
                        {`${weightUnitName(unit.weightUnit)}`}
                    </>
                </TableRowItemSpan>
            );
        }

        case "current_variance": {
            const val = +item.current_variance
            return (
                <TableRowItemSpan className="text-right">
                    <>
                        <span className="value">{!isNaN(val) && (val < 0) ? `(${numberWithCommas(item.current_variance.replace('-', ''), 0)})` : numberWithCommas(getString(item, key), 0)}</span>
                        {' '}
                        {`${weightUnitName(unit.weightUnit)}`}
                    </>
                </TableRowItemSpan>
            );
        }

        case 'note':
            return (
                item.note
                    ? <div className="publication-row"><TableRowItemTooltip>{item.note}</TableRowItemTooltip></div>
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );

        case 'note_finance':
            return (
                item.note_finance
                    ? <div className="publication-row"><TableRowItemTooltip>{item.note_finance}</TableRowItemTooltip></div>
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
