import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "Services/AsyncSelect/AsyncSelect";
import { Input } from "../../Input";
import AsyncSelectDates from "Services/AsyncSelect/AsyncSelectDates";

const propTypes = {
    className: PropTypes.string,
    url: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.any),
    inputProps: PropTypes.objectOf(PropTypes.any),
    placeholder: PropTypes.string,
    type: PropTypes.string,
    dateType: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const defaultProps = {
    className: "",
    disabled: false,
    url: "",
    type: "text",
    dateType: "",
    options: [],
    placeholder: "",
    inputProps: {},
};

const ColInput = ({ url, disabled, inputProps, className, type, name, placeholder, dateType, value, options, onChange }) => {
    switch (type) {
        case "asyncSelect":
            return (
                <AsyncSelect
                    placeholder={placeholder}
                    disabled={disabled}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    url={url}
                    handleChange={onChange}
                />
            );

        case "asyncSelectDates":
            return (
                <AsyncSelectDates
                    placeholder={placeholder}
                    disabled={disabled}
                    inputProps={{ ...inputProps, className }}
                    value={value}
                    dateType={dateType}
                    url={url}
                    handleChange={onChange}
                />
            );

        case "select":
            return (
                <Input
                    type="select"
                    className="main-modal__form-col-input"
                    name={name}
                    options={options}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            );

        default:
            return (
                <Input
                    type={type}
                    className="main-modal__form-col-input"
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            );
    }
};

ColInput.propTypes = propTypes;
ColInput.defaultProps = defaultProps;

export default ColInput;
