import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import TableRowColumnDefault from "Templates/Table/TableRowColumnWrap/Default";
import {withTagDefaultProps} from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import {withServiceConsumer} from "Services/Context";
import TableRowItemLink from "../../../../../../../../Templates/Table/TableRowItemLink";

const propTypes = {
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
};

const TableRowColumn = React.memo(({ index, secondaryIndex, column, item }) => {
    return (
        <TableRowColumnTemplate key={`TableRowColumn-${index}-${secondaryIndex}`}>
            {column === "-1" ? (
                <TableRowItemLink
                    blank
                    title={item.id}
                    href={`/product/product-finder/publication-groups/contacts/contact/${item.id}/history `}
                >
                    View
                </TableRowItemLink>
            ) : (
                <TableRowColumnDefault column={column} item={item} />
            )}
        </TableRowColumnTemplate>
    );
});

TableRowColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableRowColumn);
