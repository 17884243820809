import _ from "lodash";
import { getStoreItem } from "Services";
import { industryOptions } from "Services/enum";
import { requestCreate } from "./RequestService";
import { FormActions } from "../Actions";
import * as FormService from "./FormService";

const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...FormActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export { FormService, requestCreate, getActionStore, getStoreItem, industryOptions };
