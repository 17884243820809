import _ from "lodash";
import { FormActions } from "../Actions";

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...FormActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
