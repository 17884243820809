import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import PropTypes from "prop-types";

const InfoBlock = ({ pref, children, show }) => {
    if (!show) {
        return null;
    }

    return <div className={`${pref}__info-block main-content__info-block`}>{children}</div>
};

InfoBlock.propTypes = {
    show: PropTypes.bool
};
InfoBlock.defaultProps = {
    show: true
};

export default compose(withTagDefaultProps())(InfoBlock);
