import { handleScroll } from "../../EventsService";
import {deleteItem, requestTable} from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getTableLabel, getFilter } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`admin/list/products`),
    onClickDeleteItem: item => deleteItem(`admin/list/products/${item.id}/delete`),
    getFilter: getFilter(id),
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
