import { handleScroll } from "../../EventsService";
import {deleteItem, requestTable} from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getTableLabel, getFilter } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default ({
    requestTable: requestTable(`/legal/copy`),
    onClickDeleteItem: item => deleteItem(`/legal/copy/${item.id}/delete`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
