import React from "react";
import PropTypes from "prop-types";
import parse from 'html-react-parser';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const defaultProps = {
    column: "",
};

const propTypes = {
    onSortClick: PropTypes.func.isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    column: PropTypes.string,
    service: PropTypes.shape({
        isSortEnabled: PropTypes.func,
    }).isRequired,
};

const TableHeadTitle = ({children, pref, toolTip, column, sort, service: {isSortEnabled}, onSortClick}) => {
    const _isSortEnabled = isSortEnabled || (() => true);
    const isEnabled = _isSortEnabled(column);

    return <>
        <a
            href="#"
            className={`${pref}__table-head-link main-table__head-link ${ column === 'normalized_weight' || column === 'net_amount' ? 'total-analytic-link' : '' } ${isEnabled ? 'main-is-enabled' : 'main-is-disabled'}`}
            onClick={e => {
                e.preventDefault();
                if (isEnabled) {
                    onSortClick();
                }
            }}
        >
            {parse(children)}

            {toolTip && (
                <span className="tooltip tooltip__table" onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}>
                    <i className="la la-info-circle" />
                    <span className="tooltip__text tooltip__text__table">{parse(toolTip)}</span>
                </span>
            )}
            {isEnabled && (
                <span className={`${pref}__table-head-link main-table__head-link-icons`}>
                <i
                    className={`fas fa-sort-up main-table__head-link-icon 
                ${sort.column === column && sort.direction === "asc" ? "icon-active" : ""}`}
                />
                <i
                    className={`fas fa-sort-down main-table__head-link-icon 
                ${sort.column === column && sort.direction === "desc" ? "icon-active" : ""}`}
                />
            </span>
            )}
        </a>
    </>
};

TableHeadTitle.defaultProps = defaultProps;
TableHeadTitle.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableHeadTitle);
