import React from "react";
import { getString, numberWithCommas } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

const tableLabel = {
    name: "Plant Name",
    address: "Address",
    revenue: "Revenue",
    volume: "Volume",
    gross_margin: "Gross Margin",
};

export const title = "Plants";

export const getTableLabel = key => getString(tableLabel, key, key);

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/product/plants/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "address":
            return (
                <TableRowItemSpan title={getString(item, key)} className="max-width-60">
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "revenue":
            return (
                <TableRowItemSpan className="text-right">{`$${numberWithCommas(
                    getString(item, key),
                )}`}</TableRowItemSpan>
            );

        case "volume":
            return (
                <TableRowItemSpan className="text-right">{`${numberWithCommas(
                    getString(item, key),
                )} lb`}</TableRowItemSpan>
            );

        case "gross_margin":
            return (
                <TableRowItemSpan
                    title={getString(item, key)}
                    className={`${getString(item, key) < 0 ? "clr-red" : ""} text-right`}
                >
                    {`${getString(item, key)}%`}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
