import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {formatDate} from "Services";
import {Modal} from "Templates/Modals";
import MonthlyModal from "../MonthlyModal";
import InfoPopup from "Templates/InfoPopup/InfoPopup";

const TableBodyColumn = ({id, items, weightUnit}) => {
    const [data, setData] = useState([])
    const [forecastModalData, setForecastModalData] = useState({
        isOpen: false,
        forecast: null
    })

    useEffect(() => {
        const productItems = items && items.items && items.items.length > 0 ? items.items.map((item) => {
            return {
                id: item.forecast,
                manufacturer: {
                    value: item.manufacturer_name || '-',
                    link: item.manufacturer_id ? `/project/vehicles/${item.manufacturer_id}` : null
                },
                platform: {
                    value: item.platform || '-',
                },
                program: {
                    value: item.program || '-',
                },
                nameplate: {
                    value: item.nameplate || '-',
                },
                vehicleMnemonic: {
                    value: item.vehicle_id || '-',
                    link: item.vehicle_id ? `/project/vehicles/${item.unit_id}` : null
                },
                regionMnemonic: {
                    value: item.region_mnemonic || '-',
                },
                plantMnemonic: {
                    value: item.plant_mnemonic || '-',
                },
                unitForecastName: {
                    value: item.unit || '-',
                    link: item.unit ? `/project/forecasts/${item.unit_id}` : null
                },
                sop: {
                    value: item.sop ? formatDate(item.sop) : '-'
                },
                eop: {
                    value: item.eop ? formatDate(item.eop) : '-',
                },
                date_moved_to_current: {
                    value: item.date_moved_to_current ? formatDate(item.date_moved_to_current) : '-',
                },
                end_date: {
                    value: item.end_date ? formatDate(item.end_date) : '-',
                },
                monthlyIhsDelta: {
                    value: item.ihs_delta || '-'
                },
                monthlyIhsDeltaAll: {
                    value: item.ihs_delta_all && item.ihs_delta_all.length ? item.ihs_delta_all : []
                }
            }
        }) : null
        setData(productItems)
    }, [items])

    return data && data.length > 0 ? (
        <tr className="project-expanded-block__table project-product-expanded-block__table">
            <td className="project-expanded-block__table-row">
                <div className="project-expanded-block__table-col labels">
                    <p className="project-expanded-block__table-material-item plug"/>
                    <p className="project-expanded-block__table-title">Manufacturer</p>
                    <p className="project-expanded-block__table-title">Platform</p>
                    <p className="project-expanded-block__table-title">Program</p>
                    <p className="project-expanded-block__table-title">Nameplate</p>
                    <p className="project-expanded-block__table-title">Vehicle Mnemonic</p>
                    <p className="project-expanded-block__table-title">Region Mnemonic</p>
                    <p className="project-expanded-block__table-title">Plant Mnemonic</p>
                    <p className="project-expanded-block__table-title">Unit Forecast Name</p>
                    <p className="project-expanded-block__table-title">SOP</p>
                    <p className="project-expanded-block__table-title">EOP</p>
                    <p className="project-expanded-block__table-title">Start Date</p>
                    <p className="project-expanded-block__table-title">End Date</p>
                    <p className="project-expanded-block__table-title">Monthly IHS Delta</p>
                </div>
                {data.map((item, i) => {
                    return (
                        <div className="project-expanded-block__table-col" key={`project-forecast-${i}`}>
                            <p className="project-expanded-block__table-material-item">
                                {`${item.unitForecastName.value && item.unitForecastName.value !== '-' ? 'Unit Forecast' : 'IHS Automotive'} ${i + 1}`}
                                <span
                                    className="forecast-btn project-expanded-block__table-value-text"
                                    onClick={() => setForecastModalData({
                                        isOpen: true,
                                        forecast: item
                                    })}
                                >
                                View Monthly
                            </span>
                            </p>
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.manufacturer.value}>{item.manufacturer.value}</span></p>
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.platform.value}>{item.platform.value}</span></p>
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.program.value}>{item.program.value}</span></p>
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.nameplate.value}>{item.nameplate.value}</span></p>
                            {item.vehicleMnemonic.link ? (
                                    <a
                                        href={`${item.vehicleMnemonic.link}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="project-expanded-block__table-value text-nowrap"
                                        title={item.vehicleMnemonic.value}
                                    >
                                        {item.vehicleMnemonic.value}
                                    </a>
                                ) :
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.vehicleMnemonic.value}>{item.vehicleMnemonic.value}</span></p>}
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.regionMnemonic.value}>{item.regionMnemonic.value}</span></p>
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.plantMnemonic.value}>{item.plantMnemonic.value}</span></p>
                            {item.unitForecastName.link ? (
                                    <a
                                        href={`${item.unitForecastName.link}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="project-expanded-block__table-value text-nowrap"
                                        title={item.unitForecastName.value}
                                    >
                                        {item.unitForecastName.value}
                                    </a>
                                ) :
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.unitForecastName.value}>{item.unitForecastName.value}</span></p>}
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.sop.value}>{item.sop.value}</span></p>
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.eop.value}>{item.eop.value}</span></p>
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.date_moved_to_current.value}>{item.date_moved_to_current.value}</span></p>
                            <p className="project-expanded-block__table-value"><span className="project-expanded-block__table-value-text" title={item.end_date.value}>{item.end_date.value}</span></p>
                            <p className="project-expanded-block__table-value with-view-more">
                                <span className="project-expanded-block__table-value-text" title={item.monthlyIhsDelta.value}>{item.monthlyIhsDelta.value}</span>
                                {item.monthlyIhsDeltaAll.value && item.monthlyIhsDeltaAll.value.length > 0 ? <InfoPopup
                                    type="segments"
                                    left={true}
                                    content={[...item.monthlyIhsDeltaAll.value].map((elem, index) => (
                                        <div key={index} className="project-expanded-block__table-info-popup">
                                            <i>{formatDate(elem.date)}: </i>
                                            <i>{elem.note}</i>
                                        </div>
                                    ))}
                                /> : null}
                            </p>
                        </div>
                    )
                })}
                {forecastModalData.isOpen ? (
                    <Modal className="forecast-monthly-modal" onRequestClose={() => setForecastModalData({
                        isOpen: false,
                        forecast: false
                    })}
                    >
                        <MonthlyModal
                            id={id}
                            weightUnit={weightUnit}
                            forecast={forecastModalData.forecast}
                            onClose={() => setForecastModalData({
                                isOpen: false,
                                forecast: false
                            })}
                        />
                    </Modal>
                ) : null}
            </td>
        </tr>
    ) : null;
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        id: state.listState.view.id,
        items: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
