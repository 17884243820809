import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import _ from "lodash";
import reducers, {initialStates} from "../Reducers";
import {initState} from "../Reducers/ViewReducer";

export default function configureStore(props) {
    const { items, pagination, columns, view, financialYear, totalNotes, totalProjects, totalInvoices, total_analytics } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "companies-content" }),
        listState: {
            ...mainState,
            items,
            pagination,
            columns,
            view,
            sort: { column: columns[0], direction: "asc" },
            financialYear,
            totalNotes,
            totalProjects,
            totalInvoices,
            total_analytics
        },
    };

    if (initState.activeTab) {
        _.unset(initialState.listState, "items");
    }

    return _configureStore(initialState, reducers);
}
