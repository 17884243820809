import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import VideoList from "Services/VideoUpload/VideoList";
import {Preloader} from "Templates/Preloader";
import VideoThumbNail from "Services/VideoUpload/VideoThumbNail";

const {Button, Form, Title, ColInput} = ModalDefault;

const propTypes = {
    loading: PropTypes.bool.isRequired,
    folderId: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    addFiles: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

const UploadFileForm = ({t, loading, folderId, position, addFiles, onClose}) => {
    const [files, setFiles] = useState([])
    const [videoFiles, setVideoFiles] = useState([])
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [disabled, setDisabled] = useState(true)

    const videoMemo = React.useMemo(() => {
        if (!files.length) return ''
        return URL.createObjectURL(files[0])
    }, [files])

    useEffect(() => {
        setDisabled(files.length <= 0 || videoFiles.length <= 0 || !title.trim() || !description.trim())
    }, [files, videoFiles, title, description])

    const onChange = value => {
        setFiles([...value])
    };

    const onChangeThumb = value => {
        setVideoFiles([...value])
    };

    const handleCloseModal = event => {
        event.preventDefault();
        onClose();
    };

    const handleClickOnSubmit = () => {
        addFiles({folderId, files, videoFiles, title, description, position});
    };

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={handleCloseModal}/>
            <Title>{t("Add New Video")}</Title>
            <div className="upload-video-form__wrap">
                <div className="upload-video-form__wrap-files">
                    <div className="upload-video-form__wrap-video">
                        <p className="upload-video-form__file-label">Video</p>
                        {videoMemo ? <div className="edit-video-preview"><video src={videoMemo} controls controlsList="nodownload" /></div> : null}
                        <VideoList
                            name="files"
                            files={files}
                            dropFile={e => {
                                onChange(e)
                            }}
                            handleDrop={e => onChange([...files, ...e])}
                        />
                    </div>
                    <div className="upload-video-form__wrap-image">
                        <p className="upload-video-form__file-label">Preview Image</p>
                        {videoFiles.length > 0
                            ? <div className="edit-video-preview"><img src={URL.createObjectURL(videoFiles[0])} alt="Thumbnail Preview"/></div>
                            : null}
                        <VideoThumbNail
                            name="thumbNail"
                            videoFiles={videoFiles}
                            dropFile={e => {
                                onChangeThumb(e)
                            }}
                            handleDrop={e => onChangeThumb([...videoFiles, ...e])}
                        />
                    </div>
                </div>
                <div className="upload-video-form__wrap-fields">
                    <div className="upload-video-form__wrap-field">
                        <p>
                            Video Title
                            <span>*</span>
                        </p>
                        <ColInput
                            placeholder={t("Enter Title")}
                            name="title"
                            value={title}
                            onChange={value => {
                                setTitle(value.substr(0, 255))
                            }}
                        />
                    </div>
                    <div className="upload-video-form__wrap-field">
                        <p>
                            Description
                            <span>*</span>
                        </p>
                        <ColInput
                            placeholder={t("Enter Description")}
                            name="description"
                            value={description}
                            onChange={value => {
                                setDescription(value.substr(0, 255))
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="upload-video-form__wrap-button">
                <Button onClick={handleClickOnSubmit} disabled={disabled}>
                    {t("Add Video")}
                </Button>
            </div>
            {loading ? <Preloader/> : null}
        </Form>
    );
}

UploadFileForm.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        loading: getStoreItem(state, "loading"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        addFiles: getActionStore("uploadFileAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(UploadFileForm);
