import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {formatDate} from "Services";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoBlockTextWithUserImg,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap
} from "Templates/Content";

const defaultProps = {};

const propTypes = {
    fileName: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    userPhoto: PropTypes.string.isRequired,
};

const InfoWrap = ({
                      fileName,
                      modified,
                      userName,
                      userId,
                      userPhoto,
                      t
                  }) => {
    return (
        <ContentInfoWrap>
            <ContentInfoTop
                onClickEdit={null}
                urlBack={"/admin/ihs"}
            />
            <ContentInfoTitle>{fileName}</ContentInfoTitle>

            <ContentInfoBlock show={!!modified}>
                <ContentInfoBlockTitle>{t("Modified")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(modified)}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!userId}>
                <ContentInfoBlockTitle>{t("Modified By")}</ContentInfoBlockTitle>
                <ContentInfoBlockTextWithUserImg
                    href={`/admin/users/${userId}`}
                    url={userPhoto}
                    value={userName}
                />
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;
InfoWrap.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        fileName: getStoreItem(state, "view.fileName"),
        modified: getStoreItem(state, "view.modified"),
        userName: getStoreItem(state, "view.userName"),
        userId: getStoreItem(state, "view.userId"),
        userPhoto: getStoreItem(state, "view.userPhoto"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
