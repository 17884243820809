import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { TabWrap } from "Templates/Content";
import { CustomSimpleColumnTable } from "Hoc/Widgets/Table/SimpleColumnTableContainer";
import YearTableBodyColumn from "./Table/YearTableBodyColumn";

const YearTable = CustomSimpleColumnTable(YearTableBodyColumn);

const defaultProps = {};

const propTypes = {
    getData: PropTypes.func.isRequired,
};

class DataYear extends Component {
    componentDidMount() {
        const { getData } = this.props;
        getData();
    }

    render() {
        const { pref } = this.props;
        return (
            <>
                <TabWrap>
                    <div className={`${pref}__wrap-overflow`}>
                        <YearTable />
                    </div>
                </TabWrap>
            </>
        );
    }
}

DataYear.defaultProps = defaultProps;
DataYear.propTypes = propTypes;

const mapStateToProps = (state, { service }) => {
    return {};
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        getData: getActionStore("getDataAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(DataYear);
