import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { projectStatusList } from "Services/enum";
import { Ul } from "Templates/Default";
import ProjectStatusBlockListElement from "./ProjectStatusBlockListElement";
import {withServiceConsumer} from "Services/Context";


const ProjectStatusBlockList = ({ pref, projectStatus }) => {
    let ListStatuses = projectStatusList;

    return (<Ul className={`status status--${pref}`}>
        {ListStatuses.map((item, index) => (
            <ProjectStatusBlockListElement
                key={`projectStatus-${index}`}
                item={item}
                index={index}
                length={projectStatusList.length}
            />
        ))}
    </Ul>);
}


const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        projectStatus: getStoreItem(state, "view.subStatus"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ProjectStatusBlockList);
