import { handleScroll } from "../../EventsService";
import {deleteItem, requestAttach, requestTable} from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getTableLabel, getFilter } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default ({
    requestTable: requestTable(`admin/list/brochures`),
    requestAttach: requestAttach(`/admin/list/brochures/create`),
    requestEdit: item => requestAttach(`/admin/list/brochures/${item.id}/edit`),
    onClickDeleteItem: item => deleteItem(`admin/list/brochures/${item.id}/delete`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
