import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import TableBodyButton from "Templates/Table/TableBodyButton";
import Img from "Templates/Img/Img";
import { Modal } from "Templates/Modals";
import Help from "Templates/Titles/Help";

const defaultProps = {
    total: () => {},
    description: () => {},
    before: () => {},
    after: () => {},
    shouldWrap: () => false,
    showHelpBtn: false,
    handleHelpBtn: null,
    help: '',
};

const propTypes = {
    title: PropTypes.string.isRequired,
    help: PropTypes.string,
    total: PropTypes.func,
    description: PropTypes.func,
    before: PropTypes.func,
    after: PropTypes.func,
    shouldWrap: PropTypes.func,
    showHelpBtn: PropTypes.bool,
    handleHelpBtn: PropTypes.func,
};

const Title = ({ pref, before, after, title, help, total, children, description, shouldWrap, showHelpBtn, handleHelpBtn }) => {
    const [showHelpModal, setShowHelpModal] = React.useState(false);
    const handleOpenHelpModal = handleHelpBtn || (() => {
        setShowHelpModal(!showHelpModal);
    })

    showHelpBtn = showHelpBtn || !!help;

    return (
        <>
            <div
                className={`${pref}__wrap-title main-content__wrap-title ${shouldWrap ? 'main-content__wrap-title-break' : ''}`}
            >
                <div className={`${pref}__wrap-block main-content__wrap-block`}>
                    {before()}
                    <div className="help-wrap">
                        <h1 className={`${pref}__h1 main-content__h1`}>{title}</h1>
                        {showHelpBtn && (
                            <TableBodyButton onClick={handleOpenHelpModal} className="help-icon">
                                <Img img="icon_help" alt="help icon" />
                            </TableBodyButton>
                        )}
                    </div>
                    {total()}
                    {after()}
                </div>
                {children}
            </div>
            {description()}

            {showHelpModal && (
                <Modal onRequestClose={handleOpenHelpModal}>
                    <Help help={help} onClose={handleOpenHelpModal} />
                </Modal>
            )}
        </>
    )
};

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(Title);
