import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Vendors = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };
    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxHierarchy activeHierarchy={getPermission("vendors_view", index)} inputClass='first-hierarchy'>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_vendors_view-${index}`}
                        value={getPermission("vendors_view", index)}
                        onChange={value => onChangePermission("vendors_view", value, index)}
                    >
                        <CheckboxTitle title="Access - Vendors module" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to theVendors Module.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_vendors_edit-${index}`}
                        value={getPermission("vendors_edit", index)}
                        onChange={value => onChangePermission("vendors_edit", value, index)}
                    >
                        <CheckboxTitle title="Add/Edit/Delete - Vendors" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to Add/Edit/Delete/View Vendors.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
        </>
    )
}

Vendors.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Vendors);