import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import {canTranslate} from "Services";

const propTypes = {
    className: PropTypes.string,
    isRequired: PropTypes.bool,
};

const defaultProps = {
    className: "",
    isRequired: false,
};

const Span = ({ children, className, isRequired, t }) => (
    <span className={`${className} ${isRequired ? "required" : ""}`}>
        {React.Children.map(children, canTranslate(t))}
    </span>
);

Span.propTypes = propTypes;
Span.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(Span);
