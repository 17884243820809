import { getStoreItem as _getStoreItem } from "Services";
import { getActionStore } from "./StoreService";
import { handleScroll } from "./EventsService";
import { requestTable, requestAttach, deleteItem, requestEdit } from "./RequestService";

export const getStoreItem = _getStoreItem("listState");

export const typeList = [
    {
        value: "10",
        label: "NDA",
    },
    {
        value: "7",
        label: "Intellectual Property",
    },
    {
        value: "5",
        label: "Other",
    },
];

export {
    deleteItem,
    handleScroll,
    requestAttach,
    requestTable,
    requestEdit,
    getActionStore
};
