import _ from "lodash";
import { formDataNormalizer } from "Services";
import Constants from "../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const addNewProductAction = (service, dispatch) => () => {
    dispatch({ type: Constants.ADD_NEW_PRODUCT });
};

export const onChangeProductAction = (service, dispatch) => (key, value, index) => {
    dispatch({ type: Constants.CHANGE_PRODUCT, data: { key, value, index } });
};

export const onChangeSubSegmentAction = (service, dispatch) => (key, value, index) => {
    dispatch({ type: Constants.CHANGE_SUB_SEGMENT, data: { key, value, index } });
};

export const onChangeForecastAction = (service, dispatch) => (key, value, index) => {
    dispatch({ type: Constants.CHANGE_FORECAST, data: { key, value, index } });
};

export const onChangeVolumeAction = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_VOLUME, data: { key, value } });
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.FORM_RESET });
};

export const changePlantAction = (service, dispatch) => (gradeId, plantId, index) => {
    dispatch({ type: Constants.CHANGE_PLANT_REQUESTED });
    return new Promise((resolve, reject) => {
        if (!plantId) {
            dispatch({ type: Constants.CHANGE_PLANT_SUCCEEDED, data: { index } });
            return;
        }

        service.requestGet(`project/projects/grade/${gradeId}/sap-plant-list/${plantId}/data`).then(
            res => {
                dispatch({ type: Constants.CHANGE_PLANT_SUCCEEDED, data: { ...res, plant: plantId, index } });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.CHANGE_PLANT_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const changeCustomerAction = (service, dispatch) => (customerId) => {
    dispatch({ type: Constants.CHANGE_CUSTOMER_REQUESTED });

    return new Promise((resolve, reject) => {
        if (!customerId) {
            dispatch({ type: Constants.CHANGE_CUSTOMER_SUCCEEDED, data: {} });
            return;
        }

        service.requestGet(`project/projects/${customerId}/customer-data`).then(
            res => {
                dispatch({ type: Constants.CHANGE_CUSTOMER_SUCCEEDED, data: { ...res } });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.CHANGE_CUSTOMER_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const changeGradeAction = (service, dispatch) => (key, value, index) => {
    dispatch({ type: Constants.CHANGE_GRADE, data: { key, value, index } });
};

export const changeApplicationCodeAction = (service, dispatch) => (codeId, index) => {
    dispatch({ type: Constants.CHANGE_APPLICATION_CODE_REQUESTED });
    return new Promise((resolve, reject) => {
        if (!codeId) {
            dispatch({ type: Constants.CHANGE_APPLICATION_CODE_SUCCEEDED, data: {} });
            return;
        }

        service.requestGet(`project/projects/${codeId}/application-code/data`).then(
            res => {
                dispatch({ type: Constants.CHANGE_APPLICATION_CODE_SUCCEEDED, data: { ...res, index } });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.CHANGE_APPLICATION_CODE_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const changeForecastDateAction = (service, dispatch) => (mnemonicId, value, key, index) => {
    dispatch({ type: Constants.CHANGE_FORECAST_DATE_REQUESTED });
    return new Promise((resolve, reject) => {
        service.requestTotalUnits(`project/projects/forecast-list/${mnemonicId}/total-units`, { date: value }).then(
            apiRes => {
                dispatch({
                    type: Constants.CHANGE_FORECAST_DATE_SUCCEEDED,
                    data: { id: mnemonicId, apiRes, value, key, index },
                });
                resolve(apiRes);
            },
            errors => {
                dispatch({ type: Constants.CHANGE_FORECAST_DATE_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const submitPerfomaceAction = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_PERFOMACE_REQUESTED });
    return new Promise((resolve, reject) => {
        service.savePerformance(formDataNormalizer(data)).then(
            res => {
                dispatch({ type: Constants.SUBMIT_PERFOMACE_SUCCEEDED });
                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key: "generalBlock.performanceSpecification", value: _.get(res, "id") },
                });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SUBMIT_PERFOMACE_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const submitMaterialAction = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_MATERIAL_REQUESTED });
    return new Promise((resolve, reject) => {
        service.saveMaterial(formDataNormalizer(data)).then(
            res => {
                dispatch({ type: Constants.SUBMIT_MATERIAL_SUCCEEDED });
                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key: "generalBlock.materialSpecification", value: _.get(res, "id") },
                });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SUBMIT_MATERIAL_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const submitApplicationAction = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_APPLICATION_REQUESTED });
    return new Promise((resolve, reject) => {
        service.saveApplication(formDataNormalizer(data)).then(
            res => {
                dispatch({ type: Constants.SUBMIT_APPLICATION_SUCCEEDED });
                dispatch({
                    type: Constants.CHANGE_FORM,
                    data: { key: "applicationBlock.application.0.sub_segment", value: _.get(res, "id") },
                });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SUBMIT_APPLICATION_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const submitForm = (service, dispatch) => data => {
    dispatch({ type: Constants.FORM_REQUESTED });
    const { formData } = service.FormService;

    return new Promise((resolve, reject) => {
        service.save(formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const deleteProductAction = (service, dispatch) => key => {
    dispatch({ type: Constants.DELETE_PRODUCT, data: { key } });
};

export const deleteForecastAction = (service, dispatch) => key => {
    dispatch({ type: Constants.DELETE_FORECAST, data: { key } });
};
