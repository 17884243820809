import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Column, Row} from "Templates/Table/NewVersion";
import {Span} from "Templates/Default";
import {useSelector} from "react-redux";
import {weightUnitName} from "Services/weightUnitName";

const propTypes = {
    title: PropTypes.string.isRequired,
    marginListSelect: PropTypes.string.isRequired,
    isConnectionProjectType:PropTypes.bool.isRequired,
};

const ConnectionsTableHeadColumn = ({
  title,
  currentFY,
  nextFY,
  marginListSelect,
  isConnectionProjectType
}) => {
    const unit = useSelector(state => state.weightUnitState);

    return <Row>
        <Column colSpan={1} className="th first connection-head-td">
            <Span className="bold-text">{title}</Span>
        </Column>
        {isConnectionProjectType ? null : <Column className="connection-head-td">
            <Span className="graphs-v2__connections-head budget">Budget</Span>
            <Span className="graphs-v2__connections-subHead budget">FY {currentFY} {marginListSelect === '1' ? 'Revenue' : 'Volume'} ({marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)})</Span>
        </Column>}
        <Column className="connection-head-td">
            <Span className="graphs-v2__connections-head actuals">Actuals</Span>
            <Span className="graphs-v2__connections-subHead actuals">FYTD {marginListSelect === '1' ? 'Revenue' : 'Volume'} ({marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)})</Span>
        </Column>
        <Column className="connection-head-td">
            <Span className="graphs-v2__connections-head annualized">Annualized Actuals</Span>
            <Span className="graphs-v2__connections-subHead annualized">FY {currentFY} {marginListSelect === '1' ? 'Revenue' : 'Volume'} ({marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)})</Span>
        </Column>
        <Column className="connection-head-td">
            <Span className="graphs-v2__connections-head black current">Forecast FY {currentFY}</Span>
            <Span className="graphs-v2__connections-subHead current">FY {currentFY} {marginListSelect === '1' ? 'Revenue' : 'Volume'} ({marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)})</Span>
        </Column>
        <Column className="connection-head-td">
            <Span className="graphs-v2__connections-head black next">Forecast FY {nextFY}</Span>
            <Span className="graphs-v2__connections-subHead next">FY {nextFY} {marginListSelect === '1' ? 'Revenue' : 'Volume'} ({marginListSelect === '1' ? '$' : weightUnitName(unit.weightUnit)})</Span>
        </Column>
    </Row>
};

ConnectionsTableHeadColumn.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        title: getStoreItem(state, "title"),
        currentFY: getStoreItem(state, "financialYear"),
        nextFY: +getStoreItem(state, "financialYear") + 1,
        marginListSelect: getStoreItem(state, "old.marginListSelect"),
        isConnectionProjectType: getStoreItem(state, "isConnectionProjectType"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(ConnectionsTableHeadColumn);
