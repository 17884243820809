import {getActionStore, getStoreItem} from "./StoreService";
import {handleScroll} from "./EventsService";
import {
    addToPublicationList as _addToPublicationList,
    deleteItem,
    requestAttach,
    requestData,
    requestNewAddPublicationList,
    requestTable
} from "./RequestService";

const addToPublicationList = data => _addToPublicationList(`product/product-finder/publication-groups/add-publication`)(data);

export {
    requestAttach,
    deleteItem,
    requestTable,
    requestData,
    handleScroll,
    getStoreItem,
    getActionStore,
    addToPublicationList,
    requestNewAddPublicationList,
};
