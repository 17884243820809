import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas} from "Services";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps, PermissionsProps} from "Hoc/Template";
import {Title, WrapButtons, Total} from "Templates/Titles";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import {Modal} from "Templates/Modals";
import propertyService from "./Services/Tabs/Property";
import parameter1Service from "./Services/Tabs/Parameter1";
import parameter2Service from "./Services/Tabs/Parameter2";
import parameter3Service from "./Services/Tabs/Parameter3";
import value1Service from "./Services/Tabs/Value1";
import value2Service from "./Services/Tabs/Value2";
import TabView from "./Views/TabView";
import AddForm from "./AddForm";
import TabBlockBtn from "../../../../Templates/Content/TabBlockBtn";
import HelpPage from "./HelpPage";

const propTypes = {
    activeTab: PropTypes.number.isRequired,
    showModal: PropTypes.bool.isRequired,
    title: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
};

const labels = [
    {
        label: "Properties",
        button: "Property",
        type: 1,
        showFilterColumn: true,
        service: propertyService,
    },
    {
        label: "Parameters 1",
        button: "Parameter 1",
        type: 2,
        showFilterColumn: false,
        service: parameter1Service,
    },
    {
        label: "Parameters 2",
        button: "Parameter 2",
        type: 3,
        showFilterColumn: false,
        service: parameter2Service,
    },
    {
        label: "Parameters 3",
        button: "Parameter 3",
        type: 4,
        showFilterColumn: false,
        service: parameter3Service,
    },
    {
        label: "Values 1",
        button: "Value 1",
        type: 5,
        showFilterColumn: false,
        service: value1Service,
    },
    {
        label: "Values 2",
        button: "Value 2",
        type: 6,
        showFilterColumn: false,
        service: value2Service,
    }
];

const SolutionMaterialProperty = ({t, title, activeTab, onChangeTab, handleCloseModal, handleOpenModal, showModal}) => {
    const [showHelpModal, setShowHelpModal] = React.useState(false);
    const handleOpenHelpModal = () => {
        setShowHelpModal(!showHelpModal);
    }

    const tab = () => {
        return (
            <>
                {showModal && (
                    <Modal onRequestClose={handleCloseModal}>
                        <ServiceProvider value={labels[activeTab].service}>
                            <AddForm
                                button={labels[activeTab].button}
                                type={labels[activeTab].type}
                                showFilterColumn={labels[activeTab].showFilterColumn}
                                onClose={handleCloseModal}
                            />
                        </ServiceProvider>
                    </Modal>
                )}
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Properties")}>
                        <ServiceProvider value={propertyService}>
                            <TabView />
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Parameters 1")}>
                        <ServiceProvider value={parameter1Service}>
                            <TabView />
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Parameters 2")}>
                        <ServiceProvider value={parameter2Service}>
                            <TabView />
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Parameters 3")}>
                        <ServiceProvider value={parameter3Service}>
                            <TabView />
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Values 1")}>
                        <ServiceProvider value={value1Service}>
                            <TabView />
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Values 2")}>
                        <ServiceProvider value={value2Service}>
                            <TabView />
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </>
        );
    };

    return (
        <>
            <Title
                title={t(labels[activeTab].label)}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
                showHelpBtn
                handleHelpBtn={handleOpenHelpModal}
            >
            <WrapButtons>
                <TabBlockBtn onClick={handleOpenModal}>{t(`Add New ${labels[activeTab].button}`)}</TabBlockBtn>
            </WrapButtons>
            </Title>
            {tab()}

            {showHelpModal && (
                <Modal onRequestClose={handleOpenHelpModal}>
                    <HelpPage onClose={handleOpenHelpModal} />
                </Modal>
            )}
        </>
    );
};

SolutionMaterialProperty.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        title: getStoreItem(state, "pagination.total"),
        activeTab: getStoreItem(state, "activeTab"),
        showModal: getStoreItem(state, "showModal"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SolutionMaterialProperty);
