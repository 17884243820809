import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps, CheckPermission} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";

const TableBody = ({
                       items,
                       columns,
                       customIcon,
                       onClickEdit,
                       onClickDelete,
                       onClickCustom,
                       onClickItem,
                       TableRowColumn,
                       service: {tableButtonsPermission},
                   }) => {
    const TableRow = (column, item, index, k) => {
        return column === "-1" || column === "product_id" ? null
            : (
                <td className="main-table__body">
                    <TableRowColumn
                        item={item}
                        index={index}
                        secondaryIndex={k}
                        column={column}
                        customIcon={customIcon}
                        onClickCustom={onClickCustom}
                        onClickItem={onClickItem}
                        onClickDelete={onClickDelete}
                        onClickEdit={onClickEdit}
                    />
                </td>
            );
    };

    return (
        <>
            {Array.isArray(items) &&
                items.map((item, k) => (
                    <tr key={`TableRowColumn-${k}`}>
                        {columns.map((column, index) => (
                            <Fragment key={`TableRowColumn-${k}-${index}`}>
                                {column === "-1" ? (
                                    <CheckPermission permission={tableButtonsPermission}>
                                        {TableRow(column, item, index, k)}
                                    </CheckPermission>
                                ) : (
                                    <>{TableRow(column, item, index, k)}</>
                                )}
                            </Fragment>
                        ))}
                    </tr>
                ))}
        </>
    );
};

TableBody.defaultProps = {
    customIcon: "",
    onClickCustom: () => {
    },
    onClickEdit: () => {
    },
    onClickDelete: () => {
    },
    onClickItem: () => {
    },
    // TableRowColumn: () => null,
};

TableBody.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickItem: PropTypes.func,
    service: PropTypes.shape({
        tableButtonsPermission: PropTypes.string,
    }).isRequired,
    // TableRowColumn: PropTypes.element,
};
const mapStateToProps = (state, {service: {getStoreItem, modifierValues}}) => {
    return {
        items: modifierValues(getStoreItem(state, "items")),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBody);
