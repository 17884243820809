import React, {useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {formatDate, getString} from "Services";
import {withTagDefaultProps} from "Hoc/Template";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {Button, Span} from "Templates/Default";
import {Input, Label, Tooltip} from "Templates/Form";
import Img from "Templates/Img/Img";
import {Modal} from "Templates/Modals";
import VehicleModal from "../../Modal/VehicleModal";
import MyForecastModal from "../../Modal/MyForecastModal";
import _VehicleModalService from "../../../../Services/Modal/VehicleModal";
import _MyForecastModalService from "../../../../Services/Modal/MyForecastModal";

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
    service: PropTypes.shape({
        storeName: PropTypes.string.isRequired,
        columnsTop: PropTypes.arrayOf(PropTypes.string).isRequired,
        columnsBottom: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    onChangeForecast: PropTypes.func.isRequired,
    changeForecastDate: PropTypes.func.isRequired,
    deleteForecast: PropTypes.func.isRequired,
};

const ForecastsBlock = (
    {
        items,
        service: {storeName, columnsTop, columnsBottom},
        onChangeForecast,
        changeForecastDate,
        deleteForecast,
        t,
    }) => {

    const [showVehicleModal, setShowVehicleModal] = useState(false);
    const [showMyForecastModal, setShowMyForecastModal] = useState(false);

    const VehicleModalService = {
        ..._VehicleModalService(storeName),
    };

    const MyForecastModalService = {
        ..._MyForecastModalService(storeName),
    };

    const handleCloseModal = () => {
        setShowVehicleModal(false);
        setShowMyForecastModal(false);
    };

    const tableLabel = {
        manufacturer: "Manufacturer",
        platform: "Platform",
        program: "Program",
        nameplate: "Nameplate",
        vehicle_id: "Vehicle Mnemonic",
        region_mnemonic: "Region Mnemonic",
        plant_mnemonic: "Plant Mnemonic",
        unit: "Unit Forecast Name",
        sop: "SOP",
        eop: "EOP",
        dateMovedToCurrent: "Start Date",
        endDate: "End Date",
        units: "(%) of Units",
    };

    const getBodyView = (key, item, index) => {
        switch (key) {
            case "manufacturer": {
                return (
                    <>
                        <div className="title">
                            <Label label={getString(tableLabel, key)}/>
                        </div>
                        <Span
                            className={`items-item ${typeof item.isValid !== "undefined" && !item.isValid ? "clr-red" : ""}`}
                        >
                            {getString(item, key)}
                        </Span>
                    </>
                );
            }

            case "dateMovedToCurrent": {
                return (
                    <>
                        <div className="title text-right date-move-to-current-header">
                            <Label label={'Start Date'}/>
                            {/*<Tooltip>{t(`This is the start date of a sale for a running change OR the start date the sale transferred to a new customer in the same Fiscal Year.`)}</Tooltip>*/}
                        </div>
                        <Input
                            type="date-select"
                            name={`date-${index}`}
                            value={getString(item, key, '')}
                            placeholder="MM/DD/YYYY"
                            onChange={value => {
                                changeForecastDate(getString(item, 'id'), value, "dateMovedToCurrent", index);
                            }}
                        />
                    </>
                );
            }

            case "endDate": {
                return (
                    <>
                        <div className="title text-right date-move-to-current-header">
                            <Label label={'End Date'}/>
                            {/*<Tooltip>{t(`This is the start date of a sale for a running change OR the start date the sale transferred to a new customer in the same Fiscal Year.`)}</Tooltip>*/}
                        </div>
                        <Input
                            type="date-select"
                            name={`date-${index}`}
                            value={getString(item, key, '')}
                            placeholder="MM/DD/YYYY"
                            onChange={value => {
                                changeForecastDate(getString(item, 'id'), value, "endDate", index);
                            }}
                        />
                    </>
                );
            }

            case "units": {
                return (
                    <>
                        <div className="title text-right units-header">
                            <Label isRequired label={getString(tableLabel, key)}/>
                            <Tooltip>
                                {t(`Enter the percent of the units the product(s) will be sold into.
                                For example: Product A is being sold into 60% of Vehicle B with trim package C.`)}
                            </Tooltip>
                        </div>
                        <Input
                            className="main-text-right"
                            name={`units-${index}`}
                            value={getString(item, key)}
                            onChange={value => {
                                onChangeForecast(key, value, index);
                            }}
                        />
                    </>
                );
            }

            case "eop":
            case "sop": {
                return (
                    <>
                        <div className="title">
                            <Label label={getString(tableLabel, key)}/>
                        </div>
                        <Span className="items-item">{formatDate(getString(item, key))}</Span>
                    </>
                );
            }

            default: {
                return (
                    <>
                        <div className="title">
                            <Label label={getString(tableLabel, key)}/>
                        </div>
                        <Span className="items-item">{getString(item, key)}</Span>
                    </>
                );
            }
        }
    };

    return (
        <>
            {showVehicleModal ? (
                <Modal onRequestClose={handleCloseModal}>
                    <ServiceProvider value={VehicleModalService}>
                        <VehicleModal onClose={handleCloseModal}/>
                    </ServiceProvider>
                </Modal>
            ) : null}
            {showMyForecastModal ? (
                <Modal onRequestClose={handleCloseModal}>
                    <ServiceProvider value={MyForecastModalService}>
                        <MyForecastModal onClose={handleCloseModal}/>
                    </ServiceProvider>
                </Modal>
            ) : null}

            {items.map((item, itemIndex) => (
                <div className="items-block" key={`Item-top-${item.frontId}`}>
                    <div className="items-wrapper">
                        <div className="top-box">
                            {columnsTop.map((column, index) => (
                                <div
                                    key={index}
                                    className={`text-left col-${index}`}
                                >
                                    {getBodyView(column, item, itemIndex)}
                                </div>
                            ))}
                        </div>

                        <div className="bottom-box">
                            {columnsBottom.map((column, index) => (
                                <div
                                    key={index}
                                    className={`text-left col-${index}`}
                                >
                                    {getBodyView(column, item, itemIndex)}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="delete-box">
                        <button
                            type="button"
                            className="delete-button__form-block-link"
                            onClick={() => {
                                deleteForecast(itemIndex)
                            }}
                        >
                            <Img img="icon_delete"/>
                        </button>
                    </div>
                </div>
            ))}

            <div>
                <Button
                    className={`size_normal main-btn main-btn_white ${items.length && items[0].unit ? 'disabled' : ''}`}
                    onClick={() => {
                        setShowVehicleModal(true)
                    }}
                >
                    {t("Add IHS Automotive")}
                </Button>
                <Button
                    className={`size_normal main-btn main-btn_white ${items.length && !items[0].unit ? 'disabled' : ''}`}
                    onClick={() => {
                        setShowMyForecastModal(true)
                    }}
                >
                    {t("Add Unit Forecast")}
                </Button>

            </div>
        </>
    );
};

ForecastsBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        items: getStoreItem(state, itemsName),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeForecast: getActionStore("onChangeForecastAction", service, dispatch),
        changeForecastDate: getActionStore("changeForecastDateAction", service, dispatch),
        deleteForecast: getActionStore("deleteForecastAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ForecastsBlock);
