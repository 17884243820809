import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Production = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };
    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxHierarchy activeHierarchy={getPermission("plant_raw_view", index)} inputClass='first-hierarchy'>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_plant_raw_view-${index}`}
                        value={getPermission("plant_raw_view", index)}
                        onChange={value => onChangePermission("plant_raw_view", value, index)}
                    >
                        <CheckboxTitle title="Access - Plant/Raw Materials Module">
{/*                            <Tooltip>
                                {t(
                                    "Access user to use Plant/Raw Materials Module.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_plant_raw_crud-${index}`}
                        value={getPermission("plant_raw_crud", index)}
                        onChange={value => onChangePermission("plant_raw_crud", value, index)}
                    >
                        <CheckboxTitle title="Add/Edit/Delete - Plant/Raw Materials">
{/*                            <Tooltip>
                                {t(
                                    "Access user to Add/Edit/Delete  Plant/Raw Materials.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_plant_line_crud-${index}`}
                        value={getPermission("plant_line_crud", index)}
                        onChange={value => onChangePermission("plant_line_crud", value, index)}
                    >
                        <CheckboxTitle title="Add/Edit/Delete - Product Line">
{/*                            <Tooltip>
                                {t(
                                    "Access user to Add/Edit/Delete Product Line in the Plants module.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
        </>
    )
}

Production.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Production);