import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {sidebarOptions} from "../Config";
import DropdownMenu from "./DropdownMenu";
import ItemMenu from "./ItemMenu";
import {CheckPermission} from "Hoc/Template";
import MultylevelMenu from "./MultylevelMenu";

const defaultProps = {
    counter: 0,
};

const propTypes = {
    counter: PropTypes.number,
    map: PropTypes.objectOf(PropTypes.any).isRequired
}

const RenderItem = ({ menu, counter, item }) => {
    if (item.childrens && (item.childrens.multilevelMenu || item.childrens.solutionMultilevelMenu)) {
        return (<MultylevelMenu key={menu} counter={counter} label={menu} map={item} />);
    } else if (item.childrens) {
        return (<DropdownMenu key={menu} counter={counter} label={menu} map={item} />);
    }

    return (<ItemMenu key={menu} label={menu} map={item} />);
};

const SidebarContent = ({ counter, map }) => {
    return (
        <>
            {Object.keys(map).map((menu, index) => {
                map[menu].options = {
                    ...sidebarOptions,
                    ...map.options,
                };

                let permissionList = map[menu].permission || [];
                if(map[menu].permission && !_.isArray(map[menu].permission)) {
                    permissionList = [map[menu].permission];
                }

                return (
                    <Fragment key={index}>
                        <CheckPermission permissionList={permissionList}>
                            <RenderItem menu={menu} counter={counter + 1} item={map[menu]} />
                        </CheckPermission>
                    </Fragment>
                );
            })}
        </>
    );
};

SidebarContent.defaultProps = defaultProps;
SidebarContent.propTypes = propTypes;

export default SidebarContent;