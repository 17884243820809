import {
    onChange,
    submitFormAction,
    submitBudgetFormAction,
    changeDataAction,
    addYearAction,
    removeYearAction,
    changeYearAction
} from "./FormActions";

export {
    onChange,
    submitFormAction,
    submitBudgetFormAction,
    changeDataAction,
    addYearAction,
    removeYearAction,
    changeYearAction
};
