import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Title} from "Templates/Titles";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {Block} from "Templates/Form";
import {Div} from "Templates/Default";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import ShowPermission from "Hoc/Template/ShowPermission";
import {request, url} from "Services";
import Table from "./Views/Tabs/Templates/Table/Table";

import _graphService from "./Services/Tabs/GraphService";
import _productCodeService from "./Services/Tabs/ProductCodeService";
import ProductCodeFormBlock from "./Views/Tabs/ProductCode/FormBlock";
import DashboardCard from "../DashboardTypeThree/Views/Card/Widget/DashboardCard";
import ChartHeadDashboardCard from "./Views/Tabs/Graph/HeadDashboardCard";
import ChartDataDashboardCard from "./Views/Tabs/Graph/DataDashboardCard";
import Constants from "./Constants";
import {filterListLinks} from "./Constants/graph-v2-types";

const defaultProps = {
    help: "",
    setTitle: () => {}
};

const ChartCard = DashboardCard({
    HeadDashboardCard: ChartHeadDashboardCard,
    DataDashboardCard: ChartDataDashboardCard,
});

const propTypes = {
    help: PropTypes.string,
    productsCodes: PropTypes.arrayOf(PropTypes.any).isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    onChangeConnectionType: PropTypes.func.isRequired,
    onSetConnectionProjectType: PropTypes.func.isRequired,
    onChangeActualsType: PropTypes.func.isRequired,
    setProductCodesList: PropTypes.func.isRequired,
    setTitle: PropTypes.func,
};

const AnalyticGraphsV2View = ({ activeTab, productsCodes, onChangeTab, onChangeConnectionType, onSetConnectionProjectType, onChangeActualsType, setProductCodesList, t, help, setTitle }) => {
    const graphService = _graphService();
    const productCodeService = _productCodeService();
    const [productCodeParams, setProductCodeParams] = useState(null)
    const [isApply, setIsApply] = useState(false)

    const getMainWrapByRoute = () => {
        if (typeof window !== 'undefined') {
            if (window.location.pathname.includes('/forecasts/graphs-v2') && window.location.search.includes('?activeTab=1')) {
                const mainWrap = document.querySelector('.main-wrap');
                if (mainWrap && mainWrap.classList.contains('graphs-v2')) {
                    mainWrap.classList.remove('graphs-v2')
                }
            } else {
                const mainWrap = document.querySelector('.main-wrap');

                if (mainWrap) {
                    mainWrap.classList.add('graphs-v2')
                }
            }
        }
    }

    const getQueryParams = (href) => {
        const queryString = href.split('?')[1];

        if (queryString !== undefined) {
            const queryParams = queryString.split('&');

            const paramsObj = {};

            queryParams.forEach((_, i) => {
                const pair = queryParams[i].split('=');
                const key = decodeURIComponent(pair[0]);
                if (key === 'activeTab') return
                paramsObj[key] = decodeURIComponent(pair[1]);
            })

            return paramsObj.items ? {
                ...paramsObj,
                statusProject: paramsObj.statusProject ? paramsObj.statusProject.split(',').map((item) => item) : [],
                items: paramsObj.items ? paramsObj.items.split(',').map((item) => item) : []
            } : paramsObj;
        } else {
            return {};
        }
    }

    const compareArrays = (array1, array2) => {
        for (let i = 0; i < array1.length; i++) {
            if (!array2.includes(array1[i])) {
                return false;
            }
        }

        for (let i = 0; i < array2.length; i++) {
            if (!array1.includes(array2[i])) {
                return false;
            }
        }

        return true;
    }

    const getProductCodeData = (applied) => {
        if (typeof window !== 'undefined') {
            const paramsObject = getQueryParams(window.location.href)

            if (paramsObject.type === 'TYPE_10') {
                onChangeConnectionType(true)
                onSetConnectionProjectType(false)
                onChangeActualsType(false)
            } else if (paramsObject.type === 'TYPE_12') {
                onChangeConnectionType(true)
                onSetConnectionProjectType(true)
                onChangeActualsType(false)
            } else if (paramsObject.type === 'TYPE_13') {
                onChangeConnectionType(false)
                onSetConnectionProjectType(false)
                onChangeActualsType(true)
            } else {
                onChangeConnectionType(false)
                onSetConnectionProjectType(false)
                onChangeActualsType(false)
            }

            const listUrl = paramsObject && paramsObject.type ?  filterListLinks[paramsObject.type].value : '/project/projects/product-code-list'

            setTitle(paramsObject && paramsObject.type ?  filterListLinks[paramsObject.type].label : 'Product Code')

            const sendObj = {
                url: url.getUrl(listUrl),
                type: "GET",
            };

            request.sendRequestWithNoCacheAndNoLoading(sendObj).then((res) => {
                setProductCodesList(res[0].items.map(item => item))

                if (Object.keys(paramsObject).length) {
                    if (!compareArrays(Object.keys(paramsObject), Constants.requiredQueryParams)) {
                        const urlObject = new URL(window.location.href);
                        const newUrl = activeTab === 0 ? `${urlObject.origin}${urlObject.pathname}` : `${urlObject.origin}${urlObject.pathname}?activeTab=1`
                        history.pushState({}, '', newUrl)

                        setIsApply(applied)
                        setProductCodeParams(null)

                        return
                    }

                    let statusProject = [];
                    let items = [];

                    if (paramsObject.statusProject instanceof Array) {
                        statusProject = paramsObject.statusProject;
                    } else if (paramsObject.statusProject && typeof paramsObject.statusProject === 'string') {
                        statusProject = paramsObject.statusProject.split(',').map((item) => item);
                    }

                    if (paramsObject.items instanceof Array) {
                        items = paramsObject.items;
                    } else if (paramsObject.items && typeof paramsObject.items === 'string') {
                        items = paramsObject.items.split(',').map((item) => item);
                    }

                    setIsApply(applied)
                    setProductCodeParams(paramsObject ? {
                        ...paramsObject,
                        statusProject: statusProject ? statusProject : [],
                        items: items ? items : []
                    } : null)
                }
            });
        }
    }

    useEffect(() => {
        getMainWrapByRoute()

        if (activeTab === 0) {
            getProductCodeData(false)
        } else {
            if (typeof window !== 'undefined') {
                const urlObject = new URL(window.location.href);
                const newUrl = `${urlObject.origin}${urlObject.pathname}?activeTab=1`
                history.pushState({}, '', newUrl)
            }

            setProductCodeParams(null)
        }
    }, [activeTab])

    const handleChangeTab = index => {
        if (typeof window !== 'undefined') {
            const urlObject = new URL(window.location.href);
            const newUrl = index === 0 ? `${urlObject.origin}${urlObject.pathname}` : `${urlObject.origin}${urlObject.pathname}?activeTab=1`
            history.pushState({}, '', newUrl)
        }
        setProductCodeParams(null)
        onChangeTab(index);
    };

    return (
    <ShowPermission checkPermission={['graphs_v2']}>
        <ContentWrap>
            <ContentWrapCol>
                <Title
                    title={t("Forecast (FY)")}
                />
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            handleChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Tables")}>
                        <ServiceProvider value={productCodeService}>
                            <Div className="main-wrap-block__body">
                                <div className="analytics-graphs-v2-view__filters-wrap">
                                    <ProductCodeFormBlock
                                        productsCodes={productsCodes}
                                        productCodeParams={productCodeParams}
                                        activeTab={activeTab} isApply={isApply}
                                        onApply={() => getProductCodeData(true)}
                                        resetConnections={() => onChangeConnectionType(false)}
                                        resetActuals={() => onChangeActualsType(false)}
                                    />
                                </div>
                                <Table />
                            </Div>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Stacked Graphs")}>
                        <Div className="main-wrap-block__body chart-v2-wrapper">
                            <Block>
                                <ServiceProvider value={graphService}>
                                    <ChartCard />
                                </ServiceProvider>
                            </Block>
                        </Div>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    </ShowPermission>
    );
};

AnalyticGraphsV2View.defaultProps = defaultProps;
AnalyticGraphsV2View.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        activeTab: getStoreItem(state, "activeTab"),
        productsCodes: getStoreItem(state, "productsCodes"),
        help: getStoreItem(state, "help"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        setTitle: getActionStore("setTitle", service, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        onChangeConnectionType: getActionStore("onChangeConnectionType", service, dispatch),
        onSetConnectionProjectType: getActionStore("onSetConnectionProjectType", service, dispatch),
        onChangeActualsType: getActionStore("onChangeActualsType", service, dispatch),
        setProductCodesList: getActionStore("onGetProductCodesList", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AnalyticGraphsV2View);
