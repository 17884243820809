import React from "react";
// import PropTypes from 'prop-types'
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {};

const CardList = ({ pref, children }) => {
    return <ul className={`${pref}__card-list`}>{children}</ul>;
};

CardList.propTypes = propTypes;

export default compose(withTagDefaultProps())(CardList);
