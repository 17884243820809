const Constants = {
    CHANGE_FILTER: "CHANGE_FILTER",
    TABLE_SORT: "TABLE_SORT",
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    FETCH_ITEM_REQUESTED: "FETCH_ITEM_REQUESTED",
    FETCH_ITEM_SUCCEEDED: "FETCH_ITEM_SUCCEEDED",
    FETCH_ITEM_FAILED: "FETCH_ITEM_FAILED",

    EDIT_COST_REQUESTED: "EDIT_COST_REQUESTED",
    EDIT_COST_SUCCEEDED: "EDIT_COST_SUCCEEDED",
    EDIT_COST_FAILED: "EDIT_COST_FAILED",

    EDIT_COST: "EDIT_COST",
    RESET_FORM: "RESET_FORM",
};
export default Constants;
