import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import PermissionsProps from "Hoc/Template/PermissionsProps";
import NotAvailableMessage from "Templates/Permission/message";
import _ from "lodash";

const defaultProps = {
    permissions: [],
    checkPermission: [],
    productOnly: false,
};

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string),
    checkPermission: PropTypes.array,
    productOnly: PropTypes.bool,
};

const ShowPermission = ({ permissions, checkPermission = [], children, productOnly }) => {

    if(!checkPermission.length){
        return <NotAvailableMessage />;
    }

    return <>{_.intersection(checkPermission, permissions).length ? children : <NotAvailableMessage />}</>;
};

ShowPermission.defaultProps = defaultProps;
ShowPermission.propTypes = propTypes;

export default compose(
    PermissionsProps,
)(ShowPermission);
