import {formDataNormalizer, request, url} from "Services";

export const requestStore = attachUrl => data => {
    const sendObj = {
        url: url.getUrl(attachUrl),
        data: formDataNormalizer(data),
        type: "POST",
        contentType: false,
        processData: false,
    };

    return request.sendRequest(sendObj);
};

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                help: res[0].help,
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
        });
    });
};
