import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import Card from "../Card/Card";
import MasonryList from "../MasonryList";
import MasonryColumn from "../MasonryColumn";

const propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.object).isRequired,
            pagination: PropTypes.objectOf(PropTypes.any).isRequired,
            status: PropTypes.number.isRequired,
        }),
    ).isRequired,
};

const MainBlock = ({ items }) => {
    return (
        <MasonryList>
            {items.map(elem => (
                <MasonryColumn key={`MasonryColumn-${elem.status}`} status={elem.status} pagination={elem.pagination}>
                    {elem.items.map((elem, index) => (
                        <Card key={`Card-${index}`} item={elem} />
                    ))}
                </MasonryColumn>
            ))}
        </MasonryList>
    );
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return { items: getStoreItem(state, "items") };
};

MainBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(MainBlock);
