import React from "react";
import { compose } from "redux";
import { useDispatch } from 'react-redux';

import PropTypes from "prop-types";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import { withTagDefaultProps } from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import { url } from "Services";
import Tab from "Templates/Tabs/Tab";
import TabCheck from "Templates/Tabs/TabСheck";
import InfoWrap from "./Views/InfoWrap";

import _filesLinesService from "./Services/Tabs/Files";

import _productGradesService from "./Services/Tabs/ProductGrades";
import ProductGrades from "./Views/Tabs/ProductGrades";

import _projectGradesService from "./Services/Tabs/Projects";
import Projects from "./Views/Tabs/Projects";

import _analyticsGradesService from "./Services/Tabs/Analytics";
import { activeWeightUnit } from '../../../../../App/View/Header/Items/Units/Actions/ConverterUnitsAction';
import Analytics from "./Views/Tabs/Analytics";
import Files from "./Views/Tabs/Files";

const Products = ({ id, activeTab, t, onChangeTab }) => {
    const dispatch = useDispatch();
    const onClickEdit = () => {
        url.redirect(`/admin/list/products/${id}/edit`);
    };

    const productGradesService = _productGradesService(id);
    const projectGradesService = _projectGradesService(id);
    const analyticsGradesService = _analyticsGradesService(id);
    const filesLinesService = _filesLinesService(id);

    dispatch(activeWeightUnit([1,3].includes(activeTab)));

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit} />
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index) => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Grades")}>
                        <ServiceProvider value={productGradesService}>
                            <ProductGrades />
                        </ServiceProvider>
                    </Tab>

                    <TabCheck label={t("Projects")} permission={'product_project_view'}>
                        <ServiceProvider value={projectGradesService}>
                            <Projects />
                        </ServiceProvider>
                    </TabCheck>

                    <Tab label={t("Files")}>
                        <ServiceProvider value={filesLinesService}>
                            <Files />
                        </ServiceProvider>
                    </Tab>

                    <TabCheck label={t("Analytics")} permission={'revenue_gross_operating_view'}>
                        <ServiceProvider value={analyticsGradesService}>
                            <Analytics />
                        </ServiceProvider>
                    </TabCheck>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Products.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Products);
