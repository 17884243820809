import _ from "lodash";
import React, {Component} from 'react';
import {compose} from "redux";
import Img from "Templates/Img/Img";
import {withServiceConsumer} from "Services/Context";
import {MainButtonLink} from "Templates/Content";
import {Modal} from "Templates/Modals";
import {InputFilter} from "Templates/Table/Filters";
import {formatDate, storageUrl, url} from "Services";
import PropTypes from "prop-types";
import TableBodyButton from "Templates/Table/TableBodyButton";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {Block, Label} from "Templates/Form";
import PdfViewer from "./PdfViewer";
import NotFoundDatasheet from "./NotFoundBrochure";
import CopyPublicationForm from "../Technical/CopyPublicationForm";
import ProductTable from "../ProductTable";
import ShowMoreSwitch from "../ShowMoreSwitch";
import Approve from "../Approve";
import AssignToProductForm from "../../Shared/SapProductsColorsModals/AssignToProductForm";
import EditProductForm from "../../Shared/SapProductsColorsModals/EditProductForm";
import {ViewNotesTable} from "../../Modals/ViewNotesTable";
import {ViewHistoryTable} from "../../Modals/ViewHistoryTable";
import {PUBLICATIONS_TABS, TABS} from "../../../Constants";

const defaultProps = {
    total: 0,
    subTabs: [],
    activeSubTab: {}
}
const propTypes = {
    id: PropTypes.string.isRequired,
    fetchSubTabAction: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
    saveAgreements: PropTypes.func.isRequired,
    activeSubTab: PropTypes.shape({id: null, path: null}),
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    handleCloseEditModal: PropTypes.func.isRequired,
    handleOpenEditModal: PropTypes.func.isRequired,
    showModalCopyPublication: PropTypes.bool.isRequired,
    handleCloseCopyPublicationModal: PropTypes.func.isRequired,
    handleOpenCopyPublicationModal: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    editProductForm: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func,
        onClickDeleteFromProductItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func,
        getActionStore: PropTypes.func,
    }).isRequired,
    total: PropTypes.number,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetchSubTabs: PropTypes.func.isRequired,
    subTabs: PropTypes.arrayOf(PropTypes.object),
    activeTab: PropTypes.number.isRequired,
    getHistoryItems: PropTypes.func.isRequired,
    getNotesItems: PropTypes.func.isRequired,
    rollbackPublicationHistory: PropTypes.func.isRequired,
};

class Brochure extends Component {
    constructor(props) {
        super(props);
        this.defaultState = {
            publicationTabActive: PUBLICATIONS_TABS.VIEW,
            showUploadFileModal: false,
            urlSubTab: '',
            uploadFileForm: {
                files: [],
                name: '',
            },
        };

        this.state = {
            publicationTabActive: PUBLICATIONS_TABS.VIEW,
            showUploadFileModal: false,
            urlSubTab: '',
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        };
    }

    componentDidMount() {
        const {fetchSubTabs, fetchSubTabAction} = this.props;

        fetchSubTabs({}).then(() => {
            const subTabId = url.getQueryParam('subTab');
            this.setState({
                urlSubTab: subTabId
            })
            if (subTabId) {
                fetchSubTabAction(subTabId);
            }
        });
    }

    onClickApprove = (item, type, status) => () => {
        const {t} = this.props;
        const question = `${status ? 'Approve' : 'Disapprove'}`
            + ` selected Brochure for ${type === 1 ? 'Publication' : 'Sharing'}?`;

        if (window.confirm(t(question))) {
            const {
                fetchSubTabs,
                saveAgreements,
            } = this.props;
            const agreements = {
                1: {type: 1, status: 0},
                2: {type: 2, status: 0},
            };

            for (const a of Object.values(item.agreements)) {
                agreements[a.type] = {type: a.type, status: 10};
            }

            agreements[type] = {type, status: status ? 10 : 0};

            saveAgreements(item.id)({agreements: Object.values(agreements)}).then(
                () => fetchSubTabs({})
            );
        }
    };

    onClickDeleteBrochureFromProduct = item => {
        const {permissions} = this.props;

        if (!item.can_edit || !(permissions && permissions.includes("product_publications_edit"))) {
            alert('Contact an Admin to delete.');
            return;
        }

        if (!item.can_edit) {
            alert('Contact an Admin to delete.');
            return;
        }

        const {service: {onClickDeleteFromProductItem}, t, fetchSubTabs} = this.props;

        if (window.confirm(t("Do you want to remove the Brochure from Product?"))) {
            onClickDeleteFromProductItem(item.grade_id).then(
                () => {
                    fetchSubTabs();
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    onClickEdit = item => {
        const {
            handleOpenEditModal,
            permissions
        } = this.props;

        if (!(permissions && permissions.includes("product_publications_edit"))) {
            alert('Contact an Admin to edit.');
            return;
        }

        handleOpenEditModal(item)
    }

    handleCloseUploadModalWithCheck = event => {
        event.preventDefault();

        const {uploadFileForm: {files}} = this.state;
        const {t} = this.props;

        if (files.length >= 1) {
            if (!window.confirm(t("Are you sure you want to undo the action?"))) {
                return;
            }
        }

        this.closeModal();
    };

    onClickDelete = (item) => () => {
        const {t} = this.props;

        if (window.confirm(t("Do you want to remove the Brochure?"))) {
            const {
                fetchSubTabs,
                service: {onClickDeleteItem},
            } = this.props;

            onClickDeleteItem(item).then(() => {
                fetchSubTabs({});
            });
        }
    };

    openUploadFileModal = () => {
        this.setState({
            showUploadFileModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            ...this.defaultState,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        });
    };

    onChangeForm = (key, value) => {
        const {form, callback} = this.props;

        callback(key, value, {
            ...form,
            [key]: value || []
        });
    }

    changeTab = (id) => {
        this.setState({
            ...this.defaultState,
            publicationTabActive: id
        })
    }

    render() {
        const {
            id,
            t,
            subTabs,
            fetchSubTabAction,
            activeSubTab,
            activeTab,
            storage_url,
            showModal,
            showEditModal,
            handleCloseModal,
            handleOpenModal,
            handleOpenEditModal,
            handleCloseEditModal,
            showModalCopyPublication,
            handleOpenCopyPublicationModal,
            handleCloseCopyPublicationModal,
            total,
            form,
            editProductForm,
            permissions,
            getHistoryItems,
            getNotesItems,
            rollbackPublicationHistory,
            fetchSubTabs
        } = this.props;
        const brochureData = _.get(activeSubTab, 'items_data');
        const agreements = _.get(activeSubTab, 'agreements', []);
        const isApproveForPublication = !!_.find(agreements, {type: 1});
        const isApproveForSharing = !!_.find(agreements, {type: 2});
        const isApproveForLists = !!_.find(agreements, {type: 3});

        const showMore = agreements.length !== 0 && permissions.includes("product_publications_view");

        const canEditApprovedBrochure = permissions.includes("product_publications_approve")
            || (!isApproveForPublication && !isApproveForSharing);

        return (
            <>
                {showModal && (
                    <Modal onRequestClose={handleCloseModal}>
                        <AssignToProductForm
                            tabName={'brochures'}
                            callback={this.onChangeForm}
                            datasheetId={activeSubTab.id}
                            form={form}
                            onClose={handleCloseModal}
                        />
                    </Modal>
                )}
                {showEditModal && (
                    <Modal onRequestClose={handleCloseEditModal}>
                        <EditProductForm
                            datasheetId={activeSubTab.id}
                            editProductForm={editProductForm}
                            onClose={handleCloseEditModal}
                        />
                    </Modal>
                )}
                {showModalCopyPublication && (
                    <Modal onRequestClose={handleCloseCopyPublicationModal}>
                        <CopyPublicationForm
                            callback={this.onChangeForm}
                            datasheetId={activeSubTab.id}
                            form={form}
                            onClose={handleCloseCopyPublicationModal}
                        />
                    </Modal>
                )}

                <div className={'publications-head-wrap'}>
                    {subTabs && subTabs.length > 0 && (
                        <div className={'flex'}>
                            <Label label="Select"/>
                            <span className={'publications-counter flex'}>({subTabs.length})</span>
                        </div>
                    )}
                    <div className="tds-list-wrap tds-list-wrap__publications">
                        {(subTabs && subTabs.length) ? (
                            <InputFilter
                                type="select"
                                value={activeSubTab.id}
                                options={[...subTabs.map((val) => {
                                    return {value: val.id, label: `${val.custom_name} - ${val.name}`}
                                })]}
                                onChange={subTabId => {
                                    fetchSubTabAction(subTabId);
                                    this.setState({
                                        urlSubTab: subTabId
                                    })
                                    url.addHistory({'subTab': subTabId, 'activeTab': activeTab});
                                }}
                            />
                        ) : <div/>}
                        <div className="d-flex align-center">
                            {brochureData ? (
                                    <CheckPermission permission="product_publications_approve">
                                        {!isApproveForPublication && (
                                            <MainButtonLink
                                                className="main-btn_white"
                                                onClick={this.onClickApprove(activeSubTab, 1, true)}
                                            >
                                                {t("Approve for SAP")}
                                            </MainButtonLink>
                                        )}
                                        {isApproveForPublication && (
                                            <MainButtonLink
                                                className="main-btn_primary"
                                                onClick={this.onClickApprove(activeSubTab, 1, false)}
                                            >
                                                {t("Disapprove for SAP")}
                                            </MainButtonLink>
                                        )}
                                        {!isApproveForSharing && (
                                            <MainButtonLink
                                                className="main-btn_white"
                                                onClick={this.onClickApprove(activeSubTab, 2, true)}
                                            >
                                                {t("Approve for Shared Products")}
                                            </MainButtonLink>
                                        )}
                                        {isApproveForSharing && (
                                            <MainButtonLink
                                                className="main-btn_primary"
                                                onClick={this.onClickApprove(activeSubTab, 2, false)}
                                            >
                                                {t("Disapprove for Shared Products")}
                                            </MainButtonLink>
                                        )}
                                        {!isApproveForLists && (
                                            <MainButtonLink className="main-btn_white"
                                                            onClick={this.onClickApprove(activeSubTab, 3, true)}>
                                                {t("Approve for Lists")}
                                            </MainButtonLink>
                                        )}
                                        {isApproveForLists && (
                                            <MainButtonLink className="main-btn_primary"
                                                            onClick={this.onClickApprove(activeSubTab, 3, false)}>
                                                {t("Disapprove for Lists")}
                                            </MainButtonLink>
                                        )}
                                    </CheckPermission>
                                ) :
                                null}
                            <CheckPermission permission="product_publications_edit">
                                <MainButtonLink
                                    href={`/admin/publications/${id}/brochure/create`}
                                    className="main-btn_primary"
                                >
                                    {t("Add New Brochure")}
                                </MainButtonLink>
                            </CheckPermission>
                        </div>
                    </div>

                    {brochureData && <div className={`publications-actions-wrap`}>
                        <div>
                            {showMore && (
                                <ShowMoreSwitch isHidden/>
                            )}
                        </div>
                        <div className={'publications-actions'}>
                            {(activeSubTab && activeSubTab.id) && (
                                <CheckPermission permission="product_publications_edit">
                                    <button
                                        type="button"
                                        className="main-circle"
                                        onClick={handleOpenCopyPublicationModal}
                                    >
                                        <Img img="icon_copy" className="copy-icon"/>
                                    </button>
                                    {(!isApproveForPublication || permissions.includes("product_publications_approve")) && (
                                        <a
                                            type="button"
                                            className="main-circle"
                                            href={`/admin/publications/${id}/brochure/${activeSubTab.id}/edit`}
                                        >
                                            <Img img="icon_edit"/>
                                        </a>
                                    )}
                                    {canEditApprovedBrochure && (
                                        <TableBodyButton onClick={this.onClickDelete(activeSubTab)}>
                                            <Img img="icon_delete"/>
                                        </TableBodyButton>
                                    )}
                                </CheckPermission>
                            )}
                        </div>
                    </div>}

                    {brochureData ? (
                        <div className={`${showMore ? 'show-switch' : null}`}>
                            <Block>
                                <div className="approved-items-wrap">
                                    {agreements.map((agreement) => (
                                        <Approve
                                            key={`approved_${agreement.type}`}
                                            approver={agreement.user_name}
                                            approverEmail={agreement.user_email}
                                            approveDate={formatDate(agreement.date)}
                                            approveType={agreement.type}
                                        />
                                    ))}
                                </div>
                            </Block>


                        </div>
                    ) : null}
                </div>

                <div className="publications-sub-tabs">
                    <div className="publications-sub-tabs__list-wrap">
                        <ul className={'publications-sub-tabs__list'}>
                            {TABS.map((tab) => {
                                return (
                                    <li
                                        key={tab.id}
                                        className={`${this.state.publicationTabActive === tab.id ? 'active' : ''} ${!brochureData ? 'disabled' : ''} tab-switcher`}
                                        onClick={() => {
                                            this.changeTab(tab.id)
                                        }}
                                    >
                                        {t(tab.title)}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>

                    {this.state.publicationTabActive === PUBLICATIONS_TABS.VIEW && <div className={'tab-view'}>
                        {subTabs && subTabs.length > 0 ? (
                            <PdfViewer
                                url={activeSubTab && activeSubTab.path ? storageUrl(activeSubTab.path, storage_url) : ''}
                            />
                        ) : (
                            <div className="grade-view__wrap main-wrap-block technical-wrap not-available">
                                <NotFoundDatasheet/>
                            </div>
                        )}
                    </div>}

                    {this.state.publicationTabActive === PUBLICATIONS_TABS.SAP_PRODUCTS && <div className={'tab-view'}>
                        {isApproveForPublication ? (
                            <ProductTable
                                onClickEdit={this.onClickEdit}
                                onClickDelete={this.onClickDeleteBrochureFromProduct}
                                total={total}
                                handleOpenModal={handleOpenModal}
                            />
                        ) : <div className={`grade-view__wrap main-wrap-block technical-wrap not-available`}>
                            <div className="sheet-notInfo text-center">
                                <Img
                                    img="icon_files"
                                    width="100"
                                    height="100"
                                    alt="file"
                                    className=""
                                />
                                <p className="text-center">
                                    To display table please Approve for SAP Products.
                                </p>
                            </div>
                        </div>}
                    </div>}

                    {this.state.publicationTabActive === PUBLICATIONS_TABS.HISTORY && <div className={'tab-view'}>
                        {brochureData ? <>
                            <ViewHistoryTable urlSubTab={this.state.urlSubTab}
                                              emptyText={'There is no history for current Brochure.'}
                                              onLoadHistoryData={getHistoryItems}
                                              rollbackHistory={rollbackPublicationHistory} fetchSubTabs={fetchSubTabs}/>
                        </> : null}
                    </div>}

                    {this.state.publicationTabActive === PUBLICATIONS_TABS.NOTES && <div className={'tab-view'}>
                        {brochureData ? <>
                            <ViewNotesTable urlSubTab={this.state.urlSubTab}
                                            emptyText={'There are no notes for current Brochure.'}
                                            onLoadNotesData={getNotesItems}/>
                        </> : null}
                    </div>}
                </div>
            </>
        );
    }
}

Brochure.defaultProps = defaultProps;
Brochure.propTypes = propTypes;

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchSubTabAction: getActionStore("setActiveSubTabAction", service, dispatch),
        fetchSubTabs: getActionStore("getSubTabs", service, dispatch),
        fetchItems: getActionStore("fetchItems", service, dispatch),
        addFile: getActionStore("createFilesAction", service, dispatch),
        saveAgreements: getActionStore("saveAgreementsAction", service, dispatch),
        handleCloseModal: getActionStore("handleCloseModal", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
        handleOpenEditModal: getActionStore("handleOpenEditModal", service, dispatch),
        handleCloseEditModal: getActionStore("handleCloseEditModal", service, dispatch),
        handleCloseCopyPublicationModal: getActionStore("handleCloseCopyPublicationModal", service, dispatch),
        handleOpenCopyPublicationModal: getActionStore("handleOpenCopyPublicationModal", service, dispatch),
        callback: getActionStore("onChangeForm", service, dispatch),
    };
};

const mapStateToProps = (state, {service: {getStoreItem, getNotesData, getHistoryData, rollbackHistory}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeSubTab: getStoreItem(state, "activeSubTab"),
        subTabs: getStoreItem(state, "subTabs"),
        activeTab: getStoreItem(state, "activeTab"),
        showModal: getStoreItem(state, "showModal"),
        showEditModal: getStoreItem(state, "showEditModal"),
        showModalCopyPublication: getStoreItem(state, "showModalCopyPublication"),
        form: {...getStoreItem(state, "form", true)},
        editProductForm: getStoreItem(state, "editProductForm"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
        getHistoryItems: getHistoryData,
        getNotesItems: getNotesData,
        rollbackPublicationHistory: rollbackHistory,
        total: getStoreItem(state, "pagination.total"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Brochure);