import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";
import { formatDate } from "Services";

const propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    selling_plants: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            plant: PropTypes.string.isRequired,
        })
    ).isRequired,
    iso_expiration_date: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    vendor_code: PropTypes.string.isRequired,
    distributor: PropTypes.string.isRequired,
    distributor_vendor_code: PropTypes.string.isRequired,
    material_type: PropTypes.string.isRequired,
    lead_time: PropTypes.string.isRequired,
    standard_packaging: PropTypes.string.isRequired,
    delivery_terms: PropTypes.string.isRequired,
    freight_class: PropTypes.string.isRequired,
    date_update: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const InfoWrap = (
    {
        name,
        description,
        selling_plants,
        iso_expiration_date,
        manufacturer,
        vendor_code,
        distributor,
        distributor_vendor_code,
        material_type,
        lead_time,
        standard_packaging,
        delivery_terms,
        freight_class,
        date_update,
        t,
        onClickEdit,
        permissions
    }) => {

    return (
        <ContentInfoWrap>
            <ContentInfoTop
                onClickEdit={permissions.includes("plant_raw_crud") ? onClickEdit : null}
                urlBack="/product/raw"
            />

            <ContentInfoBlock>
                <ContentInfoTitle>{name}</ContentInfoTitle>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!description}>
                <ContentInfoBlockTitle>{t("SAP Material Name")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={description}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!selling_plants.length}>
                <ContentInfoBlockTitle>{t("Selling Plant")}</ContentInfoBlockTitle>
                {selling_plants.map((plant, index) => (
                    <ContentInfoBlockText type="selling_plant" value={plant.plant} key={`selling_plant_${index}`}/>
                ))}
            </ContentInfoBlock>

            <ContentInfoBlock show={!!iso_expiration_date}>
                <ContentInfoBlockTitle>{t("ISO Certificate Expiration Date")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(iso_expiration_date)}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!manufacturer}>
                <ContentInfoBlockTitle>{t("Manufacturer")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={manufacturer}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!vendor_code}>
                <ContentInfoBlockTitle>{t("Vendor Code")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={vendor_code}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!distributor}>
                <ContentInfoBlockTitle>{t("Distributor")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={distributor}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!distributor_vendor_code}>
                <ContentInfoBlockTitle>{t("Distributor Vendor Code")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={distributor_vendor_code}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!material_type}>
                <ContentInfoBlockTitle>{t("Material Type")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={material_type}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!lead_time}>
                <ContentInfoBlockTitle>{t("Lead Time")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={lead_time}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!standard_packaging}>
                <ContentInfoBlockTitle>{t("Standard Packaging")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={standard_packaging}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!delivery_terms}>
                <ContentInfoBlockTitle>{t("Delivery Terms")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={delivery_terms}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!freight_class}>
                <ContentInfoBlockTitle>{t("Freight Class")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={freight_class}/>
            </ContentInfoBlock>

            <ContentInfoBlock show={!!date_update}>
                <ContentInfoBlockTitle>{t("Change Date")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(date_update)}/>
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "view.name"),
        description: getStoreItem(state, "view.description"),
        selling_plants: getStoreItem(state, "view.selling_plants"),
        iso_expiration_date: getStoreItem(state, "view.iso_expiration_date"),
        manufacturer: getStoreItem(state, "view.manufacturer"),
        vendor_code: getStoreItem(state, "view.vendor_code"),
        distributor: getStoreItem(state, "view.distributor"),
        distributor_vendor_code: getStoreItem(state, "view.distributor_vendor_code"),
        material_type: getStoreItem(state, "view.material_type"),
        lead_time: getStoreItem(state, "view.lead_time"),
        standard_packaging: getStoreItem(state, "view.standard_packaging"),
        delivery_terms: getStoreItem(state, "view.delivery_terms"),
        freight_class: getStoreItem(state, "view.freight_class"),
        date_update: getStoreItem(state, "view.date_update"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
