import {formDataNormalizer, request, url} from "Services";

const addPublicationListLink =  '/product/product-finder/publication-groups';
const addNewPublicationListLink =  'product/product-finder/publication-groups/query';
const updatePublicationListLink = (id) => `/product/product-finder/publication-groups/${id}/name`;

export const requestStore = attachUrl => data => {
    const sendObj = {
        url: url.getUrl(attachUrl),
        data: formDataNormalizer(data),
        type: "POST",
        contentType: false,
        processData: false,
    };

    return request.sendRequest(sendObj);
};

export const requestAddPublicationList = data => {
    const {name} = data;

    const sendObj = {
        url: url.getUrl(addPublicationListLink),
        data: formDataNormalizer({name: name.trim()}),
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestNewAddPublicationList = data => {
    const { newListName, filters, groups } = data;

    const sendObj = {
        url: url.getUrl(addNewPublicationListLink),
        data: groups && groups.length
            ? formDataNormalizer({ list_ids: groups.map((item) => item.value), filters })
            : formDataNormalizer({ name: newListName, filters }),
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

export const requestUpdatePublicationList = data => {
    const {name, id} = data;

    const sendObj = {
        url: url.getUrl(updatePublicationListLink(id)),
        data: formDataNormalizer({name: name.trim()}),
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                help: res[0].help,
            });
        }).then(() => {
            if (url.getActiveTab()) {
                data.activeTab = url.getActiveTab();
            }
        });
    });
};
