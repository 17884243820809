import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { BlockMain, BlockWrap, Input, WrapInput, BlockShadow } from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const list = [
    {
        type: 10,
        label: "LinkedIn",
        placeholder: "https://",
    },
    {
        type: 9,
        label: "Instagram",
        placeholder: "Instagram Handle",
    },
    {
        type: 8,
        label: "X (Twitter)",
        placeholder: "@Twitter Handle",
    },
    {
        type: 7,
        label: "Facebook",
        placeholder: "Facebook Username",
    },
];

const propTypes = {
    socials: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
};

const SocialBlock = ({ editState, socials, onChange }) => {
    return (
        <BlockMain blockClassName={`digital-form ${editState ? 'active' : 'inactive'}`} title="The Digital You &trade;">
            <BlockShadow>
                {_.chunk(list, 2).map((items, index) => (
                    <BlockWrap key={`BlockWrap-${index}`}>
                        {items.map(item => {
                            const _value = _.head(socials.filter(s => s.type === item.type)) || { value: "" };

                            return (
                                <WrapInput label={item.label} name={`social.${item.label}`} key={`BlockWrap-${item.type}`}>
                                    <Input
                                        disabled={!editState}
                                        placeholder={!editState && !_value.value ? '-' : item.placeholder}
                                        value={_value.value}
                                        onChange={value => {
                                            if (!_value.type) {
                                                _value.type = item.type;
                                                socials.push(_value);
                                            }

                                            _value.value = value;
                                            onChange(socials, `socials`);
                                        }}
                                    />
                                </WrapInput>
                            );
                        })}
                    </BlockWrap>
                ))}
            </BlockShadow>
        </BlockMain>
    )
};

SocialBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { service } = ownProps;
    const { getStoreItem } = service;

    return {
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(SocialBlock);
