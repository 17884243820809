import { request, url } from "Services";

const listUrl = "auth/reset";

const getItems = token => data =>
    request.sendRequest({
        url: url.getUrl(`${listUrl}/${token}`),
        data,
        type: "POST",
    });

export const requestOnSubmitForm = token => data =>
    new Promise((resolve, reject) => {
        getItems(token)(data).then(res => resolve(res), res => reject(res));
    });
