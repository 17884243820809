import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: "",
};

const TableRowItemTooltip = ({ children, pref, className }) => {
    return (
        <span className="tooltip tooltip__table">
            <i className="la la-info-circle" />
            <span
                className={`${pref}__table-body-tooltip tooltip__text tooltip__text__table ${className}`}
                dangerouslySetInnerHTML={{ __html: children }}
            />
        </span>
    )
};

TableRowItemTooltip.propTypes = propTypes;
TableRowItemTooltip.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowItemTooltip);
