import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import _ from "lodash";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row, Column } from "Templates/Table/NewVersion";
import { Span } from "Templates/Default";
import { Input } from "Templates/Form";
import {LinkIcon} from "Templates/Link";

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
    service: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    changeApplicationCode: PropTypes.func.isRequired,
    changeSubSegment: PropTypes.func.isRequired,
    removeApplication: PropTypes.func.isRequired,
};

const TableBodyColumn = ({
    items,
    service: { columns },
    changeApplicationCode,
    changeSubSegment,
    removeApplication,
    t,
}) => {
    const [applications, setApplications] = useState([{
        code: '',
        market_segment: '-',
        segment: '-',
        sub_segment: '',
    }])

    useEffect(() => {
        if (items.length) {
            setApplications(items)
        }
    }, [items])

    const onChangeSubSegment = (value, index) => {
        changeSubSegment('sub_segment', value, index);
    }

    const getBodyView = (key, item, index) => {
        switch (key) {
            case "code": {
                return (
                    <Input
                        type="asyncSelect"
                        placeholder={t("Search")}
                        url="project/projects/application-code-list"
                        name="code"
                        value={_.get(item, key)}
                        onChange={value => {
                            changeApplicationCode(value, index);
                        }}
                    />
                );
            }

            case "sub_segment": {
                return (
                    <div className={"sub-segment-wrap"}>
                        <Input
                            type="asyncSelect"
                            url={`project/projects/${_.get(item, "code")}/application-list`}
                            name="sub_segment"
                            value={_.get(item, key)}
                            placeholder={t("Search")}
                            onChange={value => {
                                onChangeSubSegment(value, index)
                            }}
                        />
                        {(applications.length > 1 || applications[0].sub_segment) &&
                            <LinkIcon
                                className="icon-delete"
                                icon="la-icon-close"
                                onClick={
                                    () => {
                                        removeApplication(item.frontId)
                                    }
                                }
                            />
                        }
                    </div>
                );
            }

            default:
                return <Span>{_.get(item, key) === '' ? '-' : _.get(item, key)}</Span>;
        }
    };

    const getColSpan = key => {
        return ["code", "market_segment", "segment", "sub_segment"].includes(key) ? 2 : 1;
    };

    return (
        <>
            {applications.map((item, key) => (
                <Row key={`Item-${item.frontId}`}>
                    {columns.map((column, index) => (
                        <Column colSpan={getColSpan(column)} key={`Column-${index}`} className='text-left'>
                            {getBodyView(column, item, key)}
                        </Column>
                    ))}
                </Row>
            ))}
        </>
    );
};

TableBodyColumn.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem, itemsName } = ownProps.service;

    return {
        items: getStoreItem(state, itemsName),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        changeSubSegment: getActionStore("onChangeSubSegmentAction", service, dispatch),
        changeApplicationCode: getActionStore("changeApplicationCodeAction", service, dispatch),
        removeApplication: getActionStore("removeApplication", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TableBodyColumn);
