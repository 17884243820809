import _ from "lodash";

import * as SubmitAction from "../Action/SubmitAction";

export const getStoreItem = (state, key) => _.get(state, `listState.${key}`);

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...SubmitAction,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
