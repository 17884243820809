import React from "react";
import PropTypes from "prop-types";
import Img from "Templates/Img/Img";

const defaultProps = {
    link: "#",
    img: "",
    sublabel: "",
    onClick: () => {},
};

const propTypes = {
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
    img: PropTypes.string,
    sublabel: PropTypes.string,
    onClick: PropTypes.func,
};

const ItemMenuContent = ({ link, img, sublabel, label, onClick }) => {

    return (
        <a
            href={link}
            onClick={onClick}
            className={`main-sidebar__list-link ${link === defaultProps.link ? "submenu-item" : ""}`}
            data-sublabel={sublabel}
        >
            {img && (
                <span className="main-sidebar__list-icon">
                    <Img img={img} alt={label} className="main-sidebar__list-svg" />
                </span>
            )}
            <span className="main-sidebar__list-text">{label}</span>
        </a>
    );
};

ItemMenuContent.defaultProps = defaultProps;
ItemMenuContent.propTypes = propTypes;

export default ItemMenuContent;
