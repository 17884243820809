import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import _ from "lodash";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    storage_url: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    alt: "",
    className: "",
};

const ImgStorage = ({ storage_url, url, alt, className }) => {
    if (url && !_.startsWith(url, "http")) {
        if (_.startsWith(url, "/")) {
            url = url.substr(1);
        }
        url = `${storage_url}/${url}`;
    }

    return <img src={`${url}`} alt={alt} className={className} />;
};

ImgStorage.propTypes = propTypes;
ImgStorage.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(ImgStorage);
