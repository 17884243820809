import React from "react";
import {useSelector} from 'react-redux';
import {weightUnitConvertLbs, weightUnitName} from 'Services/weightUnitName';
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "./EventsService";
import {gradeStatus, projectStatusBudgetForecast} from "Services/enum";
import {numberWithCommas} from "Services";

export const getTableLabel = (key, {currentFY, totalAnalytics}) => {
    const unit = useSelector(state => state.weightUnitState);
    const nextFY = +currentFY + 1;

    const tableLabel = {
        slug: "Connection ID",
        company_short_name: "Customer Name",
        company: "Customer Full Name",
        customer_code: "Customer Code",
        customer_owner: "Customer Owner",
        customer_duns_number: "D-U-N-S® Number",
        sap_customer: "SAP Customer",
        sap_grade: "SAP Product",
        grade: "Product Name",
        grade_sub_status: "Product Status",
        material_type: "Material Type",
        sap_application_code: "SAP Application Code",
        material_number: "SAP Material Number",
        product_code: "Product Code",
        project_slug: "Project ID",
        project_name: "Project Name",
        price: `Price ($/${weightUnitName(unit.weightUnit)})`,
        rebate: `Rebate ($/${weightUnitName(unit.weightUnit)})`,
        final_price: `Final Price ($/${weightUnitName(unit.weightUnit)})`,
        part_weight: `Part Weight (${weightUnitConvertLbs(weightUnitName(unit.weightUnit))})`,
        sales_manager: "Sales Manager (ZSales)",
        note: "Notes",
        note_finance: "B&S Notes",
        project_manager: "Sales Manager (Project)",
        project_internal_team: "Internal Team (Project)",
        project_sub_status: "Project Status",
        los_date: "Loss of Sales Date",
        move_to_current: "Start Date",
        end_date: "End Date",
        sop: "SOP",
        eop: "EOP",
        plant: "Selling Plant",
    };

    switch (key) {
        case "budget_price":
            return `<span class="font-size">FY ${currentFY} Price ($/${weightUnitName(unit.weightUnit)})</span> <br> <span class="connections-total-analitic"></span>`;
        case "budget_volume":
            return `<span class="font-size">FY ${currentFY} Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].budget_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].budget_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "budget_revenue":
            return `<span class="font-size">FY ${currentFY} Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].budget_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].budget_revenue, 0)}</span>`;

        case "actual_calculated_price":
            return `<span class="connection-fy-with-tooltip actuals">
                        <span class="font-size whitespace-nowrap">Calculated Price <br> ($/${weightUnitName(unit.weightUnit)})</span> <br> <span class="connections-total-analitic"></span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 Price = FYTD Net Amount / FYTD Volume
                            </span>
                        </span>
                    </span>`;
        case "actual_normalized_weight":
            return `<span class="font-size">FYTD Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].actual_normalized_weight, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].actual_normalized_weight, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "actual_net_amount":
            return `<span class="font-size">FYTD Net Amount ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].actual_net_amount, 0)}">$${numberWithCommas(totalAnalytics[0].actual_net_amount, 0)}</span>`;

        case "annualized_volume":
            return `<span class="connection-fy-with-tooltip actuals">
                        <span class="font-size whitespace-nowrap">FY ${currentFY} Volume (${weightUnitName(unit.weightUnit)})</span> <br> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].annualized_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].annualized_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 Annualized volume is an estimate based on the FYTD volume actuals. Year end actual volume could vary from annualized volume.
                            </span>
                        </span>
                    </span>`;
        case "annualized_revenue":
            return `<span class="connection-fy-with-tooltip actuals">
                        <span class="font-size whitespace-nowrap">FY ${currentFY} Revenue ($)</span> <br> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].annualized_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].annualized_revenue, 0)}</span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 Annualized revenue is an estimate based on the FYTD revenue actuals. Year end actual revenue could vary from annualized revenue.
                            </span>
                        </span>
                    </span>`;

        case "current_average_sale_price":
            return `<span class="connection-fy-with-tooltip">
                        <span class="font-size">FYTD ${currentFY} <br> <span class="whitespace-nowrap">Calculated Price ($/${weightUnitName(unit.weightUnit)})</span></span> <br> <span class="connections-total-analitic"></span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 FY 2023 Price = FY 2023 Revenue / FY 2023 Volume
                            </span>
                        </span>
                    </span>`;
        case "current_volume":
            return `<span class="font-size whitespace-nowrap">FY ${currentFY} Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].current_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].current_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "current_variance_percent":
            return `<span class="connection-fy-with-tooltip variance">
                        <span class="font-size">FY ${currentFY} <span class="whitespace-nowrap">Variance (%)</span></span> <br> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].current_variance_percent, 0)} %">${numberWithCommas(totalAnalytics[0].current_variance_percent, 0)} %</span>                     
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 FY 2023 Forecast Variance (%) = (FY XXXX Forecast Volume - FY XXXX Annualized Volume) / FY XXXX Forecast Volume
                            </span>
                        </span>
                    </span>`;
        case "current_variance":
            return `<span class="connection-fy-with-tooltip variance">
                        <span class="font-size whitespace-nowrap">FY ${currentFY} Variance (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].current_variance, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].current_variance, 0)} ${weightUnitName(unit.weightUnit)}</span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                FY XXXX Forecast Variance = FY XXXX Forecast Volume - FY XXXX Annualized Volume
                            </span>
                        </span>
                    </span>`;
        case "current_revenue":
            return `<span class="font-size whitespace-nowrap">FY ${currentFY} Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].current_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].current_revenue, 0)}</span>`;
        case "current_total_rebate":
            return `<span class="font-size whitespace-nowrap">FY ${currentFY} Total Rebate ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].current_total_rebate, 0)}">$${numberWithCommas(totalAnalytics[0].current_total_rebate, 0)}</span>`;
        case "current_total_revenue":
            return `<span class="font-size whitespace-nowrap">FY ${currentFY} Total Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].current_total_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].current_total_revenue, 0)}</span>`;

        case "next_average_sale_price":
            return `<span class="connection-fy-with-tooltip">
                        <span class="font-size">FYTD ${nextFY} <br> <span class="whitespace-nowrap">Calculated Price ($/${weightUnitName(unit.weightUnit)})</span></span> <br> <span class="connections-total-analitic"></span>
                        <span class="tooltip">
                            <i class="la la-info-circle"></i>
                            <span class="tooltip__text tooltip__text__table">
                                 FY 2024 Price = FY 2024 Revenue / FY 2024 Volume
                            </span>
                        </span>
                    </span>`;
        case "next_volume":
            return `<span class="font-size whitespace-nowrap">FY ${nextFY} Volume (${weightUnitName(unit.weightUnit)})</span> <span class="connections-total-analitic" title="${numberWithCommas(totalAnalytics[0].next_volume, 0)} ${weightUnitName(unit.weightUnit)}">${numberWithCommas(totalAnalytics[0].next_volume, 0)} ${weightUnitName(unit.weightUnit)}</span>`;
        case "next_revenue":
            return `<span class="font-size whitespace-nowrap">FY ${nextFY} Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].next_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].next_revenue, 0)}</span>`;
        case "next_total_rebate":
            return `<span class="font-size whitespace-nowrap">FY ${nextFY} Total Rebate ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].next_total_rebate, 0)}">$${numberWithCommas(totalAnalytics[0].next_total_rebate, 0)}</span>`;
        case "next_total_revenue":
            return `<span class="font-size whitespace-nowrap">FY ${nextFY} Total Revenue ($)</span> <span class="connections-total-analitic" title="$${numberWithCommas(totalAnalytics[0].next_total_revenue, 0)}">$${numberWithCommas(totalAnalytics[0].next_total_revenue, 0)}</span>`;

        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        slug: "Search",
        company_short_name: "Search",
        company: "Search",
        customer_code: "Search",
        customer_owner: "Search",
        customer_duns_number: "Search",
        sap_customer: "Select",
        sap_grade: "Select",
        grade: "Search",
        grade_sub_status: "Select",
        project_sub_status: "Select",
        material_type: "Select",
        product_code: "Search",
        sap_application_code: "Search",
        sales_manager: "Search",
        note: "Search",
        note_finance: "Search",
        project_manager: "Search",
        project_internal_team: "Search",
        project_slug: "Search",
        project_name: "Search",
        price: "Search",
        rebate: "Search",
        final_price: "Search",
        part_weight: "Search",
        los_date: "Search",
        move_to_current: "Search",
        end_date: "Search",
        sop: "Search",
        eop: "Search",
        plant: "Search",
        material_number: "Search",
        budget_price: "Select",
        budget_volume: "Select",
        budget_revenue: "Select",
        actual_calculated_price: "Select",
        actual_normalized_weight: "Select",
        actual_net_amount: "Select",
        annualized_volume: "Select",
        annualized_revenue: "Select",
        current_average_sale_price: "Select",
        current_volume: "Select",
        current_variance_percent: "Select",
        current_variance: "Select",
        current_revenue: "Select",
        current_total_rebate: "Select",
        current_total_revenue: "Select",
        next_average_sale_price: "Select",
        next_volume: "Select",
        next_revenue: "Select",
        next_total_rebate: "Select",
        next_total_revenue: "Select",
    };

    /*
        *
        * Here, onChange(e) was modified to onChange(e, key) -> the "key" is needed to remove filter from the URL after clearing the filter in InputFilter Component
        *
    */

    switch (key) {
        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/connection/connections/slug-list"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "project_manager":
        case "sales_manager":
        case "project_internal_team":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name="sales_manager"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "company":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-list"
                    name="company"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sop":
        case "eop":
        case "move_to_current":
        case "end_date":
        case "los_date":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/plant-list"
                    name="plant"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "company_short_name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-short-list"
                    name="company_short_name"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_duns_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-duns-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_customer":
        case "sap_grade":
            return (
                <InputFilter
                    type="select"
                    value={value.toString()}
                    options={[
                        ...[
                            {value: '', label: "-"}
                        ], ...[
                            {value: '0', label: "No", color: "loss"},
                            {value: '10', label: "Yes", color: "current"}
                        ]
                    ]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "grade":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/grade-list"
                    name="grade"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "product_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/product-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-code-list"
                    name="sap_application_code"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "grade_sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={gradeStatus}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "project_sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={
                        [...projectStatusBudgetForecast]
                    }
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "material_type":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/material-type-list"
                    name="material_type"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback('material_type', _value, request));
                    }}
                />
            );

        case "project_slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-slug"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "project_name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-list"
                    name="name"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "material_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/material-number-list"
                    name="material_number"
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "current_average_sale_price":
        case "current_volume":
        case "current_variance_percent":
        case "current_variance":
        case "current_revenue":
        case "current_total_rebate":
        case "current_total_revenue":
        case "next_average_sale_price":
        case "next_volume":
        case "next_revenue":
        case "next_total_rebate":
        case "next_total_revenue":
        case "actual_calculated_price":
        case "actual_normalized_weight":
        case "actual_net_amount":
        case "annualized_volume":
        case "annualized_revenue":
        case "budget_price":
        case "budget_volume":
        case "budget_revenue": {
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[
                        {value: '', label: "All"},
                        {value: 'zero', label: "0"},
                        {value: 'non-zero', label: "Greater than 0"}]
                    ]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );
        }

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => (
    key === 'slug' ||
    key === 'company_short_name' ||
    key === 'company' ||
    key === 'grade' ||
    key === 'sap_grade' ||
    key === 'material_type' ||
    key === 'grade_sub_status' ||
    key === 'customer_code' ||
    key === 'customer_owner' ||
    key === 'sap_application_code' ||
    key === 'customer_duns_number' ||
    key === 'sap_customer' ||
    key === 'product_code' ||
    key === 'material_number' ||
    key === 'sales_manager' ||
    key === 'budget_volume' ||
    key === 'budget_revenue' ||
    key === 'stretch_volume' ||
    key === 'stretch_revenue' ||
    key === 'variance_volume' ||
    key === 'variance_revenue' ||
    key === 'actual_normalized_weight' ||
    key === 'actual_net_amount' ||
    key === 'annualized_volume' ||
    key === 'annualized_revenue' ||
    key === 'current_volume' ||
    key === 'current_variance_percent' ||
    key === 'current_variance' ||
    key === 'current_revenue' ||
    key === 'current_total_rebate' ||
    key === 'current_total_revenue' ||
    key === 'next_volume' ||
    key === 'next_revenue' ||
    key === 'next_total_rebate' ||
    key === 'next_total_revenue' ||
    key === 'application_code'
);
