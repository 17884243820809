import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { csrf_token, id, fullName, photo, companyName, companyList, hasNotifications } = props;
    const { mainState } = initialStates;

    const initialState = {
        listState: {
            ...mainState,
            csrf_token,
            id,
            fullName,
            photo,
            companyName,
            companyList,
            hasNotifications,
        },
        ...getMainModifiedStoreItems({ ...props, pref: "forgot" }),
    };

    return _configureStore(initialState, reducers);
}
