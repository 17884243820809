import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { view } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "connection-view" }),
        listState: {
            ...mainState,
            view,
        },
    };

    return _configureStore(initialState, reducers);
}
