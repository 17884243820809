import React from "react";
import _ from "lodash";
import { industryOptions } from "Services/enum";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getFilter = placeholders => (key, value, callback, request) => {
    switch (key) {
        case "sop":
        case "eop":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "industry":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={industryOptions}
                    placeholder={_.get(placeholders, key)}
                    name="industry"
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "total": 
              return <InputFilter disabled type="text" placeholder="" value="" onChange={() => {}} />;

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
