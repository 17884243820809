const Constants = {
    ADD_CONTACT_REQUESTED: "ADD_CONTACT_REQUESTED",
    ADD_CONTACT_SUCCEEDED: "ADD_CONTACT_SUCCEEDED",
    ADD_CONTACT_FAILED: "ADD_CONTACT_FAILED",

    ADD_INTERNAL_TEAM_REQUESTED: "ADD_INTERNAL_TEAM_REQUESTED",
    ADD_INTERNAL_TEAM_SUCCEEDED: "ADD_INTERNAL_TEAM_SUCCEEDED",
    ADD_INTERNAL_TEAM_FAILED: "ADD_INTERNAL_TEAM_FAILED",

    FETCH_ITEM_REQUESTED: "FETCH_ITEM_REQUESTED",
    FETCH_ITEM_SUCCEEDED: "FETCH_ITEM_SUCCEEDED",
    FETCH_ITEM_FAILED: "FETCH_ITEM_FAILED",
};
export default Constants;