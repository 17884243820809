import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import reducers, {initialStates} from "../Reducers";

export default function configureStore(props) {
    const { items, pagination, help } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "videos" }),
        listState: { ...mainState, items, pagination, help },
    };

    return _configureStore(initialState, reducers);
}
