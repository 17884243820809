import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import QuickEditTableRowColumnTemplate from "./QuickEditTableRowColumn";
import QuickEditTableRowColumnButton from "./QuickEditButton";
import QuickEditTableRowColumnDefault from "./QuickEditDefault";
import QuickEditTableRowColumnTemplateDefault
    from "Templates/Table/QuickEditTableRowColumnWrap/QuickEditTemplateDefault";

const propTypes = {
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowId: PropTypes.string,
    invalidQuickEdit: PropTypes.bool,
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickCustom: PropTypes.func,
    onClickUnlock: PropTypes.func,
    onClickSave: PropTypes.func,
    applyChange: PropTypes.func,
    setInvalid: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
};

const defaultProps = {
    customIcon: "",
    rowId: null,
    invalidQuickEdit: false,
    onClickCustom: () => {
    },
    onClickUnlock: () => {
    },
    onClickSave: () => {},
    applyChange: () => {},
    setInvalid: () => {},
};

const QuickEditTableRowColumnWrap = React.memo(
    ({customIcon, rowId, invalidQuickEdit, secondaryIndex, column, item, onClickCustom, onClickDelete, onClickUnlock, onClickEdit, onClickSave, applyChange, setInvalid}) => {

        return (
            <QuickEditTableRowColumnTemplate>
                {column === "-1" ? (
                    <QuickEditTableRowColumnButton
                        customIcon={customIcon}
                        item={item}
                        rowId={rowId}
                        invalidQuickEdit={invalidQuickEdit}
                        onClickCustom={onClickCustom}
                        onClickDelete={onClickDelete}
                        onClickUnlock={onClickUnlock}
                        onClickEdit={onClickEdit}
                        onClickSave={onClickSave}
                        index={secondaryIndex}
                    />
                ) : (
                    rowId === item.id ? <QuickEditTableRowColumnTemplateDefault invalidQuickEdit={invalidQuickEdit} column={column} item={item} applyChange={applyChange} setInvalid={setInvalid}/> :
                        <QuickEditTableRowColumnDefault column={column} item={item}/>
                )}
            </QuickEditTableRowColumnTemplate>
        )
    },
);

QuickEditTableRowColumnWrap.propTypes = propTypes;
QuickEditTableRowColumnWrap.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(QuickEditTableRowColumnWrap);
