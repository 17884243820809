import React from "react";
import {formatDate, getString, numberWithCommas} from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {gradeStatus} from "Services/enum";

export const modifierValues = items => items;
export const tableButtonsPermission = "grade_product_crud";

const typeList = {
    10: "Guides",
    7: "Safety Data Sheet",
    6: "Technical Data Sheet",
    5: "Processing Guide",
    1: "Other",
};

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLink title={getString(item, key)} href={`/product/product-finder/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "sub_status":
            return <TableRowItemStatus status={getString(item, key)} list={gradeStatus} />;

        case "sap_value":
            return <TableRowItemSpan>{getString(item, key) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case "revenue":
            return (
                <TableRowItemSpan className="text-center">
                    {`$${numberWithCommas(getString(item, key))}`}
                </TableRowItemSpan>
            );

        case "volume":
            return (
                <TableRowItemSpan className="text-center">
                    {`${numberWithCommas(getString(item, key))} lb`}
                </TableRowItemSpan>
            );

        case "gross_margin":
            return (
                <TableRowItemSpan
                    title={getString(item, key)}
                    className={`${getString(item, key) < 0 ? "clr-red" : ""} text-right`}
                >
                    {`${getString(item, key)}%`}
                </TableRowItemSpan>
            );

        case "count_projects":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "id")}?activeTab=5`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "count_invoices":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "id")}?activeTab=10`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "publications": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemLink title={elem.attachment_name} key={`TableRowItemLink-${index}`} file href={elem.attachment_path}>
                                    {typeList[elem.type]}
                                </TableRowItemLink>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        case "date_update":
        case "submitted_date": {
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => {
                        const formatValue = elem == '-' ? elem : formatDate(elem);
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                {formatValue}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case "sap_plant":
        case "sap_line": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={index} title={elem.name}>{elem.name}</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
