import React, {Component} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ModalDefault } from "Templates/Modals";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import { withServiceConsumer } from "Services/Context";
import FileList from "Services/FileList/FileList";
import _ from "lodash";
import {formatDate} from "../../../Services";

const { Button, Form, Title, Row, Col, ColInput, ColText } = ModalDefault;

const defaultProps = {
    oldValue: {},
    editValue: {},
};

const propTypes = {
    service: PropTypes.shape({
        requestAttach: PropTypes.func.isRequired,
        requestEdit: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
        typeList: PropTypes.arrayOf(
            PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
        ),
    }).isRequired,
    editValue: PropTypes.objectOf(PropTypes.any),
    onClose: PropTypes.func.isRequired,
    button: PropTypes.string.isRequired,
    oldValue: PropTypes.objectOf(PropTypes.any),
    fetchItems: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
};

class AddIso extends Component {
    state = {
        form: {
            id: "",
            iso: "",
            effectiveDate: formatDate(Date.now(), "YYYY-MM-DD"),
            expirationDate: formatDate(Date.now(), "YYYY-MM-DD"),
        },
    };

    componentDidMount() {
        const { editValue } = this.props;

        if (editValue.id) {
            const form = {
                id: editValue.id,
                effectiveDate: formatDate(editValue.effective_date, "YYYY-MM-DD"),
                expirationDate: formatDate(editValue.expiration_date, "YYYY-MM-DD"),
                iso: editValue.path,
            };
            this.setState({
                form: {...form},
                oldValue: {...form},
            });
        } else {
            this.setState({
                form: {
                    id: "",
                    iso: "",
                    effectiveDate: formatDate(Date.now(), "YYYY-MM-DD"),
                    expirationDate: formatDate(Date.now(), "YYYY-MM-DD"),
                },
            });
        }
    }

    componentWillUnmount() {
        const { resetForm } = this.props;
        resetForm();
    }

    handleChange = (value, name) => {
        this.setState(state => {
            const form = { ...state.form };
            form[name] = name === "iso" ? _.head(value) || "" : value;
            state.form = form;

            return state;
        });
    };

    onAppend = () => {
        const {
            fetchItems,
            service: { requestAttach, requestEdit },
            onClose
        } = this.props;
        const { form } = this.state;

        const request = form.id ? requestEdit({ id: form.id }) : requestAttach;

        request({
            effectiveDate: formatDate(form.effectiveDate, "YYYY-MM-DD"),
            expirationDate: formatDate(form.expirationDate, "YYYY-MM-DD"),
            iso: form.iso,
        }).then(() => {
            onClose();
            fetchItems({});
        });
    };

    render() {
        const { t, onClose } = this.props;
        const { form, oldValue } = this.state;

        const disabled = !form.iso || !form.effectiveDate
            || !form.expirationDate || JSON.stringify(oldValue) === JSON.stringify(form);

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose} />
                <Title>{t(form.id ? "Edit ISO" : "Add ISO")}</Title>
                {!form.id && (
                    <FileList
                        name="iso"
                        fileLength={1}
                        files={!form.iso ? [] : [form.iso]}
                        dropFile={e => this.handleChange(e, "iso")}
                        handleDrop={e => this.handleChange(e, "iso")}
                    />
                )}
                <Row>
                    <Col>
                        <ColText>{t("Effective Date")}</ColText>
                        <ColInput
                            type="date-select"
                            name="effectiveDate"
                            value={form.effectiveDate}
                            onChange={value => this.handleChange(value, "effectiveDate")}
                        />
                    </Col>
                    <Col>
                        <ColText>{t("Expiration Date")}</ColText>
                        <ColInput
                            type="date-select"
                            name="expirationDate"
                            value={form.expirationDate}
                            onChange={value => this.handleChange(value, "expirationDate")}
                        />
                    </Col>
                </Row>
                <Button onClick={this.onAppend} disabled={disabled}>
                    {t("Save ISO")}
                </Button>
            </Form>
        );
    }
}

AddIso.defaultProps = defaultProps;
AddIso.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        oldValue: getStoreItem(state, "oldValue"),
        editValue: getStoreItem(state, "editValue"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore, typeList } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        resetForm: getActionStore("resetFormAction", service, dispatch),
        typeList
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(AddIso);

