import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { Span } from "Templates/Default";
import { numberWithCommas } from "Services";

import CardHeader from "./CardHeader";
import CardTitle from "./CardTitle";
import CardList from "./CardList";
import CardBodyLink from "./CardBodyLink";
import CardListItem from "./CardListItem";
import CardFooter from "./CardFooter";

const propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        project_id: PropTypes.string.isRequired,
        project_name: PropTypes.string.isRequired,
        project_application_name: PropTypes.string,
        project_customer_name: PropTypes.string.isRequired,
        project_customer_id: PropTypes.string.isRequired,
        project_revenue: PropTypes.string.isRequired,
        project_manager_name: PropTypes.string.isRequired,
        project_manager_photo: PropTypes.string.isRequired,
        project_manager_id: PropTypes.string.isRequired,
        project_products: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                product_name: PropTypes.string.isRequired,
                grade_name: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

const Card = ({ item, pref }) => {
    return (
        <div className={`${pref}__card`}>
            <CardHeader>{item.project_name}</CardHeader>
            <CardTitle>{`$${numberWithCommas(item.project_revenue)}`}</CardTitle>
            <CardList>
                <CardListItem>
                    <CardBodyLink href={`/project/projects/${item.id}`}>{item.project_id}</CardBodyLink>
                </CardListItem>
                {item.project_application_name && (
                    <CardListItem>
                        <Span>{item.project_application_name}</Span>
                    </CardListItem>
                )}
                <CardListItem>
                    {item.project_products.map((elem, index) => (
                        <CardBodyLink key={`CardBodyLink-${elem.id}`} href={`/product/products/${elem.id}`}>
                            {`${elem.product_name} (${elem.grade_name})
                            ${item.project_products.length - 1 !== index ? ", " : ""}`}
                        </CardBodyLink>
                    ))}
                </CardListItem>
                <CardListItem>
                    <CardBodyLink href={`/companies/${item.project_customer_id}`}>
                        {item.project_customer_name}
                    </CardBodyLink>
                </CardListItem>
            </CardList>
            <CardFooter
                id={item.project_manager_id}
                photo={item.project_manager_photo}
                name={item.project_manager_name}
            />
        </div>
    );
};

Card.propTypes = propTypes;

export default compose(withTagDefaultProps())(Card);
