import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";

const propTypes = {
    slug: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    grade: PropTypes.string.isRequired,
    materialNumber: PropTypes.string.isRequired,
    customerCode: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired
};

const InfoWrap = ({ slug, company, customerCode, materialNumber, grade, t, onClickEdit }) => (
    <ContentInfoWrap>
        <ContentInfoTop onClickEdit={onClickEdit} urlBack="/connection/connections" />
        <ContentInfoTitle>{slug}</ContentInfoTitle>

        <ContentInfoBlock />

        <ContentInfoBlock show={!!company}>
            <ContentInfoBlockTitle>{t("Customer Name")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={company} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!customerCode}>
            <ContentInfoBlockTitle>{t("Customer Code")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={customerCode} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!grade}>
            <ContentInfoBlockTitle>{t("Product Name")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={grade} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!materialNumber}>
            <ContentInfoBlockTitle>{t("SAP Material Number")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={materialNumber} />
        </ContentInfoBlock>
    </ContentInfoWrap>
);

InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        slug: getStoreItem(state, "view.slug"),
        company: getStoreItem(state, "view.company"),
        customerCode: getStoreItem(state, "view.customerCode"),
        materialNumber: getStoreItem(state, "view.materialNumber"),
        grade: getStoreItem(state, "view.grade")
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
