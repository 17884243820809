import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    form: {
        notify_creator: true,
        products_invite: false,
        lists_invite: false,
        lists: []
    },
};

export const reducer = (state, action) => {
    const {data} = action;

    switch (action.type) {

        default:
            return state;
    }
};
