export const isNotANumber = value => value === "" || Number.isNaN(value) || !Number.isFinite(Number(value));

export const getNumber = (value, rounding = 2) => {
    if (isNotANumber(value)) {
        return 0;
    }
    return Number(typeof value === "number" ? value.toFixed(rounding) : value);
};

export const getNumberRoundToUpper = (value, rounding = 2) => {
    if (isNotANumber(value)) {
        return 0;
    }

    return getNumber((getNumber(value, rounding) * 10).toFixed() / 10, 1);
};

export const regExpForFloat = (value, cb) => {
    const re = /^[0-9]*[.]?[0-9]{0,2}$/;
    if (re.test(value)) {
        cb(value);
    }
};

//TODO переписать, передав в regExpForFloat параметр rounding
export const regExpForFloatFive = (value, cb, addMinus = false) => {
    const re =  new RegExp("^" + (addMinus ? "-?" : "")+ "[0-9]*[.]?[0-9]{0,5}$", "g");
    if (re.test(value)) {
        cb(value);
    }
};

export const regExpForInteger = (value, cb) => {
    const re = /^[0-9]*$/;
    if (re.test(value)) {
        cb(value);
    }
};

export const numberWithCommas = (value, rounding = 2) => {
    if (isNotANumber(value)) {
        return 0;
    }
    return Number(Number(value).toFixed(rounding)).toLocaleString("en-US");
};

export const numberCurrenciesWithCommas = (value, rounding = 2) => {
    if (isNotANumber(value)) {
        return 0;
    }
    return Number(value).toFixed(rounding);
};

export const numWithSub = (num, count = 0) => {
    if (count < 9 && num > 1000) {
        return numWithSub(num / 1000, count + 3);
    }

    if (typeof num !== "number") {
        num = Number(num);
    }
    switch (count) {
        case 0:
            return `${num.toFixed(0)}`;
        case 3:
            return `${numberWithCommas(num * 1000, 0)}`;
        case 6:
            return `${num.toFixed(2)}M`;
        case 9:
            return `${num.toFixed(2)}B`;

        default:
            return "";
    }
};

const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
});

export const formatPercent = (value) => {
    return percentFormatter.format(value);
}

const costFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
});

export const formatCost = (value) => {
    return costFormatter.format(value);
}
