import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { formatDate } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";
import { BlockWrapFlex } from "Templates/Form";

const defaultProps = {
    vehicleId: "",
};

const propTypes = {
    manufacturer: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    vehicleId: PropTypes.string,
    brand: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    nameplate: PropTypes.string.isRequired,
    sop: PropTypes.string.isRequired,
    eop: PropTypes.string.isRequired,
};

const InfoWrap = ({ manufacturer, program, vehicleId, brand, platform, nameplate, sop, eop, t }) => (
    <ContentInfoWrap>
        <ContentInfoTop urlBack="/project/vehicles" />
        <ContentInfoTitle>{`${manufacturer}, ${program}`}</ContentInfoTitle>

        <ContentInfoBlock show={!!vehicleId}>
            <ContentInfoBlockTitle>{t("Vehicle ID")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={vehicleId} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!manufacturer}>
            <ContentInfoBlockTitle>{t("Manufacturer")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={manufacturer} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!program}>
            <ContentInfoBlockTitle>{t("Program")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={program} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!brand}>
            <ContentInfoBlockTitle>{t("Brand")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={brand} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!platform}>
            <ContentInfoBlockTitle>{t("Platform")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={platform} />
        </ContentInfoBlock>
        <ContentInfoBlock show={!!nameplate}>
            <ContentInfoBlockTitle>{t("Nameplate")}</ContentInfoBlockTitle>
            <ContentInfoBlockText value={nameplate} />
        </ContentInfoBlock>
        <BlockWrapFlex>
            <ContentInfoBlock show={!!sop}>
                <ContentInfoBlockTitle>{t("SOP")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(sop)} />
            </ContentInfoBlock>
            <ContentInfoBlock show={!!eop}>
                <ContentInfoBlockTitle>{t("EOP")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={formatDate(eop)} />
            </ContentInfoBlock>
        </BlockWrapFlex>
    </ContentInfoWrap>
);

InfoWrap.defaultProps = defaultProps;
InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        manufacturer: getStoreItem(state, "view.manufacturer"),
        program: getStoreItem(state, "view.program"),
        vehicleId: getStoreItem(state, "view.vehicle_id"),
        brand: getStoreItem(state, "view.brand"),
        platform: getStoreItem(state, "view.platform"),
        nameplate: getStoreItem(state, "view.nameplate"),
        sop: getStoreItem(state, "view.sop"),
        eop: getStoreItem(state, "view.eop"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
