import React from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";

import ProjectTeamContactsTableBodyColumn from "./Table/TableBodyColumn";
import * as ProjectTeamContactsService from "../../../../../Services/Tabs/General/Table/ProjectTeamContactsService";
import ExpandedBlock from "../../../../Templates/ExpandedBlock";

const ProjectTeamContactsTable = CustomSimpleTable()(() => null)()(ProjectTeamContactsTableBodyColumn)()(() => null);

const ProjectTeamContactsBlock = ({ t, block, expanded, setExpandedAction }) => {
    return (
        <div className="project-expanded-block">
            <ExpandedBlock title={t("Project Team & Contacts")} service={ProjectTeamContactsService} block={block} isExpanded={expanded} setExpandedAction={setExpandedAction}>
                <ProjectTeamContactsTable />
            </ExpandedBlock>
        </div>
    );
};

ProjectTeamContactsBlock.propTypes = {};

export default compose(withTagDefaultProps())(ProjectTeamContactsBlock);
