import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row } from "Templates/Table/NewVersion";

const TableFooterColumnDefault = () => {
    return <Row />;
};

TableFooterColumnDefault.propTypes = {};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableFooterColumnDefault);
