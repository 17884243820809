import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {
    Block,
    BlockWrapFlex,
    BlockShadow,
    BlockWrap,
    Input,
    WrapInput,
    BlockMain,
    Checkbox,
} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import Tab from "Templates/Tabs/Tab";
import Tabs from "Templates/Tabs/Tabs";
import Sitewide from "./PermissionsTabs/Sitewide";
import Admin from "./PermissionsTabs/Admin";
import ProductsFinder from "./PermissionsTabs/ProductsFinder";
import Solutions from "./PermissionsTabs/Solutions";
import Analytics from "./PermissionsTabs/Analytics";
import Forecasts from "./PermissionsTabs/Forecasts";
import Finance from "./PermissionsTabs/Finance";
import Production from "./PermissionsTabs/Production";
import Companies from "./PermissionsTabs/Companies";
import Vendors from "./PermissionsTabs/Vendors";
import Contacts from "./PermissionsTabs/Contacts";
import File from "./PermissionsTabs/File";
import Legal from "./PermissionsTabs/Legal";
import Profile from "./PermissionsTabs/Profile";
import Video from "./PermissionsTabs/Video";
import { generatePermissions } from "../Constants/MapPermissions";

const {CheckboxButton, CheckboxTitle, CheckboxBlock} = Checkbox;

const propTypes = {
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeTab: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

class TeamBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCompany: _.get(props.team, '0.company')
        };
    }

    _onChangeCompany = (val) => {
        const {team, onChange} = this.props;

        if(!team[0].company.length){
            team[0].company = val;
            onChange("team", team);
        }

        const existCompany = _.findIndex(team, (o) => o.company === val) >= 0;
        if(!existCompany) {
            this._setDefaultCompany(val);
        }

        this.setState({currentCompany: val });
    }

    _setDefaultCompany = (companyId) => {
        const {team, onChange} = this.props;

        onChange("team", _.concat(team, {
            frontId: team.length + 1,
            company: companyId,
            team: "",
            haveAccess: false,
            defaultCompany: false,
            defaultPage:"dashboard.index",
            permission: [],
            role: "",
        }));
    }

    _onChange = (key, val) => {
        const {onChange} = this.props;
        let {team} = this.props;
        const {currentCompany} = this.state;

        const index = _.findIndex(team, (o) => o.company === currentCompany);

        if(team[index]){
            if(key === 'defaultCompany') {
                team = _.map(team, function(t) {
                    t.defaultCompany = false;
                    return t;
                });
            }

            _.set(team[index], key, val);
            onChange("team", _.concat(team));
        }
    };

    _onChangePermission = (key, val, index) => {
        const {team, onChange} = this.props;

        generatePermissions(key, val, index, team);

        onChange("team", [...team]);
    };

    render() {
        const {team, t, onChangeTab, activeTab} = this.props;
        const {currentCompany} = this.state;

        const index = currentCompany ? _.findIndex(team, (o) => o.company === currentCompany) : 0;
        const item = team[index];

        let landOptions = [];

        if(item && (_.includes(item.permission, 'product_publications_guest') ||
           _.includes(item.permission, 'grade_product_finder_view') ||
           _.includes(item.permission, 'product_publication_groups_edit') ||
           _.includes(item.permission, 'product_publication_groups_view'))) {
            landOptions = _.concat(landOptions, { label: "Products", value: "product.product-finder.index"});
        } else if(_.get(item, "defaultPage") === 'product.product-finder.index') {
                this._onChange(`defaultPage`, "dashboard.index");
            }

        if(item && (_.includes(item.permission,  'sale_level_1') ||
            _.includes(item.permission, 'sale_level_2') ||
            _.includes(item.permission, 'business_level_1') ||
            _.includes(item.permission, 'business_level_2'))) {
            landOptions = _.concat(landOptions, { label: "Dashboard", value: "dashboard.index"});
        }

        if(!landOptions.length) {
            landOptions = _.concat(landOptions, { label: "-", value: "dashboard.index"});
        }

        return (
                <>
                    <BlockMain title="Company(s) / Team(s)">
                        <Block>
                            <BlockShadow>
                                <BlockWrapFlex>
                                    <BlockWrap className="width_1-4">
                                        <WrapInput isRequired name="company" label={t("Company")}>
                                            <Input
                                                type="asyncSelect"
                                                placeholder={t("Search")}
                                                url="/admin/users/company-list"
                                                value={typeof item !== 'undefined' ? _.get(item, "company") : currentCompany}
                                                onChange={value => this._onChangeCompany(value)}
                                            />
                                        </WrapInput>
                                    </BlockWrap>
                                    <BlockWrap className="width_1-4">
                                        <WrapInput name="defaultPage" label={t("Landing Page")}>
                                            <Input
                                                type="select"
                                                options={landOptions}
                                                placeholder={t("Select")}
                                                value={typeof item !== 'undefined' ? _.get(item, "defaultPage") : "dashboard.index"}
                                                onChange={value => this._onChange(`defaultPage`, value)}
                                            />
                                        </WrapInput>
                                    </BlockWrap>
                                    <BlockWrap className="width_1-5">
                                        <WrapInput label={t("")} name="haveAccess">
                                            <CheckboxBlock>
                                                <CheckboxButton
                                                    htmlFor={`checkbox_have_access-${index}`}
                                                    value={Boolean(_.get(item, "haveAccess"))}
                                                    onChange={value => {
                                                        this._onChange("haveAccess", value)
                                                        if(!value) {
                                                            this._onChange("defaultCompany", false)
                                                        }
                                                    }}
                                                >
                                                    <CheckboxTitle title="Access to the Company" />
                                                </CheckboxButton>
                                            </CheckboxBlock>
                                        </WrapInput>
                                    </BlockWrap>
                                    <BlockWrap className="width_1-5">
                                        <WrapInput label={t("")} name="defaultCompany" className={!_.get(item, "haveAccess") ? ' disabled-permissions' : ''}>
                                            <CheckboxBlock>
                                                <CheckboxButton
                                                    htmlFor={`checkbox_default_company-${index}`}
                                                    value={Boolean(_.get(item, "defaultCompany"))}
                                                    isDisabled={!_.get(item, "haveAccess")}
                                                    onChange={value => this._onChange("defaultCompany", value)}
                                                >
                                                    <CheckboxTitle title="Set as default Company" />
                                                </CheckboxButton>
                                            </CheckboxBlock>
                                        </WrapInput>
                                    </BlockWrap>
                                </BlockWrapFlex>
                            </BlockShadow>
                        </Block>
                    </BlockMain>
                    {item ? <BlockMain title="Permissions">
                        <BlockShadow>
                            <div className={`user-permissions-tabs ${!_.get(item, "haveAccess") ? ' disabled-permissions' : ''}`}>
                                <Tabs
                                    activeTab={activeTab}
                                    onClickTabItem={i => {
                                        if (activeTab !== i) {
                                            onChangeTab(i);
                                        }
                                    }}
                                >
                                    <Tab label={t("Sitewide")}>
                                        <Sitewide index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Admin")}>
                                        <Admin index={index} t={t} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Product Finder")}>
                                        <ProductsFinder index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Analytics")}>
                                        <Analytics index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Forecast")}>
                                        <Forecasts index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Finance")}>
                                        <Finance index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Production")}>
                                        <Production index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Technologies")}>
                                        <Solutions index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Companies")}>
                                        <Companies index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Vendors")}>
                                        <Vendors index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Contacts")}>
                                        <Contacts index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Files")}>
                                        <File index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Legal")}>
                                        <Legal index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Profile")}>
                                        <Profile index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                    <Tab label={t("Video Guides")}>
                                        <Video index={index} team={team} onChangePermission={this._onChangePermission}/>
                                    </Tab>
                                </Tabs>
                            </div>
                        </BlockShadow>
                    </BlockMain> : null}
                </>
        );
    }
}

TeamBlock.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => ({
    team: getStoreItem(state, `team`),
    activeTab: getStoreItem(state, 'activeTab')
});

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        onChangeTab: getActionStore('onChangeTab', service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TeamBlock);
