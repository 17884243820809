import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getTableLabel = key => {
    const getDunsNumberData = () => {
        return 'D-U-N-S&#174; Number'
    }

    const tableLabel = {
        name: "Contact Name",
        title: "Title",
        company: "Company",
        customer_code: "SAP Company Code",
        duns_number: getDunsNumberData(),
        phone: "Phone",
        email: "Email",
        type: "Int/Ext",
        owner: "Owner",
        created_at: "Date of Creation",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholder = {
        name: "Search",
        title: "Search",
        company: "Search",
        customer_code: "Search",
        duns_number: "Search",
        phone: "Search",
        email: "Search",
        type: "All",
        owner: "Search",
        created_at: "Search",
    };
    switch (key) {
        case "created_at":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
