import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {Form, BlockImgWithCrop, BlockWrap, Block, Input, BlockMain, WrapInput, BlockShadow, Tooltip, BlockTitle} from "Templates/Form";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import AddressBlock from "./AddressBlock";
import ContactBlock from "./ContactBlock";
import SocialBlock from "./SocialBlock";

const propTypes = {
    firstName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    photo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    photoView: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onChange: PropTypes.func.isRequired,
};

const FormBlock = ({firstName, lastName, title, company, photoView, onChange, t}) => {
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    const onChangePhoto = (value, key) => {
        onChange(key, value);
        onChange('photoView', value);
    };

    return (
        <Form>
            <div className="wrapper_general_image">
                <div className="general-info-block">
                    <BlockMain title="Contact Information">
                        <div className="wrapper__form-block-content contact-general-info__wrap">
                            <BlockShadow>
                                <BlockWrap>
                                    <Block>
                                        <BlockWrap>
                                            <WrapInput isRequired label="First Name" name="firstName">
                                                <Input
                                                    placeholder="First Name"
                                                    value={firstName}
                                                    onChange={value => onChangeInput(value, "firstName")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                        <BlockWrap>
                                            <WrapInput isRequired label="Last Name" name="lastName">
                                                <Input
                                                    placeholder="Last Name"
                                                    value={lastName}
                                                    onChange={value => onChangeInput(value, "lastName")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                    </Block>
                                    <Block>
                                        <BlockWrap>
                                            <WrapInput label="Title" name="title">
                                                <Input
                                                    placeholder="Title"
                                                    value={title}
                                                    onChange={value => onChangeInput(value, "title")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                        <BlockWrap>
                                            <WrapInput isRequired label="Company" name="company">
                                                <Input
                                                    placeholder={t("Search")}
                                                    url="/contacts/company-list"
                                                    type="asyncSelect"
                                                    value={company}
                                                    onChange={value => onChangeInput(value, "company")}
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                    </Block>
                                </BlockWrap>
                                <ContactBlock />
                            </BlockShadow>
                        </div>
                    </BlockMain>
                </div>
                <div className="image-block">
                    <BlockTitle>
                        {t("Profile Picture ")}
                        <Tooltip>{t(`Recommended min image size 160 x 160px.`)}</Tooltip>
                    </BlockTitle>
                    <div className="wrapper__form-block-content">
                        <BlockShadow>
                            <BlockWrap>
                                <BlockImgWithCrop
                                    name="photo"
                                    value={photoView}
                                    title=""
                                    onChange={value => onChangePhoto(value, "photo")}
                                    textButton="Upload Photo"
                                />
                            </BlockWrap>
                        </BlockShadow>
                    </div>
                </div>
            </div>

            <div className="wrapper__form-block-content">
                <BlockTitle>{t("Address")}</BlockTitle>
                <BlockShadow>
                    <AddressBlock onChange={onChangeInput} />
                </BlockShadow>
            </div>

            <div className="wrapper__form-block-content row">
                <SocialBlock onChange={onChangeInput} />
            </div>
        </Form>
    );
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        firstName: getStoreItem(state, "firstName"),
        lastName: getStoreItem(state, "lastName"),
        title: getStoreItem(state, "title"),
        company: getStoreItem(state, "company"),
        photo: getStoreItem(state, "photo"),
        photoView: getStoreItem(state, "photoView"),
        validate: getStoreItem(state, "form_validate"),
        formData: state.createState,
    };
};

FormBlock.propTypes = propTypes;

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
