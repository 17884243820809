const Constants = {
    GET_FORECAST_DATA_REQUEST: "GET_FORECAST_DATA_REQUEST",
    GET_FORECAST_DATA_SUCCEEDED: "GET_FORECAST_DATA_SUCCEEDED",
    GET_FORECAST_DATA_FAILED: "GET_FORECAST_DATA_FAILED",

    CHANGE_FORECAST_REQUEST: "CHANGE_FORECAST_REQUEST",
    CHANGE_FORECAST_SUCCEEDED: "CHANGE_FORECAST_SUCCEEDED",
    CHANGE_FORECAST_FAILED: "CHANGE_FORECAST_FAILED",

    VEHICLE_BUILDS_REQUEST: "VEHICLE_BUILDS_REQUEST",
    VEHICLE_BUILDS_SUCCEEDED: "VEHICLE_BUILDS_SUCCEEDED",
    VEHICLE_BUILDS_FAILED: "VEHICLE_BUILDS_FAILED",

    RESET_FORECAST_REDUCER: "RESET_FORECAST_REDUCER",
};
export default Constants;
