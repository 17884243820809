import Constants from "../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const submitFormAction = (service, dispatch) => data => {
    dispatch({ type: Constants.FORM_REQUESTED });

    return new Promise((resolve, reject) => {
        const req = service.save;
        req(service.formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};
