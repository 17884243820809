import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import TableRowColumnDefault from "Templates/Table/TableRowColumnWrap/Default";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
};

const TableRowColumn = React.memo(({ index, secondaryIndex, column, item }) => {
    return (
        <TableRowColumnTemplate key={`TableRowColumn-${index}-${secondaryIndex}`}>
            {column === "-1" || column === "product_id" ? null : <TableRowColumnDefault column={column} item={item} />}
        </TableRowColumnTemplate>
    );
});

TableRowColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableRowColumn);
