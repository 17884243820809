import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import Img from "Templates/Img";
import TableBodyButton from "Templates/Table/TableBodyButton";
import { NoteMessage } from "../index";
import parse from 'html-react-parser';

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    handleClickOnEditButton: PropTypes.func.isRequired,
    handleClickOnDeleteButton: PropTypes.func.isRequired,
};

const NoteMessageWrap = ({ item, handleClickOnEditButton, handleClickOnDeleteButton }) => {
    const _openModal = item => {
        handleClickOnEditButton(item);
    };

    const _deleteModal = item => {
        handleClickOnDeleteButton(item);
    };

    return (
        <NoteMessage>
            <p>{item.comment ? parse(item.comment) : item.comment}</p>
                {item.can_edit ? (
                    <div className="note-btn-list">
                        <TableBodyButton onClick={() => _openModal(item)}>
                            <Img img="icon_edit" />
                        </TableBodyButton>
                        <TableBodyButton onClick={() => _deleteModal(item)}>
                            <Img img="icon_delete" />
                        </TableBodyButton>
                    </div>
                ) : null}
        </NoteMessage>
    );
};

NoteMessageWrap.propTypes = propTypes;

export default compose(withTagDefaultProps())(NoteMessageWrap);
