import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";

const {Button, Form, Title, Col, Row, ColText} = ModalDefault;

const propTypes = {
    onClose: PropTypes.func.isRequired,
    userFullName: PropTypes.string.isRequired,
};

const WelcomeSharedListGuestModal = (
    {
        onClose,
        userFullName,
        t,
    }
) => {
    const arrName = userFullName.split(' ');
    const userName = arrName !== [] ? arrName[0] : '';

    return (
        <div className="welcome-modal">
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose}/>
                <Title>{t(`Welcome to ComOpps Product Finder ${userName}!`)}</Title>
                <div className="middle">
                    <Row>
                        <Col>
                            You have been invited by Mitsubishi Chemical Group (MCG) to ComOpps to quickly and easily
                            view products that may be a perfect fit for your application.
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <ColText>
                                How it works:
                            </ColText>
                            <ul className="decimal">
                                <li>Lists shared with you are visible from the <strong>Shared Lists</strong> column.
                                </li>
                                <li>Each list contains one or more products for you to explore.</li>
                                <li>Your MCG representative can add and remove products from your Shared Lists view.
                                </li>
                                <li>Don’t see a list? You will receive an email from your MCG contact when a list is
                                    ready to view.
                                </li>
                                <li>Need to share the ComOpps Product Finder with your team? Contact your MCG
                                    representative.
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ColText>
                                Tips:
                            </ColText>
                            <ul className="decimal">
                                <li>
                                    Add <a href="/" className="main-table__body-link">mcg.comopps.com</a> to your favorites for quick access.
                                </li>
                                <li>
                                    Please do not share your account credentials.
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div className="bottom">
                    <Row>
                        <Col>
                            <ColText>
                                <strong>Questions?</strong>
                            </ColText>
                            <ColText>
                                Product Finder: Click the Support button in the top-right corner of the page.<br/>
                            </ColText>
                            <ColText>
                                Products: Contact your MCG representative.
                            </ColText>
                        </Col>
                    </Row>
                </div>
                <Button onClick={onClose}>
                    {t("Continue")}
                </Button>
            </Form>
        </div>
    );
}

WelcomeSharedListGuestModal.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {

    return {
        userFullName: getStoreItem(state, "userFullName"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(WelcomeSharedListGuestModal);
