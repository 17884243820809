import Constants from "../../Constants";

export const addActiveSubTabAction = (service, dispatch) => item => {
    const { requestTypeItem } = service;

    return requestTypeItem(item).then(
        res => dispatch({ type: Constants.CHANGE_ACTIVE_SUBTAB, data: res })
    );
};

export const saveCopyAction = (service, dispatch) => id => data => {
    const { requestOnSaveCopy } = service;

    return requestOnSaveCopy(id)(data).then(
        res => {
            dispatch({ type: Constants.SAVE_COPY_SUCCESS, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.SAVE_COPY_FAILED, data: res });
        },
    );
};

export const getSubTabs = (service, dispatch) => () => {
    const { requestSubTab } = service;

    return requestSubTab().then(
        res => dispatch({ type: Constants.FETCH_TECHNICAL_ITEMS_REQUESTED, data: res })
    );
};