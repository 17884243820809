import Constants from "../Constants";
import _ from "lodash";

export const initState = {
    loading: false,
    selectedFolderFiles: {
        items: [],
        pagination: {},
    },
    uploadVideoModal: false,
    editVideoModal: false,
    items: [],
    foldersPagination: {},
    sort: {},
}

export const reducer = (state, action) => {
    const {data} = action;

    switch (action.type) {
        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return { ...state, items: data[0].items, foldersPagination: data[0].pagination, ...data, loading: false };

        case Constants.SET_FOLDER_SUCCESS:
            return {
                ...state,
                selectedFolderFiles: {
                    items: data[0].items,
                    pagination: data[0].pagination
                },
                foldersPagination: data[0].pagination,
                ...data,
                loading: false };


        case Constants.FETCH_ITEMS_FAILED:
        case Constants.SET_FOLDER_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.CREATE_FOLDER_REQUEST:
        case Constants.EDIT_FOLDER_REQUEST:
        case Constants.EDIT_VIDEO_REQUEST:
        case Constants.SET_FOLDER_REQUEST:
        case Constants.UPLOAD_VIDEO_REQUEST:
            return { ...state, loading: true };

        case Constants.CREATE_FOLDER_SUCCESS:
        case Constants.EDIT_FOLDER_SUCCESS:
        case Constants.UPLOAD_VIDEO_FAILED:
            return { ...state, loading: false };

        case Constants.EDIT_VIDEO_SUCCESS:
            return { ...state, editVideoModal: false, loading: false };

        case Constants.OPEN_VIDEO_UPLOAD_MODAL:
            return { ...state, uploadVideoModal: true };

        case Constants.CLOSE_VIDEO_UPLOAD_MODAL:
            return { ...state, uploadVideoModal: false };

        case Constants.OPEN_EDIT_VIDEO_UPLOAD_MODAL:
            return { ...state, editVideoModal: true };

        case Constants.CLOSE_EDIT_VIDEO_UPLOAD_MODAL:
            return { ...state, editVideoModal: false };

        case Constants.UPLOAD_VIDEO_SUCCESS:
            return { ...state, uploadVideoModal: false, loading: false };

        case Constants.NEXT_PAGE:
            return { ...state, selectedFolderFiles: {
                    items: _.concat(state.selectedFolderFiles.items, data[0].items),
                    pagination: data[0].pagination
                }, loading: false };

        case Constants.NEXT_FOLDERS_PAGE:
            return { ...state, items: _.concat(state.items, data[0].items), foldersPagination: data[0].pagination, loading: false };

        default: {
            return state;
        }
    }
}