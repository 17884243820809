import React, {Component} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import AsyncSelectReact from "./AsyncSelectReact";
import {request, url as urlService} from "../index";

const propTypes = {
    disabled: PropTypes.bool,
    url: PropTypes.string,
    params: PropTypes.any,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    onMenuAction: PropTypes.func,
    value: PropTypes.string.isRequired,
    inputProps: PropTypes.shape({ isOptionDisabled: PropTypes.func }),
};

const defaultProps = {
    disabled: false,
    url: "",
    placeholder: "Search",
    params: null,
    inputProps: { isOptionDisabled: option => option.disable },
    handleChange: () => {
    },
    onMenuAction: () => {
    },
};

class AsyncCompareProductSelect extends Component {
    state = {
        value: false,
        isMenuOpen: false
    };

    componentDidMount = () => {
        const { value } = this.props;
        this.setValue(value);
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.value && !_.filter(this.refs.async.state.defaultOptions, this.state.value).length) {
            if (this.refs.async.state.defaultOptions) {
                this.refs.async.state.defaultOptions.splice(1, 0, this.state.value);
                this.refs.async.state.defaultOptions = _.sortBy(this.refs.async.state.defaultOptions, ['label']);
            }
        }
    }

    UNSAFE_componentWillReceiveProps = nextProps => {
        const { value } = this.state;
        if (value && nextProps.value !== value.value && nextProps.value === "") {
            this.setState({
                value: false,
            });
        }

        // TODO, bad code
        if (!value || nextProps.value !== value.value) {
            this.setValue(nextProps.value);
        }
    };

    handleChange = selectedOption => {
        this.setState(
            {
                value: selectedOption,
            },
            () => {
                const { handleChange } = this.props;
                handleChange(selectedOption || "");
            },
        );
    };

    getOptions = (url, params) => (data) => {
        if (url.includes("//")) {
            return new Promise(resolve => resolve([]));
        }

        return new Promise((resolve, reject) => {
            request.sendRequestWithNoCache(
                {
                    url: urlService.getUrl(url),
                    data: params && Object.keys(params).length ? params : data,
                    type: "GET",
                },
                false,
            )
            .then(res => {
                const options = _.get(res, "0.items", []);
                const selectOptions = options.map((option) => {
                    if (option.process_id) {
                        return {
                            label: option.name,
                            value: JSON.stringify({
                                label: option.name,
                                process_id: option.process_id,
                                publication_id: option.publication_id
                            })
                        }
                    }
                    return {
                        label: option.name,
                        value: JSON.stringify({
                            label: option.name,
                            datasheet_id: option.datasheet_id,
                            publication_id: option.publication_id
                        })
                    }
                })

                if (!selectOptions.filter(obj => !obj.value).length) {
                    options.unshift({ value: '', label: '-' });
                }

                resolve(selectOptions);
            }, reject);
        });
    };

    setValue = value => {
        const { url } = this.props;

        if (value && !url.includes("//")) {
            const val = JSON.parse(value);
            const searchLabel = val.label.substring(0, val.label.indexOf(' ('));
            const searchValue = val.datasheet_id ? JSON.stringify({
                datasheet_id: val.datasheet_id,
                publication_id: val.publication_id
            }) : JSON.stringify({
                process_id: val.process_id,
                publication_id: val.publication_id
            })

            this.getOptions(`${url}?label=${searchLabel}`)({}).then(options => {
                const selectOption = options.filter((option) => {
                    const parsedOption = JSON.parse(option.value)
                    const optionValue = parsedOption.datasheet_id ? {
                        datasheet_id: parsedOption.datasheet_id,
                        publication_id: parsedOption.publication_id
                    } : {
                        process_id: parsedOption.process_id,
                        publication_id: parsedOption.publication_id
                    }
                    return JSON.stringify(optionValue) === searchValue
                })

                this.setState({
                    value: _.last(selectOption) || {},
                });
            });
        }
    };

    render = () => {
        const { value, isMenuOpen } = this.state;
        const { url, params, disabled, inputProps, placeholder, onMenuAction } = this.props;

        const customStyles = {
            control: base => ({
                ...base,
            }),
        };

        const loadOptions = params ? this.getOptions(url, params) : this.getOptions(url);

        return (
            <div title={value.label} className="select-title">
                <AsyncSelectReact
                    ref='async'
                    isLoading
                    menuPosition="fixed"
                    // closeMenuOnScroll={e => !e.target.parentNode.className.includes("menu")}
                    closeMenuOnScroll={e => {
                        if (isMenuOpen) {
                            return !(e.target.parentNode || { className: '' }).className.includes("menu")
                        }
                    }}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    inputProps={inputProps}
                    styles={customStyles}
                    loadArguments={{ url }}
                    value={value}
                    cacheOptions
                    defaultOptions
                    onChange={this.handleChange}
                    onResetOptions={this.handleChange}
                    onMenuOpen={() => {
                        this.setState({
                            isMenuOpen: true
                        })
                        onMenuAction(true)
                    }}
                    onMenuClose={() => {
                        this.setState({
                            isMenuOpen: false
                        })
                        onMenuAction(false)
                    }}
                    loadOptions={label => loadOptions({ label })}
                />
            </div>
        );
    };
}

AsyncCompareProductSelect.propTypes = propTypes;
AsyncCompareProductSelect.defaultProps = defaultProps;

export default AsyncCompareProductSelect;
