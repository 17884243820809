import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {Input} from "Templates/Form";
import Img from "Templates/Img/Img";
import {InputFilter} from "Templates/Table/Filters";
import {
    datesFilters,
    emptyNotAllowedFilters,
    FiltersColumnsUrls,
    isFiltersList,
    onlyYesNoFilters,
    tableColumnsFiltersList,
    tableColumnsFiltersOperators
} from "../Constants/tableFiltersList";
import GetProjectFilters from "../Constants/getProjectFilters";
import {getSelectedFiltersItems} from "../Services/RequestService";
import FilterComponent from "./filterValue";
import selfService from "../Services/Tabs/Self";
import allService from "../Services/Tabs/All";
import archiveService from "../Services/Tabs/Archived";
import selfErrorService from "../Services/Tabs/Errors/Self";
import allErrorService from "../Services/Tabs/Errors/All";

const FiltersBlock = ({isResetProjectFilters, sort, columns, total_analytics, onApplyFilters, resetProjectFilters}) => {
    const [filtersModal, setFiltersModal] = useState(false)
    const [disabledApplyFiltersButton, setDisabledApplyFiltersButton] = useState(true)
    const [selectedFilter, setSelectedFilter] = useState('')
    const [selectedFilterOperator, setSelectedFilterOperator] = useState(tableColumnsFiltersOperators[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState('')
    const [filtersItems, setFiltersItems] = useState([])

    useEffect(() => {
        if (isResetProjectFilters) {
            setFiltersItems([])
            onApplyFilters([])
            setFiltersModal(false)
            resetProjectFilters(false)
        }
    }, [isResetProjectFilters])

    useEffect(() => {
        if (selectedFilter && (selectedFilterOperator === 'empty' || selectedFilterOperator === 'not_empty')) {
            setDisabledApplyFiltersButton(false)

            return
        }

        if (!selectedFilter || !selectedFilterOperator || !selectedFilterValue) {
            setDisabledApplyFiltersButton(true)

            return
        }

        setDisabledApplyFiltersButton(false)
    }, [selectedFilter, selectedFilterOperator, selectedFilterValue])

    useEffect(() => {
        if (window) {
            const url = new URL(window.location);
            const filtersString = url.searchParams.get('filters');

            if (filtersString) {
                const parsedFilters = JSON.parse(filtersString);

                setFiltersItems(parsedFilters);
                onApplyFilters(parsedFilters);
                setFiltersModal(true)
            }
        }
    }, []);

    useEffect(() => {
        if (window) {
            const url = new URL(window.location);

            if (filtersItems && filtersItems.length > 0) {
                const filtersString = JSON.stringify(filtersItems);
                url.searchParams.set('filters', filtersString);
            } else {
                url.searchParams.delete('filters');
            }

            if (sort && sort.column && sort.direction) {
                url.searchParams.set('sort[column]', sort.column);
                url.searchParams.set('sort[direction]', sort.direction);
            } else {
                url.searchParams.delete('sort[column]');
                url.searchParams.delete('sort[direction]');
            }

            window.history.pushState({}, '', url);
        }
    }, [filtersItems, sort]);

    const setFilters = () => {
        if (disabledApplyFiltersButton) return;

        setTimeout(() => {
            setFiltersItems((prev) => {
                const filterIndex = prev.findIndex(item =>
                    item.filter === selectedFilter && item.operator === selectedFilterOperator
                );

                if (filterIndex !== -1) {
                    if (Array.isArray(prev[filterIndex].value)) {
                        prev[filterIndex] = {
                            ...prev[filterIndex],
                            value: [...prev[filterIndex].value, ...selectedFilterValue]
                        }
                    } else {
                        prev[filterIndex].value = selectedFilterValue
                    }

                    return [...prev];
                }

                return [...prev, {filter: selectedFilter, operator: selectedFilterOperator, value: selectedFilterValue}];
            });

            setSelectedFilterValue('');

            const filterIndex = filtersItems.findIndex(item =>
                item.filter === selectedFilter && item.operator === selectedFilterOperator
            );

            if (filterIndex !== -1) {
                return onApplyFilters([...filtersItems]);
            }

            onApplyFilters([...filtersItems, {
                filter: selectedFilter,
                operator: selectedFilterOperator,
                value: selectedFilterValue
            }]);
        }, 1)
    };

    const removeFilter = (filterItem, filterItemValue) => {
        if (Array.isArray(filterItem.value) && filterItem.value.length > 1 && filterItemValue) {
            setFiltersItems((prev) => {
                const removableIndex = prev.findIndex((item) => item.filter === filterItem.filter)
                if (removableIndex < 0) return prev
                prev[removableIndex] = {
                    ...filterItem,
                    value: filterItem.value.filter((item) => item !== filterItemValue)
                }
                return [...prev]
            })

            const removableIndex = filtersItems.findIndex((item) => item.filter === filterItem.filter)
            if (removableIndex < 0) return onApplyFilters(filtersItems)
            filtersItems[removableIndex] = {
                ...filterItem,
                value: filterItem.value.filter((item) => item !== filterItemValue)
            }
            onApplyFilters([...filtersItems])

            return
        }

        setFiltersItems((prev) => {
            return prev.filter((item) => JSON.stringify(item) !== JSON.stringify(filterItem))
        })

        onApplyFilters(filtersItems.filter((item) => JSON.stringify(item) !== JSON.stringify(filterItem)))
    }

    const getFilterName = (filterValue) => {
        const searchFilter = tableColumnsFiltersList.find((tableColumnFilter) => tableColumnFilter.value === filterValue)
        if (searchFilter) {
            return searchFilter.label
        }

        return filterValue
    }

    const getFilterOperator = (filterOperator) => {
        return filterOperator.replace(/_/g, ' ').trim();
    }

    const getFilterLabel = (filterName, value, operator) => {
        if (operator && operator.includes('empty')) {
            return ''
        }

        if (value === 10) {
            return 'Yes'
        }

        if (value === 5 || value == 0) {
            return 'No'
        }

        if (value.from && value.to) {
            return `${value.from} - ${value.to}`
        }

        if (value.from && !value.to) {
            return value.from
        }

        if (!value.from && value.to) {
            return value.to
        }

        return value
    }

    return (
        <>
            <div className="new-projects__filters">
                <div className={`new-projects__filters__modal ${filtersModal ? 'visible' : 'hidden'}`}>
                    <div className="new-projects__filters__modal-title">
                        <p>Filters</p>
                        <ButtonClose
                            className="main-modal__form-close"
                            onClick={() => {
                                setFiltersItems([])
                                onApplyFilters([])
                                setFiltersModal(false)
                            }}
                        />
                    </div>
                    <div className="new-projects__filters__modal-body">
                        <div className="selected-filter new-projects__filters__filter">
                            <Input
                                name="selectedFilter"
                                type="searchSelect"
                                value={selectedFilter}
                                placeholder="Filter"
                                onChange={value => {
                                    setSelectedFilterOperator(tableColumnsFiltersOperators[0].value)
                                    setSelectedFilterValue('')

                                    setSelectedFilter(value)
                                }}
                                options={tableColumnsFiltersList.sort((a, b) => {
                                    const prevLabel = a.label.toLowerCase()
                                    const nextLabel = b.label.toLowerCase()

                                    if (prevLabel < nextLabel) return -1;
                                    if (prevLabel > nextLabel) return 1;
                                    return 0;
                                }).filter(item =>
                                    columns.includes(item.value)
                                    )}
                            />
                        </div>
                        <div className="selected-filter-operator new-projects__filters__filter">
                            <Input
                                name="selectedFilterOperator"
                                type="select"
                                disabled={!selectedFilter}
                                value={selectedFilterOperator}
                                onChange={value => {
                                    if (!selectedFilter) return
                                    setSelectedFilterValue('')
                                    setSelectedFilterOperator(value)
                                }}
                                options={
                                    datesFilters.includes(selectedFilter) || onlyYesNoFilters.includes(selectedFilter)
                                        ? tableColumnsFiltersOperators.filter((item) => item.value === 'is')
                                        : emptyNotAllowedFilters.includes(selectedFilter)
                                            ? tableColumnsFiltersOperators.filter((item) => item.value !== 'empty' && item.value !== 'not_empty')
                                            : tableColumnsFiltersOperators
                                }
                            />
                        </div>
                        {selectedFilter && (selectedFilterOperator === 'is' || selectedFilterOperator === '_contains') ? (
                            <div className="selected-filter-value new-projects__filters__filter">
                                {
                                    (isFiltersList.includes(selectedFilter) && selectedFilterOperator === 'is') || onlyYesNoFilters.includes(selectedFilter)
                                        ? (
                                            <GetProjectFilters
                                                name="selectedFilterValue"
                                                selectedFilter={selectedFilter}
                                                value={selectedFilterValue}
                                                onChange={(e) => {
                                                    setSelectedFilterValue(e)
                                                }}
                                                totalAnalytics={total_analytics}
                                            />
                                        ) : (
                                            <InputFilter
                                                type="text"
                                                placeholder="Enter Filter Value"
                                                value={selectedFilterValue}
                                                onChange={(e) => {
                                                    setSelectedFilterValue(e)
                                                }}
                                            />
                                        )
                                }
                            </div>
                        ) : null}
                        <button
                            className="apply-filters"
                            disabled={disabledApplyFiltersButton}
                            onClick={setFilters}
                        >
                            Apply Filter
                        </button>
                    </div>
                    {filtersItems ? (
                        <div className="connections-filters on-projects">
                            <div className="connections-filters__chips-wrap">
                                <div className="connections-filters__chips">
                                    {filtersItems.length > 0 ? filtersItems.map((filterItem, i) => {
                                        return (
                                            <div
                                                key={`${filterItem.filter}_${i}`}
                                                className="connections-filters__chips-item"
                                            >
                                                <p>
                                                    {getFilterName(filterItem.filter)}
                                                    :
                                                </p>
                                                {Array.isArray(filterItem.value) ? filterItem.value.map((filterItemValue, i) => (
                                                    <div
                                                        key={`filter-value-${filterItem.column}-${i}`}
                                                        className="connections-filters__chips-item__chip"
                                                    >
                                                        <span style={{fontWeight: '400'}}>{getFilterOperator(filterItem.operator)}</span>
                                                        {
                                                            filterItemValue.label
                                                            ? <span>{getFilterLabel(filterItem.filter, filterItemValue.label, filterItem.operator)}</span>
                                                            : <FilterComponent filterName={filterItem.filter} filterItemValue={filterItemValue} />
                                                        }
                                                        <button
                                                            type="button"
                                                            onClick={() => removeFilter(filterItem, filterItemValue)}
                                                        >
                                                            <Img img="icon_remove_filter"/>
                                                        </button>
                                                    </div>
                                                )) : (
                                                    <div className="connections-filters__chips-item__chip">
                                                        <span style={{fontWeight: '400'}}>{getFilterOperator(filterItem.operator)}</span>
                                                        <span>{getFilterLabel(filterItem.filter, filterItem.value, filterItem.operator)}</span>
                                                        <button
                                                            type="button"
                                                            onClick={() => removeFilter(filterItem, null)}
                                                        >
                                                            <Img img="icon_remove_filter"/>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {!filtersModal ? (
                    <button
                        type="button"
                        className="new-projects__filters__button"
                        onClick={() => {
                            setFiltersModal(true)
                        }}
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.33333 15V13.3333H11.6667V15H8.33333ZM5 10.8333V9.16667H15V10.8333H5ZM2.5 6.66667V5H17.5V6.66667H2.5Z"
                                fill="#3487DB"
                            />
                        </svg>
                        Filters
                    </button>
                ) : null}
            </div>
        </>
    )
}

const mapStateToProps = () => {
};

const mapDispatchToProps = () => {

};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FiltersBlock);