import React, {useEffect, useMemo, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ModalDefault} from "Templates/Modals";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import {Preloader} from "Templates/Preloader";
import VideoList from "Services/VideoUpload/VideoList";
import VideoThumbNail from "Services/VideoUpload/VideoThumbNail";
import {getPreSignedUrl, getVideoPreview} from "../Services/RequestService";
import Img from "Templates/Img/Img";

const {Button, Form, Title, ColInput} = ModalDefault;

const propTypes = {
    onClose: PropTypes.func.isRequired,
};

const EditUploadVideoForm = ({t, folderId, editingVideo, editFile, onClose}) => {
    const [files, setFiles] = useState([])
    const [videoFiles, setVideoFiles] = useState([])
    const [title, setTitle] = useState(editingVideo.name)
    const [description, setDescription] = useState(editingVideo.description)
    const [disabled, setDisabled] = useState(true)

    const [loader, setLoader] = useState(false)

    const [staticVideoUrl, setStaticVideoUrl] = useState('')
    const [videoUrl, setVideoUrl] = useState('')
    const [imageUrl, setImageUrl] = useState('')

    const videoMemo = useMemo(() => {
        if (!files.length) return ''
        return URL.createObjectURL(files[0])
    }, [files])

    useEffect(() => {
        setImageUrl(editingVideo.thumbnail_path)
    }, [editingVideo.thumbnail_path])

    useEffect(() => {
        getVideoPreview(editingVideo.id).then((res) => {
            if (res && res[0] && res[0].presignedUrl) {
                setVideoUrl(res[0].presignedUrl)
                setStaticVideoUrl(res[0].presignedUrl)
            }
        })
    }, [editingVideo.id])

    useEffect(() => {
        const oldForm = `${staticVideoUrl}${editingVideo.thumbnail_path}${editingVideo.name.trim()}${editingVideo.description.trim()}`
        const newForm = `${videoUrl}${imageUrl}${title.trim()}${description.trim()}`
        const changes = oldForm.trim() === newForm.trim()

        setDisabled(!title.trim() || !description.trim() || changes || (!videoUrl && !files.length) || (!imageUrl && !videoFiles.length))
    }, [files, videoFiles, title, description, videoUrl, imageUrl, staticVideoUrl])

    const onChange = value => {
        setFiles([...value])
    };

    const onChangeThumb = value => {
        setVideoFiles([...value])
    };

    const handleCloseModal = event => {
        event.preventDefault();
        onClose();
    };

    const saveEditVideoWithVideoFile = () => {
        try {
            const headers = new Headers()
            headers.set('Content-Type', files[0].type)
            headers.set('Cache-Control', 'no-cache')

            const formData = new FormData();
            formData.append("files[]", videoFiles[0])
            getPreSignedUrl(files[0].name).then((result) => {
                if (!result) {
                    return
                }
                const {path, presignedUrl} = result[0]

                fetch(presignedUrl, {
                    headers,
                    method: 'PUT',
                    body: files[0]
                }).then(() => {
                    const payload = {
                        folderId,
                        id: editingVideo.id,
                        attachment_path: path,
                        thumbnail_id: editingVideo.thumbnail_id,
                        attachment_name: title,
                        attachment_description: description,
                    }

                    editFile(payload)
                    setLoader(false)
                })
            })
        } catch (err) {
            setLoader(false)
            alert(err)
        }
    }

    const saveEditVideoWithImage = () => {
        try {

            const imageHeaders = new Headers()
            imageHeaders.set('Content-Type', videoFiles[0].type)
            imageHeaders.set('Cache-Control', 'no-cache')

            const formData = new FormData();
            formData.append("files[]", videoFiles[0])

            fetch(`/api/files/add`, {
                imageHeaders,
                method: 'POST',
                body: formData
            }).then(async (r) => {
                if (!r) return

                const response = await r.json()
                if (!response) return

                const payload = {
                    folderId,
                    id: editingVideo.id,
                    attachment_path: editingVideo.path,
                    thumbnail_id: response.message.id,
                    attachment_name: title,
                    attachment_description: description,
                }

                editFile(payload)
                setLoader(false)
            })
        } catch (err) {
            setLoader(false)
            alert(err)
        }
    }

    const saveEditVideoWithFiles = () => {
        try {
            const headers = new Headers()
            headers.set('Content-Type', files[0].type)
            headers.set('Cache-Control', 'no-cache')

            const imageHeaders = new Headers()
            headers.set('Content-Type', videoFiles[0].type)
            headers.set('Cache-Control', 'no-cache')

            const formData = new FormData();
            formData.append("files[]", videoFiles[0])
            getPreSignedUrl(files[0].name).then((result) => {
                if (!result) {
                    return
                }
                const {path, presignedUrl} = result[0]

                fetch(`/api/files/add`, {
                    imageHeaders,
                    method: 'POST',
                    body: formData
                }).then(async (r) => {
                    if (!r) return

                    const response = await r.json()
                    if (!response) return

                    fetch(presignedUrl, {
                        headers,
                        method: 'PUT',
                        body: files[0]
                    }).then(() => {
                        const payload = {
                            folderId,
                            id: editingVideo.id,
                            attachment_path: path,
                            thumbnail_id: response.message.id,
                            attachment_name: title,
                            attachment_description: description,
                        }

                        editFile(payload)
                        setLoader(false)
                    })
                })
            })
        } catch (err) {
            setLoader(false)
            alert(err)
        }
    }

    const handleClickOnSubmit = () => {
        if (videoUrl && imageUrl) {
            const payload = {
                folderId,
                id: editingVideo.id,
                attachment_path: editingVideo.path,
                thumbnail_id: editingVideo.thumbnail_id,
                attachment_name: title,
                attachment_description: description,
            }

            editFile(payload)

            return
        }

        setLoader(true)

        if ((!videoUrl && files.length) && imageUrl) {
            saveEditVideoWithVideoFile()
        }

        if (videoUrl && (!imageUrl && videoFiles.length)) {
            saveEditVideoWithImage()
        }

        if (!files.length || !videoFiles.length) return

        saveEditVideoWithFiles()
    };

    const removeOldVideo = () => {
        setVideoUrl('')
    }

    const removeOldThumb = () => {
        setImageUrl('')
    }

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={handleCloseModal}/>
            <Title>{t("Edit Video")}</Title>
            <div className="upload-video-form__wrap">
                <div className="upload-video-form__wrap-files">
                    <div className="upload-video-form__wrap-video">
                        <p className="upload-video-form__file-label">Video</p>
                        {videoUrl ? (
                            <div className="edit-video-preview">
                                <video src={videoUrl} controls controlsList="nodownload" />
                                <button
                                    type="button"
                                    className="video-item__preview-delete-btn main-circle"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        removeOldVideo()
                                    }}
                                >
                                    <Img img="icon_delete"/>
                                </button>
                            </div>
                        ) : null}
                        {!videoUrl ? (
                            <div className="edit-video-preview">
                                {videoMemo ? <video controls controlsList="nodownload">
                                    <source
                                        src={videoMemo}
                                        type="video/webm"
                                    />
                                </video> : null}
                                <VideoList
                                    name="files"
                                    files={files}
                                    dropFile={e => {
                                        onChange(e)
                                    }}
                                    handleDrop={e => onChange([...files, ...e])}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="upload-video-form__wrap-image">
                        <p className="upload-video-form__file-label">Preview Image</p>
                        {imageUrl
                            ? <div className="edit-video-preview">
                                <img src={imageUrl} alt="Thumbnail Preview"/>
                                <button
                                    type="button"
                                    className="video-item__preview-delete-btn main-circle"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        removeOldThumb()
                                    }}
                                >
                                    <Img img="icon_delete"/>
                                </button>
                            </div>
                            : null}

                        {!imageUrl
                            ? <>
                                {videoFiles.length > 0 ? <img src={URL.createObjectURL(videoFiles[0])} alt="Thumbnail Preview"/> : null}
                                <VideoThumbNail
                                    name="thumbNail"
                                    videoFiles={videoFiles}
                                    dropFile={e => {
                                        onChangeThumb(e)
                                    }}
                                    handleDrop={e => onChangeThumb([...videoFiles, ...e])}
                                />
                            </>
                            : null}
                    </div>
                </div>
                <div className="upload-video-form__wrap-fields">
                    <div className="upload-video-form__wrap-field">
                        <p>
                            Video Title
                            <span>*</span>
                        </p>
                        <ColInput
                            placeholder={t("Enter Title")}
                            name="title"
                            value={title}
                            onChange={value => {
                                setTitle(value.substr(0, 255))
                            }}
                        />
                    </div>
                    <div className="upload-video-form__wrap-field">
                        <p>
                            Description
                            <span>*</span>
                        </p>
                        <ColInput
                            placeholder={t("Enter Description")}
                            name="description"
                            value={description}
                            onChange={value => {
                                setDescription(value.substr(0, 255))
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="upload-video-form__wrap-button">
                <Button onClick={handleClickOnSubmit} disabled={disabled}>
                    {t("Save")}
                </Button>
            </div>
            {loader ? <Preloader/> : null}
        </Form>
    );
}

EditUploadVideoForm.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        editFile: getActionStore("editFileAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(EditUploadVideoForm);
