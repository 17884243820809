import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import {url} from "Services";
import Tab from "Templates/Tabs/Tab";
import InfoWrap from "./Views/InfoWrap";

import _technicalGradesService from "./Services/Tabs/Technical";
import _designGradesService from "./Services/Tabs/Design";
import _processingGradesService from "./Services/Tabs/Processing";
import _safetyService from "./Services/Tabs/Safety";
import _brochureService from "./Services/Tabs/Brochure";

import Technical from "./Views/Tabs/Technical";
import Processing from "./Views/Tabs/Processing";
import Design from "./Views/Tabs/Design";
import Safety from "./Views/Tabs/Safety";
import Brochure from "./Views/Tabs/Brochure";
import {Modal} from "Templates/Modals";
import {Button} from "Templates/Default";
import Img from "Templates/Img";
import AddSharedProductsPublicationListModal from "./Views/Modals/AddPublicationToExistingList";

const Publication = ({
                         id,
                         activeTab,
                         t,
                         onChangeTab,
                         activeSubTab,
                         permissions,
                         addSharedProductsPublicationListShowModal,
                         openAddPublicationListModal,
                         totalTechnicals,
                         totalProcesses,
                         totalDesigns,
                         totalSafeties,
                         totalBrochures,
                         handleCloseAddSharedProductsPublicationsToList
                     }) => {
    const [selectedPublication, setSelectedPublication] = useState(0)

    useEffect(() => {
        switch (activeTab) {
            case 0:
                setSelectedPublication(6)
                return
            case 1:
                setSelectedPublication(5)
                return;
            case 2:
                setSelectedPublication(10)
                return;
            case 3:
                setSelectedPublication(7)
                return;
            case 4:
                setSelectedPublication(8)
                return;
            default:
                setSelectedPublication(6)
        }
    }, [activeTab])

    const onClickEdit = () => {
        url.redirect(`/product/publications/${id}/edit`);
    };

    const technicalService = _technicalGradesService(id, activeSubTab.id);
    const processingService = _processingGradesService(id, activeSubTab.id);
    const designService = _designGradesService(id, activeSubTab.id);
    const safetyService = _safetyService(id, activeSubTab.id);
    const brochureService = _brochureService(id, activeSubTab.id);

    return <>
        <ContentWrap>
            <InfoWrap activeTab={activeTab} onClickEdit={onClickEdit}/>
            <ContentWrapCol>
                <CheckPermission permission="product_publication_groups_edit">
                    <Button onClick={openAddPublicationListModal}
                             className="size_normal main-btn add-to-list-btn main-btn_white">
                        <span>{t('Add to List')}</span>
                        <Img img={'icon_add_to_list'} width="24" height="24"/>
                    </Button>
                </CheckPermission>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index) => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Technical")} counter={totalTechnicals}>
                        <ServiceProvider value={technicalService}>
                            <Technical permissions={permissions}/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Processing")} counter={totalProcesses}>
                        <ServiceProvider value={processingService}>
                            <Processing permissions={permissions}/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Guides")} counter={totalDesigns}>
                        <ServiceProvider value={designService}>
                            <Design permissions={permissions}/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Safety")} counter={totalSafeties}>
                        <ServiceProvider value={safetyService}>
                            <Safety/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label={t("Brochure")} counter={totalBrochures}>
                        <ServiceProvider value={brochureService}>
                            <Brochure/>
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
        {addSharedProductsPublicationListShowModal ? <Modal onRequestClose={handleCloseAddSharedProductsPublicationsToList}>
            <AddSharedProductsPublicationListModal filters={{ publicationIds: id }} onClose={handleCloseAddSharedProductsPublicationsToList} />
        </Modal> : null}
    </>;
};

Publication.defaultProps = {
    activeSubTab: {id: null},
}
Publication.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeTab: PropTypes.func.isRequired,
    handleCloseAddSharedProductsPublicationsToList: PropTypes.func.isRequired,
    activeSubTab: PropTypes.shape({id: null}),
    addSharedProductsPublicationListShowModal: PropTypes.bool.isRequired,
    openAddPublicationListModal: PropTypes.func.isRequired,
    totalTechnicals: PropTypes.number.isRequired,
    totalProcesses: PropTypes.number.isRequired,
    totalDesigns: PropTypes.number.isRequired,
    totalSafeties: PropTypes.number.isRequired,
    totalBrochures: PropTypes.number.isRequired,
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
        activeSubTab: getStoreItem(state, "activeSubTab") || {id: null},
        addSharedProductsPublicationListShowModal: getStoreItem(state, "addSharedProductsPublicationListShowModal"),
        totalTechnicals: getStoreItem(state, "totalTechnicals"),
        totalProcesses: getStoreItem(state, "totalProcesses"),
        totalDesigns: getStoreItem(state, "totalDesigns"),
        totalSafeties: getStoreItem(state, "totalSafeties"),
        totalBrochures: getStoreItem(state, "totalBrochures"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        openAddPublicationListModal: getActionStore("openAddPublicationListModal", service, dispatch),
        handleCloseAddSharedProductsPublicationsToList: getActionStore("handleCloseAddSharedProductsPublicationsToList", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Publication);
