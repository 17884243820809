export const generateDatasets = (data, selectedValue) => {
    const defaultOptionInDatasets = {
        type: "line",
        pointRadius: 2,
        borderWidth: 2,
        fill: 'none',
    };

    const getColors = status => {
        switch (selectedValue) {
            case "lead":
            case "level1":
                return {
                    borderColor: "rgba(119, 140, 162, 1)",
                    backgroundColor: "rgba(119, 140, 162, 0.5)",
                };

            case "opportunity":
            case "level2":
                return {
                    borderColor: "rgba(255, 171, 0, 1)",
                    backgroundColor: "rgba(255, 171, 0, 0.5)",
                };

            case "target":
            case "level3":
                return {
                    borderColor: "rgba(45, 156, 219, 1)",
                    backgroundColor: "rgba(45, 156, 219, 0.5)",
                };

            case "current":
            case "level4":
                return {
                    borderColor: "rgba(39, 174, 96, 1)",
                    backgroundColor: "rgba(39, 174, 96, 0.5)",
                };

            case "current_target":
                return {
                    borderColor: "rgb(245,108,107)",
                    backgroundColor: "rgba(245, 108, 107, 0.5)",
                };

            case "all":
            case "business-all":
                const colorsForAll = {
                    10: {
                        borderColor: "rgba(119, 140, 162, 1)",
                        backgroundColor: "rgba(119, 140, 162, 0.5)",
                    },
                    9: {
                        borderColor: "rgba(255, 171, 0, 1)",
                        backgroundColor: "rgba(255, 171, 0, 0.5)",
                    },
                    8: {
                        borderColor: "rgba(45, 156, 219, 1)",
                        backgroundColor: "rgba(45, 156, 219, 0.5)",
                    },
                    7: {
                        borderColor: "rgba(39, 174, 96, 1)",
                        backgroundColor: "rgba(39, 174, 96, 0.5)",
                    },
                    6: {
                        borderColor: "rgba(245, 108, 107, 1)",
                        backgroundColor: "rgba(245, 108, 107, 0.5)",
                    },

                    4: {
                        borderColor: "rgba(39, 174, 96, 1)",
                        backgroundColor: "rgba(39, 174, 96, 0.5)",
                    },
                    3: {
                        borderColor: "rgba(45, 156, 219, 1)",
                        backgroundColor: "rgba(45, 156, 219, 0.5)",
                    },
                    2: {
                        borderColor: "rgba(255, 171, 0, 1)",
                        backgroundColor: "rgba(255, 171, 0, 0.5)",
                    },
                    1: {
                        borderColor: "rgba(119, 140, 162, 1)",
                        backgroundColor: "rgba(119, 140, 162, 0.5)",
                    },
                };
                return colorsForAll[status];

            default:
                return {
                    borderColor: "rgba(238, 4, 105, 1)",
                    backgroundColor: "rgba(238, 4, 105, 0.5)",
                };
        }
    };

    const getData = (type) => {
        const dataItems = [];
        Object.values(data).forEach(items => {
            switch (type) {
                case 'lead':
                case 'level1':
                    items.filter((item) => item.status === 10).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                case 'opportunity':
                case 'level2':
                    items.filter((item) => item.status === 9).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                case 'target':
                case 'level3':
                    items.filter((item) => item.status === 8).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                case 'current':
                case 'level4':
                    items.filter((item) => item.status === 7).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                case 'current_target':
                    items.filter((item) => item.status === 6).forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
                    break;
                default:
                    items.forEach((item, index) => {
                        if (!dataItems[index]) {
                            dataItems[index] = {
                                ...defaultOptionInDatasets,
                                ...getColors(item.status),
                                id: item.id,
                                label: item.name,
                                data: [Number(item.sum)]
                            };
                        } else {
                            dataItems[index].data = [...dataItems[index].data, Number(item.sum)];
                        }
                    });
            }
        });

        return Object.values(dataItems)
    }

    const mapChartData = () => {
        return getData(selectedValue)
    }

    return mapChartData();
};

export const getLabels = data => Object.keys(data);
