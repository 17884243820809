// eslint-disable-next-line import/no-extraneous-dependencies
import $ from "jquery";
import _ from "lodash";

export default function UrlComopps() {
    this.url = new URLSearchParams(window.location.search);

    this.add = (key, value) => {
        this.url.append(key, value);
    };

    this.addToggle = (key, value) => {
        this.url.set(key, value);
    };

    this.remove = key => {
        this.url.delete(key);
    };

    this.get = key => {
        return this.url.get(key);
    };

    this.getAll = key => {
        return this.url.getAll(key);
    };

    this.href = () => {
        return `${window.location.origin + window.location.pathname}?${this.url.toString()}`;
    };

    this.path = () => {
        return window.location.pathname;
    };

    this.origin = () => {
        return window.location.origin;
    };

    this.getUrl = (str, params = [], api = "/api") => {
        const queryParams = $.param(params);
        str += queryParams ? `?${queryParams}` : '';

        if (_.startsWith(str, "/")) {
            str = str.substr(1);
        }

        return `${window.location.origin}${api}/${str}`;
    };

    this.getParams = () => {
        return this.url.toString();
    };

    this.getQueryParam = (name) => {
        return this.url.get(name);
    };

    this.getActiveTab = () => {
        return +this.getQueryParam('activeTab');
    };

    this.replaceHistory = (params) => {
        const queryParams = $.param(params);
        window.history.replaceState({}, '', queryParams ? `?${queryParams}` : '');
    };

    this.addHistory = (params) => {
        const queryParams = $.param(params);
        window.history.pushState({}, '', queryParams ? `?${queryParams}` : '');
    };

    this.clearHistory = () => {
        window.history.pushState({}, '', '');
    };

    this.redirect = url => {
        document.location.href = url;
    };

    this.hrefWithoutParams = () => {
        return window.location.origin + window.location.pathname;
    };

    this.referrer = (defaultUrl = '/') => {
        return document.referrer || defaultUrl
    }
}

const combineSearchParams = (...searchParams) => {
    const result = new URLSearchParams();

    searchParams.forEach(params => {
        const entries = params.entries();
        // eslint-disable-next-line no-restricted-syntax
        for (const value of entries) {
            result.append(value[0], value[1]);
        }
    });

    return result;
};

export function buildSearchUrl(path, ...searchParams) {
    const sp = searchParams.map(p => (p instanceof URLSearchParams ? p : new URLSearchParams(p)));
    const params = combineSearchParams(...sp);
    const fullPath = `${window.location.origin}${path}`;
    let ret;

    if (Array.from(params).length > 0) {
        ret = `${fullPath}?${params.toString()}`;
    } else {
        ret = `${fullPath}`;
    }
    return ret;
}

export function getFiltersObj(filters) {
    const ret = new URLSearchParams();

    if (!filters) {
        return ret;
    }

    return filters;
}

export function checkProtocol(url) {
    if (typeof url !== "string" || url.length === 0) {
        return "";
    }

    if (url.startsWith("https://") || url.startsWith("http://")) {
        return url;
    }

    return `https://${url}`;
}

export const storageUrl = (href, storage_url) => {
    if (href && !_.startsWith(href, "http")) {
        href = `${storage_url}/${href}`;
    }
    return href;
};
