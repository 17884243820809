import { getNumber, getNumberRoundToUpper } from "Services";

export const changeCostData = (state, value) => {
    const {
        salesPrice: { actual },
    } = state;
    const grossMargin = getNumber(value - actual.goods_sold);
    const grossMarginInPercent = getNumberRoundToUpper((1 - (actual.goods_sold / value)) * 100, 1);
    const operatingMargin = getNumber(grossMargin - actual.sga);
    const operatingMarginInPercent = getNumberRoundToUpper((1 - (Number(actual.goods_sold) + Number(actual.sga)) / getNumber(value).toFixed(2)) * 100);
    return {
        ...state,
        ifThen: {
            grossMargin,
            grossMarginInPercent,
            operatingMargin,
            operatingMarginInPercent,
        },
    };
};
