import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import Graph from "./Graph";

const propTypes = {
    graphV2: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({ getChartData: PropTypes.func.isRequired, getStoreItem: PropTypes.func.isRequired })
        .isRequired,
};

const DataDashboardCard = ({ pref, graphV2 }) => {
    return (
        <div className={`${pref}__card-data`}>
            {Object.keys(graphV2).length > 0 ? <Graph graphV2={graphV2} /> : null}
        </div>
    );
};

DataDashboardCard.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        graphV2: getStoreItem(state, "graphV2"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DataDashboardCard);
