import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ContentInfoWrap} from "Templates/Content";
import {numberWithCommas} from "Services";
import {Title, Total} from "Templates/Titles";
import ApprovalFilters from "./Filters/Approval";

const defaultProps = {
    help: '',
}

const propTypes = {
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    request: PropTypes.objectOf(PropTypes.any).isRequired,

};

const InfoWrap = (props) => {
    const {
        title,
        help,
        t,
        request,
        callback,
    } = props;

    let {filter} = request;

    const onChangeFilter = (key, value) => {
        filter = {
            ...filter,
            [key]: value || []
        };
        request.filter = filter;

        callback(key, value, request);

        // if (typeof window !== 'undefined') {
        //     const urlObject = new URL(window.location.href);
        //     const newUrl = value ? `${urlObject.origin}${urlObject.pathname}` : `${urlObject.origin}${urlObject.pathname}`
        //     history.pushState({}, '', newUrl)
        // }
    }

    const renderInfoWrap = () => {
        return (
            <ContentInfoWrap>

                <Title
                    title={t("Approvals")}
                    help={help}
                    total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
                 />


                {<ApprovalFilters callback={onChangeFilter} filter={filter}/>}
            </ContentInfoWrap>
        );
    }

    return renderInfoWrap();
};

InfoWrap.propTypes = propTypes;
InfoWrap.defaultProps = defaultProps;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    const pagination = getStoreItem(state, "pagination");

    return {
        pagination,
        title: pagination.total || 0,
        help: getStoreItem(state, "help"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        callback: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(InfoWrap);
