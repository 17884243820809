import _ from "lodash";
import {url} from "Services";
import Constants from "../Constants";

export const initState = {
    loading: false,
    activeTab: +url.get('activeTab'),
    totalNotes: 0,
    totalFiles: 0,
    errors: {},
    isExpandedBlocks: true,
    expandedBlocks: Constants.EXPANDED_BLOCKS
};

export const reducer = (state, action) => {
    const { type, data } = action;

    switch (type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data, totalNotes: data.totalNotes, totalFiles: data.totalFiles, filter: {}, sort: {} };

        case Constants.RESET_FORM:
            return { ...state };

        case Constants.SET_EXPANDED:
            return { ...state, isExpandedBlocks: true, expandedBlocks: {
                    general: true,
                    product: true,
                    marketSegment: true,
                    forecast: true,
                    part: true,
                    supplyChain: true,
                    project: true,
                    specifications: true,
                    description: true,
                } };

        case Constants.SET_COLLAPSED:
            return { ...state, isExpandedBlocks: false, expandedBlocks: {
                    general: false,
                    product: false,
                    marketSegment: false,
                    forecast: false,
                    part: false,
                    supplyChain: false,
                    project: false,
                    specifications: false,
                    description: false,
                } };

        case Constants.CHANGE_STATUS_REQUESTED:
        case Constants.FETCH_ITEM_REQUESTED:
            return { ...state, loading: true };

        case Constants.FETCH_ITEM_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                items: data,
                totalNotes: data.totalNotes,
                totalFiles: data.totalFiles,
            };
        }

        case Constants.CHANGE_STATUS_SUCCEEDED:
            return { ...state, view: { ...state.view, subStatus: data }, loading: false };

        case Constants.CHANGE_STATUS_FAILED:
        case Constants.FETCH_ITEM_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        default:
            return state;
    }
};
