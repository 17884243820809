import React from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { getString, diffDate, formatDate } from "Services";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "user_name":
            return (
                <TableRowItemLinkImg
                    blank
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "user_id")}`}
                    img={getString(item, "user_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "name":
        case "slug":
            if (item.can_edit) {
                return (
                    <TableRowItemLink title={getString(item, key)} file href={getString(item, "path")}>
                        {getString(item, key)}
                    </TableRowItemLink>
                );
            }

            return (
                <TableRowItemSpan title={getString(item, key)}>
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "date":
            const modified = formatDate(getString(item, key));

            return <TableRowItemSpan title={modified}>{modified}</TableRowItemSpan>;

        default:
            let dateColor = "";
            const date = formatDate(getString(item, key));

            const dateDiff = diffDate(getString(item, "expiration_date"), false, "month");
            switch (true) {
                case dateDiff < 3:
                    dateColor = "clr-red";
                    break;

                case dateDiff < 6:
                    dateColor = "clr-yellow";
                    break;
            }

            return (
                <TableRowItemSpan title={formatDate(date)} className={dateColor}>
                    {formatDate(date)}
                </TableRowItemSpan>
            );
    }
};
