import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { withTagDefaultProps } from "Hoc/Template";
import { isEqual, url } from "Services";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Products = ({ old, validate, formData, pref, t, submitForm }) => {
    const saveProducts = () => {
        submitForm(formData).then(
            data => {
                url.redirect(`/admin/list/products/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (window.confirm(t("Cancel?"))) {
            url.redirect("/admin/list");
        }
    };

    const validateAll = validate ? !isEqual({ ...formData }, old, _.keys(old)) : validate;

    return (
        <>
            <Title
                title={t("Edit Product Source")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock edit />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>{t("Cancel")}</SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validateAll} onClick={saveProducts}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Products.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        old: getStoreItem(state, "old"),
        // errors: getStoreItem(state, "errors"),
        formData: state.editState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Products);
