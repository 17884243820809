import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;

    const {
        id,
        title,
        firstName,
        lastName,
        socials,
        photo,
        photoView,
        company,
        country,
        city,
        address1,
        zip,
        state,
        district,
        timeZone,
    } = props;

    const initData = {
        id,
        title,
        firstName,
        lastName,
        emails: props.emails.length ? _.get(props, "emails") : [
            {
                type: '1',
                value: ""
            }
        ],
        phones: props.phones.length
            ? _.get(props, "phones")
            : [
                  {
                      type: '1',
                      code: "+1",
                      number: "",
                  },
              ],
        socials,
        photo,
        photoView,
        company,
        country,
        city,
        address1,
        zip,
        state,
        district,
        timeZone,
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "edit-contact" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
