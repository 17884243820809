import {numberWithCommas} from "Services";
import {weightUnitConvertLbs, weightUnitName} from 'Services/weightUnitName';

export const industryOptions = [
    {value: "1", label: "Aerospace"},
    {value: "2", label: "Automotive"},
    {value: "3", label: "Construction & Industry"},
    {value: "4", label: "Consumer & Electronics"},
    {value: "5", label: "Industrial Materials"},
    {value: "6", label: "Medical"},
    {value: "7", label: "Food Packaging"},
    {value: "8", label: "Electric Wire"},
    {value: "9", label: "Other"},
    {value: "10", label: "N/A"},
    {value: "11", label: "3D Printing"},
    {value: "12", label: "BIC"},
    {value: "13", label: "CFRP"},
    {value: "14", label: "Electronics"},
    {value: "15", label: "Energy Storage"},
    {value: "16", label: "Healthcare"},
    {value: "17", label: "Packaging"},
    {value: "18", label: "Tolling"},
    {value: "19", label: "3DP"},
    {value: "20", label: "Mobility"},
];

export const productTypeOptions = [
    {value: "1", label: "BIO"},
    {value: "2", label: "FPO"},
    {value: "10", label: "ISP"},
    {value: "3", label: "APO"},
    {value: "4", label: "PBS"},
    {value: "6", label: "PVC"},
    {value: "7", label: "TPO"},
    {value: "8", label: "TPV"},
    {value: "9", label: "OTH"},
    {value: "11", label: "RTP"},
    {value: "12", label: "SLU"},
    {value: "13", label: "TPC"},
    {value: "14", label: "TPS"},
    {value: "15", label: "TPU"},
    {value: "16", label: "XLP"},
    {value: "17", label: "3DP"},
];

export const colorSources = [
    {value: "1", label: "Specification"},
    {value: "2", label: "Project"},
    {value: "3", label: "Legacy"},
    {value: "4", label: "Other"},
];

export const gradeStatus = [
    {value: "6", label: "WIP", color: "purple"},
    {value: "2", label: "Trial", color: "opportunity"},
    {value: "5", label: "Production Intent", color: "out"},
    {value: "4", label: "Production Viable", color: "lead"},
    {value: "1", label: "Production", color: "current"},
    {value: "3", label: "Obsolete", color: "loss"},
];

export const gradeStatusList = [
    {
        order: 6,
        label: "WIP",
        statusColor: "purple-bg",
        tooltip: `Work In Progress. Product not for sale.`,
    },
    {
        order: 2,
        label: "Trial",
        statusColor: "yellow-bg",
        tooltip: `Use for samples, development, limit lots, production trials and approvals.`,
    },
    {
        order: 5,
        label: "Production Intent",
        statusColor: "selected",
        tooltip: `Product in process for being approved.`,
    },
    {
        order: 4,
        label: "Production Viable",
        statusColor: "gray-bg",
        tooltip: `Product produced and tested. Did not go to production but is a viable product that could be sold.`,
    },
    {
        order: 1,
        label: "Production",
        statusColor: "filled",
        tooltip: `Product available for sale. Use for production and/or samples.`,
    },

    {
        order: 3,
        label: "Obsolete",
        statusColor: "red-bg",
        tooltip: `Newer product versions should be available. This product may not be available for sale.`,
    },
];

export const projectStatusList = [
    {
        order: 10,
        value: 20,
        label: "Lead",
        tooltip: `Very early stages of project assessment. Project has been outlined and the approach defined. 
            Key decision makers have been identified. Initial meetings with the client(s) 
            have been held and necessary information that is required to scope and qualify 
            the project as a probable 'Target' has been completed. Action Plans have been 
            agreed upon by the project team and responsibilities/due dates have been assigned.`,
    },
    {
        order: 9,
        value: 50,
        label: "Opportunity",
        tooltip: `Design/manufacture/cost/use issues addressed clearly showing the product is an excellent fit for the application. 
            Trials scheduled or underway. Data being collected to support our positions/hypotheses. 
            Strong support from the client to proceed and indications given that they will support the solution if it can be validated.`,
    },
    {
        order: 8,
        value: 80,
        label: "Target",
        tooltip: `All major issues addressed. Key decision makers on board and fully supportive of the solution. 
            Formal proposals are being shaped into a final draft form. All hurdles have been identified and addressed.`,
    },
    {order: 7, value: 100, label: "Current"},
    // { order: 11, value: 0, label: "On Hold"},
    {order: 6, value: 0, label: "Loss of Sale"},
    {order: 5, value: 0, label: "Out of Production"},
];

export const projectStatusBusinessList = [
    {order: 1, value: -1, label: "Level 1", statusColor: "gray-bg"},
    {order: 2, value: -1, label: "Level 2", statusColor: "yellow-bg"},
    {order: 3, value: -1, label: "Level 3", statusColor: "selected"},
    {order: 4, value: -1, label: "Level 4", statusColor: "filled"}
];

export const projectStatus = [
    // { value: "11", label: "On hold", color: "loss" },
    {value: "10", label: "Lead", color: "lead"},
    {value: "9", label: "Opportunity", color: "opportunity"},
    {value: "8", label: "Target", color: "out"},
    {value: "7", label: "Current", color: "current"},
    {value: "6", label: "Loss of Sale", color: "loss"},
    {value: "5", label: "Out of Production", color: "loss"},
];

export const stageGates = [
    {value: "1", label: "Business Case", color: ""},
    {value: "2", label: "Evaluation", color: ""},
    {value: "3", label: "Design Validation", color: ""},
    {value: "4", label: "Technical Validation", color: ""},
    {value: "5", label: "Prepare Launch", color: ""},
    {value: "6", label: "Commercialized", color: ""},
];

export const projectStatusBusiness = [
    {value: "1", label: "Level 1", color: "level1"},
    {value: "2", label: "Level 2", color: "level2"},
    {value: "3", label: "Level 3", color: "level3"},
    {value: "4", label: "Level 4", color: "level4"},
];

export const projectConsistent = [
    {value: 5, label: "No", color: "loss"},
    {value: 10, label: "Yes", color: "current"}
];

export const projectBudget = [
    {value: 5, label: "No", color: "loss"},
    {value: 10, label: "Yes", color: "current"}
];

export const historyInteractions = [
    {value: 1, label: "Downloaded"},
    {value: 2, label: "Viewed"}
];

export const historyInteractionTypes = [
    {value: 1, label: "Product"},
    {value: 2, label: "Publication"},
    {value: 3, label: "TDS"},
    {value: 4, label: "PDS"},
    {value: 5, label: "Guide"},
    {value: 6, label: "SDS"},
    {value: 7, label: "Brochure"},
];

export const historyPublicationsTypes = [
    {value: 3, label: "TDS"},
    {value: 4, label: "PDS"},
    {value: 5, label: "Guide"},
    {value: 6, label: "SDS"},
    {value: 7, label: "Brochure"},
];

export const companyPhoneTypes = [
    {value: '1', label: "Main"},
    {value: '2', label: "Fax"},
    {value: '8', label: "Other"},
];

export const contactPhoneTypes = [
    {value: '1', label: "Work"},
    {value: '2', label: "Home"},
    {value: '3', label: "Mobile"},
    {value: '4', label: "Main"},
    {value: '5', label: "Home Fax"},
    {value: '6', label: "Work Fax"},
    {value: '8', label: "Other"},
];

export const contactEmailTypes = [
    {value: '1', label: "Work"},
    {value: '2', label: "Home"},
    {value: '8', label: "Other"},
];

export const gradeFileTypes = [
    {value: '10', label: "Guides"},
    {value: '7', label: "Safety Data Sheet"},
    {value: '6', label: "Technical Data Sheet"},
    {value: '5', label: "Processing Data Sheet"},
    {value: '8', label: "Brochure"},
];

export const isSapImported = [
    {value: '0', label: "No", color: "loss"},
    {value: 10, label: "Yes", color: "current"}
];

export const isMolded = [
    {value: "yes", label: "Yes", color: "current"},
    {value: "no", label: "No", color: "loss"},
    {value: "can_be_matched", label: "No, can be color matched", color: "out"}
];

export const isBoundable = [
    {value: '10', label: "Yes", color: "current"},
    {value: '0', label: "No", color: "loss"}
];

export const isYesNo = [
    {value: '0', label: "No", color: "loss"},
    {value: 10, label: "Yes", color: "current"}
];

export const importedTemplateStatuses = [
    {value: '0', label: "Pending"},
    {value: 10, label: "Processing"},
    {value: 20, label: "Import Error"},
    {value: 30, label: "Revert Pending"},
    {value: 40, label: "Reverted"},
    {value: 50, label: "Revert Failed"},
    {value: 60, label: "Imported"}
];

export const sapAnalyticTypes = [
    {value: 1, label: "Raw Materials"},
    {value: 2, label: "Lines"},
    {value: 3, label: "Grades"},
    {value: 4, label: "Customers"},
    {value: 5, label: "Grade Cost Estimation"},
    {value: 6, label: "Invoices"},
    {value: 7, label: "Vendor Purchase Price"}
];

export const gradeType = [
    {value: 1, label: "Material Type Other"},
    {value: 2, label: "Material Type Finished"},
    {value: 3, label: "Technical Datasheet"}
];

export const datasheetPaintable = [
    {value: '10', label: "Yes"},
    {value: '9', label: "Yes (Hidden)"},
    {value: '5', label: "No"},
    {value: '0', label: "N/A"}
];

export const datasheetLocation = [
    {value: '10', label: "All"},
    {value: '9', label: "Exterior"},
    {value: '8', label: "Interior"},
    {value: '7', label: "Underhood"},
    {value: '0', label: "N/A"}
];

export const notificationTypes = {
    IHS_UPDATED: 1,
    ASSIGNED_TASK: 2,
    ADDED_TO_PROJECT: 3,
    CONTRACT_EXPIRE_MONTH: 4,
    CONTRACT_EXPIRE_TWO_WEEKS: 5,
    CONTRACT_EXPIRE_WEEK: 6,
    CONTRACT_EXPIRE_TODAY: 7,
    DELTA_UPDATED: 8,
    PROJECT_ARCHIVED: 9,
    PROJECT_EXPORT_EXPORTED: 10,
    PROJECT_EXPORT_CANCELED: 11,
    GUEST_USER_ACTIVATED: 12,
    GRADE_SHARE_FILTER: 13,
    FINANCE_EXPORT_EXPORTED: 14,
    FINANCE_EXPORT_CANCELED: 15,
    FINANCE_CUSTOMER_EXPORT_EXPORTED: 16,
    FINANCE_CUSTOMER_EXPORT_CANCELED: 17,
    PROJECT_TEMPLATE_EXPORT_EXPORTED: 18,
    PROJECT_TEMPLATE_EXPORT_CANCELED: 19,
    PROJECT_TEMPLATE_IMPORT_IMPORTED: 20,
    PROJECT_TEMPLATE_IMPORT_ERROR: 21,
    PROJECT_TEMPLATE_IMPORT_REVERTED: 22,
    PROJECT_TEMPLATE_IMPORT_REVERT_FAILED: 23,
    PUBLICATION_EXPORT_EXPORTED: 24,
    PUBLICATION_EXPORT_CANCELED: 25,
    PRODUCT_EXPORT_EXPORTED: 30,
    PRODUCT_EXPORT_CANCELED: 31,
    INVOICE_ZSALES_EXPORT_EXPORTED: 35,
    INVOICE_ZSALES_EXPORT_CANCELED: 36,
    INVOICE_INLINE_EXPORT_EXPORTED: 37,
    INVOICE_INLINE_EXPORT_CANCELED: 38,
    FINANCE_CUSTOMER_EXPORT_QUARTERLY_EXPORTED: 39,
    FINANCE_CUSTOMER_EXPORT_QUARTERLY_CANCELED: 40,
    FINANCE_CUSTOMER_EXPORT_YEARLY_EXPORTED: 41,
    FINANCE_CUSTOMER_EXPORT_YEARLY_CANCELED: 42,
    CONNECTION_EXPORT_EXPORTED: 43,
    CONNECTION_EXPORT_CANCELED: 44,
    USER_EXPORT_EXPORTED: 45,
    USER_EXPORT_CANCELED: 46,
    COMPANY_EXPORT_EXPORTED: 47,
    COMPANY_EXPORT_CANCELED: 48,
    PROJECT_VEHICLE_EXPORT_EXPORTED: 49,
    PROJECT_VEHICLE_EXPORT_CANCELED: 50,
    FINANCE_CUSTOMER_EXPORT_MONTHLY_EXPORTED: 51,
    FINANCE_CUSTOMER_EXPORT_MONTHLY_CANCELED: 52,
    ADDED_TO_PROJECT_AS_MANAGER: 53,
    ADDED_TO_PROJECT_AS_INTERNAL_TEAM_MEMBER: 54,
    CONNECTION_BUDGET_EXPORT_EXPORTED: 55,
    CONNECTION_BUDGET_EXPORT_CANCELED: 56,
    INVOICE_ZCOMOPPS_EXPORT_EXPORTED: 57,
    INVOICE_ZCOMOPPS_EXPORT_CANCELED: 58,
    COMPARE_PRODUCT_SHARED_TECHNICAL_EXPORT_EXPORTED: 59,
    COMPARE_PRODUCT_SHARED_TECHNICAL_EXPORT_CANCELED: 60,
    COMPARE_PRODUCT_SHARED_PROCESSING_EXPORT_EXPORTED: 61,
    COMPARE_PRODUCT_SHARED_PROCESSING_EXPORT_CANCELED: 62,
    COMPARE_PRODUCT_ONBOARDING_TECHNICAL_EXPORT_EXPORTED: 63,
    COMPARE_PRODUCT_ONBOARDING_TECHNICAL_EXPORT_CANCELED: 64,
    COMPARE_PRODUCT_ONBOARDING_PROCESSING_EXPORT_EXPORTED: 65,
    COMPARE_PRODUCT_ONBOARDING_PROCESSING_EXPORT_CANCELED: 66,
    SHARING_FILE_FOLDER: 67,
    SHARED_AGREEMENT: 68,
    COMPARE_SOLUTION_CATEGORY_EXPORT_EXPORTED: 69,
    COMPARE_SOLUTION_CATEGORY_EXPORT_CANCELED: 70,
};

export const taskStatus = [
    {value: 1, color: "loss", label: "Overdue"},
    {value: 2, color: "opportunity", label: "In progress"},
    {value: 3, color: "out", label: "In que"},
    {value: 4, color: "current", label: "Completed"},
];

export const marginList = [
    {
        label: "Revenue",
        value: "1",
        showTotalInTableHead: true,
        permission: "revenue_gross_operating_view",
        viewFormat: (value) => `$${numberWithCommas(value, 0)}`,
    },
    {
        label: "Volume",
        value: "2",
        showTotalInTableHead: true,
        permission: "volume_view",
        viewFormat: (value, weightUnit) => `${numberWithCommas(value, 0)} ${weightUnitConvertLbs(weightUnitName(weightUnit))}`,
    }
];

export const materialTypes = {
    PROPERTY: 1,
    TEST_METHOD: 2,
    SPECIMEN: 3,
    TEST_CONDITION: 4,
    SI_UNIT: 5,
    ENGLISH_UNIT: 6,
};

export const manufacturingTypes = {
    MANUFACTURING: 1,
    PARAMETER: 2,
    DESCRIPTION: 3,
    SI_UNIT: 4,
    ENGLISH_UNIT: 5,
};

export const colorSourceTypes = [
    {value: '1', label: "Specification"},
    {value: '2', label: "Project"},
    {value: '3', label: "Legacy"},
    {value: '4', label: "Other (Add note to describe source)"},
];

export const projectStatusArchived = projectStatus.filter(elem => ["6", "5", "11"].includes(elem.value));

export const projectStatusActive = projectStatus.filter(elem => !["6", "5", "11"].includes(elem.value));

export const projectStatusBudgetForecast = projectStatus.filter(elem => !["11"].includes(elem.value));

export const marginEnum = permissions => marginList.filter(elem => permissions.includes(elem.permission));
