import _, {get} from "lodash";
import {onChange} from "./EventsService";
import React from "react";
import {InputFilter} from "../../../../../../Templates/Table/Filters";
import {historyInteractions, historyPublicationsTypes} from "../../../../../../Services/enum";

export const getTableLabel = (key) => {
    const tableLabel = {
        list_name: "List Name",
        publication_name: "Publication Name",
        datasheet_name: "Name",
        type: "Publication Type",
        interaction: "Interaction Type",
        created_at: "Interaction Date",
    };
    return get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        list_name: "Search",
        publication_name: "Search",
        datasheet_name: "Search",
        type: "Select",
        interaction: "Select",
        created_at: "Search",
    };

    switch (key) {
        case "type":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={historyPublicationsTypes}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "interaction":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={historyInteractions}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "created_at":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e, key).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => (["created_at"].includes(key));
