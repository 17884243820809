import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { TabActions } from "../../../Actions";
import * as AnalyticsAction from "../../../Actions/Tabs/AnalyticsAction";

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TabActions,
        ...AnalyticsAction,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export const getStoreItem = _getStoreItem("listState");
export const getChartData = store => getStoreItem(store, "analytics.data");
