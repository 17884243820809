export default {
    UPDATE_PUBLICATION_GROUP_REQUESTED: "UPDATE_PUBLICATION_GROUP_REQUESTED",
    UPDATE_PUBLICATION_GROUP_SUCCEEDED: "UPDATE_PUBLICATION_GROUP_SUCCEEDED",
    UPDATE_PUBLICATION_GROUP_FAILED: "UPDATE_PUBLICATION_GROUP_FAILED",

    OPEN_USERS_MODAL: "OPEN_USERS_MODAL",
    CLOSE_USERS_MODAL: "CLOSE_USERS_MODAL",
    UPDATE_USER_FORM: "UPDATE_USER_FORM",
    SAVE_USERS_REQUESTED: "SAVE_USERS_REQUESTED",
    SAVE_USERS_SUCCEEDED: "SAVE_USERS_SUCCEEDED",
    SAVE_USERS_REJECTED: "SAVE_USERS_REJECTED",

    OPEN_INVITE_GUESTS_MODAL: "OPEN_INVITE_GUESTS_MODAL",
    CLOSE_INVITE_GUESTS_MODAL: "CLOSE_INVITE_GUESTS_MODAL",
    UPDATE_GUEST_FORM: "UPDATE_GUEST_FORM",

    GET_USERS_REQUESTED: "GET_USER_REQUESTED",
    GET_NEXT_PAGE_USERS_SUCCEEDED: "GET_NEXT_PAGE_USERS_SUCCEEDED",
    GET_USERS_SUCCEEDED: "GET_USERS_SUCCEEDED",
    GET_USERS_REJECTED: "GET_USERS_REJECTED",
    DELETE_USERS_REQUESTED: "DELETE_USERS_REQUESTED",
    DELETE_USERS_SUCCEEDED: "DELETE_USERS_SUCCEEDED",
    DELETE_USERS_REJECTED: "DELETE_USERS_REJECTED",

    OPEN_CONTACTS_LIST_MODAL: "OPEN_CONTACTS_LIST_MODAL",
    CLOSE_CONTACTS_LIST_MODAL: "CLOSE_CONTACTS_LIST_MODAL",
    UPDATE_CONTACTS_FORM: "UPDATE_CONTACTS_FORM",
    SAVE_CONTACTS_REQUESTED: "SAVE_CONTACTS_REQUESTED",
    SAVE_CONTACTS_SUCCEEDED: "SAVE_CONTACTS_SUCCEEDED",
    SAVE_CONTACTS_REJECTED: "SAVE_CONTACTS_REJECTED",
    GET_CONTACTS_REQUESTED: "GET_USER_REQUESTED",
    GET_CONTACTS_SUCCEEDED: "GET_CONTACTS_SUCCEEDED",
    GET_NEXT_PAGE_CONTACTS_SUCCEEDED: "GET_NEXT_PAGE_CONTACTS_SUCCEEDED",
    GET_CONTACTS_REJECTED: "GET_CONTACTS_REJECTED",
    DELETE_CONTACTS_REQUESTED: "DELETE_CONTACTS_REQUESTED",
    DELETE_CONTACTS_SUCCEEDED: "DELETE_CONTACTS_SUCCEEDED",
    DELETE_CONTACTS_REJECTED: "DELETE_CONTACTS_REJECTED",

    OPEN_PUBLICATIONS_MODAL: "OPEN_PUBLICATIONS_MODAL",
    CLOSE_PUBLICATIONS_MODAL: "CLOSE_PUBLICATIONS_MODAL",
    UPDATE_PUBLICATIONS_FORM: "UPDATE_PUBLICATIONS_FORM",
    SAVE_PUBLICATIONS_REQUESTED: "SAVE_PUBLICATIONS_REQUESTED",
    SAVE_PUBLICATIONS_SUCCEEDED: "SAVE_PUBLICATIONS_SUCCEEDED",
    SAVE_PUBLICATIONS_REJECTED: "SAVE_PUBLICATIONS_REJECTED",
    GET_PUBLICATIONS_REQUESTED: "GET_USER_REQUESTED",
    GET_PUBLICATIONS_SUCCEEDED: "GET_PUBLICATIONS_SUCCEEDED",
    GET_NEXT_PAGE_PUBLICATIONS_SUCCEEDED: "GET_NEXT_PAGE_PUBLICATIONS_SUCCEEDED",
    GET_PUBLICATIONS_REJECTED: "GET_PUBLICATIONS_REJECTED",
    DELETE_PUBLICATIONS_REQUESTED: "DELETE_PUBLICATIONS_REQUESTED",
    DELETE_PUBLICATIONS_SUCCEEDED: "DELETE_PUBLICATIONS_SUCCEEDED",
    DELETE_PUBLICATIONS_REJECTED: "DELETE_PUBLICATIONS_REJECTED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",
};