import React from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

const NotesWallMod = (NotesWrap, NoteBlock, NoteTop, NoteMessage) => ({
                                                                       items,
                                                                       page,
                                                                       pages,
                                                                       fetchPage,
                                                                       service,
                                                                       handleClickOnEditButton,
                                                                       handleClickOnDeleteButton,
                                                                   }) => {
    const onScroll = e => {
        if (page >= pages) {
            return;
        }

        service.handleScroll(e).then(() => {
            fetchPage({ page: page + 1 });
        });
    };

    if (typeof items === "undefined" || !Array.isArray(items)) {
        return null;
    }

    return (
        <NotesWrap onScroll={onScroll}>
            {items.map((item, key) => {
                return (
                    <NoteBlock key={`NoteBlock-${key}`}>
                        <div className="notes-sap-products-wrap">
                            <NoteTop item={item} />
                            <NoteMessage item={item}
                                         handleClickOnEditButton={handleClickOnEditButton}
                                         handleClickOnDeleteButton={handleClickOnDeleteButton}
                            />
                        </div>
                    </NoteBlock>
                );
            })}
        </NotesWrap>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem, modifierValues } = ownProps.service;

    return {
        items: modifierValues(getStoreItem(state, "items")),
        page: getStoreItem(state, "pagination.page"),
        pages: getStoreItem(state, "pagination.pages"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
        editNote: getActionStore("editNoteAction", service, dispatch),
    };
};

export default (NotesWrap, NoteBlock, NoteTop, NoteMessage) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(NotesWallMod(NotesWrap, NoteBlock, NoteTop, NoteMessage));
