import ReactOnRails from "react-on-rails";
import React from "react";
import Root from "App/View/Root";
import service from "./Services";
import DashboardTypeFour from "./Views/DashboardTypeFour";
import CheckDefaultPermission from "Hoc/Template/CheckDefaultPermission";
import NotAvailableMessage from "Templates/Permission/message";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);
    
    return (
        <Root store={store} service={service()}>
            <CheckDefaultPermission permissionList={["business_level_2", "sale_level_2"]} defaultValue={<NotAvailableMessage />}>
                <DashboardTypeFour />
            </CheckDefaultPermission>
        </Root>
    );
};

export default App;
