import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";
import {isYesNo} from "Services/enum";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Name",
        date: "Date",
        submitted_by: "Submitted By",
        show_filter: "Show in Filter",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholder = {
        name: "Search",
        date: "Date",
        submitted_by: "Search",
        show_filter: "Select"
    };
    switch (key) {
        case "date":
            return (
                <InputFilter
                    type="date"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        case "show_filter":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isYesNo]}
                    placeholder={_.get(placeholder, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
