import { formDataNormalizer } from "Services";

export const formData = ({ name, industry, segment }) => {
    const dataReq = {
        name: name,
        industry: industry,
        segment: segment,
    };

    return formDataNormalizer(dataReq);
};
