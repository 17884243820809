const Constants = {
    CHANGE_FORM: "CHANGE_FORM",
    CHANGE_TITLE: "CHANGE_TITLE",
    CHANGE_PRODUCTS_CODES: "CHANGE_PRODUCTS_CODES",
    CHANGE_TAB: "CHANGE_TAB",
    CHANGE_CONNECTION_TYPE: "CHANGE_CONNECTION_TYPE",
    CHANGE_CONNECTION_PROJECT_TYPE: "CHANGE_CONNECTION_PROJECT_TYPE",
    CHANGE_ACTUALS_TYPE: "CHANGE_ACTUALS_TYPE",
    SET_SORT_DIRECTION: "SET_SORT_DIRECTION",
    RESET_FORM: "RESET_FORM",
    REMOVE_ITEM: "REMOVE_ITEM",

    GET_PRODUCTS_CODES_LIST: "GET_PRODUCTS_CODES_LIST",
    SET_PRODUCTS_CODES_LIST: "SET_PRODUCTS_CODES_LIST",

    FETCH_ITEM_REQUESTED: "FETCH_ITEM_REQUESTED",
    FETCH_ITEM_SUCCEEDED: "FETCH_ITEM_SUCCEEDED",
    FETCH_ITEM_FAILED: "FETCH_ITEM_FAILED",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    GET_CHART_DATA_REQUESTED: "GET_CHART_DATA_REQUESTED",
    GET_CHART_DATA_SUCCEEDED: "GET_CHART_DATA_SUCCEEDED",
    GET_CHART_DATA_FAILED: "GET_CHART_DATA_FAILED",

    dashboardTypeConstant: {
        SALE: 1,
        BUSINESS: 2
    },

    requiredQueryParams: ['items', 'marginListSelect', 'periodFrom', 'periodTo', 'sortColumn', 'sortDirection', 'statusProject', 'timeScale', 'type']
};
export default Constants;