import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {ProductFinderContainerTable} from "Hoc/Widgets";
import { TableRowColumnWrap } from "Templates/Table/TableRowColumnWrap";
import { TabWrap } from "Templates/Content";
import TableHeadColumnWrap from "./TableHeadColumnWrap";

const DefaultTabTable = ProductFinderContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const Grade = ({ service, fetchItems, request, t }) => {
    const { deleteItem } = service;

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        url.redirect(`/product/product-finder/${item.grade_id}/edit`);
    };

    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the Product?"))) {
            deleteItem(`/product/product-finder/${item.grade_id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    return (
        <TabWrap className="myProduct__wrap">
            <TabViewDetail
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
            />
        </TabWrap>
    );
};

Grade.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Grade);
