import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numWithSub } from "Services";
import { withServiceConsumer, ServiceProvider } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import Column from "../Templates/Column";
import Row from "../Templates/Row";
import DashboardCard from "./Card/Widget/DashboardCard";
import TableDataDashboardCard from "./Card/Table/DataDashboardCard";
import TableService from "../Services/TableService";

const propTypes = {
    role: PropTypes.string.isRequired,
    revenue: PropTypes.string.isRequired,
    volume: PropTypes.string.isRequired,
    grossMargin: PropTypes.string.isRequired,
    service: PropTypes.shape({ getStoreItem: PropTypes.func.isRequired, getActionStore: PropTypes.func.isRequired })
        .isRequired,
};

const InfoDashboardCard = DashboardCard({});
const TableDashboardCard = DashboardCard({ DataDashboardCard: TableDataDashboardCard });

const DashboardTypeOne = ({
    role,
    financialYear,
    revenue,
    volume,
    grossMargin,
    service: { getStoreItem, getActionStore },
    t,
}) => {
    return (
        <div className="dashboard__wrap-main main-content__wrap">
            <div className="dashboard__list">
                <Row>
                    <Column columnSize={1} fromColumns={4}>
                        <InfoDashboardCard
                            headProps={{ title: t("Forecast Revenue (Current)"), subtitle: t(`FY ${financialYear}`) }}
                            dataProsp={{ value: `$${numWithSub(Number(revenue))}`, className: "content_center"  }}
                        />
                        <InfoDashboardCard
                            headProps={{
                                title: t("Forecast Volume (Current)"),
                                subtitle: t(`FY ${financialYear}`),
                            }}
                            dataProsp={{ value: `${numWithSub(Number(volume))}`, unit: 'lbs', className: "content_center" }}
                        />
                    </Column>
                    <Column columnSize={3} fromColumns={4}>
                        <ServiceProvider value={{ ...TableService(), getActionStore, getStoreItem }}>
                            <TableDashboardCard
                                className="main-data"
                                headProps={{ title: t(`My Team`), subtitle: t(`FY ${financialYear}`) }}
                            />
                        </ServiceProvider>
                    </Column>
                </Row>
            </div>
        </div>
    );
};

DashboardTypeOne.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        role: getStoreItem(state, "role"),
        financialYear: getStoreItem(state, "financialYear"),
        revenue: getStoreItem(state, "dashboard.analytic.revenue"),
        volume: getStoreItem(state, "dashboard.analytic.volume"),
        grossMargin: getStoreItem(state, "dashboard.analytic.gross_margin"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DashboardTypeOne);
