import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {uniqueGenerator} from "Services";
import Tab from "./Tab";

const defaultProps = {
    isDropDownView: false,
    dropDownTitle: 'Dropdown Title'
};

const propTypes = {
    isDropDownView: PropTypes.bool,
    dropDownTitle: PropTypes.string,
    activeTab: PropTypes.number.isRequired,
    onClickTabItem: PropTypes.func.isRequired,
};

const Tabs = ({children, onClickTabItem, activeTab, pref, isDropDownView, dropDownTitle}) => {
    const dropdownRef = useRef(null)
    const dropdownButtonRef = useRef(null)

    const [isOpenDropdown, setIsOpenDropdown] = useState(false)

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target)) {
                setIsOpenDropdown(false);
            }
        }

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [])

    return (
        <>
            {isDropDownView ? (
                <>
                    <p className="dropdown-title">{dropDownTitle}</p>
                    <div className={`tabs-dropdown ${isOpenDropdown ? 'open' : ''}`}>
                        <p ref={dropdownButtonRef} className="tabs-dropdown-selected"
                           onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
                            {children.find((_, i) => i === activeTab).props.label}
                            <span className="icon">
                        <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-6q0nyr-Svg"
                        >
<path
    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
/>
                        </svg>
                        </span>
                        </p>
                        <ul ref={dropdownRef} className={`${pref}__tabs-dropdown tabs-dropdown-list`}
                            onClick={() => setIsOpenDropdown(!isOpenDropdown)}>
                            {children.map((child, index) => {
                                if (!child) return undefined;

                                if (child.type === 'div') {
                                    const {label} = child.props;

                                    return (
                                        <Tab
                                            index={index}
                                            activeTab={activeTab}
                                            key={label}
                                            label={label}
                                            onClick={onClickTabItem}
                                        />
                                    );
                                }

                                return React.cloneElement(child, {
                                    index,
                                    pref,
                                    key: uniqueGenerator(),
                                    activeTab,
                                    onClick: onClickTabItem
                                });
                            })}
                        </ul>
                    </div>
                </>
            ) : (
                <ul className={`${pref}__tabs main-content__tabs`}>
                    {children.map((child, index) => {
                        if (!child) return undefined;

                        if (child.type === 'div') {
                            const {label} = child.props;

                            return (
                                <Tab
                                    index={index}
                                    activeTab={activeTab}
                                    key={label}
                                    label={label}
                                    onClick={onClickTabItem}
                                />
                            );
                        }

                        return React.cloneElement(child, {
                            index,
                            pref,
                            key: uniqueGenerator(),
                            activeTab,
                            onClick: onClickTabItem
                        });
                    })}
                </ul>
            )}
            <div className={`${pref}__wrap-change main-content__wrap-change ${pref}__tab-item-${activeTab}`}>
                {children.map((child, index) => {
                    if (index !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </>
    );
};

Tabs.defaultProps = defaultProps;
Tabs.propTypes = propTypes;

export default compose(withTagDefaultProps())(Tabs);