import Constants from "../../Constants";

export const createFolderAction = (service, dispatch) => data => {
    dispatch({ type: Constants.files.CREATE_FOLDER_REQUEST });

    const { requestOnCreateFolder } = service;

    return requestOnCreateFolder(data).then(
        res => dispatch({ type: Constants.files.CREATE_FOLDER_SUCCESS, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.files.CREATE_FOLDER_FAILED, data: res });
        },
    );
};

export const editFolderAction = (service, dispatch) => (id, data) => {
    dispatch({ type: Constants.files.EDIT_FOLDER_REQUEST });

    const { requestOnEditFolder } = service;

    return requestOnEditFolder(id, data).then(
        res => dispatch({ type: Constants.files.EDIT_FOLDER_SUCCESS, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.files.EDIT_FOLDER_FAILED, data: res });
        },
    );
};
