import React from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import {LinkPermission} from "Hoc/Template";
import {getString, formatDate, storageUrl} from "Services";
import TableRowItemFolderLinkImgWrap from "./Table/TableRowItemFolderLinkImgWrap";
import _ from "lodash";
import {listTypes} from "./TableHeaderService";

export const modifierValues = items => items;

export const getViewItem = (key, item, onClickItem) => {
    switch (key) {
        case "name":
            const propList = {
                Folder: {
                    ico: "icon-folder",
                    href: () => `${item.id}`,
                    label: getString(item, key),
                },
                File: {
                    ico: "icon-file",
                    target: "_blank",
                    href: storage_url => storageUrl(getString(item, "path"), storage_url),
                    label: getString(item, key),
                }
            };
            return (
                <TableRowItemFolderLinkImgWrap
                    title={getString(item, key)}
                    className="files-img"
                    type={getString(item, "type") == 10 ? 'File' : 'Folder'}
                    settings={propList}
                    onClickItem={onClickItem}
                >
                    {getString(item, key)}
                </TableRowItemFolderLinkImgWrap>
            );

        case "modified":
            return (
                <TableRowItemSpan title={formatDate(getString(item, key))}>
                    {formatDate(getString(item, key))}
                </TableRowItemSpan>
            );

        case "modified_by":
            return (
                <LinkPermission permission="admin"
                                exclude={
                                    <TableRowItemSpan title={getString(item, key)}>
                                        {getString(item, key)}
                                    </TableRowItemSpan>}>
                    <TableRowItemLinkImg
                        blank
                        title={getString(item, key)}
                        href={`/admin/users/${getString(item, "modified_by_id")}`}
                        img={getString(item, "modified_by_photo", "")}
                    >
                        {getString(item, key)}
                    </TableRowItemLinkImg>
                </LinkPermission>
            );
        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};

export const customIcon = items => index => {
    const type = _.get(items, `[${index}].type`);
    const label = _.get(listTypes, `[${type}].label`);
    return label === "File" ? "icon_download" : "";
};

export const showEditButton = item => {
    return !_.get(item, `type`, true);
};