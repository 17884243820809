import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    column: PropTypes.string.isRequired,
    applyChange: PropTypes.func,
    setInvalid: PropTypes.func,
};
const QuickEditTableRowColumnTemplateDefault = ({ column, item, applyChange, setInvalid, service, weightUnit }) => {
    const { getQuickEditViewItem } = service;
    return getQuickEditViewItem(column, item, applyChange, setInvalid, weightUnit);
};

QuickEditTableRowColumnTemplateDefault.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    weightUnit: state.weightUnitState,
  };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(QuickEditTableRowColumnTemplateDefault);
