import React from "react";
import { useSelector } from 'react-redux';

import { getString, numberWithCommas } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { weightUnitName } from 'Services/weightUnitName';

export const tableButtonsPermission = "grade_product_crud";
export const modifierValues = items => items;

export const getViewItem = (key, item) => {
  const unit = useSelector(state => state.weightUnitState);

    switch (key) {
        case "name":
            return (
                <TableRowItemLink title={getString(item, key)} href={`/product/products/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "attachment_name":
            return (
                <TableRowItemLink title={getString(item, key)} file href={getString(item, "attachment_path")}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "revenue":
            return (
                <TableRowItemSpan className="text-center">
                    {`$${numberWithCommas(getString(item, key), 0)}`}
                </TableRowItemSpan>
            );

        case "volume":
            return (
                <TableRowItemSpan className="text-center">
                    {`${numberWithCommas(getString(item, key), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );

        case "cost":
            return (
                <TableRowItemSpan title={`$${getString(item, key)}/${weightUnitName(unit.weightUnit)}`}>
                    {`$${getString(item, key)}/${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
