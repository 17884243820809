import {request, url, formDataNormalizer} from "Services";

export const requestAttach = attachUrl => data => {
    const sendObj = {
        url: url.getUrl(attachUrl),
        data: formDataNormalizer(data),
        type: "POST",
        contentType: false,
        processData: false,
    };

    return request.sendRequest(sendObj);
};

export const restoreItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "POST",
    };

    return request.sendRequest(sendObj);
};
