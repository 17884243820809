import { url } from "Services";
import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable } from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/companies/${id}/contacts`),
    onClickEditItem: item => {
        url.redirect(`/contacts/${item.id}/edit`);
    },
    onClickDeleteItem: item => deleteItem(`/contacts/${item.id}/delete`),
    getViewItem,
    getFilter,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
