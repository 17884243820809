import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.object),
    ]),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    inputProps: PropTypes.shape({ isOptionDisabled: PropTypes.func }),
};

const defaultProps = {
    disabled: false,
    placeholder: "",
    value: "",
    inputProps: { isOptionDisabled: option => option.disable },
};

const CompareProductsMultiSelect = ({ value, disabled, options, placeholder, onChange, inputProps }) => {
    return (
        <div className={'select-title'}>
        <Select
        isMulti
        isDisabled={disabled}
        menuPosition="fixed"
        value={value ? options.filter(option => value.includes(option.value)) : []}
        // closeMenuOnScroll={e => !e.target.parentNode.className.includes("menu")}
        closeMenuOnScroll={e => !(e.target.parentNode || {className: ''}).className.includes("menu")}
        options={options}
        className="select-custom-wrap basic-multi-select"
        isClearable={false}
        onChange={(value) => {
            if (value && value.some((item) => item.value === '')) return

            onChange(value ? value.map(v => v.value) : value)
        }}
        classNamePrefix="select"
        placeholder={placeholder}
        {...inputProps}
    />
        </div>
            );
};

CompareProductsMultiSelect.propTypes = propTypes;
CompareProductsMultiSelect.defaultProps = defaultProps;

export default CompareProductsMultiSelect;
