import * as main from "../../index";
import { getActionStore } from "./StoreService";

const modifierValues = items => items;

export default id => ({
    ...main,
    modifierValues,
    getActionStore,
    requestTable: main.requestTable(`/product/product-finder/${id}/notes`),
    requestAttach: main.requestAttach(`/product/product-finder/${id}/notes/add`),
    editNotesRequest: itemId => main.requestAttach(`/product/product-finder/${id}/notes/${itemId}/edit`),
    deleteNotesRequest: itemId => main.deleteItem(`/product/product-finder/${id}/notes/${itemId}/delete`)
});
