import unitsConstants from '../View/Header/Items/Units/Constants';

export const initialState = {
  weightUnit: 0,
  isActive: 0,
};

export default function weightUnitReducer(state = initialState, action) {
  switch (action.type) {

    case unitsConstants.ACTIVE_UNIT: {
      const { data } = action;

      return {
        ...state,
        weightUnit: data,
      };
    }

    case unitsConstants.IS_ACTIVE: {
      const { data } = action;

      return {
        ...state,
        isActive: data,
      };
    }

    default:
      return state;
  }
}
