import {handleScroll} from "../../EventsService";
import {getActionStore, getStoreItem} from "../../StoreService";
import {getFilter, getTableLabel, isSortEnabled} from "./TableHeaderService";
import {getViewItem, modifierValues} from "./TableBodyService";
import {requestTable} from "../../RequestService";

export default ({
    requestTable: requestTable(`/product/product-finder/publication-technical`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
    isSortEnabled
});

