import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Admin = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };

    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin-${index}`}
                    value={getPermission("admin", index) || hasAccess()}
                    onChange={value => onChangePermission("admin", value, index)}
                >
                    <CheckboxTitle title="Admin">
{/*                        <Tooltip>
                            {t(
                                "Access user to the administrator rights.",
                            )}
                        </Tooltip>*/}
                    </CheckboxTitle>
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_users-${index}`}
                    value={getPermission("admin_users_crud", index)}
                    onChange={value => onChangePermission("admin_users_crud", value, index)}
                >
                    <CheckboxTitle title="View/Add/Edit/Delete - Users" />
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_organizations-${index}`}
                    value={getPermission("admin_organizations_crud", index)}
                    onChange={value => onChangePermission("admin_organizations_crud", value, index)}
                >
                    <CheckboxTitle title="View/Add/Edit/Delete - Organizations" />
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_ihs-${index}`}
                    value={getPermission("admin_ihs_crud", index)}
                    onChange={value => onChangePermission("admin_ihs_crud", value, index)}
                >
                    <CheckboxTitle title="View - IHS Updates" />
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxHierarchy activeHierarchy={getPermission("admin_lists_crud", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        htmlFor={`checkbox_admin_lists_crud-${index}`}
                        value={getPermission("admin_lists_crud", index)}
                        onChange={value => onChangePermission("admin_lists_crud", value, index)}
                    >
                        <CheckboxTitle title="Access/Add/Edit/Delete Lists"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_product_source-${index}`}
                        value={getPermission("admin_lists_crud_product_source", index)}
                        onChange={value => onChangePermission("admin_lists_crud_product_source", value, index)}
                    >
                        <CheckboxTitle title="Product Source"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_product_code-${index}`}
                        value={getPermission("admin_lists_crud_product_code", index)}
                        onChange={value => onChangePermission("admin_lists_crud_product_code", value, index)}
                    >
                        <CheckboxTitle title="Product Code"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_application_code-${index}`}
                        value={getPermission("admin_lists_crud_application_code", index)}
                        onChange={value => onChangePermission("admin_lists_crud_application_code", value, index)}
                    >
                        <CheckboxTitle title="Application Code"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_material_type-${index}`}
                        value={getPermission("admin_lists_crud_material_type", index)}
                        onChange={value => onChangePermission("admin_lists_crud_material_type", value, index)}
                    >
                        <CheckboxTitle title="Material Type"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_oem_approval-${index}`}
                        value={getPermission("admin_lists_crud_oem_approval", index)}
                        onChange={value => onChangePermission("admin_lists_crud_oem_approval", value, index)}
                    >
                        <CheckboxTitle title="OEM Approval"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_custom_manufacturer-${index}`}
                        value={getPermission("admin_lists_crud_custom_manufacturer", index)}
                        onChange={value => onChangePermission("admin_lists_crud_custom_manufacturer", value, index)}
                    >
                        <CheckboxTitle title="Custom Manufacturer"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_contact_role-${index}`}
                        value={getPermission("admin_lists_crud_contact_role", index)}
                        onChange={value => onChangePermission("admin_lists_crud_contact_role", value, index)}
                    >
                        <CheckboxTitle title="Contact Role"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_color-${index}`}
                        value={getPermission("admin_lists_crud_color", index)}
                        onChange={value => onChangePermission("admin_lists_crud_color", value, index)}
                    >
                        <CheckboxTitle title="Color"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_color_code-${index}`}
                        value={getPermission("admin_lists_crud_color_code", index)}
                        onChange={value => onChangePermission("admin_lists_crud_color_code", value, index)}
                    >
                        <CheckboxTitle title="Color Code"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_publication_logo-${index}`}
                        value={getPermission("admin_lists_crud_publication_logo", index)}
                        onChange={value => onChangePermission("admin_lists_crud_publication_logo", value, index)}
                    >
                        <CheckboxTitle title="Publication Logo"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_safety-${index}`}
                        value={getPermission("admin_lists_crud_safety", index)}
                        onChange={value => onChangePermission("admin_lists_crud_safety", value, index)}
                    >
                        <CheckboxTitle title="Safety"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("admin_lists_crud", index)}
                        htmlFor={`checkbox_admin_lists_crud_brochure-${index}`}
                        value={getPermission("admin_lists_crud_brochure", index)}
                        onChange={value => onChangePermission("admin_lists_crud_brochure", value, index)}
                    >
                        <CheckboxTitle title="Brochure"/>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_tds_data-${index}`}
                    value={getPermission("admin_tds_data", index)}
                    onChange={value => onChangePermission("admin_tds_data", value, index)}
                >
                    <CheckboxTitle title="TDS/PDS Data/Hierarchy" />
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_solutions_data-${index}`}
                    value={getPermission("admin_solutions_data", index)}
                    onChange={value => onChangePermission("admin_solutions_data", value, index)}
                >
                    <CheckboxTitle title="Tech Hierarchy" />
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxHierarchy activeHierarchy={getPermission("product_publications_view", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        htmlFor={`checkbox_product_publications_view-${index}`}
                        value={getPermission("product_publications_view", index)}
                        onChange={value => onChangePermission("product_publications_view", value, index)}
                    >
                        <CheckboxTitle title="View - Publications" >
{/*                            <Tooltip>
                                {t(
                                    "Access - Publication / Add infobubble: Access user to the View Publications.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("product_publications_view", index)}
                        htmlFor={`checkbox_product_publications_edit-${index}`}
                        value={getPermission("product_publications_edit", index)}
                        onChange={value => onChangePermission("product_publications_edit", value, index)}
                    >
                        <CheckboxTitle title="Add/Edit/Delete - Publications" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to Add/Edit/Delete Publications.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("product_publications_view", index)}
                        htmlFor={`checkbox_product_publications_approve-${index}`}
                        value={getPermission("product_publications_approve", index)}
                        onChange={value => onChangePermission("product_publications_approve", value, index)}
                    >
                        <CheckboxTitle title="Approve - Publications" >
{/*                            <Tooltip>
                                {t(
                                    "Access user to Approve Publication for SAP Products and Shared List.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>

                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("product_publications_view", index)}
                        htmlFor={`checkbox_product_publications_notes_view-${index}`}
                        value={getPermission("product_publications_notes_view", index)}
                        onChange={value => onChangePermission("product_publications_notes_view", value, index)}
                    >
                        <CheckboxTitle title="Access - Notes tab" />
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("product_publications_view", index)}
                        htmlFor={`checkbox_product_publications_notes_edit-${index}`}
                        value={getPermission("product_publications_notes_edit", index)}
                        onChange={value => onChangePermission("product_publications_notes_edit", value, index)}
                    >
                        <CheckboxTitle title="Add/Edit/Delete - Notes tab" />
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>

            <CheckboxHierarchy activeHierarchy={getPermission("product_solutions_view", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        htmlFor={`checkbox_product_solutions_view-${index}`}
                        value={getPermission("product_solutions_view", index)}
                        onChange={value => onChangePermission("product_solutions_view", value, index)}
                    >
                        <CheckboxTitle title="View - Technologies" >
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("product_solutions_view", index)}
                        htmlFor={`checkbox_product_solutions_edit-${index}`}
                        value={getPermission("product_solutions_edit", index)}
                        onChange={value => onChangePermission("product_solutions_edit", value, index)}
                    >
                        <CheckboxTitle title="Add/Edit/Delete - Technologies" >
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("product_solutions_view", index)}
                        htmlFor={`checkbox_product_solutions_approve-${index}`}
                        value={getPermission("product_solutions_approve", index)}
                        onChange={value => onChangePermission("product_solutions_approve", value, index)}
                    >
                        <CheckboxTitle title="Approve - Technologies" >
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>

                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("product_solutions_view", index)}
                        htmlFor={`checkbox_product_solutions_notes_view-${index}`}
                        value={getPermission("product_solutions_notes_view", index)}
                        onChange={value => onChangePermission("product_solutions_notes_view", value, index)}
                    >
                        <CheckboxTitle title="Access - Notes tab" />
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={!getPermission("product_solutions_view", index)}
                        htmlFor={`checkbox_product_solutions_notes_edit-${index}`}
                        value={getPermission("product_solutions_notes_edit", index)}
                        onChange={value => onChangePermission("product_solutions_notes_edit", value, index)}
                    >
                        <CheckboxTitle title="Add/Edit/Delete - Notes tab" />
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_help-pages-${index}`}
                    value={getPermission("admin_help-pages_crud", index)}
                    onChange={value => onChangePermission("admin_help-pages_crud", value, index)}
                >
                    <CheckboxTitle title="Add/Edit/Delete - Help Pages" />
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_deleted-page-${index}`}
                    value={getPermission("admin_deleted-page_crud", index)}
                    onChange={value => onChangePermission("admin_deleted-page_crud", value, index)}
                >
                    <CheckboxTitle title="View - Deleted" />
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_system-page-${index}`}
                    value={getPermission("admin_system-page_crud", index)}
                    onChange={value => onChangePermission("admin_system-page_crud", value, index)}
                >
                    <CheckboxTitle title="View - System" />
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_templates-page-${index}`}
                    value={getPermission("admin_templates-page_crud", index)}
                    onChange={value => onChangePermission("admin_templates-page_crud", value, index)}
                >
                    <CheckboxTitle title="Import Templates" />
                </CheckboxButton>
            </CheckboxBlock>

            <CheckboxBlock>
                <CheckboxButton
                    htmlFor={`checkbox_admin_alerts-${index}`}
                    value={getPermission("admin_alerts_crud", index)}
                    onChange={value => onChangePermission("admin_alerts_crud", value, index)}
                >
                    <CheckboxTitle title="Add/Edit/Delete - Alerts" />
                </CheckboxButton>
            </CheckboxBlock>
        </>
    )
}

Admin.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Admin);
