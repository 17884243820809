import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import TabView from "./Views/TabView";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({ deleteItem: PropTypes.func.isRequired }).isRequired,
    fetchItems: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Company = ({ service, fetchItems, request, t, permissions }) => {
    const { deleteItem } = service;

    useEffect(() => {
        if(!permissions.includes('admin_organizations_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    const onClickEdit = item => {
        url.redirect(`/admin/companies/${item.id}/edit`);
    };

    const onClickShow = item => {
        const win = window.open(`/admin/companies/${item.id}`, "_blank");
        win.focus();
    };

    const onClickDelete = item => {
        if (window.confirm(t("Do you want to delete the Organization?"))) {
            deleteItem(`/admin/companies/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    return <TabView onClickCustom={onClickShow} onClickEdit={onClickEdit} onClickDelete={onClickDelete} key="1" />;
};

Company.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Company);
