import React from "react";
// import PropTypes from 'prop-types'
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {};

const CardListItem = ({ pref, children }) => {
    return <li className={`${pref}__card-item`}>{children}</li>;
};

CardListItem.propTypes = propTypes;

export default compose(withTagDefaultProps())(CardListItem);
