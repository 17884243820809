import React from "react";
import {getString, numberWithCommas, getStoreItem as _getStoreItem, formatDate} from "Services";
import {gradeStatus} from "Services/enum";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { weightUnitName } from 'Services/weightUnitName';
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";

const getStoreItem = _getStoreItem("listState.grades");

const typeList = {
    10: "Guide",
    7: "Safety Data Sheet",
    6: "Technical Data Sheet",
    5: "Processing Guide",
    1: "Other",
};

const tableLabel = {
    name: "Product Name",
    product_name: "Brand",
    sub_status: "Status",
    material_number: "Material Number",
    legacy_product_number: "Legacy Product Number",
    count_projects: "Project(s)",
    count_invoices: "Invoice(s)",
    plant_name: "Plant",
    line_name: "Production Line",
    owner: "Supplier",
    product_code: "Product Code",
    files_count: "Count File",
    submitted_by: "Submitted by",
    submitted_date: "Date Submitted",
    classification: "Material Type",
    volume: "Volume",
    revenue: "Revenue",
    gross_margin: "Gross Margin",
    publications: "File Type",
    sap_plant: "SAP Plants",
    sap_line: "SAP Production Lines",
    date_update: "Date Updated",
    sap_value: "SAP Import",
};

const title = "Products";

const getTableLabel = key => getString(tableLabel, key, key);

const getViewItem = (key, item, weightUnit) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/product/product-finder/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "publications": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemLink title={elem.attachment_name} key={`TableRowItemLink-${index}`} file href={elem.attachment_path}>
                                    {typeList[elem.type]}
                                </TableRowItemLink>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        case "count_projects":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "id")}?activeTab=5`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "count_invoices":
            return (
                <>
                    <TableRowItemLink
                        blank
                        title={getString(item, key).toString()}
                        href={`/product/product-finder/${getString(item, "id")}?activeTab=10`}
                    >
                        {getString(item, key)}
                    </TableRowItemLink>
                </>
            );

        case "sap_plant":
        case "sap_line": {
            const arrDate = getString(item, key) !== '-' ? JSON.parse(getString(item, key)) : [];

            return (
                arrDate.length > 0
                    ? (
                        <>
                            {arrDate.map((elem, index) => (
                                <TableRowItemSpan key={index} title={elem.name}>{elem.name}</TableRowItemSpan>
                            ))}
                        </>
                    )
                    : <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>
            );
        }

        case "address":
            return (
                <TableRowItemSpan title={getString(item, key)} className="max-width-60">
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "revenue":
            return <TableRowItemSpan>{`$${numberWithCommas(getString(item, key))}`}</TableRowItemSpan>;

        case "volume":
            return <TableRowItemSpan>{`${numberWithCommas(getString(item, key))} ${weightUnitName(weightUnit.weightUnit)}`}</TableRowItemSpan>;

        case "gross_margin":
            return (
                <TableRowItemSpan
                    title={getString(item, key)}
                    className={`${getString(item, key) < 0 ? "clr-red" : ""}`}
                >
                    {`${getString(item, key)}%`}
                </TableRowItemSpan>
            );

        case "date_update":
        case "submitted_date": {
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => {
                        const formatValue = elem === '-' ? elem : formatDate(elem);
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                {formatValue}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case "sap_value":
            return <TableRowItemSpan>{getString(item, key) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case "sub_status":
            return <TableRowItemStatus status={getString(item, key)} list={gradeStatus} />;

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};

export default searchValue => ({
    link: `/search/grades?value=${searchValue}`,
    getStoreItem,
    title,
    getTableLabel,
    getViewItem,
});
