import {debounce} from "Services/Debounce";
import Constants from "../Constants";

const requestFailed = data => ({type: Constants.FETCH_ITEMS_FAILED, data});

const requestItem = () => ({type: Constants.FETCH_ITEMS_REQUESTED, data: {loading: true}});

const requestSucceeded = data => ({type: Constants.FETCH_ITEMS_SUCCEEDED, data});

const requestNextPageSucceeded = data => ({type: Constants.NEXT_PAGE, data});

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const {sort} = data;
    const {requestTable} = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({...res, sort: sort || {}}));
        },
        () => {
            dispatch(requestFailed({items: []}));
        },
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({type: Constants.CHANGE_FILTER, data: {key, value}});

    if (
        [
            'sub_status',
            'application_code',
            'date_update',
            'sap_value',
            'nicknames',
            'changed_date',
            'created_code_date',
            'created_system_date',
            'marked_deletion',
            'sap_value',
            'sap_plant',
            'sap_line',
            'product_code',
            'owner',
            'classification',
            'submitted_date',
            'date_update',
            'application_code',
            'application',
            'customer',
            'industry',
            'slug',
            'name',
            'manager'
        ].includes(key)
    ) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const {requestTable} = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        () => {
            dispatch(requestFailed({items: []}));
        },
    );
};
