import Constants from "../../Constants";
import _ from "lodash";

export const initialState = {
    project: "",
    logo: "",
    logoView: "",
    customer: "",
    dunsNumber: "",
    description: "",
    customerOwner: "",
    invoiceCount: 0,
    customerNickname: "",
    materialSpecification: "", // [""]
    performanceSpecification: "", // [""]
    form_loading: false,
    form_errors: {},
    status: 0,
    stageGate: 0,
    businessStatus: 0,
    losDate: "",
};

export const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case Constants.SUBMIT_PERFOMACE_REQUESTED:
        case Constants.SUBMIT_MATERIAL_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.SUBMIT_PERFOMACE_SUCCEEDED:
        case Constants.SUBMIT_MATERIAL_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.SUBMIT_PERFOMACE_FAILED:
        case Constants.SUBMIT_MATERIAL_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.CHANGE_CUSTOMER_SUCCEEDED: {
            const generalBlock = state.generalBlock;

            _.set(generalBlock, "dunsNumber", data.duns_number || '');
            _.set(generalBlock, "invoiceCount", data.invoice_count || 0);
            _.set(generalBlock, "customerOwner", data.customer_owner || '');

            return { ...state };
        }

        case Constants.CHANGE_CUSTOMER_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.CHANGE_CUSTOMER_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        default: {
            return { ...state };
        }
    }
};
