export const weightUnitName = (value) => {
  switch (value) {
    case 0:
      return 'lb';

    case 1:
      return 'kg';

    default:
      return '';
  }
}

export const weightUnitConvertLbs = (val) => {
  return val === 'lb' ? 'lbs' : 'kg';
}
