import _ from "lodash";
import { validate, setForecastBlock } from "../../Services/Reducers/LogicService";
import { getTotalForecasts } from "../../Services/Reducers/MathService";
import Constants from "../../Constants";

export const initialState = {
    forecast: [],
    total: 0,
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORECAST: {
            return setForecastBlock(state, data);
        }

        case Constants.CHANGE_FORECAST_DATE_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.CHANGE_FORECAST_DATE_SUCCEEDED: {
            const {
                forecastBlock: { forecast },
            } = state;

            const selectedForecast = forecast.find(elem => elem.id === data.id);
            selectedForecast.total = _.get(data, "apiRes.total_units");
            return { ...setForecastBlock(state, data), form_loading: false };
        }

        case Constants.CHANGE_FORECAST_DATE_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.DELETE_FORECAST: {
            const forecast = state.forecastBlock.forecast.filter((item, index) => index !== data.key);
            const newState = {
                ...state,
                forecastBlock: {
                    ...state.forecastBlock,
                    forecast,
                    total: getTotalForecasts(forecast),
                },
            };
            return {
                ...newState,
                form_validate: validate(newState),
            };
        }

        default: {
            return { ...state };
        }
    }
};
