import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Block, BlockWrap } from "Templates/Form";
import { CustomSimpleTable } from "Hoc/Widgets/Table/SimpleTableContainer";
import TableBodyColumn from "./TableBodyColumn";
import TableHeadColumn from "./TableHeadColumn";

const propTypes = { data: PropTypes.any };

const Table = ({ data, pref }) => {
    const SimpleTable = CustomSimpleTable()(TableHeadColumn)()(TableBodyColumn)()(() => null);
    return (
        <>
            {typeof data === 'object' && Object.keys(data).length > 0 && (
                <Block>
                    <BlockWrap className={`${pref}__form-block-card`}>
                        <SimpleTable />
                    </BlockWrap>
                </Block>
            )}
        </>
    );
};

Table.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        data: getStoreItem(state, "data"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Table);
