import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import React from "react";

const propTypes = {
    item: PropTypes.string,
};

const defaultProps = {
    item: '',
};

const TableBodyColumn = ({item}) => {
    return (
        <div dangerouslySetInnerHTML={{__html: item}}
             id="css-render-form"/>
    );
};

TableBodyColumn.propTypes = propTypes;

TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
