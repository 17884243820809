import {ModalDefault} from "Templates/Modals";
import React, {useEffect, useRef, useState} from "react";
import {unionBy} from "lodash";
import {formatDateToLocal} from "Services";
import Img, {ImgStorage} from "Templates/Img";
import {handleScroll} from "Services/Scroll";

const {Title} = ModalDefault;

export const ViewNotesTable = ({urlSubTab, emptyText, onLoadNotesData}) => {

    const [listItems, setListItems] = useState([]);

    const total = useRef({ page: 1, pages: 0 })

    const loadData = (paginationData) => {
        onLoadNotesData(paginationData.page).then(res => {
            const [data] = res;
            if (!data) {
                return;
            }
            setListItems((prev) => {
                return unionBy([...prev, ...data.items], 'id')
            });
            total.current = data.pagination;
        });
    };

    useEffect(() => {
        setListItems([])
        total.current = {page: 1, pages: 0}
        loadData(total.current)
    }, [urlSubTab])

    useEffect(() => {
        loadData(total.current);
    }, [onLoadNotesData]);

    const onScroll = (e) => {
        if (!listItems.length) return;

        if ((total.current.page > 0 && total.current.pages > 0) && (total.current.page >= total.current.pages)) {
            return
        }

        handleScroll(e).then(() => {
            onLoadNotesData(total.current.page + 1).then(res => {
                const [data] = res;

                if (!data) {
                    return;
                }
                setListItems((prev) => {
                    return unionBy([...prev, ...data.items], 'id')
                });

                total.current = data.pagination
            });
        });
    }

    useEffect(() => {
        if (typeof window === 'undefined') return

        const datasheetWrap = document.querySelector('.publications-content.main-content')

        if (!datasheetWrap) return

        datasheetWrap.addEventListener('scroll', (e) => onScroll(e))

        return () => {
            datasheetWrap.removeEventListener('scroll', (e) => onScroll(e))
        }
    }, [listItems.length])

    return listItems && listItems.length > 0 ?
        <div className="main-modal__notes">
            <Title>Updates</Title>

            <div className="main-wrap-block__body main-wrap-block__body_height">
                <table className="main-table">
                    <tbody>
                    <tr>
                        <th className="main-table__head main-table__head-date">
                            <span className="main-table__head-link">Published</span>
                        </th>
                        <th className="main-table__head main-table__head-user">
                            <span className="main-table__head-link">Changed By</span>
                        </th>
                        <th className="main-table__head">
                            <span className="main-table__head-link">Notes</span>
                        </th>
                    </tr>
                    {listItems.map(({created_at, editor, id, note, user_photo}) => {
                        return (
                            <tr key={`HistoryTableRow-${id}`}>
                                <td className="main-table__body">
                                    <div
                                        className="main-table__body-row"
                                    >
                                        {formatDateToLocal(created_at, "MMM DD, YYYY h:mm A")}
                                    </div>
                                </td>
                                <td className="main-table__body history-editor">
                                    <div className="main-table__body-row">
                                        {user_photo ?
                                            <ImgStorage url={user_photo} className="history-user__photo"/> :
                                            <Img img="icon_contact_small" className="history-user__photo"/>}
                                        <span className="history-user__name">{editor}</span>
                                    </div>
                                </td>
                                <td className="main-table__body">
                                    <div className="main-table__body-row">{note}</div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div> :
        <div className={`grade-view__wrap main-wrap-block technical-wrap not-available`}>
            <div className="sheet-notInfo text-center">
                <Img
                    img="icon_files"
                    width="100"
                    height="100"
                    alt="file"
                    className=""
                />
                <p className="text-center">
                    {emptyText}
                </p>
            </div>
        </div>
};