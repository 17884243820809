import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import Invoice from "./Tabs/Invoice";

const MainBlock = ({activeTab, t, onChangeTab}) => {
    return (
        <ContentWrap>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("General")}>
                        <Invoice/>
                    </Tab>
                    <Tab label=""/>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

MainBlock.propTypes = {
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;
    return {
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MainBlock);
