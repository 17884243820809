import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableHeadColumnDefault from "./Default";
import TableHeadColumnTemplate from "Templates/Table/TableHeadColumn";

const propTypes = {
    column: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onSortClick: PropTypes.func.isRequired,
};

const TableHeadColumn = ({ column, index, onSortClick }) => (
    <TableHeadColumnTemplate>
        <TableHeadColumnDefault column={column} index={index} onSortClick={() => onSortClick(column)} />
    </TableHeadColumnTemplate>
);

TableHeadColumn.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableHeadColumn);
