import Constants from "../Constants";

export const submitFormAction = (service, dispatch) => data => {
    dispatch({ type: Constants.SUBMIT_FROM_REQUEST });

    const { requestOnSubmitForm } = service;

    return requestOnSubmitForm(data).then(
        res => {
            if (res.redirect) {
                dispatch({ type: Constants.SUBMIT_FROM_SUCCEEDED, data: { redirect_url: res.redirect } });
                return;
            }

            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.SUBMIT_FROM_FAILURE, data: res });
        },
    );
};
