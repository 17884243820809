import React from "react";
import { useSelector } from 'react-redux';

import { formatDate, getString, numberWithCommas } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { weightUnitName } from 'Services/weightUnitName';
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const unit = useSelector(state => state.weightUnitState);

    switch (key) {
        case "plant":
            return (
                <TableRowItemLink
                    title={getString(item, key)}
                    href={`/product/plants/${getString(item, "id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "cost":
            return (
                <TableRowItemSpan
                    className="text-right"
                    title={`$${numberWithCommas(getString(item, key), 3)}`}
                >
                    {`$${numberWithCommas(getString(item, key), 3)}`}
                </TableRowItemSpan>
            );

        case "price_per":
            return (
                <TableRowItemSpan
                    className="text-right"
                    title={`${numberWithCommas(getString(item, key), 3)} ${weightUnitName(unit.weightUnit)}`}
                >
                    {`${numberWithCommas(getString(item, key), 3)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );

        case "cost_per_lb":
            return (
                <TableRowItemSpan
                    className="text-right"
                    title={`$${numberWithCommas(getString(item, key), 3)}`}
                >
                    {`$${numberWithCommas(getString(item, key), 3)}`}
                </TableRowItemSpan>
            );

        case "created_date":
            const date = _.get(item, key) !== null ? formatDate(_.get(item, key)) : '-';

            return (
                <TableRowItemSpan title={date}>{date}</TableRowItemSpan>
            );

        case "marked_deletion":
        case "is_obsolete":
            return <TableRowItemSpan>{getString(item, key) ? 'YES' : 'NO'}</TableRowItemSpan>;

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
