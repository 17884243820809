import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const defaultProps = {
    className: ""
};

const BlockTitle = ({ pref, className, children }) => (
    <h2 className={`${className}  ${pref}__form-title main-content__form-title`}>{children}</h2>
);

BlockTitle.propTypes = {
    children: PropTypes.node.isRequired,
};

BlockTitle.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(BlockTitle);
