import {ModalDefault} from "Templates/Modals";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import PropTypes from "prop-types";
import {ButtonClose} from "Templates/Button";
import React, {useState} from "react";

const {Button, Form, Title, Col, Row, ColText, ColInput} = ModalDefault;

const propTypes = {
    onSaveFilters: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

const CreateFiltersModal = ({ onSaveFilters, onClose }) => {
    const [name, setName] = useState('')

    return <Form>
        <ButtonClose className="main-modal__form-close" onClick={onClose}/>
    <Title>Save Filter</Title>
    <Row>
        <Col>
            <ColText className="bold-text" isRequired>
                Filter Name
            </ColText>
            <ColInput
                placeholder="Enter Filter Name"
                name="name"
                value={name}
                onChange={(e) => setName(e)}
            />
        </Col>
    </Row>
    <div className="sandbox-save-modal-btn-wrap">
        <Button onClick={() => onSaveFilters(name)} disabled={name.trim().length === 0}>
            Save
        </Button>
    </div>
</Form>
}

CreateFiltersModal.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(CreateFiltersModal);