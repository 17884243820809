import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../../EventsService";

export const statusList = [
    {
        value: "10",
        label: "Active",
    },
    {
        value: "5",
        label: "Disabled",
    },
];

export const getTableLabel = key => {
    const tableLabel = {
        message: "Message",
        status: "Status",
        start_date: "Start Date",
        end_date: "End Date",
        date: "Last Modified",
        user_name: "Owner",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        message: "Search",
        status: "Search",
        start_date: "Effective Date",
        end_date: "Expiration Date",
        date: "Last Modified",
        user_name: "Search",
    };

    switch (key) {
        case "status":
            return (
                <InputFilter
                    type="select"
                    options={[{value: '', label: "-"}, ...statusList]}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "message":
        case "user_name":
            return (
                <InputFilter
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
