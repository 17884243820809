import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import TabView from "./Views/TabView";

const propTypes = {
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        resendInvitation: PropTypes.func.isRequired,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const Index = ({ service, fetchItems, t, request, permissions }) => {
    const { deleteItem, resendInvitation } = service;

    useEffect(() => {
        if(!permissions.includes('admin_users_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])
    
    const onClickEdit = item => {
        url.redirect(`/admin/users/${item.id}/edit`);
    };

    const onClickShow = item => {
        if (item.is_invited) {
            if (window.confirm(t("Do you want to resend the invitation to this user?"))) {
                resendInvitation(`/admin/users/${item.id}/resend-invite`).then(
                    () => {
                        alert('Congratulations! Your Guest User invitation was sent successfully.');
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        } else {
            const win = window.open(`/admin/users/${item.id}`, "_blank");
            win.focus();
        }
    };

    const onClickDelete = item => {
        if (window.confirm(t("Do you want to delete the User?"))) {
            deleteItem(`/admin/users/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };
    
    return <CheckPermission permission="admin_users_crud">
        <TabView onClickCustom={onClickShow} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
    </CheckPermission>
};

Index.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
