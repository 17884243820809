import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    onClick: PropTypes.func.isRequired,
};

const AddButton = ({ onClick, children }) => {
    return (
        <button type="button" onClick={onClick} className="main-btn main-btn_white size_small">
            {children}
        </button>
    );
};

AddButton.propTypes = propTypes;

export default compose(withTagDefaultProps())(AddButton);
