import {handleScroll} from "../../EventsService";
import {deleteItem, requestTable} from "../../RequestService";
import {requestStore, requestUpdate} from "./RequestService";
import {getStoreItem, getActionStore} from "./StoreService";
import {getTableLabel, getFilter} from "./TableHeaderService";
import {getViewItem, modifierValues} from "./TableBodyService";

export default ({
    requestTable: requestTable(`/admin/list/roles`),
    requestAttach: requestStore(`/admin/list/roles/store`),
    requestEdit: item => requestUpdate(`/admin/list/roles/${item.id}`),
    onClickDeleteItem: item => deleteItem(`/admin/list/roles/${item.id}`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});

