import { formDataNormalizer } from "Services";

export const formData = ({ company, grade, years }) => {
    const yearsArr = Object.keys(years);

    const dataReq = {
        company: company,
        grade: grade,
        dataYears: yearsArr[0] === '' ? {} : years,
    };

    return formDataNormalizer(dataReq);
};
