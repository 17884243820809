import {request, showMessage, url} from "Services";

const save = (data, requestUrl) => {
    const config = {
        url: url.getUrl(requestUrl),
        data,
        type: "POST",
        processData: false,
        contentType: false,
    };
    return request.sendRequest(config);
};

const get = (requestUrl, data) => {
    const config = {
        url: url.getUrl(requestUrl),
        type: "GET",
        data,
    };
    return request.sendRequest(config);
};

const remove = requestUrl => {
    const config = {
        url: url.getUrl(requestUrl),
        type: "POST",
        processData: false,
        contentType: false,
    };
    return request.sendRequest(config, false);
};

export const requestSave = requestUrl => data => {
    return new Promise((resolve, reject) => {
        save(data, requestUrl).then(
            response => resolve(response),
            error => {
                showMessage(`${error.title || ''} ${error.detail}`);
                reject(error);
            },
        );
    });
};

export const requestList = (requestUrl, data = {}) => {
    return new Promise((resolve, reject) => {
        get(requestUrl, data).then(
            response => resolve(response),
            error => {
                showMessage(`${error.title || ''} ${error.detail}`);
                reject(error);
            },
        );
    });
};

export const requestDelete = requestUrl => {
    return new Promise((resolve, reject) => {
        remove(requestUrl).then(
            response => resolve(response),
            error => {
                showMessage(`${error.title || ''} ${error.detail}`);
                reject(error);
            },
        );
    });
};
