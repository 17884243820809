import _ from "lodash";
import { formDataNormalizer } from "Services";

export const formData = data => {
    const dataReq = {
        name: _.get(data, "name"),
        text: _.get(data, "text"),
    };

    return formDataNormalizer(dataReq);
};
