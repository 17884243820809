import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, CheckPermission } from "Hoc/Template";
import {
    MainButtonLink,
    TabBlock,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";

import { ContainerTable } from "Hoc/Widgets";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const propTypes = {
    service: PropTypes.shape({
        onClickCreateItem: PropTypes.func.isRequired,
        onClickEditItem: PropTypes.func.isRequired,
        onClickDeleteItem: PropTypes.func.isRequired,
    }).isRequired,
    pagination: PropTypes.shape({
        total: PropTypes.number.isRequired,
    }).isRequired,
};

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const Project = ({ pagination: { total }, t, service }) => {
    const { onClickEditItem, onClickCreateItem, onClickDeleteItem } = service;

    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to remove the Project?"))) {
            onClickDeleteItem(item).then(
                () => {},
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        onClickEditItem(item);
    };

    const onClickAddNewProject = () => {
        onClickCreateItem();
    };

    return (
        <>
            <TabWrapTop>
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{`${numberWithCommas(total, 0)} ${t("Projects")}`}</TabBlockColTitleH4>
                    </TabBlockCol>

                    {/* <TabBlockCol>
                        <TabBlockColTitle>{t("Revenue")}</TabBlockColTitle>
                        <TabBlockColTitleH4 className="clr-green">$127,050,156</TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{t("Volume")}</TabBlockColTitle>
                        <TabBlockColTitleH4>1,2300 ILBs</TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{t("Gross Margin")}</TabBlockColTitle>
                        <TabBlockColTitleH4>24.36%</TabBlockColTitleH4>
                    </TabBlockCol> */}
                </TabBlock>

                <CheckPermission permission="project_crud">
                    <MainButtonLink href="/project/projects/create" className="main-btn_primary">
                        {t("Add New Project")}
                    </MainButtonLink>
                </CheckPermission>
            </TabWrapTop>
            <TabViewDetail onClickDelete={onClickDelete} onClickEdit={onClickEdit} />
        </>
    );
};

Project.propTypes = propTypes;

const mapStateToProps = (state, props) => {
    const { getStoreItem } = props.service;

    return {
        pagination: getStoreItem(state, "pagination"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Project);
