import {Block, BlockMain, BlockShadow, BlockWrap, Input, WrapInput} from "Templates/Form";
import React from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import PropTypes from "prop-types";

const propTypes = {
    url: PropTypes.string.isRequired,
    keyWords: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired
};

const KeyWords = ({url, keyWords, onChange}) => {
    const onAddKeywords = (keywords) => {
        if (!keywords || !keywords.length) {
            return onChange('keywords', [])
        }

        const val = keywords.map((item) => {
            return item.label.includes(' (Add Keyword)') ? {
                label: item.label.replace(' (Add Keyword)', '').trim(),
                value: item.value
            } : item;
        })


        onChange('keywords', val)
    }

    return (
        <BlockMain title="Keywords">
            <BlockShadow>
                <Block className="colors-wrap">
                    <BlockWrap>
                        <WrapInput
                            name="keywords"
                            label="Select"
                        >
                            <Input
                                type="asyncSearchMultiSelect"
                                placeholder="Search"
                                url={`${url}`}
                                name="keywords"
                                value={keyWords}
                                onChange={(e) => onAddKeywords(e)}
                            />
                        </WrapInput>
                    </BlockWrap>
                </Block>
            </BlockShadow>
        </BlockMain>
    )
}

KeyWords.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;
    return {
        keyWords: getStoreItem(state, "keywords"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(KeyWords);