import _ from "lodash";

export const formData = data => {
    return {
        name: _.get(data, 'name'),
        description: _.get(data, 'description'),
        iso: _.get(data, 'iso'),
        material_type: _.get(data, 'material_type'),
        lead_time: _.get(data, 'lead_time'),
        standard_packaging: _.get(data, 'standard_packaging'),
        delivery_terms: _.get(data, 'delivery_terms'),
        freight_class: _.get(data, 'freight_class'),
        note: _.get(data, 'note'),
        periods: _.get(data, 'periods')
    };
};
