import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import reducers, {initialStates} from "../Reducers";

export default function configureStore(props) {
    const { userHistoryList, contacts, id, name, publications, owner, managers } = props;
    const { mainState } = initialStates;
    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "publication-groups" }),
        listState: {
            ...mainState,
            id,
            name,
            owner,
            users: {
                ...mainState.users,
                groupId: publications.groupId,
                list: contacts,
            },
            contacts: {
                ...mainState.contacts,
                groupId: publications.groupId,
                list: managers,
            },
            publications: {
                ...mainState.publications,
                groupId: publications.groupId,
                items: publications.items,
                pagination: publications.pagination,
            },
            userHistory: {
                ...mainState.userHistory,
                groupId: publications.groupId,
                list: userHistoryList
            }
        },
    };

    return _configureStore(initialState, reducers);
}
