const Constants = {
    CHANGE_FORM: "CHANGE_FORM",

    SET_PHONE: "SET_PHONE",
    SET_EMAIL: "SET_EMAIL",

    GET_UNIT: "GET_UNIT",
    ACTIVE_UNIT: "ACTIVE_UNIT",

    FORM_REQUESTED: "FORM_REQUESTED",
    FORM_SUCCEEDED: "FORM_SUCCEEDED",
    FORM_FAILED: "FORM_FAILED",
    FORM_RESET: "FORM_RESET",

    ADD_TEAM: "ADD_TEAM",
    DELETE_TEAM: "DELETE_TEAM",

    CHANGE_TAB: "CHANGE_TAB",
};

export default Constants;
