import Constants from "../../../Constants";

export const getCostDataAction = (service, dispatch) => data => {
    dispatch({ type: Constants.FETCH_COST_DATA_REQUESTED });

    const { requestData } = service;

    return requestData(data).then(
        res => dispatch({ type: Constants.FETCH_COST_DATA_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.FETCH_COST_DATA_FAILED, data: res });
        },
    );
};

export const changeCostAction = (service, dispatch) => value => {
    dispatch({ type: Constants.CHANGE_COST_DATA, data: { value } });
};
