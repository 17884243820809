import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import PropTypes from "prop-types";

function CheckboxHierarchy({pref, children, activeHierarchy, inputClass}) {

    return (<div className={`${pref}__form-checkbox-hierarchy ${inputClass}`}>
                {children.slice(0,1)}
                <div className={`form-checkbox-hierarchy-child ${!activeHierarchy ? 'disabled-hierarchy' : ''}`}>
                    {children.slice(1)}
                </div>
           </div>);
}

CheckboxHierarchy.propTypes = {
    activeHierarchy: PropTypes.bool.isRequired,
};
export default compose(withTagDefaultProps())(CheckboxHierarchy);
