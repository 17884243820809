import { handleScroll } from "../../EventsService";
import { requestTable } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel, isSortEnabled } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/product/product-finder/${id}/invoices`),
    getViewItem,
    getFilter: getFilter(id),
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
    isSortEnabled,
});
