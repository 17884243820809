import { handleScroll } from "../../EventsService";
import { getItem, requestOnSaveCopy, getUploadUrl } from "./RequestService";
import { getActionStore, getStoreItem } from "./StoreService";
import {deleteItem} from "../../RequestService";

export default id => ({
    requestItems: () => getItem(`/product/product-finder/${id}/processing`),
    requestTypeItem: (item) => getItem(`/product/product-finder/${id}/processing/${item.id}`),
    requestOnSaveCopy: (processId) => requestOnSaveCopy(`/product/processes/${processId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/processes/${item.id}/delete`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (processId, type) => getUploadUrl(id, processId, type),
});
