import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "./EventsService";

export const getTableLabel = (key) => {
    const tableLabel = {
        name: "Vendor Name",
        code: "Vendor Code",
        created_at: "Date Submitted"
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        code: "Search",
        created_at: "Search"
    };

    switch (key) {
        case "name":
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    name={key}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "created_at":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
