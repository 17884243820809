import _ from "lodash";
import Constants from "../../../Constants";
import {setForecastBlock} from "../../../Services/Reducers/LogicService";

export const initState = {
    selected: [],
    columns: [],
    items: [],
    pagination: {
        total: 0,
        pages: 0,
    },
    sort: {},
    filter: {},
    tab: 0,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.RESET_SELECTED:
            return { ...state, modal: {
                ...state.modal, selected: []
                }
            };

        case Constants.CHANGE_ITEM:
            if (data.value) {
                return {
                    ...state,
                    modal: {
                        ...state.modal,
                        selected: state.modal.selected && state.modal.selected.length ? [...state.modal.selected, ...[{ ...data.item, units: "100", dateMovedToCurrent: "", endDate: "" }]] : [{ ...data.item, units: "100", dateMovedToCurrent: "", endDate: "" }],
                    },
                };
            }
            return { ...state, modal: {
                    ...state.modal,
                    selected: state.modal.selected.filter((sel) => sel.id !== data.item.id),
                },
            };

        case Constants.TABLE_SORT:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    items: data.items,
                    sort: data.sort,
                    pagination: data.pagination,
                },
            };

        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, form_loading: true };

        case Constants.CLOSE_MODAL: {
            const {
                forecastBlock: { forecast },
                modal: { selected },
            } = state;
            let frontId = forecast.length ? forecast[forecast.length - 1].frontId : 0;
            const newState = {
                ...state,
                forecastBlock: {
                    forecast: [...forecast, ...selected.map(elem => ({ ...elem, frontId: ++frontId }))],
                    total: 0,
                },
                modal: { ...initState },
            };
            return setForecastBlock(newState, {
                key: "units",
                value: "100",
                index: newState.forecastBlock.forecast.length - 1,
            });
        }

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return {
                ...state,
                modal: { ...state.modal, ...data, sort: { column: data.sort.column, direction: data.sort.direction } },
                form_loading: false,
            };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, errors: { ...data }, form_loading: false };

        case Constants.NEXT_PAGE:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    items: _.concat(state.modal.items, data.items),
                    pagination: data.pagination,
                    form_loading: false,
                },
            };

        default:
            return state;
    }
};
