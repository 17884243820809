import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";

const propTypes = {
    applications: PropTypes.arrayOf(PropTypes.string),
    logo: PropTypes.string.isRequired,
    company: PropTypes.string,
    companyId: PropTypes.string,
    name: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    productCode: PropTypes.string.isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
    onClickEdit: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const OldInfoWrap = ({
                      applications,
                      company,
                      companyId,
                      name,
                      productId,
                      productName,
                      productCode,
                      classification,
                      t,
                      onClickEdit,
                      permissions,
                  }) => {
    return (
        <ContentInfoWrap>
            <ContentInfoTop
                onClickEdit={permissions.includes("grade_product_crud") ? onClickEdit : null}
                urlBack="/product/product-finder"
            />

            <ContentInfoBlock show={!!productName}>
                <ContentInfoTitle>{name}</ContentInfoTitle>
                <ContentInfoBlockText type="link" value={`/product/products/${productId}`} title={productName} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!companyId}>
                <ContentInfoBlockTitle>{t("Supplier")}</ContentInfoBlockTitle>
                <ContentInfoBlockText type="link" value={`/admin/companies/${companyId}`} title={company} permission={'companies_view'} />
            </ContentInfoBlock>

            <ContentInfoBlock show={!!applications.length}>
                <ContentInfoBlockTitle>{t("Applications")}</ContentInfoBlockTitle>
                {applications.map((elem, index) => (
                    <ContentInfoBlockText key={index} value={elem} />
                ))}
            </ContentInfoBlock>

            <ContentInfoBlock show={!!productCode}>
                <ContentInfoBlockTitle>{t("Product Code")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={productCode} />
            </ContentInfoBlock>


            <ContentInfoBlock show={!!classification}>
                <ContentInfoBlockTitle>{t("Material Type")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={classification} />
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

OldInfoWrap.defaultProps = {
    applications: [],
    company: "",
    companyId: "",
};

OldInfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        logo: getStoreItem(state, "view.logo"),
        applications: getStoreItem(state, "view.applications"),
        company: getStoreItem(state, "view.company"),
        companyId: getStoreItem(state, "view.companyId"),
        name: getStoreItem(state, "view.name"),
        productId: getStoreItem(state, "view.productId"),
        productName: getStoreItem(state, "view.productName"),
        productCode: getStoreItem(state, "view.productCode"),
        classification: getStoreItem(state, "view.classification"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(OldInfoWrap);
