const Constants = {
    TABLE_UPDATE: "TABLE_UPDATE",
    NEXT_PAGE: "NEXT_PAGE",
    CHANGE_TAB: "CHANGE_TAB",

    FETCH_ITEMS_REQUESTED: "FETCH_ITEMS_REQUESTED",
    FETCH_ITEMS_SUCCEEDED: "FETCH_ITEMS_SUCCEEDED",
    FETCH_ITEMS_FAILED: "FETCH_ITEMS_FAILED",

    RESET_FORM: "RESET_FORM",
};
export default Constants;
