import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas} from "Services";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {
    TabBlock,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import {Div} from "Templates/Default";
import Breadcrumbs from "../Files/Breadcrumbs";

import ContainerTable from "../../../Services/Tabs/ChangesHistory/Table/ContainerTable";
import TableHeadColumnWrap from "./Table/TableRowColumn/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import {customTable} from "../../../Services/Tabs/ChangesHistory/Table/TabViewDetailWrap";

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TabTable);

class ChangesHistory extends Component {
    constructor(props) {
        super(props);
        this.folderForEdit = {};
        this.defaultState = {};

        this.state = {};
    }

    componentDidMount() {
        const { fetchPage } = this.props;
        fetchPage();
    }

    render() {
        const {pagination, t} = this.props;

        return (
            <>
                <TabWrapTop className="panel-changes-history">
                    <Div className="statistic-bar">
                        <TabBlock>
                            <TabBlockCol>
                                <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                                <TabBlockColTitleH4>
                                    {`${numberWithCommas(pagination.total, 0)} ${t(
                                        "Changes",
                                    )}`}
                                </TabBlockColTitleH4>
                            </TabBlockCol>
                        </TabBlock>
                    </Div>
                    <Breadcrumbs onClickItem={this.onClickItem} />
                </TabWrapTop>
                <TabViewDetail />
            </>
        );
    }
}

ChangesHistory.propTypes = {
    fetchPage: PropTypes.func.isRequired,
    pagination: PropTypes.shape({total: PropTypes.number}).isRequired
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        items: getStoreItem(state, "items"),
        pagination: getStoreItem(state, "pagination")
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchPage: getActionStore("fetchPage", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ChangesHistory);