import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../EventsService";

const placeholder = {
    name: "Contact Name",
    title: "Title",
    company: "Customer",
    customer_code: "Search",
    duns_number: "Search",
    phone: "Phone",
    email: "Email",
    type: "All",
    owner: "Search",
    created_at: "Search",
};

export const getFilter = (key, value, callback, request) => {
    switch (key) {
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
