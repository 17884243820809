import React, {Component} from "react";
import {Modal} from "Templates/Modals";
import {compose} from "redux";
import PropTypes from "prop-types";
import {numberWithCommas, url} from "Services";
import {Title, Total, WrapButtons} from "Templates/Titles";
import {MainButton, MainButtonLink, TabBlockBtn} from "Templates/Content";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import ShowPermission from "Hoc/Template/ShowPermission";
import TabCheck from "Templates/Tabs/TabСheck";
import selfService from "./Services/Tabs/Self";
import allService from "./Services/Tabs/All";
import archiveService from "./Services/Tabs/Archived";
import selfErrorService from "./Services/Tabs/Errors/Self";
import allErrorService from "./Services/Tabs/Errors/All";
import TabView from "./Views/TabView";
import TableSettingsView from "./Views/TableSettings/TableSettingsView";
import ResetFiltersView from "./Views/ResetFiltersView";
import tabList from "./Constants/tabList";
import UploadFileForm from "./Views/UploadFileForm";
import FiltersBlock from "./Views/filtersBlock";
import {sendProjectsFilterRequest} from "./Services/RequestService";
import {parseFilters} from "./Constants/tableFiltersList";

class Projects extends Component {
    constructor(props) {
        super(props);

        this.defaultState = {
            showEditColumnsModal: false,
            showImportTemplateModal: false,
            appliedFilter: {},
            selectedFilters: {},
            uploadFileForm: {
                files: [],
            },
        };

        this.state = {
            showEditColumnsModal: false,
            showImportTemplateModal: false,
            appliedFilter: {},
            selectedFilters: {},
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (window) {
            const locationUrl = new URL(window.location);
            const filtersString = locationUrl.searchParams.get('filters');

            if (filtersString) {
                const parsedFilters = JSON.parse(filtersString);
                const filters = parseFilters(parsedFilters);

                if (JSON.stringify(prevState.selectedFilters) !== JSON.stringify(filters)) {
                    this.setState({
                        selectedFilters: filters
                    });
                }
            } else {
                if (Object.keys(prevState.selectedFilters).length !== 0) {
                    this.setState({
                        selectedFilters: {}
                    });
                }
            }
        }
    }

    openEditColumnsModal = () => {
        this.setState({
            showEditColumnsModal: true,
        });
    };

    openImportTemplateModal = () => {
        this.setState({
            showImportTemplateModal: true,
        });
    };

    closeEditColumnsModal = () => {
        this.setState({
            showEditColumnsModal: false,
        });
    };

    closeImportTemplateModal = () => {
        this.setState({
            showImportTemplateModal: false,
        });
    };

    handleCloseEditColumnsModalWithCheck = e => {
        e.preventDefault();
        this.closeEditColumnsModal();
    };

    handleCloseImportTemplateModalWithCheck = e => {
        e.preventDefault();
        this.closeImportTemplateModal();
    };

    callbackAfterRequestInModalWindow = res => {
        if (res === undefined) {
            return;
        }

        this.closeImportTemplateModal();
        this.state.uploadFileForm = {files: []};
    };

    handleClickOnUploadFile = () => {
        const {addFile} = this.props;
        const {uploadFileForm: {files}} = this.state;

        return addFile({files}).then(this.callbackAfterRequestInModalWindow);
    };

    getExportLink = (type) => {
        switch (type) {
            case tabList.projects:
                return "/project/projects/export";
            case tabList.archive:
                return "/project/projects/export-archived";
            default:
                return "/project/projects/export-my-projects";
        }
    };

    getExportTemplateLink = (type) => {
        switch (type) {
            case tabList.projects:
            default:
                return "/project/projects/export-template";
        }
    };

    addImportProjectsFile = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                files: [...value],
            },
        }));
    };

    handleSaveModalWithCheck = (columnList, defaultColumns, pinnedColumns, idTemplate, noReload) => {
        const {
            service: {requestChangeSettings},
            activeTab,
            request,
            fetchSelfItems,
            fetchAllItems,
            fetchArchiveItems,
            fetchSelfErrorItems,
            fetchErrorItems,
        } = this.props;

        this.props.resetProjectFilters(true)

        requestChangeSettings({
            'templateId': idTemplate,
            'columns': columnList,
            'defaultColumns': defaultColumns,
            'pinnedColumns': pinnedColumns
        }).then(
            () => {
                if (noReload) return
                switch (activeTab) {
                    case 1:
                        fetchSelfItems(request)
                        return;
                    case 2:
                        fetchArchiveItems(request)
                        return;
                    case 3:
                        fetchSelfErrorItems(request)
                        return;
                    case 4:
                        fetchErrorItems(request)
                        return;
                    default:
                        fetchAllItems(request)
                }
            },
            errors => {
                alert(errors.detail);
            },
        );

        if (!noReload) {
            this.closeEditColumnsModal();
        }
    };

    applyFilters = (filtersItems, requestProjectsUrl) => {
        if (!filtersItems.length) {
            const filters = {}

            return sendProjectsFilterRequest({filters: {}, sort: this.props.request.sort}, requestProjectsUrl).then((res) => {
                this.props.applyFiltersActions({
                    items: res[0].items,
                    pagination: res[0].pagination,
                    columns: res[0].columns,
                    total_analytics: res[0].total_analytics,
                    pinned_columns: res[0].pinned_columns || [],
                    activeColumns: res[0].activeColumns,
                })
            }).then(() => {
                if(url.getActiveTab()){
                    filters.activeTab = url.getActiveTab();
                }

                const mergedFilters = {  };

                this.setState({appliedFilter: {}})

                url.addHistory(mergedFilters);
            });
        }

        const filters = parseFilters(filtersItems)

        sendProjectsFilterRequest({filters, sort: this.props.request.sort}, requestProjectsUrl).then((res) => {
            this.props.applyFiltersActions({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                total_analytics: res[0].total_analytics,
                pinned_columns: res[0].pinned_columns || [],
                activeColumns: res[0].activeColumns,
            })
        }).then(() => {
            if (url.getActiveTab()) {
                filters.activeTab = url.getActiveTab();
                this.setState({appliedFilter: filters})
            }
        });
    }

    render() {
        const tab = () => {
            let {activeTab} = this.props;
            const {onChangeTab, activeWeightUnit, permissions, t, total_analytics, request} = this.props;
            const { appliedFilter } = this.state;

            if (
                !permissions.includes('project_companies_team_view') && activeTab < tabList.my_projects
            ) {
                activeTab = tabList.my_projects;
                onChangeTab(activeTab);
            }

            return (
                <>
                    <Tabs
                        activeTab={activeTab}
                        onClickTabItem={index => {
                            if (activeTab !== index) {
                                this.setState({
                                    appliedFilter: {},
                                    selectedFilters: {}
                                })
                                onChangeTab(index);
                                activeWeightUnit([tabList.my_projects, tabList.projects, tabList.archive].includes(index));
                            }
                        }}
                    >
                        <TabCheck label={t("All Projects")} permission='project_companies_team_view'>
                            <ServiceProvider value={allService}>
                                <FiltersBlock isResetProjectFilters={this.props.isResetProjectFilters} filters={Object.keys(request.filter).length ? request.filter : appliedFilter} sort={request.sort} columns={columns} total_analytics={total_analytics} onApplyFilters={(filtersItems) => this.applyFilters(filtersItems, '/project/projects')} resetProjectFilters={() => {
                                    this.props.resetProjectFilters(false)
                                }}/>
                                <TabView/>
                            </ServiceProvider>
                        </TabCheck>
                        <Tab label={t("My Projects")}>
                            <ServiceProvider value={selfService}>
                                <FiltersBlock isResetProjectFilters={this.props.isResetProjectFilters} filters={Object.keys(request.filter).length ? request.filter : appliedFilter} sort={request.sort} columns={columns} total_analytics={total_analytics} onApplyFilters={(filtersItems) => this.applyFilters(filtersItems, '/project/projects/my-projects')} resetProjectFilters={() => {
                                    this.props.resetProjectFilters(false)
                                }}/>
                                <TabView/>
                            </ServiceProvider>
                        </Tab>
                        <Tab label={t("Archived")}>
                            <ServiceProvider value={archiveService}>
                                <FiltersBlock
                                    isResetProjectFilters={this.props.isResetProjectFilters}
                                    filters={Object.keys(request.filter).length ? request.filter : appliedFilter}
                                    sort={request.sort}
                                    columns={columns}
                                    total_analytics={total_analytics}
                                    onApplyFilters={(filtersItems) => this.applyFilters(filtersItems, '/project/projects/archived')}
                                    resetProjectFilters={() => {
                                        this.props.resetProjectFilters(false)
                                    }}/>
                                <TabView/>
                            </ServiceProvider>
                        </Tab>
                        <Tab label={t("My IHS Updates")}>
                            <ServiceProvider value={selfErrorService}>
                                <FiltersBlock isResetProjectFilters={this.props.isResetProjectFilters} filters={Object.keys(request.filter).length ? request.filter : appliedFilter} sort={request.sort} columns={columns} total_analytics={total_analytics} onApplyFilters={(filtersItems) => this.applyFilters(filtersItems, '/project/errors/my-error')} resetProjectFilters={() => {
                                    this.props.resetProjectFilters(false)
                                }}/>
                                <TabView/>
                            </ServiceProvider>
                        </Tab>
                        <TabCheck label={t("All IHS Updates")} permission='project_companies_team_view'>
                            <ServiceProvider value={allErrorService}>
                                <FiltersBlock isResetProjectFilters={this.props.isResetProjectFilters} filters={Object.keys(request.filter).length ? request.filter : appliedFilter} sort={request.sort} columns={columns} total_analytics={total_analytics} onApplyFilters={(filtersItems) => this.applyFilters(filtersItems, '/project/errors')} resetProjectFilters={() => {
                                    this.props.resetProjectFilters(false)
                                }}/>
                                <TabView/>
                            </ServiceProvider>
                        </TabCheck>
                    </Tabs>
                </>
            );
        };

        const {
            request,
            title,
            help,
            activeTab,
            activeWeightUnit,
            columns,
            activeColumns,
            getUrlExport,
            isActiveConvertor,
            t
        } = this.props;
        const {selectedFilters, showEditColumnsModal, showImportTemplateModal, uploadFileForm} = this.state;

        /* const lastUpdate = !updated_date ? null :
            (
                <>
                    <div className="break" />
                    <div className="last-update">{`${t("Last SAP Update")} - ${formatDate(updated_date)}`}</div>
                </>
            ); */

        const handleExportProjectsClick = (e) => {
            e.preventDefault();
            getUrlExport(url.getUrl(this.getExportLink(activeTab), { ...this.state.selectedFilters, sort: request.sort }));
        }

        const handleExportTemplateClick = (e) => {
            e.preventDefault();
            getUrlExport(url.getUrl(this.getExportTemplateLink(activeTab), { ...this.state.selectedFilters, sort: request.sort }));
        }

        if (typeof isActiveConvertor === "undefined") {
            activeWeightUnit([tabList.my_projects, tabList.projects, tabList.archive].includes(activeTab));
        }

        let activeService = selfService;

        if (activeTab === tabList.projects) {
            activeService = allService;
        } else if (activeTab === tabList.archive) {
            activeService = archiveService;
        }

        return (
            <ShowPermission checkPermission={['project_crud']}>
                {showEditColumnsModal ? (
                    <Modal onRequestClose={this.handleCloseEditColumnsModalWithCheck}>
                        <TableSettingsView
                            columns={columns}
                            activeColumns={activeColumns}
                            onAppend={this.handleSaveModalWithCheck}
                            onClose={this.handleCloseEditColumnsModalWithCheck}
                        />
                    </Modal>
                ) : <div/>}

                {showImportTemplateModal ? (
                    <Modal onRequestClose={this.handleCloseImportTemplateModalWithCheck}>
                        <UploadFileForm
                            form={uploadFileForm}
                            onAppend={this.handleClickOnUploadFile}
                            onClose={this.handleCloseImportTemplateModalWithCheck}
                            onChange={this.addImportProjectsFile}
                        />
                    </Modal>
                ) : <div/>}

                <Title
                    title={t("Projects")}
                    help={help}
                    total={() => <Total>{`${numberWithCommas(title)} ${t("Total")}`}</Total>}
                >
                    <WrapButtons>
                        {
                            [tabList.my_projects, tabList.projects, tabList.archive].includes(activeTab) && (
                                <>
                                    <ServiceProvider
                                        value={activeService}
                                    >
                                        <ResetFiltersView selectedFilters={selectedFilters}/>
                                    </ServiceProvider>

                                    <TabBlockBtn onClick={this.openEditColumnsModal} className="main-btn_white">
                                        {t("Edit Column")}
                                    </TabBlockBtn>

                                    {[tabList.my_projects].includes(activeTab) && (
                                        <>
                                            <MainButtonLink
                                                className="main-btn_white"
                                                onClick={(e) => handleExportTemplateClick(e)}
                                            >
                                                {t("Export Template")}
                                            </MainButtonLink>

                                            <MainButton
                                                className="main-btn_white"
                                                onClick={this.openImportTemplateModal}
                                            >
                                                {t("Import Template")}
                                            </MainButton>
                                        </>
                                    )}
                                    <MainButtonLink
                                        disabled
                                        className="main-btn_white"
                                        onClick={(e) => handleExportProjectsClick(e)}
                                    >
                                        {t("Export Projects")}
                                    </MainButtonLink>
                                </>
                            )
                        }
                        {
                            [tabList.my_projects, tabList.projects].includes(activeTab) && (
                                <CheckPermission permission="project_crud">
                                    <MainButtonLink href="/project/projects/create" className="main-btn_primary">
                                        {t("Add New Project")}
                                    </MainButtonLink>
                                </CheckPermission>
                            )
                        }
                    </WrapButtons>
                    {/* {lastUpdate} */}
                </Title>
                {tab()}
            </ShowPermission>
        );
    }
}

Projects.defaultProps = {
    help: ''
}

Projects.propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeTab: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    onChangeTab: PropTypes.func.isRequired,
    activeWeightUnit: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        isResetProjectFilters: getStoreItem(state, "isResetProjectFilters"),
        total_analytics: state.listState.total_analytics,
        columns: getStoreItem(state, "columns"),
        activeColumns: getStoreItem(state, "activeColumns"),
        isActiveConvertor: getStoreItem(state, "isActive"),
        title: getStoreItem(state, "pagination.total"),
        activeTab: getStoreItem(state, "activeTab"),
        updated_date: getStoreItem(state, "updated_date"),
        help: getStoreItem(state, "help"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchSelfItems: getActionStore("fetchItems", selfService, dispatch),
        fetchAllItems: getActionStore("fetchItems", allService, dispatch),
        fetchArchiveItems: getActionStore("fetchItems", archiveService, dispatch),
        fetchSelfErrorItems: getActionStore("fetchItems", selfErrorService, dispatch),
        fetchErrorItems: getActionStore("fetchItems", allErrorService, dispatch),

        resetFilter: getActionStore("resetFilter", service, dispatch),
        resetProjectFilters: getActionStore("onResetProjectFilters", service, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        getUrlExport: getActionStore("getUrlExport", service, dispatch),
        activeWeightUnit: getActionStore("activeWeightUnit", service, dispatch),
        addFile: getActionStore("createFilesAction", service, dispatch),
        applyFiltersActions: getActionStore("applyFilters", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Projects);
