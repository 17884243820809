import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Block, BlockWrap} from "Templates/Form";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import TableBodyColumn from "./TableBodyColumn";
import TableHeadColumn from "./TableHeadColumn";
import Img from "Templates/Img/Img";
import ConnectionsTableHeadColumn from "./ConnectionsTableHeadColumn";
import ConnectionsTableBodyColumn from "./ConnectionsTableBodyColumn";
import BudgetSalesTableHeadColumn from "./Budget&SalesTableHeadColumn";
import BudgetSalesTableBodyColumn from "./Budget&salesTableBodyColumn";
import budgets from "../../../../../../../Connection/Index/Services/Tabs/Budgets";

const propTypes = {
    isConnectionType:PropTypes.bool.isRequired,
    isActualsType:PropTypes.bool.isRequired,
    isConnectionProjectType:PropTypes.bool.isRequired,
};

const Table = ({ data, isConnectionType, isActualsType, pref, isConnectionProjectType }) => {
    const SimpleTable = CustomSimpleTable()(TableHeadColumn)()(TableBodyColumn)()(() => null);
    const ConnectionsTable = CustomSimpleTable()(ConnectionsTableHeadColumn)()(ConnectionsTableBodyColumn)()(() => null);
    const BudgetsSalesTable = CustomSimpleTable()(BudgetSalesTableHeadColumn)()(BudgetSalesTableBodyColumn)()(() => null);
    return (
        <>
            {Object.keys(data).length > 0 ? (
                <Block>
                    <BlockWrap className={`${pref}__form-block-card`}>
                        {isConnectionType ? <ConnectionsTable isConnectionProjectType={isConnectionProjectType} /> : isActualsType ? <div className="actuals-and-budgets"><BudgetsSalesTable /></div> : <div className="my-analiticts-table"><SimpleTable /></div>}
                    </BlockWrap>
                </Block>
            ) : <div className="empty-product-codes__plug">
                <Img img="filter_icon"/>
                <p>Apply the filters from the top first</p>
            </div>}
        </>
    );
};

Table.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        data: getStoreItem(state, "data"),
        isConnectionType: getStoreItem(state, "isConnectionType"),
        isConnectionProjectType: getStoreItem(state, "isConnectionProjectType"),
        isActualsType: getStoreItem(state, "isActualsType"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Table);
