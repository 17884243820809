import React from "react";

export const generateColumns = (showColumns, beforePrevYear, prevYear, currYear, nextYear) => showColumns.reduce((acc, cur) => {
    if (cur.includes(`FY_${beforePrevYear}_Q4`)) {
        acc.push({title: `FY ${beforePrevYear} Q4`, value: `title_FY_${beforePrevYear}_Q4`});
    }

    if (cur.includes(`FY_${prevYear}_Q1`)) {
        acc.push({title: `FY ${prevYear} Q1`, value: `title_FY_${prevYear}_Q1`});
    }

    if (cur.includes(`FY_${prevYear}_Q2`)) {
        acc.push({title: `FY ${prevYear} Q2`, value: `title_FY_${prevYear}_Q2`});
    }

    if (cur.includes(`FY_${prevYear}_Q3`)) {
        acc.push({title: `FY ${prevYear} Q3`, value: `title_FY_${prevYear}_Q3`});
    }

    if (cur.includes(`FY_${prevYear}_Q4`)) {
        acc.push({title: `FY ${prevYear} Q4`, value: `title_FY_${prevYear}_Q4`});
    }

    if (cur.includes(`FY_${prevYear}`) && !cur.includes(`volume_FY_${prevYear}_`) && !cur.includes(`revenue_FY_${prevYear}_`) && !cur.includes(`FY_${prevYear}_Q`)) {
        acc.push({title: `FY ${prevYear}`, value: `title_FY_${prevYear}`});
    }

    if (cur.includes(`FY_${currYear}_Q1`)) {
        acc.push({title: `FY ${currYear} Q1`, value: `title_FY_${currYear}_Q1`});
    }

    if (cur.includes(`FY_${currYear}_Q2`)) {
        acc.push({title: `FY ${currYear} Q2`, value: `title_FY_${currYear}_Q2`});
    }

    if (cur.includes(`FY_${currYear}_Q3`)) {
        acc.push({title: `FY ${currYear} Q3`, value: `title_FY_${currYear}_Q3`});
    }

    if (cur.includes(`FY_${currYear}_Q4`)) {
        acc.push({title: `FY ${currYear} Q4`, value: `title_FY_${currYear}_Q4`});
    }

    if (cur.includes(`FY_${currYear}`) && !cur.includes(`volume_FY_${currYear}_`) && !cur.includes(`revenue_FY_${currYear}_`) && !cur.includes(`FY_${currYear}_Q`)) {
        acc.push({title: `FY ${currYear}`, value: `title_FY_${currYear}`});
    }

    if (cur.includes(`FY_${nextYear}_Q1`)) {
        acc.push({title: `FY ${nextYear} Q1`, value: `title_FY_${nextYear}_Q1`});
    }

    if (cur.includes(`FY_${nextYear}_Q2`)) {
        acc.push({title: `FY ${nextYear} Q2`, value: `title_FY_${nextYear}_Q2`});
    }

    if (cur.includes(`FY_${nextYear}_Q3`)) {
        acc.push({title: `FY ${nextYear} Q3`, value: `title_FY_${nextYear}_Q3`});
    }

    if (cur.includes(`FY_${nextYear}_Q4`)) {
        acc.push({title: `FY ${nextYear} Q4`, value: `title_FY_${nextYear}_Q4`});
    }

    if (cur.includes(`FY_${nextYear}`) && !cur.includes(`volume_FY_${nextYear}_`) && !cur.includes(`revenue_FY_${nextYear}_`) && !cur.includes(`FY_${nextYear}_Q`)) {
        acc.push({title: `FY ${nextYear}`, value: `title_FY_${nextYear}`});
    }

    if (cur.includes(`volume_FY_${prevYear}_Jul`)) {
        acc.push({title: `Volume FY ${prevYear}`, value: `title_volume_FY_${prevYear}_month`, monthly: true});
    }

    if (cur.includes(`volume_FY_${currYear}_Jul`)) {
        acc.push({title: `Volume FY ${currYear}`, value: `title_volume_FY_${currYear}_month`, monthly: true});
    }

    if (cur.includes(`volume_FY_${nextYear}_Jul`)) {
        acc.push({title: `Volume FY ${nextYear}`, value: `title_volume_FY_${nextYear}_month`, monthly: true});
    }

    if (cur.includes(`revenue_FY_${prevYear}_Jul`)) {
        acc.push({title: `Revenue FY ${prevYear}`, value: `title_revenue_FY_${prevYear}_month`, monthly: true});
    }

    if (cur.includes(`revenue_FY_${currYear}_Jul`)) {
        acc.push({title: `Revenue FY ${currYear}`, value: `title_revenue_FY_${currYear}_month`, monthly: true});
    }

    if (cur.includes(`revenue_FY_${nextYear}_Jul`)) {
        acc.push({title: `Revenue FY ${nextYear}`, value: `title_revenue_FY_${nextYear}_month`, monthly: true});
    }

    return acc;
}, [])

export const generateUniqCols = (uniqueTitles, cols) => cols.filter(item => {
    if (!uniqueTitles[item.title]) {
        uniqueTitles[item.title] = true;
        return true;
    }
    return false;
});

const setWidth = (title, columns, activeTab) => {
    if (activeTab === 3) {
        if (title && columns.length) {
            title.style.maxWidth = `${138 * columns.length - 4}px`
            title.style.minWidth = `${138 * columns.length - 4}px`
        }

        return
    }

    if (activeTab === 2) {
        if (title && columns.length) {
            title.style.maxWidth = `${138 * columns.length}px`
            title.style.minWidth = `${138 * columns.length}px`
        }

        return
    }

    if (title && columns.length) {
        title.style.maxWidth = `${138 * columns.length - 6}px`
        title.style.minWidth = `${138 * columns.length - 6}px`
    }
}

export const generateBeforePrevYearTitleWidth = (beforePrevYear, activeTab) => {
    const beforePrevYearTitleQ4 = document.querySelector(`.title_FY_${beforePrevYear}_Q4`)
    const beforePrevYearColumnsQ4 = document.querySelectorAll(`.FY_${beforePrevYear}_Q4`)
    setWidth(beforePrevYearTitleQ4, beforePrevYearColumnsQ4, activeTab)
}

export const generatePrevYearTitleWidth = (prevYear, activeTab) => {
    const prevYearTitleQ1 = document.querySelector(`.title_FY_${prevYear}_Q1`)
    const prevYearColumnsQ1 = document.querySelectorAll(`.FY_${prevYear}_Q1`)
    setWidth(prevYearTitleQ1, prevYearColumnsQ1, activeTab)

    const prevYearTitleQ2 = document.querySelector(`.title_FY_${prevYear}_Q2`)
    const prevYearColumnsQ2 = document.querySelectorAll(`.FY_${prevYear}_Q2`)
    setWidth(prevYearTitleQ2, prevYearColumnsQ2, activeTab)

    const prevYearTitleQ3 = document.querySelector(`.title_FY_${prevYear}_Q3`)
    const prevYearColumnsQ3 = document.querySelectorAll(`.FY_${prevYear}_Q3`)
    setWidth(prevYearTitleQ3, prevYearColumnsQ3, activeTab)

    const prevYearTitleQ4 = document.querySelector(`.title_FY_${prevYear}_Q4`)
    const prevYearColumnsQ4 = document.querySelectorAll(`.FY_${prevYear}_Q4`)
    setWidth(prevYearTitleQ4, prevYearColumnsQ4, activeTab)

    const prevYearTitle = document.querySelector(`.title_FY_${prevYear}`)
    const prevYearColumns = document.querySelectorAll(`.FY_${prevYear}_prev`)
    setWidth(prevYearTitle, prevYearColumns, activeTab)

}

export const generateCurrYearTitleWidth = (currYear, activeTab) => {
    const currYearTitleQ1 = document.querySelector(`.title_FY_${currYear}_Q1`)
    const currYearColumnsQ1 = document.querySelectorAll(`.FY_${currYear}_Q1`)
    setWidth(currYearTitleQ1, currYearColumnsQ1, activeTab)

    const currYearTitleQ2 = document.querySelector(`.title_FY_${currYear}_Q2`)
    const currYearColumnsQ2 = document.querySelectorAll(`.FY_${currYear}_Q2`)
    setWidth(currYearTitleQ2, currYearColumnsQ2, activeTab)

    const currYearTitleQ3 = document.querySelector(`.title_FY_${currYear}_Q3`)
    const currYearColumnsQ3 = document.querySelectorAll(`.FY_${currYear}_Q3`)
    setWidth(currYearTitleQ3, currYearColumnsQ3, activeTab)

    const currYearTitleQ4 = document.querySelector(`.title_FY_${currYear}_Q4`)
    const currYearColumnsQ4 = document.querySelectorAll(`.FY_${currYear}_Q4`)
    setWidth(currYearTitleQ4, currYearColumnsQ4, activeTab)

    const currYearTitle = document.querySelector(`.title_FY_${currYear}`)
    const currYearColumns = document.querySelectorAll(`.FY_${currYear}_curr`)
    setWidth(currYearTitle, currYearColumns, activeTab)
}

export const generateNextYearTitleWidth = (nextYear, activeTab) => {
    const nextYearTitleQ1 = document.querySelector(`.title_FY_${nextYear}_Q1`)
    const nextYearColumnsQ1 = document.querySelectorAll(`.FY_${nextYear}_Q1`)
    setWidth(nextYearTitleQ1, nextYearColumnsQ1, activeTab)

    const nextYearTitleQ2 = document.querySelector(`.title_FY_${nextYear}_Q2`)
    const nextYearColumnsQ2 = document.querySelectorAll(`.FY_${nextYear}_Q2`)
    setWidth(nextYearTitleQ2, nextYearColumnsQ2, activeTab)

    const nextYearTitleQ3 = document.querySelector(`.title_FY_${nextYear}_Q3`)
    const nextYearColumnsQ3 = document.querySelectorAll(`.FY_${nextYear}_Q3`)
    setWidth(nextYearTitleQ3, nextYearColumnsQ3, activeTab)

    const nextYearTitleQ4 = document.querySelector(`.title_FY_${nextYear}_Q4`)
    const nextYearColumnsQ4 = document.querySelectorAll(`.FY_${nextYear}_Q4`)
    setWidth(nextYearTitleQ4, nextYearColumnsQ4, activeTab)

    const nextYearTitle = document.querySelector(`.title_FY_${nextYear}`)
    const nextYearColumns = document.querySelectorAll(`.FY_${nextYear}_next`)
    setWidth(nextYearTitle, nextYearColumns, activeTab)
}

export const generateVolumeTitleWidth = (prevYear, currYear, nextYear, activeTab) => {
    const volumeTitlePrevYear = document.querySelector(`.title_volume_FY_${prevYear}_month`)
    const volumeColumnsPrevYear = document.querySelectorAll(`.volume_FY_${prevYear}_month`)
    setWidth(volumeTitlePrevYear, volumeColumnsPrevYear, activeTab)

    const volumeTitleCurrYear = document.querySelector(`.title_volume_FY_${currYear}_month`)
    const volumeColumnsCurrYear = document.querySelectorAll(`.volume_FY_${currYear}_month`)
    setWidth(volumeTitleCurrYear, volumeColumnsCurrYear, activeTab)

    const volumeTitleNextYear = document.querySelector(`.title_volume_FY_${nextYear}_month`)
    const volumeColumnsNextYear = document.querySelectorAll(`.volume_FY_${nextYear}_month`)
    setWidth(volumeTitleNextYear, volumeColumnsNextYear, activeTab)
}

export const generateRevenueTitleWidth = (prevYear, currYear, nextYear, activeTab) => {
    const revenueTitlePrevYear = document.querySelector(`.title_revenue_FY_${prevYear}_month`)
    const revenueColumnsPrevYear = document.querySelectorAll(`.revenue_FY_${prevYear}_month`)
    setWidth(revenueTitlePrevYear, revenueColumnsPrevYear, activeTab)

    const revenueTitleCurrYear = document.querySelector(`.title_revenue_FY_${currYear}_month`)
    const revenueColumnsCurrYear = document.querySelectorAll(`.revenue_FY_${currYear}_month`)
    setWidth(revenueTitleCurrYear, revenueColumnsCurrYear, activeTab)

    const revenueTitleNextYear = document.querySelector(`.title_revenue_FY_${nextYear}_month`)
    const revenueColumnsNextYear = document.querySelectorAll(`.revenue_FY_${nextYear}_month`)
    setWidth(revenueTitleNextYear, revenueColumnsNextYear, activeTab)
}

export const generateTitles = (uniqCols, activeTab, TABS, beforePrevYear, prevYear, currYear) => {
    return uniqCols.map((item, i) => {
        return <li key={i} className={`
                ${item.value}
                ${item.monthly ? 'monthly' : ''}
                ${activeTab === TABS.QUARTERLY.value && item.title.includes(`${prevYear}`) ? 'quarterly_last-year' : ''}
                ${activeTab === TABS.MONTHLY.value && item.title.includes(`${prevYear}`) ? 'monthly_last-year' : ''}
                ${activeTab === TABS.FORECAST.value && item.title.includes(`FY ${prevYear} Q1`) ? 'forecast_big' : ''}
                ${activeTab === TABS.FORECAST.value && item.title.includes(`FY ${prevYear} Q2`) ? 'forecast_big' : ''}
                ${activeTab === TABS.FORECAST.value && item.title.includes(`FY ${prevYear} Q3`) ? 'forecast_big' : ''}
                ${activeTab === TABS.FORECAST.value && item.title.includes(`FY ${prevYear} Q4`) ? 'forecast_big' : ''}
                ${activeTab === TABS.FORECAST.value && item.title.includes(`FY ${currYear} Q1`) ? 'forecast_big' : ''}
                ${activeTab === TABS.FORECAST.value && item.title.includes(`FY ${currYear} Q2`) ? 'forecast_big' : ''}
                ${activeTab === TABS.FORECAST.value && item.title.includes(`FY ${currYear} Q3`) ? 'forecast_big' : ''}
                ${activeTab === TABS.FORECAST.value && item.title.includes(`FY ${currYear} Q4`) ? 'forecast_big' : ''}
            `}><p>{item.title}</p></li>
    })
}