import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    // ContentInfoLogo,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap,
} from "Templates/Content";

const defaultProps = {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
};

const propTypes = {
    // logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string,
    onClickEdit: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const InfoWrap = ({ name, address1, address2, city, state, zip, country, t, onClickEdit, permissions }) => {
    const addressExists = (address) => {
        return !!(address.address1 + address.address2 + address.city + address.state + address.zip + address.country).trim();
    }

    return (
        <ContentInfoWrap>
            <ContentInfoTop
                onClickEdit={permissions.includes("plant_raw_crud") ? onClickEdit : null}
                urlBack="/product/plants"
            />
            {/* <ContentInfoLogo url={logo} /> */}

            <ContentInfoBlock>
                <ContentInfoTitle>{name}</ContentInfoTitle>
            </ContentInfoBlock>

            <ContentInfoBlock show={addressExists({address1, address2, city, state, zip, country})}>
                <ContentInfoBlockTitle>{t("Address")}</ContentInfoBlockTitle>
                <ContentInfoBlockText value={address1} />
                <ContentInfoBlockText value={address2} />
                <ContentInfoBlockText value={`${`${city ? `${city},` : ""}`} ${state} ${zip}`} />
                <ContentInfoBlockText value={country} />
            </ContentInfoBlock>
        </ContentInfoWrap>
    );
};

InfoWrap.defaultProps = defaultProps;
InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    // const formatAddress = obj =>
    //     _.replace([obj.address1, obj.address2, obj.zip, obj.city, obj.state, obj.country].join(" "), "  ", " ");

    return {
        // logo: getStoreItem(state, "view.logo"),
        name: getStoreItem(state, "view.name"),
        address1: getStoreItem(state, "view.address1"),
        address2: getStoreItem(state, "view.address2"),
        zip: getStoreItem(state, "view.zip"),
        city: getStoreItem(state, "view.city"),
        state: getStoreItem(state, "view.state"),
        country: getStoreItem(state, "view.country"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
