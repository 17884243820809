import _ from "lodash";
import { formDataNormalizer, countryListObj, stateListObj } from "Services";

export const formData = data => {
    const dataReq = {
        name: _.get(data, "name"),
        sap_plant_id: _.get(data, "sap_plant_id", ''),
        logo: _.get(data, "logo"),

        country: _.get(countryListObj, _.get(data, "country")),
        city: _.get(data, "city"),
        address1: _.get(data, "address1"),
        address2: _.get(data, "address2"),
        zip: _.get(data, "zip"),
        state: _.get(stateListObj, _.get(data, "state"), ""),
    };

    return formDataNormalizer(dataReq);
};
