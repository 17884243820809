import Constants from "../Constants";
import {requestDeleteProperty} from "../Services";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const onChangeShowBtn = (service, dispatch) => (id, key) => {
    dispatch({ type: Constants.SHOW_BTN_REQUESTED });

    return new Promise((resolve, reject) => {
        service.requestHierarchyData(id).then(
            res => {
                const value = Object.values(res);
                dispatch({ type: Constants.SHOW_BTN_SUCCEEDED, data: { key, value } });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.SHOW_BTN_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const deleteProperty = (service, dispatch) => (id) => {
    dispatch({ type: Constants.DELETE_PROPERTY_REQUESTED });

    return new Promise((resolve, reject) => {
        service.requestDeleteProperty(id).then(
            res => {
                dispatch({ type: Constants.DELETE_PROPERTY_SUCCEEDED, data: {} });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.DELETE_PROPERTY_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.FORM_RESET });
};

export const refreshProperty = (service, dispatch) => hierarchyProperties => {
    dispatch({ type: Constants.SET_PROPERTY, data: { hierarchyProperties } });
};

export const addProperty = (service, dispatch) => (hierarchyProperties) => {
    dispatch({ type: Constants.ADD_PROPERTY, data: { hierarchyProperties } });
};

export const submitForm = (service, dispatch) => (data) => {
    dispatch({ type: Constants.FORM_REQUESTED });
    const { formData } = service.FormService;
    return new Promise((resolve, reject) => {
        service.save(formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};
