import React, {Component} from "react";
import {Modal} from "Templates/Modals";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import UploadFileForm from "./UploadFileForm";

const propTypes = {
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

class PublicationFileUploader extends Component {

    constructor(props) {
        super(props);

        const {fileName} = props;

        this.state = {
            showUploadModal: false,
            fileName: fileName ? fileName : '',
            uploadFileForm: {
                files: [],
            },
        };
    }

    onCloseUploadModal = () => {
        this.setState({
            showUploadModal: false,
        });
    }

    onOpenUploadModal = () => {
        if (this.state.file) {
            return;
        }
        this.setState({
            showUploadModal: true,
        });
    }

    onAddFiles = ([file]) => {
        if (!file) {
            return;
        }
        this.setState(_ => ({
            fileName: file.name,
            uploadFileForm: {
                files: [file],
            }
        }));
        this.props.onChange('file', file);
        this.props.onChange('fileName', file.name)
    };

    dropFile = () => {
        this.setState(_ => ({
            uploadFileForm: {
                fileName: '',
                files: [],
            },
            fileName: null,
        }));
        const {
            onChange
        } = this.props;
        onChange('file', null);
        onChange('fileName', '');
    }

    render() {
        const {t, title} = this.props;
        const {showUploadModal, uploadFileForm, fileName} = this.state;

        return (
            <>
                <div className="publications__upload-file">
                    <label
                        htmlFor="uploadFileBtn"
                        className="publication-designs-edit__label main-content__label required"
                    >
                        {t(title)}
                    </label>
                    <div className="publications__upload-file-action">
                        <button
                            id="uploadFileBtn"
                            onClick={this.onOpenUploadModal}
                            disabled={!!fileName}
                            className="publication-designs-edit__buttons-btn main-content__buttons-btn main-btn"
                        >
                            {t(`Upload ${title}`)}
                        </button>

                        {fileName ? (
                            <ul className="main-modal__form-list publications__upload-file-list">
                                <li className="main-modal__form-list-item">
                                    {fileName}
                                    <i className="la la-icon-close" onClick={this.dropFile}/>
                                </li>
                            </ul>
                        ) : null}
                    </div>
                </div>

                {showUploadModal ? (
                    <Modal onRequestClose={this.onCloseUploadModal}>
                        <UploadFileForm
                            onAppend={this.onCloseUploadModal}
                            onClose={this.onCloseUploadModal}
                            onChange={this.onAddFiles}
                            onDrop={this.dropFile}
                            form={uploadFileForm}
                            title={title}
                        />
                    </Modal>
                ) : null}
            </>
        );
    }
}

PublicationFileUploader.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        file: getStoreItem(state, "file"),
        fileName: getStoreItem(state, "fileName"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(PublicationFileUploader);
