import _ from "lodash";
import * as PurchasePricesActions from "../../../Actions/Tabs/PurchasePricesActions";

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...PurchasePricesActions
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
