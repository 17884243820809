import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Row } from "Templates/Form";
import Img from "Templates/Img/Img";

class Index extends Component {
    state = {
        newPassword: "",
        confirmNewPassword: "",
    };

    handleChangeString = ({ target }) => {
        this.setState({
            [target.name]: target.value,
        });
    };

    checkForm = () => {
        const { newPassword, confirmNewPassword } = this.state;
        const minPasswordLength = 6;
        return !(
            newPassword.length >= minPasswordLength &&
            confirmNewPassword.length >= minPasswordLength &&
            newPassword === confirmNewPassword
        );
    };

    handleClickOnSubmitForm = e => {
        e.preventDefault();
        const { submitForm } = this.props;
        const { newPassword } = this.state;
        submitForm({ password: newPassword });
    };

    renderForm = () => {
        const { newPassword, confirmNewPassword } = this.state;
        const { t } = this.props;

        return (
            <>
                <h1 className="forgot__title">{t("Reset Your Password")}</h1>
                <div className="forgot__desc">{t("Almost done. Enter your new password.")}</div>
                <form action="/" className="forgot__form" onSubmit={this.handleClickOnSubmitForm}>
                    <Row>
                        <label htmlFor="newPassword" className="forgot__form-label">
                            {t("New password")}
                            <input
                                required
                                id="newPassword"
                                type="password"
                                name="newPassword"
                                className="forgot__form-input"
                                value={newPassword}
                                onChange={this.handleChangeString}
                            />
                        </label>
                        <span className="forgot__form-note">{t("Minimum 6 characters")}</span>
                    </Row>
                    <Row>
                        <label htmlFor="email" className="forgot__form-label">
                            {t("Confirm new password")}
                            <input
                                required
                                id="confirmNewPassword"
                                type="password"
                                name="confirmNewPassword"
                                className="forgot__form-input"
                                value={confirmNewPassword}
                                onChange={this.handleChangeString}
                            />
                        </label>
                    </Row>
                    <Row>
                        <button disabled={this.checkForm()} type="submit" className="forgot__form-submit main-btn">
                            {t("Save Password")}
                        </button>
                    </Row>
                    <Row>
                        <a href="/login" className="forgot__form-return">
                            {t("Return to login")}
                        </a>
                    </Row>
                </form>
            </>
        );
    };

    render() {
        const { error, t } = this.props;

        return (
            <div className="main-wrap forgot">
                <div className="forgot__wrap">
                    <div className="forgot__logo">
                        <a href="/" className="forgot__logo-link">
                            <Img img="reset_password_logo" className="forgot__logo-img" alt="logo" />
                        </a>
                    </div>
                    {error ? error : this.renderForm()}
                </div>
            </div>
        );
    }
}

Index.propTypes = {
    submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        error: state.listState.error,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Index);
