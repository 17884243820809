import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import { Title } from "Templates/Titles";
import { url, formatDate } from "Services";
import LinkIcon from "./Views/Templates/LinkIcon";
import InfoWrap from "./Views/InfoWrap";

const propTypes = {
    name: PropTypes.string.isRequired,
};

const Index = ({ name, t, permissions }) => {
    useEffect(() => {
        if(!permissions.includes('admin_templates-page_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    return (
        <>
            <Title title={t(name)} before={() => <LinkIcon href="/admin/import-templates" />} />
            <InfoWrap />
        </>
    );
};

Index.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        name: formatDate(state.listState.view.date),
        permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(Index);
