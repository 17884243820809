import React from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";
import ReactJson from 'react-json-view';

const { Button, Form, Title } = ModalDefault;

const JsonModal = (
    {
        jsonData,
        onClose,
        t,
    }
) => {
    const handleSave = () => {
        const jsonString = JSON.stringify(jsonData, null, 2);
        const textarea = document.createElement('textarea');
        textarea.value = jsonString;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
    };

    return (
        <Form className="json-modal-form">
            <div className="form-title-wrap">
                <Title>{t('Preview JSON')}</Title>
                <ButtonClose className="main-modal__form-close" onClick={onClose}/>
            </div>
            <div className="json-preview">
                <ReactJson src={jsonData} />
            </div>
            <Button type="button" onClick={handleSave}>
                {t("Copy")}
            </Button>
        </Form>
    );
}

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {};
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(JsonModal);
