import Constants from "../Constants";

export const onChangeTab = (service, dispatch) => (data) => {
    dispatch({type: Constants.CHANGE_TAB, data});
};

export const openModal = (service, dispatch) => () => {
    dispatch({ type:Constants.OPEN_MODAL })
}

export const closeModal = (service, dispatch) => () => {
    dispatch({ type:Constants.CLOSE_MODAL })
}

export const openEditModal = (service, dispatch) => () => {
    dispatch({ type:Constants.OPEN_EDIT_MODAL })
}

export const closeEditModal = (service, dispatch) => () => {
    dispatch({ type:Constants.CLOSE_EDIT_MODAL })
}

export const onChangeForm = (service, dispatch) => data => {
    dispatch({type: Constants.UPDATE_VENDOR_FORM, data: { key: data.key, value: data.value }});
};

export const getItems = (service, dispatch) => (payload) => {
    dispatch({type: Constants.FETCH_ITEMS_REQUESTED});
    service.requestTable(payload).then(response => {
        const data = response;
        const type = payload && payload.page ?
            Constants.NEXT_PAGE :
            Constants.FETCH_ITEMS_SUCCEEDED;
        dispatch({type, data});
    }, errors => {
        dispatch({type: Constants.FETCH_ITEMS_FAILED, data: errors});
    })
};

export const saveVendorCode = (service, dispatch) => data => {
    const {FormService} = service;
    const payload = FormService.saveVendorFormData(data);
    service.requestSaveVendor(payload).then(() => {
        alert("Success: Vendor has been created!")
        dispatch({type: Constants.SAVE_VENDOR_SUCCEEDED});
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.SAVE_VENDOR_REJECTED, data: errors});
    });
};

export const editVendorCode = (service, dispatch) => data => {
    const {FormService} = service;
    const { vendorId, formData } = data;
    const payload = FormService.saveVendorFormData(formData);
    service.requestEditVendor({vendorId, payload}).then(() => {
        alert("Success: Vendor has been updated!")
        dispatch({type: Constants.SAVE_VENDOR_SUCCEEDED});
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.SAVE_VENDOR_REJECTED, data: errors});
    });
};

export const deleteVendorCode = (service, dispatch) => data => {
    service.requestOnDeleteVendorCode(data).then(() => {
        alert("Success: Vendor has been deleted!");
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.SAVE_VENDOR_REJECTED, data: errors});
    });
}