import { debounce } from "Services/Debounce";
import Constants from "../Constants";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const activeWeightUnit = (service, dispatch) => data => {
    dispatch({type: Constants.IS_ACTIVE_COVERTOR, data});
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (
        [
            'customer',
            'customer_full',
            'material_number',
            'customer_code',
            'billing_date',
            'created_on',
            'product_code',
            'application_code',
            'plant',
            'cancelled',
            'free_of_charge'
        ].includes(key)
    ) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const executeExport = (service, dispatch) => (data) => {
    dispatch({type: Constants.EXPORT_INVOICES, data});

    const {requestExportInvoices} = service;

    return requestExportInvoices(data).then(
        res => {
            alert('Export Invoices started. Check notifications to download.');
            dispatch({type: Constants.EXPORT_INVOICES_SUCCESS, data: res});
        },
        res => {
            alert(res.detail);
            dispatch({type: Constants.EXPORT_INVOICES_FAILED, data: res});
        },
    );
};

export const resetFilter = (service, dispatch) => (request) => {
    dispatch({ type: Constants.RESET_FILTER, data: {} });
    fetchItems(service, dispatch)(request);
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};
