import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps, CheckPermission} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";

/*
 * This component get existing items and after backend getChildren request add response children to items
 * This is
 */
const Children = ({item, columns, k, tableButtonsPermission, TableRow}) => {
    return (
        <>
            <tr key={`TableRowColumn-${item.id}`}>
                {columns.map((column, index) => (
                    <Fragment key={`TableRowColumn-${item.id}-${index}`}>
                        {column === "-1" ? (
                            <CheckPermission permission={tableButtonsPermission}>
                                {TableRow(column, item, index, k)}
                            </CheckPermission>
                        ) : (
                            <>{TableRow(column, item, index, k)}</>
                        )}
                    </Fragment>
                ))}
            </tr>
            {item.children && item.children.length && item.children.map((child, chIndex) => {
                return (
                    <Children
                        key={child.id}
                        item={child}
                        columns={columns}
                        k={chIndex}
                        tableButtonsPermission={tableButtonsPermission}
                        TableRow={TableRow}
                    />
                )
            })}
        </>
    )
}

const HierarchyTableBody = ({
                                items,
                                columns,
                                customIcon,
                                onClickEdit,
                                onClickDelete,
                                onClickCustom,
                                onClickItem,
                                TableRowColumn,
                                addHierarchy,
                                service: {tableButtonsPermission},
                            }) => {
    const TableRow = (column, item, index, k) => {
        return (
            <td className={`main-table__body ${column === "-1" ? "main-table__body--sticky" : ""}`}>
                <TableRowColumn
                    item={item}
                    index={index}
                    secondaryIndex={k}
                    column={column}
                    customIcon={customIcon}
                    onClickCustom={onClickCustom}
                    onClickItem={onClickItem}
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                    addHierarchy={addHierarchy}
                />
            </td>
        );
    };

    return (
        <>
            {Array.isArray(items) &&
                items.map((item, k) => (
                    <Children
                        key={item.id}
                        item={item}
                        columns={columns}
                        k={k}
                        tableButtonsPermission={tableButtonsPermission}
                        TableRow={TableRow}
                    />))}
        </>
    );
};

HierarchyTableBody.defaultProps = {
    customIcon: "",
    onClickCustom: () => {
    },
    onClickEdit: () => {
    },
    onClickDelete: () => {
    },
    onClickItem: () => {
    },
    addHierarchy: () => {
    },
    // TableRowColumn: () => null,
};

HierarchyTableBody.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickDelete: PropTypes.func,
    onClickItem: PropTypes.func,
    addHierarchy: PropTypes.func,
    service: PropTypes.shape({
        tableButtonsPermission: PropTypes.string,
    }).isRequired,
    // TableRowColumn: PropTypes.element,
};
const mapStateToProps = (state, {service: {getStoreItem, modifierHierarchyValues}}) => {
    return {
        items: modifierHierarchyValues(getStoreItem(state, "items")),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(HierarchyTableBody);
