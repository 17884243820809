import ReactOnRails from "react-on-rails";
import React from "react";
import Root from "App/View/Root";
import Index from "./Index";
import * as MainService from "./Services";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);
    const groupId = MainService.getStoreItem(store.getState(), "id");

    const service = {
        ...MainService,
    };

    return (
        <Root store={store} service={service}>
            <Index />
        </Root>
    );
};

export default App;
