import { request, url } from "Services";

const getItems = listUrl => data => {
    return request.sendRequest({
        url: url.getUrl(listUrl, []),
        data,
        type: "GET",
    });
};

export const requestTable = listUrl => data => {
    return new Promise(resolve => {
        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination
            });
        })
    });
};
