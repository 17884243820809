import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.node,
};

const defaultProps = {
    disabled: false,
    children: null,
};

const Button = ({ children, disabled, onClick }) => {
    const [focus, setFocus] = useState(false)

    useEffect(() => {
        if (disabled) setFocus(false)
    }, [disabled])

    return (
        <button
            type="submit"
            className={`main-modal__form-btn main-btn main-btn_primary ${disabled ? 'disabled' : ''} ${focus ? 'focus' : ''}`}
            onClick={onClick}
            onFocus={() => {
                setFocus(true)
            }}
            onBlur={() => {
                setFocus(false)
            }}
        >
            {children}
        </button>
    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
