import _ from "lodash";
import { getNumber } from "Services";

export const getTotalForecasts = arr => arr.reduce((acc, elem) => Number(acc) + (Number(elem.unitsAmount) || 0), 0);
export const getTotalPercentProducts = arr => arr.reduce((acc, elem) => Number(acc) + Number(elem.percentOfProduct), 0);
export const unitsAmount = (a, b) => getNumber(Math.round((a * b) / 100));

export const getProductGrossMargin = product =>
    getNumber((1 - _.get(product, `goodsSold`, 0) / _.get(product, `actualSale`, 0)) * 100);

export const getFinalPrice = product =>
    getNumber(_.get(product, `actualSale`, 0) - _.get(product, `rebate`, 0));

export const getProductOperatingMargin = product =>
    getNumber(
        (1 -
            (Number(_.get(product, `goodsSold`, 0)) + Number(_.get(product, `sga`, 0))) /
                _.get(product, `actualSale`, 0)) *
            100,
    );
