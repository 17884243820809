import React, {Fragment, useEffect, useState} from "react";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {uniqueGenerator} from "Services";
import Img from "Templates/Img/Img";
import {Input, WrapInput} from "Templates/Form";
import {Preloader} from "Templates/Preloader";
import {materialProperties} from "../Services/RequestService";

const ContainerTable = (TableHeadColumn, TableRowColumn, {showButtons = true}) => ({
                                                                                       reset,
                                                                                       items,
                                                                                       activeTab,
                                                                                       activeCompareTab,
                                                                                       setCompareProductsSelect,
                                                                                       resetCompareProductsSelect,
                                                                                       setTableFilters,
                                                                                       setProductsControlsAction,
                                                                                       setPropertyNameValueAction,
                                                                                       setParameter1ValueAction,
                                                                                       setParameter2NameValueAction,
                                                                                       setParameter3ValueAction,
                                                                                       setManufacturingValueAction,
                                                                                       setParameterValueAction,
                                                                                       setDescriptionValueAction,
                                                                                       request,
                                                                                       pinned_columns,
                                                                                       columns,
                                                                                       fetchItems,
                                                                                       activeFilters
                                                                                   }) => {
    const showColumns = showButtons ? [...columns, "-1"] : [...columns];
    let timeOut;

    const [productsControls, setProductsControls] = useState([{
        id: uniqueGenerator(),
        value1Label: 'Value-1',
        value2Label: 'Value-2',
        value: '',
        value1_value_min: '',
        value1_value: '',
        value1_name: '',
        value2_value_min: '',
        value2_value: '',
        value2_name: ''
    }])

    const [productsControlsData, setProductsControlsData] = useState([]);
    const [loader, setLoader] = useState(true)
    const [disabled, setDisabled] = useState(true)

    const [propertyNameOptions, setPropertyNameOptions] = useState([])
    const [parameter1Options, setParameter1Options] = useState([])
    const [parameter2NameOptions, setParameter2NameOptions] = useState([])
    const [parameter3Options, setParameter3Options] = useState([])

    const [propertyNameValue, setPropertyNameValue] = useState([])
    const [parameter1Value, setParameter1Value] = useState([])
    const [parameter2NameValue, setParameter2NameValue] = useState([])
    const [parameter3Value, setParameter3Value] = useState([])

    const [manufacturingOptions, setManufacturingOptions] = useState([])
    const [parameterOptions, setParameterOptions] = useState([])
    const [descriptionOptions, setDescriptionOptions] = useState([])

    const [manufacturingValue, setManufacturingValue] = useState([])
    const [parameterValue, setParameterValue] = useState([])
    const [descriptionValue, setDescriptionValue] = useState([])

    const [tableItems, setTableItems] = useState([])

    const resetLoader = () => {
        timeOut = setTimeout(() => {
            setLoader(false)
        }, 1000)
    }

    const generatePinnedColumns = () => {
        if (typeof window !== 'undefined' && showColumns.length && pinned_columns.length) {
            const cols = showColumns.reduce((acc, curr) => {
                if (pinned_columns.includes(curr)) {
                    acc.push(curr)
                }
                return acc
            }, [])

            if (cols.length) {
                let totalWidth = 0;
                const tableBodyCols = document.querySelectorAll(`.td.pinned-column`)
                const tableBodyLastCols = document.querySelectorAll(`.td.${cols[cols.length - 1]}`)

                if (tableBodyCols.length) {
                    tableBodyCols.forEach((el) => {
                        const tooltip = el.querySelector(`.tooltip`)

                        if (tooltip) {
                            el.classList.add('with-tooltip')
                        }
                    })
                }

                if (tableBodyLastCols.length) {
                    tableBodyLastCols.forEach((el) => el.classList.add('last-pinned'))
                }

                cols.forEach((col, i) => {
                    const elem = document.querySelector(`.th.${col}`);
                    const width = document.querySelector(`.th.${col}`).clientWidth - 10;

                    if (i === (cols.length - 1)) {
                        elem.classList.add('last-pinned')
                    }

                    if (i !== cols.length) {
                        elem.style.left = `${totalWidth}px`;

                        if (tableBodyCols.length) {
                            tableBodyCols.forEach((bodyCol, index) => {
                                if ((index % cols.length) === i) {
                                    bodyCol.style.left = `${totalWidth}px`
                                }
                            })
                        }

                        totalWidth += width;
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (Object.keys(activeFilters).length) {
            setProductsControls(activeFilters.product)
            if (activeFilters.property_name.length) {
                const propertiesValues = activeFilters.property_name;
                setPropertyNameValue(propertiesValues.flat());
                setPropertyNameValueAction(propertiesValues.flat());
            }

            if (activeFilters.parameter1_name.length) {
                const propertiesValues = activeFilters.parameter1_name;
                setParameter1Value(propertiesValues.flat());
                setParameter1ValueAction(propertiesValues.flat());
            }

            if (activeFilters.parameter2_name.length) {
                const propertiesValues = activeFilters.parameter2_name;
                setParameter2NameValue(propertiesValues.flat());
                setParameter2NameValueAction(propertiesValues.flat());
            }

            if (activeFilters.parameter3_name.length) {
                const propertiesValues = activeFilters.parameter3_name;
                setParameter3Value(propertiesValues.flat());
                setParameter3ValueAction(propertiesValues.flat());
            }

            if (activeFilters.manufacturing_name.length) {
                const propertiesValues = activeFilters.manufacturing_name;
                setManufacturingValue(propertiesValues.flat());
                setManufacturingValueAction(propertiesValues.flat());
            }

            if (activeFilters.parameter_name.length) {
                const propertiesValues = activeFilters.parameter_name;
                setParameterValue(propertiesValues.flat());
                setParameterValueAction(propertiesValues.flat());
            }

            if (activeFilters.description_name.length) {
                const propertiesValues = activeFilters.description_name;
                setDescriptionValue(propertiesValues.flat());
                setDescriptionValueAction(propertiesValues.flat());
            }
        }
    }, [activeFilters])

    useEffect(() => {
        let timer3;

        timer3 = setTimeout(() => {
            generatePinnedColumns()
        }, 1000);

        if (typeof window !== "undefined") {
            window.addEventListener('resize', generatePinnedColumns);
        }

        return () => {
            clearTimeout(timer3);
            window.removeEventListener('resize', generatePinnedColumns);
        }
    }, [pinned_columns, request, productsControls])

    useEffect(() => {
        if (typeof window !== "undefined") {
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.has('product')) {
                const productValues = queryParams.getAll('product');
                const productsArray = productValues.map(value => {
                    return JSON.parse(value);
                });

                if (productsArray && productsArray.length) {
                    setProductsControls(productsArray);
                }
            }

            if (queryParams.has('property_name')) {
                const propertiesValues = queryParams.getAll('property_name');
                const propertiesArray = propertiesValues.map(value => {
                    return JSON.parse(value);
                });

                if (propertiesArray && propertiesArray.length) {
                    setPropertyNameValue(propertiesArray.flat());
                    setPropertyNameValueAction(propertiesArray.flat());
                }
            }

            if (queryParams.has('parameter1_name')) {
                const propertiesValues = queryParams.getAll('parameter1_name');
                const propertiesArray = propertiesValues.map(value => {
                    return JSON.parse(value);
                });

                if (propertiesArray && propertiesArray.length) {
                    setParameter1Value(propertiesArray.flat());
                    setParameter1ValueAction(propertiesArray.flat());
                }
            }

            if (queryParams.has('parameter2_name')) {
                const propertiesValues = queryParams.getAll('parameter2_name');
                const propertiesArray = propertiesValues.map(value => {
                    return JSON.parse(value);
                });

                if (propertiesArray && propertiesArray.length) {
                    setParameter2NameValue(propertiesArray.flat());
                    setParameter2NameValueAction(propertiesArray.flat());
                }
            }

            if (queryParams.has('parameter3_name')) {
                const propertiesValues = queryParams.getAll('parameter3_name');
                const propertiesArray = propertiesValues.map(value => {
                    return JSON.parse(value);
                });

                if (propertiesArray && propertiesArray.length) {
                    setParameter3Value(propertiesArray.flat());
                    setParameter3ValueAction(propertiesArray.flat());
                }
            }

            if (queryParams.has('manufacturing_name')) {
                const propertiesValues = queryParams.getAll('manufacturing_name');
                const propertiesArray = propertiesValues.map(value => {
                    return JSON.parse(value);
                });

                if (propertiesArray && propertiesArray.length) {
                    setManufacturingValue(propertiesArray.flat());
                    setManufacturingValueAction(propertiesArray.flat());
                }
            }

            if (queryParams.has('parameter_name')) {
                const propertiesValues = queryParams.getAll('parameter_name');
                const propertiesArray = propertiesValues.map(value => {
                    return JSON.parse(value);
                });

                if (propertiesArray && propertiesArray.length) {
                    setParameterValue(propertiesArray.flat());
                    setParameterValueAction(propertiesArray.flat());
                }
            }

            if (queryParams.has('description_name')) {
                const propertiesValues = queryParams.getAll('description_name');
                const propertiesArray = propertiesValues.map(value => {
                    return JSON.parse(value);
                });

                if (propertiesArray && propertiesArray.length) {
                    setDescriptionValue(propertiesArray.flat());
                    setDescriptionValueAction(propertiesArray.flat());
                }
            }
        }
    }, []);

    const generateOptions = (nameKey, valueKey, data) => {
        const uniqueProperties = new Set();

        data.forEach(item => {
            if (item[valueKey]) {
                uniqueProperties.add(`${item[nameKey]}+${item[valueKey]}`);
            } else {
                uniqueProperties.add('-');
            }
        });

        return Array.from(uniqueProperties).map(item => {
            const [label, value] = item.split('+');
            return {
                label: label !== '-' ? label : '-',
                value: value || ''
            };
        });
    }

    useEffect(() => {
        let timer;
        if (typeof window !== "undefined") {
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.delete('product');

            productsControls.forEach((item) => {
                if (item.value) {
                    queryParams.append('product', JSON.stringify(item));
                }
            });

            timer = setTimeout(() => {
                queryParams.delete('activeCompareTab');
                if (activeTab === 1) {
                    queryParams.delete('activeTab');
                    window.history.replaceState({}, '', `${window.location.pathname}?activeTab=${1}${queryParams.has('product') ? `&${queryParams.toString()}&activeCompareTab=${activeCompareTab}` : `&activeCompareTab=${activeCompareTab}`}`);
                } else {
                    window.history.replaceState({}, '', `${window.location.pathname}${queryParams.has('product') ? `?${queryParams.toString()}&activeCompareTab=${activeCompareTab}` : `?activeCompareTab=${activeCompareTab}`}`);
                }
            }, 500)
        }

        setCompareProductsSelect(productsControls);
        setProductsControlsAction(productsControls);

        if (productsControls.every((item) => item.value)) {
            setLoader(true)
            if (activeTab === 1) {
                materialProperties(activeCompareTab === 1 ? '/solution/publish-material-property-list' : '/solution/publish-material-property-list', {
                    publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                    datasheet_ids: productsControls.map(item => JSON.parse(item.value).datasheet_id),
                    manufacturing_ids: manufacturingValue && manufacturingValue.every((prop) => prop) ? manufacturingValue.map((item) => item) : null,
                    parameter_ids: parameterValue && parameterValue.every((prop) => prop) ? parameterValue.map((item) => item) : null,
                    description_ids: descriptionValue && descriptionValue.every((prop) => prop) ? descriptionValue.map((item) => item) : null,
                }).then((res) => {
                    if (res && res.items && res.items.length) {
                        setTableItems(res.items);
                    } else {
                        setTableItems([])
                        setProductsControlsData([])
                    }
                    resetLoader()
                })

                return
            }

            materialProperties(activeCompareTab === 1 ? '/solution/publish-material-property-list' : '/solution/publish-material-property-list', {
                publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                datasheet_ids: productsControls.map(item => JSON.parse(item.value).datasheet_id),
                property_ids: propertyNameValue && propertyNameValue.every((prop) => prop) ? propertyNameValue.map((item) => item) : null,
                parameter1_ids: parameter1Value && parameter1Value.every((prop) => prop) ? parameter1Value.map((item) => item) : null,
                parameter2_ids: parameter2NameValue && parameter2NameValue.every((prop) => prop) ? parameter2NameValue.map((item) => item) : null,
                parameter3_ids: parameter3Value && parameter3Value.every((prop) => prop) ? parameter3Value.map((item) => item) : null
            }).then((res) => {
                if (res && res.items && res.items.length) {
                    setTableItems(res.items);
                } else {
                    setTableItems([])
                    setProductsControlsData([])
                }
                resetLoader()
            })
        } else {
            resetLoader()
        }

        return () => {
            clearTimeout(timer)
        }
    }, [productsControls]);

    useEffect(() => {
        let timer2;

        setDisabled(productsControls.some((productItem) => !productItem.value));

        if (productsControls.every((item) => item.value)) {
            timer2 = setTimeout(() => {
                if (activeTab === 1) {
                    materialProperties(activeCompareTab === 1 ? '/solution/publish-material-property-list' : '/solution/publish-material-property-list', {
                        publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                        datasheet_ids: productsControls.map(item => JSON.parse(item.value).datasheet_id),
                    }).then((res) => {
                        if (res && res.items && res.items.length) {
                            setManufacturingOptions(generateOptions('manufacturing_name', 'manufacturing_id', res.items))
                            setParameterOptions(generateOptions('parameter_name', 'parameter_id', res.items))
                            setDescriptionOptions(generateOptions('description_name', 'description_id', res.items))
                        }
                        resetLoader()
                    })

                    return
                }

                materialProperties(activeCompareTab === 1 ? '/solution/publish-material-property-list' : '/solution/publish-material-property-list', {
                    publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                    datasheet_ids: productsControls.map(item => JSON.parse(item.value).datasheet_id),
                }).then((res) => {
                    if (res && res.items && res.items.length) {
                        setPropertyNameOptions(generateOptions('property_name', 'property_id', res.items))
                        setParameter1Options(generateOptions('parameter1_name', 'parameter1_id', res.items))
                        setParameter2NameOptions(generateOptions('parameter2_name', 'parameter2_id', res.items))
                        setParameter3Options(generateOptions('parameter3_name', 'parameter3_id', res.items))
                    }
                    resetLoader()
                })
            }, 1000)
        } else {
            resetLoader()
        }

        return () => {
            clearTimeout(timer2)
        }
    }, [productsControls])

    useEffect(() => {
        if (productsControls && productsControls.every((item) => item.value)) {
            setLoader(true)
            materialProperties(activeCompareTab === 1 ? '/solution/publish-material-property-list' : '/solution/publish-material-property-list', {
                publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                datasheet_ids: productsControls.map(item => JSON.parse(item.value).datasheet_id),
                property_ids: propertyNameValue && propertyNameValue.every((prop) => prop) ? propertyNameValue.map((item) => item) : null,
                parameter1_ids: parameter1Value && parameter1Value.every((prop) => prop) ? parameter1Value.map((item) => item) : null,
                parameter2_ids: parameter2NameValue && parameter2NameValue.every((prop) => prop) ? parameter2NameValue.map((item) => item) : null,
                parameter3_ids: parameter3Value && parameter3Value.every((prop) => prop) ? parameter3Value.map((item) => item) : null
            }).then((res) => {
                if (res && res.items && res.items.length) {
                    setTableItems(res.items)
                } else {
                    setTableItems([])
                    setProductsControlsData([])
                }
                resetLoader()
            })
        } else {
            resetLoader()
        }
    }, [propertyNameValue, parameter1Value, parameter2NameValue, parameter3Value])

    useEffect(() => {
        if (productsControls && productsControls.every((item) => item.value)) {
            setLoader(true)
            materialProperties(activeCompareTab === 1 ? '/solution/publish-material-property-list' : '/solution/publish-material-property-list', {
                publication_ids: productsControls.map(item => JSON.parse(item.value).publication_id),
                datasheet_ids: productsControls.map(item => JSON.parse(item.value).datasheet_id),
                manufacturing_ids: manufacturingValue && manufacturingValue.every((prop) => prop) ? manufacturingValue.map((item) => item) : null,
                parameter_ids: parameterValue && parameterValue.every((prop) => prop) ? parameterValue.map((item) => item) : null,
                description_ids: descriptionValue && descriptionValue.every((prop) => prop) ? descriptionValue.map((item) => item) : null,
            }).then((res) => {
                if (res && res.items && res.items.length) {
                    setTableItems(res.items)
                } else {
                    setTableItems([])
                    setProductsControlsData([])
                }
                resetLoader()
            })
        } else {
            resetLoader()
        }
    }, [manufacturingValue, parameterValue, descriptionValue])

    useEffect(() => {
        if (typeof window !== "undefined") {
            const queryParams = new URLSearchParams(window.location.search);
            const product = queryParams.getAll('product').map(value => {
                    return JSON.parse(value);
                });
            const property_name = queryParams.getAll('property_name').map(value => {
                    return JSON.parse(value);
                });
            const parameter1_name = queryParams.getAll('parameter1_name').map(value => {
                    return JSON.parse(value);
                });
            const parameter2_name = queryParams.getAll('parameter2_name').map(value => {
                    return JSON.parse(value);
                });
            const parameter3_name = queryParams.getAll('parameter3_name').map(value => {
                    return JSON.parse(value);
                });
            const manufacturing_name = queryParams.getAll('manufacturing_name').map(value => {
                    return JSON.parse(value);
                });
            const parameter_name = queryParams.getAll('parameter_name').map(value => {
                    return JSON.parse(value);
                });
            const description_name = queryParams.getAll('description_name').map(value => {
                    return JSON.parse(value);
                });

            let currentCompareProduct = null;

            if (!activeTab && !activeCompareTab) {
                currentCompareProduct = 4
            }

            if (!activeTab && activeCompareTab) {
                currentCompareProduct = 8
            }

            if (activeTab && !activeCompareTab) {
                currentCompareProduct = 7
            }

            if (activeTab && activeCompareTab) {
                currentCompareProduct = 9
            }

            const selectedFilters = {
                product,
                property_name,
                parameter1_name,
                parameter2_name,
                parameter3_name,
                manufacturing_name,
                parameter_name,
                description_name,
            }

            if (currentCompareProduct && Object.values(selectedFilters).some((val) => val.length)) {
                setTableFilters({...selectedFilters, type: currentCompareProduct})
            } else {
                setTableFilters(null)
            }
        }
    }, [loader])

    function groupAndFillMaterialProperties() {
        const grouped = {};

        tableItems.forEach(item => {
            const key = `${item.property_id}-${item.parameter1_id}-${item.parameter2_id}-${item.parameter3_id}`;

            if (!grouped[key]) {
                grouped[key] = {
                    ...item,
                    items: new Array(productsControls.length).fill({})
                };
            }

            const controlIndex = productsControls.findIndex(control => {
                const controlValue = JSON.parse(control.value);
                return controlValue.datasheet_id === item.datasheet_id;
            });

            if (controlIndex !== -1) {
                const itemData = {
                    value1_name: item.value1_name,
                    value1_value_min: item.value1_value_min,
                    value1_value: item.value1_value,
                    value2_name: item.value2_name,
                    value2_value_min: item.value2_value_min,
                    value2_value: item.value2_value
                };

                grouped[key].items[controlIndex] = itemData;
            }
        });

        return Object.values(grouped);
    }

    function groupAndFillProcessProperties() {
        const grouped = {};

        tableItems.forEach(item => {
            const key = `${item.manufacturing_id}-${item.parameter_id}-${item.description_id}`;

            if (!grouped[key]) {
                grouped[key] = {
                    ...item,
                    items: new Array(productsControls.length).fill({})
                };
            }

            const controlIndex = productsControls.findIndex(control => {
                const controlValue = JSON.parse(control.value);
                return controlValue.process_id === item.process_id;
            });

            if (controlIndex !== -1) {
                const itemData = {
                    value1_name: item.value1_name,
                    value1_value_min: item.value1_value_min,
                    value1_value: item.value1_value,
                    value2_name: item.value2_name,
                    value2_value_min: item.value2_value_min,
                    value2_value: item.value2_value
                };

                grouped[key].items[controlIndex] = itemData;
            }
        });

        return Object.values(grouped);
    }

    useEffect(() => {
        if (tableItems.length) {
            const result = activeTab === 1 ? groupAndFillProcessProperties() : groupAndFillMaterialProperties();
            if (result && result.length) {
                setProductsControlsData(result)
            }
        }
    }, [tableItems])

    useEffect(() => {
        return () => {
            clearTimeout(timeOut)
        }
    }, [loader])

    const onSubmit = e => {
        e.preventDefault();

        fetchItems({...request, activeTab, activeCompareTab});
    };

    const addProductControl = () => {
        setProductsControls((prev) => {
            return [...prev, {
                id: uniqueGenerator(),
                value1Label: 'Value-1',
                value2Label: 'Value-2',
                value: '',
                value1_value_min: '',
                value1_value: '',
                value1_name: '',
                value2_value_min: '',
                value2_value: '',
                value2_name: ''
            }]
        })
    }

    const removeProductControl = (productId) => {
        setTableItems([])
        setProductsControlsData([])

        setPropertyNameOptions([])
        setParameter1Options([])
        setParameter2NameOptions([])
        setParameter3Options([])
        setManufacturingOptions([])
        setParameterOptions([])
        setDescriptionOptions([])

        setTableItems([])
        setPropertyNameValue([])
        setPropertyNameValueAction([])
        setParameter1Value([])
        setParameter1ValueAction([])
        setParameter2NameValue([])
        setParameter2NameValueAction([])
        setParameter3Value([])
        setParameter3ValueAction([])
        setManufacturingValue([])
        setManufacturingValueAction([])
        setParameterValue([])
        setParameterValueAction([])
        setDescriptionValue([])
        setDescriptionValueAction([])
        setProductsControls((prev) => {
            return prev.filter((item) => item.id !== productId)
        })
    }

    const openResetProducts = () => {
        if (typeof window !== "undefined") {
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.delete('product');

            if (activeTab === 1) {
                window.history.replaceState({}, '', `${window.location.pathname}?activeTab=${1}`);
            } else {
                window.history.replaceState({}, '', `${window.location.pathname}`);
            }
        }

        setPropertyNameOptions([])
        setParameter1Options([])
        setParameter2NameOptions([])
        setParameter3Options([])
        setManufacturingOptions([])
        setParameterOptions([])
        setDescriptionOptions([])

        setTableItems([])
        setPropertyNameValue([])
        setPropertyNameValueAction([])
        setParameter1Value([])
        setParameter1ValueAction([])
        setParameter2NameValue([])
        setParameter2NameValueAction([])
        setParameter3Value([])
        setParameter3ValueAction([])
        setManufacturingValue([])
        setManufacturingValueAction([])
        setParameterValue([])
        setParameterValueAction([])
        setDescriptionValue([])
        setDescriptionValueAction([])
        setProductsControlsData([])
        setProductsControls([{
            id: uniqueGenerator(),
            value1Label: 'Value-1',
            value2Label: 'Value-2',
            value: '',
            value1_value_min: '',
            value1_value: '',
            value1_name: '',
            value2_value_min: '',
            value2_value: '',
            value2_name: ''
        }])
    }

    useEffect(() => {
        if (reset) {
            openResetProducts()
            resetCompareProductsSelect(false)
        }
    }, [reset])

    const setQueryParams = (paramName, values) => {
        if (typeof window !== "undefined") {
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.delete(paramName);
            if (values) {
                queryParams.append(paramName, JSON.stringify(values));
            }

            queryParams.delete('activeCompareTab');

            if (activeTab === 1) {
                queryParams.delete('activeTab');
                window.history.replaceState({}, '', `${window.location.pathname}?activeTab=${1}${queryParams.has('product') ? `&${queryParams.toString()}&activeCompareTab=${activeCompareTab}` : `&activeCompareTab=${activeCompareTab}`}`);
            } else {
                window.history.replaceState({}, '', `${window.location.pathname}${queryParams.has('product') ? `?${queryParams.toString()}&activeCompareTab=${activeCompareTab}` : `?activeCompareTab=${activeCompareTab}`}`);
            }
        }
    }

    const resetFilters = () => {
        if (typeof window !== "undefined") {
            const queryParams = new URLSearchParams(window.location.search);
            queryParams.delete('property_name');
            queryParams.delete('parameter1_name');
            queryParams.delete('parameter2_name');
            queryParams.delete('parameter3_name');
            queryParams.delete('manufacturing_name');
            queryParams.delete('parameter_name');
            queryParams.delete('description_name');
            queryParams.delete('activeCompareTab');

            if (activeTab === 1) {
                queryParams.delete('activeTab');
                window.history.replaceState({}, '', `${window.location.pathname}?activeTab=${1}${queryParams.has('product') ? `&${queryParams.toString()}&activeCompareTab=${activeCompareTab}` : `&activeCompareTab=${activeCompareTab}`}`);
            } else {
                window.history.replaceState({}, '', `${window.location.pathname}${queryParams.has('product') ? `?${queryParams.toString()}&activeCompareTab=${activeCompareTab}` : `?activeCompareTab=${activeCompareTab}`}`);
            }
        }

        setPropertyNameOptions([])
        setParameter1Options([])
        setParameter2NameOptions([])
        setParameter3Options([])
        setManufacturingOptions([])
        setParameterOptions([])
        setDescriptionOptions([])

        setTableItems([])
        setPropertyNameValue([])
        setPropertyNameValueAction([])
        setParameter1Value([])
        setParameter1ValueAction([])
        setParameter2NameValue([])
        setParameter2NameValueAction([])
        setParameter3Value([])
        setParameter3ValueAction([])
        setManufacturingValue([])
        setManufacturingValueAction([])
        setParameterValue([])
        setParameterValueAction([])
        setDescriptionValue([])
        setDescriptionValueAction([])
    }

    const getDynamicWidth = (i) => {
        if (typeof window !== 'undefined') {
            const valuesList = document.querySelectorAll(`.row-${i}`);

            if (valuesList && valuesList.length) {
                valuesList.forEach((el) => {
                    const headElemSi = el.querySelector('.head-si');
                    const headElemEnglish = el.querySelector('.head-english');

                    const bodySiList = el.querySelectorAll('.body-si');
                    const bodyEnglishList = el.querySelectorAll('.body-english');

                    if (headElemSi && headElemEnglish && bodySiList && bodySiList.length && bodyEnglishList && bodyEnglishList.length) {
                        let maxWidthSi = 0;
                        let maxWidthEnglish = 0;

                        bodySiList.forEach((bodySi) => {
                            const width = bodySi.offsetWidth;
                            if (width > maxWidthSi) {
                                maxWidthSi = width;
                            }
                        });

                        bodyEnglishList.forEach((bodyEnglish) => {
                            const width = bodyEnglish.offsetWidth;
                            if (width > maxWidthEnglish) {
                                maxWidthEnglish = width;
                            }
                        });

                        if (maxWidthSi) {
                            headElemSi.style.width = `${maxWidthSi}px`
                            bodySiList.forEach((bodySi) => {
                                bodySi.style.width = `${maxWidthSi}px`;
                            });
                        }

                        if (maxWidthEnglish) {
                            headElemEnglish.style.width = `${maxWidthEnglish}px`;
                            bodyEnglishList.forEach((bodyEnglish) => {
                                bodyEnglish.style.width = `${maxWidthEnglish}px`;
                            });
                        }
                    }
                })
            }
        }
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <table className="main-table">
                    <tbody>
                    <tr className="table-wrap">
                        {showColumns.map((column, index) => {
                            return (
                                <Fragment key={`TableRowColumn-${index}`}>
                                    {column === "-1" ? (
                                        <tr className="compare-product-table-row products-row ">
                                            {productsControls.map((productControl, i) => {
                                                getDynamicWidth(i)

                                                return (
                                                    <tr key={productControl.id} className={`products-row__item row-${i}`}>
                                                        <th className={`main-table__head ${i !== productsControls.length - 1 ? 'compare-values-separator' : ''}`}>
                                                            <div className="compare-products-rows">
                                                                <div
                                                                    className="compare-products__table-head-row main-table__head-row">
                                                                    <a
                                                                        href="#"
                                                                        className="compare-products__table-head-link main-table__head-link main-is-disabled"
                                                                    >
                                                                        Select Technology
                                                                        {productsControls.length > 1 ?
                                                                            <span className="remove-product-control"
                                                                                  onClick={() => removeProductControl(productControl.id)}><i
                                                                                className="la la-icon-close"/></span> : null}
                                                                    </a>
                                                                    <div
                                                                        className="compare-products__table-input-wrap main-table__input-wrap product-select">
                                                                        {activeTab === 1
                                                                            ? (
                                                                                <WrapInput
                                                                                    name={`${productControl.id}.compare-product`}
                                                                                    label=""
                                                                                >
                                                                                    <Input
                                                                                        name={`color-${index}`}
                                                                                        type="asyncCompareProductsSelect"
                                                                                        disabled={disabled && !!productControl.value}
                                                                                        placeholder="Select"
                                                                                        url={activeCompareTab === 1 ? '/product/product-finder/publication-process' : '/product/product-finder/shared-publication-process'}
                                                                                        value={productControl.value ? productControl.value : ''}
                                                                                        onChange={value => {
                                                                                            resetFilters();
                                                                                            setProductsControls((prev) => {
                                                                                                const updatedProductIndex = prev.findIndex((item) => item.id === productControl.id);
                                                                                                if (updatedProductIndex > -1) {
                                                                                                    prev[updatedProductIndex].value = value;

                                                                                                    return [...prev]
                                                                                                }
                                                                                                return [...prev]
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </WrapInput>
                                                                            )
                                                                            : (
                                                                                <WrapInput
                                                                                    name={`${productControl.id}.compare-product`}
                                                                                    label=""
                                                                                >
                                                                                    <Input
                                                                                        name={`color-${index}`}
                                                                                        type="asyncCompareProductsSelect"
                                                                                        disabled={disabled && !!productControl.value}
                                                                                        placeholder="Select"
                                                                                        url={activeCompareTab === 1 ? '/product/product-finder/publication-technical' : '/solution/publish-solution-technical'}
                                                                                        value={productControl.value ? productControl.value : ''}
                                                                                        onChange={value => {
                                                                                            resetFilters();
                                                                                            setProductsControls((prev) => {
                                                                                                const updatedProductIndex = prev.findIndex((item) => item.id === productControl.id);
                                                                                                if (updatedProductIndex > -1) {
                                                                                                    prev[updatedProductIndex].value = value;

                                                                                                    return [...prev]
                                                                                                }
                                                                                                return [...prev]
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </WrapInput>
                                                                            )}
                                                                    </div>
                                                                    <div className="product-controls-labels">
                                                                        <p className="head-si">{productControl.value1Label || 'Value-1'}</p>
                                                                        <p className="head-english">{productControl.value2Label || 'Value-2'}</p>
                                                                    </div>
                                                                </div>
                                                                {i === productsControls.length - 1 ? (
                                                                    <button type="button" onClick={addProductControl}>
                                                                        <Img img="icon_plus"/>
                                                                        Add Technology
                                                                    </button>
                                                                ) : null}
                                                            </div>
                                                        </th>
                                                        {
                                                            productsControlsData.map((productControlBody, productControlIndex) => {
                                                                return <td key={`prop-item-${productControlIndex}`} className={`main-table__body ${i !== productsControls.length - 1 ? 'compare-values-separator' : ''}`}>
                                                                    <div className="main-table__body-row values">
                                                                    <span
                                                                        className="main-table__body-text product-controls-labels">
                                                                        {productControlBody.items[i] ? (
                                                                            productControlBody.items[i].value1_value_min === productControlBody.items[i].value1_value
                                                                                ? <p className="body-si">
                                                                                {productControlBody.items[i].value1_value}
                                                                                {' '}
                                                                                {productControlBody.items[i].value1_value ? productControlBody.items[i].value1_name : '-'}
                                                                            </p> : <p className="body-si">
                                                                                    {productControlBody.items[i].value1_value_min}
                                                                                    {' '}
                                                                                    {productControlBody.items[i].value1_value_min && productControlBody.items[i].value1_value ? '-' : ''}
                                                                                    {' '}
                                                                                    {productControlBody.items[i].value1_value}
                                                                                    {' '}
                                                                                    {productControlBody.items[i].value1_value_min || productControlBody.items[i].value1_value ? productControlBody.items[i].value1_name : '-'}
                                                                                </p>
                                                                        ) : <p>-</p>}
                                                                        {productControlBody.items[i] ? (
                                                                            productControlBody.items[i].value2_value_min === productControlBody.items[i].value2_value
                                                                            ? <p className="body-english">
                                                                                {productControlBody.items[i].value2_value}
                                                                                {' '}
                                                                                {productControlBody.items[i].value2_value ? productControlBody.items[i].value2_name : '-'}
                                                                            </p> : <p className="body-english">
                                                                                    {productControlBody.items[i].value2_value_min}
                                                                                    {' '}
                                                                                    {productControlBody.items[i].value2_value_min && productControlBody.items[i].value2_value ? '-' : ''}
                                                                                    {' '}
                                                                                    {productControlBody.items[i].value2_value}
                                                                                    {' '}
                                                                                    {productControlBody.items[i].value2_value_min || productControlBody.items[i].value2_value ? productControlBody.items[i].value2_name : '-'}
                                                                                </p>
                                                                        ) : <p>-</p>}
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tr>
                                    ) : column === 'property_name' ? (
                                        <tr className={`compare-product-table-row ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}`}>
                                            <th className="main-table__head">
                                                <div key={uniqueGenerator()}
                                                     className="compare-products__table-head-row main-table__head-row">
                                                    <a
                                                        href="#"
                                                        className="compare-products__table-head-link main-table__head-link main-is-disabled"
                                                    >
                                                        Property
                                                    </a>
                                                    <div
                                                        className="compare-products__table-input-wrap main-table__input-wrap">
                                                        <WrapInput
                                                            name="property_name"
                                                            label=""
                                                        >
                                                            <Input
                                                                name={`color-${index}`}
                                                                disabled={disabled}
                                                                type="compareProductsMultiSelect"
                                                                value={propertyNameValue || ''}
                                                                options={[...propertyNameOptions]}
                                                                placeholder="All"
                                                                onChange={e => {
                                                                    setPropertyNameValue(e);
                                                                    setPropertyNameValueAction(e);
                                                                    setQueryParams('property_name', e)
                                                                }}
                                                            />
                                                        </WrapInput>
                                                    </div>
                                                    <div className="product-controls-labels-plug"/>
                                                </div>
                                            </th>
                                            {productsControlsData.map((tableItem, i) => {
                                                return column === 'property_name' ? (
                                                    <td key={`${tableItem.publication_id}-${tableItem.datasheet_id}-${column}-${i}`}
                                                        className="main-table__body">
                                                        <div className="main-table__body-row">
                                        <span className="main-table__body-text">
                                            {tableItem.property_name || '-'}
                                        </span>
                                                        </div>
                                                    </td>
                                                ) : null
                                            })}
                                        </tr>
                                    ) : column === 'parameter1_name' ? (
                                        <tr className={`compare-product-table-row ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}`}>
                                            <th className="main-table__head">
                                                <div key={uniqueGenerator()}
                                                     className="compare-products__table-head-row main-table__head-row">
                                                    <a
                                                        href="#"
                                                        className="compare-products__table-head-link main-table__head-link main-is-disabled"
                                                    >
                                                        Parameter 1
                                                    </a>
                                                    <div
                                                        className="compare-products__table-input-wrap main-table__input-wrap">
                                                        <WrapInput
                                                            name="parameter1"
                                                            label=""
                                                        >
                                                            <Input
                                                                name={`color-${index}`}
                                                                type="compareProductsMultiSelect"
                                                                disabled={disabled}
                                                                value={parameter1Value || ''}
                                                                options={[...parameter1Options]}
                                                                placeholder="Select"
                                                                onChange={e => {
                                                                    setParameter1Value(e);
                                                                    setParameter1ValueAction(e);
                                                                    setQueryParams('parameter1_name', e)
                                                                }}
                                                            />
                                                        </WrapInput>
                                                    </div>
                                                    <div className="product-controls-labels-plug"/>
                                                </div>
                                            </th>
                                            {productsControlsData.map((tableItem, i) => {
                                                return column === 'parameter1_name' ? (
                                                    <td key={`${tableItem.publication_id}-${tableItem.datasheet_id}-${column}-${i}`}
                                                        className="main-table__body">
                                                        <div className="main-table__body-row">
                                        <span className="main-table__body-text">
                                            {tableItem.parameter1_name || '-'}
                                        </span>
                                                        </div>
                                                    </td>
                                                ) : null
                                            })}
                                        </tr>
                                    ) : column === 'parameter2_name' ? (
                                        <tr className={`compare-product-table-row ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}`}>
                                            <th className="main-table__head">
                                                <div key={uniqueGenerator()}
                                                     className="compare-products__table-head-row main-table__head-row">
                                                    <a
                                                        href="#"
                                                        className="compare-products__table-head-link main-table__head-link main-is-disabled"
                                                    >
                                                        Parameter2
                                                    </a>
                                                    <div
                                                        className="compare-products__table-input-wrap main-table__input-wrap">
                                                        <WrapInput
                                                            name="parameter2"
                                                            label=""
                                                        >
                                                            <Input
                                                                name={`color-${index}`}
                                                                type="compareProductsMultiSelect"
                                                                disabled={disabled}
                                                                value={parameter2NameValue || ''}
                                                                options={[...parameter2NameOptions]}
                                                                placeholder="Select"
                                                                onChange={e => {
                                                                    setParameter2NameValue(e);
                                                                    setParameter2NameValueAction(e);
                                                                    setQueryParams('parameter2_name', e)
                                                                }}
                                                            />
                                                        </WrapInput>
                                                    </div>
                                                    <div className="product-controls-labels-plug"/>
                                                </div>
                                            </th>
                                            {productsControlsData.map((tableItem, i) => {
                                                return column === 'parameter2_name' ? (
                                                    <td key={`${tableItem.publication_id}-${tableItem.datasheet_id}-${column}-${i}`}
                                                        className="main-table__body">
                                                        <div className="main-table__body-row">
                                        <span className="main-table__body-text">
                                            {tableItem.parameter2_name || '-'}
                                        </span>
                                                        </div>
                                                    </td>
                                                ) : null
                                            })}
                                        </tr>
                                    ) : column === 'parameter3_name' ? (
                                        <tr className={`compare-product-table-row ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}`}>
                                            <th className="main-table__head">
                                                <div key={uniqueGenerator()}
                                                     className="compare-products__table-head-row main-table__head-row">
                                                    <a
                                                        href="#"
                                                        className="compare-products__table-head-link main-table__head-link main-is-disabled"
                                                    >
                                                        Parameter 3
                                                    </a>
                                                    <div
                                                        className="compare-products__table-input-wrap main-table__input-wrap">
                                                        <WrapInput
                                                            name="parameter3"
                                                            label=""
                                                        >
                                                            <Input
                                                                name={`color-${index}`}
                                                                type="compareProductsMultiSelect"
                                                                disabled={disabled}
                                                                value={parameter3Value || ''}
                                                                options={[...parameter3Options]}
                                                                placeholder="Select"
                                                                onChange={e => {
                                                                    setParameter3Value(e);
                                                                    setParameter3ValueAction(e);
                                                                    setQueryParams('parameter3_name', e)
                                                                }}
                                                            />
                                                        </WrapInput>
                                                    </div>
                                                    <div className="product-controls-labels-plug"/>
                                                </div>
                                            </th>
                                            {productsControlsData.map((tableItem, i) => {
                                                return column === 'parameter3_name' ? (
                                                    <td key={`${tableItem.publication_id}-${tableItem.datasheet_id}-${column}-${i}`}
                                                        className="main-table__body">
                                                        <div className="main-table__body-row">
                                        <span className="main-table__body-text">
                                            {tableItem.parameter3_name || '-'}
                                        </span>
                                                        </div>
                                                    </td>
                                                ) : null
                                            })}
                                        </tr>
                                    ) : column === 'manufacturing_name' ? (
                                        <tr className={`compare-product-table-row ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}`}>
                                            <th className="main-table__head">
                                                <div key={uniqueGenerator()}
                                                     className="compare-products__table-head-row main-table__head-row">
                                                    <a
                                                        href="#"
                                                        className="compare-products__table-head-link main-table__head-link main-is-disabled"
                                                    >
                                                        Manufacturing Process
                                                    </a>
                                                    <div
                                                        className="compare-products__table-input-wrap main-table__input-wrap">
                                                        <WrapInput
                                                            name="manufacturing"
                                                            label=""
                                                        >
                                                            <Input
                                                                name={`color-${index}`}
                                                                type="compareProductsMultiSelect"
                                                                disabled={disabled}
                                                                value={manufacturingValue || ''}
                                                                options={[...manufacturingOptions]}
                                                                placeholder="All"
                                                                onChange={e => {
                                                                    setManufacturingValue(e);
                                                                    setManufacturingValueAction(e);
                                                                    setQueryParams('manufacturing_name', e)
                                                                }}
                                                            />
                                                        </WrapInput>
                                                    </div>
                                                    <div className="product-controls-labels-plug"/>
                                                </div>
                                            </th>
                                            {productsControlsData.map((tableItem, i) => {
                                                return column === 'manufacturing_name' ? (
                                                    <td key={`${tableItem.publication_id}-${tableItem.process_id}-${column}-${i}`}
                                                        className="main-table__body">
                                                        <div className="main-table__body-row">
                                        <span className="main-table__body-text">
                                            {tableItem.manufacturing_name || '-'}
                                        </span>
                                                        </div>
                                                    </td>
                                                ) : null
                                            })}
                                        </tr>
                                    ) : column === 'parameter_name' ? (
                                        <tr className={`compare-product-table-row ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}`}>
                                            <th className="main-table__head">
                                                <div key={uniqueGenerator()}
                                                     className="compare-products__table-head-row main-table__head-row">
                                                    <a
                                                        href="#"
                                                        className="compare-products__table-head-link main-table__head-link main-is-disabled"
                                                    >
                                                        Process Parameter
                                                    </a>
                                                    <div
                                                        className="compare-products__table-input-wrap main-table__input-wrap">
                                                        <WrapInput
                                                            name="parameter"
                                                            label=""
                                                        >
                                                            <Input
                                                                name={`color-${index}`}
                                                                type="compareProductsMultiSelect"
                                                                disabled={disabled}
                                                                value={parameterValue || ''}
                                                                options={[...parameterOptions]}
                                                                placeholder="Select"
                                                                onChange={e => {
                                                                    setParameterValue(e);
                                                                    setParameterValueAction(e);
                                                                    setQueryParams('parameter_name', e)
                                                                }}
                                                            />
                                                        </WrapInput>
                                                    </div>
                                                    <div className="product-controls-labels-plug"/>
                                                </div>
                                            </th>
                                            {productsControlsData.map((tableItem, i) => {
                                                return column === 'parameter_name' ? (
                                                    <td key={`${tableItem.publication_id}-${tableItem.process_id}-${column}-${i}`}
                                                        className="main-table__body">
                                                        <div className="main-table__body-row">
                                        <span className="main-table__body-text">
                                            {tableItem.parameter_name || '-'}
                                        </span>
                                                        </div>
                                                    </td>
                                                ) : null
                                            })}
                                        </tr>
                                    ) : column === 'description_name' ? (
                                        <tr className={`compare-product-table-row ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}`}>
                                            <th className="main-table__head">
                                                <div key={uniqueGenerator()}
                                                     className="compare-products__table-head-row main-table__head-row">
                                                    <a
                                                        href="#"
                                                        className="compare-products__table-head-link main-table__head-link main-is-disabled"
                                                    >
                                                        Description
                                                    </a>
                                                    <div
                                                        className="compare-products__table-input-wrap main-table__input-wrap">
                                                        <WrapInput
                                                            name="description"
                                                            label=""
                                                        >
                                                            <Input
                                                                name={`color-${index}`}
                                                                type="compareProductsMultiSelect"
                                                                disabled={disabled}
                                                                value={descriptionValue || ''}
                                                                options={[...descriptionOptions]}
                                                                placeholder="Select"
                                                                onChange={e => {
                                                                    setDescriptionValue(e);
                                                                    setDescriptionValueAction(e);
                                                                    setQueryParams('description_name', e)
                                                                }}
                                                            />
                                                        </WrapInput>
                                                    </div>
                                                    <div className="product-controls-labels-plug"/>
                                                </div>
                                            </th>
                                            {productsControlsData.map((tableItem, i) => {
                                                return column === 'description_name' ? (
                                                    <td key={`${tableItem.publication_id}-${tableItem.process_id}-${column}-${i}`}
                                                        className="main-table__body">
                                                        <div className="main-table__body-row">
                                        <span className="main-table__body-text">
                                            {tableItem.description_name || '-'}
                                        </span>
                                                        </div>
                                                    </td>
                                                ) : null
                                            })}
                                        </tr>
                                    ) : null}
                                </Fragment>
                            )
                        })}
                    </tr>
                    </tbody>
                </table>
                <button type="submit" className="visually-hidden"/>
            </form>
            {!productsControls.some((item) => item.value) ? <p className="empty-compare">Please select Product(s) to compare values</p> : null}
            {loader ? <Preloader/> : null}
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;
    return {
        reset: getStoreItem(state, "reset"),
        items: getStoreItem(state, "items"),
        columns: getStoreItem(state, "columns"),
        activeFilters: getStoreItem(state, "activeFilters"),
        pinned_columns: getStoreItem(state, "pinned_columns"),
        activeTab: getStoreItem(state, "activeTab"),
        activeCompareTab: getStoreItem(state, "activeCompareTab", 0),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        setCompareProductsSelect: getActionStore("setCompareProductsSelect", service, dispatch),
        resetCompareProductsSelect: getActionStore("resetCompareProductsSelect", service, dispatch),
        setTableFilters: getActionStore("setTableFilters", service, dispatch),
        setProductsControlsAction: getActionStore("setProductsControls", service, dispatch),
        setPropertyNameValueAction: getActionStore("setPropertyNameValue", service, dispatch),
        setParameter1ValueAction: getActionStore("setParameter1Value", service, dispatch),
        setParameter2NameValueAction: getActionStore("setParameter2NameValue", service, dispatch),
        setParameter3ValueAction: getActionStore("setParameter3Value", service, dispatch),
        setManufacturingValueAction: getActionStore("setManufacturingValue", service, dispatch),
        setParameterValueAction: getActionStore("setParameterValue", service, dispatch),
        setDescriptionValueAction: getActionStore("setDescriptionValue", service, dispatch),
    };
};

export default (TableHeadColumn, TableRowColumn, {showButtons = true} = {}) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(ContainerTable(TableHeadColumn, TableRowColumn, {showButtons}));
