import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import Img from "Templates/Img";
import {withTagDefaultProps} from "Hoc/Template";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    index: PropTypes.number.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const TableRowItem = ({ index, onClickEdit, onClickDelete }) => {
    const _onClickDelete = () => {
        onClickDelete(index);
    };

    const _onClickEdit = () => {
        onClickEdit(index);
    };

    return (
        <TableBodyButtonsBlock>
            <TableBodyButton onClick={_onClickDelete}>
                <Img img="icon_delete" />
            </TableBodyButton>
        </TableBodyButtonsBlock>
    );
};

TableRowItem.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowItem);
