import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "../../EventsService";
import {isSapImported} from "../../../../../../../Services/enum";

export const getTableLabel = (key) => {
    const tableLabel = {
        invoice_number: "Invoice Number",
        customer: "Customer",
        customer_full: "Customer Full Name",
        customer_code: "Customer Code",
        billing_date: "Billing Date",
        created_by: "Created By",
        created_on: "Created On",
        reversal_of: "Reversal Of",
        currency: "Currency",
        billing_type: "Billing Type",
        billing_category: "Billing Category",
        document_category: "Document Category",
        line_number: "Invoice Line",
        order_country: "Order Country",
        order_region: "Order Region",
        grade: "Product Name",
        material_number: "Material Number",
        product_code: "SAP Product Code",
        application_code: "SAP Application Code",
        plant: "Plant Name",
        quantity: "Billed Quantity",
        sales_unit: "Sales Unit",
        net_amount: "Net Amount",
        cost: "Cost",
        fixed_cost: "Fixed Cost",
        variable_cost: "Variable Cost",
        business_area: "Business Area",
        gross_weight: "Gross Weight",
        gross_weight_unit: "Gross Weight Unit",
        normalized_weight: "Normalized Weight",
        normalized_weight_unit: "Normalized Weight Unit",
        order_number: "Sales Order",
        order_line_number: "Sales Order Line",
        reference_invoice_number: "Reference Invoice",
        reference_invoice_line_number: "Reference Invoice Line",
        freight_amount: "Freight Amount",
        gross_margin: "Gross Margin",
        gross_margin_percent: "Gross Margin %",
        price_kg: "Price per KG",
        cost_kg: "Cost per KG",
        cancelled: "Cancelled",
        surcharge_zb00: "Freight",
        surcharge_zb04: "Price Adj",
        surcharge_zb05: "Freight Surcharge",
        surcharge_zd00: "Misc Charges",
        free_of_charge: "Free Of Charge",
    };

    switch (key) {
        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        invoice_number: "Search",
        customer: "Search",
        customer_full: "Search",
        customer_code: "Search",
        billing_date: "Billing Date",
        created_by: "Search",
        created_on: "Created On",
        reversal_of: "Search",
        currency: "Search",
        billing_type: "Search",
        billing_category: "Search",
        document_category: "Search",
        line_number: "Search",
        order_country: "Search",
        order_region: "Search",
        grade: "Search",
        material_number: "Search",
        product_code: "Search",
        application_code: "Search",
        plant: "Search",
        quantity: "Search",
        sales_unit: "Search",
        net_amount: "Search",
        cost: "Search",
        fixed_cost: "Search",
        variable_cost: "Search",
        business_area: "Search",
        gross_weight: "Search",
        gross_weight_unit: "Search",
        normalized_weight: "Search",
        normalized_weight_unit: "Search",
        order_number: "Search",
        order_line_number: "Search",
        reference_invoice_number: "Search",
        reference_invoice_line_number: "Search",
        freight_amount: "Search",
        gross_margin: "Search",
        gross_margin_percent: "Search",
        price_kg: "Search",
        cost_kg: "Search",
        cancelled: "Search",
        surcharge_zb00: "Search",
        surcharge_zb04: "Search",
        surcharge_zb05: "Search",
        surcharge_zd00: "Search",
        free_of_charge: "Search",
    };

    switch (key) {
        case "grade":
            return (
                <InputFilter
                    type="text"
                    name={key}
                    placeholder="Search"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "material_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/material-number-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/plant-list"
                    name="plant"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("plant", _value, request));
                    }}
                />
            );

        case "product_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/product-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/invoice/invoices/application-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "billing_date":
        case "created_on":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-short-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_full":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "cancelled":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "free_of_charge":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => true;
