import React from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";

const NoteViewModal = ({ onClose, content }) => {
    return (
        <>
            <div className="help-page-modal">
                <ButtonClose className="main-modal__form-close" onClick={onClose}/>
                <div className="help-page main-modal__form">
                    {content}
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(NoteViewModal);
