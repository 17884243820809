import React, {useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";
import {withServiceConsumer} from "Services/Context";

const { Button, Form, Title, Col, Row, ColText, ColInput, Textarea } = ModalDefault;

const propTypes = {
    filterForm: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    type: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    share: PropTypes.func.isRequired,
};

const ShareFiltersModal = (
    {
        filterForm,
        filter,
        sort,
        type,
        share,
        onClose,
        onChange,
        t,
    }
) => {
    const [user, setUser] = useState('');
    const formValidate = () => {
        return filterForm.user && filterForm.user.trim() !== '';
    };

    const handleShare = () => {
        filterForm.type = type;
        filterForm.data = {filter, sort};
        share(filterForm).then(
            (data) => {
                onClose(data)
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    const onChangeAsyncInput = (e, key) => {
        setUser(e.value);
        onChange(key, e.value);
    };

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <Title>{t('Share Filters')}</Title>
            <Row>
                <Col>
                    <ColText className="bold-text" isRequired>
                        {t("Select User")}
                    </ColText>
                    <ColInput
                        type="asyncSelect"
                        url="/product/product-finder/user-list"
                        name="user"
                        value={user}
                        placeholder={t("Search")}
                        onChange={e => onChangeAsyncInput(e, `filterForm.user`)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ColText className="bold-text">
                        {t("Message")}
                    </ColText>
                    <Textarea
                        placeholder={t("Enter Text")}
                        onChange={value => onChangeInput(value, `filterForm.message`)}
                    >
                        {filterForm.message || ''}
                    </Textarea>
                </Col>
            </Row>
            <Button onClick={handleShare} disabled={!formValidate()}>
                {t("Share")}
            </Button>
        </Form>
    );
}

ShareFiltersModal.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {};
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        share: getActionStore("saveShareFiltersAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ShareFiltersModal);
