import React, { useState } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withServiceConsumer } from "Services/Context";
import { Title, Total } from "Templates/Titles";
import { CheckPermission, withTagDefaultProps } from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import { Button } from "Templates/Default";
import { Modal } from "Templates/Modals";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";
import AddVendorForm from "./addVendorForm";
import EditVendorForm from "./editVendorForm";

const propTypes = {
    title: PropTypes.number.isRequired,
    showModal: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    activeTab: PropTypes.number.isRequired,
    showEditModal: PropTypes.bool.isRequired,
    openEditModal: PropTypes.func.isRequired,
    closeEditModal: PropTypes.func.isRequired,
    deleteVendorCode: PropTypes.func.isRequired,
};

const TabView = (
    {
        showModal,
        openModal,
        closeModal,
        onClickCustom,
        deleteVendorCode,
        t,
        title,
        activeTab,
        onChangeTab,
        showEditModal,
        openEditModal,
        closeEditModal
    }) => {
    const [vendor, setVendor] = useState(null);
    const _onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        setVendor(item)
        openEditModal()
    };

    const _onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the Vendor?"))) {
            deleteVendorCode(item);
        }
    };

    return (
        <>
            <div className="vendor-title__wrap">
                <Title
                    title={t("Vendors")}
                    total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
                />
                <CheckPermission permission="vendors_edit">
                    <Button className="size_normal main-btn main-btn_primary" onClick={openModal}>Add New Vendor Code</Button>
                </CheckPermission>
            </div>

            {showModal ? (
                <Modal onRequestClose={closeModal}>
                    <AddVendorForm/>
                </Modal>
            ) : <div/>}

            {showEditModal ? (
                <Modal onRequestClose={closeEditModal}>
                    <EditVendorForm data={vendor} vendorId={vendor.id}/>
                </Modal>
            ) : <div/>}

            <Tabs
                activeTab={activeTab}
                onClickTabItem={index => {
                    if (activeTab !== index) {
                        onChangeTab(index);
                    }
                }}
            >
                <Tab label={t("Vendors")}>
                    <TabViewDetail
                        customIcon="icon_external_link"
                        onClickCustom={onClickCustom}
                        onClickDelete={_onClickDelete}
                        onClickEdit={_onClickEdit}
                    />
                </Tab>
                <Tab label=""/>
            </Tabs>
        </>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        title: state.listState.pagination.total,
        activeTab: getStoreItem(state, "activeTab"),
        showModal: getStoreItem(state, "showModal"),
        showEditModal: getStoreItem(state, "showEditModal"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        openModal: getActionStore("openModal", service, dispatch),
        closeModal: getActionStore("closeModal", service, dispatch),
        openEditModal: getActionStore("openEditModal", service, dispatch),
        closeEditModal: getActionStore("closeEditModal", service, dispatch),
        deleteVendorCode: getActionStore("deleteVendorCode", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
