import React from 'react';
import Img from "Templates/Img/Img";
import PropTypes from "prop-types";

function NotFoundSafetyDatasheet({title}) {
    return (
        <div className="sheet-notInfo text-center">
            <Img
                img="icon_files"
                width="100"
                height="100"
                alt="file"
                className=""
            />
            <p className="text-center">
                Contact your technical representative
                {' '}
                to request a copy of the
                <br />
                {title} {' '} for this product.
            </p>
        </div>
    );
}

NotFoundSafetyDatasheet.propTypes = {
    title: PropTypes.string.isRequired,
};

export default NotFoundSafetyDatasheet;
