import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import TableRowColumnDefault from "Templates/Table/TableRowColumnWrap/Default";
import {withTagDefaultProps} from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import {withServiceConsumer} from "Services/Context";
import TableRowItem from "./TableRowItem";

const propTypes = {
    column: PropTypes.string.isRequired,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    index: PropTypes.number.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
};

const TableRowColumn = ({
                            index,
                            secondaryIndex,
                            column,
                            item,
                            onClickDelete,
                            onClickCustom
                        }) => {
    return (
        <TableRowColumnTemplate key={`TableRowColumn-${index}-${secondaryIndex}`}>
            {column === "-1" ? (
                <TableRowItem
                    onClickDelete={onClickDelete}
                    onClickCustom={onClickCustom}
                    index={secondaryIndex}
                />
            ) : (
                <TableRowColumnDefault column={column} item={item}/>
            )}
        </TableRowColumnTemplate>
    );
};

TableRowColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableRowColumn);
