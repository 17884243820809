import React from "react";
import _ from "lodash";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

const getViewItem = (key, item) => {
    switch (key) {
        default:
            return <TableRowItemSpan title={_.get(item, key)}>{_.get(item, key)}</TableRowItemSpan>;
    }
};

const columnCount = 1;

const getColumns = (columns, startColumn) => {
    const showColumns = _.concat(_.take(columns), _.slice(columns, startColumn, columnCount + startColumn - 1));
    const headButton = columns.length !== showColumns.length;

    // if (showColumns.length < columnCount) {
    //     showColumns = _.concat(
    //         _.take(columns),
    //         _.slice(
    //             columns,
    //             _.max([startColumn - columnCount - showColumns.length, 1]),
    //             columnCount - showColumns.length,
    //         ),
    //         showColumns,
    //     );
    // }

    return {
        showColumns,
        headButton,
    };
};

export { getViewItem, getColumns };
