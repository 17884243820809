import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {url} from "Services";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const TabView = ({ request, fetchItems, service, t }) => {
    const { deleteItem } = service;

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        url.redirect(`/project/forecasts/${item.id}/edit`);
    };

    const onClickShow = item => {
        const win = window.open(`/project/forecasts/${item.id}`, "_blank");
        win.focus();
    };

    const onClickDelete = item => {
        if (confirm(t("Do you want to delete the Forecast?"))) {
            deleteItem(`project/forecasts/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    return <TabViewDetail
        customIcon={'icon_external_link'}
        onClickCustom={onClickShow}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
    />;
};

TabView.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
