import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import Img from "Templates/Img";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    index: PropTypes.number.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    customIcon: PropTypes.string.isRequired,
};

const TableRowItem = ({
                          index,
                          onClickCustom,
                          customIcon,
                      }) => {

    const _onClickCustom = () => {
        onClickCustom(index);
    };

    return (
        <TableBodyButtonsBlock>
            <TableBodyButtonsBlock>
                <TableBodyButton onClick={_onClickCustom}>
                    <Img img={customIcon}/>
                </TableBodyButton>
            </TableBodyButtonsBlock>
        </TableBodyButtonsBlock>
    );
};

TableRowItem.propTypes = propTypes;

export default compose(withServiceConsumer, withTagDefaultProps())(TableRowItem);
