import React from "react";
import {compose} from "redux";
import {useDispatch} from 'react-redux';

import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import {url} from "Services";
import {activeWeightUnit} from 'App/View/Header/Items/Units/Actions/ConverterUnitsAction';
import InfoWrap from "./Views/InfoWrap";

import _projectService from "./Services/Tabs/Projects";
import _notesService from "./Services/Tabs/Notes";
import _contactService from "./Services/Tabs/Contacts";
import _analyticsGradesService from "./Services/Tabs/Analytics";
import _invoiceService from "./Services/Tabs/Invoices";

import Projects from "./Views/Tabs/Projects";
import Notes from "./Views/Tabs/Notes";
import Contacts from "./Views/Tabs/Contacts";
import Analytics from "./Views/Tabs/Analytics";
import Invoices from "./Views/Tabs/Invoices";

const propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    totalNotes: PropTypes.number.isRequired,
    totalProjects: PropTypes.number.isRequired,
    totalInvoices: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Company = ({pref, id, activeTab, totalNotes, totalProjects, totalInvoices, t, onChangeTab, service, permissions}) => {
    const dispatch = useDispatch();
    const {deleteItem} = service;
    const onClickEdit = () => {
        url.redirect(`/companies/${id}/edit`);
    };

    const onClickDelete = () => {
        if (window.confirm(t("Do you want to delete the Company?"))) {
            deleteItem(`/companies/${id}/delete`).then(
                () => {
                    url.redirect(`/companies`);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    const projectService = _projectService(id);
    const notesService = _notesService(id);
    const contactService = _contactService(id);
    // const contractsService = _contractsService(id);
    const analyticsGradesService = _analyticsGradesService(id);
    const invoiceService = _invoiceService(id);

    let contactsTab = <></>;
    let projectsTab = <></>;

    if (permissions.includes("contacts_view")) {
        contactsTab = (
            <Tab label={t("Contacts")}>
                <ServiceProvider value={contactService}>
                    <Contacts/>
                </ServiceProvider>
            </Tab>
        )
    } else if (activeTab === 0) {
        onChangeTab(activeTab += 1);
    }

    if (permissions.includes("companies_project_view")) {
        projectsTab = (
            <Tab label={t("Projects")} counter={totalProjects} className={`${pref}__wrap main-content__wrap-top main-wrap-block`}>
                <ServiceProvider value={projectService}>
                    <Projects/>
                </ServiceProvider>
            </Tab>
        )
    } else if (activeTab === 1) {
        onChangeTab(activeTab += 1);
    }

    const notesTab = (
        <Tab label={t(`Notes`)} counter={totalNotes}>
            <ServiceProvider value={notesService}>
                <Notes/>
            </ServiceProvider>
        </Tab>
    );

    const analyticsTab = (
        <Tab label={t("Analytics")}>
            <ServiceProvider value={analyticsGradesService}>
                <Analytics/>
            </ServiceProvider>
        </Tab>
    );

    const invoicesTab = (
        <Tab label={t("Invoices")} counter={totalInvoices}>
            <ServiceProvider value={invoiceService}>
                <Invoices/>
            </ServiceProvider>
        </Tab>
    );

    dispatch(activeWeightUnit([1, 3].includes(activeTab)));

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit} onClickDelete={onClickDelete}/>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    {contactsTab}
                    {projectsTab}
                    {notesTab}
                    {analyticsTab}
                    {invoicesTab}

                    {/* <Tab label={t("Contracts")}> */}
                    {/*    <ServiceProvider value={contractsService}> */}
                    {/*        <Contracts /> */}
                    {/*    </ServiceProvider> */}
                    {/* </Tab> */}
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Company.defaultProps = {
    totalNotes: 0,
    totalInvoices: 0,
    totalProjects: 0
}

Company.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        id: state.listState.view.id,
        activeTab: state.listState.activeTab,
        totalNotes: state.listState.totalNotes,
        totalProjects: state.listState.totalProjects,
        totalInvoices: state.listState.totalInvoices,
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Company);
