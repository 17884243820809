import _ from "lodash";
import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import reducers, {initialStates} from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const { manufacturer, unitName, owner, id, years } = props;
    const initData = { manufacturer, unitName, owner, id, years }

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "my-forecast-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: { ..._.cloneDeep(initData) } },
    };

    return _configureStore(initialState, reducers);
}
