import {withTranslation} from "Services/i18n";
import { connect } from "react-redux";
import { compose } from "redux";
import { mapStateToProps, dispatchStateToProps } from "App/Services";

const isFunction = (functionToCheck) => (functionToCheck && {}.toString.call(functionToCheck) === '[object Function]');

export default (props, actions) => Views => {
    props = props || (() => {});
    actions = isFunction(actions) ? actions : () => {};
    return compose(
        connect(
            (state, ownProps) => ({
                ...mapStateToProps(state, ownProps),
                ...props(state, ownProps),
            }),
            (dispatch, ownProps) => {
                return {
                    ...dispatchStateToProps(dispatch, ownProps),
                    ...actions(dispatch, ownProps),
                    t: withTranslation,
                };
            },
        ),
    )(Views);
};
