import Constants from "../Constants";

export const initState = {
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    error: "",
    redirect_url: "",
    appStoreUrl: "",
    playMarketUrl: ""
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.SUBMIT_FROM_REQUEST:
            return { ...state };

        case Constants.SUBMIT_FROM_FAILURE:
            return { ...state, lastAction: Constants.SUBMIT_FROM_FAILURE, error: data.error };

        case Constants.SUBMIT_FROM_SUCCEEDED:
            return { ...state, redirect_url: data.redirect_url, error: '' };
        default: {
            return state;
        }
    }
};
