import {ModalDefault} from "Templates/Modals";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ButtonClose} from "Templates/Button";
import React from "react";
import {Block, ButtonRight, Input, WrapInput} from "Templates/Form";
import ButtonLeft from "Templates/Form/BlockSubmit/ButtonLeft";
import BlockSubmit from "Templates/Form/BlockSubmit/BlockSubmit";

const {Button, Form, Title} = ModalDefault;

const propTypes = {
    form: PropTypes.any.isRequired,
    onChangeForm: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    saveVendorCode: PropTypes.func.isRequired,
}

const AddVendorForm = ({ t, form, onChangeForm, closeModal, saveVendorCode }) => {
    const isValidForm = () => {
        return form.vendorName && form.vendorCode;
    }

    const onChangeInput = (value, key) => {
        onChangeForm({key, value});
    };

    const onSave = () => {
        const formData = {
            name: form.vendorName,
            code: form.vendorCode
        };
        saveVendorCode(formData);
    }

    return <>
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={closeModal}/>
            <Title>{t('New Vendor Code')}</Title>
            <Block>
                <WrapInput isRequired label={t("Vendor Name")} name={'vendorName'}>
                    <Input
                        placeholder={t("Vendor Name")}
                        value={form.vendorName}
                        onChange={value => onChangeInput(value, 'vendorName')}
                    />
                </WrapInput>
            </Block>
            <Block>
                <WrapInput isRequired label={t("Vendor Code")} name={'vendorCode'}>
                    <Input
                        placeholder={t("Vendor Code")}
                        value={form.vendorCode}
                        onChange={value => onChangeInput(value, 'vendorCode')}
                    />
                </WrapInput>
            </Block>
            <BlockSubmit>
                <ButtonRight>
                    <Button onClick={onSave} disabled={!isValidForm()}>
                        {t('Save Vendor Code ')}
                    </Button>
                </ButtonRight>
                <ButtonLeft />
            </BlockSubmit>
        </Form>
    </>
}

AddVendorForm.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        form: getStoreItem(state, "form"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;
    return {
        closeModal: getActionStore("closeModal", service, dispatch),
        onChangeForm: getActionStore("onChangeForm", service, dispatch),
        saveVendorCode: getActionStore("saveVendorCode", service, dispatch),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps),)(AddVendorForm);
