import React, { PureComponent } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { TabWrap } from "Templates/Content";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Input } from "Templates/Form";
import { Span, Div } from "Templates/Default";

import CostTableBodyColumn from "./Table/TableBodyColumn";
import TableHeadColumn from "./Table/TableHeadColumn";

const defaultProps = {
    gradeId: "",
    gradesOptions: [],
};

const propTypes = {
    getCostData: PropTypes.func.isRequired,
    changeGrade: PropTypes.func.isRequired,
    gradeId: PropTypes.string,
    gradesOptions: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string.isRequired, label: PropTypes.string.isRequired }),
    ),
    service: PropTypes.shape({
        colorClass: PropTypes.func.isRequired,
    }).isRequired,
};

class CostModel extends PureComponent {
    componentDidMount() {
        const { getCostData } = this.props;
        getCostData();
    }

    render() {
        const { gradeId, changeGrade, gradesOptions, pref, t } = this.props;
        return (
            <>
                <TabWrap>
                    <div className={`${pref}__wrap-body main-wrap-block__body main-wrap-block__padding`}>
                        <form action="/" onSubmit={e => e.preventDefault()}>
                            <div className="cost-modal__box">
                                <Div className="cost-modal__content_box">
                                    <h2 className="cost-modal__calculator__title">{t("Select Product")}</h2>
                                    <Input
                                        type="select"
                                        name="productGrade"
                                        options={gradesOptions}
                                        placeholder="Select"
                                        value={gradeId}
                                        onChange={value => changeGrade(value)}
                                    />
                                </Div>
                                <TableHeadColumn />
                                <CostTableBodyColumn />
                            </div>
                        </form>
                    </div>
                </TabWrap>
            </>
        );
    }
}

CostModel.defaultProps = defaultProps;
CostModel.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        gradeId: getStoreItem(state, "cost.gradeId"),
        gradesOptions: getStoreItem(state, "cost.grades"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        getCostData: getActionStore("getCostDataAction", service, dispatch),
        changeGrade: getActionStore("changeGradeAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(CostModel);
