import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    item: {},
};

const TableBodyColumn = ({item}) => {
    return (
        <tr>
            <td className="project-expanded-block__table">
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">OEM Tier 1</p>
                    <p className="project-expanded-block__table-value" title={item.tier1 || '-'}>{item.tier1 || '-'}</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">OEM Tier 2</p>
                    <p className="project-expanded-block__table-value" title={item.tier2 || '-'}>{item.tier2 || '-'}</p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">OEM Tier 3</p>
                    <p className="project-expanded-block__table-value" title={item.molder || '-'}>{item.molder || '-'}</p>
                </div>
            </td>
        </tr>
    );
};

TableBodyColumn.propTypes = propTypes;

TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
