import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowItemEdit from "Templates/Table/TableRowItemEdit";

const defaultProps = {
    customIcon: "",
    onClickCustom: () => {},
};

const propTypes = {
    customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClickCustom: PropTypes.func,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

const HierarchyTableRowColumnButton = ({ index, customIcon, onClickCustom, onClickDelete, onClickEdit }) => {
    const _onClickDelete = () => {
        onClickDelete(index);
    };
    const _onClickEdit = () => {
        onClickEdit(index);
    };

    const _onClickCustom = () => {
        onClickCustom(index);
    };

    return (
        <TableRowItemEdit
            customIcon={typeof customIcon === "function" ? customIcon(index) : customIcon}
            onClickCustom={_onClickCustom}
            onClickDelete={_onClickDelete}
            onClickEdit={_onClickEdit}
        />
    );
};

HierarchyTableRowColumnButton.defaultProps = defaultProps;
HierarchyTableRowColumnButton.propTypes = propTypes;

export default compose(withTagDefaultProps())(HierarchyTableRowColumnButton);
