import React, { useState } from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate, parseDate} from "react-day-picker/moment";

import {formatDate as formatDateService} from "Services";
import YearMonthFormDateInput from './YearMonthFormDateInput';

const propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    inputProps: PropTypes.objectOf(PropTypes.any),
    dateFormat: PropTypes.string,
    viewFormat: PropTypes.string,
    placeholder: PropTypes.string,
};

const defaultProps = {
    value: "",
    dateFormat: "YYYY-MM-DD",
    viewFormat: "MMM DD, YYYY",
    placeholder: "MMM DD, YYYY",
    inputProps: {},
};

const CustomOverlay = onChange => ({classNames, selectedDay, children, onBlur ,...props}) => {
    return (
        <div className={classNames.overlayWrapper} style={{marginLeft: -100}}
             onBlur={ e => {
                 onBlur(e);
             //  props.input.focus();
                 props.input.blur();
             }}
             {...props}>
            <div className={classNames.overlay}>
                <p>
                    <button type="button" onClick={() => {
                        props.input.focus();
                        props.input.blur();
                        onChange("");
                    }}>
                        Reset
                    </button>
                </p>
                {children}
            </div>
        </div>
    );
};

const DateInputSelect = ({value, className, onChange, inputProps, viewFormat, placeholder, dateFormat}) => {
  const [isMonth, setMonth] = useState({ month: new Date() });

  const _onChange = day => {
      onChange({target: {value: day ? formatDateService(day.setHours(12,0,0,0), dateFormat) : ""}});
  };

  const handleYearMonthChange = (month) => {
    setMonth({ month });
  }

  return (
    <DayPickerInput
      selectedDays={value}
      value={value ? formatDate(value, viewFormat) : value}
      inputProps={{
        className,
        readOnly: false,
        autoComplete: "false",
        ...inputProps,
      }}
      formatDate={formatDate}
      keepFocus={false}
      format={viewFormat}
      autoComplete={"false"}
      parseDate={parseDate}
      placeholder={placeholder}
      overlayComponent={CustomOverlay(_onChange)}
      onDayChange={_onChange}
      dayPickerProps={{
        month: isMonth.month,
        captionElement: ({ date, localeUtils }) => (
          <YearMonthFormDateInput
            date={date}
            localeUtils={localeUtils}
            onChange={handleYearMonthChange}
          />
        ),
      }}
    />
  );
};

DateInputSelect.propTypes = propTypes;
DateInputSelect.defaultProps = defaultProps;

export default DateInputSelect;
