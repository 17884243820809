import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import ContainerTable from "./Table/ContainerTable";
import TableHeadTitle from "./Table/TableHeadTitle";
import TableRowColumn from "./Table/TableRowColumn";

const TableStructure = ContainerTable(TableHeadTitle, TableRowColumn);

const propTypes = {
    service: PropTypes.shape({
        title: PropTypes.string.isRequired,
    }).isRequired,
};

const SearchBlock = ({ pref, service: { title }, t }) => {
    return (
        <div className={`${pref}__card`}>
            <div className={`${pref}__title`}>{t(title)}</div>
            <TableStructure />
        </div>
    );
};

SearchBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(SearchBlock);
