import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, id: PropTypes.id })).isRequired,
};

function Breadcrumbs({ breadcrumbsItems }) {
    const getHref = elem => `/files${elem.id === "#" ? "" : `/${elem.id}`}`;
    const getLabel = elem => elem.name;

    return (
        <ul className="breadcrumbs">
            {breadcrumbsItems.map((elem, index) => (
                <li key={`breadcrumbs-li-${index}`} className="breadcrumbs__item">
                    {elem.id ? (
                        <a className="breadcrumbs__link" href={getHref(elem)}>
                            {getLabel(elem)}
                        </a>
                    ) : (
                        <span className="breadcrumbs__link">{getLabel(elem)}</span>
                    )}
                </li>
            ))}
        </ul>
    );
}
const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    breadcrumbsItems: getStoreItem(state, "view.breadcrumbsItems"),
});

Breadcrumbs.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Breadcrumbs);
