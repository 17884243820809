import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    subTabs: [],
    activeSubTab: {},
    totalTechnicals: 0,
    totalProcesses: 0,
    totalDesigns: 0,
    totalSafeties: 0,
    totalBrochures: 0,
    totalNotes: 0,
};

export const reducer = (state, action) => {
    const { data } = action;

    const totalTechnicals = data && data[0] ? data[0].totalTechnicals : 0;
    const totalProcesses = data && data[0] ? data[0].totalProcesses : 0;
    const totalDesigns = data && data[0] ? data[0].totalDesigns : 0;
    const totalSafeties = data && data[0] ? data[0].totalSafeties : 0;
    const totalBrochures = data && data[0] ? data[0].totalBrochures : 0;
    const totalNotes = data && data[0] ? data[0].totalNotes : 0;

    switch (action.type) {
        case Constants.FETCH_PRODUCT_ITEMS_SUCCEEDED: {
            const { items, activeSubTab } = state;
            if (items && items.length) {
                return { ...state }
            }

            if (activeSubTab && Object.keys(activeSubTab).length) {
                activeSubTab.product_items = data || {};
                const subTabs = state.subTabs.map(subTab => subTab.id === activeSubTab.id ? activeSubTab : subTab);

                return {
                    ...state,
                    subTabs
                }
            }

            return { ...state }
        }

        case Constants.CHANGE_ACTIVE_SUBTAB: {
            const activeSubTab = state.subTabs.find(tab => tab.id === data);
            const subItemsData = activeSubTab.items_data || {};
            const productItems = activeSubTab.product_items || {};

            const groupedColors = _.chain(subItemsData.product_colors).groupBy(item => item.color)
                .map((value, key) => ({color: key, items: _.uniqBy(_.map(value, 'items').flat(), v => [v.approval, v.code].join())}))
                .value();

            return {
                ...state,
                items: productItems.items || [],
                pagination: productItems.pagination || {},
                columns: productItems.columns || [],
                sort: { column: productItems.columns[0], direction: "asc" },
                activeSubTab,
                view: {
                    ...state.view,
                    datasheet: {
                        ...state.view.datasheet,
                        contacts: subItemsData.contacts || [],
                        locations: subItemsData.locations,
                        paintable: subItemsData.paintable,
                        molded: subItemsData.molded,
                        is_boundable: subItemsData.is_boundable,
                        colors: subItemsData.colors,
                        product_colors: groupedColors,
                        countries: subItemsData.countries,
                        application_codes: subItemsData.application_codes,
                        market_segments: subItemsData.market_segments,
                        segments: subItemsData.segments,
                        sub_segments: subItemsData.sub_segments,
                        manufacturers: subItemsData.manufacturers,
                    }
                }
            };
        }

        case Constants.FETCH_SUBTAB_ITEMS_REQUESTED: {
            const subTabs = data && data[0] ? data[0].items : [];

            if (!subTabs.length) {
                return {
                    ...state,
                    items: [],
                    pagination: {},
                    columns: [],
                    sort: { column: '', direction: '' },
                    subTabs: [],
                    activeSubTab: {},
                    view: {
                        ...state.view,
                        datasheet: {
                            contacts: [],
                            locations: [],
                            paintable: null,
                            molded: null,
                            is_boundable: null,
                            colors: [],
                            product_colors: [],
                            countries: [],
                            application_codes: [],
                            market_segments: [],
                            segments: [],
                            sub_segments: [],
                            manufacturers: [],
                        }
                    },
                    totalTechnicals,
                    totalProcesses,
                    totalDesigns,
                    totalSafeties,
                    totalBrochures,
                    totalNotes
                };
            }

            const activeSubTabId = state.activeSubTab && state.activeSubTab.id;
            const defaultActiveStub = subTabs && subTabs[0] ? subTabs[0] : {};
            const activeSubTab = activeSubTabId ?
                (subTabs.find(item => item.id === activeSubTabId) || defaultActiveStub) :
                defaultActiveStub;
            const subItemsData = activeSubTab.items_data || {};
            const productItems = activeSubTab ? activeSubTab.product_items : {};

            const groupedColors = _.chain(subItemsData.product_colors).groupBy(item => item.color)
                             .map((value, key) => ({color: key, items: _.uniqBy(_.map(value, 'items').flat(), v => [v.approval, v.code].join())}))
                             .value();

            return {
                ...state,
                items: productItems.items || [],
                pagination: productItems.pagination || {},
                columns: productItems.columns || [],
                sort: { column: productItems.columns[0], direction: "asc" },
                subTabs,
                activeSubTab,
                view: {
                    ...state.view,
                    datasheet: {
                        ...state.view.datasheet,
                        contacts: subItemsData.contacts || [],
                        locations: subItemsData.locations,
                        paintable: subItemsData.paintable,
                        molded: subItemsData.molded || [],
                        is_boundable: subItemsData.is_boundable,
                        colors: subItemsData.colors,
                        product_colors: groupedColors,
                        countries: subItemsData.countries,
                        application_codes: subItemsData.application_codes,
                        market_segments: subItemsData.market_segments,
                        segments: subItemsData.segments,
                        sub_segments: subItemsData.sub_segments,
                        manufacturers: subItemsData.manufacturers,
                    }
                },
                totalTechnicals,
                totalProcesses,
                totalDesigns,
                totalSafeties,
                totalBrochures,
                totalNotes
            };
        }

        default:
            return state;
    }
};
