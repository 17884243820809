import React from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import { getString, formatDate, numberWithCommas } from "Services";

export const modifierValues = items => items;

const OPERATION_TYPES = {
    'BASE': 1,
    'DELTA': 2,
};

const OPERATION_STATUSES = {
    'IMPORTED': 'Imported',
    'PROCESSING': 'Processing',
    'PENDING': 'Pending',
    'CANCELED': 'Canceled',
};

export const getViewItem = (key, item) => {
    const historyAnalytic = JSON.parse(item.analytics || '[]');
    const currentAnalytic = item.current_total_analytics;

    switch (key) {
        case "modified":
            return (
                <TableRowItemSpan title={formatDate(getString(item, key))}>
                    {formatDate(getString(item, key))}
                </TableRowItemSpan>
            );

        case "modified_by":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "user")}`}
                    img={getString(item, "user_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case String(key.match(/^volume_.*/)): {
            if (item.type !== OPERATION_TYPES.BASE || item.status !== OPERATION_STATUSES.IMPORTED) {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            const keys = key.toLowerCase().split("_");

            return (
                <>
                    <TableRowItemSpan className="text-right">
                        {`${numberWithCommas(getString(currentAnalytic, `${keys[2]}.${keys[1]}.${keys[0]}`), 0)} lb`}
                    </TableRowItemSpan>
                    <TableRowItemSpan className="text-right clr-red">
                        {`${numberWithCommas(getString(historyAnalytic, `project_analytics.${keys[2]}.${keys[1]}.${keys[0]}`), 0)} lb`}
                    </TableRowItemSpan>
                </>
            );
        }

        case String(key.match(/^revenue_.*/)): {
            if (item.type !== OPERATION_TYPES.BASE || item.status !== OPERATION_STATUSES.IMPORTED) {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            const keys = key.toLowerCase().split("_");

            return (
                <>
                    <TableRowItemSpan className="text-right">
                        {`$${numberWithCommas(getString(currentAnalytic, `${keys[2]}.${keys[1]}.${keys[0]}`), 0)}`}
                    </TableRowItemSpan>
                    <TableRowItemSpan className="text-right clr-red">
                        {`$${numberWithCommas(getString(historyAnalytic, `project_analytics.${keys[2]}.${keys[1]}.${keys[0]}`), 0)}`}
                    </TableRowItemSpan>
                </>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
