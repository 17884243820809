import React from "react";
import PropTypes from "prop-types";
import {ButtonClose} from "Templates/Button";
import {ModalDefault} from "Templates/Modals";
import _ from "lodash";

const {Form, ColText} = ModalDefault;

const propTypes = {
    onClose: PropTypes.func.isRequired
};

function MaterialPropertyPreviewModal({tdsData, visible, onClose}) {
    const splitCamelCaseStr = (str) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase())
    };

    const replacements = {'valueEnglish': 'Value (English)', 'valueSi': 'Value (SI)'};
    const replacedItems = (obj) => {
        return Object.entries(obj).reduce((acc, [key,value]) => {
            const newKey = replacements[key]
            acc[newKey || splitCamelCaseStr(key) ] = value;

            delete acc['Front Id']
            delete acc['Id']

            delete acc['Property'];
            if(!visible.property) {
                delete acc['Property View'];
            }

            delete acc['Specimen'];
            if(!visible.specimen) {
                delete acc['Specimen View'];
            }

            delete acc['Test Condition'];
            if(!visible.testCondition) {
                delete acc['Test Condition View'];
            }

            delete acc['Test Method'];
            if(!visible.testMethod) {
                delete acc['Test Method View'];
            }

            if(['Unit Si'].includes(newKey || splitCamelCaseStr(key))){
                const valMin = acc['Value Min Si'];
                const valMax= acc['Value Max Si'];
                let valueRes = valMin || valMax;

                if(valMin && valMax) {
                    valueRes = valMin === valMax ? valMin : (valMin + ' - ' + valMax);
                }

                if(visible.unitSi) {
                    acc['Value (SI)']  = valueRes ? valueRes + ' ' + (obj.unitSiView || '') : '';
                }
                delete acc['Unit Si']
                delete acc['Value Min Si']
                delete acc['Value Max Si']
            }

            if(['Unit English'].includes(newKey || splitCamelCaseStr(key))){
                const valMin = acc['Value Min English'];
                const valMax = acc['Value Max English'];
                let valueRes = valMin || valMax;

                if(valMin && valMax) {
                    valueRes = valMin === valMax ? valMin : (valMin + ' - ' + valMax);
                }

                if(visible.unitEnglish) {
                    acc['Value (English)']  = valueRes ? valueRes + ' ' + (obj.unitEnglishView || ''): '';
                }
                delete acc['Unit English']
                delete acc['Value Min English']
                delete acc['Value Max English']
            }

            if(!obj.testCondition) {
                delete acc['Value Min Si']
                delete acc['Value Max Si']
            }

            if(!obj.unitSi) {
                delete acc['Value Min English']
                delete acc['Value Max English']
            }

            delete acc['Unit Si View']
            delete acc['Unit English View']

            return acc
        }, {})
    }

    const replacedTDSData = tdsData.map(obj => replacedItems(obj))

    const getTableHeaderItems = () => {
        if(replacedTDSData[0]){

            return _.filter(Object.keys(replacedTDSData[0]), (val) => {
                return (visible.property && val === 'Property View') ||
                    (visible.specimen && val === 'Specimen View') ||
                    (visible.testCondition && val === 'Test Condition View') ||
                    (visible.testMethod && val === 'Test Method View') ||
                    (visible.unitEnglish && (val === 'Value (English)')) ||
                    (visible.unitSi && (val === 'Value (SI)'));
            })
        }
        return []
    }

    const headerNames = {
        'Property View' : 'Property',
        'Specimen View' : 'Specimen',
        'Test Condition View' : 'Test Condition',
        'Test Method View' : 'Test Method',
    };

    return (
        <Form className="preview_properties_modal">
            <div>
                <ButtonClose className="main-modal__form-close" onClick={onClose}/>
            </div>
            {
                getTableHeaderItems().length ?
                    (<div className="table-responsive_preview">
                        <table className="technical-table">
                            <thead>
                            <tr>
                                {getTableHeaderItems().map((title) => (
                                    <th key={title}>{headerNames[title] || title}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {replacedTDSData.map((prop, idx) => (
                                <tr key={`${idx}-prop`}>
                                    {Object.values(prop).map((value, key) => (
                                        <td key={`${idx}-${key}-item`}>{value}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>) :
                    (<ColText>Not found active columns</ColText>)
            }

        </Form>
    )
}

MaterialPropertyPreviewModal.propTypes = propTypes;
export default MaterialPropertyPreviewModal;

