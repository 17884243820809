import React from "react";
import TableHeadColumnTemplate from "Templates/Table/TableHeadColumn";
import PropTypes from "prop-types";
import _ from "lodash";
import {compose} from "redux";
import TableHeadFilter from "Templates/Table/TableHeadFilter";
import TableHeadTitle from "Templates/Table/TableHeadTitle";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    column: PropTypes.string.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getFilter: PropTypes.func.isRequired,
        getTableLabel: PropTypes.func.isRequired,
    }).isRequired,
    onSortClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const TableHeadColumnWrap = ({t, column, filter, sort, service, onSortClick, onChange}) => {
    const {getFilter, getTableLabel} = service;

    return (
        <TableHeadColumnTemplate>
            <div>
                <TableHeadTitle
                    column={column}
                    sort={sort}
                    onSortClick={() => onSortClick(column)}>
                    {t(getTableLabel(column))}
                </TableHeadTitle>
            </div>
            <TableHeadFilter>
                {getFilter(column, _.get(filter, column, "") || _.get(filter, `${column}s`, ""), onChange, {
                    filter,
                    sort
                })}
            </TableHeadFilter>
        </TableHeadColumnTemplate>
    );
};

TableHeadColumnWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        filter: getStoreItem(state, "filter"),
        sort: getStoreItem(state, "sort"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TableHeadColumnWrap);