import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const {
        id,
        publicationId,
        publicationName,
        company,
        customName,
        publicationLogo,
        contacts,
        paintable,
        molded,
        is_boundable,
        keywords,
        locations,
        countries,
        colors,
        suppliers,
        content,
        legal,
        legalHTML,
    } = props;

    const initData = {
        id,
        publicationId,
        publicationName,
        company,
        customName,
        publicationLogo,
        paintable,
        molded,
        is_boundable,
        keywords: keywords || [],
        contacts: contacts.length ? contacts : [{ frontId: 0, contact: "", role: "", role_id: "" }],
        locations: locations.length ? locations : [{ frontId: 0, value: "" }],
        countries: countries.length ? countries : [{ frontId: 0, value: "" }],
        colors: (colors || [{ frontId: 0, color: '', items: [] }]).map(color => ({
            frontId: color.frontId || 0,
            color: color.color || '',
            items: color.items.length ? color.items : [{ frontId: 0, approval: '', code: '', source: '' }]
        })),
        suppliers: suppliers.length ? suppliers : [{ frontId: 0, value: "" }],
        content: content.length
            ? content.map(item => ({
                ...item,
                value: item.value
            }))
            : [
                { frontId: 0, value: '', template: '' },
                { frontId: 1, value: '', template: '' }
            ],
        legal,
        legalHTML
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "publication-designs-edit" }),
        editState: { ...editState, form_validate: true, ...initData, old: _.cloneDeep(initData) },
    };

    return _configureStore(initialState, reducers);
}
