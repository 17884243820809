import React, {Component} from "react";
import _ from "lodash";
import {compose} from "redux";
import PropTypes from "prop-types";
import {Tooltip} from "Templates/Form";
import Img, {ImgStorage} from "Templates/Img";
import {withTagDefaultProps} from "Hoc/Template";

const defaultProps = {
    defaultImg: "input_file_img",
    textButton: "Upload Image",
    width: 320,
    height: 320,
    isZoomRange: false
};

const propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    isZoomRange: PropTypes.bool,
    defaultImg: PropTypes.string,
    textButton: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

class BlockImgWithCropAlt extends Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            image: ""
        };
    }

    fileChange = async e => {
        function readFileAsDataUrl(file) {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.addEventListener("load", () => resolve(reader.result), false);
                reader.readAsDataURL(file);
            });
        }

        function readFileAsBlob(file) {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    const blob = new Blob([reader.result], { type: file.type });
                    resolve(blob);
                }, false);
                reader.readAsArrayBuffer(file);
            });
        }

        if (e.target.files && e.target.files.length > 0) {
            const { onChange, name } = this.props;
            const file = e.target.files[0];

            const imageDataUrlPromise = readFileAsDataUrl(file);
            const fileBlobPromise = readFileAsBlob(file);

            const [imageDataUrl, fileBlob] = await Promise.all([imageDataUrlPromise, fileBlobPromise]);

            this.setState({
                image: imageDataUrl,
            });

            onChange(fileBlob, name);
        }
    };

    onClose = () => {
        this.fileRef.current.value = "";
        this.setState({
            image: '',
        });
    };

    handleClickOnUploadPhoto = () => {
        this.fileRef.current.click();
    };

    deleteCroppedImage = async event => {
        event.preventDefault();
        event.stopPropagation();
        const { onChange, name } = this.props;
        onChange("", name);
        this.fileRef.current.value = "";
        this.setState({
            image: '',
        });
    };

    renderPreview = value => {
        if (!value) {
            const { defaultImg } = this.props;
            return <Img img={defaultImg} />;
        }

        if (_.isString(value)) {
            return <ImgStorage url={value} />;
        }

        return <img src={this.src} alt="logo" />;
    };

    render = () => {
        const { image } = this.state;
        const { pref, title, value, t } = this.props;

        return <div className={`${pref}__form-block-logo main-content__form-block-logo block-image-with-crop-alt ${value ? 'crop-has-image' : ''}`} onClick={this.handleClickOnUploadPhoto}>

                { title
                    ? (
                        <>
                            <span className={`${pref}__form-logo-title main-content__form-logo-title`}>{title}</span>
                            <Tooltip>{t("Recommended min image size 160 x 160px.")}</Tooltip>
                        </>
                    )
                    : null
                }

                <div className="main-modal__form-file-wrap-text">
                    <h4 className="main-modal__form-file-h4">Drag and Drop to Upload</h4>
                    <div className="main-modal__form-file-text">
                        or{" "}
                        <a href="#" className="main-modal__form-file-link">
                            Browse
                        </a>{" "}
                        to choose a file
                    </div>
                    <div className="main-modal__form-file-text">Maximum file size 500Mb</div>
                </div>

                <div className={`${pref}__form-logo-wrap main-content__form-logo-wrap`}>
                        <span className={`${pref}__form-logo-square main-content__form-logo-square main-square-logo`}>
                            {!image ? this.renderPreview(value) : <img src={image} alt="Preview"/>}
                        </span>
                    {value ? (
                        <button
                            type="button"
                            className="main-content__form-logo-delete"
                            onClick={this.deleteCroppedImage}
                        >
                            <i className="la la-plus" />
                        </button>
                    ) : null}
                </div>

                <input
                    type="file"
                    accept="image/*"
                    ref={this.fileRef}
                    onChange={this.fileChange}
                    className="visually-hidden"
                />
            </div>
    };
}

BlockImgWithCropAlt.defaultProps = defaultProps;
BlockImgWithCropAlt.propTypes = propTypes;

export default compose(withTagDefaultProps())(BlockImgWithCropAlt);
