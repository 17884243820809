import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    statistics: {},
    data: {},
    old: {},
    form: {
        marginListSelect: "",
        statusProject: "0",
        periodFrom: new Date().getFullYear().toString(),
        periodTo: new Date().getFullYear().toString(),
        timeScale: "",
    },
    form_request: false,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = _.cloneDeep(state);
            _.set(newState, data.key, data.value);

            if (data.key.endsWith("periodFrom") && newState.analytics.form.periodTo < data.value) {
                _.set(newState, "analytics.form.periodTo", data.value);
            }

            return { ...newState };
        }

        case Constants.RESET_ANALYTICS: {
            return {
                ...state,
                analytics: {
                    data: initState.data,
                    old: { ...initState.old },
                    form: { ...initState.form },
                    form_validate: initState.form_validate,
                },
            };
        }

        case Constants.CHART_DATA_REQUESTED:
            return { ...state, analytics: { ...state.analytics, data: {} }, loading: true };

        case Constants.HEADER_DATA_REQUESTED:
            return {
                ...state,
                analytics: { ...state.analytics, statistics: { ...initState.statistics } },
                loading: true,
            };

        case Constants.HEADER_DATA_SUCCEEDED:
            return { ...state, analytics: { ...state.analytics, statistics: data.items }, loading: false };

        case Constants.HEADER_DATA_FAILED:
            return {
                ...state,
                errors: { ...data },
                analytics: { ...state.analytics, statistics: { ...initState.statistics } },
                loading: false,
            };

        case Constants.CHART_DATA_SUCCEEDED:
            return {
                ...state,
                analytics: { ...state.analytics, data: data.items, old: _.cloneDeep(state.analytics.form) },
                loading: false,
            };

        case Constants.CHART_DATA_FAILED:
            return { ...state, errors: { ...data }, analytics: { ...state.analytics }, loading: false };

        default:
            return state;
    }
};
