import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { LinkImg, NoLinkImg } from "../Link";

const propTypes = {
    className: PropTypes.string,
    storage_url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    settings: PropTypes.objectOf(
        PropTypes.shape({
            ico: PropTypes.string,
            target: PropTypes.string,
            img: PropTypes.string,
            href: PropTypes.func.isRequired,
            noLink: PropTypes.bool
        }),
    ).isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: "",
    title: "",
};

const TableRowItemFolderLinkImg = ({ storage_url, className, type, settings, children, title }) => {
    const setting = settings[type];

    if (setting.noLink) {
        return (
            <NoLinkImg title={title} className={className} img={setting.img} ico={setting.ico}>
                {children}
            </NoLinkImg>
        );
    }

    return (
        <LinkImg
            title={title}
            className={className}
            target={setting.target}
            href={setting.href(storage_url)}
            img={setting.img}
            ico={setting.ico}
        >
            {children}
        </LinkImg>
    );
};

TableRowItemFolderLinkImg.propTypes = propTypes;
TableRowItemFolderLinkImg.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowItemFolderLinkImg);
