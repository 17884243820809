import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import { BackLink } from "Templates/Link";
import { withTagDefaultProps } from "Hoc/Template";
import FormBlock from "../../Views/Form";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Plants = ({ validate, formData, pref, t, submitForm }) => {
    const savePlants = () => {
        submitForm(formData).then(data => url.redirect(`/product/plants/${data.id}`), errors => alert(errors.detail));
    };

    const handleClickOnCancelButton = () => {
        if (confirm(t("Cancel?"))) {
            url.redirect("/product/plants");
        }
    };

    return (
        <>
            <Title
                title={t("Add New Plant")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonLeft>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>
                        {t("Cancel")}
                    </SubmitBlockButton>
                </ButtonLeft>
                <ButtonRight>
                    <SubmitBlockButton disabled={!validate} onClick={savePlants}>
                        {t("Add Plant")}
                    </SubmitBlockButton>
                </ButtonRight>
            </BlockSubmit>
        </>
    );
};

Plants.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
        resetForm: getActionStore("resetForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Plants);
