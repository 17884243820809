import React, {useState} from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { formatDate, getString } from "Services";
import _ from "lodash";
import {Modal} from "Templates/Modals";
import JsonModal from "../../../Views/Tabs/SapAnalytic/Modals/JsonModal";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const [requestJsonModal, setRequestJsonModal] = useState(false)
    switch (key) {
        case "date":
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );

        case "user":
            return (
                <TableRowItemLink
                    blank
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "user_id")}`}
                >
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "type":
            return (
                <TableRowItemSpan title={getString(item, key)}>
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "imported_count":
            return (
                <TableRowItemSpan
                    className="text-right"
                    title={getString(item, key)}
                >
                    {getString(item, key)}
                </TableRowItemSpan>
            );

        case "request":
            return (
                <>
                    <button
                        title={getString(item, key)}
                        className="json-modal-button"
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            setRequestJsonModal(true)
                        }}
                    >
                        View
                    </button>
                    {requestJsonModal && (
                        <Modal onRequestClose={() => {
                            setRequestJsonModal(false)
                        }}>
                            <JsonModal
                                jsonData={item.request && item.request.length ? JSON.parse(item.request || '') : ''}
                                onClose={() => {
                                    setRequestJsonModal(false)
                                }}
                            />
                        </Modal>
                    )}
                </>
            );

        case "response":
            return (
                <>
                    <button
                        title={getString(item, key)}
                        className="json-modal-button"
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            setRequestJsonModal(true)
                        }}
                    >
                        View
                    </button>
                    {requestJsonModal && (
                        <Modal onRequestClose={() => {
                            setRequestJsonModal(false)
                        }}>
                            <JsonModal
                                jsonData={item.response && item.response.length ? JSON.parse(item.response || '') : ''}
                                onClose={() => {
                                    setRequestJsonModal(false)
                                }}
                            />
                        </Modal>
                    )}
                </>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
