const Constants = {
    CREATE_FILES_REQUEST: "CREATE_FILES_REQUEST",
    CREATE_FILES_SUCCESS: "CREATE_FILES_SUCCESS",
    CREATE_FILES_FAILED: "CREATE_FILES_FAILED",

    CREATE_FOLDER_REQUEST: "CREATE_FOLDER_REQUEST",
    CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
    CREATE_FOLDER_FAILED: "CREATE_FOLDER_FAILED",

    EDIT_FOLDER_REQUEST: "EDIT_FOLDER_REQUEST",
    EDIT_FOLDER_SUCCESS: "EDIT_FOLDER_SUCCESS",
    EDIT_FOLDER_FAILED: "EDIT_FOLDER_FAILED",
};
export default Constants;
