import React, {useState} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Block, BlockMain, BlockShadow, BlockWrap, Input, WrapInput } from "Templates/Form";
import PublicationFileUploader from "../../../../View/Views/Tabs/Brochure/PublicationFileUploader";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export const UPLOAD_BROCHURE_FILE = 1
export const SELECT_FROM_ALREADY_UPLOADED = 2

const CustomTds = ({ t, name, onChange, }) => {
    const [brochureFileAction, setBrochureFileAction] = useState(UPLOAD_BROCHURE_FILE)
    const brochureFileActionsOptions = [
        {
            label: 'Upload Brochure File',
            value: UPLOAD_BROCHURE_FILE
        },
        {
            label: 'Select from already uploaded',
            value: SELECT_FROM_ALREADY_UPLOADED
        }
    ]
    const onChangeInput = (value, key) => {
        onChange(key, value);
    };

    return (
        <BlockMain title="Brochure">
            <BlockShadow>
                <Block className="publication-top-row">
                    <BlockWrap>
                        <WrapInput isRequired label={t("Name")} name="name">
                            <Input
                                placeholder={t("Name")}
                                value={name}
                                onChange={value => {
                                    onChangeInput(value, 'name');
                                }}
                            />
                        </WrapInput>
                    </BlockWrap>
                    <BlockWrap className="width_360">
                        <WrapInput name="choseOption" label={t("Select Brochure File Action")}>
                            <Input
                                type="select"
                                options={brochureFileActionsOptions}
                                placeholder={t("Select")}
                                value={brochureFileAction}
                                onChange={value => setBrochureFileAction(value)}
                            />
                        </WrapInput>
                    </BlockWrap>
                    <BlockWrap className="width_360">
                        {brochureFileAction === UPLOAD_BROCHURE_FILE ? (
                            <PublicationFileUploader title="Brochure File"/>
                        ) : (
                            <WrapInput name="brochureFile" label={t("Select Brochure File")}>
                                <Input
                                    name="brochureFile"
                                    type="asyncSelect"
                                    placeholder={t("Select")}
                                    url="/admin/list/brochures/path/list"
                                    value=""
                                    onChange={(value, label) => {
                                        onChange('attachment_name', label);
                                        onChange('attachment_path', value);
                                    }}
                                />
                            </WrapInput>
                        )}
                    </BlockWrap>
                </Block>
            </BlockShadow>
        </BlockMain>
    );
}

CustomTds.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "name"),
        file: getStoreItem(state, "file"),
        fileName: getStoreItem(state, "fileName"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(CustomTds);
