import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {Label, Tooltip} from "Templates/Form";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Column, Row} from "Templates/Table/NewVersion";
import {Div} from "Templates/Default";

const TableHeadColumn = ({ t, service: { columns }, showFull }) => {
    const getHeadView = key => {
        const tableLabel = {
            totalUnits: "Total Units",
            partWeight: "Part Weight",
            partsperUnit: "No. of Parts/Unit",
            totalVolume: "Total Volume",
        };

        switch (key) {
            case "*":
            case "=": {
                return null;
            }

            case "partWeight": {
                return (
                    <Div className="text-center project-part-weight">
                        <Label isRequired={showFull} label={tableLabel[key]} />
                        <Tooltip>
                            <span>{t("Total volume of shot. Enter volume in lb.")}</span>
                        </Tooltip>
                    </Div>
                );
            }

            case "partsperUnit": {
                return (
                    <Div className="text-right">
                        <Label isRequired={showFull} label={tableLabel[key]} />
                    </Div>
                );
            }

            default:
                return (
                    <Div className="text-right">
                        <Label label={tableLabel[key]} />
                    </Div>
                );
        }
    }

    return (
        <Row>
            {columns.map((column, index) => (
                <Column  key={`Head-${index}`}>{getHeadView(column)}</Column>
            ))}
        </Row>
    );
};

TableHeadColumn.propTypes = {
    service: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        showFull: getStoreItem(state, "show_full"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableHeadColumn);
