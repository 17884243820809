import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getTableLabel = (key, { currentFY }) => {
    const tableLabel = {
        name: "Plant Name",
        address: "Address",
        volume: "Volume",
        revenue: "Revenue",
        gross_margin: "Gross Margin",
    };
    switch (key) {
        case "volume":
        case "revenue":
            return `FY ${currentFY} <br> Forecast ${_.get(tableLabel, key, key)} <br> (Current)`;

        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        address: "Search",
        revenue: "Search",
        volume: "Search",
    };
    switch (key) {
        case "revenue":
        case "volume":
        case "gross_margin":
            return ;
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => !(["revenue", "volume", "gross_margin"].includes(key));

