import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";
import ForecastTableBodyColumn from "./Table/TableBodyColumn";
import ForecastFYTableBodyColumn from "./Table/TableBodyColumnFY";
import ForecastCYTableBodyColumn from "./Table/TableBodyColumnCY";

import * as ForecastService from "../../../../../Services/Tabs/General/Table/ForecastService";
import {BlockTitle, Col} from "Templates/Form";
import {ServiceProvider} from "Services/Context";
import Img from "Templates/Img";

const propTypes = {};

const ForecastTable = CustomSimpleTable()(() => null)()(ForecastTableBodyColumn)()(() => null);
const ForecastTableFY = CustomSimpleTable()(() => null)()(ForecastFYTableBodyColumn)()(() => null);
const ForecastTableCY = CustomSimpleTable()(() => null)()(ForecastCYTableBodyColumn)()(() => null);

const ForecastBlock = ({ block, isExpanded, setExpandedAction }) => {
    const [expanded, setExpanded] = useState(isExpanded)

    useEffect(() => setExpanded(isExpanded), [isExpanded])

    return (
        <>
            <div className="project-expanded-block">
                <Col>
                    <div className={`project-expanded-block__title-wrap ${expanded ? 'expanded' : ''}`} onClick={() => {
                        setExpanded(!expanded)
                        setExpandedAction({
                            block,
                            expand: !expanded
                        })
                    }}>
                        <BlockTitle>Forecast</BlockTitle>
                        <Img img="expand-icon" width="24" height="24"/>
                    </div>

                    <div className={`project-expanded-block__toggle project-expanded-block__toggle-forecast ${expanded ? 'expanded' : ''}`}>
                        <ServiceProvider value={ForecastService}>
                            <ForecastTable />
                        </ServiceProvider>
                    </div>
                </Col>
            </div>
            {expanded ? <ServiceProvider value={ForecastService}>
                <ForecastTableFY/>
            </ServiceProvider> : null}
            {expanded ? <ServiceProvider value={ForecastService}>
                <ForecastTableCY expanded={expanded}/>
            </ServiceProvider> : null}
        </>
    );
};

ForecastBlock.propTypes = propTypes;

export default compose(withTagDefaultProps())(ForecastBlock);
