import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row, Column } from "Templates/Table/NewVersion";
import {Tooltip} from "Templates/Form";

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    service: PropTypes.shape({
        columnsName: PropTypes.shape({
            plantName: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            appCodeName: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            materialType: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            unit: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            standardPrice: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            currency: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            priceUnit: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            lineCode: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            lineName: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            numberRouting: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            numberOperation: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            numberWorkCenter: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            submittedBy: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            lastDateChanged: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            materialGroup: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            priceControl: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            isObsolete: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            markedDeletion: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            costingLotSize: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            costingLotUnit: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            componentScrapInPercent: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            fixedCost: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            variableCost: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            totalCost: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
            costEstimationItems: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
        }).isRequired,
        getBodyView: PropTypes.func.isRequired,
    }).isRequired,
};

const defaultProps = {
    items: [],
};

const TableBodyColumn = ({ items, service: { columnsName, getBodyView }, t }) => {
    const row = column => (
        <Row>
            <Column className="sap-view gray-text">
                {t(columnsName[column].label)}
                {columnsName[column].label === 'Standard Price' && (
                    <Tooltip>
                        {t(
                            "Expected COGS",
                        )}
                    </Tooltip>
                )}
            </Column>
            {items.length && items.map((elem, index) => (
                <Column className="sap-view" key={`Column-${index}`}>
                    {getBodyView(column, elem)}
                </Column>
            ))}
        </Row>
    );

    return (
        <>
            {Object.keys(columnsName).map(column => (
                <Fragment key={`Fragment-${column}`}>
                    {row(column)}
                </Fragment>
            ))}
        </>
    );
};

TableBodyColumn.propTypes = propTypes;
TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem, itemsName } = ownProps.service;

    return {
        items: getStoreItem(state, itemsName),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
