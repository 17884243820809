import React, {useEffect, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {formatDateToLocal, numberWithCommas} from "Services";
import {withServiceConsumer} from "Services/Context";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {TabBlock, TabBlockCol, TabBlockColTitle, TabBlockColTitleH4, TabWrap, TabWrapTop} from "Templates/Content";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import ContainerTable from "./Table/ContainerTable";
import TableHeadTitle from "./Table/TableHeadTitle";
import TableRowColumn from "./Table/TableRowColumn";
import {getUsersSessions} from "../Services/RequestService";

const TableStructure = ContainerTable(TableHeadTitle, TableRowColumn);

const TabViewDetail = customTable(TableStructure);

const propTypes = {
    id: PropTypes.string.isRequired,
    loginsMo: PropTypes.number.isRequired,
    projects: PropTypes.number.isRequired,
    sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
    // projectsAdded: PropTypes.number.isRequired,
    service: PropTypes.shape({
        handleScroll: PropTypes.func.isRequired,
    }).isRequired,
    revenue: PropTypes.string.isRequired,
    volume: PropTypes.string.isRequired,
    grossMargin: PropTypes.string.isRequired,
    currentFY: PropTypes.string.isRequired,
};

const MainBlock = ({id, loginsMo, projects, sessions, /* projectsAdded, */ service, revenue, volume, grossMargin, currentFY, t}) => {
    // const { page, pages } = pagination;
    //
    // const { handleScroll } = service;
    const [users, setUsers] = useState(null)
    const [pagination, setPagination] = useState({
        page: 1,
        pages: 1
    })

    const { handleScroll } = service

    const setUserSession = (res) => {
        if (
            res &&
            res.items &&
            res.items.items &&
            res.items.items.length
        ) {
            setUsers(res.items.items)
            setPagination(res.pagination)
            return
        }

        setUsers(sessions)
    }

    useEffect(() => {
        getUsersSessions(id, 1).then((res) => {
            setUserSession(res)
        })
    }, [])

    const onScroll = (e) => {
        const {page, pages} = pagination

        if (page >= pages) {
            return;
        }


        handleScroll(e).then(() => {
            let currentPage = page + 1;

            getUsersSessions(id, currentPage).then((res) => {
                if (
                    res &&
                    res.items &&
                    res.items.items &&
                    res.items.items.length
                ) {
                    setUsers((prev) => {
                        if (
                            res &&
                            res.items &&
                            res.items.items &&
                            res.items.items.length
                        ) {
                            return [...prev, ...res.items.items]
                        }

                        return [...prev]
                    })
                    setPagination(res.pagination)
                    return
                }

                setUsers(sessions)
            })
        });
    };

    return (
        <>
            <TabWrapTop>
                <TabBlock>
                    <TabBlockCol>
                        <TabBlockColTitle>{t("Logins/Mo")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{numberWithCommas(loginsMo, 0)}</TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{t("Projects")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{numberWithCommas(projects, 0)}</TabBlockColTitleH4>
                    </TabBlockCol>

                    {/* <TabBlockCol>
                        <TabBlockColTitle>{t("Projects created")}</TabBlockColTitle>
                        <TabBlockColTitleH4>{projectsAdded}</TabBlockColTitleH4>
                    </TabBlockCol> */}

                    <TabBlockCol>
                        <TabBlockColTitle>{`FY ${currentFY} ${t("Current Revenue")}`}</TabBlockColTitle>
                        <TabBlockColTitleH4 className="clr-green">
                            {`$${numberWithCommas(revenue, 0)}`}
                        </TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{`FY ${currentFY} ${t("Current Volume")}`}</TabBlockColTitle>
                        <TabBlockColTitleH4 className="clr-red">
                            {`${numberWithCommas(volume, 0)} lbs`}
                        </TabBlockColTitleH4>
                    </TabBlockCol>

                    <TabBlockCol>
                        <TabBlockColTitle>{`FY ${currentFY} ${t("Current Gross Margin")}`}</TabBlockColTitle>
                        <TabBlockColTitleH4 className="clr-primary">{`${grossMargin}%`}</TabBlockColTitleH4>
                    </TabBlockCol>
                </TabBlock>
            </TabWrapTop>
            <TabViewDetail />
            <CheckPermission permission='admin'>
                <TabWrap>
                    <div onScroll={onScroll} className="users-login-info main-wrap-block__body main-wrap-block__body_height">
                        <table className="main-table">
                            <tbody>
                            <tr>
                                {['IP', 'Time', 'Location'].map((column, index) => (
                                    <th key={`SessionTableHeadColumn-${index}`} className="main-table__head">
                                        <span className='main-table__head-link'>{column}</span>
                                    </th>
                                ))}
                            </tr>
                            {users && users.length > 0 ? users.map((session, index) => (
                                <tr key={`SessionTableRow-${index}`}>
                                    <td className="main-table__body">
                                        <div className="main-table__body-row">{session.ip_address}</div>
                                    </td>
                                    <td className="main-table__body">
                                        <div className="main-table__body-row">
                                            {formatDateToLocal(session.date, "MMM DD, YYYY h:mm A")}
                                        </div>
                                    </td>
                                    <td className="main-table__body">
                                        <div className="main-table__body-row">
                                            {session.country ? `${session.country}, ` : ""}
                                            {session.city ? `${session.city}` : ""}
                                        </div>
                                    </td>
                                </tr>
                            )) : null}
                            </tbody>
                        </table>
                    </div>
                </TabWrap>
            </CheckPermission>
        </>
    );
};

MainBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;
    return {
        id: getStoreItem(state, "view.id"),
        loginsMo: getStoreItem(state, "view.additionalData.login_count") || 0,
        projects: getStoreItem(state, "view.additionalData.projects") || 0,
        sessions: getStoreItem(state, "view.sessions") || [],
        // projectsAdded: getStoreItem(state, "view.additionalData.projects_create") || 0,
        revenue: getStoreItem(state, "view.additionalData.revenue") || "0",
        volume: getStoreItem(state, "view.additionalData.volume") || "0",
        grossMargin: getStoreItem(state, "view.additionalData.gross_margin") || "0",
        currentFY: getStoreItem(state, "financialYear"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(MainBlock);
