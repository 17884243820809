import React from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { LinkImg } from "Templates/Link";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import { getString, formatDate, storageUrl } from "Services";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <LinkImg
                    title={getString(item, key)}
                    target="_blank"
                    className="files-img"
                    ico="icon-file"
                    href={storage_url => storageUrl(getString(item, "path"), storage_url)}
                >
                    {getString(item, key)}
                </LinkImg>
            );

        case "modified":
            return (
                <TableRowItemSpan title={formatDate(getString(item, key))}>
                    {formatDate(getString(item, key))}
                </TableRowItemSpan>
            );

        case "modified_by":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "modified_by_id")}`}
                    img={getString(item, "modified_by_photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
