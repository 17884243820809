import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
};

const TableFooterColumn = () => {
    return (
        <>
        </>
    );
};

TableFooterColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableFooterColumn);
