import React from "react";

import {compose} from "redux";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import budgetService from "../Services/Tabs/Budgets";
import {onChangeFinancialYear} from "../Actions/TabActions";

const BudgetsTab = ({
                            children
                        }) => {
    return (
        <ServiceProvider value={budgetService}>
            {children}
        </ServiceProvider>
    );
}

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", budgetService, dispatch),
        fetchFiltersItems: getActionStore("fetchFiltersItems", budgetService, dispatch),
        onChangeFilter: getActionStore("onChange", budgetService, dispatch),
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        onChangeFinancialYear: getActionStore("onChangeFinancialYear", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(BudgetsTab);