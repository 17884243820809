import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { numberWithCommas } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { DivRow, DivColumn } from "Templates/Table/NewVersion";
import { Span } from "Templates/Default";

const propTypes = {
    items: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    items: {},
};

const MonthTableBodyColumn = ({ items, t }) => {
    const monthEnum = {
        jan: "January",
        feb: "February",
        mar: "March",
        apr: "April",
        may: "May",
        jun: "June",
        jul: "July",
        aug: "August",
        sep: "September",
        oct: "October",
        nov: "November",
        dec: "December",
    };

    return (
        <>
            <DivColumn>
                <DivRow />
                {Object.values(monthEnum).map(month => (
                    <DivRow key={`Row-${month}`}>
                        <Span className="gray-text">{t(month)}</Span>
                    </DivRow>
                ))}
                <DivRow>
                    <Span className="bold-text">{t("Total")}</Span>
                </DivRow>
            </DivColumn>
            {Object.keys(items).map(column => (
                <DivColumn key={`Column-${column}`}>
                    <DivRow>
                        <Span className="bold-text">{column}</Span>
                    </DivRow>
                    {Object.keys(monthEnum).map(key => (
                        <DivRow key={`Row-${key}`}>
                            <Span>{numberWithCommas(items[column][key])}</Span>
                        </DivRow>
                    ))}
                    <DivRow>
                        <Span className="bold-text">{numberWithCommas(items[column].total)}</Span>
                    </DivRow>
                </DivColumn>
            ))}
        </>
    );
};

MonthTableBodyColumn.propTypes = propTypes;
MonthTableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        items: getStoreItem(state, "items.monthsQuantity"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(MonthTableBodyColumn);
