import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../../EventsService";

export const listTypes = {
    "0": {
        value: 0,
        label: "Folder",
    },
    "10": {
        value: 10,
        label: "File",
    },
};

export const getTableLabel = key => {
    const tableLabel = {
        name: "File Name",
        modified: "Modified",
        modified_by: "Modified By",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        modified: "Select Date",
        modified_by: "Search",
    };
    switch (key) {
        case "modified":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
