import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import {withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import { url } from "Services";
import InfoWrap from "./Views/InfoWrap";

import _subSegmentsService from "./Services/Tabs/SubSegments";
import SubSegments from "./Views/Tabs/SubSegments";

const ApplicationCode = ({ id, activeTab, t, onChangeTab }) => {
    const onClickEdit = () => {
        url.redirect(`/admin/list/application-codes/${id}/edit`);
    };

    const subSegmentsService = _subSegmentsService(id);

    return (
        <ContentWrap>
            <InfoWrap onClickEdit={onClickEdit} />
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Project Sub-Segments")}>
                        <ServiceProvider value={subSegmentsService}>
                            <SubSegments applicationCode={id} />
                        </ServiceProvider>
                    </Tab>
                    <Tab label={''}/>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

ApplicationCode.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ApplicationCode);
