import ReactOnRails from "react-on-rails";
import React from "react";
import Root from "App/View/Root";
import Index from "./Index";
import * as service from "./Services";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);

    return (
        <div className="product-finder">
            <Root store={store} service={service}>
                <Index />
            </Root>
        </div>
    );
};

export default App;
