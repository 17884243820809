import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const ContainerTable = (TableHeadColumn, TableRowColumn) => ({ showButton, columns, items, service: { link }, t }) => {
    return (
        <form>
            <table className="main-table">
                <tbody>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={`TableHeadColumn-${index}`} className="main-table__head">
                                <TableHeadColumn column={column} />
                            </th>
                        ))}
                    </tr>
                    {items.map((item, itemIndex) => (
                        <tr key={`Row-${itemIndex}`}>
                            {columns.map((column, columnIndex) => (
                                <td key={`Column-${columnIndex}`} className="main-table__body ">
                                    <TableRowColumn column={column} item={item} />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {showButton && (
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="search-btn main-btn main-btn__general main-btn_primary"
                >
                    {t("Show more results")}
                </a>
            )}
        </form>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        showButton: getStoreItem(state, "show-pagination"),
        columns: getStoreItem(state, "columns"),
        items: getStoreItem(state, "items"),
    };
};
export default (TableHeadColumn, TableRowColumn) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps),
    )(ContainerTable(TableHeadColumn, TableRowColumn));
