import _ from "lodash";
import { getStoreItem as _getStoreItem, request, url } from "Services";
import { handleScroll } from "./EventsService";
import { requestTable } from "./RequestService";
import { getFilter } from "./FilterService";
import { getViewItem, getColumns } from "./ViewService";
import { TableActions } from "../Actions";

const tableLabel = {
    name: "Performance",
};

export const getTableLabel = key => _.get(tableLabel, key, key);

export const modifierValues = items => items;

export const getStoreItem = _getStoreItem("listState");

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableActions,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export { getViewItem, getFilter, requestTable, handleScroll, getColumns };
