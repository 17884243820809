import _ from "lodash";
import {uniqueGenerator} from "Services";
import Constants from "../Constants";

const validate = ({ name, description }) => {
    return [name, description].every(el => typeof el === "string" && el.trim());
};

const defaultPlantField = {
    id: '',
    plant: '',
    cost_per_lbs_curr: 0,
};

const defaultPeriodsField = {
    id: "",
    front_id: uniqueGenerator(),
    effective_from_date: "",
    quantity_per_unit: "",
    unit: "",
    scale_quantities: [
        {
            id: "",
            front_id: uniqueGenerator(),
            scale_quantity: "0",
            period_price: "100.00"
        }
    ]
};

export const initialState = {
    name: '',
    description: '',
    iso: '',
    manufacturer: '',
    vendor_code: '',
    distributor: '',
    distributor_vendor_code: '',
    material_type: '',
    lead_time: '',
    standard_packaging: '',
    delivery_terms: '',
    freight_class: '',
    note: '',
    plants: [{ ...defaultPlantField }],
    periods: [{ ...defaultPeriodsField }],
    periods_form: { ...defaultPeriodsField },
    form_loading: false,
    form_validate: false,
    form_errors: {},

    old: {},

    showModal: false
};

export const reducer = (state= initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = { ...state };
            _.set(newState, data.key, data.value);

            return { ..._.cloneDeep(newState), form_validate: validate(newState) };
        }

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };

        case Constants.CHANGE_VALIDITY_PERIOD_FORM: {
            const newState = {
                ...state, periods_form: {
                    ...state.periods_form
            }};
            _.set(newState, data.key, data.value);

            return {...newState};
        }

        case Constants.EDIT_VALIDITY_PERIOD_FORM: {
            return {
                ...state, showModal: true, periods_form: data
            };
        }

        case Constants.OPEN_VALIDITY_PERIOD_MODAL:
            return {
                ...state, showModal: true, periods_form: { ...defaultPeriodsField }
            };

        case Constants.CLOSE_VALIDITY_PERIOD_MODAL:
            return {
                ...state, showModal: false, periods_form: { ...defaultPeriodsField }
            };

        case Constants.UPDATE_VALIDITY_PERIOD_FORM:
            return {
            ...state, periods_form: {
                ...state.periods_form,
                 scale_quantities: data,
            }
        };

        case Constants.CHANGE_PERIODS:
            return {
                ...state, periods: data
            };

        default:
            return state;
    }
};
