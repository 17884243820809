import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
    item: {},
};

const TableBodyColumn = ({item}) => {
    return (
        <tr>
            <td className="project-expanded-block__table">
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Performance Specification</p>
                    <p className="project-expanded-block__table-value" title={item.performance_specification || '-'}>
                        {item.performance_specification || '-'}
                    </p>
                </div>
                <div className="project-expanded-block__table-row">
                    <p className="project-expanded-block__table-title">Material Specification</p>
                    <p className="project-expanded-block__table-value" title={item.material_specification || '-'}>
                        {item.material_specification || '-'}
                    </p>
                </div>
            </td>
        </tr>
    );
};

TableBodyColumn.propTypes = propTypes;

TableBodyColumn.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem, itemsName} = ownProps.service;

    return {
        item: getStoreItem(state, itemsName),
        weightUnit: state.weightUnitState,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumn);
