import Constants from "../../Constants";

export const resetForecastReducerAction = (service, dispatch) => () => {
    dispatch({ type: Constants.forecast.RESET_FORECAST_REDUCER });
};

export const getForecastDataAction = (service, dispatch) => data => {
    dispatch({ type: Constants.forecast.GET_FORECAST_DATA_REQUEST });

    const { requestData } = service;

    return requestData(data).then(
        res => dispatch({ type: Constants.forecast.GET_FORECAST_DATA_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.forecast.GET_FORECAST_DATA_FAILED, data: res });
        },
    );
};

export const changeForecastAction = (service, dispatch) => data => {
    dispatch({ type: Constants.forecast.CHANGE_FORECAST_REQUEST });

    const { requestVehicleBuilds } = service;

    const form = {};
    if (data) {
        form.forecast_id = data;
    }

    return requestVehicleBuilds(form).then(
        res =>
            dispatch({
                type: Constants.forecast.CHANGE_FORECAST_SUCCEEDED,
                data: { res, selectedForecast: data },
            }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.forecast.CHANGE_FORECAST_FAILED, data: res });
        },
    );
};

export const getVehicleBuildsAction = (service, dispatch) => data => {
    dispatch({ type: Constants.forecast.VEHICLE_BUILDS_REQUEST });

    const { requestVehicleBuilds } = service;

    return requestVehicleBuilds(data).then(
        res =>
            dispatch({
                type: Constants.forecast.VEHICLE_BUILDS_SUCCEEDED,
                data: { res, selectedYear: data.year },
            }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.forecast.VEHICLE_BUILDS_FAILED, data: res });
        },
    );
};
