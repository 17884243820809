import React, {useState} from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas , url } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { Title, WrapButtons, Total } from "Templates/Titles";
import { MainButtonLink } from "Templates/Content";
import { ContainerTable } from "Hoc/Widgets";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import ShowPermission from "Hoc/Template/ShowPermission";
import Tab from "Templates/Tabs/Tab";
import Tabs from "Templates/Tabs/Tabs";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import allService from "../Services/Tabs/All";
import archiveService from "../Services/Tabs/Archived";
import {Modal} from "Templates/Modals";
import DownloadIHSModal from "./DownloadIHSModal";

const defaultProps = {
    help: ''
};

const propTypes = {
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
};

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap, { showButtons: false });
const TabViewDetail = customTable(TabTable);

const TabView = ({ t, title, help, activeTab, can_export, onChangeTab, getUrlExport, getOriginalUrlExport, request }) => {
    const [showModal, setShowModal] = useState(false);
    const LATEST_IHS_FORECAST_TAB = 0;

    const handleDownloadIhs = ((data) => {
        const { year, month } = data;
        if (!year || !month) return;
        getOriginalUrlExport(url.getUrl(`/project/vehicles/export/${year}/${month}`, request)).then(
            res => {
                setShowModal(false);
                if (res[0].link) {
                    url.redirect(res[0].link);
                } else {
                    alert('Latest IHS file not found.');
                }
            },
            errors => {
                alert(errors.detail);
            },
        );
    })

    const handleExportClick = (e) => {
        e.preventDefault();
        getUrlExport(url.getUrl("/project/vehicles/export-parsed", request));
    }

    return (
        <ShowPermission checkPermission={["ihv_lvp_access"]}>
            <Title
                title={t("IHS Forecast")}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                <WrapButtons>
                    {can_export && (
                       <>
                           <MainButtonLink
                               className="main-btn_white"
                               onClick={() => setShowModal(true)}
                           >
                               {t("Export Original IHS Forecast")}
                           </MainButtonLink>
                           {showModal ? <Modal onRequestClose={() => setShowModal(false)}>
                               <DownloadIHSModal
                                   t={t}
                                   label={'Export Original IHS Forecast'}
                                   downloadIhs={handleDownloadIhs}
                                   onClose={() => setShowModal(false)}
                               />
                           </Modal> : null}
                       </>
                    )}
                    {activeTab === LATEST_IHS_FORECAST_TAB && (
                        <MainButtonLink
                            className="main-btn_white"
                            onClick={(e) => handleExportClick(e)}
                        >
                            {t("Export IHS Forecast")}
                        </MainButtonLink>
                    )}
                </WrapButtons>
            </Title>
            <Tabs
                activeTab={activeTab}
                onClickTabItem={index => {
                    if (activeTab !== index) {
                        onChangeTab(index);
                    }
                }}
            >
                <Tab label={t("Latest IHS Forecast")}>
                    <ServiceProvider value={allService}>
                        <TabViewDetail />
                    </ServiceProvider>
                </Tab>
                <Tab label={t("Archive - Vehicles Removed")}>
                    <ServiceProvider value={archiveService}>
                        <TabViewDetail />
                    </ServiceProvider>
                </Tab>
            </Tabs>
        </ShowPermission>
    );
};

TabView.defaultProps = defaultProps;
TabView.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        title: getStoreItem(state, "pagination.total"),
        can_export: getStoreItem(state, "can_export"),
        help: getStoreItem(state, "help"),
        activeTab: getStoreItem(state, "activeTab"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        getUrlExport: getActionStore("getUrlExport", service, dispatch),
        getOriginalUrlExport: getActionStore("getOriginalUrlExport", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
