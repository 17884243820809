import React, {useEffect} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import TabCheck from "Templates/Tabs/TabСheck";
import Tab from "Templates/Tabs/Tab";
import Total from "Templates/Titles/Total";
import Title from "Templates/Titles/Title";
import importTemplatesService from "./Services/Tabs/ImportTemplates"
import sapCallsService from "./Services/Tabs/SapAnalytic"
import ImportTemplatesTabView from "./Views/Tabs/ImportTemplates/TabView"
import SapCallsTabView from "./Views/Tabs/SapAnalytic/TabView"
import {numberWithCommas, url} from "Services";

const propTypes = {
    title: PropTypes.number.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    requestItems: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    fetching: PropTypes.bool.isRequired
};

const System = ({title, activeTab, onChangeTab, requestItems, fetching, permissions, t}) => {
    useEffect(() => {
        if(!permissions.includes('admin_system-page_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    const labels = [
        {
            label: "Import Templates",
            button: "Import Templates",
            service: importTemplatesService,
        },
        {
            label: "SAP Calls",
            button: "SAP Calls",
            service: sapCallsService,
        },
    ];

    useEffect(() => {
        if (!permissions.includes('admin_templates-page_crud') && activeTab === 0) {
            activeTab++;
            onChangeTab(activeTab);
        }
        requestItems(activeTab === 1 ? '/admin/sap-analytics' : '/admin/import-templates')
    }, [activeTab])

    return (
        <>
            <Title
                title={t(labels[activeTab].label)}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            />
            <Tabs
                activeTab={activeTab}
                onClickTabItem={index => {
                    if (activeTab !== index) {
                        onChangeTab(index);
                    }
                }}
            >
                <TabCheck label={t("Import Templates")} permission="admin_templates-page_crud">
                    <ServiceProvider value={importTemplatesService}>
                        {!fetching ? <ImportTemplatesTabView /> : null}
                    </ServiceProvider>
                </TabCheck>
                <TabCheck label={t("SAP Calls")} permission="admin_system-page_crud">
                    <ServiceProvider value={sapCallsService}>
                        {!fetching ? <SapCallsTabView /> : null}
                    </ServiceProvider>
                </TabCheck>
            </Tabs>
        </>
    );
}

System.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        title: getStoreItem(state, "pagination.total"),
        fetching: getStoreItem(state, "fetching"),
        activeTab: getStoreItem(state, "activeTab"),
        permissions: state.userState.permissions,
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        requestItems: getActionStore("requestItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(System);
