import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Name",
        date: "Date Submitted"
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        date: "Date Submitted"
    };

    switch (key) {
        case "date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
