import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ModalDefault } from "Templates/Modals";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import { withServiceConsumer } from "Services/Context";

const { Button, Form, Title, ColInput } = ModalDefault;

const defaultProps = {
    data: {
        id: "",
        folderName: "",
        oldFolderName: "",
    },
};

const propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.shape({
        id: PropTypes.string,
        folderName: PropTypes.string,
        oldFolderName: PropTypes.string,
    }),
    onClose: PropTypes.func.isRequired,
    cbAfterRequest: PropTypes.func.isRequired,
    createFolder: PropTypes.func.isRequired,
    editFolder: PropTypes.func.isRequired,
};

class ForlderForm extends Component {
    constructor(props) {
        super(props);
        const {
            data: { id, folderName, oldFolderName },
        } = this.props;
        this.state = {
            id: id || "",
            folderName: folderName || "",
            oldFolderName: oldFolderName || "",
        };
    }

    onChange = value => {
        this.setState({
            folderName: value.substr(0, 255),
        });
    };

    handleCloseModal = event => {
        event.preventDefault();
        const { id, oldFolderName, folderName } = this.state;
        const { t } = this.props;
        if (id && oldFolderName !== folderName) {
            if (window.confirm(t("Are you sure you want to undo the action?"))) {
                const { onClose } = this.props;
                onClose();
            }
        } else {
            const { onClose } = this.props;
            onClose();
        }
    };

    handleClickOnSubmitFolder = () => {
        const { id: folderId, folderName } = this.state;
        if (folderId) {
            const { id, editFolder } = this.props;
            editFolder({ id: folderId, name: folderName, currentFolder: id }).then(res => {
                const { cbAfterRequest } = this.props;
                cbAfterRequest(res);
            });
        } else {
            const { id, createFolder } = this.props;
            createFolder({ id, name: folderName }).then(res => {
                const { cbAfterRequest } = this.props;
                cbAfterRequest(res);
            });
        }
    };

    render() {
        const { id, folderName, oldFolderName } = this.state;
        const { t } = this.props;
        const disabled = Boolean(!folderName) || folderName === oldFolderName;
        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={this.handleCloseModal} />
                <Title>{t(id ? "Edit folder" : "Create folder")}</Title>
                <ColInput
                    placeholder={t("Enter Name")}
                    name="folderName"
                    value={folderName}
                    onChange={value => this.onChange(value, "folderName")}
                />
                <Button onClick={this.handleClickOnSubmitFolder} disabled={disabled}>
                    {t(id ? "Save folder" : "Create folder")}
                </Button>
            </Form>
        );
    }
}

ForlderForm.defaultProps = defaultProps;
ForlderForm.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    id: getStoreItem(state, "view.id"),
});

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        createFolder: getActionStore("createFolderAction", service, dispatch),
        editFolder: getActionStore("editFolderAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ForlderForm);
