import Constants from "../../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};
export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.RESET_ANALYTICS });
};

export const getHeaderDataAction = (service, dispatch) => data => {
    dispatch({ type: Constants.HEADER_DATA_REQUESTED });

    const { requestData } = service;

    return requestData(data).then(
        res => dispatch({ type: Constants.HEADER_DATA_SUCCEEDED, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.HEADER_DATA_FAILED, data: res });
        },
    );
};

export const getChartDataAction = (service, dispatch) => data => {
    dispatch({ type: Constants.CHART_DATA_REQUESTED });

    const { requestChartData } = service;

    const {filter: {time_scale}} = data;
    const timeScale = {
        yearly: 1,
        quarterly: 2,
        monthly: 3,
    };

    return requestChartData(data).then(
        res => {
            const data = {};

            switch (+time_scale) {
                case timeScale.quarterly:
                    for (const key in res.items) {
                        const t = (key).split("/")[0];
                        const y = (key).split("/")[1];
                        data[`Q${t} (FY ${y})`] = res.items[key];
                    }
                    res.data = data;
                    break;

                case timeScale.yearly:
                    for (const key in res.items) {
                        data[`FY ${key}`] = res.items[key];
                    }
                    res.items = data;
                    break;
            }

            dispatch({ type: Constants.CHART_DATA_SUCCEEDED, data: res })
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.CHART_DATA_FAILED, data: res });
        },
    );
};
