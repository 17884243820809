import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import { onChange } from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Product Sources",
        owner: "Suppliers",
        date: "Date Submitted"
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = id => (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        owner: "Search",
        date: "Date Submitted"
    };

    switch (key) {
        case "owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`/product/products/${id}/grades/owner-list`}
                    name="owner"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
