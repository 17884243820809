import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { debounce } from "Services/Debounce";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { WrapInput, Input } from "Templates/Form";

const defaultProps = {
    dependent: false,
    placeholder: "Search",
    label: "",
    showEmptyOption: true,
};

const propTypes = {
    dependent: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    dynamicSelect: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    service: PropTypes.shape({
        getSelectedOption: PropTypes.func.isRequired,
        optionsForSecondSelect: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string,
                url: PropTypes.string,
                urlToOptions: PropTypes.string,
                options: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string.isRequired,
                        value: PropTypes.string.isRequired,
                        urlToOptions: PropTypes.string,
                        customOptions: PropTypes.arrayOf(
                            PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
                        ),
                    }),
                ),
            }),
        ),
        getItems: PropTypes.func.isRequired,
    }).isRequired,
};

class CustomSelect extends Component {
    constructor(props) {
        super(props);
        this.urlToOptions = "";
        this.state = {
            options: [],
            tempOptions: [],
        };
    }

    componentDidMount() {
        const { dependent } = this.props;
        if (!dependent) {
            this.getAndSetAsyncOptions();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            dependent,
            dynamicSelect,
            service: { getSelectedOption, optionsForSecondSelect },
        } = this.props;
        if (dependent && prevProps.dynamicSelect !== dynamicSelect) {
            const selectedValue = getSelectedOption(dynamicSelect, optionsForSecondSelect);

            if (selectedValue.customOptions) {
                this.setState({
                    options: selectedValue.customOptions,
                });
                return;
            }
            this.urlToOptions = selectedValue.urlToOptions;
            this.getAndSetAsyncOptions(this.urlToOptions || dynamicSelect);
        }

        const { items } = this.props;

        if (prevProps.items !== items) {
            this.setState(prevState => ({
                options: prevState.options.map(option => ({
                    ...option,
                    disable: items.some(item => item.value === option.value),
                })),
            }));
        }
    }

    getAndSetAsyncOptions = (url = "") => {
        const {
            service: { getItems },
        } = this.props;
        getItems(url).then(res =>
            this.setState({
                options: res,
            }),
        );
    };

    getNewOptions = value => {
        const { dependent, dynamicSelect } = this.props;
        if ((dependent && Number(dynamicSelect) && value) || (!dependent && value)) {
            const {
                service: { getItems },
            } = this.props;
            getItems(this.urlToOptions, { label: value }).then(res => {
                this.setState({
                    tempOptions: res,
                });
            });
        }
    };

    handleChange = value => {
        const { options, tempOptions } = this.state;
        const { items, onChange } = this.props;
        this.setState({ tempOptions: [] });
        const cb = elem => elem.value === value;
        const option = tempOptions.find(cb) || options.find(cb);
        onChange("form.items", [
            ...items,
            {
                ...option,
                frontId: items.length === 0 ? 0 : items[items.length - 1].frontId + 1,
            },
        ]);
    };

    render() {
        const { tempOptions, options } = this.state;
        const { type, items, label, placeholder, showEmptyOption, t } = this.props;

        return (
            <WrapInput name="Select 3" label={label}>
                <Input
                    disabled={items.length >= 5}
                    type={type}
                    showEmptyOption={showEmptyOption}
                    options={tempOptions.length ? tempOptions : options}
                    placeholder={t(placeholder)}
                    value=""
                    inputProps={{
                        onInputChange: value => debounce(this.getNewOptions, 300, value),
                        isOptionDisabled: option => option.disable,
                    }}
                    onChange={this.handleChange}
                />
            </WrapInput>
        );
    }
}

CustomSelect.defaultProps = defaultProps;
CustomSelect.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        dynamicSelect: getStoreItem(state, "form.dynamicSelect"),
        items: getStoreItem(state, "form.items"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        resetForm: getActionStore("resetForm", service, dispatch),
        onChange: getActionStore("onChange", service, dispatch),
        getData: getActionStore("getDataAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(CustomSelect);
