import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { ContainerTable } from "Hoc/Widgets";
import { Title, Total } from "Templates/Titles";
import TabViewIndex from "Templates/Tabs/DefaultWrap/TabViewIndex";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import { TabWrap } from "Templates/Content";
import TableRowColumnWrap from "./Table/TableRowColumnWrap/TableRowColumn";

const TableStructure = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);

const TabViewDetail = customTable(TableStructure);

const propTypes = {
    title: PropTypes.number.isRequired,
};

const TabView = ({ onClickCustom, t, title }) => {
    return (
        <>
            <Title
                title={t("Brands")}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                {/*<CheckPermission permission="grade_product_crud">*/}
                {/*    <WrapButtons>*/}
                {/*        <MainButtonLink href="/product/products/create" className="main-btn_primary">*/}
                {/*            {t("Add New Brand")}*/}
                {/*        </MainButtonLink>*/}
                {/*    </WrapButtons>*/}
                {/*</CheckPermission>*/}
            </Title>
            <TabWrap>
                <TabViewDetail onClickCustom={onClickCustom} />
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        title: state.listState.pagination.total,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TabView);
