import _ from "lodash";
import Constants from "../Constants";
import {url} from "Services";

export const initState = {
    activeTab: +url.get('activeTab'),
    showModal: false,
    oldValue: {},
    editValue: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return {...state, activeTab: data, filter: {}, sort: {}};
        case Constants.CLOSE_MODAL:
            return {...state, showModal: false};
        case Constants.OPEN_MODAL:
            return {...state, showModal: true};
        case Constants.EDIT_TEAM:
            return {...state, editValue: {...data}};
        case Constants.RESET_FORM:
            return {...state, editValue: {}};
        default:
            return state;
    }
};
