import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { Row } from "Templates/Form";

class ChangePassword extends Component {
    state = {
        newPassword: "",
        confirmNewPassword: "",
    };

    handleChangeString = ({ target }) => {
        this.setState({
            [target.name]: target.value,
        });
    };

    checkForm = () => {
        const { newPassword, confirmNewPassword } = this.state;
        const minPasswordLength = 6;
        return !(
            newPassword.length >= minPasswordLength &&
            confirmNewPassword.length >= minPasswordLength &&
            newPassword === confirmNewPassword
        );
    };

    handleClickOnSubmitForm = e => {
        e.preventDefault();
        const { submitForm } = this.props;
        const { newPassword, confirmNewPassword } = this.state;

        submitForm({ password: newPassword, password_repeat: confirmNewPassword });
    };

    render() {
        const { newPassword, confirmNewPassword } = this.state;
        const { t } = this.props;

        return (
            <>
                <h1 className="forgot__title">{t("Reset Your Password")}</h1>
                <div className="width_1-3">
                    <form action="/" className="forgot__form" onSubmit={this.handleClickOnSubmitForm}>
                        <Row>
                            <label htmlFor="newPassword" className="forgot__form-label">
                                {t("New Password")}
                                <input
                                    required
                                    id="newPassword"
                                    type="password"
                                    name="newPassword"
                                    className="forgot__form-input"
                                    value={newPassword}
                                    onChange={this.handleChangeString}
                                />
                            </label>
                            <span className="forgot__form-note">{t("Minimum 6 characters")}</span>
                        </Row>
                        <Row>
                            <label htmlFor="email" className="forgot__form-label">
                                {t("Confirm New Password")}
                                <input
                                    required
                                    id="confirmNewPassword"
                                    type="password"
                                    name="confirmNewPassword"
                                    className="forgot__form-input"
                                    value={confirmNewPassword}
                                    onChange={this.handleChangeString}
                                />
                            </label>
                        </Row>
                        <Row>
                            <button disabled={this.checkForm()} type="submit" className="forgot__form-submit main-btn">
                                {t("Save")}
                            </button>
                        </Row>
                    </form>
                </div>
            </>
        );
    }
}

ChangePassword.propTypes = {
    submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        // error: state.listState.error,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ChangePassword);
