import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { createState } = initialStates;
    const {canEditCustomerCode} = props;

    const initData = {canEditCustomerCode};

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "add-company" }),
        createState: { ...createState, ...initData, old: _.cloneDeep(createState) },
    };

    return _configureStore(initialState, reducers);
}
