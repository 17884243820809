import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getTableLabel = (key, { currentFY }) => {
    const tableLabel = {
        name: "Brands",
        description: "Description",
        owner: "Suppliers",
        volume: "Volume",
        revenue: "Revenue",
        attachment_name: "File Name",
    };
    switch (key) {
        case "volume":
        case "revenue":
            return `FY ${currentFY} <br> Forecast ${_.get(tableLabel, key, key)} <br> (Current)`;

        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        description: "Search",
        owner: "Search",
        revenue: "Search",
        volume: "Search",
        attachment_name: "Search",
    };
    switch (key) {
        case "revenue":
        case "volume": {
            return ;
        }

        case "owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/products/owner-list"
                    name="owner"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => !(["revenue", "volume"].includes(key));