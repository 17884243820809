import React, {useEffect, useState} from "react";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {diffDate, formatDate, getString} from "Services";
import {companyPhoneTypes} from "Services/enum";
import _ from "lodash";
import Img from "Templates/Img/Img";
import {requestHierarchyChildrenTable} from "./RequestService";
import {Tooltip} from "Templates/Form";
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";

export const modifierHierarchyValues = items => {
    return items
};

/*
 * This component returns toggle Hierarchy button with send api request to get parent children & toggle plus/minus icon
 */
const ToggleChildrenButton = ({ item, onChange }) => {
    return <button
        type="button"
        className={`hierarchy-box__button ${item._active ? 'active' : ''}`}
        onClick={(event) => onChange(event, item)}
    >
        <Img
            img={
                item._active ?
                    'hierarchy_minus_icon'
                    : 'hierarchy_plus_icon'
            }
            width="16"
            height="18"
        />
    </button>
}

/*
 * This component returns Hierarchy children classes depends on Hierarchies list conditions
 */
function generateChildrenClasses(item) {
    return `
        hierarchy-box
        ${item.parent_company_id ? 'with-parent' : ''}
        ${item._lastParent ? 'last-parent' : ''}
        ${item._active ? 'expanded' : ''}
        ${item._last ? 'last' : ''}
        ${item._lastParent && item._level > 1 ? 'last-children' : ''}
    `;
}

/*
 * This function generate verticals lines
 */
function getVerticalLine(item, i) {
    if (i === 0) {
        return 'vertical-line';
    }

    const nextParent = item._parents[i + 1];
    if (item._level - 1 === i || nextParent && !nextParent._last) {
        return 'vertical-line';
    }
    return '';
}

export const getHierarchyViewItem = (key, item, addHierarchy) => {
    const [children, setChildren] = useState([])
    const childrenLevelList = item._level ? new Array(item._level).fill(item.id) : []

    useEffect(() => {
        if (!children.length) return;

        addHierarchy({children})
    }, [children])

    const getChildren = async (id) => {
        await requestHierarchyChildrenTable(id).then((res) => {
            if (!res.items.length) return;
            setChildren(res.items)
        })
    }

    const onChange = (event, childItem) => {
        event.stopPropagation();
        if (childItem._active) {
            delete childItem.children
            delete childItem._active
            return addHierarchy({childItem})
        }

        getChildren(childItem.id)
    }

    switch (key) {
        case "name":
            return (
                <div className={generateChildrenClasses(item)}>
                    {childrenLevelList.length ? childrenLevelList.map((hierarchyLineId, i) => {
                        return <div
                            key={`hierarchy-lines-${hierarchyLineId}-${i}`}
                            className={`
                                hierarchy-lines 
                                ${i === childrenLevelList.length - 1 ? 'hierarchy-lines-last' : ''}
                                ${getVerticalLine(item, i)}
                              `}>
                        </div>

                    }) : null}
                    {item.has_children && <ToggleChildrenButton item={item} onChange={onChange}/>}
                    <TableRowItemLink title={getString(item, key)} href={`/companies/${getString(item, "id")}`}>
                        {getString(item, key)}
                    </TableRowItemLink>
                </div>
            );

        case "email":
            const email = getString(item, key);

            if (!email || email === '-') {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            return (
                <TableRowItemLink title={email} href={`mailto:${email}`}>
                    {email}
                </TableRowItemLink>
            );

        case "project_count":
            return (
                <TableRowItemLink title={`${item.project_count}`} href={`/companies/${getString(item, "id")}?activeTab=1`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "invoice_count":
            return (
                <TableRowItemLink title={`${item.invoice_count}`} href={`/companies/${getString(item, "id")}?activeTab=4`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "nicknames": {
            const value = getString(item, key);

            if (!value || value === '-') {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }
            const arrDate = JSON.parse(getString(item, key));

            return (
                <>
                    {arrDate.map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={elem}>
                            {elem}
                        </TableRowItemSpan>
                    ))}
                </>
            );
        }

        case "nda":
            const value = getString(item, key);
            if (!value) {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }
            const date = diffDate(value, false, "month");

            let dateColor = "";
            switch (true) {
                case date < 3:
                    dateColor = "clr-red";
                    break;

                case date < 6:
                    dateColor = "clr-yellow";
                    break;
            }

            return (
                <TableRowItemSpan title={formatDate(value)} className={dateColor}>
                    {formatDate(value)}
                </TableRowItemSpan>
            );

        case 'notes':
            return (
                item.notes
                    ? <div className="publication-row"><TableRowItemTooltip>{item.notes}</TableRowItemTooltip></div>
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );

        case "marked_deletion":
        case "sap_value":
            return <TableRowItemSpan>{getString(item, key) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case "updated_date":
        case "changed_date":
        case "created_code_date":
        case "created_system_date":
            const dateItem = _.get(item, key) !== null ? formatDate(_.get(item, key)) : '-';

            return (
                <TableRowItemSpan title={dateItem}>{dateItem}</TableRowItemSpan>
            );

        case "phone":
            const phones = item.phone ? JSON.parse(item.phone) : [];

            if (!phones.length) {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            return (
                <div className="hierarchy-phone-col">
                    {`${_.find(companyPhoneTypes, {value: `${phones[0].type}`}).label}: ${phones[0].number}`}
                    <Tooltip>
                        {phones.map((elem, index) => (
                            <TableRowItemSpan title={elem.number} key={`TableRowItemSpan-${elem.number}`}>
                                {`${_.find(companyPhoneTypes, {value: `${elem.type}`}).label}: ${elem.number}`}
                            </TableRowItemSpan>
                        ))}
                    </Tooltip>
                </div>
            );

        default:
            const data = getString(item, key);

            if (!data || data === '-') {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            return <TableRowItemSpan title={data}>{data}</TableRowItemSpan>;
    }
};
