import React from "react";
import _ from "lodash";
import { FormBlockLink } from "Templates/Form";
import { formatDate, getStoreItem as _getStoreItem } from "Services";
import { Span, Ul, Li } from "Templates/Default";
import { formatPercent, formatCost } from "Services/NumberService";

export const getStoreItem = _getStoreItem("editState");
export const itemsName = "sapForm";

export const columnsName = {
    plantName: { label: "Plant" },
    appCodeName: { label: "Application Code" },
    materialType: { label: "Material Type" },
    unit: { label: "Unit of Measure" },
    standardPrice: { label: "Standard Price" },
    currency: { label: "Currency" },
    priceUnit: { label: "Price Unit" },
    lineCode: { label: "Production Line Code" },
    lineName: { label: "Production Line Name" },
    numberRouting: { label: "No. of Routings" },
    numberOperation: { label: "No. of Operations" },
    numberWorkCenter: { label: "Unique Workcenter" },
    submittedBy: { label: "Submitted By" },
    lastDateChanged: { label: "Last Date Changed" },
    materialGroup: { label: "Material Group" },
    priceControl: { label: "Price Control" },
    isObsolete: { label: "Obsolete Marker" },
    markedDeletion: { label: "Deletion Marker" },
    costingLotSize: { label: "Costing Lot Size" },
    costingLotUnit: { label: "Costing Lot Unit (of measurement)" },
    componentScrapInPercent: { label: "Component Scrap In %" },
    fixedCost: { label: "Fixed Cost" },
    variableCost: { label: "Variable Cost" },
    totalCost: { label: "Total Cost" },
    costEstimationItems: { label: "Cost Estimations" },
};

export const getBodyView = (key, item) => {
    const link = href => {
        return <FormBlockLink blank href={href}>{_.get(item, key, "")}</FormBlockLink>
    };

    switch (key) {
        case "plantName": {
            return link(`/product/plants/${_.get(item, "plant")}`);
        }

        case "lastDateChanged": {
            return <Span>{`${formatDate(_.get(item, key))}`}</Span>;
        }

        case "componentScrapInPercent": {
            const value = _.get(item, key, "");
            return <Span>{value ? formatPercent(value / 100) : ""}</Span>;
        }

        case "fixedCost":
        case "totalCost":
        case "variableCost": {
            const value = _.get(item, key, "");
            return <Span>{value ? formatCost(value) : ""}</Span>;
        }

        case "markedDeletion":
        case "isObsolete": {
            const value = _.get(item, key, "");
            return <Span>{value ? 'YES' : 'NO'}</Span>;
        }

        case "costEstimationItems": {
            const value = _.get(item, key, []);
            return (
                <Ul>
                    {value.map(estimation => (
                        <Li key={estimation.id}>
                            Category:&nbsp;
                            {estimation.category}
                            <br />
                            Currency:&nbsp;
                            {estimation.currency}
                            <br />
                            Product Number:&nbsp;
                            {estimation.productNumber}
                            <br />
                            Total Value:&nbsp;
                            {formatCost(estimation.totalValue)}
                            <br />
                            Fixed Value:&nbsp;
                            {formatCost(estimation.fixedValue)}
                            <br />
                            Quantity:&nbsp;
                            {formatCost(estimation.quantity)}&nbsp;
                            {estimation.unit}
                            <br />
                            Validity Start:&nbsp;
                            {_.get(estimation, "validFrom") !== null ? formatDate(_.get(estimation, "validFrom")) : null}
                            <br />
                            Validity End:&nbsp;
                            {_.get(estimation, "validTo") !== null ? formatDate(_.get(estimation, "validTo")) : null}
                            <br />
                            Release Date:&nbsp;
                            {_.get(estimation, "releaseDate") !== null ? formatDate(_.get(estimation, "releaseDate")) : null}
                            <br />
                            <br />
                        </Li>
                    ))}
                </Ul>
            );
        }

        default:
            return <Span>{_.get(item, key, "")}</Span>;
    }
};
