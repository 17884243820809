import {countryList, formatDateToLocal} from "Services";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import Img from "Templates/Img";
import {CheckPermission} from "Hoc/Template";
import React, {Fragment, useState} from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {TooltipHtml} from "Templates/Form";
import {Modal, ModalDefault} from "Templates/Modals";
import {ButtonClose} from "Templates/Button";

const {Form, Title, Button} = ModalDefault;

const HistoryChangesModal = ({ title, oldContent, newContent, onClose }) => {
    return <Form className="changes-history__modal">
        <ButtonClose className="main-modal__form-close" onClick={onClose} />
        <Title>{title}</Title>
        <div className="changes-history__modal-wrap">
            <div className="changes-history__modal-content left" dangerouslySetInnerHTML={ { __html: oldContent } }></div>
            <div className="changes-history__modal-content right" dangerouslySetInnerHTML={ { __html: newContent } }></div>
        </div>
        <Button onClick={onClose}>
            Close
        </Button>
    </Form>
}

export const ViewHistory = ({ listItems, sort, emptyText, onSortClick, handleRollbackHistory }) => {
    const [modal, setModal] = useState({
        open: false,
        title: '',
        oldContent: null,
        newContent: null,
    })

    const closeModal = () => {
        setModal({
            open: false,
            oldContent: null,
            newContent: null,
        })
    }

    const ViewHistoryItem = ({before_value, after_value}) => {
        const oldValue = JSON.parse(before_value);
        const newValue = JSON.parse(after_value);

        const generateStatus = (val) => {
            return val.status === 0 ?
                (
                    <TableRowItemSpan
                        className="clr-red"
                        title="Deleted"
                    >
                        Deleted
                    </TableRowItemSpan>
                ) : (
                    <TableRowItemSpan
                        className="clr-green"
                        title="Added"
                    >
                        Added
                    </TableRowItemSpan>
                );
        }

        const generatePaintable = (val) => {
            switch (val) {
                case 10:
                    return "Yes"
                case 9:
                    return "Yes (Hidden)"
                case 5:
                    return "No"
                case 0:
                    return "N/A"
                default:
                    return "-"
            }
        }

        const generateYesOrNo = (val) => {
            return val === 10 ? 'Yes' : 'No';
        }

        const generateCountry = (currentCountry) => {
            const data = countryList.find((item) => item.value === currentCountry.country)
            return data.label || '-';
        }

        const generateLocations = (location) => {
            switch (location) {
                case 10:
                    return "All"
                case 9:
                    return "Exterior"
                case 8:
                    return "Interior"
                case 7:
                    return "Underhood"
                case 0:
                    return "N/A"
                default:
                    return "-"
            }
        }

        const NoChanges = () => <span className="clr-green">No changes</span>;

        const NewValueColors = () => {

            return newValue.colors.map((color, colorKey) => {
                const approvals = Object.values(color.items);

                return (
                    <span key={`new-country-${colorKey}`} className="color-wrap">
                                            <p className="color-title">
                                                {color.name}
                                                :
                                                {' '}
                                            </p>
                        {(approvals.map((item, itemKey) => (
                                <Fragment key={`${colorKey}_${itemKey}`}>
                                    <p>{item.name}{item.code_name ? ` - ${item.code_name}` : ''}</p>
                                </Fragment>
                            ))
                        )}
                    </span>
                )
            })
        }

        const NewValueCountries = () => {
            return newValue.countries.map((val, index) => {
                return <span key={`new-country-${index}`} className="flex">{generateCountry(val)}</span>
            })
        }

        const NewValueLocations = () => {
            return newValue.locations.map((loc, index) => {
                return <span key={`new-location-${index}`} className="flex">{generateLocations(loc.location)}</span>
            })
        }

        const NewValueKeywords = () => {
            const same = JSON.stringify(oldValue.keywords) === JSON.stringify(newValue.keywords)
            if (same) {
                return <NoChanges/>
            }

            return newValue.keywords.map((keyword, index) => {
                return <span key={`new-keywords-${index}`} className="flex">{keyword.label}</span>
            })
        }

        const AttachmentLink = ({path}) => {
            return path && typeof window !== 'undefined' ? (
                <a href={`${window.location.origin}/storage/${path}`} target="_blank" className="pdf-preview">
                    <Img img="icon_external_link"/>
                </a>
            ) : null
        }

        const ContactItem = ({item}) => {
            return (
                <>
                    <p className="contact-item">Contact</p>
                    <span className="contact-info">
                        {item.contact && item.contact.value && (
                            <span className="color-wrap">
                                <p className="color-title">
                                    Product Contact:
                                </p>
                                <p>{item.contact.value}</p>
                            </span>
                        )}
                        {item.role && item.role.name && (
                            <span className="color-wrap">
                                <p className="color-title">
                                    Contact Role:
                                </p>
                                <p>{item.role.name}</p>
                            </span>
                        )}
                        {item.role_name && (
                            <span className="color-wrap">
                                <p className="color-title">
                                    Additional Information:
                                </p>
                                <p>{item.role_name}</p>
                            </span>
                        )}
                    </span>
                </>
            )
        }

        const NewValueContact = () => {
            return newValue.contacts.length > 0 ? newValue.contacts.map((item, index) => {
                return (
                    <Fragment key={`new-contacts-${index}`}>
                        <ContactItem item={item}/>
                    </Fragment>
                )
            }) : '-'
        }

        const NewContentBoxes = () => {
            return newValue.contentBoxes.map((content, index) => {
                const oldContentBox = {...oldValue.contentBoxes[index], description: !oldValue.contentBoxes[index].description.includes('<p') ? `<p>${oldValue.contentBoxes[index].description.replace(/<br\s*\/?>/g, '')}</p>` : oldValue.contentBoxes[index].description.replace(/<br\s*\/?>/g, '')}
                const newContentBox = {...newValue.contentBoxes[index], description: !newValue.contentBoxes[index].description.includes('<p') ? `<p>${newValue.contentBoxes[index].description.replace(/<br\s*\/?>/g, '')}</p>` : newValue.contentBoxes[index].description.replace(/<br\s*\/?>/g, '')}

                if (JSON.stringify(oldContentBox) === JSON.stringify(newContentBox)) return null

                return (
                    <span key={`new-content-${index}`} className='flex p-4 property-item'>
                            Position: {content.position + 1} {content.description.length > 0 ? '-' : 'Empty'} {content.description.length > 0 && <TooltipHtml>
                        {content.description}
                    </TooltipHtml>}
                        <button className="changes-modal-btn cost-modal__calculator_btn" onClick={() => {
                            setModal({
                                open: true,
                                title: 'Content Boxes History Changes',
                                oldContent: oldValue.contentBoxes && oldValue.contentBoxes[index].description ? oldValue.contentBoxes[index].description : 'Empty',
                                newContent: newValue.contentBoxes && newValue.contentBoxes[index].description ? newValue.contentBoxes[index].description : 'Empty'
                            })
                        }}>Details</button>
                    </span>
                )
            })
        }

        const NewValueSuppliers = () => {
            return newValue.suppliers.map((supplier, index) => {
                return <span key={`new-supplier-${index}`} className="flex">{supplier.name}</span>
            })
        }

        const MaterialProperty = ({material}) => {
            return material && material.path_list && material.path_list.length ? <span className={'flex p-4 property-item'}>
                <p>Table Property -</p>
                <TooltipHtml>
                    {
                        `<span class="contact-info">
                            <span class="color-wrap">
                                <p class="color-title">Property (Visible: ${oldValue.visible.property === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[0] && material.path_list[0].label ? material.path_list[0].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Method (Visible: ${oldValue.visible.testMethod === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[1] && material.path_list[1].label ? material.path_list[1].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Specimen (Visible: ${oldValue.visible.specimen === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[2] && material.path_list[2].label ? material.path_list[2].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Condition (Visible: ${oldValue.visible.testCondition === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[3] && material.path_list[3].label ? material.path_list[3].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (SI):</p>
                                <p>Max: ${material.si_max ? material.si_max : '-'}</p>
                                <p>Min: ${material.si_min ? material.si_min : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">SI Unit (Visible: ${oldValue.visible.unitSi === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[4] && material.path_list[4].label ? material.path_list[4].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (English) (Visible: ${oldValue.visible.unitEnglish === '10' ? 'Yes' : 'No'}):</p>
                                <p>Max: ${material.english_max ? material.english_max : '-'}</p>
                                <p>Min: ${material.english_min ? material.english_min : '-'}</p>
                            </span>
                        </span>`
                    }
                </TooltipHtml>
            </span> : null
        }
        const NewMaterialProperty = ({oldMaterial, material}) => {
            const oldProperty = oldMaterial && oldMaterial.path_list && oldMaterial.path_list[0] && oldMaterial.path_list[0].label ? oldMaterial.path_list[0].label : '-'
            const oldTestMethod = oldMaterial && oldMaterial.path_list && oldMaterial.path_list[1] && oldMaterial.path_list[1].label ? oldMaterial.path_list[1].label : '-'
            const oldSpecimen = oldMaterial && oldMaterial.path_list && oldMaterial.path_list[2] && oldMaterial.path_list[2].label ? oldMaterial.path_list[2].label : '-'
            const oldTestCondition = oldMaterial && oldMaterial.path_list && oldMaterial.path_list[3] && oldMaterial.path_list[3].label ? oldMaterial.path_list[3].label : '-'
            const oldSiMax = oldMaterial && oldMaterial.si_max ? oldMaterial.si_max : '-'
            const oldSiMin = oldMaterial && oldMaterial.si_min ? oldMaterial.si_min : '-'
            const oldSiUnit = oldMaterial && oldMaterial.path_list && oldMaterial.path_list[4] && oldMaterial.path_list[4].label ? oldMaterial.path_list[4].label : '-'
            const oldEnglishMax = oldMaterial && oldMaterial.english_max ? oldMaterial.english_max : '-'
            const oldEnglishMin = oldMaterial && oldMaterial.english_min ? oldMaterial.english_min : '-'

            const newProperty = material && material.path_list && material.path_list[0] && material.path_list[0].label ? material.path_list[0].label : '-'
            const newTestMethod = material && material.path_list && material.path_list[1] && material.path_list[1].label ? material.path_list[1].label : '-'
            const newSpecimen = material && material.path_list && material.path_list[2] && material.path_list[2].label ? material.path_list[2].label : '-'
            const newTestCondition = material && material.path_list && material.path_list[3] && material.path_list[3].label ? material.path_list[3].label : '-'
            const newSiMax = material && material.si_max ? material.si_max : '-'
            const newSiMin = material && material.si_min ? material.si_min : '-'
            const newSiUnit = material && material.path_list && material.path_list[4] && material.path_list[4].label ? material.path_list[4].label : '-'
            const newEnglishMax = material && material.english_max ? material.english_max : '-'
            const newEnglishMin = material && material.english_min ? material.english_min : '-'

            return material && material.path_list && material.path_list.length ? <span className={'flex p-4'}>
                <p>Table Property -</p>
                <TooltipHtml>
                    {
                        `<span class="contact-info">
                            <span class="color-wrap">
                                <p class="color-title">Property (Visible: ${newValue.visible.property === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[0] && material.path_list[0].label ? material.path_list[0].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Method (Visible: ${newValue.visible.testMethod === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[1] && material.path_list[1].label ? material.path_list[1].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Specimen (Visible: ${newValue.visible.specimen === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[2] && material.path_list[2].label ? material.path_list[2].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Condition (Visible: ${newValue.visible.testCondition === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[3] && material.path_list[3].label ? material.path_list[3].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (SI):</p>
                                <p>Max: ${material.si_max ? material.si_max : '-'}</p>
                                <p>Min: ${material.si_min ? material.si_min : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">SI Unit (Visible: ${newValue.visible.unitSi === '10' ? 'Yes' : 'No'}):</p>
                                <p>${material.path_list[4] && material.path_list[4].label ? material.path_list[4].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (English) (Visible: ${newValue.visible.unitEnglish === '10' ? 'Yes' : 'No'}):</p>
                                <p>Max: ${material.english_max ? material.english_max : '-'}</p>
                                <p>Min: ${material.english_min ? material.english_min : '-'}</p>
                            </span>
                        </span>`
                    }
                </TooltipHtml>
                <button className="changes-modal-btn cost-modal__calculator_btn" onClick={() => {
                    setModal({
                        open: true,
                        title: 'Material Properties History Changes',
                        oldContent: `<span class="contact-info">
                            <span class="color-wrap">
                                <p class="color-title">Property (Visible: ${oldValue.visible.property === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldProperty}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Method (Visible: ${oldValue.visible.testMethod === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldTestMethod}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Specimen (Visible: ${oldValue.visible.specimen === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldSpecimen}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Condition (Visible: ${oldValue.visible.testCondition === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldTestCondition}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (SI):</p>
                                <span class="flex-col">
                                    <p>Max: ${oldSiMax}</p>
                                    <p>Min: ${oldSiMin}</p>
                                </span>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">SI Unit (Visible: ${oldValue.visible.unitSi === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldSiUnit}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (English) (Visible: ${oldValue.visible.unitEnglish === '10' ? 'Yes' : 'No'}):</p>
                                <span class="flex-col">
                                    <p>Max: ${oldEnglishMax}</p>
                                    <p>Min: ${oldEnglishMin}</p>
                                </span>
                            </span>
                        </span>`,
                        newContent: `<span class="contact-info">
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.property !== newValue.visible.property ? 'highlight-span' : ''}">Property (Visible: ${newValue.visible.property === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldProperty !== newProperty ? 'highlight' : ''}">${newProperty}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.testMethod !== newValue.visible.testMethod ? 'highlight-span' : ''}">Test Method (Visible: ${newValue.visible.testMethod === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldTestMethod !== newTestMethod ? 'highlight' : ''}">${newTestMethod}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.specimen !== newValue.visible.specimen ? 'highlight-span' : ''}">Specimen (Visible: ${newValue.visible.specimen === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldSpecimen !== newSpecimen ? 'highlight' : ''}">${newSpecimen}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.testCondition !== newValue.visible.testCondition ? 'highlight-span' : ''}">Test Condition (Visible: ${newValue.visible.testCondition === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldTestCondition !== newTestCondition ? 'highlight' : ''}">${newTestCondition}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (SI):</p>
                                <span class="flex-col">
                                    <p class="${oldSiMax !== newSiMax ? 'highlight' : ''}">Max: ${newSiMax}</p>
                                    <p class="${oldSiMin !== newSiMin ? 'highlight' : ''}">Min: ${newSiMin}</p>
                                </span>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.unitSi !== newValue.visible.unitSi ? 'highlight-span' : ''}">SI Unit (Visible: ${newValue.visible.unitSi === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldSiUnit !== newSiUnit ? 'highlight' : ''}">${newSiUnit}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.unitEnglish !== newValue.visible.unitEnglish ? 'highlight-span' : ''}">Value (English) (Visible: ${newValue.visible.unitEnglish === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <span class="flex-col">
                                    <p class="${oldEnglishMax !== newEnglishMax ? 'highlight' : ''}">Max: ${newEnglishMax}</p>
                                    <p class="${oldEnglishMin !== newEnglishMin ? 'highlight' : ''}">Min: ${newEnglishMin}</p>
                                </span>
                            </span>
                        </span>`
                    })
                }}>Details</button>
            </span> : null
        }
        const NewValueMaterialProperty = () => {
            const oldMaterial = oldValue.materialProperties.map((item) => {
                delete item.path

                return item
            })

            const newMaterial = newValue.materialProperties.map((item) => {
                delete item.path

                return item
            })

            oldMaterial.forEach((obj, inx) => {
                if (oldMaterial.length === newMaterial.length) return
                if (!newMaterial.find((newObj) => newObj.id === obj.id)) {
                    newMaterial.splice(inx, 0, {});
                }
            })

            return newMaterial.length > 0 ? newMaterial.map((material, index) => {
                if (JSON.stringify(oldMaterial[index]) === JSON.stringify(newMaterial[index])) return <span key={`material-${index}-new-empty`} className={'flex p-4'} style={{minHeight: '22px'}}>-</span>

                return (
                    <Fragment key={`new-material-${index}`}>
                        {!newMaterial[index].path_list
                            ? <span key={`material-${index}-empty`} className={'flex p-4'} style={{minHeight: '22px'}}>-</span>
                            : <NewMaterialProperty oldMaterial={oldMaterial[index]} material={material}/>}
                    </Fragment>
                )
            }) : ''
        }

        const ProcessProperty = ({process}) => {
            return process && process.path_list && process.path_list.length ? <span className={'flex p-4 property-item'}>
                <p>Table Property -</p>
                <TooltipHtml>
                    {
                        `<span class="contact-info">
                            <span class="color-wrap">
                                <p class="color-title">Property (Visible: ${oldValue.visible.property === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[0] && process.path_list[0].label ? process.path_list[0].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Method (Visible: ${oldValue.visible.testMethod === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[1] && process.path_list[1].label ? process.path_list[1].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Specimen (Visible: ${oldValue.visible.specimen === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[2] && process.path_list[2].label ? process.path_list[2].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Condition (Visible: ${oldValue.visible.testCondition === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[3] && process.path_list[3].label ? process.path_list[3].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (SI):</p>
                                <p>Max: ${process.si_max ? process.si_max : '-'}</p>
                                <p>Min: ${process.si_min ? process.si_min : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">SI Unit (Visible: ${oldValue.visible.unitSi === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[4] && process.path_list[4].label ? process.path_list[4].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (English) (Visible: ${oldValue.visible.unitEnglish === '10' ? 'Yes' : 'No'}):</p>
                                <p>Max: ${process.english_max ? process.english_max : '-'}</p>
                                <p>Min: ${process.english_min ? process.english_min : '-'}</p>
                            </span>
                        </span>`
                    }
                </TooltipHtml>
            </span> : null
        }
        const NewProcessProperty = ({oldProcess, process}) => {
            const oldProperty = oldProcess && oldProcess.path_list && oldProcess.path_list[0] && oldProcess.path_list[0].label ? oldProcess.path_list[0].label : '-'
            const oldTestMethod = oldProcess && oldProcess.path_list && oldProcess.path_list[1] && oldProcess.path_list[1].label ? oldProcess.path_list[1].label : '-'
            const oldSpecimen = oldProcess && oldProcess.path_list && oldProcess.path_list[2] && oldProcess.path_list[2].label ? oldProcess.path_list[2].label : '-'
            const oldTestCondition = oldProcess && oldProcess.path_list && oldProcess.path_list[3] && oldProcess.path_list[3].label ? oldProcess.path_list[3].label : '-'
            const oldSiMax = oldProcess && oldProcess.si_max ? oldProcess.si_max : '-'
            const oldSiMin = oldProcess && oldProcess.si_min ? oldProcess.si_min : '-'
            const oldSiUnit = oldProcess && oldProcess.path_list && oldProcess.path_list[4] && oldProcess.path_list[4].label ? oldProcess.path_list[4].label : '-'
            const oldEnglishMax = oldProcess && oldProcess.english_max ? oldProcess.english_max : '-'
            const oldEnglishMin = oldProcess && oldProcess.english_min ? oldProcess.english_min : '-'

            const newProperty = process && process.path_list && process.path_list[0] && process.path_list[0].label ? process.path_list[0].label : '-'
            const newTestMethod = process && process.path_list && process.path_list[1] && process.path_list[1].label ? process.path_list[1].label : '-'
            const newSpecimen = process && process.path_list && process.path_list[2] && process.path_list[2].label ? process.path_list[2].label : '-'
            const newTestCondition = process && process.path_list && process.path_list[3] && process.path_list[3].label ? process.path_list[3].label : '-'
            const newSiMax = process && process.si_max ? process.si_max : '-'
            const newSiMin = process && process.si_min ? process.si_min : '-'
            const newSiUnit = process && process.path_list && process.path_list[4] && process.path_list[4].label ? process.path_list[4].label : '-'
            const newEnglishMax = process && process.english_max ? process.english_max : '-'
            const newEnglishMin = process && process.english_min ? process.english_min : '-'

            return process && process.path_list && process.path_list.length ? <span className={'flex p-4'}>
                <p>Table Property -</p>
                <TooltipHtml>
                    {
                        `<span class="contact-info">
                            <span class="color-wrap">
                                <p class="color-title">Property (Visible: ${newValue.visible.property === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[0] && process.path_list[0].label ? process.path_list[0].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Method (Visible: ${newValue.visible.testMethod === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[1] && process.path_list[1].label ? process.path_list[1].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Specimen (Visible: ${newValue.visible.specimen === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[2] && process.path_list[2].label ? process.path_list[2].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Condition (Visible: ${newValue.visible.testCondition === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[3] && process.path_list[3].label ? process.path_list[3].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (SI):</p>
                                <p>Max: ${process.si_max ? process.si_max : '-'}</p>
                                <p>Min: ${process.si_min ? process.si_min : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">SI Unit (Visible: ${newValue.visible.unitSi === '10' ? 'Yes' : 'No'}):</p>
                                <p>${process.path_list[4] && process.path_list[4].label ? process.path_list[4].label : '-'}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (English) (Visible: ${newValue.visible.unitEnglish === '10' ? 'Yes' : 'No'}):</p>
                                <p>Max: ${process.english_max ? process.english_max : '-'}</p>
                                <p>Min: ${process.english_min ? process.english_min : '-'}</p>
                            </span>
                        </span>`
                    }
                </TooltipHtml>
                <button className="changes-modal-btn cost-modal__calculator_btn" onClick={() => {
                    setModal({
                        open: true,
                        title: 'process Properties History Changes',
                        oldContent: `<span class="contact-info">
                            <span class="color-wrap">
                                <p class="color-title">Property (Visible: ${oldValue.visible.property === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldProperty}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Method (Visible: ${oldValue.visible.testMethod === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldTestMethod}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Specimen (Visible: ${oldValue.visible.specimen === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldSpecimen}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Test Condition (Visible: ${oldValue.visible.testCondition === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldTestCondition}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (SI):</p>
                                <span class="flex-col">
                                    <p>Max: ${oldSiMax}</p>
                                    <p>Min: ${oldSiMin}</p>
                                </span>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">SI Unit (Visible: ${oldValue.visible.unitSi === '10' ? 'Yes' : 'No'}):</p>
                                <p>${oldSiUnit}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (English) (Visible: ${oldValue.visible.unitEnglish === '10' ? 'Yes' : 'No'}):</p>
                                <span class="flex-col">
                                    <p>Max: ${oldEnglishMax}</p>
                                    <p>Min: ${oldEnglishMin}</p>
                                </span>
                            </span>
                        </span>`,
                        newContent: `<span class="contact-info">
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.property !== newValue.visible.property ? 'highlight-span' : ''}">Property (Visible: ${newValue.visible.property === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldProperty !== newProperty ? 'highlight' : ''}">${newProperty}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.testMethod !== newValue.visible.testMethod ? 'highlight-span' : ''}">Test Method (Visible: ${newValue.visible.testMethod === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldTestMethod !== newTestMethod ? 'highlight' : ''}">${newTestMethod}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.specimen !== newValue.visible.specimen ? 'highlight-span' : ''}">Specimen (Visible: ${newValue.visible.specimen === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldSpecimen !== newSpecimen ? 'highlight' : ''}">${newSpecimen}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.testCondition !== newValue.visible.testCondition ? 'highlight-span' : ''}">Test Condition (Visible: ${newValue.visible.testCondition === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldTestCondition !== newTestCondition ? 'highlight' : ''}">${newTestCondition}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title">Value (SI):</p>
                                <span class="flex-col">
                                    <p class="${oldSiMax !== newSiMax ? 'highlight' : ''}">Max: ${newSiMax}</p>
                                    <p class="${oldSiMin !== newSiMin ? 'highlight' : ''}">Min: ${newSiMin}</p>
                                </span>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.unitSi !== newValue.visible.unitSi ? 'highlight-span' : ''}">SI Unit (Visible: ${newValue.visible.unitSi === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <p class="${oldSiUnit !== newSiUnit ? 'highlight' : ''}">${newSiUnit}</p>
                            </span>
                            <span class="color-wrap">
                                <p class="color-title ${oldValue.visible.unitEnglish !== newValue.visible.unitEnglish ? 'highlight-span' : ''}">Value (English) (Visible: ${newValue.visible.unitEnglish === '10' ? `<span>Yes</span>` : `<span>No</span>`}):</p>
                                <span class="flex-col">
                                    <p class="${oldEnglishMax !== newEnglishMax ? 'highlight' : ''}">Max: ${newEnglishMax}</p>
                                    <p class="${oldEnglishMin !== newEnglishMin ? 'highlight' : ''}">Min: ${newEnglishMin}</p>
                                </span>
                            </span>
                        </span>`
                    })
                }}>Details</button>
            </span> : null
        }
        const NewValueProcessProperty = () => {
            const oldProcess = oldValue.processProperties.map((item) => {
                delete item.path

                return item
            })

            const newProcess = newValue.processProperties.map((item) => {
                delete item.path

                return item
            })

            oldProcess.forEach((obj, inx) => {
                if (oldProcess.length === newProcess.length) return
                if (!newProcess.find((newObj) => newObj.id === obj.id)) {
                    newProcess.splice(inx, 0, {});
                }
            })

            return newProcess.length > 0 ? newProcess.map((process, index) => {
                if (JSON.stringify(oldProcess[index]) === JSON.stringify(newProcess[index])) return <span key={`process-${index}-new-empty`} className={'flex p-4'} style={{minHeight: '22px'}}>-</span>

                return (
                    <Fragment key={`new-process-${index}`}>
                        {!newProcess[index].path_list
                            ? <span key={`material-${index}-empty`} className={'flex p-4'} style={{minHeight: '22px'}}>-</span>
                            : <NewProcessProperty oldProcess={oldProcess[index]} process={process}/>}
                    </Fragment>
                )
            }) : ''
        }

        return Object.keys(oldValue).map((col, i) => {
            switch (col) {
                case 'name':
                    if (`${oldValue.file_name}${oldValue.name}` === `${newValue.file_name}${newValue.name}`) return null

                    return (
                        <Fragment key={`name-${i}`}>
                            <span className="main-table__body-row">Name</span>
                            <span className="main-table__body-row">
                                {oldValue.file_name ? `${oldValue.file_name} - ${oldValue.name}` : oldValue.name}
                            </span>
                            <span className="main-table__body-row">
                              <span className="flex file-wrap">
                                  <span className="file-name" title={newValue.file_name ? `${newValue.file_name} - ${newValue.name}` : newValue.name}>{newValue.file_name ? `${newValue.file_name} - ${newValue.name}` : newValue.name}</span>
                              </span>
                            </span>
                        </Fragment>
                    )
                case 'paintable':
                    if (oldValue.paintable === newValue.paintable) return null

                    return (
                        <Fragment key={`paintable-${i}`}>
                            <span className="main-table__body-row">Paintable</span>
                            <span className="main-table__body-row">{generatePaintable(oldValue.paintable)}</span>
                            <span className="main-table__body-row">
                                {generatePaintable(newValue.paintable)}
                            </span>
                        </Fragment>
                    )
                case 'molded':
                    if (JSON.stringify(oldValue.molded) === JSON.stringify(newValue.molded)) return null
                    return (
                        <Fragment key={`molded-${i}`}>
                            <span className="main-table__body-row">Mold-in-Color</span>
                            <span className="main-table__body-row column">
                                <span>{oldValue.molded.yes === 10 && oldValue.molded.yes_desc ? oldValue.molded.yes_desc : '-'}</span>
                                <span>{oldValue.molded.no === 10 && oldValue.molded.no_desc ? oldValue.molded.no_desc : '-'}</span>
                                <span>{oldValue.molded.can_be_matched === 10 && oldValue.molded.can_be_matched_desc ? oldValue.molded.can_be_matched_desc : '-'}</span>
                            </span>
                            <span className="main-table__body-row">
                                <span>{newValue.molded.yes === 10 && newValue.molded.yes_desc ? newValue.molded.yes_desc : '-'}</span>
                                <span>{newValue.molded.no === 10 && newValue.molded.no_desc ? newValue.molded.no_desc : '-'}</span>
                                <span>{newValue.molded.can_be_matched === 10 && newValue.molded.can_be_matched_desc ? newValue.molded.can_be_matched_desc : '-'}</span>
                            </span>
                        </Fragment>
                    )
                case 'keywords':
                    if (JSON.stringify(oldValue.keywords) === JSON.stringify(newValue.keywords)) return null
                    return (
                        <Fragment key={`countries-${i}`}>
                            <span className="main-table__body-row">Keywords</span>
                            <span className="main-table__body-row">
                                {oldValue.keywords.length > 0 ? oldValue.keywords.map((keyword, index) => {
                                    return <span key={`old-country-${index}`} className="flex">{keyword.label}</span>
                                }) : '-'}
                            </span>
                            <span className="main-table__body-row">
                                {newValue.keywords.length > 0 ? <NewValueKeywords/> : '-'}
                            </span>
                        </Fragment>
                    )
                case 'locations':
                    if (JSON.stringify(oldValue.locations) === JSON.stringify(newValue.locations)) return null
                    return (
                        <Fragment key={`locations-${i}`}>
                            <span className="main-table__body-row">Vehicle Location</span>
                            <span className="main-table__body-row">
                                {
                                    oldValue.locations.length > 0 ? oldValue.locations.map((loc, index) => {
                                        return (
                                            <span
                                                key={`old-location-${index}`}
                                                className="flex"
                                            >
                                                {generateLocations(loc.location)}
                                            </span>
                                        )
                                    }) : '-'
                                }
                            </span>
                            <span className="main-table__body-row">
                                {newValue.locations.length > 0 ? <NewValueLocations/> : '-'}
                            </span>
                        </Fragment>
                    )
                case 'colors':
                    const sorted = (array) => {
                        return array.sort((a, b) => {
                            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                            const nameB = b.name.toUpperCase(); // ignore upper and lowercase

                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        });
                    }

                    const sortedOld = oldValue.colors.map((el) => {
                        return sorted(Object.values(el.items))
                    });

                    const sortedNew = newValue.colors.map((el) => {
                        return sorted(Object.values(el.items))
                    });

                    if (JSON.stringify(sortedOld) === JSON.stringify(sortedNew)) return null

                    return (
                        <Fragment key={`colors-${i}`}>
                            <span className="main-table__body-row" style={{paddingRight: '3px'}}>Color - OEM Approvals - Color Code</span>
                            <span className="main-table__body-row">
                                {
                                    oldValue.colors.length > 0 ? oldValue.colors.map((color, colorKey) => {
                                        const approvals = Object.values(color.items);

                                        return (
                                            <span key={`old-country-${colorKey}`} className="color-wrap">
                                                <p className="color-title">
                                                    {color.name}
                                                    :
                                                    {' '}
                                                </p>
                                                {(approvals.map((item, itemKey) => (
                                                        <Fragment key={`${colorKey}_${itemKey}`}>
                                                            <p>{item.name}{item.code_name ? ` - ${item.code_name}` : ''}</p>
                                                        </Fragment>
                                                    ))
                                                )}
                                            </span>
                                        )
                                    }) : '-'
                                }
                            </span>
                            <span className="main-table__body-row">
                            {
                                newValue.colors.length > 0 ? <NewValueColors/> : '-'
                            }
                            </span>
                        </Fragment>
                    )
                case 'countries':
                    if (JSON.stringify(oldValue.countries) === JSON.stringify(newValue.countries)) return null
                    return (
                        <Fragment key={`countries-${i}`}>
                            <span className="main-table__body-row">Countries</span>
                            <span className="main-table__body-row">
                                {oldValue.countries.length > 0 ? oldValue.countries.map((country, index) => {
                                    return (
                                        <span
                                            key={`old-country-${index}`}
                                            className="flex"
                                        >
                                            {generateCountry(country)}
                                        </span>
                                    )
                                }) : '-'}
                            </span>
                            <span className="main-table__body-row">
                                {newValue.countries.length > 0 ? <NewValueCountries/> : '-'}
                            </span>
                        </Fragment>
                    )
                case 'contacts':
                    if (JSON.stringify(oldValue.contacts) === JSON.stringify(newValue.contacts)) return null

                    return (
                        <Fragment key={`contacts-${i}`}>
                            <span className="main-table__body-row">Contacts</span>
                            <span className="main-table__body-row">
                                {oldValue.contacts.length > 0 ? oldValue.contacts.map((item, index) => {
                                    return (
                                        <Fragment key={`old-contacts-${index}`}>
                                            <ContactItem item={item}/>
                                        </Fragment>
                                    )
                                }) : '-'}
                            </span>
                            <span className="main-table__body-row">
                                <NewValueContact/>
                            </span>
                        </Fragment>
                    )
                case 'status':
                    if (oldValue.status === newValue.status) return null

                    return (
                        <Fragment key={`status-${i}`}>
                            <span className="main-table__body-row">Status</span>
                            <span className="main-table__body-row">
                                {generateStatus(oldValue.status)}
                            </span>
                            <span className="main-table__body-row">
                                {generateStatus(newValue.status)}
                            </span>
                        </Fragment>
                    )
                case 'suppliers':
                    if (JSON.stringify(oldValue.suppliers) === JSON.stringify(newValue.suppliers)) return null

                    return (
                        <Fragment key={`suppliers-${i}`}>
                            <span className="main-table__body-row">Suppliers</span>
                            <span className="main-table__body-row">
                                {oldValue.suppliers.map((supplier, index) => {
                                    return <span key={`old-supplier-${index}`} className="flex">{supplier.name}</span>
                                })}
                            </span>
                            <span className="main-table__body-row">
                                <NewValueSuppliers/>
                            </span>
                        </Fragment>
                    )
                case 'contentBoxes':
                    const oldContentBoxes = JSON.stringify(oldValue.contentBoxes.map((contentItem) => {
                        return {...contentItem, description: !contentItem.description.includes('<p') ? `<p>${contentItem.description}</p>` : contentItem.description}
                    })).replace(/<br\s*\/?>/g, '');

                    const newContentBoxes = JSON.stringify(newValue.contentBoxes.map((contentItem) => {
                        return {...contentItem, description: !contentItem.description.includes('<p') ? `<p>${contentItem.description}</p>` : contentItem.description}
                    })).replace(/<br\s*\/?>/g, '');

                    if (oldContentBoxes === newContentBoxes) return null

                    return (
                        <Fragment key={`contentBoxes-${i}`}>
                            <span className="main-table__body-row"><span className={'p-4'}>Content Boxes</span></span>
                            <span className="main-table__body-row">
                                {oldValue.contentBoxes.length > 0 ? oldValue.contentBoxes.map((content, index) => {
                                    const oldContentBox = {...oldValue.contentBoxes[index], description: !oldValue.contentBoxes[index].description.includes('<p') ? `<p>${oldValue.contentBoxes[index].description.replace(/<br\s*\/?>/g, '')}</p>` : oldValue.contentBoxes[index].description.replace(/<br\s*\/?>/g, '')}
                                    const newContentBox = {...newValue.contentBoxes[index], description: !newValue.contentBoxes[index].description.includes('<p') ? `<p>${newValue.contentBoxes[index].description.replace(/<br\s*\/?>/g, '')}</p>` : newValue.contentBoxes[index].description.replace(/<br\s*\/?>/g, '')}

                                    if (JSON.stringify(oldContentBox) === JSON.stringify(newContentBox)) return null

                                    return (
                                        <span key={`content-${index}`} className='flex p-4 property-item'>
                                            Position: {content.position + 1} {content.description.length > 0 ? '-' : 'Empty'} {content.description.length > 0 && <TooltipHtml>
                                            {content.description}
                                        </TooltipHtml>}
                                        </span>
                                    )
                                }) : ''}
                            </span>
                            <span className="main-table__body-row">
                                {
                                    newValue.contentBoxes.length > 0 ? <NewContentBoxes/> : '-'
                                }
                            </span>
                        </Fragment>
                    )
                case 'materialProperties':

                    const oldMaterial = oldValue.materialProperties.map((item) => {
                        delete item.path

                        return item
                    })

                    const newMaterial = newValue.materialProperties.map((item) => {
                        delete item.path

                        return item
                    })

                    const oldMaterialProperties = oldMaterial.map(({ id, ...rest }) => {
                        return {...rest, visible: oldValue.visible}
                    });
                    const newMaterialProperties = newMaterial.map(({ id, ...rest }) => {
                        return {...rest, visible: newValue.visible}
                    });

                    oldMaterial.forEach((obj, inx) => {
                        if (oldMaterial.length === newMaterial.length) return
                        if (!newMaterial.find((newObj) => newObj.id === obj.id)) {
                            newMaterial.splice(inx, 0, {});
                        }
                    })

                    if (JSON.stringify(oldMaterialProperties) === JSON.stringify(newMaterialProperties)) return null

                    return (
                        <Fragment key={`materialProperties-${i}`}>
                            <span className="main-table__body-row"><span className={'p-4'}>Material Properties</span></span>
                            <span className="main-table__body-row">
                                {oldMaterial.length > 0 ? oldMaterial.map((material, index) => {
                                    if (JSON.stringify(oldMaterial[index]) === JSON.stringify(newMaterial[index])) return <span key={`material-${index}-empty`} className={'flex p-4'} style={{minHeight: '22px'}}>-</span>
                                    return (
                                        <Fragment key={`material-${index}`}>
                                            <MaterialProperty material={material}/>
                                        </Fragment>
                                    )
                                }) : ''}
                            </span>
                            <span className="main-table__body-row">
                                <NewValueMaterialProperty/>
                            </span>
                        </Fragment>
                    )
                case 'processProperties':
                    const oldProcess = oldValue.processProperties.map((item) => {
                        delete item.path

                        return item
                    })

                    const newProcess = newValue.processProperties.map((item) => {
                        delete item.path

                        return item
                    })

                    const oldProcessProperties = oldProcess.map(({ id, ...rest }) => {
                        return {...rest, visible: oldValue.visible}
                    });
                    const newProcessProperties = newProcess.map(({ id, ...rest }) => {
                        return {...rest, visible: newValue.visible}
                    });

                    oldProcess.forEach((obj, inx) => {
                        if (oldProcess.length === newProcess.length) return
                        if (!newProcess.find((newObj) => newObj.id === obj.id)) {
                            newProcess.splice(inx, 0, {});
                        }
                    })

                    if (JSON.stringify(oldProcessProperties) === JSON.stringify(newProcessProperties)) return null

                    return (
                        <Fragment key={`processProperties-${i}`}>
                            <span className="main-table__body-row"><span className={'p-4'}>Process Properties</span></span>
                            <span className="main-table__body-row">
                                {oldProcess.length > 0 ? oldProcess.map((process, index) => {
                                    if (JSON.stringify(oldProcess[index]) === JSON.stringify(newProcess[index])) return <span key={`process-${index}-empty`} className={'flex p-4'} style={{minHeight: '22px'}}>-</span>
                                    return (
                                        <Fragment key={`process-${index}`}>
                                            <ProcessProperty process={process}/>
                                        </Fragment>
                                    )
                                }) : ''}
                            </span>
                            <span className="main-table__body-row">
                                <NewValueProcessProperty/>
                            </span>
                        </Fragment>
                    )
                case 'legal_name':
                    if (oldValue.legal_name === newValue.legal_name) return null
                    return (
                        <Fragment key={`legal_name-${i}`}>
                            <span className="main-table__body-row">Legal</span>
                            <span className="main-table__body-row">{oldValue.legal_name ? oldValue.legal_name : '-'}</span>
                            <span className="main-table__body-row">
                                {newValue.legal_name}
                            </span>
                        </Fragment>
                    )
                default:
                    return <Fragment key={i}/>;
            }
        })
    }

    return <>{listItems && listItems.length > 0 ?
            <div className="main-modal__history">
            <Title>History</Title>
            <div className="main-wrap-block__body main-wrap-block__body_height">
                <table className="main-table">
                    <tbody>
                    <tr>
                        <th className="main-table__head main-table__head-date">
                            <span className="main-table__head-link">Date</span>
                            <a
                                href="#"
                                className={`main-table__head-link main-is-enabled`}
                                onClick={e => {
                                    e.preventDefault();
                                    return onSortClick('created_at');
                                }}
                            >
                                <span className={`main-table__head-link-icons`}>
                                    <i
                                        className={`fas fa-sort-up main-table__head-link-icon 
                                    ${sort.direction === "asc" ? "icon-active" : ""}`}
                                    />
                                    <i
                                        className={`fas fa-sort-down main-table__head-link-icon 
                                    ${sort.direction === "desc" ? "icon-active" : ""}`}
                                    />
                                </span>
                            </a>
                        </th>
                        <th className="main-table__head main-table__head-user">
                            <span className="main-table__head-link">Editor</span>
                        </th>
                        <th className="main-table__head">
                            <div className="publication-history__wrapper">
                                <div className="publications-history-row__head">
                                    <span className="main-table__head-link">Field</span>
                                    <span className="main-table__head-link">Original Value</span>
                                    <span className="main-table__head-link">New Value</span>
                                </div>
                                <div className="history-head-empty"></div>
                            </div>
                        </th>
                    </tr>
                    {listItems.sort((a, b) => {
                        return sort.direction === 'asc' ? new Date(a.created_at) - new Date(b.created_at) : new Date(b.created_at) - new Date(a.created_at)
                    }).map(({
                                        created_at,
                                        editor,
                                        editor_id,
                                        id,
                                        user_photo,
                                        action_value,
                                        before_value,
                                        after_value
                                    }) => {
                        return (
                            <tr key={`HistoryTableRow-${id}`}>
                                <td className="main-table__body">
                                    <div
                                        className="main-table__body-row"
                                    >
                                        {formatDateToLocal(created_at, "MMM DD, YYYY h:mm A")}
                                    </div>
                                </td>
                                <td className="main-table__body history-editor">
                                    <TableRowItemLinkImg
                                        title={editor}
                                        href={`/admin/users/${editor_id}`}
                                        img={user_photo}
                                    >
                                        {editor}
                                    </TableRowItemLinkImg>
                                </td>
                                <td className="main-table__body">
                                    {action_value === 5 ? (
                                        <div className={'solution-history__wrapper'}>
                                            <div className="solutions-history-row__body">
                                                <ViewHistoryItem
                                                    before_value={before_value}
                                                    after_value={after_value}
                                                />
                                            </div>
                                            <CheckPermission permission="product_solutions_edit">
                                                <button className={'rollback-history__button'} title={'Rollback History Changes'} onClick={() => handleRollbackHistory(id)}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <circle opacity="0.05" cx="12" cy="12" r="12" fill="#778CA2"/>
                                                        <path
                                                            d="M11.1653 17.5977C11.6487 17.5977 12.0128 17.2398 12.0128 16.7501V14.5027H12.1698C14.4612 14.5027 15.8612 15.1744 16.8468 17.0075C17.0979 17.4658 17.3993 17.5725 17.732 17.5725C18.1715 17.5725 18.5105 17.1582 18.5105 16.3609C18.5105 11.8032 16.4953 9.02204 12.1698 9.02204H12.0128V6.78711C12.0128 6.29743 11.6487 5.9082 11.1528 5.9082C10.82 5.9082 10.5815 6.04632 10.2174 6.38532L5.24526 11.0184C5.00042 11.2507 4.90625 11.5144 4.90625 11.7529C4.90625 11.9852 5.00042 12.2552 5.24526 12.4874L10.2174 17.1519C10.5501 17.4658 10.82 17.5977 11.1653 17.5977ZM10.7635 15.978C10.7321 15.978 10.6945 15.9591 10.6568 15.9277L6.369 11.8722C6.31878 11.822 6.29994 11.7906 6.29994 11.7529C6.29994 11.7153 6.31878 11.6839 6.369 11.6336L10.6568 7.54046C10.6882 7.50907 10.7259 7.49023 10.7635 7.49023C10.82 7.49023 10.8577 7.5279 10.8577 7.5844V9.95745C10.8577 10.1018 10.9205 10.1646 11.0649 10.1646H11.8684C15.9114 10.1646 17.3302 12.8327 17.4369 15.8273C17.4369 15.8712 17.4181 15.8901 17.3867 15.8901C17.3616 15.8901 17.3491 15.8712 17.3302 15.8336C16.5831 14.2641 14.7437 13.3538 11.8684 13.3538H11.0649C10.9205 13.3538 10.8577 13.4166 10.8577 13.561V15.8775C10.8577 15.9403 10.82 15.978 10.7635 15.978Z"
                                                            fill="#778CA2"/>
                                                    </svg>
                                                </button>
                                            </CheckPermission>
                                        </div>
                                    ) : 'Other History'}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            </div> : <div className={`grade-view__wrap main-wrap-block technical-wrap not-available`}>
            <div className="sheet-notInfo text-center">
                <Img
                    img="icon_files"
                    width="100"
                    height="100"
                    alt="file"
                    className=""
                />
                <p className="text-center">
                    {emptyText}
                </p>
            </div>
        </div>}
            {modal.open ? (
                <Modal onRequestClose={closeModal}>
                    <HistoryChangesModal
                        title={modal.title}
                        oldContent={modal.oldContent}
                        newContent={modal.newContent}
                        onClose={closeModal}
                    />
                </Modal>
            ) : null}
        </>
}