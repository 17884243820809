import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { url } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabView from "./Views/TabView";
import ShowPermission from "Hoc/Template/ShowPermission";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
    }).isRequired,
};

const Contact = ({ service, fetchItems, t, request }) => {
    const { deleteItem } = service;

    const onClickEdit = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        url.redirect(`/contacts/${item.id}/edit`);
    };

    const onClickShow = item => {
        const win = window.open(`/contacts/${item.id}`, "_blank");
        win.focus();
    };

    const onClickDelete = item => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (window.confirm(t("Do you want to delete the Contact?"))) {
            deleteItem(`/contacts/${item.id}/delete`).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    return (<ShowPermission checkPermission={['contacts_view']}>
                <TabView onClickCustom={onClickShow} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
             </ShowPermission>);
};

const mapStateToProps = state => {
    return {
        request: {
            filter: {
                ...state.listState.filter,
            },
            sort: {
                ...state.listState.sort,
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

Contact.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Contact);
