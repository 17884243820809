import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {formatDate, numberWithCommas, url} from "Services";
import {Title, Total, WrapButtons} from "Templates/Titles";
import Tab from "Templates/Tabs/Tab";
import {MainButtonLink, TabBlockBtn} from "Templates/Content";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {PermissionsProps, withTagDefaultProps, CheckPermission} from "Hoc/Template";
import ShowPermission from "Hoc/Template/ShowPermission";
import Tabs from "Templates/Tabs/Tabs";
import invoiceService from "./Services/Tabs/Invoice";
import lineByLineService from "./Services/Tabs/LineByLine";
import ResetFiltersView from "./Views/ResetFiltersView";
import TabView from "./Views/TabView";
import {Modal} from "Templates/Modals";
import TableSettingsView from "./Views/Modals/TableSettings/TableSettingsView";

const defaultProps = {
    help: ''
};

const propTypes = {
    activeTab: PropTypes.number.isRequired,
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    totalAnalytics: PropTypes.any.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
};

class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditColumnsModal: false
        }
    }

    handleCloseEditColumnsModalWithCheck = e => {
        e.preventDefault();
        this.setState({
            showEditColumnsModal: false
        })
    };

    handleSaveModalWithCheck = (columnList, defaultColumns, pinnedColumns, idTemplate, noReload) => {
        const {
            service: {requestChangeSettings},
            activeTab,
            request,
            fetchLineByLineItems,
            fetchInvoiceItems
        } = this.props;

        requestChangeSettings({'templateId': idTemplate, 'columns': columnList, 'defaultColumns': defaultColumns, 'pinnedColumns': pinnedColumns}).then(
            () => {
                if (noReload) return
                activeTab ? fetchInvoiceItems(request) : fetchLineByLineItems(request);
            },
            errors => {
                alert(errors.detail);
            },
        );

        if (!noReload) {
            this.setState({
                showEditColumnsModal: false
            })
        }
    };

    openEditColumnsModal = () => {
        this.setState({
            showEditColumnsModal: true
        })
    };

    render() {
        const {
            title,
            columns,
            activeColumns,
            request,
            executeExport,
            help,
            totalAnalytics,
            activeTab,
            onChangeTab,
            activeWeightUnit,
            updatedDate,
            t,
        } = this.props;

        const lastUpdate = !updatedDate ? null :
            (
                <>
                    <div className="break"/>
                    <div className="last-update">{`${t("Last SAP Update")} - ${formatDate(updatedDate)}`}</div>
                </>
            );

        const handleButtonClick = (e) => {
            e.preventDefault();
            executeExport(url.getUrl('/api/invoice/invoices/export/zsales', request, ""));
        }

        const handleZComoppsButtonClick = (e) => {
            e.preventDefault();
            executeExport(url.getUrl('/api/invoice/invoices/export/zcomopps', request, ""));
        }

        return (
            <ShowPermission checkPermission={['forecast_invoices_view']}>
                <Title
                    title={t("Sales Invoices")}
                    help={help}
                    total={() => <Total>{`${numberWithCommas(title)} ${t("Total")}`}</Total>}
                >
                    <div className={"invoices-total-analytics"}>
                        {totalAnalytics && totalAnalytics[0] ? <div className="invoices-total-analytics__item">
                                <p>Revenue:</p>
                                <span>${numberWithCommas(totalAnalytics[0].net_amount, 0)}</span>
                        </div> : null}
                        {totalAnalytics && totalAnalytics[0] ? <div className="invoices-total-analytics__item">
                                <p>Volume:</p>
                                <span>{numberWithCommas(totalAnalytics[0].normalized_weight, 0)} (kg)</span>
                        </div> : null}
                    </div>
                    <WrapButtons>
                        {activeTab === 0 &&
                            <>
                                <ServiceProvider
                                    value={lineByLineService}
                                >
                                    <ResetFiltersView/>
                                </ServiceProvider>
                            </>
                        }
                        {activeTab === 1 &&
                            <>
                                <ServiceProvider
                                    value={invoiceService}
                                >
                                    <ResetFiltersView/>
                                </ServiceProvider>
                            </>
                        }
                        {this.state.showEditColumnsModal ? (
                            <Modal onRequestClose={this.handleCloseEditColumnsModalWithCheck}>
                                <TableSettingsView
                                    columns={columns}
                                    activeColumns={activeColumns}
                                    onAppend={this.handleSaveModalWithCheck}
                                    onClose={this.handleCloseEditColumnsModalWithCheck}
                                />
                            </Modal>
                        ) : <div/>}
                        <TabBlockBtn onClick={this.openEditColumnsModal} className="main-btn_white">
                            {t("Edit Column")}
                        </TabBlockBtn>
                        <CheckPermission permission="forecast_invoices_export">
                            <MainButtonLink
                                className="main-btn_white"
                                onClick={(e) => handleButtonClick(e)}
                            >
                                {t("Export Invoices")}
                            </MainButtonLink>
                        </CheckPermission>
                        <CheckPermission permission="forecast_invoices_export_ZComOpps">
                            <MainButtonLink
                                className="main-btn_white"
                                onClick={(e) => handleZComoppsButtonClick(e)}
                            >
                                {t("Export ZComOpps Invoices")}
                            </MainButtonLink>
                        </CheckPermission>
                    </WrapButtons>
                    {lastUpdate}
                </Title>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                            activeWeightUnit(false);
                        }
                    }}
                >
                    <Tab label={t("Invoice Line Items")}>
                        <ServiceProvider value={lineByLineService}>
                            <TabView/>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={t("Invoices")}>
                        <ServiceProvider value={invoiceService}>
                            <TabView/>
                        </ServiceProvider>
                    </Tab>
                </Tabs>
            </ShowPermission>
        );
    }
}

Invoice.defaultProps = defaultProps;
Invoice.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {

    return {
        columns: getStoreItem(state, "columns"),
        activeColumns: getStoreItem(state, "activeColumns"),
        isActiveConvertor: getStoreItem(state, "isActive"),
        title: getStoreItem(state, "pagination.total"),
        help: getStoreItem(state, "help"),
        totalAnalytics: getStoreItem(state, "total_analytics"),
        updatedDate: getStoreItem(state, "updated_date"),
        activeTab: getStoreItem(state, "activeTab"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchLineByLineItems: getActionStore("fetchItems", lineByLineService, dispatch),
        fetchInvoiceItems: getActionStore("fetchItems", invoiceService, dispatch),
        requestChangeSettings: service.requestChangeSettings,
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
        activeWeightUnit: getActionStore("activeWeightUnit", service, dispatch),
        executeExport: getActionStore("executeExport", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Invoice);
