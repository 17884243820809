import React, {Fragment, useEffect, useRef, useState} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withServiceConsumer} from "Services/Context";
import {datasheetLocation, datasheetPaintable, gradeStatus, isMolded, isBoundable} from "Services/enum";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {
    ContentInfoBlock,
    ContentInfoBlockText,
    ContentInfoBlockTitle,
    ContentInfoContactWrapper,
    ContentInfoHeader,
    ContentInfoHeaderWrap,
    ContentInfoTitle,
    ContentInfoTop,
    ContentInfoWrap
} from "Templates/Content";
import {Tooltip} from "Templates/Form";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import {countryList} from "Services";
import Img, {ImgStorage} from "Templates/Img";
import InfoPopup from "Templates/InfoPopup/InfoPopup";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {formatDateWithTime} from "Services/DateService";
import {Div} from "Templates/Default";
import parse from "html-react-parser";
import {Modal} from "Templates/Modals";
import NoteViewModal from "./Modals/NoteViewModal";

const defaultProps = {
    publicationCode: '',
    pinned: [],
    pinnedAdmin: [],
    gradeApplicationCodes: [],
    colors: [],
    colorsPartApproval: [],
    gradeSubSegments: [],
    sapPlantLine: [],
    gradeSegments: [],
    gradeMarketSegments: [],
    manufacturers: [],
};

const propTypes = {
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    productCode: PropTypes.string.isRequired,
    materialNumber: PropTypes.string.isRequired,
    lineName: PropTypes.string.isRequired,
    isSap: PropTypes.bool.isRequired,
    subStatus: PropTypes.number.isRequired,
    publicationCode: PropTypes.string,
    plantId: PropTypes.string.isRequired,
    plantName: PropTypes.string.isRequired,
    sapPlantId: PropTypes.string.isRequired,
    canEdit: PropTypes.number.isRequired,
    activeTab: PropTypes.number.isRequired,
    locations: PropTypes.arrayOf(PropTypes.string),
    datasheet: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
    onClickEdit: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    pinned: PropTypes.any,
    pinnedAdmin: PropTypes.any,
    gradeApplicationCodes: PropTypes.arrayOf(PropTypes.string),
    colors: PropTypes.arrayOf(PropTypes.any),
    colorsPartApproval: PropTypes.arrayOf(PropTypes.any),
    gradeSubSegments: PropTypes.arrayOf(PropTypes.string),
    sapPlantLine: PropTypes.arrayOf(PropTypes.string),
    gradeSegments: PropTypes.arrayOf(PropTypes.string),
    gradeMarketSegments: PropTypes.arrayOf(PropTypes.string),
    manufacturers: PropTypes.arrayOf(PropTypes.string),
};

const InfoWrap = (
    {
        name,
        company,
        pinnedAdmin,
        pinned,
        colors,
        colorsPartApproval,
        productCode,
        materialNumber,
        lineName,
        isSap,
        subStatus,
        plantId,
        plantName,
        paintable,
        is_boundable,
        molded,
        locations,
        sapPlantId,
        sapPlantLine,
        datasheet,
        gradeApplicationCodes,
        gradeMarketSegments,
        gradeSegments,
        gradeSubSegments,
        t,
        onClickEdit,
        permissions,
        activeTab,
        canEdit,
        subStatusHistory,
        publicationCode,
        manufacturers,
    }) => {

    const adminNotesContentRef = useRef(null)
    const pinnedNotesContentRef = useRef(null)

    const [adminNoteButton, setAdminNoteButton] = useState(false)
    const [pinnedNoteButton, setPinnedNoteButton] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showPinnedModal, setShowPinnedModal] = useState(false)

    const applicationCodes = datasheet && datasheet.application_codes && datasheet.application_codes.length ? datasheet.application_codes : gradeApplicationCodes;
    const marketSegments = datasheet && datasheet.market_segments && datasheet.market_segments.length ? datasheet.market_segments : gradeMarketSegments;
    const segments = datasheet && datasheet.segments && datasheet.segments.length ? datasheet.segments : gradeSegments;

    const [isShownApplicationCodesContent, setIsShownApplicationCodesContent] = useState(true);
    const [isShownMarketSegmentsContent, setIsShownMarketSegmentsContent] = useState(true);
    const [isShownSegmentsContent, setIsShownSegmentsContent] = useState(true);
    const [isShownLocationsContent, setIsShownLocationsContent] = useState(true);
    const [isShownCountryContent, setIsShowCountryContent] = useState(true);
    const [isShownSapPlantContent, setIsSapPlantContent] = useState(true);
    const [isShownManufacturersContent, setIsShowManufacturersContent] = useState(true);
    const [subSegments, setSubSegment] = useState([]);

    const subStatusItem = gradeStatus.find(el => el.value.toString() === subStatus.toString());
    const subStatusLabel = subStatusItem && subStatusItem.label;

    useEffect(() => {
        if (adminNotesContentRef && adminNotesContentRef.current) {
            setAdminNoteButton(adminNotesContentRef.current.clientHeight < adminNotesContentRef.current.scrollHeight || adminNotesContentRef.current.innerHTML.includes('img'));
        }
    }, [adminNotesContentRef])

    useEffect(() => {
        if (pinnedNotesContentRef && pinnedNotesContentRef.current) {
            setPinnedNoteButton(pinnedNotesContentRef.current.clientHeight < pinnedNotesContentRef.current.scrollHeight || pinnedNotesContentRef.current.innerHTML.includes('img'));
        }
    }, [pinnedNotesContentRef])

    useEffect(() => {
        if (!datasheet || !datasheet.sub_segments || !datasheet.sub_segments.length) {
            if (gradeSubSegments && gradeSubSegments.length) {
                setSubSegment(gradeSubSegments);
            } else {
                setSubSegment([]);
            }
        } else {
            setSubSegment(datasheet.sub_segments);
        }
    }, [datasheet.sub_segments]);

    const plantView = () => {
        let response = '';

        if (plantId) {
            response = sapPlantId ? `${sapPlantId} - ${plantName}` : plantName;
        }

        return response;
    };

    const paintableView = () => {
        return paintable ? datasheetPaintable.find(el => el.value.toString() === paintable.toString()).label : '';
    };

    const activeMolds = isMolded.filter((value) => {
        return molded && molded[value.value];
    });

    const boundableView = () => {
        return is_boundable ? isBoundable.find(el => el.value === is_boundable).label : '';
    };

    const locationView = (elem) => {
        return elem ? datasheetLocation.find(el => el.value.toString() === elem.toString()).label : '-';
    };

    const countryView = (elem) => {
        return elem ? countryList.find(el => el.value.toString() === elem.toString()).label : '-';
    };

    const showContent = (array, toggle, elemView) => {
        if (!toggle) {
            return array.slice(0, 3).map((elem, index) => (
                <ContentInfoBlockText key={index} value={elemView ? elemView(elem) : elem}/>
            ));
        }

        return array.map((elem, index) => (
            <ContentInfoBlockText key={index} value={elemView ? elemView(elem) : elem}/>
        ));
    };

    const showCountries = (array, toggle, elemView) => {
        const items = array.map((elem) => (
            elemView ? elemView(elem) : elem
        )).sort((a, b) => {
            // Global option should be the first in order
            if (a === 'Global') {
                return -1;
            }

            if (b === 'Global') {
                return 1;
            }

            return a > b ? 1 : -1;
        });

        return showContent(items, toggle)
    };

    const tabCountryLabel = ['Technical Data Sheet', 'Processing Data Sheet', 'Guide', 'Safety Data Sheet', 'Brochure'];

    const onCloseModal = () => {
        setShowModal(false)
    }

    const onClosePinnedModal = () => {
        setShowPinnedModal(false)
    }

    return (
        <ContentInfoWrap>
            <ContentInfoHeaderWrap>
                <ContentInfoTop
                    onClickEdit={permissions.includes("grade_product_finder_edit") && canEdit ? onClickEdit : null}
                    urlBack="/product/product-finder"
                />

                <ContentInfoHeader>
                    <ContentInfoTitle>{`${name}`}</ContentInfoTitle>
                    <ContentInfoBlockTitle>{company}</ContentInfoBlockTitle>
                </ContentInfoHeader>
            </ContentInfoHeaderWrap>

            <div className="product__filters">
                <ContentInfoBlock show={pinnedAdmin && (pinnedAdmin.length > 0 || Object.keys(pinnedAdmin).length > 0)}>
                    <h3 title='Admin note' className="note-label__admin">
                        <span className="note_wrap">
                            <span>
                                <Img img="admin-note-icon" className="main-header__add-svg" alt=""/>
                            </span>
                            <span ref={adminNotesContentRef} className="content-wrap">
                                {pinnedAdmin.comment ? parse(pinnedAdmin.comment) : pinnedAdmin.comment}
                            </span>
                        </span>
                        {adminNoteButton ? <button className="note-modal-btn" onClick={() => setShowModal(true)}>View Full Note</button> : null}
                    </h3>
                    {showModal ? (
                        <Modal onRequestClose={onCloseModal}>
                            <NoteViewModal
                                t={t}
                                content={parse(pinnedAdmin.comment)}
                                onClose={onCloseModal}
                            />
                        </Modal>
                    ) : null}
                </ContentInfoBlock>

                <ContentInfoBlock show={pinned && (pinned.length > 0 || Object.keys(pinned).length > 0)}>
                    <h3 title='Pinned note' className="note-label__pinned">
                        <span className="note_wrap">
                            <span>
                            <Img img="pinned-note-icon" className="main-header__add-svg" alt=""/>
                            </span>
                                <span ref={pinnedNotesContentRef} className="content-wrap">
                                {pinned.comment ? parse(pinned.comment) : pinned.comment}
                            </span>
                        </span>
                        {pinnedNoteButton ? <button className="note-modal-btn" onClick={() => setShowPinnedModal(true)}>View Full Note</button> : null}
                    </h3>
                    {showPinnedModal ? (
                        <Modal onRequestClose={onClosePinnedModal}>
                            <NoteViewModal
                                t={t}
                                content={parse(pinned.comment)}
                                onClose={onClosePinnedModal}
                            />
                        </Modal>
                    ) : null}
                </ContentInfoBlock>
                <ContentInfoBlock show={!!publicationCode}>
                    <ContentInfoBlockTitle>{t("Publication ID")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={publicationCode}/>
                </ContentInfoBlock>

                <ContentInfoBlock show={!!subStatusLabel}>
                    <ContentInfoBlockTitle>
                        <span className="flex">
                            {t("Status")}
                            <span className="status-tooltip-wrap">
                                {!Array.isArray(subStatusHistory) ? <Tooltip>
                                    {`
                                        Product Status has been updated 
                                        from ${subStatusHistory.from} 
                                        to ${subStatusHistory.to}
                                        by ${subStatusHistory.changed_by}
                                        on ${formatDateWithTime(subStatusHistory.changed_at)}
                                    `}
                                </Tooltip> : null }
                            </span>
                        </span>
                    </ContentInfoBlockTitle>
                    <TableRowItemStatus status={subStatusLabel} list={gradeStatus}/>
                </ContentInfoBlock>

                <ContentInfoBlock show={!!applicationCodes.length}>
                    <ContentInfoBlockTitle>
                        {t("Application Codes")}
                        <Tooltip>
                            {`The first Application Code listed is automatically assigned by SAP. Any additional Application Codes are assigned to a Project or a Product.`}
                        </Tooltip>
                    </ContentInfoBlockTitle>
                    {showContent(applicationCodes, isShownApplicationCodesContent)}
                    {applicationCodes.length > 3 && (
                        <button
                            type="button"
                            className={["cost-modal__calculator_btn", isShownApplicationCodesContent ? 'open' : ''].join(' ')}
                            onClick={() => setIsShownApplicationCodesContent(!isShownApplicationCodesContent)}
                        >
                            Show
                            {' '}
                            {isShownApplicationCodesContent ? 'Less' : 'More'}
                        </button>
                    )}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!marketSegments.length}>
                    <ContentInfoBlockTitle>{t("Market Segments")}</ContentInfoBlockTitle>
                    {showContent(marketSegments, isShownMarketSegmentsContent)}
                    {marketSegments.length > 3 && (
                        <button
                            type="button"
                            className={["cost-modal__calculator_btn", isShownMarketSegmentsContent ? 'open' : ''].join(' ')}
                            onClick={() => setIsShownMarketSegmentsContent(!isShownMarketSegmentsContent)}
                        >
                            Show
                            {' '}
                            {isShownMarketSegmentsContent ? 'Less' : 'More'}
                        </button>
                    )}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!segments.length}>
                    <ContentInfoBlockTitle>{t("Segments")}</ContentInfoBlockTitle>
                    {showContent(segments, isShownSegmentsContent)}
                    {segments.length > 3 && (
                        <button
                            type="button"
                            className={["cost-modal__calculator_btn", isShownSegmentsContent ? 'open' : ''].join(' ')}
                            onClick={() => setIsShownSegmentsContent(!isShownSegmentsContent)}
                        >
                            Show
                            {' '}
                            {isShownSegmentsContent ? 'Less' : 'More'}
                        </button>
                    )}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!subSegments.length}>
                    <ContentInfoBlockTitle>{t("Sub-Segments")}</ContentInfoBlockTitle>
                    {subSegments.length > 3 ? (
                        subSegments.slice(0, 3).map((elem, index) => (
                            <ContentInfoBlockText key={index} value={elem}/>
                        ))
                    ) : subSegments.map((elem, index) => (
                        <ContentInfoBlockText key={index} value={elem}/>
                    ))}
                    {subSegments.length > 3 && (
                        <InfoPopup
                            type="segments"
                            left={false}
                            content={[...subSegments].map((elem, index) => (
                                <ContentInfoBlockText key={index} value={elem}/>
                            ))}
                        />
                    )}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!materialNumber}>
                    <ContentInfoBlockTitle>{t("Material Number")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={materialNumber}/>
                </ContentInfoBlock>

                <ContentInfoBlock show={!!productCode}>
                    <ContentInfoBlockTitle>{t("Product Code")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={productCode}/>
                </ContentInfoBlock>

                <ContentInfoBlock show={!!paintableView()}>
                    <ContentInfoBlockTitle>{t("Paintable")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={paintableView()}/>
                </ContentInfoBlock>


                <ContentInfoBlock show={activeMolds.length > 0}>
                    <ContentInfoBlockTitle>
                        {t("Mold-in-Color")}
                        <Tooltip>
                            {`Mold-in-Color is a pre-colored plastic resin. You must have a color code for 80% or 100% color match. Please contact your MCGC representative for more information.`}
                        </Tooltip>
                    </ContentInfoBlockTitle>
                    {activeMolds.map((elem, key) => (
                        <div key={key}>
                            <ContentInfoBlockText value={elem.label + ' ' + (molded[elem.value + '_desc'] ? ('(' + molded[elem.value + '_desc'] + ')') : '')}/>
                        </div>
                    ))}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!boundableView()}>
                    <ContentInfoBlockTitle>{t("Bondable")}</ContentInfoBlockTitle>
                    <ContentInfoBlockText value={boundableView()}/>
                </ContentInfoBlock>

                <ContentInfoBlock show={!!colors.length}>
                    <ContentInfoBlockTitle>{t("Colors and OEM Approvals")}</ContentInfoBlockTitle>
                    {colors.length > 1 ? (
                        colors.slice(0, 1).map((color, colorKey) => (
                            <Fragment key={colorKey}>
                                <p className="color-title">
                                    {`${color.color}:`}
                                </p>
                                {(
                                    color.items.map((item, itemKey) => (
                                        <ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={item}/>
                                    ))
                                )}
                            </Fragment>
                        ))
                    ) : colors.map((color, colorKey) => (
                        <Fragment key={colorKey}>
                            <p className="color-title">
                                {color.color}
                                :
                            </p>
                            {(
                                color.items.map((item, itemKey) => (
                                    <ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={item}/>
                                ))
                            )}
                        </Fragment>
                    ))}
                    {colors.length > 1 && (
                        <InfoPopup
                            type="colors-type"
                            left={false}
                            content={colors.map((color, colorKey) => (
                                <div className="color-wrap" key={colorKey}>
                                    <p className="color-title">
                                        {`${color.color}: `}
                                    </p>
                                    {(color.items.map((item, itemKey) => (
                                        <ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={item}/>)))}
                                </div>
                            ))}
                        />
                    )}
                </ContentInfoBlock>

                <ContentInfoBlock>
                    <div className="info-block-wrap">
                        <ContentInfoBlockTitle>
                            {t("Part Approvals")}
                            <Tooltip>
                                {`This is a list of manufactures using this material in a part without an approved OEM specification. Confirm part details in the projects tab.`}
                            </Tooltip>
                        </ContentInfoBlockTitle>
                        {manufacturers.length > 0 ? <>
                                <p className="color-title">{t("Project:")}</p>
                                {manufacturers.length > 1 ? (
                                    manufacturers.slice(0, 1).map((elem, index) => (
                                        <ContentInfoBlockText key={index} value={elem}/>
                                    ))
                                ) : manufacturers.map((elem, index) => (
                                    <ContentInfoBlockText key={index} value={elem}/>
                                ))}
                                {manufacturers.length > 1 && (
                                    <InfoPopup
                                        type="approvals-manufacturers"
                                        left={false}
                                        content={[...manufacturers].map((elem, index) => (
                                            <ContentInfoBlockText key={index} value={elem}/>
                                        ))}
                                    />
                                )}
                        </> : null}
                    </div>

                    <ContentInfoBlock show={!!colorsPartApproval.length}>
                        <p className="color-title">{t("Legacy:")}</p>
                        {colorsPartApproval.length > 1 ? (
                            colorsPartApproval.slice(0, 1).map((color, colorKey) => (
                                <Fragment key={colorKey}>
                                    <p className="color-title">
                                        {`${color.color}:`}
                                    </p>
                                    {(
                                        color.items.map((item, itemKey) => (
                                            <ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={item}/>
                                        ))
                                    )}
                                </Fragment>
                            ))
                        ) : colorsPartApproval.map((color, colorKey) => (
                            <Fragment key={colorKey}>
                                <p className="color-title">
                                    {color.color}
                                    :
                                </p>
                                {(
                                    color.items.map((item, itemKey) => (
                                        <ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={item}/>
                                    ))
                                )}
                            </Fragment>
                        ))}
                        {colorsPartApproval.length > 1 && (
                            <InfoPopup
                                type="colors-type-legacy"
                                left={false}
                                content={colorsPartApproval.map((color, colorKey) => (
                                    <div className="color-wrap" key={colorKey}>
                                        <p className="color-title">
                                            {`${color.color}: `}
                                        </p>
                                        {(color.items.map((item, itemKey) => (
                                            <ContentInfoBlockText key={`${colorKey}_${itemKey}`} value={item}/>)))}
                                    </div>
                                ))}
                            />
                        )}
                    </ContentInfoBlock>

                </ContentInfoBlock>

                <ContentInfoBlock show={!!locations.length}>
                    <ContentInfoBlockTitle>{t("Vehicle Locations")}</ContentInfoBlockTitle>
                    {showContent(locations, isShownLocationsContent, locationView)}
                    {locations.length > 3 && (
                        <button
                            type="button"
                            className={["cost-modal__calculator_btn", isShownLocationsContent ? 'open' : ''].join(' ')}
                            onClick={() => setIsShownLocationsContent(!isShownLocationsContent)}
                        >
                            Show
                            {' '}
                            {isShownLocationsContent ? 'Less' : 'More'}
                        </button>
                    )}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!datasheet.countries.length}>
                    <ContentInfoBlockTitle>
                        {t("Countries")}
                        <Tooltip>
                            {`This ${tabCountryLabel[activeTab] || 'Technical Data Sheet'} is applicable to these countries. Verify Compliance.`}
                        </Tooltip>
                    </ContentInfoBlockTitle>
                    {showCountries(datasheet.countries, isShownCountryContent, countryView)}
                    {datasheet.countries.length > 3 && (
                        <button
                            type="button"
                            className={["cost-modal__calculator_btn", isShownCountryContent ? 'open' : ''].join(' ')}
                            onClick={() => setIsShowCountryContent(!isShownCountryContent)}
                        >
                            Show
                            {' '}
                            {isShownCountryContent ? 'Less' : 'More'}
                        </button>
                    )}
                </ContentInfoBlock>

                <ContentInfoBlock show={!!sapPlantLine}>
                    <ContentInfoBlockTitle>
                        {t("SAP Plant / SAP Production Line")}
                    </ContentInfoBlockTitle>
                    {showContent(sapPlantLine, isShownSapPlantContent)}
                    {sapPlantLine.length > 3 && (
                        <button
                            type="button"
                            className={["cost-modal__calculator_btn", isShownSapPlantContent ? 'open' : ''].join(' ')}
                            onClick={() => setIsSapPlantContent(!isShownSapPlantContent)}
                        >
                            Show
                            {' '}
                            {isShownSapPlantContent ? 'Less' : 'More'}
                        </button>
                    )}
                </ContentInfoBlock>

                {!!datasheet.contacts.length && (
                    <ContentInfoBlockTitle>{t("Contacts")}</ContentInfoBlockTitle>
                )}

                {!!datasheet.contacts.length && datasheet.contacts.map((contact, index) => (
                    <Fragment key={`contact-${index}`}>
                        <ContentInfoBlock show={!!contact.id}>
                            <ContentInfoContactWrapper>
                                <a
                                    href={`/admin/users/${contact.id}`}
                                    rel="nofollow noopener noreferrer"
                                    target="_blank"
                                >
                                    {
                                        contact.photo ? (
                                            <ImgStorage
                                                href={`/admin/users/${contact.id}`}
                                                url={contact.photo}
                                                className="grade-view__img"
                                            />
                                        ) : (
                                            <Img img="icon_contact_small" className="grade-view__img"/>
                                        )
                                    }
                                </a>
                                <ContentInfoBlock>
                                    <ContentInfoBlockText value={contact.name || ''}/>
                                    <ContentInfoBlockTitle>{contact.relation_role ? contact.relation_role : ''}</ContentInfoBlockTitle>
                                    <ContentInfoBlockTitle>{contact.role ? contact.role : ''}</ContentInfoBlockTitle>
                                    <TableRowItemLink
                                        title={contact.email}
                                        href={`mailto:${contact.email}`}
                                    >
                                        {contact.email}
                                    </TableRowItemLink>
                                    <ContentInfoBlockText value={contact.phone || ''}/>
                                </ContentInfoBlock>
                            </ContentInfoContactWrapper>
                        </ContentInfoBlock>
                    </Fragment>
                ))}
            </div>
        </ContentInfoWrap>
    );
};

InfoWrap.defaultProps = defaultProps;
InfoWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        name: getStoreItem(state, "view.name"),
        pinnedAdmin: getStoreItem(state, "view.pinnedAdmin"),
        pinned: getStoreItem(state, "view.pinned"),
        company: getStoreItem(state, "view.company"),
        companyId: getStoreItem(state, "view.companyId"),
        productId: getStoreItem(state, "view.productId"),
        productCode: getStoreItem(state, "view.productCode"),
        materialNumber: getStoreItem(state, "view.materialNumber"),
        colors: getStoreItem(state, "view.colors"),
        colorsPartApproval: getStoreItem(state, "view.colorsPartApproval"),
        subStatus: getStoreItem(state, "view.subStatus"),
        lineName: getStoreItem(state, "view.lineName"),
        isSap: getStoreItem(state, "view.isSap"),
        plantId: getStoreItem(state, "view.plantId"),
        plantName: getStoreItem(state, "view.plantName"),
        sapPlantId: getStoreItem(state, "view.sapPlantId"),
        sapPlantLine: getStoreItem(state, "view.sapPlantLine"),
        date: getStoreItem(state, "view.date"),
        paintable: getStoreItem(state, "view.paintable"),
        is_boundable: getStoreItem(state, "view.is_boundable"),
        molded: getStoreItem(state, "view.molded"),
        locations: getStoreItem(state, "view.locations"),
        datasheet: getStoreItem(state, "view.datasheet"),
        canEdit: getStoreItem(state, "view.canEdit"),
        gradeApplicationCodes: getStoreItem(state, "view.applicationCodes"),
        gradeMarketSegments: getStoreItem(state, "view.marketSegments"),
        gradeSegments: getStoreItem(state, "view.segments"),
        gradeSubSegments: getStoreItem(state, "view.subSegments"),
        subStatusHistory: getStoreItem(state, "view.subStatusHistory"),
        manufacturers: getStoreItem(state, "view.manufacturers"),
        publicationCode: getStoreItem(state, "activeSubTab.publication_code"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(InfoWrap);
