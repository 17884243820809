import { debounce } from "Services/Debounce";
import Constants from "../../Constants";
import { fetchItems } from "../CommonTableActions";

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if ([
        "changed_date",
        "created_code_date",
        "created_system_date",
        "sap_value",
        "project_count",
        "invoice_count",
        "customer_owner",
        "marked_deletion",
        "nicknames"
    ].includes(key)) {
        fetchItems(service, dispatch)(request);
    } else if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};
