import _ from "lodash";

export const getTableLabel = (key) => {
    const tableLabel = {
        grade: "Product Name",
        sub_status: "Product Status",
        material_number: "Material Number",
        plant_name: "Plant",
        line_name: "Production Line",
        sap_plant: "SAP Plant",
        sap_line: "SAP Production Line",
        material_type: "Material Type",
        product_code: "Product Code",
        updated_at: "Date Updated",
        count_projects: "Project(s)",
        count_invoices: "Invoice(s)",
    };

    return _.get(tableLabel, key, key);
};

export const getTooltip = key => {
    const tableTooltip = {
        sub_status: `
            <div class="long-tooltip"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</div>
            <div class="long-tooltip"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</div>
            <div class="long-tooltip"><span class="font-medium">Production Intent</span> - Product in process for being approved.</div>
            <div class="long-tooltip"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</div>
            <div class="long-tooltip"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</div>
            <div class="long-tooltip"><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</div>
        `,
        material_number: `<span class="white-space-nowrap">SAP Material Number.</span>`,
        material_type: `
            <span class="white-space-nowrap">FERT = Finished Goods</span>
            <span class="white-space-nowrap">HALB = Semifinished Goods</span>
            <span class="white-space-nowrap">HAWA = Trade Goods</span>
        `
    };

    return _.get(tableTooltip, key, null);
}

export const getFilter = () => "";
