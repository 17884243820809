import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import Img, { ImgStorage } from "../Img";

const defaultProps = {
    img: "",
    ico: "",
    href: "",
    target: "_self",
    className: "",
    additionalClassname: "",
    title: "",
  //  onClickItem: () => { },
};

const propTypes = {
    img: PropTypes.string,
    ico: PropTypes.string,
    onClickItem: PropTypes.func,
    className: PropTypes.string,
    href: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    target: PropTypes.string,
    additionalClassname: PropTypes.string,
    title: PropTypes.string,
};

const FolderImg = ({ children, className, pref, target, img, ico, href, storage_url, additionalClassname, title, onClickItem}) => {
    const _href = typeof href === "function" ? href(storage_url) : href;

    function getContent() {
        return <>
            <span className={`${pref}__table-body-link-user main-table__body-link-user ${additionalClassname}`}>
                {img ? <ImgStorage url={img}/> : <Img img={ico || "icon_contact_small"}/>}
            </span>
            <span className={`${pref}__table-body-link-text main-table__body-link-text`}>{children}</span>
        </>;
    }

    return (ico === 'icon-folder') ?
        (<span
            title={title}
            rel={_href === '_blank' ? "noopener noreferrer" : ''}
            className={`${pref}__table-body-link main-table__body-link ${className}`}
            onClick={e => { e.preventDefault(); onClickItem(href);}}
        >
            {getContent()}
        </span>) :
        (<a
            title={title}
            href={_href}
            target={target}
            rel={_href === '_blank' ? "noopener noreferrer" : ''}
            className={`${pref}__table-body-link main-table__body-link ${className}`}
        >
            {getContent()}
        </a>);
};

FolderImg.propTypes = propTypes;
FolderImg.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(FolderImg);
