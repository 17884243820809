import Constants from "../Constants";

export const openModal = (service, dispatch) => () => {
    dispatch({type: Constants.OPEN_CONTACTS_LIST_MODAL});
};

export const closeModal = (service, dispatch) => () => {
    dispatch({type: Constants.CLOSE_CONTACTS_LIST_MODAL});
};

export const updateForm = (service, dispatch) => data => {
    dispatch({type: Constants.UPDATE_CONTACTS_FORM, data});
};

export const getItems = (service, dispatch) => (payload) => {
    dispatch({type: Constants.GET_CONTACTS_REQUESTED});
    service.getItems(payload).then(response => {
        const [data] = response;
        const type = payload && payload.page ?
            Constants.GET_NEXT_PAGE_CONTACTS_SUCCEEDED :
            Constants.GET_CONTACTS_SUCCEEDED;
        dispatch({type, data});
    }, errors => {
        dispatch({type: Constants.GET_CONTACTS_REJECTED, data: errors});
    })
};

export const saveItems = (service, dispatch) => data => {
    dispatch({type: Constants.SAVE_CONTACTS_REQUESTED});
    const {FormService} = service;
    const payload = FormService.contactsFormData(data);
    service.saveItems(payload).then(response => {
        dispatch({type: Constants.SAVE_CONTACTS_SUCCEEDED});
        closeModal(service, dispatch)();
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.SAVE_CONTACTS_REJECTED, data: errors});
    });
};

export const deleteItem = (service, dispatch) => (data) => {
    dispatch({type: Constants.DELETE_CONTACTS_REQUESTED});
    service.deleteItem(data).then(response => {
        dispatch({type: Constants.DELETE_CONTACTS_SUCCEEDED});
        getItems(service, dispatch)();
    }, errors => {
        dispatch({type: Constants.DELETE_CONTACTS_REJECTED, data: errors});
    });
};
