import React from "react";
import _ from "lodash";
import { numWithSub } from "Services";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableLine from "../Views/Card/Table/Templates/TableLine";
import { requestGet } from "./RequestService";

const tableLabel = {
    name: "Name",
    volume: "Forecast Volume (Current)",
};

const getViewItem = (key, item, lineWidth) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLinkImg
                    title={_.get(item, key)}
                    href={`/admin/users/${_.get(item, "id")}`}
                    img={_.get(item, "photo")}
                >
                    {_.get(item, key)}
                </TableRowItemLinkImg>
            );

        case "volume":
            return (
                <div className="line-bg">
                    <TableLine width={lineWidth}>
                        <span>{`${numWithSub(_.get(item, key))} lb`}</span>
                    </TableLine>
                </div>
            );

        default:
            return <TableRowItemSpan title={_.get(item, key)}>{_.get(item, key)}</TableRowItemSpan>;
    }
};

const eventFrame = () => {
    let ticking = false;

    return new Promise(resolve => {
        if (!ticking) {
            window.requestAnimationFrame(() => {
                resolve();

                ticking = false;
            });

            ticking = true;
        }
    });
};

const handleScroll = e => {
    const element = e.currentTarget;
    return new Promise(resolve => {
        eventFrame().then(() => {
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                return;
            }

            if (element.scrollHeight - element.scrollTop <= element.clientHeight * 1.3) {
                resolve();
            }
        });
    });
};

export default () => ({
    requestTable: requestGet("/dashboard/table/personnel"),
    tableLabel,
    getViewItem,
    handleScroll,
});
