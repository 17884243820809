import Constants from "../../Constants";

export const getSubTabs = (service, dispatch) => () => {
    const { requestSubTab } = service;

    return requestSubTab().then(
        res => dispatch({ type: Constants.FETCH_SUBTAB_ITEMS_REQUESTED, data: res })
    );
};

export const setActiveSubTabAction = (service, dispatch) => item => {
    return dispatch({ type: Constants.CHANGE_ACTIVE_SUBTAB, data: item })
};

export const assignToProductAction = (service, dispatch) => id => data => {
    const { requestAssignToProduct } = service;
    data.isSubbmited = 0;

    return requestAssignToProduct(id)(data).then(
        res => {
            dispatch({ type: Constants.ASSIGN_TO_PRODUCT_SUCCESS, data: res });
        },
        res => {
            if (!res.code || res.code !== 322) {
                alert(res.detail);
                dispatch({ type: Constants.ASSIGN_TO_PRODUCT_FAILED, data: res });
                return;
            }

            if (window.confirm(res.detail)) {
                data.isSubbmited = 1

                requestAssignToProduct(id)(data).then(
                    resp => {
                        getSubTabs(service, dispatch)();
                        dispatch({ type: Constants.ASSIGN_TO_PRODUCT_SUCCESS, data: resp });
                    })
            }
        },
    );
};

export const editProductAction = (service, dispatch) => id => data => {
    const { requestEditProduct } = service;

    return requestEditProduct(id, data.product.value)(data).then(
        res => {
            dispatch({ type: Constants.EDIT_PRODUCT_SUCCESS, data: res });
        },
        res => {
            if (!res.code || res.code !== 322) {
                alert(res.detail);
                dispatch({ type: Constants.EDIT_PRODUCT_FAILED, data: res });
            }
        },
    );
};

export const saveCopySolutionAction = (service, dispatch) => id => data => {
    const { requestOnSaveCopySolution } = service;

    return requestOnSaveCopySolution(id)(data).then(
        res => {
            dispatch({ type: Constants.SAVE_COPY_PUBLICATION_SUCCESS, data: res });
            alert('Successfully copied');
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.SAVE_COPY_PUBLICATION_FAILED, data: res });
        },
    );
};

export const createFilesAction = (service, dispatch) => data => {
    dispatch({ type: Constants.CREATE_FILES_REQUEST });

    const { requestOnUploadFiles } = service;

    return requestOnUploadFiles(data).then(
        res => dispatch({ type: Constants.CREATE_FILES_SUCCESS, data: res }),
        res => {
            alert(res.detail);
            dispatch({ type: Constants.CREATE_FILES_FAILED, data: res });
        },
    );
};

export const saveAgreementsAction = (service, dispatch) => id => data => {
    const { requestOnSaveAgreements } = service;

    return requestOnSaveAgreements(id)(data).then(
        res => {
            dispatch({ type: Constants.UPDATE_SUCCESS, data: res });
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.UPDATE_FAILED, data: res });
        },
    );
};
