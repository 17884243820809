import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import { ContentWrap, ContentWrapCol } from "Templates/Content";
import {withTagDefaultProps} from "Hoc/Template";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import InfoWrap from "./Views/InfoWrap";

import _publicationsService from "./Services/Tabs/Publications";
import Publications from "./Views/Tabs/Publications";

const Brochure = ({ id, activeTab, t, onChangeTab }) => {
    const publicationsService = _publicationsService(id);

    return (
        <ContentWrap>
            <InfoWrap/>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={index => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Publications")}>
                        <ServiceProvider value={publicationsService}>
                            <Publications/>
                        </ServiceProvider>
                    </Tab>
                    <Tab label={''}/>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

Brochure.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Brochure);
