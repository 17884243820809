const Constants = {
    CHANGE_FORM: "CHANGE_FORM",
    FORM_REQUESTED: "FORM_REQUESTED",
    FORM_SUCCEEDED: "FORM_SUCCEEDED",
    FORM_FAILED: "FORM_FAILED",
    FORM_RESET: "FORM_RESET",

    CHANGE_SUB_SEGMENT: "CHANGE_SUB_SEGMENT",
    CHANGE_APPLICATION_CODE_REQUESTED: "CHANGE_APPLICATION_CODE_REQUESTED",
    CHANGE_APPLICATION_CODE_SUCCEEDED: "CHANGE_APPLICATION_CODE_SUCCEEDED",
    CHANGE_APPLICATION_CODE_FAILED: "CHANGE_APPLICATION_CODE_FAILED",

    ADD_APPLICATION_ITEM: "ADD_APPLICATION_ITEM",
    REMOVE_APPLICATION_ITEM: "REMOVE_APPLICATION_ITEM",
};

export default Constants;
