import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { MainButtonLink, TabWrap } from "Templates/Content";
import { Title, WrapButtons, Total } from "Templates/Titles";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {ContainerTable} from "Hoc/Widgets";
import {TableRowColumnWrap} from "Templates/Table/TableRowColumnWrap";
import TableHeadColumnWrap from "./TableHeadColumnWrap";
// import { url } from "Services";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const defaultProps = {
    help: ''
}

const propTypes = {
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    // request: PropTypes.objectOf(PropTypes.any).isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
};

const TabView = ({ onClickEdit, onClickDelete, onClickCustom,
  // request, 
  t, title, help }) => {
    return (
        <>
            <Title
                title={t("Сontacts")}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                <WrapButtons>
                    {/* <MainButtonLink href={url.getUrl("/contacts/export", request, "")} className="main-btn_white">
                        {t("Export Contacts")}
                    </MainButtonLink> */}
                    <MainButtonLink href="/contacts/create" className="main-btn_primary">
                        {t("Add New Contact")}
                    </MainButtonLink>
                </WrapButtons>
            </Title>
            <TabWrap>
                <TabViewDetail
                    customIcon="icon_external_link"
                    onClickCustom={onClickCustom}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                />
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes;
TabView.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        title: getStoreItem(state, "pagination.total"),
        help: getStoreItem(state, "help"),
        // request: {
        //     filter: state.listState.filter,
        //     sort: state.listState.sort,
        // },
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TabView);
