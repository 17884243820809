import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {Column, Row} from "Templates/Table/NewVersion";
import {Span} from "Templates/Default";
import {weightUnitName} from "Services/weightUnitName";
import {useSelector} from "react-redux";

const propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    marginListSelect: PropTypes.string.isRequired,
};

const BudgetSalesTableHeadColumn = ({ title, data, marginListSelect }) => {
    const unit = useSelector(state => state.weightUnitState);
    const generateDateTitles = (list) => {

        return list.map((item) => {
            const itemMonth = item.substring(0, 2).replace('/', '');
            const itemYear = item.slice(-4);
            switch (itemMonth) {
                case '1':
                    return `January ${itemYear}`
                case '2':
                    return `February ${itemYear}`
                case '3':
                    return `March ${itemYear}`
                case '4':
                    return `April ${itemYear}`
                case '5':
                    return `May ${itemYear}`
                case '6':
                    return `June ${itemYear}`
                case '7':
                    return `July ${itemYear}`
                case '8':
                    return `August ${itemYear}`
                case '9':
                    return `September ${itemYear}`
                case '10':
                    return `October ${itemYear}`
                case '11':
                    return `November ${itemYear}`
                case '12':
                    return `December ${itemYear}`
                default:
                    return item;
            }
        })
    }

    return (
        <>
            <Row>
                <Column colSpan={1} className="th first my-analytics-budget-date-plug-column">
                    <Span />
                </Column>
                {generateDateTitles(Object.keys(data)).map(elem => (
                    <Column key={`Column-${elem}`} className="th my-analytics-budget-date-column">
                        <Span>{elem}</Span>
                    </Column>
                ))}
            </Row>
            <Row>
                <Column colSpan={1} className="th first my-analytics-budget-head__title-wrap">
                    <Span className="my-analytics-budget-head__title">{title}</Span>
                </Column>
                {Object.keys(data).map(elem => (
                    <Column key={`Column-${elem}`} className="th my-analytics-budget-head__column-wrap">
                        <Span className="my-analytics-budget-head">
                            <Span className="my-analytics-budget-head__actual">{marginListSelect === '1' ? 'Actual Revenue ($)' : `Actual Volume (${weightUnitName(unit.weightUnit)})`}</Span>
                            <Span className="my-analytics-budget-head__forecast">{marginListSelect === '1' ? 'Forecast Revenue ($)' : `Forecast Volume (${weightUnitName(unit.weightUnit)})`}</Span>
                        </Span>
                    </Column>
                ))}
            </Row>
        </>
    );
};

BudgetSalesTableHeadColumn.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        title: getStoreItem(state, "title"),
        data: getStoreItem(state, "data"),
        marginListSelect: getStoreItem(state, "old.marginListSelect"),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(BudgetSalesTableHeadColumn);
