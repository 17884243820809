import React, {Component} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import DragAndDrop from "./DragAndDrop";

const defaultProps = {
    fileLength: 0,
};

const propTypes = {
    fileLength: PropTypes.number,
    handleDrop: PropTypes.func.isRequired,
    dropFile: PropTypes.func.isRequired,
    files: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string.isRequired,
};

class VideoList extends Component {
    src = [];

    handleDrop = files => {
        const { handleDrop, fileLength } = this.props;
        handleDrop([...files].slice(-fileLength));
    };

    dropFile = key => {
        const { files, dropFile } = this.props;
        const value = files.filter((item, n) => +n !== +key);

        dropFile(value);
    };

    renderFiles = files => {
        if (!files || !_.head(files)) {
            return undefined;
        }

        return (
            <ul className="upload-video-modal">
                {files.map((item, key) => (
                    <li className="upload-video-modal__item" key={key}>
                        <i
                            className="la la-icon-close"
                            onClick={() => {
                                this.dropFile(key);
                            }}
                        />
                    </li>
                ))}
            </ul>
        );
    };

    render() {
        const { name, files } = this.props;

        return (
            <div className="file-list">
                <DragAndDrop name={name} files={files} handleDrop={this.handleDrop}>
                    {this.renderFiles(files)}
                </DragAndDrop>
            </div>
        );
    }
}

VideoList.defaultProps = defaultProps;
VideoList.propTypes = propTypes;

export default VideoList;
