import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { importedTemplateStatuses } from "Services/enum";
import { onChange } from "./EventsService";

export const getTableLabel = (key) => {
    const tableLabel = {
        date: "Date",
        status: "Status",
        owner: "Supplier",
        user: "Modified By",
        imported_count: "Number of Imported Projects",
        updated_count: "Number of Updated Projects",
        error_message: "Error Message",
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        date: "Date",
        status: "Select",
        owner: "Search",
        user: "Search",
        imported_count: "Search",
        updated_count: "Search",
        error_message: "Search",
    };

    switch (key) {
        case "date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "status":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{ value: '', label: "-" }], ...importedTemplateStatuses]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "owner":
        case "user":
        case "error_message":
        case "imported_count":
        case "updated_count":
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    name={key}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (<></>);
    }
};
