import _ from "lodash";
import Constants from "../Constants";

const validate = state => {
    return [state.customName, state.solutionLogo].every(
        el => typeof el === "string" && el.trim(),
    );
};

export const initialState = {
    solutionId: '',
    solutionName: '',
    company: '',
    customName: '',
    paintable: '',
    solutionLogo: '',
    molded: {
        yes: false,
        yes_desc: '',
        no: false,
        no_desc: '',
        can_be_matched: false,
        can_be_matched_desc: ''
    },
    is_boundable: '',
    keywords: [],
    contacts: [{ frontId: 0, contact: "", role: "", role_id: "" }],
    locations: [{ frontId: 0, value: "" }],
    countries: [{ frontId: 0, value: "" }],
    colors: [{ frontId: 0, color: '', items: [{ frontId: 0, approval: '', code: '', source: '' }] }],
    suppliers: [{ frontId: 0, value: "" }],
    content: [
        { frontId: 0, value: '', template: '' },
        { frontId: 1, value: '', template: '' }
    ],
    subSegment: '',
    visible: {
        property: true,
        parameter1: true,
        parameter2: true,
        parameter3: true,
        unit1: true,
        unit2: true
    },
    materialProperties: [{
        id: '',
        frontId: 0,
        property: '',
        propertyView: '',
        parameter1: '',
        parameter1View: '',
        parameter2: '',
        parameter2View: '',
        parameter3: '',
        parameter3View: '',
        valueMin1: '',
        valueMax1: '',
        unit1: '',
        unit1View: '',
        valueMin2: '',
        valueMax2: '',
        unit2: '',
        unit2View: ''
    }],
    legal: '',
    legalHTML: '',
    form_validate: false,
    form_loading: false,
    form_errors: {},
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = { ...state };
            _.set(newState, data.key, data.value);
            return { ...newState, form_validate: validate(newState) };
        }

        case Constants.FORM_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.FORM_SUCCEEDED:
            return { ...state, form_loading: false };

        case Constants.FORM_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        case Constants.FORM_RESET:
            return { ...initialState };

        default:
            return state;
    }
};
