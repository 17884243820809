import Constants from "../Constants";

export const initState = {
    activeSubTab: {},
    totalTechnicals: 0,
    totalProcesses: 0,
    totalDesigns: 0,
    totalSafeties: 0,
    totalBrochures: 0,
};

export const reducer = (state, action) => {
    const { data } = action;
    const subTabs = data && data[0] ? data[0].items : [];
    const productItems = subTabs && subTabs[0] ? subTabs[0].product_items : {};
    const totalTechnicals = data && data[0] ? data[0].totalTechnicals : 0;
    const totalProcesses = data && data[0] ? data[0].totalProcesses : 0;
    const totalDesigns = data && data[0] ? data[0].totalDesigns : 0;
    const totalSafeties = data && data[0] ? data[0].totalSafeties : 0;
    const totalBrochures = data && data[0] ? data[0].totalBrochures : 0;

    switch (action.type) {
        case Constants.CHANGE_ACTIVE_SUBTAB: {
            const datasheetData = subTabs[0].datasheet_data || subTabs[0].process_data || subTabs[0].design_data || {};

            return {
                ...state,
                items: [],
                pagination: {},
                columns: [],
                sort: { column: '', direction: "asc" },
                activeSubTab: subTabs[0],
                view: {
                    ...state.view,
                    datasheet: {
                        ...state.view.datasheet,
                        contacts: datasheetData.contacts || [],
                        colors: [],
                        countries: datasheetData.countries || [],
                        paintable: '',
                        molded: '',
                        locations: [],
                        market_segments: [],
                        segments: [],
                        sub_segments: [],
                        manufacturers: [],
                    }
                }
            };
        }

        case Constants.FETCH_TECHNICAL_ITEMS_REQUESTED: {
            if (subTabs.length > 0) {
                const datasheetData = subTabs[0].datasheet_data || subTabs[0].process_data || subTabs[0].design_data || {};

                return {
                    ...state,
                    items: [],
                    pagination: {},
                    columns: [],
                    sort: { column: '', direction: "asc" },
                    subTabs,
                    activeSubTab: subTabs[0],
                    view: {
                        ...state.view,
                        datasheet: {
                            ...state.view.datasheet,
                            contacts: datasheetData.contacts || [],
                            colors: [],
                            countries: datasheetData.countries || [],
                            paintable: '',
                            molded: '',
                            locations: [],
                            market_segments: [],
                            segments: [],
                            sub_segments: [],
                            manufacturers: [],
                        }
                    },
                    totalTechnicals,
                    totalProcesses,
                    totalDesigns,
                    totalSafeties,
                    totalBrochures,
                };
            }

            return {
                ...state,
                items: [],
                pagination: {},
                columns: [],
                sort: { column: '', direction: '' },
                subTabs: [],
                activeSubTab: {},
                view: {
                    ...state.view,
                    datasheet: {
                        contacts: [],
                        colors: [],
                        countries: [],
                        paintable: null,
                        molded: null,
                        locations: [],
                        market_segments: [],
                        segments: [],
                        sub_segments: [],
                        manufacturers: [],
                    }
                },
                totalTechnicals,
                totalProcesses,
                totalDesigns,
                totalSafeties,
                totalBrochures,
            };
        }

        default:
            return state;
    }
};
