import React from "react";
import { compose } from "redux";
// import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const TableRow = ({ pref, children }) => {
    return <div className={`${pref}__table-row`}>{children}</div>;
};

TableRow.propTypes = {};

export default compose(withTagDefaultProps())(TableRow);
