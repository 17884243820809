import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    forecast: {
        selectedForecast: "",
        selectedYear: "",
        items: {},
    },
    totalNotes: 0,
    totalFiles: 0
};

export const reducer = (state, action) => {
    const { data } = action;
    switch (action.type) {
        case Constants.forecast.GET_FORECAST_DATA_REQUEST:
        case Constants.forecast.CHANGE_FORECAST_REQUEST:
        case Constants.forecast.VEHICLE_BUILDS_REQUEST:
            return { ...state, loading: true };

        case Constants.forecast.VEHICLE_BUILDS_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                forecast: {
                    ...state.forecast,
                    selectedYear: data.selectedYear,
                    items: {
                        ...state.forecast.items,
                        forecastDetail: { ...state.forecast.items.forecastDetail, ...data.res },
                    },
                },
            };
        }

        case Constants.forecast.GET_FORECAST_DATA_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                forecast: {
                    ...state.forecast,
                    selectedYear: _.get(data, "forecastDetail.monthDetail.rangeYears[0]", ""),
                    items: { ...data },
                },
                totalNotes: data.totalNotes,
                totalFiles: data.totalFiles,
            };
        }

        case Constants.forecast.CHANGE_FORECAST_SUCCEEDED: {
            return {
                ...state,
                loading: false,
                forecast: {
                    ...state.forecast,
                    selectedForecast: data.selectedForecast,
                    selectedYear: _.get(data.res, "monthDetail.rangeYears[0]", ""),
                    items: {
                        ...state.forecast.items,
                        forecastDetail: { ...state.forecast.items.forecastDetail, ...data.res },
                    },
                },
            };
        }

        case Constants.forecast.GET_FORECAST_DATA_FAILED:
        case Constants.forecast.VEHICLE_BUILDS_FAILED:
        case Constants.forecast.CHANGE_FORECAST_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        case Constants.forecast.RESET_FORECAST_REDUCER: {
            return { ...state, ...initState };
        }
        default:
            return state;
    }
};
