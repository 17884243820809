import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    overview: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.FETCH_ITEM_REQUESTED:
            return { ...state, overview: initState.overview, loading: true };

        case Constants.FETCH_ITEM_SUCCEEDED:
            return { ...state, overview: _.get(data, "items", {}), loading: false };

        case Constants.FETCH_ITEM_FAILED:
            return { ...state, errors: { ...data }, overview: initState.overview, loading: false };

        default:
            return state;
    }
};
