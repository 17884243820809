import _ from "lodash";
import { getStoreItem as _getStoreItem } from "Services";
import { TableActions, TabActions } from "../../../Actions";
import * as FileAction from "../../../Actions/Tabs/FileAction";
import * as FolderAction from "../../../Actions/Tabs/FolderAction";

export const getStoreItem = _getStoreItem("listState");

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...TableActions,
        ...TabActions,
        ...FileAction,
        ...FolderAction,
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
