import React from "react";
import PropTypes from "prop-types";

import { ButtonsGroup } from './ButtonsGroup/ButtonsGroup';


const propTypes = {
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
};

export const ToggleButtons = ({ values, active, handleClick }) => (
  <div className="toggle-btn__general">
    <ButtonsGroup
      values={values}
      handleClick={handleClick}
      active={active}
    />
  </div>
);

ToggleButtons.propTypes = propTypes;
