import Constants from "../Constants";
import {addToPublicationList} from "../Services";

export const onChangeTab = (service, dispatch) => data => {
    dispatch({type: Constants.CHANGE_TAB, data});
};

export const handleCloseModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.CLOSE_MODAL, data});
};

export const handleOpenModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.OPEN_MODAL, data});
};

export const resetFormAction = (service, dispatch) => () => {
    dispatch({type: Constants.RESET_FORM});
};

export const openAddPublicationListModal = (service, dispatch) => () => {
    dispatch({type: Constants.OPEN_ADD_TO_PUBLICATIONS_LIST_MODAL});
};

export const closeAddPublicationListModal = (service, dispatch) => () => {
    dispatch({type: Constants.CLOSE_ADD_TO_PUBLICATIONS_LIST_MODAL});
};

export const savePublicationsList = (service, dispatch) => (payload) => {
    dispatch({type: Constants.ADD_TO_PUBLICATION_LIST_REQUESTED});
    service.addToPublicationList(payload).then(response => {
        dispatch({type: Constants.ADD_TO_PUBLICATION_LIST_SUCCEEDED});
        closeAddPublicationListModal(service, dispatch)();
    }, error => {
        dispatch({type: Constants.ADD_TO_PUBLICATION_LIST_REJECTED});
    });
};

export const updatePublicationForm = (service, dispatch) => (key, value) => {
    dispatch({type: Constants.UPDATE_ADD_TO_PUBLICATION_LIST_FORM, data: {key, value}});
};
