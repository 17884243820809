export const PRODUCT_PUBLICATIONS_VIEW_PERMISSIONS = [
    'product_publications_view',
    'product_publications_edit',
    'product_publications_approve',
    'product_publications_notes_view',
    'product_publications_notes_edit'
];

export const PRODUCT_SOLUTIONS_VIEW_PERMISSIONS = [
    'product_solutions_view',
    'product_solutions_edit',
    'product_solutions_approve',
    'product_solutions_notes_view',
    'product_solutions_notes_edit'
];

export const ADMIN_LISTS_CRUD_PERMISSIONS = [
    "admin_lists_crud",
    "admin_lists_crud_product_source",
    "admin_lists_crud_product_code",
    "admin_lists_crud_application_code",
    "admin_lists_crud_material_type",
    "admin_lists_crud_oem_approval",
    "admin_lists_crud_custom_manufacturer",
    "admin_lists_crud_contact_role",
    "admin_lists_crud_color",
    "admin_lists_crud_color_code",
    "admin_lists_crud_publication_logo",
    "admin_lists_crud_safety",
    "admin_lists_crud_brochure",
];

export const GRADE_PRODUCT_FINDER_VIEW_PERMISSIONS = [
    "grade_product_finder_view",
    "grade_product_finder_edit",
    "grade_product_finder_export",
    "grade_product_sap_view",
    "grade_product_crud_cost",
    "product_project_view",
    "grade_product_analytics",
    "grade_product_invoices",
];

export const PRODUCT_NOTE_VIEW_PERMISSIONS = [
    'product_note_view',
    'product_admin_note_crud',
    'product_pinned_note_crud'
];

export const GRADE_PRODUCT_FILES_PERMISSIONS = [
    "grade_product_files",
    "grade_private_product_files",
];

export const PRODUCT_PUBLICATIONS_GUESTS_PERMISSIONS = [
    "product_publications_guest",
    "product_share_button",
];

export const PRODUCT_SHARED_COMPARE_ACCESS_PERMISSIONS = [
    "product_shared_compare_access",
    "product_onboarding_compare_access",
];

export const FORECAST_GRAPH_VIEW_PERMISSIONS = [
    "forecast_graph_view",
    "forecast_people_analytics_view",
    "forecast_project_analytics_view",
];

export const PROJECT_CRUD_PERMISSIONS = [
    "project_crud",
    "project_companies_team_view",
    "project_edit_all",
    "project_suggested_price",
    "project_view_cost",
];

export const IHV_LVP_ACCESS_PERMISSIONS = [
    "ihv_lvp_access",
    "forecast_project_view",
];

export const FORECAST_ALL_UNITS_ACCESS_PERMISSIONS = [
    "forecast_all_units_access",
    "forecast_all_units_view",
    "forecast_all_units_project_tab",
];

export const FINANCE_VIEW_PERMISSIONS = [
    "finance_view",
    "finance_view_export",
];

export const FORECAST_INVOICES_VIEW_PERMISSIONS = [
    "forecast_invoices_view",
    "forecast_invoices_export",
    "forecast_invoices_export_ZComOpps",
];

export const CONNECTION_VIEW_PERMISSIONS = [
    'connection_view',
    'connection_export',
    'connection_new_button',
    'connection_budget_view',
    'connection_budget_export',
    'connection_budget_new_button',
];

export const PLANT_RAW_VIEW_PERMISSIONS = [
    'plant_raw_view',
    'plant_raw_crud',
    'plant_line_crud'
];

export const COMPANIES_VIEW_PERMISSIONS = [
    'companies_view',
    'companies_project_view',
    'companies_export'
];

export const VENDOR_VIEWS_PERMISSIONS = [
    'vendors_view',
    'vendors_edit',
];

export const CONTACTS_VIEW_PERMISSIONS = [
    'contacts_view',
    'contacts_project_view',
];