import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Row, Column } from "Templates/Table/NewVersion";

const TableBodyColumnDefault = ({ items, service: { columns, getBodyView } }) => {
    return (
        <>
            {items.map((item, key) => (
                <Row key={`Item-${key}`}>
                    {columns.map((column, index) => (
                        <Column key={`Column-${index}`}>{getBodyView(column, item)}</Column>
                    ))}
                </Row>
            ))}
        </>
    );
};

TableBodyColumnDefault.propTypes = {
    items: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
    service: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.string).isRequired,
        getBodyView: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem, itemsName } = ownProps.service;
    return {
        items: getStoreItem(state, itemsName),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TableBodyColumnDefault);
