import { debounce } from "Services/Debounce";
import Constants from "../Constants";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());
    const { sort } = data;
    const { requestTable } = service;

    requestTable(data).then(
        res => dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } })),
        res => dispatch(requestFailed(res)),
    );
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });
    if (["modified"].includes(key)) {
        fetchItems(service, dispatch)(request);
    } else if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { requestTable } = service;

    requestTable(data).then(res => dispatch(requestNextPageSucceeded(res)), res => dispatch(requestFailed(res)));
};

export const deleteTableItemAction = (service, dispatch) => data => {
    dispatch({ type: Constants.DELETE_ITEM_REQUESTED, data });

    const { requestOnDeleteItem } = service;

    return requestOnDeleteItem(data).then(
        data => dispatch({ type: Constants.DELETE_ITEM_SUCCEEDED, data }),
        data => dispatch({ type: Constants.DELETE_ITEM_FAILED, data }),
    );
};
