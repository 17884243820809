import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockTitle, Block, BlockShadow, Tooltip } from "Templates/Form";
import { Span } from "Templates/Default";
import { LinkIcon } from "Templates/Link";
import { ServiceProvider, withServiceConsumer } from "Services/Context";
import * as _forecastService from "../../Services/Table/ForecastService";
import ForecastsBlock from "./Table/Forecast/ForecastsBlock";

const propTypes = {
    service: PropTypes.shape({
        storeName: PropTypes.string.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    showFull: PropTypes.bool.isRequired,
};

const ForecastBlock = ({ service: { getStoreItem, getActionStore, storeName }, showFull, t }) => {
    const forecastService = {
        ..._forecastService,
        getStoreItem,
        getActionStore,
        storeName,
    };

    return (
        <>
            <BlockTitle>
                <Span isRequired={showFull}>{t("Forecast")}</Span>
                <Tooltip>{t(`Select a forecast(s) to associate with this project`)}</Tooltip>
                <LinkIcon blank url="/project/forecasts/create">
                    {t("Add New")}
                </LinkIcon>
            </BlockTitle>

            <Block>
                <BlockShadow className="project__multiline-items-container forecasts-container">
                    <ServiceProvider value={forecastService}>
                        <ForecastsBlock/>
                    </ServiceProvider>
                </BlockShadow>
            </Block>
        </>
    );
};

ForecastBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        showFull: getStoreItem(state, "show_full"),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(ForecastBlock);
