import _ from "lodash";
import {VideoActions, VideoFolderAction} from "../Actions";

export const getStoreItem = (state, key) => _.get(state, `listState.${key}`);

export const getActionStore = (action, service, dispatch) => {
    const actions = {
        ...VideoActions,
        ...VideoFolderAction
    };

    return _.get(actions, action, () => {})(service, dispatch);
};
