import { handleScroll } from "./EventsService";
import { requestEditVendor, requestOnDeleteVendorCode, requestSaveVendor, requestTable } from "./RequestService";
import { getActionStore, getStoreItem } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues, tableButtonsPermission } from "./TableBodyService";
import * as FormService from "./Tabs/Vendors/FormService";

export {
    getViewItem,
    modifierValues,
    tableButtonsPermission,
    getFilter,
    getTableLabel,
    requestTable,
    requestSaveVendor,
    requestEditVendor,
    requestOnDeleteVendorCode,
    handleScroll,
    getStoreItem,
    getActionStore,
    FormService,
};
