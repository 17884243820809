import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    data: {},
    old: {},
    form: {
        marginListSelect: "",
        dynamicSelect: "",
        statusProject: "0",
        items: [],
        periodFrom: new Date().getFullYear().toString(),
        periodTo: new Date().getFullYear().toString(),
        timeScale: "",
    },
    form_request: false,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_FORM: {
            const newState = _.cloneDeep(state);
            _.set(newState, data.key, data.value);

            if (data.key.endsWith("periodFrom") && newState.form.periodTo < data.value) {
                _.set(newState, "form.periodTo", data.value);
            }

            return { ...newState };
        }

        case Constants.CHANGE_SECOND_SELECT: {
            const newState = _.cloneDeep(state);
            _.set(newState, "form.dynamicSelect", data.value);
            _.set(newState, "form.items", initState.form.items);

            return { ...newState };
        }

        case Constants.REMOVE_ITEM: {
            return {
                ...state,
                form: { ...state.form, items: state.form.items.filter(elem => elem.frontId !== data.frontId) },
            };
        }

        case Constants.RESET_FORM: {
            return {
                ...state,
                data: initState.data,
                old: { ...initState.old },
                form: { ...initState.form },
                form_validate: initState.form_validate,
            };
        }

        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return { ...state, ...data, old: _.cloneDeep(state.form), loading: false };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, errors: { ...data }, loading: false };

        default:
            return state;
    }
};
