import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    column: PropTypes.string.isRequired,
    service: PropTypes.shape({ getTableLabel: PropTypes.func.isRequired }).isRequired,
};

const TableHeadTitle = ({ column, service, pref }) => {
    const { getTableLabel } = service;
    return <span className={`${pref}__table-head-link main-table__head-link`}>{getTableLabel(column)}</span>;
};

TableHeadTitle.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableHeadTitle);
