const tableColumns = {
    invoice_number: "Invoice Number",
    customer: "Customer",
    customer_full: "Customer Full Name",
    customer_code: "Customer Code",
    billing_date: "Billing Date",
    created_by: "Created By",
    created_on: "Created On",
    reversal_of: "Reversal Of",
    currency: "Currency",
    billing_type: "Billing Type",
    billing_category: "Billing Category",
    document_category: "Document Category",
    line_number: "Invoice Line",
    order_country: "Order Country",
    order_region: "Order Region",
    grade: "Product Name",
    material_number: "Material Number",
    product_code: "SAP Product Code",
    application_code: "SAP Application Code",
    plant: "Plant Name",
    quantity: "Billed Quantity",
    sales_unit: "Sales Unit",
    net_amount: "Net Amount",
    cost: "Cost",
    fixed_cost: "Fixed Cost",
    variable_cost: "Variable Cost",
    business_area: "Business Area",
    gross_weight: "Gross Weight",
    gross_weight_unit: "Gross Weight Unit",
    normalized_weight: "Normalized Weight",
    normalized_weight_unit: "Normalized Weight Unit",
    order_number: "Sales Order",
    order_line_number: "Sales Order Line",
    reference_invoice_number: "Reference Invoice",
    reference_invoice_line_number: "Reference Invoice Line",
    freight_amount: "Freight Amount",
    gross_margin: "Gross Margin",
    gross_margin_percent: "Gross Margin %",
    price_kg: "Price per KG",
    cost_kg: "Cost per KG",
    cancelled: "Cancelled",
    surcharge_zb00: "Freight",
    surcharge_zb04: "Price Adj",
    surcharge_zb05: "Freight Surcharge",
    surcharge_zd00: "Misc Charges",
    free_of_charge: "Free Of Charge",
};

export default tableColumns;
