import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Publication Name",
        slug: "Publication ID",
    };

    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        name: "Select",
        slug: "Select",
    };

    switch (key) {
        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/admin/publications/slugs-archived"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "name":
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
