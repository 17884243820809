import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { url } from "Services";
import {Title} from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft } from "Templates/Form";
import { BackLink } from "Templates/Link";
import { withTagDefaultProps } from "Hoc/Template";
import FormBlock from "../../Views/Form";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
};

const Material = ({ validate, formData, pref, t, submitForm }) => {
    const saveMaterail = () => {
        submitForm(formData).then(() => url.redirect(`/project/material`), errors => alert(errors.detail));
    };

    const handleClickOnCancelButton = () => {
        if (confirm(t("Cancel?"))) {
            url.redirect("/project/material");
        }
    };

    return (
        <>
            <Title
                title={t("Add New Material")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <button
                        type="button"
                        className={`${pref}__buttons-btn main-btn`}
                        onClick={handleClickOnCancelButton}
                    >
                        {t("Cancel")}
                    </button>
                </ButtonRight>
                <ButtonLeft>
                    <button
                        type="button"
                        className={`${pref}__buttons-btn main-btn`}
                        onClick={saveMaterail}
                        disabled={!validate}
                    >
                        {t("Add Material")}
                    </button>
                </ButtonLeft>
            </BlockSubmit>
        </>
    );
};

Material.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        formData: state.createState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
        resetForm: getActionStore("resetForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Material);
