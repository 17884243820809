import React from "react";
import { useSelector } from 'react-redux';
import {isSapImported} from "Services/enum";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { weightUnitName } from 'Services/weightUnitName';
import { onChange } from "../../EventsService";

export const getTableLabel = key => {
  const unit = useSelector(state => state.weightUnitState);

    const tableLabel = {
        plant: "Plant",
        cost: "MAP",
        price_per: "Per (lbs)",
        created_date: "Date",
        is_obsolete: "Obsolete Marker",
        marked_deletion: "Deletion Marker",
        cost_per_lb: `MAP ($/${weightUnitName(unit.weightUnit)})`,
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {

    const placeholders = {
        plant: "Search",
        cost: "Search",
        price_per: "Search",
        created_date: "Date",
        cost_per_lb: "Search",
        is_obsolete: "Select",
        marked_deletion: "Select",
    };
    switch (key) {
        case "marked_deletion":
        case "is_obsolete":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "created_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
