import Constants from "../Constants";

export const initialState = {
    pref: '',
    storage_url: '/storage',
    editorKey: '',
    alerts: [],
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case Constants.LIST_FILTER:
            return { ...state };

        default:
            return state;
    }
}
