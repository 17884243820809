import React from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";

import AnalitycsTableBodyColumn from "./Table/TableBodyColumn";
import * as AnalitycsService from "../../../../../Services/Tabs/General/Table/AnalitycsService";
import {ServiceProvider} from "Services/Context";

const AnalitycsTable = CustomSimpleTable()(() => null)()(AnalitycsTableBodyColumn)()(() => null);
const AnalitycsBlock = () => {
    return (
        <ServiceProvider value={AnalitycsService}>
            <AnalitycsTable />
        </ServiceProvider>
    );
};

AnalitycsBlock.propTypes = {};

export default compose(withTagDefaultProps())(AnalitycsBlock);
