import React from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import Img from "Templates/Img";
import {withTagDefaultProps} from "Hoc/Template";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    index: PropTypes.number.isRequired,
    customIcon: PropTypes.string.isRequired,
    onClickShare: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};

const TableRowItem = ({index, customIcon, onClickShare, onClickEdit, onClickCustom, onClickDelete, item}) => {
    const _onClickShare = () => {
        onClickShare(item)
    };

    const _onClickCustom = () => {
        onClickCustom(index);
    };

    const _onClickEdit = () => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to edit.');
            return;
        }

        onClickEdit(index);
    };

    const _onClickDelete = () => {
        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        onClickDelete(index);
    };

    return (
        <TableBodyButtonsBlock>
            <TableBodyButton onClick={_onClickShare}>
                <Img img="icon_share_file"/>
            </TableBodyButton>
            {customIcon && (
                <TableBodyButton onClick={_onClickCustom}>
                    <Img img={customIcon}/>
                </TableBodyButton>
            )}
            <TableBodyButton onClick={_onClickEdit}>
                <Img img="icon_edit"/>
            </TableBodyButton>
            <TableBodyButton onClick={_onClickDelete}>
                <Img img="icon_delete"/>
            </TableBodyButton>
        </TableBodyButtonsBlock>
    );
};

TableRowItem.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowItem);
