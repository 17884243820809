import _ from "lodash";
import Constants from "../../Constants";
import {changeProduct, validate} from "../../Services/Reducers/LogicService";
import {getTotalPercentProducts} from "../../Services/Reducers/MathService";

const productModel = {
    frontId: 0,
    unique: true,
    isSap: false,
    name: "",
    grade: "",
    plant: "",
    line: '-',
    invoiceCount: 0,
    productCode: '-',
    code: '-',
    actualSale: "",
    rebate: "",
    finalPrice: "0",
    percentOfProduct: "100",
};

export const initialState = {
    products: [{ ...productModel }],
    totalPercent: 100,
};

export const reducer = (state = initialState, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_PRODUCT: {
            return changeProduct(state, data);
        }

        case Constants.ADD_NEW_PRODUCT: {
            const {
                productBlock: { products },
            } = state;
            const newProduct = {
                ...productModel,
                frontId: products.length ? products[products.length - 1].frontId + 1 : 0
            };
            _.set(state, "productBlock.products", [...products, newProduct]);
            _.set(state, "productBlock.totalPercent", getTotalPercentProducts(state.productBlock.products));
            return {
                ...state,
                form_validate: validate(state),
                productBlock: {
                    ...state.productBlock,
                    products: [...products, newProduct],
                },
            };
        }

        case Constants.DELETE_PRODUCT: {
            let products = state.productBlock.products.filter(item => item.frontId !== data.key);
            const uniqueList = [];

            products = products.map(item => ({
                ...item,
                unique: (() => {
                    if (item.grade && uniqueList.includes(item.grade)) {
                        return false;
                    }
                    uniqueList.push(item.grade);
                    return true;
                })(),
            }));
            _.set(state, "productBlock.totalPercent", getTotalPercentProducts(products));
            _.set(state, "productBlock.products", products);
            return {
                ...state,
                form_validate: validate(state),
                productBlock: {
                    ...state.productBlock,
                    products,
                },
            };
        }

        case Constants.CHANGE_GRADE: {
            if (typeof data.index === "number") {
                const productBlock = state.productBlock.products.find((elem, index) => index === data.index);
                _.set(productBlock, "grade", data.value);
                _.set(productBlock, "plant", '');
                _.set(productBlock, "line", '-');
                _.set(productBlock, "productCode", '-');
                _.set(productBlock, "code", '-');

                const uniqueList = [];
                const products = state.productBlock.products.map(item => ({
                    ...item,
                    unique: (() => {
                        if (item.grade && uniqueList.includes(item.grade)) {
                            return false;
                        }
                        uniqueList.push(item.grade);
                        return true;
                    })(),
                }));
                return {
                    ...state,
                    form_loading: false,
                    form_validate: validate(state),
                    productBlock: { ...state.productBlock, products },
                };
            }

            return state;
        }

        case Constants.CHANGE_PLANT_REQUESTED:
            return { ...state, form_loading: true };

        case Constants.CHANGE_PLANT_SUCCEEDED: {
            if (typeof data.index === "number") {
                const productBlock = state.productBlock.products.find((elem, index) => index === data.index);
                _.set(productBlock, "plant", data.value || '');
                _.set(productBlock, "sub_status", data.sub_status || '-');
                _.set(productBlock, "line", data.line || '-');
                _.set(productBlock, "invoiceCount", data.invoice_count || 0);
                _.set(productBlock, "code", data.code || '-');
                _.set(productBlock, "productCode", data.productcode || '-');
                _.set(productBlock, "actualSale", "");
                _.set(productBlock, "rebate", "");
                _.set(productBlock, "finalPrice", "0");

                const uniqueList = [];
                const products = state.productBlock.products.map(item => ({
                    ...item,
                    unique: (() => {
                        if (item.grade && uniqueList.includes(item.grade)) {
                            return false;
                        }
                        uniqueList.push(item.grade);
                        return true;
                    })(),
                }));

                return {
                    ...state,
                    form_loading: false,
                    form_validate: validate(state),
                    productBlock: { ...state.productBlock, products },
                };
            }

            return state;
        }

        case Constants.CHANGE_PLANT_FAILED:
            return { ...state, form_errors: { ...data }, form_loading: false };

        default: {
            return { ...state };
        }
    }
};
