import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import { filter } from "Services";
import _ from "lodash";
import reducers, { initialStates } from "../Reducers";
import {initState} from "../Reducers/ViewReducer";

export default function configureStore(props) {
    const { items, pagination, columns, pinned_columns, updated_date, user, help } = props;
    const { mainState } = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "compare-products" }),
        listState: {
            ...mainState,
            items,
            pagination,
            pinned_columns,
            columns,
            permissions: user.permissions,
            userFullName: user.fullName,
            isFirstLogin: user.isFirstLogin,
            filter: filter.getParsedFilters(),
            sort: filter.getSort(),
            updated_date,
            help
        },
    };

    if (initState.activeTab) {
        _.unset(initialState.listState, "items");
    }

    return _configureStore(initialState, reducers);
}
