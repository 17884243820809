import React from "react";
import { Span } from "Templates/Default";

export const colorClass = value => {
    if (value < 0) {
        return "bold-text clr-red";
    }

    if (value > 0) {
        return "bold-text clr-green";
    }

    return "";
};

export const getTableView = ({ className = "", label }) => <Span className={className}>{label}</Span>;
