import React, { useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import { isEqual, url } from "Services";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Company = ({ old, validate, formData, pref, t, submitForm, permissions }) => {
    useEffect(() => {
        if(!permissions.includes('admin_organizations_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])
    const saveCompany = () => {
        submitForm(formData).then(
            data => {
                url.redirect(`/admin/companies/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (window.confirm(t("Cancel?"))) {
            url.redirect("/admin/companies");
        }
    };

    const validateAll = validate ? !isEqual({ ...formData }, old, _.keys(old)) : validate;

    return (
        <CheckPermission permission="admin_organizations_crud">
            <Title
                title={t("Edit Company")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock edit />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>{t("Cancel")}</SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validateAll} onClick={saveCompany}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </CheckPermission>
    );
};

Company.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        validate: getStoreItem(state, "form_validate"),
        old: getStoreItem(state, "old"),
        // errors: getStoreItem(state, "errors"),
        formData: state.editState,
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitForm", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Company);
