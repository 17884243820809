import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas, url } from "Services";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { TabWrap, MainButtonLink } from "Templates/Content";
import { Title, WrapButtons, Total } from "Templates/Titles";
import TabViewIndex from "./TabViewIndex";

const propTypes = {
    onClickEdit: PropTypes.func.isRequired,
    getUrlExport: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    title: PropTypes.number.isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
};

const TabView = ({ onClickEdit, onClickDelete, onClickCustom, t, title, request, getUrlExport }) => {
    const handleExportUsersClick = (e) => {
        e.preventDefault();
        getUrlExport(url.getUrl('/admin/users/export', request));
    }

    return (
        <>
            <Title title={t("All Users")} total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}>
                <WrapButtons>
                    <MainButtonLink
                        disabled
                        className="main-btn_white"
                        onClick={(e) => handleExportUsersClick(e)}
                    >
                        {t("Export Users")}
                    </MainButtonLink>
                    <MainButtonLink href="/admin/users/create" className="main-btn_primary">
                        {t("Add New User")}
                    </MainButtonLink>
                </WrapButtons>
            </Title>
            <TabWrap>
                <TabViewIndex onClickCustom={onClickCustom} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        title: getStoreItem(state, "pagination.total"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        getUrlExport: getActionStore("getUrlExport", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
