import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const Count = ({ pref, className, children }) => <div className={`${pref}__count ${className}`}>{children}</div>;

Count.propTypes = {
    className: PropTypes.string,
};

Count.defaultProps = {
    className: "",
};

export default compose(withTagDefaultProps())(Count);
