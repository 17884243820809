import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";

const RadioButton = ({ isDisabled, pref, htmlFor, name, value, onChange, children }) => {
    const _onChange = ({ target }) => onChange(target.checked);
    return (
        <>
            <input
                id={htmlFor}
                type="radio"
                disabled={isDisabled}
                name={name}
                checked={value}
                onChange={_onChange}
                className={`${pref}__form-radio-input main-content__radio-input`}
            />
            <label htmlFor={htmlFor} className={`${pref}__form-radio-label main-content__radio-label`}>
                {children}
            </label>
        </>
    );
};

RadioButton.defaultProps = {
    name: "radio",
    isDisabled: false,
};

RadioButton.propTypes = {
    isDisabled: PropTypes.bool,
    htmlFor: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};
export default compose(withTagDefaultProps())(RadioButton);
