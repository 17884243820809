import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { PermissionsProps, withTagDefaultProps } from "Hoc/Template";

const Text = (pref, value) => <div title={value} className={`${pref}__info-block-text main-content__info-block-text`}>{value}</div>;

const TextEmail = (pref, email) => (
    <a href={`mailto:${email}`} className={`${pref}__info-block-text main-content__info-block-text`} title={email}>
        {email}
    </a>
);

const TextPhone = (pref, value) => (
    <div className={`${pref}__info-block-text main-content__info-block-text`} title={value}>{value}</div>
);

const TextLink = (pref, url, title) => (
    <a href={`${url}`} target="_blank" rel="noopener noreferrer" className={`${pref}__info-block-text main-content__info-block-text`} title={title}>
        {title}
    </a>
);

const propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
};

const InfoBlockText = ({ pref, type, permissions, permission, title, value }) => {

    if(permission && !permissions.includes(permission)){
        return Text(pref, title || value)
    }

    switch (type) {
        case "email":
            return TextEmail(pref, value);

        case "link":
            return TextLink(pref, value, title || value);

        case "phone":
            return TextPhone(pref, value);

        default:
            return Text(pref, value);
    }
};

InfoBlockText.propTypes = propTypes;

export default compose(withTagDefaultProps())(PermissionsProps(InfoBlockText));
