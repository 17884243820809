import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";

export const getTableLabel = (key) => {
    const tableLabel = {
        name: "Product Name",
        material_number: "Material Number",
        sap_plant: "SAP Plant",
        sap_line: "SAP Production Line",
    };
    switch (key) {
        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = () => (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        material_number: "Search",
        sap_plant: "Search",
        sap_line: "Search",
    };
    switch (key) {
        case "sap_plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/plant-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_line":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/sap-line-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
