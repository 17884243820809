import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import {withTagDefaultProps} from "Hoc/Template";
import {formatDateToLocal} from "Services";
import {withServiceConsumer} from "Services/Context";
import { ModalDefault } from "Templates/Modals";
import { ButtonClose } from "Templates/Button";
import { Button } from "Templates/Default";
import Img from "Templates/Img/Img";

const { Form } = ModalDefault;

const propTypes = {
    email: PropTypes.string.isRequired,
    csrf_token: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    sessions: PropTypes.arrayOf(
        PropTypes.shape({
            session_id: PropTypes.string.isRequired,
            ip_address: PropTypes.string.isRequired,
            browser: PropTypes.string.isRequired,
            country: PropTypes.string,
            city: PropTypes.string,
            date: PropTypes.string.isRequired,
        }),
    ).isRequired,
    deleteSession: PropTypes.func.isRequired,
    closeModalAction: PropTypes.func.isRequired,
};

const SessionsModal = ({ email, csrf_token, password, sessions, deleteSession, closeModalAction, t }) => {
    const onClose = () => {
        closeModalAction();
    };

    const handleClickOnButton = session_id => () => {
        deleteSession({ session_id, _csrf_token: csrf_token, password, email });
    };

    const getBrowserImg = browser => {
        let ico = 'session_unknown';

        switch (browser) {
            case "Firefox":
                ico = "session_firefox";
                break;

            case "Chrome":
                ico = "session_chrome";
                break;

            case "Edge":
                ico = "session_edge";
                break;

            case "InternetExplorer":
                ico = "session_ie";
                break;

            case "Opera":
                ico = "session_opera";
                break;

            case "Safari":
                ico = "session_safari";
                break;
        }

        return <Img img={ico} alt="browser-logo" />;
    };

    return (
        <Form>
            <ButtonClose className="main-modal__form-close" onClick={onClose} />
            <div className="session">
                <div className="session__title">{t("You already have an active session")}</div>
                <div className="session__subtitle">{t("Log out to use the service")}</div>
                <ul className="session__list">
                    {sessions.map(elem => (
                        <li key={`Row-${elem.session_id}`} className="session__item">
                            <div className="session__image">{getBrowserImg(elem.browser)}</div>
                            <div className="session__info">
                                <div className="session__info-title">{t(elem.browser)}</div>
                                <div className="session__info-subtitle">
                                    {`${elem.ip_address} 
                                    ${elem.country ? `${elem.country},` : ""} 
                                    ${elem.city ? `${elem.city},` : ""} 
                                    ${formatDateToLocal(elem.date, "MMM DD, YYYY h:mm A")}`}
                                </div>
                            </div>
                            <Button
                                onClick={handleClickOnButton(elem.session_id)}
                                className="size_normal main-btn main-btn_error"
                            >
                                {t("Disconnect")}
                            </Button>
                        </li>
                    ))}
                </ul>
            </div>
        </Form>
    );
};

SessionsModal.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        password: getStoreItem(state, "password"),
        sessions: getStoreItem(state, "sessions"),
        csrf_token: getStoreItem(state, "csrf_token"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        deleteSession: getActionStore("deleteSessionAction", service, dispatch),
        closeModalAction: getActionStore("closeModalAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SessionsModal);
