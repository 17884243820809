import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import PropTypes from "prop-types";
import {ButtonClose} from "Templates/Button";
import React, {Fragment, useEffect, useState} from "react";
import {ModalDefault} from "Templates/Modals";
import {Block, BlockWrap, ButtonRight, Input, WrapInput} from "Templates/Form";
import ButtonLeft from "Templates/Form/BlockSubmit/ButtonLeft";
import BlockSubmit from "Templates/Form/BlockSubmit/BlockSubmit";
import {regExpForFloat, regExpForInteger, uniqueGenerator} from "Services";
import {LinkIcon} from "Templates/Link";

const { Button, Form, Title } = ModalDefault;

const propTypes = {
    validityPeriods: PropTypes.arrayOf(PropTypes.any).isRequired,
    periods_form: PropTypes.objectOf(PropTypes.any).isRequired,
    updateValidityPeriodForm: PropTypes.func.isRequired,
    changeValidityPeriodForm: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    addValidityPeriod: PropTypes.func.isRequired,
    periodsModalType: PropTypes.string.isRequired,
};

const ValidityPeriodModalForm = (
    {
        periodsModalType,
        validityPeriods,
        t,
        periods_form,
        updateValidityPeriodForm,
        changeValidityPeriodForm,
        closeModal,
        addValidityPeriod
    }) => {
    const { effective_from_date, quantity_per_unit, unit, scale_quantities } = periods_form;
    const [invalidValueIndex, setInvalidValueIndex] = useState(-1)
    const [duplicatedPeriod, setDuplicatedPeriod] = useState(false)

    useEffect(() => {
        if (!validityPeriods.length) return;
        const list = validityPeriods.filter((item) => periods_form.id ? item.id !== periods_form.id : item.front_id !== periods_form.front_id)
        const existingPeriodDate = list.findIndex((item) => {
            return item.effective_from_date === effective_from_date
        });

        if (existingPeriodDate > -1) {
            setDuplicatedPeriod(true);
            alert('Error! You can\'t save Validity Period with same Effective From Date.');
        } else {
            setDuplicatedPeriod(false)
        }
    }, [validityPeriods, effective_from_date])

    useEffect(() => {
        const invalidIndex = scale_quantities.findIndex((value, index) => {
            if (index === 0 || !value.scale_quantity) return

            // eslint-disable-next-line consistent-return
            return +scale_quantities[index - 1].scale_quantity >= +value.scale_quantity
        });
        setInvalidValueIndex(invalidIndex)
    }, [scale_quantities])

    const isValidForm = () => {
        return periods_form.scale_quantities.every(({ scale_quantity }) => {
            return !!scale_quantity
        });
    };

    const formValidate = () => {
        const priceValidation = periods_form.scale_quantities.every(({ period_price }) => {
            return !!period_price
        });

        return !effective_from_date || !quantity_per_unit || !unit || !priceValidation || !isValidForm() || duplicatedPeriod || invalidValueIndex > -1;
    }

    const onChangeInput = (value, key) => {
        changeValidityPeriodForm(key, value)
    }

    const onUpdateItem = (value, index, key) => {
        const updatedScaleQuantitiesList = scale_quantities.map((item, itemIndex) => {
            return itemIndex === index ? { ...item, [`${key}`]: value } : item;
        });
        updateValidityPeriodForm(updatedScaleQuantitiesList, `${key}`);
    };

    const onAddItem = () => {
        updateValidityPeriodForm([...periods_form.scale_quantities, {
            id: "",
            front_id: uniqueGenerator(),
            scale_quantity: "",
            period_price: ""
        }]);
    };

    const onRemoveItem = () => {
        const index = scale_quantities.length - 1;
        const array = scale_quantities.filter((_, itemIndex) => itemIndex !== index);
        updateValidityPeriodForm(array);
    };

    const onSave = () => {
        addValidityPeriod(periods_form)
        closeModal()
    }

    return (
        <Form className="main-modal__form main-modal__validate-period">
            <ButtonClose className="main-modal__form-close" onClick={closeModal}/>
            <Title>{periodsModalType === 'create' ? t('New Validity Period') : t('Edit Validity Period')}</Title>
            <Block>
                <BlockWrap>
                    <WrapInput label={t("Effective From")} name="effective_from_date">
                        <Input
                            className={`${duplicatedPeriod ? 'error' : ''}`}
                            type="date"
                            placeholder={t("MM/DD/YYYY")}
                            value={effective_from_date}
                            onChange={value => {
                                onChangeInput(value, `periods_form.effective_from_date`)
                            }}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput label={t(`Per (${unit})`)} name="quantity_per_unit">
                        <Input
                            placeholder={t("Input")}
                            value={quantity_per_unit}
                            onChange={value => regExpForInteger(value, data => onChangeInput(data, 'periods_form.quantity_per_unit'))}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput label={t(`Unit`)} name="unit">
                        <Input
                            placeholder={t("Input")}
                            value={unit}
                            onChange={value => onChangeInput(value, 'periods_form.unit')}
                        />
                    </WrapInput>
                </BlockWrap>
            </Block>
            <div className="scale_quantities-list">
                <div className="scale_quantities-list__wrap">
                    <Block>
                        <BlockWrap>
                            <h2 className="validity-periods-title">{t('Scale Quantity (from)')}</h2>
                        </BlockWrap>
                        <BlockWrap>
                            <h2 className="validity-periods-title">{t('Price (USD)')}</h2>
                        </BlockWrap>
                    </Block>
                    {
                        scale_quantities.map((item, i) => {
                            return (
                                <Fragment key={`quantity-${item.front_id}`}>
                                    <Block>
                                        <BlockWrap>
                                            <WrapInput
                                                className={invalidValueIndex === i ? 'error' : ''}
                                                name={`scale_quantity-${item.front_id}`}
                                            >
                                                <Input
                                                    placeholder={t("Input")}
                                                    value={item.scale_quantity}
                                                    disabled={i === 0}
                                                    onChange={
                                                        value => {
                                                            regExpForInteger(value, data => onUpdateItem(data, i, 'scale_quantity'))
                                                        }
                                                    }
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                        <BlockWrap>
                                            <WrapInput name={`period_price-${item.front_id}`}>
                                                <Input
                                                    placeholder={t("Input")}
                                                    value={item.period_price}
                                                    onChange={
                                                        value => regExpForFloat(value, data => onUpdateItem(data, i, 'period_price'))
                                                    }
                                                />
                                            </WrapInput>
                                        </BlockWrap>
                                    </Block>
                                </Fragment>
                            )
                        })
                    }
                </div>
                {scale_quantities.length > 1 ? (
                    <LinkIcon
                        className="icon-delete"
                        icon="la-icon-close"
                        onClick={onRemoveItem}
                    />
                ) : null}
            </div>
            <BlockSubmit>
                <ButtonRight>
                    <button
                        type="button"
                        onClick={onAddItem}
                        disabled={invalidValueIndex > -1 || !isValidForm() || duplicatedPeriod}
                        className={`${invalidValueIndex > -1 || !isValidForm() ? 'disabled' : ''} main-modal__form-btn size_normal main-btn main-btn_white`}
                    >
                        {t('Add Scale Quantity')}
                    </button>
                </ButtonRight>
                <ButtonLeft>
                    <Button
                        onClick={() => {
                            onSave()
                        }}
                        disabled={formValidate()}
                    >
                        {t('Save')}
                    </Button>
                </ButtonLeft>
            </BlockSubmit>
        </Form>
    )
};

ValidityPeriodModalForm.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        periods_form: getStoreItem(state, "periods_form"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;
    return {
        closeModal: getActionStore("closeModal", service, dispatch),
        updateValidityPeriodForm: getActionStore("updateValidityPeriodForm", service, dispatch),
        changeValidityPeriodForm: getActionStore("changeValidityPeriodForm", service, dispatch),
    };
};

export default compose(withServiceConsumer, withTagDefaultProps(mapStateToProps, mapDispatchToProps),)(ValidityPeriodModalForm);
