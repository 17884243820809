import _ from "lodash";
import { formatDate } from "Services";

export const columns = {
    vehicle_id: "Vehicle ID",
    vehicle: "Vehicle",
    manufacturer: "Manufacturer",
    nameplate: "Nameplate",
    program: "Program",
    platform: "Platform",
    segment: "Segment",
    architecture: "Architecture",
    sop: "SOP",
    eop: "EOP",
};

export const getFormatValue = (item, key) => {
    const value = _.get(item, key, "");

    switch (key) {
        case "sop":
        case "eop": {
            return formatDate(value);
        }
        default:
            return value;
    }
};
