import React from "react";
import { getString } from "Services";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";

export const modifierValues = companies => companies;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "id")}`}
                    img={getString(item, "photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );
        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
