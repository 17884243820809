import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const statusList = {
    inProgress: "status-opportunity",
    opportunity: "status-opportunity",
    loss: "status-loss",
    out: "status-out",
    current: "status-current",
};

const propTypes = {
    status: PropTypes.oneOf(["inProgress", "opportunity", "loss", "out", "current"]).isRequired,
};

const SinglePreviewStatus = ({ pref, status, children }) => (
    <div className={`${pref}__single__body-status ${statusList[status]}`}>{children}</div>
);

SinglePreviewStatus.propTypes = propTypes;

export default compose(withTagDefaultProps())(SinglePreviewStatus);
