import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { numberWithCommas } from "Services";
import { withTagDefaultProps, CheckPermission } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { Title, WrapButtons, Total } from "Templates/Titles";
import TabViewIndex from "Templates/Tabs/DefaultWrap/TabViewIndex";
import { MainButtonLink, TabWrap } from "Templates/Content";

const defaultProps = {
    help: ''
}
const propTypes = {
    title: PropTypes.number.isRequired,
    help: PropTypes.string,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickCustom: PropTypes.func.isRequired,
};

const TabView = ({ onClickEdit, onClickCustom, onClickDelete, t, title, help }) => {
    return (
        <>
            <Title
                title={t("Plants")}
                help={help}
                total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}
            >
                <CheckPermission permission="plant_raw_crud">
                    <WrapButtons>
                        <MainButtonLink href="/product/plants/create" className="main-btn_primary">
                            {t("Add New Plant")}
                        </MainButtonLink>
                    </WrapButtons>
                </CheckPermission>
            </Title>
            <TabWrap>
                <TabViewIndex customIcon={'icon_external_link'} onClickCustom={onClickCustom} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
            </TabWrap>
        </>
    );
};

TabView.propTypes = propTypes;
TabView.defaultProps = defaultProps;

const mapStateToProps = state => {
    return {
        title: state.listState.pagination.total,
        help: state.listState.help,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(TabView);
