import { url } from "Services";
import { handleScroll } from "../../EventsService";
import { requestTable, deleteItem } from "./RequestService";
import { getActionStore } from "./StoreService";
import { getFilter, getTableLabel, isSortEnabled } from "./TableHeaderService";
import { getViewItem, tableButtonsPermission, modifierValues } from "./TableBodyService";
import { getStoreItem } from "../../StoreService";

export default id => ({
    requestTable: requestTable(`/project/vehicles/${id}/projects`),
    onClickCreateItem: item => {
        url.redirect(`/project/projects/${item.id}/create`);
    },
    onClickEditItem: item => {
        url.redirect(`/project/projects/${item.id}/edit`);
    },
    onClickDeleteItem: item => deleteItem(`/project/projects/${item.id}/delete`),
    getViewItem,
    getFilter: getFilter(id),
    modifierValues,
    getTableLabel,
    tableButtonsPermission,
    handleScroll,
    getStoreItem,
    getActionStore,
    isSortEnabled,
});
