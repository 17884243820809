import React from "react";
import PropTypes from "prop-types";
import Img from "Templates/Img";
import { Div, Li } from "Templates/Default";

const propTypes = {
    icon: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

const InfoBlock = ({ icon, caption, title }) => {
    return (
        <Li className="data__item">
            <Div className={`data__feauture ${icon}`}>
                <Img img={icon} alt="data__feauture" />
            </Div>
            <Div className="data__desc">
                <Div className="data__caption">{caption}</Div>
                <Div className="data__title">{title}</Div>
            </Div>
        </Li>
    );
};

InfoBlock.propTypes = propTypes;

export default InfoBlock;
