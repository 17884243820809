import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";

import {numWithSub} from "Services";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";
import {Title, Total} from "Templates/Titles";
import UnitWeightWrapper from "App/View/UnitWeightWrapper";
import Img from "Templates/Img";
import Column from "../Templates/Column";
import Row from "../Templates/Row";
import dashboardTypeConstant from "../Constants/DashboardConstants"
import DashboardCard from "./Level_1/Card/Widget/DashboardCard";
import StatusDataDashboardCard from "./Level_1/Card/Status/DataDashboardCard";
import DataBusinessDashboardCard from "./Level_1/Card/Status/DataBusinessDashboardCard";
import ChartHeadDashboardCard from "./Level_1/Card/Chart/HeadDashboardCard";
import ChartDataDashboardCard from "./Level_1/Card/Chart/DataDashboardCard";

const propTypes = {
    companyName: PropTypes.string.isRequired,
    financialYear: PropTypes.string.isRequired,
    revenue: PropTypes.string.isRequired,
    volume: PropTypes.string.isRequired,
    dashboardType: PropTypes.number.isRequired,
    grossMargin: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    expandRow: PropTypes.func.isRequired,
};

const InfoDashboardCard = DashboardCard({});
const ChartDashboardCard = DashboardCard({
    HeadDashboardCard: ChartHeadDashboardCard,
    DataDashboardCard: ChartDataDashboardCard,
});

const DashboardTypeThree = ({
                                companyName,
                                position,
                                financialYear,
                                revenue,
                                volume,
                                grossMargin,
                                dashboardType,
                                help,
                                expandRow,
                                t
                            }) => {
    const StatusDashboardCard = DashboardCard({
        DataDashboardCard: dashboardTypeConstant.BUSINESS === dashboardType ? DataBusinessDashboardCard : StatusDataDashboardCard
    });

    return (
        <>
            <div className="forecast-explorer__expand-title">
                <Title
                    title={t("Forecast Roll-up Level 1")}
                    help={help}
                    total={() => (
                        <Total>
                            <div className="gray-text">
                                <Tooltip>
                                    {t(`This is a roll-up for all forecasts for the current fiscal year, consolidated into one view`)}
                                </Tooltip>
                            </div>
                        </Total>
                    )}
                />
                <button type="button" className="expand-button" onClick={expandRow}>
                    <Img img="expand-icon" width="24" height="24"/>
                </button>
            </div>
            <div className="dashboard__wrap-main main-content__wrap">
                <div className="dashboard__list">
                    <Row>
                        <Column>
                            <StatusDashboardCard
                                headProps={{
                                    title: t(`${companyName} Pipeline Forecast Revenue`),
                                    subtitle: t(`FY ${financialYear}`),
                                }}
                            />
                        </Column>
                    </Row>
                    <Row>
                        <Column columnSize={1} fromColumns={4}>
                            <InfoDashboardCard
                                headProps={{
                                    title: t(`${companyName} ${dashboardTypeConstant.BUSINESS === dashboardType ? "Level 4" : "Current"} Forecast Revenue `),
                                    subtitle: t(`FY ${financialYear}`)
                                }}
                                dataProsp={{value: `$${numWithSub(Number(revenue))}`, className: "content_center"}}
                            />
                            <InfoDashboardCard
                                headProps={{
                                    title: t(`${companyName} ${dashboardTypeConstant.BUSINESS === dashboardType ? "Level 4" : "Current"} Forecast Volume`),
                                    subtitle: t(`FY ${financialYear}`),
                                }}
                                dataProsp={{
                                    value: `${numWithSub(Number(volume))}`,
                                    unit: <UnitWeightWrapper isLbs/>,
                                    className: "content_center"
                                }}
                            />
                        </Column>
                        <Column columnSize={3} fromColumns={4}>
                            <ChartDashboardCard className="main-data"/>
                        </Column>
                    </Row>
                </div>
            </div>
        </>
    );
};

DashboardTypeThree.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(DashboardTypeThree);
