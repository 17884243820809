import _ from "lodash";
import Constants from "../Constants";
import {GraphV2Types} from "../Constants/graph-v2-types";

export const initState = {
    data: {},
    isConnectionType: false,
    isConnectionProjectType: false,
    isActualsType: false,
    old: {},
    productCodesList: [],
    form: {
        sortColumn: "name",
        sortDirection: "asc",
        marginListSelect: "1",
        statusProject: [],
        type: GraphV2Types.TYPE_1,
        items: [],
        periodFrom: new Date().getFullYear().toString(),
        periodTo: new Date().getFullYear().toString(),
        timeScale: "1",
    },
    form_request: false,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_CONNECTION_TYPE: {
            return {
                ...state,
                isConnectionType: data,
                data: {}
            };
        }

        case Constants.CHANGE_CONNECTION_PROJECT_TYPE: {
            return {
                ...state,
                isConnectionProjectType: data,
                data: {}
            };
        }

        case Constants.CHANGE_ACTUALS_TYPE: {
            return {
                ...state,
                isActualsType: data,
                data: {}
            };
        }

        case Constants.CHANGE_FORM: {
            const newState = _.cloneDeep(state);
            _.set(newState, data.key, data.value);

            if (data.key.endsWith("periodFrom") && newState.form.periodTo < data.value) {
                _.set(newState, "form.periodTo", data.value);
            }

            return { ...newState };
        }

        case Constants.CHANGE_PRODUCTS_CODES: {
            return {
                ...state,
                form: {
                    ...state.form,
                    items: data.value || []
                } };
        }

        case Constants.RESET_FORM: {
            return {
                ...state,
                data: initState.data,
                old: { ...initState.old },
                form: { ...initState.form },
                form_validate: initState.form_validate,
            };
        }

        case Constants.SET_SORT_DIRECTION:
            return { ...state, form: {
                    ...state.form,
                    sortDirection: data || 'asc'
                } };

        case Constants.SET_PRODUCTS_CODES_LIST: {
            return { ...state, productCodesList: data, loading: false };
        }

        case Constants.FETCH_ITEMS_REQUESTED:
            return { ...state, ...data, loading: true };

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return { ...state, ...data, old: _.cloneDeep(state.form), loading: false };

        case Constants.FETCH_ITEMS_FAILED:
            return { ...state, old: _.cloneDeep(state.form), errors: { ...data }, loading: false };

        default:
            return state;
    }
};
