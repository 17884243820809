import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";

const propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    column: PropTypes.string.isRequired
};

const TableRowColumnDefaultEvent = ({ column, item, service }) => {
    const { getViewItem } = service;
    return getViewItem(column, item);
};

TableRowColumnDefaultEvent.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableRowColumnDefaultEvent);
