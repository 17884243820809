import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { MainButton } from "Templates/Content";
import _ from "lodash";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    resetFilter: PropTypes.func.isRequired
};

const ResetFiltersButton = ({ request, resetFilter, t }) => {
    if (_.isEmpty(request.filter) && _.isEmpty(request.sort)) {
        return '';
    }

    return (
        <MainButton className="main-btn__general main-btn_white" onClick={() => resetFilter({})}>
            {t("Reset")}
        </MainButton>
    );
};

ResetFiltersButton.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        resetFilter: getActionStore("resetFilter", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ResetFiltersButton);
