import { request, url } from "Services";

const getItems = (data, requestUrl) => {
    const sendObj = {
        url: url.getUrl(requestUrl),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestGet = requestUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, requestUrl).then(
            res => {
                resolve({ ...res[0] });
            },
            error => reject(error),
        );
    });
};

export const requestAttach = attachUrl => data => {
    const sendObj = {
        url: url.getUrl(attachUrl),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const getData = listUrl => data => {
    return new Promise((resolve, reject) => {
        getItems(data, listUrl).then(res => {
            resolve({
                data: res[0].items,
            });
        }, reject);
    });
};

export const getItemOptions = listUrl => (optionUrl, data = {}) => {
    return new Promise((resolve, reject) => {
        getItems(data, `${listUrl}${optionUrl ? `/${optionUrl}` : ""}`).then(res => {
            resolve(res[0].items);
        }, reject);
    });
};