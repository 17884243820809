import React from "react";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import { formatDate, getString } from "Services";
import _ from "lodash";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemSpan title={getString(item, key)}>
                    {getString(item, key, '')}
                </TableRowItemSpan>
            );

        case "date":
            return (
                <TableRowItemSpan title={formatDate(_.get(item, key))}>{formatDate(_.get(item, key))}</TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
