import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Video = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };
    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_video_guides_crud_view-${index}`}
                    value={getPermission("video_guides_crud", index)}
                    onChange={
                        value => onChangePermission("video_guides_crud", value, index)
                    }
                >
                    <CheckboxTitle title="Add/Edit/Delete - Video Guide"/>
                </CheckboxButton>
            </CheckboxBlock>
            <CheckboxBlock>
                <CheckboxButton
                    isDisabled={
                        hasAccess()
                    }
                    htmlFor={`checkbox_video_guides_view_view-${index}`}
                    value={getPermission("video_guides_view", index)}
                    onChange={
                        value => onChangePermission("video_guides_view", value, index)
                    }
                >
                    <CheckboxTitle title="Access - Video Guide"/>
                </CheckboxButton>
            </CheckboxBlock>
        </>
    )
}

Video.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Video);