import React from "react";
import {CheckboxBlock, CheckboxButton, CheckboxHierarchy, CheckboxTitle} from "Templates/Form/Checkbox";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Tooltip} from "Templates/Form";

const propTypes = {
    index: PropTypes.number.isRequired,
    team: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChangePermission: PropTypes.func.isRequired,
}

export const Forecasts = ({t, team, index, onChangePermission}) => {
    const getPermission = (key, i) => {
        return team[i] && team[i].permission.includes(key);
    };
    const hasAccess = () => {
        return team[index] && !team[index].haveAccess;
    };

    return (
        <>
            <CheckboxHierarchy activeHierarchy={getPermission("project_crud", index)} inputClass='first-hierarchy'>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_project_crud-${index}`}
                        value={getPermission("project_crud", index)}
                        onChange={value => onChangePermission("project_crud", value, index)}
                    >
                        <CheckboxTitle title="Add/Edit/Delete/View - Projects">
{/*                            <Tooltip>
                                {t(
                                    "Access user to Add/Edit/Delete/View Projects.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_project_companies_team_view-${index}`}
                        value={getPermission("project_companies_team_view", index)}
                        onChange={
                            value => onChangePermission("project_companies_team_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Access - All Projects tab">
{/*                            <Tooltip>
                                {t(
                                    "Access user to use All Projects tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_project_edit_all-${index}`}
                        value={getPermission("project_edit_all", index)}
                        onChange={value => onChangePermission("project_edit_all", value, index)}
                    >
                        <CheckboxTitle title="Edit All Projects" >
{/*                            <Tooltip>
                                {t(
                                    "Users able to edit all projects, not own only.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_project_suggested_price-${index}`}
                        value={getPermission("project_suggested_price", index)}
                        onChange={value => onChangePermission("project_suggested_price", value, index)}
                    >
                        <CheckboxTitle title="Access - Suggested Price">
{/*                            <Tooltip>
                                {t(
                                    "Access user to use Suggested Price.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_project_view_cost-${index}`}
                        value={getPermission("project_view_cost", index)}
                        onChange={value => onChangePermission("project_view_cost", value, index)}
                    >
                        <CheckboxTitle title="Access - Cost Model">
{/*                            <Tooltip>
                                {t(
                                    "Access user to use Cost Model tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
            <CheckboxHierarchy activeHierarchy={getPermission("ihv_lvp_access", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_ihv_lvp_access-${index}`}
                        value={getPermission("ihv_lvp_access", index)}
                        onChange={
                            value => onChangePermission("ihv_lvp_access", value, index)
                        }
                    >
                        <CheckboxTitle title="Access - IHS LVP"/>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_project_view-${index}`}
                        value={getPermission("forecast_project_view", index)}
                        onChange={
                            value => onChangePermission("forecast_project_view", value, index)
                        }
                    >
                        <CheckboxTitle title="View - Projects tab"/>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
            <CheckboxHierarchy activeHierarchy={getPermission("forecast_all_units_access", index)}>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_all_units_access-${index}`}
                        value={getPermission("forecast_all_units_access", index)}
                        onChange={
                            value => onChangePermission("forecast_all_units_access", value, index)
                        }
                    >
                        <CheckboxTitle title="Access - Unit Forecast">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the Unit Forecast Module.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_all_units_view-${index}`}
                        value={getPermission("forecast_all_units_view", index)}
                        onChange={
                            value => onChangePermission("forecast_all_units_view", value, index)
                        }
                    >
                        <CheckboxTitle title="Access - All Unit Forecasts Tab">
{/*                            <Tooltip>
                                {t(
                                    "Access user to the All Unit Forecasts tab.",
                                )}
                            </Tooltip>*/}
                        </CheckboxTitle>
                    </CheckboxButton>
                </CheckboxBlock>
                <CheckboxBlock>
                    <CheckboxButton
                        isDisabled={
                            hasAccess()
                        }
                        htmlFor={`checkbox_forecast_all_units_project_tab-${index}`}
                        value={getPermission("forecast_all_units_project_tab", index)}
                        onChange={
                            value => onChangePermission("forecast_all_units_project_tab", value, index)
                        }
                    >
                        <CheckboxTitle title="View - Projects tab"/>
                    </CheckboxButton>
                </CheckboxBlock>
            </CheckboxHierarchy>
        </>
    )
}

Forecasts.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(Forecasts);