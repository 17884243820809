import React from "react";
import { getString } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "publication_name":
            return (
                <TableRowItemLink title={getString(item, key)} href={`/admin/publications/${getString(item, "publication_id")}?subTab=${getString(item, "publication_safety_id")}&activeTab=3`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
