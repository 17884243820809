import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const propTypes = {
    value: PropTypes.number.isRequired,
};

const DataDashboardCard = ({ pref, value }) => {
    return (
        <div className={`${pref}__card-data`}>
            <div className={`${pref}__card-total`}>
                <span>{value}</span>
            </div>
        </div>
    );
};

// DataDashboardCard.defaultProps = defaultProps;
DataDashboardCard.propTypes = propTypes;

export default compose(withTagDefaultProps())(DataDashboardCard);
