import React, {useState} from "react";
import { compose } from "redux";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import {ModalDefault} from "Templates/Modals";
const {Button, Form, Title, Row, Col, ColInput, ColText} = ModalDefault;

const DownloadIHSModal = ({ onClose, downloadIhs, label, t }) => {
    const [date, setDate] = useState({
        year: '',
        month: ''
    });

    return (
        <>
            <Form className="modal-edit__form">
                <ButtonClose className="main-modal__form-close" onClick={onClose}/>
                <Title>{label}</Title>
                <Row>
                    <Col>
                        <ColText isRequired>{t("Select Year")}</ColText>
                        <ColInput
                            value={date.year || ''}
                            name="year"
                            type="asyncSelectDates"
                            url="project/vehicles/years"
                            onChange={option => {
                                setDate((prop) => {
                                    return { ...prop, year: option.value }
                                })
                            }}
                            placeholder="Select Year"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ColText isRequired>{t("Select Month")}</ColText>
                        <ColInput
                            disabled={!date.year}
                            value={date.month || ''}
                            dateType="month"
                            name="month"
                            type="asyncSelectDates"
                            url={date.year ? `project/vehicles/years/${date.year}` : '//'}
                            onChange={option => {
                                setDate((prop) => {
                                    return { ...prop, month: option.value }
                                })
                            }}
                            placeholder="Select Month"
                        />
                    </Col>
                </Row>
                <Button disabled={!date.year || !date.month} onClick={() => downloadIhs(date)}>
                    Download
                </Button>
            </Form>
        </>
    )
};

const mapStateToProps = (state) => {
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(DownloadIHSModal);
