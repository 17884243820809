import React from "react";
import { useSelector } from 'react-redux';

import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { weightUnitName, weightUnitConvertLbs } from 'Services/weightUnitName';
import { onChange } from "../../EventsService";

export const getTableLabel = key => {
    const tableLabel = {
        name: "Production Lines",
        scrap: "Scrap %",
        hours_thousand: `hrs/1000 lbs`,
        hours_required: "Hours Required",
        cost_hour: "$/hr",
        cost_thousand: `Cost/1,000 lbs`,
        cost_per_lbs: `Сost/lbs`,
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {

    const placeholders = {
        name: "Search",
        scrap: "Scrap %",
        hours_thousand: `hrs/1000 lbs`,
        hours_required: "Hours Required",
        cost_hour: "$/hr",
        cost_thousand: `Cost/1,000 lbs`,
        cost_per_lbs: `Сost/lbs`,
    };
    switch (key) {
        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
