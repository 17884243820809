import { debounce } from "Services/Debounce";
import Constants from "../../Constants";
import { fetchItems } from "../CommonTableActions";

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (["sub_status", "start", "due"].includes(key)) {
        fetchItems(service, dispatch)(request);
    } else if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};
