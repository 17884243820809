import { handleScroll } from "../../EventsService";
import { requestTable } from "../../RequestService";
import { restoreItem, deleteItem } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getTableLabel, getFilter } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default () => ({
    requestTable: requestTable(`admin/deleted/grades`),
    onClickDeleteItem: item => deleteItem(`admin/deleted/grades/${item.id}/force`),
    onClickRestoreItem: item => restoreItem(`admin/deleted/grades/${item.id}/restore`),
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
