import React from "react";
import ReactOnRails from "react-on-rails";
import Root from "App/View/Root";
import Index from "./Index";
import _service from "./Services";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);
    const id = _service.getStoreItem(store.getState(), "id");

    const service = {
        ..._service,
        save: _service.save(id),
    };

    return (
        <Root store={store} service={service}>
            <Index />
        </Root>
    );
};

export default App;
