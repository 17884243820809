import * as main from "../../../index";
import {getFilter, getTableLabel} from "../TableHeaderService";
import {getViewItem, modifierValues, tableButtonsPermission} from "../TableBodyService";

export default {
    ...main,
    getFilter, getTableLabel,
    getViewItem, modifierValues, tableButtonsPermission,
    requestTable: main.requestTable("project/errors"),
};
