import {formDataNormalizer, request, url} from "Services";

const getItems = listUrl => data => {
    const sendObj = {
        url: url.getUrl(listUrl),
        data: { ...data.filters, sort: data.sort },
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = listUrl => data => {

    return new Promise(resolve => {

        getItems(listUrl)(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
                columns: res[0].columns,
                total_analytics: res[0].total_analytics,
                pinned_columns: res[0].pinned_columns || [],
                activeColumns: res[0].activeColumns,
            });
        }).then(() => {
            if(url.getActiveTab()){
                data.activeTab = url.getActiveTab();
            }
        });
    });
};

export const editTemplateRequest = (templateId, data) => {
    const sendObj = {
        url: url.getUrl(`/project/table-settings/template/${templateId}/edit`),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
}

export const requestChangeSettings = data => {
    const sendObj = {
        url: url.getUrl('/project/table-settings/edit'),
        data,
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const requestCreateOrder = data => {
    const sendObj = {
        url: url.getUrl('/project/table-settings/template/create'),
        data,
        type: "POST",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const requestExportProjects = data => {
    const sendObj = {
        url: data,
        type: "POST"
    };

    return request.sendRequest(sendObj);
};

export const requestGetSettings = () => {
    const sendObj = {
        url: url.getUrl('/project/table-settings'),
        type: "GET"
    };

    return request.sendRequest(sendObj);
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequest(sendObj);
};

export const requestItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "POST",
    };

    return request.sendRequest(sendObj);
};

export const requestOnUploadFiles = data => {
    return request.sendRequest({
        url: url.getUrl('/project/projects/import-template'),
        data: formDataNormalizer(data),
        type: "POST",
        processData: false,
        contentType: false,
    });
};

export const sendProjectsFilterRequest = async (data, requestProjectsUrl) => {
    const sendObj = {
        url: url.getUrl(requestProjectsUrl),
        data: { ...data.filters, sort: data.sort },
        type: "GET",
    };

    return request.sendRequestWithNoCache(sendObj);
}

export const getSelectedFiltersItems = (listUrl) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        type: "GET",
    };

    return request.sendRequestWithNoCacheAndNoLoading(sendObj);
};