import { handleScroll } from "../../EventsService";
import { requestTable, requestAttach } from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getTableLabel, typeList, getFilter } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/admin/companies/${id}/users`),
    requestAttach: requestAttach(`/admin/companies/${id}/users/add`),
    requestEdit: item => requestAttach(`/admin/users/${item.id}/edit`),
    typeList,
    getFilter,
    getViewItem,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
