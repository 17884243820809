import {request, url} from "Services";

export const getItem = async ( listUrl ) => {
    const sendObj = {
        url: url.getUrl(listUrl),
        type: "GET",
    };

    return await request.sendRequest(sendObj)
};

export const requestOnSaveCopy = link => data => {
    const formData = new FormData();

    for (let i = 0; i < data.product.length; i += 1) {
        formData.append("products[]", data.product[i]);
    }

    const sendObj = {
        url: url.getUrl(link),
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
    };

    return request.sendRequest(sendObj);
};

/**
 * Get download url for PDF report
 * @param {string} gradeId - grade ID
 * @param {string} datasheetId - selected datasheet ID
 * @param {'normal' | 'extended'} type - type of report. Could be "normal" or "extended"
 * @returns {string | null} - Url or "null" if datasheetId weren't provided
 */
export const getUploadUrl = (gradeId, datasheetId, type = 'normal') => {
    if (!datasheetId || !gradeId) {
        return null;
    }

    return type === 'extended' ?
        `/api/product/product-finder/${gradeId}/technical/${datasheetId}/upload-extended` :
        `/api/product/product-finder/${gradeId}/technical/${datasheetId}/upload`;
};