const Constants = {
    CHANGE_FORM: "CHANGE_FORM",

    FORM_REQUESTED: "FORM_REQUESTED",
    FORM_SUCCEEDED: "FORM_SUCCEEDED",
    FORM_FAILED: "FORM_FAILED",
    FORM_RESET: "FORM_RESET",

    SUB_SEGMENT_REQUESTED: "SUB_SEGMENT_REQUESTED",
    SUB_SEGMENT_SUCCEEDED: "SUB_SEGMENT_SUCCEEDED",
    SUB_SEGMENT_FAILED: "SUB_SEGMENT_FAILED",

    SUBMIT_COLOR_REQUESTED: "SUBMIT_COLOR_REQUESTED",
    SUBMIT_COLOR_SUCCEEDED: "SUBMIT_COLOR_SUCCEEDED",
    SUBMIT_COLOR_FAILED: "SUBMIT_COLOR_FAILED",

    SUBMIT_CONTENT_TEMPLATE_REQUESTED: "SUBMIT_CONTENT_TEMPLATE_REQUESTED",
    SUBMIT_CONTENT_TEMPLATE_SUCCEEDED: "SUBMIT_CONTENT_TEMPLATE_SUCCEEDED",
    SUBMIT_CONTENT_TEMPLATE_FAILED: "SUBMIT_CONTENT_TEMPLATE_FAILED",

    SUB_LEGAL_REQUESTED: "SUB_LEGAL_REQUESTED",
    SUB_LEGAL_FAILED: "SUB_LEGAL_FAILED",

    CONTENT_TEMPLATE_REQUESTED: "CONTENT_TEMPLATE_REQUESTED",
    CONTENT_TEMPLATE_FAILED: "CONTENT_TEMPLATE_FAILED"
};

export default Constants;
