import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import { url } from "Services";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {MainButton} from "Templates/Content";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        deleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    resetFilter: PropTypes.func.isRequired,
};

const ResetFiltersView = ({ request, resetFilter, t }) => {
    return !_.isEmpty(request.filter) || !_.isEmpty(request.sort) ?
           (<MainButton className="main-btn__general main-btn_white" onClick={() => resetFilter({})}>
                 {t("Reset")}
            </MainButton>) : '';
};

ResetFiltersView.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        resetFilter: getActionStore("resetFilter", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ResetFiltersView);
