import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import {showConfirm} from "Services";
import TableHeadColumnWrap from "../../TableHeadColumnWrap";
import { TableRowColumnWrap } from "Templates/Table/TableRowColumnWrap";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import {ContainerTable} from "Hoc/Widgets";
import { TabWrap } from "Templates/Content";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
};

class PublicationList extends Component {
    render = () => {
        const {
            t,
            fetchItems,
            request,
            service: { onClickDeleteItem },
            handleOpenModal,
        } = this.props;

        const onClickDelete = item => {
            if (showConfirm(t("Do you want to delete this List?"))) {
                onClickDeleteItem(item).then(
                    () => {
                        fetchItems(request);
                    },
                    errors => {
                        alert(errors.detail);
                    },
                );
            }
        };

        const onClickEdit = item => {
            handleOpenModal(item);
        };

        return (
            <TabWrap className="myProduct__wrap">
                <TabViewDetail
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                />
            </TabWrap>
        );
    };
}

PublicationList.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        handleOpenModal: getActionStore("handleOpenAddPublicationListModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(PublicationList);
