import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import TableRowColumnDefault from "Templates/Table/TableRowColumnWrap/Default";
import { withTagDefaultProps } from "Hoc/Template";
import TableRowColumnTemplate from "Templates/Table/TableRowColumn";
import { withServiceConsumer } from "Services/Context";
import TableRowItem from "./TableRowItem";

const propTypes = {
    column: PropTypes.string.isRequired,
    customIcon: PropTypes.func.isRequired,
    item: PropTypes.shape({
        default_folder: PropTypes.number.isRequired,
    }).isRequired,
    service: PropTypes.shape({
        showEditButton: PropTypes.func.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    secondaryIndex: PropTypes.number.isRequired,
    onClickCustom: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickShare: PropTypes.func.isRequired,
};

const TableRowColumn = React.memo(
    ({
        index,
        customIcon,
        secondaryIndex,
        column,
        item,
        service: { showEditButton },
        onClickEdit,
        onClickDelete,
        onClickCustom,
        onClickShare,
    }) => {
        return (
            <TableRowColumnTemplate key={`TableRowColumn-${index}-${secondaryIndex}`}>
                {column === "-1" && !item.default_folder ? (
                    <TableRowItem
                        item={item}
                        customIcon={customIcon}
                        showEditButton={showEditButton(item)}
                        onClickShare={onClickShare}
                        onClickEdit={onClickEdit}
                        onClickDelete={onClickDelete}
                        onClickCustom={onClickCustom}
                        index={secondaryIndex}
                    />
                ) : (
                    <TableRowColumnDefault column={column} item={item} />
                )}
            </TableRowColumnTemplate>
        );
    },
);

TableRowColumn.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableRowColumn);
