import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { Input } from "../../Input";

const propTypes = {
    type: PropTypes.string,
    disabled: PropTypes.bool,
    viewFormat: PropTypes.string,
    url: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.object),
    ]),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.any),
};

const defaultProps = {
    type: "text",
    disabled: false,
    // cache: true,
    placeholder: "",
    value: "",
    url: "",
    options: [],
    viewFormat: "MM/DD/YYYY",
};

const InputFilter = ({ pref, type, disabled, placeholder, value, onChange, options, viewFormat, url }) => (
    <Input
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        url={url}
        onChange={onChange}
        options={options}
        className={`${pref}__table-input main-table__input select-custom-wrap`}
        viewFormat={viewFormat}
    />
);

InputFilter.propTypes = propTypes;
InputFilter.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(InputFilter);
