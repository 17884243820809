import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    className: PropTypes.string,
    isShortText: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    htmlTitle: PropTypes.bool,
};

const defaultProps = {
    className: "",
    title: "",
    isShortText: true,
    htmlTitle: false,
};

const TableRowItemSpan = ({children, pref, title, className, isShortText, htmlTitle}) => {
    const shortClass = isShortText ? "main-table__body-text" : "";

    return htmlTitle ? (
        <span className={`${pref}__table-body-text ${shortClass} ${className} html-title`}>
            {children}
            <p className="tooltip">{title}</p>
        </span>
    ) : (
        <span title={title} className={`${pref}__table-body-text ${shortClass} ${className}`}>
            {children}
        </span>
    )
};

TableRowItemSpan.propTypes = propTypes;
TableRowItemSpan.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(TableRowItemSpan);
