import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import Label from "./Label";

const propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    isWidth: PropTypes.bool,
    isRequired: PropTypes.bool,
};

const defaultProps = {
    label: "",
    className: "",
    isWidth: false,
    isRequired: false,
};

const WrapInput = ({ className, pref, name, label, isWidth, isRequired, children }) => (
    <div
        className={`${pref}__wrap-input main-content__wrap-input ${isWidth ? `${pref}__wrap-input_width` : ""} ${
            className ? `${pref}__${className}` : ""
        }`}
    >
        {label && <Label name={name} label={label} isRequired={isRequired} />}
        {React.Children.map(children, child => {
            return child ? React.cloneElement(child, { name }) : <></>;
        })}
    </div>
);

WrapInput.propTypes = propTypes;
WrapInput.defaultProps = defaultProps;

export default compose(withTagDefaultProps())(WrapInput);
