import Constants from "../Constants";

export const onChange = (service, dispatch) => (key, value) => {
    dispatch({ type: Constants.CHANGE_FORM, data: { key, value } });
};

export const resetForm = (service, dispatch) => () => {
    dispatch({ type: Constants.FORM_RESET });
};

export const submitForm = (service, dispatch) => data => {
    dispatch({ type: Constants.FORM_REQUESTED });
    const { formData } = service.FormService;

    return new Promise((resolve, reject) => {
        service.save(formData(data)).then(
            res => {
                dispatch({ type: Constants.FORM_SUCCEEDED });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.FORM_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};

export const onChangeSubSegmentAction = (service, dispatch) => (key, value, index) => {
    dispatch({ type: Constants.CHANGE_SUB_SEGMENT, data: { key, value, index } });
};

export const addApplication = (service, dispatch) => () => {
    dispatch({ type: Constants.ADD_APPLICATION_ITEM });
};

export const removeApplication = (service, dispatch) => (frontId) => {
    dispatch({ type: Constants.REMOVE_APPLICATION_ITEM, data: { frontId } });
}

export const changeApplicationCodeAction = (service, dispatch) => (codeId, index) => {
    dispatch({ type: Constants.CHANGE_APPLICATION_CODE_REQUESTED });
    return new Promise((resolve, reject) => {
        if (!codeId) {
            dispatch({ type: Constants.CHANGE_APPLICATION_CODE_SUCCEEDED, data: {} });
            return;
        }

        service.requestGet(`project/projects/${codeId}/application-code/data`).then(
            res => {
                dispatch({ type: Constants.CHANGE_APPLICATION_CODE_SUCCEEDED, data: { ...res, index } });
                resolve(res);
            },
            errors => {
                dispatch({ type: Constants.CHANGE_APPLICATION_CODE_FAILED, data: errors });
                reject(errors);
            },
        );
    });
};