import React from "react";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import TableBody from "Templates/Table/TableBody";

const ContainerTable = (TableHeadColumn, TableRowColumn) => ({ columns }) => {
    return (
        <form>
            <table className="main-table">
                <tbody>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={`TableHeadColumn-${index}`} className="main-table__head">
                                <TableHeadColumn column={column} />
                            </th>
                        ))}
                    </tr>
                    <TableBody columns={columns} TableRowColumn={TableRowColumn} />
                </tbody>
            </table>
            <button type="submit" className="visually-hidden" />
        </form>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        columns: getStoreItem(state, "columns"),
    };
};
export default (TableHeadColumn, TableRowColumn) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps),
    )(ContainerTable(TableHeadColumn, TableRowColumn));
