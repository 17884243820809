import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";

import { ContainerTable } from "Hoc/Widgets";
import { customTable } from "Templates/Tabs/DefaultWrap/TabViewDetail";
import { TableHeadColumnWrap } from "Templates/Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./TableRowColumn/TableRowColumnWrap";

const DefaultTabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(DefaultTabTable);

class Plants extends Component {
    // onClickDelete = item => {
    //     const { t } = this.props;
    //     if (window.confirm(t("Do you want to delete the cost?"))) {
    //         const {
    //             fetchItems,
    //             request,
    //             service: { onClickDeleteItem },
    //         } = this.props;
    //         onClickDeleteItem(item).then(
    //             () => {
    //                 fetchItems(request);
    //             },
    //             errors => {
    //                 alert(errors.detail);
    //             },
    //         );
    //     }
    // };

    render() {
        return <TabViewDetail />;
    }
}

Plants.propTypes = {
    service: PropTypes.shape({
        // onClickDeleteItem: PropTypes.func.isRequired,
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    // request: PropTypes.objectOf(PropTypes.any).isRequired,
    // fetchItems: PropTypes.func.isRequired,
};

// const mapStateToProps = (state, { service: { getStoreItem } }) => {
//     return {
//         request: {
//             filter: {
//                 ...getStoreItem(state, "filter"),
//             },
//             sort: {
//                 ...getStoreItem(state, "sort"),
//             },
//         },
//     };
// };

// const mapDispatchToProps = (dispatch, { service }) => {
//     const { getActionStore } = service;

//     return {
//         fetchItems: getActionStore("fetchItems", service, dispatch),
//     };
// };

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
    // withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Plants);
