import _ from "lodash";
import _configureStore from "App/Store";
import { getMainModifiedStoreItems } from "App/Services";
import reducers, { initialStates } from "../Reducers";

export default function configureStore(props) {
    const { editState } = initialStates;
    const {
        id,
        name,
        dunsNumber,
        customerCode,
        customerOwner,
        logo,
        logoView,
        email,
        url,
        parentCompanyId,
        canEditCustomerCode,
        physicalCountry,
        physicalCity,
        physicalAddress1,
        physicalZip,
        physicalState,
        physicalDistrict,
        physicalTimeZone,
        socials
    } = props;

    const initData = {
        id,
        name,
        dunsNumber,
        customerCode,
        customerOwner,
        logo,
        logoView,
        email,
        url,
        phones: props.phones.length
            ? _.get(props, "phones")
            : [
                {
                    type: '1',
                    number: "",
                },
            ],
        canEditCustomerCode,
        parentCompanyId: parentCompanyId || "",
        nicknames: props.nicknames.length
            ? _.get(props, "nicknames")
            : [
                {
                    id: "",
                    value: "",
                },
            ],
        physicalCountry,
        physicalCity,
        physicalAddress1,
        physicalZip,
        physicalState,
        physicalDistrict,
        physicalTimeZone,
        socials
    };

    const initialState = {
        ...getMainModifiedStoreItems({ ...props, pref: "edit-company" }),
        editState: {
            ...editState,
            ...initData,
            form_validate: true,
            old: { ..._.cloneDeep(initData) },
        },
    };

    return _configureStore(initialState, reducers);
}
