import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";

const DivTableFormCard = ({ pref, className, children }) => (
    <div className={`${pref}__form-card-box ${className}`}>{children}</div>
);

DivTableFormCard.defaultProps = {
    className: "",
};

DivTableFormCard.propTypes = {
    className: PropTypes.string,
};

const TableCard = ({ pref, className, children }) => (
    <table className={`${pref}__form-card ${className}`}>{children}</table>
);

TableCard.defaultProps = {
    className: "",
};

TableCard.propTypes = {
    className: PropTypes.string,
};

export const DivTableCard = compose(withTagDefaultProps())(DivTableFormCard);
export default compose(withTagDefaultProps())(TableCard);
