import React, {Fragment, useEffect, useState} from "react";

import {ButtonClose} from "Templates/Button";
import {ModalDefault} from "Templates/Modals";
import {getSharedPublications} from "../../../Services/RequestService";
import {CheckboxBlock, CheckboxButton, CheckboxLabel} from "Templates/Form/Checkbox";

const {Form, Title, Button} = ModalDefault;

export const SharingPublicationsModal = ({ t, id, sharedWithList, onShare, onCloseModal }) => {
    const [sharingPublicationsModalData, setSharingPublicationsModalData] = useState([]);
    const [selectedSharingPublications, setSelectedSharingPublications] = useState([]);

    useEffect(() => {
        getItems();

        if (sharedWithList.length) {
            sharedWithList.forEach((shared) => {
                setSelectedSharingPublications((prev) => {
                    return [...prev, shared.id]
                })
            })
        }
    }, [])

    const getItems = async () => {
        const data = await getSharedPublications(`/admin/company-sharing-data/company-list/${id}`);
        if (data && data.length) {
            setSharingPublicationsModalData(data[0].items)
        }
    }

    const onChange = (label, value) => {
        setSelectedSharingPublications((prev) => {
            if (prev.includes(value)) {
                return prev.filter((item) => item !== value)
            }
            return [...prev, value]
        })
    }

    const validate = () => {
        const sharedList = sharedWithList.map((shared) => shared.id);

        if (sharedList.length) {
            return JSON.stringify(sharedList.sort()) === JSON.stringify(selectedSharingPublications.sort())
        }

        return !selectedSharingPublications.length
    }

    return <Form className="sharing-publications-modal">
        <ButtonClose className="main-modal__form-close" onClick={onCloseModal}/>
        <Title>{t('Share with')}</Title>
        { sharingPublicationsModalData.length > 0 ? sharingPublicationsModalData.map((item) => {
            return <Fragment key={item.value}>
                <CheckboxBlock>
                    <CheckboxButton
                        htmlFor={item.value}
                        value={selectedSharingPublications.includes(item.value)}
                        onChange={() => onChange(item.label, item.value)}
                    >
                        <CheckboxLabel label={item.label}/>
                    </CheckboxButton>
                </CheckboxBlock>
            </Fragment>
        }) : <p className="sharing-publications-empty">Nothing for sharing</p> }
        <div className="sharing-publications-modal__buttons">
            <Button
                onClick={onCloseModal}
            >
                {t("Cancel")}
            </Button>

            <Button
                disabled={validate()}
                onClick={() => {
                    if (validate()) return
                    onShare(selectedSharingPublications)
                }}
            >
                {t("Save")}
            </Button>
        </div>
    </Form>
}