import Constants from "../Constants";

export const openUploadVideoModal = (service, dispatch) => () => dispatch({ type: Constants.OPEN_VIDEO_UPLOAD_MODAL });
export const closeUploadVideoModal = (service, dispatch) => () => dispatch({ type: Constants.CLOSE_VIDEO_UPLOAD_MODAL });

export const openEditUploadVideoModal = (service, dispatch) => () => dispatch({ type: Constants.OPEN_EDIT_VIDEO_UPLOAD_MODAL });
export const closeEditUploadVideoModal = (service, dispatch) => () => dispatch({ type: Constants.CLOSE_EDIT_VIDEO_UPLOAD_MODAL });

const getItems = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestSetFolderSucceeded = data => ({ type: Constants.SET_FOLDER_SUCCESS, data });

const requestSetFolderFailed = data => ({ type: Constants.SET_FOLDER_FAILED, data });

export const fetchItems = (service, dispatch) => () => {
    dispatch(getItems());

    const { getFolderList } = service;

    getFolderList(1).then(
        res => dispatch(requestSucceeded({ ...res })),
        res => dispatch(requestFailed(res)),
    );
};

export const setSelectedFolder = (service, dispatch) => (data) => {
    dispatch({ type: Constants.SET_FOLDER_REQUEST, data: { loading: true } })

    const { setFolder } = service

    setFolder({ folderId: data, page: 1 }).then(
        res => dispatch(requestSetFolderSucceeded({ ...res })),
        res => dispatch(requestSetFolderFailed(res))
    )
}

export const editFileAction = (service, dispatch) => data => {
    dispatch({ type: Constants.EDIT_VIDEO_REQUEST });

    const { requestOnEditVideo } = service;

    return requestOnEditVideo(data.id, data).then(
        res => {
            dispatch({type: Constants.EDIT_VIDEO_SUCCESS, data: res})
            const { setFolder } = service

            setFolder({ folderId: data.folderId, page: 1 }).then(
                r => {
                    dispatch({ type: Constants.UPLOAD_VIDEO_SUCCESS })
                    dispatch(requestSetFolderSucceeded({...r}))
                },
                r => dispatch(requestSetFolderFailed(r))
            )
        },
        res => {
            alert(res.detail);
            dispatch({ type: Constants.EDIT_VIDEO_FAILED, data: res });
        },
    );
}

export const uploadFileAction = (service, dispatch) => data => {
    dispatch({ type: Constants.UPLOAD_VIDEO_REQUEST });

    const { getPreSignedUrl, requestOnUploadVideo } = service;

    const { folderId, files, videoFiles, title, description, position } = data

    if (!folderId || !files.length || !videoFiles.length || !title.trim() || !description.trim()) return

    return getPreSignedUrl(files[0].name).then((result) => {
        if (!result) {
            return
        }
        const { path, presignedUrl } = result[0]

        try {
            const headers = new Headers()
            headers.set('Content-Type', files[0].type)
            headers.set('Cache-Control', 'no-cache')

            const imageHeaders = new Headers()
            headers.set('Content-Type', videoFiles[0].type)
            headers.set('Cache-Control', 'no-cache')

            const formData = new FormData();
            formData.append("files[]", videoFiles[0])

            fetch(`/api/files/add`, {
                imageHeaders,
                method: 'POST',
                body: formData
            }).then(async (r) => {
                if (!r) return

                const response = await r.json()
                if (!response) return

                fetch(presignedUrl, {
                    headers,
                    method: 'PUT',
                    body: files[0]
                }).then(() => {
                    return requestOnUploadVideo({ thumbnail_id: response.message.id, path, folderId, title, description, position }).then(() => {
                        dispatch(getItems());

                        const { setFolder } = service

                        setFolder({ folderId, page: 1 }).then(
                            res => {
                                dispatch({ type: Constants.UPLOAD_VIDEO_SUCCESS })
                                dispatch(requestSetFolderSucceeded({...res}))
                            },
                            res => dispatch(requestSetFolderFailed(res))
                        )
                    })
                }).catch(e => {
                    dispatch({ type: Constants.UPLOAD_VIDEO_FAILED })
                    alert(e)
                })
            })
        } catch (err) {
            dispatch({ type: Constants.UPLOAD_VIDEO_FAILED })
            alert(err)
        }
    })
};

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

const requestNextFoldersPageSucceeded = data => ({ type: Constants.NEXT_FOLDERS_PAGE, data });

export const fetchPage = (service, dispatch) => data => {
    dispatch(getItems());

    const { setFolder } = service;

    setFolder(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const fetchFoldersPage = (service, dispatch) => data => {
    dispatch(getItems());

    const { getFolderList } = service;

    getFolderList(data.page).then(
        res => dispatch(requestNextFoldersPageSucceeded(res)),
        res => dispatch(requestFailed(res)),
    );
};