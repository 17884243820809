import React, {useEffect} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import TableHeadColumnWrap from "./Table/TableHeadColumnWrap";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import InvoicesContainerTable from "./Table/InvoicesContainerTable";

const propTypes = {
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
};

const TabTable = InvoicesContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TabTable);

const TabView = ({fetchItems, request}) => {
    useEffect(() => {
        fetchItems(request)
    }, []);
    const onClickShow = item => {
        const win = window.open(`/invoice/invoices/${item.invoice_number}`, "_blank");
        win.focus();
    };

    return (
        <TabViewDetail
            customIcon={() => 'icon_external_link'}
            onClickCustom={onClickShow}
        />
    );
};

TabView.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
