import React from 'react';
import PropTypes from "prop-types";

const startYear = new Date('December 03, 2011 23:15:30').getFullYear();
const currentYear = new Date().getFullYear();
const fromMonth = new Date(startYear, 0);
const toMonth = new Date(currentYear + 5, 11);

function YearMonthFormDateInput({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption daypicker-caption">
      <select
        className="daypicker-caption__select"
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>

      <select
        className="daypicker-caption__select"
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

export default YearMonthFormDateInput;

YearMonthFormDateInput.propTypes = {
  date: PropTypes.objectOf(PropTypes.string).isRequired,
  localeUtils: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
}
