import React, { useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import { Title } from "Templates/Titles";
import LinkIcon from "./Views/Templates/LinkIcon";
import InfoWrap from "./Views/InfoWrap";
import {url} from "Services";

const propTypes = {
    name: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Index = ({ name, t, permissions }) => {
    useEffect(() => {
        if(!permissions.includes('admin_help-pages_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    return (
        <CheckPermission permission="admin_help-pages_crud">
            <Title title={t(name)} before={() => <LinkIcon href="/admin/help-pages" />} />
            <InfoWrap />
        </CheckPermission>
    );
};

Index.propTypes = propTypes;

const mapStateToProps = state => {
    return {
        name: state.listState.view.name,
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps),
)(Index);
