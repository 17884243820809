import { handleScroll } from "../../EventsService";
import { requestOnUploadFiles, getItem, requestOnSaveCopy, getUploadUrl } from "./RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { deleteItem, requestTable } from "../../RequestService";
import { getFilter, getTableLabel } from "../../TableHeaderService";
import { getViewItem, modifierValues } from "../../TableBodyService";

export default (id, brochureId) => ({
    requestTable: requestTable(`/product/publications/${id}/brochures/${brochureId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    modifierValues,
    getTableLabel,
    requestSubTab: () => getItem(`/product/product-finder/publications/${id}/brochures`),
    requestTypeItem: (item) => getItem(`/product/product-finder/publications/${id}/brochures/${item.id}`),
    requestOnUploadFiles: requestOnUploadFiles(`/product/publications/${id}/brochures/add`),
    requestOnSaveCopy: (bId) => requestOnSaveCopy(`/product/brochures/${bId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/brochures/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/brochures/${brochureId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (type) => getUploadUrl(brochureId, type),
});
