import React from "react";
import {useSelector} from 'react-redux';
import {formatDate, getString, numberWithCommas} from "Services";
import {gradeStatus, projectConsistent, projectStatus} from "Services/enum";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {weightUnitName} from 'Services/weightUnitName';

export const modifierValues = items => items;
export const tableButtonsPermission = "project_crud";

export const getViewItem = (key, item) => {
    const unit = useSelector(state => state.weightUnitState);

    const tableRowItemLink = (labelKey, href) => (
        <TableRowItemLink title={getString(item, labelKey)} href={href}>
            {getString(item, labelKey)}
        </TableRowItemLink>
    );

    switch (key) {
        case "slug":
            return tableRowItemLink("slug", `/project/projects/${getString(item, "id")}`);

        case "name":
            return tableRowItemLink("name", `/project/projects/${getString(item, "id")}`);

        case "replacement_business_project_id": {
            const replacementBusinessItems = item.replacement_business_projects
            return (
                <>
                    {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                        <TableRowItemLink blank
                                          title={getString(item, key)}
                                          href={`/project/projects/${elem.id}`}
                        >
                            {elem.slug}
                        </TableRowItemLink>
                    )) : '-'}
                </>
            );
        }

        case "replacement_business_project_name": {
            const replacementBusinessItems = item.replacement_business_projects
            return (
                <>
                    {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                        <TableRowItemLink blank
                                          title={getString(item, key)}
                                          href={`/project/projects/${elem.id}`}
                        >
                            {elem.name}
                        </TableRowItemLink>
                    )) : '-'}
                </>
            );
        }

        case "sub_status":
            return (
                <TableRowItemStatus
                    status={getString(item, key)}
                    list={[...projectStatus]}
                />
            );

        case "grade_sub_status":
            return (
                <TableRowItemStatus
                    status={getString(item, key)}
                    list={[...gradeStatus]}
                />
            );

        case "status":
            return <TableRowItemStatus status={getString(item, key)} list={projectConsistent} />;

        case String(key.match(/^actual_sale_average_price_.*/)): {
            const keys = key.split("_");
            return (
                <TableRowItemSpan className="text-right">
                    {getString(item, `analytics.${keys[5]}.${keys[6] || keys[4]}.${keys[3]}`).toFixed(2)}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^volume_.*/)): {
            const keys = key.split("_");
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, `analytics.${keys[2]}.${keys[3] || keys[1]}.${keys[0]}`), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^actual_volume_.*/)): {
            const keys = key.split("_");
            return (
                <TableRowItemSpan className="text-right">
                    {`${numberWithCommas(getString(item, `analytics.${keys[3]}.${keys[4] || keys[2]}.actual_volume`), 0)} ${weightUnitName(unit.weightUnit)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^revenue_.*/)): {
            const keys = key.split("_");
            const value = getString(item, `analytics.${keys[2]}.${keys[3] || keys[1]}.${keys[0]}`)

            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(value) < 10 ? Number(value).toFixed(2) : numberWithCommas(value, 0)}`}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^actual_revenue_.*/)): {
            const keys = key.split("_");
            const value = getString(item, `analytics.${keys[3]}.${keys[4] || keys[2]}.actual_revenue`)

            return (
                <TableRowItemSpan className="text-right">
                    {`$${Number(value) < 10 ? Number(value).toFixed(2) : numberWithCommas(value, 0)}`}
                </TableRowItemSpan>
            );
        }

        case "los_date":
        case "move_to_current": {
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => {
                        const formatValue = elem === '-' ? elem : formatDate(elem, "MMM, YYYY");
                        return (
                            <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatValue}>
                                {formatValue}
                            </TableRowItemSpan>
                        )
                    })}
                </>
            );
        }

        case "sap_customer":
        case "sap_grade":
            return <TableRowItemSpan>{getString(item, key, null) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case String(key.match(/^actual_sale_price.*/)): {
            let elem = getString(item, 'actual_sale_price');
            elem = (+elem).toFixed(2);

            return (
                <TableRowItemSpan className="text-right" title={elem}>
                    {elem}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^variable_cost.*/)): {
            let elem = getString(item, 'variable_cost');
            elem = (+elem).toFixed(2);

            return (
                <TableRowItemSpan className="text-right" title={elem}>
                    {elem}
                </TableRowItemSpan>
            );
        }

        case String(key.match(/^fixed_cost.*/)): {
            let elem = getString(item, 'fixed_cost');
            elem = (+elem).toFixed(2);

            return (
                <TableRowItemSpan className="text-right" title={elem}>
                    {elem}
                </TableRowItemSpan>
            );
        }

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
