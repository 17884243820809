const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", error => reject(error));
        image.src = url;
    });

/**
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export const getCroppedImg = async (imageSrc, crop, fileName) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");

    const getScale = (scale = 2) => {
        if (image.height / scale > 8000 || image.width / scale > 8000) {
            return getScale(scale + 2);
        }
        return scale;
    };

    const scale = getScale();

    const width = image.width / scale;
    const height = image.height / scale;

    canvas.width = width * 2;
    canvas.height = height * 2;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width / scale;
    canvas.height = crop.height / scale;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width / scale,
        crop.height / scale,
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(
            blob => {
                blob.name = fileName;
                resolve(blob);
            },
            "image/png",
            1,
        );
    });
};
