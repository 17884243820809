import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { storageUrl } from "Services";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps } from "Hoc/Template";
import TabViewDetail from "Templates/Tabs/DefaultWrap/TabViewDetail";

const propTypes = {
    storage_url: PropTypes.string.isRequired,
    pagination: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
    fetchItems: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
};

class SharedAgreements extends Component {
    render = () => {
        const onClickCustom = item => {
            const { storage_url } = this.props;
            window.open(storageUrl(_.get(item, "path"), storage_url), "_blank");
        };

        return (
            <>
                <TabViewDetail
                    onClickCustom={onClickCustom}
                    customIcon="icon_download"
                />
            </>
        );
    };
}

SharedAgreements.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        handleOpenModal: getActionStore("handleOpenModal", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(SharedAgreements);
