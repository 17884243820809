import _ from "lodash";
import Constants from "../Constants";

export const initState = {
    items: [],
    pagination: {},
    sort: {},
    columns: [],
    loading: false,
};

export const reducer = (state, action) => {
    const {data} = action;

    switch (action.type) {
        case Constants.TABLE_SORT:
            return {...state, items: data.items, sort: data.sort, pagination: data.pagination};

        case Constants.FETCH_ITEMS_REQUESTED:
            return {...state, ...data, loading: true};

        case Constants.FETCH_ITEMS_SUCCEEDED:
            return {
                ...state,
                ...data,
                sort: {column: data.sort.column, direction: data.sort.direction},
                loading: false,
            };

        case Constants.FETCH_ITEMS_FAILED:
            return {...state, errors: {...data}, loading: false};

        case Constants.NEXT_PAGE:
            return {...state, items: _.concat(state.items, data.items), pagination: data.pagination, loading: false};

        default:
            return state;
    }
};
