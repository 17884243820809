import React from "react";
import PropTypes from "prop-types";
import {getNumber, regExpForInteger} from "Services";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Block, BlockWrap, Input, WrapInput} from "Templates/Form";
import {Span} from "Templates/Default";
import LinkIcon from "Templates/Link/LinkIcon";
import {NoteTop} from "Templates/Notes";
import {AddButton, Data, DataCol, DataRow} from "../../Templates";

const propTypes = {
    manufacturer: PropTypes.string.isRequired,
    years: PropTypes.shape({
        [PropTypes.number]: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    }).isRequired,
    yearlyVolume: PropTypes.shape({'': ''}).isRequired,
    onChange: PropTypes.func.isRequired,
    addYear: PropTypes.func.isRequired,
    removeYear: PropTypes.func.isRequired,
    unitName: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
    changeYear: PropTypes.func.isRequired,
    changeMonthData: PropTypes.func.isRequired,
    changeYearlyVolume: PropTypes.func.isRequired,
    applyYearlyVolume: PropTypes.func.isRequired,
    service: PropTypes.shape({
        defaultMonthLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
};

const GeneralBlock = ({
    manufacturer,
    unitName,
    owner,
    years,
    onChange,
    addYear,
    removeYear,
    changeYear,
    changeMonthData,
    yearlyVolume,
    changeYearlyVolume,
    applyYearlyVolume,
    t,
    service: { defaultMonthLabels },
}) => {
    const yearsArr = Object.keys(years);
    const totalYears = 30;
    let currentYear = new Date().getFullYear() - Math.round(totalYears - 10);
    const yearOptions = new Array(totalYears).fill(null).reduce(acc => {
        currentYear = (Number(currentYear) + 1).toString();
        if (!yearsArr.includes(currentYear)) {
            return [...acc, { label: currentYear, value: currentYear, disable: false }];
        }
        return [...acc, { label: currentYear, value: currentYear, disable: true }];
    }, []);

    return (
        <>
            <Block>
                <BlockWrap>
                    <WrapInput isRequired label={t("Manufacturer")} name="manufacturer">
                        <Input
                            type="asyncSelect"
                            url="/project/forecasts/manufacturer-list"
                            placeholder={t("Search")}
                            value={manufacturer}
                            onChange={value => onChange("manufacturer", value)}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired label={t("Unit Name")} name="unitName">
                        <Input
                            placeholder="Unit Name"
                            value={unitName}
                            onChange={value => onChange("unitName", value)}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired label={t("Owner")} name="owner">
                        <Input
                            type="asyncSelect"
                            url="/project/forecasts/user-list"
                            placeholder={t("Search")}
                            value={owner}
                            onChange={value => onChange("owner", value)}
                        />
                    </WrapInput>
                </BlockWrap>
            </Block>

            <NoteTop>{t("Use pounds for volume")}</NoteTop>

            <Data>
                <DataCol>
                    <DataRow />
                    <DataRow />
                    {defaultMonthLabels.map(elem => (
                        <DataRow key={`Div-${elem}`}>{t(elem)}</DataRow>
                    ))}
                    <DataRow>
                        <Span className="bold-text">{t("Total Units")}</Span>
                    </DataRow>
                    <DataRow>
                        <Span className="bold-text">{t("Yearly Volume")}</Span>
                    </DataRow>
                    <DataRow />
                </DataCol>

                {yearsArr.map(year => (
                    <DataCol key={`Div-${year}`}>
                        <DataRow>
                            <Span className="bold-text">{t("Year")}</Span>
                            {yearsArr.length > 1 && <LinkIcon icon="la-icon-close" onClick={() => removeYear(year)} />}
                        </DataRow>
                        <DataRow>
                            <Input
                                type="select"
                                options={[...yearOptions]}
                                name={year}
                                value={year}
                                onChange={value => changeYear(value, year)}
                                inputProps={{
                                    isOptionDisabled: option => option.disable,
                                }}
                            />
                        </DataRow>
                        {defaultMonthLabels.map(elem => (
                            <DataRow key={`Div-${year}-${elem}`}>
                                <Input
                                    name={`${year}-${elem}`}
                                    value={years[year][elem]}
                                    onChange={
                                        value => regExpForInteger(value, data => changeMonthData(year, elem, data))
                                    }
                                />
                            </DataRow>
                        ))}
                        <DataRow>
                            <Span className="bold-text text-right">
                                {getNumber(Object.values(years[year]).reduce((acc, elem) => acc + Number(elem), 0))}
                            </Span>
                        </DataRow>
                        <DataRow>
                            <Input
                                name={`${year}-volume`}
                                value={yearlyVolume[year]}
                                onChange={
                                    value => regExpForInteger(value, data => changeYearlyVolume(year, data))
                                }
                            />
                        </DataRow>
                        <DataRow>
                            <AddButton onClick={() => applyYearlyVolume(year)}>
                                {t("Apply")}
                            </AddButton>
                        </DataRow>
                    </DataCol>
                ))}
                <DataCol>
                    <DataRow />
                    <DataRow>
                        <AddButton onClick={addYear}>{t("Add Year")}</AddButton>
                    </DataRow>
                </DataCol>
            </Data>
        </>
    );
};

GeneralBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        years: getStoreItem(state, "years"),
        manufacturer: getStoreItem(state, "manufacturer"),
        unitName: getStoreItem(state, "unitName"),
        owner: getStoreItem(state, "owner"),
        yearlyVolume: getStoreItem(state, "yearlyVolume"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        addYear: getActionStore("addYearAction", service, dispatch),
        removeYear: getActionStore("removeYearAction", service, dispatch),
        changeYear: getActionStore("changeYearAction", service, dispatch),
        changeMonthData: getActionStore("changeMonthDataAction", service, dispatch),
        changeYearlyVolume: getActionStore("changeYearlyVolumeAction", service, dispatch),
        applyYearlyVolume: getActionStore("applyYearlyVolumeAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(GeneralBlock);
