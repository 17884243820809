import React from "react";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {BlockWrapFlex} from "Templates/Form";
import PropTypes from "prop-types";
import {Button} from "Templates/Default";
import {Modal} from "Templates/Modals";
import PublicationsModal from "./PublicationsModal";
import {ContainerTable} from "Hoc/Widgets";
import TableHeadColumnWrap from "../Table/TableHeadColumnWrap";
import BlockTitle from "Templates/Form/BlockTitle";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import TableRowColumnWrap from "../Table/TableRowColumnWrap";
import {showConfirm} from "Services";

const PublicationsTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const PublicationsTableDetail = customTable(PublicationsTable);

const propTypes = {
    showModal: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    request: PropTypes.object.isRequired,
    getItems: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
};

const Publications = ({t, showModal, closeModal, openModal, deleteItem, pref, request, getItems, handleScroll}) => {

    const onDeleteItem = (item) => {
        if (showConfirm(`Do you want to delete "${item.name}" from Publication List?`)) {
            deleteItem(item.id);
        }
    };

    const onScroll = (e) => {
        const {page, pages} = request;
        if (page >= pages) {
            return;
        }
        handleScroll(e).then(() => {
            getItems({page: page + 1});
        })
    };

    return <BlockWrapFlex>
        <div className={`${pref}__block-header`}>
            <BlockTitle>{t('Publications')}</BlockTitle>
            <Button onClick={openModal}
                    className="size_small main-btn tds_header_suppliers_btn main-btn_white">{t('Add Publication')}</Button>
        </div>
        <div className={`${pref}__publications`}>
            <div className={`${pref}__wrap main-wrap-block`}>
                <div className={`${pref}__wrap-body main-wrap-block__body main-wrap-block__body_height`} onScroll={onScroll}>
                    <PublicationsTableDetail customIcon={() => 'icon_delete'}
                                   onClickCustom={(e) => onDeleteItem(e)}
                    />
                </div>
            </div>
        </div>

        {showModal ?
            <Modal onRequestClose={closeModal}>
                <PublicationsModal/>
            </Modal> : <div />}
    </BlockWrapFlex>
};

Publications.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        showModal: getStoreItem(state, 'showModal'),
        request: {
            ...getStoreItem(state, 'pagination')
        }
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore, handleScroll} = service;
    return {
        openModal: getActionStore("openModal", service, dispatch),
        closeModal: getActionStore("closeModal", service, dispatch),
        deleteItem: getActionStore("deleteItem", service, dispatch),
        getItems: getActionStore("getItems", service, dispatch),
        handleScroll,
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Publications);
