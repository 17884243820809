import _ from "lodash";
import { url } from "Services";
import Constants from "../Constants";

export const initState = {
    view: {},
    activeTab: +url.get('activeTab'),
    oldValue: {},
    editValue: {},
    errors: {},
    showModal: false,
    showEditModal: false,
    showModalCopySolution: false,
    form: {},
    editProductForm: { product: null, colors: [] },
    totalTechnicals: 0,
    totalProcesses: 0,
    totalDesigns: 0,
    totalSafeties: 0,
    totalBrochures: 0,
    totalNotes: 0,
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "subTabs");
            return {
                ...state,
                items: [],
                pagination: {},
                activeTab: data,
                filter: {},
                sort: {},
                activeSubTab: {},
            };

        case Constants.RESET_FORM:
            return { ...state, editValue: {} };

        case Constants.ASSIGN_TO_PRODUCT_SUCCESS:
        case Constants.CLOSE_MODAL:
            return { ...state, showModal: false, form: {} };

        case Constants.SAVE_COPY_PUBLICATION_SUCCESS:
        case Constants.CLOSE_COPY_PUBLICATION_MODAL:
            return { ...state, showModalCopySolution: false, form: {} };

        case Constants.OPEN_MODAL:
            return { ...state, showModal: true };

        case Constants.OPEN_EDIT_MODAL:
            return { ...state, showEditModal: true, editProductForm: { product: {name: data.name, value: data.grade_id}, colors: data.color ? JSON.parse(data.color) : [] } };

        case Constants.EDIT_PRODUCT_SUCCESS:
        case Constants.CLOSE_EDIT_MODAL:
            return { ...state, showEditModal: false, editProductForm: { product: null, colors: [] } };

        case Constants.OPEN_COPY_PUBLICATION_MODAL:
            return { ...state, showModalCopySolution: true };

        case Constants.CHANGE_SELECT: {
            const { form } = state;
            form[data.key] = data.value;

            return { ...state, form };
        }

        case Constants.ASSIGN_TO_PRODUCT_FAILED:
            return { ...state, errors: { ...data }, showModal: true };

        case Constants.EDIT_PRODUCT_FAILED:
            return { ...state, errors: { ...data }, showEditModal: true };

        case Constants.SAVE_COPY_PUBLICATION_FAILED:
            return { ...state, errors: { ...data }, showModalCopySolution: true };

        default:
            return state;
    }
};
