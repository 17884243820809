import _ from "lodash";
const jsx = require("js-extension-ling");

export default function TableFilter() {
    this.url = new URLSearchParams(window.location.search);

    this.getFilters = () => {
        return jsx.queryStringToObject(this.url.toString()).filter || {};
    };

    this.getSort = () => {
        return jsx.queryStringToObject(this.url.toString()).sort || {};
    };

    this.getParsedFilters = (toInt = []) => {
        const filterObject = this.getFilters();
        _.each(filterObject, ( value, key ) => {

            if(toInt.includes(key)){
                _.set(filterObject, key, +value);
            }

            if (_.isObject(value) && _.get(_.keys(value), 0) === "0") {
                _.set(filterObject, key, _.values(value));
            }
        } );

        return filterObject;
    };
}