import { request, url } from "Services";

const getItems = data => {
    const sendObj = {
        url: url.getUrl(`search/companies`),
        data,
        type: "GET",
    };

    return request.sendRequest(sendObj);
};

export const requestTable = value => data => {
    return new Promise(resolve => {
        getItems({ value, ...data }).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination,
            });
        });
    });
};
