import React from "react";
import PropTypes from "prop-types";
import {regExpForFloat} from "Services";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {Block, BlockWrap, Input, WrapInput} from "Templates/Form";
import {Span} from "Templates/Default";
import LinkIcon from "Templates/Link/LinkIcon";
import {NoteTop} from "Templates/Notes";
import {AddButton, Data, DataCol, DataRow} from "../../Templates";
import {budgetDefaultLabels} from "../../Reducers/BudgetFormReducer";

const propTypes = {
    productCode: PropTypes.string.isRequired,
    applicationCode: PropTypes.string.isRequired,
    plant: PropTypes.string.isRequired,
    materialType: PropTypes.string.isRequired,
    years: PropTypes.shape({
        [PropTypes.number]: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    addYear: PropTypes.func.isRequired,
    removeYear: PropTypes.func.isRequired,
    changeYear: PropTypes.func.isRequired,
    changeData: PropTypes.func.isRequired,
    service: PropTypes.shape({
        budgetDefaultLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
};

const BudgetGeneralBlock = ({
                                id,
                                productCode,
                                applicationCode,
                                plant,
                                materialType,
                                years,
                                onChange,
                                addYear,
                                removeYear,
                                changeYear,
                                changeData,
                                t,
                                service: {budgetDefaultLabels},
                            }) => {
    const yearsArr = Object.keys(years);
    const totalYears = 30;

    let currentYear = new Date().getFullYear() - Math.round(totalYears - 10);

    const yearOptions = new Array(totalYears).fill(null).reduce(acc => {
        currentYear = (Number(currentYear) + 1).toString();

        if (!yearsArr.includes(currentYear)) {
            return [...acc, {label: currentYear, value: currentYear, disable: false}];
        }

        return [...acc, {label: currentYear, value: currentYear, disable: true}];
    }, []);

    return (
        <>
            <Block>
                <BlockWrap>
                    <WrapInput isRequired label={t("Product Code")} name="productCode">
                        <Input
                            disabled={!!id}
                            type="asyncSelect"
                            url="/product/product-finder/product-code-list"
                            placeholder={t("Search")}
                            value={productCode}
                            onChange={value => onChange("productCode", value)}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired label={t("SAP Application Code")} name="applicationCode">
                        <Input
                            disabled={!!id}
                            type="asyncSelect"
                            url="/project/projects/application-code-list"
                            placeholder={t("Search")}
                            value={applicationCode}
                            onChange={value => onChange("applicationCode", value)}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired label={t("Selling Plant")} name="plant">
                        <Input
                            disabled={!!id}
                            type="asyncSelect"
                            url="/project/projects/plant-list"
                            placeholder={t("Search")}
                            value={plant}
                            onChange={value => onChange("plant", value)}
                        />
                    </WrapInput>
                </BlockWrap>
                <BlockWrap>
                    <WrapInput isRequired label={t("Material Type")} name="materialType">
                        <Input
                            disabled={!!id}
                            type="asyncSelect"
                            url="/project/projects/material-type-list"
                            placeholder={t("Search")}
                            value={materialType}
                            onChange={value => onChange("materialType", value)}
                        />
                    </WrapInput>
                </BlockWrap>
            </Block>

            <NoteTop>{t("Use pounds for volume")}</NoteTop>

            <Data>
                <DataCol>
                    <DataRow/>
                    <DataRow/>
                    {budgetDefaultLabels.map(elem => (
                        elem === 'sales_manager'
                        ? <DataRow key={`Div-${elem}`}>{t('Sales Manager (Budget)')}</DataRow>
                        : elem === 'Revenue'
                        ? <DataRow key={`Div-${elem}`}>{t('Budget')}</DataRow>
                        : elem === 'stretch_volume'
                        ? <DataRow key={`Div-${elem}`}>{t('Stretch Volume')}</DataRow>
                        : elem === 'stretch_revenue'
                        ? <DataRow key={`Div-${elem}`}>{t('Stretch Revenue')}</DataRow>
                        : <DataRow key={`Div-${elem}`}>{t(elem)}</DataRow>
                    ))}
                    <DataRow/>
                </DataCol>

                {yearsArr.map(year => (
                    <DataCol key={`Div-${year}`}>
                        <DataRow>
                            <Span className="bold-text">{t("Year")}</Span>
                            <LinkIcon icon="la-icon-close" onClick={() => removeYear(year)}/>
                        </DataRow>
                        <DataRow>
                            <div className="connection-year-select">
                                <Input
                                    type="select"
                                    options={[...yearOptions]}
                                    name={year}
                                    value={year}
                                    onChange={value => changeYear(value, year)}
                                    inputProps={{
                                        isOptionDisabled: option => option.disable,
                                    }}
                                />
                            </div>
                        </DataRow>
                        {budgetDefaultLabels.map(elem => (
                            <DataRow key={`Div-${year}-${elem}`}>
                                {elem === 'sales_manager' && (
                                    <div className="connection-year-select">
                                        <Input
                                            type="asyncSelect"
                                            name="sales_manager"
                                            url="/project/projects/user-list"
                                            placeholder={t("Search")}
                                            value={years[year].sales_manager || ''}
                                            onChange={
                                                value => changeData(year, 'sales_manager', value)
                                            }
                                        />
                                    </div>
                                ) || (
                                    <Input
                                        name={`${year}-${elem}`}
                                        value={years[year][elem.toLowerCase()] || ''}
                                        onChange={
                                            value => regExpForFloat(value, data => changeData(year, elem.toLowerCase(), data))
                                        }
                                    />
                                )}
                            </DataRow>
                        ))}
                    </DataCol>
                ))}
                <DataCol>
                    <DataRow/>
                    <DataRow>
                        <AddButton onClick={addYear}>{t("Add Year")}</AddButton>
                    </DataRow>
                </DataCol>
                <DataCol/>
            </Data>
        </>
    );
};

BudgetGeneralBlock.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        id: getStoreItem(state, "id"),
        years: getStoreItem(state, "years"),
        productCode: getStoreItem(state, "productCode"),
        applicationCode: getStoreItem(state, "applicationCode"),
        plant: getStoreItem(state, "plant"),
        materialType: getStoreItem(state, "materialType")
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        addYear: getActionStore("addYearAction", service, dispatch),
        removeYear: getActionStore("removeYearAction", service, dispatch),
        changeYear: getActionStore("changeYearAction", service, dispatch),
        changeData: getActionStore("changeDataAction", service, dispatch)
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(BudgetGeneralBlock);
