import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import { withTagDefaultProps } from "Hoc/Template";
import { withServiceConsumer } from "Services/Context";
import { ToggleButtons } from 'Templates/ToggleButtons';
import { weightUnitsList } from '../Constants/weightUnits';

const propTypes = {
  sendUnit: PropTypes.func.isRequired,
  getActiveUnit: PropTypes.func.isRequired,
};

const ConverterUnits = ({ sendUnit, getActiveUnit, weightUnit }) => {

  const handleUnits = (e, weight_unit) => {
    e.preventDefault();

    return new Promise(resolve => resolve(sendUnit({ weight_unit }), getActiveUnit(weight_unit)))
      .then(() => window.location.reload());
  }

  return (
    <ToggleButtons
      values={weightUnitsList}
      active={weightUnit.weightUnit}
      handleClick={handleUnits}
    />
  );
}

ConverterUnits.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    weightUnit: state.weightUnitState,
  };
};

const mapDispatchToProps = (dispatch, { service }) => {
  const { getActionStore } = service;

  return {
    sendUnit: getActionStore("sendUnit", service, dispatch),
    getActiveUnit: getActionStore("getActiveUnit", service, dispatch),
  };
};

export default compose(
  withServiceConsumer,
  withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(ConverterUnits);
