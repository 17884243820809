import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {onChange} from "../EventsService";
import {isSapImported} from "Services/enum";

const placeholders = {
    name: "Search",
    short_name: "Search",
    slug: "Search",
    customer_code: "Search",
    legacy_customer_code: "Search",
    customer_owner: "Search",
    duns_number: "Search",
    project_count: "Search",
    invoice_count: "Search",
    nicknames: "Search",
    phone: "Search",
    email: "Search",
    nda: "Search",
    supply_chain: "Search",
    corporate_group: "Search",
    notes: "Search",
    marked_deletion: "Select",
    address1: "Address",
    city: "City",
    country: "Country",
    region_code: "Region/State ABR",
    state: "Region/State",
    district: "District/County",
    zip: "Zip Code",
    time_zone: "Time Zone",
    changed_date: "Date Changed",
    updated_date: "Date Updated in ComOpps",
    created_code_date: "Date Created/Extended",
    created_system_date: "Date Created",
    owner: "Owner",
    sap_value: "Select",
};

const supplyChainOptions = [
    {
        value: "",
        label: "All",
    },
    {
        value: "1",
        label: "OEM Tier 1",
    },
    {
        value: "2",
        label: "OEM Tier 2",
    },
    {
        value: "3",
        label: "OEM Tier 3",
    },
    {
        value: "4",
        label: "Manufacturer",
    },
];

export const getFilter = (key, value, callback, request) => {
    switch (key) {
        case "supply_chain":
            return (
                <InputFilter
                    type="select"
                    options={supplyChainOptions}
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_value":
        case "marked_deletion":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "nda":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "nicknames":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/companies/nickname-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/user-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "updated_date":
        case "changed_date":
        case "created_code_date":
        case "created_system_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
