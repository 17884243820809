import React, {Component} from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {storageUrl, numberWithCommas} from "Services";
import {withServiceConsumer} from "Services/Context";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {
    TabBlock,
    TabBlockBtn,
    TabBlockCol,
    TabBlockColTitle,
    TabBlockColTitleH4,
    TabWrapTop,
} from "Templates/Content";
import {Modal} from "Templates/Modals";
import {ContainerTable} from "Hoc/Widgets";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";

import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import UploadFileForm from "./UploadFileForm";

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TabTable);

class Files extends Component {
    constructor(props) {
        super(props);
        this.defaultState = {
            showUploadFileModal: false,
            uploadFileForm: {
                files: [],
            },
        };

        this.state = {
            showUploadFileModal: false,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        };
    }

    onClickDelete = item => {
        const {t} = this.props;

        if (!item.can_edit) {
            alert('Contact the owner or an Admin to delete.');
            return;
        }

        if (confirm(t("Do you want to delete the File?"))) {
            const {
                fetchItems,
                request,
                service: {onClickDeleteItem},
            } = this.props;
            onClickDeleteItem(item).then(
                () => {
                    fetchItems(request);
                },
                errors => {
                    alert(errors.detail);
                },
            );
        }
    };

    handleClickOnDownloadFile = ({path}) => {
        const {storage_url} = this.props;
        const win = window.open(storageUrl(path, storage_url), "_blank");
        win.focus();
    };

    handleClickOnUploadFile = () => {
        const {addFiles} = this.props;
        const {
            uploadFileForm: {files},
        } = this.state;

        addFiles(files).then(this.callbackAfterRequestInModalWindow);
    };

    setFile = value => {
        this.setState(prevState => ({
            uploadFileForm: {
                ...prevState.uploadFileForm,
                files: [...value],
            },
        }));
    };

    openUploadFileModal = () => {
        this.setState({
            showUploadFileModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            ...this.defaultState,
            uploadFileForm: {
                ...this.defaultState.uploadFileForm,
            },
        });
    };

    handleCloseUploadModalWithCheck = event => {
        event.preventDefault();
        const {
            uploadFileForm: {files},
        } = this.state;
        const {t} = this.props;
        if (files.length >= 1) {
            if (!confirm(t("Are you sure you want to undo the action?"))) {
                return;
            }
        }
        this.closeModal();
    };

    callbackAfterRequestInModalWindow = res => {
        const {fetchItems} = this.props;
        if (res !== undefined) {
            this.closeModal();
            fetchItems({});
        }
    };

    render() {
        const {pagination, t} = this.props;
        const {showUploadFileModal, uploadFileForm} = this.state;
        return (
            <>
                {showUploadFileModal ? (
                    <Modal onRequestClose={this.handleCloseUploadModalWithCheck}>
                        <UploadFileForm
                            form={uploadFileForm}
                            onAppend={this.handleClickOnUploadFile}
                            onClose={this.handleCloseUploadModalWithCheck}
                            onChange={this.setFile}
                        />
                    </Modal>
                ) : null}
                <TabWrapTop>
                    <TabBlock>
                        <TabBlockCol>
                            <TabBlockColTitle>{t("Total")}</TabBlockColTitle>
                            <TabBlockColTitleH4>
                                {`${numberWithCommas(pagination.total, 0)} ${t(
                                    "Files",
                                )}`}
                            </TabBlockColTitleH4>
                        </TabBlockCol>
                    </TabBlock>
                    <CheckPermission permission="grade_product_files">
                        <TabBlockBtn onClick={this.openUploadFileModal}>{t("Add New File")}</TabBlockBtn>
                    </CheckPermission>
                </TabWrapTop>
                <TabViewDetail
                    customIcon="icon_download"
                    onClickCustom={this.handleClickOnDownloadFile}
                    onClickDelete={this.onClickDelete}
                />
            </>
        );
    }
}

Files.propTypes = {
    pagination: PropTypes.shape({total: PropTypes.number}).isRequired,
    service: PropTypes.shape({
        onClickDeleteItem: PropTypes.func,
        getStoreItem: PropTypes.func,
        getActionStore: PropTypes.func,
    }).isRequired,
    request: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchItems: PropTypes.func.isRequired,
    addFiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {service: {getStoreItem}}) => {
    return {
        pagination: getStoreItem(state, "pagination"),
        request: {
            filter: {
                ...getStoreItem(state, "filter"),
            },
            sort: {
                ...getStoreItem(state, "sort"),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        addFiles: getActionStore("createFilesAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(Files);
