import Constants from "../Constants";
import {uniqueGenerator} from "Services";
import {concat, uniqBy} from "lodash";

const EMPTY_CONTACT = {user: '', role: ''};

export const initState = {
    showModal: false,
    list: {items: [], pagination: {}},
    form: [{id: uniqueGenerator(), user: '', role: ''}],
    groupId: ''
};

export const reducer = (state, action) => {
    const {data} = action;
    const {contacts} = state;
    switch (action.type) {
        case Constants.OPEN_CONTACTS_LIST_MODAL:
            return {
                ...state, contacts: {
                    ...contacts,
                    showModal: true,
                },
            };

        case Constants.CLOSE_CONTACTS_LIST_MODAL:
            return {
                ...state,
                contacts: {
                    ...contacts,
                    showModal: false,
                    form: [{id: uniqueGenerator(), user: '', role: ''}],
                },
            };

        case Constants.UPDATE_CONTACTS_FORM: {
            return {
                ...state, contacts: {
                    ...contacts,
                    form: data,
                }
            };
        }

        case Constants.GET_CONTACTS_SUCCEEDED: {
            return {
                ...state, contacts: {
                    ...contacts,
                    list: data,
                }
            };
        }

        case Constants.GET_NEXT_PAGE_CONTACTS_SUCCEEDED: {
            const {pagination, items} = data;
            return {
                ...state, contacts: {
                    ...contacts,
                    list: {
                        items: uniqBy(concat(contacts.list.items, items), 'id'),
                        pagination,
                    }
                }
            }
        }

        default:
            return state;
    }
};