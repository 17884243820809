const Constants = {
    FETCH_COST_DATA_REQUESTED: "FETCH_COST_DATA_REQUESTED",
    FETCH_COST_DATA_SUCCEEDED: "FETCH_COST_DATA_SUCCEEDED",
    FETCH_COST_DATA_FAILED: "FETCH_COST_DATA_FAILED",

    CHANGE_GRADE_REQUESTED: "CHANGE_GRADE_REQUESTED",
    CHANGE_GRADE_SUCCEEDED: "CHANGE_GRADE_SUCCEEDED",
    CHANGE_GRADE_FAILED: "CHANGE_GRADE_FAILED",

    CHANGE_COST_DATA: "CHANGE_COST_DATA",
};
export default Constants;
