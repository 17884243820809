import React, { useEffect } from "react";
import { compose } from "redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { withServiceConsumer } from "Services/Context";
import { isEqual, url } from "Services";
import { Title } from "Templates/Titles";
import { BlockSubmit, ButtonRight, ButtonLeft, SubmitBlockButton } from "Templates/Form";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import { BackLink } from "Templates/Link";
import FormBlock from "../../Views/Form";

const propTypes = {
    validate: PropTypes.bool.isRequired,
    old: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    submitForm: PropTypes.func.isRequired,
};

const User = ({ formData, old, validate, pref, t, submitForm, permissions }) => {
    useEffect(() => {
        if(!permissions.includes('admin_users_crud')) {
            url.redirect(`/`);
        }
    }, [url, permissions])

    const handleClickOnSave = () => {
        let existDefaultCompany = _.findIndex(formData.team, (o) => o.defaultCompany) >= 0;

        if(!existDefaultCompany) {
            alert('Please set default company');
            return;
        }

        formData.team = _.filter(formData.team, {haveAccess: true})
        submitForm({ ...formData }).then(
            data => {
                url.redirect(`/admin/users/${data.id}`);
            },
            errors => {
                alert(errors.detail);
            },
        );
    };

    const handleClickOnCancelButton = () => {
        if (window.confirm(t("Cancel?"))) {
            url.redirect("/admin/users");
        }
    };

    const validateAll = validate ? !isEqual({ ...formData }, old, _.keys(old)) : validate;

    return (
        <CheckPermission permission="admin_users_crud">
            <Title
                title={t("Edit User")}
                before={() => (
                    <BackLink className={`${pref}__info-top-btn main-content__info-top-btn`}>
                        <i className="la arrow-left" />
                    </BackLink>
                )}
            />
            <FormBlock />
            <BlockSubmit>
                <ButtonRight>
                    <SubmitBlockButton onClick={handleClickOnCancelButton}>{t("Cancel")}</SubmitBlockButton>
                </ButtonRight>
                <ButtonLeft>
                    <SubmitBlockButton disabled={!validateAll} onClick={handleClickOnSave}>
                        {t("Save")}
                    </SubmitBlockButton>
                </ButtonLeft>
            </BlockSubmit>
        </CheckPermission>
    );
};

User.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => ({
    old: getStoreItem(state, "old"),
    validate: getStoreItem(state, `form_validate`),
    formData: state.editState,
});

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        submitForm: getActionStore("submitFormAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(User);
