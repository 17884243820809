import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {ServiceProvider, withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import {ContentWrap, ContentWrapCol} from "Templates/Content";
import Tabs from "Templates/Tabs/Tabs";
import Tab from "Templates/Tabs/Tab";
import _dataYearsService from "../Services/Tabs/DataYear";
import DataYears from "./Tabs/DataYear";

const MainBlock = ({ id, activeTab, t, onChangeTab }) => {
    const dataYearsService = _dataYearsService(id);

    return (
        <ContentWrap>
            <ContentWrapCol>
                <Tabs
                    activeTab={activeTab}
                    onClickTabItem={(index) => {
                        if (activeTab !== index) {
                            onChangeTab(index);
                        }
                    }}
                >
                    <Tab label={t("Budget")}>
                        <ServiceProvider value={dataYearsService}>
                            <DataYears/>
                        </ServiceProvider>
                    </Tab>

                    <Tab label=''/>
                </Tabs>
            </ContentWrapCol>
        </ContentWrap>
    );
};

MainBlock.defaultProps = {
    totalNotes: 0,
    totalFinanceNotes: 0,
};

MainBlock.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;
    return {
        id: getStoreItem(state, "view.id"),
        activeTab: getStoreItem(state, "activeTab"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChangeTab: getActionStore("onChangeTab", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(MainBlock);
