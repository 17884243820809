import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { projectStatus } from "Services/enum";
import { Row, Col, BlockWrap, Form, BlockTitle, WrapInput, Input } from "Templates/Form";
import { withServiceConsumer } from "Services/Context";
import { withTagDefaultProps, PermissionsProps } from "Hoc/Template";
import { RadioBlock, RadioButton, RadioLabel } from "Templates/Form/Radio";
import { MainButton } from "Templates/Content";

const propTypes = {
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    old: PropTypes.shape({
        marginListSelect: PropTypes.string,
        statusProject: PropTypes.string,
        periodFrom: PropTypes.string,
        periodTo: PropTypes.string,
        timeScale: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
        marginListSelect: PropTypes.string.isRequired,
        statusProject: PropTypes.string.isRequired,
        periodFrom: PropTypes.string.isRequired,
        periodTo: PropTypes.string.isRequired,
        timeScale: PropTypes.string.isRequired,
    }).isRequired,
    service: PropTypes.shape({
        optionsForFirstSelect: PropTypes.func.isRequired,
        formData: PropTypes.func.isRequired,
    }).isRequired,
    getChartData: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const radioButtons = [
    { label: "Fiscal Year", value: "1" },
    { label: "Quarterly", value: "2" },
    { label: "Monthly", value: "3" },
];

const FormBlock = ({
    permissions,
    old,
    form,
    service: { optionsForFirstSelect, formData },
    getChartData,
    onChange,
    t,
}) => {
    const optionsCount = 30;
    const currentYear = new Date().getFullYear() - 20;
    const periodToOptions = new Array(optionsCount)
        .fill(null)
        .reduce(
            (acc, elem, index) => [...acc, { label: `${currentYear + index}`, value: `${currentYear + index}` }],
            [],
        );

    const formValidate = () => {
        const { marginListSelect, periodFrom, periodTo, timeScale } = form;
        return Boolean(marginListSelect && periodFrom && periodTo && timeScale);
    };
    const periodFromOptions = periodToOptions.map(elem => ({ ...elem, disable: elem.value < form.periodFrom }));

    const _onChange = (name, value) => onChange(`analytics.form.${name}`, value);

    return (
        <BlockWrap className="chart-form">
            <Form>
                <BlockTitle>{t("Show Results For")}</BlockTitle>
                <WrapInput name="Select 1">
                    <Input
                        type="select"
                        options={optionsForFirstSelect(permissions)}
                        placeholder={t("Select")}
                        value={form.marginListSelect}
                        onChange={value => _onChange("marginListSelect", value)}
                    />
                </WrapInput>
                <WrapInput name="Period" label={t("Period")}>
                    <Row>
                        <Col>
                            <Input
                                type="select"
                                options={periodToOptions}
                                name="Period1"
                                placeholder={t("Select")}
                                value={form.periodFrom}
                                onChange={value => _onChange("periodFrom", value)}
                            />
                        </Col>
                        <Col>
                            <Input
                                type="select"
                                options={periodFromOptions}
                                name="Period2"
                                inputProps={{ isOptionDisabled: option => option.disable }}
                                placeholder={t("Select")}
                                value={form.periodTo}
                                onChange={value => _onChange("periodTo", value)}
                            />
                        </Col>
                    </Row>
                </WrapInput>
                <WrapInput name="Timescale" label={t("Timescale")}>
                    <Row>
                        {radioButtons.map(elem => (
                            <Col key={`Col-${elem.value}`}>
                                <RadioBlock>
                                    <RadioButton
                                        htmlFor={`radio_1-${elem.value}`}
                                        name={`radio-${elem.value}`}
                                        value={form.timeScale === elem.value}
                                        onChange={() => _onChange("timeScale", elem.value)}
                                    >
                                        <RadioLabel label={t(elem.label)} />
                                    </RadioButton>
                                </RadioBlock>
                            </Col>
                        ))}
                    </Row>
                </WrapInput>
                <WrapInput name="statusProject" label={t("Status")}>
                    <Row>
                        <Col>
                            <Input
                                type="select"
                                options={[
                                    {
                                        value: "0",
                                        label: "All",
                                    },
                                    ...projectStatus,
                                ]}
                                name="statusProject"
                                placeholder={t("Select")}
                                value={form.statusProject}
                                onChange={value => _onChange("statusProject", value)}
                            />
                        </Col>
                    </Row>
                </WrapInput>
                <MainButton
                    disabled={!formValidate() || JSON.stringify(form) === JSON.stringify(old)}
                    className="main-btn_primary"
                    onClick={() => getChartData(formData(form))}
                >
                    {t("Apply")}
                </MainButton>
            </Form>
        </BlockWrap>
    );
};

FormBlock.propTypes = propTypes;

const mapStateToProps = (state, { service: { getStoreItem } }) => {
    return {
        old: getStoreItem(state, "analytics.old"),
        form: getStoreItem(state, "analytics.form"),
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
        getChartData: getActionStore("getChartDataAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(FormBlock);
