import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { ModalDefault } from "Templates/Modals";
import { withTagDefaultProps } from "Hoc/Template";
import { ButtonClose } from "Templates/Button";
import { withServiceConsumer } from "Services/Context";

const { Button, Form, Title, ColInput } = ModalDefault;

const propTypes = {
    titleText: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onAppend: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

class CreateModal extends Component {
    state = {
        name: "",
    };

    handleChangeName = value => {
        this.setState({ name: value });
    };

    handleClickOnSaveButton = () => {
        const { name } = this.state;
        const { onAppend, onClose } = this.props;
        onAppend({ name }).then(onClose);
    };

    render() {
        const { name } = this.state;
        const { titleText, buttonText, t, onClose } = this.props;

        return (
            <Form>
                <ButtonClose className="main-modal__form-close" onClick={onClose} />
                <Title>{t(titleText)}</Title>
                <ColInput placeholder={t("Enter Name")} name="name" value={name} onChange={this.handleChangeName} />
                <Button onClick={this.handleClickOnSaveButton} disabled={name.length === 0}>
                    {t(buttonText)}
                </Button>
            </Form>
        );
    }
}

CreateModal.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(CreateModal);
