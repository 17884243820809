import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "./EventsService";
import {isSapImported} from "Services/enum";

export const getTableLabel = key => {
    const tableLabel = {
        name: "User Name",
        title: "Title",
        employer: "Employer",
        company: "Company Name",
        phone: "Phone Number",
        email: "Email",
        type: "Int/Ext",
        owner: "Owner",
        paid: "Internal User",
        is_admin: "Admin",
        expiration_date: "Expiration Date",
    };
    return _.get(tableLabel, key, key);
};

export const getFilter = (key, value, callback, request) => {
    const placeholder = {
        name: "Search",
        title: "Search",
        employer: "Search",
        company: "Search",
        phone: "Search",
        email: "Search",
        type: "All",
        owner: "Search",
        paid: "Select",
        is_admin: "Select",
        expiration_date: "Expiration Date",
    };
    switch (key) {
        case "is_admin":
        case "paid":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isSapImported]}
                    placeholder={_.get(placeholder, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "expiration_date":
            return (
                <InputFilter
                    type="date-range"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholder, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
