import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTagDefaultProps } from "Hoc/Template";
import Img from "Templates/Img";
import TableBodyButtonsBlock from "Templates/Table/TableBodyButtonsBlock";
import TableBodyButton from "Templates/Table/TableBodyButton";

const propTypes = {
    onClickEdit: PropTypes.func.isRequired,
};

const TableRowItemEdit = ({ onClickEdit }) => (
    <TableBodyButtonsBlock>
        <TableBodyButton onClick={onClickEdit}>
            <Img img="icon_edit" />
        </TableBodyButton>
    </TableBodyButtonsBlock>
);

TableRowItemEdit.propTypes = propTypes;

export default compose(withTagDefaultProps())(TableRowItemEdit);
