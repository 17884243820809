import React from "react";
import { marginEnum } from "Services/enum";
import { Span } from "Templates/Default";
import { FormBlockLink } from "Templates/Form";
import main, { getSelectedOption } from "../index";
import { formData } from "./FormService";
import { getStoreItem, getChartData, getActionStore } from "./StoreService";
import { getData, getItemOptions } from "../RequestService";

const optionsForFirstSelect = marginEnum;

const optionsForSecondSelect = [
    {
        label: "IHS Automotive",
        options: [
            { label: "Country", value: "1", urlToOptions: "country-list" },
            { label: "Region", value: "2", urlToOptions: "region-list" },
        ],
    },
    {
        label: "Unit Forecast(s)",
        options: [
            { label: "Manufacturer", value: "3", url: "/companies", urlToOptions: "manufacturer-list" },
            { label: "Unit Name", value: "5", url: "/project/forecasts", urlToOptions: "unit-list" },
        ],
    },
];

const getFirstColumnInTheTable = (item, dynamicSelect) => {
    const selectedOption = getSelectedOption(dynamicSelect, optionsForSecondSelect);
    switch (selectedOption.label) {
        case "Manufacturer":
            return <FormBlockLink href={`${selectedOption.url}/${item.id}`}>{item.label}</FormBlockLink>;

        case "Unit Name":
            return <FormBlockLink href={`${selectedOption.url}/${item.id}`}>{item.label}</FormBlockLink>;

        default:
            break;
    }
    return <Span>{item.label}</Span>;
};

export default () => ({
    ...main,
    getStoreItem,
    getChartData,
    getData: getData("/forecasts/market-trends"),
    getItems: getItemOptions("/forecasts/market-trends"),
    optionsForFirstSelect,
    optionsForSecondSelect,
    getFirstColumnInTheTable,
    getActionStore,
    formData,
});
