import React, {Fragment, useEffect} from "react";
import {compose} from "redux";
import {CheckPermission, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import TableBody from "./TableBody";
import {
    generateBeforePrevYearTitleWidth,
    generateColumns,
    generateCurrYearTitleWidth,
    generateNextYearTitleWidth,
    generatePrevYearTitleWidth,
    generateRevenueTitleWidth,
    generateTitles,
    generateUniqCols,
    generateVolumeTitleWidth
} from "../../../../Project/Projects/View/Constants/FinanceTableTitlesConstants";

const TABS = {
    YEARLY: {
        label: 'yearly',
        value: 0
    },
    QUARTERLY: {
        label: 'quarterly',
        value: 1
    },
    MONTHLY: {
        label: 'monthly',
        value: 2
    },
    FORECAST: {
        label: 'forecast',
        value: 3
    },
    DETAILS: {
        label: 'details',
        value: 4
    },
}

const currYear = new Date().getFullYear();
const prevYear = new Date().getFullYear() - 1;
const nextYear = new Date().getFullYear() +1;
const beforePrevYear = new Date().getFullYear() - 2;

const FinanceContainerTable = (TableHeadColumn, TableRowColumn, { showButtons = false }) => ({
    request,
    columns,
    pinned_columns,
    customIcon,
    fetchItems,
    onClickEdit,
    onClickItem,
    onClickDelete,
    onClickCustom,
    activeTab,
    service: { tableButtonsPermission },
}) => {
    const showColumns = showButtons ? [...columns, "-1"] : [...columns];

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.querySelectorAll(`.main-table__head`).forEach((el) => el.removeAttribute('style'));
            document.querySelectorAll(`.main-table__body`).forEach((el) => el.removeAttribute('style'));
            document.querySelectorAll('.last-pinned').forEach((el) => el.classList.remove('last-pinned'));

            if (showColumns.length && pinned_columns.length) {
                const cols = showColumns.reduce((acc, curr) => {
                    if (pinned_columns.includes(curr)) {
                        acc.push(curr)
                    }
                    return acc
                }, [])

                if (cols.length) {
                    let totalWidth = 0;
                    const tableBodyCols = document.querySelectorAll(`.td.pinned-column`)
                    const tableBodyLastCols = document.querySelectorAll(`.td.${cols[cols.length - 1]}`)

                    if (tableBodyCols.length) {
                        tableBodyCols.forEach((el) => {
                            const tooltip = el.querySelector(`.tooltip`)

                            if (tooltip) {
                                el.classList.add('with-tooltip')
                            }
                        })
                    }

                    if (tableBodyLastCols.length) {
                        tableBodyLastCols.forEach((el) => el.classList.add('last-pinned'))
                    }

                    cols.forEach((col, i) => {
                        const elem = document.querySelector(`.th.${col}`);
                        const width = document.querySelector(`.th.${col}`).clientWidth - 10;

                        if (i === (cols.length - 1)) elem.classList.add('last-pinned')

                        if (i !== cols.length) {
                            elem.style.left = `${totalWidth}px`;

                            if (tableBodyCols.length) {
                                tableBodyCols.forEach((bodyCol, index) => {
                                    if ((index % cols.length) === i) {
                                        bodyCol.style.left = `${totalWidth}px`
                                    }
                                })
                            }

                            totalWidth += width;
                        }
                    })
                }
            }
        }
    }, [pinned_columns, request])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            generateBeforePrevYearTitleWidth(beforePrevYear, activeTab)
            generatePrevYearTitleWidth(prevYear, activeTab)
            generateCurrYearTitleWidth(currYear, activeTab)
            generateNextYearTitleWidth(nextYear, activeTab)

            generateVolumeTitleWidth(prevYear, currYear, nextYear, activeTab)
            generateRevenueTitleWidth(prevYear, currYear, nextYear, activeTab)
        }
    }, [showColumns, activeTab])

    const onSubmit = e => {
        e.preventDefault();

        fetchItems(request);
    };

    const onSortClick = column => {
        const asc = "asc";
        const desc = "desc";
        const newRequest = {
            ...request,
            sort: {
                ...request.sort,
                column,
            },
        };
        if (request.sort.column === column) {
            newRequest.sort.direction = request.sort.direction === asc ? desc : asc;
        } else {
            newRequest.sort.direction = asc;
        }

        fetchItems(newRequest);
    };

    const Titles = () => {
        const uniqueTitles = {};
        const cols = generateColumns(showColumns, beforePrevYear, prevYear, currYear, nextYear)
        const uniqCols = generateUniqCols(uniqueTitles, cols)

        return generateTitles(uniqCols, activeTab, TABS, beforePrevYear, prevYear, currYear)
    }

    return (
        <form onSubmit={onSubmit}>
            <ul className={activeTab === TABS.DETAILS.value ? 'details finance-titles' : 'finance-titles'}>
                <Titles />
            </ul>
            <table className="main-table">
                <tbody>
                    <tr>
                        {showColumns.map((column, index) => {
                            const col = column.includes('FY') ? 'FY' : '';
                            const actual = column.includes('actual') ? 'actual' : 'forecast';

                            return (
                                <Fragment key={`TableRowColumn-${index}`}>
                                    {column === "-1" ? (
                                        <CheckPermission permission={tableButtonsPermission}>
                                            <th key={`TableHeadColumn-${index}`} className="main-table__head" />
                                        </CheckPermission>
                                    ) : (
                                        <th
                                            key={`TableHeadColumn-${index}`}
                                            className={`
                                                ${pinned_columns && pinned_columns.length && pinned_columns.includes(column) ? `th pinned-column ${column}` : ''}
                                                main-table__head 
                                                ${col}
                                                ${column.includes(`FY_${beforePrevYear}_Q4`) ? `FY_${beforePrevYear}_Q4` : ''}
                                                
                                                ${column.includes(`FY_${prevYear}_Q1`) ? `FY_${prevYear}_Q1` : ''}
                                                ${column.includes(`FY_${prevYear}_Q2`) ? `FY_${prevYear}_Q2` : ''}
                                                ${column.includes(`FY_${prevYear}_Q3`) ? `FY_${prevYear}_Q3` : ''}
                                                ${column.includes(`FY_${prevYear}_Q4`) ? `FY_${prevYear}_Q4` : ''}
                                                ${column.includes(`FY_${prevYear}`) && !column.includes(`volume_FY_${prevYear}_`) && !column.includes(`revenue_FY_${prevYear}_`) && !column.includes(`FY_${prevYear}_Q`) ? `FY_${prevYear}_prev` : ''}
                                                
                                                ${column.includes(`FY_${currYear}_Q1`) ? `FY_${currYear}_Q1` : ''}
                                                ${column.includes(`FY_${currYear}_Q2`) ? `FY_${currYear}_Q2` : ''}
                                                ${column.includes(`FY_${currYear}_Q3`) ? `FY_${currYear}_Q3` : ''}
                                                ${column.includes(`FY_${currYear}_Q4`) ? `FY_${currYear}_Q4` : ''}
                                                ${column.includes(`FY_${currYear}`) && !column.includes(`volume_FY_${currYear}_`) && !column.includes(`revenue_FY_${currYear}_`) && !column.includes(`FY_${currYear}_Q`) ? `FY_${currYear}_curr` : ''}
                                                
                                                ${column.includes(`FY_${nextYear}_Q1`) ? `FY_${nextYear}_Q1` : ''}
                                                ${column.includes(`FY_${nextYear}_Q2`) ? `FY_${nextYear}_Q2` : ''}
                                                ${column.includes(`FY_${nextYear}_Q3`) ? `FY_${nextYear}_Q3` : ''}
                                                ${column.includes(`FY_${nextYear}_Q4`) ? `FY_${nextYear}_Q4` : ''}
                                                ${column.includes(`FY_${nextYear}`) && !column.includes(`volume_FY_${nextYear}_`) && !column.includes(`revenue_FY_${nextYear}_`) && !column.includes(`FY_${nextYear}_Q`) ? `FY_${nextYear}_next` : ''}
                                                
                                                ${column.includes(`volume_FY_${prevYear}_`) && !column.includes(`volume_FY_${prevYear}_Q`) ? `volume_FY_${prevYear}_month ${actual}` : ''}
                                                ${column.includes(`volume_FY_${currYear}_`) && !column.includes(`volume_FY_${currYear}_Q`) ? `volume_FY_${currYear}_month ${actual}` : ''}
                                                ${column.includes(`volume_FY_${nextYear}_`) && !column.includes(`volume_FY_${nextYear}_Q`) ? `volume_FY_${nextYear}_month ${actual}` : ''}
                                    
                                                ${column.includes(`revenue_FY_${prevYear}_`) && !column.includes(`revenue_FY_${prevYear}_Q`) ? `revenue_FY_${prevYear}_month ${actual}` : ''}
                                                ${column.includes(`revenue_FY_${currYear}_`) && !column.includes(`revenue_FY_${currYear}_Q`) ? `revenue_FY_${currYear}_month ${actual}` : ''}
                                                ${column.includes(`revenue_FY_${nextYear}_`) && !column.includes(`revenue_FY_${nextYear}_Q`) ? `revenue_FY_${nextYear}_month ${actual}` : ''}
                                             `}>
                                            <TableHeadColumn onSortClick={onSortClick} column={column} index={index} />
                                        </th>
                                    )}
                                </Fragment>
                            );
                        })}
                    </tr>
                    <TableBody
                        columns={showColumns}
                        TableRowColumn={TableRowColumn}
                        customIcon={customIcon}
                        onClickItem={onClickItem}
                        onClickEdit={onClickEdit}
                        onClickDelete={onClickDelete}
                        onClickCustom={onClickCustom}
                    />
                </tbody>
            </table>
            <button type="submit" className="visually-hidden" />
        </form>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { getStoreItem } = ownProps.service;

    return {
        columns: getStoreItem(state, "columns"),
        activeTab: getStoreItem(state, "activeTab"),
        pinned_columns: getStoreItem(state, "pinned_columns"),
        request: {
            filter: {
                ...getStoreItem(state, "filter", true),
            },
            sort: {
                ...getStoreItem(state, "sort", true),
            },
        },
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    const { getActionStore } = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
    };
};

export default (TableHeadColumn, TableRowColumn, { showButtons = false } = {}) =>
    compose(
        withServiceConsumer,
        withTagDefaultProps(mapStateToProps, mapDispatchToProps),
    )(FinanceContainerTable(TableHeadColumn, TableRowColumn, { showButtons }));
