import React from "react";
import {compose} from "redux";
import {CustomSimpleTable} from "Hoc/Widgets/Table/SimpleTableContainer";
import {withTagDefaultProps} from "Hoc/Template";

import GeneralTableBodyColumn from "./Table/TableBodyColumn";
import * as GeneralService from "../../../../../Services/Tabs/General/Table/GeneralTableService";
import ExpandedBlock from "../../../../Templates/ExpandedBlock";

const GeneralTable = CustomSimpleTable()(() => null)()(GeneralTableBodyColumn)()(() => null);

const GeneralBlock = ({ t, block, expanded, setExpandedAction }) => {
    return (
        <div className="project-expanded-block">
            <ExpandedBlock title={t("General")} service={GeneralService} block={block} isExpanded={expanded} setExpandedAction={setExpandedAction}>
                <GeneralTable />
            </ExpandedBlock>
        </div>
    );
};

GeneralBlock.propTypes = {};

export default compose(withTagDefaultProps())(GeneralBlock);
