import _configureStore from "App/Store";
import {getMainModifiedStoreItems} from "App/Services";
import {filter} from "Services";
import _ from "lodash";
import reducers, {initialStates} from "../Reducers";
import {initState} from "../../../Company/View/Reducers/ViewReducer";

export default function configureStore(props) {
    const {items, pagination, columns} = props;

    const {mainState} = initialStates;

    const initialState = {
        ...getMainModifiedStoreItems({...props, pref: "system"}),
        listState: {
            ...mainState, items, pagination, columns, filter: filter.getParsedFilters(),
            sort: filter.getSort(),
        },
    };

    if (initState.activeTab) {
        _.unset(initialState.listState, "items");
    }

    return _configureStore(initialState, reducers);
}
