import React from "react";
import { getString, diffDate, formatDateToLocal } from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemLinkImg from "Templates/Table/TableRowItemLinkImg";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLinkImg
                    title={getString(item, key)}
                    href={`/admin/users/${getString(item, "id")}`}
                    img={getString(item, "photo", "")}
                >
                    {getString(item, key)}
                </TableRowItemLinkImg>
            );

        case "company":
            return (
                <TableRowItemLink
                    title={getString(item, "company_name")}
                    href={`/companies/${getString(item, "company_id")}`}
                >
                    {getString(item, "company_name")}
                </TableRowItemLink>
            );

        case "email":
            return (
                <TableRowItemLink title={getString(item, key)} href={`mailto:${getString(item, key)}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "expiration_date":
            const value = getString(item, key);

            if (!value || value === '-') {
                return <TableRowItemSpan />;
            }

            let dateColor = "";
            const date = formatDateToLocal(value, "MMM DD, YYYY H:mm A");

            const dateDiff = diffDate(value, false, "minutes");

            switch (true) {
                case dateDiff <= 0:
                    dateColor = "clr-red";
                    break;
            }

            return (
                <TableRowItemSpan title={date} className={dateColor}>
                    {date}
                </TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
