import React from "react";
import {compose} from "redux";
import PropTypes from "prop-types";
import {url, numberWithCommas} from "Services";
import {CheckPermission, PermissionsProps, withTagDefaultProps} from "Hoc/Template";
import {withServiceConsumer} from "Services/Context";
import {MainButton, TabWrap} from "Templates/Content";
import {Modal} from "Templates/Modals";
import {Title, WrapButtons, Total} from "Templates/Titles";
import {ContainerTable} from "Hoc/Widgets";
import {TableHeadColumnWrap} from "Templates/Table/TableHeadColumnWrap";
import {customTable} from "Templates/Tabs/DefaultWrap/TabViewDetail";
import TableRowColumnWrap from "./Table/TableRowColumn/TableRowColumnWrap";
import UploadFileForm from "./UploadFileForm";

const propTypes = {
    title: PropTypes.number.isRequired,
    addFiles: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TabTable = ContainerTable(TableHeadColumnWrap, TableRowColumnWrap);
const TabViewDetail = customTable(TabTable);

class TabView extends React.Component {
    state = {
        showUploadFileModal: false,
    };

    componentDidMount() {
        if(!this.props.permissions.includes('admin_ihs_crud')) {
            url.redirect(`/`);
        }
    }

    openUploadFileModal = () => {
        this.setState({
            showUploadFileModal: true,
        });
    };

    handleCloseUploadModalWithCheck = files => event => {
        event.preventDefault();
        const {t} = this.props;
        if (files.length >= 1) {
            if (window.confirm(t("Are you sure you want to undo the action?"))) {
                this.closeModal();
            }
        } else {
            this.closeModal();
        }
    };

    closeModal = () => {
        this.setState({
            showUploadFileModal: false,
        });
    };

    callbackAfterRequestInModalWindow = res => {
        const {fetchItems} = this.props;
        if (res !== undefined) {
            this.closeModal();
            fetchItems({});
        }
    };

    handleClickOnUploadFile = files => {
        const {addFiles} = this.props;
        return addFiles({files}).then(this.callbackAfterRequestInModalWindow);
    };

    render() {
        const {title, t} = this.props;
        const {showUploadFileModal} = this.state;

        const onClickShow = item => {
            const win = window.open(`/admin/ihs/${item.id}`);
            win.focus();
        };

        return (
            <CheckPermission permission="admin_ihs_crud">
                {showUploadFileModal ? (
                    <Modal onRequestClose={this.closeModal}>
                        <UploadFileForm
                            onAppend={this.handleClickOnUploadFile}
                            onClose={this.handleCloseUploadModalWithCheck}
                        />
                    </Modal>
                ) : null}
                <Title title={t("IHS")} total={() => <Total>{`${numberWithCommas(title, 0)} ${t("Total")}`}</Total>}>
                    <WrapButtons>
                        <MainButton className="main-btn_primary" onClick={this.openUploadFileModal}>
                            {t("Upload File")}
                        </MainButton>
                    </WrapButtons>
                </Title>
                <TabWrap>
                    <TabViewDetail
                        customIcon="icon_external_link"
                        onClickCustom={onClickShow}
                    />
                </TabWrap>
            </CheckPermission>
        );
    }
}

TabView.propTypes = propTypes;

const mapStateToProps = (state, {service: {getStoreItem}}) => ({
    items: getStoreItem(state, "items"),
    title: getStoreItem(state, "pagination.total"),
});

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        fetchItems: getActionStore("fetchItems", service, dispatch),
        addFiles: getActionStore("createFilesAction", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    PermissionsProps,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TabView);
