import { handleScroll } from "../../EventsService";
import { deleteItem, requestTable } from "../../RequestService";
import { getStoreItem, getActionStore } from "./StoreService";
import { getFilter, getTableLabel } from "./TableHeaderService";
import { getViewItem, modifierValues } from "./TableBodyService";

export default id => ({
    requestTable: requestTable(`/admin/list/safeties/${id}/publications`),
    onClickDeleteItem: item => deleteItem(`/product/safeties/${item.publication_safety_id}/delete`),
    getViewItem,
    getFilter,
    modifierValues,
    getTableLabel,
    handleScroll,
    getStoreItem,
    getActionStore,
});
