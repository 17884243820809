import React from "react";
import {TabWrap} from "Templates/Content";
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";
import SharedTableHeadColumnWrap from "./SharedTableHeadColumnWrap";
import {SharedTableRowColumnWrap} from "./SharedTableRowColumnWrap";
import SharedContainerTable from "./SharedContainerTable";
import {sharedCustomTable} from "./SharedTabViewDetail";

const DefaultTabTable = SharedContainerTable(SharedTableHeadColumnWrap, SharedTableRowColumnWrap);
const TabViewShared = sharedCustomTable(DefaultTabTable);

const Shared = ({ onClickCustom, customIcon }) => {
    return <TabWrap>
        <TabViewShared
            customIcon={customIcon}
            onClickCustom={onClickCustom}
        />
    </TabWrap>
}

const mapStateToProps = state => {
    return {

    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps),
)(Shared);