import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { withTagDefaultProps } from "Hoc/Template";
import { BlockTitle, Block, BlockShadow, Tooltip } from "Templates/Form";
import { ServiceProvider, withServiceConsumer } from "Services/Context";

import * as _productService from "../../Services/Table/ProductService";
import ProductsBlock from "./Table/Product/ProductsBlock";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
        getActionStore: PropTypes.func.isRequired,
    }).isRequired,
};

const ProductBlock = ({ service: { getStoreItem, getActionStore }, t }) => {
    const productService = {
        ..._productService,
        getStoreItem,
        getActionStore,
    };

    return (
        <>
            <BlockTitle>
                {t("Product")}
                <Tooltip>{t(`Select a product(s) to associate with this project`)}</Tooltip>
            </BlockTitle>
            <Block>
                <BlockShadow className="project__multiline-items-container products-container">
                    <ServiceProvider value={productService}>
                        <ProductsBlock/>
                    </ServiceProvider>
                </BlockShadow>
            </Block>
        </>
    );
};

ProductBlock.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(ProductBlock);
