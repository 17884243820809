import _ from "lodash";
import Constants from "../Constants";
import {url} from "Services";

export const initState = {
    view: {},
    activeTab: +url.get('activeTab'),
    oldValue: {},
    editValue: {},
    errors: {},
};

export const reducer = (state, action) => {
    const { data } = action;

    switch (action.type) {
        case Constants.CHANGE_TAB:
            _.unset(state, "items");
            return { ...state, activeTab: data, filter: {}, sort: {} };

        case Constants.EDIT_TEAM:
            return { ...state, editValue: { ...data } };

        case Constants.RESET_FORM:
            return { ...state, editValue: {} };

        default:
            return state;
    }
};
