import { request, url } from "Services";

const getItems = (data) => {
    const sendObj = {
        url: url.getUrl('/admin/import-templates', []),
        data,
        type: "GET",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const requestTable = data => {
    return new Promise(resolve => {
        getItems(data).then(res => {
            resolve({
                items: res[0].items,
                pagination: res[0].pagination
            });
        })
        .then(() => url.addHistory(data));
    });
};

export const requestRevertItem = data => {
    const sendObj = {
        url: url.getUrl('/admin/import-templates/revert', []),
        data,
        type: "POST",
    };

    return request.sendRequestWithNoCache(sendObj);
};

export const deleteItem = link => {
    const sendObj = {
        url: url.getUrl(link),
        data: {},
        type: "DELETE",
    };

    return request.sendRequestWithNoCache(sendObj);
};
