import ReactOnRails from "react-on-rails";
import React from "react";
import Root from "App/View/Root";
import Index from "./Index";
import * as _service from "./Services";
import { getProjectsFilter, getProjectTableLabel } from "./Services/TableHeaderService";
import { getProjectViewItem } from "./Services/TableBodyService";

const App = storeName => () => {
    const store = ReactOnRails.getStore(storeName);
    const defaultFolder = _service.getStoreItem(store.getState(), "view.default_folder");
    const name = _service.getStoreItem(store.getState(), "view.name");

    const service = {
        ..._service,
        requestTable: _service.requestTable(window.location.pathname),
    };

    if (defaultFolder === 10 && name === "Projects") {
        service.getFilter = getProjectsFilter;
        service.getViewItem = getProjectViewItem;
        service.getTableLabel = getProjectTableLabel;
    }

    return (
        <Root store={store} service={service}>
            <Index />
        </Root>
    );
};

export default App;
