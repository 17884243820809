export const GraphV2Types = {
    TYPE_1: {
        label: 'Product Code',
        value: '/forecasts/graphs-v2/product-codes'
    },
    TYPE_2: {
        label: 'Product Code + Project Manager',
        value: '/forecasts/graphs-v2/product-codes/managers'
    },
    TYPE_3: {
        label: 'Product Code + Project Application Code',
        value: '/forecasts/graphs-v2/product-codes/project-application-codes'
    },
    TYPE_4: {
        label: 'Product Code + SAP Application Code',
        value: '/forecasts/graphs-v2/product-codes/sap-application-codes'
    },
    TYPE_5: {
        label: 'Project Manager + Product Code',
        value: '/forecasts/graphs-v2/project-managers/product-codes'
    },
    TYPE_6: {
        label: 'Project Application Code + Product Code',
        value: '/forecasts/graphs-v2/project-application-codes/product-codes'
    },
    TYPE_7: {
        label: 'SAP Application Code',
        value: '/forecasts/graphs-v2/sap-application-codes'
    },
    TYPE_8: {
        label: 'SAP Application Code + Product Code ',
        value: '/forecasts/graphs-v2/sap-application-codes/product-codes'
    },
    TYPE_9: {
        label: 'Project Segment',
        value: '/forecasts/graphs-v2/project-segments'
    },
    TYPE_10: {
        label: 'Product Code + Project Manager Connections Comparison',
        value: '/forecasts/graphs-v2/product-codes/managers/connections'
    },
    TYPE_12: {
        label: 'Project Manager + Connections',
        value: '/forecasts/graphs-v2/project-managers/connections'
    },
    TYPE_13: {
        label: 'Actuals and Forecast Comparison',
        value: '/forecasts/graphs-v2/project-managers/product-codes/actuals-bs'
    }
}

export const filterListLinks = {
    TYPE_1: {
        label: 'Product Code',
        value: '/project/projects/product-code-list'
    },
    TYPE_2: {
        label: 'Product Code',
        value: '/project/projects/product-code-list'
    },
    TYPE_3: {
        label: 'Product Code',
        value: '/project/projects/product-code-list'
    },
    TYPE_4: {
        label: 'Product Code',
        value: '/project/projects/product-code-list'
    },
    TYPE_5: {
        label: 'Project Manager',
        value: '/project/projects/user-list'
    },
    TYPE_6: {
        label: 'Project Application Code',
        value: '/project/projects/application-code-list'
    },
    TYPE_7: {
        label: 'SAP Application Code',
        value: '/project/projects/application-code-list'
    },
    TYPE_8: {
        label: 'SAP Application Code',
        value: '/project/projects/application-code-list'
    },
    TYPE_9: {
        label: 'Project Segment',
        value: '/project/projects/sub-segment-list'
    },
    TYPE_10: {
        label: 'Product Code',
        value: '/project/projects/product-code-list'
    },
    TYPE_12: {
        label: 'Project Manager',
        value: '/project/projects/user-list'
    },
    TYPE_13: {
        label: 'Project Manager',
        value: '/project/projects/user-list'
    },
}

export const findTypeKeyByValue = (targetValue) => {
    const foundKey = Object.keys(GraphV2Types).find(
        key => GraphV2Types[key].value === targetValue
    );

    return foundKey || null;
};

export const EMPTY_CHART = {
    [`${new Date().getFullYear() - 1}`]: [
        {
            "id": 10,
            "name": "",
            "sum": 0,
            "status": 10,
            "internal_items": []
        },
        {
            "id": 9,
            "name": "",
            "sum": 0,
            "status": 9,
            "internal_items": []
        },
        {
            "id": 8,
            "name": "",
            "sum": 0,
            "status": 8,
            "internal_items": []
        },
        {
            "id": 7,
            "name": "",
            "sum": 0,
            "status": 7,
            "internal_items": []
        }
    ],
    [`${new Date().getFullYear()}`]: [
        {
            "id": 10,
            "name": "",
            "sum": 0,
            "status": 10,
            "internal_items": []
        },
        {
            "id": 9,
            "name": "",
            "sum": 0,
            "status": 9,
            "internal_items": []
        },
        {
            "id": 8,
            "name": "",
            "sum": 0,
            "status": 8,
            "internal_items": []
        },
        {
            "id": 7,
            "name": "",
            "sum": 0,
            "status": 7,
            "internal_items": []
        }
    ],
    [`${new Date().getFullYear() + 1}`]: [
        {
            "id": 10,
            "name": "",
            "sum": 0,
            "status": 10,
            "internal_items": []
        },
        {
            "id": 9,
            "name": "",
            "sum": 0,
            "status": 9,
            "internal_items": []
        },
        {
            "id": 8,
            "name": "",
            "sum": 0,
            "status": 8,
            "internal_items": []
        },
        {
            "id": 7,
            "name": "",
            "sum": 0,
            "status": 7,
            "internal_items": []
        }
    ],
    [`${new Date().getFullYear() + 2}`]: [
        {
            "id": 10,
            "name": "",
            "sum": 0,
            "status": 10,
            "internal_items": []
        },
        {
            "id": 9,
            "name": "",
            "sum": 0,
            "status": 9,
            "internal_items": []
        },
        {
            "id": 8,
            "name": "",
            "sum": 0,
            "status": 8,
            "internal_items": []
        },
        {
            "id": 7,
            "name": "",
            "sum": 0,
            "status": 7,
            "internal_items": []
        }
    ],
    [`${new Date().getFullYear() + 3}`]: [
        {
            "id": 10,
            "name": "",
            "sum": 0,
            "status": 10,
            "internal_items": []
        },
        {
            "id": 9,
            "name": "",
            "sum": 0,
            "status": 9,
            "internal_items": []
        },
        {
            "id": 8,
            "name": "",
            "sum": 0,
            "status": 8,
            "internal_items": []
        },
        {
            "id": 7,
            "name": "",
            "sum": 0,
            "status": 7,
            "internal_items": []
        }
    ],
    [`${new Date().getFullYear() + 4}`]: [
        {
            "id": 10,
            "name": "",
            "sum": 0,
            "status": 10,
            "internal_items": []
        },
        {
            "id": 9,
            "name": "",
            "sum": 0,
            "status": 9,
            "internal_items": []
        },
        {
            "id": 8,
            "name": "",
            "sum": 0,
            "status": 8,
            "internal_items": []
        },
        {
            "id": 7,
            "name": "",
            "sum": 0,
            "status": 7,
            "internal_items": []
        }
    ]
}