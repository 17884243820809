import { handleScroll } from "../../EventsService";
import {getItem, requestOnSaveCopy, getUploadUrl} from "./RequestService";
import { getActionStore, getStoreItem } from "./StoreService";
import { deleteItem, requestTable } from "../../RequestService";
import { getFilter, getTableLabel } from "../../TableHeaderService";
import { getViewItem, modifierValues } from "../../TableBodyService";

export default (id, designId) => ({
    requestTable: requestTable(`/product/publications/${id}/design/${designId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    modifierValues,
    getTableLabel,
    requestSubTab: () => getItem(`/product/product-finder/publications/${id}/design`),
    requestTypeItem: (item) => getItem(`/product/product-finder/publications/${id}/design/${item.id}`),
    requestOnSaveCopy: (designId) => requestOnSaveCopy(`/product/designs/${designId}/copy`),
    onClickDeleteItem: item => deleteItem(`/product/designs/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/designs/${designId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getUploadUrl: (type) => getUploadUrl(designId, type),
});
