import React from "react";
import PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: "",
};

const Form = ({ className, children }) => (
    <form onSubmit={e => e.preventDefault()} className={`main-modal__form ${className}`}>
        {children}
    </form>
);

Form.defaultProps = defaultProps;
Form.propTypes = propTypes;

export default Form;
