import React from "react";
import TableHeadColumnTemplate from "Templates/Table/TableHeadColumn";
import PropTypes from "prop-types";
import _ from "lodash";
import {Tooltip,} from "Templates/Form";
import {compose} from "redux";
import TableHeadFilter from "Templates/Table/TableHeadFilter";
import TableHeadTitle from "Templates/Table/TableHeadTitle";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    column: PropTypes.string.isRequired,
    filter: PropTypes.objectOf(PropTypes.any).isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    service: PropTypes.shape({
        getFilter: PropTypes.func.isRequired,
        getTableLabel: PropTypes.func.isRequired,
    }).isRequired,
    onSortClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    activeTab: PropTypes.number.isRequired,
};

const TableHeadColumnWrap = ({
                                 t,
                                 column,
                                 filter,
                                 sort,
                                 service,
                                 onSortClick,
                                 onChange,
                                 currentFY,
                                 totalAnalytics,
                                 activeTab
                             }) => {
    const {getFilter, getTableLabel} = service;
    const datesLabels = {
        'grade_sub_status': `
            <div class="long-tooltip"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</div>
            <div class="long-tooltip"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</div>
            <div class="long-tooltip"><span class="font-medium">Production Intent</span> - Product in process for being approved.</div>
            <div class="long-tooltip"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</div>
            <div class="long-tooltip"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</div>
            <div class="long-tooltip"><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</div>
        `,
        'material_type': `<div class="padding">FERT = Finished Goods</div><div class="padding">HALB = Semifinished Goods</div><div class="padding">HAWA = Trade Goods</div>`,
        'move_to_current': 'If not the full fiscal year',
        'name': 'Lists SAP company name fields 1 - 4 concatenated',
        'customer_short_name': 'Lists SAP company name fields 1 only',
        'customer_duns_number': 'D-U-N-S is a registered trademark D&B'
    };

    const getLabel = (activeTab, column) => {
        if (activeTab === 4) {
            switch (column) {
                case 'name':
                    return null;
                case 'customer':
                    return datesLabels['name'];
            }
        }
        return datesLabels[column];
    };

    return (
        <TableHeadColumnTemplate>
            <div className={`head__with-tooltip flex`}>
                <TableHeadTitle column={column} sort={sort} onSortClick={() => onSortClick(column)}>
                    {t(getTableLabel(column, {currentFY}))}
                </TableHeadTitle>
                {
                    getLabel(activeTab, column) &&
                    <Tooltip>
                        <span dangerouslySetInnerHTML={{__html: getLabel(activeTab, column)}}></span>
                    </Tooltip>
                }
            </div>
            <TableHeadFilter>
                {getFilter(column, _.get(filter, column, "") || _.get(filter, `${column}s`, ""), onChange, {
                    filter,
                    sort
                }, {totalAnalytics})}
            </TableHeadFilter>
        </TableHeadColumnTemplate>
    );
};

TableHeadColumnWrap.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const {getStoreItem} = ownProps.service;

    return {
        filter: getStoreItem(state, "filter"),
        sort: getStoreItem(state, "sort"),
        currentFY: getStoreItem(state, "financialYear"),
        activeTab: getStoreItem(state, "activeTab"),
        totalAnalytics: getStoreItem(state, "total_analytics"),
        help: getStoreItem(state, "help"),
    };
};

const mapDispatchToProps = (dispatch, {service}) => {
    const {getActionStore} = service;

    return {
        onChange: getActionStore("onChange", service, dispatch),
    };
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps(mapStateToProps, mapDispatchToProps),
)(TableHeadColumnWrap);