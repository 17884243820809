import Constants from "../Constants";

export const onChangeTab = (service, dispatch) => (data) => {
    dispatch({type: Constants.CHANGE_TAB, data});

    if (typeof window !== 'undefined') {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('product');
        queryParams.delete('activeCompareTab');
        window.history.replaceState({}, '', `${window.location.pathname}`);
    }
};
export const onChangeCompareTab = (service, dispatch) => (data) => {
    dispatch({type: Constants.CHANGE_COMPARE_TAB, data});

    if (typeof window !== 'undefined') {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('product');
        queryParams.delete('activeCompareTab');
        window.history.replaceState({}, '', `${window.location.pathname}`);
    }
};

export const handleCloseModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.CLOSE_MODAL, data});
};

export const handleOpenModal = (service, dispatch) => (data) => {
    dispatch({type: Constants.OPEN_MODAL, data});
};
